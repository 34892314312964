import { subject } from "@casl/ability";
import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { getMediaArray, QUERY_KEY_MEDIA } from "../../firestore";

type TGetRespostasResponse = Awaited<ReturnType<typeof getMediaArray>>;

/**
 * Get respostas query hook
 * @param root0 - root
 * @param root0.id - id
 * @returns Respostas query hook
 */
function useQueryMedias({ id }: { id: string }) {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  return useQuery<TGetRespostasResponse, FirestoreError, TMedia[]>({
    queryKey: ["empresas", { id: activeEmpresaId }, QUERY_KEY_MEDIA, id],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      if (!id) {
        throw new Error("Empresa não selecionada");
      }

      return getMediaArray(activeEmpresaId, id);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }
      return dataResponse.docs.map((document_) => subject("TMedia", document_.data()));
    }
  });
}

export default useQueryMedias;
