import { subject } from "@casl/ability";
import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { getCategoriasDeInovacao, QUERY_KEY } from "../../firestore";

type TGetTipoDeInovacaoResponse = Awaited<ReturnType<typeof getCategoriasDeInovacao>>;

/**
 * Get tipos de inovação query hook
 * @returns tipos de inovação query hook
 */
function useQueryCategoriaDeInovacao() {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  return useQuery<TGetTipoDeInovacaoResponse, FirestoreError, TCategoriaDeInovacao[]>({
    queryKey: ["empresas", { id: activeEmpresaId }, QUERY_KEY],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      return getCategoriasDeInovacao(activeEmpresaId);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }
      return dataResponse.docs.map((document_) => subject("TCategoriaDeInovacao", document_.data()));
    }
  });
}

export default useQueryCategoriaDeInovacao;
