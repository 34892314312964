// prettier-ignore
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Flex,
  Group,
  Image,
  LoadingOverlay,
  MultiSelect,
  rem,
  Select,
  Space,
  Stack,
  Text,
  TextInput
} from "@mantine/core";
import { DateInput, DateInputProps } from "@mantine/dates";
import { FileWithPath, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { showNotification } from "@mantine/notifications";
import { IconAlertCircle, IconUser } from "@tabler/icons-react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { getDownloadURL } from "firebase/storage";
import Can from "@/components/can";
import FileSelectorDropZoneUser from "@/components/file-selector-dropzone/FileSelectorDropZoneUser";
import TelInput from "@/components/tel-input";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import { extractTextInputPropertiesFromFormSchema } from "@/utils/form";
import "dayjs/locale/pt-br";
import useColaboradorForm from "./useColaboradorForm";
import { TColaboradorForm } from "../../firestore";
import useGetColaboradorById from "../../hooks/useGetColaboradorById";
import colaboradorSchema, { MAX_AGE, MIN_AGE } from "../../schema/colaborador-schema";
import { uploadAnexosFilesToStorage } from "../../storage";

dayjs.extend(customParseFormat);

dayjs.locale("pt-br");

// prettier-ignore
// Date object that is 120 years ago
const date120YearsAgo = new Date(Date.now() - MIN_AGE);
const date16YearsAgo = new Date(Date.now() - MAX_AGE);
const acceptedImageTypes = [...IMAGE_MIME_TYPE];
const ICON_SIZE = 52;
const MAX_RANDOM = 1_000_000;
const NUMBER_DATE_YEAR = 1939;
const NUMBER_DATE_MONTH = 8;

const BYTES_IN_KB = 1024;
const MAX_SIZE_KB = 100;
const MAX_SIZE_B = MAX_SIZE_KB * BYTES_IN_KB;

const ROLES_DATA = [
  { value: "ADMIN", label: "Administrador" },
  { value: "MEMBER", label: "Membro" },
  { value: "TEAM", label: "Equipe" },
  { value: "MASTER", label: "Master" },
  { value: "COLLABORATOR", label: "Colaborador" }
];
// prettier-ignore
const ColaboradorForm = ({ colaborador, onSuccess, setName }: { colaborador: TColaboradorForm; onSuccess: () => void , setName: (name: string) => void}) => {
  if (!colaborador?.id) {
    colaborador.role = "COLLABORATOR";
  }

  if (!colaborador.telefone) {
    colaborador.telefone = "";
  }

  const { onSubmit, form, isPending } = useColaboradorForm(colaborador, {
    onSuccess,
  }, setName);
  const empresa = useUserStore((state) => state.activeEmpresa);

  const {colaborador: activeColaborador} = useUserStore()
  const uid = getCurrentUser()?.uid;
  const { data: userColaborador } = useGetColaboradorById(empresa?.id || "", uid || "")
  const colaboradorFieldsDescriptor = colaboradorSchema.describe();

  const genericError = form.errors[""];

  /**
   * Handle anexos selected
   * @param files - The selected files
   */
  async function handleAnexosSelected(files: FileWithPath[]) {
    const image = files.pop();
    if (!image || !empresa) {
      return;
    }

    if (image.size > MAX_SIZE_B) {
      form.setFieldError("image", "O tamanho do arquivo não pode ser maior que 1MB");

      showNotification({
        message: `O tamanho da imagem não deve exceder ${MAX_SIZE_KB}Kb.`,
        color: "red",
        autoClose: 2500
      });

      return
    }

    const filename = Math.floor(Math.random() * MAX_RANDOM) + 1

    const file = await uploadAnexosFilesToStorage(empresa.id, String(filename), image)
    const fileUrl = await getDownloadURL(file.ref);

    const anexo: TFileStored = {
      path: fileUrl,
      name: "",
      size: 0,
      type: ""
    };

    form.setFieldValue("image", [anexo]);
  }

  return (
    <form onSubmit={onSubmit}>
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />
        <Stack gap="xs">
          <Flex align="center" >
            <FileSelectorDropZoneUser w="200px"
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onDrop={handleAnexosSelected}
              accept={acceptedImageTypes} >
              <Flex gap={0} justify="center" align="center" direction="column" wrap="wrap">
                {form?.values?.image?.length > 0 ? <Image
                  radius="md"
                  src={form?.values?.image[0]?.path as string}
                /> : <IconUser
                  style={{ width: rem(ICON_SIZE), height: rem(ICON_SIZE), color: "black" }}
                  stroke={1.5}
                />}
              </Flex>
            </FileSelectorDropZoneUser>
          </Flex>
          <Group>
            <Can I="update" an="TColaborador">
              {
                userColaborador && userColaborador.role === "ADMIN"
                  ? <Select
                    data={ROLES_DATA}
                    w="160px"
                    allowDeselect={false}
                    {...form.getInputProps("role")}
                    {...extractTextInputPropertiesFromFormSchema(colaboradorFieldsDescriptor, "role")}
                  /> : <></>
              }
            </Can>
            <Can I="update" an="TColaborador">
              <MultiSelect
                data={empresa?.setores}
                w="160px"
                {...form.getInputProps("setores")}
                {...extractTextInputPropertiesFromFormSchema(colaboradorFieldsDescriptor, "setores")}
              />
            </Can>
          </Group>
          <Group>
            <TextInput
              type="text"
              style={{ flex: 1 }}
              data-autofocus
              {...form.getInputProps("nome")}
              {...extractTextInputPropertiesFromFormSchema(colaboradorFieldsDescriptor, "nome")}
            />
            <DateInput
              w="160px"
              minDate={date120YearsAgo}
              maxDate={date16YearsAgo}
              dateParser={dateParser}
              valueFormat="DD/MM/YYYY"
              {...form.getInputProps("dataNascimento")}
              {...extractTextInputPropertiesFromFormSchema(colaboradorFieldsDescriptor, "dataNascimento")}
            />
          </Group>
          <Group>
            <TextInput
              type="email"
              style={{ flex: 1 }}
              {...form.getInputProps("email")}
              {...extractTextInputPropertiesFromFormSchema(colaboradorFieldsDescriptor, "email")}
            />
            <TelInput
              w="160px"
              {...form.getInputProps("telefone")}
              {...extractTextInputPropertiesFromFormSchema(colaboradorFieldsDescriptor, "telefone")}
            />
          </Group>
          <Space h={"md"} />
          {/* eslint-disable-next-line unicorn/no-negated-condition */}
          {form.values.role !== "ADMIN" && activeColaborador?.role === "ADMIN" ? <>
            <Text fw={700}>Autorizações de modulos</Text>
            <Space h={"md"} />
            <Group>
              <Checkbox
                {...form.getInputProps("gestao")}
                {...extractTextInputPropertiesFromFormSchema(colaboradorFieldsDescriptor, "gestao")}
                checked={form.getInputProps("gestao").value as boolean}
              />
              <Checkbox
                {...form.getInputProps("diagnostico")}
                {...extractTextInputPropertiesFromFormSchema(colaboradorFieldsDescriptor, "diagnostico")}
                checked={form.getInputProps("diagnostico").value as boolean}
              />
              <Checkbox
                {...form.getInputProps("incentivo")}
                {...extractTextInputPropertiesFromFormSchema(colaboradorFieldsDescriptor, "incentivo")}
                checked={form.getInputProps("incentivo").value as boolean}
              />
              <Checkbox
                {...form.getInputProps("configuracoes")}
                {...extractTextInputPropertiesFromFormSchema(colaboradorFieldsDescriptor, "configuracoes")}
                checked={form.getInputProps("configuracoes").value as boolean}
              />
            </Group>
            <Space h={"md"} />
          </> : <></>}
        </Stack>
      </Box>
      <Group justify={genericError ? "space-between" : "flex-end"} mt="md">
        {genericError && (
          <Alert icon={<IconAlertCircle size="1rem" />} color="red">
            {genericError}
          </Alert>
        )}
        <Button type="submit" loading={isPending}>
          Salvar
        </Button>
      </Group>
    </form>
  );
};

export default ColaboradorForm;

const dateParser: DateInputProps["dateParser"] = (input) => {
  if (input === "WW2") {
    return new Date(NUMBER_DATE_YEAR, NUMBER_DATE_MONTH, 1);
  }

  return dayjs(input, "DD/MM/YYYY").toDate();
};
