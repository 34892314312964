import { serverTimestamp } from "firebase/firestore";
import { ObjectSchema } from "yup";
import schema from "@/schema";
import { getCurrentUser } from "@/services/auth";
import { TQuestionarioForm, TQuestionarioDatabaseFields, TQuestionarioFormFields } from "../firestore";

const MIN_NAME_LENGTH = 3;

/**
 * Define only the meta fields that are not part of the form
 */
const questionarioSchemaDatabaseFields: ObjectSchema<TQuestionarioDatabaseFields> = schema.object().shape({
  createdAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .required()
    .defined()
    .nonNullable()
    .default<ReturnType<typeof serverTimestamp>>(() => serverTimestamp()),
  createdBy: schema
    .string()
    .required()
    .defined()
    .nonNullable()
    .default(() => {
      const currentUserId = getCurrentUser()?.uid;
      if (!currentUserId) {
        throw new Error("The current user is not defined");
      }
      return currentUserId;
    }),
  updatedAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .default<ReturnType<typeof serverTimestamp>>(() => serverTimestamp()),
  updatedBy: schema.string().default(() => {
    const currentUserId = getCurrentUser()?.uid;
    if (!currentUserId) {
      throw new Error("The current user is not defined");
    }
    return currentUserId;
  }),
  deletedAt: schema.mixed<ReturnType<typeof serverTimestamp>>().defined().nullable().default(null)
});

/**
 * Define the schema for the form
 */
const questionarioSchemaFormFields: ObjectSchema<TQuestionarioFormFields> = schema.object({
  id: schema.string().optional(),
  refPath: schema.string().optional(),
  nome: schema.string().trim().min(MIN_NAME_LENGTH).required().defined().label("Nome"),
  descricao: schema.string().trim().min(MIN_NAME_LENGTH).required().defined().label("Descricao"),
  dataAtuacao: schema.date().required().label("Data de Início"), // tornando obrigatório
  dataVigencia: schema.date().required().label("Data de Fim"), // tornando obrigatório
  limiteUsuario: schema.number().required().defined().label("Limite de usúarios"),
  // padrao: schema.boolean().optional().label("Questionário Padrão"),
  // publico: schema.boolean().optional().label("Questionário Público"),
  idProjeto: schema.string().required().defined().label("Projeto"),
  faixas: schema.array().of(schema.string().required()).default([]).label("Faixas").optional(),
  fases: schema.array().of(schema.object().optional().defined()).default([]).optional().label("Fases").optional()
});

const questionarioSchema: ObjectSchema<TQuestionarioForm> = questionarioSchemaFormFields
  // eslint-disable-next-line unicorn/prefer-spread
  .concat(questionarioSchemaDatabaseFields)
  .noUnknown()
  .strict()
  .label("Questionario");

export default questionarioSchema;
