import { Loader, Text } from "@mantine/core";
import { getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import useUserStore from "@/modules/users/store";
import { getProjetoDocumentReferenceWithConverter } from "../../firestore";

type ProjetoNameDisplayProperties = {
  projetoId: TProjeto["id"];
};

/**
 * Componente que exibe o nome do projeto
 * @param root0  Propriedades do componente
 * @param root0.projetoId ID do projeto
 * @returns JSX.Element
 */
export function ProjetoNameDisplay({ projetoId }: ProjetoNameDisplayProperties) {
  const { activeEmpresaId } = useUserStore();

  const [projeto, setProjeto] = useState<TProjeto | null>(null);

  useEffect(() => {
    if (activeEmpresaId && projetoId) {
      const loadProjeto = async () => {
        const projeto = await getDoc(getProjetoDocumentReferenceWithConverter(activeEmpresaId, projetoId));
        setProjeto(projeto.data() as TProjeto);
      };

      void loadProjeto();
    }
  }, [activeEmpresaId, projetoId]);

  if (!projeto) {
    return <Loader />;
  }

  return <Text>{projeto.nome}</Text>;
}
