import { Alert, Center, Stack, Text } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { QueryKey } from "@tanstack/react-query";
import { ColumnDef, Table as ReactTable } from "@tanstack/react-table";
import { forwardRef, useImperativeHandle } from "react";
import NoData from "@/components/no-data";
import ResetTableButton from "@/components/table/ResetTableButton";
import { THandler } from "@/components/table/Table";
import TableIdeia from "@/components/table/TableIdeia";
import useQueryTable from "@/components/table/useQueryTable";
import useUserStore from "@/modules/users/store";
import tableColumns from "./Columns";
import { DEFAULT_PAGINATION, DEFAULT_SORTING, getIdeiasNew } from "../../firestore";
const QUERY_KEY: string = "ideias-list" as const;

const renderError = (errorMessage: string | undefined, handleErrorClose: () => void) => (
  <Alert
    icon={<IconAlertCircle size="1rem" />}
    title="Erro ao buscar ideias"
    color="red"
    withCloseButton
    onClose={handleErrorClose}
  >
    {errorMessage || "Ocorreu um erro desconhecido"}
  </Alert>
);

const sortData = (data: TPaginatedData<TIdeia> | undefined) => {
  data?.entries.sort((a, b) => {
    if (
      (a?.rankingByLikes?.totalLikes || 0) === (b?.rankingByLikes?.totalLikes || 0) &&
      (a?.rankingByMoedas?.totalMoedas || 0) === (b?.rankingByMoedas?.totalMoedas || 0)
    ) {
      return a?.createdAt > b?.createdAt ? -1 : 1;
    }
    return (b?.rankingByLikes?.totalLikes || 0) === (a?.rankingByLikes?.totalLikes || 0)
      ? (b?.rankingByMoedas?.totalMoedas || 0) - (a?.rankingByMoedas?.totalMoedas || 0)
      : (b?.rankingByLikes?.totalLikes || 0) - (a?.rankingByLikes?.totalLikes || 0);
  });
};

const renderNoData = (table: ReactTable<TIdeia>) => (
  <Center m="lg">
    <NoData m="md">
      <Stack gap="xs">
        <Text c="dimmed">Nenhuma ideia Encontrada!</Text>
        <ResetTableButton table={table as ReactTable<unknown>} queryKey={QUERY_KEY as unknown as QueryKey}>
          Limpar filtros
        </ResetTableButton>
      </Stack>
    </NoData>
  </Center>
);

type IdeiasTableProperties = {
  columns?: ColumnDef<TIdeia>[];
};

const IdeiasTable = forwardRef<THandler<TIdeia>, IdeiasTableProperties>(({ columns = tableColumns }, reference) => {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  const { table, data, isLoading, error, filters, refetch } = useQueryTable<TIdeia>({
    queryKey: [QUERY_KEY],
    enabled: !!activeEmpresaId,
    subjectType: "TIdeia",
    columns,
    initialState: {
      columnFilters: [],
      globalFilter: null,
      pagination: DEFAULT_PAGINATION,
      sorting: DEFAULT_SORTING
    },
    queryFunction: ({ sorting, filters, globalFilters, pagination, paginationCursors }) => {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      const newFilter = filters?.concat([
        { id: "publishedAt", value: ["!=", null] },
        { id: "privado", value: ["==", false] }
      ]);

      return getIdeiasNew(activeEmpresaId, {
        sorting,
        filters: newFilter,
        globalFilters,
        pagination,
        paginationCursors
      });
    }
  });

  useImperativeHandle(reference, () => ({
    table,
    data,
    isLoading,
    error,
    filters
  }));

  const handleErrorClose = () => void refetch();

  if (error) return renderError(error.message, handleErrorClose);

  const isEmpty = !isLoading && data?.entries.length === 0;

  sortData(data);

  return (
    <>
      <TableIdeia table={table} isLoading={isLoading} />
      {isEmpty && !isLoading && renderNoData(table)}
    </>
  );
});

IdeiasTable.displayName = "IdeiasTable";

export default IdeiasTable;
