import { ActionIconProps, Mark, Text, UnstyledButton } from "@mantine/core";
import { modals } from "@mantine/modals";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MouseEvent, PropsWithChildren, forwardRef } from "react";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { deleteGruposDiagnostico } from "../../firestore";
import { QUERY_KEY } from "../grupos-diagnostico-table/GruposDiagnosticoTable";

type RemoveButtonProperties = PropsWithChildren<ActionIconProps & { grupo: TGruposDiagnostico }>;
type TDeleteResponse = Awaited<ReturnType<typeof deleteGruposDiagnostico>>;

const RemoveGruposButton = forwardRef<HTMLButtonElement, RemoveButtonProperties>(
  ({ grupo, children, ...properties }, reference) => {
    const queryClient = useQueryClient();
    const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

    const { mutate: deleteGrupoDiagnosticoMutation, isPending } = useMutation<TDeleteResponse, Error>({
      mutationFn: () => {
        if (!activeEmpresaId) {
          throw new Error("Empresa não selecionada");
        }
        return deleteGruposDiagnostico(activeEmpresaId, grupo);
      },
      onSuccess() {
        return queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      },
      onError(error) {
        captureException(error, true);
      }
    });

    /**
     * Handles the click event on the button.
     * @param event - The event that triggered the click.
     */
    function handleClick(event: MouseEvent<HTMLButtonElement>) {
      event.preventDefault();
      modals.openConfirmModal({
        title: "Remover grupo",
        centered: true,
        children: (
          <Text size="sm">
            Tem certeza que deseja remover o grupo <Mark>{grupo.nome}</Mark>
          </Text>
        ),
        labels: {
          confirm: "Sim",
          cancel: "Não"
        },
        confirmProps: { color: "red" },
        onConfirm: () => {
          void deleteGrupoDiagnosticoMutation();
        }
      });
    }

    return (
      <UnstyledButton disabled={isPending} ref={reference} {...properties} onClick={handleClick}>
        {children}
      </UnstyledButton>
    );
  }
);

RemoveGruposButton.displayName = "RemoveGruposButton";

export default RemoveGruposButton;
