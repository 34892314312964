import { Avatar, Card, Group, Stack, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import useParsedMarkdownText from "@/hooks/useParsedMarkdownText";
import TotalMoedasPerColaborador from "@/modules/desafios-activities-per-colaborador/components/total-moedas-per-colaborador";
import { formatDate } from "@/utils/date";
import DesafioComentarioLoadingCard from "./DesafioComentarioLoadingCard";
import ResponderDesafioComentarioButton from "./ResponderDesafioComentarioButton";
import RespostasAoComentarioAvatars from "./RespostasAoComentarioAvatars";
import useGetComentariosRespostas from "./useGetComentariosRespostas";

const DesafioComentarioRespostas = ({ desafio, comentario }: { desafio: TDesafio; comentario: TDesafioComentario }) => {
  const { isLoading, data: comentarios } = useGetComentariosRespostas(desafio, comentario);

  if (isLoading) {
    return <DesafioComentarioLoadingCard />;
  }

  return (
    <Stack gap="xs" mt="md">
      {comentarios?.map((comentarioResposta) => (
        <DesafioComentario key={comentarioResposta.id} desafio={desafio} comentario={comentarioResposta} />
      ))}
    </Stack>
  );
};

const DesafioComentario = ({ comentario, desafio }: { comentario: TDesafioComentario; desafio: TDesafio }) => {
  const [opened, { toggle }] = useDisclosure(false);
  const htmlComentario = useParsedMarkdownText(comentario.comentario);

  return (
    <Card withBorder>
      <Group gap="sm" align="start">
        <Avatar src="avatar.png" alt={comentario.autor.nome || comentario.autor.email} />
        <Stack gap="xs" style={{ flex: 1 }}>
          <Stack gap={0}>
            <Group justify="space-between" gap={0}>
              <Group>
                <Text fw={500}>{comentario.autor.nome}</Text>
                <Text fz="xs" c="gray">
                  {formatDate(comentario.createdAt, { year: "numeric", month: "long", day: "numeric" })}
                </Text>
              </Group>
              <TotalMoedasPerColaborador
                colaboradorId={comentario.createdBy}
                desafio={desafio}
                color="yellow"
                variant="light"
              />
            </Group>
            <Text fz="xs" tt="uppercase" c="dimmed">
              {comentario.autor.role}
            </Text>
          </Stack>
          {htmlComentario ? <div dangerouslySetInnerHTML={{ __html: htmlComentario }} /> : comentario.comentario}
          <Group>
            <ResponderDesafioComentarioButton desafio={desafio} comentario={comentario} />
          </Group>
          {opened ? (
            <DesafioComentarioRespostas desafio={desafio} comentario={comentario} />
          ) : (
            <RespostasAoComentarioAvatars desafio={desafio} comentario={comentario} onClick={toggle} />
          )}
        </Stack>
      </Group>
    </Card>
  );
};

export default DesafioComentario;
