import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getConvites } from "@/modules/empresas/firestore";
import { getCurrentUser } from "@/services/auth";

type TGetComitesInovacaoResponse = Awaited<ReturnType<typeof getConvites>>;
const QUERY_KEY: string = "convite-list" as const;
/**
 * Get comites de inovação query hook
 * @returns Comites de inovação query hook
 */
function useQueryConvite() {
  const uid = getCurrentUser()?.uid;
  return useQuery<TGetComitesInovacaoResponse, FirestoreError, TConvite[]>({
    queryKey: [QUERY_KEY, uid],
    enabled: !!uid,
    queryFn() {
      if (!uid) {
        throw new Error("Usuário não autenticado");
      }
      return getConvites(uid);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((document_) => document_.data());
    }
  });
}

export default useQueryConvite;
