import { Button, Group, Stack, TextInput, Text, Space, Checkbox } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { ChangeEvent, useState } from "react";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { extractTextInputPropertiesFromFormSchema } from "@/utils/form";
import inviteSchema from "./invite-colaborador-schema";
import { inviteColaborador } from "../../functions";

interface Values {
  email: string;
  nome: string;
  gestao: boolean;
  diagnostico: boolean;
  incentivo: boolean;
  configuracoes: boolean;
}

// prettier-ignore
const InviteColaboradorForm = ({ onSuccess }: { onSuccess: () => void }) => {
  const [values, setValues] = useState<Values>(oldValues)
  const [loading, setLoading] = useState(false)
  const inviteFieldsDescriptor = inviteSchema.describe();
  const empresaId = useUserStore((state) => state.activeEmpresaId);
  const licenciadaId = useUserStore((state) => state.activeLicenciadaId);

  return (
    <form>
      <Stack gap="md" pos="relative">
        <Text fw="bold" mb="md">
          Convidar colaboradores
        </Text>
        <Text fw={700}>Autorizações de modulos</Text>
        <Space h={"md"} />
        <Group>
          <Checkbox
            onChange={onChangeBoolean("gestao", setValues)}
            {...extractTextInputPropertiesFromFormSchema(inviteFieldsDescriptor, "gestao")}
            disabled={loading}
            checked={values?.gestao as boolean}
          />
          <Checkbox
            onChange={onChangeBoolean("diagnostico", setValues)}
            {...extractTextInputPropertiesFromFormSchema(inviteFieldsDescriptor, "diagnostico")}
            disabled={loading}
            checked={values?.diagnostico as boolean}
          />
          <Checkbox
            onChange={onChangeBoolean("incentivo", setValues)}
            {...extractTextInputPropertiesFromFormSchema(inviteFieldsDescriptor, "incentivo")}
            disabled={loading}
            checked={values?.incentivo as boolean}
          />
          <Checkbox
            onChange={onChangeBoolean("configuracoes", setValues)}
            {...extractTextInputPropertiesFromFormSchema(inviteFieldsDescriptor, "configuracoes")}
            disabled={loading}
            checked={values?.configuracoes as boolean}
          />
        </Group>
        <Space h={"md"} />
        <TextInput
          type="text"
          data-autofocus
          style={{ flex: 1 }}
          onChange={onChangeValues("nome", setValues)}
          disabled={loading}
          {...extractTextInputPropertiesFromFormSchema(inviteFieldsDescriptor, "nome")}
        />
        <TextInput
          type="text"
          style={{ flex: 1 }}
          onChange={onChangeValues("email", setValues)}
          disabled={loading}
          {...extractTextInputPropertiesFromFormSchema(inviteFieldsDescriptor, "email")}
        />
      </Stack>
      <Group justify={"flex-end"} mt="md">
        <Button onClick={inviteColaboradores(onSuccess, values, empresaId, licenciadaId, setLoading)} loading={loading}>
          Enviar
        </Button>
      </Group>
    </form>
  );
};

export default InviteColaboradorForm;

// prettier-ignore
const oldValues = {
  email: "",
  nome: "",
  gestao: true,
  diagnostico: true,
  incentivo: true,
  configuracoes: true
};

// prettier-ignore
const onChangeValues = (field: string, setValues: React.Dispatch<React.SetStateAction<Values>>) => {
  return (event: ChangeEvent<HTMLInputElement>) => {
    setValues(values => ({ ...values, [field]: event?.target.value }))
  }
}

// prettier-ignore
const onChangeBoolean = (field: string, setValues: React.Dispatch<React.SetStateAction<Values>>) => {
  return (event: ChangeEvent<HTMLInputElement>) => {
    setValues(values => ({ ...values, [field]: event?.target.checked }))
  }
}

/**
 * Handles the click event on copy link to clipboard.
 * @param onSuccess - Function case success invite
 * @param values - The text with emails.
 * @param empresaId - The another id company.
 * @param licenciadaId - active licenciada
 * @param setLoading - The another id company.
 * @returns - The return the click.
 */
// prettier-ignore
// eslint-disable-next-line complexity
const inviteColaboradores = (
  onSuccess: () => void,
  values: Values,
  empresaId: string = "",
  licenciadaId: string = "",
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  return () => {
    try {
      setLoading(true)
      if (values.nome === "") {
        throw new Error("Nome obrigatório");
      }

      if (values?.email === "") {
        throw new Error("Email obrigatório");
      }

      inviteColaborador({ ...values, licenciadaId, empresaId })
        .then(() => {
          onSuccess();
          showNotification({
            message: `Cadastrado com sucesso!`,
            color: "green",
            autoClose: 2500
          });
          return undefined;
        })
        .catch((error: Error) => {
          captureException(error, true);
          setLoading(false);
        });
    } catch (error) {
      if (error instanceof Error) {
        showNotification({
          message: error.message,
          color: "red",
          autoClose: 2500
        });
        setLoading(false)
      } else {
        showNotification({
          message: "Ocorreu um erro desconhecido.",
          color: "red",
          autoClose: 2500
        });
      }
    }


  };
};
