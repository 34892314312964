import { Card, Grid, Text } from "@mantine/core";
// prettier-ignore
const HeaderTable = () => {
  return (
    <Card padding="lg" radius="md">
      <Grid>
        <Grid.Col span={4}>
          <Text size="sm" fw={500}>Rota</Text>
        </Grid.Col>
        <Grid.Col span={4} offset={3}>
          <Text size="sm" fw={500}>Acesso</Text>
        </Grid.Col>
      </Grid>
    </Card>
  );
};

export default HeaderTable;
