import { Anchor, Space } from "@mantine/core";
import { useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import BreadcrumbsNavigation from "@/components/breadcrumbs-navigation";
import CardEmpresa from "@/modules/analise-etapa-processo/components/cards/CardEmpresa";
import CardNomeEmpresa from "@/modules/analise-etapa-processo/components/cards/CardNomeEmpresa";
import CardPremio from "@/modules/analise-etapa-processo/components/cards/CardPremio";
import EstrategiaList from "@/modules/analise-etapa-processo/components/estrategia-list/EstrategiaList";
import { useAcessoQuestionario } from "@/modules/analise-etapa-processo/components/estrategia-list/useAcessoQuestionario";
import useQueryGroup from "@/modules/analise-etapa-processo/components/estrategia-list/useGetGroup";
import useGetResposta from "@/modules/analise-etapa-processo/components/estrategia-list/useGetResposta";
import { getCurrentUser } from "@/services/auth";

// prettier-ignore
const AnalisarInfoPage = () => {
  const { data } = useQueryGroup();
  const { id, idProjeto, idEmpresa } = useParams<{ id: string; idProjeto: string; idEmpresa: string }>();
  const currentUserId = getCurrentUser()?.uid;
  const grupos = data || [];
  const { data: respostasQuestionarios } = useGetResposta();
  const respostas = respostasQuestionarios && respostasQuestionarios[0] && respostasQuestionarios[0].perguntas || [];

  const { mutate } = useAcessoQuestionario({
    idQuestionario: id || "",
    idProjeto: idProjeto || "",
    updatedAt: new Date(),
    deletedAt: null,
    createdBy: currentUserId,
    updatedBy: currentUserId,
    idEmpresa: idEmpresa || ""
  });

  useEffect(() => {
    mutate();
  }, [id]);

  return (
    <>
      <BreadcrumbsNavigation>
        <Anchor component={NavLink} to="/diagnostico">
          Diagnóstico
        </Anchor>
      </BreadcrumbsNavigation>
      <CardNomeEmpresa />
      <Space h={"md"} />
      <CardEmpresa />
      <Space h={"md"} />
      <CardPremio />
      <Space h={"md"} />
      {grupos.map(item => <EstrategiaList key={item.id} grupo={item} respostas={respostas as []} />)}
    </>
  );
};

AnalisarInfoPage.displayName = "AnalisarInfoPage";

export default AnalisarInfoPage;
