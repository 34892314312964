import { Button } from "@mantine/core";
import { modals } from "@mantine/modals";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { colaboradorConverter } from "@/modules/colaboradores/firestore";
import { DESAFIOS_COLLECTION_KEY, includeParticipante } from "@/modules/desafios/firestore";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { getDocumentReferenceFromPath } from "@/utils/firestore";

type IncludeParticipanteResponse = Awaited<ReturnType<typeof includeParticipante>>;

/**
 * Handle the mutation to participate in a Desafio
 * @param desafio - Desafio to participate
 * @returns - Mutation result
 */
function useParticiparDesafioMutation(desafio: TDesafio) {
  const [activeEmpresaId, currentUser] = useUserStore((state) => [state.activeEmpresaId, state.colaborador]);
  const queryClient = useQueryClient();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  const formatValue = currentUser?.refPath?.split("/")[3] as string;
  const newValue = `empresas/${activeEmpresaId}/colaboradores/${formatValue}`;

  return useMutation<IncludeParticipanteResponse, FirestoreError>({
    mutationFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!currentUser || !newValue) {
        throw new Error("Usuário não autenticado");
      }

      const colaboradorDocumentReference = getDocumentReferenceFromPath(newValue, colaboradorConverter);

      return includeParticipante(activeEmpresaId, desafio.id, colaboradorDocumentReference);
    },
    onSuccess() {
      void queryClient.invalidateQueries({ queryKey: [DESAFIOS_COLLECTION_KEY, desafio.id] });
    },
    onError(error) {
      captureException(error, true);
    }
  });
}

const ParticiparDesafioButton = ({ desafio }: { desafio: TDesafio }) => {
  const { mutate, isPending } = useParticiparDesafioMutation(desafio);
  /**
   * Handle the click event to participate in the Desafio
   */
  function handleClick() {
    modals.openConfirmModal({
      title: `Deseja participar do desafio "${desafio.titulo}"?`,
      labels: { confirm: "Participar", cancel: "Cancelar" },
      onConfirm: () => mutate()
    });
  }
  return (
    <Button type="button" onClick={handleClick} loading={isPending}>
      Participar
    </Button>
  );
};

export default ParticiparDesafioButton;
