import { Flex, Group, Space, Text } from "@mantine/core";
import useGetConvite from "./useGetEmpresasConvidadas";
// import { useParams } from "react-router-dom";
// prettier-ignore
const EmpresasConvidadas = ({ idProjeto, idEmpresa, size, fw }: { idProjeto: TProjeto["id"] | undefined, idEmpresa: TEmpresa["id"] | undefined, size: string, fw: number }) => {
  const { data } = useGetConvite(idEmpresa, idProjeto, true);
  const space = "space-between";

  return (
    <Flex direction="column">
      <Text size="xs">Empresas convidadas</Text>
      <Space h="md" />
      <Group justify={space}>
        <Text size={size} fw={fw}>
          {data && data.length > 0 ? data?.length : 0}
        </Text>
      </Group>
    </Flex>
  );
};

export default EmpresasConvidadas;
