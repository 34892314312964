import { Flex, Space, Text, Title } from "@mantine/core";
import { QueryObserverResult } from "@tanstack/react-query";
import { Timestamp } from "firebase/firestore";
import DesafioCronogramaList from "@/modules/desafio-cronograma/components/desafio-cronograma-list";
import useGetCronograma from "@/modules/desafio-cronograma/components/desafio-cronograma-list/useGetInscricao";
import { useUpdateIncricao } from "@/modules/desafio-cronograma/components/list-form-cronograma/useGetInscricao";
// prettier-ignore

interface UsuariosCronogramaCardProperties {
  inscritos: { id: string | undefined; data: Date; status?: number | undefined; destaque?: boolean | undefined; }[];
  desafio: TDesafio;
  refetch: () => Promise<QueryObserverResult>;
}

const UsuariosCronogramaCard = ({ inscritos, desafio, refetch }: UsuariosCronogramaCardProperties) => {
  const { data } = useGetCronograma(desafio?.id);
  const { mutate } = useUpdateIncricao();

  const changeMutate = (type: boolean, id: string | undefined, status: number) => {
    return () => {
      const { newData, inscricoes, inicioIncricao, terminoIncricao } = verificationFunction(data, id, type, status);
      mutate({
        ...newData,
        inscricoes,
        updatedAt: Timestamp.now(),
        createdAt: Timestamp.now(),
        deletedAt: null,
        quantidadeDeVagas: newData?.quantidadeDeVagas || (0 as number),
        createdBy: newData?.createdBy || "",
        inicioIncricao,
        terminoIncricao
      });
      void refetch();
    };
  };

  return (
    <Flex direction="column" pb="xs">
      <Title order={4}>Inscritos para participação na imersão do desafio</Title>
      <Space h={"md"} />
      {inscritos && inscritos.length > 0 ? (
        inscritos?.map((item) => (
          <DesafioCronogramaList
            key={item?.id}
            data={item}
            changeMutate={changeMutate}
            completed={data && data[0] && data[0]?.terminoIncricao < new Date() ? true : false}
          />
        ))
      ) : (
        <>
          {" "}
          <Text c="dimmed">Nenhuma inscrição encontrada!</Text>
        </>
      )}
    </Flex>
  );
};

export default UsuariosCronogramaCard;
// prettier-ignore
const verificationFunction = (data: TCronogramaDesafio[] | undefined, id: string | undefined, type: boolean, status: number) => {
  const newData = data && data[0];
  const inscricoes = newData && newData.inscricoes && newData.inscricoes.filter((item) => item.id === id).map((item) => ({ ...item, destaque: type, status }));
  const inicioIncricao = newData && newData.inicioIncricao ? newData.inicioIncricao : new Date();
  const terminoIncricao = newData && newData.terminoIncricao ? newData.terminoIncricao : new Date();

  return { newData, inscricoes, inicioIncricao, terminoIncricao };
};
