import { serverTimestamp } from "firebase/firestore";
import { ObjectSchema } from "yup";
import { IDEIA_STATUS } from "@/modules/ideias/schema/ideia-schema";
import schema from "@/schema";
import { getCurrentUser } from "@/services/auth";
import { TMovimentacaoDatabaseFields, TMovimentacaoForm, TMovimentacaoFormFields } from "../firestore";

const movimentacaoSchemaDatabaseFields: ObjectSchema<TMovimentacaoDatabaseFields> = schema.object().shape({
  createdAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .required()
    .nonNullable()
    .default<ReturnType<typeof serverTimestamp>>(() => serverTimestamp()),
  createdBy: schema
    .string()
    .required()
    .nonNullable()
    .default(() => {
      const currentUserId = getCurrentUser()?.uid;
      if (!currentUserId) {
        throw new Error("The current user is not defined");
      }
      return currentUserId;
    }),
  updatedAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .default<ReturnType<typeof serverTimestamp>>(() => serverTimestamp()),
  updatedBy: schema.string().default(() => {
    const currentUserId = getCurrentUser()?.uid;
    if (!currentUserId) {
      throw new Error("The current user is not defined");
    }
    return currentUserId;
  }),
  deletedAt: schema.mixed<ReturnType<typeof serverTimestamp>>().nullable().default(null)
});

const movimentacaoSchemaFormFields: ObjectSchema<TMovimentacaoFormFields> = schema.object({
  id: schema.string().optional(),
  refPath: schema.string().optional(),
  status: schema.string().required().oneOf(IDEIA_STATUS).defined().label("Status"),
  observacoes: schema.string().required().defined().label("Observações"),
  marcos: schema.array().of(schema.string().optional().defined()).default([]).optional().label("Marcos")
});

const movimentacaoSchema: ObjectSchema<TMovimentacaoForm> = movimentacaoSchemaFormFields
  // eslint-disable-next-line unicorn/prefer-spread
  .concat(movimentacaoSchemaDatabaseFields)
  .noUnknown()
  .strict()
  .label("Movimentação");

export default movimentacaoSchema;
