import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getAllActiveColaboradores } from "@/modules/colaboradores/firestore";
import useUserStore from "@/modules/users/store";

type TGetAllActiveColaboradoresResponse = Awaited<ReturnType<typeof getAllActiveColaboradores>>;
type TQueryOptions<T = TGetAllActiveColaboradoresResponse> = Omit<
  UseQueryOptions<TGetAllActiveColaboradoresResponse, FirestoreError, T>,
  "queryKey" | "queryFn"
>;

/**
 * Hook to get all active colaboradores from firestore
 * @param options - Query options
 * @returns Query result
 */
function useGetActiveColaboradores<T = TGetAllActiveColaboradoresResponse>(options: TQueryOptions<T> = {}) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  return useQuery<TGetAllActiveColaboradoresResponse, FirestoreError, T>({
    queryKey: ["empresas", activeEmpresaId, "colaboradores", "actives"],
    meta: {
      displayNotification: true
    },
    queryFn: () => {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return getAllActiveColaboradores(activeEmpresaId);
    },
    ...options
  });
}

export default useGetActiveColaboradores;
