import { ThemeIcon, Tooltip } from "@mantine/core";
import { IconCheck, IconLineDashed, IconX } from "@tabler/icons-react";

const IconStatusEmpresa = ({ status }: { status: string }) => {
  switch (status) {
    case "ATIVO": {
      return <EmpresaActiveStatus />;
    }
    case "INATIVO": {
      return <EmpresaInactiveStatus />;
    }
    default: {
      return (
        <ThemeIcon variant="light" color="gray" size="md">
          <IconLineDashed />
        </ThemeIcon>
      );
    }
  }
};

export default IconStatusEmpresa;

const EmpresaActiveStatus = () => {
  return (
    <Tooltip label="Ativo" color="blue" position="right" withArrow>
      <ThemeIcon variant="light" color="green" size="md">
        <IconCheck size="1rem" />
      </ThemeIcon>
    </Tooltip>
  );
};
const EmpresaInactiveStatus = () => {
  return (
    <Tooltip label="Sem acesso ao sistema" color="blue" position="right" withArrow>
      <ThemeIcon variant="light" color="red" size="md">
        <IconX size="1rem" />
      </ThemeIcon>
    </Tooltip>
  );
};
