import { useMutation } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { addIdeia, TIdeiaForm } from "../../firestore";

type TUpdateCronogramaResponse = Awaited<ReturnType<typeof addIdeia>>;
/**
 * Finalizar resposta de inovação mutation hook
 * @returns Finalizar resposta de inovação mutation
 */
// prettier-ignore
export function useAddIdeia() {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  return useMutation< TUpdateCronogramaResponse , FirestoreError, TIdeiaForm & Partial<Pick<TIdeia, "id" | "refPath">>>({
    mutationFn(item) {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!item) {
        throw new Error("Empresa não selecionada");
      }

      return addIdeia(activeEmpresaId, item)
    },
  });
}
