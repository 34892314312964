import { Button, Group } from "@mantine/core";
import { IconHexagonLetterX } from "@tabler/icons-react";
import ContinuarComiteInovacaoButton from "../continuar-comite-inovacao-button";
import FinalizarComiteInovacaoButton from "../finalizar-comite-inovacao-button";
import IniciarComiteInovacaoButton from "../iniciar-comite-inovacao-button";
import PausarComiteInovacaoButton from "../pausar-comite-inovacao-button";

const ComiteInovacaoStatusButtons = ({ comite }: { comite: TComiteInovacao | null }) => {
  const isPaused = !!comite?.pausedAt;
  const isFinished = !!comite?.endedAt;

  if (isFinished) {
    return (
      <Button leftSection={<IconHexagonLetterX />} disabled variant="light" radius="lg">
        Comitê finalizado
      </Button>
    );
  }

  if (comite) {
    return (
      <Group gap="xs">
        {isPaused ? <ContinuarComiteInovacaoButton comite={comite} /> : <PausarComiteInovacaoButton comite={comite} />}
        <FinalizarComiteInovacaoButton comite={comite} />
      </Group>
    );
  }

  return <IniciarComiteInovacaoButton />;
};

export default ComiteInovacaoStatusButtons;
