import { createFormContext } from "@mantine/form";
import { Asserts } from "yup";
import regulamentoIdeiasSchema from "../../schema";

type FormInputs = Asserts<typeof regulamentoIdeiasSchema>;

const [RegulamentoIdeiasFormProvider, useRegulamentoIdeiasFormContext, useRegulamentoIdeiasForm] =
  createFormContext<FormInputs>();

export { RegulamentoIdeiasFormProvider, useRegulamentoIdeiasFormContext, useRegulamentoIdeiasForm };
