import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getDesafio, DESAFIOS_COLLECTION_KEY } from "@/modules/desafios/firestore";
import useUserStore from "@/modules/users/store";

type TGetQuestionarioResponse = Awaited<ReturnType<typeof getDesafio>>;

/**
 * Get ideia from url params
 * @param id - id da empresa
 * @param id.id - emresa id
 * @returns - Ideia or null if not found
 */
function useGetDesafioByElementId({ id }: { id: TDesafio["id"] }) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  return useQuery<TGetQuestionarioResponse, FirestoreError, TDesafio | null>({
    queryKey: [DESAFIOS_COLLECTION_KEY, id, activeEmpresaId],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!id) {
        throw new Error("Ideia não selecionada");
      }

      return getDesafio(activeEmpresaId, id);
    },
    select(dataResponse) {
      if (!dataResponse.exists()) {
        return null;
      }

      return dataResponse.data();
    },
    enabled: !!id && !!activeEmpresaId
  });
}

export default useGetDesafioByElementId;
