import { useMutation } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
// prettier-ignore
import { addCampanhaRegulamentoIdeias, TCampanhaRegulamentoIdeiasForm, updateCampanhaRegulamentoIdeias } from "@/modules/campanha-regulamento-ideias/firestore";
import useUserStore from "@/modules/users/store";
import { TRegulamentoIdeiasForm } from "../../firestore";

type TUpdateCronogramaResponse = Awaited<ReturnType<typeof addCampanhaRegulamentoIdeias>>;
type TUpdateCampanhaResponse = Awaited<ReturnType<typeof updateCampanhaRegulamentoIdeias>>;
/**
 * Finalizar resposta de inovação mutation hook
 * @param regulamento - regulamento de ideias
 * @returns Finalizar resposta de inovação mutation
 */
// prettier-ignore
export function useAddCampanha(regulamento: TRegulamentoIdeiasForm) {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  return useMutation<TCronogramaDesafio | TUpdateCronogramaResponse | TUpdateCampanhaResponse , FirestoreError, TCampanhaRegulamentoIdeiasForm>({
    mutationFn(item) {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!item) {
        throw new Error("Erro nos dados");
      }
      if (!regulamento) {
        throw new Error("Regulamento indefinido");
      }
      return item.id
        ? updateCampanhaRegulamentoIdeias(activeEmpresaId, regulamento?.id || "", item.id, item)
        : addCampanhaRegulamentoIdeias(activeEmpresaId, regulamento?.id || "", item);
    },
  });
}
