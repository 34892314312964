// import Can from "@/components/can";
import { Card, Group, Text, Flex, Space, Badge, SimpleGrid, Button } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconChevronDown, IconChevronUp, IconTrash } from "@tabler/icons-react";
import { QueryObserverResult } from "@tanstack/react-query";
import React, { useCallback } from "react";
import JogadasCategoriaLabel from "@/modules/jogadas-de-inovacao/components/jogadas-categoria-label";
import JogadasTipoLabel from "@/modules/jogadas-de-inovacao/components/jogadasTipoLabel";
import MovimentacoesCard from "./MovimentacoesCard";

interface Tatica {
  id: string | number;
  nome: string;
  descricao: string;
  nomeCategoria: string | undefined;
  nomeTipo: string | undefined;
  tipo?: string | undefined;
  categoria?: string | undefined;
  corVinculos?: string | undefined;
  movimentacao?: [];
}
interface Movimentacao {
  id: string | undefined;
  descricao: string;
  status: string;
  data: Date | null;
}

// prettier-ignore
const HomeTaticasListCard: React.FC<{
  jogada: Tatica;
  item: TJogadaDeInovacao;
  onRemove?: (argument0: Tatica) => void;
  refetch: () => Promise<QueryObserverResult>;
  formatAutorizacoes: TAutorizacao[] | undefined;
}> = ({ jogada, item, onRemove, refetch, formatAutorizacoes }) => {
  const [opened, { toggle }] = useDisclosure(false);

  /**d
   * Função que trata o clique do botão.
   */
  const handleClickItem = useCallback(() => {
    modals.openContextModal({
      size: "xl",
      modal: "add-movimentacao-taticas",
      innerProps: {
        tatica: jogada,
        jogada: item,
        refetch
      }
    });
  }, []);

  return (
    <>
      <Card withBorder radius="lg" style={{ border: jogada && jogada?.corVinculos ? `5px solid ${jogada?.corVinculos}` : "1px solid rgb(226,230,233)" }}>
        <Flex justify={"space-between"}>
          <Group>
            <Badge radius="md" size="xl" variant="transparent" style={{ background: "rgba(244, 245, 246, 1)" }}>
              <Group>
                <Text tt="capitalize" fz="xs" fw={400} color={"rgb(118,119,125)"}>
                  Categoria de Inovação:
                </Text>
                <JogadasCategoriaLabel id={jogada?.categoria || ""} />
              </Group>
            </Badge>
            <Badge radius="md" size="xl" variant="transparent" style={{ background: "rgba(244, 245, 246, 1)" }}>
              <Group>
                <Text tt="capitalize" fz="xs" fw={400} color={"rgb(118,119,125)"}>
                  Tipo de Inovação:
                </Text>
                <JogadasTipoLabel id={jogada?.tipo || ""} />
              </Group>
            </Badge>
          </Group>
          {onRemove && (
            <Button
              color="#525356"
              variant="transparent"
              radius="lg"
              size="md"
              // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
              onClick={() => onRemove(jogada)}
            >
              <Group>
                <IconTrash size={16} />
                <Text size="sm">Excluir</Text>
              </Group>
            </Button>
          )}
        </Flex>
        <Space h="xl" />
        <SimpleGrid cols={4}>
          <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
            <Text fw={300} fz="xs">
              Tática
            </Text>
            <Text fw={500} fz="md">
              {jogada.nome}
            </Text>
          </Flex>
          <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
            <Text fw={300} fz="xs">
              Setor
            </Text>
            <Text fw={500} fz="md">
              {jogada.nome}
            </Text>
          </Flex>
          <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
            {item?.cancelado || item?.concluido || !formatAutorizacoes?.find(item => item.tipoAutorizacao === "19")?.criacao ? <></> : <Button variant="filled" color="rgba(8, 68, 244, 1)" onClick={handleClickItem}>
              Adicionar Movimentação
            </Button>}
          </Flex>
          <Flex mih={50} gap="xs" justify="flex-start" align="center" direction="column" wrap="wrap">
            <Button
              type="button"
              variant="transparent"
              size="compact-sm"
              rightSection={opened ? <IconChevronUp size="1rem" /> : <IconChevronDown size="1rem" />}
              onClick={toggle}
            >
              Ver mais
            </Button>
          </Flex>
        </SimpleGrid>
        <Space h="md" />
        <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
          <Text fw={300} fz="xs">
            Descrição
          </Text>
          <Text fw={500} fz="md">
            {jogada.descricao}
          </Text>
        </Flex>
        <Space h="xl" />
        {opened && (
          jogada && jogada?.movimentacao && jogada?.movimentacao.length > 0 ?
            jogada?.movimentacao.map((item: Movimentacao) => <MovimentacoesCard key={item?.id} movimentacao={item} />)
            : <>Nenhuma movimentação</>
        )}
      </Card>
      <Space h="xl" />
    </>
  );
};

export default HomeTaticasListCard;
