import { Stack } from "@mantine/core";
import ComiteInovacaoItem from "./ComiteInovacaoItem";
import ComitesInovacaoHistoryLoading from "./ComitesInovacaoHistoryLoading";
import useQueryComitesInovacao from "./useQueryComitesInovacao";

const ComitesInovacaoHistory = () => {
  const { data: comites, isLoading } = useQueryComitesInovacao();
  return (
    <Stack gap="xs" mt="md">
      {isLoading && (
        <>
          <ComitesInovacaoHistoryLoading />
          <ComitesInovacaoHistoryLoading />
          <ComitesInovacaoHistoryLoading />
        </>
      )}
      {comites && comites.map((comite) => <ComiteInovacaoItem key={comite.id} comite={comite} />)}
    </Stack>
  );
};

export default ComitesInovacaoHistory;
