import { Select } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { useEffect } from "react";
import { estadosData } from "@/modules/empresas/schema/endereco-schema";
import schema from "@/schema";
import { yup } from "@/schema/schema";
import { extractTextInputPropertiesFromFormSchema } from "@/utils/form";

const estadoIdentifierSchema = schema.object({
  estado: schema.string().required().label("Estado")
});

type EstadoIdentifierFormType = yup.InferType<typeof estadoIdentifierSchema>;

const defaultForm: EstadoIdentifierFormType = { estado: "" };

type Properties = {
  onChange: (estado: string) => void;
};

/**
 *  Cidade Identifier Form
 * @param root0 - Properties
 * @param root0.onChange - On change cidade
 * @returns - Cidade Identifier Form
 */
export function EstadoIdentifierForm({ onChange }: Properties) {
  const form = useForm<EstadoIdentifierFormType>({
    initialValues: defaultForm,
    validate: yupResolver(estadoIdentifierSchema),
    transformValues: (values) => estadoIdentifierSchema.cast(values)
  });

  useEffect(() => {
    const { estado } = form.values;
    if (estado) {
      onChange(estado);
    }
  }, [form.values.estado]);

  const descriptor = estadoIdentifierSchema.describe();

  return (
    <Select
      data={estadosData}
      {...form.getInputProps("estado")}
      {...extractTextInputPropertiesFromFormSchema(descriptor, "estado")}
    />
  );
}
