import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getTiposDeInovacao, QUERY_KEY } from "@/modules/tipos-de-inovacao/firestore";
import useUserStore from "@/modules/users/store";

type TGetTipoDeInovacaoResponse = Awaited<ReturnType<typeof getTiposDeInovacao>>;

/**
 * Get tipos de inovação query hook
 * @returns tipos de inovação query hook
 */
function useGetTipoDeInovacao() {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  return useQuery<TGetTipoDeInovacaoResponse, FirestoreError, TTipoDeInovacao[]>({
    queryKey: ["empresas", { id: activeEmpresaId }, QUERY_KEY],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      return getTiposDeInovacao(activeEmpresaId);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }
      return dataResponse.docs.map((document_) => document_.data());
    }
  });
}

export default useGetTipoDeInovacao;
