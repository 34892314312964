import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { QUERY_KEY } from "@/modules/perguntas/components/perguntas-table/PerguntasTable";
import { getPerguntasArray } from "@/modules/perguntas/firestore";

type TGetGroupResponse = Awaited<ReturnType<typeof getPerguntasArray>>;

/**
 * Get ideia from url params
 * @param id - array ids
 * @param id.ids - array ids
 * @param id.activeEmpresaId - id empresa
 * @returns - Ideia or null if not found
 */
// prettier-ignore
function useGetPerguntaConvite({ ids, activeEmpresaId }: { ids: Array<string> | undefined ,activeEmpresaId:string | undefined}) {
  // const activeEmpresaId = convite?.idEmpresa;

  return useQuery<TGetGroupResponse, FirestoreError, TPergunta[]>({
    queryKey: [QUERY_KEY, ids, activeEmpresaId],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!ids) {
        throw new Error("Nenhum questionario");
      }

      return getPerguntasArray(activeEmpresaId, ids);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((document_) => document_.data());
    },
    enabled: !!ids && !!activeEmpresaId
  });
}

export default useGetPerguntaConvite;
