import { Text, FocusTrap } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import TiposDeRespostasForm from "../../components/tipos-de-respostas-form";
import { TTiposDeRespostasForm } from "../../firestore";

const TiposDeRespostasFormModal = ({ context, innerProps, id }: ContextModalProps<{ item: TTiposDeRespostasForm }>) => {
  const { item: tiposDeRespostas } = innerProps;
  /**
   * Handle add ignore area success
   */
  function onSuccess() {
    context.closeModal(id);
  }

  return (
    <FocusTrap active={true}>
      <Text fw="bold" mb="md">
        Cadastro de tipos de respostas
      </Text>
      <TiposDeRespostasForm tiposDeRespostas={tiposDeRespostas} onSuccess={onSuccess} />
    </FocusTrap>
  );
};

export default TiposDeRespostasFormModal;
