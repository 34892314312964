import { Card, Grid, Text } from "@mantine/core";

const HeaderTable = () => {
  return (
    <Card padding="lg" radius="md">
      <Grid>
        <Grid.Col span={3}>
          <Text size="sm" fw={500}>
            Rota
          </Text>
        </Grid.Col>
        <Grid.Col span={2}>
          <Text size="sm" fw={500}>
            Leitura
          </Text>
        </Grid.Col>
        <Grid.Col span={2}>
          <Text size="sm" fw={500}>
            Criação
          </Text>
        </Grid.Col>
        <Grid.Col span={2}>
          <Text size="sm" fw={500}>
            Editar
          </Text>
        </Grid.Col>
        <Grid.Col span={2}>
          <Text size="sm" fw={500}>
            Remover
          </Text>
        </Grid.Col>
      </Grid>
    </Card>
  );
};

export default HeaderTable;
