import { Button, Card, Flex, Group, Select, Text, Textarea } from "@mantine/core";
import { serverTimestamp, Timestamp } from "firebase/firestore";
import { useCallback } from "react";
import { DESAFIO_STATUS } from "@/modules/desafio-cronograma/schema";
import { useUpdatedDesafio } from "./useDesafioForm";
import useDesafioMovimentacaoForm from "../hooks/useIdeiaMovimentacaoForm";
import { gerarDadosSelect } from "../utils/gerar-dados-select";

const statusSelectData = gerarDadosSelect(DESAFIO_STATUS);
// prettier-ignore
const MovimentacaoDesafioCard = ({ desafio }: { desafio: TDesafio }) => {
  const { onSubmit, getInputProps } = useDesafioMovimentacaoForm(desafio);
  const statusWithoutSelected = statusSelectData.filter((data) => data.value !== desafio.status && data.value !== "DRAFT");
  const { mutate } = useUpdatedDesafio()

  const onChangeStatus = useCallback(() => {
    mutate({
      ...desafio,
      categoriaReference: desafio?.categoriaReference as string || "",
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      status: getInputProps("status")?.value || "DRAFT" as string,
      regulamentoReference: desafio?.regulamentoReference as string || "",
      propositorReference: desafio?.propositorReference as string || "",
      anexosFilesToUpload: [],
      participantesReferences: [],
      deletedAt: null,
      publishedAt: Timestamp.fromDate(desafio && desafio.publishedAt || new Date()) || serverTimestamp(),
    })
    onSubmit();
  }, [onSubmit, mutate]);

  return (
    // <form onSubmit={onChangeStatus}>
    <Card withBorder my="md" mb="xl">
      <Flex direction="column" gap="sm">
        <Group justify="space-between">
          <Text fw="bold" size="lg">
            Movimentação do desafio
          </Text>
        </Group>
        <Select
          label="Selecione o status"
          placeholder="Selecione o status"
          data={statusWithoutSelected}
          {...getInputProps("status")}
        />
        <Textarea
          label="Adicione uma observação sobre esse status"
          placeholder="Observações"
          minRows={3}
          autosize
          {...getInputProps("observacoes")}
        />
        <Flex align="center" justify="flex-end">
          <Button type="submit" onClick={onChangeStatus}>
            Finalizar análise
          </Button>
        </Flex>
      </Flex>
    </Card>
    // </form>
  );
};

export default MovimentacaoDesafioCard;
