// prettier-ignore
import {
  Card,
  TextInput,
  Textarea,
  Space,
  Group,
  Button,
  Anchor,
  Checkbox,
  Select,
  MultiSelectProps,
  Flex,
  Text
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useCallback, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import useUserStore from "@/modules/users/store";
import { extractTextInputPropertiesFromFormSchema } from "@/utils/form";
import useAddJogadaDeInovacaoForm from "./useAddJogadaDeInovacaoForm";
import { TJogadaInovacaoForm } from "../../firestore";
import jogadaDeInovacaoSchema from "../../schema/jogadas-de-inovacao-schema";
import AddTaticaDeJogadas from "../add-tatitcas-de-jogadas";
import TaticasListCard from "../taticas-list-card";
import TipoDeJogadaSelector from "../tipo-de-jogada-selector";

interface Tatica {
  id: string | number;
  nome: string;
  descricao: string;
  nomeCategoria: string | undefined;
  nomeTipo: string | undefined;
}
// prettier-ignore
const AddJogadaDeInovacao = ({
  data
}: {
  data: (TJogadaInovacaoForm & Partial<Pick<TJogadaDeInovacao, "id" | "refPath">>) | null | undefined;
}) => {
  const navigate = useNavigate();
  const fieldsDescriptor = jogadaDeInovacaoSchema.describe();
  const [taticas, setTaticas] = useState<Tatica[]>([]);
  const [addTatica, setAddTatica] = useState<boolean>(false);
  const user = useUserStore((state) => state.colaborador);

  useEffect(() => {
    if (data?.taticas && Array.isArray(data.taticas as Tatica[]) && (data.taticas as Tatica[]).length > 0) {
      setTaticas(data.taticas as Tatica[]);
    }
  }, [data]);

  const { getInputProps, onSubmit } = useAddJogadaDeInovacaoForm(
    data || ({} as TJogadaInovacaoForm),
    taticas,
    // tiposDeJogadas as TTipoDeJogada[],
    {
      onSuccess() {
        showNotification({
          message: `${getInputProps("nome")?.value} ${data?.id ? "atualizado" : "cadastrado"} com sucesso!`,
          color: "green",
          autoClose: 2500
        });
        navigate("/jogadas-de-inovacao");
      }
    }
  );

  /**
   * handle set add tatica
   */
  const handleClick = useCallback(() => {
    setAddTatica((add) => !add);
  }, [setAddTatica]);

  const onRemove = (item: Tatica) => {
    setTaticas((taticas) => taticas.filter((tatica: Tatica) => tatica.id !== item.id));
  };

  // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
  const renderMultiSelectOption: MultiSelectProps["renderOption"] = ({ option }) => {
    const value = options.find((item) => item.value === option.value);
    return (
      <Flex direction={"column"}>
        <Text size="sm">{option?.label}</Text>
        <Text size="xs" color="gray">
          {value?.description}
        </Text>
      </Flex>
    );
  };

  return (
    <form onSubmit={onSubmit}>
      <Card>
        {user?.role === "ADMIN" && !data?.id ? <Checkbox
          checked={getInputProps("sugerida").value as boolean}
          {...getInputProps("sugerida")}
          {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "sugerida")}
        /> : <></>}
        <Card.Section inheritPadding py="xs">
          <TextInput
            mt="md"
            {...getInputProps("nome")}
            {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "nome")}
            label={"Informe o título para a jogada de inovação"}
          />
          <Space h="lg" />
          <Textarea
            autosize
            minRows={5}
            {...getInputProps("descricao")}
            {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "descricao")}
            label={"Descrição"}
          />
          <TextInput
            mt="md"
            {...getInputProps("segmento")}
            {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "segmento")}
          />
          <Select
            renderOption={renderMultiSelectOption}
            data={options}
            mt="md"
            {...getInputProps("ambicao")}
            {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "ambicao")}
          />
          <Space h="xl" />
          <TipoDeJogadaSelector
            placeholder="Tipo da jogada"
            {...getInputProps("tipoDeJogada")}
            {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "tipoDeJogada")}
            label={"Selecione o tipo de jogada"}
          />
          <Space h="xl" />
          {taticas.length > 0 ? (
            <Card style={{ background: "rgb(245,246,247)" }}>
              {taticas.map((item: Tatica) => (
                <TaticasListCard key={item.id} jogada={item} onRemove={onRemove} />
              ))}
              <Space h="xl" />

              <Group>
                <Button variant="filled" color="rgba(8, 68, 244, 1)" onClick={handleClick}>
                  Adicionar táticas
                </Button>
              </Group>
            </Card>
          ) : (
            <></>
          )}
          <Space h="xl" />
          {addTatica || taticas.length === 0 ? (
            <AddTaticaDeJogadas setAddTatica={setAddTatica} setTaticas={setTaticas} />
          ) : (
            <></>
          )}
          <Space h="xl" />
          <Group justify={"flex-end"}>
            <Group justify="flex-end">
              <Anchor component={NavLink} color="rgba(82, 83, 86, 1)" to={`/jogadas-de-inovacao`} underline="never">
                <Button variant="transparent" color="rgba(82, 83, 86, 1)">
                  Cancelar
                </Button>
              </Anchor>
              <Button variant="filled" color="rgba(8, 68, 244, 1)" type="submit">
                Salvar
              </Button>
            </Group>
          </Group>
        </Card.Section>
      </Card>
    </form>
  );
};

export default AddJogadaDeInovacao;

const options = [
  // eslint-disable-next-line sonarjs/no-duplicate-string
  {
    value: "Essencial",
    label: "Inovação Essencial",
    description:
      "Dentro de qualquer categoria conhecida sempre existem alternativas para oferecer nova qualidade, utilidade ou prazer aos clientes; isso normalmente exige uma mudança apenas em um ou dois tipos de inovação."
  },
  {
    value: "Adjacente",
    label: "Inovação Adjacente",
    description:
      "Esse nível de ambição costuma atrair novos clientes a um mercado e muda o que todos os participantes podem esperar dele. A inovação adjacente é mais ousada do que a inovação essencial, por isso, pode exigir três ou quatro tipos de inovação."
  },
  {
    value: "Transformacional",
    label: "Inovação Transformacional",
    description:
      "Esse nível de ambição pode alterar radicalmente toda a estrutura de um setor. Serão necessários cinco ou mais tipos de inovação, articulados com cuidado, para produzir negócios totalmente novos (em contraposição a apenas novos produtos/serviços)."
  }
];
