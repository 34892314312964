import { Button, FocusTrap, Group, Text } from "@mantine/core";
import { TransformedValues, useForm, yupResolver } from "@mantine/form";
import { ContextModalProps } from "@mantine/modals";
import { MutationOptions, useMutation, useQueryClient } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import ColaboradorMultiSelector from "@/modules/colaboradores/components/colaborador-multi-selector/ColaboradorMultiSelector";
import useUserStore from "@/modules/users/store";
import { extractTextInputPropertiesFromFormSchema } from "@/utils/form";
import { QUERY_KEY as COMITE_INOVACAO_COLLECTION, TComiteInovacaoForm, startComiteDeInovacao } from "../../firestore";
import comiteInovacaoSchema from "../../schema/comite-inovacao-schema";

type TStartComiteInovacaoResponse = Awaited<ReturnType<typeof startComiteDeInovacao>>;

type TMutationOptions = Omit<
  MutationOptions<TStartComiteInovacaoResponse, FirestoreError, TComiteInovacaoForm>,
  "mutationFn"
>;

const useIniciarComiteInovacaoForm = (mutationOptions: TMutationOptions = {}) => {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);
  const form = useForm<TComiteInovacaoForm>({
    validate: yupResolver(comiteInovacaoSchema),
    initialValues: comiteInovacaoSchema.getDefault() as TComiteInovacaoForm,
    transformValues: (values) => comiteInovacaoSchema.cast(values)
  });

  const { mutate, isPending } = useMutation<TStartComiteInovacaoResponse, FirestoreError, TComiteInovacaoForm>({
    mutationFn(values) {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      return startComiteDeInovacao(activeEmpresaId, values);
    },
    ...mutationOptions
  });

  type TTransformedValues = TransformedValues<typeof form>;
  /**
   * Handle form submit event
   * @param values - Form values
   */
  function onSubmit(values: TTransformedValues) {
    mutate(values);
  }

  return { ...form, isLoading: isPending, onSubmit: form.onSubmit(onSubmit) };
};

const IniciarComiteInovacaoModal = ({ context, id }: ContextModalProps) => {
  const queryClient = useQueryClient();
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);
  const { getInputProps, onSubmit, isLoading } = useIniciarComiteInovacaoForm({
    onSuccess() {
      context.closeModal(id);
      return queryClient.invalidateQueries({
        queryKey: ["empresas", { id: activeEmpresaId }, COMITE_INOVACAO_COLLECTION]
      });
    }
  });
  const fieldsDescriptor = comiteInovacaoSchema.describe();

  return (
    <FocusTrap active={true}>
      <form onSubmit={onSubmit}>
        <Text fw="bold" mb="md">
          Adicionar participantes
        </Text>
        <ColaboradorMultiSelector
          style={{ flex: 1 }}
          searchable
          multiple
          nothingFoundMessage="Nenhum participante encontrado..."
          disabled={isLoading}
          {...getInputProps("participantesReferences")}
          {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "participantesReferences")}
        />
        <Group justify="flex-end" mt="xl">
          <Button type="submit" loading={isLoading}>
            Salvar
          </Button>
        </Group>
      </form>
    </FocusTrap>
  );
};

export default IniciarComiteInovacaoModal;
