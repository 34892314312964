import { Box, LoadingOverlay, Text } from "@mantine/core";
import { useEffect } from "react";
import useQueryTiposDeRespostas from "../perguntas-form/useQueryTipoDePergunta";

const ColumnText = ({ tipo }: { tipo: string }) => {
  const { data: tiposDeRespostas, refetch, isError, isLoadingError } = useQueryTiposDeRespostas();
  const tiposDeRespostasArray = tiposDeRespostas || [];

  useEffect(() => {
    const fetchData = async () => {
      if (isError) {
        await refetch();
      }
    };

    void fetchData();
  }, [isError]);

  if (isLoadingError) {
    return (
      <Box pos="relative">
        <LoadingOverlay visible={isLoadingError} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
      </Box>
    );
  }

  return <Text size={"14px"}>{tiposDeRespostasArray?.find((item) => item?.id === tipo)?.nome}</Text>;
};

export default ColumnText;
