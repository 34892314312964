import { MutationOptions, useMutation } from "@tanstack/react-query";

import { FirestoreError } from "firebase/firestore";
import { captureException } from "@/services/log";

import { deleteMarco } from "../firestore";

type TDeleteMarcoResponse = Awaited<ReturnType<typeof deleteMarco>>;
type TMutationOptions = Omit<MutationOptions<TDeleteMarcoResponse, FirestoreError>, "mutationFn" | "onError">;

/**
 * Hook to delete marco document
 * @param marcoPath - Marco path
 * @param options - Mutation options to pass to react-query
 * @returns - Form props
 */
function useDeleteMarco(marcoPath: string, options: TMutationOptions = {}) {
  return useMutation<TDeleteMarcoResponse, FirestoreError>({
    mutationFn: () => {
      return deleteMarco(marcoPath);
    },
    onError(error) {
      captureException(error, true);
    },
    ...options
  });
}

export default useDeleteMarco;
