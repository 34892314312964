import { useForm, yupResolver, TransformedValues } from "@mantine/form";
import { UseMutationOptions, useMutation, useQueryClient } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import {
  addComentario,
  getIdeiaComentarioDocumentReferenceFromPath,
  TIdeiaComentarioForm
} from "@/modules/ideia-comentarios/firestore";
import ideiaComentarioSchema from "@/modules/ideia-comentarios/schema/ideia-comentario-schema";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { QUERY_KEY } from "../ideia-comentarios/useGetComentarios";

type TAddComentarioResponse = Awaited<ReturnType<typeof addComentario>>;

type UseIdeiaComentarioOptions = {
  ideia: TIdeia;
  respostaAoComentario?: TIdeiaComentario | null;
} & Omit<UseMutationOptions<TAddComentarioResponse, FirestoreError, TIdeiaComentarioForm>, "mutationFn">;

/**
 * Hook to handle the ideia comentario form
 * @param options - Parameters
 * @param options.ideia - Ideia to add the comentario
 * @param [options.respostaAoComentario] - Resposta ao comentario
 * @param [options.onSuccess] - On success callback [optional
 * @returns - Form props
 */
function useIdeiaComentarioForm({ ideia, respostaAoComentario, onSuccess }: UseIdeiaComentarioOptions) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const queryClient = useQueryClient();

  const form = useForm<TIdeiaComentarioForm>({
    initialValues: ideiaComentarioSchema.getDefault(),
    transformValues: (values) =>
      ideiaComentarioSchema.cast(values, {
        stripUnknown: true,
        context: {
          ideiaId: ideia.id
        }
      }),
    validate: yupResolver(ideiaComentarioSchema)
  });

  const { mutate, reset, isPending } = useMutation<TAddComentarioResponse, FirestoreError, TIdeiaComentarioForm>({
    mutationFn: (values) => {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return addComentario(activeEmpresaId, ideia.id, values);
    },
    async onSuccess(data, variables, context) {
      reset();
      form.reset();
      await queryClient.invalidateQueries({ queryKey: [activeEmpresaId, "ideia", ideia.id, QUERY_KEY] });
      if (onSuccess) {
        onSuccess(data, variables, context);
      }
    },
    onError(error) {
      captureException(error, true);
    }
  });

  type TTransformedValues = TransformedValues<typeof form>;
  /**
   * Handle form submit
   * @param values - Form values
   */
  function onSubmit(values: TTransformedValues) {
    const respostaAoComentarioReference = respostaAoComentario?.refPath
      ? getIdeiaComentarioDocumentReferenceFromPath(respostaAoComentario?.refPath)
      : null;
    mutate({ ...values, respostaAoComentarioReference });
  }

  return {
    ...form,
    isPending,
    onSubmit: form.onSubmit(onSubmit)
  };
}

export default useIdeiaComentarioForm;
