import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { User } from "firebase/auth";
import { FirestoreError } from "firebase/firestore";
import { useEffect, useState } from "react";
import { ability } from "@/components/can/Can";
import { getColaborador } from "@/modules/colaboradores/firestore";
import { removeEmpresaFromMyClaims } from "@/modules/users/functions";
import useUserStore, { setActiveEmpresaId, setActiveLicenciadaId, setColaborador } from "@/modules/users/store";
import { signOut, watchCurrentUser, watchIdToken } from "@/services/auth";
import { captureException } from "@/services/log";

type TGetColaboradorResponse = Awaited<ReturnType<typeof getColaborador>>;
type TGetColaboradorMutationOptions = UseMutationOptions<
  TGetColaboradorResponse,
  FirestoreError,
  { empresaId: TEmpresa["id"]; colaboradorId: TColaborador["id"] }
>;

/**
 * Get colaborador mutation
 * @param options - The mutation options
 * @returns - The mutation
 */
function useGetColaboradorMutation(options: Omit<TGetColaboradorMutationOptions, "mutationFn"> = {}) {
  return useMutation<
    TGetColaboradorResponse,
    FirestoreError,
    { empresaId: TEmpresa["id"]; colaboradorId: TColaborador["id"] }
  >({
    mutationFn: ({ empresaId, colaboradorId }) => getColaborador(empresaId, colaboradorId),
    ...options
  });
}

/**
 * Get authenticated firebase user or false if not ready
 * @returns - The user or false if not ready
 */
function useWatchAuthenticatedUser(): [
  User | false | null,
  TUserClaims | undefined,
  boolean,
  TColaborador | undefined
] {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [user, setUser] = useState<null | User | false>(false);
  const [claims, setClaims] = useState<TUserClaims | undefined>();
  const colaborador = useUserStore((state) => state.colaborador);

  /**
   * Handle promise error and show notification
   * @param error - The error
   */
  function handlePromiseError(error: Error) {
    captureException(error, true);
    ability.update([]);
    setIsLoading(false);
  }

  const {
    mutate: getColaboradorMutate,
    failureCount: getColaboradorFailedCount,
    isPending: isLoadingGetColaborador
  } = useGetColaboradorMutation({
    onSuccess(response) {
      const colaboradorData = response.data();
      if (response.exists() && colaboradorData) {
        setColaborador(colaboradorData);
      } else {
        setColaborador(undefined);
      }

      setIsLoading(false);
    },
    async onError(error) {
      setColaborador(undefined);
      const isPermissionDenied = error.code === "permission-denied";
      if (isPermissionDenied) {
        await removeEmpresaFromMyClaims();
        await signOut();
      } else {
        handlePromiseError(error);
      }
    }
  });

  useEffect(() => {
    const unsubscribe = watchCurrentUser(setUser, handlePromiseError);
    return () => {
      unsubscribe();
    };
  }, []);
  useEffect(() => {
    /**
     * Handle id token change
     * @param user - The user
     */
    async function handleIdTokenChanged(user: User | null) {
      if (user) {
        await user.getIdToken(true);
        const idTokenResult = await user.getIdTokenResult();
        const claims = idTokenResult.claims as unknown as TUserClaims;
        setClaims(claims);
        setActiveLicenciadaId(claims.licenciadaId);
        setActiveEmpresaId(claims.empresaId);
      } else {
        setClaims(undefined);
        setActiveLicenciadaId(undefined);
        setActiveEmpresaId(undefined);
        setColaborador(undefined);
        setIsLoading(false);
      }
    }

    const unsubscribe = watchIdToken((user) => {
      handleIdTokenChanged(user).catch(handlePromiseError);
    }, handlePromiseError);
    return () => {
      unsubscribe();
      setColaborador(undefined);
    };
  }, []);

  useEffect(() => {
    const hasNoFailed = getColaboradorFailedCount === 0;
    if (claims?.empresaId && user && hasNoFailed && !isLoadingGetColaborador && !colaborador) {
      getColaboradorMutate({ empresaId: claims.empresaId, colaboradorId: user.uid });
    } else if (claims) {
      setIsLoading(false);
    }
  }, [claims, colaborador, getColaboradorFailedCount, getColaboradorMutate, isLoadingGetColaborador, user]);

  return [user, claims, isLoading || isLoadingGetColaborador, colaborador];
}

export default useWatchAuthenticatedUser;
