import { Text, Group, Flex, rem, Button } from "@mantine/core";
import {
  IMAGE_MIME_TYPE,
  PDF_MIME_TYPE,
  MS_WORD_MIME_TYPE,
  MS_EXCEL_MIME_TYPE,
  MS_POWERPOINT_MIME_TYPE,
  FileRejection,
  DropzoneProps,
  Dropzone
} from "@mantine/dropzone";
import { useHover } from "@mantine/hooks";
import { IconCloudUpload, IconX } from "@tabler/icons-react";
import { MouseEvent, useRef } from "react";
import classNames from "./FileSelectorDropZone.module.css";

const ICON_SIZE = 52;
const ALL_VALID_MIME_TYPES = [
  ...IMAGE_MIME_TYPE,
  ...PDF_MIME_TYPE,
  ...MS_WORD_MIME_TYPE,
  ...MS_EXCEL_MIME_TYPE,
  ...MS_POWERPOINT_MIME_TYPE
];
/**
 * Handle log
 * @param event - Event name
 * @returns - Function
 */
function handleLog(event: string) {
  // eslint-disable-next-line no-console
  return (files: (File | FileRejection)[]) => console.log(`${event} files`, files);
}

const FileSelectorDropZone = ({ loading, disabled, accept = ALL_VALID_MIME_TYPES, ...properties }: DropzoneProps) => {
  const { hovered, ref } = useHover();
  const openReference = useRef<() => void>(null);
  /**
   * Handle button click
   * @param event - Event
   */
  function handleButtonClick(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    openReference.current?.();
  }
  return (
    <Dropzone
      onReject={handleLog("rejected files")}
      openRef={openReference}
      pos="relative"
      ref={ref}
      accept={accept}
      bg={hovered ? "gray.1" : "transparent"}
      classNames={{
        root: classNames.root,
        inner: classNames.inner
      }}
      loading={loading}
      disabled={disabled}
      {...properties}
    >
      <Group justify="center" gap="xl" mih={140} pb="md" style={{ pointerEvents: "none", position: "relative" }}>
        <Dropzone.Accept>
          <Flex gap={0} justify="center" align="center" direction="column" wrap="wrap">
            <IconCloudUpload style={{ width: rem(ICON_SIZE), height: rem(ICON_SIZE) }} stroke={1.5} />
            <Text size="xl" fw={500} display="block">
              Solte os arquivos
            </Text>
            <Text size="sm" c="dimmed" display="block">
              Solte os arquivos aqui para fazer upload
            </Text>
          </Flex>
        </Dropzone.Accept>
        <Dropzone.Reject>
          <Flex gap={0} justify="center" align="center" direction="column" wrap="wrap">
            <IconX
              style={{ width: rem(ICON_SIZE), height: rem(ICON_SIZE), color: "var(--mantine-color-red-6)" }}
              stroke={1.5}
            />
            <Text size="xl" fw={500} display="block">
              Formato não aceito
            </Text>
            <Text size="sm" c="dimmed" display="block">
              Não é possível enviar arquivos com este formato
            </Text>
          </Flex>
        </Dropzone.Reject>
        <Dropzone.Idle>
          <Flex gap={0} justify="center" align="center" direction="column" wrap="wrap">
            <IconCloudUpload
              style={{ width: rem(ICON_SIZE), height: rem(ICON_SIZE), color: disabled ? "gray" : "black" }}
              stroke={1.5}
            />
            <Text size="xl" fw={500} display="block" c={disabled ? "dimmed" : "dark"}>
              Enviar arquivos
            </Text>
            <Text size="sm" c="dimmed" display="block">
              Arraste e solte arquivos aqui para fazer upload
            </Text>
          </Flex>
          <Button
            type="button"
            variant="filled"
            color="dark"
            pos="absolute"
            left="calc(50% - 85px)"
            bottom="-34px"
            onClick={handleButtonClick}
            disabled={loading || disabled}
          >
            Selecionar arquivos
          </Button>
        </Dropzone.Idle>
      </Group>
    </Dropzone>
  );
};

export default FileSelectorDropZone;
