import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getIdeiaDocumentReferenceFromPath, getIdeia } from "@/modules/ideias/firestore";
import useUserStore from "@/modules/users/store";

type GetIdeiaResponse = Awaited<ReturnType<typeof getIdeia>>;

/**
 * Hook que retorna todas as ideias de um comitê
 * @param comite - ComiteInovacao que contém as referências para as ideias
 * @returns - QueryResult com todas as ideias do comitê
 */
function useGetAllIdeiasFromComite(comite: TComiteInovacao) {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);
  return useQuery<GetIdeiaResponse[], FirestoreError, TIdeia[]>({
    queryKey: ["empresas", { id: activeEmpresaId }, "comitesInovacao", "ideias"],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      // eslint-disable-next-line compat/compat
      return Promise.all(
        comite.ideiasReferences.map((reference) => {
          const ideiaDocumentReference = getIdeiaDocumentReferenceFromPath(reference);
          return getIdeia(activeEmpresaId, ideiaDocumentReference.id);
        })
      );
    },
    select(data) {
      const allIdeias: TIdeia[] = [];

      for (const ideia of data) {
        if (ideia.exists()) {
          allIdeias.push(ideia.data());
        }
      }
      return allIdeias;
    }
  });
}

export default useGetAllIdeiasFromComite;
