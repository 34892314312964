import { useMutation } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { TEmbaixadorForm, addEmbaixador } from "../../firestore";

type TAddEmbaixadorResponse = Awaited<ReturnType<typeof addEmbaixador>>;
/**
 * Finalizar resposta de inovação mutation hook
 * @returns Finalizar resposta de inovação mutation
 */
// prettier-ignore
// eslint-disable-next-line import/no-unused-modules
export function useAddEmbaixador() {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  return useMutation<TAddEmbaixadorResponse, FirestoreError, TEmbaixadorForm>({
    mutationFn(item) {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!item) {
        throw new Error("Empresa não selecionada");
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      return addEmbaixador(activeEmpresaId, item);
    },
  });
}
