import { Text, FocusTrap, Group, Button, Space, Textarea, Card, Loader } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
// import { showNotification } from "@mantine/notifications";
import { showNotification } from "@mantine/notifications";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import useGetConvites from "@/components/app-container/useGetConvites";
import useUserStore, { setReloadConvites } from "@/modules/users/store";
import { captureException } from "@/services/log";
import { inviteColaboradorDiagnostico } from "../../functions";

const isDevelopment = process.env.NODE_ENV === "development";

const ConvidarColaboradorModal = ({
  innerProps,
  context,
  id
}: ContextModalProps<{
  idQuestionario: string;
  idEmpresa: string;
  idProjeto: string;
  licenciadaId: string | undefined;
}>) => {
  const { data: convite } = useGetConvites();
  const { idQuestionario, idEmpresa, idProjeto, licenciadaId = "teste" } = innerProps;

  const empresaRespondenteId = useUserStore((state) => state.activeEmpresaId);

  const domain = isDevelopment ? `http://localhost:5173` : `https://innovei.com.br`;
  const path = `/responderQuestionario/${licenciadaId}/${idEmpresa}/${idQuestionario}/${empresaRespondenteId}`;
  const link = domain + path;

  const [type, setType] = useState(true);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const reloadConvites = useUserStore((state) => state.reloadConvites);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  }, []);

  /**
   * Handle add ignore area success
   */
  function onSuccess() {
    context.closeModal(id);
    setReloadConvites(reloadConvites);
  }
  if (loading) {
    return (
      <Card style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Loader color="blue" />
      </Card>
    );
  }

  return type ? (
    <FocusTrap active={true}>
      <Text fw={700} fz={"lg"}>
        Para convidar os colaboradores à preencher o questionário, use as opções abaixo:
      </Text>
      <Space h={"md"} />
      <Group align={"center"} justify={"center"}>
        <Button onClick={copyClipboard(link)}>Copiar link do questionário</Button>
        {/* <Button color={"rgba(105, 198, 105, 1)"}>Enviar link por Whatsapp</Button> */}
        <Button onClick={changeModal(setType)} color={"rgba(255, 199, 39, 1)"}>
          Enviar link por email
        </Button>
      </Group>
    </FocusTrap>
  ) : (
    <FocusTrap active={true}>
      <Text fw={700} fz={"lg"}>
        Enviar convite para preenchimento do questionário por e-mail:
      </Text>
      <Textarea
        autosize
        minRows={5}
        onChange={handleChange}
        value={text}
        label="Informe os e-mails dos destinatários, podendo informar mais de um e-mail, separando por vírgula os emails"
      />
      <Space h={"lg"} />
      <Group align={"center"} justify={"center"}>
        <Button
          onClick={inviteColaboradores(
            onSuccess,
            text,
            idEmpresa || (convite && convite[0]?.idEmpresaColaboradorConvidado) || "",
            idQuestionario,
            idProjeto || (convite && convite[0]?.idProjeto) || "",
            empresaRespondenteId,
            licenciadaId,
            setLoading
          )}
        >
          Enviar
        </Button>
      </Group>
    </FocusTrap>
  );
};

export default ConvidarColaboradorModal;

/**
 *
 * @param email - The email to validate
 * @returns - The return true or false
 */
function validarEmail(email: string) {
  // Expressão regular para validar o formato do e-mail
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

/**
 * Handles the click event on copy link to clipboard.
 * @param onSuccess - Function case success invite
 * @param text - The text with emails.
 * @param idEmpresa - The id questions.
 * @param idQuestionario - The id company.
 * @param idProjeto - The id project.
 * @param empresaId - The another id company.
 * @param licenciadaId - The id licenciada.
 * @param setLoading - The another id company.
 * @returns - The return the click.
 */
// prettier-ignore
const inviteColaboradores = (
  onSuccess: () => void,
  text: string,
  idEmpresa: string = "",
  idQuestionario: string = "",
  idProjeto: string = "",
  empresaId: string = "",
  licenciadaId: string = "",
  setLoading: Dispatch<SetStateAction<boolean>>
) => {
  return () => {
    const emails = text.split(",");
    const MAX_EMAILS = 10;
    if (emails.length > MAX_EMAILS) {
      showNotification({
        message: `Não é possível enviar mais de 10 convites por vez.`,
        color: "red",
        autoClose: 2500 // 2.5 seconds
      })
      return null;
    }
    for (const email of emails) {
      if (!validarEmail(email.trim())) {
        showNotification({
          message: `Um dos emails informados é inválido: ${email.trim()}`,
          color: "red",
          autoClose: 2500 // 2.5 seconds
        })
        return null;
      }
    }
    if (emails && emails.length > 0) {
      setLoading(true);
      inviteColaboradorDiagnostico({ emails, idEmpresa, idQuestionario, idProjeto, empresaId, licenciadaId })
        .then(() => onSuccess())
        .catch((error: Error) => {
          setLoading(false);
          captureException(error, true);
        });
    }
    return undefined;
  };
};

/**
 * Handles the click event on copy link to clipboard.
 * @param link - The link to copy.
 * @returns - The event that triggered the click.
 */
function copyClipboard(link: string) {
  return () => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        showNotification({
          message: `Copiado com sucesso!`,
          color: "green",
          autoClose: 2500
        });
        return true;
      })
      .catch(() => {
        showNotification({
          message: `Erro ao copiar!`,
          color: "green",
          autoClose: 2500
        });
        return false;
      });
    return undefined;
  };
}

/**
 * Handles the click event on copy link to clipboard.
 * @param setType - Hook set type to modal.
 * @returns - The event that triggered the click.
 */
function changeModal(setType: Dispatch<SetStateAction<boolean>>) {
  return () => setType((type) => !type);
}
