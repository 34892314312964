import { subject } from "@casl/ability";
import { Group } from "@mantine/core";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import ContextMenuRow from "./ContextMenuRow";

const columnHelper = createColumnHelper<TQuestionario>();

const columns: ColumnDef<TQuestionario>[] = [
  columnHelper.accessor("nome", {
    header: "Nome",
    enableSorting: true,
    enableColumnFilter: true
  }),
  columnHelper.accessor("descricao", {
    header: "Descrição",
    enableSorting: true,
    enableColumnFilter: true
  }),
  // columnHelper.accessor("padrao", {
  //   header: "Questionário Padrão",
  //   enableSorting: true,
  //   enableColumnFilter: true,
  //   cell: (properties) => <Text>{properties.getValue() ? "Sim" : "Não"}</Text>
  // }),
  // columnHelper.accessor("publico", {
  //   header: "Questionário Público",
  //   enableSorting: true,
  //   enableColumnFilter: true,
  //   cell: (properties) => <Text>{properties.getValue() ? "Sim" : "Não"}</Text>
  // }),
  columnHelper.display({
    id: "actions",
    cell: (properties) => {
      const questionario = subject("TQuestionario", properties.row.original);
      return (
        <Group justify="center" gap={2}>
          <ContextMenuRow questionario={questionario} />
        </Group>
      );
    }
  })
];

export default columns;
