import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import app from "./app";

const functions = getFunctions(app);
if (import.meta.env.DEV) {
  const __DEFAULT_FUNCTIONS_PORT__ = 5001;
  const __ENV_FUNCTIONS_PORT__ = Number(import.meta.env.VITE_FUNCTIONS_PORT);
  const FUNCTIONS_PORT = Number.isInteger(__ENV_FUNCTIONS_PORT__) ? __ENV_FUNCTIONS_PORT__ : __DEFAULT_FUNCTIONS_PORT__;

  const __ENV_FUNCTIONS_HOST__ = import.meta.env.VITE_FUNCTIONS_HOST;
  const FUNCTIONS_HOST = __ENV_FUNCTIONS_HOST__ || "localhost";

  // eslint-disable-next-line no-console
  console.log("Connecting to Functions emulator at %s:%d", FUNCTIONS_HOST, FUNCTIONS_PORT);

  connectFunctionsEmulator(functions, FUNCTIONS_HOST, FUNCTIONS_PORT);
}

export default functions;
