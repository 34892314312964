/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import { Text, Group, Badge, ThemeIcon, BadgeProps, Button } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconCoin } from "@tabler/icons-react";
import useGetTotalMoedasPerIdeia from "@/modules/ideias-activities-per-colaborador/hooks/useGetTotalMoedasPerIdeia";
import useUserStore from "@/modules/users/store";

const TotalMoedasBadge = ({ ideia, ...badgeProperties }: BadgeProps & { ideia: TIdeia }) => {
  const { colaborador } = useUserStore();
  const { data: totalMoedasNaIdeia } = useGetTotalMoedasPerIdeia(ideia);

  const isAuthor = colaborador?.refPath === ideia?.autorReference;

  const handleDetailPatrocinios = () => {
    if (!isAuthor) {
      return;
    }

    modals.openContextModal({
      modal: "detail-ideia-patrocinios-modal",
      innerProps: { ideia },
      size: "lg",
      title: "Patrocinadores da ideia"
    });
  };

  return (
    <Group align="center" gap="xs">
      <Button onClick={handleDetailPatrocinios} variant="transparent">
        <Badge
          color="gray.2"
          py="sm"
          radius="md"
          leftSection={
            <ThemeIcon variant="light" color="yellow" size="sm">
              <IconCoin />
            </ThemeIcon>
          }
          {...badgeProperties}
        >
          <Text c="dark" fz="xs" fw={500} tt="lowercase">
            {totalMoedasNaIdeia} moedas
          </Text>
        </Badge>
      </Button>
    </Group>
  );
};

export default TotalMoedasBadge;
