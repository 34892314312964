import { Alert, Box, Button, Group, LoadingOverlay, Stack } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { RegulamentoIdeiasFormProvider } from "./regulamento-ideias-form-context";
import RegulamentoIdeiasBaseInputs from "./RegulamentoIdeiasBaseInputs";
import RegulamentoIdeiasCampanhaInputs from "./RegulamentoIdeiasCampanhaInputs";
import useRegulamentoIdeiasForm from "./useRegulamentoIdeiasForm";

import { TRegulamentoIdeiasForm } from "../../firestore";

const RegulamentoIdeiasForm = ({ regulamentoIdeias }: { regulamentoIdeias: TRegulamentoIdeiasForm }) => {
  const { form, isPending, onSubmit } = useRegulamentoIdeiasForm(regulamentoIdeias);

  const genericError = form.errors[""];

  return (
    <RegulamentoIdeiasFormProvider form={form}>
      <form onSubmit={onSubmit}>
        <Box pos="relative">
          <LoadingOverlay visible={isPending} />
        </Box>
        <Stack>
          <RegulamentoIdeiasBaseInputs />
          <Group justify="right">
            <Group justify={genericError ? "space-between" : "flex-end"}>
              {genericError && (
                <Alert icon={<IconAlertCircle size="1rem" />} color="red">
                  {genericError}
                </Alert>
              )}
              <Button type="submit" loading={isPending}>
                Salvar
              </Button>
            </Group>
          </Group>
          <RegulamentoIdeiasCampanhaInputs regulamento={regulamentoIdeias} />
        </Stack>
      </form>
    </RegulamentoIdeiasFormProvider>
  );
};

export default RegulamentoIdeiasForm;
