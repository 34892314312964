/* eslint-disable unicorn/consistent-function-scoping */
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo } from "react";
import Table from "@/components/table";
import getColumns from "./Columns";

type Properties = {
  identifiers: TRegiaoIdentifier[];
  handleRemoveIdentifier: (item: TRegiaoIdentifier) => void;
};

/**
 * TiposDeRespostasTable
 * @param root0 - Properties
 * @param root0.handleRemoveIdentifier - Function to remove an identifier
 * @param root0.identifiers - List of identifiers
 * @returns React component
 */
function IdentifierTable({ handleRemoveIdentifier, identifiers }: Properties) {
  const tableOptions = useMemo(
    () => ({
      columns: getColumns(handleRemoveIdentifier),
      data: identifiers,
      getCoreRowModel: getCoreRowModel()
    }),
    [identifiers]
  );

  const table = useReactTable(tableOptions);

  return <Table table={table} isLoading={false} />;
}

export default IdentifierTable;
