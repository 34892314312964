import { RouteObject } from "react-router-dom";
import AppContainer from "@/components/app-container";
import AcessoAsPaginas from "./acesso-pagina-page";
import AnalisarInfoPage from "./analisar-info-page";
import AnalisarListaQuestionarioPage from "./analisar-lista-questionario-page";
import AnalisarRespostaPage from "./analisar-resposta-page";
import AtividadesPage from "./atividades-page";
import AuthenticatedRootRoute from "./AuthenticatedRootRoute";
import CadastrarEvidenciasPage from "./cadastrar-evidencias-page";
import CategoriaDeDesafioPage from "./categoria-de-desafio";
import CategoriaDeInovacaoPage from "./categoria-de-inovacao";
import ColaboradoresPage from "./colaboradores-page";
import ComiteDeInovacaoPage from "./comite-de-inovacao-page";
import ComitesDeInovacaoPage from "./comites-de-inovacao-page";
import ConvidarColaboradoresExternosPage from "./convidar-colaboradores-externos-page";
import ConvidarColaboradoresPage from "./convidar-colaboradores-page";
import ConvidarEmpresaPage from "./convidar-empresa-page";
import DesafioPage from "./desafio-page";
import DesafiosPage from "./desafios-page";
import DesafiosRelatorioVisaoGeralPage from "./desafios-relatorio-visao-geral";
import DiagnosticoPage from "./diagnostico-page";
import DiagnosticosEmpresaPage from "./diagnosticos-empresa-page";
import DiagnosticosPage from "./diagnosticos-page";
import EmpresaAnalisePage from "./empresa-analise-page";
import EmpresaDiagnosticoPage from "./empresa-diagnostico-page";
import EmpresaPage from "./empresa-page";
import ErrorPage from "./ErrorPage";
import GerenciarEvidenciasPage from "./gerenciar-evidencias-page";
import GestaoInovacaoPage from "./gestao-inovacao-page";
import GruposDiagnosticosPage from "./grupos-diagnostico-page";
import HomeDesafioPage from "./home-desafio-page";
import HomeIncentivoInovacaoPage from "./home-incentivo-inovacao-page";
import HomePage from "./home-page";
import IdeiaPage from "./ideia-page";
import IdeiasDeInovacaoPage from "./ideias-de-inovacao-page";
import IdeiasPage from "./ideias-page";
import IdeiasRelatorioVisaoGeralPage from "./ideias-relatorio-visao-geral";
import JogadaDeInovacaoPage from "./jogada-de-inovacao-page";
import JogadasDeInovacaoPage from "./jogadas-de-inovacao-page";
import MinhasAtividadesPage from "./minhas-atividades-page";
import PerfilDeAcessoPage from "./perfil-de-acesso-page";
import PerguntasPage from "./perguntas-page";
import ProjetosPage from "./projetos-page";
import QuestionarioColaboradorListPage from "./questionario-colaborador-list-page";
import QuestionarioColaboradorPage from "./questionario-colaborador-page";
import QuestionarioDeInovacaoPage from "./questionario-de-inovacao-page";
import QuestionarioEmpresaPage from "./questionario-empresa-page";
import QuestionarioInfoPage from "./questionario-info-page";
import QuestionarioPage from "./questionario-page";
import RankingDeColaboradoresPage from "./ranking-de-colaboradores-page";
import RankingDeIdeiasPage from "./ranking-de-ideias-page";
import RegioesPage from "./regioes-page";
import RegulamentosDesafiosPage from "./regulamentos-desafios-page";
import RegulamentosIdeiaFormPage from "./regulamentos-ideia-form-page";
import RegulamentosIdeiasPage from "./regulamentos-ideias-page";
import RelatorioPage from "./relatorio-page";
import ResponderQuestionarioListPage from "./responder-questionario-list-page";
import ResponderQuestionarioPage from "./responder-questionario-page";
import SobreIncentivoInovacaoPage from "./sobre-incentivo-inovacao-page";
import TipoDeInovacaoPage from "./tipo-de-inovacao-page";
import TipoDeJogadaPage from "./tipo-de-jogada-page";
import TiposDeInovacaoPage from "./tipos-de-inovacao-page";
import TiposDeRespostasPage from "./tipos-de-respostas-page";
import VisualizarQuestionarioExternoPage from "./visualizar-questionario-externo-page";
import VisualizarQuestionarioPage from "./visualizar-questionario-page";

const authenticatedRoutes: RouteObject[] = [
  {
    path: "/",
    element: <AuthenticatedRootRoute />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: (
          <AppContainer title="Home" isSelect={false}>
            <GestaoInovacaoPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "home-ideias",
        element: (
          <AppContainer title="Ideias de Inovação" isSelect={false}>
            <HomePage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "home-desafios",
        element: (
          <AppContainer title="Desafios" isSelect={false}>
            <HomeDesafioPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "empresas",
        element: (
          <AppContainer title="Empresas" isSelect={false}>
            <EmpresaPage empresaRole="NORMAL" />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "empresas-convidadas",
        element: (
          <AppContainer title="Empresas Convidadas" isSelect={false}>
            <EmpresaPage empresaRole="CONVIDADA" />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "colaboradores",
        element: (
          <AppContainer title="Colaboradores" isSelect={false}>
            <ColaboradoresPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "projetos",
        element: (
          <AppContainer title="Projetos" isSelect={false}>
            <ProjetosPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "questionarios",
        element: (
          <AppContainer title="Questionário" isSelect={true}>
            <QuestionarioPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      // {
      //   path: "/responderQuestionario/:idEmpresa/:id/:email",
      //   element: <ResponderQuestionarioExternoPage />,
      //   errorElement: <ErrorPage />
      // },
      {
        path: "gruposDiagnostico",
        element: (
          <AppContainer title="Grupos" isSelect={true}>
            <GruposDiagnosticosPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "perguntas",
        element: (
          <AppContainer title="Perguntas" isSelect={true}>
            <PerguntasPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "tipos-de-respostas",
        element: (
          <AppContainer title="Tipos de Repostas" isSelect={true}>
            <TiposDeRespostasPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "regioes",
        element: (
          <AppContainer title="Regiões" isSelect={true}>
            <RegioesPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "grupos",
        element: (
          <AppContainer title="Grupos" isSelect={false}>
            <ProjetosPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "diagnostico",
        element: (
          <AppContainer title="Diagnóstico de inovação" isSelect={true}>
            <DiagnosticosPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "diagnosticoEmpresa",
        element: (
          <AppContainer title="Diagnóstico de inovação" isSelect={true}>
            <DiagnosticosEmpresaPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "questionarioEmpresa/:id",
        element: (
          <AppContainer title="Diagnóstico de inovação" isSelect={true}>
            <QuestionarioEmpresaPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "questionarioDeInovacao",
        element: (
          <AppContainer title="Questionário de inovação" isSelect={true}>
            <QuestionarioDeInovacaoPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "analisarRespostas",
        element: (
          <AppContainer title="Analisar respostas" isSelect={true}>
            <AnalisarRespostaPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "analisarResposta/:id",
        element: (
          <AppContainer title="Analisar respostas" isSelect={true}>
            <AnalisarRespostaPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "diagnosticoDaEmpresa/:idEmpresa/:idProjeto",
        element: (
          <AppContainer title="Diagnóstico de inovação" isSelect={true}>
            <EmpresaDiagnosticoPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "analisarQuestionarios/:id/:idProjeto",
        element: (
          <AppContainer title="Diagnóstico de inovação" isSelect={true}>
            <EmpresaAnalisePage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "questionarioInfo/:idEmpresa/:idProjeto/:id",
        element: (
          <AppContainer title="Diagnóstico de inovação" isSelect={true}>
            <QuestionarioInfoPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "autorizacoes",
        element: (
          <AppContainer title="Perfil de acesso" isSelect={false}>
            <PerfilDeAcessoPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "sobre-incentivo-a-inovacao",
        element: (
          <AppContainer title="Home" isSelect={false}>
            <SobreIncentivoInovacaoPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "home-incentivo-a-inovacao",
        element: (
          <AppContainer title="Home" isSelect={false}>
            <HomeIncentivoInovacaoPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "acessoPagina",
        element: (
          <AppContainer title="Acesso a páginas" isSelect={false}>
            <AcessoAsPaginas />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "analisarListaQuestionarios/:idEmpresa/:id",
        element: (
          <AppContainer title="Diagnóstico de inovação" isSelect={true}>
            <AnalisarListaQuestionarioPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "analisarInfo/:idEmpresa/:id",
        element: (
          <AppContainer title="Diagnóstico de inovação" isSelect={true}>
            <AnalisarInfoPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },

      {
        path: "questionarioColaborador/:id",
        element: (
          <AppContainer title="Diagnóstico de inovação" isSelect={true}>
            <QuestionarioColaboradorPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },

      {
        path: "questionariosColaborador/:id",
        element: (
          <AppContainer title="Diagnóstico de inovação" isSelect={true}>
            <QuestionarioColaboradorListPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "listaResponderQuestionario/:id",
        element: (
          <AppContainer title="Diagnóstico de inovação" isSelect={true}>
            <ResponderQuestionarioListPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "relatorios",
        element: (
          <AppContainer title="Diagnóstico de inovação" isSelect={true}>
            <RelatorioPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "convidarColaboradores",
        element: (
          <AppContainer title="Diagnóstico de inovação" isSelect={true}>
            <ConvidarColaboradoresPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "convidarColaboradoresExternos",
        element: (
          <AppContainer title="Diagnóstico de inovação" isSelect={true}>
            <ConvidarColaboradoresExternosPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "cadastrarEvidencias",
        element: (
          <AppContainer title="Diagnóstico de inovação" isSelect={true}>
            <CadastrarEvidenciasPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "gerenciarEvidencias",
        element: (
          <AppContainer title="Diagnóstico de inovação" isSelect={true}>
            <GerenciarEvidenciasPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "responderQuestionario/:idEmpresa/:id",
        element: (
          <AppContainer title="Diagnóstico de inovação" isSelect={true}>
            <ResponderQuestionarioPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "responderQuestionario/:idEmpresa/:id/:colaboradorId",
        element: (
          <AppContainer title="Diagnóstico de inovação" isSelect={true}>
            <ResponderQuestionarioPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "visualizarQuestionario/:idEmpresa/:idRespondente/:id",
        element: (
          <AppContainer title="Diagnóstico de inovação" isSelect={true}>
            <VisualizarQuestionarioPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "visualizarQuestionarioExterno/:idEmpresa/:idRespondente/:id",
        element: (
          <AppContainer title="Diagnóstico de inovação" isSelect={true}>
            <VisualizarQuestionarioExternoPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "diagnostico/:id",
        element: (
          <AppContainer title="Diagnóstico de inovação" isSelect={true}>
            <DiagnosticoPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "convidarEmpresa/:id",
        element: (
          <AppContainer title="Diagnóstico de inovação" isSelect={true}>
            <ConvidarEmpresaPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "ideias",
        element: (
          <AppContainer title="Ideias" isSelect={false}>
            <IdeiasPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "categorias",
        element: (
          <AppContainer title="Categorias de Inovação" isSelect={false}>
            <CategoriaDeInovacaoPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "tiposDeJogada",
        element: (
          <AppContainer title="Tipos de jogada" isSelect={false}>
            <TipoDeJogadaPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "ideias/regulamentos",
        element: (
          <AppContainer title="Regulamento de Ideias" isSelect={false}>
            <RegulamentosIdeiasPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "ideias/regulamento/:regulamentoIdeiaId?",
        element: (
          <AppContainer title="Cadastro de Regulamento de Ideia" isSelect={false}>
            <RegulamentosIdeiaFormPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "ideias/:ideiaId",
        element: (
          <AppContainer title="Ideia" isSelect={false}>
            <IdeiaPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "comites-de-inovacao",
        element: (
          <AppContainer title="Comitês de Inovação" isSelect={false}>
            <ComitesDeInovacaoPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "tipos-de-inovacao",
        element: (
          <AppContainer title="Tipos de inovação" isSelect={false}>
            <TiposDeInovacaoPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "jogadas-de-inovacao",
        element: (
          <AppContainer title="Jogadas de inovação" isSelect={false}>
            <JogadasDeInovacaoPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "ideias-de-inovacao",
        element: (
          <AppContainer title="Ideias de Inovação" isSelect={false}>
            <IdeiasDeInovacaoPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "comites-de-inovacao/:comiteId",
        element: (
          <AppContainer title="Comitê" isSelect={false}>
            <ComiteDeInovacaoPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "tipos-de-inovacao/:tipoDeInovacaoId",
        element: (
          <AppContainer title="Tipos de Inovação" isSelect={false}>
            <TipoDeInovacaoPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "jogadas-de-inovacao/:tipo/:jogadaDeInovacaoId",
        element: (
          <AppContainer title="Tipos de Inovação" isSelect={false}>
            <JogadaDeInovacaoPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "minhas-atividades",
        element: (
          <AppContainer title="Minhas Atividades" isSelect={false}>
            <MinhasAtividadesPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "atividades",
        element: (
          <AppContainer title="Atividades" isSelect={false}>
            <AtividadesPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "desafios",
        element: (
          <AppContainer title="Desafios" isSelect={false}>
            <DesafiosPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "desafios/regulamentos",
        element: (
          <AppContainer title="Regulamento de Desafios" isSelect={false}>
            <RegulamentosDesafiosPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "desafios/:desafioId",
        element: (
          <AppContainer title="Desafio" isSelect={false}>
            <DesafioPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "ranking-de-ideias",
        element: (
          <AppContainer title="Ranking de Ideias" isSelect={false}>
            <RankingDeIdeiasPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "ranking-de-colaboradores",
        element: (
          <AppContainer title="Ranking de Colaboradores" isSelect={false}>
            <RankingDeColaboradoresPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "desafios/categorias-de-desafios",
        element: (
          <AppContainer title="Categorias de Desafios" isSelect={false}>
            <CategoriaDeDesafioPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "relatorios/visao-geral-de-ideias",
        element: (
          <AppContainer title="Visão Geral de Ideias" isSelect={false}>
            <IdeiasRelatorioVisaoGeralPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      },
      {
        path: "relatorios/visao-geral-de-desafios",
        element: (
          <AppContainer title="Visão Geral de Desafios" isSelect={false}>
            <DesafiosRelatorioVisaoGeralPage />
          </AppContainer>
        ),
        errorElement: <ErrorPage />
      }
    ]
  }
];

export default authenticatedRoutes;
