import { ActionIcon, Card, Group, Loader, Select, Text, Title, Tooltip } from "@mantine/core";
import { IconRefresh } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { useEffect, useState } from "react";
import BreadcrumbsNavigation from "@/components/breadcrumbs-navigation";
import NoData from "@/components/no-data";
import { getRankingAnoPerColaborador } from "@/modules/ranking-de-colaboradores/firestore";
import useUserStore from "@/modules/users/store";
import RankingDeColaboradoresList from "./RankingDeColaboradoresList";

type TGetRankingAnoPerColaboradorResponse = Awaited<ReturnType<typeof getRankingAnoPerColaborador>>;

const last5Years = Array.from({ length: 5 }, (_, index) => String(new Date().getFullYear() - index));

/**
 * Get rankingAnoPerColaborador query for the active empresa
 * @returns - rankingAnoPerColaborador query
 */
function useGetRankingAnoPerColaborador() {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  return useQuery<TGetRankingAnoPerColaboradorResponse, FirestoreError, string[]>({
    queryKey: [activeEmpresaId, "getRankingAnoPerColaboradorDocs"],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Nenhuma empresa ativa no momento");
      }

      return getRankingAnoPerColaborador(activeEmpresaId);
    },
    select(data) {
      if (data.empty) {
        return last5Years; //[];
      }

      return data.docs.map((document_) => document_.id);
    }
  });
}

const RankingDeColaboradoresPage = () => {
  const { isLoading, data: years, refetch } = useGetRankingAnoPerColaborador();
  const [selectedYear, setSelectedYear] = useState<string | null>();

  useEffect(() => {
    setSelectedYear(years ? years[0] : null);
  }, [years]);

  /**
   * Handle refresh click
   */
  function handleRefreshClick() {
    void refetch({ cancelRefetch: true });
  }

  const hasNoMovimentacao = !isLoading && years && years.length === 0;

  return (
    <>
      <BreadcrumbsNavigation notHome>
        <Text c="dimmed">Atividades</Text>
      </BreadcrumbsNavigation>
      <Card withBorder>
        <Card.Section inheritPadding py="md">
          <Group justify="space-between">
            <Title order={3} fw="normal">
              Ranking De Colaboradores
            </Title>
            <Group>
              <Select
                label="Selecione o ano"
                placeholder="Ano do ranking"
                disabled={isLoading || hasNoMovimentacao}
                data={years}
                value={selectedYear}
                onChange={setSelectedYear}
              />
              <Tooltip label="Atualizar">
                <ActionIcon variant="subtle" onClick={handleRefreshClick} loading={isLoading} mt="lg">
                  <IconRefresh />
                </ActionIcon>
              </Tooltip>
            </Group>
          </Group>
        </Card.Section>
        <Card.Section inheritPadding py="md">
          {isLoading && <Loader />}
          {years && years.length > 0 && selectedYear && <RankingDeColaboradoresList year={Number(selectedYear)} />}
          {hasNoMovimentacao && <NoData>Nenhuma movimentação encontrada</NoData>}
        </Card.Section>
      </Card>
    </>
  );
};

export default RankingDeColaboradoresPage;
