import { Anchor, Card, Flex, Grid, Group, Space, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { NavLink } from "react-router-dom";
import { verificationDate } from "@/modules/projetos/components/projeto-form/ProjetoForm";

const MAGIC_NUMBER = 1000;

// prettier-ignore
const DiagnosticoItem = ({ projeto }: { projeto: TProjeto }) => {
  const isSmMarches = useMediaQuery("(max-width: 48em)");
  return (
    <>
      <Space h="md" />
      <Card withBorder shadow="md" style={{ flex: 1, width: isSmMarches ? MAGIC_NUMBER : "100%" }}>
        <Grid align="center">
          <Grid.Col span={4}>
            <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
              <Text fw={300} fz="xs">
                Nome do projeto
              </Text>
              <Text fw={500} fz="md">
                {projeto.nome}
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={4}>
            <Group align={"center"} justify={"center"}>
              <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
                <Text fw={300} fz="xs">
                  Período de avaliação
                </Text>
                <Text fw={500} fz="md">
                  {typeof projeto?.anoAtuacao === "number" ? formatDate(verificationDate(new Date())) : formatDate(verificationDate(projeto?.anoAtuacao))} - {typeof projeto?.anoVigencia === "number" ? formatDate(verificationDate(new Date())) : formatDate(verificationDate(projeto?.anoVigencia))}
                </Text>
              </Flex>
            </Group>
          </Grid.Col>
          <Grid.Col span={3}>
            <Group align={"end"} justify={"end"} >
              <Anchor component={NavLink} to={`/questionarioEmpresa/${projeto.id}`}>
                Ver mais
              </Anchor>
            </Group>
          </Grid.Col>
        </Grid>
      </Card>
    </>
  );
};

export default DiagnosticoItem;

const MAGIC_NUMBER_ONE = 1;
const MAGIC_NUMBER_TWO = 2;

// prettier-ignore
const formatDate = (date: Date | null) => {
  if (date) {
    const day = String(date.getDate()).padStart(MAGIC_NUMBER_TWO, "0");
    const month = String(date.getMonth() + MAGIC_NUMBER_ONE).padStart(MAGIC_NUMBER_TWO, "0"); // Os meses são baseados em zero
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }
  return ""
};
