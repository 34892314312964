import { getConvitesProjetos } from "@/modules/empresas/firestore";

/**
 * Get campanhas
 * @param idProjeto - id projeto
 * @returns - campanhas
 */
// prettier-ignore
async function getEmpresas(
  idProjeto: TProjeto["id"] | undefined
): Promise<TConvite[]> {

  if (idProjeto) {
      const newData = await getConvitesProjetos(idProjeto);
      return newData.docs.map((convite) => convite.data());
  }

  return [];
}

export default getEmpresas;
