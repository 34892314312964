import { Button, Group, Stack, TextInput, Title } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { ChangeEvent, useState } from "react";
import { captureException } from "@/services/log";
import { sendResetPasswordToUserEmail } from "../../functions";

type ForgotPasswordModalProperties = ContextModalProps<{
  email: string;
}>;

interface Values {
  email: string;
}

// prettier-ignore
const ForgotPasswordModal = ({ context, id, innerProps: { email } }: ForgotPasswordModalProperties) => {
  const [values, setValues] = useState<Values>({ email } || oldValues);
  const [loading, setLoading] = useState(false);

  const onSuccess = () => {
    context.closeModal(id);
    showNotification({
      message: "Verifique seu e-mail para redefinir sua senha.",
      autoClose: 3500
    });
  };

  return (
    <form >
      <Stack gap="sm">
        <Title size="h3">Esqueci minha senha</Title>
        <TextInput
          withAsterisk
          placeholder="e-mail"
          size="lg"
          radius="lg"
          type="email"
          autoComplete="email"
          onChange={onChangeValues("email", setValues)}
          disabled={loading}
        />
      </Stack>

      <Group justify="flex-end" mt="md">
        <Button radius="lg" size="md" disabled={loading} onClick={inviteColaboradores(onSuccess, values, setLoading)}>
          Enviar
        </Button>
      </Group>
    </form>
  );
};

export default ForgotPasswordModal;

// prettier-ignore
const onChangeValues = (field: string, setValues: React.Dispatch<React.SetStateAction<Values>>) => {
  return (event: ChangeEvent<HTMLInputElement>) => {
    setValues(values => ({ ...values, [field]: event?.target.value }))
  }
}

// prettier-ignore
const oldValues = {
  email: "",
};

/**
 * Handles the click event on copy link to clipboard.
 * @param onSuccess - Function case success invite
 * @param values - The text with emails.
 * @param setLoading - The another id company.
 * @returns - The return the click.
 */
// prettier-ignore
const inviteColaboradores = (
  onSuccess: () => void,
  values: Values,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  return () => {
    try {
      setLoading(true)
      if (values?.email === "") {
        throw new Error("Email obrigatório");
      }
      sendResetPasswordToUserEmail({ ...values })
        .then(() => {
          onSuccess();
          return undefined;
        })
        .catch((error: Error) => {
          captureException(error, true);
        });
    } catch (error) {
      if (error instanceof Error) {
        showNotification({
          message: error.message,
          color: "red",
          autoClose: 2500
        });
        setLoading(false)
      } else {
        showNotification({
          message: "Ocorreu um erro desconhecido.",
          color: "red",
          autoClose: 2500
        });
      }
    }
  };
};
