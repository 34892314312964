import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { getConvitesProjetos } from "@/modules/empresas/firestore";
import useUserStore from "@/modules/users/store";

type TGetRespostaResponse = Awaited<ReturnType<typeof getConvitesProjetos>>;
const QUERY_KEY: string = "convite-list" as const;
/**
 * Get resposta from url params
 * @returns - resposta or null if not found
 */
function useGetConvites() {
  const { id } = useParams<{ id: string }>();
  const projeto = useUserStore((state) => state.activeProjeto);
  const verificationId = projeto || id;

  return useQuery<TGetRespostaResponse, FirestoreError, TConvite[] | null>({
    queryKey: [QUERY_KEY, id, verificationId],
    queryFn() {
      if (!id) {
        throw new Error("Ideia não selecionada");
      }

      return getConvitesProjetos(verificationId);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((document_) => document_.data());
    },
    enabled: !!id
  });
}

export default useGetConvites;
