// import Can from "@/components/can";
import { Card, Group, Text, Flex, Grid, Checkbox, Space } from "@mantine/core";
import { useCallback } from "react";

interface Tatica {
  id: string | number;
  nome: string;
  descricao: string;
}

interface NewTatica {
  id: string | number;
  nome: string;
  descricao: string;
  vinculos?: number[] | undefined;
  corVinculos?: string | undefined;
}
// prettier-ignore
const TaticasDeJogadasDeInovacaoCard = ({
  tatica,
  newTaticas,
  changeTaticas
}: {
  tatica: Tatica;
  changeTaticas: (value: Tatica) => void;
  newTaticas: NewTatica | undefined;
}) => {
  /**
   * Checked checkbox
   */
  const handleCheckboxChange = useCallback(() => {
    changeTaticas(tatica);
  }, []);

  return (
    <>
      <Card withBorder radius="lg" style={{ border: newTaticas && newTaticas?.corVinculos ? `5px solid ${newTaticas?.corVinculos}` : "1px solid rgb(226,230,233)" }}>
        <Grid grow>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <Group>
              <Checkbox onChange={handleCheckboxChange} />
              <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
                <Text fw={500} fz="xs">
                  Tática
                </Text>
                <Text fw={300} fz="md">
                  {tatica.nome}
                </Text>
                <Text fw={100} fz="sm">
                  {tatica.descricao}
                </Text>
              </Flex>
            </Group>
          </Grid.Col>
        </Grid>
      </Card>
      <Space h="xl" />
    </>
  );
};

export default TaticasDeJogadasDeInovacaoCard;
