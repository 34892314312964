import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getConvitesProjetos } from "@/modules/empresas/firestore";
import useUserStore from "@/modules/users/store";

type TGetRespostaResponse = Awaited<ReturnType<typeof getConvitesProjetos>>;
const QUERY_KEY: string = "convite-list" as const;
/**
 * Get resposta from url params
 * @returns - resposta or null if not found
 */
function useGetConvitesSelects() {
  const id = useUserStore((state) => state.activeProjeto);
  return useQuery<TGetRespostaResponse, FirestoreError, TConvite[] | null>({
    queryKey: [QUERY_KEY, id],
    queryFn() {
      if (!id) {
        throw new Error("Ideia não selecionada");
      }

      return getConvitesProjetos(id);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((document_) => document_.data());
    },
    enabled: !!id
  });
}

export default useGetConvitesSelects;
