import { subject } from "@casl/ability";
import { Text, Anchor, Badge, Group } from "@mantine/core";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { NavLink } from "react-router-dom";
import { formatDate } from "@/utils/date";
import ContextMenuRow from "./ContextMenuRow";
import IdeiaStatusBadge from "../ideia-status-badge";
import { IdeiaUserPatrocinio } from "../ideia-user-patrocinio";

const columnHelper = createColumnHelper<TIdeia>();

const columns: ColumnDef<TIdeia>[] = [
  columnHelper.accessor("titulo", {
    header: "Titulo",
    enableSorting: true,
    enableColumnFilter: true,
    cell({ row, getValue }) {
      const id = row.original.id;
      return (
        <Group gap="xs">
          <Anchor component={NavLink} to={`/ideias/${id}`}>
            {getValue()}
          </Anchor>
          <IdeiaStatusBadge status={row.original.status} />
        </Group>
      );
    }
  }),
  columnHelper.accessor("setores", {
    header: "Setor",
    enableSorting: true,
    enableColumnFilter: true,
    cell({ getValue }) {
      const setores = getValue();
      return (
        <Group gap="xs">
          {setores.map((setor) => (
            <Badge key={setor} variant="default" color="gray">
              {setor}
            </Badge>
          ))}
        </Group>
      );
    }
  }),
  columnHelper.accessor("publishedAt", {
    header: "Publicado",
    enableSorting: true,
    enableColumnFilter: true,
    cell({ getValue }) {
      const publishedAt = getValue();
      return publishedAt ? (
        <Text>{formatDate(publishedAt, { year: "numeric", month: "short", day: "numeric" })}</Text>
      ) : (
        <Badge color="gray" size="sm">
          Em Rascunho
        </Badge>
      );
    }
  }),
  columnHelper.display({
    id: "actions",
    cell: (properties) => {
      const ideia = subject("TIdeia", properties.row.original);

      return (
        <Group justify="flex-end" gap={2}>
          <ContextMenuRow ideia={ideia} />
        </Group>
      );
    }
  })
];

export default columns;

export const columnsIdeiasMobile: ColumnDef<TIdeia>[] = [
  columnHelper.accessor("titulo", {
    header: "Titulo",
    enableSorting: true,
    enableColumnFilter: true,
    cell({ row, getValue }) {
      const id = row.original.id;
      return (
        <Group gap="xs">
          <Anchor component={NavLink} to={`/ideias/${id}`}>
            {getValue()}
          </Anchor>
          <IdeiaStatusBadge status={row.original.status} />
        </Group>
      );
    }
  }),
  columnHelper.accessor("publishedAt", {
    header: "Publicado",
    enableSorting: true,
    enableColumnFilter: true,
    cell({ getValue }) {
      const publishedAt = getValue();
      return publishedAt ? (
        <Text>{formatDate(publishedAt, { year: "numeric", month: "short", day: "numeric" })}</Text>
      ) : (
        <Badge color="gray" size="sm">
          Em Rascunho
        </Badge>
      );
    }
  }),
  columnHelper.display({
    id: "actions",
    cell: (properties) => {
      const ideia = subject("TIdeia", properties.row.original);

      return (
        <Group justify="flex-end" gap={2}>
          <ContextMenuRow ideia={ideia} />
        </Group>
      );
    }
  })
];

export const patrocinioColumn = columnHelper.display({
  id: "patrocinio",
  header: "Patrocinio",
  cell: (properties) => {
    const ideia = subject("TIdeia", properties.row.original);
    return (
      <Group justify="start" gap={2}>
        <IdeiaUserPatrocinio ideia={ideia} />
      </Group>
    );
  }
});
