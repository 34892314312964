import { Center, Loader, Text } from "@mantine/core";
import { useLocation, useNavigate } from "react-router-dom";
import CleanContainer from "@/components/clean-container";
import useQuery from "@/hooks/useQuery";
import useSignInWithCustomToken from "@/hooks/useSignInWithCustomToken";
import { captureException } from "@/services/log";
import LoginForm from "./components/login-form";

interface LocationState {
  error?: Error;
}

const LoginPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { error } = (location.state || {}) as LocationState;

  const query = useQuery();
  const token = query.get("token");
  const { isPending } = useSignInWithCustomToken(token, {
    onSuccess() {
      navigate("/", {
        replace: true
      });
    },
    onError(error) {
      captureException(error);
      if (token) {
        query.delete("token");
        navigate({
          search: query.toString()
        });
      }
    }
  });

  if (isPending || token) {
    return (
      <Center h="100%">
        <Loader />
      </Center>
    );
  }

  return (
    <CleanContainer>
      {error && (
        <Text c="red" fz="sm" ta="center">
          {error.message}
        </Text>
      )}
      <Center h="100%" p="lg">
        <LoginForm />
      </Center>
    </CleanContainer>
  );
};

export default LoginPage;
