import { subject } from "@casl/ability";
import { Button, Group, Loader, Menu, Text } from "@mantine/core";
import { IconCaretDownFilled } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { QUERY_KEY } from "@/modules/empresas/components/empresas-table/EmpresasTable";
import { getEmpresas } from "@/modules/empresas/firestore";
import useUserStore, { setActiveEmpresaId } from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";

const EMPRESA_ID_KEY = "activeEmpresaId";

type TGetEmpresasResponse = Awaited<ReturnType<typeof getEmpresas>>;

const EmpresaMenuItem = ({ empresa }: { empresa: TEmpresa }) => {
  /**
   * Sets the active empresa id in the store
   */
  function handleMenuClick() {
    setActiveEmpresaId(empresa.id);
    localStorage.setItem(EMPRESA_ID_KEY, empresa.id);
  }

  return (
    <Menu.Item key={empresa.id} onClick={handleMenuClick}>
      {empresa.nomeFantasia}
    </Menu.Item>
  );
};

/**
 * Query hook to fetch the empresas for the current user
 * @returns The query result
 */
function useEmpresasQuery() {
  const uid = getCurrentUser()?.uid;
  return useQuery<TGetEmpresasResponse, FirestoreError, TEmpresa[]>({
    queryKey: [QUERY_KEY, "empresa-selector", uid],
    enabled: !!uid,
    queryFn() {
      if (!uid) {
        throw new Error("Usuário não autenticado");
      }

      return getEmpresas(uid);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((document_) => subject("TEmpresa", document_.data()));
    }
  });
}
// prettier-ignore
const EmpresaSelector = () => {
  const activeEmpresa = useUserStore((state) => state.activeEmpresa);
  const { data: empresas, isLoading } = useEmpresasQuery();
  const uid = getCurrentUser()?.uid;
  const hasMoreEmpresas = empresas && empresas.length > 1;

  if (!hasMoreEmpresas && activeEmpresa) {
    return (
      <Text c="white.1" fw="bold">
        {activeEmpresa.nomeFantasia}
      </Text>
    );
  }

  // const activeEmpresaId = localStorage.getItem(EMPRESA_ID_KEY); // TODO: se a empresa não existir, remover do local storage pq senão vai dar erro
  // if (activeEmpresaId) {
  //  setActiveEmpresaId(activeEmpresaId);
  // }

  return (
    <Group>
      {activeEmpresa && (
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <Button
              type="button"
              variant="subtle"
              p={0}
              size="compact-md"
              color="white"
              rightSection={
                isLoading ? (
                  <Loader size="xs" color="white.1" ml="xs" />
                ) : (
                  <IconCaretDownFilled color="white" size="1rem" />
                )
              }
            >
              <Text c="white.1" fw="bold">
                {activeEmpresa.nomeFantasia}
              </Text>
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Label>Selecionar empresa</Menu.Label>
            {empresas?.filter(item => item.idColaboradorConvidando !== uid).map((empresa) => <EmpresaMenuItem key={empresa.id} empresa={empresa} />)}
          </Menu.Dropdown>
        </Menu>
      )}
    </Group>
  );
};

export default EmpresaSelector;
