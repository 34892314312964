import { ActionIcon, Menu } from "@mantine/core";
import { IconDotsVertical, IconEdit, IconEye, IconTrash } from "@tabler/icons-react";
import EditItemButton from "@/components/edit-item-button";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import { dataAutorizacoes } from "@/src/routes/empresa-page/EmpresaPage";
import ideiaSchema from "../../schema/ideia-schema";
import PublicarIdeiaButton from "../publicar-ideia-button";
import RemoverIdeiaButton from "../remover-ideia-button";

/**
 * Hook for obtaining the user's authorizations
 * @param empresaId User's company ID
 * @param uid User's ID
 * @returns User's authorizations
 */
function useGetAuthorizations(empresaId: string = "", uid: string = "") {
  const { data: userColaborador } = useGetColaboradorById(empresaId || "", uid || "");
  const activeAutorizacao = useUserStore((store) => store.autorizacoes);
  const autorizacao = dataAutorizacoes?.find((item) => item.label === userColaborador?.role);
  const formatAutorizacoes = activeAutorizacao?.filter((item) => item.tipo === autorizacao?.value);
  const { criacao, editar, remover } = formatAutorizacoes?.find((item) => item.tipoAutorizacao === "3") ?? {};

  return { criacao, editar, remover };
}

const PublishIdeia = ({ ideia }: { ideia: TIdeia }) => {
  return (
    <>
      <Menu.Item leftSection={<IconEye size={14} />} component={PublicarIdeiaButton} ideia={ideia}>
        Publicar
      </Menu.Item>
    </>
  );
};

const EditIdeia = ({ ideia }: { ideia: TIdeia }) => {
  return (
    <>
      <Menu.Item
        leftSection={<IconEdit size={14} />}
        component={EditItemButton}
        modal="ideia-form-modal"
        schema={ideiaSchema}
        item={ideia}
      >
        Editar
      </Menu.Item>
    </>
  );
};

const RemoveIdeia = ({ ideia }: { ideia: TIdeia }) => {
  return (
    <>
      <Menu.Divider />
      <Menu.Item color="red" leftSection={<IconTrash size={14} />} component={RemoverIdeiaButton} ideia={ideia}>
        Remover
      </Menu.Item>
    </>
  );
};

// prettier-ignore
const ContextMenuRow = ({ ideia }: { ideia: TIdeia }) => {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);
  const uid = getCurrentUser()?.uid;

  const { criacao, editar, remover } = useGetAuthorizations(activeEmpresaId, uid);

  const isAuthor = ideia.createdBy === uid;
  const isIdeaPublished = !!ideia.publishedAt;

  const canRemoveIdeia = remover || (criacao && isAuthor && !isIdeaPublished);
  const canEditIdeia = editar || (criacao && isAuthor && !isIdeaPublished);
  const canPublishIdeia = !isIdeaPublished && (editar || (criacao && isAuthor));

  if (!canRemoveIdeia && !canEditIdeia && !canPublishIdeia) {
    return <></>
  }

  return (
    <Menu shadow="md" width={200} position="left" withArrow arrowPosition="center">
      <Menu.Target>
        <ActionIcon variant="transparent" aria-label="Menu" color="gray">
          <IconDotsVertical />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {canPublishIdeia && <PublishIdeia ideia={ideia} />}
        {canEditIdeia && <EditIdeia ideia={ideia} />}
        {canRemoveIdeia && <RemoveIdeia ideia={ideia} />}
      </Menu.Dropdown>
    </Menu>
  );
};

export default ContextMenuRow;
