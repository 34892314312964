import { Card, Group, Text, Flex, Space, Stack, SimpleGrid } from "@mantine/core";
import { QueryObserverResult } from "@tanstack/react-query";
import React from "react";
import BadgeCategoria from "@/modules/jogadas-de-inovacao/components/badge-categoria";
import HomeTaticasListCardModal from "../home-taticas-list-card-modal";

interface Tatica {
  id: string | number;
  nome: string;
  descricao: string;
  nomeCategoria: string;
  nomeTipo: string;
}
// prettier-ignore
const JogadaDeInovacaoCard: React.FC<{
  jogada: TJogadaDeInovacao;
  refetch: () => Promise<QueryObserverResult>;
}> = ({ jogada, refetch }) => {
  return (
    <Card padding={"0px"} radius={0}>
      <SimpleGrid cols={3}>
        <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
          <Text fw={300} fz="xs">
            Tipo de jogada
          </Text>
          <Text fw={300} fz="sm">
            <BadgeCategoria id={jogada.tipoDeJogada} />
          </Text>
        </Flex>
        {jogada.segmento ? <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
          <Text fw={300} fz="xs">
            Segmento que se aplica
          </Text>
          <Text fw={400} fz="sm">
            {jogada.segmento}
          </Text>
        </Flex> : <></>}
        {jogada.ambicao ? <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
          <Text fw={300} fz="xs">
            Ambição
          </Text>
          <Text fw={400} fz="sm">
            {jogada.ambicao}
          </Text>
        </Flex> : <></>}
      </SimpleGrid>
      <Space h="xl" />
      <Group justify="space-between">
        <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
          <Text fw={300} fz="xs">
            Descrição da jogada
          </Text>
          <Text fw={400} fz="sm">
            {jogada.descricao}
          </Text>
        </Flex>
      </Group>
      {jogada && jogada.taticas && Array.isArray(jogada.taticas) && jogada?.taticas?.length > 0 ? (
        <Card.Section inheritPadding py="xs">
          <Stack>
            <Card style={{ background: "rgb(245,246,247)" }}>
              {jogada.taticas.map((item: Tatica, index: number) => (
                <HomeTaticasListCardModal key={index} jogada={item} item={jogada} refetch={refetch} />
              ))}
            </Card>
          </Stack>
        </Card.Section>
      ) : (
        <span>Nenhuma tática cadastrada</span>
      )}
    </Card>
  );
};

export default JogadaDeInovacaoCard;
