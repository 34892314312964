import { Group, Text, Skeleton, TextProps } from "@mantine/core";
import { DocumentReference } from "firebase/firestore";
import useGetColaboradoresFromReference from "../../hooks/useGetColaboradoresFromReference";
import AvatarsColaboradores from "../avatars-colaboradores";

const DEFAULT_COLABORADORES_VISIBLE = 3;

const ColaboradoresInlineList = ({
  colaboradores,
  topVisibleAvatars,
  topVisibleNames = DEFAULT_COLABORADORES_VISIBLE,
  ...textProperties
}: Pick<TextProps, "fz" | "fw" | "c"> & {
  colaboradores: DocumentReference<TColaborador>[] | string[];
  topVisibleNames?: number;
  topVisibleAvatars?: number;
}) => {
  const { data: allParticipantes, isLoading: isLoadingResponsaveis } = useGetColaboradoresFromReference(colaboradores);

  const firstsColaboradores = (allParticipantes || []).slice(0, topVisibleNames);
  const remainingColaboradores = (allParticipantes || []).slice(topVisibleNames);

  const displayAvatars =
    topVisibleAvatars === undefined || (typeof topVisibleAvatars === "number" && topVisibleAvatars > 0);

  return (
    <Group gap="xs">
      {isLoadingResponsaveis && <Skeleton height={34} width={34} radius="xl" />}
      {allParticipantes && displayAvatars && (
        <AvatarsColaboradores colaboradores={allParticipantes} maxVisible={topVisibleAvatars} />
      )}
      {allParticipantes && (
        <Text fw={500} {...textProperties}>
          {firstsColaboradores.map((colaborador) => colaborador.nome || colaborador.email).join(", ")}
        </Text>
      )}
      {remainingColaboradores.length > 0 && <Text fw={500}>+{remainingColaboradores.length}</Text>}
    </Group>
  );
};

export default ColaboradoresInlineList;
