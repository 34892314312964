import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getRespostasMedia } from "@/modules/analisar-respostas/firestore";
import { QUERY_KEY } from "@/modules/perguntas/components/perguntas-table/PerguntasTable";
import useUserStore from "@/modules/users/store";

type TGetGroupResponse = Awaited<ReturnType<typeof getRespostasMedia>>;

/**
 * Get ideia from url params
 * @param ids - ids array
 * @param ids.ids - ids array
 * @returns - Ideia or null if not found
 */
function useGetRespostasMedia({ ids }: { ids: Array<string> | undefined }) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  return useQuery<TGetGroupResponse, FirestoreError, TResposta[]>({
    queryKey: [QUERY_KEY, ids, activeEmpresaId],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!ids) {
        throw new Error("Nenhum questionario");
      }

      return getRespostasMedia(activeEmpresaId, ids);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((document_) => document_.data());
    },
    enabled: !!ids && !!activeEmpresaId
  });
}

export default useGetRespostasMedia;
