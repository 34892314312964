const PERCENTAGE_MULTIPLIER = 100;

/**
 * Calculates the percentage change between two numbers
 * @param oldValue - The old value
 * @param newValue - The new value
 * @returns - The percentage change
 */
export function calculatePercentageChange(oldValue: number, newValue: number) {
  if (oldValue === 0) {
    return newValue === 0 ? 0 : PERCENTAGE_MULTIPLIER;
  }

  return ((newValue - oldValue) / Math.abs(oldValue)) * PERCENTAGE_MULTIPLIER;
}
