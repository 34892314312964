import { Campo } from "./types";

/**
 * Function that validates if a field is a Campo
 * @param value unknown value
 * @returns value is Campo
 */
export function isCampo(value: unknown): value is Campo {
  return (
    typeof value === "object" &&
    value !== null &&
    "campo" in value &&
    "valor" in value &&
    typeof (value as Campo).campo === "string" &&
    typeof (value as Campo).valor === "number"
  );
}
