import { subject } from "@casl/ability";
import { Timestamp, FirestoreDataConverter, collection, where, query, getDocs } from "firebase/firestore";
import { getEmpresaDocumentReference } from "../empresas/firestore";

export const QUERY_KEY: string = "respostas" as const;

type TRespostaDocument = Omit<TResposta, "createdAt" | "updatedAt" | "deletedAt"> & {
  createdAt: Timestamp;
  updatedAt?: Timestamp;
  deletedAt: Timestamp | null;
};

const respostaConverter: FirestoreDataConverter<TResposta> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const { createdAt, updatedAt, deletedAt, ...document } = snap.data() as TRespostaDocument;

    const data: TResposta = {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      deletedAt: deletedAt ? deletedAt.toDate() : null,
      refPath: snap.ref.path
    };

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }

    return subject("TResposta", data);
  }
};

/**
 * Get respostas collection reference from empresaId
 * @param empresaId - Empresa id to get the respostas collection reference
 * @returns - Respostas collection reference
 */
function getRespostasCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, "respostas");
}

/**
 * Get questionário query for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param idRespondente - id do respondente
 * @param idQuestionario - id do questionario
 * @returns - questionário query
 */
function generateRespostaQuery(
  empresaId: TEmpresa["id"],
  idRespondente: string | null | undefined,
  idQuestionario: string | null | undefined
) {
  const RespostaCollectionReference = getRespostasCollectionReference(empresaId).withConverter(respostaConverter);

  if (idRespondente && idQuestionario) {
    return query(
      RespostaCollectionReference,
      where("deletedAt", "==", null),
      where("idQuestionario", "==", idQuestionario),
      where("idRespondente", "==", idRespondente)
    );
  } else if (idRespondente) {
    return query(
      RespostaCollectionReference,
      where("deletedAt", "==", null),
      where("idRespondente", "==", idRespondente)
    );
  }

  return query(RespostaCollectionReference, where("deletedAt", "==", null));
}

/**
 * Get questionário for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param idRespondente - id do questionario
 * @param idQuestionario - id do questionario
 * @returns - questionário document snapshot
 */
// prettier-ignore
export function getRepostasArray(empresaId: TEmpresa["id"], idRespondente: string | null | undefined, idQuestionario: string | null | undefined) {
  const q = generateRespostaQuery(empresaId, idRespondente, idQuestionario);
  return getDocs(q);
}
