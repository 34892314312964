import { subject } from "@casl/ability";
import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { QUERY_KEY } from "@/modules/projetos/components/projetos-table/ProjetosTable";
import { getProjetosItem } from "@/modules/projetos/firestore";
import useUserStore from "@/modules/users/store";

type TGetProjetoResponse = Awaited<ReturnType<typeof getProjetosItem>>;

/**
 * Get projeto from url params
 * @returns - projeto or null if not found
 */
function useGetProjetoFromUrl() {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const { id } = useParams<{ id: string }>();

  return useQuery<TGetProjetoResponse, FirestoreError, TProjeto | null>({
    queryKey: [QUERY_KEY, id, activeEmpresaId],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!id) {
        throw new Error("Ideia não selecionada");
      }

      return getProjetosItem(activeEmpresaId, id);
    },
    select(dataResponse) {
      if (!dataResponse.exists()) {
        return null;
      }

      return subject("TProjeto", dataResponse.data());
    },
    enabled: !!id && !!activeEmpresaId
  });
}

export default useGetProjetoFromUrl;
