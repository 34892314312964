import { FileWithPath } from "@mantine/dropzone";
import { DocumentReference, serverTimestamp } from "firebase/firestore";
import { ObjectSchema } from "yup";
import { TColaboradorDocument, getColaboradorDocumentReferenceFromPath } from "@/modules/colaboradores/firestore";
import { getEmpresaDocumentReference, getEmpresaDocumentReferenceFromPath } from "@/modules/empresas/firestore";
import { getIdeiaDocumentReferenceFromPath } from "@/modules/ideias/firestore";
import useUserStore from "@/modules/users/store";
import schema from "@/schema";
import auditSchema from "@/schema/audit-schema";
import { TMarcoDatabaseFields, TMarcoForm, TMarcoFormFields } from "../firestore";

const MIN_ACTIONS_LENGTH = 10;

// eslint-disable-next-line unicorn/prefer-spread
const marcoSchemaDatabaseFields: ObjectSchema<TMarcoDatabaseFields> = auditSchema.concat(
  schema.object().shape({
    deletedAt: schema.mixed<ReturnType<typeof serverTimestamp>>().nullable().default(null),

    // Atuação
    startedAt: schema.mixed<ReturnType<typeof serverTimestamp>>().nullable().default(null).label("Data de inicio"),
    finishedAt: schema.mixed<ReturnType<typeof serverTimestamp>>().nullable().default(null).label("Data da finalização")
  })
);

const todayAtBeginOfDay = new Date();
todayAtBeginOfDay.setHours(0, 0, 0, 0);

const marcoSchemaFormFields: ObjectSchema<TMarcoFormFields> = schema.object({
  id: schema.string().optional(),
  refPath: schema.string().optional(),
  colaboradoresResponsaveis: schema
    .array()
    .of(schema.mixed<DocumentReference<TColaborador, TColaboradorDocument>>().required().defined())
    .required()
    .defined()
    .default([])
    .min(1)
    .transform((value: (string | DocumentReference<TColaborador, TColaboradorDocument>)[]) =>
      value.map((v) => {
        const isString = typeof v === "string";
        return isString ? getColaboradorDocumentReferenceFromPath(v) : v;
      })
    )
    .label("Colaboradores responsáveis"),

  ideiaReference: schema
    .mixed<DocumentReference<TIdeia> | string>()
    .required()
    .defined()
    .transform((value: string | DocumentReference<TIdeia>) => {
      const isString = typeof value === "string";
      return isString ? getIdeiaDocumentReferenceFromPath(value) : value;
    })
    .label("Ideia"),

  empresaReference: schema
    .mixed<DocumentReference<TEmpresa> | string>()
    .required()
    .defined()
    .default(() => {
      const activeEmpresaId = useUserStore.getState().activeEmpresaId;
      if (activeEmpresaId) {
        return getEmpresaDocumentReference(activeEmpresaId).path;
      }
      return null;
    })
    .transform((value: string | DocumentReference<TEmpresa>) => {
      const isString = typeof value === "string";
      return isString ? getEmpresaDocumentReferenceFromPath(value) : value;
    })
    .label("Empresa"),

  dataPrazo: schema.date().required().defined().label("Data de prazo"),
  acoes: schema.string().min(MIN_ACTIONS_LENGTH).required().default("").defined().label("Ações"),

  // Atuação
  descricaoAtuacao: schema
    .string()
    .min(MIN_ACTIONS_LENGTH)
    .required()
    .defined()
    .default("")
    .label("Descreva a sua atuação"),
  dataConclusao: schema
    .date()
    .required()
    .defined()
    .min(todayAtBeginOfDay)
    .default(null)
    .meta({
      description: "Preencha o tempo estimado de finalização"
    })
    .label("Data de conclusão"),
  anexos: schema
    .array()
    .of(schema.mixed<TFileStored>().required().defined())
    .required()
    .defined()
    .default([])
    .meta({
      description:
        "Sintetize e inclua tudo o que for necessário em um arquivo, se tiver vídeos pode incluir o link do vídeo no arquivo de texto"
    })
    .label("Se tiver algum anexo sobre a atividade, pode incluir aqui:"),

  anexosFilesToUpload: schema
    .array()
    .of(schema.mixed<FileWithPath>().required().defined())
    .required()
    .defined()
    .default([])
});

const marcoSchema: ObjectSchema<TMarcoForm> = marcoSchemaFormFields
  // eslint-disable-next-line unicorn/prefer-spread
  .concat(marcoSchemaDatabaseFields)
  // .noUnknown()
  // .strict()
  .label("Marco");

export default marcoSchema;
