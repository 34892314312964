import { Card, Divider, Group, Skeleton, Stack, Text, ThemeIcon, Tooltip, TooltipProps } from "@mantine/core";
import { IconTrendingDown, IconTrendingUp } from "@tabler/icons-react";
import { useMemo } from "react";
import { extractRelativeTimeParts, getLastPeriodFromDate } from "@/utils/date";
import { calculatePercentageChange } from "@/utils/number";
import useGetCountTotalOfIdeiasCadastradas from "./useGetCountTotalOfIdeiasCadastradas";
import useGetCountTotalOfIdeiasCadastradasBetween from "./useGetCountTotalOfIdeiasCadastradasBetween";

const CASAS_DECIMAIS = 2;

const DifferencePercentageIcon = ({ oldNumber, newNumber }: { oldNumber: number; newNumber: number }) => {
  const percentageDifferenceIdeias = calculatePercentageChange(oldNumber, newNumber);
  const color = percentageDifferenceIdeias > 0 ? "green" : percentageDifferenceIdeias < 0 ? "red" : "gray";
  const icon =
    percentageDifferenceIdeias > 0 ? <IconTrendingUp /> : percentageDifferenceIdeias < 0 ? <IconTrendingDown /> : "";

  return (
    <ThemeIcon variant="white" size="sm" color={color}>
      {icon}
    </ThemeIcon>
  );
};
const DifferencePercentageText = ({
  oldNumber,
  newNumber,
  ...tooltipProperties
}: { oldNumber: number; newNumber: number } & Omit<TooltipProps, "children">) => {
  const percentageDifferenceIdeias = calculatePercentageChange(oldNumber, newNumber);
  const color = percentageDifferenceIdeias > 0 ? "green" : percentageDifferenceIdeias < 0 ? "red" : "gray";
  const text = percentageDifferenceIdeias > 0 ? "acréscimo" : percentageDifferenceIdeias < 0 ? "recuo" : "";
  return (
    <Tooltip {...tooltipProperties}>
      <Text c={color} fz="sm">
        {percentageDifferenceIdeias === 0
          ? "Sem alteração"
          : `${text} de ${percentageDifferenceIdeias.toFixed(CASAS_DECIMAIS)}%`}
      </Text>
    </Tooltip>
  );
};

const IdeiasCadastradasDesdeCard = ({ date }: { date: Date }) => {
  //debugger;
  const { data: totalIdeias, isLoading: isLoadingCountTotalIdeias } = useGetCountTotalOfIdeiasCadastradas();

  const { data: totalIdeiasSince, isLoading: isLoadingCountTotalIdeiasSince } =
    useGetCountTotalOfIdeiasCadastradasBetween(date);

  const dateDiffParts = extractRelativeTimeParts(date);

  const lastPeriod = useMemo(() => getLastPeriodFromDate(date), [date]);
  const { data: totalIdeiasLastPeriod, isLoading: isLoadingCountTotalIdeiasLastPeriod } =
    useGetCountTotalOfIdeiasCadastradasBetween(lastPeriod, date);

  const ideiasCountLoaded =
    !isLoadingCountTotalIdeiasLastPeriod && totalIdeiasSince !== undefined && totalIdeiasLastPeriod !== undefined;

  return (
    <Card withBorder p="lg" miw={320}>
      <Text c="dimmed">Número de ideias cadastradas</Text>
      {isLoadingCountTotalIdeias && <Skeleton height={52} width={80} />}
      <Text fz={40} fw={500}>
        {totalIdeias}
      </Text>
      <Divider my="sm" />
      <Stack gap={5}>
        <Text>
          Últimos {dateDiffParts.value} {dateDiffParts.part}
        </Text>
        <Group align="center" justify="space-between">
          {isLoadingCountTotalIdeiasSince ? (
            <Skeleton height={22} width={60} />
          ) : (
            <Text fz="xl" fw={500}>
              {totalIdeiasSince}
            </Text>
          )}

          <Group gap={10} align="center">
            {ideiasCountLoaded && (
              <DifferencePercentageIcon oldNumber={totalIdeiasLastPeriod} newNumber={totalIdeiasSince} />
            )}
            {isLoadingCountTotalIdeiasLastPeriod && <Skeleton height={22} width={60} />}
            {ideiasCountLoaded && (
              <DifferencePercentageText
                label={`${totalIdeiasLastPeriod} ideias no período anterior`}
                oldNumber={totalIdeiasLastPeriod}
                newNumber={totalIdeiasSince}
              />
            )}
          </Group>
        </Group>
      </Stack>
    </Card>
  );
};

export default IdeiasCadastradasDesdeCard;
