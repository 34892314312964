import { Anchor, Box, Button, Flex, LoadingOverlay, Space } from "@mantine/core";
import { Timestamp } from "firebase/firestore";
import { NavLink } from "react-router-dom";
import BreadcrumbsNavigation from "@/components/breadcrumbs-navigation";
import CardEmpresaItem from "@/modules/diagnostico/components/cards";
import RankingList from "@/modules/diagnostico/components/ranking-list";
import useGetProjetoId from "@/modules/diagnostico/components/ranking-list/hooks/useGetProjetoId";

const isExpired = (projeto: TProjeto) => {
  const now = new Date();

  const anoAtuacao = parseDate(projeto.anoAtuacao);
  const anoVigencia = parseDate(projeto.anoVigencia);

  return new Date(anoAtuacao) > now || new Date(anoVigencia) < now;
};

const parseDate = (date: Date | Timestamp): Date => {
  const msInSecond = 1000;
  let timeInMilliseconds = 0;

  if (date instanceof Date) {
    timeInMilliseconds = date.getTime();
  } else if (date instanceof Timestamp) {
    timeInMilliseconds = date.seconds * msInSecond;
  }

  return new Date(timeInMilliseconds);
};

const DiagnosticosPage = () => {
  const { data: projeto, isFetching } = useGetProjetoId();

  if (isFetching) {
    return (
      <Box pos="relative">
        <LoadingOverlay visible={isFetching} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
      </Box>
    );
  }

  return (
    <>
      <Flex h={28} mt="lg" mb="lg" align="center" justify={"space-between"}>
        <BreadcrumbsNavigation notHome={true}>
          <Anchor component={NavLink} to="/diagnostico">
            Home
          </Anchor>
        </BreadcrumbsNavigation>
        <Anchor component={NavLink} to={`/convidarEmpresa/${projeto?.id}`}>
          <Button size="xs" color="blue" disabled={!projeto || isExpired(projeto)}>
            Convidar Empresa para o Diagnóstico
          </Button>
        </Anchor>
      </Flex>

      <CardEmpresaItem projeto={projeto} />

      <Space h="md" />

      {projeto && <RankingList projeto={projeto} />}
    </>
  );
};

export default DiagnosticosPage;
