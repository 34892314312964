import { Card, Space, Group, Text } from "@mantine/core";
import StatusComponente from "./StatusComponente";
import { Fase } from "..";

type RankingListBaseProperties = {
  projeto: TProjeto;
  empresasSelected: TEmpresa[];
  setReload: (reload: boolean) => void;
  children: React.ReactNode;
};

/**
 * RankingListBase component
 * @param root0 - properties
 * @param root0.projeto - projeto
 * @param root0.empresasSelected - empresasSelected
 * @param root0.setReload - setReload
 * @param root0.children - children
 * @returns component
 */
export function RankingListBase({ projeto, empresasSelected, setReload, children }: RankingListBaseProperties) {
  return (
    <Card withBorder shadow="md" style={{ overflow: "auto" }}>
      <Space h="md" />
      <Group justify={"space-between"} align="center">
        <Text size="xl">Ranking de empresas</Text>
        <StatusComponente selects={empresasSelected} setReload={setReload} fases={projeto.fases as Fase[]} />
      </Group>
      <Space h="md" />
      {children}
    </Card>
  );
}
