import { Card, Group, Text } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { and, or, where } from "firebase/firestore";
import { RefObject, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import AddItemButton, { TModalProperties } from "@/components/add-item-button/AddItemButton";
import { THandler } from "@/components/table/Table";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import DesafiosTable from "@/modules/desafios/components/desafios-table";
import desafioFormSchema from "@/modules/desafios/schema";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import { dataAutorizacoes } from "../empresa-page/EmpresaPage";

const modalProperties: TModalProperties = {
  modal: "desafio-form-modal"
};
// prettier-ignore
const DesafiosPage = () => {
  const tableHandlersReference = useRef<THandler<TDesafio>>(null);
  const activeEmpresaId = useUserStore((store) => store.activeEmpresa);
  const activeAutorizacao = useUserStore((store) => store.autorizacoes);
  const uid = getCurrentUser()?.uid;
  const { data: userColaborador } = useGetColaboradorById(activeEmpresaId?.id || "", uid || "");
  const autorizacao = dataAutorizacoes.find(item => item.label === userColaborador?.role);
  const formatAutorizacoes = activeAutorizacao?.filter(item => item.tipo === autorizacao?.value);
  useGlobalFilters(tableHandlersReference);

  return (
    <Card withBorder shadow="md">
      <Card.Section inheritPadding py="md" withBorder>
        <Group justify="space-between">
          <Text fw={500} fz="lg">
            Listagem de Desafios
          </Text>
          {formatAutorizacoes?.find(item => item.tipoAutorizacao === "9")?.criacao ? <AddItemButton
            size="sm"
            leftSection={<IconPlus size="1rem" />}
            modalProperties={modalProperties}
            schema={desafioFormSchema}
          >
            Adicionar Desafio
          </AddItemButton> : <></>}
        </Group>
      </Card.Section>
      <Card.Section withBorder>
        <DesafiosTable ref={tableHandlersReference} />
      </Card.Section>
    </Card>
  );
};

export default DesafiosPage;

/**
 * Set the global filter to the table
 * @param tableReference - Reference to the table handlers
 */
// prettier-ignore
function useGlobalFilters(tableReference: RefObject<THandler<TDesafio>>) {
  const [query] = useSearchParams();
  const colaboradorId = getCurrentUser()?.uid;

  useEffect(() => {
    const tableHandlers = tableReference.current;
    if (tableHandlers) {
      const publishedFilter = and(
        where("deletedAt", "==", null),
        where("published", "==", true),
      );

      const draftFilter = and(
        where("deletedAt", "==", null),
        where("createdBy", "==", colaboradorId),
        where("published", "==", false),
      );

      // Combina os filtros com 'or'
      const filters = or(draftFilter, publishedFilter/* privateAndUnpublishedFilter */);

      tableHandlers.table.setGlobalFilter(filters);
    }
  }, [query, tableReference]);
}
