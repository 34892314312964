import { TransformedValues, useForm, yupResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { UseMutationOptions, useQueryClient, useMutation } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { Asserts } from "yup";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { TGruposDiagnosticoForm, addGruposDiagnostico, updateGruposDiagnostico } from "../../firestore";
import gruposDiagnosticoSchema from "../../schema/grupos-diagnostico-schema";
import { QUERY_KEY } from "../grupos-diagnostico-table/GruposDiagnosticoTable";

type FormInputs = Asserts<typeof gruposDiagnosticoSchema>;
type TUpdateResponse = Awaited<ReturnType<typeof updateGruposDiagnostico>>;
type TAddResponse = Awaited<ReturnType<typeof addGruposDiagnostico>>;

/**
 * Grupo Form hook
 * @param grupo - Grupo data
 * @param root0 - Options
 * @param root0.onSuccess - On success callback
 * @param root0.onError - On error callback
 * @returns - Grupo Form
 */
function useGrupoForm(
  grupo: TGruposDiagnosticoForm & Partial<Pick<TGruposDiagnosticoForm, "id" | "refPath">>,
  {
    onSuccess,
    onError
  }: Pick<UseMutationOptions<TUpdateResponse | TAddResponse, FirestoreError, FormInputs>, "onSuccess" | "onError">
) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const queryClient = useQueryClient();
  const form = useForm<FormInputs>({
    validate: yupResolver(gruposDiagnosticoSchema),
    transformValues: (values) => gruposDiagnosticoSchema.cast(values),
    initialValues: grupo
  });

  const { mutate, isPending, reset } = useMutation<TUpdateResponse | TAddResponse, FirestoreError, FormInputs>({
    mutationFn: (values) => {
      if (!activeEmpresaId) {
        throw new Error("Nenhuma empresa selecionada");
      }
      return grupo.id
        ? updateGruposDiagnostico(activeEmpresaId, grupo.id, values)
        : addGruposDiagnostico(activeEmpresaId, values);
    },
    async onSuccess(data, variables, context) {
      showNotification({
        message: `${variables.nome} ${grupo.id ? "atualizado" : "cadastrado"} com sucesso!`,
        color: "green",
        autoClose: 2500
      });
      reset();
      form.reset();
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      if (onSuccess) {
        return onSuccess(data, variables, context);
      }
      return null;
    },
    onError(error, variables, context) {
      captureException(error, true);
      if (onError) {
        onError(error, variables, context);
      }
    }
  });

  type TFormTransformed = TransformedValues<typeof form>;

  /**
   * Handle form submit
   * @param values - Form values
   */
  function handleFormSubmit(values: TFormTransformed) {
    mutate(values);
  }

  return { form, isPending, onSubmit: form.onSubmit(handleFormSubmit) };
}

export default useGrupoForm;
