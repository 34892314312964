import { ThemeIcon, ThemeIconProps } from "@mantine/core";
import { IconCaretUpFilled, IconCaretDownFilled, IconEqual } from "@tabler/icons-react";

const RankingTrendIconLikes = ({
  ideia,
  ...themeIconProperties
}: { ideia: TIdeia | undefined } & Omit<ThemeIconProps, "color">) => {
  if (!ideia) {
    return null;
  }
  if (ideia.rankingByLikes?.fromLastUpdate === "NOT_CHANGED") {
    if (ideia.rankingByMoedas?.fromLastUpdate === "INCREASED") {
      return (
        <ThemeIcon variant="transparent" color="green" size={14} {...themeIconProperties}>
          <IconCaretUpFilled />
        </ThemeIcon>
      );
    }

    if (ideia.rankingByMoedas?.fromLastUpdate === "DECREASED") {
      return (
        <ThemeIcon variant="transparent" color="red" size={14} {...themeIconProperties}>
          <IconCaretDownFilled />
        </ThemeIcon>
      );
    }
  }

  if (ideia.rankingByLikes?.fromLastUpdate === "INCREASED") {
    return (
      <ThemeIcon variant="transparent" color="green" size={14} {...themeIconProperties}>
        <IconCaretUpFilled />
      </ThemeIcon>
    );
  }

  if (ideia.rankingByLikes?.fromLastUpdate === "DECREASED") {
    return (
      <ThemeIcon variant="transparent" color="red" size={14} {...themeIconProperties}>
        <IconCaretDownFilled />
      </ThemeIcon>
    );
  }

  return (
    <ThemeIcon variant="transparent" color="blue" size={14} {...themeIconProperties}>
      <IconEqual />
    </ThemeIcon>
  );
};

export default RankingTrendIconLikes;
