import { Card, Flex, Group, Skeleton, Text, ThemeIcon, Title } from "@mantine/core";
import { IconCoin } from "@tabler/icons-react";
import useCatchFirestoreError from "@/hooks/useCatchFirestoreError";
import DesafioPatrocinioForm from "@/modules/desafios-activities-per-colaborador/components/desafio-patrocinio-form";
import useGetTotalMoedasPerRegulamento from "@/modules/desafios-activities-per-colaborador/components/desafio-patrocinio-form/useGetTotalMoedasPerRegulamento";
import useGetDesafioActivityByColaborador from "@/modules/desafios-activities-per-colaborador/hooks/useGetDesafioActivityByColaborador";
import useGetRegulamentoFromDesafios from "@/modules/regulamento-desafios/hooks/useGetRegulamentoFromDesafios";
import useUserStore from "@/modules/users/store";

const DesafioPatrocinioCard = ({ desafio }: { desafio: TDesafio }) => {
  const colaborador = useUserStore((state) => state.colaborador);
  const {
    data: desafioActivity,
    isLoading: isLoadingActivities,
    error: activitiesError
  } = useGetDesafioActivityByColaborador(desafio, colaborador?.id);
  useCatchFirestoreError(activitiesError);

  const { data: regulamento, error: regulamentoError } = useGetRegulamentoFromDesafios(desafio);
  useCatchFirestoreError(regulamentoError);

  const { data: totalMoedasGastas, error: totalMoedasGastasError } = useGetTotalMoedasPerRegulamento(regulamento);
  useCatchFirestoreError(totalMoedasGastasError);

  const totalMoedasPeloRegulamento = regulamento?.quantidadeMoedasPorColaborador || 0;
  const totalMoedasDisponiveis = totalMoedasPeloRegulamento - (totalMoedasGastas || 0);

  return (
    <Card withBorder>
      <Flex direction="column" pb="xs">
        <Title order={4}>Quanto você deseja investir?</Title>
        <Group align="center" gap={2} c="gray">
          <Text size="xs">Você tem:</Text>
          <ThemeIcon variant="light" color="yellow" size="xs">
            <IconCoin />
          </ThemeIcon>
          <Skeleton visible={isLoadingActivities} width={150} height={16}>
            <Text c="gray" size="xs" tt="lowercase">
              {`${totalMoedasDisponiveis} moedas`}
            </Text>
          </Skeleton>
        </Group>
      </Flex>
      {regulamento && (
        <DesafioPatrocinioForm desafio={desafio} desafioActivity={desafioActivity} regulamento={regulamento} />
      )}
    </Card>
  );
};

export default DesafioPatrocinioCard;
