import { Button } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconPlayerPlay } from "@tabler/icons-react";

/**
 * Open modal context
 */
function handleIniciarClick() {
  modals.openContextModal({
    modal: "iniciar-comite-inovacao-modal",
    size: "xl",
    innerProps: {}
  });
}

const IniciarComiteInovacaoButton = () => {
  return (
    <Button color="green" size="md" leftSection={<IconPlayerPlay />} radius="lg" onClick={handleIniciarClick}>
      Iniciar
    </Button>
  );
};

export default IniciarComiteInovacaoButton;
