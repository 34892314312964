import { Button, Card, Group, Text } from "@mantine/core";
import { useDisclosure, useForceUpdate } from "@mantine/hooks";
import { IconFilter, IconPlus } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";
import { useRef } from "react";
import AddItemButton from "@/components/add-item-button";
import RefreshQueryButton from "@/components/refresh-query-button";
import { THandler } from "@/components/table/Table";
import FilterList from "@/components/table/TableFiltersList";
import ColaboradoresTable from "@/modules/colaboradores/components/colaboradores-table";
import { QUERY_KEY } from "@/modules/colaboradores/components/colaboradores-table/ColaboradoresTable";
import InviteColaboradorButton from "@/modules/colaboradores/components/invite-colaborador-button";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import colaboradorSchema from "@/modules/colaboradores/schema/colaborador-schema";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import TableFilters from "@/src/routes/colaboradores-page/TableFilters";
import { dataAutorizacoes } from "../empresa-page/EmpresaPage";

const REFRESH_ALL_KEY = [QUERY_KEY];
// prettier-ignore
const ColaboradoresPage = () => {
  const tableHandlersReference = useRef<THandler<TColaborador>>(null);
  const [opened, { close, toggle }] = useDisclosure(false);
  const queryClient = useQueryClient();
  const forceUpdate = useForceUpdate();
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);
  const activeAutorizacao = useUserStore((store) => store.autorizacoes);
  const uid = getCurrentUser()?.uid;
  const { data: userColaborador } = useGetColaboradorById(activeEmpresaId || "", uid || "");

  const autorizacao = dataAutorizacoes.find(item => item.label === userColaborador?.role);
  const formatAutorizacoes = activeAutorizacao?.filter(item => item.tipo === autorizacao?.value);
  /**
   * Handle filter applied
   */
  function handleFilterApplied() {
    close();
    void queryClient.invalidateQueries({ queryKey: REFRESH_ALL_KEY }).then(() => forceUpdate());
  }

  return (
    <Card
      withBorder
      shadow="md"
      styles={{
        section: {
          overflow: "auto"
        }
      }}
    >
      <Card.Section px="md" py="xs" withBorder>
        <Group justify="space-between">
          <Text fw={500} fz="lg">
            Listagem de Colaboradores
          </Text>
          {formatAutorizacoes?.find(item => item.tipoAutorizacao === "2")?.criacao ? <Group>
            <AddItemButton
              size="sm"
              leftSection={<IconPlus size="1rem" />}
              modalProperties={{ modal: "colaborador-form-modal" }}
              schema={colaboradorSchema}
            >
              Adicionar Colaborador
            </AddItemButton>
            <InviteColaboradorButton variant="outline" size="sm" color="dark">
              Convidar
            </InviteColaboradorButton>
          </Group> : <></>}
        </Group>
      </Card.Section>
      <Card.Section inheritPadding py="md" withBorder>
        <Group justify="space-between">
          <Group align="center">
            <Button variant="subtle" size="compact-md" onClick={toggle} leftSection={<IconFilter size="1rem" />}>
              Filtros
            </Button>
            {tableHandlersReference.current && <FilterList filters={tableHandlersReference.current.filters} />}
          </Group>
          <RefreshQueryButton queryKey={REFRESH_ALL_KEY} />
        </Group>

        {tableHandlersReference.current && (
          <TableFilters
            table={tableHandlersReference.current.table}
            filters={tableHandlersReference.current.filters}
            onClose={close}
            onFilterApplied={handleFilterApplied}
            opened={opened}
          />
        )}
      </Card.Section>

      <ColaboradoresTable ref={tableHandlersReference} />
    </Card>
  );
};

ColaboradoresPage.displayName = "ColaboradoresPage";

export default ColaboradoresPage;
