import { Text, MantineColor, Grid, Stack, Badge } from "@mantine/core";

const StatusQuantityItem = ({ text, total, color }: { text: string; total: number; color: MantineColor }) => (
  <Grid.Col span="content">
    <Stack gap={2}>
      <Badge color={color} size="sm" radius="sm">
        {text}
      </Badge>
      <Text fz="lg" fw={500}>
        {total}
      </Text>
    </Stack>
  </Grid.Col>
);

export default StatusQuantityItem;
