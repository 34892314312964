import { Stack } from "@mantine/core";
import { IconFileDescription, IconFileSymlink, IconHome2, IconUserPlus } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import getStatusIdEmpresa from "@/modules/diagnostico/components/ranking-item/useGetStatusIdEmpresa";
import useQueryConvite from "@/modules/diagnosticos/components/diagnostico-list/useQueryConvite";
import useQueryQuestionarioConvite from "@/modules/responder-questionario-list/components/questionario-list/useQueryQuestionarioConvite";
import useUserStore from "@/modules/users/store";
import NavLinkMenu from "./NavLinkMenu";

const STATUS_EVIDENCIA = "8";

const AppDiagnosticoMenuConvidado = () => {
  const activeEmpresa = useUserStore((store) => store.activeEmpresa);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("");

  const { data: convite } = useQueryConvite();
  const { data: questionariosConvite } = useQueryQuestionarioConvite({ data: convite && convite[0] });

  useEffect(() => {
    if (questionariosConvite && questionariosConvite[0] && convite && convite[0]) {
      const status = getStatusIdEmpresa({
        idEmpresaItem: convite[0]?.idEmpresaColaboradorConvidado || "",
        id: convite[0]?.idProjeto || "",
        activeEmpresaId: convite[0]?.idEmpresa || ""
      });
      void status.then((item) => {
        setLoading(false);
        setStatus(item[0]?.status || "");
        return null;
      });
    }
  }, [questionariosConvite]);

  if (activeEmpresa && activeEmpresa?.status === "INATIVO") {
    return <></>;
  }

  return (
    <Stack mx="md" mt="lg" gap="xs">
      <NavLinkMenu
        label="Início"
        leftSection={<IconHome2 size="1.2rem" stroke={1.5} />}
        component={NavLink}
        to="/diagnosticoEmpresa"
      />
      <NavLinkMenu
        label="Responder questionário"
        leftSection={<IconFileSymlink size="1.2rem" stroke={1.5} />}
        component={NavLink}
        to="/listaResponderQuestionario/0"
      />
      {!loading && status === STATUS_EVIDENCIA && (
        <NavLinkMenu
          label="Cadastrar Evidências"
          leftSection={<IconFileDescription size="1.2rem" stroke={1.5} />}
          component={NavLink}
          to="/cadastrarEvidencias"
        />
      )}
      <NavLinkMenu
        label="Convidar Colaboradores"
        leftSection={<IconUserPlus size="1.2rem" stroke={1.5} />}
        component={NavLink}
        to="/convidarColaboradoresExternos"
      />
    </Stack>
  );
};

export default AppDiagnosticoMenuConvidado;
