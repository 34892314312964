import { serverTimestamp } from "firebase/firestore";
import { ObjectSchema } from "yup";
import schema from "@/schema";
import { getCurrentUser } from "@/services/auth";
import { TJogadaInovacaoForm, TJogadaInovacaoFormFields, TTJogadaDeInovacao } from "../firestore";

const MIN_NAME_LENGTH = 3;
/**
 * Define only the meta fields that are not part of the form
 */
const jogadaDeInovacaoSchemaDatabaseFields: ObjectSchema<TTJogadaDeInovacao> = schema.object().shape({
  createdAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .required()
    .defined()
    .nonNullable()
    .default<ReturnType<typeof serverTimestamp>>(() => serverTimestamp()),
  createdBy: schema
    .string()
    .required()
    .defined()
    .nonNullable()
    .default(() => {
      const currentUserId = getCurrentUser()?.uid;
      if (!currentUserId) {
        throw new Error("The current user is not defined");
      }
      return currentUserId;
    }),
  updatedAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .default<ReturnType<typeof serverTimestamp>>(() => serverTimestamp()),
  updatedBy: schema.string().default(() => {
    const currentUserId = getCurrentUser()?.uid;
    if (!currentUserId) {
      throw new Error("The current user is not defined");
    }
    return currentUserId;
  }),
  startedAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .required()
    .defined()
    .nonNullable()
    .default<ReturnType<typeof serverTimestamp>>(() => serverTimestamp()),
  pausedAt: schema.mixed<ReturnType<typeof serverTimestamp>>().defined().nullable().default(null),
  endedAt: schema.mixed<ReturnType<typeof serverTimestamp>>().defined().nullable().default(null),
  deletedAt: schema.mixed<ReturnType<typeof serverTimestamp>>().defined().nullable().default(null)
});

const jogadaDeInovacaoSchemaFormFields: ObjectSchema<TJogadaInovacaoFormFields> = schema.object({
  id: schema.string().optional(),
  refPath: schema.string().optional(),
  implementada: schema.boolean().optional(),
  concluido: schema.boolean().optional(),
  cancelado: schema.boolean().optional(),
  ambicao: schema.string().optional().label("Nível de ambição"),
  segmento: schema.string().optional().label("Segmento que se aplica"),
  sugerida: schema.boolean().optional().label("Criar como jogada sugerida?"),
  nome: schema.string().min(MIN_NAME_LENGTH).required().defined().label("Nome"),
  descricao: schema.string().min(MIN_NAME_LENGTH).required().defined().label("Descrição"),
  nomeTipoDeJogada: schema.string().optional(),
  tipoDeJogada: schema.string().min(MIN_NAME_LENGTH).required().defined().label("Categoria"),
  taticas: schema.array().of(schema.object().optional().defined()).default([]).optional().label("Taticas").optional()
  // nomeTipo: schema.string().optional(),
  // tipo: schema.string().min(MIN_NAME_LENGTH).required().defined().label("Tipo")
});

const jogadaDeInovacaoSchema: ObjectSchema<TJogadaInovacaoForm> = jogadaDeInovacaoSchemaFormFields
  // eslint-disable-next-line unicorn/prefer-spread
  .concat(jogadaDeInovacaoSchemaDatabaseFields)
  .noUnknown()
  .strict()
  .label("jogadas de Inovação");

export default jogadaDeInovacaoSchema;
