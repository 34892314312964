import { useMutation } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { addAutorizacao, TAutorizacaoForm, updateAutorizacao } from "../../firestore";

type TAutorizacaoResponse = Awaited<ReturnType<typeof addAutorizacao>>;
type TUpdateAutorizacaoResponse = Awaited<ReturnType<typeof updateAutorizacao>>;
/**
 * Finalizar resposta de inovação mutation hook
 * @returns Finalizar resposta de inovação mutation
 */
// prettier-ignore
export function useSendAutorizacao() {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  return useMutation<TAutorizacao | TUpdateAutorizacaoResponse | TAutorizacaoResponse, FirestoreError, TAutorizacaoForm>({
    mutationFn(item) {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!item) {
        throw new Error("Empresa não selecionada");
      }
      return item?.id ? updateAutorizacao(activeEmpresaId, item.id, item) : addAutorizacao(activeEmpresaId, item);
    },
  });
}
