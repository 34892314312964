import { Avatar, Flex, Group, Radio, Space, Text, Tooltip } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconInfoCircle } from "@tabler/icons-react";
import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { ArrayProperties, Campo, TRespostaField } from "../../types";
import { isCampo } from "../../util";

const AJUSTE = 10;
const DOIS = 2;
const MOBILE_MARGIN = 10;

type Properties = {
  item: ArrayProperties;
  disabled: boolean;
  tipo: TTiposDeRespostas;
  onChange: (value: TRespostaField, item: ArrayProperties) => void;
  horizontal: boolean;
  licenciado: TLicenciado | null | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
const RadioComponent = ({ item, disabled, tipo, onChange, horizontal, licenciado }: Properties) => {
  const isSmMarches = useMediaQuery("(max-width: 48em)");
  const containerReference = useRef<HTMLDivElement>(null);
  const [spaceInfo, setSpaceInfo] = useState({ width: 0, height: 0 });
  const handleChangeRadio = useCallback(
    (item: ArrayProperties, value: Campo) => () => {
      onChange(value, item);
    },
    [onChange, item]
  );
  const getMaxText = useCallback(
    (width: number) => {
      if (tipo && tipo.campos && Array.isArray(tipo.campos)) {
        if (isSmMarches) {
          return MOBILE_MARGIN;
        }
        let qtdePixeis = 0;
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        if (context) {
          context.font = "12px Arial";
          const metrics = context.measureText("A");
          qtdePixeis = metrics.width;
        } else {
          const minimo = 8;
          qtdePixeis = minimo;
        }
        const multiplier = qtdePixeis;
        const maior = Math.max(...tipo.campos.map((campo: Campo) => campo.campo.length)) * multiplier;
        const calculado = (width - maior) / DOIS - AJUSTE;
        if (calculado < 0) {
          return 0;
        }
        return (width - maior) / DOIS - AJUSTE;
      }
      return 0;
    },
    [tipo]
  );

  const selectedField = isCampo(item.resposta) ? item.resposta.campo : null;
  useLayoutEffect(() => {
    if (containerReference.current) {
      const { clientWidth, clientHeight } = containerReference.current;
      setSpaceInfo({ width: clientWidth, height: clientHeight });
    }
  }, []);
  return (
    <Flex justify={"start"} align="center" direction="column" w={"100%"} style={{ overflow: "auto" }}>
      {item.obrigatoria ? (
        <>
          <Tooltip label="Está pergunta é obrigatória">
            <IconInfoCircle style={{ cursor: "pointer" }} />
          </Tooltip>
          <Space h="md" />
        </>
      ) : (
        <></>
      )}
      {licenciado && licenciado.logo && licenciado?.logo?.length > 0 ? (
        <>
          <Group>
            {licenciado.logo.map((item) => (
              <>
                <Avatar src={item?.path} radius="sm" size="lg" variant="default" />
                <Space h="md" />
              </>
            ))}
          </Group>
          <Space h="md" />
        </>
      ) : (
        <></>
      )}
      <Text fw={700} size="lg">
        {item?.nome}
      </Text>
      <Space h="md" />
      <Text size="md">{item?.descricao}</Text>
      <Space h="md" />
      <Flex
        ref={containerReference}
        align="start"
        gap="xs"
        direction={horizontal ? "row" : "column"}
        pr={10}
        style={{ overflow: "auto" }}
        h={isSmMarches ? "100%" : "55vh"}
        w={"100%"}
      >
        {tipo && tipo.campos && Array.isArray(tipo.campos) ? (
          tipo.campos.map((value: Campo) => (
            <React.Fragment key={value.valor}>
              <Radio
                ml={getMaxText(spaceInfo.width)}
                style={{ alignSelf: "flex-start" }}
                label={value.campo}
                onChange={handleChangeRadio(item, value)}
                disabled={disabled}
                checked={selectedField === value.campo}
              />
              {/* <Space h="xs" /> */}
            </React.Fragment>
          ))
        ) : (
          <></>
        )}
      </Flex>
      <Space h="md" />
    </Flex>
  );
};

export default RadioComponent;
