import { Text, Button } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconTrash } from "@tabler/icons-react";
import { UseMutationOptions, useMutation, useQueryClient, QueryObserverResult } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { DESAFIO_CRONOGRAMA_COLLECTION_KEY, removeCronograma } from "@/modules/desafio-cronograma/firestore";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";

type RemoveCronogramaResponse = Awaited<ReturnType<typeof removeCronograma>>;
type RemoveCronogramaDesafioMutationOptions = Pick<
  UseMutationOptions<RemoveCronogramaResponse, FirestoreError>,
  "onSuccess" | "onError"
>;
type RemoverImersaoButtonProperties = {
  cronogramaId: TCronogramaDesafio["id"];
  refetch: () => Promise<QueryObserverResult>;
};

/**
 *
 * @param cronogramaId id do cronogama
 * @param options options
 * @returns mutation
 */
function useRemoveCronogramaDesafioMutation(
  cronogramaId: TCronogramaDesafio["id"],
  options: RemoveCronogramaDesafioMutationOptions
) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  return useMutation<RemoveCronogramaResponse, FirestoreError>({
    mutationFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!cronogramaId) {
        throw new Error("Campanha inválida");
      }

      return removeCronograma(activeEmpresaId, cronogramaId);
    },
    ...options
  });
}

const RemoveImersaoButton = ({ cronogramaId, refetch }: RemoverImersaoButtonProperties) => {
  const queryClient = useQueryClient();
  const { mutate } = useRemoveCronogramaDesafioMutation(cronogramaId, {
    onSuccess() {
      void queryClient.invalidateQueries({
        queryKey: [DESAFIO_CRONOGRAMA_COLLECTION_KEY, "getCronogramaDesafio", { cronogramaId: cronogramaId }]
      });
      void refetch();
    },
    onError(error) {
      captureException(error, true);
    }
  });
  /**
   *
   */
  function handleRemoveClick() {
    modals.openConfirmModal({
      title: `Remover imersão ?`,
      centered: true,
      children: (
        <Text size="sm">Ao confirmar a imersão todos seus eventos serão removidos e não poderão ser recuperados.</Text>
      ),
      labels: { confirm: "Remover", cancel: "Cancelar" },
      confirmProps: { color: "red" },
      onConfirm: () => confirmFunction()
    });
  }

  const confirmFunction = () => {
    if (cronogramaId) {
      mutate();
    }
  };

  return (
    <Button variant="transparent" bg="transparent" onClick={handleRemoveClick}>
      <IconTrash size="1.2rem" />
    </Button>
  );
};

RemoveImersaoButton.displayName = "RemoveImersaoButton";

export default RemoveImersaoButton;
