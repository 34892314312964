import { subject } from "@casl/ability";
import {
  DocumentReference,
  FirestoreDataConverter,
  addDoc,
  collection,
  doc,
  getCountFromServer,
  getDocs,
  orderBy,
  query,
  where
} from "firebase/firestore";
import { firestore } from "@/base";
import { generateQuery } from "@/utils/firestore";
import { TColaboradorDocument } from "../colaboradores/firestore";
import { getIdeiaDocumentReference } from "../ideias/firestore";
import ideiaSchema from "../ideias/schema/ideia-schema";

export type TIdeiaComentarioFormFields = Omit<
  TIdeiaComentario,
  keyof TAudit | "respostaAoComentarioReference" | "colaboradoresMencionadosReferences"
> & {
  respostaAoComentarioReference: DocumentReference<TIdeiaComentario> | null;
  colaboradoresMencionadosReferences: DocumentReference<TColaborador>[] | string[];
};

export type TIdeiaComentarioForm = TIdeiaComentarioFormFields & TAuditForm;

type TIdeiaComentarioDocument = Omit<TIdeiaComentarioFormFields, "autor" | "respostaComentarioReferencePath"> &
  TAuditDocument & {
    respostaAoComentarioReference: DocumentReference<TIdeiaComentario> | null;
    colaboradoresMencionadosReferences: DocumentReference<TColaborador>[];
    autor: Omit<TIdeiaComentarioFormFields["autor"], "refPath"> & {
      refPath: DocumentReference<TColaborador, TColaboradorDocument>;
    };
  };

const ideiaComentarioConverter: FirestoreDataConverter<TIdeiaComentario> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const {
      createdAt,
      updatedAt,
      autor,
      respostaAoComentarioReference,
      colaboradoresMencionadosReferences,
      ...document
    } = snap.data() as TIdeiaComentarioDocument;

    const { refPath, ...autorData } = autor;
    const ideiaBase = ideiaSchema.omit(["createdAt", "updatedAt"]).getDefault() as Omit<
      TIdeiaComentarioForm,
      "createdAt" | "updatedAt"
    >;
    const data: TIdeiaComentario = {
      ...ideiaBase,
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      refPath: snap.ref.path,
      respostaAoComentarioReference: respostaAoComentarioReference?.path || null,
      colaboradoresMencionadosReferences: colaboradoresMencionadosReferences
        ? colaboradoresMencionadosReferences.map((reference) => reference.path)
        : [],
      autor: {
        ...autorData,
        refPath: refPath.path
      }
    };

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }
    return subject("TIdeiaComentario", data);
  }
};

/**
 * Get ideias collection reference from empresaId
 * @param empresaId - Empresa id to get the ideias collection reference
 * @param ideiaId - Ideia id to get the ideias collection reference
 * @returns - Ideias collection reference
 */
function getIdeiaComentariosCollectionReference(empresaId: TEmpresa["id"], ideiaId: TIdeia["id"]) {
  const ideiaDocumentReference = getIdeiaDocumentReference(empresaId, ideiaId);
  return collection(ideiaDocumentReference, "comentarios");
}

/**
 * Get ideia comentario document reference
 * @param empresaId - Empresa id to get the empresa document reference
 * @param ideiaId - Ideia id to get the ideia document reference reference
 * @param ideiaComentarioId - Comentario id to get the comentário document reference reference
 * @returns - ideia comentário document reference
 */
function getIdeiaComentarioDocumentReference(
  empresaId: TEmpresa["id"],
  ideiaId: TIdeia["id"],
  ideiaComentarioId: TIdeiaComentario["id"]
) {
  const ideiaCollectionReference = getIdeiaComentariosCollectionReference(empresaId, ideiaId);
  return doc(ideiaCollectionReference, ideiaComentarioId);
}

/**
 * Get ideia comentario document reference from path
 * @param path - Path to get the ideia comentario document reference
 * @returns - Ideia comentario document reference
 */
export function getIdeiaComentarioDocumentReferenceFromPath(path: string) {
  return doc(firestore, path).withConverter(ideiaComentarioConverter);
}

/**
 * Get count total of comentarios from ideia
 * @param empresaId - Empresa id to get the ideias collection reference
 * @param ideiaId - Ideia id to get the ideias collection reference
 * @returns - Ideias collection reference
 */
export function getCountIdeiaComentarios(empresaId: TEmpresa["id"], ideiaId: TIdeia["id"]) {
  const ideiaComentariosCollectionReference = getIdeiaComentariosCollectionReference(empresaId, ideiaId);
  return getCountFromServer(ideiaComentariosCollectionReference);
}

/**
 * Add comentário
 * @param empresaId - The empresa ID
 * @param ideiaId - the ideia ID to add comentário
 * @param comentario - The comentário to add
 * @returns - Comentário document
 */
export function addComentario(empresaId: TEmpresa["id"], ideiaId: TIdeia["id"], comentario: TIdeiaComentarioForm) {
  const ideiaComentariosCollectionReference = getIdeiaComentariosCollectionReference(empresaId, ideiaId);
  return addDoc(ideiaComentariosCollectionReference, comentario);
}

/**
 * Get comentário documents
 * @param empresaId - The empresa ID
 * @param ideiaId - the ideia ID to add comentário
 * @param root0 - Pagination options
 * @param root0.sorting - Sorting options
 * @param root0.pagination - Pagination options
 * @param root0.paginationCursors - Array of pagination cursors
 * @param root0.filters - Array of filters options
 * @returns - The comentários documents
 */
export function getComentarios(
  empresaId: TEmpresa["id"],
  ideiaId: TIdeia["id"],
  { sorting, pagination, filters, paginationCursors }: TPaginatedQueryOptions<TIdeiaComentario>
) {
  const ideiaComentariosCollectionReference = getIdeiaComentariosCollectionReference(empresaId, ideiaId).withConverter(
    ideiaComentarioConverter
  );

  const q = generateQuery(ideiaComentariosCollectionReference, { sorting, filters, pagination, paginationCursors });
  return getDocs(q);
}

/**
 * Get todos as respostas ao comentário
 * @param empresaId - The empresa ID
 * @param ideiaId - Ideia ID
 * @param comentarioId - The comentário ID to get the respostas
 * @returns - The respostas ao comentário
 */
export function getRespostasAoComentario(
  empresaId: TEmpresa["id"],
  ideiaId: TIdeia["id"],
  comentarioId: TIdeiaComentario["id"]
) {
  const ideiaComentariosCollectionReference = getIdeiaComentariosCollectionReference(empresaId, ideiaId).withConverter(
    ideiaComentarioConverter
  );
  const ideiaComentarioDocumentReference = getIdeiaComentarioDocumentReference(empresaId, ideiaId, comentarioId);

  const q = query(
    ideiaComentariosCollectionReference,
    where("respostaAoComentarioReference", "==", ideiaComentarioDocumentReference),
    orderBy("createdAt", "desc")
  );
  return getDocs(q);
}
