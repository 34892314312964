import {
  onAuthStateChanged,
  onIdTokenChanged,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  updatePassword
} from "firebase/auth";
import { auth } from "@/base";

// eslint-disable-next-line no-magic-numbers
type NextOrObserverAuthChanged = Parameters<typeof onAuthStateChanged>[1];
// eslint-disable-next-line no-magic-numbers
type ErrorOrObserverAuthChanged = Parameters<typeof onAuthStateChanged>[2];
// eslint-disable-next-line no-magic-numbers
type CompleteOrObserverAuthChanged = Parameters<typeof onAuthStateChanged>[3];

/**
 * Get authenticated firebase user
 * @param resolve - The resolve function
 * @param [reject] - The reject function
 * @param [complete] - The complete function
 * @returns - The unsubscribe function
 */
export function watchCurrentUser(
  resolve: NextOrObserverAuthChanged,
  reject?: ErrorOrObserverAuthChanged,
  complete?: CompleteOrObserverAuthChanged
) {
  return onAuthStateChanged(auth, resolve, reject, complete);
}

// eslint-disable-next-line no-magic-numbers
type NextOrObserverTokenChanged = Parameters<typeof onIdTokenChanged>[1];
// eslint-disable-next-line no-magic-numbers
type ErrorOrObserverTokenChanged = Parameters<typeof onIdTokenChanged>[2];
// eslint-disable-next-line no-magic-numbers
type CompleteOrObserverTokenChanged = Parameters<typeof onIdTokenChanged>[3];
/**
 * Get authenticated firebase user
 * @param resolve - The resolve function
 * @param [reject] - The reject function
 * @param [complete] - The complete function
 * @returns - The unsubscribe function
 */
export function watchIdToken(
  resolve: NextOrObserverTokenChanged,
  reject?: ErrorOrObserverTokenChanged,
  complete?: CompleteOrObserverTokenChanged
) {
  return onIdTokenChanged(auth, resolve, reject, complete);
}

/**
 * Get authenticated firebase user
 * @returns - The user
 */
export function getCurrentUser() {
  return auth.currentUser;
}

// let currentUserClaims: TUserClaims | undefined;
// watchIdToken(
//   (user: User | null) => {
//     currentUserClaims = undefined;
//     if (user) {
//       user
//         .getIdTokenResult()
//         .then((idTokenResult) => {
//           const claims = idTokenResult.claims as TUserClaims;
//           currentUserClaims = claims;
//         })
//         .catch((error: Error) => captureException(error));
//     }
//   },
//   (error) => captureException(error)
// );

/**
 * Sign in with email and password
 * @param email - The email to send the login link to
 * @param password - The password to sign in with
 * @returns - The promise that resolves when sign in is complete
 */
export function signInWith(email: string, password: string) {
  return signInWithEmailAndPassword(auth, email, password);
}

/**
 * Sign in with custom token
 * @param token - The token to sign in with
 * @returns - The promise that resolves when sign in is complete
 */
export function signInUserWithCustomToken(token: string) {
  return signInWithCustomToken(auth, token);
}

/**
 * Update the current user password
 * @param newPassword - The new password
 * @returns - The promise that resolves when the password is updated
 */
export function updateUserPassword(newPassword: string) {
  if (!auth.currentUser) {
    throw new Error("The current user is not defined");
  }

  return updatePassword(auth.currentUser, newPassword);
}

/**
 * Sign out the current user
 * @returns - The promise that resolves when the user is signed out
 */
export async function signOut(): Promise<void> {
  return auth.signOut();
}
