import { Text, FocusTrap } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import { QueryObserverResult } from "@tanstack/react-query";
import JogadasDeInovacaoListModal from "../../components/home-de-inovacao-list-modal";

// prettier-ignore
const AddMovimentacaoModal = ({
  context,
  innerProps,
  id
}: ContextModalProps<{ refetch: () => Promise<QueryObserverResult>; }>) => {
  const { refetch } = innerProps;
  /**
   * Handle add ignore area success
   */
  function onSuccess() {
    context.closeModal(id);
  }
  return (
    <FocusTrap active={true}>
      <Text fw="bold" mb="md">
        Jogadas de inovação
      </Text>
      <JogadasDeInovacaoListModal onSuccess={onSuccess} refetchList={refetch} />
    </FocusTrap>
  );
};

export default AddMovimentacaoModal;
