import { UnstyledButton, UnstyledButtonProps } from "@mantine/core";
import { hideNotification, showNotification } from "@mantine/notifications";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FirebaseError } from "firebase/app";
import { ForwardedRef, PropsWithChildren, forwardRef, useId } from "react";
import { disableUser } from "@/modules/users/functions";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { QUERY_KEY } from "../colaboradores-table/ColaboradoresTable";

type DisableColaboradorButtonProperties = PropsWithChildren<UnstyledButtonProps> & { colaborador: TColaborador };
type TDisableUserResponse = Awaited<ReturnType<typeof disableUser>>;

/**
 * Disable a colaborador.
 * @param colaborador - The colaborador to be disabled.
 * @returns The mutation.
 */
function useDisableUserMutation(colaborador: TColaborador) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const queryClient = useQueryClient();
  const notificationId = useId();
  return useMutation<TDisableUserResponse, FirebaseError>({
    mutationFn() {
      hideNotification(notificationId);
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      showNotification({
        title: "Desabilitando colaborador...",
        message: "Aguarde enquanto desabilitamos o colaborador",
        autoClose: false,
        id: notificationId,
        loading: true
      });

      return disableUser({ empresaId: activeEmpresaId, userEmail: colaborador.email, colaboradorId: colaborador.id });
    },
    onSuccess() {
      return queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    },
    onError(error) {
      captureException(error, true);
    },
    onSettled() {
      hideNotification(notificationId);
    }
  });
}

/**
 * A button that disable a colaborador.
 * @param properties - The properties of the button.
 * @param properties.children - The children of the button.
 * @param properties.colaborador - The colaborador to be disabled.
 * @param reference - The reference to the button.
 * @returns The element to be rendered.
 */
function DisableColaboradorButton(
  { children, colaborador, ...properties }: DisableColaboradorButtonProperties,
  reference: ForwardedRef<HTMLButtonElement>
) {
  const { mutate } = useDisableUserMutation(colaborador);
  /**
   *
   */
  function handleClick() {
    mutate();
  }

  return (
    <UnstyledButton ref={reference} {...properties} onClick={handleClick}>
      {children}
    </UnstyledButton>
  );
}

const DisableColaboradorButtonReference = forwardRef<HTMLButtonElement, DisableColaboradorButtonProperties>(
  DisableColaboradorButton
);

DisableColaboradorButtonReference.displayName = "DisableColaboradorButton";

export default DisableColaboradorButtonReference;
