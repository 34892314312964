import JogadasDeInovacaoHeader from "@/modules/jogadas-de-inovacao/components/jogadas-de-inovacao-header";

const JogadasDeInovacaoPage = () => {
  return (
    <>
      <JogadasDeInovacaoHeader />
    </>
  );
};

export default JogadasDeInovacaoPage;
