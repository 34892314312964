import { subject } from "@casl/ability";
import {
  DocumentReference,
  FirestoreDataConverter,
  addDoc,
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  where
} from "firebase/firestore";
import { generateQuery } from "@/utils/firestore";
import { TColaboradorDocument } from "../colaboradores/firestore";
import { getDesafioDocumentReference } from "../desafios/firestore";

export const DESAFIO_COMENTARIOS_COLLECTION_KEY = "comentarios" as const;

export type TDesafioComentarioForm = TFormWithTransformations<
  Omit<TDesafioComentario, "colaboradoresMencionadosReferences">,
  "respostaAoComentarioReference"
> & {
  colaboradoresMencionadosReferences: DocumentReference<TColaborador>[] | null | string[];
};

type TDesafioComentarioDocument = TFirestoreDocument<
  Omit<TDesafioComentario, "colaboradoresMencionadosReferences" | "autor">,
  "respostaAoComentarioReference"
> & {
  colaboradoresMencionadosReferences: DocumentReference<TColaborador>[] | null;
  autor: Omit<TDesafioComentario["autor"], "refPath"> & {
    refPath: DocumentReference<TColaborador, TColaboradorDocument>;
  };
};

export const desafioComentarioConverter: FirestoreDataConverter<TDesafioComentario> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const {
      createdAt,
      updatedAt,
      autor,
      respostaAoComentarioReference,
      colaboradoresMencionadosReferences,
      ...document
    } = snap.data() as TDesafioComentarioDocument;

    const { refPath, ...autorData } = autor;
    const data: TDesafioComentario = {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      refPath: snap.ref.path,
      respostaAoComentarioReference: respostaAoComentarioReference?.path || null,
      colaboradoresMencionadosReferences: colaboradoresMencionadosReferences
        ? colaboradoresMencionadosReferences.map((reference) => reference.path)
        : [],
      autor: {
        ...autorData,
        refPath: refPath.path
      }
    };

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }
    return subject("TDesafioComentario", data);
  }
};

/**
 * Get desafio comentarios collection reference
 * @param empresaId - Empresa id where the desafio is located
 * @param desafioId - Desafio id to get the comentarios collection reference
 * @returns - Desafio comentarios collection reference
 */
function getDesafioComentariosCollectionReference(empresaId: TEmpresa["id"], desafioId: TDesafio["id"]) {
  const desafioDocumentReference = getDesafioDocumentReference(empresaId, desafioId);
  return collection(desafioDocumentReference, DESAFIO_COMENTARIOS_COLLECTION_KEY);
}

/**
 * Get desafio comentario document reference
 * @param empresaId - Empresa id to get the empresa document reference
 * @param desafioId - Desafio id to get the desafio document reference reference
 * @param comentarioId - Comentario id to get the comentário document reference reference
 * @returns - desafio comentário document reference
 */
function getDesafioComentarioDocumentReference(
  empresaId: TEmpresa["id"],
  desafioId: TDesafio["id"],
  comentarioId: TDesafioComentario["id"]
) {
  const collectionReference = getDesafioComentariosCollectionReference(empresaId, desafioId);
  return doc(collectionReference, comentarioId);
}

/**
 * Get comentário documents
 * @param empresaId - The empresa ID
 * @param desafioId - the desafio ID to add comentário
 * @param root0 - Pagination options
 * @param root0.sorting - Sorting options
 * @param root0.pagination - Pagination options
 * @param root0.paginationCursors - Array of pagination cursors
 * @param root0.filters - Array of filters options
 * @returns - The comentários documents
 */
export function getComentarios(
  empresaId: TEmpresa["id"],
  desafioId: TDesafio["id"],
  { sorting, pagination, filters, paginationCursors }: TPaginatedQueryOptions<TDesafioComentario>
) {
  const desafioComentariosCollectionReference = getDesafioComentariosCollectionReference(
    empresaId,
    desafioId
  ).withConverter(desafioComentarioConverter);

  const q = generateQuery(desafioComentariosCollectionReference, { sorting, filters, pagination, paginationCursors });
  return getDocs(q);
}

/**
 * Get Quantidade comentário documents
 * @param empresaId - The empresa ID
 * @param desafioId - the desafio ID to add comentário
 * @returns - The comentários documents
 */
// function getQuantidadeComentariosPorDesafio(empresaId: TEmpresa["id"], desafioId: TDesafio["id"]) {
//   const desafioComentariosCollectionReference = getDesafioComentariosCollectionReference(
//     empresaId,
//     desafioId
//   ).withConverter(desafioComentarioConverter);

//   const q = generateQuery(desafioComentariosCollectionReference, {});
//   return getDocs(q).then((querySnapshot) => querySnapshot.size);
// }

/**
 * Add comentário
 * @param empresaId - The empresa ID
 * @param desafioId - the desafio ID to add comentário
 * @param comentario - The comentário to add
 * @returns - Comentário document
 */
export function addComentario(
  empresaId: TEmpresa["id"],
  desafioId: TDesafio["id"],
  comentario: TDesafioComentarioForm
) {
  const comentariosCollectionReference = getDesafioComentariosCollectionReference(empresaId, desafioId);
  return addDoc(comentariosCollectionReference, comentario);
}

/**
 * Get todos as respostas ao comentário
 * @param empresaId - The empresa ID
 * @param desafioId - Desafio ID
 * @param comentarioId - The comentário ID to get the respostas
 * @returns - The respostas ao comentário
 */
export function getRespostasAoComentario(
  empresaId: TEmpresa["id"],
  desafioId: TDesafio["id"],
  comentarioId: TDesafioComentario["id"]
) {
  const comentariosCollectionReference = getDesafioComentariosCollectionReference(empresaId, desafioId).withConverter(
    desafioComentarioConverter
  );
  const comentarioDocumentReference = getDesafioComentarioDocumentReference(empresaId, desafioId, comentarioId);

  const q = query(
    comentariosCollectionReference,
    where("respostaAoComentarioReference", "==", comentarioDocumentReference),
    orderBy("createdAt", "desc")
  );
  return getDocs(q);
}
