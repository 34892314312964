import { subject } from "@casl/ability";
import { Group } from "@mantine/core";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import ContextMenuRow from "./ContextMenuRow";

const columnHelper = createColumnHelper<TCategoriaDeInovacao>();

const columns: ColumnDef<TCategoriaDeInovacao>[] = [
  columnHelper.accessor("nome", {
    header: "Nome",
    enableSorting: true,
    enableColumnFilter: true
  }),
  columnHelper.accessor("descricao", {
    header: "Descrição",
    enableSorting: true,
    enableColumnFilter: true
  }),

  columnHelper.display({
    id: "actions",
    cell: (properties) => {
      const categorias = subject("TCategoriaDeInovacao", properties.row.original);

      return (
        <Group justify="center" gap={2}>
          <ContextMenuRow categorias={categorias} />
        </Group>
      );
    }
  })
];

export default columns;
