import { Card, Flex, Space, Text } from "@mantine/core";
import ConvidarEmpresaFormFields from "../convidar-empresa-form-fields/ConvidarEmpresaFormFields";

const ConvidarEmpresaForm = ({ projeto }: { projeto: TProjeto | null | undefined }) => {
  if (!projeto) {
    return (
      <Card withBorder shadow="md">
        <Text size={"xl"}>Convite para Processo de Inovação</Text>
        <Space h={"md"} />
        <Text size="lg" fw={700}>
          Projeto não encontrado
        </Text>
      </Card>
    );
  }

  return (
    <Card withBorder shadow="md">
      <Text size={"xl"}>Convite para Processo de Inovação</Text>
      <Space h={"md"} />
      <Flex direction="column">
        <Text size="sm" fw={500}>
          Projeto de inovação
        </Text>
        <Space h={"sm"} />
        <Text size="lg" fw={700}>
          {projeto?.nome}
        </Text>
      </Flex>
      <Space h={"md"} />

      <ConvidarEmpresaFormFields projeto={projeto} />
    </Card>
  );
};

export default ConvidarEmpresaForm;
