import { FileWithPath } from "@mantine/dropzone";
import { serverTimestamp } from "firebase/firestore";
import { ObjectSchema } from "yup";
import schema from "@/schema";
import { getCurrentUser } from "@/services/auth";
import { TColaboradorDatabaseFields, TColaboradorForm, TColaboradorFormFields } from "../firestore";

const MIN_NAME_LENGTH = 3;

/**
 * Define only the meta fields that are not part of the form
 */
const colaboradorSchemaDatabaseFields: ObjectSchema<TColaboradorDatabaseFields> = schema.object().shape({
  createdAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .required()
    .defined()
    .nonNullable()
    .default<ReturnType<typeof serverTimestamp>>(() => serverTimestamp()),
  createdBy: schema
    .string()
    .required()
    .defined()
    .nonNullable()
    .default(() => {
      const currentUserId = getCurrentUser()?.uid;
      if (!currentUserId) {
        throw new Error("The current user is not defined");
      }
      return currentUserId;
    }),
  updatedAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .default<ReturnType<typeof serverTimestamp>>(() => serverTimestamp()),
  updatedBy: schema.string().default(() => {
    const currentUserId = getCurrentUser()?.uid;
    if (!currentUserId) {
      throw new Error("The current user is not defined");
    }
    return currentUserId;
  }),
  deletedAt: schema.mixed<ReturnType<typeof serverTimestamp>>().defined().nullable().default(null),
  inviteSentAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .optional()
    .nullable()
    .default(null)
    .label("Data de envio do convite"),
  inviteAcceptedAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .optional()
    .nullable()
    .default(null)
    .label("Data de aceite do convite")
});

const ONE_SECOND_IN_MS = 1000;
// eslint-disable-next-line no-magic-numbers
const ONE_MINUTE_IN_MS = ONE_SECOND_IN_MS * 60;
// eslint-disable-next-line no-magic-numbers
const ONE_HOUR_IN_MS = ONE_MINUTE_IN_MS * 60;
// eslint-disable-next-line no-magic-numbers
const ONE_DAY_IN_MS = ONE_HOUR_IN_MS * 24;
// eslint-disable-next-line no-magic-numbers
const ONE_HUNDRED_TWENTY_YEARS_IN_MS = ONE_DAY_IN_MS * 365 * 120;
export const MIN_AGE = ONE_HUNDRED_TWENTY_YEARS_IN_MS;
// eslint-disable-next-line no-magic-numbers
export const MAX_AGE = ONE_DAY_IN_MS * 365 * 16;
// prettier-ignore
const colaboradorSchemaFormFields: ObjectSchema<TColaboradorFormFields> = schema.object({
  id: schema.string().optional(),
  refPath: schema.string().optional(),
  gestao: schema.boolean().optional().default(true).defined().label("Gestão de Inovação"),
  diagnostico: schema.boolean().optional().default(true).defined().label("Diagnóstico"),
  incentivo: schema.boolean().optional().default(true).defined().label("Incentivo a Inovação"),
  configuracoes: schema.boolean().optional().default(true).defined().label("Configurações"),
  nome: schema.string().min(MIN_NAME_LENGTH).required().defined().label("Nome"),
  email: schema.string().trim().email().required().defined().label("E-mail"),
  telefone: schema.string().trim().optional().label("Telefone").default(''),
  image: schema.array().of(schema.mixed<FileWithPath>().required().defined()).required().defined().default([]),
  dataNascimento: schema
    .date()
    .min(new Date(Date.now() - MIN_AGE))
    .max(new Date(Date.now() - MAX_AGE))
    .optional()
    .label("Data de nascimento"),
  disabledAt: schema.date().optional().nullable().default(null).label("Desabilitado em"),
  setores: schema.array().of(schema.string().required().defined()).meta({label: "Setores"}).required().defined().default([]).min(1) .label("Setores"),
  role: schema
    .mixed<"ADMIN" | "MEMBER" | "TEAM" | "MASTER" | "COLLABORATOR">()
    .oneOf(["ADMIN", "MEMBER", "TEAM", "MASTER", "COLLABORATOR"])
    .default("MEMBER")
    .required()
    .defined()
    .label("Perfil"),
  status: schema
    .mixed<"ACTIVE" | "PENDING" | "INACTIVE" | "DISABLED">()
    .default("PENDING")
});

const colaboradorSchema: ObjectSchema<TColaboradorForm> = colaboradorSchemaFormFields
  // eslint-disable-next-line unicorn/prefer-spread
  .concat(colaboradorSchemaDatabaseFields)
  .noUnknown()
  .strict()
  .label("Colaborador");

export default colaboradorSchema;
