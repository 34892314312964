import { Menu, ActionIcon } from "@mantine/core";
import { IconDotsVertical, IconEdit, IconTrash } from "@tabler/icons-react";
import EditItemButton from "@/components/edit-item-button";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import empresaSchema from "../../schema/empresa-schema";
import RemoveEmpresaButton from "../remove-empresa-button";
// prettier-ignore
const ContextMenuRow = ({ empresa }: { empresa: TEmpresa }) => {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);
  const activeAutorizacao = useUserStore((store) => store.autorizacoes);
  const uid = getCurrentUser()?.uid;
  const { data: userColaborador } = useGetColaboradorById(activeEmpresaId || "", uid || "");

  const autorizacao = data?.find(item => item.label === userColaborador?.role);
  const formatAutorizacoes = activeAutorizacao?.filter(item => item.tipo === autorizacao?.value);

  if (!formatAutorizacoes?.find(item => item.tipoAutorizacao === "1")?.editar && !formatAutorizacoes?.find(item => item.tipoAutorizacao === "1")?.remover) {
    return <></>
  }

  return <Menu shadow="md" width={200} position="left" withArrow arrowPosition="center">
    <Menu.Target>
      <ActionIcon variant="transparent" aria-label="Menu" color="gray">
        <IconDotsVertical />
      </ActionIcon>
    </Menu.Target>
    <Menu.Dropdown>
      {formatAutorizacoes?.find(item => item.tipoAutorizacao === "1")?.editar ? <Menu.Item
        leftSection={<IconEdit size={14} />}
        component={EditItemButton}
        modal="empresa-form-modal"
        schema={empresaSchema}
        item={empresa}
      >
        Editar
      </Menu.Item> : <></>}
      {formatAutorizacoes?.find(item => item.tipoAutorizacao === "1")?.remover ? <>
        <Menu.Divider />
        <Menu.Item color="red" leftSection={<IconTrash size={14} />} component={RemoveEmpresaButton} empresa={empresa}>
          Remover
        </Menu.Item>
      </> : <></>}

    </Menu.Dropdown>
  </Menu>
};

export default ContextMenuRow;

const data = [
  { value: "1", label: "ADMIN" },
  { value: "2", label: "MEMBER" },
  { value: "3", label: "TEAM" },
  { value: "4", label: "MASTER" },
  { value: "5", label: "COLLABORATOR" }
];
