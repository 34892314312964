import { DocumentReference } from "firebase/firestore";
import { TColaboradorDocument, getColaboradorDocumentReferenceFromPath } from "@/modules/colaboradores/firestore";
import schema from "@/schema";

const filterSchema = schema.object().shape({
  colaboradoresReferences: schema
    .array()
    .of(schema.mixed<DocumentReference<TColaborador, TColaboradorDocument>>().required().defined())
    .required()
    .defined()
    .default([])
    .transform((value: (string | DocumentReference<TColaborador, TColaboradorDocument>)[]) =>
      value.map((v) => {
        const isString = typeof v === "string";
        return isString ? getColaboradorDocumentReferenceFromPath(v) : v;
      })
    )
    .label("Colaboradores"),

  datePrazoRange: schema.mixed<[Date | null, Date | null]>().nullable().default([null, null]).label("Prazo")
});

export default filterSchema;
