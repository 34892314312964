import { Alert, Card, Center, Stack, Text } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { QueryKey } from "@tanstack/react-query";
import { Table as ReactTable } from "@tanstack/react-table";
import { forwardRef, RefObject, useImperativeHandle } from "react";
import NoData from "@/components/no-data";
import Table from "@/components/table";
import ResetTableButton from "@/components/table/ResetTableButton";
import { THandler } from "@/components/table/Table";
import TablePagination from "@/components/table/TablePagination";
import useQueryTable from "@/components/table/useQueryTable";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import empresaColumns from "./Columns";
import { DEFAULT_PAGINATION, DEFAULT_SORTING, getPaginatedEmpresas } from "../../firestore";

export const QUERY_KEY: string = "empresas-list" as const;

interface EmpresasTableProperties {
  empresaRole: string;
  ref: RefObject<THandler<TEmpresa>>;
}

const EmpresasTable = forwardRef<THandler<TEmpresa>, EmpresasTableProperties>(({ empresaRole }, reference) => {
  const uid = getCurrentUser()?.uid;
  const empresa = useUserStore((state) => state.activeEmpresa);
  const { data: colaborador } = useGetColaboradorById(empresa?.id || "", uid || "");

  const { table, data, isLoading, error, filters, refetch } = useQueryTable<TEmpresa>({
    queryKey: [QUERY_KEY, colaborador?.id],
    enabled: !!uid,
    subjectType: "TEmpresa",
    columns: empresaColumns,
    initialState: {
      columnFilters: [],
      globalFilter: "",
      pagination: DEFAULT_PAGINATION,
      sorting: DEFAULT_SORTING
    },
    queryFunction: ({ sorting, filters, pagination, paginationCursors }) => {
      if (!uid) {
        throw new Error("Usuário não autenticado");
      }
      const newFilter = filters?.concat([{ id: "role", value: ["==", empresaRole] }]);
      return getPaginatedEmpresas(uid, colaborador?.role, {
        sorting,
        filters: newFilter,
        pagination,
        paginationCursors
      });
    }
  });

  useImperativeHandle<THandler<TEmpresa>, THandler<TEmpresa>>(
    reference,
    () => {
      return {
        table,
        data,
        isLoading,
        error,
        filters
      };
    },
    [filters, data, error, isLoading, table, colaborador]
  );

  /**
   * Handles the close of the error alert.
   */
  function handleErrorClose() {
    // queryClient.removeQueries({ queryKey: QUERY_KEY });
    // remove();
    void refetch();
  }

  if (error) {
    return (
      <Alert
        icon={<IconAlertCircle size="1rem" />}
        title="Erro ao buscar empresas"
        color="red"
        withCloseButton
        onClose={handleErrorClose}
      >
        {error.message}
      </Alert>
    );
  }

  const isEmpty = !isLoading && data?.entries.length === 0;

  return (
    <>
      <Card.Section withBorder>
        <Table table={table} isLoading={isLoading} />
        {isEmpty && !isLoading && (
          <Center m="lg">
            <NoData m="md">
              <Stack gap="xs">
                <Text c="dimmed">Nenhuma Empresa Cadastrada!</Text>
                {filters.length > 0 && (
                  <ResetTableButton table={table as ReactTable<unknown>} queryKey={QUERY_KEY as unknown as QueryKey}>
                    Limpar filtros
                  </ResetTableButton>
                )}
              </Stack>
            </NoData>
          </Center>
        )}
      </Card.Section>
      {reference && "current" in reference && reference.current !== null ? (
        <Card.Section withBorder>
          <TablePagination tableHandlers={reference.current} />
        </Card.Section>
      ) : null}
    </>
  );
});

EmpresasTable.displayName = "EmpresasTable";

export default EmpresasTable;
