// import "./wdyr";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import AppProviders from "./AppProviders";
import Routes from "./Routes";
import "@mantine/core/styles/global.css";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/spotlight/styles.css";
import "./main.module.css";

const appVersion = import.meta.env.VITE_APP_VERSION;
const projectId = import.meta.env.VITE_PROJECT_ID;
// eslint-disable-next-line no-console
console.log(
  `${window.location.hostname} \n VERSION  ${appVersion}  ${projectId}\n  -> Are you trying to understand us? Why not work with us? developers@adi.io`
);

const root = document.querySelector("#root");
const renderApp = () =>
  root &&
  ReactDOM.createRoot(root).render(
    <React.StrictMode>
      <AppProviders>
        <Routes />
      </AppProviders>
    </React.StrictMode>
  );

// eslint-disable-next-line compat/compat
requestAnimationFrame(renderApp);
