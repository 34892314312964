import { Group, Loader, Stack } from "@mantine/core";
import NoData from "@/components/no-data/NoData";
import RankingDeColaboradorCard from "./RankingDeColaboradorCard";
import useGetRankingAnoPerColaborador from "./useGetRankingAnoPerColaborador";
// prettier-ignore
const RankingDeColaboradoresList = ({ year }: { year: number }) => {
  const { data: rankingPerColaborador, isLoading } = useGetRankingAnoPerColaborador(year);

  if (isLoading) {
    return <Group justify={"center"}>
      <Loader color="blue" />
    </Group>;
  }

  if (!rankingPerColaborador || rankingPerColaborador.length === 0) {
    return <NoData title="Nenhuma movimentação encontrada!">Tente selecionar outro ano</NoData>;
  }

  return (
    <Stack gap="xs">
      {rankingPerColaborador.map((ranking) => (
        <RankingDeColaboradorCard key={ranking.id} year={year} ranking={ranking} />
      ))}
    </Stack>
  );
};

export default RankingDeColaboradoresList;
