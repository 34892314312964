import { Card, Flex, Space, Text } from "@mantine/core";

// prettier-ignore
const SobreIncentivoInovacaoPage = () => {
  return (
    <Card p={30}>
      <Flex direction="column" align={"center"}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <iframe title="Documento sobre incentivo à inovação" src="https://drive.google.com/file/d/1RM4cIUMyJGQb0P52elrvzkOIRqnhwdS-/preview" width="640" height="480" allow="autoplay"></iframe>
        </div>
        <Space h="xl" />
        <div style={{ display: "flex", flexDirection: "column", }}>
          <Text fw={500}>Sobre</Text>
          <Space h="xl" />
          <Text size="sm" c="dimmed">O módulo de incentivo à inovação é baseado no livro &quot;Dez Tipos de Inovação&quot;, escrito por Larry Keeley, Ryan Pikkel, Brian Quinn e Helen Walters (DVS Editora, 2015).</Text>
          <Space h="xl" />
          <Text size="sm" c="dimmed">Este livro se tornou uma referência essencial para compreender os diversos caminhos que as empresas podem seguir para inovar de forma eficaz.</Text>
          <Space h="xl" />
          <Text size="sm" c="dimmed">Exploramos cada um dos tipos de inovação apresentados, desde inovações dentro do modelo de negócios até aquelas relacionadas aos produtos e serviços. Nosso objetivo é ajudar você a entender como sua empresa pode se destacar no mercado por meio da inovação estratégica.</Text>
        </div>
        <Space h="xl" />
        {/* <div style={{ display: "flex", justifyContent: "center" }}>
          <video height="400" controls style={{ borderRadius: 10, background: "#000" }} >
            <source src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4" />
            <track kind="captions" src="path/to/captions.vtt" label="English" />
          </video>
        </div> */}
      </Flex>
      <Space h="xl" />
    </Card>
  );
};

export default SobreIncentivoInovacaoPage;
