import { subject } from "@casl/ability";
import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getJogadaDeInovacaoItem, QUERY_KEY } from "@/modules/jogadas-de-inovacao/firestore";
import useUserStore from "@/modules/users/store";

type TGetJogadaDeInovacaoResponse = Awaited<ReturnType<typeof getJogadaDeInovacaoItem>>;

/**
 * Get ideia from url params
 * @param jogadaDeInovacaoId - jogada id
 * @returns - Ideia or null if not found
 */
function useGetJogadaDeInovacaoFromUrl(jogadaDeInovacaoId: string) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  return useQuery<TGetJogadaDeInovacaoResponse, FirestoreError, TJogadaDeInovacao | null>({
    queryKey: [QUERY_KEY, jogadaDeInovacaoId, activeEmpresaId],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!jogadaDeInovacaoId) {
        throw new Error("Ideia não selecionada");
      }

      return getJogadaDeInovacaoItem(activeEmpresaId, jogadaDeInovacaoId);
    },
    select(dataResponse) {
      if (!dataResponse.exists()) {
        return null;
      }

      return subject("TJogadaDeInovacao", dataResponse.data());
    },
    enabled: !!jogadaDeInovacaoId && !!activeEmpresaId
  });
}

export default useGetJogadaDeInovacaoFromUrl;
