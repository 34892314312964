import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { QUERY_KEY } from "@/modules/categorias-de-inovacao/firestore";
import { getTipoDeJogadaItem } from "@/modules/tipo-de-jogada/firestore";
import useUserStore from "@/modules/users/store";

type TGetTipoDeInovacaoResponse = Awaited<ReturnType<typeof getTipoDeJogadaItem>>;

/**
 * Get ideia from url params
 * @param categoriaDeInovacaoId - categoria id
 * @returns - Ideia or null if not found
 */
function useGetJogadaItem(categoriaDeInovacaoId: string) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  return useQuery<TGetTipoDeInovacaoResponse, FirestoreError, TCategoriaDeInovacao | null>({
    queryKey: [QUERY_KEY, categoriaDeInovacaoId, activeEmpresaId],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!categoriaDeInovacaoId) {
        throw new Error("Ideia não selecionada");
      }

      return getTipoDeJogadaItem(activeEmpresaId, categoriaDeInovacaoId);
    },
    select(dataResponse) {
      if (!dataResponse.exists()) {
        return null;
      }

      return dataResponse.data();
    },
    enabled: !!categoriaDeInovacaoId && !!activeEmpresaId
  });
}

export default useGetJogadaItem;
