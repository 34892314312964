import { useForm, yupResolver } from "@mantine/form";
import { useMutation } from "@tanstack/react-query";
import { AuthError } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Asserts } from "yup";
import { signInWith } from "@/services/auth";
import { captureException } from "@/services/log";
import loginFormSchema from "./login-form-schema";

type LoginFormInputs = Asserts<typeof loginFormSchema>;
type SignInWithResponse = Awaited<ReturnType<typeof signInWith>>;

/**
 * Custom hook to handle login form
 * @returns - The login form hook
 */
function useLoginForm() {
  const navigate = useNavigate();

  const form = useForm<LoginFormInputs>({
    validate: yupResolver(loginFormSchema),
    initialValues: {
      email: "",
      password: ""
    },
    transformValues: (values) => loginFormSchema.cast(values)
  });

  const { mutate: login, isPending } = useMutation<SignInWithResponse, AuthError, LoginFormInputs>({
    mutationFn: (loginData) => signInWith(loginData.email, loginData.password),
    onSuccess() {
      navigate("/", {
        replace: true
      });
    },
    onError(error: AuthError) {
      captureException(error, true);
    }
  });

  /**
   * Handle login form submit
   * @param data - The login form data
   * @returns - The login promise
   */
  function handleSubmit(data: LoginFormInputs) {
    return login(data);
  }

  return { ...form, isPending, onSubmit: form.onSubmit(handleSubmit) };
}

export default useLoginForm;
