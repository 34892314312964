import { httpsCallable } from "firebase/functions";
import { functions } from "@/base";

type InviteEmpresaInputs = {
  email: string;
  nome: string;
  projetoId: string;
  licenciadaId?: string | undefined;
  empresaId: string;
  idEmpresa: string;
  cnpj: string;
};

export const inviteEmpresa = httpsCallable<InviteEmpresaInputs, never>(functions, "projetosFunctions-inviteEmpresa");
