import { Box, LoadingOverlay } from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import useGetConvites from "@/components/app-container/useGetConvites";
import { getLicenciados } from "@/modules/projetos/firestore";
import Questionario from "@/modules/responder-questionario/components/questionario";
import QuestionarioV2 from "@/modules/responder-questionario/components/v2/Questionario";
import useGetQuestionarioConviteFromUrl from "@/modules/responder-questionario/hooks/useGetQuestionarioConviteFromUrl";
import { getCurrentUser } from "@/services/auth";
import useGetProjetoItem from "./useGetProjetoNew";

const message = "";

type ResponderQuestionarioPageParameters = {
  idEmpresa: string;
  colaboradorId?: string;
};

const ResponderQuestionarioPage = () => {
  const { idEmpresa, colaboradorId } = useParams<ResponderQuestionarioPageParameters>();

  const userId = useMemo(() => {
    return colaboradorId ?? getCurrentUser()?.uid ?? "";
  }, [colaboradorId]);

  const { data: empresaConvidada } = useGetConvites(userId);

  const [licenciado, setLicenciado] = useState<TLicenciado | null>(null);

  const { data: data, isFetching } = useGetQuestionarioConviteFromUrl({ activeEmpresaId: idEmpresa });

  const { data: projeto } = useGetProjetoItem({
    idProjeto: data?.idProjeto,
    empresaConvidada: empresaConvidada && empresaConvidada?.length > 0 ? true : false
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadLicenciados();
      } catch (error) {
        console.error(message, error);
      }
    };

    void fetchData();
  }, [projeto?.licenciado]);

  const loadLicenciados = async () => {
    const arrayLicenciados = await getLicenciados(idEmpresa || "");
    const aux = arrayLicenciados.docs.map((desafio) => desafio.data());
    setLicenciado(aux.find((item) => item?.id === projeto?.licenciado) as TLicenciado);
  };

  if (isFetching) {
    return (
      <Box pos="relative">
        <LoadingOverlay visible={isFetching} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
      </Box>
    );
  }

  if (!idEmpresa) {
    return <Box>Empresa não encontrada</Box>;
  }

  if (data) {
    return (
      <QuestionarioV2
        licenciado={licenciado ?? undefined}
        questionario={data}
        empresaId={idEmpresa}
        colaboradorId={userId ?? ""}
      />
    );
  }

  return <Questionario data={data} licenciado={licenciado as TLicenciado} />;
};

export default ResponderQuestionarioPage;
