import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { DESAFIOS_COLLECTION_KEY, getDesafiosSortedByRanking } from "@/modules/desafios/firestore";
import useUserStore from "@/modules/users/store";

type TGetDesafiosSortedByRanking = Awaited<ReturnType<typeof getDesafiosSortedByRanking>>;

/**
 * Hook para obter as ideias mais votadas da empresa
 * @returns - Top ideias mais votadas da empresa
 */
function useGetTopDesafiosMaisVotadas() {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  return useQuery<TGetDesafiosSortedByRanking, FirestoreError, TDesafio[]>({
    queryKey: [activeEmpresaId, DESAFIOS_COLLECTION_KEY, "getDesafiosSortedByRanking"],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return getDesafiosSortedByRanking(activeEmpresaId);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((desafio) => desafio.data());
    }
  });
}
export default useGetTopDesafiosMaisVotadas;
