import { Card, Flex, Group, Loader, Text } from "@mantine/core";
import { useState } from "react";
import FiltrosHistorico from "./FiltrosHistorico";
import ItemHistorico from "./ItemHistorico";
import { FilterMovimentacao } from "../../firestore";
import useGetHistoricoMovimentacoes from "../../hooks/useGetHistoricoMovimentacoes";
// prettier-ignore
const HistoricoMovimentacaoCard = ({ ideia }: { ideia: TIdeia }) => {
  const [filters, setFilters] = useState<FilterMovimentacao[]>([]);
  const { data: movimentacoes, isPending } = useGetHistoricoMovimentacoes(ideia, filters);

  return (
    <Card withBorder my="md" mb="xl">
      <Flex direction="column" gap="sm">
        <Group>
          <Text fw="bold" size="lg">
            Histórico de Movimentações
          </Text>
        </Group>

        <FiltrosHistorico updateFilter={setFilters} />
        {isPending && (
          <Flex align="center" justify="center">
            <Loader size={30} />
          </Flex>
        )}
        {movimentacoes && movimentacoes.length > 0 ? movimentacoes?.map((movimentacao) => (
          <ItemHistorico key={movimentacao.id} movimentacao={movimentacao} ideia={ideia} />
        )) : <Group justify={"center"}>
          <Text fw={"bold"}>Nenhuma movimentação encontrada!</Text>
        </Group>}
      </Flex>
    </Card>
  );
};

export default HistoricoMovimentacaoCard;
