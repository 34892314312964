import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { getConvitesProjeto } from "../firestore";

type TgetConvitesProjetoResponse = Awaited<ReturnType<typeof getConvitesProjeto>>;

const QUERY_KEY = "convites-questionario";

/**
 * Get ideia from url params
 * @param projetoId - Projeto id
 * @param empresaProjetoId - Empresa do projeto id
 * @param empresaColaboradorId - Empresa do colaborador id
 * @returns - Ideia or null if not found
 */
function useGetConvitesProjeto(
  projetoId: TProjeto["id"],
  empresaProjetoId: TEmpresa["id"],
  empresaColaboradorId: TEmpresa["id"]
) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  return useQuery<TgetConvitesProjetoResponse, FirestoreError, TConvite[]>({
    queryKey: [QUERY_KEY, projetoId, empresaProjetoId, empresaColaboradorId, activeEmpresaId],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return getConvitesProjeto(projetoId, empresaProjetoId, empresaColaboradorId);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((document_) => document_.data()).filter((c) => !c.cancelado);
    },
    enabled: !!projetoId && !!empresaProjetoId && !!empresaColaboradorId && !!activeEmpresaId
  });
}

export default useGetConvitesProjeto;
