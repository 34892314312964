import { Button, Card, Flex, Group, Select, Text, Textarea } from "@mantine/core";
import { IDEIA_STATUS } from "@/modules/ideias/schema/ideia-schema";
import { AddMarcosButton } from "../../ideia-marcos/components/AddMarcosButton";
import MarcosList from "../../ideia-marcos/components/MarcosList";
import useIdeiaMovimentacaoForm from "../hooks/useIdeiaMovimentacaoForm";
import { gerarDadosSelect } from "../utils/gerar-dados-select";

const statusSelectData = gerarDadosSelect(IDEIA_STATUS);

/**
 * Get status label
 * @param status - Status
 * @returns - Label
 */
function getStatusLabel(status: TIdeiaStatus | null): string {
  const statusLabel = statusSelectData.find((data) => data.value === status)?.label;
  return statusLabel ? `de ${statusLabel}` : "";
}

const MovimentacaoIdeiasCard = ({ ideia }: { ideia: TIdeia }) => {
  const { onSubmit, getInputProps, isPending, values, setFieldValue } = useIdeiaMovimentacaoForm(ideia);

  /**
   * Add path to movimentacao card
   * @param path - Path to add
   */
  function addPathToMovimentacaoCard(path: string) {
    const hasPath = values.marcos.includes(path);
    if (!hasPath) {
      setFieldValue("marcos", [...values.marcos, path]);
    }
  }

  /**
   * Remove path from movimentacao card
   * @param marcoPath - Path to remove
   */
  function removeMarcoPath(marcoPath: string) {
    setFieldValue(
      "marcos",
      values.marcos.filter((marco) => marco !== marcoPath)
    );
  }

  const boardStatus = getStatusLabel(values?.status as TIdeiaStatus);
  const statusWithoutSelected = statusSelectData.filter((data) => data.value !== ideia.status);

  return (
    <form onSubmit={onSubmit}>
      <Card withBorder my="md" mb="xl">
        <Flex direction="column" gap="sm">
          <Group justify="space-between">
            <Text fw="bold" size="lg">
              Movimentação da Ideia
            </Text>
            <AddMarcosButton ideia={ideia} addPathToMovimentacaoCard={addPathToMovimentacaoCard} />
          </Group>
          <Select
            label="Selecione o status"
            placeholder="Selecione o status"
            data={statusWithoutSelected}
            {...getInputProps("status")}
          />
          <Textarea
            label="Adicione uma observação sobre esse status"
            placeholder="Observações"
            minRows={3}
            autosize
            {...getInputProps("observacoes")}
          />
          <MarcosList
            title={`Marcos ${boardStatus}`}
            marcos={values.marcos}
            ideia={ideia}
            allowEdit={true}
            onMarcoRemoved={removeMarcoPath}
          />
          <Flex align="center" justify="flex-end">
            <Button type="submit" loading={isPending}>
              Finalizar análise
            </Button>
          </Flex>
        </Flex>
      </Card>
    </form>
  );
};

export default MovimentacaoIdeiasCard;
