import { Space } from "@mantine/core";
import CardColaborador from "@/modules/diagnosticos/components/cards/CardColaborador";
import QuestionarioDiagnosticoList from "@/modules/responder-questionario-list/components/questionario-list";
// prettier-ignore
const ResponderQuestionarioListPage = () => {
  return <>
    <CardColaborador />
    <Space h={"md"} />
    <QuestionarioDiagnosticoList />
  </>
};

export default ResponderQuestionarioListPage;
