import { Anchor, Card, Text, Stepper, Space, Box, LoadingOverlay } from "@mantine/core";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import BreadcrumbsNavigation from "@/components/breadcrumbs-navigation";
import AddTaticaDeInovacao from "@/modules/tipos-de-inovacao/components/add-tatica-de-inovacao";
import AddTipoDeInovacao from "@/modules/tipos-de-inovacao/components/add-tipo-de-inovacao";
import useQueryCategoriaDeInovacao from "@/modules/tipos-de-inovacao/components/tipo-de-inovacao-list/useGetCategoriasDeInovacao";
import useGetTipoDeInovacaoFromUrl from "@/modules/tipos-de-inovacao/components/tipo-de-inovacao-list/useGetTipoDeInovacaoFromUrl";

const TipoDeInovacaoPage = () => {
  const [active, setActive] = useState(0);
  const { data, isFetching, isLoading, refetch } = useGetTipoDeInovacaoFromUrl();
  const { data: categorias, isFetching: isFetchingCategoria } = useQueryCategoriaDeInovacao();
  const categoriasArray = categorias || [];

  if (isFetching || isFetchingCategoria || isLoading) {
    return (
      <Box pos="relative">
        <LoadingOverlay visible={isFetching} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
      </Box>
    );
  }

  return (
    <>
      <BreadcrumbsNavigation notHome>
        <Anchor component={NavLink} to="/tipos-de-inovacao">
          Tipos de Inovação
        </Anchor>
      </BreadcrumbsNavigation>
      <Card withBorder padding="xl">
        <Text size="xl" fw={500}>
          {data?.id ? "Editando" : "Novo"} tipo de Inovação
        </Text>
        <Space h="md" />
        <Card.Section inheritPadding py="xl">
          <Stepper active={active}>
            <Stepper.Step label="Tipo de inovação" description="Crie o tipo de inovação">
              <AddTipoDeInovacao categorias={categoriasArray} setActive={setActive} data={data} refetch={refetch} />
            </Stepper.Step>
            <Stepper.Step label="Táticas de inovação" description="Crie as tática de inovação">
              <AddTaticaDeInovacao data={data} categorias={categoriasArray} />
            </Stepper.Step>
          </Stepper>
        </Card.Section>
      </Card>
    </>
  );
};

export default TipoDeInovacaoPage;
