import { Text, Group, Button, Avatar } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconThumbUp } from "@tabler/icons-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import useGetLikes from "./hooks/useGetLikes";
import useIdeiaLike from "./hooks/useIdeiaLike";
import useUserStore from "../users/store";

const MAX_LIKES_TO_SHOW = 5;

type LikeGalleryProperties = {
  ideia: TIdeia;
  isLiked: boolean;
  likedBy: TIdeiaLike[] | null;
};

const LikeGallery = ({ ideia, isLiked, likedBy }: LikeGalleryProperties) => {
  const handleDetailLikes = useCallback(() => {
    modals.openContextModal({
      modal: "detail-ideia-likes-modal",
      innerProps: { ideia },
      size: "lg",
      title: "Quem curtiu essa a ideia"
    });
  }, [ideia]);
  const handleOnMouseEnter = useCallback((event_: React.MouseEvent<HTMLDivElement>) => {
    event_.currentTarget.style.cursor = "pointer";
  }, []);
  const handleOnMouseLeave = useCallback((event_: React.MouseEvent<HTMLDivElement>) => {
    event_.currentTarget.style.cursor = "default";
  }, []);
  const displayedLikedBy = useMemo(() => {
    return likedBy?.slice(0, MAX_LIKES_TO_SHOW) ?? [];
  }, [likedBy]);
  if (displayedLikedBy?.length === 0) {
    return null;
  }
  return (
    <Group>
      {displayedLikedBy?.map((like) => (
        <div
          key={String(crypto.randomUUID())}
          style={{
            position: "relative",
            display: "inline-block",
            marginLeft: "2px",
            marginTop: "-36px"
          }}
        >
          <Avatar
            onClick={handleDetailLikes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            src={like.colaboradorImagem || ""}
            radius="xl"
            style={{
              backgroundColor: "white",
              border: "1px solid #ccc",
              position: "absolute",
              left: "-25px",
              top: "0",
              zIndex: displayedLikedBy?.indexOf(like) ?? 0 + 1
            }}
            title={like.colaboradorNome || "Colaborador"}
          />
        </div>
      ))}
      <Text ml="5px" size="sm">
        {likedBy && likedBy.length === 1
          ? isLiked
            ? "Você curtiu essa ideia"
            : "1 pessoa curtiu essa ideia"
          : isLiked
            ? `Você e outras ${likedBy && likedBy.length - 1} pessoas curtiram essa ideia`
            : likedBy && likedBy.length === 1
              ? `1 pessoa curtiu essa ideia`
              : `${likedBy && likedBy.length > 0} pessoas curtiram essa ideia`}
      </Text>
    </Group>
  );
};

const IdeiaLikes = ({ ideia }: { ideia: TIdeia }) => {
  const { data: likedByData } = useGetLikes(ideia);
  const [likedBy, setLikedBy] = useState<TIdeiaLike[] | null>([]);

  useEffect(() => {
    setLikedBy(likedByData ?? null);
  }, [likedByData]);

  const { like, unlike, isPending } = useIdeiaLike({
    ideia,
    onSuccess: (data) => {
      let novoLikedBy = likedBy;
      if (data === "unlike") {
        novoLikedBy = novoLikedBy?.filter((like) => like.createdBy !== currentUser?.id) ?? null;
      } else if (data === "like") {
        novoLikedBy = [
          ...(novoLikedBy || []),
          {
            createdAt: new Date(),
            colaboradorNome: currentUser?.nome || "",
            colaboradorImagem: currentUser?.image?.[0]?.path || "",
            createdBy: currentUser?.id || ""
          }
        ];
      }
      setLikedBy(novoLikedBy);
    }
  });
  const currentUser = useUserStore((state) => state.colaborador);

  const isLiked = useMemo(() => {
    return !!likedBy?.find((likeItem) => likeItem?.createdBy === currentUser?.id);
  }, [likedBy, currentUser]);

  const handleLikeToggle = useCallback(() => {
    isLiked ? unlike() : like();
  }, [isLiked, like, unlike]);

  return (
    <Group align="center" gap="xs">
      <Button
        variant="subtle"
        style={{ backgroundColor: "transparent" }}
        leftSection={isLiked ? <IconThumbUp color="#228BE6" /> : <IconThumbUp color="gray" />}
        color={isLiked ? "gray" : "blue"}
        onClick={handleLikeToggle}
        disabled={isPending}
        title={isLiked ? "Descurtir essa ideia" : "Curtir essa ideia"}
      />
      <LikeGallery ideia={ideia} isLiked={isLiked} likedBy={likedBy} />
    </Group>
  );
};

export default IdeiaLikes;
