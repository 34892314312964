import { Badge, BadgeProps, Group, MantineColor, Text, Tooltip } from "@mantine/core";

export const colorsMap: Record<TIdeia["status"], MantineColor> = {
  NOVA_IDEIA: "green.1",
  AGUARDANDO_ANALISE_DO_COMITE: "grape.9",
  PRE_ANALISE: "blue.5",
  EM_ANALISE_DE_VIABILIDADE: "grape.2",
  RASCUNHO: "gray.1",
  APROVADA: "green.4",
  REPROVADA: "red.6",
  IMPLEMENTADA: "indigo.6",
  CANCELADA: "orange.4",
  EM_IMPLEMENTACAO: "indigo.2",
  BACKLOG_DE_AVALIACAO_FUTURA: "gray.3"
};

const textMap: Record<TIdeia["status"], string> = {
  NOVA_IDEIA: "Nova Ideia",
  AGUARDANDO_ANALISE_DO_COMITE: "Aguardando análise do comitê",
  PRE_ANALISE: "Pré-análise",
  EM_ANALISE_DE_VIABILIDADE: "Em análise de viabilidade",
  RASCUNHO: "Rascunho",
  APROVADA: "Aprovada",
  REPROVADA: "Reprovada",
  IMPLEMENTADA: "Implementada",
  CANCELADA: "Cancelada",
  EM_IMPLEMENTACAO: "Em implementação",
  BACKLOG_DE_AVALIACAO_FUTURA: "Backlog de avaliação futura"
};

const IdeiaStatusBadge = ({
  status,
  variant,
  iconOnly = false
}: {
  status: TIdeia["status"];
  iconOnly?: boolean;
  variant?: BadgeProps["variant"];
}) => {
  // eslint-disable-next-line security/detect-object-injection
  const color = colorsMap[status];
  // eslint-disable-next-line security/detect-object-injection
  const statusText = textMap[status];

  if (iconOnly) {
    return (
      <Tooltip label={statusText} position="right" arrowPosition="center" arrowOffset={10} withArrow>
        <Badge color={color} size="sm" radius="sm" />
      </Tooltip>
    );
  }

  if (variant === "filled") {
    return (
      <Badge color={color} size="sm" radius="sm" variant={variant} autoContrast>
        {statusText}
      </Badge>
    );
  }

  return (
    <Group gap={6}>
      <Badge color={color} size="sm" radius="sm" variant={variant} />
      <Text>{statusText}</Text>
    </Group>
  );
};

export default IdeiaStatusBadge;
