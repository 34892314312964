import { Menu, ActionIcon, Group } from "@mantine/core";
import { IconDotsVertical, IconEdit, IconLockOpen, IconSend, IconTrash } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import Can from "@/components/can";
import EditItemButton from "@/components/edit-item-button";
import { getAutorizacoes } from "@/modules/perfil-de-acesso/firestore";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import { captureException } from "@/services/log";
import useGetColaboradorById from "../../hooks/useGetColaboradorById";
import colaboradorSchema from "../../schema/colaborador-schema";
import DisableColaboradorButton from "../disable-colaborador-button";
import EnableColaboradorButton from "../enable-colaborador-button";
import ReenviarConviteColaborador from "../reenviar-convite-colaborador";
import RemoveColaboradorButton from "../remove-colaborador-button";

const message = "";

interface Autorizacao {
  editar: boolean;
  remover: boolean;
}

// prettier-ignore
const ContextMenuRow = ({ colaborador }: { colaborador: TColaborador }) => {
  const isColaboradorDisabled = !!colaborador.disabledAt;
  const isColaboradorDeleted = !!colaborador.deletedAt;
  const isColaboradorInviteAccepted = colaborador.inviteSentAt && !colaborador.inviteAcceptedAt;
  const isColaboradorActive = !isColaboradorDisabled && !isColaboradorDeleted && !isColaboradorInviteAccepted;
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);
  const user = getCurrentUser();
  const { data: autor } = useGetColaboradorById(activeEmpresaId || "", user?.uid || "");
  const [autorizacao, setAutorizacao] = useState<Autorizacao>({ editar: false, remover: false });

  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadData();
      } catch (error) {
        console.error(message, error);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, [activeEmpresaId]);

  const loadData = async () => {
    const newAutorizations = await getAutorizacoes(activeEmpresaId || "").then(response => response.docs.map((autorizacao) => autorizacao.data())).catch((error: Error) => {
      captureException(error, true);
      throw error;
    });
    const { editar, remover } = verificationColaborador(newAutorizations.filter(item => item.tipoAutorizacao === "2"), autor) as Autorizacao;
    setAutorizacao({ editar, remover })
  }

  if (autorizacao.remover === false && autorizacao.editar === false) {
    return <></>
  }

  return (
    <Group justify="center" gap={2}>
      <Menu shadow="md" width={200} position="left" withArrow arrowPosition="center">
        <Menu.Target>
          <ActionIcon variant="transparent" aria-label="Menu" color="gray">
            <IconDotsVertical />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Can I="update" a="TColaborador">
            {/* <Can I="update" this={colaborador}> */}
            {colaborador.inviteSentAt && !colaborador.inviteAcceptedAt && (
              <Menu.Item
                leftSection={<IconSend size={14} />}
                component={ReenviarConviteColaborador}
                colaborador={colaborador}
              >
                Re-enviar convite
              </Menu.Item>
            )}
            <Menu.Item
              leftSection={<IconEdit size={14} />}
              component={EditItemButton}
              modal="colaborador-form-modal"
              schema={colaboradorSchema}
              item={colaborador}
            >
              Editar
            </Menu.Item>
            {isColaboradorDisabled && (
              <Menu.Item
                leftSection={<IconLockOpen size={14} />}
                component={EnableColaboradorButton}
                colaborador={colaborador}
              >
                Habilitar
              </Menu.Item>
            )}
            {isColaboradorActive && (
              <Menu.Item
                leftSection={<IconLockOpen size={14} />}
                color="red"
                component={DisableColaboradorButton}
                colaborador={colaborador}
              >
                Desabilitar
              </Menu.Item>
            )}
          </Can>
          <Can I="delete" a="TColaborador">
            <Menu.Divider />
            <Menu.Item
              color="red"
              leftSection={<IconTrash size={14} />}
              component={RemoveColaboradorButton}
              colaborador={colaborador}
            >
              Remover
            </Menu.Item>
          </Can>
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
};

export default ContextMenuRow;

// prettier-ignore
const verificationColaborador = (autorizacoes: TAutorizacao[], colaborador: TColaborador | null | undefined) => {
  let autorizacao;
  switch (colaborador?.role) {
    case "ADMIN": {
      autorizacao = autorizacoes.find(item => item.tipo === "1");
      break;
    }
    case "MEMBER": {
      autorizacao = autorizacoes.find(item => item.tipo === "2");
      break;
    }
    case "TEAM": {
      autorizacao = autorizacoes.find(item => item.tipo === "3");
      break;
    }
    case "MASTER": {
      autorizacao = autorizacoes.find(item => item.tipo === "4");
      break;
    }
    default: {
      autorizacao = autorizacoes.find(item => item.tipo === "5");
    }
  }

  return autorizacao;
}
