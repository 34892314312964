import { UnstyledButton, UnstyledButtonProps } from "@mantine/core";
import { showNotification, updateNotification, hideNotification } from "@mantine/notifications";
import { UseMutationOptions, useMutation, useQueryClient } from "@tanstack/react-query";
import { FirebaseError } from "firebase/app";
import { ForwardedRef, MouseEvent, PropsWithChildren, forwardRef } from "react";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { resentInviteToColaborador } from "../../functions";
import { QUERY_KEY } from "../colaboradores-table/ColaboradoresTable";

type ResentInviteToColaboradorResponse = Awaited<ReturnType<typeof resentInviteToColaborador>>;
type ReenviarConviteColaboradorProperties = PropsWithChildren<UnstyledButtonProps & { colaborador: TColaborador }>;

/**
 * Sends an invite to a colaborador.
 * @param colaborador - The colaborador to send the invite.
 * @param options - The options to use on the mutation.
 * @returns The mutation state.
 */
function useResentInviteToColaboradorMutation(
  colaborador: TColaborador,
  options: Omit<UseMutationOptions<ResentInviteToColaboradorResponse, FirebaseError>, "mutationFn"> = {}
) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  return useMutation<ResentInviteToColaboradorResponse, FirebaseError>({
    mutationFn() {
      if (!activeEmpresaId) {
        throw new Error("Nenhuma empresa selecionada");
      }

      return resentInviteToColaborador({ empresaId: activeEmpresaId, email: colaborador.email });
    },
    ...options
  });
}

const NOTIFICATION_ID = "RESEND_INVITE_TO_COLABORADOR_NOTIFICATION";

/**
 * A button that opens a modal to edit an item.
 * @param root0 - The properties of the button.
 * @param root0.colaborador - The colaborador to send the invite.
 * @param root0.children - The children of the button.
 * @param reference - The reference to the button.
 * @returns The element to be rendered.
 */
function ReenviarConviteColaborador(
  { colaborador, children, ...properties }: ReenviarConviteColaboradorProperties,
  reference: ForwardedRef<HTMLButtonElement>
) {
  const queryClient = useQueryClient();
  const { mutate, isPending, reset } = useResentInviteToColaboradorMutation(colaborador, {
    onMutate() {
      showNotification({
        id: NOTIFICATION_ID,
        message: `Enviando convite...`,
        loading: true
      });
    },
    onSuccess() {
      reset();
      updateNotification({
        id: NOTIFICATION_ID,
        message: `Convite enviado com sucesso!`,
        loading: false,
        color: "green",
        autoClose: 2500,
        onClose() {
          void queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
        }
      });
    },
    onError(error) {
      hideNotification(NOTIFICATION_ID);
      captureException(error, true);
    }
  });

  /**
   * Handles the click event on the button.
   * @param event - The event that triggered the click.
   */
  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    mutate();
  }

  return (
    <UnstyledButton ref={reference} {...properties} disabled={isPending} onClick={handleClick}>
      {children}
    </UnstyledButton>
  );
}

const ReenviarConviteColaboradorWithReference = forwardRef(ReenviarConviteColaborador);

ReenviarConviteColaboradorWithReference.displayName = "ReenviarConviteColaborador";

export default ReenviarConviteColaboradorWithReference;
