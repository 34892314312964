/**
 * Função para gerar dados para o select do formulário no formato do Mantine
 * @param strings - Array de strings
 * @deprecated - Não utilize essa function
 * @returns - Array de objetos
 */
export function gerarDadosSelect(strings: string[]): { label: string; value: string }[] {
  return strings.map((string_) => {
    const palavras = string_.split("_").map((palavra) => {
      return palavra.charAt(0).toUpperCase() + palavra.slice(1).toLowerCase();
    });

    const labelFormatada = palavras.join(" ");

    return { label: labelFormatada, value: string_ };
  });
}
