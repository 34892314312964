import { serverTimestamp } from "firebase/firestore";
import { ObjectSchema } from "yup";
import schema from "@/schema";
import { getCurrentUser } from "@/services/auth";
import { TTipoInovacaoForm, TTipoInovacaoFormFields, TTTipoDeInovacao } from "../firestore";

const MIN_NAME_LENGTH = 3;
/**
 * Define only the meta fields that are not part of the form
 */
const tipoDeInovacaoSchemaDatabaseFields: ObjectSchema<TTTipoDeInovacao> = schema.object().shape({
  createdAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .required()
    .defined()
    .nonNullable()
    .default<ReturnType<typeof serverTimestamp>>(() => serverTimestamp()),
  createdBy: schema
    .string()
    .required()
    .defined()
    .nonNullable()
    .default(() => {
      const currentUserId = getCurrentUser()?.uid;
      if (!currentUserId) {
        throw new Error("The current user is not defined");
      }
      return currentUserId;
    }),
  updatedAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .default<ReturnType<typeof serverTimestamp>>(() => serverTimestamp()),
  updatedBy: schema.string().default(() => {
    const currentUserId = getCurrentUser()?.uid;
    if (!currentUserId) {
      throw new Error("The current user is not defined");
    }
    return currentUserId;
  }),
  startedAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .required()
    .defined()
    .nonNullable()
    .default<ReturnType<typeof serverTimestamp>>(() => serverTimestamp()),
  pausedAt: schema.mixed<ReturnType<typeof serverTimestamp>>().defined().nullable().default(null),
  endedAt: schema.mixed<ReturnType<typeof serverTimestamp>>().defined().nullable().default(null),
  deletedAt: schema.mixed<ReturnType<typeof serverTimestamp>>().defined().nullable().default(null)
});

const tipoInovacaoSchemaFormFields: ObjectSchema<TTipoInovacaoFormFields> = schema.object({
  id: schema.string().optional(),
  refPath: schema.string().optional(),
  nomeCategoria: schema.string().optional(),
  taticas: schema.array().of(schema.object().optional().defined()).default([]).optional().label("Taticas").optional(),
  nome: schema.string().min(MIN_NAME_LENGTH).required().defined().label("Nome"),
  categoria: schema.string().min(MIN_NAME_LENGTH).required().defined().label("Categoria"),
  descricao: schema.string().min(MIN_NAME_LENGTH).required().defined().label("Descricao")
});

const tipoDeInovacaoSchema: ObjectSchema<TTipoInovacaoForm> = tipoInovacaoSchemaFormFields
  // eslint-disable-next-line unicorn/prefer-spread
  .concat(tipoDeInovacaoSchemaDatabaseFields)
  .noUnknown()
  .strict()
  .label("tipoDeInovação");

export default tipoDeInovacaoSchema;
