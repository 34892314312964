/* eslint-disable no-console */
import { subject } from "@casl/ability";
import { SortingState, PaginationState } from "@tanstack/react-table";
import {
  FirestoreDataConverter,
  collection,
  getCountFromServer,
  getDocs,
  DocumentReference,
  DocumentData,
  query,
  QuerySnapshot,
  CollectionReference,
  Timestamp
} from "firebase/firestore";
import * as Yup from "yup";
import { generateQuery } from "@/utils/firestore";
import { getColaboradorFromDocument, TColaboradorDocument } from "../colaboradores/firestore";
import { getCronogramaDesafioFilter } from "../desafio-cronograma/firestore";
import { desafioConverter } from "../desafios/firestore";
import desafioFormSchema from "../desafios/schema";
import {
  desafioActivityPerColaboradorConverter,
  getDesafioActivitiesPerColaboradorCollectionReference
} from "../desafios-activities-per-colaborador/firestore";
import { getEmpresaDocumentReference } from "../empresas/firestore";

const ANO = 5000;
const MES = 12;
const DIA = 31;
const MENOR_DATA = new Date(ANO, 0, 1);
const MAIOR_DATA = new Date(ANO * -1, MES, DIA);

// Cache local em memória.
const colaboradorCache = new Map<string | undefined, TColaborador | undefined>();

interface TDesafioExtended
  extends Omit<
    TDesafio,
    "categoriaReference" | "regulamentoReference" | "propositorReference" | "participantesReferences" | "status"
  > {
  moedas?: number;
  primeiraImersao?: Date;
  ultimaImersao?: Date;
  propositorNome?: string;
  propositorEmail?: string;

  categoriaReference: string | DocumentReference<TDesafioCategoria, DocumentData>;
  regulamentoReference: string | DocumentReference<TRegulamentoDesafios, DocumentData>;
  propositorReference: string | DocumentReference<TColaborador, DocumentData>;
  participantesReferences: DocumentReference<TColaborador, TColaboradorDocument>[];

  status: string;
}

const desafioExtendedFormSchema = desafioFormSchema.shape({
  moedas: Yup.number().required("Moedas é um campo obrigatório").min(0, "Moedas não pode ser negativo"),

  primeiraImersao: Yup.date().required("Primeira Imersão é um campo obrigatório"),

  ultimaImersao: Yup.date()
    .required("Última Imersão é um campo obrigatório")
    .min(Yup.ref("primeiraImersao"), "Última Imersão deve ser posterior à Primeira Imersão"),

  createdBy: Yup.string().required("CreatedBy é um campo obrigatório")
});

export const DESAFIOS_COLLECTION_KEY = "desafios" as const;
const DEFAULT_LIMIT = 10;
export const DEFAULT_SORTING: SortingState = [{ id: "publishedAt", desc: true }];
export const DEFAULT_PAGINATION: PaginationState = { pageIndex: 0, pageSize: DEFAULT_LIMIT };

type TDesafioDocument = TFirestoreDocument<TDesafioExtended>;

const desafioExtendedConverter: FirestoreDataConverter<TDesafioExtended> = {
  toFirestore(data: TDesafioExtended) {
    const { moedas, primeiraImersao, ultimaImersao, propositorNome, propositorEmail, ...dataToStore } = data;

    return {
      ...dataToStore,
      moedas,
      primeiraImersao,
      ultimaImersao,
      propositorNome,
      propositorEmail,
      categoriaReference: data.categoriaReference,
      regulamentoReference: data.regulamentoReference,
      propositorReference: data.propositorReference,
      status: data.status // Garantir que status é uma string
    };
  },
  fromFirestore(snapshot, options) {
    const {
      createdAt,
      updatedAt,
      deletedAt,
      publishedAt,
      moedas,
      primeiraImersao,
      ultimaImersao,
      propositorNome,
      propositorEmail,
      createdBy,
      categoriaReference,
      regulamentoReference,
      propositorReference,
      status,
      ...data
    } = snapshot.data(options) as TDesafioDocument & {
      moedas: number;
      primeiraImersao: Date;
      ultimaImersao: Date;
      propositorNome: string;
      propositorEmail: string;
      createdBy: string;
      categoriaReference: string | DocumentReference<TDesafioCategoria, DocumentData>;
      regulamentoReference: string | DocumentReference<TRegulamentoDesafios, DocumentData>;
      propositorReference: string | DocumentReference<TColaborador, DocumentData>;
    };

    const baseData = desafioExtendedFormSchema.cast(data, { stripUnknown: true, assert: false });

    const desafio: TDesafioExtended = {
      ...baseData,
      moedas,
      primeiraImersao,
      ultimaImersao,
      propositorNome,
      propositorEmail,
      createdBy,
      categoriaReference,
      regulamentoReference,
      propositorReference,
      id: snapshot.id,
      refPath: snapshot.ref.path,
      updatedAt: updatedAt?.toDate(),
      createdAt: createdAt.toDate(),
      deletedAt: deletedAt?.toDate() ?? null,
      publishedAt: publishedAt?.toDate() ?? null,
      status: status as TDesafioStatus
    };

    return subject("TDesafio", desafio);
  }
};

/**
 * Get Desafios collection reference
 * @param empresaId  - Empresa id
 * @returns - Desafios collection reference
 */
function getDesafiosCollection(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, DESAFIOS_COLLECTION_KEY).withConverter(desafioExtendedConverter);
}

// Defina um tipo para a resposta sem paginação
interface TQueryResponse<T> {
  documentos: T[];
  totalCount: number;
}

/**
 * Get all Desafios from the database
 * @param empresaId - Empresa id
 * @param root0 - Query options
 * @param root0.sorting - Sorting options
 * @param root0.filters - Filters options
 * @param root0.globalFilters - Global filters
 * @returns - Promise with all Desafios
 */
export async function getAllDesafios(
  empresaId: string | undefined,
  { sorting = [], filters = [], globalFilters }: TPaginatedQueryOptions<TDesafio> = {}
): Promise<TQueryResponse<TDesafioExtended>> {
  if (!empresaId) {
    throw new Error("Empresa id is required");
  }
  const queryDesafiosCollectionReference = getDesafiosCollection(empresaId);
  const qAll = generateQuery(queryDesafiosCollectionReference, { sorting, filters, globalFilters });
  const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(qAll);
  const desafios = querySnapshot.docs.map((document_) => document_.data()) as TDesafioExtended[];
  for (const desafio of desafios) {
    const propositores = await getPropositores(desafio);
    console.log("**** countSnapshot", propositores);
    desafio.propositorEmail = propositores.propositorEmail;
    desafio.propositorNome = propositores.propositorNome;
    desafio.moedas = await getTotalMoedas(desafio, empresaId);
    console.log("**** moedas", desafio.moedas);
    const { primeiraImersao, ultimaImersao } = await getImersoes(desafio, empresaId);
    console.log("**** primeiraImersao", primeiraImersao);
    desafio.primeiraImersao = primeiraImersao;
    desafio.ultimaImersao = ultimaImersao;
  }
  // const desafiosExtended: TDesafioExtended[] = [];
  // PreencherDadosExtras(desafios, propositores, desafiosExtended, totalMoedas, primeiraImersao, ultimaImersao);
  const countSnapshot = await getCountFromServer(qAll);
  console.log("**** countSnapshot", countSnapshot);
  const totalCount = countSnapshot.data().count;

  return {
    documentos: desafios,
    totalCount
  };
}

/**
 * Get all Desafios from the database
 * @param empresaId - Empresa id
 * @param root0 - Query options
 * @param root0.sorting - Sorting options
 * @param root0.filters - Filters options
 * @param root0.globalFilters - Global filters
 * @param root0.pagination - Pagination options
 * @param root0.paginationCursors - Array of pagination cursors (startAt, endAt)[]
 * @returns - Promise with all Desafios
 */
export function getPaginatedDesafios(
  empresaId: string,
  {
    sorting = [],
    filters = [],
    globalFilters,
    pagination = { pageIndex: 0, pageSize: DEFAULT_LIMIT },
    paginationCursors
  }: TPaginatedQueryOptions<TDesafio> = {}
): Promise<TPaginatedQueryResponse<TDesafio>> {
  const queryDesafiosCollectionReference = getDesafiosCollection(empresaId).withConverter(desafioConverter);

  const qAll = generateQuery(queryDesafiosCollectionReference, { sorting, filters, globalFilters });
  const qPaginated = generateQuery(queryDesafiosCollectionReference, {
    sorting,
    filters,
    globalFilters,
    pagination,
    paginationCursors
  });

  // eslint-disable-next-line compat/compat
  return Promise.all([getDocs(qPaginated), getCountFromServer(qAll)]);
}

/**
 *
 * @param desafio - desafios
 * @param empresaId - empresa id
 * @returns - total de moedas
 */
async function getTotalMoedas(desafio: TDesafioExtended, empresaId: string) {
  const reference = getDesafioActivitiesPerColaboradorCollectionReference(
    empresaId,
    desafio.id
  ) as CollectionReference<TDesafioActivityPerColaborador>;
  const desafiosActivitiesPerColaboradorMap = new Map<string, TDesafioActivityPerColaborador>();
  const results = new Map<string, TDesafioActivityPerColaborador>();
  if (reference) {
    const queryReference = query(reference);
    const querySnapshot = await getDocs(queryReference);
    for (const document_ of querySnapshot.docs) {
      const data = desafioActivityPerColaboradorConverter.fromFirestore(document_);
      results.set(document_.id, data);
    }
    for (const [key, value] of results.entries()) {
      desafiosActivitiesPerColaboradorMap.set(key, value);
    }
  }
  return [...desafiosActivitiesPerColaboradorMap.values()]
    .map((activity) => activity.moedas)
    .reduce((accumulator, moedas) => accumulator + moedas, 0);
}

/**
 *
 * @param desafio - desafios
 * @returns - propositores
 */
async function getPropositores(desafio: TDesafioExtended): Promise<{
  propositorEmail: string | undefined;
  propositorNome: string | undefined;
}> {
  const colaboradores = [desafio.propositorReference];
  const propositorData = { emails: [] as string[], nomes: [] as string[] };

  const processAutores = async () => {
    for (const valor of colaboradores) {
      const colaborador = await BuscaColaborador(valor as unknown as DocumentReference<TColaborador>);
      if (colaborador) {
        propositorData.nomes.push(colaborador.nome);
        propositorData.emails.push(colaborador.email);
      }
    }
  };

  await processAutores();

  return {
    propositorNome: propositorData.nomes.join(", "),
    propositorEmail: propositorData.emails.join(", ")
  };
}
/**
 *
 * @param colaboradorReference - ref para o colaborador
 * @returns - colaborador
 */
const BuscaColaborador = async (colaboradorReference: DocumentReference<TColaborador>) => {
  const cached = colaboradorCache.get(colaboradorReference.id);
  if (cached) {
    return cached;
  }

  return await getColaboradorFromDocument(colaboradorReference).then((colaborador) => {
    if (colaborador) {
      colaboradorCache.set(colaboradorReference.id, colaborador.data());
      return colaborador.data();
    }
    return null;
  });
};

/**
 *
 * @param desafio - desafio
 * @param activeEmpresaId - id da empresa ativa
 * @returns - maior e menor data
 */
async function getImersoes(
  desafio: TDesafioExtended,
  activeEmpresaId: string
): Promise<{ primeiraImersao: Date; ultimaImersao: Date }> {
  const cronogramas = await getCronogramaDesafioFilter(activeEmpresaId, desafio.id);
  let primeiraImersao = MENOR_DATA;
  let ultimaImersao = MAIOR_DATA;

  if (cronogramas.docs.length > 0) {
    const data: TCronogramaDesafio[] | undefined = cronogramas.docs.map((document_) => {
      return document_.data();
    });
    for (const item of data) {
      for (const evento of item.event) {
        const eventoTimestamp = (evento as { data: Timestamp }).data.toDate().getTime();
        primeiraImersao = new Date(Math.min(primeiraImersao.getTime(), eventoTimestamp));
        ultimaImersao = new Date(Math.max(ultimaImersao.getTime(), eventoTimestamp));
      }
    }
  }
  primeiraImersao = primeiraImersao.getTime() === MENOR_DATA.getTime() ? (0 as unknown as Date) : primeiraImersao;
  ultimaImersao = ultimaImersao.getTime() === MENOR_DATA.getTime() ? (0 as unknown as Date) : ultimaImersao;

  return { primeiraImersao, ultimaImersao };
}
