import { subject } from "@casl/ability";
import { useQuery } from "@tanstack/react-query";
import { FirestoreError, Query } from "firebase/firestore";
import { getMarcosByQuery } from "@/modules/ideia-marcos/firestore";
import useUserStore from "@/modules/users/store";

type TGetAllMarcosResponse = Awaited<ReturnType<typeof getMarcosByQuery>>;

/**
 * Hook to get all marcos from firestore
 * @param query - Query to get marcos
 * @returns Query result to get all marcos
 */
function useGetAllMarcos(query: Query) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  return useQuery<TGetAllMarcosResponse, FirestoreError, TMarco[]>({
    queryKey: ["empresas", activeEmpresaId, "marcos", { query }],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return getMarcosByQuery(query);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((document) => subject("TMarco", document.data()));
    }
  });
}

export default useGetAllMarcos;
