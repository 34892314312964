/* eslint-disable react/jsx-no-undef */
import { Avatar, Card, Flex, Group, Image, Text } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import dayjs from "dayjs";
import useGetLikes from "@/modules/ideias-likes/hooks/useGetLikes";

/**
 * Ideias likes card
 * @param root0 readOnly
 * @param root0.like like to display
 * @returns JSX.Element
 */
function IdeiaLikesCard({ like }: { like: TIdeiaLike }) {
  const profileImage = like.colaboradorImagem;

  const date = dayjs(like.createdAt).format("DD/MM/YYYY HH:mm");

  return (
    <Card withBorder>
      <Flex direction={"column"} gap={"sm"}>
        <Group display={"flex"} align="center">
          {profileImage ? (
            <Image h={48} w={48} radius="xl" src={profileImage} />
          ) : (
            <Avatar src={""} radius="xl" color="white" size="md" alt={like.colaboradorNome} />
          )}
          <Flex direction={"column"} flex="1 0 0">
            <Group flex="1 0 0" justify="space-between">
              <Text size="lg">{like.colaboradorNome}</Text>
              <Text size="sm" c="gray">
                {date}
              </Text>
            </Group>
          </Flex>
        </Group>
      </Flex>
    </Card>
  );
}

/**
 * Detail ideia likes modal
 * @param root0 readOnly
 * @param root0.innerProps Additional properties passed to the modal
 * @returns JSX.Element
 */
export function DetailIdeiaLikesModal({ innerProps }: ContextModalProps<{ ideia: TIdeia }>) {
  const { data: likes } = useGetLikes(innerProps.ideia);
  if (!likes || likes.length === 0) return null;
  return (
    <Flex direction="column" gap="sm">
      {likes?.map((likes) => <IdeiaLikesCard key={String(crypto.randomUUID())} like={likes} />)}
    </Flex>
  );
}
