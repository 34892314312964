import { Button, Flex, NumberInput, TextInput } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { QueryObserverResult } from "@tanstack/react-query";
import { ChangeEvent, useState } from "react";
import { useParams } from "react-router-dom";
import { getCurrentUser } from "@/services/auth";
import { useAddNota } from "./useGetInscricao";

interface Fase {
  id: string;
  titulo: string;
  cor: string;
  checked: boolean;
}

interface MyState {
  nota: string;
  justificativa: string;
}

const NotaItem = ({
  pergunta,
  fase,
  refetch,
  grupo
}: {
  pergunta: TPergunta | null | undefined;
  fase: Fase;
  refetch: () => Promise<QueryObserverResult>;
  grupo: TGruposDiagnostico;
}) => {
  const [values, setValues] = useState<MyState>(mockValues);
  const currentUserId = getCurrentUser()?.uid;
  const { mutate } = useAddNota();
  const { idEmpresa } = useParams<{ idEmpresa: string }>();

  return (
    <>
      <NumberInput placeholder="Digite a nota" onChange={onChangeNumber(setValues)} value={values.nota} />
      <TextInput placeholder="Justificativa da nota" onChange={onChangeText(setValues)} value={values.justificativa} />
      <Flex direction="row" justify="flex-end">
        <Button
          onClick={changeMutate(mutate, values, setValues, pergunta, currentUserId, fase, refetch, idEmpresa, grupo)}
        >
          Enviar
        </Button>
      </Flex>
    </>
  );
};

export default NotaItem;

// prettier-ignore
const onChangeNumber = (setValues: React.Dispatch<React.SetStateAction<MyState>>) => {
  return (value: string) => {
    setValues(values => ({ ...values, nota: value }))
  }
}

// prettier-ignore
const onChangeText = (setValues: React.Dispatch<React.SetStateAction<MyState>>) => {
  return (event: ChangeEvent<HTMLInputElement>) => {
    setValues(values => ({ ...values, justificativa: event.target.value }))
  }
}

// prettier-ignore
const changeMutate = (mutate: (item: TMedia) => void, values: MyState, setValues: React.Dispatch<React.SetStateAction<MyState>>, pergunta: TPergunta | null | undefined, currentUserId: string | undefined, fase: Fase, refetch: () => Promise<QueryObserverResult>, idEmpresa: string | undefined, grupo: TGruposDiagnostico) => {
  return () => {
    mutate({
      nota: values.nota,
      justificativa: values.justificativa,
      fase: fase?.id,
      idResposta: pergunta?.id || "",
      respondente: currentUserId || "",
      idEmpresaNota: idEmpresa || "",
      grupo: grupo.id

    });
    setValues(mockValues);
    void refetch();
    showNotification({
      message: `Nota adicionada com sucesso!`,
      color: "green",
      autoClose: 2500
    });
  }
};

// prettier-ignore
const mockValues = {
  nota: "",
  justificativa: ""
}
