import { subject } from "@casl/ability";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import {
  serverTimestamp,
  Timestamp,
  FirestoreDataConverter,
  collection,
  getCountFromServer,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  query,
  where
} from "firebase/firestore";
import { getCurrentUser } from "@/services/auth";
import { generateQuery } from "@/utils/firestore";
import { getEmpresaDocumentReference } from "../empresas/firestore";

const DEFAULT_LIMIT = 10;
export const DEFAULT_SORTING: SortingState = [{ id: "nome", desc: false }];
export const DEFAULT_PAGINATION: PaginationState = { pageIndex: 0, pageSize: DEFAULT_LIMIT };

export type TPerguntaFormFields = Omit<
  TPergunta,
  "id" | "refPath" | "createdAt" | "updatedAt" | "createdBy" | "updatedBy" | "deletedAt"
> &
  Partial<Pick<TPergunta, "id" | "refPath">>;

export type TPerguntaDatabaseFields = Pick<TPergunta, "createdBy" | "updatedBy"> & {
  createdAt: ReturnType<typeof serverTimestamp>;
  updatedAt?: ReturnType<typeof serverTimestamp>;
  deletedAt: ReturnType<typeof serverTimestamp> | null;
};
export type TPerguntaForm = TPerguntaFormFields & TPerguntaDatabaseFields;

type TPerguntaDocument = Omit<TPergunta, "createdAt" | "updatedAt" | "deletedAt"> & {
  createdAt: Timestamp;
  updatedAt?: Timestamp;
  deletedAt: Timestamp | null;
};

const perguntaConverter: FirestoreDataConverter<TPergunta> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const { createdAt, updatedAt, deletedAt, ...document } = snap.data() as TPerguntaDocument;

    const data: TPergunta = {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      deletedAt: deletedAt ? deletedAt.toDate() : null,
      refPath: snap.ref.path
    };

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }
    return subject("TPergunta", data);
  }
};

/**
 * Get perguntas collection reference from empresaId
 * @param empresaId - Empresa id to get the perguntas collection reference
 * @returns - Perguntas collection reference
 */
function getPerguntasCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, "perguntas");
}

/**
 * Get pergunta document reference from empresaId
 * @param empresaId - Empresa id to get the pergunta document reference
 * @param perguntaId - Pergunta id to get the pergunta document reference
 * @returns - Pergunta document reference
 */
function getPerguntaDocumentReference(empresaId: TEmpresa["id"], perguntaId: TPergunta["id"]) {
  const perguntasCollectionReference = getPerguntasCollectionReference(empresaId);
  return doc(perguntasCollectionReference, perguntaId);
}

const BASE_FILTER: ColumnFiltersState = [
  {
    id: "deletedAt",
    value: ["==", null]
  }
];

/**
 * Get perguntas documents from empresaId
 * @param empresaId - Empresa id to get the perguntas documents
 * @param options - Options to get the perguntas documents
 * @param options.sorting - Sorting options
 * @param options.filters - Filters options
 * @param options.pagination - Pagination options
 * @param options.paginationCursors - Array of pagination cursors (startAt, endAt)[]
 * @returns - Perguntas documents
 */
export function getPerguntas(
  empresaId: TEmpresa["id"],
  {
    sorting = [],
    filters = [],
    pagination = { pageIndex: 0, pageSize: DEFAULT_LIMIT },
    paginationCursors
  }: TPaginatedQueryOptions<TPergunta> = {}
): Promise<TPaginatedQueryResponse<TPergunta>> {
  const perguntasCollectionReference = getPerguntasCollectionReference(empresaId).withConverter(perguntaConverter);

  const allFilters = [...BASE_FILTER, ...filters];
  const qAll = generateQuery(perguntasCollectionReference, { filters: allFilters });
  const qPaginated = generateQuery(perguntasCollectionReference, {
    sorting,
    filters: allFilters,
    pagination,
    paginationCursors
  });

  // eslint-disable-next-line compat/compat
  return Promise.all([getDocs(qPaginated), getCountFromServer(qAll)]);
}

/**
 * Add a new pergunta to the given empresa
 * @param empresaId - Empresa id to add the pergunta
 * @param pergunta - Pergunta data
 * @returns - Pergunta document reference
 */
export async function addPergunta(empresaId: TEmpresa["id"], pergunta: Omit<TPerguntaForm, "id" | "refPath">) {
  const perguntasCollectionReference = getPerguntasCollectionReference(empresaId);
  return addDoc(perguntasCollectionReference, pergunta);
}

// /**
//  * Get a pergunta document snapshot
//  * @param empresaId - Empresa id to get the pergunta
//  * @param perguntaId - pergunta id to get the pergunta
//  * @returns - Pergunta document snapshot
//  */
// export function getPergunta(empresaId: TEmpresa["id"], perguntaId: TPergunta["id"]) {
//   const perguntaDocumentReference = getPerguntaDocumentReference(empresaId, perguntaId).withConverter(perguntaConverter);
//   return getDoc(perguntaDocumentReference);
// }

/**
 * Update a empresa to the database
 * @param empresaId - Empresa id
 * @param perguntaId - Pergunta id to update
 * @param pergunta - Pergunta data
 * @returns - Promise with the pergunta reference
 */
export function updatePergunta(empresaId: TEmpresa["id"], perguntaId: TPergunta["id"], pergunta: TPerguntaForm) {
  const perguntaDocumentReference = getPerguntaDocumentReference(empresaId, perguntaId).withConverter(
    perguntaConverter
  );
  const currentUserId = getCurrentUser()?.uid;
  return updateDoc(perguntaDocumentReference, {
    ...pergunta,
    updatedAt: serverTimestamp(),
    updatedBy: currentUserId
  });
}

/**
 * Delete a pergunta to the database
 * @param empresaId - Empresa id to delete the pergunta
 * @param pergunta - Pergunta to delete
 * @returns - Promise
 */
export function deletePergunta(empresaId: TEmpresa["id"], pergunta: TPergunta) {
  const perguntaDocumentReference = getPerguntaDocumentReference(empresaId, pergunta.id);
  return updateDoc(perguntaDocumentReference, {
    deletedAt: serverTimestamp()
  });
}

type TTiposDeRespostasDocument = Omit<TTiposDeRespostas, "createdAt" | "updatedAt" | "deletedAt"> & {
  createdAt: Timestamp;
  deletedAt: Timestamp | null;
};

const tiposDeRespostasConverter: FirestoreDataConverter<TTiposDeRespostas> = {
  toFirestore(data) {
    delete data.id;
    return data;
  },
  fromFirestore(snap) {
    const { deletedAt, createdAt, ...document } = snap.data() as TTiposDeRespostasDocument;

    const data: TTiposDeRespostas = subject("TTiposDeRespostas", {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      deletedAt: deletedAt ? deletedAt.toDate() : null,
      refPath: snap.ref.path
    });

    return data;
  }
};

/**
 * Get all questionário
 * @param empresaId - Empresa id
 * @returns - questionário collection reference
 */
function getTiposDeRespostasCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, "tiposDeRespostas");
}

/**
 * Get questionário query for a empresa
 * @param empresaId - Empresa id to get comitês
 * @returns - questionário query
 */
function generateTiposDeRespostasQuery(empresaId: TEmpresa["id"]) {
  const categoriasInovacaoCollectionReference =
    getTiposDeRespostasCollectionReference(empresaId).withConverter(tiposDeRespostasConverter);
  return query(categoriasInovacaoCollectionReference, where("deletedAt", "==", null));
}

/**
 * Get questionário for a empresa
 * @param empresaId - Empresa id to get comitês
 * @returns - questionário document snapshot
 */
export function getTiposDeRespostas(empresaId: TEmpresa["id"]) {
  const q = generateTiposDeRespostasQuery(empresaId);
  return getDocs(q);
}

type TGruposDiagnosticoDocument = Omit<TGruposDiagnostico, "createdAt" | "updatedAt" | "deletedAt"> & {
  createdAt: Timestamp;
  updatedAt?: Timestamp;
  deletedAt: Timestamp | null;
};

const gruposConverter: FirestoreDataConverter<TGruposDiagnostico> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const { createdAt, updatedAt, deletedAt, ...document } = snap.data() as TGruposDiagnosticoDocument;

    const data: TGruposDiagnostico = {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      deletedAt: deletedAt ? deletedAt.toDate() : null,
      refPath: snap.ref.path
    };

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }

    return subject("TGruposDiagnostico", data);
  }
};
interface FiltersType {
  field: string | null | undefined;
  id: string | null | undefined;
}

/**
 * Get all questionário
 * @param empresaId - Empresa id
 * @returns - questionário collection reference
 */
function getGrupoCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, "gruposDiagnostico");
}
// prettier-ignore
/**
 * Get questionário query for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param filters - Empresa filters
 * @returns - questionário query
 */
function generateGrupoQuery(empresaId: TEmpresa["id"], filters: FiltersType | null | undefined) {
  const categoriasInovacaoCollectionReference = getGrupoCollectionReference(empresaId).withConverter(gruposConverter);

  if (filters && filters.id) {
    return query(categoriasInovacaoCollectionReference,  where("deletedAt", "==", null), where(filters?.field|| "", "==", filters?.id));
}

  return query(categoriasInovacaoCollectionReference,  where("deletedAt", "==", null));
}

/**
 * Get questionário for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param filters - Filtros da empresa
 * @returns - questionário document snapshot
 */
export function getGrupos(empresaId: TEmpresa["id"], filters: FiltersType | null | undefined) {
  const q = generateGrupoQuery(empresaId, filters);
  return getDocs(q);
}

/**
 * Get questionário query for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param grupos - grupos da empresa
 * @returns - questionário query
 */
function generatePerguntaQuery(empresaId: TEmpresa["id"], grupos: Array<string> | null | undefined) {
  const categoriasInovacaoCollectionReference =
    getPerguntasCollectionReference(empresaId).withConverter(perguntaConverter);
  return query(categoriasInovacaoCollectionReference, where("deletedAt", "==", null), where("grupo", "in", grupos));
}

/**
 * Get questionário for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param grupos - grupos da empresa
 * @returns - questionário document snapshot
 */
export function getPerguntasArray(empresaId: TEmpresa["id"], grupos: Array<string> | null | undefined) {
  const q = generatePerguntaQuery(empresaId, grupos);
  return getDocs(q);
}
