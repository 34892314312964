import { NavLink, NavLinkProps, createPolymorphicComponent } from "@mantine/core";
import { forwardRef } from "react";
import { useMatch } from "react-router-dom";
import navLinkMenuStyles from "./NavLinkMenu.module.css";

type NavLinkMenuProperties = Omit<NavLinkProps, "styles" | "variant"> & {
  to?: string;
};

const _NavLinkMenu = forwardRef<HTMLAnchorElement, NavLinkMenuProperties>(({ ...navLinkProperties }, reference) => {
  const active = useMatch(navLinkProperties.to || "");

  return (
    <NavLink
      variant="filled"
      color="white.0"
      {...navLinkProperties}
      classNames={{ root: navLinkMenuStyles.root, body: navLinkMenuStyles.body }}
      active={navLinkProperties.to ? !!active : false}
      ref={reference}
    />
  );
});

_NavLinkMenu.displayName = "NavLinkMenu";

const NavLinkMenu = createPolymorphicComponent<"a", NavLinkMenuProperties>(_NavLinkMenu);

export default NavLinkMenu;
