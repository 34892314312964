import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { getActiveRegulamentosIdeias, REGULAMENTOS_IDEIAS_COLLECTION_KEY } from "../firestore";

type TGetRegulamentoIdeiasResponse = Awaited<ReturnType<typeof getActiveRegulamentosIdeias>>;
type TQueryOptions<T = TGetRegulamentoIdeiasResponse> = Omit<
  UseQueryOptions<TGetRegulamentoIdeiasResponse, FirestoreError, T>,
  "queryKey" | "queryFn"
>;

/**
 * Get Regulamentos ativos para a empresa selecionada
 * @param options - Query options
 * @returns - Regulamentos ativos
 */
function useGetActiveRegulamentosIdeias<T = TGetRegulamentoIdeiasResponse>(options: TQueryOptions<T> = {}) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  return useQuery<TGetRegulamentoIdeiasResponse, FirestoreError, T>({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: REGULAMENTOS_IDEIAS_COLLECTION_KEY,
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      return getActiveRegulamentosIdeias(activeEmpresaId);
    },
    enabled: !!activeEmpresaId,
    ...options
  });
}

export default useGetActiveRegulamentosIdeias;
