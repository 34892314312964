/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react-perf/jsx-no-new-function-as-prop */
/* eslint-disable react-perf/jsx-no-new-array-as-prop */
import { Button, Flex, Group, Loader, Select, Text, TextInput } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useEffect, useState } from "react";
import CepInput from "@/components/cep-input";
import CNPJInput from "@/components/cnpj-input";
import TelInput from "@/components/tel-input";
import { useGetMunicipiosByUf } from "@/hooks/ibge/useGetMunicipiosByUf";
import {
  porteData,
  numeroColaboradoresData,
  autocadastroOriginData,
  EmpresaInputs
} from "@/modules/empresas/schema/empresa-schema";
import { estadosData } from "@/modules/empresas/schema/endereco-schema";
import { getEmpresaDataByCnpj } from "@/services/brasilapi";
import { extractTextInputPropertiesFromFormSchema } from "@/utils/form";
import { useAutocadastroForm } from "../../hooks/useAutocadastroForm";
import { useGetEmbaixadoresAutocadastro } from "../../hooks/useGetEmbaixadoresAutocadastro";
import { useGetEmbaixadoresPessoasAutocadastro } from "../../hooks/useGetEmbaixadoresPessoasAutocadastro";

const TAM_CNPJ = 14;

type AutocadastroEmpresaFormProperties = {
  empresaId: TEmpresa["id"];
  projeto: TProjeto;
  loading: boolean;
  licenciadaId?: TLicenciada["id"] | undefined;
  handleSubmit: (empresa: EmpresaInputs) => Promise<void>;
};

/**
 * Arquivo de formulário de cadastro de empresa
 * @param root0 - The root object
 * @param root0.empresaId - The empresaId object
 * @param root0.projeto - The projeto object
 * @param root0.loading - The loading state
 * @param root0.handleSubmit - The handleSubmit function
 * @param root0.licenciadaId - The licenciadaId object
 * @returns JSX.Element com o conteúdo da página
 */
export function AutocadastroEmpresaForm({
  empresaId,
  projeto,
  loading,
  handleSubmit,
  licenciadaId
}: AutocadastroEmpresaFormProperties) {
  const { form, empresaDescriptor, enderecoDescriptor, responsavelDescriptor } = useAutocadastroForm();
  const { embaixadoresData } = useGetEmbaixadoresAutocadastro(empresaId, projeto, licenciadaId);
  const { embaixadoresPessoasData } = useGetEmbaixadoresPessoasAutocadastro(empresaId, projeto, licenciadaId);
  const [isFetchingCnpj, setIsFetchingCnpj] = useState(false);

  const { municipios, fetch } = useGetMunicipiosByUf();

  const onSubmit = form.onSubmit(
    (values) => {
      values.role = "CONVIDADA";
      !loading && handleSubmit(values);
    },
    (error) => {
      showNotification({
        title: "Verifique os campos do formulário",
        message: error.message,
        color: "red"
      });
    }
  );

  const setEmpresaDataByCnpj = async (cnpj: TEmpresa["cnpj"]) => {
    try {
      setIsFetchingCnpj(true);
      const data = await getEmpresaDataByCnpj(cnpj);
      if (!data || !data.razao_social || data.razao_social === "ERRO") {
        // Se tiver algum problema de dados, exibe notificação
        showNotification({
          message:
            data?.razao_social === "ERRO"
              ? "Base de consulta indisponível no momento. Cadastre os dados manualmente."
              : `O CNPJ ${cnpj} não foi encontrado na base nacional de empresas.`,
          color: "red",
          autoClose: 2500
        });
        return;
      }

      form.setValues((values: EmpresaInputs) => ({
        ...values,
        nomeFantasia: data.nome_fantasia,
        razaoSocial: data.razao_social,
        email: form.values.email || data.email || ("" as TEmpresa["email"]),
        telefone: data.ddd_telefone_1,
        codigoCNAE: data.cnae_fiscal.toString(),
        cnae: data.cnae_fiscal_descricao,
        endereco: {
          cep: data.cep,
          logradouro: data.logradouro,
          numero: data.numero,
          complemento: data.complemento as TEmpresa["endereco"]["complemento"],
          bairro: data.bairro,
          cidade: data.municipio,
          estado: data.uf as TEstado
        }
      }));
    } finally {
      setIsFetchingCnpj(false);
    }
  };

  useEffect(() => {
    const cnpjInput = form.getInputProps("cnpj");
    const cnpjValue = cnpjInput.value as string;

    if (!cnpjValue) {
      return;
    }

    const cnpj = cnpjValue.replaceAll(/\D/g, "");
    if (cnpj.length === TAM_CNPJ) {
      setEmpresaDataByCnpj(cnpj);
    }
  }, [form.getInputProps("cnpj").value]);

  useEffect(() => {
    const { estado } = form.values.endereco;
    if (estado) {
      fetch(estado);
    }
  }, [form.values.endereco.estado]);

  const municipiosData = municipios.map((municipio) => municipio.nome);

  return (
    <form onSubmit={onSubmit}>
      <Flex direction="column" gap="md">
        <Flex direction="column" gap="lg">
          <Text fw="bold">De onde você veio?</Text>
          <Group gap="md">
            <Select
              style={{ flex: 1 }}
              data={autocadastroOriginData}
              {...form.getInputProps("autocadastroOrigin")}
              {...extractTextInputPropertiesFromFormSchema(empresaDescriptor, "autocadastroOrigin")}
              label={`Como você conheceu o projeto ${projeto.nome}?`}
            />
            <Select
              style={{ flex: 1 }}
              data={embaixadoresData}
              {...form.getInputProps("embaixadorReference")}
              {...extractTextInputPropertiesFromFormSchema(empresaDescriptor, "embaixadorReference")}
              label="Sua empresa é vinculada à alguma entidade ou associação?"
            />
            <Select
              style={{ flex: 1 }}
              data={embaixadoresPessoasData}
              {...form.getInputProps("embaixadorPessoaReference")}
              {...extractTextInputPropertiesFromFormSchema(empresaDescriptor, "embaixadorPessoaReference")}
              label="A sua inscrição foi indicada por um de nossos embaixadores?"
            />
          </Group>

          <Text fw="bold">Dados da empresa</Text>
          <Group gap="md">
            <CNPJInput
              rightSection={isFetchingCnpj ? <Loader size="xs" /> : null}
              {...form.getInputProps("cnpj")}
              {...extractTextInputPropertiesFromFormSchema(empresaDescriptor, "cnpj")}
            />
            <TextInput
              type="text"
              style={{ flex: 1 }}
              {...form.getInputProps("razaoSocial")}
              {...extractTextInputPropertiesFromFormSchema(empresaDescriptor, "razaoSocial")}
            />
          </Group>
          <Group gap="md">
            <TextInput
              type="text"
              style={{ flex: 1 }}
              {...form.getInputProps("nomeFantasia")}
              {...extractTextInputPropertiesFromFormSchema(empresaDescriptor, "nomeFantasia")}
            />
            <TextInput
              type="email"
              style={{ flex: 1 }}
              {...form.getInputProps("email")}
              {...extractTextInputPropertiesFromFormSchema(empresaDescriptor, "email")}
            />
            <TelInput
              w="140px"
              {...form.getInputProps("telefone")}
              {...extractTextInputPropertiesFromFormSchema(empresaDescriptor, "telefone")}
            />
          </Group>
          <Group gap="md">
            <TextInput
              {...form.getInputProps("codigoCNAE")}
              {...extractTextInputPropertiesFromFormSchema(empresaDescriptor, "codigoCNAE")}
            />
            <TextInput
              style={{ flex: 1 }}
              {...form.getInputProps("cnae")}
              {...extractTextInputPropertiesFromFormSchema(empresaDescriptor, "cnae")}
            />
          </Group>
          <Group gap="md">
            <Select
              data={porteData}
              {...form.getInputProps("porte")}
              {...extractTextInputPropertiesFromFormSchema(empresaDescriptor, "porte")}
            />
            <Select
              data={numeroColaboradoresData}
              {...form.getInputProps("numeroColaboradores")}
              {...extractTextInputPropertiesFromFormSchema(empresaDescriptor, "numeroColaboradores")}
            />
            <Select
              data={(projeto && projeto.categoria) || []}
              {...form.getInputProps("categorias")}
              {...extractTextInputPropertiesFromFormSchema(empresaDescriptor, "categorias")}
              label="Categoria/Segmento"
            />
          </Group>

          <Text fw="bold">Endereço da empresa</Text>

          <Group gap="md" wrap="wrap">
            <CepInput
              w="100px"
              {...form.getInputProps("endereco.cep")}
              {...extractTextInputPropertiesFromFormSchema(enderecoDescriptor, "cep")}
            />
            <TextInput
              type="text"
              style={{ flex: "1 0 96px" }}
              {...form.getInputProps("endereco.logradouro")}
              {...extractTextInputPropertiesFromFormSchema(enderecoDescriptor, "logradouro")}
            />
            <TextInput
              type="text"
              w="100px"
              {...form.getInputProps("endereco.numero")}
              {...extractTextInputPropertiesFromFormSchema(enderecoDescriptor, "numero")}
            />
            <TextInput
              type="text"
              w="150px"
              {...form.getInputProps("endereco.complemento")}
              {...extractTextInputPropertiesFromFormSchema(enderecoDescriptor, "complemento")}
            />
          </Group>
          <Group gap="md" wrap="wrap">
            <Select
              data={estadosData}
              style={{ flex: "1 0 96px" }}
              {...form.getInputProps("endereco.estado")}
              {...extractTextInputPropertiesFromFormSchema(enderecoDescriptor, "estado")}
            />
            <Select
              data={municipiosData}
              type="text"
              style={{ flex: "1 0 128px" }}
              searchable
              {...form.getInputProps("endereco.cidade")}
              {...extractTextInputPropertiesFromFormSchema(enderecoDescriptor, "cidade")}
            />
            <TextInput
              type="text"
              style={{ flex: "1 0 128px" }}
              {...form.getInputProps("endereco.bairro")}
              {...extractTextInputPropertiesFromFormSchema(enderecoDescriptor, "bairro")}
            />
          </Group>

          <Text fw="bold">Principal Contato Com Equipe do Prêmio</Text>

          <Group gap="md" wrap="wrap">
            <TextInput
              type="text"
              style={{ flex: "1 0 160px" }}
              {...form.getInputProps("responsavel.nome")}
              {...extractTextInputPropertiesFromFormSchema(responsavelDescriptor, "nome")}
              label="Nome do responsável"
            />
            <TextInput
              type="text"
              style={{ flex: "1 0 160px" }}
              {...form.getInputProps("responsavel.email")}
              {...extractTextInputPropertiesFromFormSchema(responsavelDescriptor, "email")}
              label="E-mail do responsável"
            />
            <TelInput
              style={{ flex: "1 0 160px" }}
              {...form.getInputProps("responsavel.telefone")}
              {...extractTextInputPropertiesFromFormSchema(responsavelDescriptor, "telefone")}
              label="WhatsApp do responsável"
            />
          </Group>
        </Flex>

        <Button disabled={loading} type="submit" ms="auto" w={100}>
          Finalizar
        </Button>
      </Flex>
    </form>
  );
}
