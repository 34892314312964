import { Text, FocusTrap } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import InviteColaboradorForm from "../../components/invite-colaborador-form";

const InviteColaboradorModal = ({ context, id }: ContextModalProps) => {
  /**
   * Handle add ignore area success
   */
  function onSuccess() {
    context.closeModal(id);
  }
  return (
    <FocusTrap active={true}>
      <Text fw="bold" mb="md">
        {/* Enviar convite */}
      </Text>
      <InviteColaboradorForm onSuccess={onSuccess} />
    </FocusTrap>
  );
};

export default InviteColaboradorModal;
