import { Container, Flex, Slider } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { Timestamp } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import { captureException } from "@/services/log";
import OptionsButtons from "./OptionsButtons";
import OptionsFormat from "./OptionsFormat";
import { useSendResposta } from "../../hooks/useSendResposta";

interface ArrayProperties {
  nome?: string;
  value: number;
  descricao: string;
  id: string;
  tipoDeResposta: string;
  resposta?: string | number | TCampo | TCampo[] | boolean;
  pergunta?: boolean;
  grupo: string;
  obrigatoria: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const findClosestValue = (array: any[], target: number) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, unicorn/no-array-reduce
  return array.reduce((previous: number, current: number) =>
    Math.abs(current - target) < Math.abs(previous - target) ? current : previous
  );
};

const MAGIC_NUMBER = 100;

const PerguntasTipo = ({
  perguntas,
  questionario,
  resposta,
  licenciado,
  empresaConvidada
}: {
  perguntas: TPergunta[] | undefined;
  questionario: TQuestionario | null | undefined;
  resposta: TResposta[] | undefined | null;
  licenciado: TLicenciado | null | undefined;
  empresaConvidada: string | null | undefined;
}) => {
  const [arrayFormat, setArrayFormat] = useState<ArrayProperties[]>([]);
  const [stepPergunta, setStepPergunta] = useState(0);
  const [pausar, setPausar] = useState(false);

  const perguntasArray = perguntas || [];
  const respostaItem = resposta && resposta[0];

  const idQuestionario = questionario?.id || "";
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  const navigate = useNavigate();
  const user = getCurrentUser();
  const finalStep = 100;

  const { mutate } = useSendResposta(
    activeEmpresaId ?? "",
    {
      id: respostaItem?.id || "",
      pausado: pausar,
      idRespondente: user?.uid,
      idEmpresa: activeEmpresaId || "",
      idQuestionario,
      perguntas: arrayFormat
        .filter((item) => item.pergunta)
        .map((item) => ({ id: item.id, resposta: item.resposta || false, grupo: item?.grupo })),
      deletedAt: null,
      createdBy: questionario?.createdBy || "",
      createdAt: Timestamp.now(),
      stepPausado: stepPergunta
    },
    {
      onSuccess() {
        showNotification({
          color: "green",
          message: `Questionário ${pausar ? "pausado" : "respondido"} com sucesso!`,
          autoClose: 3500
        });
        navigate("/listaResponderQuestionario/0");
      },
      onError(error) {
        captureException(error, true);
      }
    }
  );

  useEffect(() => {
    if (resposta && resposta.find((item) => item.pausado === true)?.pausado) {
      setStepPergunta(resposta.find((item) => item.pausado === true)?.stepPausado as number);
    }
  }, [resposta?.find((item) => item.pausado === true)?.stepPausado as number]);

  useEffect(() => {
    if (respostaItem && respostaItem.id) {
      const array = [
        questionario,
        ...(perguntasArray?.map((item) => {
          const dados = respostaItem.perguntas.find((row: TRespostaPergunta) => row?.id === item.id);
          return { ...item, pergunta: true, ...dados };
        }) || [])
      ];
      setArrayFormat(
        array?.map((data: ArrayProperties & TQuestionario & TPergunta, index: number) => ({
          ...data,
          value: data?.pergunta ? (index * finalStep) / perguntasArray?.length : 0
        }))
      );
    } else if (questionario?.id) {
      const array = [questionario, ...(perguntasArray?.map((item) => ({ ...item, pergunta: true })) || [])];
      setArrayFormat(
        array?.map((data: ArrayProperties & TQuestionario & TPergunta, index: number) => ({
          ...data,
          value: data?.pergunta ? (index * finalStep) / (perguntasArray?.length || 1) : 0
        }))
      );
    }
  }, [perguntas, questionario]);

  const handlePrevious = useCallback(() => {
    setStepPergunta((step) =>
      step === 0 ? 0 : step === MAGIC_NUMBER ? 0 : step - finalStep / (perguntasArray?.length || 1)
    );
  }, [perguntasArray, finalStep]);

  const handleStop = useCallback(() => {
    setPausar((stop) => !stop);
    mutate();
  }, [pausar]);

  const handleNext = useCallback(() => {
    if (
      arrayFormat?.filter((item) => item.resposta && item.obrigatoria).length ===
        perguntas?.filter((item) => item.obrigatoria).length &&
      Number.parseInt(stepPergunta.toFixed(0)) === finalStep
    ) {
      setPausar(false);
      mutate();
    } else {
      setStepPergunta((step) => (step === finalStep ? finalStep : step + finalStep / perguntasArray?.length));
    }
  }, [arrayFormat, perguntas, stepPergunta, finalStep]);

  const changePergunta = useCallback(
    (value: number) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const closestValue = findClosestValue(
        arrayFormat.map((item) => item.value),
        value
      );
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setStepPergunta(closestValue);
    },
    [setStepPergunta]
  );

  return (
    <Container fluid h={"60vh"} w={"100vw"} bg="#fff">
      <Slider
        label={null}
        value={stepPergunta}
        marks={arrayFormat}
        styles={{ markLabel: { display: "none" } }}
        step={finalStep / arrayFormat.length}
        onChange={changePergunta}
      />
      <Flex justify={"space-between"} align="center" direction="column" h={"100%"} w={"100%"}>
        <OptionsFormat
          arrayFormat={arrayFormat}
          setArrayFormat={setArrayFormat}
          respostaItem={respostaItem}
          stepPergunta={stepPergunta}
          licenciado={licenciado}
          empresaConvidada={empresaConvidada}
          questionario={questionario}
        />
        <OptionsButtons
          arrayFormat={arrayFormat}
          perguntasArray={perguntasArray}
          respostaItem={respostaItem}
          stepPergunta={stepPergunta}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          handleStop={handleStop}
          questionario={questionario}
        />
      </Flex>
    </Container>
  );
};

export default PerguntasTipo;
