import { TransformedValues, useForm } from "@mantine/form";
import { ObjectSchema } from "yup";
import useCatchFirestoreError from "@/hooks/useCatchFirestoreError";
import schema from "@/schema";
import { extractYupError } from "@/utils/form";
import useGetTotalMoedasPerRegulamento from "./useGetTotalMoedasPerRegulamento";
import useSetPatrocinioMutation from "./useSetPatrocinioMutation";

type TIdeiaPatrocinioForm = Pick<TIdeiaActivityPerColaborador, "moedas">;
const ideiaPatrocinioSchema: ObjectSchema<TIdeiaPatrocinioForm> = schema
  .object({
    moedas: schema
      .number()
      .required()
      .defined()
      .min(0)
      .max(schema.ref("$totalMoedasDisponiveis"))
      .default(0)
      .typeError("${value} não é um valor de ${path} válido")
      .label("Moedas")
  })
  .strict()
  .noUnknown();

type UseIdeiaPatrocinioFormOptions = {
  ideia: TIdeia;
  regulamento: TRegulamentoIdeias;
  ideiaActivity?: TIdeiaActivityPerColaborador | null;
};

/**
 * Use ideia patrocinio form
 * @param options - Options for the hook
 * @param options.ideia - Ideia to be patrocinada
 * @param options.regulamento - Regulamento da ideia a ser patrocinada
 * @param [options.ideiaActivity] - Ideia activity to be updated (if any)
 * @returns - Form props and submit handler
 */
function useIdeiaPatrocinioForm({ ideia, regulamento, ideiaActivity }: UseIdeiaPatrocinioFormOptions) {
  const {
    data: totalMoedasGastas,
    error: totalMoedasGastasError,
    isLoading: loadingTotalMoedasGastas
  } = useGetTotalMoedasPerRegulamento(regulamento);
  useCatchFirestoreError(totalMoedasGastasError);

  const qtdMoedasGastasNestaIdeia = ideiaActivity?.moedas || 0;
  const totalMoedasPeloRegulamento = regulamento.quantidadeMoedasPorColaborador || 0;
  const totalMoedasDisponiveis = totalMoedasPeloRegulamento - (totalMoedasGastas || 0) + qtdMoedasGastasNestaIdeia;
  const context = { totalMoedasDisponiveis };

  const form = useForm<TIdeiaPatrocinioForm>({
    validate: (values) => {
      try {
        ideiaPatrocinioSchema.validateSync(values, { abortEarly: false, context });
        return {};
      } catch (yupError) {
        return extractYupError(yupError as YupValidationResult);
      }
    },
    transformValues: (values) => ideiaPatrocinioSchema.cast(values, { context }),
    initialValues: ideiaPatrocinioSchema.cast(ideiaActivity || { moedas: 0 }, { context })
  });

  const { mutate, isPending: isUpdatingPatrocinio } = useSetPatrocinioMutation(ideia, regulamento.id);

  type TFormValues = TransformedValues<typeof form>;

  /**
   * Handle form submit
   * @param values - Form values
   */
  function onSubmit(values: TFormValues) {
    mutate(values);
  }

  return { ...form, isLoading: isUpdatingPatrocinio || loadingTotalMoedasGastas, onSubmit: form.onSubmit(onSubmit) };
}

export default useIdeiaPatrocinioForm;
