import { Text, Alert, Center, Stack, Card } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconAlertCircle } from "@tabler/icons-react";
import { QueryKey } from "@tanstack/react-query";
import { Table as ReactTable } from "@tanstack/react-table";
import { forwardRef, useImperativeHandle } from "react";
import NoData from "@/components/no-data";
import Table from "@/components/table";
import ResetTableButton from "@/components/table/ResetTableButton";
import { THandler } from "@/components/table/Table";
import TablePagination from "@/components/table/TablePagination";
import useQueryTable from "@/components/table/useQueryTable";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import regulamentoIdeiasColumns, { regulamentoColumnMobile } from "./Columns";
import {
  DEFAULT_PAGINATION,
  DEFAULT_SORTING,
  REGULAMENTOS_IDEIAS_COLLECTION_KEY,
  getPaginatedRegulamentosIdeias
} from "../../firestore";

const RegulamentosIdeiasTable = forwardRef<THandler<TRegulamentoIdeias>>((_properties, reference) => {
  const uid = getCurrentUser()?.uid;
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const isSmMarches = useMediaQuery("(max-width: 48em)");
  const newColumns = isSmMarches ? regulamentoColumnMobile : regulamentoIdeiasColumns;

  const { table, data, isLoading, error, filters, refetch } = useQueryTable<TRegulamentoIdeias>({
    queryKey: [...REGULAMENTOS_IDEIAS_COLLECTION_KEY],
    enabled: !!uid,
    subjectType: "TRegulamentoIdeias",
    columns: newColumns,
    initialState: {
      columnFilters: [],
      globalFilter: "",
      pagination: DEFAULT_PAGINATION,
      sorting: DEFAULT_SORTING
    },
    queryFunction: ({ sorting, filters, pagination, paginationCursors }) => {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return getPaginatedRegulamentosIdeias(activeEmpresaId, {
        sorting,
        filters,
        pagination,
        paginationCursors
      });
    }
  });

  useImperativeHandle<THandler<TRegulamentoIdeias>, THandler<TRegulamentoIdeias>>(
    reference,
    () => {
      return {
        table,
        data,
        isLoading,
        error,
        filters
      };
    },
    [filters, data, error, isLoading, table]
  );

  /**
   * Handles the close of the error alert.
   */
  function handleErrorClose() {
    // queryClient.removeQueries({ queryKey: [REGULAMENTOS_IDEIAS_COLLECTION_KEY] });
    // remove();
    void refetch();
  }

  if (error) {
    return (
      <Alert
        icon={<IconAlertCircle size="1rem" />}
        title="Erro ao buscar regulamentos"
        color="red"
        withCloseButton
        onClose={handleErrorClose}
      >
        {error.message}
      </Alert>
    );
  }

  const isEmpty = !isLoading && data?.entries.length === 0;

  return (
    <>
      <Card.Section withBorder>
        <Table table={table} isLoading={isLoading} />
        {isEmpty && !isLoading && (
          <Center m="lg">
            <NoData m="md">
              <Stack gap="xs">
                <Text c="dimmed">Nenhum Regulamento Cadastrado!</Text>
                {filters.length > 0 && (
                  <ResetTableButton
                    table={table as ReactTable<unknown>}
                    queryKey={REGULAMENTOS_IDEIAS_COLLECTION_KEY as unknown as QueryKey}
                  >
                    Limpar filtros
                  </ResetTableButton>
                )}
              </Stack>
            </NoData>
          </Center>
        )}
      </Card.Section>
      {reference && "current" in reference && reference.current !== null ? (
        <Card.Section withBorder>
          <TablePagination tableHandlers={reference.current} />
        </Card.Section>
      ) : null}
    </>
  );
});

RegulamentosIdeiasTable.displayName = "RegulamentosIdeiasTable";

export default RegulamentosIdeiasTable;
