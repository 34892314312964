import { Text, Button, Group, Code, ButtonProps } from "@mantine/core";
import { spotlight } from "@mantine/spotlight";
import { IconSearch } from "@tabler/icons-react";
import { MouseEvent } from "react";
import codeStyle from "./Code.module.css";

/**
 * Handle spotlight button click
 * @param event - Click event
 */
function handleSpotlightButtonClick(event: MouseEvent<HTMLButtonElement>) {
  event.preventDefault();
  spotlight.toggle();
}

const SpotlightButton = (properties: ButtonProps) => {
  return (
    <Button
      type="button"
      variant="default"
      color="gray.2"
      radius="lg"
      visibleFrom="sm"
      onClick={handleSpotlightButtonClick}
      size="xs"
      leftSection={<IconSearch size="0.8rem" stroke={1.5} color="gray" />}
      {...properties}
    >
      <Group justify="space-between" wrap="nowrap" w={130}>
        <Text c="dimmed" fz="sm">
          Buscar
        </Text>
        <Code className={codeStyle.searchCode}>Ctrl + K</Code>
      </Group>
    </Button>
  );
};

export default SpotlightButton;
