import { ObjectSchema } from "yup";
import schema from "@/schema";

interface LoginFormSchema {
  email: string;
  password: string;
}

const loginFormSchema: ObjectSchema<LoginFormSchema> = schema
  .object()
  .shape({
    email: schema.string().email().required().defined(),
    password: schema.string().required().defined()
  })
  .required()
  .defined();

export default loginFormSchema;
