import { getStatus } from "../../firestore";

/**
 * Get comites de inovação query hook
 * @param root0 - root
 * @param root0.idEmpresaItem - id da empresa
 * @param root0.id -id
 * @param root0.activeEmpresaId - empresa id
 * @returns Comites de inovação query hook
 */
// prettier-ignore
async function getStatusIdEmpresa({ idEmpresaItem, id, activeEmpresaId }: { idEmpresaItem: string, id: string, activeEmpresaId: string }) {
  if (idEmpresaItem && activeEmpresaId) {
    const newData = await getStatus(activeEmpresaId, id, idEmpresaItem);
    return newData.docs.map((desafio) => desafio.data());
  }

  return [];
}

export default getStatusIdEmpresa;
