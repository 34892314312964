import { Text, Alert, Center, Stack, Card, ScrollArea } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { QueryKey } from "@tanstack/react-query";
import { Table as ReactTable } from "@tanstack/react-table";
import { forwardRef, useImperativeHandle, useEffect } from "react";
import NoData from "@/components/no-data";
import Table from "@/components/table";
import ResetTableButton from "@/components/table/ResetTableButton";
import { THandler } from "@/components/table/Table";
import TablePagination from "@/components/table/TablePagination";
import useQueryTable from "@/components/table/useQueryTable";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import tableColumns from "./Columns";
import { DEFAULT_PAGINATION, DEFAULT_SORTING, IDEIAS_COLLECTION_KEY, getPaginatedIdeias } from "../firestore";

interface IdeiasRelatorioVisaoGeralPageProperties {
  onTableReady?: () => void;
}

const IdeiasRelatorioVisaoGeralPage = forwardRef<THandler<TIdeia>, IdeiasRelatorioVisaoGeralPageProperties>(
  (properties, reference) => {
    const { onTableReady } = properties;
    const uid = getCurrentUser()?.uid;
    const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
    const { table, data, isLoading, error, filters, refetch } = useQueryTable<TIdeia>({
      queryKey: [activeEmpresaId, IDEIAS_COLLECTION_KEY] as QueryKey,
      enabled: !!uid,
      subjectType: "TIdeia",
      columns: tableColumns,
      initialState: {
        columnFilters: [],
        globalFilter: null,
        pagination: DEFAULT_PAGINATION,
        sorting: DEFAULT_SORTING
      },
      queryFunction: ({ sorting, globalFilters, filters, pagination, paginationCursors }) => {
        if (!activeEmpresaId) {
          throw new Error("Empresa não selecionada");
        }

        return getPaginatedIdeias(activeEmpresaId, {
          sorting,
          filters,
          globalFilters,
          pagination,
          paginationCursors
        });
      }
    });

    useImperativeHandle<THandler<TIdeia>, THandler<TIdeia>>(
      reference,
      () => {
        return {
          table,
          data,
          isLoading,
          error,
          filters
        };
      },
      [filters, data, error, isLoading, table]
    );

    useEffect(() => {
      if (!isLoading && data && onTableReady) {
        onTableReady();
      }
    }, [isLoading, data, onTableReady]);

    /**
     *
     */
    function handleErrorClose() {
      void refetch();
    }

    if (error) {
      return (
        <Alert
          icon={<IconAlertCircle size="1rem" />}
          title="Erro ao buscar ideias!"
          color="red"
          withCloseButton
          onClose={handleErrorClose}
        >
          {error.message}
        </Alert>
      );
    }

    const isEmpty = !isLoading && data?.entries.length === 0;
    return (
      <>
        <Card.Section withBorder>
          <ScrollArea>
            <Table table={table} isLoading={isLoading} />
          </ScrollArea>
          {isEmpty && !isLoading && (
            <Center m="lg">
              <NoData m="md">
                <Stack gap="xs">
                  <Text c="dimmed">Nenhuma ideia encontrado com o filtro aplicado!</Text>
                  {filters.length > 0 && (
                    <ResetTableButton
                      table={table as ReactTable<unknown>}
                      queryKey={IDEIAS_COLLECTION_KEY as unknown as QueryKey}
                    >
                      Limpar filtros
                    </ResetTableButton>
                  )}
                </Stack>
              </NoData>
            </Center>
          )}
        </Card.Section>
        {reference && "current" in reference && reference.current !== null ? (
          <Card.Section withBorder>
            <TablePagination tableHandlers={reference.current} />
          </Card.Section>
        ) : null}
      </>
    );
  }
);

IdeiasRelatorioVisaoGeralPage.displayName = "RelatorioVisaoGeralIdeiasTable";

export default IdeiasRelatorioVisaoGeralPage;
