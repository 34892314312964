import { TransformedValues, useForm, yupResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { Asserts } from "yup";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { addTipoDeJogada, QUERY_KEY, TTipoDeJogadaForm, updatedTipoDeJogada } from "../../firestore";
import tipoDeJogadaSchema from "../../schema/tipo-de-jogada-schema";

type FormInputs = Asserts<typeof tipoDeJogadaSchema>;
type TUpdateResponse = Awaited<ReturnType<typeof updatedTipoDeJogada>>;
type TAddResponse = Awaited<ReturnType<typeof addTipoDeJogada>>;

// type TMutationOptions = Omit<
//   MutationOptions<TUpdateResponse | TStartComiteResponse, FirestoreError, TTipoDeJogadaForm>,
//   "mutationFn"
// >;
const useTipoDeJogadaForm = (
  data: TTipoDeJogadaForm & Partial<Pick<TTipoDeJogada, "id" | "refPath">>,
  {
    onSuccess,
    onError
  }: Pick<UseMutationOptions<TUpdateResponse | TAddResponse, FirestoreError, FormInputs>, "onSuccess" | "onError">
  // mutationOptions: TMutationOptions = {}
) => {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);
  const queryClient = useQueryClient();
  const form = useForm<FormInputs>({
    validate: yupResolver(tipoDeJogadaSchema),
    initialValues: data?.id ? (data as TTipoDeJogadaForm) : (tipoDeJogadaSchema.getDefault() as TTipoDeJogadaForm),
    transformValues: (values) => tipoDeJogadaSchema.cast(values)
  });
  const { mutate, isPending, reset } = useMutation<TUpdateResponse | TAddResponse, FirestoreError, TTipoDeJogadaForm>({
    mutationFn(values) {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return data?.id
        ? updatedTipoDeJogada(activeEmpresaId, data.id, values)
        : addTipoDeJogada(activeEmpresaId, values);
    },
    async onSuccess(item, variables, context) {
      showNotification({
        message: `${data?.id ? "Atualizado" : "Cadastrado"} com sucesso!`,
        color: "green",
        autoClose: 2500
      });
      reset();
      form.reset();
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      if (onSuccess) {
        return onSuccess(item, variables, context);
      }
      return null;
    },
    // async onSuccess(data, variables, context) {
    //   showNotification({
    //     message: `${variables.nome} ${projeto.id ? "atualizado" : "cadastrado"} com sucesso!`,
    //     color: "green",
    //     autoClose: 2500
    //   });
    //   reset();
    //   form.reset();
    //   await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    //   if (onSuccess) {
    //     return onSuccess(data, variables, context);
    //   }
    //   return null;
    // },
    onError(error, variables, context) {
      captureException(error, true);
      if (onError) {
        onError(error, variables, context);
      }
    }
  });

  type TTransformedValues = TransformedValues<typeof form>;
  /**
   * Handle form submit event
   * @param values - Form values
   */
  function onSubmit(values: TTransformedValues) {
    mutate(values);
  }

  return { ...form, isLoading: isPending, onSubmit: form.onSubmit(onSubmit) };
};

export default useTipoDeJogadaForm;
