import { Avatar, Button, Group, Text } from "@mantine/core";
import { ComponentPropsWithoutRef } from "react";
import { extractInitialsFromText } from "@/utils/text";
import RespostasAoComentarioAvatarsLoading from "./RespostasAoComentarioAvatarsLoading";
import useGetComentariosRespostas from "./useGetComentariosRespostas";

const MAX_COMENTARIOS_COUNT = 4;

type RespostasAoComentarioAvatarsProperties = ComponentPropsWithoutRef<"button"> & {
  desafio: TDesafio;
  comentario: TDesafioComentario;
};

const RespostasAoComentarioAvatars = ({
  desafio,
  comentario,
  ...buttonProperties
}: RespostasAoComentarioAvatarsProperties) => {
  const { isLoading, data: comentarios } = useGetComentariosRespostas(desafio, comentario);

  if (isLoading) {
    return <RespostasAoComentarioAvatarsLoading />;
  }

  const hasRespostas = comentarios && comentarios.length > 0;
  if (!hasRespostas) {
    return null;
  }

  const firstComentarios = comentarios.slice(0, MAX_COMENTARIOS_COUNT);
  const remainingComentarios = comentarios.slice(MAX_COMENTARIOS_COUNT);

  return (
    <Group>
      <Button
        leftSection={
          <Avatar.Group>
            {firstComentarios.map((comentarioRespostas) => (
              <Avatar
                src="image.png"
                key={comentarioRespostas.id || comentarioRespostas.refPath}
                alt={comentarioRespostas.autor.nome || comentarioRespostas.autor.email}
              >
                {extractInitialsFromText(comentarioRespostas.autor.nome || comentarioRespostas.autor.email)}
              </Avatar>
            ))}
            {remainingComentarios.length > 0 && <Avatar>+{remainingComentarios.length}</Avatar>}
          </Avatar.Group>
        }
        variant="default"
        type="button"
        size="lg"
        {...buttonProperties}
      >
        <Text c="blue" fw={500}>
          {comentarios.length} {comentarios.length > 1 ? "respostas" : "resposta"}
        </Text>
      </Button>
    </Group>
  );
};

export default RespostasAoComentarioAvatars;
