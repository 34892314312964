import { RowData } from "@tanstack/react-table";
import { where } from "firebase/firestore";
import { RefObject, useEffect } from "react";
import { THandler } from "@/components/table/Table";
import { getCurrentUser } from "@/services/auth";

/**
 * Set the global filter to the table to only show non deleted items
 * @description
 * This is useful to avoid showing deleted items in the table
 * @param tableReference - Reference to the table handlers object
 */
function useNonDeletedGlobalFilters<T extends RowData>(tableReference: RefObject<THandler<T>>) {
  const colaboradorId = getCurrentUser()?.uid;

  useEffect(() => {
    const tableHandlers = tableReference.current;
    if (tableHandlers && tableHandlers.table.getState().globalFilter === null) {
      tableHandlers.table.setGlobalFilter(where("deletedAt", "==", null));
    }
  }, [colaboradorId, tableReference]);
}

export default useNonDeletedGlobalFilters;
