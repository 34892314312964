import { subject } from "@casl/ability";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import {
  FirestoreDataConverter,
  Timestamp,
  collection,
  getDocs,
  addDoc,
  serverTimestamp,
  doc,
  updateDoc,
  getCountFromServer,
  getDoc
} from "firebase/firestore";
import { getCurrentUser } from "@/services/auth";
import { generateQuery } from "@/utils/firestore";

import { getEmpresaDocumentReference } from "../empresas/firestore";

export const QUERY_KEY = "categoriaDeInovacao";
const DEFAULT_LIMIT = 10;
export const DEFAULT_SORTING: SortingState = [{ id: "nome", desc: false }];
export const DEFAULT_PAGINATION: PaginationState = { pageIndex: 0, pageSize: DEFAULT_LIMIT };
const BASE_FILTER: ColumnFiltersState = [
  {
    id: "deletedAt",
    value: ["==", null]
  }
];
export type TTCategoriaDeInovacao = Pick<TAuditForm, "createdBy" | "updatedBy" | "createdAt" | "updatedAt"> & {
  startedAt: ReturnType<typeof serverTimestamp>;
  pausedAt: ReturnType<typeof serverTimestamp> | null;
  endedAt: ReturnType<typeof serverTimestamp> | null;
};

type TCategoriaDeInovacaoDocument = TAuditDocument &
  Omit<TCategoriaDeInovacao, keyof TAudit | keyof TAuditDocument | "startedAt" | "pausedAt" | "endedAt"> & {
    nome: string;
    descricao: string;
    createdAt: Timestamp;
    refPath: string;
    deletedAt: Date | null;
    startedAt: Timestamp;
    pausedAt: Timestamp | null;
    endedAt: Timestamp | null;
  };

export type TcategoriaDeInovacaoFormFields = Omit<
  TCategoriaDeInovacao,
  "id" | "createdAt" | "updatedAt" | "createdBy" | "updatedBy" | "startedAt" | "pausedAt" | "endedAt" | "deletedAt"
> &
  Partial<Pick<TCategoriaDeInovacao, "id">>;

type TTCategoriaInovacaoDatabaseFields = Pick<TAuditForm, "createdBy" | "updatedBy" | "createdAt" | "updatedAt"> & {
  startedAt: ReturnType<typeof serverTimestamp>;
  pausedAt: ReturnType<typeof serverTimestamp> | null;
  endedAt: ReturnType<typeof serverTimestamp> | null;
};

export type TCategoriaInovacaoForm = TcategoriaDeInovacaoFormFields & TTCategoriaInovacaoDatabaseFields;

const categoriaDeInovacaoConverter: FirestoreDataConverter<TCategoriaDeInovacao> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const { nome, descricao, updatedAt, deletedAt, createdAt, endedAt, startedAt, pausedAt, refPath, ...document } =
      snap.data() as TCategoriaDeInovacaoDocument;

    const data: TCategoriaDeInovacao = subject("TCategoriaDeInovacao", {
      ...document,
      id: snap.id,
      nome,
      descricao,
      deletedAt,
      refPath,
      createdAt: createdAt.toDate(),
      startedAt: startedAt.toDate(),
      endedAt: endedAt ? endedAt.toDate() : null,
      pausedAt: pausedAt ? pausedAt.toDate() : null
    });

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }
    return subject("TCategoriaDeInovacao", data);
  }
};

/**
 * Get all categorias de inovação
 * @param empresaId - Empresa id
 * @returns - tipos de inovação collection reference
 */
function getCategoriaDeInovacaoCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, "categoriaDeInovacao");
}

/**
 * Get categorias de inovação for a empresa
 * @param  empresaId - Empresa id to get comitês
 * @param  root0 - Objeto contendo opções como sorting, filters, pagination, paginationCursors
 * @param  root0.sorting - Sorting option for categorias de inovação
 * @param  root0.filters - Filters for categorias de inovação
 * @param  root0.pagination - Pagination options for categorias de inovação
 * @param root0.paginationCursors - Pagination cursors for categorias de inovação
 * @returns - Tipos de inovação document snapshot
 */
export function getCategoriasDeInovacao(
  empresaId: TEmpresa["id"],
  {
    sorting = [],
    filters = [],
    pagination = { pageIndex: 0, pageSize: DEFAULT_LIMIT },
    paginationCursors
  }: TPaginatedQueryOptions<TCategoriaDeInovacao> = {}
): Promise<TPaginatedQueryResponse<TCategoriaDeInovacao>> {
  const projetosCollectionReference =
    getCategoriaDeInovacaoCollectionReference(empresaId).withConverter(categoriaDeInovacaoConverter);

  const allFilters = [...BASE_FILTER, ...filters];
  const qAll = generateQuery(projetosCollectionReference, { filters: allFilters });
  const qPaginated = generateQuery(projetosCollectionReference, {
    sorting,
    filters: allFilters,
    pagination,
    paginationCursors
  });

  // eslint-disable-next-line compat/compat
  return Promise.all([getDocs(qPaginated), getCountFromServer(qAll)]);
}

/**
 * Start a new categoria de inovação for a empresa
 * @param empresaId - Empresa id to start a new tipo de inovação
 * @param values - tipo de inovação form fields
 * @returns - tipo de inovação document reference
 */
export function addCategoriaDeInovacao(empresaId: TEmpresa["id"], values: TcategoriaDeInovacaoFormFields) {
  const categoriaDeInovacaoCollectionReference = getCategoriaDeInovacaoCollectionReference(empresaId);

  return addDoc(categoriaDeInovacaoCollectionReference, {
    ...values,
    deletedAt: null
  });
}
/**
 * Get categoria de inovação reference
 * @param empresaId - Empresa id to start a new tipo de inovação
 * @param categoriaDeInovacaoId - tipo de inovação form fields
 * @returns - tipo de inovação document reference
 */
function getCategoriaInovacaoDocumentReference(
  empresaId: TEmpresa["id"],
  categoriaDeInovacaoId: TCategoriaDeInovacao["id"]
) {
  const categoriaDeInovacaoCollectionReference = getCategoriaDeInovacaoCollectionReference(empresaId);
  return doc(categoriaDeInovacaoCollectionReference, categoriaDeInovacaoId);
}

/**
 * delete categoria de inovação
 * @param empresaId - Empresa id to start a new tipo de inovação
 * @param categoriaDeInovacaoId - tipo de inovação form fields
 * @returns - tipo de inovação delete
 */
export function deleteCategoriaDeInovacao(empresaId: TEmpresa["id"], categoriaDeInovacaoId: TEmpresa["id"]) {
  const tipoDocumentReference = getCategoriaInovacaoDocumentReference(empresaId, categoriaDeInovacaoId);
  return updateDoc(tipoDocumentReference, {
    deletedAt: serverTimestamp()
  });
}

/**
 * updated categoria de inovação
 * @param empresaId - Empresa id to start a new tipo de inovação
 * @param categoriaDeInovacaoId - tipo de inovação form fields
 * @param values - values to update
 * @returns - tipo de inovação delete
 */
export function updatedCategoriaDeInovacao(
  empresaId: TEmpresa["id"],
  categoriaDeInovacaoId: TProjeto["id"],
  values: TcategoriaDeInovacaoFormFields
) {
  const currentUserId = getCurrentUser()?.uid;
  const tipoDocumentReference = getCategoriaInovacaoDocumentReference(empresaId, categoriaDeInovacaoId);

  return updateDoc(tipoDocumentReference, {
    ...values,
    updatedAt: serverTimestamp(),
    updatedBy: currentUserId
  });
}

/**
 * Get all tipos de inovação
 * @param empresaId - Empresa id
 * @returns - tipos de inovação collection reference
 */
function getCategoriaCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, "categoriaDeInovacao");
}

/**
 * Get tipo de inovação reference
 * @param empresaId - Empresa id to start a new tipo de inovação
 * @param categoriaId - tipo de inovação form fields
 * @returns - tipo de inovação document reference
 */
function getTipoInovacaoDocumentReference(empresaId: TEmpresa["id"], categoriaId: TTipoDeInovacao["id"]) {
  const categoriaCollectionReference = getCategoriaCollectionReference(empresaId);
  return doc(categoriaCollectionReference, categoriaId);
}

/**
 * Get tipo de inovação item
 * @param empresaId - Empresa id to start a new tipo de inovação
 * @param categoriaId - tipo de inovação form fields
 * @returns - tipo de inovação document item
 */
export function getCategoriaItem(empresaId: TEmpresa["id"], categoriaId: TTipoDeInovacao["id"]) {
  const categoriaDocumentReference = getTipoInovacaoDocumentReference(empresaId, categoriaId);
  return getDoc(categoriaDocumentReference.withConverter(categoriaDeInovacaoConverter));
}
