import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import {
  RANKING_ANO_PER_COLABORADOR_KEY,
  getRankingAnoPerColaboradorDocumentsSnapshot
} from "@/modules/ranking-de-colaboradores/firestore";
import useUserStore from "@/modules/users/store";

type TGetRankingAnoPerColaborador = Awaited<ReturnType<typeof getRankingAnoPerColaboradorDocumentsSnapshot>>;

/**
 * Get historicoPontosPerColaborador from the current year from the active empresa
 * @param year - The year to get the historicoPontosPerColaborador
 * @returns - historicoPontosPerColaborador from the current year from the active empresa
 */
function useGetRankingAnoPerColaborador(year: number) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  return useQuery<TGetRankingAnoPerColaborador, FirestoreError, TRankingAnoPerColaborador[]>({
    queryKey: [activeEmpresaId, RANKING_ANO_PER_COLABORADOR_KEY, { year }],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return getRankingAnoPerColaboradorDocumentsSnapshot(activeEmpresaId, year);
    },
    select(data) {
      if (data.empty) {
        return [];
      }

      return data.docs.map((document_) => document_.data());
    }
  });
}

export default useGetRankingAnoPerColaborador;
