import { Card, Group, Text, Button, Flex, Stack, Space, Grid, Badge, ThemeIcon } from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { QueryObserverResult } from "@tanstack/react-query";
import React, { useCallback } from "react";
import BadgeCategoria from "@/modules/jogadas-de-inovacao/components/badge-categoria";
import JogadaDeInovacaoCard from "./HomedeInovacaoCard";
import { useUpdateJogadaDeInovacao } from "../home-de-inovacao-card-modal/useUpdateJogada";

const MAGIC_NUMBER = 1000;

interface Tatica {
  id: string | number;
  nome: string;
  descricao: string;
  nomeCategoria: string;
  nomeTipo: string;
  movimentacao?: [];
}

// prettier-ignore
const HomeDeInovacaoItem: React.FC<{
  jogada: TJogadaDeInovacao;
  refetch: () => Promise<QueryObserverResult>;
  defaultExpand?: boolean;
  formatAutorizacoes: TAutorizacao[] | undefined;
}> = ({ jogada, refetch, defaultExpand, formatAutorizacoes }) => {
  const [opened, { toggle }] = useDisclosure(defaultExpand);
  const { mutate } = useUpdateJogadaDeInovacao();
  const isSmMarches = useMediaQuery("(max-width: 48em)");

  const handleCancel = useCallback(() => {
    modals.openConfirmModal({
      title: "Cancelar jogada de inovação",
      centered: true,
      children: (
        <Text size="sm">
          Tem certeza que deseja cancelar a jogada de inovação?
        </Text>
      ),
      labels: {
        confirm: "Sim",
        cancel: "Não"
      },
      confirmProps: { color: "red" },
      onConfirm: () => {
        delete jogada.refPath;
        mutate({ ...jogada, cancelado: true });
        void refetch();
      }
    });
  }, []);

  const handleConcluir = useCallback(() => {
    delete jogada.refPath;
    mutate({ ...jogada, concluido: true });
    void refetch();
  }, [jogada]);

  return (
    <>
      <Card withBorder radius="lg" style={{ flex: 1, width: onVerification(isSmMarches) }}>
        <Grid align="center">
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
              <Text fw={300} fz="xs">
                Nome da jogada
              </Text>
              <Text fw={500} fz="md">
                {jogada.nome}
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.1 }}>
            <Flex justify="center" direction="column" wrap="wrap">
              <Text tt="capitalize" fz="sm" fw={500} color="black">
                Tipo da jogada
              </Text>
              <BadgeCategoria id={jogada.tipoDeJogada} />
            </Flex>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 1.9 }}>
            <Badge
              radius="md"
              size="xl"
              variant="outline"
              px={10}
              style={{ background: "#e6f0e8" }}
              color="rgba(105, 198, 105, 1)"
            >
              <Group justify="space-between">
                <ThemeIcon color="rgba(105, 198, 105, 1)" radius="lg" size="sm">
                  <Text size="sm">
                    {jogada && jogada.taticas && Array.isArray(jogada.taticas) ? jogada.taticas.length : 0}
                  </Text>
                </ThemeIcon>
                <Text tt="capitalize" fz="sm" fw={500}>
                  Táticas
                </Text>
              </Group>
            </Badge>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.75 }}>
            <Badge radius="md" size="xl" variant="outline" style={{ background: "#F4F5F6" }} color="#C9CDD9" px={10}>
              <Group justify="space-between">
                <ThemeIcon color="#C9CDD9" radius="lg" size="sm">
                  <Text size="sm" color="black">
                    {jogada && jogada.taticas && Array.isArray(jogada.taticas) ? calcularTotalMovimentacoes(jogada?.taticas as Tatica[]) : 0}
                  </Text>
                </ThemeIcon>
                <Text tt="capitalize" fz="sm" fw={500} color="black">
                  Movimentações
                </Text>
              </Group>
            </Badge>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 1.75 }}>
            <Flex justify="center" direction="column" wrap="wrap">
              <Text tt="capitalize" fz="sm" fw={500} color="black">
                Status
              </Text>
              <Badge radius={"md"} bg={jogada.cancelado ? "#FADCDC" : jogada.concluido ? "rgba(240, 249, 240, 1)" : "#f5e7df"} px={5}>
                <Text tt="capitalize" fz="xs" color={jogada.cancelado ? "#EA7473" : jogada.concluido ? "rgba(105, 198, 105, 1)" : "#ff810d"}>
                  {jogada.cancelado ? "Cancelado" : jogada.concluido ? "Concluído" : "Em andamento"}
                </Text>
              </Badge>
            </Flex>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 1.5 }}>
            <Group justify="flex-end" align="center">
              <Button
                type="button"
                variant="transparent"
                size="compact-sm"
                rightSection={opened ? <IconChevronUp size="1rem" /> : <IconChevronDown size="1rem" />}
                onClick={toggle}
              >
                {opened ? "Ver menos" : "Ver mais"}
              </Button>
            </Group>
          </Grid.Col>
        </Grid>
        {opened && (
          <>
            <Space h="xl" />
            <Stack>
              <JogadaDeInovacaoCard jogada={jogada} refetch={refetch} handleCancel={handleCancel} handleConcluir={handleConcluir} formatAutorizacoes={formatAutorizacoes} />
            </Stack>
          </>
        )}
      </Card>
      <Space h="xl" />
    </>
  );
};

export default HomeDeInovacaoItem;

/**
 * Calcula o total de movimentações em um array de táticas.
 * @param array - O array de táticas para calcular o total de movimentações.
 * @returns number O total de movimentações.
 */
function calcularTotalMovimentacoes(array: Tatica[]) {
  let totalMovimentacoes = 0;

  for (const objeto of array) {
    if (objeto.movimentacao) {
      totalMovimentacoes += objeto.movimentacao.length;
    }
  }

  return totalMovimentacoes;
}

const onVerification = (isSmMarches: boolean | undefined) => {
  return isSmMarches ? MAGIC_NUMBER : "100%";
};
