import { subject } from "@casl/ability";
import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import {
  CAMPANHA_REGULAMENTO_IDEIA_COLLECTION_KEY,
  getCampanhasRegulamentoIdeias
} from "@/modules/campanha-regulamento-ideias/firestore";
import useUserStore from "@/modules/users/store";

type TGetRegulamentoIdeiasById = Awaited<ReturnType<typeof getCampanhasRegulamentoIdeias>>;

/**
 * Get regulamento ideias by id hook
 * @param regulamentoIdeiaId - Regulamento ideia id to get
 * @returns - Query result
 */
function useGetCampanhasRegulamentoIdeias(regulamentoIdeiaId: TRegulamentoIdeias["id"] | undefined) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  return useQuery<TGetRegulamentoIdeiasById, FirestoreError, TCampanhaRegulamentoIdeias[]>({
    queryKey: [
      CAMPANHA_REGULAMENTO_IDEIA_COLLECTION_KEY,
      "getCampanhasRegulamentoIdeias",
      { regulamentoIdeiaId },
      activeEmpresaId
    ],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!regulamentoIdeiaId) {
        throw new Error("Regulamento de ideia não selecionado");
      }

      return getCampanhasRegulamentoIdeias(activeEmpresaId, regulamentoIdeiaId);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((campanha) => subject("TCampanhaRegulamentoIdeias", campanha.data()));
    },
    enabled: !!regulamentoIdeiaId
  });
}

export default useGetCampanhasRegulamentoIdeias;
