import { Anchor, Card, CardProps, Divider, Group, Stack, Text } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import RankingTrendIcon from "@/components/ranking-trend-icon";
import ColaboradoresInlineList from "@/modules/colaboradores/components/colaboradores-inline-list";
import DesafioStatusBadge from "@/modules/desafios/components/desafio-status-badge";
import TotalMoedasBadge from "@/modules/desafios/components/total-moedas-badge";
// prettier-ignore
const TopDesafioCard = ({ desafio, index, ...cardProperties }: CardProps & { desafio: TDesafio, index: number }) => {
  const propositores = useMemo(
    () => [
      typeof desafio.propositorReference === "string"
        ? desafio.propositorReference
        : desafio?.propositorReference?.refPath
    ],
    [desafio.propositorReference]
  );
  return (
    <Card key={desafio.id} withBorder {...cardProperties}>
      <Card.Section inheritPadding py="md" h="100%">
        <Group mb="sm">
          {desafio.rankingByMoedas && (
            <Group gap={2}>
              <Text fw={500}>#{index + 1}</Text>{" "}
              <RankingTrendIcon ranking={desafio.rankingByMoedas} size={18} />
            </Group>
          )}

          <DesafioStatusBadge variant="filled" status={desafio.status} />
        </Group>
        <Stack gap="xs">
          <Text fz="sm">{desafio.titulo}</Text>
          <Text fw={500}>{desafio.descricao}</Text>
        </Stack>
      </Card.Section>
      <Card.Section inheritPadding py="md">
        <ColaboradoresInlineList colaboradores={propositores} fw={undefined} c="dimmed" />
        <Divider my="md" />
        <Group justify="space-between">
          <TotalMoedasBadge desafio={desafio} variant="transparent" px={0} />
          <Anchor component={NavLink} to={`/desafios/${desafio.id}`}>
            <Group gap={0}>
              <Text>Votar</Text> <IconChevronRight size="1.2rem" />
            </Group>
          </Anchor>
        </Group>
      </Card.Section>
    </Card>
  );
};

export default TopDesafioCard;
