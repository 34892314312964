import {
  Alert,
  Box,
  Button,
  Group,
  LoadingOverlay,
  NumberInput,
  Select,
  Space,
  Stack,
  TagsInput,
  Text,
  TextInput
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { IconAlertCircle } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import useUserStore from "@/modules/users/store";
import { extractTextInputPropertiesFromFormSchema } from "@/utils/form";
import useQueryProjetos from "./useQueryProjetos";
import useQuestionarioForm from "./useQuestionarioForm";
import { TQuestionarioForm } from "../../firestore";
import questionarioSchema from "../../schema/questionario-schema";
import FaseForm from "../fase-form";

interface Fase {
  id: string;
  titulo: string;
  cor: string;
  checked?: boolean;
  // status: Status[];
}

const QuestionarioForm = ({ questionario, onSuccess }: { questionario: TQuestionarioForm; onSuccess: () => void }) => {
  const [fases, setFases] = useState<Fase[]>([]);
  const { form, isPending, onSubmit } = useQuestionarioForm(questionario, fases, {
    onSuccess
  });
  const { data: projetos, isFetching } = useQueryProjetos();
  const projetosArray = projetos || [];
  const fieldsDescriptor = questionarioSchema.describe();
  const genericError = form.errors[""];
  const projeto = useUserStore((state) => state.activeProjeto);

  useEffect(() => {
    if (questionario && questionario?.fases && questionario?.fases?.length > 0) {
      const fasesVerification = questionario?.fases as Fase[];
      setFases(fasesVerification);
    }
  }, [questionario && questionario?.fases]);

  useEffect(() => {
    if (projeto) {
      form.setValues((values) => ({ ...values, idProjeto: projeto }));
    }
  }, [projeto]);

  if (isFetching) {
    return (
      <Box pos="relative">
        <LoadingOverlay visible={isFetching} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
      </Box>
    );
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <Box pos="relative">
          <LoadingOverlay visible={isPending} />
          <Stack gap="sm" mb="lg">
            <TextInput
              type="text"
              style={{ flex: 1 }}
              {...form.getInputProps("nome")}
              {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "nome")}
            />
            <TextInput
              type="text"
              style={{ flex: 1 }}
              {...form.getInputProps("descricao")}
              {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "descricao")}
            />
            <Group>
              <DateInput
                style={{ flex: 1 }}
                valueFormat="DD/MM/YYYY"
                {...form.getInputProps("dataAtuacao")}
                {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "dataAtuacao")}
              />
              <DateInput
                style={{ flex: 1 }}
                valueFormat="DD/MM/YYYY"
                {...form.getInputProps("dataVigencia")}
                {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "dataVigencia")}
              />
              <NumberInput
                style={{ flex: 1 }}
                {...form.getInputProps("limiteUsuario")}
                {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "limiteUsuario")}
              />
            </Group>
            <TagsInput
              style={{ flex: 1 }}
              {...form.getInputProps("faixas")}
              {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "faixas")}
            />
            {/* <Space h="md" /> */}
            {/* <Group>
              <Switch
                {...form.getInputProps("padrao")}
                {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "padrao")}
                defaultChecked={form.getInputProps("padrao").value as boolean}
              />
              <Switch
                {...form.getInputProps("publico")}
                {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "publico")}
                defaultChecked={form.getInputProps("publico").value as boolean}
              />
            </Group> */}
            <Space h="md" />
            <Text size="lg" fw={700}>
              Projeto Associado
            </Text>
            <Group>
              <Select
                data={projetosArray
                  .filter((item) => (projeto ? item.id === projeto : item))
                  .map((item) => ({ value: item.id || "", label: item.nome || "" }))}
                {...form.getInputProps("idProjeto")}
                {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "idProjeto")}
                style={{ flex: 1 }}
                label={""}
                placeholder="Selecione um projeto aqui"
                disabled={projeto ? true : false}
              />
            </Group>
          </Stack>
        </Box>
        {form?.getInputProps("idProjeto")?.value &&
        projetos &&
        projetos?.find((item) => item.id === form.getInputProps("idProjeto")?.value)?.id ? (
          <FaseForm
            fases={
              (projetos &&
                (projetos?.find((item) => item.id === form?.getInputProps("idProjeto")?.value)?.fases as Fase[])) ||
              arrayEmpty
            }
            setFases={setFases}
            checkeds={fases}
          />
        ) : (
          <></>
        )}
        <Group justify={genericError ? "space-between" : "flex-end"} mt="md">
          {genericError && (
            <Alert icon={<IconAlertCircle size="1rem" />} color="red">
              {genericError}
            </Alert>
          )}
          <Button type="submit" loading={isPending}>
            Salvar
          </Button>
        </Group>
      </form>
    </>
  );
};

export default QuestionarioForm;

const arrayEmpty = [] as [];
