import { Anchor, Button, Card, Group, Stack, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { NavLink } from "react-router-dom";
import { formatDate, formatRelativeTime } from "@/utils/date";
import DuracaoComiteCard from "./DuracaoComiteCard";
import IdeiasMovimentas from "./IdeiasMovimentas";
import ParticipantesConvidadosCard from "./ParticipantesConvidadosCard";
import QuantidadeIdeiasMovimentadasCard from "./QuantidadeIdeiasMovimentadasCard";
import QuantidadeIdeiasPorStatusCard from "./QuantidadeIdeiasPorStatusCard";
import QuantidadeRegulamentosDeDesafiosAlteradosCard from "./QuantidadeRegulamentosDeDesafiosAlteradosCard";
import QuantidadeRegulamentosDeIdeiasAlteradosCard from "./QuantidadeRegulamentosDeIdeiasAlteradosCard";
// prettier-ignore
const ComiteInovacaoItem = ({
  comite,
  defaultExpand = false
}: {
  comite: TComiteInovacao;
  defaultExpand?: boolean;
}) => {
  const [opened, { toggle }] = useDisclosure(defaultExpand);

  return (
    <Card withBorder radius="lg">
      <Card.Section inheritPadding py="xs">
        <Group justify="space-between">
          <Anchor component={NavLink} to={`/comites-de-inovacao/${comite.id}`} c="dark">
            <Group gap="xs">
              <Text fw={500} fz="sm">
                Início {formatDate(comite.startedAt)}
              </Text>
              -
              {comite.endedAt && (
                <Text fw={500} fz="sm">
                  Término {formatRelativeTime(comite.endedAt)}
                </Text>
              )}
            </Group>
          </Anchor>
          <Button
            type="button"
            variant="transparent"
            size="compact-sm"
            rightSection={opened ? <IconChevronUp size="1rem" /> : <IconChevronDown size="1rem" />}
            onClick={toggle}
          >
            Ver mais
          </Button>
        </Group>
      </Card.Section>
      {opened && (
        <Card.Section inheritPadding py="xs">
          <Stack>
            <ParticipantesConvidadosCard comite={comite} />
            <DuracaoComiteCard comite={comite} />
            <QuantidadeIdeiasMovimentadasCard comite={comite} />
            <IdeiasMovimentas comite={comite} />
            <QuantidadeIdeiasPorStatusCard comite={comite} />
            <QuantidadeRegulamentosDeIdeiasAlteradosCard comite={comite} />
            <QuantidadeRegulamentosDeDesafiosAlteradosCard comite={comite} />
          </Stack>
        </Card.Section>
      )}
    </Card>
  );
};

export default ComiteInovacaoItem;
