import { InputProps, TextInput } from "@mantine/core";
import { PatternFormat } from "react-number-format";

interface newValues {
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CepInput = (properties: InputProps | newValues) => {
  return <PatternFormat customInput={TextInput} type="text" format="#####-###" {...properties} />;
};

export default CepInput;
