import { Alert, Card, Center, Stack, Text } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { QueryKey } from "@tanstack/react-query";
import { Table as ReactTable } from "@tanstack/react-table";
import { forwardRef, useImperativeHandle } from "react";
import NoData from "@/components/no-data";
import Table from "@/components/table";
import ResetTableButton from "@/components/table/ResetTableButton";
import { THandler } from "@/components/table/Table";
import TablePagination from "@/components/table/TablePagination";
import useQueryTable from "@/components/table/useQueryTable";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import projectColumns from "./Columns";

import { DEFAULT_PAGINATION, DEFAULT_SORTING, getTiposDeRespostas } from "../../firestore";

export const QUERY_KEY: string = "tipos-de-respostas-list" as const;

const TiposDeRespostasTable = forwardRef<THandler<TTiposDeRespostas>>((_properties, reference) => {
  const uid = getCurrentUser()?.uid;
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  const { table, data, isLoading, error, filters, refetch } = useQueryTable<TTiposDeRespostas>({
    queryKey: [QUERY_KEY],
    enabled: !!uid && !!activeEmpresaId,
    subjectType: "TTiposDeRespostas",
    columns: projectColumns,
    initialState: {
      columnFilters: [],
      globalFilter: "",
      pagination: DEFAULT_PAGINATION,
      sorting: DEFAULT_SORTING
    },
    queryFunction: ({ sorting, filters, pagination, paginationCursors }) => {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      return getTiposDeRespostas(activeEmpresaId, {
        sorting,
        filters,
        pagination,
        paginationCursors
      });
    }
  });

  useImperativeHandle<THandler<TTiposDeRespostas>, THandler<TTiposDeRespostas>>(
    reference,
    () => {
      return {
        table,
        data,
        isLoading,
        error,
        filters
      };
    },
    [filters, data, error, isLoading, table]
  );

  /**
   * Handles the close of the error alert.
   */
  function handleErrorClose() {
    void refetch();
  }

  if (error) {
    return (
      <Alert
        icon={<IconAlertCircle size="1rem" />}
        title="Erro ao buscar tipos de respostas"
        color="red"
        withCloseButton
        onClose={handleErrorClose}
      >
        {error.message}
      </Alert>
    );
  }

  const isEmpty = !isLoading && data?.entries.length === 0;

  return (
    <>
      <Card.Section withBorder>
        <Table table={table} isLoading={isLoading} />
        {isEmpty && !isLoading && (
          <Center m="lg">
            <NoData m="md">
              <Stack gap="xs">
                <Text c="dimmed">Nenhum Tipo de Resposta Encontrado!</Text>
                <ResetTableButton table={table as ReactTable<unknown>} queryKey={QUERY_KEY as unknown as QueryKey}>
                  Limpar filtros
                </ResetTableButton>
              </Stack>
            </NoData>
          </Center>
        )}
      </Card.Section>
      {reference && "current" in reference && reference.current !== null ? (
        <Card.Section withBorder>
          <TablePagination tableHandlers={reference.current} />
        </Card.Section>
      ) : null}
    </>
  );
});

TiposDeRespostasTable.displayName = "TiposDeRespostasTable";

export default TiposDeRespostasTable;
