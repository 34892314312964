import { TransformedValues, yupResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { UseMutationOptions, useQueryClient, useMutation } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { Asserts } from "yup";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { useIdeiaForm as useIdeiaFormContext } from "./ideia-form-context";
import { updateIdeia, addIdeia, TIdeiaForm } from "../../firestore";
import ideiaSchema from "../../schema/ideia-schema";
import { QUERY_KEY } from "../ideias-table/IdeiasTable";

type FormInputs = Asserts<typeof ideiaSchema>;
type TUpdateResponse = Awaited<ReturnType<typeof updateIdeia>>;

/**
 * Empresa Form hook
 * @param ideia - Empresa data
 * @param root0 - Options
 * @param root0.onSuccess - On success callback
 * @param root0.onError - On error callback
 * @returns - Empresa Form
 */
function useIdeiaForm(
  ideia: TIdeiaForm & Partial<Pick<TIdeia, "id" | "refPath">>,
  { onSuccess, onError }: Pick<UseMutationOptions<void, FirestoreError, FormInputs>, "onSuccess" | "onError">
) {
  const [activeEmpresaId, colaborador] = useUserStore((state) => [state.activeEmpresaId, state.colaborador]);
  const queryClient = useQueryClient();
  const form = useIdeiaFormContext({
    validate: yupResolver(ideiaSchema),
    transformValues: (values) => ideiaSchema.cast(values) as TIdeiaForm,
    initialValues: {
      ...(ideiaSchema.getDefault() as TIdeiaForm),
      ...ideia,
      autorReference: (ideia.autorReference || colaborador?.refPath) ?? ""
    }
  });

  const { mutate, isPending, reset } = useMutation<TUpdateResponse, FirestoreError, FormInputs>({
    mutationFn: (values) => {
      if (!activeEmpresaId) {
        throw new Error("Nenhuma empresa selecionada");
      }
      return ideia.id ? updateIdeia(activeEmpresaId, ideia.id, values) : addIdeia(activeEmpresaId, values);
    },
    async onSuccess(data, variables, context) {
      showNotification({
        message: `${variables.titulo} ${ideia.id ? "atualizada" : "cadastrada"} com sucesso!`,
        color: "green",
        autoClose: 2500
      });
      reset();
      form.reset();
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      if (onSuccess) {
        return onSuccess(data, variables, context);
      }
      return null;
    },
    onError(error, variables, context) {
      captureException(error, true);
      if (onError) {
        onError(error, variables, context);
      }
    }
  });

  type TFormTransformed = TransformedValues<typeof form>;

  /**
   * Handle form submit
   * @param values - Form values
   */
  function handleFormSubmit(values: TFormTransformed) {
    mutate(values);
  }

  return { form, isPending, onSubmit: form.onSubmit(handleFormSubmit) };
}

export default useIdeiaForm;
