import { Group, Skeleton } from "@mantine/core";

const ComitesInovacaoHistoryLoading = () => (
  <Group justify="space-between">
    <Group gap="xs">
      <Skeleton height={18} w={20} radius="md" />
      <Skeleton height={18} w={150} radius="md" />
      <Skeleton height={18} w={150} radius="md" />
    </Group>
    <Skeleton height={10} w={80} radius="md" />
  </Group>
);

export default ComitesInovacaoHistoryLoading;
