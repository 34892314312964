/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable react-perf/jsx-no-new-function-as-prop */

import { ActionIconProps, UnstyledButton } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { forwardRef, PropsWithChildren } from "react";
import { captureException } from "@/services/log";

const POS_OLD = 1;
const POS_NEW = 3;
type CopyAutocadastroUrlProperties = PropsWithChildren<ActionIconProps & { projeto: TProjeto }>;

export const CopyAutocadastroUrlButton = forwardRef<HTMLButtonElement, CopyAutocadastroUrlProperties>(
  ({ projeto, children, ...properties }, reference) => {
    const handleClick = async () => {
      const baseUrl = window.location.host;
      const referenceParts = projeto.refPath.split("/");
      const isLicenciada = referenceParts.includes("licenciadas");
      // Se for licenciada, projetoEmpresaId está no índice 3, e licenciadaId no índice 1.
      // Caso contrário, projetoEmpresaId está no índice 1; não existe licenciadaId.
      const projetoEmpresaId = referenceParts[isLicenciada ? POS_NEW : POS_OLD];
      const licenciadaId = isLicenciada ? referenceParts[1] : undefined;
      const autocadastroUrl = isLicenciada
        ? `${baseUrl}/auto-cadastro/${licenciadaId}/${projetoEmpresaId}/${projeto.id}`
        : `${baseUrl}/auto-cadastro/${projetoEmpresaId}/${projeto.id}`;
      try {
        await navigator.clipboard.writeText(autocadastroUrl);
        showNotification({
          title: "Url copiada",
          message: autocadastroUrl,
          autoClose: 2500,
          color: "blue"
        });
      } catch (error) {
        const error_ = error as Error;
        captureException(error_, true);
        showNotification({
          message: "Não foi possível copiar a url",
          autoClose: 1000,
          color: "red"
        });
      }
    };

    return (
      <UnstyledButton ref={reference} {...properties} onClick={handleClick}>
        {children}
      </UnstyledButton>
    );
  }
);

CopyAutocadastroUrlButton.displayName = "CopyAutocadastroUrlButton";
