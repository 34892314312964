import { MongoAbility, createMongoAbility, AbilityBuilder } from "@casl/ability";

type TActions = "manage" | "create" | "update" | "delete" | "read";
export type TSubjects =
  | "all"
  | "TEmpresa"
  | TEmpresa
  | "TColaborador"
  | TColaborador
  | "TProjeto"
  | TProjeto
  | "TQuestionario"
  | TQuestionario
  | "TGruposDiagnostico"
  | TGruposDiagnostico
  | "TPergunta"
  | TPergunta
  | "TTiposDeRespostas"
  | TTiposDeRespostas
  | "TGrupos"
  | TGrupos
  | "TIdeia"
  | TIdeia
  | "TIdeiaComentario"
  | TIdeiaComentario
  | "TRegulamentoIdeias"
  | TRegulamentoIdeias
  | "TCampanhaRegulamentoIdeias"
  | TCampanhaRegulamentoIdeias
  | "TComiteInovacao"
  | TComiteInovacao
  | "TMarco"
  | TMarco
  | "TRegulamentoDesafios"
  | TRegulamentoDesafios
  | "TDesafio"
  | TDesafio
  | "THistoricoPontosPerColaborador"
  | THistoricoPontosPerColaborador
  | "TRankingAnoPerColaborador"
  | TRankingAnoPerColaborador
  | "TDesafioCategoria"
  | TDesafioCategoria
  | "TDesafioComentario"
  | TDesafioComentario
  | "TCategoriaDeInovacao"
  | TCategoriaDeInovacao
  | "TTipoDeJogada"
  | TTipoDeJogada
  | "TAutorizacao"
  | TAutorizacao
  | "TRegiao"
  | TRegiao;

type AppAbility = MongoAbility<[TActions, TSubjects]>;
// prettier-ignore
type DefinePermissions = (empresa: TEmpresa, colaborador: TColaborador, builder: AbilityBuilder<AppAbility>, autorizacoes: TAutorizacao[] | undefined ) => void;
type Roles = NonNullable<TColaborador["role"]>;
// prettier-ignore
const rolePermissions: Record<Roles, DefinePermissions> = {
  // eslint-disable-next-line complexity
  MEMBER(empresa, colaborador, builder, autorizacoes) {
    // eslint-disable complexity
    const currentColaboradorReferencePath = `empresas/${empresa.id}/colaboradores/${colaborador.id}}`;
    const autorizacoesMembro = autorizacoes?.filter((item) => item.tipo === "2");
    verificationAuhotized(autorizacoesMembro, builder, colaborador, currentColaboradorReferencePath)
  },
  // eslint-disable-next-line complexity
  ADMIN(empresa, user, builder, autorizacoes) {
    const { can } = builder
    // eslint-disable complexity
    const currentColaboradorReferencePath = `empresas/${empresa.id}/colaboradores/${user.id}}`;
    const autorizacoesAdmin = autorizacoes?.filter((item) => item.tipo === "1");

    can("read", "TAutorizacao");
    can("update", "TAutorizacao");
    can("create", "TAutorizacao");

    verificationAuhotized(autorizacoesAdmin, builder, user, currentColaboradorReferencePath);
  },
  // eslint-disable-next-line complexity
  TEAM(empresa, user, builder, autorizacoes) {
    const { can } = builder
    // eslint-disable complexity
    const currentColaboradorReferencePath = `empresas/${empresa.id}/colaboradores/${user.id}}`;
    const autorizacoesAdmin = autorizacoes?.filter((item) => item.tipo === "3");

    can("read", "TAutorizacao");
    can("update", "TAutorizacao");
    can("create", "TAutorizacao");

    verificationAuhotized(autorizacoesAdmin, builder, user, currentColaboradorReferencePath);
  },
  // eslint-disable-next-line complexity
  MASTER(empresa, user, builder, autorizacoes) {
    const { can } = builder
    // eslint-disable complexity
    const currentColaboradorReferencePath = `empresas/${empresa.id}/colaboradores/${user.id}}`;
    const autorizacoesAdmin = autorizacoes?.filter((item) => item.tipo === "4");

    can("read", "TAutorizacao");
    can("update", "TAutorizacao");
    can("create", "TAutorizacao");

    verificationAuhotized(autorizacoesAdmin, builder, user, currentColaboradorReferencePath);
  },
  // eslint-disable-next-line complexity
  COLLABORATOR(empresa, user, builder, autorizacoes) {
    const { can } = builder
    // eslint-disable complexity
    const currentColaboradorReferencePath = `empresas/${empresa.id}/colaboradores/${user.id}}`;
    const autorizacoesAdmin = autorizacoes?.filter((item) => item.tipo === "5");

    can("read", "TAutorizacao");
    can("update", "TAutorizacao");
    can("create", "TAutorizacao");

    verificationAuhotized(autorizacoesAdmin, builder, user, currentColaboradorReferencePath);
  }
};

/**
 * Define permissions for a user
 * @param empresa - The empresa to define permissions
 * @param colaborador - The colaborador to define permissions
 * @param autorizacoes - The autorizations
 * @returns - The user permissions
 */
function defineAbilityFor(empresa?: TEmpresa, colaborador?: TColaborador, autorizacoes?: TAutorizacao[]): AppAbility {
  const builder = new AbilityBuilder<AppAbility>(createMongoAbility);
  if (!colaborador || !empresa) {
    return builder.build();
  }

  if (colaborador.role && typeof rolePermissions[colaborador.role] === "function") {
    rolePermissions[colaborador.role](empresa, colaborador, builder, autorizacoes);
  } else {
    rolePermissions.MEMBER(empresa, colaborador, builder, autorizacoes);
  }
  return builder.build();
}

export default defineAbilityFor;

/**
 * Define permissions for a user
 * @param autorizacoes - The autorizacoes to define permissions
 * @param builder - The can
 * @param user - The user
 * @param currentColaboradorReferencePath - currentColaboradorReferencePath
 */
// prettier-ignore
function verificationAuhotized(autorizacoes: TAutorizacao[] | undefined, builder: AbilityBuilder<AppAbility>, user: TColaborador, currentColaboradorReferencePath: string) {
  const { can } = builder

  verificationEmpresa(autorizacoes, builder);
  verificationProjeto(autorizacoes, builder);
  verificationQuestionario(autorizacoes, builder, user);
  verificationGrupos(autorizacoes, builder, user);
  verificationPergunta(autorizacoes, builder, user);
  verificationTiposDeResposta(autorizacoes, builder, user);
  verificationColaborador(autorizacoes, builder, user);
  verificationIdeia(autorizacoes, builder, user);
  verificationIdeiaComentario(autorizacoes, builder, user);
  verificationDesafio(autorizacoes, builder, user);
  verificationCategoriaDeInovacao(autorizacoes, builder, user);
  verificationTipoDeJogada(autorizacoes, builder, user);
  verificationRegulamentoDeDesafios(autorizacoes, builder);
  verificationRegulamentoDeIdeias(autorizacoes, builder);
  verificationComiteDeInovacao(autorizacoes, builder, user);
  verificationDesafioDaCategoria(autorizacoes, builder);

  autorizacoes?.find((item) => item.tipoAutorizacao === "10")?.leitura ? can("read", "TMarco") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "10")?.editar ? can("update", "TMarco", { createdBy: { $eq: user.id } }) : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "10")?.editar ? can("update", "TMarco", { colaboradoresResponsaveis: { $in: currentColaboradorReferencePath } }) : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "10")?.criacao ? can("create", "TMarco") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "10")?.remover ? can("delete", "TMarco") : {};
 }
// prettier-ignore
const verificationEmpresa = (autorizacoes: TAutorizacao[] | undefined, builder: AbilityBuilder<AppAbility>,) => {
  const { can } = builder
  autorizacoes?.find((item) => item.tipoAutorizacao === "1")?.leitura ? can("read", "TEmpresa") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "1")?.editar ? can("update", "TEmpresa") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "1")?.criacao ? can("create", "TEmpresa") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "1")?.remover ? can("delete", "TEmpresa") : {};
}
// prettier-ignore
const verificationProjeto = (autorizacoes: TAutorizacao[] | undefined, builder: AbilityBuilder<AppAbility>,) => {
  const { can } = builder
  autorizacoes?.find((item) => item.tipoAutorizacao === "13")?.leitura ? can("read", "TProjeto") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "13")?.editar ? can("update", "TProjeto") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "13")?.criacao ? can("create", "TProjeto") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "13")?.remover ? can("delete", "TProjeto") : {};
}
// prettier-ignore
const verificationQuestionario = (autorizacoes: TAutorizacao[] | undefined, builder: AbilityBuilder<AppAbility>, user: TColaborador) => {
  const { can } = builder
  autorizacoes?.find((item) => item.tipoAutorizacao === "5")?.leitura ? can("read", "TQuestionario") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "5")?.editar ? can("update", "TQuestionario") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "5")?.criacao ? can("create", "TQuestionario", { createdBy: { $eq: user.id } }) : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "5")?.remover ? can("delete", "TQuestionario") : {};
}
// prettier-ignore
const verificationGrupos = (autorizacoes: TAutorizacao[] | undefined, builder: AbilityBuilder<AppAbility>, user: TColaborador) => {
  const { can } = builder
  autorizacoes?.find((item) => item.tipoAutorizacao === "6")?.leitura ? can("read", "TGruposDiagnostico") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "6")?.editar ? can("update", "TGruposDiagnostico", { createdBy: { $eq: user.id } }) : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "6")?.criacao ? can("create", "TGruposDiagnostico") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "6")?.remover ? can("delete", "TGruposDiagnostico") : {};
}
// prettier-ignore
const verificationPergunta = (autorizacoes: TAutorizacao[] | undefined, builder: AbilityBuilder<AppAbility>, user: TColaborador) => {
  const { can } = builder
  autorizacoes?.find((item) => item.tipoAutorizacao === "7")?.leitura ? can("read", "TPergunta") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "7")?.editar ? can("update", "TPergunta", { createdBy: { $eq: user.id } }) : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "7")?.criacao ? can("create", "TPergunta") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "7")?.remover ? can("delete", "TPergunta") : {};
}
// prettier-ignore
const verificationTiposDeResposta = (autorizacoes: TAutorizacao[] | undefined, builder: AbilityBuilder<AppAbility>, user: TColaborador) => {
  const { can } = builder
  autorizacoes?.find((item) => item.tipoAutorizacao === "8")?.leitura ? can("read", "TTiposDeRespostas") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "8")?.editar ? can("update", "TTiposDeRespostas", { createdBy: { $eq: user.id } }) : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "8")?.criacao ? can("create", "TTiposDeRespostas") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "8")?.remover ? can("delete", "TTiposDeRespostas") : {};
}
// prettier-ignore
const verificationColaborador = (autorizacoes: TAutorizacao[] | undefined, builder: AbilityBuilder<AppAbility>, user: TColaborador) => {
  const { can } = builder
  autorizacoes?.find((item) => item.tipoAutorizacao === "2")?.leitura ? can("read", "TColaborador") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "2")?.editar ? can("update", "TColaborador", { id: { $eq: user.id } }) : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "2")?.criacao ? can("create", "TColaborador") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "2")?.remover ? can("delete", "TColaborador", { id: { $eq: user.id } }) : {};
}
// prettier-ignore
const verificationIdeia = (autorizacoes: TAutorizacao[] | undefined, builder: AbilityBuilder<AppAbility>, user: TColaborador) => {
  const { can } = builder
  autorizacoes?.find((item) => item.tipoAutorizacao === "3")?.leitura ? can("read", "TIdeia") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "3")?.editar ? can("update", "TIdeia", { createdBy: { $eq: user.id } }) : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "3")?.criacao ? can("create", "TIdeia") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "3")?.remover ? can("delete", "TIdeia") : {};
}
// prettier-ignore
const verificationIdeiaComentario = (autorizacoes: TAutorizacao[] | undefined, builder: AbilityBuilder<AppAbility>, user: TColaborador) => {
  const { can } = builder
  autorizacoes?.find((item) => item.tipoAutorizacao === "3")?.leitura ? can("read", "TIdeiaComentario") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "3")?.editar ? can("update", "TIdeiaComentario", { createdBy: { $eq: user.id } }) : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "3")?.criacao ? can("create", "TIdeiaComentario") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "3")?.remover ? can("delete", "TIdeiaComentario") : {};
}
// prettier-ignore
const verificationDesafio = (autorizacoes: TAutorizacao[] | undefined, builder: AbilityBuilder<AppAbility>, user: TColaborador) => {
  const { can } = builder
  autorizacoes?.find((item) => item.tipoAutorizacao === "9")?.leitura ? can("read", "TDesafio") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "9")?.editar ? can("update", "TDesafio", { createdBy: { $eq: user.id } }) : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "9")?.criacao ? can("create", "TDesafio") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "9")?.remover ? can("delete", "TDesafio") : {};
}
// prettier-ignore
const verificationCategoriaDeInovacao = (autorizacoes: TAutorizacao[] | undefined, builder: AbilityBuilder<AppAbility>, user: TColaborador) => {
  const { can } = builder
  autorizacoes?.find((item) => item.tipoAutorizacao === "11")?.leitura ? can("read", "TCategoriaDeInovacao") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "11")?.editar ? can("update", "TCategoriaDeInovacao", { createdBy: { $eq: user.id } }) : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "11")?.criacao ? can("create", "TCategoriaDeInovacao") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "11")?.remover ? can("delete", "TCategoriaDeInovacao") : {};
}
// prettier-ignore
const verificationTipoDeJogada = (autorizacoes: TAutorizacao[] | undefined, builder: AbilityBuilder<AppAbility>, user: TColaborador) => {
  const { can } = builder
  autorizacoes?.find((item) => item.tipoAutorizacao === "12")?.leitura ? can("read", "TTipoDeJogada") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "12")?.editar ? can("update", "TTipoDeJogada", { createdBy: { $eq: user.id } }) : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "12")?.criacao ? can("create", "TTipoDeJogada") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "12")?.remover ? can("delete", "TTipoDeJogada") : {};
}

// prettier-ignore
const verificationRegulamentoDeDesafios = (autorizacoes: TAutorizacao[] | undefined, builder: AbilityBuilder<AppAbility>) => {
  const { can } = builder
  const todayEOD = new Date();
  // eslint-disable-next-line no-magic-numbers
  todayEOD.setHours(23, 59, 59, 999);
  const todayBOD = new Date();
  todayBOD.setHours(0, 0, 0, 0);

  autorizacoes?.find((item) => item.tipoAutorizacao === "14")?.leitura ? can("read", "TRegulamentoDesafios") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "14")?.editar ? can("update", "TRegulamentoDesafios", { dataInicial: { $gte: todayBOD } }) : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "14")?.criacao ? can("create", "TRegulamentoDesafios") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "14")?.remover ? can("delete", "TRegulamentoDesafios") : {};
};

// prettier-ignore
const verificationRegulamentoDeIdeias = (autorizacoes: TAutorizacao[] | undefined, builder: AbilityBuilder<AppAbility>) => {
  const { can } = builder
  const todayEOD = new Date();
  // eslint-disable-next-line no-magic-numbers
  todayEOD.setHours(23, 59, 59, 999);
  const todayBOD = new Date();
  todayBOD.setHours(0, 0, 0, 0);

  autorizacoes?.find((item) => item.tipoAutorizacao === "15")?.leitura ? can("read", "TRegulamentoIdeias") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "15")?.editar ? can("update", "TRegulamentoIdeias", { dataInicial: { $gte: todayBOD } }) : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "15")?.criacao ? can("create", "TRegulamentoIdeias") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "15")?.remover ? can("delete", "TRegulamentoIdeias") : {};
}

// prettier-ignore
const verificationComiteDeInovacao = (autorizacoes: TAutorizacao[] | undefined, builder: AbilityBuilder<AppAbility>, user: TColaborador) => {
  const { can } = builder

  autorizacoes?.find((item) => item.tipoAutorizacao === "16")?.leitura ? can("read", "TComiteInovacao") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "16")?.editar ? can("update", "TComiteInovacao",{ createdBy: { $eq: user.id } }) : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "16")?.criacao ? can("create", "TComiteInovacao") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "16")?.remover ? can("delete", "TComiteInovacao") : {};
}
// prettier-ignore
const verificationDesafioDaCategoria = (autorizacoes: TAutorizacao[] | undefined, builder: AbilityBuilder<AppAbility>) => {
  const { can } = builder

  autorizacoes?.find((item) => item.tipoAutorizacao === "16")?.leitura ? can("read", "TDesafioCategoria") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "16")?.editar ? can("update", "TDesafioCategoria") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "16")?.criacao ? can("create", "TDesafioCategoria") : {};
  autorizacoes?.find((item) => item.tipoAutorizacao === "16")?.remover ? can("delete", "TDesafioCategoria") : {};
}
