import { Card, Flex, Group, Loader, Text } from "@mantine/core";
import useGetHistoricoMarcos from "../../hooks/useGetHistoricoMarcos";
import MarcosList from "../marcos-list";

// prettier-ignore
const HistoricoDeMarcos = ({ ideia }: { ideia: TIdeia }) => {
  const { data: marcos, isFetched, isPending } = useGetHistoricoMarcos(ideia);

  const hasData = isFetched && !isPending && marcos && marcos.length > 0;

  if (isFetched && !hasData) {
    return null;
  }

  return (
    <Card withBorder my="md" mb="xl">
      <Flex direction="column" gap="sm">
        <Group>
          <Text fw="bold" size="lg">
            Histórico de Marcos
          </Text>
        </Group>
        {isPending && (
          <Flex align="center" justify="center">
            <Loader size={30} />
          </Flex>
        )}

        {marcos && marcos.length > 0 ? marcos?.map((marco) => (
          <MarcosList key={marco.id} marco={marco} />
        )) : <></>}
      </Flex>
    </Card>
  );
};

export default HistoricoDeMarcos;
