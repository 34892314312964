import { useQueryClient, useMutation } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { TDesafioActivityPerColaboradorForm, setPatrocinio } from "../../firestore";

type TSetPatrocinioResponse = Awaited<ReturnType<typeof setPatrocinio>>;

/**
 * Hook to set patrocinio
 * @param desafio - Desafio to set patrocinio
 * @param regulamentoId - Regulamento
 * @returns - Mutation state
 */
function useSetPatrocinioMutation(desafio: TDesafio, regulamentoId: TRegulamentoDesafios["id"] | null) {
  const [activeEmpresaId, colaborador] = useUserStore((state) => [state.activeEmpresaId, state.colaborador]);
  const queryClient = useQueryClient();
  return useMutation<TSetPatrocinioResponse, FirestoreError, Pick<TDesafioActivityPerColaboradorForm, "moedas">>({
    mutationFn: (values) => {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!colaborador) {
        throw new Error("Colaborador não encontrado");
      }
      if (!regulamentoId) {
        throw new Error("Regulamento não encontrado");
      }

      return setPatrocinio(activeEmpresaId, desafio.id, colaborador.id, {
        createdBy: colaborador.id,
        updatedBy: colaborador.id,
        ...values
      });
    },
    async onSuccess() {
      const possuiParametros = colaborador && regulamentoId;

      if (possuiParametros) {
        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: [activeEmpresaId, "desafio", desafio.id, "activities"]
          }),
          queryClient.invalidateQueries({
            queryKey: ["total-votos-per-regulamento", activeEmpresaId, colaborador.id, regulamentoId]
          })
        ]);
      }
    },
    onError(error) {
      captureException(error, true);
    }
  });
}

export default useSetPatrocinioMutation;
