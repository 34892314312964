import { Flex, Text } from "@mantine/core";
import useGetResposta from "@/modules/questionario-de-inovacao/components/questionario-list/useGetResposta";
// prettier-ignore
const RespostaComponente = ({ id, fw, fz }: { id: TQuestionario["id"], fw: number, fz: string }) => {
  const { data } = useGetResposta({ id });
  return (
    <>
      <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
        <Text fw={300} fz="xs">
          Quantidade de respostas
        </Text>
        <Text fw={fw} fz={fz}>
          {data?.length}
        </Text>
      </Flex>
    </>
  );
};

export default RespostaComponente;
