import { Anchor, Card, Tabs, Title } from "@mantine/core";
import { useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import BreadcrumbsNavigation from "@/components/breadcrumbs-navigation";
import AtividadesList from "@/modules/ideia-marcos/components/atividades-list";
import {
  generateAllMarcosQuery,
  generateMarcosConcluidosQuery,
  generateMarcosEmAndamentoQuery,
  generateMarcosNaoIniciadaQuery,
  generateMarcosVencidosQuery
} from "@/modules/ideia-marcos/firestore";
import useUserStore from "@/modules/users/store";

const MinhasAtividadesPage = () => {
  const [activeTab, setActiveTab] = useState<string | null>("todas");

  const [activeEmpresaId, colaborador] = useUserStore((state) => [state.activeEmpresaId, state.colaborador]);

  const activeQuery = useMemo(() => {
    if (!activeEmpresaId) {
      throw new Error("activeEmpresaId is required");
    }
    if (!colaborador) {
      throw new Error("colaborador is required");
    }
    const referencePath = `empresas/${activeEmpresaId}/colaboradores/${colaborador.id}`;
    switch (activeTab) {
      case "todas": {
        return generateAllMarcosQuery(activeEmpresaId, [referencePath]);
      }
      case "continuar-atividade": {
        return generateMarcosEmAndamentoQuery(activeEmpresaId, [referencePath]);
      }
      case "aguardando-participacao": {
        return generateMarcosNaoIniciadaQuery(activeEmpresaId, [referencePath]);
      }
      case "vencidas": {
        return generateMarcosVencidosQuery(activeEmpresaId, [referencePath]);
      }
      case "concluidas": {
        return generateMarcosConcluidosQuery(activeEmpresaId, [referencePath]);
      }
      default: {
        throw new Error(`Invalid tab: ${activeTab}`);
      }
    }
  }, [activeEmpresaId, activeTab, colaborador]);

  return (
    <>
      <BreadcrumbsNavigation>
        <Anchor component={NavLink} to="/minhas-atividades">
          Minhas Atividades
        </Anchor>
      </BreadcrumbsNavigation>
      <Card withBorder>
        <Title order={3} fw={500}>
          Minhas Atividades
        </Title>

        <Tabs value={activeTab} onChange={setActiveTab} my="md">
          <Tabs.List>
            <Tabs.Tab value="todas">Todos</Tabs.Tab>
            <Tabs.Tab value="continuar-atividade">Continuar atividade</Tabs.Tab>
            <Tabs.Tab value="aguardando-participacao">Aguardando participação</Tabs.Tab>
            <Tabs.Tab value="vencidas">Vencidas</Tabs.Tab>
            <Tabs.Tab value="concluidas">Concluídas</Tabs.Tab>
          </Tabs.List>
        </Tabs>

        <AtividadesList query={activeQuery} activeTab={activeTab} />
      </Card>
    </>
  );
};

export default MinhasAtividadesPage;
