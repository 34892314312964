import { Card, Group, Skeleton, Stack, Text, ThemeIcon } from "@mantine/core";
import { IconCalendarTime } from "@tabler/icons-react";
import useQueryComiteStatusByType from "@/modules/comite-inovacao/hooks/useQueryComiteStatusByType";
import { formatDate } from "@/utils/date";
import ActivityCardItem from "./ActivityCardItem";

const MIN_LOADING_SIZE = 18;
const MAX_LOADING_SIZE = 48;
const ComiteStatusHistoryLoading = () => {
  const randomBetween18And24 = Math.floor(Math.random() * (MAX_LOADING_SIZE - MIN_LOADING_SIZE + 1) + MIN_LOADING_SIZE);
  return (
    <Card withBorder radius="md">
      <Group justify="space-between">
        <Group mr="lg">
          <Skeleton height={18} w={randomBetween18And24} radius="xl" />
          <Skeleton height={18} w={120} radius="md" />
          <Skeleton height={18} w={randomBetween18And24} radius="xl" />
          <Skeleton height={18} w={120} radius="md" />
        </Group>
        <Skeleton height={18} w={120} radius="md" mr="lg" />
        <Skeleton height={18} w={120} radius="md" />
      </Group>
    </Card>
  );
};

const ComiteStatusHistory = ({ comite }: { comite: TComiteInovacao }) => {
  const { data: activities, isLoading } = useQueryComiteStatusByType(
    comite.id,
    "INICIADO",
    "PAUSADO",
    "REINICIADO",
    "FINALIZADO"
  );

  const hasActivities = !isLoading && activities && activities.length > 0;

  if (!hasActivities) {
    return null;
  }

  return (
    <Stack gap="xs" miw="100%">
      {isLoading && (
        <>
          <ComiteStatusHistoryLoading />
          <ComiteStatusHistoryLoading />
          <ComiteStatusHistoryLoading />
          <ComiteStatusHistoryLoading />
        </>
      )}
      {activities && activities.map((activity) => <ActivityCardItem key={activity.id} activity={activity} />)}
    </Stack>
  );
};

const DuracaoComiteCard = ({ comite }: { comite: TComiteInovacao }) => {
  return (
    <Card withBorder>
      <Group align="flex-start">
        <ThemeIcon size="md" radius="xl" mt={2}>
          <IconCalendarTime width={18} radius="xl" />
        </ThemeIcon>
        <Stack gap={0}>
          <Text fz="lg">Duração do Comitê</Text>
          <Group gap={6}>
            Do dia
            <Text fw="bold" fz="sm" span>
              {formatDate(comite.startedAt)}
            </Text>
            até o dia
            {comite.endedAt && (
              <Text fw="bold" fz="sm" span>
                {formatDate(comite.endedAt)}
              </Text>
            )}
          </Group>
        </Stack>
        <ComiteStatusHistory comite={comite} />
      </Group>
    </Card>
  );
};

export default DuracaoComiteCard;
