import { subject } from "@casl/ability";
import { Group, Text } from "@mantine/core";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import ContextMenuRow from "./ContextMenuRow";

const columnHelper = createColumnHelper<TTiposDeRespostas>();

const columns: ColumnDef<TTiposDeRespostas>[] = [
  columnHelper.accessor("nome", {
    header: "Nome",
    enableSorting: true,
    enableColumnFilter: true
  }),
  columnHelper.accessor("tipoDeResposta", {
    header: "Tipo de Resposta",
    enableSorting: true,
    enableColumnFilter: true,
    cell: (tipo) => <Text size={"14px"}>{tipoDeResposta?.find((item) => item?.value === tipo?.getValue())?.label}</Text>
  }),
  columnHelper.accessor("orientacao", {
    header: "Orientação",
    enableSorting: true,
    enableColumnFilter: true,
    cell: (orientacao) => (
      <Text size={"14px"}>{orientacoes?.find((item) => item?.value === orientacao?.getValue())?.label}</Text>
    )
  }),
  columnHelper.display({
    id: "actions",
    cell: (properties) => {
      const tiposDeRespostas = subject("TTiposDeRespostas", properties.row.original);

      return (
        <Group justify="center" gap={2}>
          <ContextMenuRow tiposDeRespostas={tiposDeRespostas} />
        </Group>
      );
    }
  })
];

export default columns;

const tipoDeResposta = [
  { value: "1", label: "Texto" },
  { value: "2", label: "Escala" },
  { value: "3", label: "Caixas de Seleção" },
  { value: "4", label: "Escolha Única" }
];
const orientacoes = [
  { value: "1", label: "Vertical" },
  { value: "2", label: "Horizontal" }
];
