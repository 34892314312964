import { Avatar, Card, Flex, Group, Text } from "@mantine/core";

const CardNomeEmpresa = () => {
  return (
    <Card withBorder shadow="md" style={{ flex: 1, height: 140, marginBottom: 15 }}>
      <Group style={{ flex: 1, height: 140 }}>
        <Avatar variant="filled" radius="xl" size="xl" src="" ml={10} />
        <Flex direction="column">
          <Text size="xl" fw={700}>
            Premier HVAC Sydney
          </Text>
          <Text size="xs">Segmento</Text>
        </Flex>
      </Group>
    </Card>
  );
};

export default CardNomeEmpresa;
