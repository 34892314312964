import { Flex, Loader, Text } from "@mantine/core";
import useGetConvitesQuestionario from "../../hooks/useGetConvitesQuestionario";

type ColaboradoresConvidadosProperties = {
  questionarioId: string;
};

/**
 * Componente que exibe a quantidade de colaboradores convidados da empresa atual em um questionário
 * @param root0 Properties
 * @param root0.questionarioId id do questionário
 * @returns Componente
 */
function ColaboradoresConvidados({ questionarioId }: ColaboradoresConvidadosProperties) {
  const { data = [], isFetching } = useGetConvitesQuestionario(questionarioId);

  if (isFetching) {
    return (
      <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
        <Loader size={36} />
      </Flex>
    );
  }

  return (
    <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
      <Text fw={300} fz="xs">
        Colaboradores convidados
      </Text>
      <Text fw={500} fz="md">
        {data.length}
      </Text>
    </Flex>
  );
}

export default ColaboradoresConvidados;
