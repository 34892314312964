import { getCampanhasRegulamentoIdeias } from "@/modules/campanha-regulamento-ideias/firestore";

/**
 * Get campanhas
 * @param data - data regulamento
 * @param activeEmpresaId - activeEmpresaId
 * @returns - campanhas
 */
// prettier-ignore
async function GetCampanhas(
  data: TRegulamentoIdeias[] | undefined,
  activeEmpresaId: string | undefined
): Promise<TCampanhaRegulamentoIdeias[]> {
  const campanhas: TCampanhaRegulamentoIdeias[] = [];

  if (activeEmpresaId && data && data.length > 0) {
    for (const item of data) {
      const newData = await getCampanhasRegulamentoIdeias(activeEmpresaId, item.id || "");
      campanhas.push(...(newData.docs.map((desafio) => desafio.data())));
    }
    return campanhas;
  }

  return [];
}

export default GetCampanhas;
