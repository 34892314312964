import { Button, Group, Space, TextInput } from "@mantine/core";
import { extractTextInputPropertiesFromFormSchema } from "@/utils/form";
import useCategoriaDeDesafioForm from "./useCategoriaDeDesafioForm";
import { TCategoriaDeDesafioForm } from "../../firestore";
import categoriaDeDesafioSchema from "../../schema/categoria-de-desafio-schema";

const CategoriaDeDesafioForm = ({
  categoria,
  onSuccess
}: {
  categoria: TCategoriaDeDesafioForm;
  onSuccess: () => void;
}) => {
  const { getInputProps, onSubmit, isLoading } = useCategoriaDeDesafioForm(categoria, {
    onSuccess
  });
  const fieldsDescriptor = categoriaDeDesafioSchema.describe();

  return (
    <form onSubmit={onSubmit}>
      <TextInput
        mt="md"
        {...getInputProps("nome")}
        {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "nome")}
        label={"Nome"}
      />
      <Space h="xl" />
      <Group justify="flex-end">
        <Button type="submit" variant="filled" loading={isLoading}>
          Salvar
        </Button>
      </Group>
    </form>
  );
};
export default CategoriaDeDesafioForm;
