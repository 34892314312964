import { TransformedValues, useForm, yupResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { UseMutationOptions, useQueryClient, useMutation } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { Asserts } from "yup";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { QUERY_KEY } from "../components/tables/regiao-table/RegioesTable";
import { addRegiao, TRegiaoForm, updateRegiao } from "../firestore";
import regiaoSchema from "../schema/regiao-schema";

type FormInputs = Asserts<typeof regiaoSchema>;
type TUpdateResponse = Awaited<ReturnType<typeof updateRegiao>>;
type TAddResponse = Awaited<ReturnType<typeof addRegiao>>;

/**
 * Empresa Form hook
 * @param regiao - Empresa data
 * @param root0 - Options
 * @param root0.onSuccess - On success callback
 * @param root0.onError - On error callback
 * @returns - Empresa Form
 */
function useRegiaoForm(
  regiao: TRegiaoForm & Partial<Pick<TRegiao, "id" | "refPath">>,
  {
    onSuccess,
    onError
  }: Pick<UseMutationOptions<TUpdateResponse | TAddResponse, FirestoreError, FormInputs>, "onSuccess" | "onError">
) {
  const { activeEmpresaId } = useUserStore();

  const queryClient = useQueryClient();

  const form = useForm<FormInputs>({
    validate: yupResolver(regiaoSchema),
    transformValues: (values) => regiaoSchema.cast(values),
    initialValues: regiao
  });

  const { mutate, isPending, reset } = useMutation<TUpdateResponse | TAddResponse, FirestoreError, FormInputs>({
    mutationFn: (values) => {
      if (!activeEmpresaId) {
        throw new Error("Nenhuma empresa selecionada");
      }

      const projetoId = values.projetoId;

      return regiao.id
        ? updateRegiao(activeEmpresaId, projetoId, regiao.id, values)
        : addRegiao(activeEmpresaId, projetoId, values);
    },
    async onSuccess(data, variables, context) {
      showNotification({
        message: `${variables.name} ${regiao.id ? "atualizado" : "cadastrado"} com sucesso!`,
        color: "green",
        autoClose: 2500
      });
      reset();
      form.reset();
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      if (onSuccess) {
        return onSuccess(data, variables, context);
      }
      return null;
    },
    onError(error, variables, context) {
      captureException(error, true);
      if (onError) {
        onError(error, variables, context);
      }
    }
  });

  type TFormTransformed = TransformedValues<typeof form>;

  const handleFormSubmit = (values: TFormTransformed) => {
    mutate(values);
  };

  const handleAddIdentifier = (identifier?: TRegiaoIdentifier) => {
    if (identifier) {
      const { identifiers } = form.getValues();

      if (identifiers) {
        form.setFieldValue("identifiers", [...identifiers, identifier]);
      } else {
        form.setFieldValue("identifiers", [identifier]);
      }
    }
  };

  const handleRemoveIdentifier = (identifier: TRegiaoIdentifier) => {
    if (identifier) {
      const id = identifier.field + identifier.value;
      const { identifiers } = form.getValues();
      form.setFieldValue(
        "identifiers",
        identifiers.filter((index) => index.field + index.value !== id)
      );
    }
  };

  return {
    form,
    isPending,
    onSubmit: form.onSubmit(handleFormSubmit),
    removeIdentifier: handleRemoveIdentifier,
    addIdentifier: handleAddIdentifier
  };
}

export default useRegiaoForm;
