import { Card, Text, Group, Button } from "@mantine/core";
import { useDisclosure, useForceUpdate } from "@mantine/hooks";
import { IconFilter, IconPlus } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";
import { useRef } from "react";
import AddItemButton from "@/components/add-item-button";
import { TModalProperties } from "@/components/add-item-button/AddItemButton";
import RefreshQueryButton from "@/components/refresh-query-button";
import { THandler } from "@/components/table/Table";
import useNonDeletedGlobalFilters from "@/hooks/useNonDeletedGlobalFilters";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import RegulamentosDesafiosTable from "@/modules/regulamento-desafios/components/regulamentos-desafios-table";
import regulamentoDesafioFormSchema from "@/modules/regulamento-desafios/schema";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import TableFilters from "./TableFilters";
import { dataAutorizacoes } from "../empresa-page/EmpresaPage";

const modalProperties: TModalProperties = {
  modal: "regulamento-desafios-form-modal"
};
const REFRESH_ALL_KEY = ["desafios-regulamentos-list"] as const;
// prettier-ignore
const RegulamentosDesafiosPage = () => {
  const tableHandlersReference = useRef<THandler<TRegulamentoDesafios>>(null);
  useNonDeletedGlobalFilters(tableHandlersReference);
  const [opened, { close, toggle }] = useDisclosure(false);
  const queryClient = useQueryClient();
  const activeEmpresaId = useUserStore((store) => store.activeEmpresa);
  const activeAutorizacao = useUserStore((store) => store.autorizacoes);
  const uid = getCurrentUser()?.uid;
  const { data: userColaborador } = useGetColaboradorById(activeEmpresaId?.id || "", uid || "");
  const autorizacao = dataAutorizacoes.find(item => item.label === userColaborador?.role);
  const formatAutorizacoes = activeAutorizacao?.filter(item => item.tipo === autorizacao?.value);

  const forceUpdate = useForceUpdate();
  /**
   * Handle filter applied
   */
  function handleFilterApplied() {
    close();
    void queryClient.invalidateQueries({ queryKey: REFRESH_ALL_KEY }).then(() => forceUpdate());
  }

  return (
    <Card withBorder shadow="md">
      <Card.Section inheritPadding py="md" withBorder>
        <Group justify="space-between">
          <Text fw={500} fz="lg">
            Listagem de regulamento
          </Text>
          {formatAutorizacoes?.find(item => item.tipoAutorizacao === "14")?.criacao ? <AddItemButton
            size="sm"
            leftSection={<IconPlus size="1rem" />}
            modalProperties={modalProperties}
            schema={regulamentoDesafioFormSchema}
          >
            Adicionar regulamento
          </AddItemButton> : <></>}
        </Group>
      </Card.Section>
      <Card.Section inheritPadding py="md" withBorder>
        <Group justify="space-between">
          <Button variant="subtle" size="compact-md" leftSection={<IconFilter size="1rem" />} onClick={toggle}>
            Filtros
          </Button>
          <RefreshQueryButton queryKey={REFRESH_ALL_KEY} />
        </Group>
      </Card.Section>
      {tableHandlersReference.current && (
        <TableFilters
          table={tableHandlersReference.current.table}
          filters={tableHandlersReference.current.filters}
          onClose={close}
          onFilterApplied={handleFilterApplied}
          opened={opened}
        />
      )}
      <RegulamentosDesafiosTable ref={tableHandlersReference} />
    </Card>
  );
};

export default RegulamentosDesafiosPage;
