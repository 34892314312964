import { Card, Group, Title } from "@mantine/core";
import ColaboradoresInlineList from "@/modules/colaboradores/components/colaboradores-inline-list";

// prettier-ignore
const IdeiaParticipantesCard = ({ ideia }: { ideia: TIdeia }) => {
  const referenceCompany = ideia.refPath.split("ideias")[0];
  const participantesReferencesPath = ideia.participantesReferences.map((participante) =>
    typeof participante === "string" ? participante : referenceCompany + `colaboradores/${ideia.createdBy}`
  );
  participantesReferencesPath.push(
    typeof ideia.participantesReferences === "string"
      ? ideia.participantesReferences
      : referenceCompany + `colaboradores/${ideia.createdBy}`
  );

  return (
    <Card withBorder>
      <Title order={4}>Autores</Title>
      <Group justify="space-between" mt="xs">
        <ColaboradoresInlineList colaboradores={participantesReferencesPath} />
      </Group>
    </Card>
  );
};

export default IdeiaParticipantesCard;
