import { AppShell, AppShellNavbarProps, Box, Loader, ScrollArea, Tabs, ThemeIcon, Tooltip } from "@mantine/core";
import { IconChecklist, IconDeviceIpadStar, IconLogout, IconRocket, IconSettings } from "@tabler/icons-react";
import React, { MouseEvent, PropsWithChildren } from "react";
import useSignOutHandler from "@/hooks/useSignOutHandler";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import AppAdminMenu from "./AppAdminMenu";
import AppConfigurationMenu from "./AppConfigurationMenu";
import AppDiagnosticoMenu from "./AppDiagnosticoMenu";
import AppDiagnosticoMenuConvidado from "./AppDiagnosticoMenuConvidado";
import AppGestaoInovacaoMenu from "./AppGestaoInovacaoMenu";
import AppIncentivoInovacao from "./AppIncentivoInovacao"; // Move this line up
import navBarStyles from "./Navbar.module.css";
import NavLinkMenu from "./NavLinkMenu";
import TabPanelMenu from "./TabPanelMenu";
import tabsClassNames from "./Tabs.module.css";
import useGetConvites from "./useGetConvites";

interface newFunction {
  toggleMobile: () => void;
}

/**
 * Left side of the navbar with links to main pages
 * @param root0 - Props
 * @param root0.children - React children
 * @param root0.toggleMobile - function
 * @returns React component
 */

const AppNavbar: React.FC<PropsWithChildren<AppShellNavbarProps & newFunction>> = ({
  children,
  toggleMobile,
  ...navbarProperties
}) => {
  const signOutHandler = useSignOutHandler();
  const { data, isLoading } = useGetConvites();
  const empresa = useUserStore((state) => state.activeEmpresa);
  const uid = getCurrentUser()?.uid;
  const { data: userColaborador, isLoading: isLoadingColaborador } = useGetColaboradorById(
    empresa?.id || "",
    uid || ""
  );

  /**
   * Handle logout click
   * @param event - Click event
   */
  function handleLogout(event: MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    signOutHandler().catch((error) => {
      console.error("Erro durante o logout:", error);
    });
  }

  if (isLoading || isLoadingColaborador) {
    return (
      <AppShell.Navbar bg="dark.9" p="md" {...navbarProperties}>
        <AppShell.Section>{children}</AppShell.Section>
        <AppShell.Section className={navBarStyles.section} pb="md" grow component={ScrollArea}>
          <Box
            pos="relative"
            style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}
          >
            <Loader />
          </Box>
        </AppShell.Section>
      </AppShell.Navbar>
    );
  }

  return (
    <AppShell.Navbar bg="dark.9" p="md" {...navbarProperties}>
      <AppShell.Section>{children}</AppShell.Section>
      <AppShell.Section className={navBarStyles.section} pb="md" grow component={ScrollArea}>
        {data && data.length > 0 && userColaborador?.role !== "ADMIN" ? (
          <Tabs
            color="dark"
            variant="pills"
            defaultValue={"diagnostico"}
            mt="md"
            classNames={{ tab: tabsClassNames.tab }}
          >
            <Tabs.List grow justify="center" mx="md">
              <Tabs.Tab
                value="diagnostico"
                leftSection={
                  <Tooltip label="Diagnóstico">
                    <ThemeIcon color="dark" bg="transparent">
                      <IconChecklist size="1rem" />
                    </ThemeIcon>
                  </Tooltip>
                }
              />
            </Tabs.List>
            <TabPanelMenu value="diagnostico" title="Diagnóstico">
              <AppDiagnosticoMenuConvidado />
            </TabPanelMenu>
          </Tabs>
        ) : (
          <NewComponent userColaborador={userColaborador} toggleMobile={toggleMobile} />
        )}
      </AppShell.Section>
      <AppShell.Section>
        {/* <NavLinkMenu label="Ajuda e informações" leftSection={<IconInfoCircle size="1.2rem" stroke={1.5} />} /> */}
        <NavLinkMenu label="Sair" leftSection={<IconLogout size="1.2rem" stroke={1.5} />} onClick={handleLogout} />
      </AppShell.Section>
    </AppShell.Navbar>
  );
};

export default AppNavbar;

const NewComponent = ({
  userColaborador,
  toggleMobile
}: {
  userColaborador: TColaborador | null | undefined;
  toggleMobile: () => void;
}) => {
  const { acessaGestaoInovacao, acessaAdministracao, acessaDiagnostico, acessaIncentivoInovacao, acessaConfiguracoes } =
    defineAcessos(userColaborador);
  return (
    <Tabs
      color="dark"
      variant="pills"
      defaultValue={verificationValue(userColaborador)}
      mt="md"
      classNames={{ tab: tabsClassNames.tab }}
    >
      <Tabs.List grow justify="center" mx="md">
        {!acessaAdministracao && acessaGestaoInovacao ? (
          <Tabs.Tab
            value="gestao-inovacao"
            leftSection={
              <Tooltip label="Gestão de Inovação">
                <ThemeIcon color="dark" bg="transparent">
                  <IconDeviceIpadStar size="1rem" />
                </ThemeIcon>
              </Tooltip>
            }
          />
        ) : (
          <></>
        )}
        {!acessaAdministracao && acessaDiagnostico ? (
          <Tabs.Tab
            value="diagnostico"
            leftSection={
              <Tooltip label="Diagnóstico">
                <ThemeIcon color="dark" bg="transparent">
                  <IconChecklist size="1rem" />
                </ThemeIcon>
              </Tooltip>
            }
          />
        ) : (
          <></>
        )}
        {!acessaAdministracao && acessaIncentivoInovacao ? (
          <Tabs.Tab
            value="incentivo-inovacao"
            leftSection={
              <Tooltip label="Incentivo a Inovação">
                <ThemeIcon color="dark" bg="transparent">
                  <IconRocket size="1rem" />
                </ThemeIcon>
              </Tooltip>
            }
          />
        ) : (
          <></>
        )}
        {!acessaAdministracao && acessaConfiguracoes ? (
          <Tabs.Tab
            value="config"
            leftSection={
              <Tooltip label="Configurações">
                <ThemeIcon bg="transparent">
                  <IconSettings size="1rem" />
                </ThemeIcon>
              </Tooltip>
            }
          />
        ) : (
          <></>
        )}
        {acessaAdministracao ? (
          <Tabs.Tab
            value="admin"
            leftSection={
              <Tooltip label="Administração">
                <ThemeIcon bg="transparent">
                  <IconSettings size="1rem" />
                </ThemeIcon>
              </Tooltip>
            }
          />
        ) : (
          <></>
        )}
      </Tabs.List>
      <NewTabMenu userColaborador={userColaborador} toggleMobile={toggleMobile} />
    </Tabs>
  );
};

// eslint-disable-next-line complexity
const NewTabMenu = ({
  userColaborador,
  toggleMobile
}: {
  userColaborador: TColaborador | null | undefined;
  toggleMobile: () => void;
}) => {
  const { acessaGestaoInovacao, acessaAdministracao, acessaDiagnostico, acessaIncentivoInovacao, acessaConfiguracoes } =
    defineAcessos(userColaborador);
  return (
    <>
      {!acessaAdministracao && acessaIncentivoInovacao ? (
        <TabPanelMenu value="incentivo-inovacao" title="Incentivo a Inovação">
          <AppIncentivoInovacao toggleMobile={toggleMobile} />
        </TabPanelMenu>
      ) : (
        <></>
      )}
      {!acessaAdministracao && acessaGestaoInovacao ? (
        <TabPanelMenu value="gestao-inovacao" title="Gestão de Inovação">
          <AppGestaoInovacaoMenu toggleMobile={toggleMobile} />
        </TabPanelMenu>
      ) : (
        <></>
      )}
      {!acessaAdministracao && acessaDiagnostico ? (
        <TabPanelMenu value="diagnostico" title="Diagnóstico">
          <AppDiagnosticoMenu toggleMobile={toggleMobile} />
        </TabPanelMenu>
      ) : (
        <></>
      )}
      {!acessaAdministracao && acessaConfiguracoes ? (
        <TabPanelMenu value="config" title="Configurações">
          <AppConfigurationMenu toggleMobile={toggleMobile} />
        </TabPanelMenu>
      ) : (
        <></>
      )}
      {acessaAdministracao ? (
        <TabPanelMenu value="admin" title="Administração">
          <AppAdminMenu toggleMobile={toggleMobile} />
        </TabPanelMenu>
      ) : (
        <></>
      )}
    </>
  );
};

const verificationValue = (userColaborador: TColaborador | null | undefined) => {
  const { acessaGestaoInovacao, acessaAdministracao, acessaDiagnostico, acessaIncentivoInovacao, acessaConfiguracoes } =
    defineAcessos(userColaborador);
  if (acessaGestaoInovacao) {
    return "gestao-inovacao";
  } else if (acessaDiagnostico) {
    return "diagnostico";
  } else if (acessaIncentivoInovacao) {
    return "incentivo-inovacao";
  } else if (acessaConfiguracoes) {
    return "config";
  } else if (acessaAdministracao) {
    return "config";
  } else {
    return "gestao-inovacao";
  }
};
/**
 *
 * @param userColaborador - User colaborator
 * @returns - acessos
 */
function defineAcessos(userColaborador: TColaborador | null | undefined) {
  const acessaGestaoInovacao =
    userColaborador?.role === "ADMIN" || userColaborador?.gestao || userColaborador?.gestao === undefined;
  const acessaIncentivoInovacao =
    userColaborador?.role === "ADMIN" || userColaborador?.incentivo || userColaborador?.gestao === undefined;
  const acessaDiagnostico =
    userColaborador?.role === "ADMIN" || userColaborador?.diagnostico || userColaborador?.gestao === undefined;
  const acessaConfiguracoes =
    userColaborador?.role === "ADMIN" || userColaborador?.configuracoes || userColaborador?.gestao === undefined;
  const acessaAdministracao = userColaborador?.role === "MASTER";
  return { acessaGestaoInovacao, acessaAdministracao, acessaDiagnostico, acessaIncentivoInovacao, acessaConfiguracoes };
}
