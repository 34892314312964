import { Text, Button, Center, Loader, NumberFormatter, Table } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  HISTORICO_PONTOS_PER_COLABORADOR_KEY,
  getHistoricoPontosPerColaborador
} from "@/modules/historico-pontos-per-colaborador/firestore";
import { getIdeia } from "@/modules/ideias/firestore";
import useUserStore from "@/modules/users/store";
import { formatDate } from "@/utils/date";

type TGetHistoricoPontosPerColaboradorResponse = Awaited<ReturnType<typeof getHistoricoPontosPerColaborador>>;

const LAST_MONTH = 11;
const LAST_DAY = 31;

/**
 * Get historicoPontosPerColaborador query
 * @param colaborador - Colaborador to get historicoPontosPerColaborador for
 * @param year - Year to get historicoPontosPerColaborador for
 * @returns - historicoPontosPerColaborador query
 */
export function useGetHistoricoPontosPerColaborador(colaborador: TColaborador | null | undefined, year: number) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  return useQuery<TGetHistoricoPontosPerColaboradorResponse, FirestoreError, THistoricoPontosPerColaborador[]>({
    queryKey: [
      activeEmpresaId,
      HISTORICO_PONTOS_PER_COLABORADOR_KEY,
      "getHistoricoPontosPerColaborador",
      { colaboradorId: colaborador?.id, year }
    ],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Nenhuma empresa ativa no momento");
      }

      const beginOfYear = new Date(year, 0, 1);
      const endOfYear = new Date(year, LAST_MONTH, LAST_DAY);

      return getHistoricoPontosPerColaborador(activeEmpresaId, colaborador?.id || "", beginOfYear, endOfYear);
    },
    select(data) {
      if (data.empty) {
        return [];
      }
      return data.docs.map((document_) => document_.data());
    }
  });
}

/**
 * HistoricoPontuacao type guard
 * @param historico - Historico to check if it is a TOrigemPontuacaoMovimentacaoNaIdeias
 * @returns - True if historico is a TOrigemPontuacaoMovimentacaoNaIdeias
 */
function isHistoricoPontuacaoMovimentacaoNaIdeias(
  historico: TOrigemPontuacao
): historico is TOrigemPontuacaoMovimentacaoNaIdeias {
  return ideiasTypes.includes(historico.type);
}

const ideiasTypes: TOrigemPontuacao["type"][] = [
  "CADASTRO_DE_IDEIAS",
  "APROVACAO_DE_IDEIAS",
  "IMPLEMENTACAO_DE_IDEIAS",
  "PARTICIPACAO_DE_IDEIA_PROPRIA",
  "PARTICIPACAO_DE_IDEIA_TERCEIRO",
  "CANCELADA",
  "REPROVADA"
] as const;

const typesTextMap: Record<TOrigemPontuacao["type"], string> = {
  APROVACAO_DE_IDEIAS: "Aprovação de ideia",
  CADASTRO_DE_IDEIAS: "Cadastro de ideia",
  IMPLEMENTACAO_DE_IDEIAS: "Implementação de ideia",
  PARTICIPACAO_DE_IDEIA_PROPRIA: "Participação de ideia própria",
  PARTICIPACAO_DE_IDEIA_TERCEIRO: "Participação de ideia de terceiros",
  BONIFICACAO: "Bonificação do comitê",
  CANCELADA: "Cancelado",
  REPROVADA: "Reprovado"
};

const GetIdeiaFromId = ({ id }: { id: string }) => {
  const [ideia, setIdeia] = useState<string | null>(null);
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  useEffect(() => {
    const fetchIdeia = async () => {
      const ideia = await getIdeia(activeEmpresaId || "", id);
      const createdAt = ideia?.data()?.createdAt;
      setIdeia(createdAt ? formatDate(createdAt) : "");
    };
    void fetchIdeia().catch((error) => console.error("Erro ao buscar ideia", error));
  }, [id, activeEmpresaId]);

  return ideia ? <Text>{ideia}</Text> : <Text>Loading...</Text>;
};

const HistoricoPontosRow = ({ historico }: { historico: THistoricoPontosPerColaborador }) => {
  return (
    <Table.Tr>
      <Table.Td>
        {isHistoricoPontuacaoMovimentacaoNaIdeias(historico) && "Ideia"}
        {historico.type === "BONIFICACAO" && "Bonificação do comitê"}
      </Table.Td>
      <Table.Td>{typesTextMap[historico.type] || historico.type}</Table.Td>
      <Table.Td>
        {isHistoricoPontuacaoMovimentacaoNaIdeias(historico) ? (
          <Button component={NavLink} to={`/ideias/${historico.ideia.id}`} size="xs" variant="outline">
            Ver ideia
          </Button>
        ) : (
          historico.observacao
        )}
      </Table.Td>
      <Table.Td>
        <Text c={historico.type === "CANCELADA" || historico.type === "REPROVADA" ? "red" : "green"} fw="bold">
          <NumberFormatter value={historico.pontos} suffix=" pontos" />
        </Text>
      </Table.Td>
      <Table.Td>{formatDate(historico.createdAt)}</Table.Td>
      <Table.Td>
        {isHistoricoPontuacaoMovimentacaoNaIdeias(historico) ? <GetIdeiaFromId id={historico.ideia.id} /> : ""}
      </Table.Td>
    </Table.Tr>
  );
};

const HistoricoPontosTable = ({ colaborador, year }: { colaborador: TColaborador; year: number }) => {
  const { isLoading, data: historicoPontos } = useGetHistoricoPontosPerColaborador(colaborador, year);

  if (isLoading) {
    return (
      <Center mah={300} py="xl">
        <Loader />
      </Center>
    );
  }

  const isEmpty = !historicoPontos || historicoPontos.length === 0;
  if (isEmpty) {
    return null;
  }

  return (
    <Table highlightOnHover withColumnBorders>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Origem da Pontuação</Table.Th>
          <Table.Th>Participação</Table.Th>
          <Table.Th>Origem</Table.Th>
          <Table.Th>Pontos recebidos</Table.Th>
          <Table.Th>Data da Participação</Table.Th>
          <Table.Th>Data de Cadastro Ideia</Table.Th>
        </Table.Tr>
      </Table.Thead>

      {historicoPontos.map((historico) => (
        <HistoricoPontosRow key={historico.id} historico={historico} />
      ))}
    </Table>
  );
};

export default HistoricoPontosTable;
