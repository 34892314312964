import { Button, Card, Group, ScrollArea, Space, Text } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { or, and, where } from "firebase/firestore";
import { MouseEvent, RefObject, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import AddItemButton from "@/components/add-item-button";
import { TModalProperties } from "@/components/add-item-button/AddItemButton";
import { THandler } from "@/components/table/Table";
import { getColaboradorDocumentReferenceFromPath } from "@/modules/colaboradores/firestore";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import IdeiasFilterButton from "@/modules/comite-inovacao/components/status-comite-inovacao/IdeiasFilterButton";
import IdeiasTableList from "@/modules/ideias/components/ideias-table/IdeiasTableList";
import useCountIdeiasByStatusQuery from "@/modules/ideias/hooks/useCountIdeiasByStatusQuery";
import ideiaSchema from "@/modules/ideias/schema/ideia-schema";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import { dataAutorizacoes } from "../empresa-page/EmpresaPage";
import { allStatus } from "../ideias-de-inovacao-page/IdeiasDeInovacaoPage";

const modalProperties: TModalProperties = {
  modal: "ideia-form-modal"
};

/**
 * Set the global filter to the table
 * @param tableReference - Reference to the table handlers
 */
// prettier-ignore
function useGlobalFilters(tableReference: RefObject<THandler<TIdeia>>) {
  const [query] = useSearchParams();
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const colaboradorId = getCurrentUser()?.uid;
  const colaboradorPath = `empresas/${activeEmpresaId}/colaboradores/${colaboradorId}`;

  useEffect(() => {
    const tableHandlers = tableReference.current;
    if (tableHandlers) {
      const queryStringFilter = query.get("filter");

      const publishedFilter = and(
        where("deletedAt", "==", null),
        where("publishedAt", "!=", null),
        queryStringFilter ? and(where("deletedAt", "==", null), where("status", "==", queryStringFilter)) : where("deletedAt", "==", null),
        or(
          // and(
          // eslint-disable-next-line sonarjs/no-duplicate-string
          // where(`participantesReferences`, "array-contains", getColaboradorDocumentReferenceFromPath(colaboradorPath)),
          // ),
          and(
            where("privado", "==", true),
            where("createdBy", "==", colaboradorId),
          ),
          and(
            where("privado", "==", true),
            // eslint-disable-next-line sonarjs/no-duplicate-string
            where(`participantesReferences`, "array-contains", getColaboradorDocumentReferenceFromPath(colaboradorPath)),
          ),
          where("privado", "==", false),
          where("createdBy", "==", colaboradorId),
        )
      );

      const draftFilter = and(
        where("deletedAt", "==", null),
        where("createdBy", "==", colaboradorId),
        where("publishedAt", "==", null),
        queryStringFilter ? and(where("deletedAt", "==", null), where("status", "==", queryStringFilter)) : where("deletedAt", "==", null)
      );

      // const privateAndUnpublishedFilter = and(
      //   where("deletedAt", "==", null),
      //   where("privado", "==", true),
      //   // eslint-disable-next-line sonarjs/no-duplicate-string
      //   where(`participantesReferences`, "array-contains", getColaboradorDocumentReferenceFromPath(colaboradorPath)),
      //   where("publishedAt", "==", null),
      //   queryStringFilter ? and(where("deletedAt", "==", null), where("status", "==", queryStringFilter)) : where("deletedAt", "==", null)
      // );

      // Combina os filtros com 'or'
      const filters = or(publishedFilter, draftFilter/* privateAndUnpublishedFilter */);
      tableHandlers.table.setGlobalFilter(filters);
    }
  }, [query, tableReference]);
}

// prettier-ignore
const IdeiasPage = () => {
  const tableHandlersReference = useRef<THandler<TIdeia>>(null);
  useGlobalFilters(tableHandlersReference);
  const activeEmpresaId = useUserStore((store) => store.activeEmpresa);
  const activeAutorizacao = useUserStore((store) => store.autorizacoes);
  const uid = getCurrentUser()?.uid;
  const { data: userColaborador } = useGetColaboradorById(activeEmpresaId?.id || "", uid || "");
  const autorizacao = dataAutorizacoes.find(item => item.label === userColaborador?.role);
  const formatAutorizacoes = activeAutorizacao?.filter(item => item.tipo === autorizacao?.value);
  const [, setSearchParameters] = useSearchParams();
  /**
   * Handle the click event
   * @param event - The event object
   */
  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    setSearchParameters();
    // void queryClient.invalidateQueries({ queryKey: "ideias-list" });
  }
  return (
    <>
      <Button variant="filled" onClick={handleClick}>Limpar Filtro</Button>
      <Space h={"md"} />
      <Card withBorder>
        <Card.Section inheritPadding py="md" >
          <Group justify="space-between">
            <Text fw={500} fz="lg">
              Listagem de Ideias
            </Text>
            {formatAutorizacoes?.find(item => item.tipoAutorizacao === "3")?.criacao ? <AddItemButton
              size="sm"
              leftSection={<IconPlus size="1rem" />}
              modalProperties={modalProperties}
              schema={ideiaSchema}
            >
              Adicionar Ideia
            </AddItemButton> : <></>}
          </Group>
        </Card.Section>

        <IdeiasByStatusFilter />
        <IdeiasTableList ref={tableHandlersReference} />
      </Card>
    </>
  );
};

export default IdeiasPage;

const IdeiasByStatusFilter = () => {
  const { data: statusCount } = useCountIdeiasByStatusQuery(Object.keys(allStatus) as TIdeia["status"][]);

  return (
    <ScrollArea scrollbars="x">
      <Group wrap="nowrap">
        {statusCount &&
          Object.entries(statusCount).map(([status, total]: [TIdeia["status"], number]) => (
            // eslint-disable-next-line security/detect-object-injection
            <IdeiasFilterButton key={status} status={status} {...allStatus[status]} total={total} />
          ))}
      </Group>
    </ScrollArea>
  );
};
