import { subject } from "@casl/ability";
import {
  FirestoreDataConverter,
  collection,
  doc,
  getAggregateFromServer,
  getDoc,
  getDocs,
  serverTimestamp,
  setDoc,
  sum
} from "firebase/firestore";
import { getIdeiaDocumentReference } from "../ideias/firestore";

const IDEIAS_ACTIVITY_PER_COLABORADOR_COLLECTION_KEY = "ideiasActivitiesPerColaborador" as const;

export type TIdeiaActivityPerColaboradorForm = TFormWithTransformations<TIdeiaActivityPerColaborador>;

type TIdeiaActivityPerColaboradorDocument = TFirestoreDocument<TIdeiaActivityPerColaborador>;

const ideiaActivityPerColaboradorComentarioConverter: FirestoreDataConverter<TIdeiaActivityPerColaborador> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const { createdAt, updatedAt, ...document } = snap.data() as TIdeiaActivityPerColaboradorDocument;

    const data: TIdeiaActivityPerColaborador = {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      refPath: snap.ref.path
    };

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }
    return subject("TIdeiaActivityPerColaborador", data);
  }
};

/**
 * Get ideias collection reference from empresaId
 * @param empresaId - Empresa id to get the ideias collection reference
 * @param ideiaId - Ideia id to get the ideias collection reference
 * @returns - Ideias collection reference
 */
function getIdeiaActivitiesPerColaboradorCollectionReference(empresaId: TEmpresa["id"], ideiaId: TIdeia["id"]) {
  const ideiaDocumentReference = getIdeiaDocumentReference(empresaId, ideiaId);
  return collection(ideiaDocumentReference, IDEIAS_ACTIVITY_PER_COLABORADOR_COLLECTION_KEY);
}

/**
 * Get the activity document reference from the colaborador in the ideia
 * @param empresaId - Empresa ID
 * @param ideiaId - Ideia ID
 * @param colaboradorId - Colaborador ID
 * @returns - The activity document reference from the colaborador in the ideia
 */
function getIdeiaActivitiesPerColaboradorDocumentReference(
  empresaId: TEmpresa["id"],
  ideiaId: TIdeia["id"],
  colaboradorId: TColaborador["id"]
) {
  const ideiaActivitiesPerColaboradorCollection = getIdeiaActivitiesPerColaboradorCollectionReference(
    empresaId,
    ideiaId
  );
  return doc(ideiaActivitiesPerColaboradorCollection, colaboradorId).withConverter(
    ideiaActivityPerColaboradorComentarioConverter
  );
}

/**
 * Get the activity from the colaborador in the ideia
 * @param empresaId - Empresa ID
 * @param ideiaId - Ideia ID
 * @param colaboradorId - Colaborador ID
 * @returns - The activity from the colaborador in the ideia
 */
export function getIdeiaActivitiesPerColaborador(
  empresaId: TEmpresa["id"],
  ideiaId: TIdeia["id"],
  colaboradorId: TColaborador["id"]
) {
  const ideiaActivityPerColaboradorDocumentReference = getIdeiaActivitiesPerColaboradorDocumentReference(
    empresaId,
    ideiaId,
    colaboradorId
  ).withConverter(ideiaActivityPerColaboradorComentarioConverter);

  return getDoc(ideiaActivityPerColaboradorDocumentReference);
}

/**
 * Get the total moedas from ideia
 * @param empresaId - Empresa ID
 * @param ideiaId - Ideia ID
 * @returns - The activity document reference from the colaborador in the ideia
 */
export function getSumMoedasPerIdeia(empresaId: TEmpresa["id"], ideiaId: TIdeia["id"]) {
  const ideiaActivitiesPerColaboradorCollection = getIdeiaActivitiesPerColaboradorCollectionReference(
    empresaId,
    ideiaId
  );

  return getAggregateFromServer(ideiaActivitiesPerColaboradorCollection, {
    moedas: sum("moedas")
  });
}

/**
 * Update the ideia activity per colaborador
 * @param empresaId - The empresa ID
 * @param ideiaId - the ideia ID to update
 * @param colaboradorId - The colaborador ID to add comentário
 * @param patrocinio - The patrocinio to add
 * @returns - ideia activity per colaborador reference
 */
export function setPatrocinio(
  empresaId: TEmpresa["id"],
  ideiaId: TIdeia["id"],
  colaboradorId: TColaborador["id"],
  patrocinio: Pick<TIdeiaActivityPerColaboradorForm, "moedas"> & Pick<TAuditForm, "createdBy" | "updatedBy">
) {
  const ideiaComentariosCollectionReference = getIdeiaActivitiesPerColaboradorDocumentReference(
    empresaId,
    ideiaId,
    colaboradorId
  );
  return setDoc(
    ideiaComentariosCollectionReference,
    { ...patrocinio, createdAt: serverTimestamp(), updatedAt: serverTimestamp() },
    { merge: true }
  );
}

/**
 * Get the total moedas from ideia
 * @param empresaId - Empresa ID
 * @param ideiaId - Ideia ID
 * @returns - The activity document reference from the colaborador in the ideia
 */
export async function getIdeaActivities(empresaId: TEmpresa["id"], ideiaId: TIdeia["id"]) {
  const reference = getIdeiaActivitiesPerColaboradorCollectionReference(empresaId, ideiaId).withConverter(
    ideiaActivityPerColaboradorComentarioConverter
  );

  return await getDocs(reference);
}
