import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getQuestionarios } from "@/modules/grupos-diagnostico/firestore";
import { QUERY_KEY } from "@/modules/questionarios/components/questionario-table/QuestionarioTable";
import useUserStore from "@/modules/users/store";

type TGetQuestionarioResponse = Awaited<ReturnType<typeof getQuestionarios>>;

/**
 * Get questionário de inovação query hook
 * @returns Questionário de inovação query hook
 */
function useQueryQuestionario() {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  return useQuery<TGetQuestionarioResponse, FirestoreError, TQuestionario[]>({
    queryKey: ["empresas", { id: activeEmpresaId }, QUERY_KEY],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      return getQuestionarios(activeEmpresaId, "");
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }
      return dataResponse.docs.map((document_) => document_.data());
    }
  });
}

export default useQueryQuestionario;
