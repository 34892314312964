import { FocusTrap, Group, SegmentedControl, Text } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import { useState } from "react";
import BulkAddColaboradorForm from "../../components/bulk-add-colaborador-form";
import ColaboradorForm from "../../components/colaborador-form";
import { TColaboradorForm } from "../../firestore";
// prettier-ignore
const views = [
  { label: "Individual", value: "individual" },
  { label: "Vários", value: "varios" }
];

const ColaboradorFormModal = ({
  context,
  innerProps,
  id
}: ContextModalProps<{ item: TColaboradorForm; setName: (name: string) => void }>) => {
  const { item: colaborador, setName } = innerProps;
  const [view, setView] = useState("individual");

  /**
   * Handle add ignore area success
   */
  function onSuccess() {
    context.closeModal(id);
  }

  return (
    <FocusTrap active={true}>
      <Group justify="space-between" gap={0} align="center" mb="md">
        <Text fw="bold" mb="md">
          {colaborador?.id ? "Editar colaborador" : "Cadastro de colaborador"}
        </Text>
        {!colaborador.id && <SegmentedControl radius="md" size="xs" value={view} onChange={setView} data={views} />}
      </Group>

      {view === "individual" && <ColaboradorForm colaborador={colaborador} onSuccess={onSuccess} setName={setName} />}
      {view === "varios" && <BulkAddColaboradorForm onSuccess={onSuccess} />}
    </FocusTrap>
  );
};

export default ColaboradorFormModal;
