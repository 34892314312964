import { Button, Card, Flex, Group, Space, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import React, { MouseEvent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TCategoriaInovacaoForm } from "@/modules/categorias-de-inovacao/firestore";
import CardTipos from "./CardTipos";
import { TTipoInovacaoForm } from "../../firestore";
import tipoDeInovacaoSchema from "../../schema/tipo-de-inovacao-schema";
import useAddTipoDeInovacaoForm from "../add-tipo-de-inovacao/useAddTipoDeInovacaoForm";

interface Tipo {
  id: string | number;
  nome: string;
  descricao: string;
}

interface RowItem {
  id: string | number;
}

const onRemove = (item: RowItem, setTaticas: React.Dispatch<React.SetStateAction<Tipo[]>>) => {
  setTaticas((taticas: Tipo[]) => taticas.filter((row: RowItem) => row.id !== item.id));
};

const AddTaticaDeInovacao = ({
  data,
  categorias
}: {
  data: (TTipoInovacaoForm & Partial<Pick<TTipoDeInovacao, "id" | "refPath">>) | null | undefined;
  categorias: (TCategoriaInovacaoForm & Partial<Pick<TCategoriaDeInovacao, "id" | "refPath">>)[];
}) => {
  const navigate = useNavigate();
  const navigation = () => navigate("/tipos-de-inovacao");
  const [taticas, setTaticas] = useState<Tipo[]>([]);

  useEffect(() => {
    if (data?.taticas && Array.isArray(data.taticas as Tipo[]) && data.taticas.length > 0) {
      setTaticas(data.taticas as Tipo[]);
    }
  }, []);

  const { getInputProps, onSubmit, isLoading } = useAddTipoDeInovacaoForm(
    data || ({} as TTipoInovacaoForm),
    taticas,
    categorias as TCategoriaDeInovacao[],
    {
      onSuccess() {
        showNotification({
          message: `${getInputProps("nome")?.value} ${data?.id ? "atualizado" : "cadastrado"} com sucesso!`,
          color: "green",
          autoClose: 2500
        });
        navigate("/tipos-de-inovacao");
      }
    }
  );

  /**
   * Função que trata o clique do botão.
   * @param event - O evento de clique do botão.
   */
  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    const schema = tipoDeInovacaoSchema;
    const defaultValues = schema.getDefault() as unknown;
    event.preventDefault();
    modals.openContextModal({
      size: "xl",
      innerProps: {
        item: schema.cast(defaultValues, { stripUnknown: true, assert: false }),
        setTaticas,
        data: false
      },
      modal: "add-tipo-de-inovacao-form-modal"
    });
  }

  /**
   * Função que trata o clique do botão.
   * @param item - O evento de clique do botão.
   */
  function handleClickItem(item: Tipo) {
    const schema = tipoDeInovacaoSchema;
    const defaultValues = schema.getDefault() as unknown;
    modals.openContextModal({
      size: "xl",
      innerProps: {
        item: schema.cast(defaultValues, { stripUnknown: true, assert: false }),
        setTaticas,
        data: item
      },
      modal: "add-tipo-de-inovacao-form-modal"
    });
  }

  const handleRemoveClick = useCallback(
    (item: RowItem) => {
      onRemove(item, setTaticas);
    },
    [setTaticas]
  );

  const handleCancelClick = useCallback(() => {
    navigation();
  }, [navigation]);

  const category = categorias.find((item) => item.id === getInputProps("categoria").value);
  const nomeInputProperties = getInputProps("nome");
  const descricaoInputProperties = getInputProps("descricao");

  return (
    <form onSubmit={onSubmit}>
      <Card padding={0}>
        <Card.Section inheritPadding py="xs">
          <Text size="lg" fw={500}>
            Dados do tipo de inovação
          </Text>
          <Space h="lg" />
          <CardTipos
            titulo={"Categoria de inovação"}
            tituloValor={category?.nome || ""}
            descricao={"Descrição"}
            descricaoValor={category?.descricao || ""}
          />
          <Space h="lg" />
          <CardTipos
            titulo={"Tipo de inovação"}
            tituloValor={typeof nomeInputProperties.value === "string" ? nomeInputProperties.value : ""}
            descricao={"Descrição"}
            descricaoValor={typeof descricaoInputProperties.value === "string" ? descricaoInputProperties.value : ""}
          />
        </Card.Section>
      </Card>
      <Space h="lg" />
      <Group justify="space-between" align={"center"}>
        <Text size="lg" fw={500}>
          Táticas de inovação
        </Text>
        <Button color="rgba(8, 68, 244, 1)" variant="outline" radius="lg" size="md" onClick={handleClick}>
          <Text size="sm">Adicionar tática de inovação</Text>
        </Button>
      </Group>
      <Space h="lg" />
      {taticas && Array.isArray(taticas) && taticas.length > 0 ? (
        taticas.map((item: Tipo) => (
          <React.Fragment key={item.id}>
            <Card py="xs" style={{ background: "rgba(244, 245, 246, 1)" }}>
              <Group justify="space-between">
                <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
                  <Text size="md" fw={500}>
                    Táticas de inovação
                  </Text>
                  <Text size="sm" c="dimmed">
                    {item.nome}
                  </Text>
                </Flex>
                <Flex justify={"flex-end"}>
                  <Button
                    color="#525356"
                    variant="transparent"
                    radius="lg"
                    size="md"
                    p={4}
                    // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
                    onClick={() => handleClickItem(item)}
                  >
                    <IconEdit size={16} />
                  </Button>
                  <Button
                    color="#525356"
                    variant="transparent"
                    radius="lg"
                    size="md"
                    p={4}
                    // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
                    onClick={() => handleRemoveClick(item)}
                  >
                    <Group>
                      <IconTrash size={16} />
                      <Text size="sm">Excluir</Text>
                    </Group>
                  </Button>
                </Flex>
              </Group>
              <Space h="lg" />
              {/* <SimpleGrid cols={2}>
                <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
                  <Text size="md" fw={500}>
                    Segmento que se aplica
                  </Text>
                  <Text size="sm" c="dimmed">
                    {item.segmento}
                  </Text>
                </Flex>
                <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
                  <Text size="md" fw={500}>
                    Ambição
                  </Text>
                  <Text size="sm" c="dimmed">
                    {item.ambicao}
                  </Text>
                </Flex>
              </SimpleGrid> */}
              <Space h="lg" />
              <Text size="md" fw={500}>
                Descrição
              </Text>
              <Text size="sm" c="dimmed">
                {item.descricao}
              </Text>
            </Card>
            <Space h="lg" />
          </React.Fragment>
        ))
      ) : (
        <p>Nenhuma tática de inovação disponível</p>
      )}
      <Group justify="flex-end">
        <Button variant="transparent" color="rgba(82, 83, 86, 1)" onClick={handleCancelClick}>
          Cancelar
        </Button>
        <Button type="submit" variant="filled" color="rgba(8, 68, 244, 1)" loading={isLoading}>
          Salvar
        </Button>
      </Group>
    </form>
  );
};

export default AddTaticaDeInovacao;
