import { Avatar, Card, Flex, Group, Title } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import Can from "@/components/can";
import EditItemButton from "@/components/edit-item-button";
import empresaSchema from "@/modules/empresas/schema/empresa-schema";
import useUserStore from "@/modules/users/store";
import { extractInitialsFromText } from "@/utils/text";

const EmpresaSelecionadaCard = () => {
  const empresa = useUserStore((state) => state.activeEmpresa);

  if (!empresa) {
    return null;
  }

  const companyName = empresa.nomeFantasia || empresa.razaoSocial;

  return (
    <Card style={{ flex: 1 }} withBorder p="lg" pos="relative">
      <Flex align="center" h="100%">
        <Group p="lg">
          <Avatar src={null} alt={companyName} size="xl">
            {extractInitialsFromText(companyName)}
          </Avatar>
          <Title order={3} fw={500}>
            {companyName}
          </Title>
        </Group>
      </Flex>
      <Can I="update" this={empresa}>
        <EditItemButton
          modal="empresa-form-modal"
          schema={empresaSchema}
          item={empresa}
          size="xs"
          pos="absolute"
          top={20}
          right={30}
        >
          <Group gap={4}>
            <IconEdit size="1.2rem" stroke={1.5} />
            Editar
          </Group>
        </EditItemButton>
      </Can>
    </Card>
  );
};

export default EmpresaSelecionadaCard;
