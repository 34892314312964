import { useQuery } from "@tanstack/react-query";
import { DocumentReference, FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { getDocumentReferenceFromPath } from "@/utils/firestore";
import { getColaborador, getColaboradorFromDocument, colaboradorConverter } from "../firestore";

type GetColaboradorResponse = Awaited<ReturnType<typeof getColaborador>>;

/**
 * Get all colaboradores from reference path or document reference
 * @param colaboradoresReference Reference path or document reference
 * @returns All colaboradores
 */
function useGetColaboradoresFromReference(colaboradoresReference: string[] | DocumentReference<TColaborador>[]) {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  return useQuery<GetColaboradorResponse[], FirestoreError, TColaborador[]>({
    queryKey: ["empresas", { id: activeEmpresaId }, "colaboradores", { colaboradores: colaboradoresReference }],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      // eslint-disable-next-line compat/compat
      return Promise.all(
        colaboradoresReference.map((reference) => {
          if (typeof reference === "string") {
            const colaboradorDocumentReference = getDocumentReferenceFromPath(reference, colaboradorConverter);
            return getColaborador(activeEmpresaId, colaboradorDocumentReference.id);
          }

          return getColaboradorFromDocument(reference);
        })
      );
    },
    select(data) {
      const allColaboradores: TColaborador[] = [];

      for (const colaborador of data) {
        if (colaborador.exists()) {
          allColaboradores.push(colaborador.data());
        }
      }
      return allColaboradores;
    }
  });
}

export default useGetColaboradoresFromReference;
