import { subject } from "@casl/ability";
import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getColaborador } from "../firestore";

type TGetColaboradorResponse = Awaited<ReturnType<typeof getColaborador>>;

/**
 * Get colaborador for empresa and colaborador id
 * @param empresaId - Empresa id
 * @param colaboradorId - Colaborador id
 * @returns - Query results for get colaborador
 */
function useGetColaboradorById(empresaId: TEmpresa["id"], colaboradorId: TColaborador["id"]) {
  return useQuery<TGetColaboradorResponse, FirestoreError, TColaborador | null>({
    queryKey: ["empresas", empresaId, "colaboradores", colaboradorId],
    queryFn() {
      if (!empresaId) {
        const empresa = localStorage.getItem("activeEmpresaId");
        if (empresa) {
          empresaId = empresa;
        } else {
          throw new Error("Empresa não selecionada");
        }
      }

      return getColaborador(empresaId, colaboradorId);
    },
    select(dataResponse) {
      if (!dataResponse.exists()) {
        return null;
      }
      return subject("TColaborador", dataResponse.data());
    }
  });
}

export default useGetColaboradorById;
