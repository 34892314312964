import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getRepostasArray, QUERY_KEY } from "../firestore";

type TGetRespostaResponse = Awaited<ReturnType<typeof getRepostasArray>>;

type Properties = {
  rootEmpresaId: string;
  id: string | null | undefined;
  idQuestionario: TQuestionario["id"] | undefined;
};

/**
 * Get resposta from url params
 * @param root0 - properties
 * @param root0.rootEmpresaId - rootEmpresaId
 * @param root0.idQuestionario - idQuestionario
 * @param root0.id - id do colaborador
 * @returns - resposta or null if not found
 */
function useGetRespostaConvite({ rootEmpresaId, id, idQuestionario }: Properties) {
  return useQuery<TGetRespostaResponse, FirestoreError, TResposta[] | null>({
    queryKey: [QUERY_KEY, id, rootEmpresaId, idQuestionario],
    queryFn() {
      return getRepostasArray(rootEmpresaId || "", id, idQuestionario);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((document_) => document_.data());
    },
    enabled: !!id && !!rootEmpresaId
  });
}

export default useGetRespostaConvite;
