import { subject } from "@casl/ability";
import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { getTiposDeInovacao, QUERY_KEY } from "../../firestore";

type TGetTiposDeInovacaoResponse = Awaited<ReturnType<typeof getTiposDeInovacao>>;

/**
 * Get tipos de inovação query hook
 * @returns tipos de inovação query hook
 */
function useQueryTipoDeInovacao() {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  return useQuery<TGetTiposDeInovacaoResponse, FirestoreError, TTipoDeInovacao[]>({
    queryKey: ["empresas", { id: activeEmpresaId }, QUERY_KEY],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return getTiposDeInovacao(activeEmpresaId);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((document_) => subject("TTipoDeInovacao", document_.data()));
    }
  });
}

export default useQueryTipoDeInovacao;
