import { Button, Group, Space, Textarea, TextInput } from "@mantine/core";
import { extractTextInputPropertiesFromFormSchema } from "@/utils/form";
import useCategoriaDeInovacaoForm from "./useCategoriaDeInovacaoForm";
import { TCategoriaInovacaoForm } from "../../firestore";
import categoriaDeInovacaoSchema from "../../schema/categoria-de-inovacao-schema";

const CategoriaDeInovacaoForm = ({
  categoria,
  onSuccess
}: {
  categoria: TCategoriaInovacaoForm;
  onSuccess: () => void;
}) => {
  const { getInputProps, onSubmit, isLoading } = useCategoriaDeInovacaoForm(categoria, {
    onSuccess
  });
  const fieldsDescriptor = categoriaDeInovacaoSchema.describe();

  return (
    <form onSubmit={onSubmit}>
      <TextInput
        mt="md"
        {...getInputProps("nome")}
        {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "nome")}
        label={"Nome"}
      />
      <Space h="lg" />
      <Textarea
        autosize
        minRows={5}
        {...getInputProps("descricao")}
        {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "descricao")}
        label={"Descrição"}
      />
      <Space h="xl" />
      <Group justify="flex-end">
        <Button type="submit" variant="filled" loading={isLoading}>
          Salvar
        </Button>
      </Group>
    </form>
  );
};

export default CategoriaDeInovacaoForm;
