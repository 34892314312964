import { Text } from "@mantine/core";
import useGetProjetoItem from "./useGetProjetoItem";

interface Properties {
  id: string;
  idEmpresa: string | undefined;
}

const ProjetoLabel = (properties: Properties) => {
  const { data } = useGetProjetoItem(properties?.id, properties.idEmpresa);
  return <Text>{data?.nome}</Text>;
};

export default ProjetoLabel;
