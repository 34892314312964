import { ActionIcon, Group, Table as MantineTable } from "@mantine/core";
import { IconCaretDownFilled, IconCaretUpDown, IconCaretUpFilled } from "@tabler/icons-react";
import { Header, RowData, flexRender } from "@tanstack/react-table";

const sortIcon = {
  asc: <IconCaretDownFilled size="1rem" stroke={1.5} />,
  desc: <IconCaretUpFilled size="1rem" stroke={1.5} />,
  "": <IconCaretUpDown size="1rem" stroke={1.5} />
};

const TableHeader = <T extends RowData>({ header }: { header: Header<T, unknown> }) => (
  <MantineTable.Th>
    <Group gap={2} wrap="nowrap">
      {flexRender(header.column.columnDef.header, header.getContext())}
      {header.column.getCanSort() && (
        <ActionIcon
          variant="subtle"
          size="xs"
          color="gray"
          aria-label="Settings"
          type="button"
          onClick={header.column.getToggleSortingHandler()}
          tabIndex={-1}
        >
          {sortIcon[header.column.getIsSorted() || ""]}
        </ActionIcon>
      )}
    </Group>
  </MantineTable.Th>
);

export default TableHeader;
