import { Text, Card, Group, BackgroundImage, Button, Space, Anchor } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconCalendar, IconFlame } from "@tabler/icons-react";
import { MouseEvent, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import cronogramaSchema from "@/modules/desafio-cronograma/schema";
import useUserStore from "@/modules/users/store";
import useGetAllDesafios from "./useGetAllDesafios";
import getCronograma from "./useGetCronograma";
import useGetDesafioByElementId from "./useGetDesafioById";

const message = "Ocorreu um erro ao carregar os dados:";
const MAX_TITLE_SIZE = 120;
const DEFAULT_IMAGE_URL =
  "https://firebasestorage.googleapis.com/v0/b/acelera-inova.appspot.com/o/assets%2Fwaves.jpeg?alt=media&token=f03e8fdd-7cac-4a0e-b2f6-f39239f69053";

const CronogramasDeDesafiosCard = () => {
  const color = "rgba(8, 68, 244, 1)";

  const [data, setData] = useState<TCronogramaDesafio[]>([]);
  const [reload, setReload] = useState(false);

  const { activeEmpresaId, colaborador } = useUserStore();

  const { data: desafiosList } = useGetAllDesafios();

  const newData = data.filter((item) => {
    const desafio = desafiosList || [];
    return desafio?.filter((row) => row.id === item.idDesafio)?.length > 0;
  });

  const uniqueData = newData && newData[0];

  const { data: desafio } = useGetDesafioByElementId({ id: uniqueData?.idDesafio || "" });

  useEffect(() => {
    const loadData = async () => {
      const arrayDesafio = await getCronograma(activeEmpresaId || "");
      setData(arrayDesafio);
    };
    const fetchData = async () => {
      try {
        await loadData();
      } catch (error) {
        console.error(message, error);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, [activeEmpresaId, reload]);

  /**
   * Função que trata o clique do botão.
   * @param event - O evento de clique do botão.
   */
  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    const schema = cronogramaSchema;
    const defaultValues = schema.getDefault() as unknown;
    event.preventDefault();
    modals.openContextModal({
      size: "xl",
      innerProps: {
        item: schema.cast(defaultValues, { stripUnknown: true, assert: false }),
        data: data,
        refetch: () => setReload((reload) => !reload)
      },
      modal: "cronograma-desafios"
    });
  }

  const Background = ({ children }: { children: React.ReactNode }) => {
    return (
      <Card withBorder p={0} maw={200} h={335}>
        <BackgroundImage
          src={DEFAULT_IMAGE_URL}
          radius="xs"
          style={{ backgroundColor: "rgba(97, 136, 250, 1)", padding: 10, backgroundBlendMode: "multiply" }}
          h={335}
        >
          {children}
        </BackgroundImage>
      </Card>
    );
  };

  const hasChallenge = !!newData?.length;
  const hasMultipleChallenges = hasChallenge && newData.length > 1;
  const canCreateChallenge = colaborador?.role !== "COLLABORATOR";

  if (hasMultipleChallenges) {
    return (
      <Background>
        <Text c={"white"} fw={700} size="md">
          Visualize os desafios da empresa:
        </Text>
        <Space h="md" />
        <Group justify={"center"} h={"100%"}>
          <Button bg={color} onClick={handleClick}>
            Visualizar
          </Button>
        </Group>
      </Background>
    );
  }

  if (hasChallenge) {
    return (
      <Background>
        <Text c={"white"} fw={700} size="md">
          Se inscreva para o dia do desafio
        </Text>
        <Space h="md" />
        <Group justify={"flex-start"} align={"center"} style={{ gap: "unset", marginRight: 5 }}>
          <IconFlame color={"white"} style={{ fontSize: "12" }} />
          <Text c={"white"} fw={500} size="xs">
            Desafio de inovação
          </Text>
        </Group>
        <Text c={"white"} fw={600} size="sm">
          {desafio?.titulo && desafio.titulo?.length > MAX_TITLE_SIZE
            ? `${desafio.titulo.slice(0, MAX_TITLE_SIZE)}...`
            : desafio?.titulo}
        </Text>
        <Space h="md" />
        <Group justify={"flex-start"} align={"center"} style={{ gap: "unset" }}>
          <IconCalendar color={"white"} style={{ fontSize: "12", marginRight: 5 }} />
          <Text c={"white"} fw={500} size="xs">
            Data de inscrição
          </Text>
        </Group>
        <Text c={"white"} fw={600} size="sm">
          {uniqueData?.inicioIncricao?.toLocaleDateString("pt-BR") ?? ""} -{" "}
          {uniqueData?.terminoIncricao?.toLocaleDateString("pt-BR") ?? ""}{" "}
        </Text>
        <Space h="md" />
        <Group justify={"center"}>
          <Button bg={color} onClick={handleClick}>
            Se inscreva
          </Button>
        </Group>
      </Background>
    );
  }

  if (canCreateChallenge) {
    return (
      <Background>
        <Text c={"white"} fw={700} size="md">
          Cadastre um desafio para iniciar:
        </Text>
        <Space h="md" />
        <Group justify={"center"} h={"100%"}>
          <Anchor component={NavLink} c={color} to={`/desafios`} underline="never">
            <Button bg={color}>Cadastrar</Button>
          </Anchor>
        </Group>
      </Background>
    );
  }

  return <></>;
};

export default CronogramasDeDesafiosCard;
