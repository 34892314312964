import { Timestamp } from "firebase/firestore";
import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";
import { ability } from "@/components/can/Can";
import { captureException } from "@/services/log";
import defineAbilityFor from "@/src/define-ability-for";
import { getEmpresaById } from "../empresas/firestore";
import { addAutorizacao, getAutorizacoes } from "../perfil-de-acesso/firestore";

interface UserState {
  activeLicenciadaId?: string;
  activeEmpresaId?: string;
  activeEmpresa?: TEmpresa;
  activeProjeto?: TProjeto["id"] | null;
  colaborador?: TColaborador;
  autorizacoes?: TAutorizacao[];
  reloadConvites?: boolean;

  reset: () => void;
}

const initialState: Omit<UserState, "reset"> = {
  activeEmpresaId: undefined,
  activeEmpresa: undefined,
  colaborador: undefined,
  autorizacoes: undefined,
  reloadConvites: false
};

const useUserStore = create(
  subscribeWithSelector<UserState>((set) => ({
    ...initialState,
    reset() {
      set(initialState);
    }
  }))
);

useUserStore.subscribe(
  (state) => state.activeEmpresaId,
  function fetchEmpresa(activeEmpresaId) {
    void setActiveEmpresa(activeEmpresaId);
    void setAutorizacoes(activeEmpresaId);
  }
);

useUserStore.subscribe(
  function (state): [TEmpresa | undefined, TColaborador | undefined, TAutorizacao[] | undefined] {
    return [state.activeEmpresa, state.colaborador, state.autorizacoes];
  },
  function updateAbility([activeEmpresa, colaborador, autorizacoes]) {
    if (activeEmpresa && colaborador) {
      const newAbility = defineAbilityFor(activeEmpresa, colaborador, autorizacoes);
      ability.update(newAbility.rules);
    } else {
      ability.update([]);
    }
  }
);
export const getActiveLicenciadaId = (): string | undefined => {
  return useUserStore.getState().activeLicenciadaId;
};
export const setActiveLicenciadaId = (activeLicenciadaId: UserState["activeLicenciadaId"]) =>
  useUserStore.setState(() => ({ activeLicenciadaId }));
export const setActiveEmpresaId = (activeEmpresaId: UserState["activeEmpresaId"]) =>
  useUserStore.setState(() => ({ activeEmpresaId }));
export const setColaborador = (colaborador: UserState["colaborador"]) => useUserStore.setState(() => ({ colaborador }));
export const setReloadConvites = (convite: UserState["reloadConvites"]) =>
  useUserStore.setState(() => ({ reloadConvites: !convite }));
export const setActiveProjeto = (activeProjeto: UserState["activeProjeto"] | null) =>
  useUserStore.setState(() => ({ activeProjeto }));

const setActiveEmpresa = async (activeEmpresaId: UserState["activeEmpresaId"]) => {
  if (activeEmpresaId) {
    const empresaSnapshot = await getEmpresaById(activeEmpresaId).catch((error: Error) => {
      captureException(error, true);
      throw error;
    });
    useUserStore.setState(() => ({ activeEmpresa: empresaSnapshot.exists() ? empresaSnapshot.data() : undefined }));
  } else {
    useUserStore.setState(() => ({ activeEmpresa: undefined }));
  }
};

// prettier-ignore
export const setAutorizacoes = async (activeEmpresaId: UserState["activeEmpresaId"]) => {
  if (activeEmpresaId) {
    const autorizacaoSnapshot = await getAutorizacoes(activeEmpresaId).catch((error: Error) => {
      captureException(error, true);
      throw error;
    });
    const autorizacoes = autorizacaoSnapshot.docs.map((document_) => document_.data())
    if (autorizacoes && autorizacoes.length === 0) {
      // Array de Promises das chamadas addAutorizacao
      const promises = lista.map((item) => {
        return addAutorizacao(activeEmpresaId, {
          ...item,
          updatedAt: Timestamp.now(),
          createdBy: "",
          createdAt: Timestamp.now()
        });
      });

      await Promise.all(promises);

      const newAutorizations = await getAutorizacoes(activeEmpresaId).catch((error: Error) => {
        captureException(error, true);
        throw error;
      });

      useUserStore.setState(() => ({ autorizacoes: newAutorizations.docs.map((document_) => document_.data()) }));
    } else {
      useUserStore.setState(() => ({ autorizacoes: autorizacoes }));
    }
  }
};

export default useUserStore;
// prettier-ignore
const desafioCategoriaField = "Atividades";
const commiteDeInovacaoField = "Comitê de inovação";
const regulamentoDeIdeiasField = "Regulamento de ideias";
const regulamentoDeDesafiosField = "Regulamento de desafios";
const projetosField = "Projetos";
const tipoDeJogadaField = "Tipo de jogada";
const categoriaDeInovacaoField = "Categoria de inovação";
const marcoField = "Marco";
const desafioField = "Desafio";
const tipoDeRespostaField = "Tipo de resposta";
const perguntaField = "Pergunta";
const gruposField = "Grupos";
const questionarioField = "Questionario";
// const regulamentoFields = "Regulamento";
const ideiasField = "Ideias";
const colaboradoresField = "Colaboradores";
const empresaField = "Empresa";
const ranking = "Ranking de colaboradores";
const implementacao = "implementação de jogadas de inovação";
const tipodejogada = "Tipo de inovação";
const jogadadeinovacao = "Jogada de inovação";
// prettier-ignore
const lista = [
  { tipoAutorizacao: "1", label: empresaField, leitura: true, criacao: true, editar: true, remover: true, tipo: "1" },
  { tipoAutorizacao: "2", label: colaboradoresField, leitura: true, criacao: true, editar: true, remover: true, tipo: "1" },
  { tipoAutorizacao: "3", label: ideiasField, leitura: true, criacao: true, editar: true, remover: true, tipo: "1" },
  // { tipoAutorizacao: "4", label: regulamentoFields, leitura: true, criacao: true, editar: true, remover: true, tipo: "1" },
  { tipoAutorizacao: "5", label: questionarioField, leitura: true, criacao: true, editar: true, remover: true, tipo: "1" },
  { tipoAutorizacao: "6", label: gruposField, leitura: true, criacao: true, editar: true, remover: true, tipo: "1" },
  { tipoAutorizacao: "7", label: perguntaField, leitura: true, criacao: true, editar: true, remover: true, tipo: "1" },
  { tipoAutorizacao: "8", label: tipoDeRespostaField, leitura: true, criacao: true, editar: true, remover: true, tipo: "1" },
  { tipoAutorizacao: "9", label: desafioField, leitura: true, criacao: true, editar: true, remover: true, tipo: "1" },
  { tipoAutorizacao: "10", label: marcoField, leitura: true, criacao: true, editar: true, remover: true, tipo: "1" },
  { tipoAutorizacao: "11", label: categoriaDeInovacaoField, leitura: true, criacao: true, editar: true, remover: true, tipo: "1" },
  { tipoAutorizacao: "12", label: tipoDeJogadaField, leitura: true, criacao: true, editar: true, remover: true, tipo: "1" },
  { tipoAutorizacao: "13", label: projetosField, leitura: true, criacao: true, editar: true, remover: true, tipo: "1" },
  { tipoAutorizacao: "14", label: regulamentoDeDesafiosField, leitura: true, criacao: true, editar: true, remover: true, tipo: "1" },
  { tipoAutorizacao: "15", label: regulamentoDeIdeiasField, leitura: true, criacao: true, editar: true, remover: true, tipo: "1" },
  { tipoAutorizacao: "16", label: commiteDeInovacaoField, leitura: true, criacao: true, editar: true, remover: true, tipo: "1" },
  { tipoAutorizacao: "17", label: desafioCategoriaField, leitura: true, criacao: true, editar: true, remover: true, tipo: "1" },
  { tipoAutorizacao: "18", label: ranking, leitura: true, criacao: true, editar: true, remover: true, tipo: "1" },
  { tipoAutorizacao: "19", label: implementacao, leitura: true, criacao: true, editar: true, remover: true, tipo: "1" },
  { tipoAutorizacao: "20", label: tipodejogada, leitura: true, criacao: true, editar: true, remover: true, tipo: "1" },
  { tipoAutorizacao: "21", label: jogadadeinovacao, leitura: true, criacao: true, editar: true, remover: true, tipo: "1" },
  { tipoAutorizacao: "1", label: empresaField, leitura: true, criacao: true, editar: true, remover: true, tipo: "2" },
  { tipoAutorizacao: "2", label: colaboradoresField, leitura: true, criacao: true, editar: true, remover: true, tipo: "2" },
  { tipoAutorizacao: "3", label: ideiasField, leitura: true, criacao: true, editar: true, remover: true, tipo: "2" },
  // { tipoAutorizacao: "4", label: regulamentoFields, leitura: true, criacao: true, editar: true, remover: true, tipo: "2" },
  { tipoAutorizacao: "5", label: questionarioField, leitura: true, criacao: true, editar: true, remover: true, tipo: "2" },
  { tipoAutorizacao: "6", label: gruposField, leitura: true, criacao: true, editar: true, remover: true, tipo: "2" },
  { tipoAutorizacao: "7", label: perguntaField, leitura: true, criacao: true, editar: true, remover: true, tipo: "2" },
  { tipoAutorizacao: "8", label: tipoDeRespostaField, leitura: true, criacao: true, editar: true, remover: true, tipo: "2" },
  { tipoAutorizacao: "9", label: desafioField, leitura: true, criacao: true, editar: true, remover: true, tipo: "2" },
  { tipoAutorizacao: "10", label: marcoField, leitura: true, criacao: true, editar: true, remover: true, tipo: "2" },
  { tipoAutorizacao: "11", label: categoriaDeInovacaoField, leitura: true, criacao: true, editar: true, remover: true, tipo: "2" },
  { tipoAutorizacao: "12", label: tipoDeJogadaField, leitura: true, criacao: true, editar: true, remover: true, tipo: "2" },
  { tipoAutorizacao: "13", label: projetosField, leitura: true, criacao: true, editar: true, remover: true, tipo: "2" },
  { tipoAutorizacao: "14", label: regulamentoDeDesafiosField, leitura: true, criacao: true, editar: true, remover: true, tipo: "2" },
  { tipoAutorizacao: "15", label: regulamentoDeIdeiasField, leitura: true, criacao: true, editar: true, remover: true, tipo: "2" },
  { tipoAutorizacao: "16", label: commiteDeInovacaoField, leitura: true, criacao: true, editar: true, remover: true, tipo: "2" },
  { tipoAutorizacao: "17", label: desafioCategoriaField, leitura: true, criacao: true, editar: true, remover: true, tipo: "2" },
  { tipoAutorizacao: "18", label: ranking, leitura: true, criacao: true, editar: true, remover: true, tipo: "2" },
  { tipoAutorizacao: "19", label: implementacao, leitura: true, criacao: true, editar: true, remover: true, tipo: "2" },
  { tipoAutorizacao: "20", label: tipodejogada, leitura: true, criacao: true, editar: true, remover: true, tipo: "2" },
  { tipoAutorizacao: "21", label: jogadadeinovacao, leitura: true, criacao: true, editar: true, remover: true, tipo: "2" },
  { tipoAutorizacao: "1", label: empresaField, leitura: true, criacao: true, editar: true, remover: true, tipo: "3" },
  { tipoAutorizacao: "2", label: colaboradoresField, leitura: true, criacao: true, editar: true, remover: true, tipo: "3" },
  { tipoAutorizacao: "3", label: ideiasField, leitura: true, criacao: true, editar: true, remover: true, tipo: "3" },
  // { tipoAutorizacao: "4", label: regulamentoFields, leitura: true, criacao: true, editar: true, remover: true, tipo: "3" },
  { tipoAutorizacao: "5", label: questionarioField, leitura: true, criacao: true, editar: true, remover: true, tipo: "3" },
  { tipoAutorizacao: "6", label: gruposField, leitura: true, criacao: true, editar: true, remover: true, tipo: "3" },
  { tipoAutorizacao: "7", label: perguntaField, leitura: true, criacao: true, editar: true, remover: true, tipo: "3" },
  { tipoAutorizacao: "8", label: tipoDeRespostaField, leitura: true, criacao: true, editar: true, remover: true, tipo: "3" },
  { tipoAutorizacao: "9", label: desafioField, leitura: true, criacao: true, editar: true, remover: true, tipo: "3" },
  { tipoAutorizacao: "10", label: marcoField, leitura: true, criacao: true, editar: true, remover: true, tipo: "3" },
  { tipoAutorizacao: "11", label: categoriaDeInovacaoField, leitura: true, criacao: true, editar: true, remover: true, tipo: "3" },
  { tipoAutorizacao: "12", label: tipoDeJogadaField, leitura: true, criacao: true, editar: true, remover: true, tipo: "3" },
  { tipoAutorizacao: "13", label: projetosField, leitura: true, criacao: true, editar: true, remover: true, tipo: "3" },
  { tipoAutorizacao: "14", label: regulamentoDeDesafiosField, leitura: true, criacao: true, editar: true, remover: true, tipo: "3" },
  { tipoAutorizacao: "15", label: regulamentoDeIdeiasField, leitura: true, criacao: true, editar: true, remover: true, tipo: "3" },
  { tipoAutorizacao: "16", label: commiteDeInovacaoField, leitura: true, criacao: true, editar: true, remover: true, tipo: "3" },
  { tipoAutorizacao: "17", label: desafioCategoriaField, leitura: true, criacao: true, editar: true, remover: true, tipo: "3" },
  { tipoAutorizacao: "18", label: ranking, leitura: true, criacao: true, editar: true, remover: true, tipo: "3" },
  { tipoAutorizacao: "19", label: implementacao, leitura: true, criacao: true, editar: true, remover: true, tipo: "3" },
  { tipoAutorizacao: "20", label: tipodejogada, leitura: true, criacao: true, editar: true, remover: true, tipo: "3" },
  { tipoAutorizacao: "21", label: jogadadeinovacao, leitura: true, criacao: true, editar: true, remover: true, tipo: "3" },
  { tipoAutorizacao: "1", label: empresaField, leitura: true, criacao: true, editar: true, remover: true, tipo: "4" },
  { tipoAutorizacao: "2", label: colaboradoresField, leitura: true, criacao: true, editar: true, remover: true, tipo: "4" },
  { tipoAutorizacao: "3", label: ideiasField, leitura: true, criacao: true, editar: true, remover: true, tipo: "4" },
  // { tipoAutorizacao: "4", label: regulamentoFields, leitura: true, criacao: true, editar: true, remover: true, tipo: "4" },
  { tipoAutorizacao: "5", label: questionarioField, leitura: true, criacao: true, editar: true, remover: true, tipo: "4" },
  { tipoAutorizacao: "6", label: gruposField, leitura: true, criacao: true, editar: true, remover: true, tipo: "4" },
  { tipoAutorizacao: "7", label: perguntaField, leitura: true, criacao: true, editar: true, remover: true, tipo: "4" },
  { tipoAutorizacao: "8", label: tipoDeRespostaField, leitura: true, criacao: true, editar: true, remover: true, tipo: "4" },
  { tipoAutorizacao: "9", label: desafioField, leitura: true, criacao: true, editar: true, remover: true, tipo: "4" },
  { tipoAutorizacao: "10", label: marcoField, leitura: true, criacao: true, editar: true, remover: true, tipo: "4" },
  { tipoAutorizacao: "11", label: categoriaDeInovacaoField, leitura: true, criacao: true, editar: true, remover: true, tipo: "4" },
  { tipoAutorizacao: "12", label: tipoDeJogadaField, leitura: true, criacao: true, editar: true, remover: true, tipo: "4" },
  { tipoAutorizacao: "13", label: projetosField, leitura: true, criacao: true, editar: true, remover: true, tipo: "4" },
  { tipoAutorizacao: "14", label: regulamentoDeDesafiosField, leitura: true, criacao: true, editar: true, remover: true, tipo: "4" },
  { tipoAutorizacao: "15", label: regulamentoDeIdeiasField, leitura: true, criacao: true, editar: true, remover: true, tipo: "4" },
  { tipoAutorizacao: "16", label: commiteDeInovacaoField, leitura: true, criacao: true, editar: true, remover: true, tipo: "4" },
  { tipoAutorizacao: "17", label: desafioCategoriaField, leitura: true, criacao: true, editar: true, remover: true, tipo: "4" },
  { tipoAutorizacao: "18", label: ranking, leitura: true, criacao: true, editar: true, remover: true, tipo: "4" },
  { tipoAutorizacao: "19", label: implementacao, leitura: true, criacao: true, editar: true, remover: true, tipo: "4" },
  { tipoAutorizacao: "20", label: tipodejogada, leitura: true, criacao: true, editar: true, remover: true, tipo: "4" },
  { tipoAutorizacao: "21", label: jogadadeinovacao, leitura: true, criacao: true, editar: true, remover: true, tipo: "4" },
  { tipoAutorizacao: "1", label: empresaField, leitura: true, criacao: true, editar: true, remover: true, tipo: "5" },
  { tipoAutorizacao: "2", label: colaboradoresField, leitura: true, criacao: true, editar: true, remover: true, tipo: "5" },
  { tipoAutorizacao: "3", label: ideiasField, leitura: true, criacao: true, editar: true, remover: true, tipo: "5" },
  // { tipoAutorizacao: "4", label: regulamentoFields, leitura: true, criacao: true, editar: true, remover: true, tipo: "5" },
  { tipoAutorizacao: "5", label: questionarioField, leitura: true, criacao: true, editar: true, remover: true, tipo: "5" },
  { tipoAutorizacao: "6", label: gruposField, leitura: true, criacao: true, editar: true, remover: true, tipo: "5" },
  { tipoAutorizacao: "7", label: perguntaField, leitura: true, criacao: true, editar: true, remover: true, tipo: "5" },
  { tipoAutorizacao: "8", label: tipoDeRespostaField, leitura: true, criacao: true, editar: true, remover: true, tipo: "5" },
  { tipoAutorizacao: "9", label: desafioField, leitura: true, criacao: true, editar: true, remover: true, tipo: "5" },
  { tipoAutorizacao: "10", label: marcoField, leitura: true, criacao: true, editar: true, remover: true, tipo: "5" },
  { tipoAutorizacao: "11", label: categoriaDeInovacaoField, leitura: true, criacao: true, editar: true, remover: true, tipo: "5" },
  { tipoAutorizacao: "12", label: tipoDeJogadaField, leitura: true, criacao: true, editar: true, remover: true, tipo: "5" },
  { tipoAutorizacao: "13", label: projetosField, leitura: true, criacao: true, editar: true, remover: true, tipo: "5" },
  { tipoAutorizacao: "14", label: regulamentoDeDesafiosField, leitura: true, criacao: true, editar: true, remover: true, tipo: "5" },
  { tipoAutorizacao: "15", label: regulamentoDeIdeiasField, leitura: true, criacao: true, editar: true, remover: true, tipo: "5" },
  { tipoAutorizacao: "16", label: commiteDeInovacaoField, leitura: true, criacao: true, editar: true, remover: true, tipo: "5" },
  { tipoAutorizacao: "17", label: desafioCategoriaField, leitura: true, criacao: true, editar: true, remover: true, tipo: "5" },
  { tipoAutorizacao: "18", label: ranking, leitura: true, criacao: true, editar: true, remover: true, tipo: "5" },
  { tipoAutorizacao: "19", label: implementacao, leitura: true, criacao: true, editar: true, remover: true, tipo: "5" },
  { tipoAutorizacao: "20", label: tipodejogada, leitura: true, criacao: true, editar: true, remover: true, tipo: "5" },
  { tipoAutorizacao: "21", label: jogadadeinovacao, leitura: true, criacao: true, editar: true, remover: true, tipo: "5" }
];
