import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { QUERY_KEY } from "@/modules/tipos-de-respostas/components/tipos-de-respostas-table/TiposDeRespostasTable";
import { getTiposDeRespostasItem } from "@/modules/tipos-de-respostas/firestore";

type TGetGroupResponse = Awaited<ReturnType<typeof getTiposDeRespostasItem>>;

/**
 * Get ideia from url params
 * @param id = id pergunta
 * @param id.id - id id
 * @param id.activeEmpresaId - empresa id
 * @returns - Ideia or null if not found
 */
// prettier-ignore
function useGetTipoPerguntaConvite({ id, activeEmpresaId }: { id: string | undefined , activeEmpresaId: string | undefined}) {
  // const activeEmpresaId = convite?.idEmpresa;

  return useQuery<TGetGroupResponse, FirestoreError, TTiposDeRespostas | null>({
    queryKey: [QUERY_KEY, id, activeEmpresaId],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!id) {
        throw new Error("Nenhum questionario");
      }

      return getTiposDeRespostasItem(activeEmpresaId, id);
    },
    select(dataResponse) {
      if (!dataResponse.exists()) {
        return null;
      }

      return dataResponse.data();
    },
    enabled: !!id && !!activeEmpresaId
  });
}

export default useGetTipoPerguntaConvite;
