import { Card, Title } from "@mantine/core";
import DesafioComentarioForm from "@/modules/desafio-comentarios/components/desafio-comentario-form";

const DesafioComentarioFormCard = ({ desafio }: { desafio: TDesafio }) => {
  return (
    <Card withBorder style={{ overflow: "visible" }}>
      <Title order={4}>Deixe um comentário sobre esse desafio</Title>
      <DesafioComentarioForm desafio={desafio} />
    </Card>
  );
};

export default DesafioComentarioFormCard;
