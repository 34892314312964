import { Card, Group, Text, Flex, Stack, Space, Button, Mark, Anchor, SimpleGrid } from "@mantine/core";
import { modals } from "@mantine/modals";
import { QueryObserverResult } from "@tanstack/react-query";
import React, { MouseEvent } from "react";
import { NavLink } from "react-router-dom";
import useUserStore from "@/modules/users/store";
import { deleteJogadaDeInovacao } from "../../firestore";
import BadgeCategoria from "../badge-categoria";
import TaticasListCard from "../taticas-list-card";

interface Tatica {
  id: string | number;
  nome: string;
  descricao: string;
  nomeCategoria: string;
  nomeTipo: string;
}
// prettier-ignore
const JogadaDeInovacaoCard: React.FC<{
  jogada: TJogadaDeInovacao;
  formatAutorizacoes: TAutorizacao[] | undefined;
  refetch: () => Promise<QueryObserverResult>;
}> = ({ jogada, formatAutorizacoes, refetch }) => {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  /**
   * Handle removing the jogada de inovação.
   * @param event - The event that triggered the function.
   */
  function handleRemove(event: MouseEvent) {
    event.preventDefault();
    modals.openConfirmModal({
      title: "Remover tipo de inovação",
      centered: true,
      children: (
        <Text size="sm">
          Tem certeza que deseja remover o tipo de inovação <Mark>{jogada.nome}</Mark>
        </Text>
      ),
      labels: {
        confirm: "Sim",
        cancel: "Não"
      },
      confirmProps: { color: "red" },
      onConfirm: () => {
        void deleteJogadaDeInovacao(activeEmpresaId || "", jogada.id || "");
        void refetch();
      }
    });
  }

  return (
    <Card padding={"0px"} radius={0}>
      <SimpleGrid cols={3}>
        <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
          <Text fw={300} fz="xs">
            Tipo de jogada
          </Text>
          <Text fw={300} fz="sm">
            <BadgeCategoria id={jogada.tipoDeJogada} />
          </Text>
        </Flex>
        {jogada.segmento ? <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
          <Text fw={300} fz="xs">
            Segmento que se aplica
          </Text>
          <Text fw={400} fz="sm">
            {jogada.segmento}
          </Text>
        </Flex> : <></>}
        {jogada.ambicao ? <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
          <Text fw={300} fz="xs">
            Ambição
          </Text>
          <Text fw={400} fz="sm">
            {jogada.ambicao}
          </Text>
        </Flex> : <></>}
      </SimpleGrid>
      <Space h="xl" />
      <Group justify="space-between">
        <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
          <Text fw={300} fz="xs">
            Descrição da jogada
          </Text>
          <Text fw={400} fz="sm">
            {jogada.descricao}
          </Text>
        </Flex>
      </Group>
      <Space h="xl" />
      {jogada && jogada.taticas && Array.isArray(jogada.taticas) && jogada?.taticas?.length > 0 ? (
        <Card.Section inheritPadding py="xs">
          <Stack>
            <Card style={{ background: "rgb(245,246,247)" }}>
              {jogada.taticas.map((item: Tatica, index: number) => (
                <TaticasListCard key={index} jogada={item} />
              ))}
            </Card>
          </Stack>
        </Card.Section>
      ) : (
        <span>Nenhuma tática cadastrada</span>
      )}
      <Space h="xl" />
      <Group justify={"flex-end"}>
        {formatAutorizacoes?.find(item => item.tipoAutorizacao === "21")?.remover ? <Button
          type="button"
          variant="transparent"
          size="compact-sm"
          color="rgba(252, 85, 85, 1)"
          onClick={handleRemove}
        >
          Excluir
        </Button> : <></>}
        {formatAutorizacoes?.find(item => item.tipoAutorizacao === "21")?.editar ? <Anchor
          component={NavLink}
          color="rgba(8, 68, 244, 1)"
          to={`/jogadas-de-inovacao/normal/${jogada.id}`}
          underline="never"
        >
          <Button
            type="button"
            variant="transparent"
            size="compact-sm"
            color="rgba(8, 68, 244, 1)"
          >
            Editar
          </Button>
        </Anchor> : <></>}
      </Group>
    </Card>
  );
};

export default JogadaDeInovacaoCard;
