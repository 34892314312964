import { Container } from "@mantine/core";
import { useParams } from "react-router-dom";
import PerguntasTipo from "@/modules/responder-questionario/components/pergunta-tipos";
import useGetPerguntaConvite from "../../hooks/useGetPerguntaConvite";

const PerguntasItem = ({
  ids,
  questionario,
  resposta,
  licenciado
}: {
  ids: string[] | undefined;
  questionario: TQuestionario | null | undefined;
  resposta: TResposta[] | undefined | null;
  licenciado: TLicenciado | null | undefined;
}) => {
  const { idEmpresa } = useParams<{ idEmpresa: string }>();
  const { data: perguntas } = useGetPerguntaConvite({ ids, activeEmpresaId: idEmpresa });

  return (
    <Container fluid h={"65vh"} w={"100vw"} bg="#fff">
      <PerguntasTipo
        perguntas={ordemPerguntas(perguntas)}
        questionario={questionario}
        resposta={resposta}
        licenciado={licenciado}
        empresaConvidada={undefined}
      />
    </Container>
  );
};

export default PerguntasItem;
const ordemPerguntas = (data: TPergunta[] | undefined) => {
  return data?.sort((a, b) => a?.ordem - b?.ordem);
};
