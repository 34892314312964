/* eslint-disable react-perf/jsx-no-new-array-as-prop */
/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import { Box, Button, Group, LoadingOverlay, Modal, Select, Stack, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { regiaoIdentifierSchema } from "@/modules/regiao/schema/regiao-schema";
import { extractTextInputPropertiesFromFormSchema } from "@/utils/form";
import { CidadeIdentifierForm } from "./CidadeIdentifierForm";
import { EstadoIdentifierForm } from "./EstadoIdentifierForm";

type IdentifierFormModalProperties = {
  identifier?: TRegiaoIdentifier;
  open: boolean;
  onClose: (identifier?: TRegiaoIdentifier) => void;
};

const defaultIdentifier: TRegiaoIdentifier = {
  field: "cidade",
  value: ""
};

type FieldOption = {
  label: string;
  value: TRegiaoIdentifier["field"];
};

const fieldOptions: FieldOption[] = [
  { label: "CEP", value: "cep" },
  { label: "Estado", value: "estado" },
  { label: "Cidade", value: "cidade" },
  { label: "Bairro", value: "bairro" },
  { label: "Número", value: "numero" },
  { label: "Complemento", value: "complemento" },
  { label: "Logradouro", value: "logradouro" }
];

const fieldOptionsMap = new Map(fieldOptions.map((option) => [option.value, option.label]));

export const formatRegiaoIdentifier = (field: TRegiaoIdentifier["field"]) => {
  return fieldOptionsMap.get(field) ?? field;
};

/**
 * Identifier Form Modal
 * @param root0 - Properties
 * @param root0.identifier - Identifier
 * @param root0.open - Open
 * @param root0.onClose - On close
 * @returns - Identifier Form Modal
 */
function IdentifierFormModal({ identifier = defaultIdentifier, open, onClose }: IdentifierFormModalProperties) {
  const form = useForm({
    initialValues: identifier,
    validate: yupResolver(regiaoIdentifierSchema),
    transformValues: (values) => regiaoIdentifierSchema.cast(values)
  });

  const handleSubmit = (values?: TRegiaoIdentifier) => {
    onClose(values);
  };

  const handleSubmitEvent = (event: React.FormEvent<HTMLFormElement>) => {
    event.stopPropagation();
    event.preventDefault();
    return form.onSubmit(handleSubmit)(event);
  };

  const handleChangeValue = (value: string) => {
    form.setFieldValue("value", value);
  };

  const descriptor = regiaoIdentifierSchema.describe();
  const currentField = form.values.field as TRegiaoIdentifier["field"];

  return (
    <Modal zIndex={201} opened={open} onClose={() => onClose()}>
      <form onSubmit={handleSubmitEvent}>
        <Box pos="relative">
          <LoadingOverlay />
          <Stack gap="sm" mb="lg">
            <Select
              data={fieldOptions}
              style={{ flex: 1 }}
              {...form.getInputProps("field")}
              {...extractTextInputPropertiesFromFormSchema(descriptor, "field")}
            />

            {currentField === "cidade" && <CidadeIdentifierForm onChange={handleChangeValue} />}
            {currentField === "estado" && <EstadoIdentifierForm onChange={handleChangeValue} />}

            {currentField !== "cidade" && currentField !== "estado" && (
              <TextInput
                {...form.getInputProps("value")}
                {...extractTextInputPropertiesFromFormSchema(descriptor, "value")}
              />
            )}
          </Stack>
        </Box>
        <Group justify="flex-end" mt="md">
          <Button type="submit">Salvar</Button>
        </Group>
      </form>
    </Modal>
  );
}

export default IdentifierFormModal;
