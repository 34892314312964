import { subject } from "@casl/ability";
import { Badge, Center, Text } from "@mantine/core";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import ContextMenuRow from "./ContextMenuRow";
import ColaboradorStatusIcon from "../colaborador-status-icon";

const columnHelper = createColumnHelper<TColaborador>();

const columns: ColumnDef<TColaborador>[] = [
  columnHelper.accessor("status", {
    header: "Status",
    enableSorting: true,
    sortDescFirst: true,
    enableColumnFilter: true,
    cell: (properties) => {
      const colaborador = subject("TColaborador", properties.row.original);

      return (
        <Center>
          <ColaboradorStatusIcon status={colaborador.status} />
        </Center>
      );
    }
  }),
  columnHelper.accessor("nome", {
    header: "Nome",
    enableSorting: true,
    enableColumnFilter: true,
    meta: {
      type: "text"
    }
  }),
  columnHelper.accessor("email", {
    header: "E-mail",
    enableColumnFilter: true,
    enableSorting: true
  }),
  columnHelper.accessor("telefone", {
    header: "Telefone",
    enableColumnFilter: true,
    enableSorting: true
  }),
  columnHelper.accessor("dataNascimento", {
    header: "Data Nasc.",
    enableSorting: true,
    cell: (properties) => {
      const value = properties.getValue();

      return value?.toLocaleDateString("pt-BR") ?? "";
    }
  }),
  columnHelper.accessor("role", {
    header: "Perfil",
    enableSorting: false,
    cell: (properties) => {
      return (
        <Badge variant="light" size="sm">
          <Text tt="capitalize" fz="xs" fw="bold">
            {properties.getValue().toLocaleLowerCase()}
          </Text>
        </Badge>
      );
    }
  }),
  columnHelper.display({
    id: "actions",
    cell: (properties) => {
      const colaborador = subject("TColaborador", properties.row.original);
      return <ContextMenuRow colaborador={colaborador} />;
    }
  })
];

export default columns;
