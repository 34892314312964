import { Alert, Card, Center, Stack, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconAlertCircle } from "@tabler/icons-react";
import { QueryKey } from "@tanstack/react-query";
import { ColumnDef, Table as ReactTable } from "@tanstack/react-table";
import { forwardRef, useImperativeHandle } from "react";
import NoData from "@/components/no-data";
import ResetTableButton from "@/components/table/ResetTableButton";
import { THandler } from "@/components/table/Table";
import TableIdeia from "@/components/table/TableIdeia";
import TablePagination from "@/components/table/TablePagination";
import useQueryTable from "@/components/table/useQueryTable";
import useUserStore from "@/modules/users/store";
import tableColumns, { columnsIdeiasMobile } from "./Columns";
import { DEFAULT_PAGINATION, DEFAULT_SORTING, getIdeiasNew } from "../../firestore";
export const QUERY_KEY: string = "ideias-list" as const;

type IdeiasTableProperties = {
  columns?: ColumnDef<TIdeia>[];
  filterValue?: string;
};

// eslint-disable-next-line import/no-unused-modules
export let refetchIdeasTable: () => Promise<void>;

const IdeiasTable = forwardRef<THandler<TIdeia>, IdeiasTableProperties>(
  ({ columns = tableColumns, filterValue }, reference) => {
    const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
    const isSmMarches = useMediaQuery("(max-width: 48em)");
    const newColumns = isSmMarches ? columnsIdeiasMobile : columns;

    const { table, data, isLoading, error, filters, refetch } = useQueryTable<TIdeia>(
      {
        queryKey: [QUERY_KEY],
        enabled: !!activeEmpresaId,
        subjectType: "TIdeia",
        columns: newColumns,
        initialState: {
          columnFilters: [],
          globalFilter: null,
          pagination: DEFAULT_PAGINATION,
          sorting: DEFAULT_SORTING
        },
        queryFunction: async ({ sorting, filters, globalFilters, pagination, paginationCursors }) => {
          if (!activeEmpresaId) {
            throw new Error("Empresa não selecionada");
          }

          return await getIdeiasNew(activeEmpresaId, {
            sorting,
            filters,
            globalFilters,
            pagination,
            paginationCursors
          });
        }
      },
      // prettier-ignore
      filterValue
        ? {
          filterValue,
          options: {
            includeScore: true,
            keys: ["titulo"]
          }
        }
        : undefined
    );

    refetchIdeasTable = async () => {
      await refetch();
    };

    useImperativeHandle<THandler<TIdeia>, THandler<TIdeia>>(
      reference,
      () => {
        return {
          table,
          data,
          isLoading,
          error,
          filters
        };
      },
      [filters, data, error, isLoading, table, reference]
    );

    /**
     * Handles the close of the error alert.
     */
    function handleErrorClose() {
      void refetch();
    }

    if (error) {
      return (
        <Alert
          icon={<IconAlertCircle size="1rem" />}
          title="Erro ao buscar ideias"
          color="red"
          withCloseButton
          onClose={handleErrorClose}
        >
          {error.message}
        </Alert>
      );
    }

    const isEmpty = !isLoading && data?.entries.length === 0;

    return (
      <>
        <TableIdeia table={table} isLoading={isLoading} />
        {isEmpty && !isLoading && (
          <Center m="lg">
            <NoData m="md">
              <Stack gap="xs">
                <Text c="dimmed">Nenhuma ideia Encontrada!</Text>
                <ResetTableButton table={table as ReactTable<unknown>} queryKey={QUERY_KEY as unknown as QueryKey}>
                  Limpar filtros
                </ResetTableButton>
              </Stack>
            </NoData>
          </Center>
        )}
        {reference && "current" in reference && reference.current !== null ? (
          <Card.Section withBorder p={10}>
            <TablePagination tableHandlers={reference.current} />
          </Card.Section>
        ) : null}
      </>
    );
  }
);

IdeiasTable.displayName = "IdeiasTable";

export default IdeiasTable;
