import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import useGetCronograma from "@/modules/desafio-cronograma/components/desafio-cronograma-list/useGetInscricao";
import useUserStore from "@/modules/users/store";
import DesafioCronogramaCard from "./DesafioCronograma";
import DetailsDiaDoDesafio from "./DetailsDiadoDesafio";

// prettier-ignore
const CronogramaCardVerification = ({ desafio }: { desafio: TDesafio }) => {
  const { data, refetch } = useGetCronograma(desafio?.id);
  const empresa = useUserStore((state) => state.activeEmpresa);
  const colaboradorLogged = useUserStore((state) => state.colaborador);
  const { data: colaborador } = useGetColaboradorById(empresa?.id || "", desafio?.createdBy || "");

  if (colaboradorLogged && colaboradorLogged?.role !== "ADMIN" || desafio.createdBy !== colaborador?.id) {
    return <></>
  }

  return (
    <>
      <DesafioCronogramaCard desafio={desafio} refetch={refetch} />
      {data && data.map(item => <DetailsDiaDoDesafio key={item.id} data={item} desafio={desafio} refetch={refetch} />)}
    </>
  );
};

export default CronogramaCardVerification;
