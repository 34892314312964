import { Button } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconHexagonLetterX } from "@tabler/icons-react";

import { MutationOptions, useMutation, useQueryClient } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import {
  QUERY_KEY as COMITE_INOVACAO_COLLECTION,
  QUERY_KEY,
  finalizarComiteDeInovacao
} from "@/modules/comite-inovacao/firestore";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";

type TFinalizarComiteDeInovacaoResponse = Awaited<ReturnType<typeof finalizarComiteDeInovacao>>;
type TFinalizarComiteDeInovacaoMutationOptions = Omit<
  MutationOptions<TFinalizarComiteDeInovacaoResponse, FirestoreError>,
  "mutationFn"
>;
/**
 * Finalizar comite de inovação mutation hook
 * @param comite - Comite de inovação
 * @param [options] = {} - Mutation options
 * @returns Finalizar comite de inovação mutation
 */
function useFinalizarComiteInovacaoMutation(
  comite: TComiteInovacao,
  options: TFinalizarComiteDeInovacaoMutationOptions = {}
) {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  return useMutation<TFinalizarComiteDeInovacaoResponse, FirestoreError>({
    mutationFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return finalizarComiteDeInovacao(activeEmpresaId, comite.id);
    },
    ...options
  });
}

const FinalizarComiteInovacaoButton = ({ comite }: { comite: TComiteInovacao }) => {
  const queryClient = useQueryClient();
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  const { mutate, isPending } = useFinalizarComiteInovacaoMutation(comite, {
    onSuccess() {
      showNotification({
        color: "green",
        message: "Comitê finalizado com sucesso!",
        autoClose: 3500
      });

      // eslint-disable-next-line compat/compat
      return Promise.all([
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY]
        }),
        queryClient.invalidateQueries({
          queryKey: ["empresas", { id: activeEmpresaId }, COMITE_INOVACAO_COLLECTION, "ativo"]
        }),
        queryClient.invalidateQueries({
          queryKey: ["empresas", { id: activeEmpresaId }, COMITE_INOVACAO_COLLECTION]
        })
      ]);
    },
    onError(error) {
      captureException(error, true);
    }
  });

  /**
   * Handle click on button to finalizar comite de inovação
   */
  function onClick() {
    mutate();
  }

  return (
    <Button leftSection={<IconHexagonLetterX />} color="pink" onClick={onClick} loading={isPending}>
      Finalizar
    </Button>
  );
};

export default FinalizarComiteInovacaoButton;
