/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import { AppShell, AppShellProps, Box, Burger, Group, Select, Space, Text } from "@mantine/core";
import { useDisclosure, useElementSize, useMediaQuery } from "@mantine/hooks";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import getProjetos from "@/modules/relatorio/components/relatorio-item/useGetProjetos";
import useUserStore, { setActiveProjeto } from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import appContainerStyle from "./AppContainer.module.css";
import AppNavbar from "./AppNavbar";
import AppNavbarLogo from "./AppNavbarLogo";
import MenuUser from "./MenuUser";
import SpotlightButton from "./SpotlightButton";
import useGetConvites from "./useGetConvites";

type AppContainerProperties = PropsWithChildren<AppShellProps & { title: string; isSelect: boolean }>;

interface ComboboxItem {
  value: string;
  label: string;
}

const MARGIN_SIZE = 32;
const message = "Ocorreu um erro ao carregar os dados:";
const array = [] as [];

// eslint-disable-next-line complexity
const AppContainer: FC<AppContainerProperties> = ({ title, isSelect, children, ...rest }) => {
  const isSmMarches = useMediaQuery("(max-width: 48em)");

  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure(isSmMarches);
  const [projetos, setProjetos] = useState<ComboboxItem[]>([]);

  const empresa = useUserStore((state) => state.activeEmpresa);
  const projeto = useUserStore((state) => state.activeProjeto);

  const { ref, width } = useElementSize<HTMLDivElement>();

  const { data } = useGetConvites();

  const uid = getCurrentUser()?.uid;
  const { data: colaborador } = useGetColaboradorById(empresa?.id || "", uid || "");

  const location = useLocation();

  useEffect(() => {
    setActiveProjeto(null);
  }, [empresa?.id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadData();
      } catch (error) {
        console.error(message, error);
      }
    };
    void fetchData();
  }, [empresa?.id, location.pathname]);

  const loadData = async () => {
    const arrayProjetos = await getProjetos(empresa?.id || "");
    setProjetos(arrayProjetos.map((projeto) => ({ ...projeto, value: projeto.id, label: projeto.nome })));
  };

  return (
    <AppShell
      padding={0}
      withBorder={false}
      navbar={{
        width: 310,
        breakpoint: "sm",
        collapsed: { desktop: isSmMarches && mobileOpened, mobile: isSmMarches && mobileOpened }
      }}
      {...rest}
    >
      <AppNavbar toggleMobile={toggleMobile}>
        <Group align="center" justify={isSmMarches ? "space-between" : "center"} p="md">
          <AppNavbarLogo />
          <Burger opened={!mobileOpened} onClick={toggleMobile} hiddenFrom="sm" size="sm" color="white.1" />
        </Group>
      </AppNavbar>
      <AppShell.Main pos="relative">
        <Space h="md" />
        <Group gap={0} px="md" py="sm" mx="md" justify="space-between" bg="blue" className={appContainerStyle.header}>
          <Group gap="xs">
            <Burger opened={!mobileOpened} onClick={toggleMobile} hiddenFrom="sm" size="sm" color="white.1" />
            <Group>
              {(isSelect && colaborador?.role === "ADMIN") || (isSelect && data?.length === 0) ? (
                <Select
                  placeholder="Selecione um projeto"
                  data={projetos && projetos.length > 0 ? projetos : array}
                  onChange={onChange}
                  value={projeto}
                />
              ) : (
                <></>
              )}
              <Text fw={600} size="lg" c="white.1">
                {title}
              </Text>
            </Group>
          </Group>
          <Group gap="sm">
            {empresa &&
            empresa.status !== "INATIVO" &&
            data?.length === 0 &&
            colaborador?.role === "ADMIN" &&
            verification(colaborador) ? (
              <SpotlightButton />
            ) : (
              <></>
            )}
            <MenuUser />
          </Group>
        </Group>
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
        <Box m="md" mb="lg" pb="lg" ref={ref}>
          {children}
        </Box>
        <Group
          justify="space-between"
          bottom={0}
          py={4}
          px="lg"
          pos="absolute"
          bg="gray.6"
          w={`${width + MARGIN_SIZE}px`}
        >
          <Text fz="xs" fw={500} c="white.0">
            Instituto Synapse
          </Text>
          <Text fz="xs" fw={500} c="white.0">
            Versão {import.meta.env.VITE_APP_VERSION} ({import.meta.env.MODE})
          </Text>
        </Group>
      </AppShell.Main>
    </AppShell>
  );
};

export default AppContainer;

// prettier-ignore
const onChange = (value: string) => {
  setActiveProjeto(value)
}
// prettier-ignore
const verification = (colaborador: TColaborador | null | undefined) => {
  return ((colaborador?.role === "ADMIN" || colaborador?.gestao || colaborador?.gestao === undefined) && (colaborador?.role === "ADMIN" || colaborador?.diagnostico || colaborador?.diagnostico === undefined) && (colaborador?.role === "ADMIN" || colaborador?.incentivo || colaborador?.incentivo === undefined) && (colaborador?.role === "ADMIN" || colaborador?.configuracoes || colaborador?.configuracoes === undefined))
}
