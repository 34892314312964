import { Button, Group } from "@mantine/core";
import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { useCallback } from "react";
import useGetAllDesafios from "./useGetAllDesafios";
import useGetAllIdeias from "./useGetAllIdeias";
/* eslint-disable @typescript-eslint/no-floating-promises */
// prettier-ignore
const ExportIdeias = () => {
  const { data: ideias } = useGetAllIdeias();
  const { data: desafios } = useGetAllDesafios();

  const formatIdeias = ideias?.map((item, index) => {
    return [
      index,
      item.titulo,
      item.descricao,
      item.descricaoProblema,
      item.diferenciais,
      item.feedback,
      textMapIdeias[item.status]
    ];
  });

  const formatDesafios = desafios?.map((item, index) => {
    return [
      index,
      item.titulo,
      item.descricao,
      item.objetivo,
      textMapDesafios[item.status]
    ];
  })
  const handleExport = useCallback(() => {
    onExport(formatIdeias, formatDesafios);
  }, [formatIdeias, formatDesafios]);

  return (
    <Group justify={"flex-end"}>
      <Button onClick={handleExport}>Exportar</Button>
    </Group>
  );
};

export default ExportIdeias;

/* eslint-disable sonarjs/cognitive-complexity */
// prettier-ignore
const onExport = async (csvIdeias: (string | number | undefined)[][] | undefined, csvDesafios: (string | number | undefined)[][] | undefined) => {
  if (!csvIdeias) return;

  const columns = ["Ordem", "Título", "Descrição", "Descrição do problema", "diferencias", "Feedback", "Status",];
  const columnsDesafios = ["Ordem", "Título", "Descrição", "Objetivo", "Status",];

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Ideias');
  const worksheetDesafios = workbook.addWorksheet('Desafios');

  const headerRow = worksheet.addRow(columns);
  const headerRowDesafios = worksheetDesafios.addRow(columnsDesafios);

  headerRow.eachCell((cell) => {
    cell.font = { bold: true };
  });

  headerRowDesafios.eachCell((cell) => {
    cell.font = { bold: true };
  });

  if (Array.isArray(csvIdeias)) {
    for (const rowData of csvIdeias) {
      worksheet.addRow(Object.values(rowData));
    }
  }

  if (Array.isArray(csvDesafios)) {
    for (const rowData of csvDesafios) {
      worksheetDesafios.addRow(Object.values(rowData));
    }
  }

  try {
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      `relatorio.xlsx`
    );
  } catch (error) {
    console.error("Erro ao escrever buffer:", error);
  }
}

export const textMapIdeias: Record<TIdeia["status"], string> = {
  NOVA_IDEIA: "Nova Ideia",
  AGUARDANDO_ANALISE_DO_COMITE: "Aguardando análise do comitê",
  PRE_ANALISE: "Pré-análise",
  EM_ANALISE_DE_VIABILIDADE: "Em análise de viabilidade",
  RASCUNHO: "Rascunho",
  APROVADA: "Aprovada",
  REPROVADA: "Reprovada",
  IMPLEMENTADA: "Implementada",
  CANCELADA: "Cancelada",
  EM_IMPLEMENTACAO: "Em implementação",
  BACKLOG_DE_AVALIACAO_FUTURA: "Backlog de avaliação futura"
};

export const textMapDesafios: Record<TDesafioStatus, string> = {
  DRAFT: "Rascunho",
  NOVO_DESAFIO: "Novo Desafio",
  PROPOSTO_PELO_COLABORADOR: "Proposto pelo colaborador",
  PRE_ANALISE: "Pré-análise",
  ANALISE_DE_VIABILIDADE: "Análise de Viabilidade",
  AGUARDANDO_ANALISE_DO_COMITE: "Aguardando análise do comitê",
  APROVADO_PELO_COMITE: "Aprovado pelo comitê",
  AGENDADO: "Agendado",
  RECUSADO_PELO_COMITE: "Recusado pelo comitê",
  BACKLOG_PARA_AVALIACAO_FUTURA: "Backlog para avaliação futura",
  EM_REALIZACAO: "Em realização",
  REALIZADO: "Realizado",
  SOLUCOES_VALIDADAS: "Soluções validadas",
  CANCELADA: "Cancelada"
};
