import { Text } from "@mantine/core";
import { useParams } from "react-router-dom";
import { DEZ, DOIS } from "@/utils/constants";
import useMediaGrupoByEmpresaId from "./useGetMediaGrupoByEmpresaId";

// prettier-ignore
const NotaMedia = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useMediaGrupoByEmpresaId({ id: id || "" });
  let media = 0;
  if (data) for (const item of data) {
    media += (item ? item.media : 0) / DEZ || 0; // Updated to use 'item' instead of 'data[0]'
  }
  media = media / (data?.length || 1);
  return (
    <>
      <Text>{media.toFixed(DOIS)}</Text>
    </>
  );
};

export default NotaMedia;
