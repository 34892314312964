import {
  DocumentData,
  FirestoreDataConverter,
  Query,
  QueryNonFilterConstraint,
  WhereFilterOp,
  and,
  doc,
  getDoc,
  limit,
  or,
  orderBy,
  query,
  startAfter,
  where
} from "firebase/firestore";
import firestore from "../base/firestore";

/**
 * Get document reference from path
 * @param path - Path to get the document reference
 * @param converter - Converter to convert the document
 * @returns - document reference
 */
export function getDocumentReferenceFromPath<T>(path: string, converter: FirestoreDataConverter<T>) {
  return doc(firestore, path).withConverter(converter);
}

/**
 * Get document snapshot from path and converter
 * @param path - Path to get the document reference
 * @param converter - Converter to convert the document
 * @returns - document snapshot
 */
export function getDocumentSnapshotFromPath<T>(path: string, converter: FirestoreDataConverter<T>) {
  const documentReference = doc(firestore, path).withConverter(converter);
  return getDoc(documentReference);
}

/**
 * Generate firestore sorting queryConstraints
 * @param sorting - Sorting options
 * @returns - Sorting queryConstraints
 */
function generateSortingQuery<T>(sorting: TPaginatedQueryOptions<T>["sorting"] = []) {
  return sorting.map(({ id, desc }) => orderBy(id, desc ? "desc" : "asc"));
}

/**
 * Generate firestore filter queryConstraints
 * @param filters - Filters options
 * @returns - Filters queryConstraints
 */
function generateFilterConstraints<T>(filters: TPaginatedQueryOptions<T>["filters"] = []) {
  return filters.map(({ id, value }) => {
    const queryValue: string[] = Array.isArray(value) ? (value as Array<string>) : [value as string];
    const [operation, fieldValue] = queryValue as [WhereFilterOp, string];
    return where(id, operation, fieldValue);
  });
}

/**
 * Generate a paginated query to get documents from empresaId
 * @param collectionReference - Collection reference to get the documents
 * @param options - Options to get the documents
 * @param options.sorting - Sorting options
 * @param options.filters - Filters options
 * @param options.pagination - Pagination options
 * @param options.paginationCursors - Array of pagination cursors
 * @param options.globalFilters - Global filters options
 * @returns - Query to get documents
 */
// eslint-disable-next-line complexity
export function generateQuery<T extends DocumentData>(
  collectionReference: Query<T, DocumentData>,
  { sorting = [], filters = [], pagination, globalFilters, paginationCursors }: TPaginatedQueryOptions<T>
) {
  const queryConstraints: Array<QueryNonFilterConstraint> = [];

  queryConstraints.push(...generateSortingQuery(sorting));

  const pageIndex = pagination?.pageIndex ?? 0;
  const [, lastDocument] = paginationCursors?.get(pageIndex - 1) ?? [undefined, undefined];
  if (lastDocument && sorting.length > 0) {
    queryConstraints.push(startAfter(lastDocument));
  }

  if (pagination) {
    queryConstraints.push(limit(pagination.pageSize));
  }

  const hasFilterAndGlobalFilters = filters && filters.length > 0 && !!globalFilters;
  if (hasFilterAndGlobalFilters) {
    return query(
      collectionReference,
      and(globalFilters, or(...generateFilterConstraints(filters))),
      ...queryConstraints
    );
  }

  if (globalFilters) {
    return query(collectionReference, globalFilters, ...queryConstraints);
  }

  return filters && filters.length > 0
    ? query(collectionReference, ...generateFilterConstraints(filters), ...queryConstraints)
    : query(collectionReference, ...queryConstraints);
}

/**
 * Generate a paginated query to get documents from empresaId
 * @param collectionReference - Collection reference to get the documents
 * @param options - Options to get the documents
 * @param options.sorting - Sorting options
 * @param options.filters - Filters options
 * @param options.pagination - Pagination options
 * @param options.paginationCursors - Array of pagination cursors
 * @param options.globalFilters - Global filters options
 * @returns - Query to get documents
 */
// eslint-disable-next-line complexity
export function generateNewQuery<T extends DocumentData>(
  collectionReference: Query<T, DocumentData>,
  { sorting = [], filters = [], pagination, globalFilters, paginationCursors }: TPaginatedQueryOptions<T>
) {
  const queryConstraints: Array<QueryNonFilterConstraint> = [];

  queryConstraints.push(...generateSortingQuery(sorting));

  const pageIndex = pagination?.pageIndex ?? 0;
  const [, lastDocument] = paginationCursors?.get(pageIndex - 1) ?? [undefined, undefined];
  if (lastDocument && sorting.length > 0) {
    queryConstraints.push(startAfter(lastDocument));
  }

  if (pagination) {
    queryConstraints.push(limit(pagination.pageSize));
  }

  const hasFilterAndGlobalFilters = filters && filters.length > 0 && !!globalFilters;
  if (hasFilterAndGlobalFilters) {
    return query(
      collectionReference,
      and(globalFilters, and(...generateFilterConstraints(filters))),
      ...queryConstraints
    );
  }

  if (globalFilters) {
    return query(collectionReference, globalFilters, ...queryConstraints);
  }

  return filters && filters.length > 0
    ? query(collectionReference, ...generateFilterConstraints(filters), ...queryConstraints)
    : query(collectionReference, ...queryConstraints);
}
