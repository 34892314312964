import HomeDeInovacaoHeader from "@/modules/home-de-inovacao/components/home-de-inovacao-header";

// prettier-ignore
const HomeIncentivoInovacaoPage = () => {
  return (
    <>
      <HomeDeInovacaoHeader />
    </>
  );
};

export default HomeIncentivoInovacaoPage;
