import { Space } from "@mantine/core";
import DiagnosticoList from "@/modules/diagnosticos/components/diagnostico-list";

const DiagnosticosPage = () => {
  return (
    <>
      {/* <CardColaborador /> */}
      {/* <Group>
        <CardDiagnostico titulo={"Empresas participando do diagnostico da inovação"} isColaborador={false} />
        <CardDiagnostico titulo={"Total de colaboradores participando"} isColaborador={true} />
      </Group> */}
      <Space h="md" />
      {/* <QuestionarioList /> */}
      <DiagnosticoList />
    </>
  );
};

export default DiagnosticosPage;
