import { useState } from "react";
import { getRegioesByEmpresaAndProjeto } from "../firestore";

/**
 * Hook para buscar as regiões de um projeto
 * @returns regioes: Regiões do projeto, fetch: Função para buscar as regiões
 */
export function useGetRegioesByProjeto() {
  const [regioes, setRegioes] = useState<TRegiao[]>([]);

  const fetch = async (empresaId: TEmpresa["id"], projetoId: TProjeto["id"]) => {
    const regioes = await getRegioesByEmpresaAndProjeto(empresaId, projetoId);
    setRegioes(regioes.docs.map((document) => document.data()));
  };

  return { regioes, fetch };
}
