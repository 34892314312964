import { subject } from "@casl/ability";
import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getMarcosWithIdeia } from "@/modules/ideia-marcos/firestore";
import useUserStore from "@/modules/users/store";

const QUERY_KEY: string = "marcos" as const;

type TGetHistoricoMovimentacoesResponse = Awaited<ReturnType<typeof getMarcosWithIdeia>>;

/**
 * Get histórico de movimentações de uma ideia
 * @param ideia - The ideia to get the histórico de movimentações
 * @returns - Query results for get histórico de movimentações
 */
function useGetHistoricoMarcos(ideia: TIdeia) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  return useQuery<TGetHistoricoMovimentacoesResponse, FirestoreError, TMarco[]>({
    queryKey: [activeEmpresaId, "ideia", ideia.id, QUERY_KEY],
    async queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return getMarcosWithIdeia(activeEmpresaId, ideia.id);
    },
    select(dataSnapshot) {
      if (dataSnapshot.empty) {
        return [];
      }
      return dataSnapshot.docs.map((document) => subject("TMarco", document.data()));
    }
  });
}

export default useGetHistoricoMarcos;
