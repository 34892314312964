import { FocusTrap } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import DesafioComentarioForm from "../../components/desafio-comentario-form";

const ResponderDesafioComentarioModal = ({
  context,
  innerProps,
  id
}: ContextModalProps<{ desafio: TDesafio; comentario: TDesafioComentario }>) => {
  const { desafio, comentario } = innerProps;
  /**
   * Handle add ignore area success
   */
  function onSuccess() {
    context.closeModal(id);
  }

  return (
    <FocusTrap active={true}>
      <DesafioComentarioForm desafio={desafio} respostaAoComentario={comentario} onSuccess={onSuccess} />
    </FocusTrap>
  );
};

export default ResponderDesafioComentarioModal;
