import { Text, Card, Group, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconPlus } from "@tabler/icons-react";
import { useRef } from "react";
import AddItemButton from "@/components/add-item-button";
import { TModalProperties } from "@/components/add-item-button/AddItemButton";
import { THandler } from "@/components/table/Table";
import useNonDeletedGlobalFilters from "@/hooks/useNonDeletedGlobalFilters";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import IdeiaStatusBadge, { colorsMap } from "@/modules/ideias/components/ideia-status-badge/IdeiaStatusBadge";
import IdeiasTable from "@/modules/ideias/components/ideias-table/IdeiasTopTable";
import ideiaSchema from "@/modules/ideias/schema/ideia-schema";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import ideiaColumns, { ideiaColumnsMobile } from "./IdeiaColumns";
import { dataAutorizacoes } from "../empresa-page/EmpresaPage";
const ULTIMAS_IDEIAS_ATUALIZADAS_LIMIT = 10;

const modalProperties: TModalProperties = {
  modal: "ideia-form-modal"
};

// prettier-ignore
const IdeiaStatusLegend = () => {
  const allStatus = Object.keys(colorsMap) as TIdeia["status"][];

  return (
    <>
      <Text fw={500}>Legenda</Text>
      <Group mt="md">
        {allStatus.map((status) => (
          <IdeiaStatusBadge key={status} status={status} />
        ))}
      </Group>
    </>
  );
};
// prettier-ignore
const UltimasIdeiasAtualizadasCard = () => {
  const tableHandlersIdeiasReference = useRef<THandler<TIdeia>>(null);
  useNonDeletedGlobalFilters(tableHandlersIdeiasReference);
  const isSmMarches = useMediaQuery("(max-width: 48em)");
  const activeEmpresaId = useUserStore((store) => store.activeEmpresa);
  const activeAutorizacao = useUserStore((store) => store.autorizacoes);
  const uid = getCurrentUser()?.uid;
  const { data: userColaborador } = useGetColaboradorById(activeEmpresaId?.id || "", uid || "");
  const autorizacao = dataAutorizacoes.find(item => item.label === userColaborador?.role);
  const formatAutorizacoes = activeAutorizacao?.filter(item => item.tipo === autorizacao?.value);

  return (
    <Card withBorder>
      <Card.Section inheritPadding py="md">
        <Group justify={"space-between"} mb="md">
          <Title order={3} fw={500}>
            As {ULTIMAS_IDEIAS_ATUALIZADAS_LIMIT} últimas ideias mais curtidas na empresa
          </Title>
          {formatAutorizacoes?.find(item => item.tipoAutorizacao === "3")?.criacao ? <AddItemButton
            size="sm"
            leftSection={<IconPlus size="1rem" />}
            modalProperties={modalProperties}
            schema={ideiaSchema}
          >
            Adicionar Ideia
          </AddItemButton> : <></>}
        </Group>
        <Card withBorder p={0}>
          <IdeiasTable ref={tableHandlersIdeiasReference} columns={isSmMarches ? ideiaColumnsMobile : ideiaColumns} />
        </Card>
      </Card.Section>
      {isSmMarches ? <></> : <Card.Section inheritPadding pb="md">
        <Card withBorder>
          <IdeiaStatusLegend />
        </Card>
      </Card.Section>}
    </Card>
  );
};

export default UltimasIdeiasAtualizadasCard;
