import { Anchor, Box, Card, CardProps, Divider, Group, Stack, Text } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import { NavLink } from "react-router-dom";
import RankingTrendIconLikes from "@/components/ranking-trend-icon/RankingTrendIconLikes";
import ColaboradoresInlineList from "@/modules/colaboradores/components/colaboradores-inline-list";
import IdeiaStatusBadge from "@/modules/ideias/components/ideia-status-badge";
import TotalMoedasBadge from "@/modules/ideias/components/total-moedas-badge";
// prettier-ignore
const TopIdeiaCard = ({ ideia, index, ...cardProperties }: CardProps & { ideia: TIdeia; index: number }) => {
  const hasParticipantes = ideia.participantesReferences && ideia.participantesReferences.length > 0;

  return (
    <Card key={ideia.id} withBorder {...cardProperties}>
      <Card.Section inheritPadding mb='sm' py="md" h={hasParticipantes ? "112px" : "150px"}>
        <Group mb="sm">
          {ideia.rankingByLikes && (
            <Group gap={2}>
              <Text fw={500}>#{index + 1}</Text>{" "}
              <RankingTrendIconLikes ideia={ideia} size={18} />
            </Group>
          )}

          <IdeiaStatusBadge variant="filled" status={ideia.status} />
        </Group>
        <Stack gap="xs">
          <Text fz="sm" style={{ overflow: 'hidden', textOverflow: 'ellipsis', textWrap: 'nowrap' }}>
            {ideia.titulo}
          </Text>
          <Box style={{ height: hasParticipantes ? '55px' : '94px', overflow: 'hidden' }}>
            <Text fw={500}>{ideia.descricao}</Text>
          </Box>
        </Stack>
      </Card.Section>
      <Card.Section inheritPadding py="md">
        <ColaboradoresInlineList colaboradores={ideia.participantesReferences} fw={undefined} c="dimmed" />
        <Divider my="md" />
        <Group justify="space-between">
          <TotalMoedasBadge ideia={ideia} variant="transparent" px={0} />
          <Anchor component={NavLink} to={`/ideias/${ideia.id}`}>
            <Group gap={0}>
              <Text>Patrocinar</Text> <IconChevronRight size="1.2rem" />
            </Group>
          </Anchor>
        </Group>
      </Card.Section>
    </Card>
  );
};

export default TopIdeiaCard;
