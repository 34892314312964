import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getRepostasQuestionariosArray } from "@/modules/responder-questionario/firestore";

type TGetComitesInovacaoResponse = Awaited<ReturnType<typeof getRepostasQuestionariosArray>>;
const QUERY_KEY: string = "convite-list" as const;
/**
 * Get comites de inovação query hook
 * @param activeEmpresa - id da empresa
 * @param idQuestionario - id questionario
 * @returns Comites de inovação query hook
 */
// prettier-ignore
function useGetQuestionariosRespondidos(activeEmpresa: TEmpresa["id"] | undefined, idQuestionario: Array<string> | undefined) {
  return useQuery<TGetComitesInovacaoResponse, FirestoreError, TResposta[]>({
    queryKey: [QUERY_KEY, idQuestionario, activeEmpresa],
    enabled: !!activeEmpresa && !!idQuestionario,
    queryFn() {
      if (!activeEmpresa) {
        throw new Error("Empresa não autenticada");
      }

      if (!idQuestionario) {
        throw new Error("Projeto não autenticada");
      }
      return getRepostasQuestionariosArray(activeEmpresa, idQuestionario);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((document_) => document_.data());
    }
  });
}

export default useGetQuestionariosRespondidos;
