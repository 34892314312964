import { Button, Card, Group, Space, Text } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { Timestamp } from "firebase/firestore";
import { getCurrentUser } from "@/services/auth";
import { useUpdateIncricao } from "./useGetInscricao";
import CardSolucao from "../desafio-cronograma-form-item/CardSolucao";

const FORM_TYPE_INSCRICAO = 2;

interface Inscricao {
  id: string;
  data: Date;
  status: number | undefined;
  destaque: boolean | undefined;
}
interface MyState {
  id?: number;
  data: Date | null;
  descricao: string;
  orientacaoLocal: string;
}

// prettier-ignore
const ListFormCronograma = ({ data, onSuccess, refetch }: { data: TCronogramaDesafio, onSuccess: () => void, refetch: () => void; }) => {
  const { mutate } = useUpdateIncricao();
  const inscricoes = data && data.inscricoes && data?.inscricoes?.length > 0 ? data?.inscricoes : []
  const uid = getCurrentUser()?.uid;
  const inscrito = inscricoes && inscricoes.filter((item: Inscricao) => item.id === uid);
  const resultado = data?.quantidadeDeVagas === inscricoes.length;

  const changeMutate = (type: number) => {
    return () => {
      const novaInscricao = inscricoes?.length > 0 ? [...inscricoes, { id: uid, data: new Date() }] : [{ id: uid, data: new Date() }];
      const removerInscricao = inscricoes?.length > 0 ? inscricoes.filter((item: Inscricao) => item.id !== uid) : [];

      mutate({
        ...data,
        inscricoes: type === 1 ? removerInscricao : novaInscricao,
        updatedAt: Timestamp.now(),
        createdAt: Timestamp.now(),
        deletedAt: null
      });
      onSuccess();
      void refetch();
    }
  };

  return (
    <>
      <Card withBorder>
        <Group justify={"space-between"}>
          <Group>
            <Text>Data de inicio: </Text>
            <Text>{data?.inicioIncricao?.toLocaleDateString("pt-BR") ?? ""}</Text>
          </Group>
          <Group>
            <Text>Data de término:</Text>
            <Text>{data?.inicioIncricao?.toLocaleDateString("pt-BR") ?? ""}</Text>
          </Group>
        </Group>
        <Space h={"md"} />
        {data && data.event && Array.isArray(data.event) && data.event.length > 0 ?
          data.event.map((item: MyState) => <CardSolucao key={item.id} item={item} />) :
          <><Text c="dimmed">Nenhum evento encontrado!</Text></>
        }
        <Space h={"md"} />
        {resultado ? <Group justify={"flex-end"}>
          <Text fz={"sm"} color={"red"}>Este desafio está cheio</Text>
        </Group> : <Group justify={"flex-end"}>
          {inscrito.length > 0 ?
            <Group>
              <Button bg={"transparent"} onClick={changeMutate(1)} >
                <Text fz={"sm"} color={"black"}>Cancelar minha inscrição</Text>
              </Button>
              <Group>
                <IconCheck color={"rgba(105, 198, 105, 1)"} style={{ fontSize: 12 }} />
                <Text fz={"sm"} color={"rgba(105, 198, 105, 1)"}>Inscrição realizada</Text>
              </Group>
            </Group>
            : <Button bg={"rgba(8, 68, 244, 1)"} onClick={changeMutate(FORM_TYPE_INSCRICAO)}>
              Quero me inscrever
            </Button>}
        </Group>}
      </Card>
      <Space h={"md"} />
    </>
  );
};

export default ListFormCronograma;
