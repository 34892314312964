import { ActionIcon, Menu } from "@mantine/core";
import { IconDotsVertical, IconEdit, IconTrash } from "@tabler/icons-react";
import EditItemButton from "@/components/edit-item-button";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import { dataAutorizacoes } from "@/src/routes/empresa-page/EmpresaPage";
import tipoDeJogadaSchema from "../../schema/tipo-de-jogada-schema";
import RemoveTipoDeJogadaButton from "../remove-tipo-de-jogada-button";
// prettier-ignore
const ContextMenuRow = ({ tipoDeJogadas }: { tipoDeJogadas: TTipoDeJogada }) => {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);
  const activeAutorizacao = useUserStore((store) => store.autorizacoes);
  const uid = getCurrentUser()?.uid;
  const { data: userColaborador } = useGetColaboradorById(activeEmpresaId || "", uid || "");

  const autorizacao = dataAutorizacoes?.find(item => item.label === userColaborador?.role);
  const formatAutorizacoes = activeAutorizacao?.filter(item => item.tipo === autorizacao?.value);

  if (!formatAutorizacoes?.find(item => item.tipoAutorizacao === "12")?.editar && !formatAutorizacoes?.find(item => item.tipoAutorizacao === "12")?.remover) {
    return <></>
  }

  return <Menu shadow="md" width={200} position="left" withArrow arrowPosition="center">
    <Menu.Target>
      <ActionIcon variant="transparent" aria-label="Menu" color="gray">
        <IconDotsVertical />
      </ActionIcon>
    </Menu.Target>
    <Menu.Dropdown>
      {formatAutorizacoes?.find(item => item.tipoAutorizacao === "12")?.editar ? <Menu.Item
        leftSection={<IconEdit size={14} />}
        component={EditItemButton}
        modal="tipo-de-jogada-form-modal"
        schema={tipoDeJogadaSchema}
        item={tipoDeJogadas}
      >
        Editar
      </Menu.Item> : <></>}

      {formatAutorizacoes?.find(item => item.tipoAutorizacao === "12")?.remover ? <>
        <Menu.Divider />
        <Menu.Item
          color="red"
          leftSection={<IconTrash size={14} />}
          component={RemoveTipoDeJogadaButton}
          tipoDeJogada={tipoDeJogadas}
        >
          Remover
        </Menu.Item>
      </> : <></>}
    </Menu.Dropdown>
  </Menu>
};

export default ContextMenuRow;
