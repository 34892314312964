import { Table as MantineTable } from "@mantine/core";
import { ColumnFiltersState, Table as ReactTable, RowData, flexRender } from "@tanstack/react-table";
import LoadingRows from "./LoadingRows";
import TableHeader from "./TableHeader";

export type THandler<T extends RowData> = {
  table: ReactTable<T>;
  data: TPaginatedData<T> | undefined;
  isLoading: boolean;
  error: Error | null;
  filters: ColumnFiltersState;
};

const Table = <T extends RowData>({ table, isLoading }: { table: ReactTable<T>; isLoading: boolean }) => {
  return (
    <MantineTable>
      <MantineTable.Thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <MantineTable.Tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TableHeader key={header.id} header={header} />
            ))}
          </MantineTable.Tr>
        ))}
      </MantineTable.Thead>
      <MantineTable.Tbody>
        {table.getRowModel().rows.map((row) => (
          <MantineTable.Tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <MantineTable.Td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </MantineTable.Td>
            ))}
          </MantineTable.Tr>
        ))}
        {isLoading && <LoadingRows table={table} />}
      </MantineTable.Tbody>
    </MantineTable>
  );
};
Table.displayName = "Table";

export default Table;
