import { Container, Image } from "@mantine/core";
import { useEffect, useState } from "react";
// import useGetProjetoItem from "./useGetProjeto";

const MAGIC_NUMBER = 20;

// prettier-ignore
const BannerComponent = ({ projeto, tipo }: { projeto: TProjeto | null | undefined, tipo: string }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    if (projeto && projeto.posicao === tipo && projeto.images && projeto.images.length > 0 && projeto.trocar) {
      const intervalId = setInterval(() => {
        setCurrentImageIndex(previousIndex => (previousIndex + 1) % projeto.images.length);
      }, projeto.tempo); // Troca de imagem a cada 5000ms (5 segundos)

      return () => clearInterval(intervalId);
    }
    return undefined;
  }, [projeto, tipo]);

  return projeto && projeto.posicao === tipo ? <Container onClick={onOpen(projeto, currentImageIndex)} fluid h={"15vh"} w={"100vw"} p={0} style={{ marginTop: tipo === "1" ? 0 : MAGIC_NUMBER }}>
    <Image
      h={"15vh"}
      src={projeto && projeto.images[currentImageIndex] && projeto?.images[currentImageIndex]?.path}
    />
  </Container> : <></>
};

export default BannerComponent;

const onOpen = (projeto: TProjeto | null | undefined, currentImageIndex: number) => {
  const imagem = projeto?.images[currentImageIndex];
  return () => {
    window.open(projeto?.urlImages?.find((item) => item.path === imagem?.name)?.url);
  };
};
