import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getProjetosAll } from "@/modules/projetos/firestore";
import useUserStore from "@/modules/users/store";

type TGetComitesInovacaoResponse = Awaited<ReturnType<typeof getProjetosAll>>;

/**
 * Get comites de inovação query hook
 * @returns Comites de inovação query hook
 */
function useQueryProjetos() {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  return useQuery<TGetComitesInovacaoResponse, FirestoreError, TProjeto[]>({
    queryKey: ["empresas", { id: activeEmpresaId }, "projetos-list"],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return getProjetosAll(activeEmpresaId);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }
      return dataResponse.docs.map((document_) => document_.data());
    }
  });
}

export default useQueryProjetos;
