/* eslint-disable unicorn/prefer-spread */
import { serverTimestamp } from "firebase/firestore";
import { ObjectSchema } from "yup";
import schema from "@/schema";
import { getCurrentUser } from "@/services/auth";
import { TRegiaoDatabaseFields, TRegiaoFormFields, TRegiaoForm } from "../firestore";

const MIN_NAME_LENGTH = 3;

export const regiaoIdentifierSchema = schema.object({
  field: schema.mixed<TRegiaoIdentifier["field"]>().required().label("Campo"),
  value: schema.string().required().label("Valor")
});

const regiaoDatabaseFields: ObjectSchema<TRegiaoDatabaseFields> = schema.object().shape({
  createdAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .required()
    .defined()
    .nonNullable()
    .default<ReturnType<typeof serverTimestamp>>(() => serverTimestamp()),
  createdBy: schema
    .string()
    .required()
    .defined()
    .nonNullable()
    .default(() => {
      const currentUserId = getCurrentUser()?.uid;
      if (!currentUserId) {
        throw new Error("The current user is not defined");
      }
      return currentUserId;
    }),
  updatedAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .default<ReturnType<typeof serverTimestamp>>(() => serverTimestamp()),
  updatedBy: schema.string().default(() => {
    const currentUserId = getCurrentUser()?.uid;
    if (!currentUserId) {
      throw new Error("The current user is not defined");
    }
    return currentUserId;
  }),
  deletedAt: schema.mixed<ReturnType<typeof serverTimestamp>>().defined().nullable().default(null)
});

const regiaoFormFields: ObjectSchema<TRegiaoFormFields> = schema.object({
  id: schema.string().optional(),
  refPath: schema.string().optional(),
  name: schema.string().required().min(MIN_NAME_LENGTH).label("Nome"),
  comparisonType: schema.string().oneOf(["and", "or"]).required().label("Tipo de comparação"),
  identifiers: schema.array().of(regiaoIdentifierSchema).required().label("Identificadores"),
  projetoId: schema.string().required().label("Projeto"),
  empresas: schema.array().of(schema.mixed<TEmpresa["id"]>().required()).optional().default([]).label("Empresas")
});

const regiaoSchema: ObjectSchema<TRegiaoForm> = regiaoFormFields
  .concat(regiaoDatabaseFields)
  .noUnknown()
  .strict()
  .label("Região");

export default regiaoSchema;
