import { subject } from "@casl/ability";
import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { getGrupoResposta } from "@/modules/diagnosticos/firestore";
import useUserStore from "@/modules/users/store";

type TGetRespostasResponse = Awaited<ReturnType<typeof getGrupoResposta>>;

const QUERY_KEY = "respostas";

/**
 * Get respostas query hook
 * @param root0 - root
 * @param root0.id - id
 * @returns Respostas query hook
 */
function useQueryRespostas({ id }: { id: TQuestionario["id"] }) {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);
  const { idEmpresa } = useParams<{ idEmpresa: string }>();
  return useQuery<TGetRespostasResponse, FirestoreError, TResposta[]>({
    queryKey: ["empresas", { id: activeEmpresaId }, QUERY_KEY, id, idEmpresa],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      if (!id) {
        throw new Error("Empresa não selecionada");
      }

      return getGrupoResposta(activeEmpresaId, id, idEmpresa || "");
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }
      return dataResponse.docs.map((document_) => subject("TResposta", document_.data()));
    }
  });
}

export default useQueryRespostas;
