import { Flex, Group, Loader, Space, Table, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { getRespostasMediaEmpresa } from "@/modules/analisar-respostas/firestore";
import useGetProjetoItem from "@/modules/diagnostico/components/questionario-item/useGetProjeto";
import { RankingListPaginator } from "@/modules/diagnostico/components/ranking-list/components/RankingListPaginator";
import { usePaginator } from "@/modules/diagnostico/components/ranking-list/hooks/usePaginator";
import { getMediaGrupoByEmpresaId } from "@/modules/media-grupo/firestore";
import useUserStore from "@/modules/users/store";
import { DEZ, DOIS } from "@/utils/constants";
import ExportExcel from "./ExportExcel";
import ModalFilter from "./ModalFilter";
import GetEmpresas from "../graphics-nivo/useGetEmpresa";
import TableMediaItem from "../table-media-item";

interface RespostaItem {
  valor: number;
}
interface StatusResposta {
  idQuestionario: string;
  idEmpresa: string;
  pausado: boolean;
  idRespondente: string;
}
interface Pergunta {
  resposta: RespostaItem | RespostaItem[] | number;
}

interface TResposta {
  perguntas: Pergunta[];
}
interface Filtro {
  search: string;
  filtro: string;
  habilitada: boolean;
}

interface Media {
  media: number;
  habilitada: boolean;
}

const message = "Ocorreu um erro ao carregar os dados:";

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
// prettier-ignore
const TableMedias = ({ empresas, questionario }: { empresas: TConvite[], questionario: string }) => {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const [filter, setFilter] = useState<Filtro>(mockFilter)
  const [newEmpresa, setNewEmpresa] = useState<TEmpresa[]>([]);
  const projeto = useUserStore((state) => state.activeProjeto);
  const { data } = useGetProjetoItem({ idProjeto: projeto || "" });
  const [loading, setLoading] = useState(false);
  const dados = filteredData(newEmpresa as (TEmpresa & Media)[], filter);
  const { currentPageContent, currentPage, pageSize, setCurrentPage, setPageSize } = usePaginator(dados);

  const uniqueArray = empresas.filter((item, index, self) =>
    index === self.findIndex((t) => (
      t.idEmpresa === item.idEmpresa
    ))
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadData();
      } catch (error) {
        console.error(message, error);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
    // eslint-disable-next-line unicorn/explicit-length-check
  }, [data?.minimoResposta])
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const loadData = async () => {
    if (empresas && empresas?.length > 0) {
      setLoading(true)
      const newEmpresa = await GetEmpresas([...uniqueArray.map(item => ({ ...item, idEmpresaColaboradorConvidado: item.idEmpresa })), ...empresas]);
      let mediasEmpresas: { empresaId: string; mediaGrupo: number | null; }[] = [];
      let newArray = [] as [];
      for (const item of newEmpresa) {
        const media = await getMediaGrupoByEmpresaId(activeEmpresaId || "", questionario, item.id);
        const newData = await getRespostasMediaEmpresa(item.id);
        const format = newData.docs.map((resposta) => resposta.data()) || [] as TResposta[] & StatusResposta[];
        newArray = [...newArray, ...format as TResposta[] & StatusResposta[] as []]
        // eslint-disable-next-line unicorn/no-array-for-each
        media.forEach((item) => {
          mediasEmpresas = [...mediasEmpresas, { empresaId: item.data().empresaId, mediaGrupo: item.data().media || 0 }];
        });
      }

      setNewEmpresa(newEmpresa.map(item => {
        const mediasEmpresa = mediasEmpresas.filter(mediaEmpresa => mediaEmpresa.empresaId === item.id) || [];
        const somaMedias = mediasEmpresa.reduce((accumulator, current) => accumulator + (current.mediaGrupo || 0), 0);
        const media = (somaMedias / (mediasEmpresa.length || 1)) / DEZ;
        const respostas = (newArray as (TResposta & StatusResposta)[]).filter(row => row.idEmpresa === item.id && row.pausado === false && row.idQuestionario === questionario) || [];
        const minimoResposta = data?.minimoResposta || 0;

        return {
          ...item,
          media: media.toFixed(DOIS),
          habilitada: respostas.length >= minimoResposta
        }
      }))
      setLoading(false)
    }
  }

  const rows = currentPageContent.map((item) => (
    <TableMediaItem key={item.id} item={item} />
  ));
  return (
    <>
      <Group justify={"space-between"} mb={10}>
        <Text size={"md"} fw={700}>Nota média das Empresas</Text>
        <Group>
          <ModalFilter setFilter={setFilter} />
          <ExportExcel empresas={filteredData(newEmpresa as (TEmpresa & Media)[], filter)} />
        </Group>
      </Group>
      <div style={{ overflowX: "auto" }}>
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Empresa</Table.Th>
              <Table.Th>Cnpj</Table.Th>
              <Table.Th>Responsavel</Table.Th>
              <Table.Th>Telefone</Table.Th>
              <Table.Th>Porte</Table.Th>
              <Table.Th>Estado</Table.Th>
              <Table.Th>Categoria</Table.Th>
              <Table.Th>Média</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{loading ? <> </> : rows}</Table.Tbody>
        </Table>
        <Flex p="sm" justify="flex-end" gap="sm">
          <RankingListPaginator
            numberOfItems={dados.length}
            currentPage={currentPage}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
            onPageSizeChange={setPageSize}
          />
        </Flex>
      </div>
      <Space h={"md"} />
      {loading ? <Group justify={"center"} align={"center"}>
        <Loader color="blue" />
      </Group> : <></>}
    </>
  )
};

export default TableMedias;

// prettier-ignore
const filteredData = (data: (TEmpresa & Media)[], filter: Filtro) => {
  return data
    .filter(item => doFilter(item, filter))
    .sort((a, b) => b.media - a.media);
}

// prettier-ignore
const doFilter = (item: TEmpresa & Media, filter: Filtro) => {
  switch (filter.filtro) {
    case "Empresa": {
      return filterByEmpresa(item, filter);
    }
    case "Cnpj": {
      return filterByCnpj(item, filter);
    }
    case "Responsavel": {
      return filterByResponsavel(item, filter);
    }
    case "Telefone": {
      return filterByTelefone(item, filter);
    }
    case "Porte": {
      return filterByPorte(item, filter);
    }
    case "Estado": {
      return filterByEstado(item, filter);
    }
    case "Media": {
      return filterByMedia(item, filter);
    }
    case "Categoria": {
      return filterByCategoria(item, filter);
    }
    case "": {
      return filterByHabilitada(item, filter);
    }
    default: {
      return item;
    }
  }
}
// prettier-ignore
const filterByEmpresa = (item: TEmpresa & Media, filter: Filtro) => {
  return filter.habilitada ?
    item.nomeFantasia === filter.search && item.habilitada === filter.habilitada :
    item.nomeFantasia === filter.search;
};
// prettier-ignore
const filterByCnpj = (item: TEmpresa & Media, filter: Filtro) => {
  return filter.habilitada ?
    item.cnpj === filter.search && item.habilitada === filter.habilitada :
    item.cnpj === filter.search;
};
// prettier-ignore
const filterByResponsavel = (item: TEmpresa & Media, filter: Filtro) => {
  return filter.habilitada ?
    item.responsavel.nome === filter.search && item.habilitada === filter.habilitada :
    item.responsavel.nome === filter.search;
};
// prettier-ignore
const filterByTelefone = (item: TEmpresa & Media, filter: Filtro) => {
  return filter.habilitada ?
    item.telefone === filter.search && item.habilitada === filter.habilitada :
    item.telefone === filter.search;
};
// prettier-ignore
const filterByPorte = (item: TEmpresa & Media, filter: Filtro) => {
  return filter.habilitada ?
    item.porte === filter.search && item.habilitada === filter.habilitada :
    item.porte === filter.search;
};
// prettier-ignore
const filterByEstado = (item: TEmpresa & Media, filter: Filtro) => {
  return filter.habilitada ?
    item.endereco.estado === filter.search && item.habilitada === filter.habilitada :
    item.endereco.estado === filter.search;
};
// prettier-ignore
const filterByMedia = (item: TEmpresa & Media, filter: Filtro) => {
  return filter.habilitada ?
    item?.media?.toString() === filter.search && item.habilitada === filter.habilitada :
    item?.media?.toString() === filter.search;
};
// prettier-ignore
const filterByCategoria = (item: TEmpresa & Media, filter: Filtro) => {
  return filter.habilitada ?
    item.categorias === filter.search && item.habilitada === filter.habilitada :
    item.categorias === filter.search;
};
// prettier-ignore
const filterByHabilitada = (item: TEmpresa & Media, filter: Filtro) => {
  return filter.habilitada ? filter.habilitada === item.habilitada : item
}

// prettier-ignore
const mockFilter = {
  search: "",
  filtro: "",
  habilitada: false
};
