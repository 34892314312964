import { Card, Group, Space, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import CardPies from "@/modules/relatorio/components/card-bar";
import CardEmpresa from "@/modules/relatorio/components/card-empresa";
import getEmpresas from "@/modules/relatorio/components/card-empresa/get-empresa";
import AreaRelatorio from "@/modules/relatorio/components/graphics-nivo/AreaBarRelatorio";
import AreaRelatorioEstado from "@/modules/relatorio/components/graphics-nivo/AreaBarRelatorioEstado";
import RelatorioComponent from "@/modules/relatorio/components/relatorio-item";
import TableColaboradores from "@/modules/relatorio/components/table-colaboradores";
import TableEmpresas from "@/modules/relatorio/components/table-empresas";
import TableMedias from "@/modules/relatorio/components/table-medias";
import useUserStore from "@/modules/users/store";

const message = "Ocorreu um erro ao carregar os dados:";

const RelatorioPage = () => {
  const [values, setValues] = useState({ questionario: "" });
  const [empresas, setEmpresas] = useState<TConvite[]>([]);
  const projeto = useUserStore((state) => state.activeProjeto);

  useEffect(() => {
    setValues((values) => ({ ...values, questionario: "" }));
  }, [projeto]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadData();
      } catch (error) {
        console.error(message, error);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, [projeto, values?.questionario]);

  const loadData = async () => {
    if (projeto) {
      const arrayEmpresas = await getEmpresas(projeto);
      setEmpresas(arrayEmpresas);
    }
  };

  return (
    <>
      <RelatorioComponent setValues={setValues} questionario={values.questionario} />
      <Space h={"xl"} />
      {projeto !== "" && projeto !== null && values.questionario !== "" && values.questionario !== null ? (
        <Card>
          <CardEmpresa empresas={empresas} questionario={values?.questionario} />
          <Space h={"xl"} />
          <TableEmpresas empresas={empresas} questionario={values?.questionario} />
          <Space h={"xl"} />
          <AreaRelatorio empresas={empresas} />
          <Space h={"xl"} />
          <CardPies empresas={empresas} />
          <Space h={"xl"} />
          <TableMedias empresas={empresas} questionario={values?.questionario} />
          <Space h={"xl"} />
          <AreaRelatorioEstado empresas={empresas} />
          <Space h={"xl"} />
          <TableColaboradores empresas={empresas} questionario={values?.questionario} />
          <Space h={"xl"} />
        </Card>
      ) : (
        <Group justify={"center"}>
          <Text fw={700}>Selecione um projeto e um question�rio para buscar!!</Text>
        </Group>
      )}
      <Space h={"xl"} />
    </>
  );
};

export default RelatorioPage;
