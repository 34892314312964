import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { FunctionsError } from "firebase/functions";
import { Asserts } from "yup";
import inviteEmpresaSchema from "@/modules/empresas/components/invite-empresa-form/invite-empresa-schema";
import useUserStore from "../../users/store";
import { inviteEmpresa } from "../functions";

type InviteInputs = Asserts<typeof inviteEmpresaSchema>;
type SendInviteToUserResponse = Awaited<ReturnType<typeof inviteEmpresa>>;

type MutationOptions = Omit<
  UseMutationOptions<SendInviteToUserResponse, FunctionsError, InviteInputs>,
  "mutationFn" | "mutationKey"
>;

/**
 * Sends an invite to the given email
 * @param options - The mutation options
 * @throws If no empresa is selected
 * @returns The mutation object
 */
function useInviteEmpresaMutation(options: MutationOptions = {}) {
  const empresaId = useUserStore((state) => state.activeEmpresaId);
  const licenciadaId = useUserStore((state) => state.activeLicenciadaId);
  return useMutation<SendInviteToUserResponse, FunctionsError, InviteInputs>({
    mutationFn: (values) => {
      if (empresaId) {
        return inviteEmpresa({ ...values, licenciadaId, empresaId });
      }

      throw new Error("Nenhuma empresa selecionada");
    },
    ...options
  });
}
export default useInviteEmpresaMutation;
