import { Text, Card, Group, BackgroundImage, Button, Space, Anchor } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconCalendar, IconFlame } from "@tabler/icons-react";
import { MouseEvent, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import campanhaRegulamentoIdeiasSchema from "@/modules/campanha-regulamento-ideias/schema";
import useUserStore from "@/modules/users/store";
import GetCampanhas from "./useGetCampanha";
import useGetRegulamentos from "./useGetRegulamentos";

const MAX_TITLE_SIZE = 100;

const CampanhaDeInovacao = () => {
  const [reload, setReload] = useState(false);
  const [campanhas, setCampanhas] = useState<TCampanhaRegulamentoIdeias[]>([]);

  const { data } = useGetRegulamentos();
  const { activeEmpresaId, colaborador } = useUserStore();

  const color = "rgba(8, 68, 244, 1)";
  const uniqueData = campanhas && campanhas[0];

  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadData();
      } catch (error) {
        console.error("Ocorreu um erro ao carregar os dados:", error);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, [data && data.length > 0, activeEmpresaId, reload]);

  const loadData = async () => {
    const campanha = await GetCampanhas(data, activeEmpresaId);

    const dataAtual = new Date();
    const filteredArray = campanha.filter((item) => {
      const dataInicial = new Date(item.dataInicial);
      const dataFinal = new Date(item.dataFinal);

      return dataInicial <= dataAtual && dataFinal >= dataAtual;
    });

    setCampanhas(filteredArray);
  };

  /**
   * Função que trata o clique do botão.
   * @param event - O evento de clique do botão.
   */
  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    const schema = campanhaRegulamentoIdeiasSchema;
    const defaultValues = schema.getDefault() as unknown;
    event.preventDefault();
    modals.openContextModal({
      size: "xl",
      innerProps: {
        item: schema.cast(defaultValues, { stripUnknown: true, assert: false }),
        data: campanhas,
        setReload: () => setReload((reload) => !reload)
      },
      modal: "modal-campanha"
    });
  }

  const Background = ({ children }: { children: React.ReactNode }) => {
    return (
      <Card withBorder p={0} maw={200} h={335}>
        <BackgroundImage
          src="https://firebasestorage.googleapis.com/v0/b/acelera-inova.appspot.com/o/assets%2Fwaves.jpeg?alt=media&token=f03e8fdd-7cac-4a0e-b2f6-f39239f69053"
          radius="xs"
          style={{ backgroundColor: "rgba(97, 136, 250, 1)", padding: 10, backgroundBlendMode: "multiply" }}
          h={335}
        >
          {children}
        </BackgroundImage>
      </Card>
    );
  };

  const hasCampanha = !!campanhas?.length;
  const hasMultipleCampanhas = hasCampanha && campanhas.length > 1;
  const canCreateCampanha = colaborador?.role !== "COLLABORATOR";

  if (hasMultipleCampanhas) {
    return (
      <Background>
        <Text color={"white"} fw={700} size="md">
          Visualize as campanhas de ideias
        </Text>
        <Space h="md" />
        <Group justify={"center"} h={"100%"}>
          <Button bg={color} onClick={handleClick}>
            Visualizar
          </Button>
        </Group>
      </Background>
    );
  }

  if (hasCampanha) {
    return (
      <Background>
        <Text color={"white"} fw={700} size="md">
          Campanha de fomento de ideias
        </Text>
        <Space h="md" />
        <Group justify={"flex-start"} align={"center"} style={{ gap: "unset", marginRight: 5 }}>
          <IconFlame color={"white"} style={{ fontSize: "12" }} />
          <Text color={"white"} fw={500} size="xs">
            Objetivo da campanha
          </Text>
        </Group>
        <Text color={"white"} fw={600} size="sm">
          {uniqueData && uniqueData?.descricao.length > MAX_TITLE_SIZE
            ? `${uniqueData?.descricao.slice(0, MAX_TITLE_SIZE)}...`
            : uniqueData?.descricao}
        </Text>
        <Space h="md" />
        <Group justify={"flex-start"} align={"center"} style={{ gap: "unset" }}>
          <IconCalendar color={"white"} style={{ fontSize: "12", marginRight: 5 }} />
          <Text color={"white"} fw={500} size="xs">
            Período de realização
          </Text>
        </Group>
        <Text color={"white"} fw={600} size="sm">
          {uniqueData?.dataInicial?.toLocaleDateString("pt-BR") ?? ""} -{" "}
          {uniqueData?.dataFinal?.toLocaleDateString("pt-BR") ?? ""}{" "}
        </Text>
        <Space h="md" />
        <Group justify={"center"}>
          <Button bg={color} onClick={handleClick}>
            Entrar na campanha
          </Button>
        </Group>
      </Background>
    );
  }

  if (canCreateCampanha) {
    return (
      <Background>
        <Text c={"white"} fw={700} size="md">
          Cadastre um regulamento para iniciar:
        </Text>
        <Space h="md" />
        <Group justify={"center"} h={"100%"}>
          <Anchor component={NavLink} color={color} to={`/ideias/regulamentos`} underline="never">
            <Button bg={color}>Cadastrar</Button>
          </Anchor>
        </Group>
      </Background>
    );
  }

  return <></>;
};

export default CampanhaDeInovacao;
