/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import { subject } from "@casl/ability";
import { Button } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import { createColumnHelper } from "@tanstack/react-table";
import { formatRegiaoIdentifier } from "../../modals/identifier-form-modal/IdentifierFormModal";

const columnHelper = createColumnHelper<TRegiaoIdentifier>();

/**
 * Get columns
 * @param onRemove - On remove
 * @returns - Columns
 */
function getColumns(onRemove: (identifier: TRegiaoIdentifier) => void) {
  return [
    columnHelper.accessor("field", {
      header: "Campo",
      enableSorting: true,
      enableColumnFilter: true,
      cell(properties) {
        const value: TRegiaoIdentifier["field"] = properties.row.getValue("field");
        return formatRegiaoIdentifier(value);
      }
    }),
    columnHelper.accessor("value", {
      header: "Valor",
      enableSorting: true,
      enableColumnFilter: true
    }),
    columnHelper.display({
      id: "actions",
      cell: (properties) => {
        const identifier = subject("TRegiaoIdentifier", properties.row.original);
        return (
          <Button onClick={() => onRemove(identifier)} variant="transparent">
            <IconTrash />
          </Button>
        );
      }
    })
  ];
}

export default getColumns;
