import { Center, Space, Stack, Tabs, Text } from "@mantine/core";
import { QueryObserverResult } from "@tanstack/react-query";
import NoData from "@/components/no-data";
import HomeDeInovacaoHistoryLoading from "./HomeDeInovacaoHistoryLoading";
import HomeDeInovacaoItem from "../home-de-inovacao-card";
// prettier-ignore
const HomeDeInovacaoList = ({ data, refetch, isLoading, isFetching, formatAutorizacoes }: { data: TJogadaDeInovacao[] | undefined, refetch: () => Promise<QueryObserverResult>; isLoading: boolean; isFetching: boolean; formatAutorizacoes: TAutorizacao[] | undefined }) => {
  // const { data, isLoading, refetch, isFetching } = useQueryJogadaDeInovacao();

  if (data?.length === 0) {
    return (
      <Center m="lg">
        <NoData m="md">
          <Stack gap="xs">
            <Text c="dimmed">Nenhuma jogada encontrada!</Text>
          </Stack>
        </NoData>
      </Center>
    );
  }

  return (
    <Stack gap="xs" mt="md">
      {(isLoading || isFetching) && (
        <>
          <HomeDeInovacaoHistoryLoading />
          <HomeDeInovacaoHistoryLoading />
          <HomeDeInovacaoHistoryLoading />
        </>
      )}
      <Tabs defaultValue="gallery">
        <Tabs.List>
          <Tabs.Tab value="gallery">Jogadas em implementação</Tabs.Tab>
          <Tabs.Tab value="messages">Jogadas concluídas</Tabs.Tab>
        </Tabs.List>
        <Space h="md" />
        <Tabs.Panel value="gallery">
          {data && data.filter(item => item.implementada && !item.concluido && !item.cancelado).map((jogada) => <HomeDeInovacaoItem key={jogada.id} jogada={jogada} refetch={refetch} formatAutorizacoes={formatAutorizacoes} />)}
        </Tabs.Panel>
        <Tabs.Panel value="messages">
          {data && data.filter(item => item.implementada && (item.concluido || item.cancelado)).map((jogada) => <HomeDeInovacaoItem key={jogada.id} jogada={jogada} refetch={refetch} formatAutorizacoes={formatAutorizacoes} />)}
        </Tabs.Panel>
      </Tabs>
    </Stack>
  );
};

export default HomeDeInovacaoList;
