import { TransformedValues, useForm, yupResolver } from "@mantine/form";
import { QueryObserverResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { DESAFIO_CRONOGRAMA_COLLECTION_KEY, TCronogramaForm, addCronograma } from "../../firestore";
import desafioComentarioSchema from "../../schema";

type TAddCronogramaResponse = Awaited<ReturnType<typeof addCronograma>>;
interface MyState {
  id?: number;
  data: Date | null;
  descricao: string;
  orientacaoLocal: string;
}

type Options = {
  desafio: TDesafio;
  refetch: () => Promise<QueryObserverResult>;
  onSuccess?: () => void;
  event: MyState[];
};

/**
 * Hook to handle desafio comentario form
 * @param options - Hook options
 * @param options.desafio - Desafio to comment
 * @param options.refetch - Desafio to comment
 * @param options.onSuccess - Function to be called on success
 * @param options.event - event
 * @returns - Desafio comentario form
 */
// prettier-ignore
function useCronogramaForm({ desafio, refetch, onSuccess, event}: Options) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const queryClient = useQueryClient();

  const form = useForm<TCronogramaForm>({
    initialValues: desafioComentarioSchema.getDefault() as TCronogramaForm,
    transformValues: (values) =>
      desafioComentarioSchema.cast(values, {
        stripUnknown: true,
        context: {
          desafioId: desafio.id
        }
      }),
    validate: yupResolver(desafioComentarioSchema)
  });

  const { mutate, reset, isPending } = useMutation<TAddCronogramaResponse, FirestoreError, TCronogramaForm>({
    mutationFn: (values) => {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return addCronograma(activeEmpresaId, {...values, idDesafio: desafio?.id || "", event});
    },
    async onSuccess() {
      reset();
      form.reset();
      await queryClient.invalidateQueries({
        queryKey: [activeEmpresaId, "desafio", desafio.id, DESAFIO_CRONOGRAMA_COLLECTION_KEY]
      });
      if (onSuccess) {
        onSuccess();
       await refetch();
      }
    },
    onError(error) {
      captureException(error, true);
    }
  });

  type TTransformedValues = TransformedValues<typeof form>;
  /**
   * Handle form submit
   * @param values - Form values
   */
  function onSubmit(values: TTransformedValues) {
    mutate({ ...values });
  }

  return { form, isPending, onSubmit: form.onSubmit(onSubmit) };
}

export default useCronogramaForm;
