import { Box, Card, Group, LoadingOverlay, Space, Tabs, Text } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useState } from "react";
import useGetProjetoItem from "@/modules/analisar-resposta/components/questionario-item/useGetProjetoNew";
import BannerComponent from "@/modules/projeto-externo/components/banner-component";
import { verificationDate } from "@/modules/projetos/components/projeto-form/ProjetoForm";
import useUserStore from "@/modules/users/store";
import useQueryProjetos from "./useQueryProjetos";
import DiagnosticoItem from "../diagnostico-item";

const NUMBER_TWO = 2;
const MAGIC_NUMBER = 30;
const HORAS = 23;
const MINUTOS = 59;
const SEGUNDOS = 59;
const MILISEGUNDOS = 999;

interface DateFormat {
  dataInicial: Date;
  dataFinal: Date;
}

// prettier-ignore
const DiagnosticoList = () => {
  const { data: projetos, isFetching } = useQueryProjetos();
  const projeto = useUserStore((state) => state.activeProjeto);
  const [date, setDate] = useState<DateFormat>({ dataInicial: new Date(), dataFinal: new Date(new Date().setDate(new Date().getDate() + MAGIC_NUMBER)) });
  const { data: projetoItem } = useGetProjetoItem({ idProjeto: projeto || "" });

  if (isFetching) {
    return (
      <Box pos="relative">
        <LoadingOverlay visible={isFetching} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
      </Box>
    );
  }

  /**
   * Handle período date change
   * @param dates - [dataInicial, dataFinal]
   */
  function onDateIniciaFinalChange(dates: [Date, Date]) {
    const [dataInicial, dataFinal] = dates;
    setDate({ dataInicial, dataFinal })
  }

  return (
    <Card withBorder shadow="md" style={{ flex: 1 }} p={0}>
      {projeto && filteredData(projetos, 1, date)?.filter(item => projeto ? item.id === projeto : item).length === 1 && projetoItem?.exibir ? <BannerComponent projeto={filteredData(projetos, 1, date)?.find(item => projeto ? item.id === projeto : item)} tipo={"1"} /> : <></>}
      <div style={{ padding: 16 }}>
        <Tabs defaultValue="gallery">
          <Tabs.List>
            <Tabs.Tab value="gallery">Projetos em andamento</Tabs.Tab>
            <Tabs.Tab value="messages">Projetos concluídos</Tabs.Tab>
          </Tabs.List>
          <Space h="md" />
          <Tabs.Panel value="gallery" style={{ overflow: "auto" }}>
            <Group>
              <DatePickerInput
                type="range"
                w="280px"
                valueFormat="DD [de] MMMM, YYYY"
                value={[date.dataInicial, date.dataFinal]} // eslint-disable-line react-perf/jsx-no-new-array-as-prop
                onChange={onDateIniciaFinalChange}
                label="Período"
              />
            </Group>
            {filteredData(projetos, 1, date)?.filter(item => projeto ? item.id === projeto : item) && filteredData(projetos, 1, date)?.filter(item => projeto ? item.id === projeto : item).length > 0 ?
              filteredData(projetos, 1, date)?.filter(item => projeto ? item.id === projeto : item).map((projeto) => <DiagnosticoItem key={projeto.id} projeto={projeto} />) : <Group justify={"center"} mt="20px">
                <Text fw={700}>Nenhum projeto em andamento!</Text>
              </Group>}
          </Tabs.Panel>
          <Tabs.Panel value="messages" style={{ overflow: "auto" }}>
            <Group>
              <DatePickerInput
                type="range"
                w="280px"
                valueFormat="DD [de] MMMM, YYYY"
                value={[date.dataInicial, date.dataFinal]} // eslint-disable-line react-perf/jsx-no-new-array-as-prop
                onChange={onDateIniciaFinalChange}
                label="Período"
              />
            </Group>
            {filteredData(projetos, NUMBER_TWO, date)?.filter(item => projeto ? item.id === projeto : item) && filteredData(projetos, NUMBER_TWO, date)?.filter(item => projeto ? item.id === projeto : item).length > 0 ?
              filteredData(projetos, NUMBER_TWO, date)?.filter(item => projeto ? item.id === projeto : item).map((projeto) => <DiagnosticoItem key={projeto.id} projeto={projeto} />) : <Group justify={"center"} mt="20px">
                <Text fw={700}>Nenhum projeto concluído!</Text>
              </Group>}
          </Tabs.Panel>
        </Tabs>
      </div>
      {projeto && filteredData(projetos, 1, date)?.filter(item => projeto ? item.id === projeto : item).length === 1 && projetoItem?.exibir ? <BannerComponent projeto={filteredData(projetos, 1, date)?.find(item => projeto ? item.id === projeto : item)} tipo={"2"} /> : <></>}
    </Card>
  );
};

export default DiagnosticoList;

// prettier-ignore
const filteredData = (data: TProjeto[] | undefined, type: number, date: DateFormat) => {
  if (data && data.length > 0) {
    const newData = data.filter(item => {
      const endDate = typeof item?.anoVigencia === "number" ? verificationDate(new Date()) : verificationDate(item?.anoVigencia);
      if (endDate) {
        const endDateWithTime = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), HORAS, MINUTOS, SEGUNDOS, MILISEGUNDOS);
        return type === 1 ? endDateWithTime >= new Date() : endDate < new Date()
      }
      return item
    })

    return filteredDate(newData, date)
  }
  return []
}

// prettier-ignore
const filteredDate = (projetos: TProjeto[], periodo: DateFormat) => {
  const dataInicial = periodo.dataInicial;
  const dataFinal = periodo.dataFinal;

  if (dataInicial && dataFinal) {
    return projetos.filter(projeto => {
      const anoAtuacao = typeof projeto?.anoAtuacao === "number" ? verificationDate(new Date()) : verificationDate(projeto?.anoAtuacao);
      const anoVigencia = typeof projeto?.anoVigencia === "number" ? verificationDate(new Date()) : verificationDate(projeto?.anoVigencia);

      if (anoAtuacao && anoVigencia) {
        const dataInicialWithoutTime = new Date(dataInicial.getFullYear(), dataInicial.getMonth(), dataInicial.getDate(), 0, 0, 0, 0);
        return (anoAtuacao <= dataFinal && anoVigencia >= dataInicialWithoutTime) || (anoAtuacao >= dataInicialWithoutTime && anoVigencia <= dataFinal);
      }

      return projeto
    });
  }

  return projetos
}
