import { subject } from "@casl/ability";
import {
  FirestoreDataConverter,
  addDoc,
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where
} from "firebase/firestore";
import { getCurrentUser } from "@/services/auth";
import { getRegulamentoIdeiasDocumentReference } from "../regulamento-ideias/firestore";

export const CAMPANHA_REGULAMENTO_IDEIA_COLLECTION_KEY = "campanhas" as const;

// export type TCampanhaRegulamentoIdeiasForm = TFormWithTransformations<TCampanhaRegulamentoIdeias, "deletedAt" | "ideias">;

// prettier-ignore
export type TCampanhaRegulamentoIdeiasForm = Omit<
TCampanhaRegulamentoIdeias,
 "id" | "refPath" | "createdAt" | "updatedAt" | "createdBy" | "updatedBy" | "deletedAt" /* | "ideias" */
> & Partial<Pick<TCampanhaRegulamentoIdeias, /* "ideias" | */ "id">>;

type TCampanhaRegulamentoIdeiasDocument = TFirestoreDocument<TCampanhaRegulamentoIdeias>;

const campanhaRegulamentoIdeiasConverter: FirestoreDataConverter<TCampanhaRegulamentoIdeias> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const { dataInicial, dataFinal, createdAt, updatedAt, deletedAt, ...document } =
      snap.data() as TCampanhaRegulamentoIdeiasDocument;

    const data: TCampanhaRegulamentoIdeias = {
      ...document,
      id: snap.id,
      dataInicial: dataInicial.toDate(),
      dataFinal: dataFinal.toDate(),
      createdAt: createdAt?.toDate() || new Date(),
      refPath: snap.ref.path,
      deletedAt: deletedAt ? deletedAt.toDate() : null
    };

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }

    return subject("TCampanhaRegulamentoIdeias", data);
  }
};

/**
 * Get campanhas collection reference
 * @param empresaId - Empresa id where the desafio is located
 * @param regulamentoIdeiasId - regulamento id to get the campanhas collection reference
 * @returns - Campanhas collection reference
 */
function getCampanhaRegulamentoIdeiasCollectionReference(
  empresaId: TEmpresa["id"],
  regulamentoIdeiasId: TRegulamentoIdeias["id"]
) {
  const regulamentoIdeiasReference = getRegulamentoIdeiasDocumentReference(empresaId, regulamentoIdeiasId);
  return collection(regulamentoIdeiasReference, CAMPANHA_REGULAMENTO_IDEIA_COLLECTION_KEY);
}

/**
 * Get campanha de regulamento ideias document reference
 * @param empresaId - Empresa id where the campanha is located
 * @param regulamentoIdeiasId - Regulamento ideias id where the campanha is located
 * @param campanhaId - Campanha id to get the document reference
 * @returns - Campanha document reference
 */
function getCampanhaRegulamentoIdeiasDocumentReference(
  empresaId: TEmpresa["id"],
  regulamentoIdeiasId: TRegulamentoIdeias["id"],
  campanhaId: TCampanhaRegulamentoIdeias["id"]
) {
  const campanhaCollectionReference = getCampanhaRegulamentoIdeiasCollectionReference(empresaId, regulamentoIdeiasId);
  return doc(campanhaCollectionReference, campanhaId);
}

/**
 * Add a campanha to a regulamento ideias
 * @param empresaId - Empresa id where the campanha is located
 * @param regulamentoIdeiasId - Regulamento ideias id where the campanha is located
 * @param campanha - Campanha to add
 * @returns - The added campanha
 */
export async function addCampanhaRegulamentoIdeias(
  empresaId: TEmpresa["id"],
  regulamentoIdeiasId: TRegulamentoIdeias["id"],
  campanha: TCampanhaRegulamentoIdeiasForm
) {
  const campanhaCollectionReference = getCampanhaRegulamentoIdeiasCollectionReference(empresaId, regulamentoIdeiasId);
  return addDoc(campanhaCollectionReference, campanha);
}

/**
 * Update a campanha in regulamento ideias
 * @param empresaId - Empresa id where the campanha is located
 * @param regulamentoIdeiasId - Regulamento ideias id where the campanha is located
 * @param campanhaId - Campanha ID to update
 * @param campanha - Campanha to update
 * @returns - The updated campanha
 */
export async function updateCampanhaRegulamentoIdeias(
  empresaId: TEmpresa["id"],
  regulamentoIdeiasId: TRegulamentoIdeias["id"],
  campanhaId: TCampanhaRegulamentoIdeias["id"],
  campanha: TCampanhaRegulamentoIdeiasForm
) {
  const campanhaDocumentReference = getCampanhaRegulamentoIdeiasDocumentReference(
    empresaId,
    regulamentoIdeiasId,
    campanhaId
  );
  const currentUserId = getCurrentUser()?.uid;
  return updateDoc(campanhaDocumentReference, { ...campanha, updatedAt: serverTimestamp(), updatedBy: currentUserId });
}

/**
 * Remove campanha from regulamento ideias
 * @param empresaId - Empresa id where the campanha is located
 * @param regulamentoIdeiasId - Regulamento ideias id where the campanha is located
 * @param campanhaId - Campanha ID to remove
 * @returns - The removed campanha
 */
export async function removeCampanhaRegulamentoIdeias(
  empresaId: TEmpresa["id"],
  regulamentoIdeiasId: TRegulamentoIdeias["id"],
  campanhaId: TCampanhaRegulamentoIdeias["id"]
) {
  const campanhaDocumentReference = getCampanhaRegulamentoIdeiasDocumentReference(
    empresaId,
    regulamentoIdeiasId,
    campanhaId
  );
  const currentUserId = getCurrentUser()?.uid;
  return updateDoc(campanhaDocumentReference, { deletedAt: serverTimestamp(), updatedBy: currentUserId });
}

/**
 * Get campanhas from regulamento ideias
 * @param empresaId - Empresa id where the campanha is located
 * @param regulamentoIdeiasId - Regulamento ideias id where the campanha is located
 * @returns - The campanhas
 */
export function getCampanhasRegulamentoIdeias(
  empresaId: TEmpresa["id"],
  regulamentoIdeiasId: TRegulamentoIdeias["id"]
) {
  const campanhaCollectionReference = getCampanhaRegulamentoIdeiasCollectionReference(
    empresaId,
    regulamentoIdeiasId
  ).withConverter(campanhaRegulamentoIdeiasConverter);
  return getDocs(query(campanhaCollectionReference, where("deletedAt", "==", null), orderBy("dataInicial", "desc")));
}
