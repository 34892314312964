import { Flex, Group, Loader, Space, Table, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import useGetProjetoItem from "@/modules/analisar-resposta/components/questionario-item/useGetProjetoNew";
import { getRespostasMediaEmpresa } from "@/modules/analisar-respostas/firestore";
import { RankingListPaginator } from "@/modules/diagnostico/components/ranking-list/components/RankingListPaginator";
import { usePaginator } from "@/modules/diagnostico/components/ranking-list/hooks/usePaginator";
import useUserStore from "@/modules/users/store";
import ExportExcel from "./ExportExcel";
import ModalFilter from "./ModalFilter";
import GetEmpresas from "../graphics-nivo/useGetEmpresa";
import TableItem from "../table-item";
// prettier-ignore
interface Filtro {
  search: string;
  filtro: string;
  habilitada: boolean;
}

interface Habilitada {
  habilitada: boolean;
}

interface StatusResposta {
  idQuestionario: string;
  idEmpresa: string;
}

const message = "Ocorreu um erro ao carregar os dados:";

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
// prettier-ignore
const TableEmpresa = ({ empresas, questionario }: { empresas: TConvite[], questionario: string }) => {
  const [filter, setFilter] = useState<Filtro>(mockFilter)
  const [newEmpresa, setNewEmpresa] = useState<(TEmpresa & Habilitada)[]>([]);
  const projeto = useUserStore((state) => state.activeProjeto);
  const { data } = useGetProjetoItem({ idProjeto: projeto || "" });
  const [loading, setLoading] = useState(false);
  const dados = filteredData(newEmpresa, filter);
  const { currentPageContent, currentPage, pageSize, setCurrentPage, setPageSize } = usePaginator(dados);


  const uniqueArray = empresas.filter((item, index, self) =>
    index === self.findIndex((t) => (
      t.idEmpresa === item.idEmpresa
    ))
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadData();
      } catch (error) {
        console.error(message, error);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, [data?.minimoResposta])

  const loadData = async () => {
    const newData = [...uniqueArray.map(item => ({ ...item, idEmpresaColaboradorConvidado: item.idEmpresa })), ...empresas];
    if (newData && newData?.length > 0) {
      setLoading(true)
      const newEmpresa = await GetEmpresas(newData);
      let newArray = [] as [];
      for (const item of newEmpresa) {
        const newData = await getRespostasMediaEmpresa(item.id);
        const format = newData.docs.map((resposta) => resposta.data()) || [] as TResposta[] & StatusResposta[];
        newArray = [...newArray, ...format as TResposta[] & StatusResposta[] as []]
      }

      setNewEmpresa(newEmpresa.map(item => {
        const respostas = (newArray as (TResposta & StatusResposta)[]).filter(row => row.idEmpresa === item.id && row.pausado === false && row.idQuestionario === questionario) || [];
        const minimoResposta = data?.minimoResposta || 0;

        return {
          ...item,
          habilitada: respostas.length >= minimoResposta
        }
      }))
      setLoading(false)
    }
  }
  const rows = currentPageContent.map((item) => (
    <TableItem key={item.id} data={item} />
  ));

  return (
    <>
      <Group justify={"space-between"} mb={10}>
        <Text size={"md"} fw={700}>Detalhamento das Empresas</Text>
        <Group>
          <ModalFilter setFilter={setFilter} />
          <ExportExcel newEmpresa={filteredData(newEmpresa, filter)} />
        </Group>
      </Group>
      <div style={{ overflowX: "auto" }}>

        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Empresa</Table.Th>
              <Table.Th>Código CNAE</Table.Th>
              <Table.Th>Cnpj</Table.Th>
              <Table.Th>Responsável</Table.Th>
              <Table.Th>Telefone</Table.Th>
              <Table.Th>Porte</Table.Th>
              <Table.Th>Estado</Table.Th>
              <Table.Th>Categoria</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{loading ? <> </> : rows}</Table.Tbody>
        </Table>
        <Flex p="sm" justify="flex-end" gap="sm">
          <RankingListPaginator
            numberOfItems={dados.length}
            currentPage={currentPage}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
            onPageSizeChange={setPageSize}
          />
        </Flex>
      </div>
      <Space h={"md"} />
      {loading ? <Group justify={"center"} align={"center"}>
        <Loader color="blue" />
      </Group> : <></>}
    </>
  )
};

export default TableEmpresa;

// prettier-ignore
const filteredData = (data: (TEmpresa & Habilitada)[], filter: Filtro) => {
  return data.filter(item => doFilter(item, filter))
}

// prettier-ignore
const doFilter = (item: (TEmpresa & Habilitada), filter: Filtro) => {
  switch (filter.filtro) {
    case "Empresa": {
      return filterByEmpresa(item, filter);
    }
    case "Cnpj": {
      return filterByCnpj(item, filter);
    }
    case "Responsavel": {
      return filterByResponsavel(item, filter);
    }
    case "Telefone": {
      return filterByTelefone(item, filter);
    }
    case "Porte": {
      return filterByPorte(item, filter);
    }
    case "Estado": {
      return filterByEstado(item, filter);
    }
    case "Categoria": {
      return filterByCategoria(item, filter);
    }
    case "": {
      return filterByHabilitada(item, filter);
    }
    default: {
      return item;
    }
  }
}
// prettier-ignore
const filterByEmpresa = (item: (TEmpresa & Habilitada), filter: Filtro) => {
  return filter.habilitada ?
    item.nomeFantasia === filter.search && item.habilitada === filter.habilitada :
    item.nomeFantasia === filter.search;
};
// prettier-ignore
const filterByCnpj = (item: (TEmpresa & Habilitada), filter: Filtro) => {
  return filter.habilitada ?
    item.cnpj === filter.search && item.habilitada === filter.habilitada :
    item.cnpj === filter.search;
};
// prettier-ignore
const filterByResponsavel = (item: (TEmpresa & Habilitada), filter: Filtro) => {
  return filter.habilitada ?
    item.responsavel.nome === filter.search && item.habilitada === filter.habilitada :
    item.responsavel.nome === filter.search;
};
// prettier-ignore
const filterByTelefone = (item: (TEmpresa & Habilitada), filter: Filtro) => {
  return filter.habilitada ?
    item.telefone === filter.search && item.habilitada === filter.habilitada :
    item.telefone === filter.search;
};
// prettier-ignore
const filterByPorte = (item: (TEmpresa & Habilitada), filter: Filtro) => {
  return filter.habilitada ?
    item.porte === filter.search && item.habilitada === filter.habilitada :
    item.porte === filter.search;
};
// prettier-ignore
const filterByEstado = (item: (TEmpresa & Habilitada), filter: Filtro) => {
  return filter.habilitada ?
    item.endereco.estado === filter.search && item.habilitada === filter.habilitada :
    item.endereco.estado === filter.search;
};
// prettier-ignore
const filterByCategoria = (item: (TEmpresa & Habilitada), filter: Filtro) => {
  return filter.habilitada ?
    item.categorias === filter.search && item.habilitada === filter.habilitada :
    item.categorias === filter.search;
};
// prettier-ignore
const filterByHabilitada = (item: TEmpresa & Habilitada, filter: Filtro) => {
  return filter.habilitada ? filter.habilitada === item.habilitada : item
}

// prettier-ignore
const mockFilter = {
  search: "",
  filtro: "",
  habilitada: false
};
