import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { getCountIdeiaLikes } from "../firestore";

type TGetCountIdeiasLikesResponse = Awaited<ReturnType<typeof getCountIdeiaLikes>>;
type TQueryOptions = Omit<UseQueryOptions<TGetCountIdeiasLikesResponse, FirestoreError, number>, "queryKey">;

/**
 * Get total likes per ideia
 * @param ideia - Ideia to get activities
 * @param options - Query options to use
 * @returns - Query result
 */
function useCountTotalLikesPerIdeia(ideia: TIdeia | null, options: TQueryOptions = {}) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  return useQuery<TGetCountIdeiasLikesResponse, FirestoreError, number | null>({
    queryKey: [activeEmpresaId, "ideia", ideia?.id, "likes", ideia],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!ideia) {
        throw new Error("Ideia não informada");
      }

      return getCountIdeiaLikes(activeEmpresaId, ideia.id);
    },
    select(dataSnapshot) {
      return dataSnapshot.data().count;
    },
    enabled: !!ideia,
    ...options
  });
}

export default useCountTotalLikesPerIdeia;
