import { Avatar, Group, Image, Stack, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import colaboradorSchema from "@/modules/colaboradores/schema/colaborador-schema";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import EmpresaSelector from "./EmpresaSelector";
import useGetConvites from "./useGetConvites";
import AddItemButton from "../add-item-button";

// prettier-ignore
const MenuUser = () => {
  const [name, setName] = useState("");

  const empresa = useUserStore((state) => state.activeEmpresa);
  const colaborador = useUserStore((store) => store.colaborador);
  const currentUser = getCurrentUser();

  const { data } = useGetColaboradorById(empresa?.id || "", currentUser?.uid || "");
  const { data: convite } = useGetConvites();

  useEffect(() => {
    setName(
      colaborador?.nome ?? currentUser?.displayName ?? currentUser?.email ?? ""
    )
  }, [])

  if (!currentUser || !data) {
    return null;
  }

  return (
    <Group wrap="nowrap" gap="xs">
      {(convite && convite?.length === 0) || colaborador?.role === "ADMIN" ? (
        <AddItemButton
          size="sm"
          modalProperties={{ modal: "colaborador-form-modal" }}
          schema={colaboradorSchema}
          item={data}
          setName={setName}
          style={{ padding: 0 }}
          disabled={empresa?.status === "INATIVO"}
        >
          {data?.image?.length > 0 ? (
            <Image h={38} w={38} radius="xl" src={data.image[0]?.path as string} />
          ) : (
            <Avatar src={""} radius="xl" color="white" size="md" alt={name} style={{ cursor: "pointer" }} />
          )}
        </AddItemButton>
      ) : (
        <>
          {data?.image?.length > 0 ? (
            <Image h={38} w={38} radius="xl" src={data.image[0]?.path as string} />
          ) : (
            <Avatar src={""} radius="xl" color="white" size="md" alt={name} />
          )}
        </>
      )}
      <Stack gap={0}>
        <EmpresaSelector />
        <Text size="sm" c="white.1">
          {name}
        </Text>
      </Stack>
    </Group>
  );
};

export default MenuUser;
