import { Stack } from "@mantine/core";
import { IconId } from "@tabler/icons-react";
import { NavLink } from "react-router-dom";
import useUserStore from "@/modules/users/store";
import NavLinkMenu from "./NavLinkMenu";

// prettier-ignore
const AppAdminMenu = ({ toggleMobile }: { toggleMobile: () => void }) => {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresa);

  if (activeEmpresaId && activeEmpresaId?.status === "INATIVO") {
    return <></>;
  }

  const renderNavLink = (label: string, icon: React.ReactNode, to: string) => (
    <NavLinkMenu label={label} leftSection={icon} component={NavLink} onClick={toggleMobile} to={to} />
  );


  return (
    <Stack mx="md" mt="lg" gap="xs">
      {renderNavLink("Licenciadas", <IconId size="1.2rem" stroke={1.5} />, "/")}
    </Stack>
  );

};

export default AppAdminMenu;
