/* eslint-disable react-perf/jsx-no-new-function-as-prop */
/* eslint-disable unicorn/consistent-function-scoping */
import { Box, Stack, Group, Button, Textarea, Text, NumberInput, Modal, Select } from "@mantine/core";
import { useForm } from "@mantine/form";
import { FormEvent, useEffect } from "react";
import { extractTextInputPropertiesFromFormSchema } from "@/utils/form";
import feedbackSchema from "../../schema/feedback-schema";

const defaultFeedback: TFeedback = {
  title: "",
  description: "",
  start: 0,
  end: 0
};

type Properties = {
  open: boolean | TFeedback;
  onClose: (feedback?: TFeedback) => void;
  faixas: string[];
};

/**
 * Feedback form for GrupoFeedbacks
 * @param root0 - props
 * @param root0.open - open
 * @param root0.onClose - onClose
 * @param root0.faixas - faixas
 * @returns React component
 */
export default function GrupoFeedbackForm({ open, onClose, faixas }: Properties) {
  const form = useForm<TFeedback>({ initialValues: defaultFeedback });

  const descriptor = feedbackSchema.describe();

  useEffect(() => {
    if (typeof open === "object") {
      form.setValues(open);
    }
  }, [open]);

  const handleSubmit = (feedback?: TFeedback) => {
    if (!feedback) {
      onClose();
      return;
    }

    if (feedback.start >= feedback.end) {
      form.setErrors({ end: "O valor final deve ser menor que o valor final" });
      return;
    }

    form.reset();
    onClose(feedback);
  };

  return (
    <Modal zIndex={201} opened={!!open} onClose={handleSubmit}>
      <form
        onSubmit={(event: FormEvent<HTMLFormElement>) => {
          event.stopPropagation();
          return form.onSubmit(handleSubmit)(event);
        }}
      >
        <Stack gap="md">
          <Text size="lg" fw="bold">
            Novo feedback
          </Text>
          <Box>
            <Stack gap="sm" mb="lg">
              <Select
                data={faixas}
                allowDeselect={false}
                {...form.getInputProps("title")}
                {...extractTextInputPropertiesFromFormSchema(descriptor, "title")}
              />
              <Group gap="md">
                <NumberInput
                  style={{ flex: 1 }}
                  {...form.getInputProps("start")}
                  {...extractTextInputPropertiesFromFormSchema(descriptor, "start")}
                />
                <NumberInput
                  style={{ flex: 1 }}
                  {...form.getInputProps("end")}
                  {...extractTextInputPropertiesFromFormSchema(descriptor, "end")}
                />
              </Group>
              <Textarea
                style={{ flex: 1 }}
                resize="vertical"
                {...form.getInputProps("description")}
                {...extractTextInputPropertiesFromFormSchema(descriptor, "description")}
              />
            </Stack>
          </Box>
          <Group justify="flex-end">
            <Button variant="outline" onClick={() => handleSubmit()}>
              Cancelar
            </Button>
            <Button type="submit">Salvar</Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
}
