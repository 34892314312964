import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import {
  getRegulamentoDocumentReferenceFromReferencePath,
  getTotalMoedasForRegulamentoDesafios
} from "@/modules/regulamento-desafios/firestore";
import useUserStore from "@/modules/users/store";

type TGetTotalMoedasForRegulamentoDesafiosResponse = Awaited<ReturnType<typeof getTotalMoedasForRegulamentoDesafios>>;

/**
 * Get total votes for the colaborador in multiple regulamentos
 * @param regulamentos - List of regulamentos to get the total votes
 * @returns - Query result
 */
function useGetTotalMoedasTodosRegulamentos(regulamentos?: TRegulamentoDesafios[] | null) {
  const [activeEmpresaId, colaborador] = useUserStore((state) => [state.activeEmpresaId, state.colaborador]);

  return useQuery<TGetTotalMoedasForRegulamentoDesafiosResponse[], FirestoreError>({
    queryKey: [
      "total-votos-todos-regulamentos",
      activeEmpresaId,
      colaborador?.id,
      colaborador,
      regulamentos,
      regulamentos?.length,
      regulamentos?.map((regulamento) => regulamento.id)
    ],
    initialData: regulamentos?.map((regulamento) => regulamento.quantidadeMoedasPorColaborador || 0) || [],
    placeholderData: regulamentos?.map((regulamento) => regulamento.quantidadeMoedasPorColaborador || 0) || [],
    async queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!colaborador) {
        throw new Error("Colaborador não encontrado");
      }
      if (!regulamentos || regulamentos.length === 0) {
        throw new Error("Nenhum regulamento fornecido");
      }

      const results = [];
      for (const regulamento of regulamentos) {
        const regulamentoDocumentReference = getRegulamentoDocumentReferenceFromReferencePath(regulamento.refPath);

        const totalMoedas = await getTotalMoedasForRegulamentoDesafios(
          activeEmpresaId,
          colaborador.id,
          regulamentoDocumentReference
        );

        results.push(totalMoedas ?? 0);
      }

      return results;
    },
    enabled: !!regulamentos && regulamentos.length > 0
  });
}

export default useGetTotalMoedasTodosRegulamentos;
