import { Anchor, Box, Group, LoadingOverlay, Space, Text } from "@mantine/core";
import { NavLink } from "react-router-dom";
import BreadcrumbsNavigation from "@/components/breadcrumbs-navigation";
import CardEmpresaItem from "@/modules/analisar-resposta/components/cards";
import RankingList from "@/modules/analisar-resposta/components/ranking-list";
import useGetProjetoFromUrl from "@/modules/analisar-resposta/components/ranking-list/useGetProjetoFromUrl";
import useUserStore from "@/modules/users/store";
// import useGetConvite from "@/modules/diagnostico/components/cards/useGetEmpresasConvidadas";
// prettier-ignore
const AnalisarRespostaPage = () => {
  const { data: projeto, isFetching } = useGetProjetoFromUrl();
  const selectProjeto = useUserStore((state) => state.activeProjeto);
  if (isFetching) {
    return (
      <Box pos="relative">
        <LoadingOverlay visible={isFetching} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
      </Box>
    );
  }

  if (selectProjeto === null) {
    return (
      <Group justify={"center"}>
        <Text fw={700}>Nenhum projeto selecionado!</Text>
      </Group>
    );
  }

  return (
    <>
      <BreadcrumbsNavigation notHome={true}>
        <Anchor component={NavLink} to="/analisarRespostas">
          Home
        </Anchor>
      </BreadcrumbsNavigation>
      <CardEmpresaItem projeto={projeto} />
      <Space h="md" />
      {projeto && <RankingList projeto={projeto} />}
    </>
  );
};

export default AnalisarRespostaPage;
