import { Text } from "@mantine/core";
import useGetProjetoFromUrl from "./useGetProjetoFromUrl";

const NomePlano = () => {
  const { data } = useGetProjetoFromUrl();

  return (
    <>
      <Text>{data?.nome}</Text>
    </>
  );
};

export default NomePlano;
