import { Text, Badge, Group } from "@mantine/core";
import { ColumnFilter, ColumnFiltersState } from "@tanstack/react-table";

const FilterValue = ({ id, value }: { id: ColumnFilter["id"]; value: ColumnFilter["value"] }) => {
  const [fieldOperator, fieldValue] = value as Array<string | string[]>;
  const stringValue = Array.isArray(fieldValue) ? fieldValue.join(", ") : fieldValue;

  return (
    <Badge
      color="cyan"
      size="sm"
      radius="md"
      variant="light"
      leftSection={
        <Text fw="bold" fz="xs">
          {id}
        </Text>
      }
      rightSection={stringValue}
    >
      {fieldOperator}
    </Badge>
  );
};

const FilterList = ({ filters }: { filters: ColumnFiltersState }) => (
  <Group gap="xs" align="center">
    {filters && filters.map((filter) => <FilterValue key={filter.id} id={filter.id} value={filter.value} />)}
  </Group>
);

export default FilterList;
