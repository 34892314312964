import { Card, Grid, Switch, Text } from "@mantine/core";
import { Timestamp } from "firebase/firestore";
import useUserStore, { setAutorizacoes } from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import useGetAutorizacao from "./useGetAutorizacao";
import { useSendAutorizacao } from "./useSendAutorizacao";

interface Item {
  id: string;
  label: string;
  editar: boolean;
  criar: boolean;
  remover: boolean;
}

const booleanChange = (field: string, checked: boolean, newData: TAutorizacao | undefined) => {
  return {
    leitura: field === "leitura" ? checked : newData?.leitura || false,
    criacao: field === "criacao" ? checked : newData?.criacao || false,
    editar: field === "editar" ? checked : newData?.editar || false,
    remover: field === "remover" ? checked : newData?.remover || false
  };
};
// prettier-ignore
const AutorizacaoItem = ({ item, select }: { item: Item; select: string }) => {
  const { mutate } = useSendAutorizacao();
  const { data, refetch } = useGetAutorizacao(select, item.id);
  const uid = getCurrentUser()?.uid;
  const newData = data && data[0];
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  const changeMutate = (field: string) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const newId = newData?.id ? { id: newData.id } : {};

      mutate({
        ...newId,
        tipo: select || "",
        tipoAutorizacao: item.id || "",
        ...booleanChange(field, event.target.checked, newData),
        updatedAt: Timestamp.now(),
        createdBy: uid || "",
        createdAt: Timestamp.now()
      });
      void refetch();
      setAutorizacoes(activeEmpresaId).catch(error => {
        console.error(error);
      });
    };
  };

  return (
    <Card padding="lg" radius="md">
      <Grid>
        <Grid.Col span={3}>
          <Text size="sm">{item.label}</Text>
        </Grid.Col>
        <Grid.Col span={2}>
          <Switch onChange={changeMutate("leitura")} checked={newData && newData.leitura} />
        </Grid.Col>
        <Grid.Col span={2}>
          <Switch onChange={changeMutate("criacao")} checked={newData && newData.criacao} disabled={item.criar} />
        </Grid.Col>
        <Grid.Col span={2}>
          <Switch onChange={changeMutate("editar")} checked={newData && newData.editar} disabled={item.editar} />
        </Grid.Col>
        <Grid.Col span={2}>
          <Switch onChange={changeMutate("remover")} checked={newData && newData.remover} disabled={item.remover} />
        </Grid.Col>
      </Grid>
    </Card>
  );
};

export default AutorizacaoItem;
