import { Avatar, Flex, Group, Space, Text, TextInput, Tooltip } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import React, { useCallback } from "react";

interface ArrayProperties {
  nome?: string;
  value: number;
  descricao: string;
  id: string;
  tipoDeResposta: string;
  resposta?: string | number | Campo | Campo[] | boolean;
  pergunta?: boolean;
  obrigatoria: boolean;
}

interface Campo {
  campo: string;
  valor: string;
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
// prettier-ignore
const TextComponent = ({ item, changeValues, disabled, licenciado }: { item: ArrayProperties, changeValues: (value: string | number, item: ArrayProperties) => void, disabled: boolean, licenciado: TLicenciado | null | undefined }) => {

  const handleChangeTextInput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    changeValues(event?.target.value, item);
  }, [changeValues, item]);

  return (
    <Flex justify={"center"} align="center" direction={"column"} w={"80vw"} style={{ overflow: "auto" }}>
      {item.obrigatoria ? <>
        <Tooltip label="Está pergunta é obrigatória">
          <IconInfoCircle style={{ cursor: "pointer" }} />
        </Tooltip>
        <Space h="md" />
      </>
        : <></>}
      {licenciado && licenciado.logo && licenciado?.logo?.length > 0 ?
        <>
          <Group>
            {licenciado.logo.map(item =>
              <>
                <Avatar src={item?.path} radius="sm" size="lg" variant="default" />
                <Space h="md" />
              </>
            )}
          </Group>
          <Space h="md" />
        </>
        : <></>}
      <Text fw={700} size="lg">{item?.nome}</Text>
      <Space h="md" />
      <Text size="md">{item?.descricao}</Text>
      <Space h="md" />
      <TextInput
        placeholder="Digite..."
        w={"60vw"}
        onChange={handleChangeTextInput}
        value={typeof item.resposta === 'string' ? item.resposta : ""}
        disabled={disabled}
      />
      <Space h="md" />
    </Flex>

  )
};

export default TextComponent;
