import { Anchor, Group, Space } from "@mantine/core";
import { NavLink } from "react-router-dom";
import BreadcrumbsNavigation from "@/components/breadcrumbs-navigation";
import CardEmpresa from "@/modules/analisar-resposta/components/cards/CardEmpresa";
import QuestionariosList from "@/modules/analisar-resposta/components/questionarios-list";

const EmpresaAnalisePage = () => {
  return (
    <>
      <BreadcrumbsNavigation notHome={true}>
        <Anchor component={NavLink} to="/diagnostico">
          Home
        </Anchor>
      </BreadcrumbsNavigation>
      <Group>
        <CardEmpresa />
      </Group>
      <Space h="md" />
      <QuestionariosList />
    </>
  );
};

export default EmpresaAnalisePage;
