import { RouteObject } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import SetupMyEmpresaPage from "./setup-my-empresa-page";

const noCompanyRoutes: RouteObject[] = [
  {
    path: "/",
    index: true,
    element: <SetupMyEmpresaPage />,
    errorElement: <ErrorPage />
  }
];

export default noCompanyRoutes;
