import { Avatar, Card, Flex, Group, GroupProps, Stack, Text } from "@mantine/core";
import { PropsWithChildren, ReactNode } from "react";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import MarcosList from "@/modules/ideia-marcos/components/MarcosList";
import { IDEIA_STATUS } from "@/modules/ideias/schema/ideia-schema";
import useUserStore from "@/modules/users/store";
import { formatDate } from "@/utils/date";
import { extractInitialsFromText } from "@/utils/text";
import { gerarDadosSelect } from "../../utils/gerar-dados-select";

const statusSelectData = gerarDadosSelect(IDEIA_STATUS);

const Item = ({
  title,
  value,
  children,
  ...groupProperties
}: PropsWithChildren<{ title: string; value: ReactNode } & GroupProps>) => (
  <Group {...groupProperties}>
    {children}
    <Stack gap={0}>
      <Text fz="sm" c="dimmed">
        {title}
      </Text>
      {value}
    </Stack>
  </Group>
);

type ColaboradorInputProperties = {
  empresaId: TEmpresa["id"];
  colaboradorId: TColaborador["id"];
};
// prettier-ignore
const ColaboradorInput = ({ empresaId, colaboradorId }: ColaboradorInputProperties) => {
  const { data: autor } = useGetColaboradorById(empresaId, colaboradorId);
  return (
    <Item title="Autor" value={<Text>{autor?.nome}</Text>} style={{ flex: 1 }}>
      {autor && (
        <Avatar src={autor && autor.image && autor.image.length > 0 ? autor?.image[0]?.path : ""} key={autor.id} alt={autor.nome || autor.email} >
          {extractInitialsFromText(autor.nome || autor.email)}
        </Avatar>
      )}
    </Item>
  );
};

/**
 * Get status label from status
 * @param status - Status
 * @returns - Label
 */
function getStatusLabel(status: TIdeiaStatus | null): string {
  const statusLabel = statusSelectData.find((data) => data.value === status)?.label;
  return statusLabel ? `de ${statusLabel}` : "";
}
// prettier-ignore
const ItemHistorico = ({ movimentacao, ideia }: { movimentacao: TMovimentacao; ideia: TIdeia }) => {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const statusTitle = getStatusLabel(movimentacao.status as TIdeiaStatus);

  return (
    <Card withBorder>
      <Flex direction="column" gap="sm">
        <Group justify="space-between" align="start">
          {activeEmpresaId && <ColaboradorInput empresaId={activeEmpresaId} colaboradorId={movimentacao?.createdBy} />}
          <Item
            title="Status"
            value={statusSelectData.find((data) => data.value === movimentacao.status)?.label}
            style={{ flex: 1 }}
          />
          <Item title="Data da modificação" value={formatDate(movimentacao.createdAt)} style={{ flex: 1 }} />
          <Item title="Observação" value={movimentacao.observacoes} style={{ flex: 1 }} />
        </Group>
        <MarcosList title={`Marcos ${statusTitle}`} marcos={movimentacao.marcos} ideia={ideia} />
      </Flex>
    </Card>
  );
};

export default ItemHistorico;
