import { Button, Group } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { TMarcoForm } from "../firestore";
import useDeleteMarco from "../hooks/useDeleteMarco";
import useGetMarcoFromReferencePath from "../hooks/useGetMarcoFromReferencePath";

interface MarcoActionButtonsProperties {
  marcoPath: string;
  ideia: TIdeia;
  onMarcoRemoved?: (path: string) => void;
}
const MarcoActionButtons = ({ marcoPath, ideia, onMarcoRemoved }: MarcoActionButtonsProperties) => {
  const { data: marco, isLoading: isLoadingMarco } = useGetMarcoFromReferencePath(marcoPath);

  const { mutate: deleteMacro, isPending } = useDeleteMarco(marcoPath, {
    onSuccess() {
      if (onMarcoRemoved) {
        onMarcoRemoved(marcoPath);
      }
    }
  });

  /**
   *  Handles the click event to delete the macro.
   */
  function handleDelete() {
    deleteMacro();
  }

  /**
   * Handles the click event.
   *
   */
  function handleModalEdit() {
    modals.openContextModal({
      modal: "marcos-form-modal",
      size: "xl",
      innerProps: {
        ideia,
        marco: marco as unknown as TMarcoForm
      },
      ml: "110px"
    });
  }

  return (
    <Group gap={6}>
      <Button
        size="xs"
        variant="outline"
        title="Editar"
        onClick={handleModalEdit}
        loading={isLoadingMarco}
        disabled={isPending}
      >
        <IconEdit size={12} />
      </Button>
      <Button
        size="xs"
        variant="outline"
        title="Deletar"
        onClick={handleDelete}
        loading={isPending}
        disabled={isLoadingMarco}
      >
        <IconTrash size={12} />
      </Button>
    </Group>
  );
};

export default MarcoActionButtons;
