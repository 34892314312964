import { Card, Flex, Group, Skeleton, Text, ThemeIcon } from "@mantine/core";
import { IconCoin } from "@tabler/icons-react";
import useCatchFirestoreError from "@/hooks/useCatchFirestoreError";
import IdeaPatrocinioForm from "@/modules/ideias-activities-per-colaborador/components/ideia-patrocinio-form";
import useGetTotalMoedasPerRegulamento from "@/modules/ideias-activities-per-colaborador/components/ideia-patrocinio-form/useGetTotalMoedasPerRegulamento";
import useGetIdeiaActivityByColaborador from "@/modules/ideias-activities-per-colaborador/hooks/useGetIdeiaActivityByColaborador";
import useGetRegulamentoFromIdeia from "@/modules/regulamento-ideias/hooks/useGetRegulamentoFromIdeia";
import useUserStore from "@/modules/users/store";

const IdeiaPatrocinioCard = ({ ideia }: { ideia: TIdeia }) => {
  const colaborador = useUserStore((state) => state.colaborador);

  const {
    data: ideiaActivity,
    isLoading: isLoadingActivities,
    error: activitiesError
  } = useGetIdeiaActivityByColaborador(ideia, colaborador?.id);
  useCatchFirestoreError(activitiesError);

  const { data: regulamento, error: regulamentoError } = useGetRegulamentoFromIdeia(ideia);
  useCatchFirestoreError(regulamentoError);

  const totalMoedas = regulamento?.quantidadeMoedasPorColaborador ?? 0;

  const { data: totalMoedasGastas, error: totalMoedasGastasError } = useGetTotalMoedasPerRegulamento(regulamento);
  useCatchFirestoreError(totalMoedasGastasError);

  const moedasGastas = totalMoedasGastas ?? 0;

  return (
    <>
      <Text fw="bold" size="lg">
        Deseja patrocinar essa ideia?
      </Text>
      <Card withBorder mt="md">
        <Flex direction="column" pb="xs">
          <Text size="md" fw={500} c="#555">
            Quanto você deseja investir?
          </Text>
          <Group align="center" gap={2} c="gray">
            <Text c="#000" size="xs">
              Você tem:
            </Text>
            <ThemeIcon variant="light" color="yellow" size="xs">
              <IconCoin />
            </ThemeIcon>
            <Skeleton visible={isLoadingActivities} width={150} height={16}>
              <Text c="#000" size="xs" tt="lowercase">
                {`${totalMoedas - moedasGastas} moedas`}
              </Text>
            </Skeleton>
          </Group>
        </Flex>
        {regulamento && <IdeaPatrocinioForm ideia={ideia} ideiaActivity={ideiaActivity} regulamento={regulamento} />}
      </Card>
    </>
  );
};

export default IdeiaPatrocinioCard;
