import { connectStorageEmulator, getStorage } from "firebase/storage";
import app from "./app";

const storage = getStorage(app);

if (import.meta.env.DEV) {
  const DEFAULT_STORAGE_PORT = 9199;
  const __ENV_STORAGE_PORT__ = Number(import.meta.env.VITE_STORAGE_PORT);
  const STORAGE_PORT = Number.isInteger(__ENV_STORAGE_PORT__) ? __ENV_STORAGE_PORT__ : DEFAULT_STORAGE_PORT;

  const __ENV_STORAGE_HOST__ = import.meta.env.VITE_STORAGE_HOST;
  const STORAGE_HOST = __ENV_STORAGE_HOST__ || "localhost";

  // eslint-disable-next-line no-console
  console.log("Connecting to Storage emulator at %s:%d", STORAGE_HOST, STORAGE_PORT);

  connectStorageEmulator(storage, STORAGE_HOST, STORAGE_PORT);
}

export default storage;
