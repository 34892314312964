import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { getStatus } from "@/modules/diagnostico/firestore";
import useUserStore from "@/modules/users/store";

type TGetComitesInovacaoResponse = Awaited<ReturnType<typeof getStatus>>;
const QUERY_KEY: string = "status-list" as const;
/**
 * Get comites de inovação query hook
 * @returns Comites de inovação query hook
 */
function useGetStatusIdEmpresa() {
  const { idProjeto: id, idEmpresa: idEmpresaItem } = useParams<{ idProjeto: string; idEmpresa: string }>();
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  return useQuery<TGetComitesInovacaoResponse, FirestoreError, TStatus[]>({
    queryKey: [QUERY_KEY, id, idEmpresaItem, activeEmpresaId],
    enabled: !!activeEmpresaId,
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Usuário não autenticado");
      }
      return getStatus(activeEmpresaId, id, idEmpresaItem || "");
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((document_) => document_.data());
    }
  });
}

export default useGetStatusIdEmpresa;
