import { serverTimestamp } from "firebase/firestore";
import { ObjectSchema } from "yup";
import schema from "@/schema";
import { getCurrentUser } from "@/services/auth";
import FeedbackSchema from "./feedback-schema";
import { TGruposDiagnosticoForm, TGruposDiagnosticoDatabaseFields, TGruposDiagnosticoFormFields } from "../firestore";

const MIN_NAME_LENGTH = 3;

/**
 * Define only the meta fields that are not part of the form
 */
const gruposDiagnosticoSchemaDatabaseFields: ObjectSchema<TGruposDiagnosticoDatabaseFields> = schema.object().shape({
  createdAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .required()
    .defined()
    .nonNullable()
    .default<ReturnType<typeof serverTimestamp>>(() => serverTimestamp()),
  createdBy: schema
    .string()
    .required()
    .defined()
    .nonNullable()
    .default(() => {
      const currentUserId = getCurrentUser()?.uid;
      if (!currentUserId) {
        throw new Error("The current user is not defined");
      }
      return currentUserId;
    }),
  updatedAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .default<ReturnType<typeof serverTimestamp>>(() => serverTimestamp()),
  updatedBy: schema.string().default(() => {
    const currentUserId = getCurrentUser()?.uid;
    if (!currentUserId) {
      throw new Error("The current user is not defined");
    }
    return currentUserId;
  }),
  deletedAt: schema.mixed<ReturnType<typeof serverTimestamp>>().defined().nullable().default(null)
});

/**
 * Define the schema for the form
 */
const gruposDiagnosticoSchemaFormFields: ObjectSchema<TGruposDiagnosticoFormFields> = schema.object({
  id: schema.string().optional(),
  refPath: schema.string().optional(),
  nome: schema.string().min(MIN_NAME_LENGTH).required().defined().label("Nome"),
  descricao: schema.string().trim().min(MIN_NAME_LENGTH).required().defined().label("Descricao"),
  idQuestionario: schema.string().required().defined().label("Questionário"),
  idProjeto: schema.string().optional(),
  ordemQuestionario: schema.number().required().defined().label("Ordem no questionário"),
  pesoNota: schema.number().optional().label("Máx. de pontos do Grupo"),
  radar: schema.boolean().optional().label("Exibe no radar?"),
  feedbacks: schema.array(FeedbackSchema).default([]).label("Feedbacks")
});

const gruposDiagnosticoSchema: ObjectSchema<TGruposDiagnosticoForm> = gruposDiagnosticoSchemaFormFields
  // eslint-disable-next-line unicorn/prefer-spread
  .concat(gruposDiagnosticoSchemaDatabaseFields)
  .noUnknown()
  .strict()
  .label("GruposDiagnostico");

export default gruposDiagnosticoSchema;
