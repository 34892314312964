import { subject } from "@casl/ability";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import {
  FirestoreDataConverter,
  collection,
  getDocs,
  query,
  where,
  getCountFromServer,
  serverTimestamp,
  addDoc,
  doc,
  updateDoc
} from "firebase/firestore";
import { getCurrentUser } from "@/services/auth";
import { generateQuery } from "@/utils/firestore";
import { getEmpresaDocumentReference } from "../empresas/firestore";

export type TDesafioCategoriaDocument = TFirestoreDocument<TDesafioCategoria>;

export const QUERY_KEY = "desafioCategorias";
const DEFAULT_LIMIT = 10;
export const DEFAULT_SORTING: SortingState = [{ id: "nome", desc: false }];
export const DEFAULT_PAGINATION: PaginationState = { pageIndex: 0, pageSize: DEFAULT_LIMIT };

const BASE_FILTER: ColumnFiltersState = [
  {
    id: "deletedAt",
    value: ["==", null]
  }
];

export type TTCategoriaDeDesafio = Pick<TAuditForm, "createdAt" | "updatedAt">;

export const desafioCategoriaConverter: FirestoreDataConverter<TDesafioCategoria> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snapshot, options) {
    const { createdAt, updatedAt, deletedAt, ...data } = snapshot.data(options) as TDesafioCategoriaDocument;

    const categoria: TDesafioCategoria = {
      ...data,
      id: snapshot.id,
      refPath: snapshot.ref.path,
      createdAt: createdAt.toDate(),
      updatedAt: updatedAt?.toDate(),
      deletedAt: deletedAt ? deletedAt.toDate() : null
    };

    return subject("TDesafioCategoria", categoria);
  }
};

/**
 * Get desafio categorias collection reference
 * @param empresaId - Empresa id to get the desafios categorias collection reference
 * @returns - desafios categorias collection reference
 */
function getDesafioCategoriasCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, QUERY_KEY);
}

/**
 * Get all desafios categorias ativas from the database
 * @param empresaId - Empresa id to get the desafios categorias collection reference
 * @returns - desafios categorias collection reference
 */
export function getDesafioCategoriasAtivas(empresaId: TEmpresa["id"]) {
  const desafioCategoriasCollectionReference =
    getDesafioCategoriasCollectionReference(empresaId).withConverter(desafioCategoriaConverter);
  const q = query(desafioCategoriasCollectionReference, where("deletedAt", "==", null));
  return getDocs(q);
}
/**
 * Get categorias de desafio for a empresa
 * @param  empresaId - Empresa id to get comitês
 * @param  root0 - Objeto contendo opções como sorting, filters, pagination, paginationCursors
 * @param  root0.sorting - Sorting option for categorias de desafio
 * @param  root0.filters - Filters for categorias de desafio
 * @param  root0.pagination - Pagination options for categorias de desafio
 * @param root0.paginationCursors - Pagination cursors for categorias de desafio
 * @returns - Tipos de desafio document snapshot
 */
export function getDesafioCategorias(
  empresaId: TEmpresa["id"],
  {
    sorting = [],
    filters = [],
    pagination = { pageIndex: 0, pageSize: DEFAULT_LIMIT },
    paginationCursors
  }: TPaginatedQueryOptions<TDesafioCategoria> = {}
): Promise<TPaginatedQueryResponse<TDesafioCategoria>> {
  const projetosCollectionReference =
    getDesafioCategoriasCollectionReference(empresaId).withConverter(desafioCategoriaConverter);

  const allFilters = [...BASE_FILTER, ...filters];
  const qAll = generateQuery(projetosCollectionReference, { filters: allFilters });
  const qPaginated = generateQuery(projetosCollectionReference, {
    sorting,
    filters: allFilters,
    pagination,
    paginationCursors
  });

  // eslint-disable-next-line compat/compat
  return Promise.all([getDocs(qPaginated), getCountFromServer(qAll)]);
}

export type TCategoriaDeDesafioFormFields = Omit<
  TDesafioCategoria,
  "id" | "createdAt" | "updatedAt" | "createdBy" | "updatedBy" | "deletedAt"
> &
  Partial<Pick<TDesafioCategoria, "id">>;

type TTCategoriaDeDesafioDatabaseFields = Pick<TAuditForm, "createdAt" | "updatedAt">;

export type TCategoriaDeDesafioForm = TCategoriaDeDesafioFormFields & TTCategoriaDeDesafioDatabaseFields;

/**
 * Start a new categoria de desafio for a empresa
 * @param empresaId - Empresa id to start a new tipo de desafio
 * @param values - tipo de desafio form fields
 * @returns - tipo de desafio document reference
 */
export function addCategoriaDeDesafio(empresaId: TEmpresa["id"], values: TCategoriaDeDesafioFormFields) {
  const categoriaDeDesafioCollectionReference = getDesafioCategoriasCollectionReference(empresaId);

  return addDoc(categoriaDeDesafioCollectionReference, {
    ...values,
    deletedAt: null
  });
}
/**
 * Get categoria de desafio reference
 * @param empresaId - Empresa id to start a new tipo de desafio
 * @param categoriaDeDesafioId - tipo de desafio form fields
 * @returns - tipo de desafio document reference
 */
function getCategoriaDesafioDocumentReference(
  empresaId: TEmpresa["id"],
  categoriaDeDesafioId: TDesafioCategoria["id"]
) {
  const categoriaDeDesafioCollectionReference = getDesafioCategoriasCollectionReference(empresaId);
  return doc(categoriaDeDesafioCollectionReference, categoriaDeDesafioId);
}

/**
 * updated categoria de desafio
 * @param empresaId - Empresa id to start a new tipo de desafio
 * @param categoriaDeDesafioId - tipo de desafio form fields
 * @param values - values to update
 * @returns - tipo de desafio delete
 */
export function updatedCategoriaDeDesafio(
  empresaId: TEmpresa["id"],
  categoriaDeDesafioId: TDesafioCategoria["id"],
  values: TCategoriaDeDesafioFormFields
) {
  const currentUserId = getCurrentUser()?.uid;
  const tipoDocumentReference = getCategoriaDesafioDocumentReference(empresaId, categoriaDeDesafioId);
  return updateDoc(tipoDocumentReference, {
    ...values,
    updatedAt: serverTimestamp(),
    updatedBy: currentUserId
  });
}

/**
 * delete categoria de desafio
 * @param empresaId - Empresa id to start a new tipo de desafio
 * @param categoriaDeDesafioId - tipo de desafio form fields
 * @returns - tipo de desafio delete
 */
export function deleteCategoriaDeDesafio(empresaId: TEmpresa["id"], categoriaDeDesafioId: TEmpresa["id"]) {
  const tipoDocumentReference = getCategoriaDesafioDocumentReference(empresaId, categoriaDeDesafioId);
  return updateDoc(tipoDocumentReference, {
    deletedAt: serverTimestamp()
  });
}
