import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getConvitesEmpresaParticipando, getConvitesEmpresaProjeto } from "@/modules/empresas/firestore";

type TGetComitesInovacaoResponse = Awaited<ReturnType<typeof getConvitesEmpresaProjeto>>;
const QUERY_KEY: string = "convite-list" as const;
/**
 * Get comites de inovação query hook
 * @param activeEmpresa - id da empresa
 * @param idProjeto - id do projeto
 * @param convidado - convidado do projeto
 * @returns Comites de inovação query hook
 */
// prettier-ignore
function useGetConvite(activeEmpresa: TEmpresa["id"] | undefined, idProjeto: TProjeto["id"] | undefined, convidado: boolean | undefined) {
  return useQuery<TGetComitesInovacaoResponse, FirestoreError, TConvite[]>({
    queryKey: [QUERY_KEY, idProjeto, convidado, activeEmpresa],
    enabled: !!activeEmpresa && !!idProjeto,
    queryFn() {
      if (!activeEmpresa) {
        throw new Error("Empresa não autenticada");
      }

      if (!idProjeto) {
        throw new Error("Projeto não autenticada");
      }
      return convidado ? getConvitesEmpresaParticipando(activeEmpresa, idProjeto) : getConvitesEmpresaProjeto(activeEmpresa, idProjeto);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((document_) => document_.data());
    }
  });
}

export default useGetConvite;
