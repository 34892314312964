import { Box, Card, Group, LoadingOverlay, Space, Text } from "@mantine/core";
import useQueryQuestionario from "@/modules/grupos-diagnostico/components/grupos-diagnostico-form/useQueryQuestionario";
import QuestionarioItem from "../questionario-item";

// prettier-ignore
const QuestionariosList = () => {
  const { data, isFetching } = useQueryQuestionario();

  if (isFetching) {
    return (
      <Box pos="relative">
        <LoadingOverlay visible={isFetching} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
      </Box>
    );
  }

  return (
    <Card withBorder shadow="md" style={{ overflow: "auto" }}>
      <Space h="md" />
      <Group justify={"space-between"} align="center">
        <Text size="xl">Questionários aplicados</Text>
      </Group>
      <Space h="md" />
      {data && data.length > 0 ? data?.map((item) => <QuestionarioItem key={item.id} questionario={item} />) : <Group justify={"center"}>
        <Text fw={700}>Nenhum Questionário encontrado!</Text>
      </Group>}
    </Card>
  );
};

export default QuestionariosList;
