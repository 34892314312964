import { Card, Group, Skeleton, Stack } from "@mantine/core";
import { Query } from "firebase/firestore";
import { useEffect } from "react";
import NoData from "@/components/no-data";
import { captureException } from "@/services/log";
import AtividadeItem from "./AtividadeItem";
import useGetAllMarcos from "./useGetAllMarcos";

const AtividadeItemLoading = () => (
  <Card withBorder>
    <Group gap="lg" wrap="nowrap">
      <Skeleton height={34} width={34} radius="xl" />
      <Stack gap={2}>
        <Skeleton height={16} width={80} />
        <Skeleton height={16} width={110} />
      </Stack>
      <Stack gap={2}>
        <Skeleton height={16} width={70} />
        <Skeleton height={16} width={130} />
      </Stack>
      <Stack gap={2}>
        <Skeleton height={16} width={70} />
        <Skeleton height={16} width={270} />
      </Stack>
      <Stack gap={2} style={{ flex: 1 }}>
        <Skeleton height={16} width={70} />
        <Skeleton height={16} width={130} />
      </Stack>
    </Group>
  </Card>
);

const MIN_LOADING_ITEMS = 2;
const MAX_LOADING_ITEMS = 7;
const generateRandomNumberBetween = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1) + min);
// prettier-ignore
const AtividadesList = ({ query, activeTab }: { query: Query; activeTab: string | null }) => {
  const { data: marcos, error, isLoading, isFetched } = useGetAllMarcos(query);
  useEffect(() => {
    if (error) {
      captureException(error, true);
    }
  }, [error]);

  if (isFetched && marcos && marcos.filter(item => activeTab === "continuar-atividade" || activeTab === "aguardando-participacao" ? item.finishedAt === null : item).length === 0) {
    return <NoData title="Nenhuma atividade encontrada" />;
  }

  if (isLoading) {
    const random = generateRandomNumberBetween(MIN_LOADING_ITEMS, MAX_LOADING_ITEMS);
    return (
      <Stack gap="sm">
        {Array.from({ length: random }).map((_, index) => (
          <AtividadeItemLoading key={index} />
        ))}
      </Stack>
    );
  }

  return <Stack gap="sm">{marcos && marcos.filter(item => activeTab === "continuar-atividade" || activeTab === "aguardando-participacao" ? item.finishedAt === null : item).map((marco) => <AtividadeItem key={marco.id} marco={marco} />)}</Stack>;
};

export default AtividadesList;
