import { Flex, Text } from "@mantine/core";
import { BarDatum, ResponsiveBar } from "@nivo/bar";
// prettier-ignore
const keys = ["Empresas inscritas por dia"];
// prettier-ignore
const defs = [
  {
    id: "dots",
    type: "patternDots",
    background: "inherit",
    color: "#38bcb2",
    size: 4,
    padding: 1,
    stagger: true
  },
  {
    id: "lines",
    type: "patternLines",
    background: "inherit",
    color: "#eed312",
    rotation: -45,
    lineWidth: 6,
    spacing: 10
  }
]
// prettier-ignore
const fill = [
  {
    match: {
      id: "fries"
    },
    id: "dots"
  },
  {
    match: {
      id: "sandwich"
    },
    id: "lines"
  }
]

const MAGIC_NUMBER = 1.6;

// prettier-ignore
const AreaRelatorio = ({ empresas }: { empresas: TConvite[] }) => {
  const formatData = formatArray(empresas);

  return <>
    <Text size={"md"} fw={700}>Empresas inscritas por dia</Text>
    <Flex h={400} justify="center">
      <ResponsiveBar
        data={formatData}
        keys={keys}
        indexBy="country"
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        colorBy="indexValue"
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={{ scheme: "nivo" }}
        defs={defs}
        fill={fill}
        borderColor={{
          from: "color",
          modifiers: [
            [
              "darker",
              MAGIC_NUMBER
            ]
          ]
        }}
        axisTop={null}
        axisRight={null}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [
            [
              "darker",
              MAGIC_NUMBER
            ]
          ]
        }}
        role="application"
        ariaLabel="Nivo bar chart demo"
      // barAriaLabel={e => e.id + ": " + e.formattedValue + " in country: " + e.indexValue}
      />
    </Flex>
  </>

};

export default AreaRelatorio;

/**
 * Formata um array de convites em um array de dados compatíveis com o tipo BarDatum.
 * @param originalArray originalArray O array de convites original.
 * @returns O array de dados formatado.
 */
// prettier-ignore
function formatArray(originalArray: TConvite[]): BarDatum[] {
  // Objeto para armazenar a contagem de empresas por data
  // eslint-disable-next-line unicorn/no-array-reduce
  const dateCountMap: Record<string, number> = originalArray.reduce((accumulator: Record<string, number>, object: TConvite) => {
    // Extrai a data do campo createdAt e formata como DD/MM/YYYY
    const date = new Date(object.createdAt);
    const formattedDate: string = `${String(date.getDate()).padStart(PAD_NUMBER, '0')}/${String(date.getMonth() + 1).padStart(PAD_NUMBER, '0')}/${date.getFullYear()}`;

    // Se a data não estiver no acumulador, inicializa com 0
    if (!accumulator[formattedDate]) {
      accumulator[formattedDate] = 0;
    }

    // Incrementa a contagem para a data
    accumulator[formattedDate]++;

    return accumulator;
  }, {} as Record<string, number>);

  // Constrói o array resultante com a contagem de empresas por dia
  const result: BarDatum[] = Object.entries(dateCountMap).map(([date, count]) => {
    return {
      "country": date,
      "Empresas inscritas por dia": count
    };
  });

  return result;
}

const PAD_NUMBER = 2;
