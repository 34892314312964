import { serverTimestamp } from "firebase/firestore";
import { ObjectSchema } from "yup";
import schema from "@/schema";
import auditSchema from "@/schema/audit-schema";
import { TCampanhaRegulamentoIdeiasForm } from "./firestore";

const quantidadeMoedas = schema
  .number()
  .transform((value: number | string | null) => (value ? Number(value) : value))
  .required()
  .defined()
  .min(0)
  .default(0);

const campanhaRegulamentoIdeiasSchema: ObjectSchema<TCampanhaRegulamentoIdeiasForm> = auditSchema
  .clone()
  // eslint-disable-next-line unicorn/prefer-spread
  .concat(
    schema.object({
      // ideias: schema.array().optional(),
      descricao: schema
        .string()
        .meta({
          placeholder: "Escreva sobre a campanha"
        })
        .required()
        .defined()
        .default("")
        .label("Descrição da campanha"),
      dataInicial: schema
        .date()
        .meta({
          label: "Período de Recepção das Ideais"
        })
        .required()
        .defined()
        .label("Data Inicial"),
      dataFinal: schema.date().required().defined().label("Data Final"),
      setores: schema
        .array()
        .of(schema.string().required().defined())
        .meta({
          label: "Setores da empresa que vão participar da campanha"
        })
        .required()
        .defined()
        .default([])
        .min(1)
        .label("Setores"),

      quantidadeGanhoNoCadastro: quantidadeMoedas.clone().label("Cadastro de ideias"),
      quantidadeGanhoNaAprovacao: quantidadeMoedas.clone().label("Aprovação de ideias"),
      quantidadeGanhoNaImplementacao: quantidadeMoedas.clone().label("Implementação de ideias"),
      quantidadeGanhoNaParticipacaoEImplementacaoIdeiaPropria: quantidadeMoedas
        .clone()
        .label("Participação na implementação de ideia própria"),
      quantidadeGanhoNaParticipacaoEImplementacaoIdeiaTerceiro: quantidadeMoedas
        .clone()
        .label("Participação na implementação de ideia de terceiros"),

      deletedAt: schema.mixed<ReturnType<typeof serverTimestamp>>().defined().nullable().default(null)
    })
  )
  .noUnknown()
  .strict()
  .label("Campanha de incentivo");

export default campanhaRegulamentoIdeiasSchema;
