import { Card, Title } from "@mantine/core";
import DesafioComentariosList from "@/modules/desafio-comentarios/components/desafio-comentarios-list";

const DesafioComentariosCard = ({ desafio }: { desafio: TDesafio }) => {
  return (
    <Card withBorder>
      <Title order={4}>Comentários sobre o desafio</Title>
      <DesafioComentariosList desafio={desafio} />
    </Card>
  );
};

export default DesafioComentariosCard;
