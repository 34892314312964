import {
  FirestoreDataConverter,
  addDoc,
  collection,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  where
} from "firebase/firestore";
import { getCurrentUser } from "@/services/auth";
import { getComiteInovacaoDocumentReference } from "../comite-inovacao/firestore";

type TComiteInovacaoActivityFormFields = TComiteInovacaoActivityForm & TAuditForm;
type TComiteInovacaoActivityDocument = TComiteInovacaoActivityFormFields & TAuditDocument;

const comiteInovacaoActivityConverter: FirestoreDataConverter<TComiteInovacaoActivity> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const { createdAt, updatedAt, ...document } = snap.data() as TComiteInovacaoActivityDocument;

    const data: TComiteInovacaoActivity = {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      refPath: snap.ref.path
    };

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }
    return data;
  }
};

/**
 * Get comiteInovacaoActivities collection reference from empresaId and comiteInovacaoId
 * @param empresaId - Empresa id to get the comiteInovacaoActivities collection reference
 * @param comiteInovacaoId - ComiteInovacao id to get the comiteInovacaoActivities collection reference
 * @returns - ComiteInovacaoActivities collection reference
 */
function getComiteInovacaoActivitiesCollectionReference(
  empresaId: TEmpresa["id"],
  comiteInovacaoId: TComiteInovacao["id"]
) {
  const comiteInovacaoDocumentReference = getComiteInovacaoDocumentReference(empresaId, comiteInovacaoId);
  return collection(comiteInovacaoDocumentReference, "comiteInovacaoActivities");
}

/**
 * Add a comiteInovacaoActivity
 * @param empresaId - Empresa id to get the comiteInovacaoActivities collection reference
 * @param comiteInovacaoId - ComiteInovacao id to get the comiteInovacaoActivities collection reference
 * @param data - ComiteInovacaoActivity data
 * @returns - ComiteInovacaoActivity document reference
 */
export function addComiteInovacaoActivity(
  empresaId: TEmpresa["id"],
  comiteInovacaoId: TComiteInovacao["id"],
  data: TComiteInovacaoActivityForm
) {
  const comiteInovacaoActivitiesCollectionReference = getComiteInovacaoActivitiesCollectionReference(
    empresaId,
    comiteInovacaoId
  );

  const currentUser = getCurrentUser();

  if (!currentUser) {
    throw new Error("The current user is not defined");
  }

  return addDoc(comiteInovacaoActivitiesCollectionReference, {
    ...data,
    createdAt: serverTimestamp(),
    createdBy: currentUser.uid
  });
}

/**
 * Get comiteInovacaoActivities by type
 * @param empresaId  - Empresa id to get the comiteInovacaoActivities
 * @param comiteInovacaoId - ComiteInovacao id to get the comiteInovacaoActivities
 * @param types - ComiteInovacaoActivity types to get the comiteInovacaoActivities
 * @returns - ComiteInovacaoActivities query snapshot
 */
export function getComiteInovacaoActivitiesByType(
  empresaId: TEmpresa["id"],
  comiteInovacaoId: TComiteInovacao["id"],
  ...types: TComiteInovacaoActivity["type"][]
) {
  const comiteInovacaoActivitiesCollectionReference = getComiteInovacaoActivitiesCollectionReference(
    empresaId,
    comiteInovacaoId
  );

  const q = query(
    comiteInovacaoActivitiesCollectionReference,
    where("type", "in", types),
    orderBy("createdAt", "desc")
  ).withConverter(comiteInovacaoActivityConverter);

  return getDocs(q);
}
