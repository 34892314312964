import { subject } from "@casl/ability";
import { Text, Group } from "@mantine/core";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { ability } from "@/components/can/Can";
import ContextMenuRow from "./ContextMenuRow";
import IconStatusEmpresa from "../icons-status";

const columnHelper = createColumnHelper<TEmpresa>();

const columns: ColumnDef<TEmpresa>[] = [
  columnHelper.accessor("status", {
    header: "Status",
    enableSorting: true,
    cell: (properties) => {
      // eslint-disable-next-line unicorn/prefer-logical-operator-over-ternary
      return <IconStatusEmpresa status={properties.getValue() ? properties.getValue() : "ATIVO"} />;
    }
  }),
  columnHelper.accessor("nomeFantasia", {
    header: "Nome Fantasia",
    enableSorting: true
  }),
  columnHelper.accessor("razaoSocial", {
    header: "Razão Social",
    enableSorting: true
  }),
  columnHelper.accessor("cnpj", {
    header: "CNPJ",
    enableSorting: true
  }),
  columnHelper.accessor("porte", {
    header: "Porte",
    enableSorting: true,
    cell: (properties) => {
      const porte = properties.getValue()?.replace("EMPRESA", "").split("_").join(" ").toLocaleLowerCase();
      return <Text tt="capitalize">{porte}</Text>;
    }
  }),
  columnHelper.accessor("responsavel", {
    header: "Responsável",
    enableSorting: true,
    cell: (properties) => properties.getValue()?.nome
  }),
  columnHelper.display({
    id: "actions",
    cell: (properties) => {
      const empresa = subject("TEmpresa", properties.row.original);
      const canIUpdate = ability.can("update", empresa);
      const canIDelete = ability.can("delete", empresa);
      const hasActions = canIDelete || canIUpdate;
      if (hasActions) {
        return (
          <Group justify="center" gap={2}>
            <ContextMenuRow empresa={empresa} />
          </Group>
        );
      }

      return null;
    }
  })
];

export default columns;
