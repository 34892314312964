import { Card, Flex, Group, Loader, Space, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { getRespostasMediaEmpresa } from "@/modules/analisar-respostas/firestore";
import { getConvites } from "@/modules/questionario-colaborador/firestore";
import { getRepostasArrayRelatorio } from "@/modules/responder-questionario/firestore";
import useUserStore from "@/modules/users/store";
import GetEmpresas from "../table-colaboradores/useGetEmpresa";

interface NColaboradores {
  nColaboradores: number;
}

interface StatusResposta {
  idQuestionario: string;
  idEmpresa: string;
}

const message = "";

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
// prettier-ignore
const CardEmpresa = ({ empresas, questionario }: { empresas: TConvite[], questionario: string }) => {
  const empresaAtivaId = useUserStore((state) => state.activeEmpresaId);
  const [newEmpresa, setNewEmpresa] = useState<(TEmpresa & NColaboradores)[]>([]);
  const [loading, setLoading] = useState(false);
  const isSmMarches = useMediaQuery("(max-width: 48em)");
  const uniqueArray = empresas.filter((item, index, self) =>
    index === self.findIndex((t) => (
      t.idEmpresa === item.idEmpresa
    ))
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadData();
      } catch (error) {
        console.error(message, error);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, [])

  const loadData = async () => {
    if (empresas && empresas?.length > 0) {
      setLoading(true)
      const newEmpresa = await GetEmpresas([...uniqueArray, ...empresas.map(item => ({ ...item, idEmpresa: item.idEmpresaColaboradorConvidado }))]);
      let newRespostas = [] as TResposta[]
      let newConvites = [] as TConvite[]
      for (const empresa of newEmpresa) {
        const convites = await getConvites(empresa.id, questionario);
        const respostas = await getRepostasArrayRelatorio(empresaAtivaId || "", empresa.id, questionario);
        const aux = respostas.docs.map((desafio) => desafio.data());
        const newFormat = convites.docs.map((desafio) => desafio.data());
        newConvites = [...newConvites, ...newFormat]
        newRespostas = [...newRespostas, ...aux]
      }

      let newArray = [] as [];
      for (const item of newEmpresa) {
        const newData = await getRespostasMediaEmpresa(item.id);
        const format = newData.docs.map((resposta) => resposta.data()) || [] as TResposta[] & StatusResposta[];
        newArray = [...newArray, ...format as TResposta[] & StatusResposta[] as []]
      }

      setNewEmpresa(newEmpresa.map(item => {
        return {
          ...item,
          nColaboradores: removeDuplicates(newConvites.filter(row => row.idEmpresa === item.id && !row.cancelado)).length + newRespostas.filter(row => row.idEmpresa === item.id && row.idRespondente === "" && !row.pausado).length,
        }
      }))
      setLoading(false)
    }
  }

  const totalColaboradores = newEmpresa.reduce((sum, empresa) => {
    return sum + empresa.nColaboradores;
  }, 0);

  return (
    <Group justify={"space-around"}>
      <Card style={{ width: isSmMarches ? "100%" : "30%", height: "150px", display: "flex" }} withBorder>
        <Flex align={"center"} direction="column" >
          <Text size="xl" fw={700}>Empresas inscritas</Text>
          <Space h={"md"} />
          <Text size="xl" fw={500}>{[...empresas, ...uniqueArray]?.length}</Text>
        </Flex>
      </Card>
      <Card style={{ width: isSmMarches ? "100%" : "30%", height: "150px" }} withBorder>
        <Flex align={"center"} direction="column">
          <Text size="xl" fw={700}>Colaboradores Convidados</Text>
          <Space h={"md"} />
          {loading ? <Group align={"center"} justify={"center"}>
            <Loader color="blue" />
          </Group> : <Text size="xl" fw={500}>{totalColaboradores}</Text>}
        </Flex>
      </Card>
    </Group >
  )
};

export default CardEmpresa;

// prettier-ignore
const removeDuplicates = (data: TConvite[]) => {
  const uniqueIds = new Set();
  return data.filter(item => {
    if (!uniqueIds.has(item.idColaboradorConvidado)) {
      uniqueIds.add(item.idColaboradorConvidado);
      return true;
    }
    return false;
  });
};
