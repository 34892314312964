import { Button, Card, Group, ScrollArea, Space, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { useEffect, useState } from "react";
import EvidenciaEnviadaCard from "@/modules/cadastrar-evidencias/components/EvidenciaEnviadaCard";
import { getEvidenciasByQuestionarioQuery } from "@/modules/cadastrar-evidencias/firestore";
import useQueryConvite from "@/modules/diagnosticos/components/diagnostico-list/useQueryConvite";
import RelatorioComponent from "@/modules/relatorio/components/relatorio-item";
import useQueryQuestionarioConvite from "@/modules/responder-questionario-list/components/questionario-list/useQueryQuestionarioConvite";
import useUserStore from "@/modules/users/store";

export const MAX_EVIDENCIAS = 5;
export const MAX_FILE_SIZE = 20_000_000;

const CadastrarEvidenciasPage = () => {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const reloadConvites = useUserStore((state) => state.reloadConvites);
  const [values, setValues] = useState({ questionario: "" });
  const [loading, setLoading] = useState(true);
  const [empresaId, setEmpresaId] = useState("");
  const [projetoId, setProjetoId] = useState("");
  const [evidencias, setEvidencias] = useState<TEvidencia[]>([]);
  const [refresh, setRefresh] = useState(false);

  const { data: convite } = useQueryConvite();
  const { data: questionariosConvite } = useQueryQuestionarioConvite({ data: convite && convite[0] });

  useEffect(() => {
    const evidenciasSnapshot = getEvidenciasByQuestionarioQuery(
      empresaId,
      projetoId,
      values.questionario,
      activeEmpresaId || ""
    );
    void evidenciasSnapshot.then((document) => {
      setEvidencias(
        document.docs.map((document_) => document_.data()).sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
      );
      return null;
      // console.log("Evidencias:", document.docs[0].data());
    });
  }, [values, refresh]);

  useEffect(() => {
    if (loading && questionariosConvite && questionariosConvite[0]) {
      setLoading(false);
      setProjetoId(questionariosConvite[0]?.idProjeto);
      if (convite && convite[0]) setEmpresaId(convite[0]?.idEmpresa);
    }
  }, [questionariosConvite]);

  useEffect(() => {
    setRefresh(!refresh);
  }, [reloadConvites]);

  return (
    <>
      <RelatorioComponent
        setValues={setValues}
        questionario={values.questionario}
        idEmpresa={empresaId}
        idProjeto={projetoId}
      />
      <Space h={"xl"} />
      {projetoId && values.questionario !== "" && values.questionario !== null ? (
        <Card withBorder shadow="md" style={{ flex: 1 }}>
          <Group justify={"space-between"}>
            <Text size="xl">Cadastro de Evidências</Text>
            <Button
              onClick={handleClick(
                values.questionario,
                empresaId,
                projetoId,
                activeEmpresaId,
                evidencias.filter((evidencia) => !evidencia.externo).length
              )}
              color="rgba(8, 68, 244, 1)"
            >
              Cadastrar evidência
            </Button>
          </Group>
          <Space h="md" />
          <ScrollArea w="100%">
            {evidencias?.length ? (
              evidencias
                .filter((evidencia) => evidencia.idQuestionario === values.questionario)
                .map((evidencia) => <EvidenciaEnviadaCard key={evidencia.id} evidencia={evidencia} />)
            ) : (
              <Group align={"center"} justify={"center"}>
                <Space h="md" />
                <Text fw={"bold"}>Nenhuma evidência cadastrada!</Text>
                <Space h="md" />
              </Group>
            )}
          </ScrollArea>
        </Card>
      ) : (
        <Group justify={"center"}>
          <Text fw={700}>Selecione um questionário para cadastrar evidências</Text>
        </Group>
      )}
      <Space h={"xl"} />
    </>
  );
};

export default CadastrarEvidenciasPage;

/**
 * Handles the click event on the button.
 * @param id - The id questions.
 * @param idEmpresa - The id company.
 * @param idProjeto - The id project.
 * @param idEmpresaColaborador - id da Empresa colaborador
 * @param totalEvidencias - total de evidencias
 * @returns - The return the click.
 */
function handleClick(
  id: string = "",
  idEmpresa: string = "",
  idProjeto: string = "",
  idEmpresaColaborador: string = "",
  totalEvidencias: number
) {
  return (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    modals.openContextModal({
      size: "xl",
      innerProps: {
        idQuestionario: id,
        idEmpresa,
        idProjeto,
        idEmpresaColaborador,
        totalEvidencias
      },
      modal: "cadastrar-evidencias-modal"
    });
  };
}
