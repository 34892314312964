import { useForm, yupResolver } from "@mantine/form";
import { SchemaObjectDescription } from "yup";
import { TEmpresaForm } from "@/modules/empresas/firestore";
import empresaSchema, { EmpresaInputs } from "@/modules/empresas/schema/empresa-schema";

export const useAutocadastroForm = () => {
  const empresaSchemaDescriptor = empresaSchema.describe();
  const enderecoDescriptor = empresaSchema.fields.endereco.describe() as SchemaObjectDescription;
  const responsavelDescriptor = empresaSchema.fields.responsavel.describe() as SchemaObjectDescription;
  const defaultEmpresaValues = empresaSchema.getDefault() as TEmpresaForm;

  const form = useForm<EmpresaInputs>({
    transformValues: (values) => empresaSchema.cast(values),
    validate: yupResolver(empresaSchema),
    initialValues: {
      ...defaultEmpresaValues,
      createdBy: "autocadastro",
      updatedBy: "autocadastro",
      role: "CONVIDADA",
      setores: ["Diagnóstico"]
    }
  });

  return {
    form,
    empresaDescriptor: empresaSchemaDescriptor,
    enderecoDescriptor,
    responsavelDescriptor
  };
};
