import { Anchor, Card, Flex, Grid, Group, Space, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconEye } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import useGetConvites from "@/modules/questionario-colaborador/components/questionario-item/useGetConvites";
import { getRepostasArrayNewUser } from "@/modules/responder-questionario/firestore";
import RespostaComponente from "./RespostaComponente";
import TextAcesso from "./TextAcesso";
import useGetProjetoItem from "./useGetProjeto";
const message = "";

const MAGIC_NUMBER = 1000;

// prettier-ignore
const QuestionarioItem = ({ questionario }: { questionario: TQuestionario }) => {
  const { data } = useGetProjetoItem({ idProjeto: questionario.idProjeto });
  const { idEmpresa: id } = useParams<{ idEmpresa: string; idProjeto: string }>();
  const { data: dataConvites } = useGetConvites({ id: questionario.id });
  const [userExterno, setUserExterno] = useState(0);
  const isSmMarches = useMediaQuery("(max-width: 48em)");

  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadData();
      } catch (error) {
        console.error(message, error);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, []);

  const loadData = async () => {
    const respostas = await getRepostasArrayNewUser(id || "", questionario.id);
    const format: TResposta[] = respostas.docs.map((resposta) => resposta.data());
    setUserExterno(format.filter((item) => item.idRespondente === "").length);
  };

  return (
    <>
      <Space h="md" />
      <Card withBorder shadow="md" style={{ flex: 1, width: isSmMarches ? MAGIC_NUMBER : "100%" }}>
        <Grid align="center">
          <Grid.Col span={2}>
            <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
              <Text fw={300} fz="xs">
                Nome do projeto
              </Text>
              <Text fw={500} fz="md">
                {data?.nome}
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={2}>
            <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
              <Text fw={300} fz="xs">
                Nome do questionário
              </Text>
              <Text fw={500} fz="md">
                {questionario.nome}
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={2}>
            <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
              <Text fw={300} fz="xs">
                Colaboradores convidados
              </Text>
              <Text fw={500} fz="md">
                {(dataConvites?.filter((item) => item.idEmpresa === id && !item.cancelado)?.length || 0) + userExterno}
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={2}>
            <TextAcesso id={questionario?.id} />
          </Grid.Col>
          <Grid.Col span={2}>
            <RespostaComponente id={questionario?.id} fw={500} fz="md" />
          </Grid.Col>
          <Grid.Col span={2}>
            <Group align={"center"} justify={"center"}>
              <Anchor component={NavLink} to={`/questionarioInfo/${id}/${questionario.idProjeto}/${questionario?.id}/`}>
                <IconEye color="rgba(57, 106, 249, 1)" />
              </Anchor>
            </Group>
          </Grid.Col>
        </Grid>
      </Card>
    </>
  );
};

export default QuestionarioItem;
