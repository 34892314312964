import { Button } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconPlayerPause } from "@tabler/icons-react";
import { MutationOptions, useMutation, useQueryClient } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { QUERY_KEY, pauseComiteDeInovacao } from "@/modules/comite-inovacao/firestore";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";

type TPauseComiteDeInovacaoResponse = Awaited<ReturnType<typeof pauseComiteDeInovacao>>;
type TPauseComiteDeInovacaoMutationOptions = Omit<
  MutationOptions<TPauseComiteDeInovacaoResponse, FirestoreError>,
  "mutationFn"
>;
/**
 * Pause comite de inovação mutation hook
 * @param comite - Comite de inovação
 * @param [options] = {} - Mutation options
 * @returns Pause comite de inovação mutation
 */
function usePauseComiteInovacaoMutation(comite: TComiteInovacao, options: TPauseComiteDeInovacaoMutationOptions = {}) {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  return useMutation<TPauseComiteDeInovacaoResponse, FirestoreError>({
    mutationFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return pauseComiteDeInovacao(activeEmpresaId, comite.id);
    },
    ...options
  });
}

const PausarComiteInovacaoButton = ({ comite }: { comite: TComiteInovacao }) => {
  const queryClient = useQueryClient();
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  const { mutate, isPending } = usePauseComiteInovacaoMutation(comite, {
    onSuccess() {
      showNotification({
        color: "green",
        message: "Comitê pausado com sucesso!",
        autoClose: 3500
      });

      // eslint-disable-next-line compat/compat
      return Promise.all([
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY]
        }),
        queryClient.invalidateQueries({
          queryKey: ["empresas", { id: activeEmpresaId }, "comitesInovacao", "ativo"]
        }),
        queryClient.invalidateQueries({
          queryKey: ["empresas", { id: activeEmpresaId }, "comitesInovacao", { id: comite.id }]
        })
      ]);
    },
    onError(error) {
      captureException(error, true);
    }
  });

  /**
   * Handle click on button to pause comite de inovação
   */
  function onClick() {
    mutate();
  }

  return (
    <Button color="yellow" leftSection={<IconPlayerPause />} onClick={onClick} loading={isPending}>
      Pausar
    </Button>
  );
};

export default PausarComiteInovacaoButton;
