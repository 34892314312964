import {
  ActionIcon,
  Alert,
  Box,
  Button,
  Group,
  LoadingOverlay,
  NumberInput,
  Paper,
  Select,
  Space,
  Stack,
  Switch,
  Textarea,
  TextInput,
  Tooltip
} from "@mantine/core";
import { IconAlertCircle, IconMarkdown, IconMarkdownOff } from "@tabler/icons-react";
import { useCallback, useEffect, useState } from "react";
import Markdown from "@/components/markdown/Markdown";
import useUserStore from "@/modules/users/store";
import { extractTextInputPropertiesFromFormSchema } from "@/utils/form";
import useGrupoForm from "./useGruposForm";
import useQueryQuestionario from "./useQueryQuestionario";
import { TGruposDiagnosticoForm } from "../../firestore";
import { useGrupoFeedbacks } from "../../hooks/useGrupoFeedbacks";
import gruposDiagnosticoSchema from "../../schema/grupos-diagnostico-schema";
import GrupoFeedbacksCard from "../grupo-feedbacks/GrupoFeedbacksCard";

const GruposDiagnosticoForm = ({ grupo, onSuccess }: { grupo: TGruposDiagnosticoForm; onSuccess: () => void }) => {
  const [displayMarkdown, setDisplayMarkdown] = useState(false);

  const { form, isPending, onSubmit } = useGrupoForm(grupo, {
    onSuccess
  });

  const feedbacksController = useGrupoFeedbacks(grupo.feedbacks);
  const projeto = useUserStore((state) => state.activeProjeto);
  const { data: questionarios, isFetching } = useQueryQuestionario();
  const questionariosArray = questionarios || [];
  const fieldsDescriptor = gruposDiagnosticoSchema.describe();

  const genericError = form.errors[""];

  useEffect(() => {
    form.setFieldValue("feedbacks", feedbacksController.feedbacks);
  }, [feedbacksController.feedbacks]);

  const handleMarkdownToggle = useCallback(() => {
    setDisplayMarkdown((previous) => !previous);
  }, []);

  if (isFetching) {
    return (
      <Box pos="relative">
        <LoadingOverlay visible={isFetching} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
      </Box>
    );
  }

  const questionarioSelected = questionariosArray.find((q) => q.id === form.getValues().idQuestionario);

  return (
    <form onSubmit={onSubmit}>
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />
        <Stack gap="sm" mb="lg">
          <TextInput
            type="text"
            style={{ flex: 1 }}
            {...form.getInputProps("nome")}
            {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "nome")}
          />
          <Stack>
            {displayMarkdown ? (
              <Paper withBorder p="md" ta="center">
                <Markdown text={form.getValues().descricao} />
              </Paper>
            ) : (
              <Textarea
                autosize
                style={{ flex: 1 }}
                {...form.getInputProps("descricao")}
                {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "descricao")}
              />
            )}
            <Group justify="end">
              <Tooltip label="Mostrar/ocultar texto processado">
                <ActionIcon variant="default" onClick={handleMarkdownToggle}>
                  {displayMarkdown ? <IconMarkdown /> : <IconMarkdownOff />}
                </ActionIcon>
              </Tooltip>
            </Group>
          </Stack>
          <Select
            data={questionariosArray
              .filter((item) => (projeto ? item.idProjeto === projeto : item))
              .map((item) => ({ value: item.id || "", label: item.nome || "" }))}
            {...form.getInputProps("idQuestionario")}
            {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "idQuestionario")}
            style={{ flex: 1 }}
          />
          <Group>
            <NumberInput
              style={{ flex: 1 }}
              {...form.getInputProps("ordemQuestionario")}
              {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "ordemQuestionario")}
            />
            <Textarea
              autosize
              style={{ flex: 1 }}
              disabled={true}
              {...form.getInputProps("pesoNota")}
              {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "pesoNota")}
            />
          </Group>
          <Space h="md" />

          {questionarioSelected && (
            <GrupoFeedbacksCard controller={feedbacksController} questionario={questionarioSelected} />
          )}

          <Space h="md" />
          <Switch
            {...form.getInputProps("radar")}
            {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "radar")}
            defaultChecked={form?.getInputProps("radar")?.value as boolean}
          />
        </Stack>
      </Box>
      <Group justify={genericError ? "space-between" : "flex-end"} mt="md">
        {genericError && (
          <Alert icon={<IconAlertCircle size="1rem" />} color="red">
            {genericError}
          </Alert>
        )}
        <Button type="submit" loading={isPending}>
          Salvar
        </Button>
      </Group>
    </form>
  );
};

export default GruposDiagnosticoForm;
