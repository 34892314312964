import { Table } from "@mantine/core";

interface NColaboradores {
  nColaboradores: number;
  nRespostas: number;
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
// prettier-ignore
const TableColaboradoresItem = ({ item }: { item: TEmpresa & NColaboradores }) => {
  return (
    <Table.Tr key={item?.nomeFantasia}>
      <Table.Td>{item?.nomeFantasia}</Table.Td>
      <Table.Td>{item?.cnpj}</Table.Td>
      <Table.Td>{item?.responsavel.nome}</Table.Td>
      <Table.Td>{item?.responsavel?.telefone}</Table.Td>
      <Table.Td>{item?.porte}</Table.Td>
      <Table.Td>{item?.endereco?.estado}</Table.Td>
      <Table.Td>{item?.nColaboradores}</Table.Td>
      <Table.Td>{item?.nRespostas}</Table.Td>
    </Table.Tr>
  )
};

export default TableColaboradoresItem;
