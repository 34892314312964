import { Group, Select } from "@mantine/core";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import useUserStore from "@/modules/users/store";
import getEmpresasByQuestionario from "./useGetEmpresasByQuestionario";
import getQuestionario from "./useGetQuestionarios";

interface Values {
  projeto: string;
  questionario: string;
  idEmpresa?: string;
  idProjeto?: string;
  empresaEvidencia: string;
}

interface ComboboxItem {
  value: string;
  label: string;
}

const message = "Ocorreu um erro ao carregar os dados:";
const array = [] as [];

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
const SelecionaEvidencias = ({
  setValues,
  questionario,
  idEmpresa,
  idProjeto,
  empresaEvidencia
}: {
  setValues: Dispatch<SetStateAction<Values>>;
  questionario: string;
  idEmpresa?: string;
  idProjeto?: string;
  empresaEvidencia: string;
}) => {
  const [questionarios, setQuestionarios] = useState<ComboboxItem[]>([]);
  const [empresas, setEmpresas] = useState<ComboboxItem[]>([]);
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const projeto = useUserStore((state) => state.activeProjeto);
  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadQuestionario();
      } catch (error) {
        console.error(message, error);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, [activeEmpresaId, projeto, idProjeto]);

  const loadQuestionario = async () => {
    if (projeto || idProjeto) {
      const arrayQuestionario = await getQuestionario(idEmpresa || activeEmpresaId || "", idProjeto || projeto);
      setQuestionarios(arrayQuestionario.map((projeto) => ({ ...projeto, value: projeto.id, label: projeto.nome })));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadEmpresas();
      } catch (error) {
        console.error(message, error);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, [questionario]);

  const loadEmpresas = async () => {
    if (questionario) {
      const arrayEmpresas = await getEmpresasByQuestionario(idEmpresa || "", idProjeto || "", questionario);
      setEmpresas(arrayEmpresas.map((empresa) => ({ ...empresa, value: empresa.id, label: empresa.nomeFantasia })));
    }
  };

  return (
    <>
      <Group justify={"end"}>
        <Select
          label="Selecione um questionário"
          placeholder="Questionário"
          data={questionarios && questionarios.length > 0 ? questionarios : array}
          disabled={questionarios.length === 0 || idProjeto === ""}
          onChange={onChangeQuestionario(setValues)}
          value={questionario === "" || questionarios.length === 0 ? null : questionario}
        />
        <Select
          label="Selecione uma empresa"
          placeholder="Empresa"
          data={empresas && empresas.length > 0 ? empresas : array}
          disabled={questionario === "" || empresas.length === 0}
          onChange={onChangeEmpresa(setValues)}
          value={empresaEvidencia === "" || empresas.length === 0 ? null : empresaEvidencia}
          searchable
        />
      </Group>
    </>
  );
};

export default SelecionaEvidencias;

// prettier-ignore
const onChangeQuestionario = (setValues: Dispatch<SetStateAction<Values>>) => {
  return (value: string) => {
    setValues(values => ({ ...values, questionario: value, empresaEvidencia: "" }))
  }
}

// prettier-ignore
const onChangeEmpresa = (setValues: Dispatch<SetStateAction<Values>>) => {
  return (value: string) => {
    setValues(values => ({ ...values, empresaEvidencia: value }))
  }
}
