import { Card, Group, Text, Button, Flex, Stack, Space, Grid, Badge, ThemeIcon } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { QueryObserverResult } from "@tanstack/react-query";
import React, { MouseEventHandler } from "react";
import Can from "@/components/can";
import JogadaDeInovacaoCard from "./HomedeInovacaoCard";

const JogadaDeInovacaoItemModal: React.FC<{
  jogada: TJogadaDeInovacao;
  refetch: () => Promise<QueryObserverResult>;
  defaultExpand?: boolean;
  handleImplement: (jogada: TJogadaDeInovacao) => MouseEventHandler<HTMLButtonElement> | undefined;
}> = ({ jogada, refetch, defaultExpand, handleImplement }) => {
  const [opened, { toggle }] = useDisclosure(defaultExpand);

  return (
    <>
      <Card withBorder radius="lg">
        <Grid align="center">
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
              <Text fw={300} fz="xs">
                Titulo da jogada
              </Text>
              <Text fw={500} fz="md">
                {jogada.nome}
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <Badge
              radius="md"
              size="xl"
              variant="outline"
              style={{ background: "#e6f0e8" }}
              color="rgba(105, 198, 105, 1)"
            >
              <Group justify="space-between">
                <ThemeIcon color="rgba(105, 198, 105, 1)" radius="lg" size="sm">
                  <Text size="sm">
                    {jogada && jogada.taticas && Array.isArray(jogada.taticas) ? jogada.taticas.length : 0}
                  </Text>
                </ThemeIcon>
                <Text tt="capitalize" fz="sm" fw={500}>
                  Táticas
                </Text>
              </Group>
            </Badge>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <Can I="create" a="TEmpresa">
              <Button bg="rgba(8, 68, 244, 1)" radius="lg" size="md" onClick={handleImplement(jogada)}>
                <Text size="sm">Implementar jogada</Text>
              </Button>
            </Can>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <Group justify="flex-end" align="center">
              <Button
                type="button"
                variant="transparent"
                size="compact-sm"
                rightSection={opened ? <IconChevronUp size="1rem" /> : <IconChevronDown size="1rem" />}
                onClick={toggle}
              >
                {opened ? "Ver menos" : "Ver mais"}
              </Button>
            </Group>
          </Grid.Col>
        </Grid>
        {opened && (
          <>
            <Space h="xl" />
            <Stack>
              <JogadaDeInovacaoCard jogada={jogada} refetch={refetch} />
            </Stack>
          </>
        )}
      </Card>
      <Space h="xl" />
    </>
  );
};

export default JogadaDeInovacaoItemModal;
