import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import {
  getRegulamentoDocumentReferenceFromReferencePath,
  getTotalMoedasForRegulamentoDesafios
} from "@/modules/regulamento-desafios/firestore";
import useUserStore from "@/modules/users/store";

type TGetTotalMoedasForRegulamentoDesafiosResponse = Awaited<ReturnType<typeof getTotalMoedasForRegulamentoDesafios>>;

/**
 * Get total votes for the colaborador in the regulamento document
 * @param regulamento - Regulamento to get the total votes
 * @returns - Query result
 */
function useGetTotalMoedasPerRegulamento(regulamento?: TRegulamentoDesafios | null) {
  const [activeEmpresaId, colaborador] = useUserStore((state) => [state.activeEmpresaId, state.colaborador]);

  return useQuery<TGetTotalMoedasForRegulamentoDesafiosResponse, FirestoreError>({
    queryKey: [
      "total-votos-per-regulamento",
      activeEmpresaId,
      colaborador?.id,
      regulamento?.id,
      colaborador,
      regulamento,
      regulamento?.refPath,
      colaborador?.id
    ],
    initialData: regulamento?.quantidadeMoedasPorColaborador || 0,
    placeholderData: regulamento?.quantidadeMoedasPorColaborador || 0,
    async queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!colaborador) {
        throw new Error("Colaborador não encontrado");
      }
      if (!regulamento) {
        throw new Error("Regulamento não encontrado");
      }

      const regulamentoDocumentReference = getRegulamentoDocumentReferenceFromReferencePath(regulamento.refPath);

      const totalMoedas = await getTotalMoedasForRegulamentoDesafios(
        activeEmpresaId,
        colaborador.id,
        regulamentoDocumentReference
      );
      return totalMoedas || 0;
    },
    enabled: !!regulamento
  });
}

export default useGetTotalMoedasPerRegulamento;
