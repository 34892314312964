import { Alert, Button, Group, LoadingOverlay, Stack, TextInput } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { extractTextInputPropertiesFromFormSchema } from "@/utils/form";
import inviteEmpresaSchema from "./invite-empresa-schema";
import useInviteEmpresaForm from "./useInviteEmpresaForm";

const InviteEmpresaForm = ({ onSuccess }: { onSuccess: () => void }) => {
  const { form, onSubmit, isPending } = useInviteEmpresaForm({
    onSuccess
  });

  const inviteFieldsDescriptor = inviteEmpresaSchema.describe();

  const genericError = form.errors[""];

  return (
    <form onSubmit={onSubmit}>
      <Stack gap="md" pos="relative">
        <LoadingOverlay visible={isPending} />
        <TextInput
          type="text"
          data-autofocus
          style={{ flex: 1 }}
          {...form.getInputProps("nome")}
          {...extractTextInputPropertiesFromFormSchema(inviteFieldsDescriptor, "nome")}
        />
        <TextInput
          type="text"
          style={{ flex: 1 }}
          {...form.getInputProps("email")}
          {...extractTextInputPropertiesFromFormSchema(inviteFieldsDescriptor, "email")}
        />
      </Stack>
      <Group justify={genericError ? "space-between" : "flex-end"} mt="md">
        {genericError && (
          <Alert icon={<IconAlertCircle size="1rem" />} color="red">
            {genericError}
          </Alert>
        )}
        <Button type="submit" loading={isPending}>
          Enviar
        </Button>
      </Group>
    </form>
  );
};

export default InviteEmpresaForm;
