import { subject } from "@casl/ability";
import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getComiteDeInovacaoEmAndamentoDocumentSnapshot } from "@/modules/comite-inovacao/firestore";
import useUserStore from "@/modules/users/store";

type GetStartedComiteDeInovacaoResponse = Awaited<ReturnType<typeof getComiteDeInovacaoEmAndamentoDocumentSnapshot>>;

/**
 * Hook to get first comitê de inovacao ativo
 * @returns Query result with first comitê de inovacao ativo
 */
function useGetFirstComiteInovacaoAtivo() {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  return useQuery<GetStartedComiteDeInovacaoResponse, FirestoreError, TComiteInovacao | null>({
    queryKey: ["empresas", { id: activeEmpresaId }, "comitesInovacao", "ativo"],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return getComiteDeInovacaoEmAndamentoDocumentSnapshot(activeEmpresaId);
    },
    select(result) {
      if (result.empty) {
        return null;
      }

      const [firstComiteAtivo] = result.docs;
      if (!firstComiteAtivo || !firstComiteAtivo.exists()) {
        return null;
      }

      return subject("TComiteInovacao", firstComiteAtivo.data());
    }
  });
}

export default useGetFirstComiteInovacaoAtivo;
