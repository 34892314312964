import { subject } from "@casl/ability";
import { Group } from "@mantine/core";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import ColumnText from "./ColumnText";
import ContextMenuRow from "./ContextMenuRow";
import GruposColumn from "../grupos-column";

const columnHelper = createColumnHelper<TPergunta>();

const columns: ColumnDef<TPergunta>[] = [
  columnHelper.accessor("grupo", {
    header: "Grupo",
    enableSorting: true,
    enableColumnFilter: true,
    cell: (properties) => {
      return <GruposColumn properties={properties.getValue()} />;
    }
  }),
  columnHelper.accessor("nome", {
    header: "Nome",
    enableSorting: true,
    enableColumnFilter: true
  }),
  columnHelper.accessor("tipoDeResposta", {
    header: "Tipo de Resposta",
    enableSorting: true,
    enableColumnFilter: true,
    cell: (tipo) => <ColumnText tipo={tipo.getValue()} />
  }),
  columnHelper.accessor("ordem", {
    header: "Ordem",
    enableSorting: true,
    enableColumnFilter: true
  }),
  columnHelper.accessor("peso", {
    header: "Peso",
    enableSorting: true,
    enableColumnFilter: true
  }),
  columnHelper.display({
    id: "actions",
    cell: (properties) => {
      const pergunta = subject("TPergunta", properties.row.original);

      return (
        <Group justify="center" gap={2}>
          <ContextMenuRow pergunta={pergunta} />
        </Group>
      );
    }
  })
];

export default columns;
