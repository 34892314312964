import { Button, Drawer, Group, ScrollArea, Stack, TagsInput, Text, TextInput } from "@mantine/core";
import { TransformedValues, useForm, yupResolver } from "@mantine/form";
import { IconRestore } from "@tabler/icons-react";
import { ColumnFiltersState, Table } from "@tanstack/react-table";
import { ComponentProps, MouseEvent } from "react";
import { ObjectSchema, SchemaDescription } from "yup";
import schema from "@/schema";
import { extractTextInputPropertiesFromFormSchema } from "@/utils/form";

type TColaboradorStatus = "ACTIVE" | "DISABLED" | "INACTIVE" | "PENDING";

type Filters = Partial<{
  nome: TColaborador["nome"];
  email: TColaborador["email"];
  telefone: TColaborador["telefone"];
  role: Array<TColaborador["role"]>;
  status: Array<TColaboradorStatus>;
}>;
type FilterKeys = keyof Filters;

const filterSchema: ObjectSchema<Filters, unknown, Filters> = schema
  .object({
    nome: schema.string().optional().trim().label("Nome"),
    email: schema.string().optional().trim().label("E-mail"),
    telefone: schema.string().optional().trim().label("Telefone"),
    role: schema
      .array(schema.mixed<TColaborador["role"]>().oneOf(["ADMIN", "MEMBER"]).defined().required())
      .optional()
      .meta({ operator: "in" })
      .label("Perfil"),
    status: schema
      .array(
        schema.mixed<TColaboradorStatus>().oneOf(["ACTIVE", "DISABLED", "INACTIVE", "PENDING"]).defined().required()
      )
      .optional()
      .meta({ operator: "in" })
      .label("Status")
  })
  .noUnknown()
  .label("Filtros de Colaboradores");

const MAX_ITEMS_FILTER = 5;

const PERFIL_DATA: Filters["role"] = ["ADMIN", "MEMBER"];
const STATUS_DATA: Filters["status"] = ["ACTIVE", "DISABLED", "INACTIVE", "PENDING"];

const TableFilters = ({
  table,
  filters,
  onFilterApplied,
  ...drawerProperties
}: { table: Table<TColaborador>; filters: ColumnFiltersState; onFilterApplied: () => void } & ComponentProps<
  typeof Drawer
>) => {
  const filtersObject: Filters = filterSchema.getDefault();
  for (const filter of filters || []) {
    filtersObject[filter.id as unknown as FilterKeys] = filter.value as never;
  }

  const filterDescriptor = filterSchema.describe();

  const form = useForm<Filters>({
    initialValues: filtersObject,
    transformValues: (values) => filterSchema.cast(values),
    validate: yupResolver(filterSchema)
  });

  type TFormTransformedValues = TransformedValues<typeof form>;
  /**
   * Handle form submit
   * @param values - Form values
   */
  function onSubmit(values: TFormTransformedValues) {
    table.resetColumnFilters();
    for (const [key, value] of Object.entries(values)) {
      const column = table.getColumn(key);
      if (column && value) {
        // eslint-disable-next-line security/detect-object-injection
        const fieldMeta = (filterDescriptor.fields[key] as SchemaDescription).meta as { operator?: string } | undefined;
        column.setFilterValue([fieldMeta?.operator ?? "==", value]);
      }
    }
    onFilterApplied();
  }

  /**
   * Handle restaurar button click
   * @param event - Click event
   */
  function handleRestaurarClick(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    table.resetColumnFilters();
    form.reset();
    onFilterApplied();
  }

  return (
    <Drawer
      scrollAreaComponent={ScrollArea.Autosize}
      size="sm"
      title={<Text fw="bold">Filtrar Colaboradores</Text>}
      {...drawerProperties}
    >
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack gap="sm">
          <TextInput
            type="text"
            data-autofocus
            {...extractTextInputPropertiesFromFormSchema(filterDescriptor, "nome")}
            {...form.getInputProps("nome")}
          />
          <TextInput
            type="text"
            {...extractTextInputPropertiesFromFormSchema(filterDescriptor, "email")}
            {...form.getInputProps("email")}
          />
          <TextInput
            type="text"
            {...extractTextInputPropertiesFromFormSchema(filterDescriptor, "telefone")}
            {...form.getInputProps("telefone")}
          />
          <TagsInput
            data={PERFIL_DATA}
            maxTags={MAX_ITEMS_FILTER}
            clearable
            {...extractTextInputPropertiesFromFormSchema(filterDescriptor, "role")}
            {...form.getInputProps("role")}
          />
          <TagsInput
            data={STATUS_DATA}
            maxTags={MAX_ITEMS_FILTER}
            clearable
            {...extractTextInputPropertiesFromFormSchema(filterDescriptor, "status")}
            {...form.getInputProps("status")}
          />
        </Stack>

        <Group justify="space-between" mt="lg">
          <Button
            type="button"
            variant="subtle"
            color="orange"
            size="compact-sm"
            onClick={handleRestaurarClick}
            leftSection={<IconRestore size="1rem" />}
          >
            Restaurar
          </Button>
          <Button type="submit" variant="light">
            Aplicar
          </Button>
        </Group>
      </form>
    </Drawer>
  );
};

export default TableFilters;
