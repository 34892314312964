import { Group, Pagination, Text } from "@mantine/core";
import { IconArrowLeft, IconArrowRight } from "@tabler/icons-react";
import { RowData } from "@tanstack/react-table";
import { MouseEvent } from "react";
import { THandler } from "./Table";

const TablePagination = <T extends RowData>({ tableHandlers }: { tableHandlers: THandler<T> }) => {
  const { table } = tableHandlers;
  const totalPages = table.getPageCount();
  const pagination = table.getState().pagination;
  const totalItems = table.options.meta?.totalItems;

  /**
   * Handle the next page click event
   * @param event - The event object
   */
  function handleNextPageClick(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    table.nextPage();
  }

  /**
   * Handle the previous page click event
   * @param event - The event object
   */
  function handlePreviousPageClick(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    table.previousPage();
  }

  /**
   *
   * @param page - The page number
   */
  function handlePageChange(page: number) {
    table.setPageIndex(page);
  }

  return (
    <Group justify="space-between" m="md">
      <Group gap={5}>
        <Text c="dimmed" fz="sm">
          Itens por página:
        </Text>
        <Text c="dimmed" fw={500} fz="sm">
          {pagination.pageSize}
        </Text>
      </Group>
      <Pagination.Root total={totalPages} value={pagination.pageIndex} onChange={handlePageChange}>
        <Group justify="center" gap="xs">
          <Text c="dark.3" fz="sm">
            {pagination.pageIndex + 1} - {totalPages} de {totalItems}
          </Text>
          <Pagination.Previous
            icon={IconArrowLeft}
            disabled={!table.getCanPreviousPage()}
            onClick={handlePreviousPageClick}
          />
          <Pagination.Next icon={IconArrowRight} disabled={!table.getCanNextPage()} onClick={handleNextPageClick} />
        </Group>
      </Pagination.Root>
    </Group>
  );
};

export default TablePagination;
