import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getConvitesEmpresas } from "@/modules/empresas/firestore";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";

type TGetRespostaResponse = Awaited<ReturnType<typeof getConvitesEmpresas>>;
/**
 * Get resposta from url params
 * @param userId - userId
 * @returns - resposta or null if not found
 */
function useGetConvites(userId?: string) {
  const id = useUserStore((store) => store.activeEmpresaId);

  const uid = userId ?? getCurrentUser()?.uid;

  if (!uid) {
    throw new Error("Usuário não encontrado");
  }

  return useQuery<TGetRespostaResponse, FirestoreError, TConvite[] | null>({
    queryKey: [id, uid],
    queryFn() {
      if (!id) {
        throw new Error("Ideia não selecionada");
      }

      return getConvitesEmpresas(id, uid || "");
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((document_) => document_.data());
    },
    enabled: !!id
  });
}

export default useGetConvites;
