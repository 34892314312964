import { Group, Button, Avatar, Skeleton } from "@mantine/core";

const RespostasAoComentarioAvatarsLoading = () => (
  <Group>
    <Button
      loading={true}
      variant="default"
      type="button"
      size="lg"
      leftSection={
        <Avatar.Group>
          <Skeleton height={34} circle />
          <Skeleton height={34} circle />
          <Skeleton height={34} circle />
        </Avatar.Group>
      }
    >
      <Skeleton height={14} w={90} radius="md" />
    </Button>
  </Group>
);

export default RespostasAoComentarioAvatarsLoading;
