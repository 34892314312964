import { Text, Card, Title, Group, ScrollArea } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useEffect, useState } from "react";
import NoData from "@/components/no-data";
import TopIdeiaCard from "./TopIdeiaCard";
import useGetTopIdeiasMaisVotadas from "./useGetTopIdeiasMaisVotadas";

const getRanking = (ideia: TIdeia) => ideia.rankingByMoedas?.ranking ?? 0;

const TOP_LIMIT_IDEIAS_MAIS_VOTADAS = 3;
const MAGIC_NUMBER = 335;
// prettier-ignore
const TopIdeiasMaisVotadasCard = () => {
  const [ranking, setRanking] = useState<TIdeia[]>([])

  const { data: topIdeias } = useGetTopIdeiasMaisVotadas();


  useEffect(() => {
    if (topIdeias) {
      const rankingProcessed = [...topIdeias]
        .filter((ideia) => !ideia.privado)
        .sort((a, b) => getRanking(a) - getRanking(b))
        .slice(0, TOP_LIMIT_IDEIAS_MAIS_VOTADAS);

      setRanking(rankingProcessed);
    }
  }, [topIdeias])

  const isSmMarches = useMediaQuery("(max-width: 48em)");

  return (
    <Card withBorder w={"100%"} h={isSmMarches ? "100%" : MAGIC_NUMBER}>
      <Title order={3} fw={500} mb="md">
        Top {TOP_LIMIT_IDEIAS_MAIS_VOTADAS} ideias mais votadas da empresa
      </Title>
      {ranking.length === 0 ? (
        <NoData>
          <Text c="dimmed">Não há ideias cadastradas</Text>
        </NoData>
      ) : (
        <ScrollArea scrollbars="x">
          <Group wrap="nowrap" align="stretch" grow>
            {ranking.map((ideia, index) => <TopIdeiaCard key={ideia.id} ideia={ideia} maw={300} index={index} />)}
          </Group>
        </ScrollArea>
      )}
    </Card>
  );
};

export default TopIdeiasMaisVotadasCard;
