import { InputProps } from "@mantine/core";
import TotalCoinsColaboradorDesafios from "./TotalCoinsColaboradorDesafios";
import TotalCoinsColaboradorIdeias from "./TotalCoinsColaboradorIdeias";

interface newValues {
  tipo: "ideias" | "desafios";
}

const TotalCoinsColaborador = (properties: InputProps & newValues) => {
  const { tipo } = properties;

  return tipo === "ideias" ? <TotalCoinsColaboradorIdeias /> : <TotalCoinsColaboradorDesafios />;
};

export default TotalCoinsColaborador;
