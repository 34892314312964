import { Avatar, Card, Flex, Group, Text } from "@mantine/core";
import { useParams } from "react-router-dom";
import useGetEmpresaById from "@/modules/analisar-resposta/components/ranking-list/useGetEmpresas";

const CardEmpresa = () => {
  const { idEmpresa: id } = useParams<{ idEmpresa: string }>();
  const { data } = useGetEmpresaById(id);

  return (
    <Card withBorder shadow="md" style={{ flex: 1, padding: 30 }}>
      <Group justify={"start"}>
        <Avatar variant="filled" radius="xl" size="xl" src="">
          {data?.nomeFantasia.charAt(0)}
        </Avatar>
        <Flex mih={50} gap="md" justify="center" align="flex-start" direction="column" wrap="wrap">
          <Text size="xl" fw={700}>
            {data?.nomeFantasia}
          </Text>
        </Flex>
      </Group>
    </Card>
  );
};

export default CardEmpresa;
