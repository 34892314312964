import { Anchor, Group, Text } from "@mantine/core";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import RankingTrendIcon from "@/components/ranking-trend-icon";
import ColaboradoresInlineList from "@/modules/colaboradores/components/colaboradores-inline-list";
import DesafioStatusBadge from "@/modules/desafios/components/desafio-status-badge";
import TotalMoedasBadge from "@/modules/desafios/components/total-moedas-badge";
import useUserStore from "@/modules/users/store";

const columnHelper = createColumnHelper<TDesafio>();

const ColaboradorCreatedByText = ({ colaboradorId }: { colaboradorId: TColaborador["id"] }) => {
  const activeCompanyId = useUserStore((state) => state.activeEmpresaId);
  const colaboradores = useMemo(
    () => [`empresas/${activeCompanyId}/colaboradores/${colaboradorId}`],
    [activeCompanyId, colaboradorId]
  );
  return <ColaboradoresInlineList colaboradores={colaboradores} topVisibleAvatars={0} />;
};

const desafioColumns: ColumnDef<TDesafio>[] = [
  columnHelper.display({
    id: "top",
    header: "Top",
    enableSorting: false,
    enableColumnFilter: false,
    cell({ row }) {
      const desafio = row.original;
      if (!desafio || !desafio.rankingByMoedas) {
        return null;
      }

      return (
        <Group gap={2}>
          <Text fw={500}>#{desafio.rankingByMoedas.ranking}</Text>{" "}
          <RankingTrendIcon ranking={desafio.rankingByMoedas} size={18} />
        </Group>
      );
    }
  }),
  columnHelper.accessor("titulo", {
    header: "Titulo",
    enableSorting: false,
    enableColumnFilter: false,
    cell({ row, getValue }) {
      const id = row.original.id;
      return (
        <Anchor component={NavLink} to={`/desafios/${id}`}>
          {getValue()}
        </Anchor>
      );
    }
  }),
  columnHelper.accessor("createdBy", {
    header: "Autor",
    enableSorting: false,
    enableColumnFilter: false,
    cell({ getValue }) {
      const createdBy = getValue();
      return <ColaboradorCreatedByText colaboradorId={createdBy} />;
    }
  }),
  columnHelper.accessor("status", {
    header: "Status",
    enableSorting: false,
    enableColumnFilter: false,
    cell({ getValue }) {
      return <DesafioStatusBadge status={typeof getValue() === "object" ? "DRAFT" : getValue()} iconOnly />;
    }
  }),
  columnHelper.accessor("rankingByMoedas.totalMoedas", {
    header: "Total Moedas",
    enableSorting: false,
    enableColumnFilter: false,
    cell({ row }) {
      return <TotalMoedasBadge desafio={row.original} variant="transparent" px={0} />;
    }
  })
];

export default desafioColumns;
// prettier-ignore
export const desafioColumnsMobile: ColumnDef<TDesafio>[] = [
  columnHelper.display({
    id: "top",
    header: "Top",
    enableSorting: false,
    enableColumnFilter: false,
    cell({ row }) {
      const desafio = row.original;
      if (!desafio || !desafio.rankingByMoedas) {
        return null;
      }

      return (
        <Group gap={2}>
          <Text fw={500}>#{desafio.rankingByMoedas.ranking}</Text>{" "}
          <RankingTrendIcon ranking={desafio.rankingByMoedas} size={18} />
        </Group>
      );
    }
  }),
  columnHelper.accessor("titulo", {
    header: "Titulo",
    enableSorting: false,
    enableColumnFilter: false,
    cell({ row, getValue }) {
      const id = row.original.id;
      return (
        <Anchor component={NavLink} to={`/desafios/${id}`}>
          {getValue()}
        </Anchor>
      );
    }
  }),
  columnHelper.accessor("rankingByMoedas.totalMoedas", {
    header: "Total Moedas",
    enableSorting: false,
    enableColumnFilter: false,
    cell({ row }) {
      return <TotalMoedasBadge desafio={row.original} variant="transparent" px={0} />;
    }
  })
];
