import { Flex, Space, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { porteData, numeroColaboradoresData } from "@/modules/empresas/schema/empresa-schema";
import useGetProjetoById from "@/modules/projetos/hooks/useGetProjetoById";
import { useGetRegioesByProjeto } from "@/modules/regiao/hooks/useGetRegioesByProjeto";
import useUserStore from "@/modules/users/store";
import PieChartComponent from "../graphics-nivo/PieChart";
import GetEmpresas from "../graphics-nivo/useGetEmpresa";

const message = "Ocorreu um erro ao carregar os dados:";

type GraphSegment = { id: string; label: string; value: number };

const CardPies = ({ empresas }: { empresas: TConvite[] }) => {
  const { activeEmpresaId, activeProjeto } = useUserStore();

  const [formatEmpresas, setFormatEmpresas] = useState<TEmpresa[]>([]);
  const [formatRegioes, setFormatRegioes] = useState<GraphSegment[]>([]);
  const [formatCategorias, setFormatCategorias] = useState<GraphSegment[]>([]);

  const { data: projeto } = useGetProjetoById(activeEmpresaId ?? "", activeProjeto ?? "");
  const { regioes, fetch } = useGetRegioesByProjeto();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadData();
      } catch (error) {
        console.error(message, error);
      }
    };
    void fetchData();
  }, [empresas]);

  useEffect(() => {
    if (activeEmpresaId && activeProjeto) {
      void fetch(activeEmpresaId, activeProjeto);
    }
  }, [activeEmpresaId, activeProjeto]);

  useEffect(() => {
    const regioesFormatted: GraphSegment[] = regioes.map((item) => {
      return {
        id: item.name,
        label: item.name,
        value: item.empresas.length
      };
    });

    setFormatRegioes(regioesFormatted);
  }, [regioes]);

  useEffect(() => {
    if (projeto) {
      const segments = new Map<string, GraphSegment>();

      for (const empresa of formatEmpresas) {
        const categoria = empresa.categorias ?? "Sem categoria";

        const segment = segments.get(categoria);

        if (!segment) {
          segments.set(categoria, { id: categoria, label: categoria, value: 1 });
          continue;
        }

        segment.value++;

        segments.set(categoria, segment);
      }

      setFormatCategorias([...segments.values()]);
    }
  }, [projeto, formatEmpresas]);

  const loadData = async () => {
    if (empresas.length > 0) {
      const newEmpresa = await GetEmpresas(empresas);
      setFormatEmpresas(newEmpresa);
    }
  };

  const formatPorteData = porteData.map((item) => {
    const value = formatEmpresas.filter((empresa) => empresa.porte === item.value).length || 0;
    return {
      id: item.value,
      label: item.label,
      value
    };
  });

  const formatColaboradores = numeroColaboradoresData.map((item) => {
    const value = formatEmpresas.filter((empresa) => empresa.numeroColaboradores === item.value).length || 0;
    return {
      id: item.value,
      label: item.label,
      value
    };
  });

  return (
    <>
      <Text size={"md"} fw={700}>
        Quantidade de empresas por porte
      </Text>
      <PieChartComponent data={formatPorteData} type={1} />
      <Space h={"md"} />
      <Text size={"md"} fw={700}>
        Quantidade de empresas por número de colaboradores
      </Text>
      <PieChartComponent data={formatColaboradores} type={2} />
      <Text size={"md"} fw={700}>
        Quantidade de empresas por número categoria
      </Text>
      <PieChartComponent data={formatCategorias} type={3} />
      <Text size={"md"} fw={700}>
        Quantidade de empresas por região
      </Text>
      {formatRegioes.length > 0 ? (
        <PieChartComponent data={formatRegioes} type={4} />
      ) : (
        <Flex justify="center" style={{ minHeight: 50 }}>
          <Text>Nenhum região cadastrada</Text>
        </Flex>
      )}
    </>
  );
};

export default CardPies;
