import { Text, FocusTrap } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import { useQueryClient } from "@tanstack/react-query";
import { Asserts } from "yup";
import useUserStore from "@/modules/users/store";
import AddBonusForm from "../../components/add-bonus-form";
import { HISTORICO_PONTOS_PER_COLABORADOR_KEY } from "../../firestore";
import schema from "../../schema";

const AddBonusFormModal = ({ context, innerProps, id }: ContextModalProps<{ item: Asserts<typeof schema> }>) => {
  const { item: historico } = innerProps;
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const queryClient = useQueryClient();

  /**
   * Handle add ignore area success
   */
  function onSuccess() {
    context.closeModal(id);
    void queryClient.invalidateQueries({
      queryKey: [activeEmpresaId, HISTORICO_PONTOS_PER_COLABORADOR_KEY]
    });
  }

  return (
    <FocusTrap active={true}>
      <Text fw="bold" mb="md">
        Adicionar Bônus ao Colaborador
      </Text>
      <AddBonusForm historico={historico} onSuccess={onSuccess} />
    </FocusTrap>
  );
};

export default AddBonusFormModal;
