import { Image } from "@mantine/core";
import logo from "@/assets/favicon.svg";
import useUserStore from "@/modules/users/store";

/**
 * Navbar logo component
 * @returns JSX.Element
 */
function AppNavbarLogo() {
  const { activeEmpresa } = useUserStore();

  const logoPath = activeEmpresa?.image[0]?.path ?? logo;

  return <Image key={logoPath} src={logoPath} alt="Acelerador de Inovação | ADI" width={120} />;
}

export default AppNavbarLogo;
