import { subject } from "@casl/ability";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import {
  serverTimestamp,
  Timestamp,
  FirestoreDataConverter,
  collection,
  getCountFromServer,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  query,
  where
} from "firebase/firestore";
import { getCurrentUser } from "@/services/auth";
import { generateQuery } from "@/utils/firestore";
import { getProjetoDocumentReference } from "../projetos/firestore";

const DEFAULT_LIMIT = 10;

export const DEFAULT_SORTING: SortingState = [{ id: "name", desc: false }];
export const DEFAULT_PAGINATION: PaginationState = { pageIndex: 0, pageSize: DEFAULT_LIMIT };

export type TRegiaoFormFields = Omit<
  TRegiao,
  "id" | "refPath" | "createdAt" | "updatedAt" | "createdBy" | "updatedBy"
> &
  Partial<Pick<TRegiao, "id" | "refPath">>;

export type TRegiaoDatabaseFields = Pick<TRegiao, "createdBy" | "updatedBy"> & {
  createdAt: ReturnType<typeof serverTimestamp>;
  updatedAt?: ReturnType<typeof serverTimestamp>;
  deletedAt: ReturnType<typeof serverTimestamp> | null;
};

export type TRegiaoForm = TRegiaoFormFields & TRegiaoDatabaseFields;

type TRegiaoDocument = Omit<TRegiao, "createdAt" | "updatedAt"> & {
  createdAt: Timestamp;
  updatedAt?: Timestamp;
  deletedAt: Timestamp | null;
};

const regiaoConverter: FirestoreDataConverter<TRegiao> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const { createdAt, updatedAt, ...document } = snap.data() as TRegiaoDocument;

    const data: TRegiao = {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      refPath: snap.ref.path
    };

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }
    return subject("TRegiao", data);
  }
};

/**
 * Get regioes collection reference from empresaId
 * @param empresaId - Empresa id to get the regioes collection reference
 * @param projetoId - Projeto id to get the regioes collection reference
 * @returns - Regioes collection reference
 */
function getRegioesCollectionReference(empresaId: TEmpresa["id"], projetoId: TProjeto["id"]) {
  const projetoDocumentReference = getProjetoDocumentReference(empresaId, projetoId);
  return collection(projetoDocumentReference, "regioes");
}

/**
 * Get regiao document reference from empresaId
 * @param empresaId - Empresa id to get the regiao document reference
 * @param projetoId - Projeto id to get the regiao document reference
 * @param regiaoId - Regiao id to get the regiao document reference
 * @returns - Regiao document reference
 */
function getRegioesDocumentReference(empresaId: TEmpresa["id"], projetoId: TProjeto["id"], regiaoId: TRegiao["id"]) {
  const regioesCollectionReference = getRegioesCollectionReference(empresaId, projetoId);
  return doc(regioesCollectionReference, regiaoId);
}

const BASE_FILTER: ColumnFiltersState = [
  {
    id: "deletedAt",
    value: ["==", null]
  }
];

/**
 * Get regioes documents from empresaId
 * @param empresaId - Empresa id to get the regioes documents
 * @param projetoId - Projeto id to get the regioes documents
 * @param options - Options to get the regioes documents
 * @param options.sorting - Sorting options
 * @param options.filters - Filters options
 * @param options.pagination - Pagination options
 * @param options.paginationCursors - Array of pagination cursors (startAt, endAt)[]
 * @returns - Regiao documents
 */
export function getRegioes(
  empresaId: TEmpresa["id"],
  projetoId: TProjeto["id"],
  {
    sorting = DEFAULT_SORTING,
    filters = [],
    pagination = DEFAULT_PAGINATION,
    paginationCursors
  }: TPaginatedQueryOptions<TRegiao> = {}
): Promise<TPaginatedQueryResponse<TRegiao>> {
  const regioesCollectionReference = getRegioesCollectionReference(empresaId, projetoId).withConverter(regiaoConverter);

  const allFilters = [...BASE_FILTER, ...filters];
  const qAll = generateQuery(regioesCollectionReference, { filters: allFilters });
  const qPaginated = generateQuery(regioesCollectionReference, {
    sorting,
    filters: allFilters,
    pagination,
    paginationCursors
  });

  // eslint-disable-next-line compat/compat
  return Promise.all([getDocs(qPaginated), getCountFromServer(qAll)]);
}

/**
 * Get regioes by empresa and projeto
 * @param empresaId - Empresa id
 * @param projetoId - Projeto id
 * @returns - Regioes documents
 */
export function getRegioesByEmpresaAndProjeto(empresaId: TEmpresa["id"], projetoId: TProjeto["id"]) {
  const regioesCollectionReference = getRegioesCollectionReference(empresaId, projetoId).withConverter(regiaoConverter);
  return getDocs(regioesCollectionReference);
}

/**
 * Add a new regiao to the given empresa
 * @param empresaId - Empresa id to add the regiao
 * @param projetoId - Projeto id to add the regiao
 * @param regiao - Regiao data
 * @returns - Regiao document reference
 */
export async function addRegiao(
  empresaId: TEmpresa["id"],
  projetoId: TProjeto["id"],
  regiao: Omit<TRegiaoForm, "id" | "refPath">
) {
  const regiaoCollectionReference = getRegioesCollectionReference(empresaId, projetoId);
  return addDoc(regiaoCollectionReference, regiao);
}

/**
 * Update a empresa to the database
 * @param empresaId - Empresa id
 * @param projetoId - Projeto id
 * @param regiaoId - Regiao id to update
 * @param regiao - Regiao data
 * @returns - Promise with the regiao reference
 */
export function updateRegiao(
  empresaId: TEmpresa["id"],
  projetoId: TProjeto["id"],
  regiaoId: TRegiao["id"],
  regiao: TRegiaoForm
) {
  const regiaoDocumentReference = getRegioesDocumentReference(empresaId, projetoId, regiaoId);
  const currentUserId = getCurrentUser()?.uid;
  return updateDoc(regiaoDocumentReference, {
    ...regiao,
    updatedAt: serverTimestamp(),
    updatedBy: currentUserId
  });
}

/**
 * Delete a regiao to the database
 * @param empresaId - Empresa id to delete the regiao
 * @param projetoId - Projeto id to delete the regiao
 * @param regiao - Regiao to delete
 * @returns - Promise
 */
export function deleteRegiao(empresaId: TEmpresa["id"], projetoId: TProjeto["id"], regiao: TRegiao) {
  const regiaoDocumentReference = getRegioesDocumentReference(empresaId, projetoId, regiao.id);
  return updateDoc(regiaoDocumentReference, {
    deletedAt: serverTimestamp()
  });
}

/**
 * Restore a regiao to the database
 * @param rootEmpresaId - Root empresa id
 * @param projeto - Projeto id
 * @param empresaId - Empresa id to restore the regiao
 * @returns - Promise
 */
export async function getRegiaoByEmpresaId(
  rootEmpresaId: TEmpresa["id"],
  projeto: TProjeto["id"],
  empresaId: TEmpresa["id"]
) {
  const regioesCollections = getRegioesCollectionReference(rootEmpresaId, projeto).withConverter(regiaoConverter);
  const regioesQuery = query(regioesCollections, where("empresas", "array-contains", empresaId));
  const regioesFound = await getDocs(regioesQuery);
  return regioesFound.docs.map((document_) => document_.data())[0];
}
