import { Anchor, Badge, Card, Grid, Group, Skeleton, Stack, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconCheck } from "@tabler/icons-react";
import { NavLink } from "react-router-dom";
import Can from "@/components/can";
import AvatarsColaboradores from "@/modules/colaboradores/components/avatars-colaboradores";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import useGetColaboradoresFromReference from "@/modules/colaboradores/hooks/useGetColaboradoresFromReference";
import OpenAtuacaoModalButton from "@/modules/ideia-marcos/components/OpenAtuacaoModalButton";
import IdeiaStatusBadge from "@/modules/ideias/components/ideia-status-badge";
import useGetIdeiaFromDocumentReference from "@/modules/ideias/hooks/useGetIdeiaFromDocumentReference";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import { dataAutorizacoes } from "@/src/routes/empresa-page/EmpresaPage";
import { formatDate } from "@/utils/date";
// prettier-ignore
// eslint-disable-next-line complexity
const AtividadeItem = ({ marco }: { marco: TMarco }) => {
  const { data: allParticipantes, isLoading: isLoadingResponsaveis } = useGetColaboradoresFromReference(
    marco.colaboradoresResponsaveis
  );
  const { data: ideia, isLoading: isLoadingIdeia } = useGetIdeiaFromDocumentReference(marco.ideiaReference);
  const activeEmpresaId = useUserStore((store) => store.activeEmpresa);
  const activeAutorizacao = useUserStore((store) => store.autorizacoes);
  const uid = getCurrentUser()?.uid;
  const { data: userColaborador } = useGetColaboradorById(activeEmpresaId?.id || "", uid || "");
  const autorizacao = dataAutorizacoes.find(item => item.label === userColaborador?.role);
  const formatAutorizacoes = activeAutorizacao?.filter(item => item.tipo === autorizacao?.value);
  const isSmMarches = useMediaQuery("(max-width: 48em)");
  let status: string = "Não iniciada";
  if (marco.finishedAt) {
    status = "Concluída";
  } else if (marco.dataPrazo < new Date()) {
    status = "Atrasada";
  } else if (marco.startedAt) {
    status = "Em andamento";
  }

  return (
    <Card withBorder>
      {isLoadingResponsaveis && <Skeleton height={34} width={34} radius="xl" />}
      <Grid align="center">
        <Grid.Col span={4}>
          <Group>
            {allParticipantes && <AvatarsColaboradores colaboradores={allParticipantes} />}
            <Stack gap={2}>
              <Text c="dimmed" fz="sm">
                Atividade
              </Text>
              <Text truncate="end" maw={"200"}>{marco.acoes}</Text>
            </Stack>
          </Group>
        </Grid.Col>
        {isSmMarches ? <></> : <Grid.Col span={2}>
          <Group gap={2} justify="space-between">
            {isLoadingIdeia && <Skeleton height={20} width={180} />}
            {ideia && (
              <Stack gap={2}>
                <Text c="dimmed" fz="sm">
                  Ideia
                </Text>
                <Group>
                  <Anchor component={NavLink} to={`/ideias/${ideia.id}`}>
                    {ideia.titulo}
                  </Anchor>
                  <IdeiaStatusBadge status={ideia.status} iconOnly />
                </Group>
              </Stack>
            )}
          </Group>
        </Grid.Col>}
        {isSmMarches ? <></> : <Grid.Col span={2}>
          <Stack gap={2}>
            <Text c="dimmed" fz="sm">
              Status
            </Text>
            <Badge variant="dot" color="orange" size="sm">
              {status}
            </Badge>
          </Stack>
        </Grid.Col>}
        {marco && marco?.dataPrazo && !isSmMarches ? <Grid.Col span={2}>
          <Stack gap={2} style={{ flex: 1 }}>
            <Text c="dimmed" fz="sm">
              Data Prazo
            </Text>
            <Text fz="sm">{formatDate(marco?.dataPrazo)}</Text>
          </Stack>
        </Grid.Col> : <></>}
        {formatAutorizacoes?.find(item => item.tipoAutorizacao === "17")?.editar === false && !marco.finishedAt ? <></> : marco.finishedAt ? <Grid.Col span={2}>
          <Group>
            <IconCheck color={"rgba(105, 198, 105, 1)"} style={{ fontSize: 12 }} />
            <Text fz={"sm"} color={"rgba(105, 198, 105, 1)"}>Concluído</Text>
          </Group>
        </Grid.Col> : (
          <Grid.Col span={2}>
            <Can I="update" a="TMarco">
              <Group>
                <OpenAtuacaoModalButton marco={marco} />
              </Group>
            </Can>
          </Grid.Col>
        )}
      </Grid>
    </Card>
  );
};

export default AtividadeItem;
