import { DocumentReference } from "firebase/firestore";
import { ObjectSchema } from "yup";
import schema from "@/schema";
import auditSchema from "@/schema/audit-schema";
import { THistoricoPontosPerColaboradorForm } from "./firestore";
import { TColaboradorDocument, getColaboradorDocumentReferenceFromPath } from "../colaboradores/firestore";

const historicoPontosPerColaboradorSchema: ObjectSchema<THistoricoPontosPerColaboradorForm & TAuditForm> = auditSchema
  .clone()
  // eslint-disable-next-line unicorn/prefer-spread
  .concat(
    schema.object({
      type: schema
        .mixed<TOrigemPontuacaoBonificacao["type"]>()
        .required()
        .defined()
        .default("BONIFICACAO")
        .label("Tipo"),

      observacao: schema.string().required().defined().default("").label("Observação"),

      pontos: schema.number().min(1).required().defined().label("Pontos"),

      colaboradorReference: schema
        .mixed<DocumentReference<TColaborador, TColaboradorDocument>>()
        .required()
        .defined()
        .transform((value: string | DocumentReference<TColaborador, TColaboradorDocument>) => {
          const isString = typeof value === "string";
          return isString ? getColaboradorDocumentReferenceFromPath(value) : value;
        })
        .label("Colaborador")

      // regulamentoIdeias: schema
      //   .mixed<DocumentReference<TRegulamentoIdeias>>()
      //   .defined()
      //   .nullable()
      //   .transform((value: string | DocumentReference<TRegulamentoIdeias>) => {
      //     const isString = typeof value === "string";
      //     return isString ? getDocumentReferenceFromPath(value, regulamentoIdeiasConverter) : value;
      //   })
      //   .label("Regulamento de Ideias"),

      // regulamentoDesafios: schema
      //   .mixed<DocumentReference<TRegulamentoDesafios>>()
      //   .defined()
      //   .nullable()
      //   .transform((value: string | DocumentReference<TRegulamentoDesafios>) => {
      //     const isString = typeof value === "string";
      //     return isString ? getDocumentReferenceFromPath(value, regulamentoDesafiosConverter) : value;
      //   })
      //   .label("Regulamento de Desafios"),

      // comiteInovacaoReference: schema
      //   .mixed<DocumentReference<TComiteInovacao>>()
      //   .defined()
      //   .nullable()
      //   .transform((value: string | DocumentReference<TComiteInovacao>) => {
      //     const isString = typeof value === "string";
      //     return isString ? getDocumentReferenceFromPath(value, comiteInovacaoConverter) : value;
      //   })
      //   .label("Regulamento de Desafios")
    })
  );

export default historicoPontosPerColaboradorSchema;
