import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getStatus } from "@/modules/diagnostico/firestore";
import useUserStore from "@/modules/users/store";

type TGetComitesInovacaoResponse = Awaited<ReturnType<typeof getStatus>>;
const QUERY_KEY: string = "status-list" as const;
/**
 * Get comites de inovação query hook
 * @param root0 - root
 * @param root0.idEmpresaItem - id da empresa
 * @param root0.id - id
 * @returns Comites de inovação query hook
 */
function useGetStatusIdEmpresa({ idEmpresaItem, id }: { idEmpresaItem: string; id: string }) {
  // const { id } = useParams<{ id: string }>();
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  return useQuery<TGetComitesInovacaoResponse, FirestoreError, TStatus[]>({
    queryKey: [QUERY_KEY, id, idEmpresaItem, activeEmpresaId],
    enabled: !!activeEmpresaId,
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Usuário não autenticado");
      }
      return getStatus(idEmpresaItem, id, activeEmpresaId);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((document_) => document_.data());
    }
  });
}

export default useGetStatusIdEmpresa;
