import { subject } from "@casl/ability";
import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { useParams } from "react-router-dom";
import useUserStore from "@/modules/users/store";
import { QUERY_KEY, getTipoDeInovacaoItem } from "../../firestore";

type TGetTipoDeInovacaoResponse = Awaited<ReturnType<typeof getTipoDeInovacaoItem>>;

/**
 * Get ideia from url params
 * @returns - Ideia or null if not found
 */
function useGetTipoDeInovacaoFromUrl() {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const { tipoDeInovacaoId } = useParams<{ tipoDeInovacaoId: string }>();

  return useQuery<TGetTipoDeInovacaoResponse, FirestoreError, TTipoDeInovacao | null>({
    queryKey: [QUERY_KEY, tipoDeInovacaoId, activeEmpresaId],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!tipoDeInovacaoId) {
        throw new Error("Ideia não selecionada");
      }

      return getTipoDeInovacaoItem(activeEmpresaId, tipoDeInovacaoId);
    },
    select(dataResponse) {
      if (!dataResponse.exists()) {
        return null;
      }

      return subject("TTipoDeInovacao", dataResponse.data());
    },
    enabled: !!tipoDeInovacaoId && !!activeEmpresaId
  });
}

export default useGetTipoDeInovacaoFromUrl;
