import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { QUERY_KEY } from "@/modules/projetos/components/projetos-table/ProjetosTable";
import { getProjetosItem } from "@/modules/projetos/firestore";

type TGetProjetoResponse = Awaited<ReturnType<typeof getProjetosItem>>;

/**
 * Get projeto from url params
 * @param empresaId - id da empresa
 * @param projetoId - id do projeto
 * @param licenciadaId - id da licenciada
 * @returns - projeto or null if not found
 */
function useGetProjetoById(
  empresaId: TEmpresa["id"],
  projetoId: TProjeto["id"],
  licenciadaId?: TLicenciada["id"] | undefined
) {
  return useQuery<TGetProjetoResponse, FirestoreError, TProjeto | null>({
    queryKey: [QUERY_KEY, projetoId, empresaId, licenciadaId],
    queryFn() {
      if (!empresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!projetoId) {
        throw new Error("Ideia não selecionada");
      }

      return getProjetosItem(empresaId, projetoId, licenciadaId);
    },
    select(dataResponse) {
      if (!dataResponse.exists()) {
        return null;
      }

      return dataResponse.data();
    },
    enabled: !!projetoId && !!empresaId
  });
}

export default useGetProjetoById;
