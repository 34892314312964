/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import { Text, FocusTrap } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import { TRegiaoForm } from "@/modules/regiao/firestore";
import RegiaoForm from "../../regiao-form";

type Properties = ContextModalProps<{ item: TRegiaoForm }>;

const RegiaoFormModal = ({ context, innerProps, id }: Properties) => {
  const { item: regiao } = innerProps;

  const onSuccess = () => {
    context.closeModal(id);
  };

  return (
    <FocusTrap active={true}>
      <Text fw="bold" mb="md">
        Cadastro de Região
      </Text>
      <RegiaoForm regiao={regiao} onSuccess={onSuccess} />
    </FocusTrap>
  );
};

export default RegiaoFormModal;
