import { collection, doc, FirestoreDataConverter, Timestamp, updateDoc } from "firebase/firestore";
import { firestore } from "@/base";
import { TConviteForm } from "../empresas/firestore";

type TConviteDocument = Omit<TConvite, "createdAt" | "updatedAt"> & {
  createdAt: Timestamp;
  updatedAt?: Timestamp;
};

const conviteConverter: FirestoreDataConverter<TConvite> = {
  toFirestore(data) {
    delete data.id;
    return data;
  },
  fromFirestore(snap) {
    const { createdAt, updatedAt, ...document } = snap.data() as TConviteDocument;

    const data: TConvite = {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate()
    };

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }
    return data;
  }
};

/**
 * Get all empresas collection reference
 * @returns - Empresas collection reference
 */
function getConviteCollectionReference() {
  return collection(firestore, "convites");
}

/**
 * Get a empresa document reference
 * @param conviteId - Empresa id
 * @returns - Empresa document reference
 */
function getConviteDocumentReference(conviteId: TEmpresa["id"]) {
  const empresasCollectionReference = getConviteCollectionReference().withConverter(conviteConverter);
  return doc(empresasCollectionReference, conviteId);
}

/**
 * Update a empresa to the database
 * @param conviteId - Empresa id
 * @param convite - Empresa to update
 * @returns - Promise with the empresa reference
 */
export function updateConvite(conviteId: TConvite["id"], convite: TConviteForm) {
  const conviteDocumentReference = getConviteDocumentReference(conviteId).withConverter(conviteConverter);
  return updateDoc(conviteDocumentReference, { ...convite });
}
