import { getAnalytics, setAnalyticsCollectionEnabled, isSupported } from "firebase/analytics";
import { captureException } from "@/services/log";
import app from "./app";

const analytics = getAnalytics(app);

/**
 * Setup firebase analytics
 */
async function setupAnalytics() {
  const isAnalyticsSupported = await isSupported();
  if (isAnalyticsSupported) {
    setAnalyticsCollectionEnabled(analytics, import.meta.env.PROD);
  }
}

// eslint-disable-next-line unicorn/prefer-top-level-await
setupAnalytics().catch((error: Error) => captureException(error));

// eslint-disable-next-line import/no-unused-modules
export default analytics;
