import { Select, SelectProps } from "@mantine/core";
import useGetCategoria from "./useGetCategoria";

interface Tipo {
  nome?: string;
  id?: string;
}

const CategoriaSelector = (properties: SelectProps) => {
  const { data, isLoading, error } = useGetCategoria();
  const formatData = data?.map((item: Tipo) => ({
    value: item.id || "",
    label: item.nome || ""
  }));

  return <Select data={formatData} disabled={isLoading} error={error?.message} {...properties} />;
};

export default CategoriaSelector;
