/* eslint-disable react-perf/jsx-no-new-array-as-prop */
import { Button, Card, Divider, Flex, Group, Loader, Stack, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useGetResultEmpresa } from "@/modules/media-grupo/hooks/useGetResultEmpresa";
import useGetProjetoFromUrl from "./useGetProjetoFromUrl";
import useGetQuestionarioFromUrl from "../estrategia-list/useGetQuestionarioFromUrl";
import RadarNota from "../radar/RadarNota";

type Properties = {
  grupos: TGruposDiagnostico[];
};

type WrapperProperties<T> = {
  children: React.ReactNode;
  items: T[];
};

/**
 *  Wrapper que condiciona a renderização do children baseado na quantidade de items
 * @param root0 Props
 * @param root0.children Componentes a serem renderizados
 * @param root0.items Itens a serem verificados
 * @returns Componente a ser renderizado
 */
function ConditionalWrapper<T>({ children, items }: WrapperProperties<T>) {
  if (items.length === 0) {
    return null;
  }

  return children;
}

const CardResultados = ({ grupos }: Properties) => {
  const [opened, { toggle }] = useDisclosure(false);

  const { idEmpresa } = useParams<{ id: string; idEmpresa: string }>();
  const { data: projeto } = useGetProjetoFromUrl();
  const { data: questionario } = useGetQuestionarioFromUrl();

  const { load, loading, resultEmpresa, resultCategoria, resultGeral, resultRegiao } = useGetResultEmpresa();

  const resultEmpresaDataset = useMemo(() => [{ id: "Empresa", data: resultEmpresa }], [resultEmpresa]);

  const resultCategoriaDataset = useMemo(
    () => [
      { id: "Média do segmento", data: resultCategoria },
      { id: "Empresa", data: resultEmpresa }
    ],
    [resultEmpresa, resultCategoria]
  );

  const resultRegiaoDataset = useMemo(
    () => [
      { id: "Média regional", data: resultRegiao },
      { id: "Empresa", data: resultEmpresa }
    ],
    [resultEmpresa, resultRegiao]
  );

  const resultGeralDataset = useMemo(
    () => [
      { id: "Média geral", data: resultGeral },
      { id: "Empresa", data: resultEmpresa }
    ],
    [resultEmpresa, resultGeral]
  );

  const labels = useMemo(() => grupos.map((grupo) => grupo.nome), [grupos]);

  useEffect(() => {
    if (questionario && projeto && idEmpresa) {
      void load(questionario.id, projeto.id, idEmpresa, grupos);
    }
  }, [questionario?.id, projeto?.id, idEmpresa]);

  if (loading) {
    return (
      <Card withBorder shadow="md" style={{ flex: 1, marginBottom: 15 }}>
        <Group justify="center">
          <Loader size="md" />
        </Group>
      </Card>
    );
  }

  return (
    <Card withBorder shadow="md" style={{ flex: 1, marginBottom: 15 }}>
      <Flex justify="space-between" align="center">
        <Text size="xl">Resumo dos resultados</Text>
        <Button
          variant="transparent"
          size="compact-sm"
          rightSection={opened ? <IconChevronUp size="1rem" /> : <IconChevronDown size="1rem" />}
          onClick={toggle}
        >
          Ver mais
        </Button>
      </Flex>
      <Text size="xs">Média das notas recebidas pela empresa nos diversos segmentos do questionário</Text>
      {opened && (
        <Flex direction="column">
          <ConditionalWrapper items={resultEmpresa}>
            <Divider my="md" />
            <Stack h={400} justify="center" align="center" pt="md" gap="0">
              <Text size="lg" fw="bold">
                Resultado da empresa
              </Text>
              <RadarNota datasets={resultEmpresaDataset} segments={labels} />
            </Stack>
          </ConditionalWrapper>

          <ConditionalWrapper items={resultRegiao}>
            <Divider my="md" />
            <Stack h={400} justify="center" align="center" pt="md" gap="0">
              <Text size="lg" fw="bold">
                Média regional
              </Text>
              <RadarNota datasets={resultRegiaoDataset} segments={labels} />
            </Stack>
          </ConditionalWrapper>

          <ConditionalWrapper items={resultCategoria}>
            <Divider my="md" />
            <Stack h={400} justify="center" align="center" pt="md" gap="0">
              <Text size="lg" fw="bold">
                Média do segmento
              </Text>
              <RadarNota datasets={resultCategoriaDataset} segments={labels} />
            </Stack>
          </ConditionalWrapper>

          <ConditionalWrapper items={resultGeral}>
            <Divider my="md" />
            <Stack h={400} justify="center" align="center" pt="md" gap="0">
              <Text size="lg" fw="bold">
                Media geral
              </Text>
              <RadarNota datasets={resultGeralDataset} segments={labels} />
            </Stack>
          </ConditionalWrapper>
          <Divider my="md" />
        </Flex>
      )}
    </Card>
  );
};

export default CardResultados;
