import { TransformedValues, useForm, yupResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { UseMutationOptions, useMutation, useQueryClient } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { Asserts } from "yup";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { TColaboradorForm, updateColaborador } from "../../firestore";
import { inviteColaboradorAdd } from "../../functions";
import colaboradorSchema from "../../schema/colaborador-schema";
import { QUERY_KEY } from "../colaboradores-table/ColaboradoresTable";

type FormInputs = Asserts<typeof colaboradorSchema>;
type TUpdateResponse = Awaited<ReturnType<typeof updateColaborador>>;
type TAddResponse = Awaited<ReturnType<typeof inviteColaboradorAdd>>;

/**
 * Colaborador Form hook
 * @param colaborador - Colaborador data
 * @param root0 - Options
 * @param root0.onSuccess - On success callback]
 * @param root0.onError - On error callback
 * @param setName - On success callback
 * @returns - Colaborador Form
 */
// prettier-ignore
function useColaboradorForm(
  colaborador: TColaboradorForm & Partial<Pick<TColaborador, "id" | "refPath">>,
  {
    onSuccess,
    onError
  }: Pick<UseMutationOptions<TUpdateResponse | TAddResponse, FirestoreError, FormInputs>, "onSuccess" | "onError">,
  setName?: (name: string) => void
) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const activeLicenciadaId = useUserStore((state) => state.activeLicenciadaId);
  const queryClient = useQueryClient();
  const form = useForm<FormInputs>({
    validate: yupResolver(colaboradorSchema),
    transformValues: (values) => colaboradorSchema.cast(values),
    initialValues: colaborador
  });
  // "nome", "email", "role", "telefone", "image", "setores", "dataNascimento"
  const { mutate, isPending, reset } = useMutation<TUpdateResponse | TAddResponse, FirestoreError, FormInputs>({
    mutationFn: (values) => {
      if (!activeEmpresaId) {
        throw new Error("Nenhuma empresa selecionada");
      }
      return colaborador.id
        ? updateColaborador(activeEmpresaId, colaborador.id, values)
        : inviteColaboradorAdd({
            nome:values?.nome,
            email:values?.email,
            role:values.role,
            telefone:values?.telefone,
            image:values?.image,
            setores:values?.setores,
            dataNascimento:values?.dataNascimento,
            licenciadaId: activeLicenciadaId,
            empresaId: activeEmpresaId,
            gestao: values.gestao,
            incentivo: values.incentivo,
            diagnostico: values.diagnostico,
            configuracoes: values.configuracoes  });
    },
    async onSuccess(data, variables, context) {
      showNotification({
        message: `${variables.nome} ${colaborador.id ? "atualizado" : "cadastrado"} com sucesso!`,
        color: "green",
        autoClose: 2500
      });
      reset();
      form.reset();
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });

      if (setName) {
        setName(variables.nome);
      }

      const {colaborador: colaboradorState} = useUserStore.getState()
      if (colaboradorState) {
        useUserStore.setState({ colaborador: { ...colaboradorState, nome: variables.nome } });
      }

      if (onSuccess) {
        return onSuccess(data, variables, context);
      }
      return null;
    },
    onError(error, variables, context) {
      captureException(error, true);
      if (onError) {
        onError(error, variables, context);
      }
    }
  });

  type TFormTransformed = TransformedValues<typeof form>;

  /**
   * Handle form submit
   * @param values - Form values
   */
  function handleFormSubmit(values: TFormTransformed) {
    mutate(values);
  }

  return { form, isPending, onSubmit: form.onSubmit(handleFormSubmit) };
}

export default useColaboradorForm;
