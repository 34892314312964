import { Text } from "@mantine/core";
import useGetTipoDeInovacaoItem from "./useGetTipoDeInovacaoFromUrl";

interface Properties {
  id: string;
}
// prettier-ignore
const JogadasTipoLabel = (properties: Properties) => {
  const { data } = useGetTipoDeInovacaoItem(properties?.id);

  return <Text tt="capitalize" fz="sm" fw={400} color={"rgba(21, 22, 25, 1)"}>
    {data?.nome}
  </Text>
}

export default JogadasTipoLabel;
