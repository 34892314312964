import { subject } from "@casl/ability";
import {
  Timestamp,
  FirestoreDataConverter,
  collection,
  getDocs,
  query,
  where,
  addDoc,
  serverTimestamp,
  updateDoc,
  doc
} from "firebase/firestore";
import { getCurrentUser } from "@/services/auth";
import { getEmpresaDocumentReference } from "../empresas/firestore";

type TStatusDocument = Omit<TStatus, "createdAt" | "updatedAt" | "deletedAt"> & {
  createdAt: Timestamp;
  updatedAt?: Timestamp;
  deletedAt: Timestamp | null;
};

type TStatusFormFields = Omit<TStatus, "id" | "refPath" | "createdBy" | "updatedBy" | "deletedAt"> &
  Partial<Pick<TStatus, "id">>;

export type TStatusForm = TStatusFormFields & TStatusDocument;

const statusConverter: FirestoreDataConverter<TStatus> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const { updatedAt, deletedAt, createdAt, ...document } = snap.data() as TStatusDocument;

    const data: TStatus = subject("TStatus", {
      ...document,
      id: snap?.id,
      createdAt: createdAt.toDate(),
      deletedAt: deletedAt ? deletedAt.toDate() : null,
      refPath: snap.ref.path
    });

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }

    return data;
  }
};

/**
 * Get questionário query for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param idProjeto - id do projeto
 * @param idEmpresaItem - id empresa
 * @returns - questionário query
 */
function generateStatusQuery(empresaId: TEmpresa["id"], idProjeto: string | undefined, idEmpresaItem: TEmpresa["id"]) {
  const categoriasInovacaoCollectionReference = getStatusCollectionReference(empresaId).withConverter(statusConverter);

  return query(
    categoriasInovacaoCollectionReference,
    where("idProjeto", "==", idProjeto),
    where("idEmpresa", "==", idEmpresaItem)
  );
}

/**
 * Get questionário for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param idProjeto -id projeto
 * @param idEmpresaItem - idProjeto id to get comitês
 * @returns - questionário document snapshot
 */
export function getStatus(empresaId: TEmpresa["id"], idProjeto: string | undefined, idEmpresaItem: TEmpresa["id"]) {
  const q = generateStatusQuery(empresaId, idProjeto, idEmpresaItem);
  return getDocs(q);
}

/**
 * Get Status collection reference from empresaId
 * @param empresaId - Empresa id to get the Status collection reference
 * @returns - Status collection reference
 */
function getStatusCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, "status");
}

/**
 * Get Status document reference from empresaId
 * @param empresaId - Empresa id to get the Status document reference
 * @param statusId - Status id to get the Status document reference
 * @returns - Status document reference
 */
function getStatusDocumentReference(empresaId: TEmpresa["id"], statusId: TStatus["id"]) {
  const StatusCollectionReference = getStatusCollectionReference(empresaId);
  return doc(StatusCollectionReference, statusId);
}

/**
 * Update a empresa to the database
 * @param empresaId - Empresa id
 * @param statusId - status id to update
 * @param status - status data
 * @returns - Promise with the grupos reference
 */
export function updateStatus(empresaId: TEmpresa["id"], statusId: TStatus["id"], status: TStatusForm) {
  const gruposDocumentReference = getStatusDocumentReference(empresaId, statusId).withConverter(statusConverter);
  const currentUserId = getCurrentUser()?.uid;
  return updateDoc(gruposDocumentReference, {
    ...status,
    updatedAt: serverTimestamp(),
    updatedBy: currentUserId
  });
}

/**
 * Add a new Acesso to the given empresa
 * @param empresaId - Empresa id to add the Acesso
 * @param status - Acesso data
 * @returns - Acesso document reference
 */
export function addStatus(empresaId: TEmpresa["id"], status: TStatusForm) {
  const statusCollectionReference = getStatusCollectionReference(empresaId);
  const currentUserId = getCurrentUser()?.uid;
  delete status.id;

  return addDoc(statusCollectionReference, {
    ...status,
    // createdAt: serverTimestamp(),
    createdBy: currentUserId,
    deletedAt: null
  });
}
