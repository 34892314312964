import { Box, Card, Group, LoadingOverlay, ScrollArea, Space, Text } from "@mantine/core";
import useGetQuestionariosEmpresaConvidada from "../../hooks/useGetQuestionariosEmpresaConvidada";
import QuestionarioItem from "../questionario-item";

const QuestionariosList = () => {
  const { data: questionariosConvidados, isFetching } = useGetQuestionariosEmpresaConvidada();
  if (isFetching) {
    return (
      <Box pos="relative">
        <LoadingOverlay visible={isFetching} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
      </Box>
    );
  }

  return (
    <Card withBorder shadow="md">
      <Space h="md" />
      <Group justify="space-between" align="center">
        <Text size="xl">Questionários aplicados</Text>
      </Group>
      <Space h="md" />
      <ScrollArea w={"100%"}>
        {questionariosConvidados?.length ? (
          questionariosConvidados.map((item) => (
            <QuestionarioItem
              key={item.questionarioId}
              empresaId={item.empresaId}
              questionarioId={item.questionarioId}
            />
          ))
        ) : (
          <Group justify="center">
            <Text fw={700}>Nenhum Questionário encontrado!</Text>
          </Group>
        )}
      </ScrollArea>
    </Card>
  );
};

export default QuestionariosList;
