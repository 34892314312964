import { rem } from "@mantine/core";
import { modals } from "@mantine/modals";
import { SpotlightActionData, Spotlight } from "@mantine/spotlight";
import { IconArrowRight, IconHome, IconPlus, IconSearch, IconTable } from "@tabler/icons-react";
import { memo, useMemo, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ability } from "@/components/can/Can";

const CONFIGURACOES_GROUP = "Configurações" as const;
const GESTAO_INOVACAO_GROUP = "Gestação de Inovação" as const;
/**
 * Generate spotlight actions
 * @returns - Spotlight actions
 */
function useBaseSpotlightActions() {
  const navigate = useNavigate();

  return useMemo<SpotlightActionData[]>(() => {
    const canIReadEmpresas = ability.can("read", "TEmpresa");
    const canIInviteEmpresa = ability.can("create", "TEmpresa");
    const canIReadColaborador = ability.can("read", "TColaborador");
    const canIReadIdeias = ability.can("read", "TIdeia");
    const canIReadRegulamentoIdeias = ability.can("read", "TRegulamentoIdeias");
    const canIReadDesafios = ability.can("read", "TDesafio");
    const canIReadComiteDeInovacao = ability.can("read", "TComiteInovacao");
    const canIInviteColaborador = ability.can("create", "TColaborador");
    const canIReadCategoriasDeInovacao = ability.can("read", "TCategoriaDeInovacao");

    const defaultActions: SpotlightActionData[] = [
      {
        id: "home",
        label: "Home",
        description: "Ir para página inicial",
        leftSection: <IconHome size="1.2rem" />,
        onClick: () => navigate("/")
      },
      {
        id: "home",
        label: "Minhas Atividades",
        description: "Ir para Minhas Atividades",
        leftSection: <IconArrowRight size="1.2rem" />,
        onClick: () => navigate("/")
      },
      {
        id: "home",
        label: "Atividades",
        description: "Ir para Atividades",
        group: GESTAO_INOVACAO_GROUP,
        leftSection: <IconArrowRight size="1.2rem" />,
        onClick: () => navigate("/")
      }
    ];

    if (canIInviteEmpresa) {
      defaultActions.push({
        id: "convidar-empresa",
        label: "Convidar Empresa",
        group: CONFIGURACOES_GROUP,
        leftSection: <IconPlus size="1.2rem" />,
        onClick: () => {
          modals.openContextModal({
            modal: "invite-empresa-modal",
            size: "xl",
            innerProps: {},
            ml: "110px"
          });
        }
      });
    }

    if (canIInviteColaborador) {
      defaultActions.push({
        id: "convidar-colaborador",
        label: "Convidar Colaborador",
        group: CONFIGURACOES_GROUP,
        leftSection: <IconPlus size="1.2rem" />,
        onClick: () => {
          modals.openContextModal({
            modal: "invite-colaborador-modal",
            size: "xl",
            innerProps: {},
            ml: "110px"
          });
        }
      });
    }

    if (canIReadEmpresas) {
      defaultActions.push({
        id: "listar-empresa",
        label: "Listar Empresas",
        group: CONFIGURACOES_GROUP,
        leftSection: <IconTable size="1.2rem" />,
        onClick: () => navigate("/empresas")
      });
    }
    if (canIReadColaborador) {
      defaultActions.push({
        id: "listar-colaborador",
        label: "Listar Colaboradores",
        group: CONFIGURACOES_GROUP,
        leftSection: <IconTable size="1.2rem" />,
        onClick: () => navigate("/colaboradores")
      });
    }

    if (canIReadIdeias) {
      defaultActions.push({
        id: "listar-ideias",
        label: "Listar Ideias",
        group: CONFIGURACOES_GROUP,
        leftSection: <IconTable size="1.2rem" />,
        onClick: () => navigate("/ideias")
      });
    }

    if (canIReadCategoriasDeInovacao) {
      defaultActions.push({
        id: "listar-categorias",
        label: "Listar Categorias",
        group: CONFIGURACOES_GROUP,
        leftSection: <IconTable size="1.2rem" />,
        onClick: () => navigate("/categorias-de-inovacao")
      });
    }

    if (canIReadComiteDeInovacao) {
      defaultActions.push({
        id: "listar-comites-de-inovacao",
        label: "Listar Comitês de Inovação",
        group: "Gestação de Inovação",
        leftSection: <IconTable size="1.2rem" />,
        onClick: () => navigate("/comites-de-inovacao")
      });
    }
    if (canIReadRegulamentoIdeias) {
      defaultActions.push({
        id: "listar-ideias-regulamentos",
        label: "Listar Regulamento de Ideias",
        leftSection: <IconTable size="1.2rem" />,
        onClick: () => navigate("/ideias/regulamentos")
      });
    }
    if (canIReadDesafios) {
      defaultActions.push({
        id: "listar-desafios",
        group: GESTAO_INOVACAO_GROUP,
        label: "Listar Desafios",
        leftSection: <IconTable size="1.2rem" />,
        onClick: () => navigate("/desafios")
      });
    }

    return defaultActions;
  }, [navigate]);
}

const AuthenticatedRootRoute = () => {
  const baseActions = useBaseSpotlightActions();
  const [actions] = useState<SpotlightActionData[]>(baseActions);

  return (
    <>
      <Spotlight
        actions={actions}
        highlightQuery
        searchProps={{
          // eslint-disable-next-line no-magic-numbers
          leftSection: <IconSearch style={{ width: rem(20), height: rem(20) }} stroke={1.5} />,
          placeholder: "Buscar..."
        }}
        shortcut="mod + k"
        nothingFound="Nada encontrado..."
      />
      <Outlet />
    </>
  );
};

export default memo(AuthenticatedRootRoute);
