const MEDIA_GRUPO_SCALE = 100;

/**
 * Função que calcula a média de um grupo de perguntas
 * @param root0 - Objeto que contém as propriedades necessárias para calcular a média
 * @param root0.respostasCount - Quantidade de respostas
 * @param root0.perguntasSum - Soma das perguntas
 * @returns Retorna a média do grupo de perguntas
 */
function getMediaGrupoNota({ respostasCount, perguntasSum }: Pick<TMediaGrupo, "respostasCount" | "perguntasSum">) {
  const mediaRounded = Math.round(perguntasSum / respostasCount);
  return mediaRounded / MEDIA_GRUPO_SCALE;
}

export { getMediaGrupoNota };
