import { Card, Divider, Flex, Grid, Group, Space, Text } from "@mantine/core";
import { useParams } from "react-router-dom";
import RespostaComponente from "@/modules/diagnostico/components/questionario-item/RespostaComponente";
import TextAcesso from "@/modules/diagnostico/components/questionario-item/TextAcesso";
import useGetProjetoFromUrl from "@/modules/diagnostico/components/ranking-list/hooks/useGetProjetoFromUrl";
import NomePlano from "./NomePlano";
import NotaMedia from "./NotaMedia";

const CardPremio = () => {
  const { id } = useParams<{ id: string; idProjeto: string }>();
  const { data } = useGetProjetoFromUrl();
  const space = "space-between";

  return (
    <Card withBorder shadow="md" style={{ flex: 1, marginBottom: 15 }}>
      <NomePlano />
      <Flex direction="row" justify="space-between">
        <Text size="xl">{data?.nome}</Text>
        <Flex direction="row" gap={15}>
          {/* <Badge
            radius="md"
            size="xl"
            variant="outline"
            style={{ background: "#525356" }}
            color="rgba(244, 245, 246, 1)"
          >
            <Flex direction="row" align="center" gap={4}>
              <IconArrowBack color="white" size="14px" />
              <Text tt="capitalize" fz="12px" fw={500} color={"white"}>
                Voltar
              </Text>
            </Flex>
          </Badge> */}
        </Flex>
      </Flex>
      <Divider my="md" />
      <Grid justify={space}>
        <Grid.Col span={3}>
          <TextAcesso id={id || ""} />
        </Grid.Col>
        <Grid.Col span={3}>
          <RespostaComponente id={id || ""} fw={700} fz="xl" />
        </Grid.Col>
        <Grid.Col span={3}>
          <Flex direction="column">
            <Text size="xs">Nota média</Text>
            <Space h="md" />
            <Group>
              <NotaMedia />
              {/* <Group justify="flex-end" align="flex-end" gap="xs">
                <IconChartInfographic color={color} />
                <Text color={color} size="xs">
                  acréscimo de 3,5%
                </Text>
              </Group> */}
            </Group>
          </Flex>
        </Grid.Col>
      </Grid>
    </Card>
  );
};

export default CardPremio;
