import { Card, Text, Space, Group, Badge, Button } from "@mantine/core";
import { useState } from "react";
import CategoriaSelector from "@/modules/jogadas-de-inovacao/components/categoria-de-inovacao-selector";
import NewTaticasComponent from "../new-taticas";
import TipoDeInovacaoSelector from "../tipo-de-inovacao-selector";
interface Tatica {
  id: string | number;
  nome: string;
  descricao: string;
  nomeCategoria: string | undefined;
  nomeTipo: string | undefined;
}

interface NewTaticas {
  id: number;
  nome: string;
  descricao: string;
  vinculos?: number[] | undefined;
}

interface MyState {
  categoria: string;
  tipo: string;
}

const HEX_DIGITS = 16;
const COLOR_LENGTH = 6;
const MAX_HEX_COLOR = 0xffffff; // eslint-disable-line unicorn/numeric-separators-style

/**
 * Handles input change for a specific field.
 * @param field - The name of the field being updated
 * @param setValues - The state setter function
 * @returns - function
 */
function handleInputChange(field: string, setValues: React.Dispatch<React.SetStateAction<MyState>>) {
  return (event: string) => {
    setValues((values: MyState) => ({ ...values, [field]: event }));
  };
}
// prettier-ignore
// eslint-disable-next-line complexity
const AddTaticaDeJogadas = ({
  setTaticas,
  setAddTatica
}: {
  setTaticas: React.Dispatch<React.SetStateAction<Tatica[]>>;
  setAddTatica: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [newTaticas, setNewTaticas] = useState<NewTaticas[]>([]);
  const [values, setValues] = useState<MyState>({ categoria: "", tipo: "" });

  return (
    <Card withBorder >
      <Text size="sm" fw={300}>
        Selecione a categoria, o tipo e a tática de inovação para montar a jogada de inovação
      </Text>
      <Space h="xl" />
      <Card>
        <Group>
          <Badge size="lg" color="#2056f4" circle>
            1
          </Badge>
          <Text size="md" fw={500}>
            Selecione a categoria de inovação
          </Text>
        </Group>
        <Space h="xl" />
        <CategoriaSelector
          onChange={handleInputChange("categoria", setValues)}
          value={values.categoria}
          placeholder="Categoria de inovação"
          label=""
        />
      </Card>
      <Space h="xl" />
      <Card>
        <Group>
          <Badge size="lg" color={values.categoria === "" ? "#cdd1dc" : "#2056f4"} circle>
            2
          </Badge>
          <Text size="md" fw={500} color={values.categoria === "" ? "#cdd1dc" : "#000"}>
            Selecione o tipo de inovação
          </Text>
        </Group>
        <Space h="xl" />
        <TipoDeInovacaoSelector
          placeholder="Tipo de inovação"
          onChange={handleInputChange("tipo", setValues)}
          value={values.tipo}
          disabled={values.categoria === ""}
          label=""
          categoria={values.categoria}
        />
      </Card>
      <Space h="xl" />
      <Card>
        <Group justify={"space-between"}>
          <Group>
            <Badge size="lg" color={values.tipo === "" ? "#cdd1dc" : "#2056f4"} circle>
              3
            </Badge>
            <Text size="md" fw={500} color={values.tipo === "" ? "#cdd1dc" : "#000"}>
              Selecione as táticas
            </Text>
          </Group>
          <Button variant="filled" color="rgba(8, 68, 244, 1)" disabled={newTaticas.filter(item => !item.vinculos).length <= 1} onClick={linkedTaticas(setNewTaticas)}>
            Vincular táticas
          </Button>
        </Group>
        <Space h="xl" />
        <NewTaticasComponent
          tipo={values.tipo}
          categoria={values.categoria}
          newTaticas={newTaticas}
          setNewTaticas={setNewTaticas}
          setAddTatica={setAddTatica}
          setValues={setValues}
          setTaticas={setTaticas}
        />
      </Card>
    </Card>
  );
};

export default AddTaticaDeJogadas;
// prettier-ignore
const linkedTaticas = (setNewTaticas: React.Dispatch<React.SetStateAction<NewTaticas[]>>) => {
  return () => {
    const randomColor = '#' + Math.trunc(Math.random() * MAX_HEX_COLOR).toString(HEX_DIGITS).padStart(COLOR_LENGTH, '0');
    // eslint-disable-next-line unicorn/no-negated-condition
    setNewTaticas((taticas) => taticas.map(item => (!item.vinculos ? { ...item, vinculos: taticas.filter(row => row.id !== item.id).map(item => item.id), corVinculos: randomColor } : item)))
  };
};
