import DiagnosticoList from "@/modules/diagnosticos-empresa/components/diagnostico-list";

const DiagnosticosPage = () => {
  return (
    <>
      <DiagnosticoList />
    </>
  );
};

export default DiagnosticosPage;
