import { getCronogramaDesafio } from "@/modules/desafio-cronograma/firestore";

/**
 * Hook para obter os cronogramas
 * @param idEmpresa - id da empresa
 * @returns -  cronogramas
 */
async function getCronograma(idEmpresa: TEmpresa["id"]) {
  if (idEmpresa) {
    const newData = await getCronogramaDesafio(idEmpresa);
    return newData.docs.map((desafio) => desafio.data());
  }

  return [];
}

export default getCronograma;
