import { Card, Flex, Space, Title } from "@mantine/core";
import { QueryObserverResult } from "@tanstack/react-query";
import DesafioCronogramaFormItem from "@/modules/desafio-cronograma/components/desafio-cronograma-form-item";
import SolucoesCronogramaList from "@/modules/desafio-cronograma/components/solucoes-list";
import SolucoesCronogramaCard from "./SolucoesCronogramaCard";
import UsuariosCronogramaCard from "./UsuariosCronogramaCard";
// prettier-ignore

type DetailsDiaDoDesafioProperties = {
  data: TCronogramaDesafio;
  desafio: TDesafio;
  refetch: () => Promise<QueryObserverResult>;
};

const DetailsDiaDoDesafio = ({ data, desafio, refetch }: DetailsDiaDoDesafioProperties) => {
  return (
    <Card withBorder>
      <Flex direction="column" pb="xs">
        <Title order={4}>Imersão no desafio</Title>
        <Space h={"md"} />
        <DesafioCronogramaFormItem data={data} refetch={refetch} />
      </Flex>
      <UsuariosCronogramaCard inscritos={data?.inscricoes} desafio={desafio} refetch={refetch} />
      <SolucoesCronogramaList data={data} refetch={refetch} />
      <SolucoesCronogramaCard data={data} refetch={refetch} />
    </Card>
  );
};

export default DetailsDiaDoDesafio;
