import { useForm, yupResolver, TransformedValues } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { Asserts } from "yup";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { isAudit } from "@/utils/audit";
import {
  REGULAMENTOS_DESAFIOS_COLLECTION_KEY,
  TRegulamentoDesafiosForm,
  addRegulamentoDesafios,
  updateRegulamentoDesafios
} from "../../firestore";
import regulamentoDesafioFormSchema from "../../schema";

type FormInputs = Asserts<typeof regulamentoDesafioFormSchema>;
type TFirestoreResponse =
  | Awaited<ReturnType<typeof addRegulamentoDesafios>>
  | Awaited<ReturnType<typeof updateRegulamentoDesafios>>;

/**
 * RegulamentoDesafios Form hook
 * @param regulamentoDesafios - The regulamento data
 * @param onSuccess - On success callback
 * @returns - The regulamento form
 */
function useRegulamentoDesafiosForm(
  regulamentoDesafios: TRegulamentoDesafiosForm | TRegulamentoDesafios,
  onSuccess: () => void
) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const queryClient = useQueryClient();

  const initialValues = {
    ...(regulamentoDesafioFormSchema.getDefault({ value: regulamentoDesafios }) as TRegulamentoDesafiosForm),
    ...regulamentoDesafios
  } as TRegulamentoDesafiosForm;

  const form = useForm<TRegulamentoDesafiosForm>({
    initialValues,
    validate: yupResolver(regulamentoDesafioFormSchema),
    transformValues: (values) => regulamentoDesafioFormSchema.cast(values, { stripUnknown: true })
  });

  const { mutate, isPending, reset } = useMutation<TFirestoreResponse, FirestoreError, FormInputs>({
    mutationFn: (values) => {
      if (!activeEmpresaId) {
        throw new Error("Nenhuma empresa selecionada");
      }

      const auditData = regulamentoDesafios as TAudit;
      if (isAudit(auditData) && !!auditData.id) {
        return updateRegulamentoDesafios(activeEmpresaId, auditData.id, values);
      }

      return addRegulamentoDesafios(activeEmpresaId, values);
    },
    async onSuccess(_data, variables) {
      const auditData = regulamentoDesafios as TAudit;
      const wasUpdated = isAudit(auditData) && !!auditData.id;

      showNotification({
        message: `${variables.nome} ${wasUpdated ? "atualizado" : "cadastrado"} com sucesso!`,
        color: "green",
        autoClose: 2500
      });
      reset();
      form.reset();
      await queryClient.invalidateQueries({ queryKey: [activeEmpresaId, ...REGULAMENTOS_DESAFIOS_COLLECTION_KEY] });
      onSuccess();
    },
    onError(error) {
      captureException(error, true);
    }
  });

  type TFormTransformed = TransformedValues<typeof form>;

  /**
   * Handle form submit
   * @param values - Form values
   */
  function handleFormSubmit(values: TFormTransformed) {
    mutate(values);
  }

  return { form, isPending, onSubmit: form.onSubmit(handleFormSubmit) };
}

export default useRegulamentoDesafiosForm;
