import { FileWithPath } from "@mantine/dropzone";
import { serverTimestamp } from "firebase/firestore";
import { ObjectSchema } from "yup";
import schema from "@/schema";
import { getCurrentUser } from "@/services/auth";
// import empresaSchema from "./empresa-schema";
import { TProjetoForm, TProjetoDatabaseFields, TProjetoFormFields } from "../firestore";

const MIN_NAME_LENGTH = 3;

/**
 * Define only the meta fields that are not part of the form
 */
const projetoSchemaDatabaseFields: ObjectSchema<TProjetoDatabaseFields> = schema.object().shape({
  createdAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .required()
    .defined()
    .nonNullable()
    .default<ReturnType<typeof serverTimestamp>>(() => serverTimestamp()),
  createdBy: schema
    .string()
    .required()
    .defined()
    .nonNullable()
    .default(() => {
      const currentUserId = getCurrentUser()?.uid;
      if (!currentUserId) {
        throw new Error("The current user is not defined");
      }
      return currentUserId;
    }),
  updatedAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .default<ReturnType<typeof serverTimestamp>>(() => serverTimestamp()),
  updatedBy: schema.string().default(() => {
    const currentUserId = getCurrentUser()?.uid;
    if (!currentUserId) {
      throw new Error("The current user is not defined");
    }
    return currentUserId;
  }),
  deletedAt: schema.mixed<ReturnType<typeof serverTimestamp>>().defined().nullable().default(null)
});

/**
 * Define the schema for the form
 */
// prettier-ignore
const projetoSchemaFormFields: ObjectSchema<TProjetoFormFields> = schema.object({
  id: schema.string().optional(),
  refPath: schema.string().optional(),
  nome: schema.string().trim().min(MIN_NAME_LENGTH).required().defined().label("Nome"),
  licenciado: schema.string().trim().required().defined().label("Licenciado do projeto"),
  embaixadores: schema
    .array()
    .of(schema.string().optional().defined())
    .optional()
    .defined()
    .default([])
    .label("Entidades(es) do Projeto"),
    embaixadoresPessoas: schema
    .array()
    .of(schema.string().optional().defined())
    .optional()
    .defined()
    .default([])
    .label("Embaixador(es) do Projeto"),
  minimoResposta: schema.number().required().defined().label("Minimo de resposta por empresa"),
  posicao: schema.string().trim().required().defined().label("Posição do banners"),
  tempo: schema.number().required().defined().label("Tempo para a transição da imagem em milessegundos"),
  fases: schema.array().of(schema.object().optional().defined()).default([]).optional().label("Fases").optional(),
  urlImages: schema.array().optional().default([]).optional().label("Url").optional(),
  exibir: schema.boolean().optional().label("Exibir banner"),
  trocar: schema.boolean().optional().label("Trocar Imagem"),
  images: schema.array().of(schema.mixed<FileWithPath>().required().defined()).required().defined().default([]),
  categoria: schema
    .array()
    .of(schema.string().required().defined())
    .required()
    .defined()
    .min(1)
    .default([])
    .label("Categoria"),
  anoAtuacao: schema
    .date()
    .required()
    .default(new Date())
    .label("Data de início"),
  anoVigencia: schema
    .date()
    .required()
    .default(new Date())
    .label("Data de Fim"),
  linkRegulamento: schema.string().url().trim().required().defined().label("Link do regulamento")
  // empresa: empresaSchema.optional().label("Empresa")
});

const projetoSchema: ObjectSchema<TProjetoForm> = projetoSchemaFormFields
  // eslint-disable-next-line unicorn/prefer-spread
  .concat(projetoSchemaDatabaseFields)
  .noUnknown()
  .strict()
  .label("Projeto");

export default projetoSchema;
