import { Button } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconEdit } from "@tabler/icons-react";
import { QueryObserverResult } from "@tanstack/react-query";
import cronogramaSchema from "@/modules/desafio-cronograma/schema";

type RemoverImersaoButtonProperties = {
  cronograma: TCronogramaDesafio;
  refetch: () => Promise<QueryObserverResult>;
};

const EditaImersaoButton = ({ cronograma, refetch }: RemoverImersaoButtonProperties) => {
  /**
   * Handles the click event on the button.
   */
  function handleRemoveClick() {
    const schema = cronogramaSchema;
    const defaultValues = schema.getDefault() as unknown;
    const cronogramaDesafios: TCronogramaDesafio[] = [cronograma];
    modals.openContextModal({
      size: "xl",
      innerProps: {
        item: schema.cast(defaultValues, { stripUnknown: true, assert: false }),
        data: cronogramaDesafios,
        refetch: () => void refetch()
      },
      modal: "editar-cronograma-desafios"
    });
  }

  return (
    <Button variant="transparent" bg="transparent" onClick={handleRemoveClick}>
      <IconEdit size="1.2rem" />
      &nbsp; Editar
    </Button>
  );
};

EditaImersaoButton.displayName = "EditaImersaoButton";

export default EditaImersaoButton;
