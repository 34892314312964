import { TransformedValues, useForm, yupResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { MutationOptions, useMutation } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { Asserts } from "yup";
import useUserStore from "@/modules/users/store";
import { addJogadaDeInovacao, TJogadaInovacaoForm, updatedJogadaDeInovacao } from "../../firestore";
import jogadaDeInovacaoSchema from "../../schema/jogadas-de-inovacao-schema";

interface Tatica {
  id: string | number;
  nome: string;
  descricao: string;
}

type FormInputs = Asserts<typeof jogadaDeInovacaoSchema>;
type TUpdateResponse = Awaited<ReturnType<typeof updatedJogadaDeInovacao>>;
type TStartComiteInovacaoResponse = Awaited<ReturnType<typeof addJogadaDeInovacao>>;
// prettier-ignore
type TMutationOptions = Omit<
MutationOptions<TUpdateResponse | TStartComiteInovacaoResponse, FirestoreError, TJogadaInovacaoForm>,
"mutationFn"
>;
// prettier-ignore
const useAddJogadaDeInovacaoForm = (
  data: TJogadaInovacaoForm & Partial<Pick<TJogadaDeInovacao, "id" | "refPath">>,
  taticas: Tatica[],
  // tiposDeJogadas: TTipoDeJogada[],
  mutationOptions: TMutationOptions = {}
  ) => {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);
  const { tipo } = useParams<{ tipo: string }>();
  const form = useForm<FormInputs>({
    validate: yupResolver(jogadaDeInovacaoSchema),
    initialValues: data?.id
      ? (data as TJogadaInovacaoForm)
      : (jogadaDeInovacaoSchema.getDefault() as TJogadaInovacaoForm),
    transformValues: (values) => jogadaDeInovacaoSchema.cast(values)
  });
  const { mutate, isPending, reset } = useMutation<
    TUpdateResponse | TStartComiteInovacaoResponse,
    FirestoreError,
    TJogadaInovacaoForm
  >({
    mutationFn(values) {
      // const tipoDeJogadaId = tiposDeJogadas?.find((item) => item.id === values.tipoDeJogada)?.id;
      // const tipoDeJogadaNome = tiposDeJogadas?.find((item) => item.id === values.tipoDeJogada)?.nome;

      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      // if (tipoDeJogadaId === undefined) {
      //   throw new Error("Categoria não encontrada");
      // }

      // if (tipoDeJogadaNome === undefined) {
      //   throw new Error("Categoria não encontrada");
      // }
      return data?.id && tipo !== "customizada"
        ? updatedJogadaDeInovacao(activeEmpresaId, data.id, {
          ...values,
          implementada:false,
            taticas
          })
        : addJogadaDeInovacao(activeEmpresaId, {
            ...values,
            sugerida: tipo === "customizada" ? false : values.sugerida || false,
            taticas:taticas.map(item => ({...item, movimentacao:[]})),
            implementada:false,
          });
    },
    onSuccess() {
      showNotification({
        message: `${data.id ? "Atualizado" : "Cadastrado"} com sucesso!`,
        color: "green",
        autoClose: 2500
      });
      reset();
      form.reset();
      return null;
    },
    ...mutationOptions
  });

  type TTransformedValues = TransformedValues<typeof form>;
  /**
   * Handle form submit event
   * @param values - Form values
   */
  function onSubmit(values: TTransformedValues) {
    mutate(values);
  }

  return { ...form, isLoading: isPending, onSubmit: form.onSubmit(onSubmit) };
};

export default useAddJogadaDeInovacaoForm;
