import { Flex, Text } from "@mantine/core";
import { BarDatum, ResponsiveBar } from "@nivo/bar";
import { useEffect, useState } from "react";
import GetEmpresas from "./useGetEmpresa";

const stringKey = "Quantidade de empresas por estado";
// prettier-ignore
const keys = [stringKey];
// prettier-ignore
const defs = [
  {
    id: "dots",
    type: "patternDots",
    background: "inherit",
    color: "#38bcb2",
    size: 4,
    padding: 1,
    stagger: true
  },
  {
    id: "lines",
    type: "patternLines",
    background: "inherit",
    color: "#eed312",
    rotation: -45,
    lineWidth: 6,
    spacing: 10
  }
]
// prettier-ignore
const fill = [
  {
    match: {
      id: "fries"
    },
    id: "dots"
  },
  {
    match: {
      id: "sandwich"
    },
    id: "lines"
  }
]

const MAGIC_NUMBER = 1.6;

const message = "Ocorreu um erro ao carregar os dados:";

// prettier-ignore
const AreaRelatorioEstado = ({ empresas }: { empresas: TConvite[] }) => {
  const [formatEmpresas, setFormatEmpresas] = useState<TEmpresa[]>([])
  const resultado = formatarEmpresasPorEstado(formatEmpresas)

  const dataFormatted: BarDatum[] = resultado
    .filter(item => item[stringKey] !== undefined)
    .map(item => {
      const dynamicKey = keys[0]; // Supondo que você esteja usando a primeira chave da matriz `keys`
      const barDatum: BarDatum = {
        country: item.country
      };
      const quantidadeEmpresas = item[stringKey];
      if (quantidadeEmpresas !== undefined && dynamicKey) {
        barDatum[dynamicKey] = quantidadeEmpresas;
      }
      return barDatum;
    });

  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadData();
      } catch (error) {
        console.error(message, error);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, [empresas])

  const loadData = async () => {
    if (empresas.length > 0) {
      const newEmpresa = await GetEmpresas(empresas);
      setFormatEmpresas(newEmpresa)
    }
  }

  return <>
    <Text size={"md"} fw={700}>Quantidade de empresas inscritas por estado</Text>
    <Flex h={400} justify="center">
      <ResponsiveBar
        data={dataFormatted}
        keys={keys}
        indexBy="country"
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={{ scheme: 'category10' }}
        defs={defs}
        fill={fill}
        borderColor={{
          from: "color",
          modifiers: [
            [
              "darker",
              MAGIC_NUMBER
            ]
          ]
        }}
        axisTop={null}
        axisRight={null}
        colorBy="indexValue"
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [
            [
              "darker",
              MAGIC_NUMBER
            ]
          ]
        }}
        role="application"
        ariaLabel="Nivo bar chart demo"
      // barAriaLabel={e => e.id + ": " + e.formattedValue + " in country: " + e.indexValue}
      />
    </Flex>
  </>

};

export default AreaRelatorioEstado;

/**
 * Formata as empresas por estado, contando a quantidade de empresas em cada estado.
 * @param empresas - O array de empresas a serem formatadas.
 * @returns Um array de objetos representando a contagem de empresas por estado.
 */
// prettier-ignore
function formatarEmpresasPorEstado(empresas: TEmpresa[]) {
  // Inicializar objeto de contagem
  const contagemPorEstado: { [estado: string]: number } = {};

  // Iterar sobre o array de empresas usando for...of
  for (const empresa of empresas) {
    const estado = empresa.endereco.estado as string;
    if (contagemPorEstado[estado]) {
      contagemPorEstado[estado]++;
    } else {
      contagemPorEstado[estado] = 1;
    }
  }

  // Converter objeto de contagem para array de objetos
  return Object.keys(contagemPorEstado).map(estado => {
    return {
      "country": estado,
      [stringKey]: contagemPorEstado[estado]
    };
  });
}
