import { connectAuthEmulator, getAuth } from "firebase/auth";
import app from "./app";

const auth = getAuth(app);

if (import.meta.env.DEV) {
  const __DEFAULT_AUTH_PORT__ = 9099;
  const __ENV_AUTH_PORT__ = Number(import.meta.env.VITE_AUTH_PORT);
  const AUTH_PORT = Number.isInteger(__ENV_AUTH_PORT__) ? __ENV_AUTH_PORT__ : __DEFAULT_AUTH_PORT__;

  const __ENV_AUTH_HOST__ = import.meta.env.VITE_AUTH_HOST;
  const AUTH_HOST = __ENV_AUTH_HOST__ || "http://localhost";

  // eslint-disable-next-line no-console
  console.log("Connecting to Auth emulator at %s:%d", AUTH_HOST, AUTH_PORT);

  connectAuthEmulator(auth, AUTH_PORT ? `${AUTH_HOST}:${AUTH_PORT}` : AUTH_HOST);
}

auth.useDeviceLanguage();
auth.settings.appVerificationDisabledForTesting = import.meta.env.DEV;

export default auth;
