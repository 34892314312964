import { subject } from "@casl/ability";
import { FileWithPath } from "@mantine/dropzone";
import {
  addDoc,
  collection,
  collectionGroup,
  deleteDoc,
  doc,
  DocumentReference,
  FirestoreDataConverter,
  getDoc,
  getDocs,
  orderBy,
  Query,
  query,
  serverTimestamp,
  Timestamp,
  updateDoc,
  where
} from "firebase/firestore";
import { getDownloadURL } from "firebase/storage";
import { firestore } from "@/base";
import { getCurrentUser } from "@/services/auth";
import { uploadAnexosFilesToStorage } from "./storage";
import { getColaboradorDocumentReferenceFromPath, TColaboradorDocument } from "../colaboradores/firestore";
import { getEmpresaDocumentReference } from "../empresas/firestore";
import { getIdeiaDocumentReference } from "../ideias/firestore";

export type TMarcoDatabaseFields = Pick<TMarco, "createdBy" | "updatedBy"> & {
  createdAt: ReturnType<typeof serverTimestamp>;
  updatedAt?: ReturnType<typeof serverTimestamp>;
  startedAt: ReturnType<typeof serverTimestamp> | null;
  finishedAt: ReturnType<typeof serverTimestamp> | null;
};
export type TMarcoFormFields = Omit<
  TMarco,
  keyof TAudit | "colaboradoresResponsaveis" | "empresaReference" | "ideiaReference" | "startedAt" | "finishedAt"
> &
  Partial<Pick<TMarco, "id" | "refPath">> & {
    anexosFilesToUpload: FileWithPath[];
    colaboradoresResponsaveis: DocumentReference<TColaborador, TColaboradorDocument>[];
    empresaReference: DocumentReference<TEmpresa> | string;
    ideiaReference: DocumentReference<TIdeia> | string;
  };
export type TMarcoForm = TMarcoFormFields & TMarcoDatabaseFields;

type TMarcoDocument = Omit<
  TMarco,
  | "createdAt"
  | "updatedAt"
  | "startedAt"
  | "finishedAt"
  | "colaboradoresResponsaveis"
  | "empresaReference"
  | "ideiaReference"
  | "dataPrazo"
> & {
  createdAt: Timestamp;
  updatedAt?: Timestamp;
  startedAt: Timestamp | null;
  finishedAt: Timestamp | null;
  dataConclusao: Timestamp | null;
  colaboradoresResponsaveis: DocumentReference<TColaborador, TColaboradorDocument>[];
  empresaReference: DocumentReference<TEmpresa>;
  ideiaReference: DocumentReference<TIdeia>;
  dataPrazo: Timestamp;
};

const marcoConverter: FirestoreDataConverter<TMarco> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const {
      createdAt,
      updatedAt,
      startedAt,
      finishedAt,
      dataConclusao,
      colaboradoresResponsaveis,
      empresaReference,
      ideiaReference,
      dataPrazo,
      ...document
    } = snap.data() as TMarcoDocument;

    const data: TMarco = {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      startedAt: startedAt ? startedAt.toDate() : null,
      finishedAt: finishedAt ? finishedAt.toDate() : null,
      dataConclusao: dataConclusao ? dataConclusao.toDate() : null,
      refPath: snap.ref.path,
      colaboradoresResponsaveis: colaboradoresResponsaveis.map((reference) => reference.path),
      empresaReference: empresaReference.path,
      ideiaReference: ideiaReference.path,
      dataPrazo: dataPrazo.toDate()
    };

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }

    return subject("TMarco", data);
  }
};

/**
 * Get ideias collection reference from empresaId
 * @param empresaId - Empresa id to get the ideias collection reference
 * @param ideiaId - Ideia id to get the ideias collection reference
 * @returns - Ideias collection reference
 */
function getIdeiaMarcosCollectionReference(empresaId: TEmpresa["id"], ideiaId: TIdeia["id"]) {
  const ideiaDocumentReference = getIdeiaDocumentReference(empresaId, ideiaId);
  return collection(ideiaDocumentReference, "marcos");
}

/**
 * Get marco document reference from empresaId, ideiaId and marcoId
 * @param empresaId - The empresa ID
 * @param ideiaId - The ideia id
 * @param marcoId - The marco id
 * @returns - Marco document
 */
function getMarcoDocumentReference(empresaId: TEmpresa["id"], ideiaId: TIdeia["id"], marcoId: TMarco["id"]) {
  const ideiasCollectionReference = getIdeiaMarcosCollectionReference(empresaId, ideiaId);
  return doc(ideiasCollectionReference, marcoId);
}

/**
 * Add movimentacao
 * @param empresaId - The empresa ID
 * @param ideiaId - the ideia ID to add comentário
 * @param marco - The marco to add
 * @returns - Marco document
 */
export function addMarco(
  empresaId: TEmpresa["id"],
  ideiaId: TIdeia["id"],
  marco: Omit<TMarcoForm, "dataConclusao" | "descricaoAtuacao" | "anexos">
) {
  const ideiaMarcosCollectionReference = getIdeiaMarcosCollectionReference(empresaId, ideiaId);
  return addDoc(ideiaMarcosCollectionReference, marco);
}

/**
 *
 * @param marcoPath - The marco path
 * @returns - Marco document
 */
export function getMarco(marcoPath: string) {
  const marcoDocumentReference = doc(firestore, marcoPath).withConverter(marcoConverter);
  return getDoc(marcoDocumentReference);
}

/**
 *
 * @param empresaId -   The empresa ID
 * @param ideiaId - The ideia ID
 * @param marcoId - The marco ID
 * @param marco - The marco to update
 * @returns - Marco document updated
 */
export function updateMarco(
  empresaId: TEmpresa["id"],
  ideiaId: TIdeia["id"],
  marcoId: TMarco["id"],
  marco: Partial<Omit<TMarcoForm, "dataConclusao" | "descricaoAtuacao" | "anexos">>
) {
  const marcoDocumentReference = getMarcoDocumentReference(empresaId, ideiaId, marcoId).withConverter(marcoConverter);
  const currentUserId = getCurrentUser()?.uid;
  return updateDoc(marcoDocumentReference, {
    ...marco,
    updatedAt: serverTimestamp(),
    updatedBy: currentUserId
  });
}

/**
 * Finalizar atuação em um marco
 * @param empresaId - The empresa ID to finalizar atuação
 * @param ideiaId - The ideia ID to finalizar atuação
 * @param marcoId - The marco ID to finalizar atuação
 * @param marco - The marco to finalizar atuação
 * @returns - Marco document updated with finalizar atuação
 */
export async function updateAtuacao(
  empresaId: TEmpresa["id"],
  ideiaId: TIdeia["id"],
  marcoId: TMarco["id"],
  marco: Pick<
    TMarcoForm,
    "dataConclusao" | "descricaoAtuacao" | "anexos" | "anexosFilesToUpload" | "startedAt" | "finishedAt"
  >
) {
  const marcoDocumentReference = getMarcoDocumentReference(empresaId, ideiaId, marcoId).withConverter(marcoConverter);
  const currentUserId = getCurrentUser()?.uid;

  const { anexosFilesToUpload, ...marcoData } = marco;

  // Upload anexos files
  const anexosPromises = anexosFilesToUpload.map<Promise<TFileStored>>((file) =>
    uploadAnexosFilesToStorage(empresaId, ideiaId, marcoId, file).then(async (uploadTaskSnapshot) => {
      const url = await getDownloadURL(uploadTaskSnapshot.ref);
      return {
        path: url,
        name: uploadTaskSnapshot.metadata.name,
        size: uploadTaskSnapshot.metadata.size,
        type: uploadTaskSnapshot.metadata.contentType || ""
      };
    })
  );
  // eslint-disable-next-line compat/compat
  const anexos = await Promise.all(anexosPromises);

  // Update marco document
  return updateDoc(marcoDocumentReference, {
    ...marcoData,
    anexos,
    updatedAt: serverTimestamp(),
    updatedBy: currentUserId
  });
}

/**
 * Delete marco by path
 * @param marcoPath - The marco path
 * @returns - Marco document
 */
export function deleteMarco(marcoPath: string) {
  const marcoDocumentReference = doc(firestore, marcoPath).withConverter(marcoConverter);
  return deleteDoc(marcoDocumentReference);
}

/**
 * Generate query to get all marcos
 * @param empresaId - The empresa ID to get all marcos
 * @param colaboradoresResponsaveis - The colaboradores responsáveis to filter
 * @returns - Marcos query reference
 */
export function generateAllMarcosQuery(
  empresaId: TEmpresa["id"],
  colaboradoresResponsaveis: string[] | DocumentReference<TColaborador>[] = []
) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);

  if (colaboradoresResponsaveis.length === 0) {
    return query(
      collectionGroup(firestore, "marcos"),
      where("empresaReference", "==", empresaDocumentReference),
      orderBy("dataPrazo", "desc")
    );
  }

  const colaboradoresDocumentReferences = colaboradoresResponsaveis.map((colaboradorReferencePath) =>
    typeof colaboradorReferencePath === "string"
      ? getColaboradorDocumentReferenceFromPath(colaboradorReferencePath)
      : colaboradorReferencePath
  );

  return query(
    collectionGroup(firestore, "marcos"),
    where("empresaReference", "==", empresaDocumentReference),
    // eslint-disable-next-line sonarjs/no-duplicate-string
    where("colaboradoresResponsaveis", "array-contains-any", colaboradoresDocumentReferences),
    orderBy("dataPrazo", "desc")
  );
}

/**
 * Generate query to get marcos em andamento
 * @param empresaId - The empresa ID to get all marcos
 * @param colaboradoresResponsaveis - The colaboradores responsáveis to filter
 * @returns - Marcos query reference
 */
export function generateMarcosEmAndamentoQuery(
  empresaId: TEmpresa["id"],
  colaboradoresResponsaveis: string[] | DocumentReference<TColaborador>[] = []
) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);

  if (colaboradoresResponsaveis.length === 0) {
    return query(
      collectionGroup(firestore, "marcos"),
      where("startedAt", "!=", null),
      where("finishedAt", "==", null),
      where("empresaReference", "==", empresaDocumentReference),
      orderBy("startedAt"),
      orderBy("dataPrazo", "desc")
    );
  }

  const colaboradoresDocumentReferences = colaboradoresResponsaveis.map((colaboradorReferencePath) =>
    typeof colaboradorReferencePath === "string"
      ? getColaboradorDocumentReferenceFromPath(colaboradorReferencePath)
      : colaboradorReferencePath
  );

  return query(
    collectionGroup(firestore, "marcos"),
    where("startedAt", "!=", null),
    where("empresaReference", "==", empresaDocumentReference),
    where("colaboradoresResponsaveis", "array-contains-any", colaboradoresDocumentReferences),
    orderBy("startedAt"),
    orderBy("dataPrazo", "desc")
  );
}

/**
 * Generate query to get marcos não iniciadas
 * @param empresaId - The empresa ID to get all marcos
 * @param colaboradoresResponsaveis - The colaboradores responsáveis to filter
 * @returns - Marcos query reference
 */
export function generateMarcosNaoIniciadaQuery(
  empresaId: TEmpresa["id"],
  colaboradoresResponsaveis: string[] | DocumentReference<TColaborador>[] = []
) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);

  if (colaboradoresResponsaveis.length === 0) {
    return query(
      collectionGroup(firestore, "marcos"),
      where("startedAt", "==", null),
      where("empresaReference", "==", empresaDocumentReference),
      orderBy("dataPrazo", "desc"),
      orderBy("startedAt")
    );
  }

  const colaboradoresDocumentReferences = colaboradoresResponsaveis.map((colaboradorReferencePath) =>
    typeof colaboradorReferencePath === "string"
      ? getColaboradorDocumentReferenceFromPath(colaboradorReferencePath)
      : colaboradorReferencePath
  );

  return query(
    collectionGroup(firestore, "marcos"),
    where("startedAt", "==", null),
    where("empresaReference", "==", empresaDocumentReference),
    where("colaboradoresResponsaveis", "array-contains-any", colaboradoresDocumentReferences),
    orderBy("dataPrazo", "desc"),
    orderBy("startedAt")
  );
}

/**
 * Generate query to get marcos concluídos
 * @param empresaId - The empresa ID to get all marcos
 * @param colaboradoresResponsaveis - The colaboradores responsáveis to filter
 * @returns - Marcos query reference
 */
export function generateMarcosConcluidosQuery(
  empresaId: TEmpresa["id"],
  colaboradoresResponsaveis: string[] | DocumentReference<TColaborador>[] = []
) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);

  if (colaboradoresResponsaveis.length === 0) {
    return query(
      collectionGroup(firestore, "marcos"),
      where("finishedAt", "!=", null),
      where("empresaReference", "==", empresaDocumentReference),
      orderBy("finishedAt"),
      orderBy("dataPrazo", "desc")
    );
  }

  const colaboradoresDocumentReferences = colaboradoresResponsaveis.map((colaboradorReferencePath) =>
    typeof colaboradorReferencePath === "string"
      ? getColaboradorDocumentReferenceFromPath(colaboradorReferencePath)
      : colaboradorReferencePath
  );

  return query(
    collectionGroup(firestore, "marcos"),
    where("finishedAt", "!=", null),
    where("empresaReference", "==", empresaDocumentReference),
    where("colaboradoresResponsaveis", "array-contains-any", colaboradoresDocumentReferences),
    orderBy("finishedAt"),
    orderBy("dataPrazo", "desc")
  );
}

/**
 * Generate query to get marcos vencidos
 * @param empresaId - The empresa ID to get all marcos
 * @param colaboradoresResponsaveis - The colaboradores responsáveis to filter
 * @returns - Marcos query reference
 */
export function generateMarcosVencidosQuery(
  empresaId: TEmpresa["id"],
  colaboradoresResponsaveis: string[] | DocumentReference<TColaborador>[] = []
) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);

  if (colaboradoresResponsaveis.length === 0) {
    return query(
      collectionGroup(firestore, "marcos"),
      where("dataPrazo", "<", new Date()),
      where("finishedAt", "==", null),
      where("empresaReference", "==", empresaDocumentReference),
      orderBy("dataPrazo", "desc")
    );
  }

  const colaboradoresDocumentReferences = colaboradoresResponsaveis.map((colaboradorReferencePath) =>
    typeof colaboradorReferencePath === "string"
      ? getColaboradorDocumentReferenceFromPath(colaboradorReferencePath)
      : colaboradorReferencePath
  );

  return query(
    collectionGroup(firestore, "marcos"),
    where("dataPrazo", "<", new Date()),
    where("finishedAt", "==", null),
    where("empresaReference", "==", empresaDocumentReference),
    where("colaboradoresResponsaveis", "array-contains-any", colaboradoresDocumentReferences),
    orderBy("dataPrazo", "desc")
  );
}

/**
 * Get marcos by query
 * @param query - The query to get all marcos
 * @returns - Marcos snapshots
 */
export function getMarcosByQuery(query: Query) {
  const queryReference = query.withConverter(marcoConverter);
  return getDocs(queryReference);
}

/**
 * Get movimentações documents based on multiple filters
 * @param empresaId - The empresa ID
 * @param ideiaId - the ideia ID to add movimentações
 * @returns - The movimentações documents
 */
// prettier-ignore
export function getMarcosWithIdeia(
  empresaId: TEmpresa["id"],
  ideiaId: TIdeia["id"],
) {
  const movimentacoesCollectionReference = getIdeiaMarcosCollectionReference(empresaId, ideiaId).withConverter(
    marcoConverter
  );

  const q = query(movimentacoesCollectionReference);

  return getDocs(q);
}
