import { Card, Text, TextInput, Textarea, Space, Group, Button, Select } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { QueryObserverResult } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TCategoriaInovacaoForm } from "@/modules/categorias-de-inovacao/firestore";
import { extractTextInputPropertiesFromFormSchema } from "@/utils/form";
import useAddTipoDeInovacaoForm from "./useAddTipoDeInovacaoForm";
import { TTipoInovacaoForm } from "../../firestore";
import tipoDeInovacaoSchema from "../../schema/tipo-de-inovacao-schema";

interface Tipo {
  nome?: string;
  id?: string;
}

interface Tatica {
  id: string | number;
  nome: string;
  descricao: string;
}

const AddTipoDeInovacao = ({
  data,
  categorias,
  setActive,
  refetch
}: {
  data: (TTipoInovacaoForm & Partial<Pick<TTipoDeInovacao, "id" | "refPath">>) | null | undefined;
  categorias: (TCategoriaInovacaoForm & Partial<Pick<TCategoriaDeInovacao, "id" | "refPath">>)[];
  setActive: Dispatch<SetStateAction<number>>;
  refetch: () => Promise<QueryObserverResult>;
}) => {
  const navigate = useNavigate();
  const navigation = () => navigate("/tipos-de-inovacao");
  const fieldsDescriptor = tipoDeInovacaoSchema.describe();
  const [taticas, setTaticas] = useState<Tatica[]>([]);

  useEffect(() => {
    if (data?.taticas && Array.isArray(data.taticas as Tatica[]) && data.taticas.length > 0) {
      setTaticas(data.taticas as Tatica[]);
    }
  }, [data]);

  const { getInputProps, onSubmit } = useAddTipoDeInovacaoForm(
    data || ({} as TTipoInovacaoForm),
    taticas,
    categorias as TCategoriaDeInovacao[],
    {
      onSuccess(item) {
        showNotification({
          message: `${getInputProps("nome")?.value} ${data?.id ? "atualizado" : "cadastrado"} com sucesso!`,
          color: "green",
          autoClose: 2500
        });

        void refetch();
        if (item && typeof item === "object" && "id" in item) {
          navigate(`/tipos-de-inovacao/${item.id}`);
        }
        setActive(1);
      }
    }
  );

  const handleCancelClick = useCallback(() => {
    navigation();
  }, [navigate]);

  return (
    <form onSubmit={onSubmit}>
      <Card>
        <Card.Section inheritPadding py="xs">
          <Text size="lg" fw={500}>
            Tipo de Inovação
          </Text>
          <Space h="lg" />
          <Select
            data={categorias.map((item: Tipo) => ({ value: item.id || "", label: item.nome || "" }))}
            {...getInputProps("categoria")}
            {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "categoria")}
            label={"Categoria de Inovação"}
            placeholder="Selecione a categoria de inovação"
          />
          <Space h="lg" />
          <TextInput
            mt="md"
            {...getInputProps("nome")}
            {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "nome")}
            label={"Nome do tipo de inovação"}
          />
          <Space h="lg" />
          <Textarea
            autosize
            minRows={5}
            {...getInputProps("descricao")}
            {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "descricao")}
            label={"Descrição"}
          />
          <Space h="xl" />
          <Group justify={"flex-end"}>
            <Group justify="flex-end">
              <Button variant="transparent" color="rgba(82, 83, 86, 1)" onClick={handleCancelClick}>
                Cancelar
              </Button>
              <Button variant="filled" color="rgba(8, 68, 244, 1)" type="submit">
                Salvar e continuar
              </Button>
            </Group>
          </Group>
        </Card.Section>
      </Card>
    </form>
  );
};

export default AddTipoDeInovacao;
