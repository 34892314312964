import { Anchor, Card, Flex, Grid, Group, Space, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconEye } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { formatDate } from "@/modules/diagnosticos/components/diagnostico-item/DiagnosticoList";
import { verificationDate } from "@/modules/projetos/components/projeto-form/ProjetoForm";
import { getRepostasArrayNewUser } from "@/modules/responder-questionario/firestore";
import useUserStore from "@/modules/users/store";
import RespostaComponente from "./RespostaComponente";
import useGetConvites from "./useGetConvites";

const MAGIC_NUMBER = 1000;

const message = "";
// prettier-ignore
const QuestionarioItem = ({ questionario }: { questionario: TQuestionario }) => {
  const { data: dataConvites } = useGetConvites({ id: questionario.id });
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const [userExterno, setUserExterno] = useState(0);
  const isSmMarches = useMediaQuery("(max-width: 48em)");

  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadData();
      } catch (error) {
        console.error(message, error);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, []);

  const loadData = async () => {
    const respostas = await getRepostasArrayNewUser(activeEmpresaId || "", questionario.id);
    const format: TResposta[] = respostas.docs.map((resposta) => resposta.data());
    setUserExterno(format.filter(item => item.idRespondente === "").length);
  }

  return (
    <>
      <Space h="md" />
      <Card withBorder shadow="md" style={{ flex: 1, width: isSmMarches ? MAGIC_NUMBER : "100%" }}>
        <Grid align="center">
          <Grid.Col span={3}>
            <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
              <Text fw={300} fz="xs">
                Nome do questionário
              </Text>
              <Text fw={500} fz="md">
                {questionario.nome}
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={2}>
            <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
              <Text fw={300} fz="xs">
                Colaboradores convidados
              </Text>
              <Text fw={500} fz="md">
                {(dataConvites?.filter((item) => item.idEmpresa === activeEmpresaId && !item.cancelado)?.length || 0) + userExterno}
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={2}>
            <RespostaComponente id={questionario?.id} fw={500} fz="md" />
          </Grid.Col>
          <Grid.Col span={2}>
            <Group align={"center"} justify={"center"}>
              <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
                <Text fw={300} fz="xs">
                  Período de avaliação
                </Text>
                <Text fw={500} fz="md">
                  {typeof questionario?.dataAtuacao === "number" ? formatDate(verificationDate(new Date())) : formatDate(verificationDate(questionario?.dataAtuacao))} - {typeof questionario?.dataVigencia === "number" ? formatDate(verificationDate(new Date())) : formatDate(verificationDate(questionario?.dataVigencia))}
                </Text>
              </Flex>
            </Group>
          </Grid.Col>
          <Grid.Col span={2}>
            <Group align={"center"} justify={"center"}>
              <Anchor component={NavLink} to={`/questionariosColaborador/${questionario?.id}`}>
                <IconEye color="rgba(57, 106, 249, 1)" />
              </Anchor>
            </Group>
          </Grid.Col>
        </Grid>
      </Card>
    </>
  );
};

export default QuestionarioItem;
