import { subject } from "@casl/ability";
import {
  serverTimestamp,
  Timestamp,
  FirestoreDataConverter,
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  query,
  where
} from "firebase/firestore";
import { getCurrentUser } from "@/services/auth";
import { getEmpresaDocumentReference } from "../empresas/firestore";

type TAutorizacaoFormFields = Omit<
  TAutorizacao,
  "id" | "refPath" | "createdAt" | "updatedAt" | "createdBy" | "updatedBy"
> &
  Partial<Pick<TAutorizacao, "id" | "refPath">>;

type TAutorizacaoDatabaseFields = Pick<TAutorizacao, "createdBy" | "updatedBy"> & {
  createdAt: ReturnType<typeof serverTimestamp>;
  updatedAt?: ReturnType<typeof serverTimestamp>;
};
export type TAutorizacaoForm = TAutorizacaoFormFields & TAutorizacaoDatabaseFields;

type TAutorizacaoDocument = Omit<TAutorizacao, "createdAt" | "updatedAt"> & {
  createdAt: Timestamp;
  updatedAt?: Timestamp;
};

const autorizacaoConverter: FirestoreDataConverter<TAutorizacao> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const { createdAt, updatedAt, ...document } = snap.data() as TAutorizacaoDocument;

    const data: TAutorizacao = {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      refPath: snap.ref.path
    };

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }
    return subject("TAutorizacao", data);
  }
};

/**
 * Get autorizacao collection reference from empresaId
 * @param empresaId - Empresa id to get the autorizacao collection reference
 * @returns - Autorizacao collection reference
 */
function getAutorizacaoCollectionReferencer(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, "autorizacao");
}

/**
 * Add a new autorizacao to the given empresa
 * @param empresaId - Empresa id to add the pergunta
 * @param autorizacao - Autorizacao data
 * @returns - Autorizacao document reference
 */
export async function addAutorizacao(empresaId: TEmpresa["id"], autorizacao: Omit<TAutorizacaoForm, "id" | "refPath">) {
  const autorizacaoCollectionReference = getAutorizacaoCollectionReferencer(empresaId);
  return addDoc(autorizacaoCollectionReference, autorizacao);
}

/**
 * Get autorizacao collection reference from empresaId
 * @param empresaId - Empresa id to get the autorizacao collection reference
 * @param autorizacaoId - id de autorizacao
 * @returns - Autorizacao collection reference
 */
function getAutorizacaoCollectionUpdateReferencer(empresaId: TEmpresa["id"], autorizacaoId: TAutorizacao["id"]) {
  const perguntasCollectionReference = getAutorizacaoCollectionReferencer(empresaId);
  return doc(perguntasCollectionReference, autorizacaoId);
}

/**
 * Update a empresa to the database
 * @param empresaId - Empresa id
 * @param autorizacaoId - Pergunta id to update
 * @param autorizacao - Pergunta data
 * @returns - Promise with the pergunta reference
 */
// prettier-ignore
export function updateAutorizacao(empresaId: TEmpresa["id"], autorizacaoId: TAutorizacao["id"], autorizacao: TAutorizacaoForm) {
  const autorizacaoDocumentReference = getAutorizacaoCollectionUpdateReferencer(empresaId, autorizacaoId).withConverter(
      autorizacaoConverter
    );
  const currentUserId = getCurrentUser()?.uid;
  return updateDoc(autorizacaoDocumentReference, {
      ...autorizacao,
      updatedAt: serverTimestamp(),
      updatedBy: currentUserId
  });
}

/**
 * Get all questionário
 * @param empresaId - Empresa id
 * @returns - questionário collection reference
 */
function getAutorizacaoCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, "autorizacao");
}

/**
 * Get questionário query for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param tipo - tipo de autorizacao
 * @param tipoDeAutorizacao - tipo de autorizacao
 * @returns - questionário query
 */
function generateAutorizacaoQuery(empresaId: TEmpresa["id"], tipo: string, tipoDeAutorizacao: string) {
  const categoriasInovacaoCollectionReference =
    getAutorizacaoCollectionReference(empresaId).withConverter(autorizacaoConverter);

  return query(
    categoriasInovacaoCollectionReference,
    where("tipo", "==", tipo),
    where("tipoAutorizacao", "==", tipoDeAutorizacao)
  );
}

/**
 * Get questionário for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param tipo - tipo de autorizacao
 * @param tipoDeAutorizacao - tipo de autorizacao
 * @returns - questionário document snapshot
 */
export function getAutorizacao(empresaId: TEmpresa["id"], tipo: string, tipoDeAutorizacao: string) {
  const q = generateAutorizacaoQuery(empresaId, tipo, tipoDeAutorizacao);
  return getDocs(q);
}

/**
 * Get questionário query for a empresa
 * @param empresaId - Empresa id to get comitês
 * @returns - questionário query
 */
function generateAutorizacoesQuery(empresaId: TEmpresa["id"]) {
  const categoriasInovacaoCollectionReference =
    getAutorizacaoCollectionReference(empresaId).withConverter(autorizacaoConverter);
  return query(categoriasInovacaoCollectionReference);
}

/**
 * Get questionário for a empresa
 * @param empresaId - Empresa id to get comitês
 * @returns - questionário document snapshot
 */
export function getAutorizacoes(empresaId: TEmpresa["id"]) {
  const q = generateAutorizacoesQuery(empresaId);
  return getDocs(q);
}
