import { Card, Group, Text, Button, Flex, Stack, Space, Grid, Badge, ThemeIcon } from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { QueryObserverResult } from "@tanstack/react-query";
import React from "react";
import TipoDeInovacaoCard from "./TipodeInovacaoCard";

const MAGIC_NUMBER = 1000;

interface Tipo {
  id: string | number;
  nome: string;
  descricao: string;
}
// prettier-ignore
const TipoDeInovacaoItem: React.FC<{
  categoria: TTipoDeInovacao;
  defaultExpand?: boolean;
  refetch: () => Promise<QueryObserverResult>;
  formatAutorizacoes: TAutorizacao[] | undefined;
}> = ({ categoria, defaultExpand, refetch, formatAutorizacoes }) => {
  const [opened, { toggle }] = useDisclosure(defaultExpand);
  const isSmMarches = useMediaQuery("(max-width: 48em)");

  return (
    <Card withBorder radius="lg" style={{ flex: 1, width: isSmMarches ? MAGIC_NUMBER : "100%" }}>
      <Grid align="center">
        <Grid.Col span={{ base: 12, md: 8, lg: 3.25, xl: 3.95 }}>
          <Group align="center">
            <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
              <Text fw={300} fz="xs">
                Tipo de Inovação
              </Text>
              <Text fw={500} fz="md">
                {categoria.nome}
              </Text>
            </Flex>
          </Group>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 8, lg: 3.25, xl: 4 }}>
          <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
            <Text fw={300} fz="xs">
              Categoria de inovação
            </Text>
            <Text fw={500} fz="md">
              {categoria?.nomeCategoria}
            </Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 3.5, xl: 2.7 }}>
          {categoria.taticas && Array.isArray(categoria.taticas) && categoria.taticas.length > 0 && (
            <Group align="center">
              <Badge
                radius="md"
                size="xl"
                variant="outline"
                style={{ background: "#e6f0e8" }}
                color="rgba(105, 198, 105, 1)"
              >
                <Group justify="space-between">
                  <ThemeIcon color="rgba(105, 198, 105, 1)" radius="lg" size="sm">
                    <Text size="sm">
                      {categoria.taticas && Array.isArray(categoria.taticas) ? categoria.taticas.length : 0}
                    </Text>
                  </ThemeIcon>
                  <Text tt="capitalize" fz="sm" fw={500}>
                    Táticas de inovação
                  </Text>
                </Group>
              </Badge>
            </Group>
          )}
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 2, lg: 2, xl: 1.35 }}>
          <Group justify="flex-end" align="center">
            <Button
              type="button"
              variant="transparent"
              size="compact-sm"
              rightSection={opened ? <IconChevronUp size="1rem" /> : <IconChevronDown size="1rem" />}
              onClick={toggle}
            >
              {opened ? "Ver menos" : "Ver mais"}
            </Button>
          </Group>
        </Grid.Col>
      </Grid>
      {opened && (
        <>
          <Space h="xl" />
          <Card.Section inheritPadding py="xs">
            <Stack>
              <TipoDeInovacaoCard
                id={categoria.id}
                descricao={categoria.descricao}
                nome={categoria.nome}
                taticas={categoria.taticas as Tipo[]}
                refetch={refetch}
                formatAutorizacoes={formatAutorizacoes}
              />
            </Stack>
          </Card.Section>
        </>
      )}
    </Card>
  );
};

export default TipoDeInovacaoItem;
