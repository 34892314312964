import { ActionIconProps, Mark, Text, UnstyledButton } from "@mantine/core";
import { modals } from "@mantine/modals";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MouseEvent, PropsWithChildren, forwardRef } from "react";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { deleteColaborador } from "../../firestore";
import { QUERY_KEY } from "../colaboradores-table/ColaboradoresTable";

type RemoveColaboradorButtonProperties = PropsWithChildren<ActionIconProps & { colaborador: TColaborador }>;
type TDeleteColaboradorResponse = Awaited<ReturnType<typeof deleteColaborador>>;

const RemoveColaboradorButton = forwardRef<HTMLButtonElement, RemoveColaboradorButtonProperties>(
  ({ colaborador, children, ...properties }, reference) => {
    const queryClient = useQueryClient();
    const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

    const { mutate: deleteColaboradorMutation, isPending } = useMutation<TDeleteColaboradorResponse, Error>({
      mutationFn: () => {
        if (!activeEmpresaId) {
          throw new Error("Empresa não selecionada");
        }
        return deleteColaborador(activeEmpresaId, colaborador);
      },
      onSuccess() {
        return queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      },
      onError(error) {
        captureException(error, true);
      }
    });

    /**
     * Handles the click event on the button.
     * @param event - The event that triggered the click.
     */
    function handleClick(event: MouseEvent<HTMLButtonElement>) {
      event.preventDefault();
      modals.openConfirmModal({
        title: "Remover colaborador",
        centered: true,
        children: (
          <Text size="sm">
            Tem certeza que deseja remover o colaborador <Mark>{colaborador.nome}</Mark>
          </Text>
        ),
        labels: {
          confirm: "Sim",
          cancel: "Não"
        },
        confirmProps: { color: "red" },
        onConfirm: () => {
          void deleteColaboradorMutation();
        }
      });
    }

    return (
      <UnstyledButton disabled={isPending} ref={reference} {...properties} onClick={handleClick}>
        {children}
      </UnstyledButton>
    );
  }
);

RemoveColaboradorButton.displayName = "RemoveColaboradorButton";

export default RemoveColaboradorButton;
