import { subject } from "@casl/ability";
import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { REGULAMENTOS_IDEIAS_COLLECTION_KEY, getRegulamentoIdeiasById } from "@/modules/regulamento-ideias/firestore";
import useUserStore from "@/modules/users/store";

type TGetRegulamentoIdeiasById = Awaited<ReturnType<typeof getRegulamentoIdeiasById>>;

/**
 * Get regulamento ideias by id hook
 * @param regulamentoIdeiaId - Regulamento ideia id to get
 * @returns - Query result
 */
function useGetRegulamentoIdeiasById(regulamentoIdeiaId: TRegulamentoIdeias["id"] | undefined) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  return useQuery<TGetRegulamentoIdeiasById, FirestoreError, TRegulamentoIdeias | null>({
    queryKey: [REGULAMENTOS_IDEIAS_COLLECTION_KEY, "getRegulamentoIdeiasById", { regulamentoIdeiaId }, activeEmpresaId],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!regulamentoIdeiaId) {
        throw new Error("Regulamento de ideia não selecionado");
      }

      return getRegulamentoIdeiasById(activeEmpresaId, regulamentoIdeiaId);
    },
    select(dataResponse) {
      if (!dataResponse.exists()) {
        return null;
      }

      return subject("TRegulamentoIdeias", dataResponse.data());
    },
    enabled: !!regulamentoIdeiaId
  });
}

export default useGetRegulamentoIdeiasById;
