import { useMutation } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { addMedia, TMediaForm } from "../../firestore";

type TUpdateCronogramaResponse = Awaited<ReturnType<typeof addMedia>>;
/**
 * Finalizar resposta de inovação mutation hook
 * @returns Finalizar resposta de inovação mutation
 */
// prettier-ignore
export function useAddNota() {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  return useMutation<TMedia | TUpdateCronogramaResponse , FirestoreError, TMediaForm>({
    mutationFn(item) {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!item) {
        throw new Error("Empresa não selecionada");
      }

      return addMedia(activeEmpresaId, item);
    },
  });
}
