import { cnpj } from "cpf-cnpj-validator";
import { FieldValue, deleteField } from "firebase/firestore";
import { pt } from "yup-locale-pt";
import { yup } from "./schema";

yup.setLocale(pt);

/**
 * Add deleteField from empty string
 */
yup.addMethod(yup.mixed<string | ReturnType<typeof deleteField>>, "firebaseDeleteEmptyField", function () {
  return this.transform(function (value: string) {
    if (this.isType(value) && (!value || value === "")) {
      return deleteField();
    }
    return value as string | FieldValue;
  });
});

yup.addMethod(yup.string, "cnpj", function () {
  return this.test({
    name: "cnpj",
    message: "${path} inválido",
    test: function (value) {
      if (typeof value !== "string" || value.length === 0) {
        return true;
      }

      return cnpj.isValid(value);
    }
  });
});

export default { ...yup };
