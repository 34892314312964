import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { getRepostasQuestionarioArray, QUERY_KEY } from "@/modules/responder-questionario/firestore";

type TGetRespostaResponse = Awaited<ReturnType<typeof getRepostasQuestionarioArray>>;

/**
 * Get resposta from url params
 * @returns - resposta or null if not found
 */
function useGetResposta() {
  const { id, idEmpresa: activeEmpresaId } = useParams<{ id: string; idEmpresa: string }>();

  return useQuery<TGetRespostaResponse, FirestoreError, TResposta[] | null>({
    queryKey: [QUERY_KEY, id, activeEmpresaId],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!id) {
        throw new Error("Ideia não selecionada");
      }

      return getRepostasQuestionarioArray(activeEmpresaId, id);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((document_) => document_.data());
    },
    enabled: !!id && !!activeEmpresaId
  });
}

export default useGetResposta;
