import { FileWithPath } from "@mantine/dropzone";
import { DocumentReference, serverTimestamp } from "firebase/firestore";
import { ObjectSchema } from "yup";
import { TColaboradorDocument, getColaboradorDocumentReferenceFromPath } from "@/modules/colaboradores/firestore";
import { getRegulamentoDocumentReferenceFromReferencePath } from "@/modules/regulamento-ideias/firestore";
import schema from "@/schema";
import { getCurrentUser } from "@/services/auth";
import { TIdeiaDatabaseFields, TIdeiaForm, TIdeiaFormFields } from "../firestore";

const MIN_NAME_LENGTH = 3;

export const IDEIA_OBJETIVOS = new Map<TIdeiaObjetivo, string>([
  ["Melhorar a qualidade do produto", "Melhorar a qualidade do produto"],
  ["Melhorar a qualidade do serviço", "Melhorar a qualidade do serviço"],
  ["Aumentar a produtividade", "Aumentar a produtividade"],
  ["Reduzir Custos", "Reduzir Custos"],
  ["Otimizar Processos", "Otimizar Processos"],
  ["Melhorar a Estrutura Física", "Melhorar a Estrutura Física"],
  ["Melhorar o Posicionamento da Marca", "Melhorar o Posicionamento da Marca"],
  ["Desenvolver Pessoas", "Desenvolver Pessoas"],
  ["Captar ou Manter Clientes", "Captar ou Manter Clientes"],
  ["Melhorar a eficiência Comercial", "Melhorar a eficiência Comercial"],
  ["Outros, conforme funcionalidade administrativa", "Outros, conforme funcionalidade administrativa"]
]);

export const IDEIA_STATUS: TIdeia["status"][] = [
  "NOVA_IDEIA",
  "AGUARDANDO_ANALISE_DO_COMITE",
  "PRE_ANALISE",
  "EM_ANALISE_DE_VIABILIDADE",
  "RASCUNHO",
  "APROVADA",
  "REPROVADA",
  "IMPLEMENTADA",
  "CANCELADA",
  "EM_IMPLEMENTACAO",
  "BACKLOG_DE_AVALIACAO_FUTURA"
];

/**
 * Define only the meta fields that are not part of the form
 */
const ideiaSchemaDatabaseFields: ObjectSchema<TIdeiaDatabaseFields> = schema.object().shape({
  createdAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .required()
    .defined()
    .nonNullable()
    .default<ReturnType<typeof serverTimestamp>>(() => serverTimestamp()),
  createdBy: schema
    .string()
    .required()
    .defined()
    .nonNullable()
    .default(() => {
      const currentUserId = getCurrentUser()?.uid;
      if (!currentUserId) {
        throw new Error("The current user is not defined");
      }
      return currentUserId;
    }),
  updatedAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .default<ReturnType<typeof serverTimestamp>>(() => serverTimestamp()),
  updatedBy: schema.string().default(() => {
    const currentUserId = getCurrentUser()?.uid;
    if (!currentUserId) {
      throw new Error("The current user is not defined");
    }
    return currentUserId;
  }),
  cancelAt: schema.mixed<ReturnType<typeof serverTimestamp>>().defined().nullable().default(null),
  deletedAt: schema.mixed<ReturnType<typeof serverTimestamp>>().defined().nullable().default(null),
  publishedAt: schema.mixed<ReturnType<typeof serverTimestamp>>().defined().nullable().default(null)
});

/**
 * Define the schema for the form
 */
const ideiaSchemaFormFields: ObjectSchema<TIdeiaFormFields> = schema.object({
  id: schema.string().optional(),
  refPath: schema.string().optional(),
  rankingByMoedas: schema.object().optional(),
  rankingByLikes: schema.object().optional(),
  titulo: schema.string().min(MIN_NAME_LENGTH).required().defined().label("Título"),
  descricao: schema.string().defined().label("Descrição"),
  autorReference: schema
    .mixed<DocumentReference<TColaborador, TColaboradorDocument>>()
    .required()
    .defined()
    .transform((value: string) => (value && value.length > 0 ? getColaboradorDocumentReferenceFromPath(value) : value))
    .label("Autor"),
  objetivo: schema
    .array()
    .of(schema.mixed<TIdeiaObjetivo>().required().defined())
    .required()
    .defined()
    .min(1, "A sua ideia precisa ter ao menos um objetivo")
    .label("A sua ideia tem como objetivo:"),
  status: schema.string().oneOf(IDEIA_STATUS).required().defined().default("RASCUNHO"),
  comoAtingirObjetivo: schema
    .string()
    .defined()
    .optional()
    .default("")
    .label("Descreva como a ideia pode contribuir para que algum dos objetivos acima seja atingido."),
  descricaoProblema: schema
    .string()
    .defined()
    .optional()
    .default("")
    .label(
      "Descreva aqui o problema que sua ideia irá ajudar a solucionar. Apresente detalhes do problema e o impacto disso."
    ),
  diferenciais: schema
    .string()
    .defined()
    .optional()
    .default("")
    .label("Que diferenciais inovadores e tecnológicos seu Projeto/Ideia possui?"),
  feedback: schema
    .string()
    .defined()
    .optional()
    .default("")
    .label("Você já buscou o feedback para validação do seu Projeto/Ideia? Se sim, qual foi o resultado?"),
  autorGostariaParticiparImplantacao: schema
    .boolean()
    .required()
    .defined()
    .default(true)
    .label("Você gostaria de participar da implementação da ideia proposta?"),
  participantesReferences: schema
    .array()
    // .of(schema.mixed<DocumentReference<TColaborador, TColaboradorDocument>>().optional().defined())
    .optional()
    // .defined()
    .default([])
    .transform((value: (string | DocumentReference<TColaborador, TColaboradorDocument>)[]) =>
      (value || []).map((v) => {
        const isString = typeof v === "string";
        return isString ? getColaboradorDocumentReferenceFromPath(v) : v;
      })
    )
    .label("Participantes da ideia"),
  patrocinadoresReferences: schema
    .array()
    .optional()
    .default([])
    .transform((value: (string | DocumentReference<TColaborador, TColaboradorDocument>)[]) =>
      value.map((v) => {
        const isString = typeof v === "string";
        return isString ? getColaboradorDocumentReferenceFromPath(v) : v;
      })
    )
    .label("Patrocinadores da ideia"),
  implementadoresReferences: schema
    .array()
    .optional()
    .default([])
    .transform((value: (string | DocumentReference<TColaborador, TColaboradorDocument>)[]) =>
      (value || []).map((v) => {
        const isString = typeof v === "string";
        return isString ? getColaboradorDocumentReferenceFromPath(v) : v;
      })
    )
    .label("Implementadores da ideia"),
  setores: schema
    .array()
    .of(schema.string().required().defined())
    .required()
    .defined()
    .default([])
    .min(1)
    .label("Setores"),
  regulamentoReference: schema
    .mixed<DocumentReference<TRegulamentoIdeias> | string>()
    .required()
    .defined()
    .transform((value: string | DocumentReference<TRegulamentoIdeias>) => {
      const isString = typeof value === "string";
      return isString ? getRegulamentoDocumentReferenceFromReferencePath(value) : value;
    })
    .label("Regulamento"),
  anexos: schema
    .array()
    .of(schema.mixed<TFileStored>().required().defined())
    .required()
    .defined()
    .meta({
      description:
        "Sintetize e inclua tudo o que for necessário em um arquivo, se tiver vídeos pode incluir o link do vídeo no arquivo de texto"
    })
    .default([])
    .label("Se tiver algum anexo sobre a ideia, pode incluir aqui:"),
  anexosFilesToUpload: schema
    .array()
    .of(schema.mixed<FileWithPath>().required().defined())
    .required()
    .defined()
    .default([]),

  permitirComentarios: schema.boolean().required().defined().default(true).label("Permitir Comentários"),
  permitirPatrocinio: schema.boolean().required().defined().default(true).label("Permitir Patrocínio"),
  privado: schema.boolean().optional().required().default(false).label("Criar como ideia privada?")
});

const ideiaSchema: ObjectSchema<TIdeiaForm> = ideiaSchemaFormFields
  // eslint-disable-next-line unicorn/prefer-spread
  .concat(ideiaSchemaDatabaseFields)
  .noUnknown()
  .strict()
  .label("Ideia");

export default ideiaSchema;
