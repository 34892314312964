import { Button } from "@mantine/core";
import { modals } from "@mantine/modals";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { colaboradorConverter } from "@/modules/colaboradores/firestore";
import { DESAFIOS_COLLECTION_KEY, removeParticipante } from "@/modules/desafios/firestore";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { getDocumentReferenceFromPath } from "@/utils/firestore";

type RemoveParticipanteResponse = Awaited<ReturnType<typeof removeParticipante>>;

/**
 * Handle the mutation to participate in a Desafio
 * @param desafio - Desafio to participate
 * @returns - Mutation result
 */
function useRemoveParticipacaoDesafioMutation(desafio: TDesafio) {
  const [activeEmpresaId, currentUser] = useUserStore((state) => [state.activeEmpresaId, state.colaborador]);
  const queryClient = useQueryClient();

  return useMutation<RemoveParticipanteResponse, FirestoreError>({
    mutationFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!currentUser || !currentUser.refPath) {
        throw new Error("Usuário não autenticado");
      }

      const colaboradorDocumentReference = getDocumentReferenceFromPath(currentUser.refPath, colaboradorConverter);

      return removeParticipante(activeEmpresaId, desafio.id, colaboradorDocumentReference);
    },
    onSuccess() {
      void queryClient.invalidateQueries({ queryKey: [DESAFIOS_COLLECTION_KEY, desafio.id] });
    },
    onError(error) {
      captureException(error, true);
    }
  });
}

const RemoverParticipacaoDesafioButton = ({ desafio }: { desafio: TDesafio }) => {
  const { mutate, isPending } = useRemoveParticipacaoDesafioMutation(desafio);
  /**
   * Handle the click event to participate in the Desafio
   */
  function handleClick() {
    modals.openConfirmModal({
      title: `Deseja remover sua participação do desafio "${desafio.titulo}"?`,
      labels: { confirm: "Remover", cancel: "Cancelar" },
      onConfirm: () => mutate()
    });
  }
  return (
    <Button type="button" color="red" onClick={handleClick} loading={isPending}>
      Remover Participação
    </Button>
  );
};

export default RemoverParticipacaoDesafioButton;
