import { Text, UnstyledButton, UnstyledButtonProps } from "@mantine/core";
import { modals } from "@mantine/modals";
import { hideNotification, showNotification, updateNotification } from "@mantine/notifications";
import { IconAlertCircle } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { ForwardedRef, MouseEvent, PropsWithChildren, forwardRef, useId } from "react";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { publishIdeia } from "../../firestore";
import { QUERY_KEY } from "../ideias-table/IdeiasTable";

type TPublishIdeiaResponse = Awaited<ReturnType<typeof publishIdeia>>;

/**
 * Mutation that publishes an ideia.
 * @param ideia - The ideia to be publicada.
 * @returns The mutation to be used.
 */
function useSetPublishAtMutation(ideia: TIdeia) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const queryClient = useQueryClient();

  const notificationId = useId();

  return useMutation<TPublishIdeiaResponse, FirestoreError>({
    mutationFn() {
      hideNotification(notificationId);
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      showNotification({
        title: "Publicando ideia",
        message: "Aguarde enquanto publicamos sua ideia",
        autoClose: false,
        id: notificationId,
        loading: true
      });
      return publishIdeia(activeEmpresaId, ideia.id);
    },
    onSuccess() {
      updateNotification({
        id: notificationId,
        title: "Ideia publicada",
        color: "green",
        message: "Sua ideia foi publicada com sucesso",
        autoClose: true,
        loading: false
      });
      return queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    },
    onError(error) {
      captureException(error, true);
      hideNotification(notificationId);
    }
  });
}

type PublicarIdeiaButtonProperties = PropsWithChildren<UnstyledButtonProps & { ideia: TIdeia }>;

/**
 * A button that opens a modal to edit an item.
 * @param root0 - The properties of the button.
 * @param root0.children - The children of the button.
 * @param root0.ideia - The ideia to be publicada.
 * @param reference - The reference to the button.
 * @returns The element to be rendered.
 */
function PublicarIdeiaButton(
  { ideia, children, ...properties }: PublicarIdeiaButtonProperties,
  reference: ForwardedRef<HTMLButtonElement>
) {
  const { mutate, isPending } = useSetPublishAtMutation(ideia);

  /**
   * Handles the click event on the button.
   * @param event - The event that triggered the click.
   */
  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    if (
      ideia.objetivo.length > 0 &&
      (ideia.descricao.trim() === "" ||
        ideia.diferenciais.trim() === "" ||
        ideia.descricaoProblema.trim() === "" ||
        ideia.feedback.trim() === "" ||
        ideia.comoAtingirObjetivo.trim() === "")
    ) {
      showNotification({
        title: "Não foi possível prosseguir!!",
        message: `Existem campos obrigatórios não preenchidos, complete as informações antes de publicar.`,
        icon: <IconAlertCircle />,
        color: "red"
      });
      return;
    }
    modals.openConfirmModal({
      title: "Deseja publicar sua ideia?",
      children: (
        <Text size="sm">
          Ao publicar sua ideia, ela será visível para todos os usuários e não poderá ser editada ou removida.
        </Text>
      ),
      labels: { confirm: "Publicar", cancel: "Cancelar" },
      onConfirm: () => mutate()
    });
  }

  return (
    <UnstyledButton ref={reference} {...properties} disabled={isPending} onClick={handleClick}>
      {children}
    </UnstyledButton>
  );
}

const PublicarIdeiaButtonWithReference = forwardRef(PublicarIdeiaButton);

PublicarIdeiaButtonWithReference.displayName = "PublicarIdeiaButton";

export default PublicarIdeiaButtonWithReference;
// export default createPolymorphicComponent<"button", PublicarIdeiaButtonProperties>(PublicarIdeiaButtonWithReference);
