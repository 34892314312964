import { getEmpresaById } from "@/modules/empresas/firestore";
import { getEmpresasByQuestionarioArray } from "../firestore";

/**
 * Get Empresas
 * @param empresaId - Empresa id
 * @param projetoId - Projeto id
 * @param questionarioId - Questionário id
 * @returns - Empresas
 */
async function getEmpresasByQuestionario(
  empresaId: string,
  projetoId: TProjeto["id"],
  questionarioId: TQuestionario["id"]
): Promise<TEmpresa[]> {
  if (empresaId && projetoId && questionarioId) {
    const newData = await getEmpresasByQuestionarioArray(empresaId, projetoId, questionarioId);
    const promises = newData.docs.map((document_) => getEmpresaById(document_.data().idEmpresaColaborador));
    const empresas = await Promise.all(promises);
    return Array.from(new Set(empresas.map((empresa) => empresa.data()?.id)), (id) =>
      empresas.find((empresa) => empresa.data()?.id === id)?.data()
    ) as TEmpresa[];
  }

  return [];
}

export default getEmpresasByQuestionario;
