import { Card, Group, Button, Skeleton, Flex } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useDisclosure, useForceUpdate } from "@mantine/hooks";
import { IconFilter } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";
import { and, DocumentData, QuerySnapshot, where } from "firebase/firestore";
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import RefreshQueryButton from "@/components/refresh-query-button";
import { THandler } from "@/components/table/Table";
import useNonDeletedGlobalFilters from "@/hooks/useNonDeletedGlobalFilters";
import { getAllColaboradoresAtivosENaoExcluidos } from "@/modules/colaboradores/firestore";
import { getComentarios } from "@/modules/ideia-comentarios/firestore";
import { getIdeiasPublished, IDEIAS_COLLECTION_KEY } from "@/modules/ideias/firestore";
import { getLikes } from "@/modules/ideias-likes/firestore";
import ExportExcel from "@/modules/ideias-relatorio-visao-geral/components/ExportExcel";
import RelatorioVisaoGeralCard from "@/modules/ideias-relatorio-visao-geral/components/RelatorioVisaoGeralCard";
import useUserStore from "@/modules/users/store";
import RelatorioVisaoGeralIdeiasTable from "@/src/modules/ideias-relatorio-visao-geral/components/RelatorioVisaoGeralIdeiasTable";
import TableFilters from "@/src/modules/ideias-relatorio-visao-geral/components/TableFilters";

const DIAS_MES = 30;
const CEM_PORCENTO = 100;
const REFRESH_ALL_KEY = [IDEIAS_COLLECTION_KEY];

interface DateFormat {
  dataInicial: Date;
  dataFinal: Date;
}

export interface Cards {
  colaboradoresAtivos: number;
  ideias: {
    total: number;
    participantes: Set<string>;
    porcentagem: number;
  };
  curtidas: {
    total: number;
    participantes: Set<string>;
    porcentagem: number;
  };
  comentarios: {
    total: number;
    participantes: Set<string>;
    porcentagem: number;
  };
}

const defaultCard = (): Cards => ({
  colaboradoresAtivos: 0,
  ideias: { total: 0, participantes: new Set(), porcentagem: 0 },
  curtidas: { total: 0, participantes: new Set(), porcentagem: 0 },
  comentarios: { total: 0, participantes: new Set(), porcentagem: 0 }
});

// prettier-ignore
const IdeiasRelatorioVisaoGeralPage = () => {
  const [date, setDate] = useState<DateFormat>({
    dataInicial: new Date(new Date().setDate(new Date().getDate() - DIAS_MES)),
    dataFinal: new Date()
  });
  const empresa = useUserStore((state) => state.activeEmpresa);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const tableHandlersReference = useRef<THandler<TIdeia>>(null);
  useNonDeletedGlobalFilters(tableHandlersReference);
  const [opened, { close, toggle }] = useDisclosure(false);
  const queryClient = useQueryClient();
  const [cards, setCards] = useState<Cards>(defaultCard());
  const forceUpdate = useForceUpdate();

  const handleFilterApplied = useCallback(() => {
    close();
    void queryClient.invalidateQueries({ queryKey: REFRESH_ALL_KEY }).then(() => forceUpdate());
  }, [queryClient, forceUpdate, close]);

  const onDateIniciaFinalChange = useCallback((dates: [Date, Date]) => {
    setIsLoading(true);
    const [dataInicial, dataFinal] = dates;
    setDate({ dataInicial, dataFinal });
    const tableHandlers = tableHandlersReference.current;
    if (tableHandlers) {
      // Define o filtro global com base nas datas selecionadas
      tableHandlers?.table.resetGlobalFilter;
      tableHandlers.table.setGlobalFilter(
        and(
          where("createdAt", ">=", dataInicial),
          where("createdAt", "<=", dataFinal),
          where("publishedAt", "!=", null))
      );
      // Invalida a query para forçar o recarregamento dos dados
      void queryClient.invalidateQueries({ queryKey: REFRESH_ALL_KEY }).then(() => {
        forceUpdate();
        return null;
      });
    }
  }, [queryClient, forceUpdate, tableHandlersReference]);

  useEffect(() => {
    setIsLoading(true);
    const tableHandlers = tableHandlersReference.current;
    tableHandlers?.table.resetGlobalFilter;
    tableHandlers?.table.setGlobalFilter(
      and(
        where("publishedAt", "!=", null)
      )
    );
    const fetchData = async () => {
      try {
        const colaboradores = await getAllColaboradoresAtivosENaoExcluidos(empresa?.id ?? "");
        const ideias = await getIdeiasPublished(empresa?.id ?? "");
        CalcularIdeias(ideias, colaboradores, setCards, "ideias");
        await CalcularComentarios(ideias, empresa, colaboradores, setCards, "comentarios");
        await CalcularCurtidas(ideias, empresa, colaboradores, setCards, "curtidas");
        setIsLoading(false);
      } catch {
        //console.error(message, error);
      }
    };
    void fetchData();
  }, []);
  /**
   *
   */
  function handleOnTableReady(): void {
    setIsLoading(tableHandlersReference.current?.isLoading || false);
  }
  /**
   * @returns - filtros aplicados
   */
  function getFiltrosAplicados() {
    return tableHandlersReference.current?.filters?.concat([{ id: "publishedAt", value: ["!=", null] }]);
  }

  return (
    <Card withBorder shadow="md">
      <Card.Section inheritPadding py="md" withBorder>
        <Flex justify="space-between">
          <DatePickerInput
            type="range"
            w="340px"
            valueFormat="DD [de] MMMM, YYYY"
            value={[date.dataInicial, date.dataFinal]} // eslint-disable-line react-perf/jsx-no-new-array-as-prop
            onChange={onDateIniciaFinalChange}
            label="Período"
          />
          <ExportExcel cards={cards} empresa={empresa} filtros={getFiltrosAplicados()} />
        </Flex>
      </Card.Section>
      <Card.Section inheritPadding py="md" withBorder>
        <Group justify="space-between">
          <Button variant="subtle" size="compact-md" leftSection={<IconFilter size="1rem" />} onClick={toggle}>
            Filtros
          </Button>
          <RefreshQueryButton queryKey={REFRESH_ALL_KEY} />
        </Group>
      </Card.Section>
      {tableHandlersReference.current && (
        <TableFilters
          table={tableHandlersReference.current.table}
          filters={tableHandlersReference.current.filters}
          onClose={close}
          onFilterApplied={handleFilterApplied}
          opened={opened}
        />
      )}
      {isLoading ? (
        <Group justify="center" mt="md" mb="md" gap="xs">
          <Skeleton height={300} width={200} />
          <Skeleton height={300} width={200} />
          <Skeleton height={300} width={200} />
        </Group>
      ) : (
        <Group justify="center" mt="md" mb="md" gap="xs">
          <RelatorioVisaoGeralCard cards={cards} tipo="ideias" />
          <RelatorioVisaoGeralCard cards={cards} tipo="comentarios" />
          <RelatorioVisaoGeralCard cards={cards} tipo="curtidas" />
          <RelatorioVisaoGeralCard cards={cards} tipo="resumo" />
        </Group>
      )}
      <RelatorioVisaoGeralIdeiasTable
        ref={tableHandlersReference}
        onTableReady={handleOnTableReady}
      />
    </Card>
  );
};

export default IdeiasRelatorioVisaoGeralPage;

/**
 *
 * @param documentSnapshots - Documentos dos ideias
 * @param colaboradoresAtivos - Colaboradores ativos
 * @param setCards - setter
 * @param card - tipo do card
 * @returns - Quantidade de ideias
 */
function CalcularIdeias(
  documentSnapshots: QuerySnapshot<TIdeia, DocumentData>,
  colaboradoresAtivos: QuerySnapshot<TColaborador, DocumentData>,
  setCards: Dispatch<SetStateAction<Cards | undefined>>,
  card: keyof Cards
) {
  setCards((oldCards) => {
    const baseCards: Cards = getDefaultCard(oldCards);
    const updatedCards = { ...baseCards };
    updatedCards.colaboradoresAtivos = colaboradoresAtivos.docs.length;

    const participantes = new Set<string>();
    for (const ds of documentSnapshots.docs) {
      if (ds.exists()) {
        const ideia = ds.data();
        participantes.add(ideia.createdBy);
        if (typeof updatedCards[card] === "object" && updatedCards[card] !== null) {
          (updatedCards[card] as { participantes: Set<string> }).participantes.add(ideia.createdBy);
        }
      }
    }

    const total = documentSnapshots.docs.length;
    const qtdParticipantes = participantes.size;
    const porcentagem = (qtdParticipantes / colaboradoresAtivos.docs.length) * CEM_PORCENTO;

    const cardKey = card as keyof typeof updatedCards;
    if (typeof updatedCards[cardKey] === "object" && updatedCards[cardKey] !== null) {
      (updatedCards[cardKey] as { total: number; participantes: Set<string>; porcentagem: number }) = {
        total,
        participantes,
        porcentagem
      };
    }

    return updatedCards;
  });

  return null;
}

/**
 *
 * @param oldCards - Cards antigos
 * @returns - Cards
 */
function getDefaultCard(oldCards: Cards | undefined): Cards {
  return (
    oldCards ?? {
      colaboradoresAtivos: 0,
      ideias: { total: 0, participantes: new Set<string>(), porcentagem: 0 },
      curtidas: { total: 0, participantes: new Set<string>(), porcentagem: 0 },
      comentarios: { total: 0, participantes: new Set<string>(), porcentagem: 0 }
    }
  );
}

/**
 *
 * @param documentSnapshots - Documentos dos ideias
 * @param empresa - empresa ativa
 * @param colaboradoresAtivos - Colaboradores ativos
 * @param setCards - setter
 * @param card - tipo do card
 * @returns - Quantidade de ideias
 */
async function CalcularComentarios(
  documentSnapshots: QuerySnapshot<TIdeia, DocumentData>,
  empresa: TEmpresa | undefined,
  colaboradoresAtivos: QuerySnapshot<TColaborador, DocumentData>,
  setCards: Dispatch<SetStateAction<Cards | undefined>>,
  card: keyof Cards
) {
  let movimentacoes = 0;
  const envolvidos = new Set<string>();
  const likesPromises: Promise<QuerySnapshot<TIdeiaComentario, DocumentData>>[] = [];
  for (const ds of documentSnapshots.docs) {
    if (ds.exists()) {
      const ideia = ds.data();
      likesPromises.push(getComentarios(empresa?.id ?? "", ideia.id, { sorting: [{ id: "createdAt", desc: false }] }));
    }
  }

  const likesSnapshots = await Promise.all(likesPromises); // eslint-disable-line compat/compat
  for (const cs of likesSnapshots) {
    for (const comentarioDocument of cs.docs) {
      envolvidos.add(comentarioDocument.data().createdBy);
      movimentacoes += 1;
    }
  }
  setCards((previousCards) => {
    const cards = previousCards ?? getDefaultCard(previousCards);
    const cardData = cards[card] as { total: number; participantes: Set<string>; porcentagem: number };
    return {
      ...cards,
      colaboradoresAtivos: colaboradoresAtivos.docs.length,
      [card]: {
        total: movimentacoes,
        porcentagem: (envolvidos.size / colaboradoresAtivos.docs.length) * CEM_PORCENTO,
        participantes: new Set([...(cardData?.participantes || []), ...envolvidos])
      }
    } as Cards;
  });
  return null;
}

/**
 *
 * @param documentSnapshots - Documentos dos ideias
 * @param empresa - empresa ativa
 * @param colaboradoresAtivos - Colaboradores ativos
 * @param setCards - setter
 * @param card - tipo do card
 * @returns - Quantidade de ideias
 */
async function CalcularCurtidas(
  documentSnapshots: QuerySnapshot<TIdeia, DocumentData>,
  empresa: TEmpresa | undefined,
  colaboradoresAtivos: QuerySnapshot<TColaborador, DocumentData>,
  setCards: Dispatch<SetStateAction<Cards | undefined>>,
  card: keyof Cards
) {
  let movimentacoes = 0;
  const envolvidos = new Set<string>();
  for (const ds of documentSnapshots.docs) {
    if (ds.exists()) {
      const ideia = ds.data();
      const snapshot = getLikes(empresa?.id ?? "", ideia.id);
      await snapshot.then((likesSnapshot) => {
        for (const document_ of likesSnapshot) {
          envolvidos.add(document_.createdBy);
          movimentacoes += 1;
        }
        return null;
      });
    }
  }
  setCards((previousCards) => {
    const cards = previousCards ?? getDefaultCard(previousCards);
    const cardData = cards[card] as { total: number; participantes: Set<string>; porcentagem: number };
    return {
      ...cards,
      colaboradoresAtivos: colaboradoresAtivos.docs.length,
      [card]: {
        total: movimentacoes,
        porcentagem: (envolvidos.size / colaboradoresAtivos.docs.length) * CEM_PORCENTO,
        participantes: new Set([...(cardData?.participantes || []), ...envolvidos])
      }
    } as Cards;
  });

  return null;
}
