import { FirestoreError } from "firebase/firestore";
import { useEffect } from "react";
import { captureException } from "@/services/log";

/**
 * Catch Firestore error and log it
 * @param error - Firestore error to catch
 * @param displayError - Whether to display error or not
 */
function useCatchFirestoreError(error: FirestoreError | null, displayError = true) {
  useEffect(() => {
    if (error) {
      captureException(error, displayError);
    }
  }, [displayError, error]);
}

export default useCatchFirestoreError;
