import { serverTimestamp } from "firebase/firestore";
import { ObjectSchema } from "yup";
import { getCurrentUser } from "@/services/auth";
import schema from ".";

/**
 * Define only the meta fields that are not part of the form
 */
const auditSchema: ObjectSchema<TAuditForm, unknown, TAuditForm> = schema.object<TAuditForm>().shape({
  id: schema.string().optional(),
  refPath: schema.string().optional(),
  createdAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .required()
    .defined()
    .nonNullable()
    .default<ReturnType<typeof serverTimestamp>>(() => serverTimestamp()),
  createdBy: schema
    .string()
    .required()
    .defined()
    .nonNullable()
    .default(() => {
      const currentUserId = getCurrentUser()?.uid;
      if (!currentUserId) {
        throw new Error("The current user is not defined");
      }
      return currentUserId;
    }),
  publishedAt: schema.mixed<ReturnType<typeof serverTimestamp>>().defined().nullable().default(null),
  updatedAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .default<ReturnType<typeof serverTimestamp>>(() => serverTimestamp()),
  updatedBy: schema.string().default(() => {
    const currentUserId = getCurrentUser()?.uid;
    if (!currentUserId) {
      throw new Error("The current user is not defined");
    }
    return currentUserId;
  })
});

export default auditSchema;
