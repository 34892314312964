import { Card, Text, Group, Select, Stack, Button } from "@mantine/core";
import { useInputState } from "@mantine/hooks";
import { IconChevronDown } from "@tabler/icons-react";
import NoData from "@/components/no-data";
import DesafioComentario from "./DesafioComentario";
import useGetComentarios from "./useGetComentarios";

const sortByOptions = [
  { label: "Últimos comentários", value: "createdAt,desc" },
  { label: "Primeiros comentários", value: "createdAt" }
];

const DesafioComentariosList = ({ desafio }: { desafio: TDesafio }) => {
  const [sortBy, setSortBy] = useInputState("createdAt,desc");
  const {
    data: comentariosPages,
    fetchNextPage,
    isFetching,
    hasNextPage,
    isLoading
  } = useGetComentarios(desafio, { sortBy });

  const comentarios = comentariosPages?.pages.flatMap((page) => page.entries);

  const noData = !isLoading && comentarios && comentarios.length === 0;
  if (noData) {
    return (
      <NoData title="Nenhum comentário encontrado" my="md">
        Seja o primeiro a comentar!
      </NoData>
    );
  }

  /**
   * Handle next page click
   */
  function handleNextPageClick() {
    void fetchNextPage();
  }

  return (
    <Stack gap="xs" mt="md">
      Lista de comentários
      {comentarios && (
        <Card bg="gray.2" py="xs" mt="md">
          <Group align="center">
            <Text fz="sm">Ordenar por</Text>
            <Select size="xs" data={sortByOptions} value={sortBy} onChange={setSortBy} />
          </Group>
        </Card>
      )}
      {comentarios && (
        <Stack gap="xs" mt="md">
          {comentarios.map((comentario) => (
            <div id={`comentario-${comentario.id}`} key={comentario.id}>
              <DesafioComentario desafio={desafio} comentario={comentario} />
            </div>
          ))}
        </Stack>
      )}
      {hasNextPage && (
        <Group mt="md">
          <Button
            variant="transparent"
            size="xs"
            rightSection={<IconChevronDown size="1rem" />}
            onClick={handleNextPageClick}
            loading={isLoading || isFetching}
          >
            Ver mais comentários
          </Button>
        </Group>
      )}
    </Stack>
  );
};

export default DesafioComentariosList;
