import { Button, Group, Space, Textarea, TextInput } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React, { useCallback, useState } from "react";

interface MyState {
  id?: string | number | undefined;
  nome: string;
  descricao: string;
}

interface Tipo {
  id?: string | number;
  nome: string;
  descricao: string;
}

/**
 * Handles input change for a specific field.
 * @param field - The name of the field being updated
 * @param setValues - The state setter function
 * @returns - function
 */
function handleInputChange(field: string, setValues: React.Dispatch<React.SetStateAction<MyState>>) {
  return (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues((values: Tipo) => ({ ...values, [field]: event.target.value }));
  };
}

const TipoDeInovacaoForm = ({
  onSuccess,
  setTaticas,
  data
}: {
  onSuccess: () => void;
  setTaticas: React.Dispatch<React.SetStateAction<unknown[]>>;
  data: Tipo;
}) => {
  const [values, setValues] = useState<MyState>(data);

  /**
   * Salva os tipos.
   */
  function saveTipos() {
    try {
      if (values.nome === "") {
        throw new Error("Nome obrigatório");
      }

      if (values.descricao === "") {
        throw new Error("Descrição obrigatória");
      }

      if (data?.id === undefined || data.id === null) {
        setTaticas((tipos) => [...tipos, { ...(values as Tipo), id: generateRandomId() }]);
      } else {
        setTaticas((tipos) => tipos.map((item: Tipo) => (item?.id === data?.id ? { ...(values as Tipo) } : item)));
      }
      onSuccess();
    } catch (error) {
      if (error instanceof Error) {
        showNotification({
          message: error.message,
          color: "red",
          autoClose: 2500
        });
      } else {
        showNotification({
          message: "Ocorreu um erro desconhecido.",
          color: "red",
          autoClose: 2500
        });
      }
    }
  }

  const handleChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValues((previousValues) => ({ ...previousValues, descricao: event.target.value }));
  }, []);

  return (
    <form>
      <TextInput mt="md" onChange={handleInputChange("nome", setValues)} value={values.nome} label="Nome da tática" />
      <Space h="lg" />
      <Textarea autosize minRows={5} onChange={handleChange} value={values.descricao} label="Descrição" />
      <Space h="xl" />
      <Group justify="flex-end">
        <Button variant="filled" onClick={saveTipos}>
          Salvar
        </Button>
      </Group>
    </form>
  );
};

export default TipoDeInovacaoForm;

const RANDOM_ID_LENGTH_STRING = 36;
const RANDOM_ID_LENGTH = 9;
const RANDOM_ID_START_INDEX = 2;

/**
 * Gera um ID aleatório.
 * @returns string O ID gerado.
 */
function generateRandomId() {
  return Math.random().toString(RANDOM_ID_LENGTH_STRING).slice(RANDOM_ID_START_INDEX, RANDOM_ID_LENGTH); // Usando slice em vez de substr
}
