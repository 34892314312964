import { getEmpresaById } from "@/modules/empresas/firestore";

/**
 * Get campanhas
 * @param data - data regulamento
 * @returns - campanhas
 */
// prettier-ignore
async function GetEmpresas(
  data: TConvite[] | undefined,
): Promise<TEmpresa[]> {
  const empresas: TEmpresa[] = [];

  if (data && data.length > 0) {
    for (const item of data) {
      const newData = await getEmpresaById(item.idEmpresa || "");
      const empresaData = newData.data();
      if (empresaData) {
        empresas.push(empresaData);
      }
    }

    return empresas;
  }

  return [];
}

export default GetEmpresas;
