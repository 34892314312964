import { subject } from "@casl/ability";
import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { QUERY_KEY } from "@/modules/tipos-de-respostas/components/tipos-de-respostas-table/TiposDeRespostasTable";
import useUserStore from "@/modules/users/store";
import { getAutorizacao } from "../../firestore";

type TGetTiposDeRespostasResponse = Awaited<ReturnType<typeof getAutorizacao>>;

/**
 * Get questionário de inovação query hook
 * @param select - dados selecionados
 * @param tipoDeAutorizacao - tipo de autorizacao
 * @returns Questionário de inovação query hook
 */
function useGetAutorizacao(select: string, tipoDeAutorizacao: string) {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  return useQuery<TGetTiposDeRespostasResponse, FirestoreError, TAutorizacao[]>({
    queryKey: ["empresas", { id: activeEmpresaId }, QUERY_KEY, select, tipoDeAutorizacao],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      return getAutorizacao(activeEmpresaId, select, tipoDeAutorizacao);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }
      return dataResponse.docs.map((document_) => subject("TAutorizacao", document_.data()));
    }
  });
}

export default useGetAutorizacao;
