import { subject } from "@casl/ability";
import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getMarco } from "../firestore";

export const MARCOS_QUERY_KEY: string = "marcos-list" as const;

type TGetMarcoResponse = Awaited<ReturnType<typeof getMarco>>;

/**
 * Get marco from reference path
 * @param marcoReferencePath - The marco path
 * @returns - Marco or null if not found
 */
function useGetMarcoFromReferencePath(marcoReferencePath: string) {
  return useQuery<TGetMarcoResponse, FirestoreError, TMarco | null>({
    queryKey: [MARCOS_QUERY_KEY, marcoReferencePath],
    queryFn() {
      return getMarco(marcoReferencePath);
    },
    select(dataResponse) {
      if (!dataResponse.exists()) {
        return null;
      }

      return subject("TMarco", dataResponse.data());
    }
  });
}

export default useGetMarcoFromReferencePath;
