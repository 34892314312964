import { Button, Card, Group, ScrollArea, Space, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { Timestamp } from "firebase/firestore";
import { useEffect, useState } from "react";
import ConviteRespostaCard from "@/modules/questionario-de-inovacao/components/questionario-item/ConviteRespostaCard";
import useGetConvitesQuestionario from "@/modules/questionario-empresa/hooks/useGetConvitesQuestionario";
import RelatorioComponent from "@/modules/relatorio/components/relatorio-item";
import useUserStore from "@/modules/users/store";
import useGetQuestionarioItem from "../responder-questionario-externo-page/hooks/useGetQuestionarioFromUrl";

const ConvidarColaboradoresPage = () => {
  const [values, setValues] = useState({ questionario: "" });
  const activeLicenciadaId = useUserStore((state) => state.activeLicenciadaId);
  const reloadConvites = useUserStore((state) => state.reloadConvites);
  const projeto = useUserStore((state) => state.activeProjeto);
  const empresaId = useUserStore((state) => state.activeEmpresaId);
  const { data: questionario } = useGetQuestionarioItem(empresaId ?? "", values.questionario);
  const { data: convites, refetch } = useGetConvitesQuestionario(questionario?.id || "");

  useEffect(() => {
    setValues((values) => ({ ...values, questionario: "" }));
  }, [projeto]);

  useEffect(() => {
    void refetch();
  }, [reloadConvites]);

  return (
    <>
      <RelatorioComponent setValues={setValues} questionario={values.questionario} />
      <Space h={"xl"} />
      {projeto !== "" && projeto !== null && values.questionario !== "" && values.questionario !== null ? (
        <Card withBorder shadow="md" style={{ flex: 1 }}>
          <Group justify={"space-between"}>
            <Text size="xl">Como estão as participações?</Text>
            <Button
              onClick={handleClick(questionario?.id, empresaId, questionario?.idProjeto, activeLicenciadaId)}
              color="rgba(8, 68, 244, 1)"
              disabled={verificacaoExpirada(questionario)}
            >
              Convidar colaboradores
            </Button>
          </Group>
          <Space h="md" />
          <ScrollArea w="100%">
            {convites?.length ? (
              convites.map((convite) => <ConviteRespostaCard key={convite.id} convite={convite} />)
            ) : (
              <Group align={"center"} justify={"center"}>
                <Space h="md" />
                <Text fw={"bold"}>Nenhum colaborador convidado!</Text>
                <Space h="md" />
              </Group>
            )}
          </ScrollArea>
        </Card>
      ) : (
        <Group justify={"center"}>
          <Text fw={700}>Selecione um projeto e um questionário para convidar colaboradores!</Text>
        </Group>
      )}
      <Space h={"xl"} />
    </>
  );
};

export default ConvidarColaboradoresPage;

const verificacaoExpirada = (questionario: TQuestionario | null | undefined) => {
  const now = new Date();
  const anoAtuacao =
    typeof questionario?.dataAtuacao === "number"
      ? verificationDate(new Date())
      : verificationDate(questionario?.dataAtuacao);
  const anoVigencia =
    typeof questionario?.dataVigencia === "number"
      ? verificationDate(new Date())
      : verificationDate(questionario?.dataVigencia);

  if (anoVigencia && anoAtuacao) {
    return new Date(anoAtuacao) > now || new Date(anoVigencia) < now;
  }
  return false;
};

/**
 * Handles the click event on the button.
 * @param id - The id questions.
 * @param idEmpresa - The id company.
 * @param idProjeto - The id project.
 * @param licenciadaId - Id da Licenciada
 * @returns - The return the click.
 */
function handleClick(
  id: string = "",
  idEmpresa: string = "",
  idProjeto: string = "",
  licenciadaId: string | undefined
) {
  return (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    modals.openContextModal({
      size: "xl",
      innerProps: {
        idQuestionario: id,
        idEmpresa,
        idProjeto,
        licenciadaId
      },
      modal: "convidar-colaborador-modal"
    });
  };
}

const SEGUNDOS_EM_MILISEGUNDOS = 1000;

const verificationDate = (date: Date | Timestamp | undefined): Date | null => {
  let data: number | undefined;

  if (date instanceof Date) {
    data = date.getTime() / SEGUNDOS_EM_MILISEGUNDOS;
  } else if (date && typeof date === "object" && "seconds" in date) {
    data = date.seconds;
  }

  return data ? new Date(data * SEGUNDOS_EM_MILISEGUNDOS) : null;
};
