import { TransformedValues, useForm, yupResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { Asserts } from "yup";
import useInviteEmpresaMutation from "@/modules/empresas/hooks/useInviteEmpresaMutation";
import { captureException } from "@/services/log";
import inviteEmpresaSchema from "./invite-empresa-schema";

type InviteInputs = Asserts<typeof inviteEmpresaSchema>;

/**
 * Invite Empresa Form hook
 * @param root0 - The options
 * @param root0.onSuccess - Function to be called when the invite is sent successfully
 * @returns - Invite Empresa Form
 */
function useInviteEmpresaForm({ onSuccess }: { onSuccess?: () => void }) {
  const form = useForm<InviteInputs>({
    validate: yupResolver(inviteEmpresaSchema),
    transformValues: (values) => inviteEmpresaSchema.cast(values),
    initialValues: inviteEmpresaSchema.getDefault() as InviteInputs
  });

  type TInviteFormTransformed = TransformedValues<typeof form>;

  const {
    mutate: sendInvite,
    reset,
    isPending
  } = useInviteEmpresaMutation({
    onSuccess() {
      showNotification({
        message: "Convite enviado com sucesso!",
        autoClose: 3500
      });
      reset();
      form.reset();
      if (onSuccess) {
        onSuccess();
      }
    },
    onError(error: Error) {
      captureException(error, true);
    }
  });

  /**
   * Handle form submit
   * @param values - Form values
   */
  function handleFormSubmit(values: TInviteFormTransformed) {
    sendInvite(values);
  }

  return { form, onSubmit: form.onSubmit(handleFormSubmit), isPending };
}

export default useInviteEmpresaForm;
