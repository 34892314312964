import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Group,
  LoadingOverlay,
  NumberInput,
  Select,
  Stack,
  Text,
  TextInput
} from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";
import { Key, useState } from "react";
import { extractTextInputPropertiesFromFormSchema } from "@/utils/form";
import useTiposDeRespostasForm from "./useTiposDeRespostasForm";
import { TTiposDeRespostasForm } from "../../firestore";
import tiposDeRespostasSchema from "../../schema/tipos-de-respostas-schema";

interface TTipoCampos {
  campo: string | undefined;
  valor: number | undefined;
}
// prettier-ignore
const TiposDeRespostasForm = ({
  tiposDeRespostas,
  onSuccess
}: {
  tiposDeRespostas: TTiposDeRespostasForm;
  onSuccess: () => void;
}) => {
  const { form, isPending, onSubmit } = useTiposDeRespostasForm(tiposDeRespostas, {
    onSuccess
  });
  const { values } = form;
  const [valores, setValores] = useState({ campo: "", valor: 0 });

  const fieldsDescriptor = tiposDeRespostasSchema.describe();

  const resposta: string = (form.getInputProps("tipoDeResposta")?.value as string) || "0";
  let campos: TTipoCampos[] = [];

  if (values?.campos) {
    campos = values?.campos as TTipoCampos[];
  }

  const removerValor = (item: TTipoCampos) => {
    if (item) {
      const valores: TTipoCampos[] = values.campos as TTipoCampos[];
      form.setFieldValue("campos", valores.filter((row: TTipoCampos) => row.campo !== item.campo));
    }
  };

  // prettier-ignore
  /**
   * Handle change texto
   * @param field - The field function
   * @returns - function
   */
  function alterarTexto(field: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      setValores((antigos) => ({ ...antigos, [field]: event.target.value }));
    };
  }

  // prettier-ignore
  /**
   * Handle change texto
   * @param field - The field function
   * @returns - function
   */
  function alterarValor(field: string) {
    return (event: number) => {
      setValores((antigos) => ({ ...antigos, [field]: event }));
    };
  }

  // prettier-ignore
  /**
   * Handle save valores
   */
  function salvarValores() {
    if (values && values.campos) {
      const antigosCampos: TTipoCampos[] = values.campos as TTipoCampos[];
      form.setFieldValue("campos", [...antigosCampos, valores]);
    } else {
      form.setFieldValue("campos", [valores]);
    }
    setValores({ campo: "", valor: 0 });
  }

  // prettier-ignore
  /**
   * Handle remove valor
   * @param item - The field function
   * @returns - function
   */
  function excluirValor(item: TTipoCampos) {
    return () => {
      removerValor(item);
    };
  }

  return (
    <form onSubmit={onSubmit}>
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />
        <Stack gap="sm" mb="lg">
          <TextInput
            type="text"
            style={{ flex: 1 }}
            {...form.getInputProps("nome")}
            {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "nome")}
          />
          <Select
            placeholder="Selecione o tipo de resposta"
            data={tipoDeResposta}
            {...form.getInputProps("tipoDeResposta")}
            {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "tipoDeResposta")}
          />
          <Select
            placeholder="Selecione a orientação das opções"
            data={orientacoes}
            {...form.getInputProps("orientacao")}
            {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "orientacao")}
          />
          {(resposta == "3" || resposta == "4") && (
            <Stack gap="sm" mt="lg">
              <Text fw="bold" fz={"sm"}>
                Respostas
              </Text>
              <Stack dir="col">
                <Flex gap={"sm"}>
                  <TextInput
                    type="text"
                    style={{ flex: 3 }}
                    onChange={alterarTexto("campo")}
                    value={valores?.campo}
                    placeholder="Legenda"
                  />
                  <NumberInput
                    style={{ flex: 1 }}
                    onChange={alterarValor("valor")}
                    value={valores?.valor}
                    placeholder="Valor"
                    max={10}
                    min={-1}
                  />
                  <ActionIcon color="#198754" variant="outline" size="lg" onClick={salvarValores}>
                    <IconPlus size="1.125rem" color="#198754" />
                  </ActionIcon>
                </Flex>
                {campos?.map((item: TTipoCampos, index: Key | null | undefined) => (
                  <Flex gap={"sm"} key={index}>
                    <Text flex={3} ml={2}>
                      {item.campo}
                    </Text>
                    <Text flex={1} ml={2}>
                      {item.valor}
                    </Text>
                    <ActionIcon color="#dc3545" variant="outline" size="lg" onClick={excluirValor(item)}>
                      <IconTrash size="1.125rem" color="#dc3545" />
                    </ActionIcon>
                  </Flex>
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      </Box>
      <Group justify={"flex-end"} mt="md">
        <Button type="submit" loading={isPending}>
          Salvar
        </Button>
      </Group>
    </form>
  );
};

export default TiposDeRespostasForm;

const tipoDeResposta = [
  { value: "1", label: "Texto" },
  { value: "2", label: "Escala" },
  { value: "3", label: "Caixas de Seleção" },
  { value: "4", label: "Escolha Única" }
];
const orientacoes = [
  { value: "1", label: "Vertical" },
  { value: "2", label: "Horizontal" }
];
