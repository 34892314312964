import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getRegulamentoDocumentReferenceFromReferencePath } from "@/modules/regulamento-ideias/firestore";
import useUserStore from "@/modules/users/store";
import { getIdeiasByRegulamento, IDEIAS_COLLECTION_KEY } from "../../firestore";

type TGetTipoDeInovacaoResponse = Awaited<ReturnType<typeof getIdeiasByRegulamento>>;

/**
 * Get tipos de inovação query hook
 * @param referencePath - string
 * @returns tipos de inovação query hook
 */
function useGetIdeiasByRegulamento(referencePath: string | undefined) {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  return useQuery<TGetTipoDeInovacaoResponse, FirestoreError, TIdeia[]>({
    queryKey: ["empresas", { id: activeEmpresaId }, IDEIAS_COLLECTION_KEY, referencePath],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!referencePath) {
        throw new Error("Empresa não selecionada");
      }
      const regulamentoDocumentReference = getRegulamentoDocumentReferenceFromReferencePath(referencePath);

      return getIdeiasByRegulamento(activeEmpresaId, regulamentoDocumentReference);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }
      return dataResponse.docs.map((document_) => document_.data());
    }
  });
}

export default useGetIdeiasByRegulamento;
