import { Avatar, Badge, Button, Card, Flex, Group, Image, Text } from "@mantine/core";
import { IconCircleCheck, IconCircleX, IconStar, IconStarFilled, IconTrash } from "@tabler/icons-react";
import { Timestamp } from "firebase/firestore";
import { MouseEventHandler } from "react";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import useUserStore from "@/modules/users/store";

const FORM_TYPE_INSCRITO = 1;
const FORM_TYPE_PARTICIPOU = 2;
const FORM_TYPE_NAO_PARTICIPOU = 3;
const FORM_TYPE_INSCRICAO_REPROVADA = 4;

interface Inscricao {
  id: string | undefined;
  data: Date | Timestamp;
  status?: number | undefined;
  destaque?: boolean | undefined;
}

// prettier-ignore
const DesafioCronogramaList = ({ data, changeMutate, completed }: { data: Inscricao, changeMutate: (type: boolean | undefined, id: string | undefined, status: number | undefined) => MouseEventHandler<HTMLButtonElement> | undefined, completed: boolean }) => {
  const color = "rgba(8, 68, 244, 1)"
  const empresa = useUserStore((state) => state.activeEmpresa);
  const { data: colaborador } = useGetColaboradorById(empresa?.id || "", data?.id || "");

  return (
    <>
      <Card withBorder>
        <Group justify={"space-between"}>
          <Group>
            {colaborador && colaborador.image && colaborador.image.length > 0 ? <Image
              h={38}
              w={38}
              radius="xl"
              src={colaborador.image[0]?.path as string}
            /> : <Avatar src={""} radius="xl" color="white" size="md" alt={""} style={{ cursor: "pointer" }} />}
            <Text>
              {colaborador?.nome}
            </Text>
          </Group>
          <Group>
            <Flex direction={"column"}>
              <Text fz="sm">Status</Text>
              <StatusComponent status={data && data.status ? data.status : 1} />
            </Flex>
          </Group>
          <Group>
            <Flex direction={"column"}>
              <Text fz="sm">Data da inscrição</Text>
              <Text>
                {data?.data instanceof Timestamp
                  ? data?.data.toDate().toLocaleDateString("pt-BR")
                  : data?.data?.toLocaleDateString("pt-BR") ?? ""}
              </Text>
            </Flex>
          </Group>
          {completed ? <Group>
            <Button onClick={changeMutate(data?.destaque, data?.id, FORM_TYPE_PARTICIPOU)} bg="transparent" p={0}>
              <IconCircleCheck style={{ color: "rgba(105, 198, 105, 1)", cursor: "pointer" }} />
            </Button>
            <Button onClick={changeMutate(data?.destaque, data?.id, FORM_TYPE_NAO_PARTICIPOU)} bg="transparent" p={0}>
              <IconCircleX style={{ color: "rgba(229, 78, 78, 1)", cursor: "pointer" }} />
            </Button>
            <Button onClick={changeMutate(data?.destaque, data?.id, FORM_TYPE_INSCRICAO_REPROVADA)} bg="transparent" p={0}>
              <IconTrash style={{ color, cursor: "pointer" }} />
            </Button>
            {data?.destaque ? <Button onClick={changeMutate(false, data?.id, data?.status)} bg="transparent" p={0}>
              <IconStarFilled style={{ color, cursor: "pointer" }} />
            </Button>
              : <Button onClick={changeMutate(true, data?.id, FORM_TYPE_PARTICIPOU)} bg="transparent" p={0}>
                <IconStar style={{ color, cursor: "pointer" }} />
              </Button>
            }
          </Group> : <></>}
        </Group>
      </Card>
    </>
  );
};

export default DesafioCronogramaList;
// prettier-ignore
const StatusComponent = ({ status }: { status: number }) => {
  return status === FORM_TYPE_INSCRITO ? (
    <Badge color="rgba(255, 199, 39, 0.34)" radius="sm">
      <Text size="xs" tt="capitalize" color={"black"}>
        Inscrito
      </Text>
    </Badge>
  ) : status === FORM_TYPE_PARTICIPOU ? (
    <Badge color="rgba(105, 198, 105, 0.2)" radius="sm">
      <Text size="xs" tt="capitalize" color={"black"}>
        Participou
      </Text>
    </Badge>
  ) : status === FORM_TYPE_NAO_PARTICIPOU ?
    (
      <Badge color="rgba(229, 78, 78, 0.35)" radius="sm">
        <Text size="xs" tt="capitalize" color={"black"}>
          Não participou
        </Text>
      </Badge>
    ) : status === FORM_TYPE_INSCRICAO_REPROVADA ?
      (
        <Badge color="rgba(229, 78, 78, 0.87)" radius="sm">
          <Text size="xs" tt="capitalize" color={"black"}>
            Inscrição reprovada
          </Text>
        </Badge>
      )
      : <></>
}
