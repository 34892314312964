import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { QUERY_KEY } from "@/modules/grupos-diagnostico/components/grupos-diagnostico-table/GruposDiagnosticoTable";
import { getGrupoArray } from "@/modules/grupos-diagnostico/firestore";

type TGetGroupResponse = Awaited<ReturnType<typeof getGrupoArray>>;

/**
 * Hook to get group from convite
 * @param root0 - params
 * @param root0.id - id do questionario
 * @param root0.activeEmpresaId - empresa selecionada
 * @returns - Query result
 */
function useQueryGroupConvite({
  id,
  activeEmpresaId
}: {
  id: string | undefined;
  activeEmpresaId: string | undefined;
}) {
  return useQuery<TGetGroupResponse, FirestoreError, TGruposDiagnostico[]>({
    queryKey: [QUERY_KEY, id, activeEmpresaId],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!id) {
        throw new Error("Nenhum questionario");
      }

      return getGrupoArray(activeEmpresaId, id);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((document_) => document_.data());
    },
    enabled: !!id && !!activeEmpresaId
  });
}

export default useQueryGroupConvite;
