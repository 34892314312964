import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { getCountIdeiaComentarios } from "../firestore";

type TGetSumMoedasPerIdeiaResponse = Awaited<ReturnType<typeof getCountIdeiaComentarios>>;
type TQueryOptions = Omit<UseQueryOptions<TGetSumMoedasPerIdeiaResponse, FirestoreError, number>, "queryKey">;

/**
 * Get total comentarios per ideia
 * @param ideia - Ideia to get activities
 * @param options - Query options to use
 * @returns - Query result
 */
function useCountTotalComentariosPerIdeia(ideia: TIdeia | null, options: TQueryOptions = {}) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  return useQuery<TGetSumMoedasPerIdeiaResponse, FirestoreError, number | null>({
    queryKey: [activeEmpresaId, "ideia", ideia?.id, "activities", "comentarios", ideia, ideia?.id],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!ideia) {
        throw new Error("Ideia não informada");
      }

      return getCountIdeiaComentarios(activeEmpresaId, ideia.id);
    },
    select(dataSnapshot) {
      return dataSnapshot.data().count;
    },
    enabled: !!ideia,
    ...options
  });
}

export default useCountTotalComentariosPerIdeia;
