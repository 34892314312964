import { FileWithPath } from "@mantine/dropzone";
import { serverTimestamp } from "firebase/firestore";
import { Asserts, ObjectSchema } from "yup";
import schema from "@/schema";
import { getCurrentUser } from "@/services/auth";
import enderecoSchema from "./endereco-schema";
import responsavelSchema from "./responsavel-schema";
import { TEmpresaForm, TEmpresaDatabaseFields, TEmpresaFormFields } from "../firestore";

const MIN_NAME_LENGTH = 3;

export const porteData: { label: string; value: TPorte }[] = [
  { value: "MICRO_EMPREENDEDOR", label: "Micro Empreendedor" },
  { value: "MICRO_EMPRESA", label: "Micro Empresa" },
  { value: "EMPRESA_PEQUENO_PORTE", label: "Pequeno Porte" },
  { value: "EMPRESA_MEDIO_PORTE", label: "Médio Porte" },
  { value: "EMPRESA_GRANDE_PORTE", label: "Grande Porte" }
];

export const numeroColaboradoresData: { label: string; value: TNumeroColaboradores }[] = [
  { value: "ATE_50", label: "Até 50" },
  { value: "DE_51_A_100", label: "de 51 a 100" },
  { value: "DE_101_A_200", label: "de 101 a 200" },
  { value: "DE_201_A_500", label: "de 201 a 500" },
  { value: "ACIMA_DE_500", label: "acima de 500" }
];

export const autocadastroOriginData = [
  { value: "REDES_SOCIAIS", label: "Através das redes sociais" },
  { value: "INDICACAO", label: "Através de uma indicação" },
  { value: "BUSCA", label: "Através de pesquisa na internet" },
  { value: "ANUNCIO", label: "Por anúncios online" },
  { value: "EVENTO", label: "Por meio de um evento ou conferência" },
  { value: "SITE", label: "Por meio de um site ou blog" },
  { value: "E_MAIL", label: "Por meio de um e-mail de divulgação" }
];
/**
 * Define only the meta fields that are not part of the form
 */
const empresaSchemaDatabaseFields: ObjectSchema<TEmpresaDatabaseFields> = schema.object().shape({
  createdAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .required()
    .defined()
    .nonNullable()
    .default<ReturnType<typeof serverTimestamp>>(() => serverTimestamp()),
  createdBy: schema
    .string()
    .required()
    .defined()
    .nonNullable()
    .default(() => getCurrentUser()?.uid ?? ""),
  updatedAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .default<ReturnType<typeof serverTimestamp>>(() => serverTimestamp()),
  updatedBy: schema.string().default(() => getCurrentUser()?.uid ?? ""),
  deletedAt: schema.mixed<ReturnType<typeof serverTimestamp>>().defined().nullable().default(null)
});

/**
 * Define the schema for the form
 */
const empresaSchemaFormFields: ObjectSchema<TEmpresaFormFields> = schema.object({
  id: schema.string().optional(),
  refPath: schema.string().optional(),
  cnpj: schema.string().trim().cnpj().required().defined().default("").label("CNPJ"),
  razaoSocial: schema.string().trim().min(MIN_NAME_LENGTH).required().defined().default("").label("Razão Social"),
  nomeFantasia: schema.string().trim().min(MIN_NAME_LENGTH).required().defined().default("").label("Nome Fantasia"),
  email: schema.string().trim().email().required().defined().default("").label("E-mail"),
  telefone: schema.string().trim().required().defined().default("").label("Telefone"),
  codigoCNAE: schema.string().trim().required().defined().default("").label("Código CNAE"),
  cnae: schema.string().trim().required().defined().default("").label("CNAE"),
  status: schema.string().trim().required().defined().default("ATIVO").label("Status"),
  idColaboradorConvidando: schema.string().optional(),
  setores: schema
    .array()
    .of(schema.string().required().defined())
    .optional()
    .defined()
    .min(1)
    .default([])
    .label("Setores"),
  image: schema.array().of(schema.mixed<FileWithPath>().optional().defined()).required().defined().default([]),
  porte: schema
    .mixed<TPorte>()
    .oneOf(porteData.map((porte) => porte.value))
    .required()
    .defined()
    .label("Porte"),
  numeroColaboradores: schema
    .mixed<TNumeroColaboradores>()
    .oneOf(numeroColaboradoresData.map((numeroColaboradores) => numeroColaboradores.value))
    .required()
    .defined()
    .label("Número de Colaboradores"),
  responsavel: responsavelSchema.label("Responsável"),
  colaboradores: schema.array().of(schema.string().required().defined()).default([]).label("Colaboradores"),
  categorias: schema.string().required().default("Geral").label("Categorias"),
  endereco: enderecoSchema.required().defined().label("Endereço"),
  autocadastroOrigin: schema.string().optional().label("Origem do Autocadastro"),
  embaixadorReference: schema.string().optional().label("Embaixador"),
  embaixadorPessoaReference: schema.string().optional().label("Embaixador Pessoa"),
  embaixadores: schema
    .array()
    .of(
      schema.object({
        origem: schema.string().required(),
        cadastroReference: schema.string().required(),
        empresaProjetoReference: schema.string().required(),
        projetoReference: schema.string().required(),
        data: schema.date().required()
      })
    )
    .optional()
    .defined()
    .default([])
    .label("Embaixadores"),
  role: schema.mixed<TRole>().required().defined().label("Role")
});

const empresaSchema: ObjectSchema<TEmpresaForm> = empresaSchemaFormFields
  // eslint-disable-next-line unicorn/prefer-spread
  .concat(empresaSchemaDatabaseFields)
  .noUnknown()
  .strict()
  .label("Empresa");

export type EmpresaInputs = Asserts<typeof empresaSchema>;

export default empresaSchema;
