import { Alert, Box, Button, Group, LoadingOverlay, NumberInput, Stack, TextInput } from "@mantine/core";
import { DatePickerInput, DateValue } from "@mantine/dates";
import { IconAlertCircle } from "@tabler/icons-react";
import { useMemo } from "react";
import { extractTextInputPropertiesFromFormSchema } from "@/utils/form";
import useRegulamentoDesafiosForm from "./useRegulamentoDesafiosForm";
import { TRegulamentoDesafiosForm } from "../../firestore";
import regulamentoDesafioFormSchema from "../../schema";

const RegulamentoDesafiosForm = ({
  regulamento,
  onSuccess
}: {
  regulamento: TRegulamentoDesafiosForm;
  onSuccess: () => void;
}) => {
  const schemaFieldsDescriptor = regulamentoDesafioFormSchema.describe();

  const { form, isPending, onSubmit } = useRegulamentoDesafiosForm(regulamento, onSuccess);

  const genericError = form.errors[""];

  /**
   * Handle período date change
   * @param dates - [dataInicial, dataFinal]
   */
  function onDateIniciaFinalChange(dates: [Date, Date]) {
    const [dataInicial, dataFinal] = dates;
    form.setFieldValue("dataInicial", dataInicial);
    form.setFieldValue("dataFinal", dataFinal);
  }
  const periodo: [DateValue, DateValue] = useMemo<[DateValue, DateValue]>(
    () => [form.values.dataInicial, form.values.dataFinal],
    [form.values.dataFinal, form.values.dataInicial]
  );

  const today = new Date();

  return (
    <form onSubmit={onSubmit}>
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />
      </Box>
      <Stack>
        <Group>
          <TextInput
            type="text"
            style={{ flex: 1 }}
            data-autofocus
            {...form.getInputProps("nome")}
            {...extractTextInputPropertiesFromFormSchema(schemaFieldsDescriptor, "nome")}
          />
        </Group>
        <Group>
          <DatePickerInput
            style={{ flex: 1 }}
            type="range"
            minDate={today}
            valueFormat="DD [de] MMMM, YYYY"
            value={periodo}
            onChange={onDateIniciaFinalChange}
            {...extractTextInputPropertiesFromFormSchema(schemaFieldsDescriptor, "dataInicial")}
            label="Período"
          />
          <NumberInput
            min={0}
            {...form.getInputProps("quantidadeMoedasPorColaborador")}
            {...extractTextInputPropertiesFromFormSchema(schemaFieldsDescriptor, "quantidadeMoedasPorColaborador")}
          />
        </Group>
      </Stack>
      <Group justify={genericError ? "space-between" : "flex-end"} mt="md">
        {genericError && (
          <Alert icon={<IconAlertCircle size="1rem" />} color="red">
            {genericError}
          </Alert>
        )}
        <Button type="submit" loading={isPending}>
          Salvar
        </Button>
      </Group>
    </form>
  );
};

export default RegulamentoDesafiosForm;
