// import Can from "@/components/can";
import { Card, Group, Text, Flex, Space, SimpleGrid, ColorSwatch } from "@mantine/core";
import { Timestamp } from "firebase/firestore";
import React from "react";

interface Tatica {
  id: string | undefined;
  descricao: string;
  status: string;
  data: Date | null;
}
// prettier-ignore
const MovimentacoesCard: React.FC<{
  movimentacao: Tatica;

}> = ({ movimentacao }) => {
  const statusMovimentacao = status.find(item => item.value === movimentacao?.status);
  const { data } = verificationDate(movimentacao);

  return (
    <>
      <Card radius="md" bg="rgb(245,248,254)" >
        <Group justify={"space-between"}>
          <Text fw={500} fz="md">Movimentação</Text>
          <Text fw={500} fz="md">Data: {data.toLocaleString("pt-bt")}</Text>
        </Group>
        <Space h={"md"} />
        <SimpleGrid cols={2}>
          <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
            <Text fw={300} fz="xs">
              Status
            </Text>
            <Group align={"center"} justify={"center"}>
              <ColorSwatch color={statusMovimentacao?.color || ""} radius="sm" size={"20"} />
              <Text fw={500} fz="md">
                {statusMovimentacao?.label}
              </Text>
            </Group>
          </Flex>
        </SimpleGrid>
        <Space h={"md"} />
        <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
          <Text fw={300} fz="xs">
            Movimentação
          </Text>
          <Text fw={500} fz="md">
            {movimentacao.descricao}
          </Text>
        </Flex>
      </Card>
      <Space h={"md"} />
    </>
  );
};

export default MovimentacoesCard;

const status = [
  { value: "1", label: "Em elaboração", color: "#ff810d" },
  { value: "2", label: "Em estudo", color: "#ffc726 " },
  { value: "3", label: "Em andamento", color: "#6288fb " },
  { value: "4", label: "Concluído", color: "#68c669 " }
];

const SEGUNDOS_EM_MILISEGUNDOS = 1000;

// prettier-ignore
const verificationDate = (item: Tatica) => {
  let data: number | undefined;

  if (item.data instanceof Timestamp) {
    data = item.data.seconds;
  } else if (item.data && typeof item.data === 'object' && 'seconds' in item.data) {
    data = (item.data as { seconds: number }).seconds;
  }

  return { data: data ? new Date(data * SEGUNDOS_EM_MILISEGUNDOS) : "" }
}
