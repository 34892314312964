import { Card, Group, Space, Text } from "@mantine/core";
import useGetRespostasMedia from "@/modules/diagnostico/components/ranking-list/hooks/useGetRespostasMedia";
import useGetQuestionarios from "@/modules/questionario-de-inovacao/components/projeto-item/useGetQuestionarios";
import useGetConvitesSelects from "./useGetConvitesSelect";
import useGetEmpresaById from "./useGetEmpresas";
import RankingItem from "../ranking-item";
// prettier-ignore
const RankingList = ({ projeto }: { projeto: TProjeto }) => {
  const { data } = useGetConvitesSelects();
  const idEmpresaProjeto = projeto?.refPath?.split('/')[1] || "";
  const { data: empresa } = useGetEmpresaById(idEmpresaProjeto);
  const newEmpresa = formatEmpresa(empresa, projeto);
  const { data: RespostasMedia } = useGetRespostasMedia({ ids: [...data || [], newEmpresa]?.map(item => item.idEmpresaColaboradorConvidado) })
  const { data: questionarios } = useGetQuestionarios({ id: projeto?.id });

  const formattedData = [...data || [], newEmpresa]?.map(item => {
    const formatQuestionario = questionarios?.find(row => row.idProjeto === item.idProjeto);
    const respostas = RespostasMedia?.filter(row => row.idEmpresa === item.idEmpresaColaboradorConvidado && row.idQuestionario === formatQuestionario?.id) || [];
    const media = calcularMedia(respostas as TResposta[]);

    return {
      ...item,
      media
    }
  }) || [];

  const sortArray = formattedData?.sort(function (a, b) {
    return b.media - a.media;
  });

  return (
    <Card withBorder shadow="md">
      <Space h="md" />
      <Group justify={"space-between"} align="center">
        <Text size="xl">Ranking de empresas</Text>
      </Group>
      <Space h="md" />
      {sortArray && sortArray.map((item, index) => <RankingItem key={item.id} empresa={item} projeto={projeto} posicao={index} />)}
    </Card>
  );
};

export default RankingList;

interface RespostaItem {
  valor: number;
}

interface Pergunta {
  resposta: RespostaItem | RespostaItem[] | number;
}

interface TResposta {
  perguntas: Pergunta[];
}

/* eslint-disable sonarjs/cognitive-complexity */
const calcularMedia = (respostas: TResposta[]) => {
  if (respostas.length === 0) return 0;

  let total = 0;
  let count = 0;

  for (const resposta of respostas) {
    for (const pergunta of resposta.perguntas) {
      const respostaPergunta = pergunta.resposta;

      if (respostaPergunta !== undefined && respostaPergunta !== null) {
        if (Array.isArray(respostaPergunta)) {
          for (const resp of respostaPergunta) {
            if (typeof resp === "object" && resp.valor !== undefined) {
              total += resp.valor;
              count++;
            }
          }
        } else if (typeof respostaPergunta === "object" && respostaPergunta.valor !== undefined) {
          total += respostaPergunta.valor;
          count++;
        } else if (typeof respostaPergunta === "number") {
          total += respostaPergunta;
          count++;
        }
      }
    }
  }

  return count > 0 ? total / count : 0;
};
// prettier-ignore
const formatEmpresa = (empresa: TEmpresa | null | undefined, projeto: TProjeto | null | undefined) => {
  return {
    convite: false,
    createdAt: new Date(),
    createdBy: "",
    id: empresa?.id,
    idColaboradorConvidado: "",
    idEmpresa: empresa?.id,
    idEmpresaColaboradorConvidado: empresa?.id,
    idProjeto: projeto?.id,
    idQuestionario: ""
  } as TConvite;
}
