import { useMutation } from "@tanstack/react-query";
import { FieldValue, FirestoreError } from "firebase/firestore";
import { TConviteForm, updateConvite } from "@/modules/empresas/firestore";

type TUpdateResponse = Awaited<ReturnType<typeof updateConvite>>;
/**
 * Finalizar resposta de inovação mutation hook
 * @param item - resposta de inovação
 * @returns Finalizar resposta de inovação mutation
 */
// prettier-ignore
export function useConvite(
  item: TConviteForm & Partial<Pick<TConvite, "id">>,
) {

  return useMutation<TConvite | TUpdateResponse , FirestoreError>({
    mutationFn() {
      if (!item.id) {
        throw new Error("Empresa não selecionada");
      }
      return  updateConvite(item.id, {
        ...item,
        deletedAt: item.deletedAt instanceof FieldValue ? null : (item.deletedAt || null)
      })
    },
  });
}
