import { Box, Image, MantineStyleProps, SimpleGrid } from "@mantine/core";
import { PropsWithChildren } from "react";
import Logo from "@/assets/login.svg";

type CleanContainerProperties = {
  w?: MantineStyleProps["w"];
};

const CleanContainer = ({ children, w = "50vw" }: PropsWithChildren<CleanContainerProperties>) => {
  return (
    <SimpleGrid cols={{ base: 1, sm: 2 }} spacing={{ base: "xs", sm: "xl" }} verticalSpacing={{ base: 0, sm: "sm" }}>
      <div>
        <Box mih="100vh" w={w} bg="#111111" visibleFrom="sm">
          <Image
            src={Logo}
            alt="Acelerador de Inovação | ADI"
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
            p="md"
          />
        </Box>
        <Box h="90px" m="sm" bg="indigo.6" hiddenFrom="sm" style={{ borderRadius: "0px" }}>
          <Image w={360} src={Logo} alt="Acelerador de Inovação | ADI" p="md" />
        </Box>
      </div>
      <div>{children}</div>
    </SimpleGrid>
  );
};

export default CleanContainer;
