import { subject } from "@casl/ability";
import {
  FirestoreDataConverter,
  Timestamp,
  collection,
  getDocs,
  orderBy,
  query,
  addDoc,
  serverTimestamp,
  getDoc,
  doc,
  updateDoc,
  where
} from "firebase/firestore";
import { getCurrentUser } from "@/services/auth";
import { getEmpresaDocumentReference } from "../empresas/firestore";

export const QUERY_KEY = "tipoDeInovacao";

export type TTTipoDeInovacao = Pick<TAuditForm, "createdBy" | "updatedBy" | "createdAt" | "updatedAt"> & {
  startedAt: ReturnType<typeof serverTimestamp>;
  pausedAt: ReturnType<typeof serverTimestamp> | null;
  endedAt: ReturnType<typeof serverTimestamp> | null;
};

type TTipoDeInovacaoDocument = TAuditDocument &
  Omit<TTipoDeInovacao, keyof TAudit | keyof TAuditDocument | "startedAt" | "pausedAt" | "endedAt"> & {
    nome: string;
    descricao: string;
    createdAt: Timestamp;
    refPath: string;
    deletedAt: Date | null;
    startedAt: Timestamp;
    pausedAt: Timestamp | null;
    endedAt: Timestamp | null;
  };

export type TTipoInovacaoFormFields = Omit<
  TTipoDeInovacao,
  "id" | "createdAt" | "updatedAt" | "createdBy" | "updatedBy" | "startedAt" | "pausedAt" | "endedAt" | "deletedAt"
> &
  Partial<Pick<TTipoDeInovacao, "id">>;

type TTTipoInovacaoDatabaseFields = Pick<TAuditForm, "createdBy" | "updatedBy" | "createdAt" | "updatedAt"> & {
  startedAt: ReturnType<typeof serverTimestamp>;
  pausedAt: ReturnType<typeof serverTimestamp> | null;
  endedAt: ReturnType<typeof serverTimestamp> | null;
};

type TCategoriaDeInovacaoDocument = TAuditDocument &
  Omit<TCategoriaDeInovacao, keyof TAudit | keyof TAuditDocument | "startedAt" | "pausedAt" | "endedAt"> & {
    nome: string;
    descricao: string;
    createdAt: Timestamp;
    refPath: string;
    deletedAt: Date | null;
    startedAt: Timestamp;
    pausedAt: Timestamp | null;
    endedAt: Timestamp | null;
  };

export type TTipoInovacaoForm = TTipoInovacaoFormFields & TTTipoInovacaoDatabaseFields;

const tipoInovacaoConverter: FirestoreDataConverter<TTipoDeInovacao> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const {
      nome,
      descricao,
      updatedAt,
      deletedAt,
      createdAt,
      endedAt,
      taticas,
      nomeCategoria,
      categoria,
      startedAt,
      pausedAt,
      refPath,
      ...document
    } = snap.data() as TTipoDeInovacaoDocument;

    const data: TTipoDeInovacao = subject("TTipoDeInovacao", {
      ...document,
      id: snap.id,
      nome,
      descricao,
      nomeCategoria,
      updatedAt,
      categoria,
      deletedAt,
      taticas,
      refPath,
      createdAt: createdAt.toDate(),
      startedAt: startedAt.toDate(),
      endedAt: endedAt ? endedAt.toDate() : null,
      pausedAt: pausedAt ? pausedAt.toDate() : null
    });

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }
    return subject("TTipoDeInovacao", data);
  }
};

const categoriaInovacaoConverter: FirestoreDataConverter<TCategoriaDeInovacao> = {
  toFirestore(data) {
    delete data.id;
    return data;
  },
  fromFirestore(snap) {
    const { nome, descricao, updatedAt, deletedAt, createdAt, endedAt, startedAt, pausedAt, refPath, ...document } =
      snap.data() as TCategoriaDeInovacaoDocument;

    const data: TCategoriaDeInovacao = subject("TCategoriaDeInovacao", {
      ...document,
      id: snap.id,
      nome,
      descricao,
      deletedAt,
      refPath,
      updatedAt,
      createdAt: createdAt.toDate(),
      startedAt: startedAt.toDate(),
      endedAt: endedAt ? endedAt.toDate() : null,
      pausedAt: pausedAt ? pausedAt.toDate() : null
    });

    return data;
  }
};

/**
 * Get all categorias de inovação
 * @param empresaId - Empresa id
 * @returns - categorias de inovação collection reference
 */
function getCategoriasInovacaoCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, "categoriaDeInovacao");
}

/**
 * Get categorias de inovação query for a empresa
 * @param empresaId - Empresa id to get comitês
 * @returns - categorias de inovação query
 */
function generateCategoriaDeInovacaoQuery(empresaId: TEmpresa["id"]) {
  const categoriasInovacaoCollectionReference =
    getCategoriasInovacaoCollectionReference(empresaId).withConverter(categoriaInovacaoConverter);
  return query(categoriasInovacaoCollectionReference, orderBy("startedAt", "desc"), where("deletedAt", "==", null));
}

/**
 * Get categorias de inovação for a empresa
 * @param empresaId - Empresa id to get comitês
 * @returns - categorias de inovação document snapshot
 */
export function getCategoriasDeInovacao(empresaId: TEmpresa["id"]) {
  const q = generateCategoriaDeInovacaoQuery(empresaId);
  return getDocs(q);
}

type TTipoDeJogadaDocument = TAuditDocument &
  Omit<TTipoDeJogada, keyof TAudit | keyof TAuditDocument | "startedAt" | "pausedAt" | "endedAt"> & {
    nome: string;
    descricao: string;
    createdAt: Timestamp;
    refPath: string;
    deletedAt: Date | null;
    startedAt: Timestamp;
    pausedAt: Timestamp | null;
    endedAt: Timestamp | null;
  };

const tipoDeJogadaConverter: FirestoreDataConverter<TTipoDeJogada> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const { nome, descricao, updatedAt, deletedAt, createdAt, endedAt, startedAt, pausedAt, refPath, ...document } =
      snap.data() as TTipoDeJogadaDocument;

    const data: TTipoDeJogada = subject("TTipoDeJogada", {
      ...document,
      id: snap.id,
      nome,
      descricao,
      deletedAt,
      refPath,
      createdAt: createdAt.toDate(),
      startedAt: startedAt.toDate(),
      endedAt: endedAt ? endedAt.toDate() : null,
      pausedAt: pausedAt ? pausedAt.toDate() : null
    });

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }
    return subject("TTipoDeJogada", data);
  }
};

/**
 * Get all categorias de inovação
 * @param empresaId - Empresa id
 * @returns - categorias de inovação collection reference
 */
function getTipoDeJogadaCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, "tipoDeJogada");
}

/**
 * Get categorias de inovação query for a empresa
 * @param empresaId - Empresa id to get comitês
 * @returns - categorias de inovação query
 */
function generateTipoDeJogadaQuery(empresaId: TEmpresa["id"]) {
  const tipoDeJogadaCollectionReference =
    getTipoDeJogadaCollectionReference(empresaId).withConverter(tipoDeJogadaConverter);
  return query(tipoDeJogadaCollectionReference, orderBy("startedAt", "desc"), where("deletedAt", "==", null));
}

/**
 * Get categorias de inovação for a empresa
 * @param empresaId - Empresa id to get comitês
 * @returns - categorias de inovação document snapshot
 */
export function getTipoDeJogada(empresaId: TEmpresa["id"]) {
  const q = generateTipoDeJogadaQuery(empresaId);
  return getDocs(q);
}

/**
 * Get all tipos de inovação
 * @param empresaId - Empresa id
 * @returns - tipos de inovação collection reference
 */
function getTiposInovacaoCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, "tipoDeInovacao");
}

/**
 * Get tipo de inovação query for a empresa
 * @param empresaId - Empresa id to get comitês
 * @returns - tipos de inovação query
 */
function generateTipoDeInovacaoQuery(empresaId: TEmpresa["id"]) {
  const tiposInovacaoCollectionReference =
    getTiposInovacaoCollectionReference(empresaId).withConverter(tipoInovacaoConverter);
  return query(tiposInovacaoCollectionReference, orderBy("startedAt", "desc"), where("deletedAt", "==", null));
}

/**
 * Get tipos de inovação for a empresa
 * @param empresaId - Empresa id to get comitês
 * @returns - tipos de inovação document snapshot
 */
export function getTiposDeInovacao(empresaId: TEmpresa["id"]) {
  const q = generateTipoDeInovacaoQuery(empresaId);
  return getDocs(q);
}

/**
 * Start a new tipo de inovação for a empresa
 * @param empresaId - Empresa id to start a new tipo de inovação
 * @param values - tipo de inovação form fields
 * @returns - tipo de inovação document reference
 */
export function addTipoDeInovacao(empresaId: TEmpresa["id"], values: TTipoInovacaoFormFields) {
  const tiposInovacaoCollectionReference = getTiposInovacaoCollectionReference(empresaId);
  return addDoc(tiposInovacaoCollectionReference, {
    ...values,
    deletedAt: null
  });
}
/**
 * Get tipo de inovação reference
 * @param empresaId - Empresa id to start a new tipo de inovação
 * @param tipoDeInovacaoId - tipo de inovação form fields
 * @returns - tipo de inovação document reference
 */
function getTipoInovacaoDocumentReference(empresaId: TEmpresa["id"], tipoDeInovacaoId: TTipoDeInovacao["id"]) {
  const tipoInovacaoCollectionReference = getTiposInovacaoCollectionReference(empresaId);
  return doc(tipoInovacaoCollectionReference, tipoDeInovacaoId);
}

/**
 * Get tipo de inovação item
 * @param empresaId - Empresa id to start a new tipo de inovação
 * @param tipoDeInovacaoId - tipo de inovação form fields
 * @returns - tipo de inovação document item
 */
export function getTipoDeInovacaoItem(empresaId: TEmpresa["id"], tipoDeInovacaoId: TTipoDeInovacao["id"]) {
  const tipoInovacaoDocumentReference = getTipoInovacaoDocumentReference(empresaId, tipoDeInovacaoId);
  return getDoc(tipoInovacaoDocumentReference.withConverter(tipoInovacaoConverter));
}

/**
 * delete tipo de inovação
 * @param empresaId - Empresa id to start a new tipo de inovação
 * @param tipoDeInovacaoId - tipo de inovação form fields
 * @returns - tipo de inovação delete
 */
export function deleteTipoDeInovacao(empresaId: TEmpresa["id"], tipoDeInovacaoId: TEmpresa["id"]) {
  const tipoDocumentReference = getTipoInovacaoDocumentReference(empresaId, tipoDeInovacaoId);
  return updateDoc(tipoDocumentReference, {
    deletedAt: serverTimestamp()
  });
}

/**
 * updated tipo de inovação
 * @param empresaId - Empresa id to start a new tipo de inovação
 * @param tipoDeInovacaoId - tipo de inovação form fields
 * @param values - values to update
 * @returns - tipo de inovação delete
 */
export function updatedTipoDeInovacao(
  empresaId: TEmpresa["id"],
  tipoDeInovacaoId: TProjeto["id"],
  values: TTipoInovacaoFormFields
) {
  const currentUserId = getCurrentUser()?.uid;
  const tipoDocumentReference = getTipoInovacaoDocumentReference(empresaId, tipoDeInovacaoId);

  return updateDoc(tipoDocumentReference, {
    ...values,
    updatedAt: serverTimestamp(),
    updatedBy: currentUserId
  });
}
