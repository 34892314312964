import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getRespostaColaboradorInQuestionario, QUERY_KEY } from "../firestore";

type TGetRespostaResponse = Awaited<ReturnType<typeof getRespostaColaboradorInQuestionario>>;

/**
 * Get resposta from url params
 * @param rootEmpresaId - Id da empresa que criou o questionário
 * @param questionarioId Id do questionário respondido
 * @param colaboradorId Id do respondente
 * @returns - resposta or null if not found
 */
function useGetRespostaColaborador(
  rootEmpresaId: TEmpresa["id"],
  questionarioId: TQuestionario["id"],
  colaboradorId: TColaborador["id"]
) {
  return useQuery<TGetRespostaResponse, FirestoreError, TResposta | null>({
    queryKey: [QUERY_KEY, colaboradorId, rootEmpresaId, questionarioId],
    queryFn() {
      return getRespostaColaboradorInQuestionario(rootEmpresaId, colaboradorId, questionarioId);
    },
    select(dataResponse) {
      const data = dataResponse.docs.map((document_) => document_.data());
      return data[0] ?? null;
    }
  });
}

export default useGetRespostaColaborador;
