import { Button, Flex, Group, Image, Modal, Space, Text, TextInput, rem } from "@mantine/core";
import { FileWithPath, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { IconUpload } from "@tabler/icons-react";
import { Timestamp } from "firebase/firestore";
import { getDownloadURL } from "firebase/storage";
import { useState } from "react";
import FileSelectorDropZoneUser from "@/components/file-selector-dropzone/FileSelectorDropZoneUser";
import useUserStore from "@/modules/users/store";
import { useUpdateLicenciados } from "./useUpdateLicenciados";
import { uploadAnexosFilesToStorage } from "../../storage";

interface NewValues {
  nome: string;
  email: string;
  logo?: TFileStored[];
}

const acceptedImageTypes = [...IMAGE_MIME_TYPE];
const MAX_RANDOM = 1_000_000;
const ICON_SIZE = 52;

// prettier-ignore
const LicenciadosFormModal = ({ openModal, changeModal, onSuccess }: { openModal: boolean, changeModal: () => void, onSuccess: () => void }) => {
  const { mutate } = useUpdateLicenciados();
  const colaborador = useUserStore((state) => state.colaborador);
  const empresa = useUserStore((state) => state.activeEmpresa);
  const [values, setValues] = useState<NewValues>(valuesOld)

  // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
  const changeMutate = () => {
    mutate({
      ...values,
      updatedAt: Timestamp.now(),
      createdAt: Timestamp.now(),
      deletedAt: null,
      createdBy: colaborador?.id || "",
      empresaId: empresa?.id || "",
    });
    onSuccess();
  };

  /**
   * Handle anexos selected
   * @param files - The selected files
   */
  async function handleAnexosSelected(files: FileWithPath[]) {
    // const file = files[0] as FileWithPath;
    const images = [] as TFileStored[]
    for (const file of files) {

      const image = await uploadAnexosFilesToStorage(empresa?.id || "", `${Math.floor(Math.random() * MAX_RANDOM) + 1}`, file).then(async (uploadTaskSnapshot) => {
        const url = await getDownloadURL(uploadTaskSnapshot.ref);
        return {
          path: url,
          name: "",
          size: 0,
          type: ""
        };
      })

      images.push(image)
    }
    setValues(values => ({ ...values, "logo": images }));
  }

  return <>
    <Modal opened={openModal} onClose={changeModal} zIndex={9999}>
      <Text fw="bold" mb="md">
        Adicionar licenciado
      </Text>
      <TextInput
        label="Nome"
        onChange={onChange("nome", setValues)}
        value={values?.nome}
        w={"100%"}
      />
      <TextInput
        label="Email"
        onChange={onChange("email", setValues)}
        value={values?.email}
        w={"100%"}
      />
      <Space h="xs" />
      <Flex justify="center" align="center">
        <FileSelectorDropZoneUser w="200px"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onDrop={handleAnexosSelected}
          accept={acceptedImageTypes}
          multiple={true}
          maxFiles={2}
        >
          <Flex gap={0} justify="center" align="center" direction="column" wrap="wrap">
            {values && values?.logo && values?.logo?.length > 0 ? values?.logo?.map(item => <Image
              radius="md"
              key={item.path}
              src={item?.path as string}
            />) : <IconUpload
              style={{ width: rem(ICON_SIZE), height: rem(ICON_SIZE), color: "black" }}
              stroke={1.5}
            />}
          </Flex>
        </FileSelectorDropZoneUser>
      </Flex>
      <Space h="xl" />
      <Text fw="lighter" size="xs" mb="md">
        * Escolha até duas logos para o licenciado.<br></br>
        * Para incluir mais de uma imagem, selecione ambas aos mesmo tempo.
      </Text>

      <Group justify="flex-end">
        <Button variant="filled" onClick={changeMutate}>
          Salvar
        </Button>
      </Group>
    </Modal>
  </>
};

export default LicenciadosFormModal;

// prettier-ignore
const valuesOld = {
  nome: "",
  email: "",
}

// prettier-ignore
const onChange = (field: string, setValues: React.Dispatch<React.SetStateAction<NewValues>>) => {
  return (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues(values => ({ ...values, [field]: event.target.value }));
  };
};
