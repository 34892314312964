import { Flex, Alert } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { useEffect } from "react";
import { useRouteError, isRouteErrorResponse } from "react-router-dom";
import { captureException } from "@/services/log";

const HTTP_NOT_FOUND = 404;

/**
 * Error Login Page
 * @returns - Error Login Page
 */
function ErrorPage() {
  const error = useRouteError() as Error;

  const isNotFoundResponse = isRouteErrorResponse(error) && error.status === HTTP_NOT_FOUND;

  let errorMessage = "A PAGINA PROCURADA NÃO EXISTE!";
  if (isRouteErrorResponse(error)) {
    errorMessage = error?.message ?? errorMessage;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  }

  useEffect(() => {
    captureException(error);
  }, [error]);

  return (
    <Flex mih="90vh" gap="xs" justify="center" align="center" direction="column" wrap="nowrap">
      <Alert
        icon={<IconAlertCircle size={16} />}
        title={isNotFoundResponse ? "NÃO ENCONTRADO!" : "Ops"}
        color={isNotFoundResponse ? "yellow" : "red"}
        variant="outline"
      >
        {errorMessage}
      </Alert>
    </Flex>
  );
}

export default ErrorPage;
