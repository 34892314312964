/* eslint-disable react-perf/jsx-no-new-array-as-prop */
/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import { Card, Group, Input, Stack, Tabs, Text } from "@mantine/core";
import { IconBulb, IconBulbFilled, IconPlus, IconSearch, IconX } from "@tabler/icons-react";
import { and, doc, or, where } from "firebase/firestore";
import { RefObject, useEffect, useRef, useState } from "react";
import { firestore } from "@/base";
import AddItemButton from "@/components/add-item-button";
import { TModalProperties } from "@/components/add-item-button/AddItemButton";
import { THandler } from "@/components/table/Table";
import { useGetAuthorizations } from "@/hooks/useGetAuthorizations";
import ColaboradorCard from "@/modules/colaboradores/components/colaborador-card";
import IdeiasCadastradasDesdeCard from "@/modules/ideias/components/ideias-cadastradas-desde-card";
import IdeiasTable from "@/modules/ideias/components/ideias-table";
import tableColumns, { patrocinioColumn } from "@/modules/ideias/components/ideias-table/Columns";
import ideiaSchema from "@/modules/ideias/schema/ideia-schema";
import useUserStore from "@/modules/users/store";

const DAYS_AGO = 30;
const date30DaysAgo = new Date();
date30DaysAgo.setDate(date30DaysAgo.getDate() - DAYS_AGO);

const modalProperties: TModalProperties = {
  modal: "ideia-form-modal"
};

/**
 * Set the global filter to the table to only show minhas ideias and non deleted items
 * @param tableReference - Reference to the table handlers object
 */
// prettier-ignore
function useNonDeletedGlobalFilters(tableReference: RefObject<THandler<TIdeia>>) {
  const colaborador = useUserStore((state) => state.colaborador);

  useEffect(() => {
    const tableHandlers = tableReference.current;
    if (tableHandlers && colaborador && tableHandlers.table.getState().globalFilter === null) {
      tableHandlers.table.setGlobalFilter(
        and(where("deletedAt", "==", null), where("publishedAt", "!=", null), where("privado", "==", false))
      );
    }
  }, [colaborador, tableReference]);
}

/**
 * Set the global filter to the table to only show minhas ideias and non deleted items
 * @param tableReference - Reference to the table handlers object
 */
function useMinhasNonDeletedIdeiasGlobalFilters(tableReference: RefObject<THandler<TIdeia>>) {
  const colaborador = useUserStore((state) => state.colaborador);

  useEffect(() => {
    const tableHandlers = tableReference.current;
    if (tableHandlers && colaborador && tableHandlers.table.getState().globalFilter === null) {
      tableHandlers.table.setGlobalFilter(
        and(
          where("deletedAt", "==", null),
          or(
            where("participantesReferences", "array-contains", doc(firestore, colaborador.refPath)),
            where("createdBy", "==", colaborador.id)
          )
        )
      );
    }
  }, [colaborador, tableReference]);
}

/**
 * Set the global filter to the table to only show minhas ideias and non deleted items
 * @param tableReference - Reference to the table handlers object
 */
function usePatrocinadasNonDeletedIdeiasGlobalFilters(tableReference: RefObject<THandler<TIdeia>>) {
  const { colaborador, activeEmpresaId } = useUserStore();

  useEffect(() => {
    const tableHandlers = tableReference.current;

    if (!colaborador || !activeEmpresaId || !tableHandlers) {
      return;
    }

    tableHandlers.table.setGlobalFilter(
      and(where("deletedAt", "==", null), where("patrocinadoresReferences", "array-contains", colaborador.id))
    );
  }, [colaborador, tableReference]);
}

// prettier-ignore
const HomeDesafioPage = () => {
  const SEARCH_TIMEOUT = 500
  const [searchValue, setSearchValue] = useState("");
  const [, setSearchTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const { activeEmpresa } = useUserStore();
  const { create } = useGetAuthorizations("3")

  const tableHandlersIdeiasReference = useRef<THandler<TIdeia> & { setData: (data: TIdeia[]) => void }>(null);
  useNonDeletedGlobalFilters(tableHandlersIdeiasReference);

  const tableHandlersMinhasIdeiasReference = useRef<THandler<TIdeia>>(null);
  useMinhasNonDeletedIdeiasGlobalFilters(tableHandlersMinhasIdeiasReference);

  const tableHandlersMeusPatrociniosReference = useRef<THandler<TIdeia>>(null);
  usePatrocinadasNonDeletedIdeiasGlobalFilters(tableHandlersMeusPatrociniosReference);

  if (activeEmpresa?.status === "INATIVO") {
    return <Group justify={"center"} align="center">
      <IconX size="1rem" color={"red"} />
      <Text c={"red"} fw={"bold"}>Empresa inativa</Text>
    </Group>;
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTimeoutId((state) => {
      if (state) {
        clearTimeout(state);
      }

      return setTimeout(() => {
        setSearchValue(event.target.value)
      }, SEARCH_TIMEOUT);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const sponsorshipsTableColumn = [...tableColumns.slice(0, -1), patrocinioColumn, tableColumns.at(-1)!]

  return (
    <Stack>
      <Group justify="space-around" align="stretch">
        <ColaboradorCard tipo="ideias" />
        <IdeiasCadastradasDesdeCard date={date30DaysAgo} />
      </Group>
      <Group justify="space-between" >
        <Input
          onChange={handleSearchChange}
          placeholder="Pesquisar ideias"
          size="sm"
          radius="lg"
          leftSection={<IconSearch size="1rem" />}
        />

        {
          create && (
            <AddItemButton
              size="sm"
              leftSection={<IconPlus size="1rem" />}
              modalProperties={modalProperties}
              schema={ideiaSchema}
            >
              Adicionar Ideia
            </AddItemButton>
          )
        }
      </Group>
      <Card withBorder p={0}>
        <Tabs defaultValue="todas-as-ideias">
          <Tabs.List>
            <Tabs.Tab value="todas-as-ideias" leftSection={<IconBulbFilled />}>
              Todas as Ideias
            </Tabs.Tab>
            <Tabs.Tab value="minhas-ideias" leftSection={<IconBulb />}>
              Minhas Ideias
            </Tabs.Tab>
            <Tabs.Tab value="meus-patrocinios" leftSection={<IconBulbFilled style={{ color: "#999" }} />}>
              Meus Patrocínios
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="todas-as-ideias">
            <IdeiasTable filterValue={searchValue} ref={tableHandlersIdeiasReference} />
          </Tabs.Panel>
          <Tabs.Panel value="minhas-ideias">
            <IdeiasTable filterValue={searchValue} ref={tableHandlersMinhasIdeiasReference} />
          </Tabs.Panel>
          <Tabs.Panel value="meus-patrocinios">
            <IdeiasTable columns={sponsorshipsTableColumn} filterValue={searchValue} ref={tableHandlersMeusPatrociniosReference} />
          </Tabs.Panel>
        </Tabs>
      </Card>
    </Stack>
  );
};

HomeDesafioPage.displayName = "HomeDesafioPage";

export default HomeDesafioPage;
