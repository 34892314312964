import { Group, Button, Tooltip } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useEffect, useState } from "react";
import useQuestionarioStepper from "../../hooks/useQuestionarioStepper";

type Properties = {
  controller: ReturnType<typeof useQuestionarioStepper>;
};

/**
 * Componente de ações do questionário
 * @param root0 Props
 * @param root0.controller Controller do questionário
 * @returns JSX.Element
 */
function QuestionarioActions({ controller }: Properties) {
  const isSmMarches = useMediaQuery("(max-width: 48em)");
  const {
    isFinalStep,
    isFirstStep,
    canPause,
    canFinish,
    handlePrevious,
    handleNext,
    handlePause,
    handleFinish,
    isQuestionarioAlreadyFinished
  } = controller;

  const [event, setEvent] = useState<KeyboardEvent | null>(null);

  useEffect(() => {
    window.addEventListener("keydown", setEvent);
  }, []);

  useEffect(() => {
    if (event) {
      if (event.key === "ArrowRight") {
        event.preventDefault();
        handleNext();
      }

      if (event.key === "ArrowLeft") {
        event.preventDefault();
        handlePrevious();
      }

      if (event.key === "Escape") {
        event.preventDefault();
        handlePause();
      }

      if (event.key === "Enter") {
        event.preventDefault();
        handleFinish();
      }
      setEvent(null);
    }
  }, [event]);

  return (
    <Group>
      {!isFirstStep && (
        <Button onClick={handlePrevious} variant="filed">
          Voltar
        </Button>
      )}

      {canPause && !isFirstStep && !isFinalStep && (
        <Button onClick={handlePause} variant="outline">
          Pausar
        </Button>
      )}

      {!isFinalStep && (
        <Button onClick={handleNext} variant="filed">
          Proximo
        </Button>
      )}

      {isFinalStep &&
        !isQuestionarioAlreadyFinished &&
        (canFinish ? (
          <Button disabled={!canFinish} onClick={handleFinish} variant="filed">
            Finalizar
          </Button>
        ) : (
          <Tooltip label="Uma ou mais perguntas obrigatórias não foram respondidas!">
            <Button disabled={!canFinish && !isSmMarches} onClick={handleFinish} variant="filed">
              Finalizar
            </Button>
          </Tooltip>
        ))}
    </Group>
  );
}

export default QuestionarioActions;
