import { ObjectSchema } from "yup";
import schema from "@/schema";

const MIN_NAME_LENGTH = 3;

const responsavelSchema: ObjectSchema<TEmpresa["responsavel"]> = schema.object({
  nome: schema.string().trim().min(MIN_NAME_LENGTH).required().defined().default("").label("Nome"),
  email: schema.string().trim().email().required().defined().default("").label("E-mail"),
  telefone: schema.string().trim().required().defined().default("").label("Telefone")
});

export default responsavelSchema;
