import { FocusTrap, Group, Space, Checkbox, Card, Text, ColorSwatch } from "@mantine/core";

interface Fase {
  id: string;
  titulo: string;
  cor: string;
  checked?: boolean;
  // status: Status[];
}
// prettier-ignore
const FaseForm = ({ fases, setFases, checkeds }: { fases: Fase[]; setFases: React.Dispatch<React.SetStateAction<Fase[]>>, checkeds: Fase[] }) => {
  // prettier-ignore
  /**
   * Handle select status
   * @param fase - The fase function
   * @returns - function
   */
  function selectStatus(fase: Fase) {
    return () => {
      setFases((fases: Fase[]) =>
        fases.some(item => item.id === fase.id)
          ? fases.filter(item => item.id !== fase.id)
          : [...fases, { ...fase, checked: true }]
      );
    };
  }

  return (
    <FocusTrap active={true}>
      {ordenarArray(fases)?.map((fase: Fase) => (
        <>
          <Card withBorder radius="md">
            <Group justify={"space-between"}>
              <Group align={"center"}>
                <ColorSwatch color={fase.cor} />
                <Text>{fase.titulo}</Text>
              </Group>
              {fase.checked || fase.id === "1" || fase.id === "7" ? <></> : <Checkbox onChange={selectStatus(fase)} checked={checkeds.some(item => item.id === fase.id)} />}
            </Group>
          </Card>
          <Space h="md" />
        </>
      ))}
    </FocusTrap>
  );
};

export default FaseForm;

/**
 * Ordena o array para que o item com o título "Inscrição" seja o primeiro
 * e o item com o título "Vencedores" seja o último. Os demais itens
 * mantêm a ordem relativa entre si.
 * @param array - O array de objetos a ser ordenado.
 * @returns - O array ordenado.
 */
function ordenarArray(array: Fase[]) {
  return array.sort((a, b) => {
    if (a.titulo === "Inscrição") return -1;
    if (b.titulo === "Inscrição") return 1;

    if (a.titulo === "Vencedores") return 1;
    if (b.titulo === "Vencedores") return -1;

    return 0;
  });
}
