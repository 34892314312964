import { Group, Anchor } from "@mantine/core";
import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import BreadcrumbsNavigation from "@/components/breadcrumbs-navigation";
import useQueryGroupConvite from "@/modules/responder-questionario/hooks/useQueryGroupConvite";
import useUserStore from "@/modules/users/store";
import { QuestionarioContent } from "./QuestionarioContent";

const Header = () => {
  return (
    <BreadcrumbsNavigation notHome={true}>
      <Anchor component={NavLink} to="/questionarioDeInovacao">
        Questionário
      </Anchor>
    </BreadcrumbsNavigation>
  );
};

type Properties = {
  questionario: TQuestionario | undefined;
  licenciado: TLicenciado | undefined;
  empresaId: TEmpresa["id"];
  colaboradorId: TColaborador["id"];
};

const QuestionarioV2 = ({ questionario, licenciado, empresaId, colaboradorId }: Properties) => {
  const { data: gruposFound } = useQueryGroupConvite({ id: questionario?.id, activeEmpresaId: empresaId });
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId) ?? "";

  const grupos = useMemo(
    () => gruposFound?.sort((a, b) => a.ordemQuestionario - b.ordemQuestionario) ?? [],
    [gruposFound]
  );

  if (!questionario || !grupos) {
    return (
      <>
        <Header />
        <Group align="center" justify="center">
          Questionário não encontrado
        </Group>
      </>
    );
  }

  return (
    <>
      <Header />
      <Group>
        <QuestionarioContent
          grupos={grupos}
          questionario={questionario}
          licenciado={licenciado}
          userId={colaboradorId}
          empresaId={empresaId}
          empresaRespondenteId={activeEmpresaId}
        />
      </Group>
    </>
  );
};

export default QuestionarioV2;
