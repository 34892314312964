import { Center, Divider, Group, Text } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import AddItemButton from "@/components/add-item-button";
import { TModalProperties } from "@/components/add-item-button/AddItemButton";
import NoData from "@/components/no-data";
// prettier-ignore
import { getCampanhasRegulamentoIdeias, TCampanhaRegulamentoIdeiasForm } from "@/modules/campanha-regulamento-ideias/firestore";
import campanhaRegulamentoIdeiasSchema from "@/modules/campanha-regulamento-ideias/schema";
import useUserStore from "@/modules/users/store";
import CampanhaCard from "./CampanhaCard";
import { useRegulamentoIdeiasFormContext } from "./regulamento-ideias-form-context";
import { useAddCampanha } from "./useAddCampanha";
import { TRegulamentoIdeiasForm } from "../../firestore";

const modalProperties: TModalProperties = {
  modal: "campanha-regulamento-ideias-form-modal"
};
// prettier-ignore
const RegulamentoIdeiasCampanhaInputs = ({ regulamento }: { regulamento: TRegulamentoIdeiasForm }) => {
  const form = useRegulamentoIdeiasFormContext();
  const { mutate } = useAddCampanha(regulamento);
  const [newCampanhas, setNewCampanhas] = useState<TCampanhaRegulamentoIdeias[]>([]);
  const [reload, setReload] = useState(false);
  const { campanhas } = form.values;
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);
  const formatCampanha = regulamento?.id ? newCampanhas : campanhas;

  /**
   * Handles the add campanha event.
   * @param campanha - The values of the form.
   */
  function handleAddCampanha(campanha: TCampanhaRegulamentoIdeiasForm) {
    if (regulamento && regulamento.id) {
      mutate({ ...campanha });
      setReload(reload => !reload)
      form.setFieldValue("campanhas", []); // limpa o campo para evitar que o usuario salve e sobreponha o que foi editado
    } else {
      form.insertListItem("campanhas", campanha);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadData();
      } catch (error) {
        console.error('Ocorreu um erro ao carregar os dados:', error);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, [regulamento?.id, reload])

  const loadData = async () => {
    const campanha = await getCampanhasRegulamentoIdeias(activeEmpresaId || "", regulamento.id || "");
    const format = campanha.docs.map((desafio) => desafio.data());
    setNewCampanhas(format)
  }

  return (
    <>
      <Divider />
      <Group justify="space-between">
        <Text fw={500}>Campanha de incentivo</Text>


        <AddItemButton
          size="xs"
          variant="outline"
          leftSection={<IconPlus size="1rem" />}
          modalProperties={modalProperties}
          schema={campanhaRegulamentoIdeiasSchema}
          onSubmit={handleAddCampanha}
        >
          Nova campanha
        </AddItemButton>
      </Group>
      {formatCampanha.length === 0 ? (
        <NoData>
          <Center>
            <Text c="gray.5">Nenhuma campanha cadastrada</Text>
          </Center>
        </NoData>
      ) : (
        formatCampanha.map((campanha, index) => (
          <CampanhaCard
            key={campanha.dataInicial.toISOString() + campanha.dataFinal.toISOString() + index}
            campanha={campanha}
            regulamento={regulamento}
            index={index}
            setReload={setReload}
            handleAddCampanha={handleAddCampanha}
          />
        ))
      )}
    </>
  );
};

export default RegulamentoIdeiasCampanhaInputs;
