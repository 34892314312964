import { TransformedValues, useForm } from "@mantine/form";
import { ObjectSchema } from "yup";
import useCatchFirestoreError from "@/hooks/useCatchFirestoreError";
import schema from "@/schema";
import { extractYupError } from "@/utils/form";
import useGetTotalMoedasPerRegulamento from "./useGetTotalMoedasPerRegulamento";
import useSetPatrocinioMutation from "./useSetPatrocinioMutation";

type TDesafioPatrocinioForm = Pick<TDesafioActivityPerColaborador, "moedas">;
const desafioPatrocinioSchema: ObjectSchema<TDesafioPatrocinioForm> = schema
  .object({
    moedas: schema
      .number()
      .required()
      .defined()
      .min(0)
      .max(schema.ref("$totalMoedasDisponiveis"))
      .default(0)
      .typeError("${value} não é um valor de ${path} válido")
      .label("Moedas")
  })
  .strict()
  .noUnknown();

type UseDesafioPatrocinioFormOptions = {
  desafio: TDesafio;
  regulamento: TRegulamentoDesafios;
  desafioActivity?: TDesafioActivityPerColaborador | null;
};

/**
 * Use desafio patrocinio form
 * @param options - Options for the hook
 * @param options.desafio - Desafio to be patrocinado
 * @param options.regulamento - Regulamento da desafio a ser patrocinada
 * @param [options.desafioActivity] - Desafio activity to be updated (if any)
 * @returns - Form props and submit handler
 */
function useDesafioPatrocinioForm({ desafio, regulamento, desafioActivity }: UseDesafioPatrocinioFormOptions) {
  const {
    data: totalMoedasGastas,
    error: totalMoedasGastasError,
    isLoading: loadingTotalMoedasGastas
  } = useGetTotalMoedasPerRegulamento(regulamento);
  useCatchFirestoreError(totalMoedasGastasError);

  const qtdMoedasGastasNesteDesafio = desafioActivity?.moedas || 0;
  const totalMoedasPeloRegulamento = regulamento.quantidadeMoedasPorColaborador || 0;
  const totalMoedasDisponiveis = totalMoedasPeloRegulamento - (totalMoedasGastas || 0) + qtdMoedasGastasNesteDesafio;
  const context = { totalMoedasDisponiveis };

  const form = useForm<TDesafioPatrocinioForm>({
    validate: (values) => {
      try {
        desafioPatrocinioSchema.validateSync(values, { abortEarly: false, context });
        return {};
      } catch (yupError) {
        return extractYupError(yupError as YupValidationResult);
      }
    },
    transformValues: (values) => desafioPatrocinioSchema.cast(values, { context }),
    initialValues: desafioPatrocinioSchema.cast(desafioActivity || { moedas: 0 }, { context })
  });

  const { mutate, isPending: isUpdatingPatrocinio } = useSetPatrocinioMutation(desafio, regulamento.id);

  type TFormValues = TransformedValues<typeof form>;

  /**
   * Handle form submit
   * @param values - Form values
   */
  function onSubmit(values: TFormValues) {
    mutate(values);
  }

  return { ...form, isLoading: isUpdatingPatrocinio || loadingTotalMoedasGastas, onSubmit: form.onSubmit(onSubmit) };
}

export default useDesafioPatrocinioForm;
