import { subject } from "@casl/ability";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import {
  FirestoreDataConverter,
  Timestamp,
  collection,
  getDocs,
  addDoc,
  serverTimestamp,
  doc,
  updateDoc,
  getCountFromServer,
  getDoc
} from "firebase/firestore";
import { getCurrentUser } from "@/services/auth";
import { generateQuery } from "@/utils/firestore";

import { getEmpresaDocumentReference } from "../empresas/firestore";

export const QUERY_KEY = "tipoDeJogada";
const DEFAULT_LIMIT = 10;
export const DEFAULT_SORTING: SortingState = [{ id: "nome", desc: false }];
export const DEFAULT_PAGINATION: PaginationState = { pageIndex: 0, pageSize: DEFAULT_LIMIT };
const BASE_FILTER: ColumnFiltersState = [
  {
    id: "deletedAt",
    value: ["==", null]
  }
];
export type TTTipoDeJogada = Pick<TAuditForm, "createdBy" | "updatedBy" | "createdAt" | "updatedAt"> & {
  startedAt: ReturnType<typeof serverTimestamp>;
  pausedAt: ReturnType<typeof serverTimestamp> | null;
  endedAt: ReturnType<typeof serverTimestamp> | null;
};

type TTipoDeJogadaDocument = TAuditDocument &
  Omit<TTipoDeJogada, keyof TAudit | keyof TAuditDocument | "startedAt" | "pausedAt" | "endedAt"> & {
    nome: string;
    descricao: string;
    createdAt: Timestamp;
    refPath: string;
    deletedAt: Date | null;
    startedAt: Timestamp;
    pausedAt: Timestamp | null;
    endedAt: Timestamp | null;
  };

export type TtipoDeJogadaFormFields = Omit<
  TTipoDeJogada,
  "id" | "createdAt" | "updatedAt" | "createdBy" | "updatedBy" | "startedAt" | "pausedAt" | "endedAt" | "deletedAt"
> &
  Partial<Pick<TTipoDeJogada, "id">>;

type TTTipoDeJogadaDatabaseFields = Pick<TAuditForm, "createdBy" | "updatedBy" | "createdAt" | "updatedAt"> & {
  startedAt: ReturnType<typeof serverTimestamp>;
  pausedAt: ReturnType<typeof serverTimestamp> | null;
  endedAt: ReturnType<typeof serverTimestamp> | null;
};

export type TTipoDeJogadaForm = TtipoDeJogadaFormFields & TTTipoDeJogadaDatabaseFields;

const tipoDeJogadaConverter: FirestoreDataConverter<TTipoDeJogada> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const { nome, descricao, updatedAt, deletedAt, createdAt, endedAt, startedAt, pausedAt, refPath, ...document } =
      snap.data() as TTipoDeJogadaDocument;

    const data: TTipoDeJogada = subject("TTipoDeJogada", {
      ...document,
      id: snap.id,
      nome,
      descricao,
      deletedAt,
      refPath,
      createdAt: createdAt.toDate(),
      startedAt: startedAt.toDate(),
      endedAt: endedAt ? endedAt.toDate() : null,
      pausedAt: pausedAt ? pausedAt.toDate() : null
    });

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }
    return subject("TTipoDeJogada", data);
  }
};

/**
 * Get all tipoDeJogadas de inovação
 * @param empresaId - Empresa id
 * @returns - tipos de inovação collection reference
 */
function getTipoDeJogadaCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, "tipoDeJogada");
}

/**
 * Get tipoDeJogadas de inovação for a empresa
 * @param  empresaId - Empresa id to get comitês
 * @param  root0 - Objeto contendo opções como sorting, filters, pagination, paginationCursors
 * @param  root0.sorting - Sorting option for tipoDeJogadas de inovação
 * @param  root0.filters - Filters for tipoDeJogadas de inovação
 * @param  root0.pagination - Pagination options for tipoDeJogadas de inovação
 * @param root0.paginationCursors - Pagination cursors for tipoDeJogadas de inovação
 * @returns - Tipos de inovação document snapshot
 */
export function getTipoDeJogadas(
  empresaId: TEmpresa["id"],
  {
    sorting = [],
    filters = [],
    pagination = { pageIndex: 0, pageSize: DEFAULT_LIMIT },
    paginationCursors
  }: TPaginatedQueryOptions<TTipoDeJogada> = {}
): Promise<TPaginatedQueryResponse<TTipoDeJogada>> {
  const projetosCollectionReference =
    getTipoDeJogadaCollectionReference(empresaId).withConverter(tipoDeJogadaConverter);

  const allFilters = [...BASE_FILTER, ...filters];
  const qAll = generateQuery(projetosCollectionReference, { filters: allFilters });
  const qPaginated = generateQuery(projetosCollectionReference, {
    sorting,
    filters: allFilters,
    pagination,
    paginationCursors
  });

  // eslint-disable-next-line compat/compat
  return Promise.all([getDocs(qPaginated), getCountFromServer(qAll)]);
}

/**
 * Start a new tipoDeJogada de inovação for a empresa
 * @param empresaId - Empresa id to start a new tipo de inovação
 * @param values - tipo de inovação form fields
 * @returns - tipo de inovação document reference
 */
export function addTipoDeJogada(empresaId: TEmpresa["id"], values: TtipoDeJogadaFormFields) {
  const tipoDeJogadaCollectionReference = getTipoDeJogadaCollectionReference(empresaId);

  return addDoc(tipoDeJogadaCollectionReference, {
    ...values,
    deletedAt: null
  });
}
/**
 * Get tipoDeJogada de inovação reference
 * @param empresaId - Empresa id to start a new tipo de inovação
 * @param tipoDeJogadaId - tipo de inovação form fields
 * @returns - tipo de inovação document reference
 */
function getTipoDeJogadaDocumentReference(empresaId: TEmpresa["id"], tipoDeJogadaId: TTipoDeJogada["id"]) {
  const tipoDeJogadaCollectionReference = getTipoDeJogadaCollectionReference(empresaId);
  return doc(tipoDeJogadaCollectionReference, tipoDeJogadaId);
}

/**
 * delete tipoDeJogada de inovação
 * @param empresaId - Empresa id to start a new tipo de inovação
 * @param tipoDeJogadaId - tipo de inovação form fields
 * @returns - tipo de inovação delete
 */
export function deleteTipoDeJogada(empresaId: TEmpresa["id"], tipoDeJogadaId: TEmpresa["id"]) {
  const tipoDocumentReference = getTipoDeJogadaDocumentReference(empresaId, tipoDeJogadaId);
  return updateDoc(tipoDocumentReference, {
    deletedAt: serverTimestamp()
  });
}

/**
 * updated tipoDeJogada de inovação
 * @param empresaId - Empresa id to start a new tipo de inovação
 * @param tipoDeJogadaId - tipo de inovação form fields
 * @param values - values to update
 * @returns - tipo de inovação delete
 */
export function updatedTipoDeJogada(
  empresaId: TEmpresa["id"],
  tipoDeJogadaId: TProjeto["id"],
  values: TtipoDeJogadaFormFields
) {
  const currentUserId = getCurrentUser()?.uid;
  const tipoDocumentReference = getTipoDeJogadaDocumentReference(empresaId, tipoDeJogadaId);

  return updateDoc(tipoDocumentReference, {
    ...values,
    updatedAt: serverTimestamp(),
    updatedBy: currentUserId
  });
}

/**
 * Get tipo de inovação reference
 * @param empresaId - Empresa id to start a new tipo de inovação
 * @param tipoDeJogadaId - tipo de inovação form fields
 * @returns - tipo de inovação document reference
 */
function getTipoDeJogadaReference(empresaId: TEmpresa["id"], tipoDeJogadaId: TTipoDeInovacao["id"]) {
  const categoriaCollectionReference = getTipoDeJogadaCollectionReference(empresaId);
  return doc(categoriaCollectionReference, tipoDeJogadaId);
}

/**
 * Get tipo de inovação item
 * @param empresaId - Empresa id to start a new tipo de inovação
 * @param tipoDeJogadaId - tipo de inovação form fields
 * @returns - tipo de inovação document item
 */
export function getTipoDeJogadaItem(empresaId: TEmpresa["id"], tipoDeJogadaId: TTipoDeInovacao["id"]) {
  const tipoDeJogadaDocumentReference = getTipoDeJogadaReference(empresaId, tipoDeJogadaId);
  return getDoc(tipoDeJogadaDocumentReference.withConverter(tipoDeJogadaConverter));
}
