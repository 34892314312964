import { useForm, yupResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { Asserts } from "yup";

import inviteDiagnosticoSchema from "@/modules/convidar-empresa/components/convidar-empresa-form/invite-diagnostico-schema";
import { captureException } from "@/services/log";
import useInviteDiagnosticoMutation from "../../hooks/useInviteDiagnosticoMutation";

type InviteInputs = Asserts<typeof inviteDiagnosticoSchema>;

/**
 * Invite Empresa Form hook
 * @param  options - Opções do formulário de convite da empresa
 * @param  options.projetoId - O ID do projeto
 * @param  options.empresaId - O ID da empresa
 * @param  options.setLoading - O ID da empresa
 * @returns - O formulário de convite da empresa
 */
// prettier-ignore
function useInviteEmpresaForm({ projetoId, empresaId, setLoading } :{ projetoId: TProjeto | string | undefined, empresaId: string | undefined,  setLoading: Dispatch<SetStateAction<boolean>> }) {
  const navigate = useNavigate();
  const form = useForm<InviteInputs>({
    validate: yupResolver(inviteDiagnosticoSchema),
    transformValues: (values) => inviteDiagnosticoSchema.cast(values),
    initialValues: inviteDiagnosticoSchema.getDefault() as InviteInputs
  });

  const {
    mutate: sendInvite,
    reset,
    isPending
  } = useInviteDiagnosticoMutation({
    onSuccess() {
      showNotification({
        message: "Convite enviado com sucesso!",
        autoClose: 3500
      });
      reset();
      form.reset();
      setLoading(false)
      navigate("/", {
        replace: true
      });
    },
    onError(error: Error) {
      captureException(error, true);
    }
  });

  /**
   * Handle form submit
   * @param values - Form values
   * @param values.email - O email dos valores
   * @param values.nome - O nome dos valores
   * @param values.cnpj - O cnpj dos valores
   */
  function handleFormSubmit(values: { email: string; nome: string; cnpj: string }) {
    sendInvite({ ...values, projetoId, empresaId});
  }

  return { form, onSubmit: form.onSubmit(handleFormSubmit), isPending };
}

export default useInviteEmpresaForm;
