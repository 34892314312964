import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { getRepostasArray, QUERY_KEY } from "../../firestore";

type TGetRespostaResponse = Awaited<ReturnType<typeof getRepostasArray>>;

/**
 * Get resposta from url params
 * @param id - id da resposta
 * @param id.id - id
 * @param id.idQuestionario - id do questionario
 * @param id.convite - convite
 * @returns - resposta or null if not found
 */
// prettier-ignore
function useGetRespostaConvite({ id, idQuestionario, convite }: { id: string | null | undefined , idQuestionario: TQuestionario["id"] | undefined, convite:TConvite[] | null | undefined}) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const verification = convite?.some(item => item.idEmpresaColaboradorConvidado === activeEmpresaId) ?convite?.find(item => item.idEmpresaColaboradorConvidado === activeEmpresaId)?.idEmpresaColaboradorConvidado : activeEmpresaId;
  // const { id } = useParams<{ id: string }>();

  return useQuery<TGetRespostaResponse, FirestoreError, TResposta[] | null>({
    queryKey: [QUERY_KEY, verification, id, idQuestionario],
    queryFn() {
      if (!verification) {
        throw new Error("Empresa não selecionada");
      }
      if (!id) {
        throw new Error("Ideia não selecionada");
      }

      return getRepostasArray(verification, id, idQuestionario);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((document_) => document_.data());
    },
    enabled: !!id && !!verification
  });
}

export default useGetRespostaConvite;
