import { Group, Select } from "@mantine/core";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import useUserStore from "@/modules/users/store";
import getQuestionario from "./useGetQuestionarios";

interface Values {
  projeto: string;
  questionario: string;
  idEmpresa?: string;
  idProjeto?: string;
}

interface ComboboxItem {
  value: string;
  label: string;
}

const message = "Ocorreu um erro ao carregar os dados:";
const array = [] as [];

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
const RelatorioComponent = ({
  setValues,
  questionario,
  idEmpresa,
  idProjeto
}: {
  setValues: Dispatch<SetStateAction<Values>>;
  questionario: string;
  idEmpresa?: string;
  idProjeto?: string;
}) => {
  const [questionarios, setQuestionarios] = useState<ComboboxItem[]>([]);
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const projeto = useUserStore((state) => state.activeProjeto);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadQuestionario();
      } catch (error) {
        console.error(message, error);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, [activeEmpresaId, projeto, idProjeto]);

  const loadQuestionario = async () => {
    if (projeto || idProjeto) {
      const arrayQuestionario = await getQuestionario(idEmpresa || activeEmpresaId || "", idProjeto || projeto);
      setQuestionarios(arrayQuestionario.map((projeto) => ({ ...projeto, value: projeto.id, label: projeto.nome })));
    }
  };

  return (
    <>
      <Group justify={"end"}>
        <Select
          label="Selecione um questionário"
          placeholder="Questionário"
          data={questionarios && questionarios.length > 0 ? questionarios : array}
          disabled={questionarios.length === 0}
          onChange={onChange("questionario", setValues)}
          value={questionario === "" || questionarios.length === 0 ? null : questionario}
        />
      </Group>
    </>
  );
};

export default RelatorioComponent;

// prettier-ignore
const onChange = (field: string, setValues: Dispatch<SetStateAction<Values>>) => {
  return (value: string) => {
    setValues(values => ({ ...values, [field]: value }))
  }
}
