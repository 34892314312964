import { Card, Group, Stack, Text, ThemeIcon } from "@mantine/core";
import { IconDeviceAnalytics } from "@tabler/icons-react";
import useGetAllIdeiasFromComite from "@/modules/comite-inovacao/hooks/useGetAllIdeiasFromComite";
import IdeiaStatusBadge from "@/modules/ideias/components/ideia-status-badge";
// prettier-ignore
const IdeiasMovimentas = ({ comite }: { comite: TComiteInovacao }) => {
  const { data: ideias, isFetched } = useGetAllIdeiasFromComite(comite);

  const hasNoIdeias = isFetched && ideias && ideias.length === 0;
  if (hasNoIdeias) {
    return null;
  }

  return (
    <Card withBorder>
      <Group align="flex-start">
        <ThemeIcon size="md" radius="xl" mt={2}>
          <IconDeviceAnalytics width={18} radius="xl" />
        </ThemeIcon>
        <Stack gap={0}>
          <Text fz="lg" mb={5}>Ideias que foram analisadas</Text>
          {ideias?.map(item => {
            return <Group gap={6} mb={5} key={item.id}>
              <IdeiaStatusBadge status={item.status} iconOnly />
              <Text fw="bold" fz="sm" span>
                {item.titulo}
              </Text>
            </Group>
          })}
        </Stack>
      </Group>
    </Card>
  );
};

export default IdeiasMovimentas;
