import { Card, Flex, Space, Title } from "@mantine/core";
import { QueryObserverResult } from "@tanstack/react-query";
import DesafioCronogramaForm from "@/modules/desafio-cronograma/components/desafio-comentario-form";
// prettier-ignore
const DesafioCronogramaCard = ({ desafio, refetch }: { desafio: TDesafio; refetch: () => Promise<QueryObserverResult>; }) => {

  return (
    <Card withBorder>
      <Flex direction="column" pb="xs">
        <Title order={4}>Agendamento da imersão no desafio</Title>
        <Space h={"md"} />
        <DesafioCronogramaForm desafio={desafio} refetch={refetch} />
      </Flex>
    </Card>

  );
};

export default DesafioCronogramaCard;
