import { FileWithPath } from "@mantine/dropzone";
import { ref, uploadBytes } from "firebase/storage";
import storage from "@/src/base/storage";

/**
 * Upload the anexo file to the storage
 * @param empresaId - The empresa id
 * @param id - The ideia id
 * @param anexoToUpload  - The anexo
 * @returns - The public url to the file
 */
export function uploadAnexosFilesToStorage(empresaId: TEmpresa["id"], id: string, anexoToUpload: FileWithPath) {
  const fileExtension = anexoToUpload.name.split(".").pop();
  if (!fileExtension) {
    throw new Error("The anexo file has no extension");
  }

  const fileReference = ref(storage, `empresas/${empresaId}/colaboradores/${id}/${anexoToUpload.name}`);
  return uploadBytes(fileReference, anexoToUpload);
}
