import { Asserts } from "yup";
import marcoSchema from "../../schema/marco-movimentacao-schema";

const marcoAtuacaoSchema = marcoSchema.pick([
  "dataConclusao",
  "descricaoAtuacao",
  "anexos",
  "anexosFilesToUpload",
  "startedAt",
  "finishedAt"
]);

export type FormInputs = Asserts<typeof marcoAtuacaoSchema>;

export default marcoAtuacaoSchema;
