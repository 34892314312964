import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { IDEIAS_COLLECTION_KEY, getIdeiasSortedByRanking } from "@/modules/ideias/firestore";
import useUserStore from "@/modules/users/store";

type TGetIdeiasSortedByRanking = Awaited<ReturnType<typeof getIdeiasSortedByRanking>>;

/**
 * Hook para obter as ideias mais votadas da empresa
 * @returns - Top ideias mais votadas da empresa
 */
function useGetTopIdeiasMaisVotadas() {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  return useQuery<TGetIdeiasSortedByRanking, FirestoreError, TIdeia[]>({
    queryKey: [activeEmpresaId, IDEIAS_COLLECTION_KEY, "getIdeiasSortedByRanking"],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return getIdeiasSortedByRanking(activeEmpresaId);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((ideia) => ideia.data());
    }
  });
}
export default useGetTopIdeiasMaisVotadas;
