/* eslint-disable complexity */
import {
  Stack,
  Group,
  Title,
  Button,
  MultiSelect,
  Collapse,
  Text,
  Flex,
  Checkbox,
  SimpleGrid,
  Box,
  Switch,
  List
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronUp, IconChevronDown } from "@tabler/icons-react";
import { ReactNode } from "react";
import FileItem from "@/components/file-selector-dropzone/FileItem";
import ColaboradorSelector from "@/modules/colaboradores/components/colaborador-selector";
import IdeiaLikes from "@/modules/ideias-likes/IdeiaLikes";
import RankingByMoedasBadge from "@/src/routes/ideia-page/RankingByMoedasBadge";
import TotalComentariosBadge from "@/src/routes/ideia-page/TotalComentariosBadge";
import TotalLikesBadge from "@/src/routes/ideia-page/TotalLikesBadge";
import IdeiaStatusBadge from "../ideia-status-badge";
import TotalMoedasBadge from "../total-moedas-badge";

type FieldCardProperties = {
  label: string;
  value?: string;
  children?: ReactNode;
};

/**
 *  Field card
 * @param root0 readOnly
 * @param root0.label Field label
 * @param root0.value Field value
 * @param root0.children Field children
 * @returns Field card
 */
function FieldCard({ label, value, children }: FieldCardProperties) {
  return (
    <Flex direction={"column"}>
      <Text mb="2px" size="sm" fw={500} c={"#555"} style={{ fontSize: 16 }}>
        {label}
      </Text>
      {value && (
        <Text size="sm" fw={400} style={{ wordBreak: "break-word", fontSize: 16 }}>
          {value}
        </Text>
      )}
      {children}
    </Flex>
  );
}

type IdeiaContentProperties = {
  ideia: TIdeia;
};
/**
 * Ideia form fields
 * @param root0 readOnly
 * @param root0.ideia Ideia form
 * @returns Ideia form fields
 */
export function IdeiaContent({ ideia }: IdeiaContentProperties) {
  const [opened, { toggle }] = useDisclosure(false);

  return (
    <Stack gap="lg">
      <Stack gap="xs">
        <Group justify="space-between" mb={5}>
          <Title order={4}>Informações do cadastro de ideias</Title>
          {ideia.objetivo && ideia.objetivo.length > 0 && (
            <Button
              type="button"
              variant="transparent"
              size="compact-sm"
              rightSection={opened ? <IconChevronUp size="1rem" /> : <IconChevronDown size="1rem" />}
              onClick={toggle}
            >
              Ver mais
            </Button>
          )}
        </Group>
        <Group>
          <RankingByMoedasBadge ideia={ideia} />
          <TotalMoedasBadge ideia={ideia} />
          <TotalComentariosBadge ideia={ideia} />
          <TotalLikesBadge ideia={ideia} variant="filled" />
          <IdeiaStatusBadge status={ideia.status} />
        </Group>
      </Stack>
      <Group display="flex" flex="1 0 0" justify="stretch">
        <FieldCard label="Setores">
          <MultiSelect value={ideia.setores} style={{ flex: 1 }} readOnly variant="unstyled" />
        </FieldCard>
        <FieldCard label="Autor">
          <ColaboradorSelector
            searchable
            style={{ flex: 1 }}
            clearable={false}
            allowDeselect={false}
            readOnly
            variant={"unstyled"}
            nothingFoundMessage="Nenhum colaborador encontrado..."
            value={ideia.autorReference}
          />
        </FieldCard>
      </Group>

      <FieldCard label="Título" value={ideia.titulo} />
      <FieldCard label="Descrição" value={ideia.descricao} />

      <Collapse in={opened}>
        <Stack gap="lg">
          <Checkbox.Group
            label="A sua ideia tem como objetivo:"
            labelProps={{ style: { size: "sm", fw: 500, color: "#555", fontSize: 16 } }}
            defaultValue={ideia.objetivo}
            readOnly
          >
            <SimpleGrid cols={2} spacing="sm" verticalSpacing="sm" mt="md">
              {ideia.objetivo.map((objetivo) => (
                <Checkbox
                  key={objetivo}
                  value={objetivo}
                  variant="unstyled"
                  label={
                    <Text size="sm" fw={400} style={{ wordBreak: "break-word", fontSize: 16 }} c={"#000"}>
                      {objetivo}
                    </Text>
                  }
                />
              ))}
            </SimpleGrid>
          </Checkbox.Group>

          <FieldCard
            label="Descreva como a ideia pode contribuir para que algum dos objetivos acima seja atingido."
            value={ideia.comoAtingirObjetivo}
          />

          <FieldCard
            label="Descreva aqui o problema que sua ideia irá ajudar a solucionar. Apresente detalhes do problema e o impacto disso."
            value={ideia.descricaoProblema}
          />

          <FieldCard
            label="Que diferenciais inovadores e tecnológicos seu Projeto/Ideia possui?"
            value={ideia.diferenciais}
          />

          <FieldCard
            label="Você já buscou o feedback para validação do seu Projeto/Ideia? Se sim, qual foi o resultado?"
            value={ideia.feedback}
          />

          {ideia.anexos.length > 0 && (
            <div>
              <Text size="sm" fw={500} c={"#555"} style={{ fontSize: 16 }}>
                Se tiver algum anexo sobre a ideia, pode incluir aqui:
              </Text>
              <Text fz="xs" c="dimmed">
                Sintetize e inclua tudo o que for necessário em um arquivo, se tiver vídeos pode incluir o link do vídeo
                no arquivo de texto
              </Text>
              <Group mt="sm" wrap="nowrap" justify="space-between" align="center">
                <List
                  withPadding
                  styles={{
                    root: {
                      flex: 1
                    }
                  }}
                >
                  {ideia.anexos.map((file, index) => (
                    <FileItem key={file.path} file={file} index={index} readOnly />
                  ))}
                </List>
              </Group>
            </div>
          )}

          <Box pos="relative">
            <Box mt="lg">
              <Group justify="start" gap="xl">
                <Switch
                  defaultChecked={ideia.permitirComentarios}
                  variant={"unstyled"}
                  onLabel="Sim"
                  offLabel="Não"
                  size="md"
                  checked={ideia.permitirComentarios}
                  label="Permite comentários"
                />
                <Switch
                  variant={"unstyled"}
                  defaultChecked={ideia.permitirPatrocinio}
                  onLabel="Sim"
                  offLabel="Não"
                  size="md"
                  checked={ideia.permitirPatrocinio}
                  label="Permite patrocínio"
                />
              </Group>
            </Box>
          </Box>
        </Stack>
      </Collapse>
      <Group align="flex-start">
        <Flex align="center" gap="sm">
          <IdeiaLikes ideia={ideia} />
        </Flex>
      </Group>
    </Stack>
  );
}
