import { TransformedValues, useForm, yupResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { UseMutationOptions, useQueryClient, useMutation } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { Asserts } from "yup";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { TProjetoForm, addProjeto, updateProjeto } from "../../firestore";
import projetoSchema from "../../schema/projeto-schema";
import { QUERY_KEY } from "../projetos-table/ProjetosTable";

type FormInputs = Asserts<typeof projetoSchema>;
type TUpdateResponse = Awaited<ReturnType<typeof updateProjeto>>;
type TAddResponse = Awaited<ReturnType<typeof addProjeto>>;
// interface Status {
//   id: number;
//   titulo: string;
//   cor: string;
// }

interface Images {
  path: string;
  url: string;
}
interface Fase {
  id: string;
  titulo: string;
  cor: string;
  checked?: boolean;
  // status: Status[];
}

/**
 * Projeto Form hook
 * @param projeto - Projeto data
 * @param fases - fases project
 * @param root0 - Options
 * @param root0.onSuccess - On success callback
 * @param root0.onError - On error callback
 * @returns - Projeto Form
 */
// prettier-ignore
function useProjetoForm(
  projeto: TProjetoForm & Partial<Pick<TProjeto, "id" | "refPath">>,
  fases: Fase[],
  {
    onSuccess,
    onError
  }: Pick<UseMutationOptions<TUpdateResponse | TAddResponse, FirestoreError, FormInputs>, "onSuccess" | "onError">
) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const queryClient = useQueryClient();
  const form = useForm<FormInputs>({
    validate: yupResolver(projetoSchema),
    transformValues: (values) => projetoSchema.cast(values),
    initialValues: projeto
  });

  const { mutate, isPending, reset } = useMutation<TUpdateResponse | TAddResponse, FirestoreError, FormInputs>({
    mutationFn: (values) => {

      if (!activeEmpresaId) {
        throw new Error("Nenhuma empresa selecionada");
      }

      if (values.urlImages.length > 0 && verifyLinks(values.urlImages))  {
        throw new Error("O link de uma das imagens está indefinido.");
      }
      return projeto.id
        ? updateProjeto(activeEmpresaId, projeto.id, { ...values, fases})
        : addProjeto(activeEmpresaId, { ...values, fases});
    },
    async onSuccess(data, variables, context) {
      showNotification({
        message: `${variables.nome} ${projeto.id ? "atualizado" : "cadastrado"} com sucesso!`,
        color: "green",
        autoClose: 2500
      });
      reset();
      form.reset();
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      if (onSuccess) {
        return onSuccess(data, variables, context);
      }
      return null;
    },
    onError(error, variables, context) {
      captureException(error, true);
      if (onError) {
        onError(error, variables, context);
      }
    }
  });

  type TFormTransformed = TransformedValues<typeof form>;

  /**
   * Handle form submit
   * @param values - Form values
   */
  function handleFormSubmit(values: TFormTransformed) {
    mutate(values);
  }

  return { form, isPending, onSubmit: form.onSubmit(handleFormSubmit) };
}

export default useProjetoForm;

/**
 * Handle form submit
 * @param url - Form url
 * @returns - Projeto Form
 */
function isLink(url: string) {
  const pattern = /^(http|https):\/\//;
  return pattern.test(url);
}
/**
 * Handle form submit
 * @param array - Form array
 * @returns - Projeto Form
 */
function verifyLinks(array: Images[]) {
  for (const item of array) {
    if (!isLink(item.url)) return true;
  }
  return false;
}
