import { Card, Flex, Group, Space, Text, Title } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import { QueryObserverResult } from "@tanstack/react-query";
import { Timestamp } from "firebase/firestore";
import { useUpdateIncricao } from "../list-form-cronograma/useGetInscricao";

interface MyState {
  id: number;
  titulo: string;
  descricao: string;
}

// prettier-ignore
const SolucoesCronogramaList = ({ data, refetch }: { data: TCronogramaDesafio; refetch: () => Promise<QueryObserverResult>; }) => {

  const { mutate } = useUpdateIncricao();

  const changeMutate = (id: number) => {
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { inicioIncricao, terminoIncricao, solucoes }: { inicioIncricao: Date, terminoIncricao: Date, solucoes: MyState[] } = verificationFunction(data, id);
      mutate({
        ...data,
        updatedAt: Timestamp.now(),
        createdAt: Timestamp.now(),
        deletedAt: null,
        inicioIncricao,
        terminoIncricao,
        solucoes
      });
      void refetch();
    }
  };

  return (
    <>
      <Flex direction="column" pb="xs">
        <Title order={4}>Soluções propostas na imersão</Title>
        <Space h={"md"} />
        {data && data.solucoes && Array.isArray(data.solucoes) && data.solucoes.length > 0 ? data.solucoes.map((item: MyState) => <>
          <Card withBorder>
            <Group justify={"space-between"}>
              <Flex direction={"column"}>
                <Text fz="xs" color="rgba(82, 83, 86, 1)">{item.titulo}</Text>
                <Text fz="md">{item.descricao}</Text>
              </Flex>
              <IconTrash size={16} color="rgba(8, 68, 244, 1)" style={{ cursor: "pointer" }} onClick={changeMutate(item.id)} />
            </Group>
          </Card>
          <Space h={"md"} />
        </>) : <><Text c="dimmed">Nenhuma proposta encontrada!</Text></>}
      </Flex>
    </>
  );
};

export default SolucoesCronogramaList;

// prettier-ignore
const verificationFunction = (data: TCronogramaDesafio | undefined, id: number) => {
  const inicioIncricao = data && data.inicioIncricao ? data.inicioIncricao : new Date();
  const terminoIncricao = data && data.terminoIncricao ? data.terminoIncricao : new Date();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const solucoes = data && Array.isArray(data?.solucoes) && data?.solucoes?.length > 0 ? data?.solucoes.filter((item: MyState) => item.id !== id) : []

  return { inicioIncricao, terminoIncricao, solucoes };
};
