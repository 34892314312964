import { Space, Card, Grid, Flex, Text, Button, Anchor } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { useMutation } from "@tanstack/react-query";
import { deleteObject, getStorage, ref } from "firebase/storage";
import { MouseEvent } from "react";
import useUserStore, { setReloadConvites } from "@/modules/users/store";
import { captureException } from "@/services/log";
import { formatDate } from "@/utils/date";
import { updateEvidencia } from "../firestore";

type TCancelResponse = Awaited<ReturnType<typeof updateEvidencia>>;

/**
 * Handles the click event on the button.
 * @param evidencia - Evidencia
 * @param cancelEvidencia - The function to cancel evidencia.
 * @param reloadConvites - reaload
 * @returns - The return the click.
 */
function handleClick(evidencia: TEvidencia, cancelEvidencia: () => void, reloadConvites: boolean | undefined) {
  return (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    modals.openConfirmModal({
      title: "Excluir evidência?",
      centered: true,
      children: <Text size="sm">Tem certeza que deseja excluir essa evidência?</Text>,
      labels: {
        confirm: "Sim",
        cancel: "Não"
      },
      confirmProps: { color: "red" },
      onConfirm: () => {
        const storage = getStorage();
        void cancelEvidencia();
        if (!evidencia.externo) {
          const fileReference = ref(storage, evidencia.link);
          void deleteObject(fileReference);
        }
        setReloadConvites(reloadConvites);
      }
    });
  };
}

/**
 * Cancel evidencia mutation hook
 * @param evidencia - The evidencia.
 * @returns Cancel evidencia mutation hook
 */
function useCancelEvidenciaMutation(evidencia: TEvidencia) {
  return useMutation<TCancelResponse, Error>({
    mutationFn: () => {
      return updateEvidencia(evidencia || "", {
        ...evidencia,
        deletedAt: new Date()
      });
    },
    onError(error) {
      captureException(error, true);
    }
  });
}

type EvidenciaEnviadaCardProperties = {
  evidencia: TEvidencia;
};

/**
 * Card para exibir o status da inscrição do colaborador em um questionário.
 * @param root0 Props
 * @param root0.evidencia Evidencia
 * @returns Component
 */
function EvidenciaEnviadaCard({ evidencia }: EvidenciaEnviadaCardProperties) {
  const reloadConvites = useUserStore((state) => state.reloadConvites);
  const { mutate: cancelEvidencia } = useCancelEvidenciaMutation(
    evidencia || {
      id: "",
      idEmpresa: "",
      idProjeto: "",
      idQuestionario: "",
      idEmpresaColaborador: "",
      link: "",
      externo: false
    }
  );
  const isSmMarches = useMediaQuery("(max-width: 48em)");

  return (
    <>
      <Space h="md" />
      <Card withBorder shadow="md" style={{ flex: 1, width: isSmMarches ? "1000px" : "100%" }}>
        <Grid align="center">
          {/* Coluna que ocupa o espaço livre */}
          <Grid.Col style={{ flex: 1 }}>
            <Flex mih={50} gap="xs" justify="flex-start" align="flex-start" direction="column" wrap="wrap">
              <Text fw={300} fz="xs">
                Nome do arquivo
              </Text>
              <Anchor
                href={evidencia?.link}
                target="_blank"
                rel="noopener noreferrer"
                style={{ wordBreak: "break-all" }}
              >
                {evidencia?.nomeArquivo}
              </Anchor>
            </Flex>
          </Grid.Col>
          {/* Coluna de data com alinhamento à direita */}
          <Grid.Col span={3}>
            <Flex mih={50} gap="xs" justify="flex-end" align="flex-start" direction="column" wrap="wrap">
              <Text fw={300} fz="xs">
                Data do envio
              </Text>
              <Text fw={500} fz="md">
                {evidencia && formatDate(evidencia.createdAt)}
              </Text>
            </Flex>
          </Grid.Col>
          {/* Coluna do botão, também alinhada à direita */}
          <Grid.Col span={3}>
            <Flex mih={50} gap="xs" justify="flex-end" align="flex-start" direction="column" wrap="wrap">
              <Button onClick={handleClick(evidencia, cancelEvidencia, reloadConvites)} color="red">
                Excluir Evidência
              </Button>
            </Flex>
          </Grid.Col>
        </Grid>
      </Card>
    </>
  );
}

export default EvidenciaEnviadaCard;
