import { TransformedValues, useForm, yupResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { UseMutationOptions, useQueryClient, useMutation } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { Asserts } from "yup";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { TTiposDeRespostasForm, addTiposDeRespostas, updateTiposDeRespostas } from "../../firestore";
import tiposDeRespostasSchema from "../../schema/tipos-de-respostas-schema";
import { QUERY_KEY } from "../tipos-de-respostas-table/TiposDeRespostasTable";

type FormInputs = Asserts<typeof tiposDeRespostasSchema>;
type TUpdateResponse = Awaited<ReturnType<typeof updateTiposDeRespostas>>;
type TAddResponse = Awaited<ReturnType<typeof addTiposDeRespostas>>;

/**
 * Empresa Form hook
 * @param tiposDeRespostas - Empresa data
 * @param root0 - Options
 * @param root0.onSuccess - On success callback
 * @param root0.onError - On error callback
 * @returns - Empresa Form
 */
function useTiposDeRespostasForm(
  tiposDeRespostas: TTiposDeRespostasForm & Partial<Pick<TTiposDeRespostas, "id" | "refPath">>,
  {
    onSuccess,
    onError
  }: Pick<UseMutationOptions<TUpdateResponse | TAddResponse, FirestoreError, FormInputs>, "onSuccess" | "onError">
) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const queryClient = useQueryClient();
  const form = useForm<FormInputs>({
    validate: yupResolver(tiposDeRespostasSchema),
    transformValues: (values) => tiposDeRespostasSchema.cast(values),
    initialValues: tiposDeRespostas
  });

  const { mutate, isPending, reset } = useMutation<TUpdateResponse | TAddResponse, FirestoreError, FormInputs>({
    mutationFn: (values) => {
      if (!activeEmpresaId) {
        throw new Error("Nenhuma empresa selecionada");
      }
      return tiposDeRespostas.id
        ? updateTiposDeRespostas(activeEmpresaId, tiposDeRespostas.id, values)
        : addTiposDeRespostas(activeEmpresaId, values);
    },
    async onSuccess(data, variables, context) {
      showNotification({
        message: `${variables.nome} ${tiposDeRespostas.id ? "atualizado" : "cadastrado"} com sucesso!`,
        color: "green",
        autoClose: 2500
      });
      reset();
      form.reset();
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      if (onSuccess) {
        return onSuccess(data, variables, context);
      }
      return null;
    },
    onError(error, variables, context) {
      captureException(error, true);
      if (onError) {
        onError(error, variables, context);
      }
    }
  });

  type TFormTransformed = TransformedValues<typeof form>;

  /**
   * Handle form submit
   * @param values - Form values
   */
  function handleFormSubmit(values: TFormTransformed) {
    mutate(values);
  }

  return { form, isPending, onSubmit: form.onSubmit(handleFormSubmit) };
}

export default useTiposDeRespostasForm;
