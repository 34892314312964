import schema from "@/schema";

const FeedbackSchema = schema.object({
  title: schema.string().required().defined().label("Faixa"),
  description: schema.string().required().defined().label("Feedback"),
  start: schema.number().required().defined().label("Nota inicial"),
  end: schema.number().required().defined().label("Nota final")
});

export default FeedbackSchema;
