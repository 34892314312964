import { useEffect, useState } from "react";

const DEFAULT_PAGE_SIZE = 10;

export const usePaginator = <T>(items: T[]) => {
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(0);

  const start = currentPage * pageSize;
  const end = start + pageSize;
  const currentPageContent = items.slice(start, end);

  useEffect(() => {
    setCurrentPage(0);
  }, [pageSize]);

  const getPageContent = (page: number) => {
    const start = page * pageSize;
    const end = start + pageSize;

    return items.slice(start, end);
  };

  return { setPageSize, setCurrentPage, pageSize, currentPage, currentPageContent, getPageContent };
};
