import { useForm, yupResolver } from "@mantine/form";
import { useMemo } from "react";
import { yup } from "@/schema/schema";

const schema = yup.object({
  email: yup.string().email().required().label("E-mail"),
  name: yup.string().required().label("Nome")
});

export type ConviteForm = yup.InferType<typeof schema>;

const conviteFormInitialValues: ConviteForm = {
  email: "",
  name: ""
};

const useConviteForm = () => {
  const form = useForm({
    initialValues: conviteFormInitialValues,
    validate: yupResolver(schema)
  });

  const descriptor = useMemo(() => schema.describe(), [form]);

  return { form, descriptor };
};

export default useConviteForm;
