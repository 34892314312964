/* eslint-disable compat/compat */
type TRegiao = {
  id: number;
  sigla: string;
  nome: string;
};

type TUnidadeFederativa = {
  id: number;
  sigla: string;
  nome: string;
  regiao: TRegiao;
};

type TRegiaoIntermediaria = {
  id: number;
  nome: string;
  UF: TUnidadeFederativa;
};

type TRegiaoImediata = {
  id: number;
  nome: string;
  "regiao-intermediaria": TRegiaoIntermediaria;
};

export type TMunicipio = {
  id: number;
  nome: string;
  "regiao-imediata": TRegiaoImediata;
};

const ibgeApi = "https://servicodados.ibge.gov.br";

const fetchIbge = async <T>(url: string) => {
  const response = await fetch(ibgeApi + url);
  const responseJson: unknown = await response.json();
  return responseJson as T;
};

/**
 * Get all municipios from IBGE API
 * @param uf - Unidade Federativa sigla
 * @returns - Promise with all municipios
 */
export async function getMunicipiosByUf(uf: string) {
  return await fetchIbge<TMunicipio[]>(`/api/v1/localidades/estados/${uf}/municipios`);
}
