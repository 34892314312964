/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import { Button, ComboboxData, Flex, Select, Text } from "@mantine/core";
import { IconArrowLeft, IconArrowRight } from "@tabler/icons-react";
import { useEffect, useState } from "react";

type RankingListPaginatorProperties = {
  numberOfItems: number;
  currentPage: number;
  pageSize: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
};

const PAGE_SIZE_OPTIONS: ComboboxData = [
  {
    label: "10 items",
    value: "10"
  },
  {
    label: "25 items",
    value: "25"
  },
  {
    label: "100 items",
    value: "100"
  },
  {
    label: "250 items",
    value: "250"
  }
];

const getNumberOfPages = (numberOfItems: number, pageSize: number) => {
  return numberOfItems === 0 ? 1 : Math.ceil(numberOfItems / pageSize);
};

/**
 * RankingListPaginator
 * @param root0 - properties
 * @param root0.numberOfItems - total pages
 * @param root0.currentPage - current page
 * @param root0.pageSize - page size
 * @param root0.onPageChange - on page change
 * @param root0.onPageSizeChange - on page size change
 * @returns JSX.Element
 */
export function RankingListPaginator({
  numberOfItems,
  currentPage,
  pageSize,
  onPageChange,
  onPageSizeChange
}: RankingListPaginatorProperties) {
  const [numberOfPages, setNumberOfPages] = useState(1);
  const isFirstPage = currentPage === 0;
  const isLastPage = currentPage + 1 === numberOfPages;
  const pageSizeValue = String(pageSize);

  const handlePageChange = (newPage: number) => () => {
    if (newPage < 0 || newPage >= numberOfPages) {
      return;
    }
    onPageChange(newPage);
  };

  useEffect(() => {
    setNumberOfPages(getNumberOfPages(numberOfItems, pageSize));
  }, [numberOfItems, pageSize]);

  const handlePageSizeChange = (value: string) => {
    const newPageSize = Number.parseInt(value, 10);
    setNumberOfPages(getNumberOfPages(numberOfItems, newPageSize));
    onPageSizeChange(newPageSize);
  };

  return (
    <Flex p="sm" justify="end" align="center" gap="sm">
      <Button onClick={handlePageChange(currentPage - 1)} disabled={isFirstPage} variant="light">
        <IconArrowLeft />
      </Button>

      <Text size="14px" m={0}>
        {currentPage + 1} de {numberOfPages}
      </Text>

      <Button onClick={handlePageChange(currentPage + 1)} disabled={isLastPage} variant="light">
        <IconArrowRight />
      </Button>

      <Select onChange={handlePageSizeChange} defaultValue={pageSizeValue} w={128} data={PAGE_SIZE_OPTIONS} />
    </Flex>
  );
}
