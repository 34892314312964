import ProjetoExternoItem from "@/modules/projeto-externo/components/projeto-externo-item";

// prettier-ignore
const ProjetosExternoPage = () => {
  return (
    <ProjetoExternoItem />
  );
};

ProjetosExternoPage.displayName = "ProjetosExternoPage";

export default ProjetosExternoPage;
