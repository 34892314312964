import { MultiSelectProps, MultiSelect, Loader, Avatar, Group, Text } from "@mantine/core";
import { getCurrentUser } from "@/services/auth";
import useGetActiveColaboradores from "../../hooks/useGetActiveColaboradores";
// prettier-ignore
const ColaboradorMultiSelector = (properties: MultiSelectProps) => {
  const uid = getCurrentUser()?.uid;
  const { data, isLoading, error } = useGetActiveColaboradores({
    select(dataSnapshot) {
      if (dataSnapshot.empty) {
        return [];
      }

      return dataSnapshot.docs.map((document_) => {
        const colaborador = document_.data();

        return {
          value: colaborador.refPath,
          label: colaborador.nome,
          image: colaborador.image
        };
      });
    }
  });

  // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
  const renderMultiSelectOption: MultiSelectProps["renderOption"] = ({ option }) => {
    const newData = data && data?.find((item) => item.value === option.value);
    return <Group gap="sm">
      <Avatar src={newData && newData?.image && newData.image?.length > 0 ? newData?.image[0]?.path : ""} size={36} radius="xl" />
      <div>
        <Text size="sm">{option?.label}</Text>
      </div>
    </Group>
  };

  return (
    <MultiSelect
      data={data?.filter(item => item?.value?.split("/")[3] !== uid)}
      renderOption={renderMultiSelectOption}
      leftSection={isLoading ? <Loader color="blue" size="1rem" /> : undefined}
      disabled={isLoading}
      error={error?.message}
      {...properties}
    />
  );
};

export default ColaboradorMultiSelector;
