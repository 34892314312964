import { Anchor, Avatar, Badge, Card, Checkbox, Flex, Grid, Group, Image, Space, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import useGetQuestionariosRespondidos from "@/modules/analisar-resposta/components/ranking-item/useGetQuestionariosRespondidos";
import useGetEmpresaById from "@/modules/analisar-resposta/components/ranking-list/useGetEmpresas";
import useGetQuestionarios from "@/modules/questionario-de-inovacao/components/projeto-item/useGetQuestionarios";
import useUserStore from "@/modules/users/store";
import NotaMedia from "./NotaMedia";
import useGetQuestionariosRespondidosEmpresa from "./useGetQuestionariosRespondidos";
import getStatusIdEmpresa from "./useGetStatusIdEmpresa";
import { NotaEmpresa } from "../ranking-list/RankingList";

interface Fase {
  id: string;
  cor: string;
  titulo: string;
}

interface StatusItem {
  statusItem: TStatus;
}

const message = "Ocorreu um erro ao carregar os dados:";

const MAGIC_NUMBER = 1500;

const onChange = (
  data: TEmpresa | null | undefined,
  changeSelects: (data: TEmpresa | null | undefined, statusItem: TStatus | null | undefined) => void,
  statusItem: TStatus | undefined
) => {
  return () => {
    changeSelects(data, statusItem);
  };
};

// eslint-disable-next-line complexity
const RankingItem = ({
  selects,
  empresa,
  changeSelects,
  projeto,
  posicao,
  reload
}: {
  selects: TEmpresa[];
  empresa: NotaEmpresa;
  changeSelects: (data: TEmpresa | null | undefined, statusItem: TStatus | null | undefined) => void;
  projeto: TProjeto | null | undefined;
  posicao: number;
  reload: boolean;
}) => {
  const { id } = useParams<{ id: string }>();
  const projetoSelect = useUserStore((state) => state.activeProjeto);
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const { data } = useGetEmpresaById(empresa.empresaId);
  const [statusArray, setStatusArray] = useState<TStatus[]>([]);
  // const { data: statusArray } = useGetStatusIdEmpresa({ idEmpresaItem: empresa.empresaId });
  const statusItem = statusArray && statusArray[0];
  const fases = projeto && projeto.fases;
  const selectFase = (fases && (fases?.find((item: Fase) => item?.id === statusItem?.status) as Fase)) || false;
  const { data: questionarios } = useGetQuestionarios({ id });
  const { data: respondidos } = useGetQuestionariosRespondidos(questionarios?.map((item) => item?.id));
  const { data: respondidoEmpresa } = useGetQuestionariosRespondidosEmpresa(
    questionarios?.map((item) => item?.id),
    empresa.empresaId || ""
  );
  const formatEmpresa = respondidoEmpresa || [];
  const verifyId = projetoSelect || id;
  const isSmMarches = useMediaQuery("(max-width: 48em)");

  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadData();
      } catch (error) {
        console.error(message, error);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, [id, activeEmpresaId, empresa.empresaId, reload, verifyId]);

  const loadData = async () => {
    const arrayStatus = await getStatusIdEmpresa({
      idEmpresaItem: empresa.empresaId,
      id: id || "",
      activeEmpresaId: activeEmpresaId || ""
    });
    setStatusArray(arrayStatus);
  };

  const filtrados =
    respondidos?.filter((item) => item.idEmpresa === data?.id && item.idEmpresa !== activeEmpresaId) || [];
  const combinados = [...filtrados, ...formatEmpresa];
  const idsQuestionarios = combinados.map((item) => item.idQuestionario);
  const idsUnicos = [...new Set(idsQuestionarios)];
  const resultado = idsUnicos.length || 0;

  return (
    <>
      <Card withBorder shadow="md" style={{ flex: 1, width: isSmMarches ? MAGIC_NUMBER : "100%" }}>
        <Grid align="center">
          <Grid.Col span={1}>
            <Group>
              <Checkbox
                onChange={onChange(data, changeSelects, statusItem)}
                checked={selects.some((item: TEmpresa & StatusItem) => item?.id === empresa?.empresaId)}
              />
              <Text fz="md" fw={700}>
                #{posicao + 1}º
              </Text>
            </Group>
          </Grid.Col>
          <Grid.Col span={3}>
            <Group>
              {data && data.image && data.image.length > 0 ? (
                <>
                  <Avatar color="cyan" radius="xl">
                    <Image radius="md" src={data?.image[0]?.path as string} />
                  </Avatar>
                </>
              ) : (
                <Avatar color="cyan" radius="xl">
                  {data?.nomeFantasia.charAt(0)}
                </Avatar>
              )}
              <Anchor component={NavLink} to={`/diagnosticoDaEmpresa/${data?.id}/${verifyId}`} color={"#000"}>
                <Text fz="md">{data?.nomeFantasia}</Text>
              </Anchor>
            </Group>
          </Grid.Col>
          <Grid.Col span={2}>
            <Group align={"center"} justify={"center"}>
              <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
                <Text fw={300} fz="xs">
                  Status
                </Text>
                {selectFase && selectFase.id ? (
                  <Badge color={selectFase.cor} radius="sm">
                    <Text size="xs" tt="capitalize">
                      {selectFase && selectFase.titulo}
                    </Text>
                  </Badge>
                ) : (
                  <Badge color="blue" radius="sm">
                    <Text size="xs" tt="capitalize">
                      Inscrição
                    </Text>
                  </Badge>
                )}
              </Flex>
            </Group>
          </Grid.Col>
          <Grid.Col span={2}>
            <Group align={"center"} justify={"center"}>
              <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
                <Text fw={300} fz="xs">
                  Questionários respondidos
                </Text>
                <Text fw={500} fz="md">
                  {resultado}
                </Text>
              </Flex>
            </Group>
          </Grid.Col>
          {/* <Grid.Col span={2}>
            <NotaMedia media={empresa?.media} />
          </Grid.Col> */}
          <Grid.Col span={2}>
            <NotaMedia media={empresa?.mediaEmpresa} />
          </Grid.Col>
          <Grid.Col span={2}>
            <NotaMedia media={empresa?.peso} />
          </Grid.Col>
        </Grid>
      </Card>
      <Space h="md" />
    </>
  );
};

export default RankingItem;
