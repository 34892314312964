import { Text, Center, Stack, Alert } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { ColumnDef, Table as ReactTable } from "@tanstack/react-table";
import { forwardRef, useImperativeHandle } from "react";
import NoData from "@/components/no-data";
import ResetTableButton from "@/components/table/ResetTableButton";
import Table, { THandler } from "@/components/table/Table";
import useQueryTable from "@/components/table/useQueryTable";
import useUserStore from "@/modules/users/store";
import tableColumns from "./Columns";
import { DEFAULT_PAGINATION, DEFAULT_SORTING, DESAFIOS_COLLECTION_KEY, getDesafios } from "../../firestore";

const DEFAULT_TABLE_KEY = [DESAFIOS_COLLECTION_KEY];

type DesafiosTableProperties = {
  columns?: ColumnDef<TDesafio>[];
};
// prettier-ignore
const DesafiosTable = forwardRef<THandler<TDesafio>, DesafiosTableProperties>(
  ({ columns = tableColumns }, reference) => {
    const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

    const { table, data, isLoading, error, filters, refetch } = useQueryTable<TDesafio>({
      queryKey: [DESAFIOS_COLLECTION_KEY],
      enabled: !!activeEmpresaId,
      subjectType: "TDesafio",
      columns,
      initialState: {
        columnFilters: [],
        globalFilter: null,
        pagination: DEFAULT_PAGINATION,
        sorting: DEFAULT_SORTING
      },
      queryFunction: ({ filters, pagination, globalFilters, paginationCursors }) => {
        if (!activeEmpresaId) {
          throw new Error("Empresa não selecionada");
        }
        const newFilter = filters?.concat([{ id: "publishedAt", value: ["!=", null] }]);

        return getDesafios(activeEmpresaId, {
          sorting: [],
          filters: newFilter,
          globalFilters,
          pagination,
          paginationCursors
        });
      }
    });

    useImperativeHandle<THandler<TDesafio>, THandler<TDesafio>>(
      reference,
      () => {
        return {
          table,
          data,
          isLoading,
          error,
          filters
        };
      },
      [filters, data, error, isLoading, table]
    );

    /**
     * Handles the close of the error alert.
     */
    function handleErrorClose() {
      void refetch();
    }

    if (error) {
      return (
        <Alert
          icon={<IconAlertCircle size="1rem" />}
          title="Erro ao buscar desafios"
          color="red"
          withCloseButton
          onClose={handleErrorClose}
        >
          {error.message}
        </Alert>
      );
    }

    const isEmpty = !isLoading && data?.entries.length === 0;
    data && data.entries && data.entries.sort((a, b) => (b?.rankingByMoedas?.totalMoedas || 0) - (a?.rankingByMoedas?.totalMoedas || 0));

    return (
      <>
        <Table table={table} isLoading={isLoading} />
        {isEmpty && !isLoading && (
          <Center m="lg">
            <NoData m="md">
              <Stack gap="xs">
                <Text c="dimmed">Nenhum desafio encontrado!</Text>
                <ResetTableButton table={table as ReactTable<unknown>} queryKey={DEFAULT_TABLE_KEY}>
                  Limpar filtros
                </ResetTableButton>
              </Stack>
            </NoData>
          </Center>
        )}
      </>
    );
  }
);

DesafiosTable.displayName = "DesafiosTable";

export default DesafiosTable;
