import { Avatar, Badge, Box, Button, Card, Collapse, Group, Skeleton, Space, Stack, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import RankingTrendIcon from "@/components/ranking-trend-icon";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import useGetColaboradorByReferencePath from "@/modules/colaboradores/hooks/useGetColaboradorByReferencePath";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import { extractInitialsFromText } from "@/utils/text";
import AdicionarBonusButton from "./AdicionarBonusButton";
import HistoricoPontosTable, { useGetHistoricoPontosPerColaborador } from "./HistoricoPontosTable";
import { dataAutorizacoes } from "../empresa-page/EmpresaPage";
// prettier-ignore
const RankingDeColaboradorCard = ({ ranking, year }: { ranking: TRankingAnoPerColaborador; year: number }) => {
  const [opened, { toggle }] = useDisclosure(false);
  const { isLoading, data: colaborador } = useGetColaboradorByReferencePath(
    typeof ranking.colaboradorReference == "string"
      ? ranking.colaboradorReference
      : ranking.colaboradorReference.refPath
  );
  const { data: historicoPontos } = useGetHistoricoPontosPerColaborador(colaborador, year);
  const activeEmpresaId = useUserStore((store) => store.activeEmpresa);
  const activeAutorizacao = useUserStore((store) => store.autorizacoes);
  const uid = getCurrentUser()?.uid;
  const { data: userColaborador } = useGetColaboradorById(activeEmpresaId?.id || "", uid || "");
  const autorizacao = dataAutorizacoes.find(item => item.label === userColaborador?.role);
  const formatAutorizacoes = activeAutorizacao?.filter(item => item.tipo === autorizacao?.value);

  const soma = processarPontos(historicoPontos)

  return (
    <Card withBorder padding="md">
      <Card.Section inheritPadding py="xs" pb={0}>
        <Group>
          <Group flex='1 0 0'>
            <Badge size="xl" circle bg="yellow" style={{fontSize: 14}}>
              {ranking.rankingByMoedas?.ranking}
            </Badge>
            <RankingTrendIcon ranking={ranking.rankingByMoedas} />
          </Group>
          {isLoading && <Skeleton width={100} height={20} />}
          {colaborador && (
            <Group flex='1 0 0' gap="xs">
              <Avatar src="image.png" key={colaborador.id} alt={colaborador.nome || colaborador.email}>
                {extractInitialsFromText(colaborador.nome || colaborador.email)}
              </Avatar>
              <Text>{colaborador.nome}</Text>
            </Group>
          )}
          <Stack flex='1 0 0' gap={0}>
            <Badge color="gray" radius="sm">
              Pontos
            </Badge>
            <Text>{soma}</Text>
          </Stack>
          {colaborador && formatAutorizacoes?.find(item => item.tipoAutorizacao === "18")?.editar && <Box flex="1 0 0"><AdicionarBonusButton colaborador={colaborador} /></Box>}
          <Button
            variant="transparent"
            rightSection={opened ? <IconChevronUp /> : <IconChevronDown />}
            onClick={toggle}
          >
            Ver {opened ? "menos" : "mais"}
          </Button>
        </Group>
      </Card.Section>
      <Collapse in={opened}>
        <Card.Section pt="xs">
          {opened && colaborador &&
            <>
              <Space h={"md"} />
              <HistoricoPontosTable colaborador={colaborador} year={year} />
            </>

          }
        </Card.Section>
      </Collapse>
    </Card>
  );
};

export default RankingDeColaboradorCard;
// prettier-ignore
const processarPontos = (array: THistoricoPontosPerColaborador[] | undefined) => {
  // Separar os itens positivos e negativos
  if (array && array.length > 0) {

    const positivos = array.filter(item => item.type !== "REPROVADA" && item.type !== "CANCELADA");
    const negativos = array.filter(item => item.type === "REPROVADA" || item.type === "CANCELADA")

    // Somar os pontos positivos e negativos
    const somaPositivos = positivos.reduce((total, item) => {
      return total + item.pontos;
    }, 0);

    const somaNegativos = negativos.reduce((total, item) => {
      return total - item.pontos;
    }, 0);
    // Combinar os resultados
    return somaPositivos + somaNegativos;
  }
  return 0
};
