import { Flex, Text } from "@mantine/core";
import MarcoActionButtons from "./MarcoActionButtons";
import MarcoItem from "./MarcoItem";

interface MarcosListProperties {
  marcos: string[];
  title: string;
  ideia: TIdeia;
  allowEdit?: boolean;
  onMarcoRemoved?: (path: string) => void;
}

const MarcosList = ({ title, marcos, ideia, allowEdit = false, onMarcoRemoved }: MarcosListProperties) => {
  const hasMarcosContent = marcos.length > 0;
  if (!hasMarcosContent) {
    return null;
  }

  return (
    <Flex direction="column" gap="sm">
      <Text fw="bold">{title}</Text>
      {marcos.map((marco) => (
        <MarcoItem key={marco} marcoPath={marco}>
          {allowEdit && <MarcoActionButtons marcoPath={marco} ideia={ideia} onMarcoRemoved={onMarcoRemoved} />}
        </MarcoItem>
      ))}
    </Flex>
  );
};

export default MarcosList;
