import { Button, Drawer, Group, ScrollArea, Stack, Text, TextInput } from "@mantine/core";
import { TransformedValues, useForm, yupResolver } from "@mantine/form";
import { IconRestore } from "@tabler/icons-react";
import { ColumnFiltersState, Table } from "@tanstack/react-table";
import { ComponentProps, MouseEvent } from "react";
import { ObjectSchema, SchemaDescription } from "yup";
import schema from "@/schema";
import { extractTextInputPropertiesFromFormSchema } from "@/utils/form";

type Filters = Partial<{
  nomeFantasia: TEmpresa["nomeFantasia"];
  razaoSocial: TEmpresa["razaoSocial"];
  cnpj: TEmpresa["cnpj"];
}>;
type FilterKeys = keyof Filters;

const filterSchema: ObjectSchema<Filters, unknown, Filters> = schema
  .object({
    nomeFantasia: schema.string().optional().trim().label("Nome Fantasia"),
    razaoSocial: schema.string().optional().trim().label("Razão Social"),
    cnpj: schema.string().optional().trim().label("CNPJ")
  })
  .noUnknown()
  .label("Filtros de Empresas");

const TableFilters = ({
  table,
  filters,
  onFilterApplied,
  ...drawerProperties
}: { table: Table<TEmpresa>; filters: ColumnFiltersState; onFilterApplied: () => void } & ComponentProps<
  typeof Drawer
>) => {
  const filtersObject: Filters = filterSchema.getDefault();
  for (const filter of filters || []) {
    filtersObject[filter.id as unknown as FilterKeys] = filter.value as never;
  }

  const filterDescriptor = filterSchema.describe();

  const form = useForm<Filters>({
    initialValues: filtersObject,
    transformValues: (values) => filterSchema.cast(values),
    validate: yupResolver(filterSchema)
  });

  type TFormTransformedValues = TransformedValues<typeof form>;
  /**
   * Handle form submit
   * @param values - Form values
   */
  function onSubmit(values: TFormTransformedValues) {
    table.resetColumnFilters();
    for (const [key, value] of Object.entries(values)) {
      const column = table.getColumn(key);
      if (column && value) {
        // eslint-disable-next-line security/detect-object-injection
        const fieldMeta = (filterDescriptor.fields[key] as SchemaDescription).meta as { operator?: string } | undefined;
        column.setFilterValue([fieldMeta?.operator ?? "==", value]);
      }
    }
    onFilterApplied();
  }

  /**
   * Handle restaurar button click
   * @param event - Click event
   */
  function handleRestaurarClick(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    table.resetColumnFilters();
    form.reset();
    onFilterApplied();
  }

  return (
    <Drawer
      scrollAreaComponent={ScrollArea.Autosize}
      size="sm"
      title={<Text fw="bold">Filtrar Empresas</Text>}
      {...drawerProperties}
    >
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack gap="sm">
          <TextInput
            type="text"
            data-autofocus
            {...extractTextInputPropertiesFromFormSchema(filterDescriptor, "nomeFantasia")}
            {...form.getInputProps("nomeFantasia")}
          />
          <TextInput
            type="text"
            data-autofocus
            {...extractTextInputPropertiesFromFormSchema(filterDescriptor, "razaoSocial")}
            {...form.getInputProps("razaoSocial")}
          />
          <TextInput
            type="text"
            data-autofocus
            {...extractTextInputPropertiesFromFormSchema(filterDescriptor, "cnpj")}
            {...form.getInputProps("cnpj")}
          />
        </Stack>

        <Group justify="space-between" mt="lg">
          <Button
            type="button"
            variant="subtle"
            color="orange"
            size="compact-sm"
            onClick={handleRestaurarClick}
            leftSection={<IconRestore size="1rem" />}
          >
            Restaurar
          </Button>
          <Button type="submit" variant="light">
            Aplicar
          </Button>
        </Group>
      </form>
    </Drawer>
  );
};

export default TableFilters;
