import { Box, Group, LoadingOverlay, Text } from "@mantine/core";
import useQueryQuestionario from "./useQueryQuestionario";

const CategoriaValueRow = ({ properties }: { properties: string }) => {
  const { data: questionarios, isFetching } = useQueryQuestionario(properties);

  if (isFetching) {
    return (
      <Box pos="relative">
        <LoadingOverlay visible={isFetching} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
      </Box>
    );
  }
  return (
    <Group justify="left" gap={2}>
      <Text>{questionarios?.nome}</Text>
    </Group>
  );
};
export default CategoriaValueRow;
