import { subject } from "@casl/ability";
import { FirestoreDataConverter, collection, doc, getDocs, limit, orderBy, query } from "firebase/firestore";
import { getEmpresaDocumentReference } from "../empresas/firestore";

export const RANKING_ANO_PER_COLABORADOR_KEY = "rankingAnoPerColaborador" as const;

type TRankingAnoPerColaboradorDocumentReference = TFirestoreDocument<TRankingAnoPerColaborador, "colaboradorReference">;

const colaboradoresConverter: FirestoreDataConverter<TRankingAnoPerColaborador> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const { createdAt, updatedAt, colaboradorReference, ...document } =
      snap.data() as TRankingAnoPerColaboradorDocumentReference;

    const data: TRankingAnoPerColaborador = {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      refPath: snap.ref.path,
      colaboradorReference: colaboradorReference.path
    };
    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }

    return subject("TRankingAnoPerColaborador", data);
  }
};

/**
 * Get historicoPontosPerColaborador collection reference
 * @param empresaId - Empresa id where the historicoPontosPerColaborador is located
 * @returns - historicoPontosPerColaborador collection reference
 */
function getRankingAnoPerColaboradorCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocument = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocument, RANKING_ANO_PER_COLABORADOR_KEY);
}

const DEFAULT_LIMIT = 10;
/**
 * Get rankingAnoPerColaborador
 * @param empresaId - Empresa id where the rankingAnoPerColaborador is located
 * @param size - The size of the rankingAnoPerColaborador documents to get
 * @returns - RankingAnoPerColaborador documents
 */
export function getRankingAnoPerColaborador(empresaId: TEmpresa["id"], size = DEFAULT_LIMIT) {
  const rankingAnoPerColaboradorCollectionReference = getRankingAnoPerColaboradorCollectionReference(empresaId);
  const q = query(rankingAnoPerColaboradorCollectionReference, limit(size));
  return getDocs(q);
}

/**
 * Get colaboradores collection reference from the year
 * @param empresaId - Empresa id where the rankingAnoPerColaborador is located
 * @param year - The year to get the colaboradores collection reference
 * @returns - Colaboradores collection reference
 */
function getColaboradoresRankingAnoPerColaboradorCollectionReference(empresaId: TEmpresa["id"], year: number) {
  const rankingColaboradoresCollectionReference = getRankingAnoPerColaboradorCollectionReference(empresaId);
  const yearDocumentReference = doc(rankingColaboradoresCollectionReference, year.toString());
  return collection(yearDocumentReference, "colaboradores");
}

/**
 * Get rankingAnoPerColaborador documents snapshot from the year
 * @param empresaId - Empresa id where the rankingAnoPerColaborador is located
 * @param year - The year to get the rankingAnoPerColaborador document snapshot
 * @returns - RankingAnoPerColaborador documents snapshot
 */
export function getRankingAnoPerColaboradorDocumentsSnapshot(empresaId: TEmpresa["id"], year: number) {
  const colaboradoresCollectionReference = getColaboradoresRankingAnoPerColaboradorCollectionReference(empresaId, year);
  const q = query(colaboradoresCollectionReference, orderBy("rankingByMoedas.ranking")).withConverter(
    colaboradoresConverter
  );
  return getDocs(q);
}
