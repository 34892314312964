import { Flex, Group, Text } from "@mantine/core";
import { DOIS } from "@/utils/constants";

const NotaMedia = ({ media }: { media: number }) => {
  return (
    <>
      <Group align={"center"} justify={"center"}>
        <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
          <Text fw={300} fz="xs">
            Nota média
          </Text>
          <Text fw={500} fz="md">
            {media.toFixed(DOIS)}
          </Text>
        </Flex>
      </Group>
    </>
  );
};

export default NotaMedia;
