import { Button, Group } from "@mantine/core";
import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import useQueryRespostasEmpresa from "../cards/useGetRespostasEmpresa";
import useGetPergunta from "../estrategia-list/useGetPerguntas";
import useQueryRespostas from "../estrategia-list/useGetRespostas";

// interface Fase {
//   id: string;
//   titulo: string;
//   cor: string;
// }

interface Objeto {
  resposta: RespostaItem | RespostaItem[] | number | string;
}
/* eslint-disable @typescript-eslint/no-floating-promises */
// prettier-ignore
const ExportExcel = ({ grupos }: { grupos: TGruposDiagnostico[] }) => {
  const { id } = useParams<{ id: string }>();
  const { data: perguntas } = useGetPergunta({ ids: grupos.map(item => item.id) });
  const { data: respostas } = useQueryRespostas({ id: id || "" });
  const { data: respostasEmpresa } = useQueryRespostasEmpresa({ id: id || "" });

  const formatPergunta = perguntas?.map((item, index) => {
    const formatResposta = respostas && [...respostasEmpresa || [], ...respostas].map(row => {
      return row.perguntas.find((p: TRespostaPergunta) => p?.id === item?.id);
    }) || [];

    const media = calcularMedia(formatResposta as Objeto[]);
    return [
      index,
      item.nome,
      item.descricao,
      grupos && grupos.find(row => row.id === item.grupo)?.id ? grupos?.find(row => row?.id === item?.grupo)?.nome : "",
      typeof media === "string" ? 0 : media,
    ]
  })

  const handleExport = useCallback(() => {
    onExport(formatPergunta);
  }, []);

  return <Group justify={"flex-end"}>
    <Button variant="filled" onClick={handleExport}>Exportar</Button>
  </Group>

};

export default ExportExcel;

/* eslint-disable sonarjs/cognitive-complexity */
// prettier-ignore
const onExport = async (csvData: (string | number | undefined)[][] | undefined) => {

  // Se csvData for undefined, interrompa a execução
  if (!csvData) return;

  const columns = ["Ordem", "Pergunta", "Descrição", "Grupo", "Media"];

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Atividades');

  const headerRow = worksheet.addRow(columns);

  headerRow.eachCell((cell) => {
    cell.font = { bold: true };
  });

  if (Array.isArray(csvData)) {
    for (const rowData of csvData) {
      worksheet.addRow(Object.values(rowData)); // Removi a assertiva de tipo desnecessária
    }
  }

  try { // Adicionei um bloco try para capturar possíveis erros
    const buffer = await workbook.xlsx.writeBuffer(); // Aguardo a resolução da Promise
    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      `relatorio.xlsx`
    );
  } catch (error) { // Tratamento de erro
    console.error("Erro ao escrever buffer:", error);
  }
}

interface RespostaItem {
  valor: number | string;
}

const STRING_TRIM_LENGTH = -2;

/* eslint-disable sonarjs/cognitive-complexity */
/**
 * Calcula a média dos valores numéricos em um array de objetos.
 * @param array - Array de objetos contendo valores a serem incluídos no cálculo da média.
 * @returns A média dos valores numéricos no array, ou uma string contendo valores não numéricos se nenhum valor numérico for encontrado.
 */
function calcularMedia(array: Objeto[]) {
  let total = 0;
  let quantidade = 0;
  let respostasString = "";
  for (const objeto of array) {
    let valor;
    if (Array.isArray(objeto?.resposta)) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      for (const item of objeto?.resposta) {
        if (typeof item.valor === "number") {
          total += Number.parseFloat(item.valor.toString());
          quantidade++;
        } else if (typeof item.valor === "string") {
          respostasString += item.valor + ", ";
        }
      }
    } else if (typeof objeto?.resposta === "object" && objeto?.resposta !== null && objeto?.resposta !== undefined) {
      if (typeof objeto.resposta.valor === "number") {
        valor = Number.parseFloat(objeto?.resposta?.valor.toString());
        if (!Number.isNaN(valor)) {
          total += valor;
          quantidade++;
        }
      } else if (typeof objeto?.resposta?.valor === "string") {
        respostasString += objeto.resposta.valor + ", ";
      }
    } else if (typeof objeto?.resposta === "number") {
      valor = Number.parseFloat(objeto.resposta.toString());
      if (!Number.isNaN(valor)) {
        total += valor;
        quantidade++;
      }
    } else if (typeof objeto?.resposta === "string") {
      respostasString += objeto?.resposta + ", ";
    }
  }

  return quantidade === 0 ? respostasString.slice(0, STRING_TRIM_LENGTH) : total / quantidade;
}
