import { Box, Group, Loader, Stack, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useGetConvites from "@/components/app-container/useGetConvites";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import CampanhaDeInovacao from "./CampanhaDeInovacao";
import CronogramasDeDesafiosCard from "./CronogramasDeDesafios";
import ExportIdeias from "./ExportIdeias";
import TopDesafiosMaisVotadasCard from "./TopDesafiosMaisVotadasCard";
import TopIdeiasMaisVotadasCard from "./TopIdeiasMaisVotadasCard";
import UltimasIdeiasAtualizadasCard from "./UltimasIdeiasAtualizadasCard";
import UltimosDesafiosAtualizadasCard from "./UltimosDesafiosAtualizadasCard";

// prettier-ignore
const GestaoInovacaoPage = () => {
  const { data, isLoading } = useGetConvites();
  const empresa = useUserStore((state) => state.activeEmpresa);
  const uid = getCurrentUser()?.uid;
  const { data: colaborador } = useGetColaboradorById(empresa?.id || "", uid || "")
  const navigate = useNavigate();
  const isSmMarches = useMediaQuery("(max-width: 48em)");

  useEffect(() => {
    if (data && data.length > 0 && colaborador?.role !== "ADMIN") {
      navigate("/diagnosticoEmpresa");
    }
  }, [data?.length])


  if (isLoading && colaborador?.role !== "ADMIN") {
    return (
      <Box pos="relative" style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}>
        <Loader />
      </Box>
    );
  }

  if (colaborador?.gestao === false && colaborador?.role !== "ADMIN") {
    return (
      <Box pos="relative" style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}>
        <Text fw={"bold"}>Você não possui acesso a este módulo. Navegue para aquele ao qual você possui acesso.</Text>
      </Box>
    );
  }
  return (
    <Stack gap="md">
      <ExportIdeias />
      <Group w={"100%"} justify="space-between" style={{ flexWrap: isSmMarches ? "wrap" : "nowrap" }}>
        <TopIdeiasMaisVotadasCard />
        <CampanhaDeInovacao />
      </Group>
      <UltimasIdeiasAtualizadasCard />
      <Group w={"100%"} justify="space-between" style={{ flexWrap: isSmMarches ? "wrap" : "nowrap" }}>
        <TopDesafiosMaisVotadasCard />
        <CronogramasDeDesafiosCard />
      </Group>
      <UltimosDesafiosAtualizadasCard />
    </Stack>
  );
};

export default GestaoInovacaoPage;
