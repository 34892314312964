import { ActionIcon, Menu } from "@mantine/core";
import { IconDotsVertical, IconEdit, IconTrash } from "@tabler/icons-react";
import EditItemButton from "@/components/edit-item-button";
import { useGetAuthorizations } from "@/hooks/useGetAuthorizations";
import regiaoSchema from "@/modules/regiao/schema/regiao-schema";
import RemoveRegiaoButton from "./remove-regiao-button/RemoveRegiaoButton";

type Properties = {
  regiao: TRegiao;
};

const ContextMenuRow = ({ regiao }: Properties) => {
  const { update, remove } = useGetAuthorizations("8");

  if (!update && !remove) {
    return <></>;
  }

  return (
    <Menu shadow="md" width={200} position="left" withArrow arrowPosition="center">
      <Menu.Target>
        <ActionIcon variant="transparent" aria-label="Menu" color="gray">
          <IconDotsVertical />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {update && (
          <Menu.Item
            leftSection={<IconEdit size={14} />}
            component={EditItemButton}
            modal="regiao-form-modal"
            schema={regiaoSchema}
            item={regiao}
          >
            Editar
          </Menu.Item>
        )}

        {remove && (
          <>
            <Menu.Divider />
            <Menu.Item
              color="red"
              leftSection={<IconTrash size={14} />}
              component={RemoveRegiaoButton}
              regiao={regiao}
              projetoId={regiao.projetoId}
            >
              Remover
            </Menu.Item>
          </>
        )}
      </Menu.Dropdown>
    </Menu>
  );
};

export default ContextMenuRow;
