import { subject } from "@casl/ability";
import { useInfiniteQuery } from "@tanstack/react-query";
import { PaginationState, SortingState } from "@tanstack/react-table";
import { DocumentData, DocumentSnapshot, FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { getComentarios } from "../../firestore";

export const QUERY_KEY: string = "ideia-comentarios-list" as const;

/**
 * Get the comentários
 * @param ideia - The ideia to get the comentários
 * @param root0 - Pagination options
 * @param root0.sortBy - Sort by field
 * @returns - Query results for get comentários
 */
function useGetComentarios(ideia: TIdeia, { sortBy }: { sortBy: string }) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  const [field, order] = sortBy.split(",");
  const DEFAULT_QUERY_OPTIONS: TPaginatedQueryOptions<TIdeiaComentario> = {
    pagination: { pageIndex: 1, pageSize: 3 },
    sorting: [{ id: field || sortBy, desc: order === "desc" }],
    filters: [{ id: "respostaAoComentarioReference", value: ["==", null] }]
  };

  return useInfiniteQuery<TPaginatedData<TIdeiaComentario>, FirestoreError>({
    queryKey: [activeEmpresaId, "ideia", ideia.id, QUERY_KEY, { sortBy }, DEFAULT_QUERY_OPTIONS],
    initialPageParam: 0,
    meta: {
      displayNotification: true
    },
    getNextPageParam(_lastPage, pages) {
      if (_lastPage.lastDocument === undefined) {
        return undefined;
      }
      const paginationCursors = new Map<
        number,
        [
          DocumentSnapshot<TIdeiaComentario, DocumentData> | undefined,
          DocumentSnapshot<TIdeiaComentario, DocumentData> | undefined
        ]
      >([]);

      for (const [index, page] of pages.entries()) {
        paginationCursors.set(index, [page.firstDocument, page.lastDocument]);
      }

      const [field, order] = sortBy.split(",");
      const sorting: SortingState = [{ id: field || sortBy, desc: order === "desc" }];
      const pagination: PaginationState = { pageIndex: pages.length, pageSize: 3 };

      const options: TPaginatedQueryOptions<TIdeiaComentario> = {
        pagination,
        paginationCursors,
        sorting
      };

      return options;
    },

    async queryFn({ pageParam: pageParameter }) {
      const queryOptions = pageParameter as TPaginatedQueryOptions<TIdeiaComentario>;
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      const comentariosResponse = await getComentarios(
        activeEmpresaId,
        ideia.id,
        queryOptions || DEFAULT_QUERY_OPTIONS
      );

      if (comentariosResponse.empty) {
        return {
          entries: [],
          firstDocument: undefined,
          lastDocument: undefined,
          total: 0
        };
      }
      const comentarios = comentariosResponse.docs.map((comentarioData) =>
        subject("TIdeiaComentario", comentarioData.data())
      );

      const result: TPaginatedData<TIdeiaComentario> = {
        entries: comentarios,
        firstDocument: comentariosResponse.docs[0],
        lastDocument: comentariosResponse.docs.at(-1),
        total: comentarios.length
      };

      return result;
    }
  });
}

export default useGetComentarios;
