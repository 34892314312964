import { TransformedValues, useForm, yupResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { Asserts } from "yup";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { addCategoriaDeDesafio, QUERY_KEY, TCategoriaDeDesafioForm, updatedCategoriaDeDesafio } from "../../firestore";
import categoriaDeDesafioSchema from "../../schema/categoria-de-desafio-schema";

type FormInputs = Asserts<typeof categoriaDeDesafioSchema>;
type TUpdateResponse = Awaited<ReturnType<typeof updatedCategoriaDeDesafio>>;
type TAddResponse = Awaited<ReturnType<typeof addCategoriaDeDesafio>>;

const useCategoriaDeDesafioForm = (
  data: TCategoriaDeDesafioForm & Partial<Pick<TDesafioCategoria, "id" | "refPath">>,
  {
    onSuccess,
    onError
  }: Pick<UseMutationOptions<TUpdateResponse | TAddResponse, FirestoreError, FormInputs>, "onSuccess" | "onError">
  // mutationOptions: TMutationOptions = {}
) => {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);
  const queryClient = useQueryClient();
  const form = useForm<FormInputs>({
    validate: yupResolver(categoriaDeDesafioSchema),
    initialValues: data?.id
      ? (data as TCategoriaDeDesafioForm)
      : (categoriaDeDesafioSchema.getDefault() as TCategoriaDeDesafioForm),
    transformValues: (values) => categoriaDeDesafioSchema.cast(values)
  });
  const { mutate, isPending, reset } = useMutation<
    TUpdateResponse | TAddResponse,
    FirestoreError,
    TCategoriaDeDesafioForm
  >({
    mutationFn(values) {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      return data?.id
        ? updatedCategoriaDeDesafio(activeEmpresaId, data.id, values)
        : addCategoriaDeDesafio(activeEmpresaId, values);
    },
    async onSuccess(item, variables, context) {
      showNotification({
        message: `${data?.id ? "Atualizado" : "Cadastrado"} com sucesso!`,
        color: "green",
        autoClose: 2500
      });
      reset();
      form.reset();
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      if (onSuccess) {
        return onSuccess(item, variables, context);
      }
      return null;
    },
    onError(error, variables, context) {
      captureException(error, true);
      if (onError) {
        onError(error, variables, context);
      }
    }
  });

  type TTransformedValues = TransformedValues<typeof form>;
  /**
   * Handle form submit event
   * @param values - Form values
   */
  function onSubmit(values: TTransformedValues) {
    mutate(values);
  }

  return { ...form, isLoading: isPending, onSubmit: form.onSubmit(onSubmit) };
};

export default useCategoriaDeDesafioForm;
