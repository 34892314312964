import { Box, Button, Card, Group, LoadingOverlay, ScrollArea, Space, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { Timestamp } from "firebase/firestore";
import { MouseEvent, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import useGetConvitesQuestionario from "@/modules/questionario-empresa/hooks/useGetConvitesQuestionario";
import useUserStore from "@/modules/users/store";
import useGetQuestionarioItem from "@/src/routes/responder-questionario-externo-page/hooks/useGetQuestionarioFromUrl";
import ConviteRespostaCard from "../questionario-item/ConviteRespostaCard";

/**
 * Handles the click event on the button.
 * @param id - The id questions.
 * @param idEmpresa - The id company.
 * @param idProjeto - The id project.
 * @param licenciadaId - Id da Licenciada
 * @returns - The return the click.
 */
function handleClick(
  id: string = "",
  idEmpresa: string = "",
  idProjeto: string = "",
  licenciadaId: string | undefined
) {
  return (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    modals.openContextModal({
      size: "xl",
      innerProps: {
        idQuestionario: id,
        idEmpresa,
        idProjeto,
        licenciadaId
      },
      modal: "convidar-colaborador-modal"
    });
  };
}

const QuestionarioDiagnosticoList = () => {
  const { id = "" } = useParams<{ id: string }>();
  const activeLicenciadaId = useUserStore((state) => state.activeLicenciadaId);
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const reloadConvites = useUserStore((state) => state.reloadConvites);
  const { data: convites, isFetching: isFetchingConvites, refetch } = useGetConvitesQuestionario(id);

  const empresaId = useMemo(() => {
    return convites?.at(0)?.idEmpresa;
  }, [convites]);

  useEffect(() => {
    void refetch();
  }, [reloadConvites]);

  const { data: questionario, isFetching: isFetchingQuestionario } = useGetQuestionarioItem(
    empresaId ?? activeEmpresaId ?? "",
    id
  );

  if (isFetchingConvites || isFetchingQuestionario) {
    return (
      <Box pos="relative">
        <LoadingOverlay visible zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
      </Box>
    );
  }

  return (
    <Card withBorder shadow="md" style={{ flex: 1 }}>
      <Group justify={"space-between"}>
        <Text size="xl">Como estão as participações?</Text>

        <Button
          onClick={handleClick(id, empresaId, questionario?.idProjeto, activeLicenciadaId)}
          color="rgba(8, 68, 244, 1)"
          disabled={verificacaoExpirada(questionario)}
        >
          Convidar colaboradores
        </Button>
      </Group>
      <Space h="md" />
      <ScrollArea w="100%">
        {convites?.length ? (
          convites.map((convite) => <ConviteRespostaCard key={convite.id} convite={convite} />)
        ) : (
          <Group align={"center"} justify={"center"}>
            <Space h="md" />
            <Text fw={"bold"}>Nenhum colaborador convidado!</Text>
            <Space h="md" />
          </Group>
        )}
      </ScrollArea>
    </Card>
  );
};

export default QuestionarioDiagnosticoList;

const verificacaoExpirada = (questionario: TQuestionario | null | undefined) => {
  const now = new Date();
  const anoAtuacao =
    typeof questionario?.dataAtuacao === "number"
      ? verificationDate(new Date())
      : verificationDate(questionario?.dataAtuacao);
  const anoVigencia =
    typeof questionario?.dataVigencia === "number"
      ? verificationDate(new Date())
      : verificationDate(questionario?.dataVigencia);

  if (anoVigencia && anoAtuacao) {
    return new Date(anoAtuacao) > now || new Date(anoVigencia) < now;
  }
  return false;
};

const SEGUNDOS_EM_MILISEGUNDOS = 1000;

const verificationDate = (date: Date | Timestamp | undefined): Date | null => {
  let data: number | undefined;

  if (date instanceof Date) {
    data = date.getTime() / SEGUNDOS_EM_MILISEGUNDOS;
  } else if (date && typeof date === "object" && "seconds" in date) {
    data = date.seconds;
  }

  return data ? new Date(data * SEGUNDOS_EM_MILISEGUNDOS) : null;
};
