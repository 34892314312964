import { Card, Group, Text, Button, Flex, Stack, Space, Grid, Badge, ThemeIcon, Anchor } from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { QueryObserverResult } from "@tanstack/react-query";
import React from "react";
import { NavLink } from "react-router-dom";
import JogadaDeInovacaoCard from "./JogadadeInovacaoCard";

const MAGIC_NUMBER = 1000;

// prettier-ignore
const JogadaDeInovacaoItem: React.FC<{
  jogada: TJogadaDeInovacao;
  refetch: () => Promise<QueryObserverResult>;
  defaultExpand?: boolean;
  formatAutorizacoes: TAutorizacao[] | undefined;
}> = ({ jogada, refetch, defaultExpand, formatAutorizacoes }) => {
  const [opened, { toggle }] = useDisclosure(defaultExpand);
  const isSmMarches = useMediaQuery("(max-width: 48em)");

  return (
    <>
      <Card withBorder radius="lg" style={{ flex: 1, width: isSmMarches ? MAGIC_NUMBER : "100%" }}>
        <Grid align="center">
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
              <Text fw={300} fz="xs">
                Titulo da jogada
              </Text>
              <Text fw={500} fz="md">
                {jogada.nome}
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <Badge
              radius="md"
              size="xl"
              variant="outline"
              style={{ background: "#e6f0e8" }}
              color="rgba(105, 198, 105, 1)"
            >
              <Group justify="space-between">
                <ThemeIcon color="rgba(105, 198, 105, 1)" radius="lg" size="sm">
                  <Text size="sm">
                    {jogada && jogada.taticas && Array.isArray(jogada.taticas) ? jogada.taticas.length : 0}
                  </Text>
                </ThemeIcon>
                <Text tt="capitalize" fz="sm" fw={500}>
                  Táticas
                </Text>
              </Group>
            </Badge>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>

            {formatAutorizacoes?.find(item => item.tipoAutorizacao === "21")?.editar ? <Anchor
              component={NavLink}
              color="rgba(8, 68, 244, 1)"
              to={`/jogadas-de-inovacao/customizada/${jogada.id}`}
              underline="never"
            >
              <Button color="rgba(8, 68, 244, 1)" variant="outline" radius="lg" size="md">
                <Text size="sm">Customizar jogada</Text>
              </Button>
            </Anchor> : <></>}

          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <Group justify="flex-end" align="center">
              <Button
                type="button"
                variant="transparent"
                size="compact-sm"
                rightSection={opened ? <IconChevronUp size="1rem" /> : <IconChevronDown size="1rem" />}
                onClick={toggle}
              >
                {opened ? "Ver menos" : "Ver mais"}
              </Button>
            </Group>
          </Grid.Col>
        </Grid>
        {opened && (
          <>
            <Space h="xl" />
            <Stack>
              <JogadaDeInovacaoCard jogada={jogada} refetch={refetch} formatAutorizacoes={formatAutorizacoes} />
            </Stack>
          </>
        )}
      </Card>
      <Space h="xl" />
    </>
  );
};

export default JogadaDeInovacaoItem;
