import { subject } from "@casl/ability";
import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getDocumentReferenceFromPath } from "@/utils/firestore";
import { getColaboradorFromDocument, colaboradorConverter } from "../firestore";

type TGetColaboradorFromDocumentResponse = Awaited<ReturnType<typeof getColaboradorFromDocument>>;

/**
 * Gets a colaborador by its reference path
 * @param stringReferencePath - The string reference path to the document in firestore
 * @returns The colaborador
 */
function useGetColaboradorByReferencePath(stringReferencePath: string | null) {
  return useQuery<TGetColaboradorFromDocumentResponse, FirestoreError, TColaborador | null>({
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    enabled: !!stringReferencePath,
    queryKey: ["colaborador", stringReferencePath],
    queryFn() {
      if (!stringReferencePath) {
        throw new Error("Path de referência não informado");
      }

      const colaboradorDocument = getDocumentReferenceFromPath(stringReferencePath, colaboradorConverter);
      return getColaboradorFromDocument(colaboradorDocument);
    },
    select(dataResponse) {
      if (!dataResponse.exists()) {
        return null;
      }

      return subject("TColaborador", dataResponse.data());
    }
  });
}

export default useGetColaboradorByReferencePath;
