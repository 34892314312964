import { subject } from "@casl/ability";
import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { getQuestionariosArray } from "@/modules/grupos-diagnostico/firestore";
import { QUERY_KEY } from "@/modules/questionarios/components/questionario-table/QuestionarioTable";
import useUserStore from "@/modules/users/store";

type TGetQuestionarioResponse = Awaited<ReturnType<typeof getQuestionariosArray>>;

/**
 * Get questionário de inovação query hook
 * @returns Questionário de inovação query hook
 */
// prettier-ignore
function useGetQuestionarios() {
  const { idProjeto } = useParams<{ id: string; idProjeto: string }>();
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  return useQuery<TGetQuestionarioResponse, FirestoreError, TQuestionario[]>({
    queryKey: ["empresas", { id: activeEmpresaId }, QUERY_KEY, idProjeto],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      return getQuestionariosArray(activeEmpresaId, [idProjeto || ""]);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }
        return dataResponse.docs.map((document_) => subject("TQuestionario", document_.data()));
    }
  });
}

export default useGetQuestionarios;
