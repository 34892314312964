import { useMutation } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { addJogadaDeInovacao, TJogadaInovacaoForm } from "@/modules/jogadas-de-inovacao/firestore";
import useUserStore from "@/modules/users/store";

type TUpdateJogadaDeInovacao = Awaited<ReturnType<typeof addJogadaDeInovacao>>;
/**
 * Finalizar resposta de inovação mutation hook
 * @returns Finalizar resposta de inovação mutation
 */
// prettier-ignore
export function useAddJogadaDeInovacao() {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  return useMutation<TJogadaDeInovacao | TUpdateJogadaDeInovacao, FirestoreError, TJogadaInovacaoForm>({
    mutationFn(item) {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!item) {
        throw new Error("Empresa não selecionada");
      }

      return addJogadaDeInovacao(activeEmpresaId, item);
    },
  });
}
