import { Text, Card, Title, Group, ScrollArea } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import NoData from "@/components/no-data";
import TopDesafioCard from "./TopDesafioCard";
import useGetTopDesafiosMaisVotadas from "./useGetTopDesafiosMaisVotadas";

const TOP_LIMIT_DESAFIOS_MAIS_VOTADAS = 3;
const MAGIC_NUMBER = 335;
// prettier-ignore
const TopDesafiosMaisVotadasCard = () => {
  const { data: topDesafios } = useGetTopDesafiosMaisVotadas();
  topDesafios && topDesafios.sort((a, b) => (b?.rankingByMoedas?.totalMoedas || 0) - (a?.rankingByMoedas?.totalMoedas || 0));
  const noData = topDesafios && topDesafios.length === 0;
  const isSmMarches = useMediaQuery("(max-width: 48em)");

  return (
    <Card withBorder w={"100%"} h={isSmMarches ? "100%" : MAGIC_NUMBER}>
      <Title order={3} fw={500} mb="md">
        Top {TOP_LIMIT_DESAFIOS_MAIS_VOTADAS} desafios mais votados da empresa
      </Title>
      {noData ? (
        <NoData>
          <Text c="dimmed">Não há desafios cadastradas</Text>
        </NoData>
      ) : (
        <ScrollArea scrollbars="x">
          <Group wrap="nowrap" align="stretch" grow>
            {topDesafios?.slice(0, TOP_LIMIT_DESAFIOS_MAIS_VOTADAS).filter(item => item.publishedAt !== null).map((desafio, index) => <TopDesafioCard key={desafio.id} desafio={desafio} maw={300} index={index} />)}
          </Group>
        </ScrollArea>
      )}
    </Card>
  );
};

export default TopDesafiosMaisVotadasCard;
