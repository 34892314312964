import { useMutation } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { TLicenciadoForm, updateLicenciado, addLicenciado } from "../../firestore";

type TUpdateLicenciadoResponse = Awaited<ReturnType<typeof updateLicenciado>>;
type TAddLicenciadoResponse = Awaited<ReturnType<typeof addLicenciado>>;
/**
 * Finalizar resposta de inovação mutation hook
 * @returns Finalizar resposta de inovação mutation
 */
// prettier-ignore
// eslint-disable-next-line import/no-unused-modules
export function useUpdateLicenciados() {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  return useMutation<TAddLicenciadoResponse | TUpdateLicenciadoResponse , FirestoreError, TLicenciadoForm>({
    mutationFn(item) {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!item) {
        throw new Error("Empresa não selecionada");
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      return item.id ? updateLicenciado(activeEmpresaId, item?.id || "", item) : addLicenciado(activeEmpresaId, item);
    },
  });
}
