import { Button, Group } from "@mantine/core";
import { useEffect } from "react";
import { verificationDate } from "@/modules/projetos/components/projeto-form/ProjetoForm";

interface ArrayProperties {
  nome?: string;
  value: number;
  descricao: string;
  id: string;
  tipoDeResposta: string;
  resposta?: string | number | Campo | Campo[] | boolean;
  pergunta?: boolean;
  obrigatoria: boolean;
}

interface Campo {
  campo: string;
  valor: string;
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
// prettier-ignore
/* eslint-disable sonarjs/cognitive-complexity */
const OptionsButtons = (
  { arrayFormat, perguntasArray, respostaItem, stepPergunta, handlePrevious, handleNext, handleStop, questionario }:
    {
      arrayFormat: ArrayProperties[],
      stepPergunta: number,
      respostaItem: TResposta | null | undefined,
      handlePrevious: () => void;
      handleNext: () => void;
      handleStop: () => void;
      perguntasArray: TPergunta[],
      questionario: TQuestionario | null | undefined

    }
) => {
  const finalStep = 100;

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft' && Number.parseInt(stepPergunta.toFixed(0)) !== 0) {
        handlePrevious();
      } else if (event.key === 'ArrowRight' && Number.parseInt(stepPergunta.toFixed(0)) !== finalStep) {
        handleNext();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [stepPergunta, arrayFormat.filter(item => item.resposta).length, perguntasArray?.length]);

  return (
    <>
      {perguntasArray.length > 0 ? <Group>
        {Number.parseInt(stepPergunta?.toFixed(0)) > 0 ? <Button onClick={handlePrevious} variant="filed">Voltar</Button> : null}
        {(respostaItem && respostaItem.id && !respostaItem.pausado) || Number.parseInt(stepPergunta?.toFixed(0)) === 0 ? <></> : <Button onClick={handleStop} variant="outline">Pausar</Button>}
        {Number.parseInt(stepPergunta?.toFixed(0)) === finalStep && respostaItem && respostaItem.id && !respostaItem.pausado ? <></>
          : <Button onClick={handleNext} variant="filed"
            disabled={(Number.parseInt(stepPergunta?.toFixed(0)) === finalStep && arrayFormat.filter(item => item.resposta && item.obrigatoria).length !== perguntasArray.filter(item => item.obrigatoria)?.length) || (Number.parseInt(stepPergunta?.toFixed(0)) === finalStep && verificacaoExpirada(questionario))}>
            {arrayFormat.filter(item => item.resposta && item.obrigatoria).length === perguntasArray?.filter(item => item.obrigatoria).length && Number.parseInt(stepPergunta?.toFixed(0)) === finalStep ? "Finalizar" : "Proximo"}</Button>}
      </Group> : <></>}
    </>
  )
};

export default OptionsButtons;

// prettier-ignore
const verificacaoExpirada = (questionario: TQuestionario | null | undefined) => {
  const now = new Date();
  const anoAtuacao = typeof questionario?.dataAtuacao === "number" ? verificationDate(new Date()) : verificationDate(questionario?.dataAtuacao);
  const anoVigencia = typeof questionario?.dataVigencia === "number" ? verificationDate(new Date()) : verificationDate(questionario?.dataVigencia);

  if (anoVigencia && anoAtuacao) {
    return new Date(anoAtuacao) > now || new Date(anoVigencia) < now;
  }
  return false
}
