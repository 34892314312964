import { Badge, Text } from "@mantine/core";
import useGetJogadaItem from "./useGetCategoriaFromUrl";

interface Properties {
  id: string;
}
// prettier-ignore
const BadgeCategoria = (properties: Properties) => {
  const { data } = useGetJogadaItem(properties?.id);

  return <Badge
    radius="md"
    size="xl"
    variant="transparent"
    style={{ background: "rgb(243,241,255)" }}
    color="rgba(123, 97, 255, 1)"
  >
    <Text tt="capitalize" fz="xs" fw={500}>
      {data?.nome}
    </Text>
  </Badge>
}

export default BadgeCategoria;
