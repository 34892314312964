import { Flex, Group, NumberInput, Space, Text } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { QueryObserverResult } from "@tanstack/react-query";
import useUserStore from "@/modules/users/store";
import CardSolucao from "./CardSolucao";
import EditaImersaoButton from "./EditaImersaoButton";
import RemoveImersaoButton from "./RemoveImersaoButton";

type DesafioComentarioFormProperties = {
  data: TCronogramaDesafio;
  refetch: () => Promise<QueryObserverResult>;
};

interface MyState {
  id?: number;
  data: Date | null;
  descricao: string;
  orientacaoLocal: string;
}

// prettier-ignore
const DesafioCronogramaFormItem = ({ data, refetch }: DesafioComentarioFormProperties) => {
  const colaborador = useUserStore((state) => state.colaborador);

  return (
    <>
      <Flex direction="column" pb="xs">
        <Group justify="space-between" align="center">
          <Flex gap="lg" direction="row">
            <DateInput
              label="Data e hora de início das inscrições"
              disabled={true}
              value={data?.inicioIncricao}
              valueFormat="DD/MM/YYYY HH:mm:ss"
            />
            <DateInput
              label="Data e hora de término das inscrições"
              disabled={true}
              value={data?.terminoIncricao}
              valueFormat="DD/MM/YYYY HH:mm:ss"
            />
            <NumberInput
              label="Quantidade de vagas"
              disabled={true}
              value={data?.quantidadeDeVagas}
            />
          </Flex>
          {colaborador && colaborador?.role === "ADMIN" ?
            <Flex>
              <EditaImersaoButton cronograma={data} refetch={refetch} />
              <RemoveImersaoButton cronogramaId={data.id} refetch={refetch} />
            </Flex>
            : <></>}
        </Group >
        <Space h={"md"} />
        {
          data && data.event && Array.isArray(data.event) && data.event.length > 0 ?
            data.event.map((item: MyState) => <CardSolucao key={item.id} item={item} />) :
            <><Text c="dimmed">Nenhum evento encontrado!</Text></>
        }
      </Flex >
    </>
  );
};

export default DesafioCronogramaFormItem;
