import { Stack } from "@mantine/core";
import {
  IconDeviceIpadQuestion,
  IconFileDescription,
  IconFileSymlink,
  IconHome2,
  IconQuestionMark,
  IconReportAnalytics,
  IconUserPlus
} from "@tabler/icons-react";
import { NavLink } from "react-router-dom";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import { dataAutorizacoes } from "@/src/routes/empresa-page/EmpresaPage";
import NavLinkMenu from "./NavLinkMenu";

const AppDiagnosticoMenu = ({ toggleMobile }: { toggleMobile: () => void }) => {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresa);
  const projeto = useUserStore((state) => state.activeProjeto);
  const activeAutorizacao = useUserStore((store) => store.autorizacoes);
  const uid = getCurrentUser()?.uid;
  const { data: userColaborador } = useGetColaboradorById(activeEmpresaId?.id || "", uid || "");
  const getAuthorization = (tipoAutorizacao: string) =>
    formatAutorizacoes?.find((item) => item.tipoAutorizacao === tipoAutorizacao);
  const autorizacao = dataAutorizacoes.find((item) => item.label === userColaborador?.role);
  const formatAutorizacoes = activeAutorizacao?.filter((item) => item.tipo === autorizacao?.value);

  if (activeEmpresaId && activeEmpresaId?.status === "INATIVO") {
    return <></>;
  }

  return (
    <Stack mx="md" mt="lg" gap="xs">
      <NavLinkMenu
        label="Início"
        leftSection={<IconHome2 size="1.2rem" stroke={1.5} />}
        component={NavLink}
        onClick={toggleMobile}
        to="/diagnostico"
      />
      {projeto && (
        <NavLinkMenu
          label="Questionário de Inovação"
          leftSection={<IconDeviceIpadQuestion size="1.2rem" stroke={1.5} />}
          component={NavLink}
          to="/questionarioDeInovacao"
          onClick={toggleMobile}
        />
      )}
      <NavLinkMenu
        label="Responder questionário"
        leftSection={<IconFileSymlink size="1.2rem" stroke={1.5} />}
        component={NavLink}
        to="/listaResponderQuestionario/0"
        onClick={toggleMobile}
      />
      <NavLinkMenu
        label="Convidar Colaboradores"
        leftSection={<IconUserPlus size="1.2rem" stroke={1.5} />}
        component={NavLink}
        onClick={toggleMobile}
        to="/convidarColaboradores"
      />
      {getAuthorization("25")?.leitura ? (
        <NavLinkMenu
          label="Gerenciar Evidências"
          leftSection={<IconFileDescription size="1.2rem" stroke={1.5} />}
          component={NavLink}
          to="/gerenciarEvidencias"
        />
      ) : null}
      <NavLinkMenu
        label="Relatórios"
        leftSection={<IconReportAnalytics size="1.2rem" stroke={1.5} />}
        component={NavLink}
        onClick={toggleMobile}
        to="/relatorios"
      />
      <NavLinkMenu label="Cadastros" leftSection={<IconQuestionMark size="1.2rem" stroke={1.5} />}>
        {formatAutorizacoes?.find((item) => item.tipoAutorizacao === "13")?.leitura && (
          <NavLinkMenu label="Projetos" component={NavLink} onClick={toggleMobile} to="/projetos" />
        )}
        {formatAutorizacoes?.find((item) => item.tipoAutorizacao === "5")?.leitura && (
          <NavLinkMenu label="Questionários" component={NavLink} onClick={toggleMobile} to="/questionarios" />
        )}
        {formatAutorizacoes?.find((item) => item.tipoAutorizacao === "6")?.leitura && (
          <NavLinkMenu label="Grupos de perguntas" component={NavLink} onClick={toggleMobile} to="/gruposDiagnostico" />
        )}
        {formatAutorizacoes?.find((item) => item.tipoAutorizacao === "8")?.leitura && (
          <NavLinkMenu label="Tipo de Respostas" component={NavLink} onClick={toggleMobile} to="/tipos-de-respostas" />
        )}
        {formatAutorizacoes?.find((item) => item.tipoAutorizacao === "7")?.leitura && (
          <NavLinkMenu label="Perguntas" component={NavLink} onClick={toggleMobile} to="/perguntas" />
        )}
        {formatAutorizacoes?.find((item) => item.tipoAutorizacao === "8")?.leitura && (
          <NavLinkMenu label="Regiões" component={NavLink} onClick={toggleMobile} to="/regioes" />
        )}
      </NavLinkMenu>
    </Stack>
  );
};

export default AppDiagnosticoMenu;
