import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { getRespostasEmpresaQuestionario } from "../firestore";

type TGetConviteResponse = Awaited<ReturnType<typeof getRespostasEmpresaQuestionario>>;

const QUERY_KEY = "respostas-questionario";

/**
 * Get ideia from url params
 * @param empresaId - id empresa
 * @param questionarioId - id questionario
 * @returns - Ideia or null if not found
 */
function useGetRespostasQuestionario(empresaId: TEmpresa["id"], questionarioId: TQuestionario["id"]) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  return useQuery<TGetConviteResponse, FirestoreError, TResposta[]>({
    queryKey: [QUERY_KEY, empresaId, questionarioId, activeEmpresaId],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return getRespostasEmpresaQuestionario(empresaId, questionarioId, activeEmpresaId);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((document_) => document_.data());
    },
    enabled: !!activeEmpresaId
  });
}

export default useGetRespostasQuestionario;
