import { Button, ButtonProps } from "@mantine/core";
import { modals } from "@mantine/modals";
import { MouseEvent, PropsWithChildren } from "react";

/**
 * Handles the click event.
 * @param event - The event object.
 */
function handleClick(event: MouseEvent<HTMLButtonElement>) {
  event.preventDefault();
  modals.openContextModal({
    modal: "invite-empresa-modal",
    size: "xl",
    innerProps: {},
    ml: "110px"
  });
}

const InviteEmpresaButton = ({ children, ...properties }: PropsWithChildren<ButtonProps>) => {
  return (
    <Button type="button" {...properties} onClick={handleClick}>
      {children}
    </Button>
  );
};

export default InviteEmpresaButton;
