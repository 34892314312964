import { Button, Flex, Group, NumberInput, Space, Text, Textarea } from "@mantine/core";
import { DateInput, DateValue } from "@mantine/dates";
import { IconCheck } from "@tabler/icons-react";
import { Timestamp } from "firebase/firestore";
import { useState, useEffect } from "react";
import { getCurrentUser } from "@/services/auth";
import { useUpdateIncricao } from "./useGetInscricao";
import { TCronogramaForm } from "../../firestore";
import CardSolucao from "../desafio-cronograma-form-item/CardSolucao";

const FORM_TYPE_INSCREVER = 2;
const FORM_TYPE_DESINCREVER = 1;

interface Inscricao {
  id: string | undefined;
  data: Date;
  status?: number;
  destaque?: boolean;
}

interface MyState {
  id?: number;
  data: DateValue;
  descricao: string;
  orientacaoLocal: string;
}

interface NewFormModalProperties {
  data: TCronogramaDesafio | undefined;
  onSuccess: () => void;
  refetch: () => void;
  isUpdateCronogramaDesafio?: boolean;
}

// prettier-ignore
const NewFormModal = ({ data, onSuccess, refetch, isUpdateCronogramaDesafio = false }: NewFormModalProperties) => {
  const { mutate: mutateInscricao } = useUpdateIncricao();
  const [dataInicial, setDataInicial] = useState<Date | undefined>(data?.inicioIncricao);
  const [dataFinal, setDataFinal] = useState<Date | undefined>(data?.terminoIncricao);
  const [quantidadeDeVagas, setQuantidadeDeVagas] = useState<number | undefined>(data?.quantidadeDeVagas);
  const inscricoes = data?.inscricoes ?? [];
  const uid = getCurrentUser()?.uid;
  const inscrito = inscricoes && inscricoes.filter((item: Inscricao) => item.id === uid);
  const resultado = data?.quantidadeDeVagas === inscricoes.length;
  const [values, setValues] = useState<MyState>({ data: null, descricao: "", orientacaoLocal: "" });
  const [events, setEvents] = useState<MyState[]>([]);

  useEffect(() => {
    setEvents(data?.event as MyState[] || []);
  }, [data]);

  const changeMutate = (type: number) => {
    return () => {
      const payload = {
        ...data,
        inicioIncricao: isUpdateCronogramaDesafio ? dataInicial : undefined,
        terminoIncricao: isUpdateCronogramaDesafio ? dataFinal : undefined,
        updatedAt: Timestamp.now(),
        createdAt: Timestamp.now(),
        deletedAt: null,
        quantidadeDeVagas: isUpdateCronogramaDesafio ? (quantidadeDeVagas as number) : undefined,
        createdBy: data?.createdBy || "",
        event: events
      };

      if (isUpdateCronogramaDesafio) {
        payload.inscricoes = inscricoes;
        mutateInscricao(payload as TCronogramaForm);
      } else {
        const { novaInscricao, removerInscricao, inicioIncricao, terminoIncricao } = verificationFunction(inscricoes, data, uid);
        payload.inscricoes = type === 1 ? removerInscricao : novaInscricao;
        payload.inicioIncricao = inicioIncricao;
        payload.terminoIncricao = terminoIncricao;
        payload.quantidadeDeVagas = data?.quantidadeDeVagas || 0 as number;
        mutateInscricao(payload as TCronogramaForm);
      }

      onSuccess();
      void refetch();
    }
  };

  const handleOnChangeDataInicial = () => {
    return (value: Date | null) => {
      if (value) {
        setDataInicial(value);
      }
    };
  };

  const handleOnChangeDataFinal = () => {
    return (value: Date | null) => {
      if (value) {
        setDataFinal(value);
      }
    };
  };

  const handleOnChangeQuantidadeDeVagas = () => {
    return (value: string | number | null) => {
      if (value !== null && !Number.isNaN(Number(value))) {
        setQuantidadeDeVagas(Number(value));
      }
    };
  };

  /**
   * Função que deleta o item de sugestão
   * @param item - item a ser apagado
   */
  function handleDeleteItem(item: MyState) {
    if (item.id) {
      setEvents((previousEvents) => previousEvents.filter((event) => event.id !== item.id));
    }
  }

  // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
  const addSugestoes = () => {
    setEvents((previousEvents) => [...previousEvents, { ...values, id: Math.random() }]);
    setValues({ data: null, descricao: "", orientacaoLocal: "" })
  };
  return (
    <>
      <Flex direction="column" pb="xs">
        <Group justify={"space-between"}>
          <NumberInput
            label="Quantidade de vagas"
            disabled={!isUpdateCronogramaDesafio}
            onChange={handleOnChangeQuantidadeDeVagas()}
            value={quantidadeDeVagas}
          />
        </Group>
        <Space h={"md"} />
        <Group justify={"space-between"}>
          <DateInput
            label="Data e hora de início das inscrições"
            disabled={!isUpdateCronogramaDesafio}
            onChange={handleOnChangeDataInicial()}
            value={dataInicial}
            valueFormat="DD/MM/YYYY HH:mm:ss"
          />
          <DateInput
            label="Data e hora de término das inscrições"
            disabled={!isUpdateCronogramaDesafio}
            onChange={handleOnChangeDataFinal()}
            value={dataFinal}
            minDate={dataInicial || new Date()}
            valueFormat="DD/MM/YYYY HH:mm:ss"
          />
        </Group>
        {isUpdateCronogramaDesafio && (
          <>
            <Space h={"md"} />
            <Group justify="space-between" align="flex-end">
              <Group align="center" style={{ flexGrow: 1 }}>
                <DateInput
                  label="Data da Imersão"
                  onChange={changeDate("data", setValues)}
                  value={values.data}
                  valueFormat="DD/MM/YYYY HH:mm:ss"
                  minDate={dataFinal || new Date()}
                  defaultDate={dataFinal}
                  style={{ width: 200 }}
                />
                <Textarea
                  label="Descrição da Imersão"
                  autosize
                  minRows={1}
                  maxRows={5}
                  style={{ flexGrow: 1 }}
                  onChange={changeText("descricao", setValues)}
                  value={values.descricao}
                />
                <Textarea
                  label="Orientações sobre o local de Imersão"
                  autosize
                  minRows={1}
                  maxRows={5}
                  style={{ flexGrow: 1 }}
                  onChange={changeText("orientacaoLocal", setValues)}
                  value={values.orientacaoLocal}
                />
                <Button
                  variant="filled"
                  onClick={addSugestoes}
                  disabled={values.data === null || values.descricao === "" || values.orientacaoLocal === ""}
                >
                  Adicionar
                </Button>
              </Group>
            </Group>
          </>
        )}
        <Space h={"md"} />
        {events.length > 0 ? (
          events.map((item) => (
            <CardSolucao key={item.id} item={item} onDeleteClick={handleDeleteItem} />
          ))
        ) : (
          <Text c="dimmed">Nenhum evento encontrado!</Text>
        )}
      </Flex>
      <Space h={"md"} />
      {resultado ? <Group justify="flex-end" mr="sm">
        <Text fz={"sm"} c={"red"}>Este desafio está cheio</Text>
      </Group> : <Group justify="flex-end" mr="sm">
        {!isUpdateCronogramaDesafio && inscrito && inscrito.length > 0 ?
          <Group>
            <Button bg={"transparent"} onClick={changeMutate(FORM_TYPE_DESINCREVER)} >
              <Text fz={"sm"} c={"black"}>Cancelar minha inscrição</Text>
            </Button>
            <Group>
              <IconCheck color={"rgba(105, 198, 105, 1)"} style={{ fontSize: 12 }} />
              <Text fz={"sm"} c={"rgba(105, 198, 105, 1)"}>Inscrição realizada</Text>
            </Group>
          </Group>
          : <Button bg={"rgba(8, 68, 244, 1)"} onClick={changeMutate(FORM_TYPE_INSCREVER)}>
            {isUpdateCronogramaDesafio ? "Salvar" : "Quero me inscrever"}
          </Button>}
      </Group>}
    </>
  );
};

export default NewFormModal;

// prettier-ignore
const verificationFunction = (inscricoes: Inscricao[], data: TCronogramaDesafio | undefined, uid: string | undefined) => {
  const novaInscricao = inscricoes?.length > 0 ? [...inscricoes, { id: uid, data: new Date(), status: 1, destaque: false }] : [{ id: uid, data: new Date(), status: 1, destaque: false }];
  const removerInscricao = inscricoes?.length > 0 ? inscricoes.filter((item: Inscricao) => item.id !== uid) : [];
  const inicioIncricao = data && data.inicioIncricao ? data.inicioIncricao : new Date();
  const terminoIncricao = data && data.terminoIncricao ? data.terminoIncricao : new Date();

  return { novaInscricao, removerInscricao, inicioIncricao, terminoIncricao };
};

// prettier-ignore
const changeDate = (field: string, setValues: React.Dispatch<React.SetStateAction<MyState>>) => {
  return (event: DateValue) => {
    setValues((values) => ({ ...values, [field]: event }))
  };
};
// prettier-ignore
const changeText = (field: string, setValues: React.Dispatch<React.SetStateAction<MyState>>) => {
  return (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValues((values) => ({ ...values, [field]: event.target.value }))
  };
};
