import { collection, FirestoreDataConverter, getDocs, query, Timestamp, where } from "firebase/firestore";
import { firestore } from "@/base";

type TConviteDocument = Omit<TConvite, "createdAt" | "updatedAt"> & {
  createdAt: Timestamp;
  updatedAt?: Timestamp;
};

const conviteConverter: FirestoreDataConverter<TConvite> = {
  toFirestore(data) {
    delete data.id;
    return data;
  },
  fromFirestore(snap) {
    const { createdAt, updatedAt, ...document } = snap.data() as TConviteDocument;

    const data: TConvite = {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate()
    };

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }
    return data;
  }
};

/**
 * Get all convites collection reference
 * @returns - Convites collection reference
 */
function getConviteCollectionReference() {
  return collection(firestore, "convites");
}

/**
 * Get all convites collection reference where questionario
 * @param empresaId - Empresa id
 * @param uid - Questionario id
 * @returns - Convites collection reference
 */
function queryMyConvitesCollectionReference(empresaId: TEmpresa["id"], uid: string) {
  const convitesCollectionReference = getConviteCollectionReference();
  return query(
    convitesCollectionReference,
    where("idQuestionario", "==", uid),
    where("idEmpresaColaboradorConvidado", "==", empresaId)
  );
}

/**
 * Get all convites from the database
 * @param empresaId - Empresa id
 * @param uid - Questionario id
 * @returns - Promise with all convites
 */
export function getConvites(empresaId: TEmpresa["id"], uid: string) {
  const queryConvitesCollectionReference = queryMyConvitesCollectionReference(empresaId, uid).withConverter(
    conviteConverter
  );
  return getDocs(queryConvitesCollectionReference);
}
