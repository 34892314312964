import { TransformedValues, useForm, yupResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { UseMutationOptions, useQueryClient, useMutation } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { Asserts } from "yup";
import { captureException } from "@/services/log";
import { TEmpresaForm, addEmpresa, updateEmpresa } from "../../firestore";
import empresaSchema from "../../schema/empresa-schema";
import { QUERY_KEY } from "../empresas-table/EmpresasTable";

type EmpresaInputs = Asserts<typeof empresaSchema>;
type TUpdateEmpresaResponse = Awaited<ReturnType<typeof updateEmpresa>>;
type TAddEmpresaResponse = Awaited<ReturnType<typeof addEmpresa>>;

/**
 * Empresa Form hook
 * @param empresa - Empresa data
 * @param root0 - Options
 * @param root0.onSuccess - On success callback
 * @param root0.onError - On error callback
 * @returns - Empresa Form
 */
function useEmpresaForm(
  empresa: TEmpresaForm & Partial<Pick<TEmpresa, "id" | "refPath">>,
  {
    onSuccess,
    onError
  }: Pick<
    UseMutationOptions<TUpdateEmpresaResponse | TAddEmpresaResponse, FirestoreError, EmpresaInputs>,
    "onSuccess" | "onError"
  >
) {
  const defaultEmpresaValues = empresaSchema.getDefault() as TEmpresaForm;

  const queryClient = useQueryClient();
  const form = useForm<EmpresaInputs>({
    validate: yupResolver(empresaSchema),
    transformValues: (values) => empresaSchema.cast(values),
    initialValues: {
      ...defaultEmpresaValues,
      ...empresa
    }
  });

  const { mutate, isPending, reset } = useMutation<
    TUpdateEmpresaResponse | TAddEmpresaResponse,
    FirestoreError,
    EmpresaInputs
  >({
    mutationFn: (values) => {
      return empresa.id ? updateEmpresa(empresa.id, values) : addEmpresa(values);
    },
    async onSuccess(data, variables, context) {
      showNotification({
        message: `${variables.nomeFantasia} ${empresa.id ? "atualizada" : "cadastrada"} com sucesso!`,
        color: "green",
        autoClose: 2500
      });
      reset();
      form.reset();
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      if (onSuccess) {
        return onSuccess(data, variables, context);
      }
      return null;
    },
    onError(error, variables, context) {
      captureException(error, true);
      if (onError) {
        onError(error, variables, context);
      }
    }
  });

  type TFormTransformed = TransformedValues<typeof form>;

  /**
   * Handle form submit
   * @param values - Form values
   */
  function handleFormSubmit(values: TFormTransformed) {
    mutate(values);
  }

  return { form, isPending, onSubmit: form.onSubmit(handleFormSubmit) };
}

export default useEmpresaForm;
