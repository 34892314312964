import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { getActiveRegulamentosDesafiosForDesafio, REGULAMENTOS_DESAFIOS_COLLECTION_KEY } from "../firestore";

type TGetRegulamentoDesafiosResponse = Awaited<ReturnType<typeof getActiveRegulamentosDesafiosForDesafio>>;
type TQueryOptions<T = TGetRegulamentoDesafiosResponse> = Omit<
  UseQueryOptions<TGetRegulamentoDesafiosResponse, FirestoreError, T>,
  "queryKey" | "queryFn"
>;

/**
 * Get Regulamentos ativos para a empresa selecionada
 * @param options - Query options
 * @returns - Regulamentos ativos
 */
function useGetActiveRegulamentosDesafios<T = TGetRegulamentoDesafiosResponse>(options: TQueryOptions<T> = {}) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  return useQuery<TGetRegulamentoDesafiosResponse, FirestoreError, T>({
    queryKey: [REGULAMENTOS_DESAFIOS_COLLECTION_KEY, activeEmpresaId],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      return getActiveRegulamentosDesafiosForDesafio(activeEmpresaId);
    },
    enabled: !!activeEmpresaId,
    ...options
  });
}

export default useGetActiveRegulamentosDesafios;
