import { Alert, Box, Button, Group, LoadingOverlay } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconAlertCircle } from "@tabler/icons-react";
import { FormEvent, PropsWithChildren } from "react";
import { IdeiaFormProvider } from "./ideia-form-context";
import IdeiaFormFields from "./IdeiaFormFields";
import useIdeiaForm from "./useIdeiaForm";
import { TIdeiaForm } from "../../firestore";

type IdeiaFormProperties = PropsWithChildren<{
  ideia: TIdeiaForm;
  readOnly?: boolean;
  onSuccess?: () => void;
}>;

const IdeiaForm = ({ ideia, children, readOnly = false, onSuccess }: IdeiaFormProperties) => {
  const { form, isPending, onSubmit } = useIdeiaForm(ideia, {
    onSuccess
  });

  const genericError = form.errors[""] ?? form.errors["regulamentoReference"];

  /**
   * Handle submit
   * @param event - Form event
   */
  function onHandleSubmitForm(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    const submitEvent = event.nativeEvent as SubmitEvent;
    const submitButton = submitEvent.submitter as HTMLButtonElement | null;
    const emRascunho = submitButton?.value === "RASCUNHO";
    if (
      !emRascunho &&
      (form.values.descricao.trim() === "" ||
        form.values.diferenciais.trim() === "" ||
        form.values.comoAtingirObjetivo.trim() === "" ||
        form.values.feedback.trim() === "" ||
        form.values.descricaoProblema.trim() === "")
    ) {
      showNotification({
        title: "Campo obrigatorio não preenchido!!",
        message: `Por favor, para Salvar a ideia, preencha todos os campos de descrição.`,
        icon: <IconAlertCircle />,
        color: "red"
      });
      return;
    }
    onSubmit();
  }

  return (
    <IdeiaFormProvider form={form}>
      <form onSubmit={readOnly ? undefined : onHandleSubmitForm}>
        <Box pos="relative">
          <LoadingOverlay visible={isPending} />
          <IdeiaFormFields readOnly={readOnly} isLoading={isPending}>
            {children}
          </IdeiaFormFields>
        </Box>
        {!readOnly && (
          <Group justify={genericError ? "space-between" : "flex-end"} mt="md">
            {genericError && (
              <Alert icon={<IconAlertCircle size="1rem" />} color="red">
                {genericError}
              </Alert>
            )}
            <Button type="submit" value={"RASCUNHO"} variant="outline" loading={isPending}>
              Salvar Rascunho
            </Button>
            <Button type="submit" loading={isPending}>
              Salvar
            </Button>
          </Group>
        )}
      </form>
    </IdeiaFormProvider>
  );
};

export default IdeiaForm;
