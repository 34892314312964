import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { getConvites } from "../../firestore";

type TGetConviteResponse = Awaited<ReturnType<typeof getConvites>>;

const QUERY_KEY = "convite-list";

/**
 * Get ideia from url params
 * @param route - id convite
 * @param route.id - id convite
 * @returns - Ideia or null if not found
 */
function useGetConvites({ id }: { id: TConvite["id"] }) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  return useQuery<TGetConviteResponse, FirestoreError, TConvite[]>({
    queryKey: [QUERY_KEY, id, activeEmpresaId],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!id) {
        throw new Error("Nenhum convite");
      }

      return getConvites(activeEmpresaId, id);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((document_) => document_.data());
    },
    enabled: !!id && !!activeEmpresaId
  });
}

export default useGetConvites;
