import { Select, SelectProps } from "@mantine/core";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { useEffect } from "react";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { QUERY_KEY, TDesafioCategoriaDocument, getDesafioCategoriasAtivas } from "../../firestore";

type TGetDesafioCategoriasAtivas = Awaited<ReturnType<typeof getDesafioCategoriasAtivas>>;

type UseGetActiveDesafioCategoriasQueryOptions<T> = Pick<
  UseQueryOptions<TGetDesafioCategoriasAtivas, FirestoreError, T>,
  "select"
>;

/**
 * Hook to fetch active challenge categories
 * @param options Query hook options
 * @returns useQuery hook result
 */
function useGetActiveDesafioCategoriasQuery<T>(options: UseGetActiveDesafioCategoriasQueryOptions<T> = {}) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  return useQuery<TGetDesafioCategoriasAtivas, FirestoreError, T>({
    queryKey: ["empresas", activeEmpresaId, QUERY_KEY],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("No active empresa id");
      }

      return getDesafioCategoriasAtivas(activeEmpresaId);
    },
    ...options
  });
}

/**
 * Select component to select a desafio categoria
 * @param properties Select component properties
 * @returns Select component
 */
const DesafioCategoriaSelector = (properties: SelectProps) => {
  const {
    data: categorias,
    error,
    isLoading
  } = useGetActiveDesafioCategoriasQuery({
    select(data) {
      if (data.empty) {
        return [];
      }

      return data.docs.map((document) => {
        const data = document.data() as unknown as TDesafioCategoriaDocument;
        return {
          value: document.ref.path,
          label: data.nome
        };
      });
    }
  });

  useEffect(() => {
    if (error) {
      captureException(error, true);
    }
  }, [error]);

  return <Select data={categorias} disabled={isLoading} {...properties} />;
};

export default DesafioCategoriaSelector;
