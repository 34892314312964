import { InputBase, List, Paper, ScrollArea } from "@mantine/core";
import { ComponentProps, ComponentPropsWithoutRef, ReactNode } from "react";
import { Mention, MentionsInput, MentionsInputProps, SuggestionDataItem } from "react-mentions";
import mentionClassNames from "./mention.module.css";
import useGetActiveColaboradores from "../../hooks/useGetActiveColaboradores";

type TMentionColaboradorProperties = ComponentPropsWithoutRef<typeof MentionsInput>;

const EMPTY_ARRAY: SuggestionDataItem[] = [];

const MentionColaboradorInput = ({ children, ...inputProperties }: TMentionColaboradorProperties) => (
  <MentionsInput {...inputProperties} className="mentions" classNames={mentionClassNames}>
    {children}
  </MentionsInput>
);

const MentionContainer = (children: ReactNode) => (
  <Paper shadow="sm" withBorder radius={0}>
    <ScrollArea mah={250}>
      <List spacing="md">{children}</List>
    </ScrollArea>
  </Paper>
);

/**
 * Transform mention to display name
 * @param _id - ID of the user
 * @param display - Display name of the user
 * @returns - Display name of the user with @ prefix
 */
function transformMention(_id: string, display: string) {
  return `@${display}`;
}

const MentionColaboradoresInput = ({
  onColaboradorMentioned,
  ...inputProperties
}: Omit<MentionsInputProps, "children"> & { onColaboradorMentioned: ComponentProps<typeof Mention>["onAdd"] }) => {
  const { data: colaboradores } = useGetActiveColaboradores<SuggestionDataItem[]>({
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((document) => {
        const colaborador = document.data();
        return {
          id: colaborador.refPath,
          display: colaborador.nome
        };
      });
    }
  });

  return (
    <InputBase customSuggestionsContainer={MentionContainer} component={MentionColaboradorInput} {...inputProperties}>
      <Mention
        trigger="@"
        data={colaboradores || EMPTY_ARRAY}
        markup="**@__display__**"
        className={mentionClassNames.mentions__mention}
        displayTransform={transformMention}
        onAdd={onColaboradorMentioned}
      />
    </InputBase>
  );
};

export default MentionColaboradoresInput;
