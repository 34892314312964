import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { FunctionsError } from "firebase/functions";
import { useParams } from "react-router-dom";
import { Asserts } from "yup";
import inviteDiagnosticoSchema from "@/modules/convidar-empresa/components/convidar-empresa-form/invite-diagnostico-schema";
import { inviteEmpresaExterno } from "../functions";

type InviteInputs = Asserts<typeof inviteDiagnosticoSchema>;
type SendInviteToUserResponse = Awaited<ReturnType<typeof inviteEmpresaExterno>>;

type MutationOptions = Omit<
  UseMutationOptions<SendInviteToUserResponse, FunctionsError, InviteInputs>,
  "mutationFn" | "mutationKey"
>;

/**
 * Sends an invite to the given email
 * @param options - The mutation options
 * @throws If no empresa is selected
 * @returns The mutation object
 */
function useInviteDiagnosticoMutation(options: MutationOptions = {}) {
  const { idEmpresa } = useParams<{ idEmpresa: string }>();
  return useMutation<SendInviteToUserResponse, FunctionsError, InviteInputs>({
    mutationFn: (values: { email: string; nome: string; cnpj: string }) => {
      if (values && !values.cnpj) {
        throw new Error("CNPJ é obrigatório");
      }
      if (values && !values.nome) {
        throw new Error("Nome é obrigatório");
      }
      if (values && !values.email) {
        throw new Error("Email é obrigatório");
      }

      if (idEmpresa) {
        return inviteEmpresaExterno({ idEmpresa, ...values });
      }

      throw new Error("Nenhum email inserido");
    },
    ...options
  });
}
export default useInviteDiagnosticoMutation;
