import { subject } from "@casl/ability";
import { Anchor, Card, Skeleton, Text } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { NavLink, useParams } from "react-router-dom";
import BreadcrumbsNavigation from "@/components/breadcrumbs-navigation";
import Can from "@/components/can";
import NoData from "@/components/no-data";
import { useGetAuthorizations } from "@/hooks/useGetAuthorizations";
import IdeiaComentarioForm from "@/modules/ideia-comentarios/components/ideia-comentario-form";
import IdeiaComentarios from "@/modules/ideia-comentarios/components/ideia-comentarios";
import HistoricoMovimentacaoCard from "@/modules/ideia-movimentacao/components/historico-movimentacao";
import HistoricoDeMarcos from "@/modules/ideia-movimentacao/components/historico-movimentacao/HistoricoDeMarcos";
import MovimentacaoIdeiasCard from "@/modules/ideia-movimentacao/components/MovimentacaoIdeiasCard";
import { IdeiaContent } from "@/modules/ideias/components/ideia-form/IdeiaContent";
import { QUERY_KEY } from "@/modules/ideias/components/ideias-table/IdeiasTable";
import { getIdeia } from "@/modules/ideias/firestore";
import useUserStore from "@/modules/users/store";
import IdeiaImplementadoresCard from "./IdeiaImplementadoresCard";
import IdeiaParticipantesCard from "./IdeiaParticipantesCard";
import IdeiaPatrocinioCard from "./IdeiaPatrocinioCard";

type TGetIdeiaResponse = Awaited<ReturnType<typeof getIdeia>>;

/**
 * Get ideia from url params
 * @returns - Ideia or null if not found
 */
function useGetIdeiaFromUrl() {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const { ideiaId } = useParams<{ ideiaId: string }>();

  return useQuery<TGetIdeiaResponse, FirestoreError, TIdeia | null>({
    queryKey: [QUERY_KEY, ideiaId, activeEmpresaId],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!ideiaId) {
        throw new Error("Ideia não selecionada");
      }
      return getIdeia(activeEmpresaId, ideiaId);
    },
    select(dataResponse) {
      if (!dataResponse.exists()) {
        return null;
      }

      return subject("TIdeia", dataResponse.data());
    },
    enabled: !!ideiaId && !!activeEmpresaId
  });
}

type TIdeiaPageHeaderProperties = {
  title: string;
  isLoading: boolean;
};

/**
 *
 * @param root0 - Properties
 * @param root0.title - Ideia title
 * @param root0.isLoading - Loading state
 * @returns - Ideia page header
 */
function IdeiaPageHeader({ title, isLoading }: TIdeiaPageHeaderProperties) {
  return (
    <BreadcrumbsNavigation>
      <Anchor component={NavLink} to="/ideias">
        Ideias
      </Anchor>
      <Skeleton radius="md" height={12} visible={isLoading}>
        <Text c="dimmed" mt={-6}>
          {title}
        </Text>
      </Skeleton>
    </BreadcrumbsNavigation>
  );
}

const IdeiaPage = () => {
  const { data: ideia, isLoading } = useGetIdeiaFromUrl();
  const { colaborador } = useUserStore();

  const { create, update } = useGetAuthorizations("3");

  if (isLoading) {
    return (
      <>
        <IdeiaPageHeader title="" isLoading />
      </>
    );
  }

  if (!ideia) {
    return (
      <>
        <IdeiaPageHeader title="" isLoading={false} />
        <NoData>
          <Text>Nenhuma ideia encontrada!</Text>
        </NoData>
      </>
    );
  }

  const canSponsorIdea =
    ideia.status === "NOVA_IDEIA" && ideia.permitirPatrocinio && ideia.publishedAt !== null && create;

  const canCommentOnIdea = ideia.permitirComentarios && update;

  const canMoveIdea = colaborador && colaborador.role != "COLLABORATOR" && colaborador.role != "MEMBER";

  return (
    <>
      <Card withBorder my="md" mb="xl">
        <IdeiaContent ideia={ideia}></IdeiaContent>
      </Card>

      {canSponsorIdea && (
        <Card withBorder my="md" mb="xl">
          <IdeiaPatrocinioCard ideia={ideia} />
        </Card>
      )}

      {canCommentOnIdea && (
        <>
          <Card withBorder my="md" mb="xl" style={{ overflow: "visible" }}>
            <IdeiaComentarioForm ideia={ideia} />
          </Card>
        </>
      )}

      {ideia.permitirComentarios && <IdeiaComentarios ideia={ideia} />}

      <IdeiaParticipantesCard ideia={ideia} />

      <IdeiaImplementadoresCard ideia={ideia} />

      <Can I="read" an="TMarco">
        <HistoricoDeMarcos ideia={ideia} />
      </Can>

      <Can I="read" an="TMarco">
        <HistoricoMovimentacaoCard ideia={ideia} />
      </Can>

      {canMoveIdea && <MovimentacaoIdeiasCard ideia={ideia} />}
    </>
  );
};

export default IdeiaPage;
