import { Anchor, Button, Group } from "@mantine/core";
import { MouseEvent } from "react";
import { NavLink } from "react-router-dom";
import useUserStore from "@/modules/users/store";

type Properties = {
  resposta: TResposta | null;
  colaborador: TColaborador;
  convite: TConvite;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
};

const VersionRespostaButton = ({ resposta, colaborador, convite, onClick }: Properties) => {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  const link = resposta
    ? `/responderQuestionario/${convite.idEmpresa}/${resposta.idQuestionario}/${colaborador.id}`
    : `/visualizarQuestionario/${activeEmpresaId}/${colaborador.id}/${colaborador.id}`;

  const respondido = resposta && resposta.pausado === false && resposta.perguntas.length > 0;

  return (
    <Group align="center" justify="center">
      {respondido ? (
        <Anchor component={NavLink} to={link}>
          <Button type="button" variant="transparent" size="compact-sm">
            Ver respostas
          </Button>
        </Anchor>
      ) : (
        <Button onClick={onClick} color="red">
          Cancelar convite
        </Button>
      )}
    </Group>
  );
};

export default VersionRespostaButton;
