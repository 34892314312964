import { Button } from "@mantine/core";
import { modals } from "@mantine/modals";

const ResponderDesafioComentarioButton = ({
  desafio,
  comentario
}: {
  desafio: TDesafio;
  comentario: TDesafioComentario;
}) => {
  /**
   * Handles the click event.
   */
  function handleClick() {
    modals.openContextModal({
      modal: "responder-desafio-comentario-modal",
      size: "xl",
      innerProps: {
        desafio,
        comentario
      },
      ml: "110px"
    });
  }

  return (
    <Button variant="transparent" size="xs" p={0} onClick={handleClick}>
      Responder
    </Button>
  );
};

export default ResponderDesafioComentarioButton;
