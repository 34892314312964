import { Card, Grid, Switch, Text } from "@mantine/core";

interface Item {
  id: string;
  label: string;
}

const AutorizacaoItem = ({ item }: { item: Item }) => {
  return (
    <>
      <Card padding="lg" radius="md">
        <Grid>
          <Grid.Col span={4}>
            <Text size="sm">{item.label}</Text>
          </Grid.Col>
          <Grid.Col span={4} offset={3}>
            <Switch />
          </Grid.Col>
        </Grid>
      </Card>
    </>
  );
};

export default AutorizacaoItem;
