import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getConvitesEmpresas, getConvitesEmpresasConvidada } from "@/modules/empresas/firestore";

type TGetRespostaResponse = Awaited<ReturnType<typeof getConvitesEmpresas>>;
/**
 * Get resposta from url params
 * @param empresaId - empresa empresaId
 * @returns - resposta or null if not found
 */
function useGetConvites(empresaId: string) {
  return useQuery<TGetRespostaResponse, FirestoreError, TConvite[] | null>({
    queryKey: [empresaId],
    queryFn() {
      if (!empresaId) {
        throw new Error("Ideia não selecionada");
      }

      return getConvitesEmpresasConvidada(empresaId);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((document_) => document_.data());
    },
    enabled: !!empresaId
  });
}

export default useGetConvites;
