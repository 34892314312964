/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable compat/compat */
/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import { Button, Checkbox, Flex, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { ChangeEvent, useState } from "react";

const MODAL_ID = "projeto-term-modal";

const PrivacyPolicyLabel = () => {
  return <Text size="xs">Estou ciente do regulamento do Prêmio Empresa Inovadora e da Política de Privacidade.</Text>;
};

const CompanyPolicyLabel = () => {
  return (
    <Text m="0" size="xs">
      Declaro que minha empresa não é uma startup e também não é a representante de um grupo econômico ou holding. Estou
      ciente de que o não cumprimento desta declaração pode resultar na desclassificação da minha empresa no processo em
      questão. Para mais informações, ver Artigo 2⁰ do regulamento.
    </Text>
  );
};

type ProjetoTermConfirmModalProperties = {
  projeto: TProjeto;
  onClose: (result: boolean) => void;
};

const ProjetoTermConfirmModal = ({ projeto, onClose }: ProjetoTermConfirmModalProperties) => {
  const [acceptPrivacy, setAcceptPrivacy] = useState(false);
  const [acceptCompany, setAcceptCompany] = useState(false);

  const handleOpenRegulamento = () => {
    window.open(projeto.linkRegulamento, "_blank");
  };

  const handleChangeCheckbox = (setState: (v: boolean) => void) => (event_: ChangeEvent<HTMLInputElement>) => {
    setState(event_.target.checked);
  };

  const handleClose = () => {
    onClose(acceptCompany && acceptPrivacy);
    modals.close(MODAL_ID);
  };

  const handleCancel = () => {
    onClose(false);
    modals.close(MODAL_ID);
  };

  return (
    <Flex gap={"md"} direction={"column"}>
      <Text size="sm">Antes de finalizar seu cadastro, é preciso aceitar o regulamento</Text>
      <Flex justify="center" align="center">
        <Button onClick={handleOpenRegulamento}>Acessar regulamento</Button>
      </Flex>
      <Flex direction="column" align="start" gap="sm">
        <Checkbox onChange={handleChangeCheckbox(setAcceptPrivacy)} label={<PrivacyPolicyLabel />} />
        <Checkbox onChange={handleChangeCheckbox(setAcceptCompany)} label={<CompanyPolicyLabel />} />
      </Flex>
      <Flex align="center" justify="end" gap="sm">
        <Button variant="outline" onClick={handleCancel}>
          Cancelar
        </Button>
        <Button disabled={!acceptPrivacy || !acceptCompany} onClick={handleClose}>
          Aceitar
        </Button>
      </Flex>
    </Flex>
  );
};

/**
 * Abre o modal de termo de projeto
 * @param projeto TProjeto com o projeto a ser aceito
 * @returns Promise<boolean> com o resultado do modal
 */
export function openProjetoTermModal(projeto: TProjeto) {
  return new Promise<boolean>((resolve) => {
    modals.open({
      modalId: MODAL_ID,
      title: "Termos do auto-cadastro",
      centered: true,
      closeOnEscape: true,
      size: 552,
      children: <ProjetoTermConfirmModal projeto={projeto} onClose={resolve} />,
      onAbort: () => resolve(false)
    });
  });
}
