/* eslint-disable compat/compat */
interface TEmpresaData {
  nome_fantasia: string;
  razao_social: string;
  email: string | null;
  ddd_telefone_1: string;
  cnae_fiscal: number;
  cnae_fiscal_descricao: string;
  type?: string | undefined;
  cep: string;
  logradouro: string;
  numero: string;
  complemento: string;
  bairro: string;
  municipio: string;
  uf: TEstado;
}

const CNPJ_LENGTH = 14;

export const getEmpresaDataByCnpj = async (cnpj: TEmpresa["cnpj"]) => {
  if (!cnpj || cnpj.length !== CNPJ_LENGTH) {
    return;
  }

  const response = await fetch(`https://brasilapi.com.br/api/cnpj/v1/${cnpj}`);
  return response.json() as Promise<TEmpresaData>;
};
