/* eslint-disable react-perf/jsx-no-new-function-as-prop */
/* eslint-disable react-perf/jsx-no-new-array-as-prop */
import { Container, Slider, Space, Stack } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useEffect, useMemo, useState } from "react";
import QuestionarioActions from "./QuestionarioActions";
import QuestionarioPages from "./QuestionarioPages";
import useGetPerguntaConvite from "../../hooks/useGetPerguntaConvite";
import useGetRespostaConvite from "../../hooks/useGetRespostaConvite";
import useQuestionarioStepper from "../../hooks/useQuestionarioStepper";

export type QuestionarioPage = (TGruposDiagnostico | TPergunta) & { type: "grupo" | "pergunta" };

type Properties = {
  grupos: TGruposDiagnostico[];
  questionario: TQuestionario;
  licenciado: TLicenciado | undefined;
  userId: string;
  empresaId: TEmpresa["id"];
  empresaRespondenteId: TEmpresa["id"];
};

const getPages = (grupos: TGruposDiagnostico[], perguntas: TPergunta[]) => {
  const perguntaByGrupoId = new Map<string, QuestionarioPage[]>();

  for (const p of perguntas) {
    const grupoId = p.grupo;
    const pergunta = perguntaByGrupoId.get(grupoId) || [];
    pergunta.push({ ...p, type: "pergunta" });
    perguntaByGrupoId.set(grupoId, pergunta);
  }

  const pages: QuestionarioPage[] = [];

  for (const grupo of grupos) {
    const perguntasInGrupo = perguntaByGrupoId.get(grupo.id) ?? [];
    pages.push({ ...grupo, type: "grupo" }, ...perguntasInGrupo);
  }

  return pages;
};

/* eslint-disable sonarjs/cognitive-complexity */
/**
 * QuestionarioContent
 * @param root0 - properties
 * @param root0.grupos - gruposIds
 * @param root0.questionario - questionario
 * @param root0.licenciado - licenciado
 * @param root0.userId - userId
 * @param root0.empresaId - empresaId
 * @param root0.empresaRespondenteId - empresaRespondenteId
 * @returns QuestionarioContent
 */
export function QuestionarioContent({
  grupos,
  questionario,
  licenciado,
  userId,
  empresaId,
  empresaRespondenteId
}: Properties) {
  const [respostasQuestionario, setRespostasQuestionario] = useState<TResposta["perguntas"]>([]);
  const isSmMarches = useMediaQuery("(max-width: 48em)");
  const gruposIds = useMemo(() => grupos.map((grupo) => grupo.id), [grupos]);

  const { data: resposta } = useGetRespostaConvite({
    rootEmpresaId: empresaId,
    id: userId,
    idQuestionario: questionario?.id
  });

  const { data: perguntasFound } = useGetPerguntaConvite({ ids: gruposIds, activeEmpresaId: empresaId });
  const respostas = useMemo(
    () => resposta?.filter((item) => item.idEmpresa === empresaRespondenteId) ?? [],
    [resposta]
  );
  const perguntas = useMemo(() => perguntasFound?.sort((a, b) => a?.ordem - b?.ordem) ?? [], [perguntasFound]);
  const pages = useMemo(() => getPages(grupos, perguntas), [grupos, perguntas]);
  const respostaUser = useMemo(() => respostas.find((item) => item.idRespondente === userId), [respostas]);

  const controller = useQuestionarioStepper({
    questionario,
    pages,
    perguntas,
    respostas: respostasQuestionario,
    resposta: respostaUser,
    userId,
    empresaId,
    empresaRespondenteId
  });

  useEffect(() => {
    if (perguntas.length > 0) {
      const userRespostas = respostaUser?.perguntas ?? [];
      const respostasByPergunta = new Map(userRespostas.map((item) => [item.id, item.resposta]));

      setRespostasQuestionario(
        perguntas.map((item) => ({
          id: item.id,
          resposta: respostasByPergunta.get(item.id) ?? false,
          grupo: item.grupo
        }))
      );
    }
  }, [perguntas.length, respostaUser?.perguntas.length]);

  const handleChangeValue = (perguntaId: string, value: TRespostaPergunta["resposta"]) => {
    const perguntaFound = respostasQuestionario.find((item) => item.id === perguntaId);
    if (perguntaFound) {
      const perguntaMapped = { ...perguntaFound, resposta: value };
      setRespostasQuestionario((previous) => previous.map((item) => (item.id === perguntaId ? perguntaMapped : item)));
    }
  };

  const handleJustify = () => {
    if (isSmMarches) {
      return "start";
    }
    return "space-between";
  };

  const corpo = (
    <Container fluid h="100%" w="100vw">
      <Slider
        label={null}
        value={controller.step * controller.stepGap + controller.stepGap}
        step={controller.stepGap}
        styles={{ markLabel: { display: "none" } }}
      />

      <Stack justify={handleJustify()} align="center" h="100%" w="100%" mt="lg">
        <QuestionarioPages
          onChangeResposta={handleChangeValue}
          controller={controller}
          pages={pages}
          licenciado={licenciado}
          respostas={respostasQuestionario}
          resposta={respostaUser}
          empresaId={empresaId}
        />
        <QuestionarioActions controller={controller} />
      </Stack>
      <Space h="md" />
    </Container>
  );
  return isSmMarches ? (
    corpo
  ) : (
    <Container fluid h="65vh" w="100vw">
      {corpo}
    </Container>
  );
}
