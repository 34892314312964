import { Group, Text, ThemeIcon } from "@mantine/core";
import { IconCoin } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { getIdeiaActivitiesPerColaborador } from "@/modules/ideias-activities-per-colaborador/firestore";
import useUserStore from "@/modules/users/store";

type IdeiaUserPatrocinioProperties = {
  ideia: TIdeia;
};

/**
 *  Componente que exibe a quantidade de moedas que o colaborador patrocinou em uma ideia
 * @param root0 - props
 * @param root0.ideia - ideia
 * @returns - componente
 */
export function IdeiaUserPatrocinio({ ideia }: IdeiaUserPatrocinioProperties) {
  const { activeEmpresa, colaborador } = useUserStore();
  const { patrocinadoresReferences } = ideia;

  const [activity, setActivity] = useState<TIdeiaActivityPerColaborador | null>(null);

  const handleLoad = async (empresa: TEmpresa, colaborador: TColaborador, ideia: TIdeia) => {
    // eslint-disable-next-line unicorn/consistent-destructuring
    const activities = await getIdeiaActivitiesPerColaborador(empresa.id, ideia.id, colaborador.id);
    const activitiesFound = activities.data();
    setActivity(activitiesFound ?? null);
  };

  useEffect(() => {
    if (!activeEmpresa || !colaborador || !patrocinadoresReferences) {
      return;
    }

    void handleLoad(activeEmpresa, colaborador, ideia);
  }, []);

  const isColaboradorSponsoring = !!activity?.moedas;

  if (!isColaboradorSponsoring) {
    return <></>;
  }

  return (
    <Group align="center" gap={2} c="gray">
      <ThemeIcon variant="light" color="yellow" size="xs">
        <IconCoin />
      </ThemeIcon>
      <Text c="#000" size="xs" tt="lowercase">
        {`${activity?.moedas} moedas`}
      </Text>
    </Group>
  );
}
