import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getRepostasArray, getRepostasArrayNew, QUERY_KEY } from "@/modules/responder-questionario/firestore";
import useUserStore from "@/modules/users/store";

type TGetRespostaResponse = Awaited<ReturnType<typeof getRepostasArray>>;

/**
 * Get resposta from url params
 * @param id - id do questionario
 * @param id.id - id do questionario
 * @returns - resposta or null if not found
 */
function useGetResposta({ id }: { id: TColaborador["id"] | null | undefined }) {
  const idEmpresa = useUserStore((store) => store.activeEmpresaId);

  return useQuery<TGetRespostaResponse, FirestoreError, TResposta[] | null>({
    queryKey: [QUERY_KEY, id, idEmpresa],
    queryFn() {
      if (!idEmpresa) {
        throw new Error("Empresa não selecionada");
      }
      if (!id) {
        throw new Error("Ideia não selecionada");
      }

      return getRepostasArrayNew(idEmpresa || "", id);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((document_) => document_.data());
    },
    enabled: !!id && !!idEmpresa
  });
}

export default useGetResposta;
