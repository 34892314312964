import { subject } from "@casl/ability";
import {
  serverTimestamp,
  Timestamp,
  FirestoreDataConverter,
  collection,
  addDoc,
  where,
  query,
  getDocs
} from "firebase/firestore";
import { getEmpresaDocumentReference } from "../empresas/firestore";

export const QUERY_KEY: string = "Acessos" as const;

type TAcessoFormFields = Omit<TAcesso, "id" | "refPath" | "createdBy" | "updatedBy" | "deletedAt"> &
  Partial<Pick<TAcesso, "id">>;

type TAcessoDatabaseFields = Pick<TAcesso, "createdBy" | "updatedBy"> & {
  deletedAt: ReturnType<typeof serverTimestamp> | null;
};
export type TAcessoForm = TAcessoFormFields & TAcessoDatabaseFields;

type TAcessoDocument = Omit<TAcesso, "deletedAt"> & {
  deletedAt: Timestamp | null;
};

const AcessoConverter: FirestoreDataConverter<TAcesso> = {
  toFirestore(data) {
    delete data.id;
    return data;
  },
  fromFirestore(snap) {
    const { ...document } = snap.data() as TAcessoDocument;

    const data: TAcesso = {
      ...document,
      id: snap.id
    };

    return subject("TAcesso", data);
  }
};

/**
 * Get Acessos collection reference from empresaId
 * @param empresaId - Empresa id to get the Acessos collection reference
 * @returns - Acessos collection reference
 */
function getAcessosCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, "acesso");
}
// /**
//  * Get Acessos document reference from empresaId
//  * @param empresaId - Empresa id to get the Acessos document reference
//  * @param AcessosId - Acessos id to get the Acessos document reference
//  * @returns - Acessos document reference
//  */
// function getAcessosDocumentReference(empresaId: TEmpresa["id"], AcessosId: TAcesso["id"]) {
//   const AcessosCollectionReference = getAcessosCollectionReference(empresaId);
//   return doc(AcessosCollectionReference, AcessosId);
// }

/**
 * Add a new Acesso to the given empresa
 * @param empresaId - Empresa id to add the Acesso
 * @param Acessos - Acesso data
 * @returns - Acesso document reference
 */
export async function addAcessos(empresaId: TEmpresa["id"], Acessos: Omit<TAcessoForm, "id">) {
  const AcessosCollectionReference = getAcessosCollectionReference(empresaId);
  return addDoc(AcessosCollectionReference, Acessos);
}
// /**
//  * Update a empresa to the database
//  * @param empresaId - Empresa id
//  * @param AcessoId - Acessos id to update
//  * @returns - Promise with the Acesso reference
//  */
// export function updateAcessos(empresaId: TEmpresa["id"], AcessoId: TAcesso["id"]) {
//   const AcessoDocumentReference = getAcessosDocumentReference(empresaId, AcessoId).withConverter(AcessoConverter);
//   const currentUserId = getCurrentUser()?.uid;
//   return updateDoc(AcessoDocumentReference, {
//     updatedBy: currentUserId
//   });
// }

/**
 * Get questionário query for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param idQuestionario - id do questionario
 * @returns - questionário query
 */
function generateAcessoQuery(empresaId: TEmpresa["id"], idQuestionario: string | null | undefined) {
  const AcessoCollectionReference = getAcessosCollectionReference(empresaId).withConverter(AcessoConverter);

  if (idQuestionario) {
    return query(
      AcessoCollectionReference,
      where("deletedAt", "==", null),
      where("idQuestionario", "==", idQuestionario)
    );
  }

  return query(AcessoCollectionReference, where("deletedAt", "==", null));
}

/**
 * Get questionário for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param idQuestionario - id do questionario
 * @returns - questionário document snapshot
 */
export function getAcessoArray(empresaId: TEmpresa["id"], idQuestionario: string | null | undefined) {
  const q = generateAcessoQuery(empresaId, idQuestionario);
  return getDocs(q);
}

export const QUERY_KEY_MEDIA: string = "Medias" as const;
// prettier-ignore
type TMediaFormFields = Omit<TMedia, "id" | "nota"> &
  Partial<Pick<TMedia, "id" | "nota">>;

type TMediaDatabaseFields = Pick<TMedia, "id" | "nota">;
export type TMediaForm = TMediaFormFields & TMediaDatabaseFields;

type TMediaDocument = Omit<TMedia, "deletedAt">;

const MediaConverter: FirestoreDataConverter<TMedia> = {
  toFirestore(data) {
    delete data.id;
    return data;
  },
  fromFirestore(snap) {
    const { ...document } = snap.data() as TMediaDocument;

    const data: TMedia = {
      ...document,
      id: snap.id
    };

    return subject("TMedia", data);
  }
};

/**
 * Get Acessos collection reference from empresaId
 * @param empresaId - Empresa id to get the Acessos collection reference
 * @returns - Acessos collection reference
 */
function getMediaCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, "medias");
}

/**
 * Add a new Acesso to the given empresa
 * @param empresaId - Empresa id to add the Acesso
 * @param medias - Acesso data
 * @returns - Acesso document reference
 */
export async function addMedia(empresaId: TEmpresa["id"], medias: TMedia) {
  const MediaCollectionReference = getMediaCollectionReference(empresaId);

  return addDoc(MediaCollectionReference, medias);
}

/**
 * Get questionário query for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param idPergunta - id do questionario
 * @returns - questionário query
 */
function generateMediaQuery(empresaId: TEmpresa["id"], idPergunta: string | null | undefined) {
  const AcessoCollectionReference = getMediaCollectionReference(empresaId).withConverter(MediaConverter);
  return query(AcessoCollectionReference, where("idResposta", "==", idPergunta));
}

/**
 * Get questionário for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param idPergunta - id do questionario
 * @returns - questionário document snapshot
 */
export function getMediaArray(empresaId: TEmpresa["id"], idPergunta: string | null | undefined) {
  const q = generateMediaQuery(empresaId, idPergunta);
  return getDocs(q);
}
