import { Button, Group, Modal, Select, Space, Text } from "@mantine/core";
import { Timestamp } from "firebase/firestore";
import { Dispatch, SetStateAction, useState } from "react";
import { useParams } from "react-router-dom";
import { TStatusForm } from "../../../firestore";
import { useStatus } from "../hooks/useStatus";

const changeStatus = (
  selects: TEmpresa[],
  mutate: (item: TStatusForm) => void,
  id: string | undefined,
  status: string,
  setReload: Dispatch<SetStateAction<boolean>>,
  setOpenModal: Dispatch<SetStateAction<boolean>>
) => {
  return () => {
    selects.map((item: TEmpresa & StatusItem) =>
      mutate({
        id: item?.statusItem?.id,
        updatedAt: Timestamp.now(),
        idEmpresa: item?.id || "",
        idProjeto: id || "",
        status: status || "",
        createdAt: Timestamp.now(),
        deletedAt: null
      })
    );
    setReload((reload) => !reload);
    setOpenModal(false);
  };
};

interface StatusItem {
  statusItem: TStatus;
}

interface Fase {
  id: string;
  cor: string;
  titulo: string;
}

// prettier-ignore
const StatusComponente = ({ selects, setReload, fases }: { selects: TEmpresa[], setReload: Dispatch<SetStateAction<boolean>>, fases: Fase[] | undefined }) => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [fase, setFase] = useState("")
  const { mutate } = useStatus();
  const { id } = useParams<{ id: string }>();

  // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
  const changeModal = () => {
    setOpenModal(open => !open)
  }

  // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
  const onChange = (value: string) => {
    setFase(value)
  }

  return <>
    {selects && selects.length > 0 && <Button onClick={changeModal} color="rgba(8, 68, 244, 1)">Alterar fase</Button>}
    <Modal opened={openModal} onClose={changeModal}>
      <Text fw="bold" mb="md">
        Alterar fase
      </Text>
      <Select
        placeholder="Selecione uma fase"
        data={fases?.map(item => ({ value: item.id, label: item.titulo }))}
        onChange={onChange}
        value={fase}
      />
      <Space h="xl" />
      <Group justify="flex-end">
        <Button type="submit" variant="filled" onClick={changeStatus(selects, mutate, id, fase, setReload, setOpenModal)}>
          Salvar
        </Button>
      </Group>
    </Modal>
  </>
};

export default StatusComponente;
