import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import app from "./app";

const firestore = getFirestore(app);

if (import.meta.env.DEV) {
  const DEFAULT_FIRESTORE_PORT = 8080;
  const __ENV_FIRESTORE_PORT__ = Number(import.meta.env.VITE_FIRESTORE_PORT);
  const FIRESTORE_PORT = Number.isInteger(__ENV_FIRESTORE_PORT__) ? __ENV_FIRESTORE_PORT__ : DEFAULT_FIRESTORE_PORT;

  const __ENV_FIRESTORE_HOST__ = import.meta.env.VITE_FIRESTORE_HOST;
  const FIRESTORE_HOST = __ENV_FIRESTORE_HOST__ || "localhost";

  // eslint-disable-next-line no-console
  console.log("Connecting to Firestore emulator at %s:%d", FIRESTORE_HOST, FIRESTORE_PORT);

  connectFirestoreEmulator(firestore, FIRESTORE_HOST, FIRESTORE_PORT);
}

export default firestore;
