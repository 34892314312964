import { Button, Flex, Group, Space, Title } from "@mantine/core";
import { QueryObserverResult } from "@tanstack/react-query";
import { Timestamp } from "firebase/firestore";
import { useCallback, useState } from "react";
import { useUpdateIncricao } from "@/modules/desafio-cronograma/components/list-form-cronograma/useGetInscricao";
import SolucoesCronogramaForm from "@/modules/desafio-cronograma/components/solucoes-form";
import { TCronogramaForm } from "@/modules/desafio-cronograma/firestore";

interface MyState {
  titulo: string;
  descricao: string;
}

// prettier-ignore
const SolucoesCronogramaCard = ({ data, refetch }: { data: TCronogramaDesafio, refetch: () => Promise<QueryObserverResult>; }) => {
  const { mutate } = useUpdateIncricao();
  const [values, setValues] = useState({ titulo: "", descricao: "" });

  const onClick = useCallback(() => {
    changeMutate(data, mutate, refetch, setValues, values)
  }, [data, values]);

  return (
    <Flex direction="column" pb="xs">
      <Title order={4}>Cadastrar nova proposta de solução</Title>
      <Space h={"md"} />
      <SolucoesCronogramaForm values={values} setValues={setValues} />
      <Space h="md" />
      <Group justify={"flex-start"}>
        <Button bg={"rgba(8, 68, 244, 1)"} onClick={onClick} disabled={values.titulo === "" || values.descricao === ""}>Adicionar</Button>
      </Group>
    </Flex>
  );
};

export default SolucoesCronogramaCard;
// prettier-ignore
const verificationFunction = (data: TCronogramaDesafio | undefined) => {

  const inicioIncricao = data && data.inicioIncricao ? data.inicioIncricao : new Date();
  const terminoIncricao = data && data.terminoIncricao ? data.terminoIncricao : new Date();
  const solucoes = data && Array.isArray(data?.solucoes) && data.solucoes.length > 0 ? data.solucoes : []

  return { inicioIncricao, terminoIncricao, solucoes };
};

// prettier-ignore
const changeMutate = (data: TCronogramaDesafio, mutate: (item: TCronogramaForm) => void, refetch: () => Promise<QueryObserverResult>, setValues: React.Dispatch<React.SetStateAction<MyState>>, values: MyState) => {
  const { inicioIncricao, terminoIncricao, solucoes } = verificationFunction(data);

  mutate({
    ...data,
    updatedAt: Timestamp.now(),
    createdAt: Timestamp.now(),
    deletedAt: null,
    inicioIncricao,
    terminoIncricao,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    solucoes: [...solucoes, { ...values, id: Math.random() }]
  });
  void refetch();
  setValues({ titulo: "", descricao: "" });

};
