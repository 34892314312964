import { Card, Group, MantineStyleProps, Stack, Text, Tooltip } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { Dispatch, SetStateAction } from "react";
import EditItemNewButtonWithReference from "@/components/edit-item-button/EditItemNewButton";
import { TCampanhaRegulamentoIdeiasForm } from "@/modules/campanha-regulamento-ideias/firestore";
import campanhaRegulamentoIdeiasSchema from "@/modules/campanha-regulamento-ideias/schema";
import { formatDate } from "@/utils/date";
// import IdeiaItem from "./IdeiaItem";
import RemoveCampanhaButton from "./RemoveCampanhaButton";
import { TRegulamentoIdeiasForm } from "../../firestore";

// interface Ideia {
//   idUser: string;
//   nameUser: string;
//   descricao: string;
//   referenceUser: string;
// }

// prettier-ignore
const ItemView = ({ title, description, c }: { title: string; description: string; c: MantineStyleProps["c"] }) => (
  <Stack gap={0}>
    <Text c={c} fz="xs">
      {title}
    </Text>
    <Text c={c}>{description}</Text>
  </Stack>
);
// prettier-ignore
const CampanhaCard = ({
  regulamento,
  campanha,
  index,
  setReload,
  handleAddCampanha
}: {
  regulamento: TRegulamentoIdeiasForm;
  campanha: TCampanhaRegulamentoIdeiasForm;
  index: number;
  setReload: Dispatch<SetStateAction<boolean>>;
  handleAddCampanha: (campanha: TCampanhaRegulamentoIdeiasForm) => void;
}) => {
  const hasCampanhaFinished = campanha.dataFinal < new Date();
  const isCampanhaInProgress = campanha.dataInicial < new Date() && !hasCampanhaFinished;
  // const ideias = campanha && campanha.ideias as Ideia[];
  const textColor = isCampanhaInProgress ? "white" : undefined;

  return (

    <Card bg={isCampanhaInProgress ? "blue" : hasCampanhaFinished ? "gray.2" : "white"} pos="relative" withBorder>
      <Group justify={"end"}>

        <EditItemNewButtonWithReference
          modal="campanha-regulamento-ideias-form-modal"
          schema={campanhaRegulamentoIdeiasSchema}
          item={campanha}
          size="xs"
          onSubmit={handleAddCampanha}
        >
          <Group gap={4}>
            <IconEdit size="1.2rem" stroke={1.5} color={campanha.dataInicial > new Date() ? "black" : "white"} />
          </Group>
        </EditItemNewButtonWithReference>
        <Tooltip label="Remover campanha" position="left" withArrow offset={2}>
          <RemoveCampanhaButton regulamento={regulamento} campanha={campanha} index={index} setReload={setReload} />
        </Tooltip>
      </Group>
      <Stack>
        <Group grow>
          <ItemView c={textColor} title="Descrição da campanha" description={campanha.descricao} />
          <ItemView
            c={textColor}
            title="Inicio da campanha"
            description={formatDate(campanha.dataInicial, { dateStyle: "short", timeStyle: undefined })}
          />
          <ItemView
            c={textColor}
            title="Termino da campanha"
            description={formatDate(campanha.dataFinal, { dateStyle: "short", timeStyle: undefined })}
          />
        </Group>
        <Group grow>
          <ItemView c={textColor} title="Setores participantes" description={campanha.setores.join(", ")} />
          <ItemView
            c={textColor}
            title="Cadastro de ideias"
            description={`${campanha.quantidadeGanhoNoCadastro} pontos`}
          />
          <ItemView
            c={textColor}
            title="Aprovação de ideias"
            description={`${campanha.quantidadeGanhoNaAprovacao} pontos`}
          />
        </Group>
        <Group grow>
          <ItemView
            c={textColor}
            title="Implementação da ideia"
            description={`${campanha.quantidadeGanhoNaImplementacao} pontos`}
          />
          <ItemView
            c={textColor}
            title="Participação em implementação de ideia própria"
            description={`${campanha.quantidadeGanhoNaParticipacaoEImplementacaoIdeiaPropria} pontos`}
          />
          <ItemView
            c={textColor}
            title="Participação em implementação de ideia de terceiros"
            description={`${campanha.quantidadeGanhoNaParticipacaoEImplementacaoIdeiaTerceiro} pontos`}
          />
        </Group>
      </Stack>
      {/* <Space h={"md"} />
      <Stack>
        {ideias && ideias.length > 0 ? (
          ideias.map((item: Ideia) => (
            <IdeiaItem key={item.idUser} item={item} />
          ))
        ) : (
          <></>
        )}
      </Stack> */}
    </Card>
  );
};

export default CampanhaCard;
