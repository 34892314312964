import { Badge, Card, Divider, Flex, Grid, Group, Space, Text } from "@mantine/core";
import { IconFlame, IconStar, IconListDetails } from "@tabler/icons-react";

const CardEmpresa = () => {
  const space = "space-between";

  return (
    <Card withBorder shadow="md" style={{ flex: 1, marginBottom: 15 }}>
      <Flex direction="row" justify="space-between">
        <Text size="xl">Empresa Inovadora 2024</Text>
        <Flex direction="row" gap={15}>
          <Badge
            radius="md"
            size="xl"
            variant="outline"
            style={{ background: "#E54E4E" }}
            color="rgba(244, 245, 246, 1)"
          >
            <Flex direction="row" align="center" gap={4}>
              <IconFlame color="white" size="14px" />
              <Text tt="capitalize" fz="12px" fw={500} color={"white"}>
                Retirar da lista de finalistas
              </Text>
            </Flex>
          </Badge>
          <Badge
            radius="md"
            size="xl"
            variant="outline"
            style={{ background: "#69C669" }}
            color="rgba(244, 245, 246, 1)"
          >
            <Flex direction="row" align="center" gap={4}>
              <IconStar color="white" size="14px" />
              <Text tt="capitalize" fz="12px" fw={500} color={"white"}>
                Marcar como vencedor
              </Text>
            </Flex>
          </Badge>
          <Badge
            radius="md"
            size="xl"
            variant="outline"
            style={{ background: "#0844F4" }}
            color="rgba(244, 245, 246, 1)"
          >
            <Flex direction="row" align="center" gap={4}>
              <IconListDetails color="white" size="14px" />
              <Text tt="capitalize" fz="12px" fw={500} color={"white"}>
                Retornar para entrevista inicial
              </Text>
            </Flex>
          </Badge>
        </Flex>
      </Flex>
      <Divider my="md" />
      <Grid justify={space}>
        <Grid.Col span={3}>
          <Flex direction="column">
            <Text size="xs">Questionários aplicados</Text>
            <Space h="md" />
            <Group justify={space}>
              <Text size="xl" fw={700}>
                2
              </Text>
              {/* <Group justify="flex-end" align="flex-end" gap="xs">
                <IconChartInfographic color={color} />
                <Text color={color} size="xs">
                  acréscimo de 3,5%
                </Text>
              </Group> */}
            </Group>
          </Flex>
        </Grid.Col>
        <Grid.Col span={3}>
          <Flex direction="column">
            <Text size="xs">Questionários preenchidos</Text>
            <Space h="md" />
            <Group justify={space}>
              <Text size="xl" fw={700}>
                57
              </Text>
              {/* <Group justify="flex-end" align="flex-end" gap="xs">
                <IconChartInfographic color={color} />
                <Text color={color} size="xs">
                  acréscimo de 3,5%
                </Text>
              </Group> */}
            </Group>
          </Flex>
        </Grid.Col>
        <Grid.Col span={3}>
          <Flex direction="column">
            <Text size="xs">Status da empresa no projeto</Text>
            <Space h="md" />
            <Group justify={space}>
              <Flex direction="column">
                <Text fw={700}>#1</Text>
                <Text size="10px">Posição</Text>
              </Flex>
              <Flex direction="column">
                <Text fw={700}>8,4</Text>
                <Text size="10px">Nota média</Text>
              </Flex>
              <Badge
                radius="md"
                size="xl"
                variant="outline"
                style={{ background: "#E54E4E" }}
                color="rgba(244, 245, 246, 1)"
              >
                <Text tt="capitalize" fz="10px" fw={500} color={"white"}>
                  Finalista
                </Text>
              </Badge>
            </Group>
          </Flex>
        </Grid.Col>
      </Grid>
    </Card>
  );
};

export default CardEmpresa;
