import { Avatar, Card, Group, Stack, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import useParsedMarkdownText from "@/hooks/useParsedMarkdownText";
import TotalMoedasPerColaborador from "@/modules/ideias-activities-per-colaborador/components/total-moedas-per-colaborador";
import { formatDate } from "@/utils/date";
import IdeiaComentarioLoadingCard from "./IdeiaComentarioLoadingCard";
import ResponderIdeiaComentarioButton from "./ResponderIdeiaComentarioButton";
import RespostasAoComentarioAvatars from "./RespostasAoComentarioAvatars";
import useGetComentariosRespostas from "../../hooks/useGetComentariosRespostas";

const IdeiaRespostasComentario = ({ ideia, comentario }: { ideia: TIdeia; comentario: TIdeiaComentario }) => {
  const { isLoading, data: comentarios } = useGetComentariosRespostas(ideia, comentario);

  if (isLoading) {
    return <IdeiaComentarioLoadingCard />;
  }

  return (
    <Stack gap="xs" mt="md">
      {comentarios?.map((comentarioResposta) => (
        <IdeiaComentario key={comentarioResposta.id} ideia={ideia} comentario={comentarioResposta} />
      ))}
    </Stack>
  );
};

const IdeiaComentario = ({ comentario, ideia }: { comentario: TIdeiaComentario; ideia: TIdeia }) => {
  const [opened, { toggle }] = useDisclosure(false);
  const htmlComentario = useParsedMarkdownText(comentario.comentario);

  return (
    <Card withBorder>
      <Group gap="sm" align="start">
        <Avatar src="avatar.png" alt={comentario.autor.nome || comentario.autor.email} />
        <Stack gap="xs" style={{ flex: 1 }}>
          <Stack gap={0}>
            <Group justify="space-between" gap={0}>
              <Group>
                <Text fw={500}>{comentario.autor.nome}</Text>
                <Text fz="xs" c="gray">
                  {formatDate(comentario.createdAt, { year: "numeric", month: "long", day: "numeric" })}
                </Text>
              </Group>
              <TotalMoedasPerColaborador
                colaboradorId={comentario.createdBy}
                ideia={ideia}
                color="yellow"
                variant="light"
              />
            </Group>
            <Text fz="xs" tt="uppercase" c="dimmed">
              {comentario.autor.role}
            </Text>
          </Stack>
          {htmlComentario ? <div dangerouslySetInnerHTML={{ __html: htmlComentario }} /> : comentario.comentario}
          <Group>
            <ResponderIdeiaComentarioButton ideia={ideia} comentario={comentario} />
          </Group>
          {opened ? (
            <IdeiaRespostasComentario ideia={ideia} comentario={comentario} />
          ) : (
            <RespostasAoComentarioAvatars ideia={ideia} comentario={comentario} onClick={toggle} />
          )}
        </Stack>
      </Group>
    </Card>
  );
};

export default IdeiaComentario;
