import { Stack } from "@mantine/core";
// prettier-ignore
import { IconHome2, IconChartArrowsVertical, IconScriptPlus, IconQuestionMark, IconSettings } from "@tabler/icons-react";
import { NavLink } from "react-router-dom";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import { dataAutorizacoes } from "@/src/routes/empresa-page/EmpresaPage";
import NavLinkMenu from "./NavLinkMenu";
// prettier-ignore
const AppIncentivoInovacao = ({ toggleMobile }: { toggleMobile: () => void }) => {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresa);
  const activeAutorizacao = useUserStore((store) => store.autorizacoes);
  const uid = getCurrentUser()?.uid;
  const { data: userColaborador } = useGetColaboradorById(activeEmpresaId?.id || "", uid || "");

  const autorizacao = dataAutorizacoes.find(item => item.label === userColaborador?.role);
  const formatAutorizacoes = activeAutorizacao?.filter(item => item.tipo === autorizacao?.value);
  if (activeEmpresaId && activeEmpresaId?.status === "INATIVO") {
    return <></>;
  };

  return (
    <Stack mx="md" mt="lg" gap="xs">
      {formatAutorizacoes?.find(item => item.tipoAutorizacao === "19")?.leitura ? <NavLinkMenu
        label="Início"
        leftSection={<IconHome2 size="1.2rem" stroke={1.5} />}
        component={NavLink}
        onClick={toggleMobile}
        to="/home-incentivo-a-inovacao" /> : <></>}
      {formatAutorizacoes?.find(item => item.tipoAutorizacao === "20")?.leitura ? <NavLinkMenu
        label="Tipos de Inovação"
        leftSection={<IconScriptPlus size="1.2rem" stroke={1.5} />}
        component={NavLink}
        onClick={toggleMobile}
        to="/tipos-de-inovacao"
      /> : <></>}
      {formatAutorizacoes?.find(item => item.tipoAutorizacao === "21")?.leitura ? <NavLinkMenu
        label="Jogadas de Inovação"
        leftSection={<IconChartArrowsVertical size="1.2rem" stroke={1.5} />}
        component={NavLink}
        onClick={toggleMobile}
        to="/jogadas-de-inovacao"
      /> : <></>}
      <NavLinkMenu label="Configurações" childrenOffset={28} leftSection={<IconSettings size="1.2rem" stroke={1.5} />}>
        {formatAutorizacoes?.find(item => item.tipoAutorizacao === "11")?.leitura ? <NavLinkMenu label="Categorias de inovação" component={NavLink} onClick={toggleMobile} to="/categorias" /> : <></>}
        {formatAutorizacoes?.find(item => item.tipoAutorizacao === "12")?.leitura ? <NavLinkMenu label="Tipos de jogada" component={NavLink} onClick={toggleMobile} to="/tiposDeJogada" /> : <></>}
      </NavLinkMenu>
      <NavLinkMenu
        label="Sobre"
        leftSection={<IconQuestionMark size="1.2rem" stroke={1.5} />}
        component={NavLink}
        onClick={toggleMobile}
        to="/sobre-incentivo-a-inovacao" />
    </Stack>
  )
};

export default AppIncentivoInovacao;
