import { Button, Card, Group, Select, Stack, Text, Title } from "@mantine/core";
import { useInputState } from "@mantine/hooks";
import { IconChevronDown } from "@tabler/icons-react";
import { MouseEvent } from "react";
import IdeiaComentario from "./IdeiaComentario";
import IdeiaComentarioLoadingCard from "./IdeiaComentarioLoadingCard";
import useGetComentarios from "./useGetComentarios";

const sortByOptions = [
  { label: "Últimos comentários", value: "createdAt,desc" },
  { label: "Primeiros comentários", value: "createdAt" }
];

const IdeiaComentarios = ({ ideia }: { ideia: TIdeia }) => {
  const [sortBy, setSortBy] = useInputState("createdAt,desc");
  const {
    data: ideiaComentariosPages,
    fetchNextPage,
    isFetching,
    hasNextPage,
    isLoading
  } = useGetComentarios(ideia, { sortBy });

  const ideiaComentarios = ideiaComentariosPages?.pages.flatMap((page) => page.entries);

  const noData = !isLoading && ideiaComentarios && ideiaComentarios.length === 0;
  if (noData) {
    return null;
  }

  /**
   * Handle next page click
   * @param event - Click event
   */
  function handleNextPageClick(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    void fetchNextPage();
  }

  return (
    <Card withBorder my="md" mb="xl">
      <Title order={4}>Comentários sobre a ideia</Title>
      {isLoading && <IdeiaComentarioLoadingCard />}
      <Card bg="gray.2" py="xs" mt="md">
        <Group align="center">
          <Text fz="sm">Ordenar por</Text>
          <Select size="xs" data={sortByOptions} value={sortBy} onChange={setSortBy} />
        </Group>
      </Card>
      {ideiaComentarios && (
        <Stack gap="xs" mt="md">
          {ideiaComentarios.map((comentario) => (
            <div id={`comentario-${comentario.id}`} key={comentario.id}>
              <IdeiaComentario ideia={ideia} comentario={comentario} />
            </div>
          ))}
        </Stack>
      )}
      {hasNextPage && (
        <Group mt="md">
          <Button
            variant="transparent"
            size="xs"
            rightSection={<IconChevronDown size="1rem" />}
            onClick={handleNextPageClick}
            loading={isLoading || isFetching}
          >
            Ver mais comentários
          </Button>
        </Group>
      )}
    </Card>
  );
};

export default IdeiaComentarios;
