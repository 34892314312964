import { httpsCallable } from "firebase/functions";
import { Asserts } from "yup";
import { functions } from "@/base";
import colaboradorDiagnosticoSchema from "./schema/colaborador-diagnostico-schema";

type InviteColaboradoresInputs = Asserts<typeof colaboradorDiagnosticoSchema> & {
  idEmpresa: string;
  idQuestionario: string;
  idProjeto: string;
  empresaId: string;
  licenciadaId: string;
};
export const inviteColaboradorDiagnostico = httpsCallable<InviteColaboradoresInputs, never>(
  functions,
  "colaboradoresFunctions-inviteColaboradorDiagnostico"
);
