import { subject } from "@casl/ability";
import { Anchor, Badge, Group, Text } from "@mantine/core";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { NavLink } from "react-router-dom";
import useGetDocumentFromReferencePath from "@/hooks/useGetDocumentFromReferencePath";
import { desafioCategoriaConverter, QUERY_KEY } from "@/modules/desafio-categorias/firestore";
import { formatDate } from "@/utils/date";
import ContextMenuRow from "./ContextMenuRow";
import DesafioStatusBadge from "../desafio-status-badge";

const columnHelper = createColumnHelper<TDesafio>();

const DesafioCategoriaBadge = ({ categoriaReference }: { categoriaReference: string | null }) => {
  const { data: categoria } = useGetDocumentFromReferencePath({
    referencePath: typeof categoriaReference === "string" ? categoriaReference : null,
    converter: desafioCategoriaConverter,
    queryKey: [QUERY_KEY],
    subjectType: "TDesafioCategoria"
  });

  return <Badge variant="default">{categoria ? categoria.nome : ""}</Badge>;
};

const columns: ColumnDef<TDesafio>[] = [
  columnHelper.accessor("titulo", {
    header: "Titulo",
    enableSorting: true,
    enableColumnFilter: true,
    cell({ row, getValue }) {
      const id = row.original.id;
      return (
        <Anchor component={NavLink} to={`/desafios/${id}`}>
          {getValue()}
        </Anchor>
      );
    }
  }),
  columnHelper.accessor("categoriaReference", {
    header: "Categoria",
    enableSorting: false,
    enableColumnFilter: false,
    cell({ getValue }) {
      const categoriaReferencePath = getValue();
      return (
        <DesafioCategoriaBadge
          categoriaReference={typeof categoriaReferencePath === "string" ? categoriaReferencePath : null}
        />
      );
    }
  }),
  columnHelper.accessor("status", {
    header: "Status",
    enableSorting: false,
    enableColumnFilter: false,
    cell({ getValue }) {
      const status = getValue();
      return (
        <Group gap="xs">
          <DesafioStatusBadge status={typeof status === "object" ? "DRAFT" : status} />
        </Group>
      );
    }
  }),
  columnHelper.accessor("publishedAt", {
    header: "Publicado",
    enableSorting: true,
    enableColumnFilter: true,
    cell({ getValue }) {
      const publishedAt = getValue();
      return publishedAt ? (
        <Text>{formatDate(publishedAt, { year: "numeric", month: "short", day: "numeric" })}</Text>
      ) : (
        <Badge color="gray" size="sm">
          Em Rascunho
        </Badge>
      );
    }
  }),
  columnHelper.display({
    id: "actions",
    cell: (properties) => {
      const desafio = subject("TDesafio", properties.row.original);

      return (
        <Group justify="flex-end" gap={2}>
          <ContextMenuRow desafio={desafio} />
        </Group>
      );
    }
  })
];

export default columns;

export const desafioColumnsMobile: ColumnDef<TDesafio>[] = [
  columnHelper.accessor("titulo", {
    header: "Titulo",
    enableSorting: true,
    enableColumnFilter: true,
    cell({ row, getValue }) {
      const id = row.original.id;
      return (
        <Anchor component={NavLink} to={`/desafios/${id}`}>
          {getValue()}
        </Anchor>
      );
    }
  }),
  columnHelper.accessor("status", {
    header: "Status",
    enableSorting: false,
    enableColumnFilter: false,
    cell({ getValue }) {
      const status = getValue();
      return (
        <Group gap="xs">
          <DesafioStatusBadge status={typeof status === "object" ? "DRAFT" : status} iconOnly={true} />
        </Group>
      );
    }
  }),
  columnHelper.accessor("publishedAt", {
    header: "Publicado",
    enableSorting: true,
    enableColumnFilter: true,
    cell({ getValue }) {
      const publishedAt = getValue();
      return publishedAt ? (
        <Text>{formatDate(publishedAt, { year: "numeric", month: "short", day: "numeric" })}</Text>
      ) : (
        <Badge color="gray" size="sm">
          Em Rascunho
        </Badge>
      );
    }
  }),
  columnHelper.display({
    id: "actions",
    cell: (properties) => {
      const desafio = subject("TDesafio", properties.row.original);

      return (
        <Group justify="flex-end" gap={2}>
          <ContextMenuRow desafio={desafio} />
        </Group>
      );
    }
  })
];
