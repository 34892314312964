import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getQuestionarioAplicadoArray } from "@/modules/grupos-diagnostico/firestore";
import { QUERY_KEY } from "@/modules/questionarios/components/questionario-table/QuestionarioTable";
import useUserStore from "@/modules/users/store";

type TGetQuestionarioResponse = Awaited<ReturnType<typeof getQuestionarioAplicadoArray>>;

/**
 * Get questionário de inovação query hook
 * @param id - id do questionario aplicado
 * @param id.id - id do questionario aplicado
 * @returns Questionário de inovação query hook
 */
function useGetQuestionarios({ id }: { id: TProjeto["id"] | undefined }) {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  return useQuery<TGetQuestionarioResponse, FirestoreError, TQuestionario[]>({
    queryKey: ["empresas", { id: activeEmpresaId }, QUERY_KEY, id],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      return getQuestionarioAplicadoArray(activeEmpresaId, id);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }
      return dataResponse.docs.map((document_) => document_.data());
    }
  });
}

export default useGetQuestionarios;
