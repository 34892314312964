/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-magic-numbers */
import { Flex } from "@mantine/core";
import { ResponsivePie } from "@nivo/pie";

interface Data {
  id: string;
  label: string;
  value: number;
}

const PieChartComponent = ({ data, type }: { data: Data[]; type: number }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const colors: any = type === 0 ? "paired" : type < 4 && type > 0 ? `set${type}` : "accent";

  return (
    <>
      <Flex h={400} justify="center">
        <ResponsivePie
          data={data}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          colors={{ scheme: colors }}
          borderColor={{
            from: "color",
            modifiers: [["darker", MAGIC_NUMBER_MEDIA]]
          }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{
            from: "color",
            modifiers: [["darker", MAGIC_NUMBER]]
          }}
          defs={defs}
          // eslint-disable-next-line react-perf/jsx-no-new-array-as-prop
          legends={[
            {
              anchor: "bottom",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: 56,
              itemsSpacing: 0,
              itemWidth: 200,
              itemHeight: 18,
              itemTextColor: "#999",
              itemDirection: "left-to-right",
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: "circle",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "#000"
                  }
                }
              ]
            }
          ]}
        />
      </Flex>
    </>
  );
};

export default PieChartComponent;

const defs = [
  {
    id: "dots",
    type: "patternDots",
    background: "inherit",
    color: "rgba(255, 255, 255, 0.3)",
    size: 4,
    padding: 1,
    stagger: true
  },
  {
    id: "lines",
    type: "patternLines",
    background: "inherit",
    color: "rgba(255, 255, 255, 0.3)",
    rotation: -45,
    lineWidth: 6,
    spacing: 10
  }
];

const MAGIC_NUMBER_MEDIA = 0.2;
const MAGIC_NUMBER = 2;
