import {
  Box,
  Button,
  Card,
  Checkbox,
  Collapse,
  Group,
  LoadingOverlay,
  MultiSelect,
  SimpleGrid,
  Stack,
  Switch,
  TextInput,
  Textarea,
  Title
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { PropsWithChildren, useEffect } from "react";
import ColaboradorMultiSelector from "@/modules/colaboradores/components/colaborador-multi-selector-ideias";
import ColaboradorSelector from "@/modules/colaboradores/components/colaborador-selector";
import useGetActiveRegulamentosIdeias from "@/modules/regulamento-ideias/hooks/useGetActiveRegulamentosIdeias";
import useUserStore from "@/modules/users/store";
import { extractBaseInputPropertiesFromFormSchema, extractTextInputPropertiesFromFormSchema } from "@/utils/form";
import { useIdeiaFormContext } from "./ideia-form-context";
import IdeiaAnexos from "./IdeiaAnexos";
import ideiaSchema, { IDEIA_OBJETIVOS } from "../../schema/ideia-schema";
import "./styles/textarea.css";

const IDEIA_OBJETIVOS_ARRAY = [...IDEIA_OBJETIVOS.entries()];
const EMPTY_ARRAY: string[] = [];

type IdeiaFormFieldsProperties = PropsWithChildren<{ isLoading: boolean; readOnly?: boolean }>;

/**
 * Sync regulamento reference with form values
 * @returns - Regulamento
 */
function useSyncRegulamentoReference() {
  return useGetActiveRegulamentosIdeias<TRegulamentoIdeias | undefined>({
    select(dataSnapshot) {
      if (dataSnapshot.length === 0) {
        return undefined;
      }

      const [firstRegulamento] = dataSnapshot;
      if (!firstRegulamento) {
        throw new Error("Regulamento não encontrado");
      }
      return firstRegulamento.data();
    }
  });
}

// eslint-disable-next-line complexity, sonarjs/cognitive-complexity
const IdeiaFormFields = ({ isLoading, children, readOnly = false }: IdeiaFormFieldsProperties) => {
  const [opened, { toggle }] = useDisclosure(!readOnly);
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);
  const activeEmpresa = useUserStore((state) => state.activeEmpresa);
  const { values, setFieldValue, getInputProps } = useIdeiaFormContext();

  const fieldsDescriptor = ideiaSchema.describe();

  const { data: regulamento, isLoading: isLoadingRegulamento } = useSyncRegulamentoReference();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  const formatValue = getInputProps("autorReference").value?.split("/")[3] as string;
  const newValue = `empresas/${activeEmpresaId}/colaboradores/${formatValue}`;
  const descricaoProblema = getInputProps("descricaoProblema").value as string[];

  useEffect(() => {
    const regulamentoReferencePath =
      typeof values.regulamentoReference === "string" ? values.regulamentoReference : values.regulamentoReference?.path;
    const hasRegulamentoDifferent = regulamentoReferencePath !== regulamento?.refPath;
    if (!isLoading && regulamento && hasRegulamentoDifferent) {
      setFieldValue("regulamentoReference", regulamento.refPath);
    }
  }, [isLoading, regulamento, setFieldValue, values.regulamentoReference]);

  // prettier-ignore
  return (
    <Stack gap="md" mb="lg">
      {readOnly && descricaoProblema.length > 0 && (
        <Stack gap="xs">
          <Group justify="space-between" mb={5}>
            <Title order={4}>Informações do cadastro de ideias</Title>
            <Button
              type="button"
              variant="transparent"
              size="compact-sm"
              rightSection={opened ? <IconChevronUp size="1rem" /> : <IconChevronDown size="1rem" />}
              onClick={toggle}
            >
              Ver mais
            </Button>
          </Group>
          {children}
        </Stack>
      )}
      {(!readOnly && regulamento?.permitirIdeiaPrivada) &&  (
        <Checkbox
          checked={getInputProps("privado").value as boolean}
          {...getInputProps("privado")}
          {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "privado")}
        />
      )}
      <Group>
        <MultiSelect
          data={activeEmpresa?.setores}
          style={{ flex: 1 }}
          readOnly={readOnly || !!values.id}
          variant={readOnly ? "unstyled" : "default"}
          {...getInputProps("setores")}
          {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "setores")}
        />

        {readOnly && (
          <ColaboradorSelector
            searchable
            style={{ flex: 1 }}
            clearable={false}
            allowDeselect={false}
            readOnly={readOnly}
            variant={readOnly ? "unstyled" : "default"}
            nothingFoundMessage="Nenhum colaborador encontrado..."
            {...getInputProps("autorReference")}
            {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "autorReference")}
            value={newValue}
          />
        )}
      </Group>

      <TextInput
        type="text"
        style={{ flex: 1 }}
        readOnly={readOnly}
        variant={readOnly ? "unstyled" : "default"}
        {...getInputProps("titulo")}
        {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "titulo")}
      />
      <Textarea
        style={{ flex: 1 }}
        readOnly={readOnly}
        variant={readOnly ? "unstyled" : "default"}
        className="textarea-lg"
        {...getInputProps("descricao")}
        {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "descricao")}
      />
        <Collapse in={opened}>
        <Stack gap="md">
          <Checkbox.Group
            defaultValue={EMPTY_ARRAY}
            {...getInputProps("objetivo")}
            {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "objetivo")}
          >
            <SimpleGrid cols={2} spacing="sm" verticalSpacing="sm" mt="md">
              {readOnly
                ? values.objetivo.map((objetivo) => (
                  <Checkbox key={objetivo} value={objetivo} variant="unstyled" checked={true} label={objetivo} />
                ))
                : IDEIA_OBJETIVOS_ARRAY.map(([objetivoKey, objetivoLabel]) => (
                  <Checkbox key={objetivoKey} value={objetivoKey} label={objetivoLabel} />
                ))}
            </SimpleGrid>
          </Checkbox.Group>
          <Textarea
            readOnly={readOnly}
            variant={readOnly ? "unstyled" : "default"}
            className="textarea-lg"
            {...getInputProps("comoAtingirObjetivo")}
            {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "comoAtingirObjetivo")}
          />
          <Textarea
            readOnly={readOnly}
            variant={readOnly ? "unstyled" : "default"}
            className="textarea-lg"
            {...getInputProps("descricaoProblema")}
            {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "descricaoProblema")}
          />
          <Textarea
            readOnly={readOnly}
            variant={readOnly ? "unstyled" : "default"}
            className="textarea-sm"
            {...getInputProps("diferenciais")}
            {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "diferenciais")}
          />
          <Textarea
            readOnly={readOnly}
            variant={readOnly ? "unstyled" : "default"}
            className="textarea-sm"
            {...getInputProps("feedback")}
            {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "feedback")}
          />
          <ColaboradorMultiSelector
            style={{ flex: 1 }}
            searchable
            multiple
            readOnly={readOnly}
            variant={readOnly ? "unstyled" : "default"}
            nothingFoundMessage="Nenhum participante encontrado..."
            {...getInputProps("participantesReferences")}
            {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "participantesReferences")}
            description={"Preencha este campo caso haja mais participantes além de você."}
          />
          <IdeiaAnexos isLoading={isLoading} readOnly={readOnly} />
          <Box pos="relative">
            {isLoadingRegulamento && <LoadingOverlay visible={true} />}
            {regulamento?.permitirControlarInteracao && (
              <Card withBorder mt="lg">
                <Group justify="space-between">
                  <Switch
                    disabled={readOnly}
                    defaultChecked={values.permitirComentarios}
                    variant={readOnly ? "unstyled" : "default"}
                    onLabel="Sim"
                    offLabel="Não"
                    size="md"
                    {...getInputProps("permitirComentarios")}
                    {...extractBaseInputPropertiesFromFormSchema(fieldsDescriptor, "permitirComentarios")}
                  />
                  <Switch
                    disabled={readOnly}
                    variant={readOnly ? "unstyled" : "default"}
                    defaultChecked={values.permitirPatrocinio}
                    onLabel="Sim"
                    offLabel="Não"
                    size="md"
                    {...getInputProps("permitirPatrocinio")}
                    {...extractBaseInputPropertiesFromFormSchema(fieldsDescriptor, "permitirPatrocinio")}
                  />
                </Group>
              </Card>
            )}
          </Box>
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default IdeiaFormFields;
