import { subject } from "@casl/ability";
import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { QUERY_KEY } from "@/modules/tipos-de-respostas/components/tipos-de-respostas-table/TiposDeRespostasTable";
import useUserStore from "@/modules/users/store";
import { getGrupos } from "../../firestore";

type TGetTiposDeRespostasResponse = Awaited<ReturnType<typeof getGrupos>>;

/**
 * Get questionário de inovação query hook
 * @returns Questionário de inovação query hook
 */
function useQueryGrupo() {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  return useQuery<TGetTiposDeRespostasResponse, FirestoreError, TGruposDiagnostico[]>({
    queryKey: ["empresas", { id: activeEmpresaId }, QUERY_KEY],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      return getGrupos(activeEmpresaId, undefined);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }
      return dataResponse.docs.map((document_) => subject("TGruposDiagnostico", document_.data()));
    }
  });
}

export default useQueryGrupo;
