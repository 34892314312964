import { Anchor, Card, Flex, Grid, Group, Space, Text } from "@mantine/core";
import { IconEye } from "@tabler/icons-react";
import { NavLink, useParams } from "react-router-dom";
import RespostaComponente from "./RespostaComponente";
import TextAcesso from "./TextAcesso";
import useGetProjetoItem from "./useGetProjetoNew";

const QuestionarioItem = ({ questionario }: { questionario: TQuestionario }) => {
  const { data } = useGetProjetoItem({ idProjeto: questionario.idProjeto });
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <Space h="md" />
      <Card withBorder shadow="md" style={{ flex: 1 }}>
        <Grid align="center">
          <Grid.Col span={2}>
            <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
              <Text fw={300} fz="xs">
                Nome do projeto
              </Text>
              <Text fw={500} fz="md">
                {data?.nome}
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={2}>
            <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
              <Text fw={300} fz="xs">
                Nome do questionário
              </Text>
              <Text fw={500} fz="md">
                {questionario.nome}
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={2}>
            <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
              <Text fw={300} fz="xs">
                Colaboradores convidados
              </Text>
              <Text fw={500} fz="md">
                0
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={2}>
            <TextAcesso id={questionario?.id} />
          </Grid.Col>
          <Grid.Col span={2}>
            <RespostaComponente id={questionario?.id} fw={500} fz="md" />
          </Grid.Col>
          <Grid.Col span={2}>
            <Group align={"center"} justify={"center"}>
              <Anchor component={NavLink} to={`/analisarListaQuestionarios/${id}/${questionario?.id}`}>
                <IconEye color="rgba(57, 106, 249, 1)" />
              </Anchor>
            </Group>
          </Grid.Col>
        </Grid>
      </Card>
    </>
  );
};

export default QuestionarioItem;
