/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import { Text } from "@mantine/core";
import { useMemo } from "react";
import { QuestionarioPage } from "./QuestionarioContent";
import QuestionarioGrupo from "./QuestionarioGrupo";
import useQuestionarioStepper from "../../hooks/useQuestionarioStepper";
import { ArrayProperties, TRespostaField } from "../../types";
import PerguntasComponente from "../pergunta-tipos/PerguntasComponente";

type Properties = {
  controller: ReturnType<typeof useQuestionarioStepper>;
  licenciado: TLicenciado | undefined;
  pages: QuestionarioPage[];
  respostas: TResposta["perguntas"];
  resposta: TResposta | undefined;
  onChangeResposta: (perguntaId: string, value: TRespostaField) => void;
  empresaId: TEmpresa["id"];
};

/**
 * QuestionarioPages
 * @param root0 Properties
 * @param root0.controller - controller
 * @param root0.pages - pages
 * @param root0.licenciado - licenciado
 * @param root0.respostas - respostas
 * @param root0.onChangeResposta - onChangeResposta
 * @param root0.resposta - resposta
 * @param root0.empresaId - empresaId
 * @returns QuestionarioPages
 */
function QuestionarioPages({
  controller,
  licenciado,
  pages,
  respostas,
  resposta: respostaUser,
  empresaId,
  onChangeResposta
}: Properties) {
  const { getCurrentPage, step } = controller;

  const currentPage = useMemo(getCurrentPage, [pages, step]);

  if (!currentPage) {
    return <Text>Não foi possível encontrar a página</Text>;
  }

  if (currentPage.type === "grupo") {
    return <QuestionarioGrupo grupo={currentPage as TGruposDiagnostico} licenciado={licenciado} />;
  }

  if (currentPage.type === "pergunta") {
    const pergunta = currentPage as TPergunta;
    const resposta = respostas.find((item) => item.id === pergunta.id);

    const item = { ...pergunta, ...resposta, value: step };
    const changeValues = (value: TRespostaField, item: ArrayProperties) => {
      onChangeResposta(item.id, value);
    };

    return (
      <PerguntasComponente
        item={item}
        licenciado={licenciado}
        disabled={!!respostaUser && !respostaUser.pausado}
        changeValues={changeValues}
        empresaId={empresaId}
      />
    );
  }

  return <Text>Houve um problema inesperado</Text>;
}

export default QuestionarioPages;
