import { Badge, Group, Text } from "@mantine/core";
import RankingTrendIconLikes from "@/components/ranking-trend-icon/RankingTrendIconLikes";

const RankingByMoedasBadge = ({ ideia }: { ideia: TIdeia }) => {
  if (!ideia.rankingByMoedas) {
    return null;
  }

  return (
    <Group align="center" gap="xs">
      <Badge color="gray.2" py="sm" radius="md" leftSection={<RankingTrendIconLikes ideia={ideia} />}>
        <Text c="dark" fz="xs" fw={500} tt="lowercase">
          {ideia.rankingByLikes?.ranking}&#9900; no Ranking
        </Text>
      </Badge>
    </Group>
  );
};

export default RankingByMoedasBadge;
