import { Card, Group, Title } from "@mantine/core";
import ColaboradoresInlineList from "@/modules/colaboradores/components/colaboradores-inline-list";
import useUserStore from "@/modules/users/store";
import ParticiparDesafioButton from "./ParticiparDesafioButton";
import RemoverParticipacaoDesafioButton from "./RemoverParticipacaoDesafioButton";
// prettier-ignore
const DesafioParticipantesCard = ({ desafio }: { desafio: TDesafio }) => {
  const currentUser = useUserStore((state) => state.colaborador);
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);
  const participantesReferencesPath = desafio.participantesReferences.map((participante) =>
    typeof participante === "string" ? participante : participante.refPath
  );
  participantesReferencesPath.push(
    typeof desafio.propositorReference === "string" ? desafio.propositorReference : desafio?.propositorReference?.refPath
  );

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  const formatValue = currentUser?.refPath?.split("/")[3] as string;
  const newValue = `empresas/${activeEmpresaId}/colaboradores/${formatValue}`;

  const propositorReferencePath =
    typeof desafio.propositorReference === "string" ? desafio.propositorReference : desafio?.propositorReference?.refPath;
  const imAnPropositor = currentUser?.refPath === propositorReferencePath;
  const imAnParticipante = participantesReferencesPath.includes(newValue || "");

  return (
    <Card withBorder>
      <Title order={4}>Participantes</Title>
      <Group justify="space-between" mt="xs">
        <ColaboradoresInlineList colaboradores={participantesReferencesPath} />
        {imAnParticipante && !imAnPropositor && <RemoverParticipacaoDesafioButton desafio={desafio} />}
        {!imAnParticipante && <ParticiparDesafioButton desafio={desafio} />}
      </Group>
    </Card>
  );
};

export default DesafioParticipantesCard;
