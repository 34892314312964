import { ActionIcon } from "@mantine/core";
import { IconRefresh } from "@tabler/icons-react";
import { QueryKey, useIsFetching, useQueryClient } from "@tanstack/react-query";
import { MouseEvent, forwardRef } from "react";

const RefreshQueryButton = forwardRef<HTMLButtonElement, { queryKey: QueryKey }>(({ queryKey }, reference) => {
  const queryClient = useQueryClient();
  /**
   * Handle the click event
   * @param event - The event object
   */
  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    void queryClient.invalidateQueries({ queryKey });
  }

  const loadingCount = useIsFetching({ queryKey });

  return (
    <ActionIcon
      color="dark"
      variant="outline"
      size="lg"
      onClick={handleClick}
      loading={loadingCount > 0}
      ref={reference}
    >
      <IconRefresh size="1.125rem" />
    </ActionIcon>
  );
});

RefreshQueryButton.displayName = "RefreshQueryButton";

export default RefreshQueryButton;
