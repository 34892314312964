import { subject } from "@casl/ability";
import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { getGrupoResposta } from "@/modules/diagnosticos/firestore";

type TGetRespostasResponse = Awaited<ReturnType<typeof getGrupoResposta>>;

const QUERY_KEY = "respostas";

/**
 * Get respostas query hook
 * @param root0 - root
 * @param root0.id - id
 * @returns Respostas query hook
 */
function useQueryRespostasEmpresa({ id }: { id: TQuestionario["id"] }) {
  const { idEmpresa } = useParams<{ idEmpresa: string }>();

  return useQuery<TGetRespostasResponse, FirestoreError, TResposta[]>({
    queryKey: ["empresas", { id: idEmpresa }, QUERY_KEY, id, idEmpresa],
    queryFn() {
      if (!idEmpresa) {
        throw new Error("Empresa não selecionada");
      }

      if (!id) {
        throw new Error("Empresa não selecionada");
      }

      return getGrupoResposta(idEmpresa || "", id, idEmpresa || "");
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }
      return dataResponse.docs.map((document_) => subject("TResposta", document_.data()));
    }
  });
}

export default useQueryRespostasEmpresa;
