import { Anchor, Avatar, Badge, Card, Flex, Grid, Group, Image, Space, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import NotaMedia from "@/modules/diagnostico/components/ranking-item/NotaMedia";
import getStatusIdEmpresa from "@/modules/diagnostico/components/ranking-item/useGetStatusIdEmpresa";
import useGetQuestionarios from "@/modules/questionario-de-inovacao/components/projeto-item/useGetQuestionarios";
import useUserStore from "@/modules/users/store";
import useGetQuestionariosRespondidos from "./useGetQuestionariosRespondidos";
import useGetEmpresaById from "../ranking-list/useGetEmpresas";

interface Fase {
  id: string;
  cor: string;
  titulo: string;
}

interface Media {
  media: number;
}

const message = "Ocorreu um erro ao carregar os dados:";

const RankingItem = ({
  empresa,
  projeto,
  posicao
}: {
  empresa: TConvite & Media;
  projeto: TProjeto | null | undefined;
  posicao: number;
}) => {
  const id = useUserStore((state) => state.activeProjeto);
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const { data } = useGetEmpresaById(empresa.idEmpresaColaboradorConvidado);
  const [statusArray, setStatusArray] = useState<TStatus[]>([]);
  // const { data: statusArray } = useGetStatusIdEmpresa({ idEmpresaItem: empresa.idEmpresaColaboradorConvidado });
  const statusItem = statusArray && statusArray[0];
  const fases = projeto && projeto.fases;
  const selectFase = (fases && (fases?.find((item: Fase) => item?.id === statusItem?.status) as Fase)) || false;
  const { data: questionarios } = useGetQuestionarios({ id: id || "" });
  const { data: respondidos } = useGetQuestionariosRespondidos(questionarios?.map((item) => item?.id));

  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadData();
      } catch (error) {
        console.error(message, error);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, [id, activeEmpresaId, empresa.idEmpresaColaboradorConvidado]);

  const loadData = async () => {
    const arrayStatus = await getStatusIdEmpresa({
      idEmpresaItem: empresa.idEmpresaColaboradorConvidado,
      id: id || "",
      activeEmpresaId: activeEmpresaId || ""
    });
    setStatusArray(arrayStatus);
  };

  return (
    <>
      <Card withBorder shadow="md">
        <Grid align="center">
          <Grid.Col span={1}>
            <Group>
              <Text fz="md" fw={700}>
                #{posicao + 1}º
              </Text>
            </Group>
          </Grid.Col>
          <Grid.Col span={3}>
            <Group>
              {data && data.image && data.image.length > 0 ? (
                <>
                  <Avatar color="cyan" radius="xl">
                    <Image radius="md" src={data?.image[0]?.path as string} />
                  </Avatar>
                </>
              ) : (
                <Avatar color="cyan" radius="xl">
                  {data?.nomeFantasia.charAt(0)}
                </Avatar>
              )}
              <Anchor component={NavLink} to={`/analisarQuestionarios/${data?.id}/${id}`} color={"#000"}>
                <Text fz="md">{data?.nomeFantasia}</Text>
              </Anchor>
            </Group>
          </Grid.Col>
          <Grid.Col span={2}>
            <Group align={"center"} justify={"center"}>
              <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
                <Text fw={300} fz="xs">
                  Status
                </Text>
                {selectFase && selectFase.id ? (
                  <Badge color={selectFase.cor} radius="sm">
                    <Text size="xs" tt="capitalize">
                      {selectFase && selectFase.titulo}
                    </Text>
                  </Badge>
                ) : (
                  <Badge color="blue" radius="sm">
                    <Text size="xs" tt="capitalize">
                      Inscrição
                    </Text>
                  </Badge>
                )}
              </Flex>
            </Group>
          </Grid.Col>
          <Grid.Col span={2}>
            <Group align={"center"} justify={"center"}>
              <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
                <Text fw={300} fz="xs">
                  Questionários respondidos
                </Text>
                <Text fw={500} fz="md">
                  {respondidos?.filter((item) => item.idEmpresa === data?.id)?.length || 0}
                </Text>
              </Flex>
            </Group>
          </Grid.Col>
          <Grid.Col span={4}>
            <NotaMedia media={empresa?.media} />
          </Grid.Col>
        </Grid>
      </Card>
      <Space h="md" />
    </>
  );
};

export default RankingItem;
