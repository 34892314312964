import { subject } from "@casl/ability";
import { SortingState, PaginationState } from "@tanstack/react-table";
import {
  DocumentReference,
  FirestoreDataConverter,
  Timestamp,
  addDoc,
  collection,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where
} from "firebase/firestore";
import { getCurrentUser } from "@/services/auth";
import { generateQuery, getDocumentReferenceFromPath } from "@/utils/firestore";
import { getDesafiosByRegulamento } from "../desafios/firestore";
import { getTotalMoedasSpentForColaborador } from "../desafios-activities-per-colaborador/firestore";
import { getEmpresaDocumentReference } from "../empresas/firestore";

export const REGULAMENTOS_DESAFIOS_COLLECTION_KEY = "regulamentosDesafios" as const;
const DEFAULT_LIMIT = 10;
export const DEFAULT_SORTING: SortingState = [{ id: "createdAt", desc: true }];
export const DEFAULT_PAGINATION: PaginationState = { pageIndex: 0, pageSize: DEFAULT_LIMIT };

export type TRegulamentoDesafiosForm = TFormWithTransformations<TRegulamentoDesafios, "deletedAt">;

type TRegulamentoDesafiosDocument = TFirestoreDocument<TRegulamentoDesafios>;

const regulamentoDesafiosConverter: FirestoreDataConverter<TRegulamentoDesafios> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const { createdAt, updatedAt, deletedAt, dataInicial, dataFinal, ...document } =
      snap.data() as TRegulamentoDesafiosDocument;

    const data: TRegulamentoDesafios = {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      deletedAt: deletedAt?.toDate() ?? null,
      dataInicial: dataInicial.toDate(),
      dataFinal: dataFinal.toDate(),
      refPath: snap.ref.path
    };

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }

    return subject("TRegulamentoDesafios", data);
  }
};

/**
 * Get regulamentosDesafios collection reference
 * @param empresaId  - Empresa id
 * @returns - RegulamentosDesafios collection reference
 */
function getRegulamentosDesafiosCollection(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, REGULAMENTOS_DESAFIOS_COLLECTION_KEY);
}

/**
 * Get a regulamentoDesafios document reference
 * @param empresaId - Empresa id
 * @param regulamentoDesafiosId - RegulamentoDesafios id to get
 * @returns - Regulamento Desafios document reference
 */
function getRegulamentoDesafiosDocumentReference(
  empresaId: TEmpresa["id"],
  regulamentoDesafiosId: TRegulamentoDesafios["id"]
) {
  const regulamentoDesafiosCollectionReference =
    getRegulamentosDesafiosCollection(empresaId).withConverter(regulamentoDesafiosConverter);
  return doc(regulamentoDesafiosCollectionReference, regulamentoDesafiosId);
}

/**
 * Get a regulamentoDesafios document reference from a reference path
 * @param rePath - RegulamentoDesafios reference path
 * @returns - RegulamentoDesafios document
 */
export function getRegulamentoDocumentReferenceFromReferencePath(rePath: string) {
  return getDocumentReferenceFromPath(rePath, regulamentoDesafiosConverter);
}

/**
 * Get a regulamentoDesafios document from a reference path
 * @param rePath - RegulamentoDesafios reference path
 * @returns - RegulamentoDesafios document
 */
export function getRegulamentoDocumentFromReferencePath(rePath: string) {
  const document = getRegulamentoDocumentReferenceFromReferencePath(rePath);
  return getDoc(document);
}

/**
 * Get all regulamentoDesafios from the database
 * @param empresaId - User id
 * @param root0 - Query options
 * @param root0.sorting - Sorting options
 * @param root0.filters - Filters options
 * @param root0.globalFilters - Global filters
 * @param root0.pagination - Pagination options
 * @param root0.paginationCursors - Array of pagination cursors (startAt, endAt)[]
 * @returns - Promise with all regulamentoDesafios
 */
export function getPaginatedRegulamentosDesafios(
  empresaId: string,
  {
    sorting = [],
    filters = [],
    globalFilters,
    pagination = { pageIndex: 0, pageSize: DEFAULT_LIMIT },
    paginationCursors
  }: TPaginatedQueryOptions<TRegulamentoDesafios> = {}
): Promise<TPaginatedQueryResponse<TRegulamentoDesafios>> {
  const queryRegulamentosDesafiosCollectionReference =
    getRegulamentosDesafiosCollection(empresaId).withConverter(regulamentoDesafiosConverter);

  const qAll = generateQuery(queryRegulamentosDesafiosCollectionReference, { sorting, filters, globalFilters });
  const qPaginated = generateQuery(queryRegulamentosDesafiosCollectionReference, {
    sorting,
    filters,
    globalFilters,
    pagination,
    paginationCursors
  });

  // eslint-disable-next-line compat/compat
  return Promise.all([getDocs(qPaginated), getCountFromServer(qAll)]);
}

/**
 * Get the active regulamentoDesafios for a desafio
 * @param empresaId - Empresa ID
 * @returns - Promise with all regulamentoDesafios
 */
export async function getActiveRegulamentosDesafiosForDesafio(empresaId: TEmpresa["id"]) {
  const regulamentosCollectionReference =
    getRegulamentosDesafiosCollection(empresaId).withConverter(regulamentoDesafiosConverter);

  const todayAtBeginning = new Date();
  todayAtBeginning.setHours(0, 0, 0, 0);

  const todayAtEnd = new Date(todayAtBeginning);
  todayAtEnd.setDate(todayAtEnd.getDate() + 1);

  const queryRegulamentosStarted = query(
    regulamentosCollectionReference,
    where("deletedAt", "==", null),
    where("dataInicial", "<=", Timestamp.fromDate(todayAtBeginning))
  );
  const queryRegulamentosNotEnded = query(
    regulamentosCollectionReference,
    where("deletedAt", "==", null),
    where("dataFinal", ">=", Timestamp.fromDate(todayAtEnd))
  );

  // eslint-disable-next-line compat/compat
  const [startedRegulamentos, notEndedRegulamentos] = await Promise.all([
    getDocs(queryRegulamentosStarted),
    getDocs(queryRegulamentosNotEnded)
  ]);

  return startedRegulamentos.docs.filter((document_) =>
    notEndedRegulamentos.docs.some((dateDocument) => dateDocument.id === document_.id)
  );
}

/**
 * Sum the total votes for the colaborador from all desafios in the regulamento document
 * @param empresaId - Empresa ID to get the total votes
 * @param colaboradorId - Colaborador ID to get the total votes
 * @param regulamentoDocumentReference - Regulamento document reference to get the total votes
 * @returns - Total votes for the colaborador in the regulamento document
 */
export async function getTotalMoedasForRegulamentoDesafios(
  empresaId: TEmpresa["id"],
  colaboradorId: TColaborador["id"],
  regulamentoDocumentReference: DocumentReference<TRegulamentoDesafios>
) {
  const allDesafiosSameRegulamento = await getDesafiosByRegulamento(empresaId, regulamentoDocumentReference);

  return getTotalMoedasSpentForColaborador(empresaId, colaboradorId, allDesafiosSameRegulamento);
}

/**
 * Add a regulamento desafios to the database
 * @param empresaId - Empresa id
 * @param regulamento - Regulamento to add
 * @returns - Promise with the regulamentoDesafios reference
 */
export function addRegulamentoDesafios(
  empresaId: TEmpresa["id"],
  regulamento: Omit<TRegulamentoDesafiosForm, "id" | "refPath">
) {
  const regulamentoDesafiosCollectionReference = getRegulamentosDesafiosCollection(empresaId);
  return addDoc(regulamentoDesafiosCollectionReference, regulamento);
}

/**
 * Update a regulamentoDesafios to the database
 * @param empresaId - Empresa id
 * @param regulamentoDesafiosId - RegulamentoDesafios id to update
 * @param regulamentoDesafios - RegulamentoDesafios data to update
 * @returns - Promise with the regulamentoDesafios reference
 */
export function updateRegulamentoDesafios(
  empresaId: TEmpresa["id"],
  regulamentoDesafiosId: TRegulamentoDesafios["id"],
  regulamentoDesafios: TRegulamentoDesafiosForm
) {
  const regulamentoDesafiosDocumentReference = getRegulamentoDesafiosDocumentReference(
    empresaId,
    regulamentoDesafiosId
  ).withConverter(regulamentoDesafiosConverter);
  const currentUserId = getCurrentUser()?.uid;
  return updateDoc(regulamentoDesafiosDocumentReference, {
    ...regulamentoDesafios,
    updatedAt: serverTimestamp(),
    updatedBy: currentUserId
  });
}

/**
 * Delete a regulamentoDesafios to the database
 * @param empresaId - Empresa id
 * @param regulamento - RegulamentoDesafios to delete
 * @returns - Promise with the regulamentoDesafios reference
 */
export function deleteRegulamentoDesafios(empresaId: TEmpresa["id"], regulamento: TRegulamentoDesafios) {
  const regulamentoDocumentReference = getRegulamentoDesafiosDocumentReference(empresaId, regulamento.id).withConverter(
    regulamentoDesafiosConverter
  );
  return updateDoc(regulamentoDocumentReference, {
    deletedAt: serverTimestamp()
  });
}

/**
 * Get cronograma query for a empresa
 * @param empresaId - Empresa id to get comitês
 * @returns - cronograma query
 */
function generateRegulamentoQuery(empresaId: TEmpresa["id"]) {
  const regulamentoCollectionReference =
    getRegulamentosDesafiosCollection(empresaId).withConverter(regulamentoDesafiosConverter);
  return query(regulamentoCollectionReference, where("deletedAt", "==", null));
}

/**
 * Get cronograma for a empresa
 * @param empresaId - Empresa id to get comitês
 * @returns - cronograma document snapshot
 */
export function getRegulamento(empresaId: TEmpresa["id"]) {
  const q = generateRegulamentoQuery(empresaId);
  return getDocs(q);
}
