import { ObjectSchema } from "yup";
import schema from "@/schema";

type ChangePasswordInputs = {
  password: string;
};

const MIN_PASSWORD_LENGTH = 5;

const changePasswordSchema: ObjectSchema<ChangePasswordInputs> = schema
  .object({
    password: schema.string().trim().min(MIN_PASSWORD_LENGTH).required().label("Senha"),
    confirmPassword: schema
      .string()
      .required()
      .oneOf([schema.ref("password")], "As senhas devem ser iguais")
      .label("Confirmar senha")
  })
  .noUnknown();

export default changePasswordSchema;
