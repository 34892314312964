/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import { BackgroundImage, Box, Flex, LoadingOverlay, Text, Title } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Asserts } from "yup";
import { inviteEmpresa } from "@/modules/convidar-empresa/functions";
import { addEmpresaWithConverter } from "@/modules/empresas/firestore";
import empresaSchema from "@/modules/empresas/schema/empresa-schema";
import useGetProjetoById from "@/modules/projetos/hooks/useGetProjetoById";
import useUserStore, { setActiveLicenciadaId } from "@/modules/users/store";
import { AutocadastroEmpresaForm } from "./components/empresa-form/AutocadastroEmpresaForm";
import { openProjetoTermModal } from "./components/regulamento-term/RegulamentoTermModal";

type EmpresaInputs = Asserts<typeof empresaSchema>;
type AutocadastroPageParameters = {
  licenciadaId: string;
  empresaId: string;
  projetoId: string;
};

/**
 * Página de autocadastro
 * @returns JSX.Element com o conteúdo da página
 */
export function AutocadastroPage() {
  const navigate = useNavigate();
  const resetState = useUserStore((state) => state.reset);
  const { licenciadaId, empresaId, projetoId } = useParams<AutocadastroPageParameters>();
  const { data: projeto } = useGetProjetoById(empresaId ?? "", projetoId ?? "", licenciadaId ?? "");
  const [loading, setLoading] = useState(false);
  const [aguardandoTermos, setAguardandoTermos] = useState(false);
  setActiveLicenciadaId(licenciadaId);
  if (projeto === undefined) {
    return <div>Buscando projeto..</div>;
  }
  if (!projetoId || !empresaId || !projeto) {
    return <div>Projeto não encontrado</div>;
  }

  const handleCreateEmpresa = async (empresa: EmpresaInputs, licenciadaId?: TLicenciada["id"] | undefined) => {
    try {
      cadastraEmbaixadores(empresa, empresaId, projetoId);
      const empresaCreated = await addEmpresaWithConverter(empresa, licenciadaId);
      return empresaCreated && empresaCreated.data();
    } catch (error) {
      const { message } = error as Error;

      showNotification({
        title: "Erro ao criar empresa",
        message: message,
        color: "red"
      });

      return undefined;
    }
  };

  const handleSubmit = async (empresa: EmpresaInputs) => {
    setLoading(true);
    try {
      setAguardandoTermos(true);
      const acceptTerms = await openProjetoTermModal(projeto);
      setAguardandoTermos(false);
      if (!acceptTerms) {
        return;
      }

      const empresaCreated = await handleCreateEmpresa(empresa, licenciadaId);
      if (!empresaCreated) {
        return;
      }

      await inviteEmpresa({
        cnpj: empresa.cnpj,
        empresaId: empresaCreated.id,
        nome: empresaCreated.responsavel.nome,
        email: empresaCreated.responsavel.email,
        idEmpresa: empresaId ?? "",
        licenciadaId: licenciadaId ?? "",
        projetoId: projetoId
      });
      showNotification({
        title: "Empresa cadastrada com sucesso!",
        message: "Você receberá um e-mail com as instruções para acessar o sistema",
        color: "teal"
      });
      const delay = 2000;
      setTimeout(() => {
        navigate("/", { replace: false });
        resetState();
        location.reload();
      }, delay);
    } catch {
      showNotification({
        title: "Erro ao criar empresa",
        message: "",
        color: "red"
      });
    } finally {
      setLoading(false);
    }
  };
  const corSombra = "#9e2828";
  const sombraTexto = {
    root: {
      color: "black",
      textShadow: `1px -1px 0 #${corSombra}, 1px -1px 0 #${corSombra}, -1px  1px 0 #${corSombra}, 1px  1px 0 #${corSombra}`
    }
  };
  return (
    <Flex direction="column" gap="md">
      <LoadingOverlay
        visible={!aguardandoTermos && loading}
        styles={{
          overlay: {
            backdropFilter: "blur(2px)"
          }
        }}
        style={{ position: "fixed", top: 0, left: 0, width: "100vw", height: "100vh" }}
      />
      <BackgroundImage
        src="https://firebasestorage.googleapis.com/v0/b/acelera-inova.appspot.com/o/assets%2Fautocadastro.jpg?alt=media&token=a777a1be-80b2-49f9-a7a1-631e6741cb55"
        style={{ backgroundColor: "rgb(255, 255, 255)", backgroundBlendMode: "multiply" }}
        p={"xl"}
      >
        <Box p="xl" style={{ minHeight: "200px", minWidth: "200px" }}>
          {" "}
        </Box>
      </BackgroundImage>
      <Box p="xl" style={{ marginTop: 0 }}>
        <Flex direction="column" gap="xs">
          <Title size="24px" styles={sombraTexto}>
            {projeto.nome}
          </Title>
          <Text ta={"left"} size="lg">
            Preencha seus dados abaixo para confirmar sua inscrição:
          </Text>
          <AutocadastroEmpresaForm
            licenciadaId={licenciadaId}
            empresaId={empresaId}
            projeto={projeto}
            loading={loading}
            handleSubmit={handleSubmit}
          />
        </Flex>
      </Box>
    </Flex>
  );
}

/**
 *
 * @param empresa - empresa
 * @param empresaId - id da empresa
 * @param projetoId - id do projeto
 */
function cadastraEmbaixadores(empresa: EmpresaInputs, empresaId: string, projetoId: string) {
  empresa.embaixadores = empresa.embaixadores || [];

  const adicionarEmbaixador = (reference: string | undefined, origem: string) => {
    if (!reference) return;

    const exists = empresa.embaixadores.some(
      (embaixador) => embaixador.cadastroReference === reference && embaixador.origem === origem
    );

    if (!exists) {
      empresa.embaixadores.push({
        origem,
        cadastroReference: reference,
        empresaProjetoReference: empresaId,
        projetoReference: projetoId,
        data: new Date()
      });
    }
  };

  adicionarEmbaixador(empresa.embaixadorPessoaReference, "embaixadorPessoa");
  adicionarEmbaixador(empresa.embaixadorReference, "embaixador");
}
