import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getAcessoArray, QUERY_KEY } from "@/modules/etapa-processo/firestore";
import useUserStore from "@/modules/users/store";

type TGetGroupResponse = Awaited<ReturnType<typeof getAcessoArray>>;

/**
 * Get ideia from url params
 * @param id - id questionario
 * @param id.id - id questionario
 * @returns - Ideia or null if not found
 */
function useGetAcessos({ id }: { id: TQuestionario["id"] }) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  return useQuery<TGetGroupResponse, FirestoreError, TAcesso[]>({
    queryKey: [QUERY_KEY, id, activeEmpresaId],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!id) {
        throw new Error("Nenhum questionario");
      }

      return getAcessoArray(activeEmpresaId, id);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((document_) => document_.data());
    },
    enabled: !!id && !!activeEmpresaId
  });
}

export default useGetAcessos;
