import { Stack } from "@mantine/core";
import {
  IconActivity,
  IconArrowRight,
  IconBook,
  IconBulb,
  IconHome2,
  IconMedal2,
  IconReport,
  IconShieldHalfFilled,
  IconUsersGroup
} from "@tabler/icons-react";
import { NavLink } from "react-router-dom";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import { dataAutorizacoes } from "@/src/routes/empresa-page/EmpresaPage";
import NavLinkMenu from "./NavLinkMenu";

const AppGestaoInovacaoMenu = ({ toggleMobile }: { toggleMobile: () => void }) => {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresa);
  const activeAutorizacao = useUserStore((store) => store.autorizacoes);
  const uid = getCurrentUser()?.uid;
  const { data: userColaborador } = useGetColaboradorById(activeEmpresaId?.id || "", uid || "");

  if (activeEmpresaId && activeEmpresaId?.status === "INATIVO") {
    return <></>;
  }

  const autorizacao = dataAutorizacoes.find((item) => item.label === userColaborador?.role);
  const formatAutorizacoes = activeAutorizacao?.filter((item) => item.tipo === autorizacao?.value);

  const getAuthorization = (tipoAutorizacao: string) =>
    formatAutorizacoes?.find((item) => item.tipoAutorizacao === tipoAutorizacao);

  const renderNavLink = (label: string, icon: React.ReactNode, to: string) => (
    <NavLinkMenu label={label} leftSection={icon} component={NavLink} onClick={toggleMobile} to={to} />
  );

  const renderConditionalNavLink = (authType: string, label: string, icon: React.ReactNode, to: string) =>
    getAuthorization(authType)?.leitura ? renderNavLink(label, icon, to) : <></>;

  return (
    <Stack mx="md" mt="lg" gap="xs">
      {renderNavLink("Início", <IconHome2 size="1.2rem" stroke={1.5} />, "/")}
      {renderConditionalNavLink("3", "Ideias de Inovação", <IconBulb size="1.2rem" stroke={1.5} />, "/home-ideias")}
      {renderNavLink("Minhas Atividades", <IconArrowRight size="1.2rem" stroke={1.5} />, "/minhas-atividades")}
      {renderConditionalNavLink("17", "Todas Atividades", <IconActivity size="1.2rem" stroke={1.5} />, "/atividades")}
      {renderConditionalNavLink(
        "18",
        "Ranking de Colaboradores",
        <IconMedal2 size="1.2rem" stroke={1.5} />,
        "/ranking-de-colaboradores"
      )}
      {renderConditionalNavLink(
        "16",
        "Comitês de Inovação",
        <IconUsersGroup size="1.2rem" stroke={1.5} />,
        "/comites-de-inovacao"
      )}

      <NavLinkMenu label="Listagem de Ideias" leftSection={<IconBook size="1.2rem" stroke={1.5} />} childrenOffset={34}>
        {renderConditionalNavLink("3", "Listagem de ideias", null, "/ideias")}
        {renderConditionalNavLink("15", "Regulamentos", null, "/ideias/regulamentos")}
      </NavLinkMenu>

      <NavLinkMenu
        label="Desafios de Inovação"
        leftSection={<IconShieldHalfFilled size="1.2rem" stroke={1.5} />}
        childrenOffset={34}
      >
        {renderConditionalNavLink("9", "Desafios", null, "/home-desafios")}
        {renderConditionalNavLink("9", "Listagem de desafios", null, "/desafios")}
        {renderConditionalNavLink("14", "Regulamentos", null, "/desafios/regulamentos")}
        {renderConditionalNavLink("22", "Categorias de Desafios", null, "/desafios/categorias-de-desafios")}
      </NavLinkMenu>

      {(getAuthorization("23")?.leitura || getAuthorization("24")?.leitura) && (
        <NavLinkMenu label="Relatórios" leftSection={<IconReport size="1.2rem" stroke={1.5} />} childrenOffset={34}>
          {renderConditionalNavLink("23", "Visão Geral de Ideias", null, "/relatorios/visao-geral-de-ideias")}
          {renderConditionalNavLink("24", "Visão Geral de Desafios", null, "/relatorios/visao-geral-de-desafios")}
        </NavLinkMenu>
      )}
    </Stack>
  );
};

export default AppGestaoInovacaoMenu;
