import { Anchor, Text, Title } from "@mantine/core";
import ReactMarkdown from "react-markdown";

type Properties = {
  text: string;
};

/**
 * Markdown display
 * @param root0 - properties
 * @param root0.text - markdown text
 * @returns Markdown
 */
function Markdown({ text }: Properties) {
  return (
    <ReactMarkdown
      components={{
        h1: ({ children }) => <Title order={1}>{children}</Title>,
        h2: ({ children }) => <Title order={2}>{children}</Title>,
        h3: ({ children }) => <Title order={3}>{children}</Title>,
        h4: ({ children }) => <Title order={4}>{children}</Title>,
        h5: ({ children }) => <Title order={5}>{children}</Title>,
        h6: ({ children }) => <Title order={6}>{children}</Title>,
        p: ({ children }) => <Text>{children}</Text>,
        a: ({ children, href }) => (
          <Anchor target="_blank" href={href}>
            {children}
          </Anchor>
        )
      }}
    >
      {text}
    </ReactMarkdown>
  );
}

export default Markdown;
