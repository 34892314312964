import { RouteObject } from "react-router-dom";
import { AutocadastroPage } from "./autocadastro-page/AutocadastroPage";
import ConviteDiagnosticoPage from "./convite-diagnostico-page";
import ErrorPage from "./ErrorPage";
import LoginPage from "./login-page";
import ProjetoExternoPage from "./projeto-externo-page";
import ResponderQuestionarioExternoPage from "./responder-questionario-externo-page";

const noAuthenticatedRoutes: RouteObject[] = [
  {
    path: "/auto-cadastro/:licenciadaId/:empresaId/:projetoId",
    element: <AutocadastroPage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/responderQuestionario/:licenciadaId/:empresaId/:questionarioId/:empresaRespondenteId",
    element: <ResponderQuestionarioExternoPage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/responderQuestionario/:licenciadaId/:empresaId/:questionarioId/:empresaRespondenteId/:email",
    element: <ResponderQuestionarioExternoPage />,
    errorElement: <ErrorPage />
  },
  {
    path: "projeto/:idEmpresa/:id/:licenciadaId",
    element: <ProjetoExternoPage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/convite-diagnostico/:licenciadaId/:projetoId/:empresaId/:colaboradorId",
    element: <ConviteDiagnosticoPage />,
    errorElement: <ErrorPage />
  },
  {
    path: "*",
    element: <LoginPage />,
    errorElement: <ErrorPage />
  }
];

export default noAuthenticatedRoutes;
