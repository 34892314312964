import { TransformedValues, useForm, yupResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { UseMutationOptions, useQueryClient, useMutation } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { Asserts } from "yup";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { TQuestionarioForm, addQuestionario, updateQuestionario } from "../../firestore";
import questionarioSchema from "../../schema/questionario-schema";
import { QUERY_KEY } from "../questionario-table/QuestionarioTable";

type FormInputs = Asserts<typeof questionarioSchema>;
type TUpdateResponse = Awaited<ReturnType<typeof updateQuestionario>>;
type TAddResponse = Awaited<ReturnType<typeof addQuestionario>>;

interface Fase {
  id: string;
  titulo: string;
  cor: string;
  checked?: boolean;
  // status: Status[];
}

/**
 * Empresa Form hook
 * @param questionario - fases
 * @param fases - fases
 * @param root0 - root
 * @param root0.onSuccess - On success callback
 * @param root0.onError - On error callback
 * @returns - Empresa Form
 */
// prettier-ignore
function useQuestionarioForm(
  questionario: TQuestionarioForm & Partial<Pick<TQuestionario, "id" | "refPath">>,
  fases: Fase[],
  {
    onSuccess,
    onError
  }: Pick<UseMutationOptions<TUpdateResponse | TAddResponse, FirestoreError, FormInputs>, "onSuccess" | "onError">
) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const queryClient = useQueryClient();
  const form = useForm<FormInputs>({
    validate: yupResolver(questionarioSchema),
    transformValues: (values) => questionarioSchema.cast(values),
    initialValues: questionario
  });

  const { mutate, isPending, reset } = useMutation<TUpdateResponse | TAddResponse, FirestoreError, FormInputs>({
    mutationFn: (values) => {
      if (!activeEmpresaId) {
        throw new Error("Nenhuma empresa selecionada");
      }
      return questionario.id
        ? updateQuestionario(activeEmpresaId, questionario.id, {...values, fases})
        : addQuestionario(activeEmpresaId, {...values, fases});
    },
    async onSuccess(data, variables, context) {
      showNotification({
        message: `${variables.nome} ${questionario.id ? "atualizado" : "cadastrado"} com sucesso!`,
        color: "green",
        autoClose: 2500
      });
      reset();
      form.reset();
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      if (onSuccess) {
        return onSuccess(data, variables, context);
      }
      return null;
    },
    onError(error, variables, context) {
      captureException(error, true);
      if (onError) {
        onError(error, variables, context);
      }
    }
  });

  type TFormTransformed = TransformedValues<typeof form>;

  /**
   * Handle form submit
   * @param values - Form values
   */
  function handleFormSubmit(values: TFormTransformed) {
    mutate(values);
  }

  return { form, isPending, onSubmit: form.onSubmit(handleFormSubmit) };
}

export default useQuestionarioForm;
