import { subject } from "@casl/ability";
import { FirestoreDataConverter, Timestamp, collection, doc } from "firebase/firestore";
import { firestore } from "@/base";
import { getActiveLicenciadaId } from "../users/store";

// const DEFAULT_LIMIT = 10;
// export const DEFAULT_SORTING: SortingState = [{ id: "nomeFantasia", desc: false }];
// export const DEFAULT_PAGINATION: PaginationState = { pageIndex: 0, pageSize: DEFAULT_LIMIT };

// export type TLicenciadaDatabaseFields = Pick<TLicenciada, "createdBy" | "updatedBy"> & {
//   createdAt: ReturnType<typeof serverTimestamp>;
//   updatedAt?: ReturnType<typeof serverTimestamp>;
//   deletedAt: ReturnType<typeof serverTimestamp> | null;
// };
// export type TLicenciadaFormFields = Omit<
//   TLicenciada,
//   "id" | "refPath" | "createdAt" | "updatedAt" | "createdBy" | "updatedBy"
// > &
//   Partial<Pick<TLicenciada, "id" | "refPath">>;
// export type TLicenciadaForm = TLicenciadaFormFields & TLicenciadaDatabaseFields;

type TLicenciadaDocument = Omit<TLicenciada, "createdAt" | "updatedAt" | "deletedAt"> & {
  createdAt: Timestamp;
  updatedAt?: Timestamp;
  deletedAt: Timestamp | null;
};

const licenciadaConverter: FirestoreDataConverter<TLicenciada> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    // delete data.__caslSubjectType__;
    return data;
  },
  fromFirestore(snap) {
    const { createdAt, updatedAt, ...document } = snap.data() as TLicenciadaDocument;

    const data: TLicenciada = subject("TLicenciada", {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      refPath: snap.ref.path
    });

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }
    return data;
  }
};

/**
 * Get all licenciadas collection reference
 * @returns - licenciadas collection reference
 */
function getLicenciadasCollectionReference() {
  return collection(firestore, `licenciadas`);
}
// prettier-ignore
/**
 * Get all licenciadas collection reference where the current user is the owner
 * @returns - licenciadas collection reference
 */
// function queryMylicenciadasCollectionReference() {
//   const licenciadasCollectionReference = getLicenciadasCollectionReference();
//   return query(
//     licenciadasCollectionReference,
//     and(where("deletedAt", "==", null), where("ativo", "==", true))
//   );
// }

/**
 * Get all licenciadas collection reference where the current user is the owner
 * @param uid - User id
 * @param role - role
 * @returns - licenciadas collection reference
 */
// prettier-ignore
// function queryMylicenciadasCollectionNewReference(uid: string, role: string) {
//    const licenciadasCollectionReference = getLicenciadasCollectionReference();

//    if (role === "ADMIN") {
//     return query(licenciadasCollectionReference);
//    }

//   return query(
//     licenciadasCollectionReference,
//     or(where("createdBy", "==", uid), where("colaboradores", "array-contains", uid))
//   );
// }

/**
 * Get a licenciada document reference
 * @param licenciadaId - licenciada id
 * @returns - licenciada document reference
 */
export function getLicenciadaDocumentReference(licenciadaId: TLicenciada["id"] | undefined) {
   if (!licenciadaId) {
    return undefined;
  }
  const licenciadasCollectionReference = getLicenciadasCollectionReference().withConverter(licenciadaConverter);
  return doc(licenciadasCollectionReference, licenciadaId);
}

/**
 * Get a licenciada document reference
 * @returns - licenciada document reference
 */
export function getActiveLicenciadaDocumentReference() {
  const uid = getActiveLicenciadaId();
  if (!uid) {
    return null;
  }
  const licenciadasCollectionReference = getLicenciadasCollectionReference().withConverter(licenciadaConverter);
  return doc(licenciadasCollectionReference, uid);
}

/**
 * Get a licenciada document reference from path
 * @param licenciadaPath - licenciada path
 * @returns - licenciada document reference
 */
// function getLicenciadaDocumentReferenceFromPath(licenciadaPath: string) {
//   return doc(firestore, licenciadaPath).withConverter(licenciadaConverter);
// }

/**
 * Get a licenciada by cnpj
 * @param cnpj - licenciada cnpj
 * @returns - Promise with the licenciada snapshot
 * @throws - Error if the licenciada is already registered
 */
// async function getLicenciadaByCnpj(cnpj: string) {
//   const licenciadasCollectionReference = getLicenciadasCollectionReference();
//   const licenciadaQuery = query(licenciadasCollectionReference, where("cnpj", "==", cnpj));
//   const { docs } = await getDocs(licenciadaQuery);
//   return docs[0] && licenciadaConverter.fromFirestore(docs[0]);
// }

/**
 * Add a licenciada to the database
 * @param licenciada - licenciada to add
 * @returns - Promise with the licenciada reference
 */
// function addlicenciada(licenciada: Omit<TLicenciadaForm, "id" | "refPath">) {
//   const licenciadasCollectionReference = getLicenciadasCollectionReference();
//   return addDoc(licenciadasCollectionReference, licenciada);
// }

/**
 * Add a licenciada to the database
 * @param licenciada - licenciada to add
 * @returns - Promise with the licenciada reference
 */
// async function addlicenciadaWithConverter(licenciada: Omit<TLicenciadaForm, "id" | "refPath">) {
//   const licenciadasCollectionReference = getLicenciadasCollectionReference();

//   const licenciadaWithSameCNPJ = await getLicenciadaByCnpj(licenciada.cnpj);
//   if (licenciadaWithSameCNPJ) {
//     throw new Error("licenciada já cadastrada");
//   }

//   const licenciadaCreated = await addDoc(licenciadasCollectionReference, licenciada);
//   return await getDoc(licenciadaCreated.withConverter(licenciadaConverter));
// }

/**
 * Update a licenciada to the database
 * @param licenciadaId - licenciada id
 * @param licenciada - licenciada to update
 * @returns - Promise with the licenciada reference
 */
// export function updatelicenciada(licenciadaId: TLicenciada["id"], licenciada: TLicenciadaForm) {
//   const licenciadaDocumentReference = getLicenciadaDocumentReference(licenciadaId);
//   if (!licenciadaDocumentReference) {
//     throw new Error(`Licenciada document reference not found for id ${licenciadaId}`);
//   }
//   const licenciadaDocumentReferenceWithConverter = licenciadaDocumentReference.withConverter(licenciadaConverter);
//   const currentUserId = getCurrentUser()?.uid;
//   return updateDoc(licenciadaDocumentReferenceWithConverter, {
//     ...licenciada,
//     updatedAt: serverTimestamp(),
//     updatedBy: currentUserId
//   });
// }

/**
 * Delete a licenciada to the database
 * @param licenciada - licenciada to delete
 * @returns - Promise with the licenciada reference
 */
// export function deletelicenciada(licenciada: TLicenciada) {
//   const licenciadaDocumentReference = getLicenciadaDocumentReference(licenciada.id);
//   if (!licenciadaDocumentReference) {
//     throw new Error(`Licenciada document reference not found for id ${licenciada.id}`);
//   }
//   return deleteDoc(licenciadaDocumentReference);
// }

/**
 * Get a licenciada by id
 * @param licenciadaId - licenciada id
 * @returns - Promise with the licenciada snapshot
 */
// export function getLicenciadaById(licenciadaId: TLicenciada["id"]) {
//   const licenciadaDocumentReference = getLicenciadaDocumentReference(licenciadaId);
//   if (!licenciadaDocumentReference) {
//     throw new Error(`Licenciada document reference not found for id ${licenciadaId}`);
//   }
//   return getDoc(licenciadaDocumentReference);
// }

/**
 * Get all licenciadas from the database
 * @returns - Promise with all licenciadas
 */
// export function getLicenciadas() {
//   const querylicenciadasCollectionReference =
//     queryMylicenciadasCollectionReference().withConverter(licenciadaConverter);
//   return getDocs(querylicenciadasCollectionReference);
// }

// prettier-ignore
/**
 * Get all licenciadas from the database
 * @param uid - User id
 * @param role - role
 * @param root0 - Query options
 * @param root0.sorting - Sorting options
 * @param root0.filters - Filters options
 * @param root0.pagination - Pagination options
 * @param root0.paginationCursors - Array of pagination cursors (startAt, endAt)[]
 * @returns - Promise with all licenciadas
 */
// export function getPaginatedlicenciadas(
//   uid: string,
//   role = "",
//   {
//     sorting = [],
//     filters = [],
//     pagination = { pageIndex: 0, pageSize: DEFAULT_LIMIT },
//     paginationCursors,
//   }: TPaginatedQueryOptions<TLicenciada> = {}
// ): Promise<TPaginatedQueryResponse<TLicenciada>> {
//   const querylicenciadasCollectionReference = queryMylicenciadasCollectionNewReference(uid, role).withConverter(licenciadaConverter);
//   const qAll = generateQuery(querylicenciadasCollectionReference, { sorting, filters });
//   const qPaginated = generateQuery(querylicenciadasCollectionReference, {
//     sorting,
//     filters,
//     pagination,
//     paginationCursors
//   });

//   // eslint-disable-next-line compat/compat
//   return Promise.all([getDocs(qPaginated), getCountFromServer(qAll)]);
// }
