import { subject } from "@casl/ability";
import { Timestamp, FirestoreDataConverter, collection, getDocs, query, where } from "firebase/firestore";
import { getEmpresaDocumentReference } from "../empresas/firestore";

type TQuestionarioDocument = Omit<
  TQuestionario,
  "createdAt" | "updatedAt" | "deletedAt" | "dataAtuacao" | "dataVigencia"
> & {
  createdAt: Timestamp;
  updatedAt?: Timestamp;
  deletedAt: Timestamp | null;
  dataAtuacao: Timestamp;
  dataVigencia: Timestamp;
};

const questionarioConverter: FirestoreDataConverter<TQuestionario> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const { updatedAt, deletedAt, createdAt, dataAtuacao, dataVigencia, ...document } =
      snap.data() as TQuestionarioDocument;

    const data: TQuestionario = subject("TQuestionario", {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      deletedAt: deletedAt ? deletedAt.toDate() : null,
      refPath: snap.ref.path,
      dataAtuacao: dataAtuacao.toDate(),
      dataVigencia: dataVigencia.toDate()
    });

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }

    return data;
  }
};

type TRespostaDocument = Omit<TResposta, "createdAt" | "updatedAt" | "deletedAt"> & {
  createdAt: Timestamp;
  updatedAt?: Timestamp;
  deletedAt: Timestamp | null;
};

const respostaConverter: FirestoreDataConverter<TResposta> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const { createdAt, updatedAt, deletedAt, ...document } = snap.data() as TRespostaDocument;

    const data: TResposta = {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      deletedAt: deletedAt ? deletedAt.toDate() : null,
      refPath: snap.ref.path
    };

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }

    return subject("TResposta", data);
  }
};

/**
 * Get all questionário
 * @param empresaId - Empresa id
 * @returns - questionário collection reference
 */
function getQuestionarioCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, "questionarios");
}

/**
 * Get all questionário
 * @param empresaId - Empresa id
 * @returns - questionário collection reference
 */
function getRespostasCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, "respostas");
}

/**
 * Get questionário query for a empresa
 * @param empresaId - Empresa id to get comitês
 * @returns - questionário query
 */
function generateQuestionarioQuery(empresaId: TEmpresa["id"]) {
  const categoriasInovacaoCollectionReference =
    getQuestionarioCollectionReference(empresaId).withConverter(questionarioConverter);
  return query(categoriasInovacaoCollectionReference, where("deletedAt", "==", null));
}

/**
 * Get questionário for a empresa
 * @param empresaId - Empresa id to get comitês
 * @returns - questionário document snapshot
 */
export function getQuestionarios(empresaId: TEmpresa["id"]) {
  const q = generateQuestionarioQuery(empresaId);
  return getDocs(q);
}

/**
 * Get questionário query for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param idQuestionario - Id user
 * @param id - id
 * @returns - questionário query
 */
// prettier-ignore
function generateGrupoRespostasQuery(empresaId: TEmpresa["id"], idQuestionario: TQuestionario["id"],id:string) {
  const respostasCollectionReference = getRespostasCollectionReference(empresaId).withConverter(respostaConverter);
  return query(respostasCollectionReference, where("deletedAt", "==", null), where("idQuestionario", "==", idQuestionario), where("idEmpresa", "==", id));
}
/**
 * Get questionário for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param idQuestionario - Id user
 * @param id - id
 * @returns - questionário document snapshot
 */
// prettier-ignore
export function getGrupoResposta(empresaId: TEmpresa["id"], idQuestionario: TQuestionario["id"], id:string) {
  const q = generateGrupoRespostasQuery(empresaId, idQuestionario, id);
  return getDocs(q);
}
