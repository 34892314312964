import { Stack, Title } from "@mantine/core";
import Markdown from "@/components/markdown/Markdown";

type Properties = {
  grupo: TGruposDiagnostico;
  licenciado: TLicenciado | undefined;
};

/**
 * QuestionarioGrupo
 * @param root0 - properties
 * @param root0.grupo - grupo
 * @returns QuestionarioGrupo
 */
function QuestionarioGrupo({ grupo }: Properties) {
  return (
    <Stack justify="space-around" align="center">
      <Title>{grupo.nome}</Title>
      <Markdown text={grupo.descricao} />
    </Stack>
  );
}

export default QuestionarioGrupo;
