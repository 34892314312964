import { Alert, Button, Group, NumberInput, Space, Text, Textarea } from "@mantine/core";
import { TransformedValues, useForm, yupResolver } from "@mantine/form";
import { IconAlertCircle } from "@tabler/icons-react";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { Asserts } from "yup";
import schema from "@/modules/historico-pontos-per-colaborador/schema";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { extractTextInputPropertiesFromFormSchema } from "@/utils/form";
import { THistoricoPontosPerColaboradorForm, addHistoricoPontosPerColaborador } from "../../firestore";

type TAddHistoricoPontosPerColaborador = Awaited<ReturnType<typeof addHistoricoPontosPerColaborador>>;
type TMutationOptions = Pick<
  UseMutationOptions<TAddHistoricoPontosPerColaborador, FirestoreError, THistoricoPontosPerColaboradorForm>,
  "onSuccess" | "onError"
>;

/**
 * Use add bonus form
 * @param historico - Historico to be used to add bonus
 * @param options - Mutation options to pass to react-query
 * @returns Form and submit handler
 */
function useAddBonusForm(historico: Asserts<typeof schema>, options: TMutationOptions) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const initialValues = schema
    .omit(["createdAt", "createdBy", "updatedAt", "updatedBy"])
    .cast(historico, { stripUnknown: true, assert: false });
  const form = useForm({
    initialValues: {
      ...initialValues,
      colaboradorReference:
        typeof historico.colaboradorReference === "string"
          ? historico.colaboradorReference
          : historico.colaboradorReference.path
    },
    validate: yupResolver(schema),
    transformValues: (values) => schema.cast(values, { stripUnknown: true })
  });

  const { mutate, isPending } = useMutation<TAddHistoricoPontosPerColaborador, FirestoreError, Asserts<typeof schema>>({
    mutationFn(values) {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return addHistoricoPontosPerColaborador(activeEmpresaId, values);
    },
    ...options
  });

  type TFormValues = TransformedValues<typeof form>;
  /**
   * Handle submit form
   * @param values - Form values
   */
  function handleSubmit(values: TFormValues) {
    mutate(values);
  }

  return { form, onSubmit: form.onSubmit(handleSubmit), isPending };
}

const AddBonusForm = ({ historico, onSuccess }: { historico: Asserts<typeof schema>; onSuccess: () => void }) => {
  const { form, isPending, onSubmit } = useAddBonusForm(historico, {
    onSuccess,
    onError(error) {
      captureException(error, true);
    }
  });

  const schemaFieldsDescriptor = schema.describe();
  const genericError = form.errors[""];

  return (
    <form onSubmit={onSubmit}>
      <Group>
        <Text c="dimmed">Informe a quantidade de pontos bônus: </Text>
        <NumberInput
          allowNegative={false}
          min={1}
          suffix=" pontos"
          {...form.getInputProps("pontos")}
          {...extractTextInputPropertiesFromFormSchema(schemaFieldsDescriptor, "pontos")}
          label={null}
        />
      </Group>

      <Space h="md" />

      <Textarea
        {...form.getInputProps("observacao")}
        {...extractTextInputPropertiesFromFormSchema(schemaFieldsDescriptor, "observacao")}
        label={null}
        placeholder="Observação"
      />

      <Group justify={genericError ? "space-between" : "flex-end"} mt="md">
        {genericError && (
          <Alert icon={<IconAlertCircle size="1rem" />} color="red">
            {genericError}
          </Alert>
        )}
        <Button type="submit" loading={isPending}>
          Adicionar
        </Button>
      </Group>
    </form>
  );
};

export default AddBonusForm;
