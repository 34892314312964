import { Text, List, Group, Anchor, CloseButton } from "@mantine/core";
import { FileWithPath } from "@mantine/dropzone";
import { useHover } from "@mantine/hooks";
import { MouseEvent } from "react";

/**
 * Anexo to upload component
 * @param root0 - Props
 * @param root0.file - File to upload
 * @param root0.index - File index
 * @param root0.onRemove - On remove callback
 * @param root0.readOnly - Is read only mode enabled
 * @returns Component
 */
function FileItem({
  file,
  onRemove,
  index,
  readOnly = false
}: {
  file: TFileStored | FileWithPath;
  onRemove?: (file: TFileStored | FileWithPath, index: number) => void;
  readOnly?: boolean;
  index: number;
}) {
  const { hovered, ref } = useHover<HTMLLIElement>();

  /**
   *
   * @param event - Event
   */
  function handleCloseClick(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    if (onRemove) {
      onRemove(file, index);
    }
  }
  return (
    <List.Item key={file.path} ref={ref}>
      <Group gap="xs" wrap="nowrap">
        <Text truncate="end">
          <Anchor target="_blank" href={file.path}>
            {file.name}
          </Anchor>
        </Text>
        {hovered && !readOnly && onRemove && <CloseButton size="sm" onClick={handleCloseClick} />}
      </Group>
    </List.Item>
  );
}

export default FileItem;
