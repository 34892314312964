import { createContextualCan } from "@casl/react";
import { createContext } from "react";
import defineAbilityFor from "@/src/define-ability-for";

export const ability = defineAbilityFor();
export const AbilityContext = createContext(ability);

const Can = createContextualCan(AbilityContext.Consumer);

export default Can;
