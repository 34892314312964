/* eslint-disable complexity */
/* eslint-disable jsdoc/require-jsdoc */
import { Anchor, Flex, Loader, Skeleton, Stack, Text } from "@mantine/core";
import { NavLink } from "react-router-dom";
import BreadcrumbsNavigation from "@/components/breadcrumbs-navigation";
import NoData from "@/components/no-data";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import HistoricoMovimentacaoCard from "@/modules/desafio-movimentacao/components/historico-movimentacao";
import MovimentacaoDesafioCard from "@/modules/desafio-movimentacao/components/MovimentacaoDesafioCard";
import DesafioStatusBadge from "@/modules/desafios/components/desafio-status-badge";
import TotalMoedasBadge from "@/modules/desafios/components/total-moedas-badge";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import CronogramaCardVerification from "./CronogramaCardVerification";
import DesafioCard from "./DesafioCard";
import DesafioComentarioFormCard from "./DesafioComentarioFormCard";
import DesafioComentariosCard from "./DesafioComentariosCard";
import DesafioParticipantesCard from "./DesafioParticipantesCard";
import DesafioPatrocinioCard from "./DesafioPatrocinioCard";
import RankingByMoedasBadge from "./RankingByMoedasBadge";
import useGetDesafioFromUrl from "./useGetDesafioFromUrl";
import { dataAutorizacoes } from "../empresa-page/EmpresaPage";
// prettier-ignore
const DesafioPage = () => {
  const { data: desafio, isLoading } = useGetDesafioFromUrl();
  const activeEmpresaId = useUserStore((store) => store.activeEmpresa);
  const activeAutorizacao = useUserStore((store) => store.autorizacoes);
  const uid = getCurrentUser()?.uid;
  const { colaborador } = useUserStore();
  const { data: userColaborador } = useGetColaboradorById(activeEmpresaId?.id || "", uid || "");
  const autorizacao = dataAutorizacoes.find(item => item.label === userColaborador?.role);
  const formatAutorizacoes = activeAutorizacao?.filter(item => item.tipo === autorizacao?.value);

  return (
    <>
      <BreadcrumbsNavigation>
        <Anchor component={NavLink} to="/desafios">
          Desafios
        </Anchor>
        <Skeleton radius="md" height={12} visible={isLoading && !!desafio}>
          <Text c="dimmed" mt={-6}>
            {desafio?.titulo}
          </Text>
        </Skeleton>
      </BreadcrumbsNavigation>

      {!isLoading && !desafio && (
        <NoData>
          <Text>Nenhuma desafio encontrada!</Text>
        </NoData>
      )}

      {desafio && (
        <Stack>
          <DesafioCard desafio={desafio}>
            <TotalMoedasBadge desafio={desafio} />
            <RankingByMoedasBadge desafio={desafio} />
            <DesafioStatusBadge status={typeof desafio.status === "object" ? "DRAFT" : desafio.status} iconOnly />
          </DesafioCard>
          {formatAutorizacoes?.find(item => item.tipoAutorizacao === "9")?.editar ? <CronogramaCardVerification desafio={desafio} /> : <></>}
          {desafio.publishedAt === null || !formatAutorizacoes?.find(item => item.tipoAutorizacao === "9")?.editar ? <></> : <DesafioPatrocinioCard desafio={desafio} />}
          <DesafioComentarioFormCard desafio={desafio} />
          {formatAutorizacoes?.find(item => item.tipoAutorizacao === "9")?.editar ? <DesafioParticipantesCard desafio={desafio} /> : <></>}
          <DesafioComentariosCard desafio={desafio} />
          <HistoricoMovimentacaoCard desafio={desafio} />
          {
            (colaborador && colaborador.role != 'COLLABORATOR' && colaborador.role != 'MEMBER') ? <MovimentacaoDesafioCard desafio={desafio} /> : <></>
          }
        </Stack>
      )}

      {isLoading && (
        <Flex w="100%" mih={400} justify="center" align="center">
          <Loader />
        </Flex>
      )}
    </>
  );
};

export default DesafioPage;
