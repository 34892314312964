import { subject } from "@casl/ability";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import {
  serverTimestamp,
  Timestamp,
  FirestoreDataConverter,
  collection,
  getCountFromServer,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  query,
  where
} from "firebase/firestore";
import { getCurrentUser } from "@/services/auth";
import { generateQuery } from "@/utils/firestore";
import { getEmpresaDocumentReference } from "../empresas/firestore";
import { getQuestionariosIdsByProjeto } from "../questionarios/firestore";
// prettier-ignore
const DEFAULT_LIMIT = 10;
export const DEFAULT_SORTING: SortingState = [{ id: "nome", desc: false }];
export const DEFAULT_PAGINATION: PaginationState = { pageIndex: 0, pageSize: DEFAULT_LIMIT };

export type TGruposDiagnosticoFormFields = Omit<
  TGruposDiagnostico,
  "id" | "refPath" | "createdAt" | "updatedAt" | "createdBy" | "updatedBy" | "deletedAt"
> &
  Partial<Pick<TGruposDiagnostico, "id" | "refPath">>;

export type TGruposDiagnosticoDatabaseFields = Pick<TGruposDiagnostico, "createdBy" | "updatedBy"> & {
  createdAt: ReturnType<typeof serverTimestamp>;
  updatedAt?: ReturnType<typeof serverTimestamp>;
  deletedAt: ReturnType<typeof serverTimestamp> | null;
};
export type TGruposDiagnosticoForm = TGruposDiagnosticoFormFields & TGruposDiagnosticoDatabaseFields;

type TGruposDiagnosticoDocument = Omit<TGruposDiagnostico, "createdAt" | "updatedAt" | "deletedAt"> & {
  createdAt: Timestamp;
  updatedAt?: Timestamp;
  deletedAt: Timestamp | null;
};

const gruposConverter: FirestoreDataConverter<TGruposDiagnostico> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const { createdAt, updatedAt, deletedAt, ...document } = snap.data() as TGruposDiagnosticoDocument;

    const data: TGruposDiagnostico = {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      deletedAt: deletedAt ? deletedAt.toDate() : null,
      refPath: snap.ref.path
    };

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }

    return subject("TGruposDiagnostico", data);
  }
};

/**
 * Get grupos collection reference from empresaId
 * @param empresaId - Empresa id to get the grupos collection reference
 * @returns - GruposDiagnosticos collection reference
 */
function getGruposDiagnosticosCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, "gruposDiagnostico");
}

/**
 * Get grupos document reference from empresaId
 * @param empresaId - Empresa id to get the grupos document reference
 * @param gruposId - Grupos id to get the grupos document reference
 * @returns - Grupos document reference
 */
function getGrupoDocumentReference(empresaId: TEmpresa["id"], gruposId: TGruposDiagnostico["id"]) {
  const gruposCollectionReference = getGruposDiagnosticosCollectionReference(empresaId);
  return doc(gruposCollectionReference, gruposId);
}

const BASE_FILTER: ColumnFiltersState = [
  {
    id: "deletedAt",
    value: ["==", null]
  }
];

/**
 * Get grupos documents from empresaId
 * @param empresaId - Empresa id to get the grupos documents
 * @param options - Options to get the grupos documents
 * @param options.sorting - Sorting options
 * @param options.filters - Filters options
 * @param options.pagination - Pagination options
 * @param options.paginationCursors - Array of pagination cursors (startAt, endAt)[]
 * @returns - GruposDiagnosticos documents
 */
export function getGruposDiagnosticos(
  empresaId: TEmpresa["id"],
  {
    sorting = [],
    filters = [],
    pagination = { pageIndex: 0, pageSize: DEFAULT_LIMIT },
    paginationCursors
  }: TPaginatedQueryOptions<TGruposDiagnostico> = {}
): Promise<TPaginatedQueryResponse<TGruposDiagnostico>> {
  const gruposCollectionReference = getGruposDiagnosticosCollectionReference(empresaId).withConverter(gruposConverter);

  const allFilters = [...BASE_FILTER, ...filters];
  const qAll = generateQuery(gruposCollectionReference, { filters: allFilters });
  const qPaginated = generateQuery(gruposCollectionReference, {
    sorting,
    filters: allFilters,
    pagination,
    paginationCursors
  });

  // eslint-disable-next-line compat/compat
  return Promise.all([getDocs(qPaginated), getCountFromServer(qAll)]);
}

/**
 * Add a new grupos to the given empresa
 * @param empresaId - Empresa id to add the grupos
 * @param grupos - Grupos data
 * @returns - Grupos document reference
 */
export async function addGruposDiagnostico(
  empresaId: TEmpresa["id"],
  grupos: Omit<TGruposDiagnosticoForm, "id" | "refPath">
) {
  const gruposCollectionReference = getGruposDiagnosticosCollectionReference(empresaId);
  return addDoc(gruposCollectionReference, grupos);
}

/**
 * Update a empresa to the database
 * @param empresaId - Empresa id
 * @param gruposId - Grupos id to update
 * @param grupos - Grupos data
 * @returns - Promise with the grupos reference
 */
export function updateGruposDiagnostico(
  empresaId: TEmpresa["id"],
  gruposId: TGruposDiagnostico["id"],
  grupos: TGruposDiagnosticoForm
) {
  const gruposDocumentReference = getGrupoDocumentReference(empresaId, gruposId).withConverter(gruposConverter);
  const currentUserId = getCurrentUser()?.uid;
  return updateDoc(gruposDocumentReference, {
    ...grupos,
    updatedAt: serverTimestamp(),
    updatedBy: currentUserId
  });
}

/**
 * Delete a grupos to the database
 * @param empresaId - Empresa id to delete the grupos
 * @param grupos - Grupos to delete
 * @returns - Promise
 */
export function deleteGruposDiagnostico(empresaId: TEmpresa["id"], grupos: TGruposDiagnostico) {
  const gruposDocumentReference = getGrupoDocumentReference(empresaId, grupos.id);
  return updateDoc(gruposDocumentReference, {
    deletedAt: serverTimestamp()
  });
}

type TQuestionarioDocument = Omit<
  TQuestionario,
  "createdAt" | "updatedAt" | "deletedAt" | "dataAtuacao" | "dataVigencia"
> & {
  createdAt: Timestamp;
  updatedAt?: Timestamp;
  deletedAt: Timestamp | null;
  dataAtuacao: Timestamp;
  dataVigencia: Timestamp;
};

const questionarioConverter: FirestoreDataConverter<TQuestionario> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const { updatedAt, deletedAt, createdAt, dataAtuacao, dataVigencia, ...document } =
      snap.data() as TQuestionarioDocument;

    const data: TQuestionario = subject("TQuestionario", {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      deletedAt: deletedAt ? deletedAt.toDate() : null,
      refPath: snap.ref.path,
      dataAtuacao: dataAtuacao.toDate(),
      dataVigencia: dataVigencia.toDate()
    });

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }

    return data;
  }
};

/**
 * Get all questionário
 * @param empresaId - Empresa id
 * @returns - questionário collection reference
 */
export function getQuestionarioCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, "questionarios");
}

/**
 * Get questionário query for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param id - Project id to get comitês
 * @returns - questionário query
 */
function generateQuestionarioQuery(empresaId: TEmpresa["id"], id: string = "") {
  const categoriasInovacaoCollectionReference =
    getQuestionarioCollectionReference(empresaId).withConverter(questionarioConverter);

  if (id && id !== "") {
    return query(categoriasInovacaoCollectionReference, where("deletedAt", "==", null), where("idProjeto", "==", id));
  }

  return query(categoriasInovacaoCollectionReference, where("deletedAt", "==", null));
}

/**
 * Get questionário for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param id - Project id to get comitês
 * @returns - questionário document snapshot
 */
export function getQuestionarios(empresaId: TEmpresa["id"], id: string) {
  const q = generateQuestionarioQuery(empresaId, id);
  return getDocs(q);
}

/**
 * Get questionário query for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param idQuestionario - id do questionario
 * @returns - questionário query
 */
function generateGrupoQuery(empresaId: TEmpresa["id"], idQuestionario: string | null | undefined) {
  const gruposCollectionReference = getGruposDiagnosticosCollectionReference(empresaId).withConverter(gruposConverter);

  if (idQuestionario) {
    return query(
      gruposCollectionReference,
      where("deletedAt", "==", null),
      where("idQuestionario", "==", idQuestionario)
    );
  }

  return query(gruposCollectionReference, where("deletedAt", "==", null));
}

/**
 * Get questionário for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param idQuestionario - id do questionario
 * @returns - questionário document snapshot
 */
export function getGrupoArray(empresaId: TEmpresa["id"], idQuestionario: string | null | undefined) {
  const q = generateGrupoQuery(empresaId, idQuestionario);
  return getDocs(q);
}

/**
 * Get questionário query for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param idProjeto -  id to get questionarios
 * @returns - questionário query
 */
// prettier-ignore
function generateQuestionarioArrayQuery(empresaId: TEmpresa["id"], idProjeto: Array<string> | undefined) {
  const categoriasInovacaoCollectionReference =
    getQuestionarioCollectionReference(empresaId).withConverter(questionarioConverter);
    return query(categoriasInovacaoCollectionReference, where("deletedAt", "==", null), where("idProjeto", "in", idProjeto));
}

/**
 * Get questionário for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param idProjeto - id to get questionarios
 * @returns - questionário document snapshot
 */
// prettier-ignore
export function getQuestionariosArray(empresaId: TEmpresa["id"], idProjeto: Array<string> | undefined) {
  const q = generateQuestionarioArrayQuery(empresaId, idProjeto);
  return getDocs(q);
}

/**
 * Get questionário query for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param idProjeto -  id to get projeto
 * @returns - questionário query
 */
function generateQuestionarioAplicadoArrayQuery(empresaId: TEmpresa["id"], idProjeto: TQuestionario["id"] | undefined) {
  const q = getQuestionarioCollectionReference(empresaId).withConverter(questionarioConverter);
  return query(q, where("deletedAt", "==", null), where("idProjeto", "==", idProjeto));
}

/**
 * Get questionário for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param idProjeto - id to get projeto
 * @returns - questionário document snapshot
 */
export function getQuestionarioAplicadoArray(empresaId: TEmpresa["id"], idProjeto: TQuestionario["id"] | undefined) {
  const q = generateQuestionarioAplicadoArrayQuery(empresaId, idProjeto);
  return getDocs(q);
}

/**
 * Get questionário for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param idProjeto - id to get questionarios
 * @returns - questionário document snapshot
 */
export function getQuestionariosArrayNew(empresaId: TEmpresa["id"], idProjeto: TProjeto["id"] | undefined) {
  const q = generateQuestionarioAplicadoArrayQuery(empresaId, idProjeto);
  return getDocs(q);
}

/**
 * Get questionarios ids by projeto
 * @param empresaId - Empresa id
 * @param projeto - Projeto id
 * @returns - Questionarios ids
 */
export async function getGruposIdsByProjeto(empresaId: TEmpresa["id"], projeto: TProjeto["id"]) {
  const questionariosIds = await getQuestionariosIdsByProjeto(empresaId, projeto);
  const gruposCollectionReference = getGruposDiagnosticosCollectionReference(empresaId);
  const queryGrupos = query(gruposCollectionReference, where("idQuestionario", "in", questionariosIds));
  const grupos = await getDocs(queryGrupos);
  return grupos.docs.map((document_) => document_.id);
}
