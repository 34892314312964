import { Group, Loader, Text } from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { getConviteByEmail } from "@/modules/convites/firestore";
import { getLicenciados } from "@/modules/projetos/firestore";
import { QuestionarioContent } from "@/modules/responder-questionario/components/v2/QuestionarioContent";
import useQueryGroupConvite from "@/modules/responder-questionario/hooks/useQueryGroupConvite";
import { setActiveLicenciadaId } from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import CreateConviteModal from "./CreateConviteModal";
import useGetProjetoItem from "../hooks/useGetProjeto";
import useGetQuestionarioItem from "../hooks/useGetQuestionarioFromUrl";

type Parameters_ = {
  licenciadaId: string;
  empresaId: string;
  questionarioId: string;
  empresaRespondenteId: string;
  email?: string;
};

const ResponderQuestionarioExternoPage = () => {
  const {
    licenciadaId,
    empresaId = "",
    questionarioId = "",
    email,
    empresaRespondenteId = ""
  } = useParams<Parameters_>();

  const [loading, setLoading] = useState(true);
  const [licenciado, setLicenciado] = useState<TLicenciado | null>(null);
  const [convite, setConvite] = useState<TConvite | null>(null);

  const userLogged = getCurrentUser();

  const { data: questionario } = useGetQuestionarioItem(empresaId, questionarioId);
  const { data: gruposFound } = useQueryGroupConvite({ id: questionario?.id, activeEmpresaId: empresaId });

  const { data: projeto } = useGetProjetoItem(empresaId, questionario?.idProjeto ?? "");

  const grupos = useMemo(
    () => gruposFound?.sort((a, b) => a.ordemQuestionario - b.ordemQuestionario) ?? [],
    [gruposFound]
  );

  const handleCreateConvite = (conviteCreated: TConvite) => {
    setConvite(conviteCreated);
  };

  useEffect(() => {
    const loadLicenciados = async () => {
      const arrayLicenciados = await getLicenciados(convite?.idEmpresa || empresaId || "");
      const aux = arrayLicenciados.docs.map((desafio) => desafio.data());
      setLicenciado(aux.find((item) => item?.id === projeto?.licenciado) as TLicenciado);
      setLoading(false);
    };

    void loadLicenciados();
  }, [projeto?.licenciado]);

  useEffect(() => {
    setActiveLicenciadaId(licenciadaId);
  }, [licenciadaId]);

  useEffect(() => {
    const userEmail = email ?? localStorage.getItem("emailConvite");

    const loadConvite = async () => {
      const convite = await getConviteByEmail(empresaId, questionarioId, empresaRespondenteId, userEmail ?? "");
      const conviteFound = convite?.data() ?? null;

      const isConviteInvalid = !!conviteFound?.cancelado || (!email && !conviteFound?.externo);
      if (isConviteInvalid) {
        return;
      }

      if (conviteFound) {
        setConvite(conviteFound);
      }
    };

    void loadConvite();
  }, [email, !questionario]);

  if (loading) {
    return (
      <Group justify="center" align="center" style={{ height: "100vh" }}>
        <Text fw="bold" c="blue">
          Buscando questionário...
        </Text>
        <Loader size="xs" />
      </Group>
    );
  }

  if (
    questionario?.dataVigencia &&
    new Date(new Date(questionario.dataVigencia).setHours(0, 0, 0, 0)) < new Date(new Date().setHours(0, 0, 0, 0))
  ) {
    return (
      <Group justify="center" align="center" style={{ height: "100vh" }}>
        <Text fw="bold" c="red">
          O questionário não está mais disponível.
        </Text>
      </Group>
    );
  }

  if (!empresaId) {
    return (
      <Group justify="center" align="center" style={{ height: "100vh" }}>
        <Text fw="bold" c="red">
          Empresa não encontrada!
        </Text>
      </Group>
    );
  }

  if (!questionario) {
    return (
      <Group justify="center" align="center" style={{ height: "100vh" }}>
        <Text fw="bold" c="red">
          Questionário não encontrado!
        </Text>
      </Group>
    );
  }

  if (convite?.cancelado) {
    return (
      <Group justify="center" align="center" style={{ height: "100vh" }}>
        <Text fw="bold" c="red">
          Seu convite foi cancelado!
        </Text>
      </Group>
    );
  }

  //se estiver logado e o email do usario for diferente do email passado na url
  if (userLogged !== null && userLogged?.email !== email) {
    return (
      <Group justify="center" align="center" style={{ height: "100vh" }}>
        <Text fw="bold" c={"red"}>
          Esse questionário não foi enviado para você. Deslogue ou entre na conta correta!
        </Text>
      </Group>
    );
  }

  if (!convite) {
    return (
      <CreateConviteModal
        licenciadaId={licenciadaId}
        empresaId={empresaId}
        questionario={questionario}
        empresaRespondenteId={empresaRespondenteId}
        onClose={handleCreateConvite}
        opened
      />
    );
  }

  return (
    <Group>
      <QuestionarioContent
        grupos={grupos}
        questionario={questionario}
        licenciado={licenciado ?? undefined}
        userId={convite.idColaboradorConvidado}
        empresaId={convite.idEmpresa}
        empresaRespondenteId={empresaRespondenteId}
      />
    </Group>
  );
};

export default ResponderQuestionarioExternoPage;
