import { subject } from "@casl/ability";
import { PaginationState, SortingState } from "@tanstack/react-table";
import {
  DocumentReference,
  FirestoreDataConverter,
  Timestamp,
  addDoc,
  collection,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where
} from "firebase/firestore";
import { getCurrentUser } from "@/services/auth";
import { generateQuery, getDocumentReferenceFromPath } from "@/utils/firestore";
import {
  TCampanhaRegulamentoIdeiasForm,
  addCampanhaRegulamentoIdeias,
  updateCampanhaRegulamentoIdeias
} from "../campanha-regulamento-ideias/firestore";
import { getEmpresaDocumentReference } from "../empresas/firestore";

const DEFAULT_LIMIT = 10;
export const REGULAMENTOS_IDEIAS_COLLECTION_KEY = ["regulamento-ideias"] as const;
export const DEFAULT_SORTING: SortingState = [{ id: "createdAt", desc: true }];
export const DEFAULT_PAGINATION: PaginationState = { pageIndex: 0, pageSize: DEFAULT_LIMIT };

export type TRegulamentoIdeiasForm = TFormWithTransformations<TRegulamentoIdeias, "deletedAt"> & {
  campanhas: TCampanhaRegulamentoIdeiasForm[];
};

type TRegulamentoIdeiasDocument = TFirestoreDocument<TRegulamentoIdeias>;

const regulamentoIdeiasConverter: FirestoreDataConverter<TRegulamentoIdeias> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    // delete data.__caslSubjectType__;
    return data;
  },
  fromFirestore(snap) {
    const { createdAt, updatedAt, deletedAt, dataInicial, dataFinal, ...document } =
      snap.data() as TRegulamentoIdeiasDocument;

    const data: TRegulamentoIdeias = subject("TRegulamentoIdeias", {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      deletedAt: deletedAt?.toDate() ?? null,
      dataInicial: dataInicial.toDate(),
      dataFinal: dataFinal.toDate(),
      refPath: snap.ref.path,
      permitirIdeiaPrivada: document.permitirIdeiaPrivada ?? false
    });

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }
    return data;
  }
};

type TRegulamentoIdeiasActivitiesDocument = TFirestoreDocument<TRegulamentoIdeiasActivities>;

const regulamentoIdeiasActivitiesConverter: FirestoreDataConverter<TRegulamentoIdeiasActivities> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const { createdAt, updatedAt, ...document } = snap.data() as TRegulamentoIdeiasActivitiesDocument;

    const data: TRegulamentoIdeiasActivities = subject("TRegulamentoIdeiasActivities", {
      ...document,
      id: snap.id,
      createdAt: createdAt?.toDate(),
      refPath: snap.ref.path,
      totalMoedas: document.totalMoedas ?? 0
    });

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }
    return data;
  }
};

/**
 * Get all regulamentoIdeias collection reference
 * @param empresaId - Empresa id
 * @returns - RegulamentoIdeias collection reference
 */
function getRegulamentoIdeiasCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, "regulamentosIdeias");
}

/**
 * Get a regulamentoIdeias document reference
 * @param empresaId - Empresa id
 * @param regulamentoIdeiasId - RegulamentoIdeias id to get
 * @returns - RegulamentoIdeias document reference
 */
export function getRegulamentoIdeiasDocumentReference(
  empresaId: TEmpresa["id"],
  regulamentoIdeiasId: TRegulamentoIdeias["id"]
) {
  const regulamentoIdeiasCollectionReference =
    getRegulamentoIdeiasCollectionReference(empresaId).withConverter(regulamentoIdeiasConverter);
  return doc(regulamentoIdeiasCollectionReference, regulamentoIdeiasId);
}

/**
 * Get all regulamentoIdeias collection reference
 * @param empresaId - Empresa id
 * @param regulamentoId - RegulamentoIdeias id
 * @returns - RegulamentoIdeias collection reference
 */
function getRegulamentoActivitiesPerColaboradorCollectionReference(
  empresaId: TEmpresa["id"],
  regulamentoId: TRegulamentoIdeias["id"]
) {
  const regulamentoReference = getRegulamentoIdeiasDocumentReference(empresaId, regulamentoId);
  return collection(regulamentoReference, "regulamentoActivitiesPerColaborador");
}

/**
 * Get all regulamentoIdeias collection reference
 * @param empresaId - Empresa id
 * @param regulamentoId - RegulamentoIdeias id
 * @param colaboradorId - Colaborador id
 * @returns - RegulamentoIdeias collection reference
 */
function getRegulamentoActivitiesPerColaboradorDocumentReference(
  empresaId: TEmpresa["id"],
  regulamentoId: TRegulamentoIdeias["id"],
  colaboradorId: TColaborador["id"]
) {
  const activities = getRegulamentoActivitiesPerColaboradorCollectionReference(empresaId, regulamentoId);
  return doc(activities, colaboradorId);
}

/**
 * Get a regulamentoIdeias by id
 * @param empresaId - Empresa id to get the regulamentoIdeias
 * @param id - RegulamentoIdeias ID
 * @returns - Promise with the regulamentoIdeias document
 */
export function getRegulamentoIdeiasById(empresaId: TEmpresa["id"], id: TRegulamentoIdeias["id"]) {
  const regulamentoIdeiasCollectionReference = getRegulamentoIdeiasCollectionReference(empresaId);
  const regulamento = doc(regulamentoIdeiasCollectionReference, id).withConverter(regulamentoIdeiasConverter);
  return getDoc(regulamento);
}

/**
 * Get a regulamentoIdeias document reference from a reference path
 * @param rePath - RegulamentoIdeias reference path
 * @returns - RegulamentoIdeias document
 */
export function getRegulamentoDocumentReferenceFromReferencePath(rePath: string) {
  return getDocumentReferenceFromPath(rePath, regulamentoIdeiasConverter);
}

/**
 * Get a regulamentoIdeias document from a reference path
 * @param rePath - RegulamentoIdeias reference path
 * @returns - RegulamentoIdeias document
 */
export function getRegulamentoDocumentFromReferencePath(rePath: string) {
  const document = getRegulamentoDocumentReferenceFromReferencePath(rePath);
  return getDoc(document);
}

/**
 * Add a regulamentoIdeias to the database
 * @param empresaId - Empresa id
 * @param regulamento - Empresa to add
 * @returns - Promise with the regulamentoIdeias reference
 */
export async function addRegulamentoIdeias(
  empresaId: TEmpresa["id"],
  regulamento: Omit<TRegulamentoIdeiasForm, "id" | "refPath">
) {
  const regulamentoIdeiasCollectionReference = getRegulamentoIdeiasCollectionReference(empresaId);

  const { campanhas, ...data } = regulamento;

  const regulamentoSnapshot = await addDoc(regulamentoIdeiasCollectionReference, data);

  const addAllCampanhasPromises = campanhas.map((campanha) =>
    addCampanhaRegulamentoIdeias(empresaId, regulamentoSnapshot.id, campanha)
  );

  // eslint-disable-next-line compat/compat
  await Promise.all(addAllCampanhasPromises);
}

/**
 * Update a regulamentoIdeias to the database
 * @param empresaId - Empresa id
 * @param regulamentoIdeiasId - Empresa id
 * @param regulamentoIdeias - Empresa to update
 * @returns - Promise with the regulamentoIdeias reference
 */
export async function updateRegulamentoIdeias(
  empresaId: TEmpresa["id"],
  regulamentoIdeiasId: TRegulamentoIdeias["id"],
  regulamentoIdeias: TRegulamentoIdeiasForm
) {
  const regulamentoIdeiasDocumentReference = getRegulamentoIdeiasDocumentReference(
    empresaId,
    regulamentoIdeiasId
  ).withConverter(regulamentoIdeiasConverter);
  const currentUserId = getCurrentUser()?.uid;

  const { campanhas, ...data } = regulamentoIdeias;

  const updateAllCampanhasPromises = campanhas
    .filter((campanha) => !!campanha.id)
    .map((campanha) =>
      updateCampanhaRegulamentoIdeias(empresaId, regulamentoIdeiasId, campanha.id as string, campanha)
    );
  const addAllCampanhasPromises = campanhas
    .filter((campanha) => !campanha.id)
    .map((campanha) => addCampanhaRegulamentoIdeias(empresaId, regulamentoIdeiasId, campanha));

  // eslint-disable-next-line compat/compat
  await Promise.all([
    ...addAllCampanhasPromises,
    ...updateAllCampanhasPromises,
    updateDoc(regulamentoIdeiasDocumentReference, {
      ...data,
      updatedAt: serverTimestamp(),
      updatedBy: currentUserId
    })
  ]);
}

/**
 * Delete a regulamentoIdeias to the database
 * @param empresaId -
 * @param regulamentoIdeias - Empresa to delete
 * @returns - Promise with the regulamentoIdeias reference
 */
export function deleteRegulamentoIdeias(empresaId: TEmpresa["id"], regulamentoIdeias: TRegulamentoIdeias) {
  const regulamentoIdeiasDocumentReference = getRegulamentoIdeiasDocumentReference(
    empresaId,
    regulamentoIdeias.id
  ).withConverter(regulamentoIdeiasConverter);
  return updateDoc(regulamentoIdeiasDocumentReference, {
    deletedAt: serverTimestamp()
  });
}

/**
 * Get the active regulamentoIdeias for a ideia
 * @param empresaId - Empresa ID
 * @returns - Promise with all regulamentoIdeias
 */
export async function getActiveRegulamentosIdeias(empresaId: TEmpresa["id"]) {
  const regulamentosIdeiasCollectionReference =
    getRegulamentoIdeiasCollectionReference(empresaId).withConverter(regulamentoIdeiasConverter);

  const todayAtBeginning = new Date();
  todayAtBeginning.setHours(0, 0, 0, 0);

  const todayAtEnd = new Date(todayAtBeginning);
  todayAtEnd.setDate(todayAtEnd.getDate() + 1);

  const queryRegulamentosStarted = query(
    regulamentosIdeiasCollectionReference,
    where("deletedAt", "==", null),
    where("dataInicial", "<=", Timestamp.fromDate(todayAtBeginning))
  );
  const queryRegulamentosNotEnded = query(
    regulamentosIdeiasCollectionReference,
    where("deletedAt", "==", null),
    where("dataFinal", ">=", Timestamp.fromDate(todayAtEnd))
  );

  // eslint-disable-next-line compat/compat
  const [startedRegulamentos, notEndedRegulamentos] = await Promise.all([
    getDocs(queryRegulamentosStarted),
    getDocs(queryRegulamentosNotEnded)
  ]);

  return startedRegulamentos.docs.filter((document_) =>
    notEndedRegulamentos.docs.some((dateDocument) => dateDocument.id === document_.id)
  );
}

/**
 * Sum the total votes for the colaborador from all ideias in the regulamento document
 * @param empresaId - Empresa ID to get the total votes
 * @param colaboradorId - Colaborador ID to get the total votes
 * @param regulamentoDocumentReference - Regulamento document reference to get the total votes
 * @returns - Total votes for the colaborador in the regulamento document
 */
export async function getTotalMoedasForRegulamentoIdeias(
  empresaId: TEmpresa["id"],
  colaboradorId: TColaborador["id"],
  regulamentoDocumentReference: DocumentReference<TRegulamentoIdeias>
) {
  // empresas/{empresaId}/regulamentosIdeias/{regulamentoId}/regulamentoActivitiesPerColaborador/{colaboradorId}
  const activitiesDocument = getRegulamentoActivitiesPerColaboradorDocumentReference(
    empresaId,
    regulamentoDocumentReference.id,
    colaboradorId
  );
  const activitiesDocumentSnapshot = await getDoc(
    activitiesDocument.withConverter(regulamentoIdeiasActivitiesConverter)
  );
  return activitiesDocumentSnapshot.data()?.totalMoedas;
}

/**
 * Get all regulamentoIdeias from the database
 * @param empresaId - User id
 * @param root0 - Query options
 * @param root0.sorting - Sorting options
 * @param root0.filters - Filters options
 * @param root0.pagination - Pagination options
 * @param root0.paginationCursors - Array of pagination cursors (startAt, endAt)[]
 * @returns - Promise with all regulamentoIdeias
 */
export function getPaginatedRegulamentosIdeias(
  empresaId: string,
  {
    sorting = [],
    filters = [],
    pagination = { pageIndex: 0, pageSize: DEFAULT_LIMIT },
    paginationCursors
  }: TPaginatedQueryOptions<TRegulamentoIdeias> = {}
): Promise<TPaginatedQueryResponse<TRegulamentoIdeias>> {
  const queryRegulamentoIdeiasCollectionReference =
    getRegulamentoIdeiasCollectionReference(empresaId).withConverter(regulamentoIdeiasConverter);

  const requiredFilter: TPaginatedQueryOptions<TRegulamentoIdeias>["filters"] = [
    {
      id: "deletedAt",
      value: ["==", null]
    }
  ];
  const allFilters: TPaginatedQueryOptions<TRegulamentoIdeias>["filters"] = [...requiredFilter, ...filters];

  const qAll = generateQuery(queryRegulamentoIdeiasCollectionReference, { sorting, filters: allFilters });
  const qPaginated = generateQuery(queryRegulamentoIdeiasCollectionReference, {
    sorting,
    filters: allFilters,
    pagination,
    paginationCursors
  });

  // eslint-disable-next-line compat/compat
  return Promise.all([getDocs(qPaginated), getCountFromServer(qAll)]);
}

/**
 * Get all cronograma
 * @param empresaId - Empresa id
 * @returns - cronograma collection reference
 */
function getRegulamentoCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, "regulamentosIdeias");
}

/**
 * Get cronograma query for a empresa
 * @param empresaId - Empresa id to get comitês
 * @returns - cronograma query
 */
function generateRegulamentoQuery(empresaId: TEmpresa["id"]) {
  const cronogramaCollectionReference =
    getRegulamentoCollectionReference(empresaId).withConverter(regulamentoIdeiasConverter);
  return query(cronogramaCollectionReference, where("deletedAt", "==", null));
}

/**
 * Get cronograma for a empresa
 * @param empresaId - Empresa id to get comitês
 * @returns - cronograma document snapshot
 */
export function getRegulamento(empresaId: TEmpresa["id"]) {
  const q = generateRegulamentoQuery(empresaId);
  return getDocs(q);
}
