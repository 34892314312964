import { subject } from "@casl/ability";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import {
  ColumnDef,
  ColumnFiltersState,
  PaginationState,
  SortingState,
  TableOptions,
  getCoreRowModel,
  useReactTable
} from "@tanstack/react-table";
import {
  DocumentData,
  DocumentSnapshot,
  FirestoreError,
  QueryCompositeFilterConstraint,
  QueryDocumentSnapshot
} from "firebase/firestore";
import Fuse, { IFuseOptions } from "fuse.js";
import { useEffect, useMemo, useRef, useState } from "react";
import { TSubjects } from "@/src/define-ability-for";

const DEFAULT_LIMIT = 10;
const DEFAULT_SORTING: SortingState = [];
const DEFAULT_PAGINATION: PaginationState = { pageIndex: 0, pageSize: DEFAULT_LIMIT };

type TUseQueryOptions<T extends DocumentData> = Omit<
  UseQueryOptions<TPaginatedQueryResponse<T>, FirestoreError, TPaginatedData<T>>,
  "queryFn" | "select"
>;

type TUseQueryTableOptions<T extends DocumentData> = Pick<TableOptions<T>, "initialState"> & {
  subjectType: TSubjects;
  columns: ColumnDef<T>[];
  queryFunction: (options: TPaginatedQueryOptions<T>) => Promise<TPaginatedQueryResponse<T>>;
} & TUseQueryOptions<T>;

type TQuerySearch<T> = {
  filterValue: string;
  options: IFuseOptions<T>;
};

/**
 * Hook to use the Table component.
 * @description
 * This hook uses the `useQuery` hook from react-query to get the data from firestore and the `useReactTable` hook from react-table to handle the table state
 * @param root0 - Options
 * @param root0.queryFunction - Function to get the data from firestore
 * @param root0.subjectType - Subject type to use in the ability
 * @param root0.initialState - Initial state to use in the table state
 * @param root0.columns - Columns to use in the table
 * @param root0.queryKey - Query key to use in the query
 * @param filter - Query key to use in the query
 * @example
 * ```ts
 * const { table } = useQueryTable<TProjeto>({
 *     queryKey: [QUERY_KEY],
 *     subjectType: "TProjeto",
 *     columns: projectColumns,
 *     initialState: {
 *      columnFilters: [],
 *      globalFilter: "",
 *      pagination: DEFAULT_PAGINATION,
 *      sorting: DEFAULT_SORTING
 *    },
 *     queryFunction: ({ sorting, filters, pagination, paginationCursors }) => {
 *       if (!activeEmpresaId) {
 *         throw new Error("Empresa não selecionada");
 *       }
 *       return getProjetos(activeEmpresaId, {
 *         sorting,
 *         filters,
 *         pagination,
 *         paginationCursors
 *       });
 *     }
 *   });
 * <Table table={table} isLoading={isLoading} />
 * ```
 * @returns The table hook response
 */
function useQueryTable<T extends DocumentData>(
  { queryFunction, subjectType, columns, queryKey = [], initialState = {}, ...queryOptions }: TUseQueryTableOptions<T>,
  filter?: TQuerySearch<T>
) {
  const shouldFilter = !!filter?.filterValue;

  const [sorting, setSorting] = useState<SortingState>(initialState.sorting || DEFAULT_SORTING);
  const [filters, setColumnFilters] = useState<ColumnFiltersState>(initialState.columnFilters || []);
  const [globalFilters, setGlobalFilters] = useState<QueryCompositeFilterConstraint | null>(null);
  const [pagination, setPagination] = useState<PaginationState>(DEFAULT_PAGINATION);
  const paginationCursorsReference = useRef<TPaginatedQueryOptions<T>["paginationCursors"]>(
    new Map<
      number,
      [QueryDocumentSnapshot<T, DocumentData> | undefined, QueryDocumentSnapshot<T, DocumentData> | undefined]
    >()
  );

  /**
   * Hook that handle get paginated data from firestore to be used in the <Table /> component
   * @param subjectType - Subject type to use in the ability
   * @param queryOptions - Query options to use in the useQuery hook
   * @returns The Table handler
   */
  const query = useQuery<TPaginatedQueryResponse<T>, FirestoreError, TPaginatedData<T>>({
    queryKey: [
      ...queryKey,
      { sorting, filters, globalFilters, pagination },
      paginationCursorsReference.current,
      shouldFilter
    ],
    queryFn() {
      return queryFunction({
        sorting,
        filters,
        globalFilters,
        pagination: shouldFilter ? undefined : pagination,
        paginationCursors: shouldFilter ? undefined : paginationCursorsReference.current
      });
    },
    meta: {
      displayNotification: true
    },
    select(dataResponse) {
      const [querySnapshot, aggregateSnapshot] = dataResponse;

      if (querySnapshot.empty) {
        return {
          entries: [],
          firstDocument: undefined,
          lastDocument: undefined,
          total: 0
        };
      }

      let data: T[] = [];

      for (const document_ of querySnapshot.docs) {
        const dataItem = subject(subjectType as string, document_.data()) as unknown as T;
        data.push(dataItem);
      }

      if (shouldFilter) {
        const filterResult = new Fuse(data, filter.options).search(filter.filterValue);
        filterResult.sort(({ score: a = 0 }, { score: b = 0 }) => a - b);
        data = filterResult.slice(0, pagination.pageSize).map((result) => result.item);
      }

      const result: TPaginatedData<T> = {
        entries: data,
        firstDocument: querySnapshot.docs[0],
        lastDocument: querySnapshot.docs.at(-1),
        total: aggregateSnapshot.data().count
      };

      return result;
    },
    ...queryOptions
  });

  useEffect(() => {
    const { firstDocument, lastDocument } = query.data ?? {};
    const paginationCursors = new Map<
      number,
      [DocumentSnapshot<T, DocumentData> | undefined, DocumentSnapshot<T, DocumentData> | undefined]
    >(paginationCursorsReference.current);
    paginationCursors.set(pagination.pageIndex, [firstDocument, lastDocument]);
    paginationCursorsReference.current = paginationCursors;
  }, [pagination.pageIndex, query.data]);

  const totalItems = query.data?.total ?? 0;
  const tableOptions = useMemo<TableOptions<T>>(() => {
    const totalPages = Math.ceil((totalItems || 1) / pagination.pageSize);
    return {
      data: query.data?.entries ?? [],
      columns,
      manualSorting: true,
      manualFiltering: true,
      manualPagination: true,
      meta: {
        totalItems
      },
      initialState,
      state: {
        sorting,
        pagination: pagination,
        columnFilters: filters,
        globalFilter: globalFilters
      },
      pageCount: shouldFilter ? 1 : totalPages,
      onSortingChange: setSorting,
      onColumnFiltersChange: setColumnFilters,
      onGlobalFilterChange: setGlobalFilters,
      onPaginationChange: setPagination,
      getCoreRowModel: getCoreRowModel()
      // debugTable: import.meta.env.MODE === "development"
    };
  }, [columns, initialState, filters, globalFilters, pagination, query.data?.entries, sorting, totalItems]);
  const table = useReactTable<T>(tableOptions);
  return { table, sorting, filters, globalFilters, ...query };
}

export default useQueryTable;
