import { useMutation } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { addAcessos, TAcessoForm } from "../../firestore";

type TRespostaResponse = Awaited<ReturnType<typeof addAcessos>>;
/**
 * Finalizar resposta de inovação mutation hook
 * @param item - resposta de inovação
 * @returns Finalizar resposta de inovação mutation
 */
export function useAcessoQuestionario(item: TAcessoForm & Partial<Pick<TQuestionario, "id" | "refPath">>) {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  return useMutation<TResposta | TRespostaResponse, FirestoreError>({
    mutationFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      return addAcessos(activeEmpresaId, item);
    }
  });
}
