import { Alert, Button, Group } from "@mantine/core";
import { getHotkeyHandler, useListState } from "@mantine/hooks";
import { IconAlertCircle } from "@tabler/icons-react";
import { ChangeEvent, useRef } from "react";
import MentionColaboradoresInput from "@/modules/colaboradores/components/mention-colaboradores-input";
import useDesafioComentarioForm from "./useDesafioComentarioForm";

type DesafioComentarioFormProperties = {
  desafio: TDesafio;
  respostaAoComentario?: TDesafioComentario | null;
  onSuccess?: () => void;
};

const DesafioComentarioForm = ({ desafio, respostaAoComentario, onSuccess }: DesafioComentarioFormProperties) => {
  const [colaboraresMentioned, colaboradoresMentionedHandlers] = useListState<TColaborador["id"]>([]);
  const formReference = useRef<HTMLFormElement>(null);

  const { form, isPending, onSubmit } = useDesafioComentarioForm({
    desafio,
    respostaAoComentario,
    onSuccess() {
      colaboradoresMentionedHandlers.setState([]);
      if (onSuccess) {
        onSuccess();
      }
    }
  });

  const genericError = form.errors[""];

  /**
   * Handle submit
   */
  function handleSubmit() {
    const formElement = formReference.current;
    if (formElement) {
      formElement.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    }
  }

  /**
   * Handle colaborador mentioned
   * @param id - ID of the user mentioned
   */
  function handleColaboradorMentioned(id: string) {
    form.setFieldValue("colaboradoresMencionadosReferences", [id, ...colaboraresMentioned]);
    colaboradoresMentionedHandlers.append(id);
  }

  /**
   * Handle change event from textarea
   * @param event - Change event from textarea
   */
  function handleChange(event: ChangeEvent<HTMLTextAreaElement>) {
    form.setFieldValue("comentario", event.target.value);
  }

  return (
    <form onSubmit={onSubmit} ref={formReference}>
      <MentionColaboradoresInput
        {...form.getInputProps("comentario")}
        onKeyDown={getHotkeyHandler([["mod+Enter", handleSubmit]])}
        value={form.values.comentario}
        onChange={handleChange}
        data-autofocus
        placeholder="Escreva seu comentário aqui. Cmd/Ctrl + Enter para enviar"
        disabled={isPending}
        onColaboradorMentioned={handleColaboradorMentioned}
      />

      {genericError && (
        <Alert icon={<IconAlertCircle size="1rem" />} color="red" mb="md">
          {genericError}
        </Alert>
      )}
      <Group justify="flex-end" mr="sm">
        <Button type="submit" loading={isPending}>
          Enviar
        </Button>
      </Group>
    </form>
  );
};

export default DesafioComentarioForm;
