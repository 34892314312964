import { useForm, yupResolver, TransformedValues } from "@mantine/form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";

import { Asserts } from "yup";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { MARCOS_QUERY_KEY } from "./useGetMarcoFromReferencePath";
import { TMarcoForm, addMarco, updateMarco } from "../firestore";
import marcoSchema from "../schema/marco-movimentacao-schema";

type TMarcoOnly = Omit<TMarcoForm, "dataConclusao" | "descricaoAtuacao" | "anexos">;
const marcoOnlySchema = marcoSchema.omit<"dataConclusao" | "descricaoAtuacao" | "anexos">([
  "dataConclusao",
  "descricaoAtuacao",
  "anexos"
]);

type FormInputs = Asserts<typeof marcoOnlySchema>;
type TAddMarcoResponse = Awaited<ReturnType<typeof addMarco>>;
type TUpdateMarcoResponse = Awaited<ReturnType<typeof updateMarco>>;

/**
 * Hook to handle the ideia movimentação form
 * @param ideia - Ideia to add the movimentação
 * @param onSuccessFunction - On success callback
 * @param marco - Marco
 * @returns - Form props
 */
function useIdeiaMarcoForm(ideia: TIdeia, onSuccessFunction: (path: string | undefined) => void, marco?: TMarcoForm) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const queryClient = useQueryClient();

  const form = useForm<TMarcoOnly>({
    initialValues: {
      ...(marcoOnlySchema.getDefault() as TMarcoOnly),
      ideiaReference: ideia.refPath,
      ...marco
    },
    transformValues: (values) => marcoOnlySchema.cast(values) as TMarcoForm,
    validate: yupResolver(marcoOnlySchema)
  });

  const { mutate, reset, isPending } = useMutation<
    TUpdateMarcoResponse | TAddMarcoResponse,
    FirestoreError,
    FormInputs
  >({
    mutationFn: (values) => {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return values.id
        ? updateMarco(activeEmpresaId, ideia.id, values.id, values)
        : addMarco(activeEmpresaId, ideia.id, values);
    },
    async onSuccess(data) {
      reset();
      form.reset();
      await queryClient.invalidateQueries({ queryKey: [MARCOS_QUERY_KEY] });
      onSuccessFunction(data?.path);
    },
    onError(error) {
      captureException(error, true);
    }
  });

  type TTransformedValues = TransformedValues<typeof form>;

  /**
   * Handle form submit
   * @param values - Form values
   */
  function onSubmit(values: TTransformedValues) {
    mutate(values);
  }

  return {
    ...form,
    isPending,
    onSubmit: form.onSubmit(onSubmit)
  };
}

export default useIdeiaMarcoForm;
