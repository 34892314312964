import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { colaboradorConverter, getColaborador } from "@/modules/colaboradores/firestore";
import useUserStore from "@/modules/users/store";
import { getDocumentReferenceFromPath } from "@/utils/firestore";

type GetColaboradorResponse = Awaited<ReturnType<typeof getColaborador>>;

/**
 * Get all participantes from comite query hook
 * @param comite - Comite de inovação
 * @returns All participantes from comite query
 */
function useGetAllParticipantesFromComite(comite: TComiteInovacao) {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);
  return useQuery<GetColaboradorResponse[], FirestoreError, TColaborador[]>({
    queryKey: ["empresas", { id: activeEmpresaId }, "comitesInovacao", "participantes"],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      // eslint-disable-next-line compat/compat
      return Promise.all(
        comite.participantesReferences.map((reference) => {
          const colaboradorDocumentReference = getDocumentReferenceFromPath(reference, colaboradorConverter);
          return getColaborador(activeEmpresaId, colaboradorDocumentReference.id);
        })
      );
    },
    select(data) {
      const allColaboradores: TColaborador[] = [];

      for (const colaborador of data) {
        if (colaborador.exists()) {
          allColaboradores.push(colaborador.data());
        }
      }
      return allColaboradores;
    }
  });
}

export default useGetAllParticipantesFromComite;
