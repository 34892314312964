import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getDesafiosAll, DESAFIOS_COLLECTION_KEY } from "@/modules/desafios/firestore";
import useUserStore from "@/modules/users/store";

type TGetQuestionarioResponse = Awaited<ReturnType<typeof getDesafiosAll>>;

/**
 * Get questionário de inovação query hook
 * @returns Questionário de inovação query hook
 */
function useGetAllDesafios() {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  return useQuery<TGetQuestionarioResponse, FirestoreError, TDesafio[]>({
    queryKey: ["empresas", { id: activeEmpresaId }, DESAFIOS_COLLECTION_KEY],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      return getDesafiosAll(activeEmpresaId);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }
      return dataResponse.docs.map((document_) => document_.data());
    }
  });
}

export default useGetAllDesafios;
