import { subject } from "@casl/ability";
import { Timestamp, FirestoreDataConverter, collection, getDocs, query, where } from "firebase/firestore";
import { getEmpresaDocumentReference } from "../empresas/firestore";

type TRespostaDocument = Omit<TResposta, "createdAt" | "updatedAt" | "deletedAt"> & {
  createdAt: Timestamp;
  updatedAt?: Timestamp;
  deletedAt: Timestamp | null;
};

const respostaConverter: FirestoreDataConverter<TResposta> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const { createdAt, updatedAt, deletedAt, ...document } = snap.data() as TRespostaDocument;

    const data: TResposta = {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      deletedAt: deletedAt ? deletedAt.toDate() : null,
      refPath: snap.ref.path
    };

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }

    return subject("TResposta", data);
  }
};

/**
 * Get all questionário
 * @param empresaId - Empresa id
 * @returns - questionário collection reference
 */
function getRespostasCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, "respostas");
}

/**
 * Get questionário query for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param ids - Id user
 * @returns - questionário query
 */
function generateRespostasMediaQuery(empresaId: TEmpresa["id"], ids: string[]) {
  const respostasCollectionReference = getRespostasCollectionReference(empresaId).withConverter(respostaConverter);
  return query(respostasCollectionReference, where("deletedAt", "==", null), where("idEmpresa", "in", ids));
}

/**
 * Get questionário for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param ids - Id user
 * @returns - questionário document snapshot
 */
export function getRespostasMedia(empresaId: TEmpresa["id"], ids: string[]) {
  const q = generateRespostasMediaQuery(empresaId, ids);
  return getDocs(q);
}

/**
 * Get questionário query for a empresa
 * @param empresaId - Empresa id to get comitês
 * @returns - questionário query
 */
function generateRespostasMediaEmpresaQuery(empresaId: TEmpresa["id"]) {
  const respostasCollectionReference = getRespostasCollectionReference(empresaId).withConverter(respostaConverter);
  return query(respostasCollectionReference, where("deletedAt", "==", null));
}

/**
 * Get questionário for a empresa
 * @param empresaId - Empresa id to get comitês
 * @returns - questionário document snapshot
 */
export function getRespostasMediaEmpresa(empresaId: TEmpresa["id"]) {
  const q = generateRespostasMediaEmpresaQuery(empresaId);
  return getDocs(q);
}
