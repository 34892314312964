import { Text, Group, Anchor, CloseButton, Flex, TextInput, List } from "@mantine/core";
import { FileWithPath } from "@mantine/dropzone";
import { useHover } from "@mantine/hooks";
import { MouseEvent } from "react";
import { TProjetoForm } from "@/modules/projetos/firestore";

interface Images {
  path: string;
  url: string;
  index: number;
}

/**
 * Anexo to upload component
 * @param root0 - Props
 * @param root0.file - File to upload
 * @param root0.index - File index
 * @param root0.onRemove - On remove callback
 * @param root0.readOnly - Is read only mode enabled
 * @param root0.values = values
 * @param root0.setValues - setValues
 * @returns Component
 */
// prettier-ignore
function FileItem({
  file,
  onRemove,
  index,
  readOnly = false,
  values,
  setValues
}: {
  file: TFileStored | FileWithPath;
  onRemove?: (file: TFileStored | FileWithPath, index: number) => void;
  readOnly?: boolean;
  values: TProjetoForm;
  setValues: (values: TProjetoForm) => void;
  index: number;
}) {
  const { hovered, ref } = useHover<HTMLLIElement>();

  /**
   *
   * @param event - Event
   */
  function handleCloseClick(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    if (onRemove) {
      onRemove(file, index);
    }
  }

  return (
    <List.Item key={file.path} ref={ref} w="100%">
      <Group gap="xs" wrap="nowrap" mb={10} w="100%">
        <Flex direction="column" w="100%">
          <Text truncate="end" mb={10}>
            <Anchor target="_blank" href={file.path}>
              {file.name}
            </Anchor>
          </Text>
          <TextInput
            placeholder="Digitar o link"
            onChange={onChange(values, setValues, file, index)}
            value={values && values.urlImages.find((item: Images) => item.path === file.name)?.url}
            w={"100%"}
          />
        </Flex>
        {hovered && !readOnly && onRemove && <CloseButton size="sm" onClick={handleCloseClick} />}
      </Group>
    </List.Item>
  );
}

export default FileItem;

// prettier-ignore
const onChange = (values: TProjetoForm, setValues: (values: TProjetoForm) => void, file: TFileStored | FileWithPath, indexList: number) => {
  return (event: React.ChangeEvent<HTMLInputElement>) => {

    const oldUrl = values.urlImages && values.urlImages.length > 0 ? values.urlImages : [];
    const urlImages = oldUrl.some((item: Images) => indexList === item.index) ? oldUrl.map((item: Images, index) => indexList === item.index ? { path: file.path, url: event.target.value, index } : item) : [...oldUrl, { path: file.path, url: event.target.value, index: oldUrl.length }]
    setValues(({ ...values, urlImages: urlImages as Images[] }))
  }
}
