import { subject } from "@casl/ability";
import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { QUERY_KEY } from "../components/ideia-comentarios/useGetComentarios";
import { getRespostasAoComentario } from "../firestore";

type TGetRespostasAoComentarioResponse = Awaited<ReturnType<typeof getRespostasAoComentario>>;

/**
 *
 * @param ideia - Ideia to get comments
 * @param comentario - Comentario to get replies
 * @returns - Query result with comments
 */
function useGetComentariosRespostas(ideia: TIdeia, comentario: TIdeiaComentario) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  return useQuery<TGetRespostasAoComentarioResponse, FirestoreError, TIdeiaComentario[]>({
    queryKey: [activeEmpresaId, "ideia", ideia.id, QUERY_KEY, { comentarioId: comentario.id }],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return getRespostasAoComentario(activeEmpresaId, ideia.id, comentario.id);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      const comentarios: TIdeiaComentario[] = [];
      for (const comentario of dataResponse.docs) {
        if (comentario.exists()) {
          comentarios.push(subject("TIdeiaComentario", comentario.data()));
        }
      }
      return comentarios;
    }
  });
}

export default useGetComentariosRespostas;
