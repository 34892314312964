import { subject } from "@casl/ability";
import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { QUERY_KEY } from "@/modules/projetos/components/projetos-table/ProjetosTable";
import { getProjetosItem } from "@/modules/projetos/firestore";

type TGetProjetoResponse = Awaited<ReturnType<typeof getProjetosItem>>;

/**
 * Get projeto from url params
 * @returns - projeto or null if not found
 */
function useGetProjetoFromUrl() {
  // prettier-ignore
  const { id,  idEmpresa } = useParams<{ id: string ,idEmpresa: string}>();

  return useQuery<TGetProjetoResponse, FirestoreError, TProjeto | null>({
    queryKey: [QUERY_KEY, id, idEmpresa],
    queryFn() {
      if (!idEmpresa) {
        throw new Error("Empresa não selecionada");
      }
      if (!id) {
        throw new Error("Ideia não selecionada");
      }

      return getProjetosItem(idEmpresa, id);
    },
    select(dataResponse) {
      if (!dataResponse.exists()) {
        return null;
      }

      return subject("TProjeto", dataResponse.data());
    },
    enabled: !!id && !!idEmpresa
  });
}

export default useGetProjetoFromUrl;
