import { Card, Group, ScrollArea, TextInput, Title } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import { and, or, where } from "firebase/firestore";
import { RefObject, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { THandler } from "@/components/table/Table";
import TablePagination from "@/components/table/TablePagination";
import StatusComiteInovacao from "@/modules/comite-inovacao/components/status-comite-inovacao";
import IdeiasFilterButton from "@/modules/comite-inovacao/components/status-comite-inovacao/IdeiasFilterButton";
import IdeiasTable from "@/modules/ideias/components/ideias-table";
import useCountIdeiasByStatusQuery from "@/modules/ideias/hooks/useCountIdeiasByStatusQuery";

type TIdeiasStatus = Record<TIdeia["status"], { text: string; color: string }>;

export const allStatus: TIdeiasStatus = {
  APROVADA: { text: "Aprovada", color: "green" },
  REPROVADA: { text: "Reprovada", color: "pink.2" },
  BACKLOG_DE_AVALIACAO_FUTURA: { text: "Backlog de avaliação futura", color: "gray.4" },
  EM_IMPLEMENTACAO: { text: "Em implementação", color: "blue.2" },
  IMPLEMENTADA: { text: "Implementada", color: "blue" },
  CANCELADA: { text: "Cancelada", color: "orange.4" },
  RASCUNHO: { text: "Rascunho", color: "gray.2" },
  NOVA_IDEIA: { text: "Nova Ideia", color: "green.1" },
  PRE_ANALISE: { text: "Pré analise", color: "indigo.3" },
  EM_ANALISE_DE_VIABILIDADE: { text: "Em analise de viabilidade", color: "grape.3" },
  AGUARDANDO_ANALISE_DO_COMITE: { text: "Aguardando analise", color: "grape" }
};

const IdeiasByStatusFilter = () => {
  const { data: statusCount } = useCountIdeiasByStatusQuery(Object.keys(allStatus) as TIdeia["status"][]);

  return (
    <ScrollArea scrollbars="x">
      <Group wrap="nowrap">
        {statusCount &&
          Object.entries(statusCount).map(([status, total]: [TIdeia["status"], number]) => (
            // eslint-disable-next-line security/detect-object-injection
            <IdeiasFilterButton key={status} status={status} {...allStatus[status]} total={total} />
          ))}
      </Group>
    </ScrollArea>
  );
};

/**
 * Set the global filter to the table
 * @param tableReference - Reference to the table handlers
 */
function useGlobalFilters(tableReference: RefObject<THandler<TIdeia>>) {
  const [query] = useSearchParams();

  useEffect(() => {
    const tableHandlers = tableReference.current;
    if (tableHandlers) {
      const queryStringFilter = query.get("filter");
      const filters = or(
        queryStringFilter
          ? and(where("deletedAt", "==", null), where("status", "==", queryStringFilter))
          : where("deletedAt", "==", null)
      );
      tableHandlers.table.setGlobalFilter(filters);
    }
  }, [query, tableReference]);
}

const IdeiasDeInovacaoPage = () => {
  const tableHandlersReference = useRef<THandler<TIdeia>>(null);
  useGlobalFilters(tableHandlersReference);

  return (
    <>
      <Title order={2} fw="normal" mb="md">
        Comitê de Inovação
      </Title>
      <Card withBorder>
        <Card.Section inheritPadding py="md" withBorder>
          <StatusComiteInovacao title="Histórico do comitê" />
        </Card.Section>
        <Card.Section inheritPadding py="md">
          <Group justify="space-between">
            <Title order={3} fw="normal">
              Análise das ideias
            </Title>
            <TextInput rightSection={<IconSearch size="1rem" />} placeholder="Buscar" />
          </Group>
        </Card.Section>

        <IdeiasByStatusFilter />
        <Card.Section withBorder mt="md">
          <IdeiasTable ref={tableHandlersReference} />
        </Card.Section>
        {tableHandlersReference.current && (
          <Card.Section withBorder>
            <TablePagination tableHandlers={tableHandlersReference.current} />
          </Card.Section>
        )}
      </Card>
    </>
  );
};

export default IdeiasDeInovacaoPage;
