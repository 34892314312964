/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import { Box, Button, Group, LoadingOverlay, Select, Stack, Text, TextInput } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { useState } from "react";
import useQueryProjetos from "@/modules/questionarios/components/questionario-form/useQueryProjetos";
import { extractTextInputPropertiesFromFormSchema } from "@/utils/form";
import { TRegiaoForm } from "../../firestore";
import useRegiaoForm from "../../hooks/useRegiaoForm";
import regiaoSchema from "../../schema/regiao-schema";
import IdentifierFormModal from "../modals/identifier-form-modal/IdentifierFormModal";
import IdentifierTable from "../tables/identifier-table/IdentifierTable";

const comparisonType = [
  { label: "Corresponder a todos", value: "and" },
  { label: "Corresponder a qualquer", value: "or" }
];

type Properties = {
  regiao: TRegiaoForm;
  onSuccess: () => void;
};

const RegiaoForm = ({ regiao, onSuccess }: Properties) => {
  const [identifierModalOpen, setIdentifierModalOpen] = useState(false);

  const { form, isPending, onSubmit, addIdentifier, removeIdentifier } = useRegiaoForm(regiao, {
    onSuccess
  });

  const { data: projetos, isLoading: loadingProjeto } = useQueryProjetos();

  const handleOpenIdentifierModal = () => {
    setIdentifierModalOpen(true);
  };

  const handleAddIdentifier = (identifier?: TRegiaoIdentifier) => {
    setIdentifierModalOpen(false);
    if (identifier) {
      addIdentifier(identifier);
    }
  };

  const descriptor = regiaoSchema.describe();
  const projetoOptions = projetos?.map((p) => ({ label: p.nome, value: p.id })) ?? [];
  const identifiers = form.getValues().identifiers ?? [];

  return (
    <form onSubmit={onSubmit}>
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />
        <Stack gap="sm" mb="lg">
          <TextInput
            type="text"
            style={{ flex: 1 }}
            {...form.getInputProps("name")}
            {...extractTextInputPropertiesFromFormSchema(descriptor, "name")}
          />

          <Select
            data={projetoOptions}
            disabled={loadingProjeto}
            {...form.getInputProps("projetoId")}
            {...extractTextInputPropertiesFromFormSchema(descriptor, "projetoId")}
          />

          <Select
            data={comparisonType}
            {...form.getInputProps("comparisonType")}
            {...extractTextInputPropertiesFromFormSchema(descriptor, "comparisonType")}
          />

          <Group mt="md" align="center" justify="space-between">
            <Text>Regras de agrupamento</Text>
            <Button onClick={handleOpenIdentifierModal}>
              <IconPlus />
            </Button>
          </Group>

          <IdentifierTable handleRemoveIdentifier={removeIdentifier} identifiers={identifiers} />
        </Stack>
      </Box>
      <Group justify="flex-end" mt="md">
        <Button type="submit" loading={isPending}>
          Salvar
        </Button>
      </Group>
      <IdentifierFormModal onClose={handleAddIdentifier} open={identifierModalOpen} />
    </form>
  );
};

export default RegiaoForm;
