import { Text, FocusTrap, Group, Button, Space, Textarea, Card, Loader } from "@mantine/core";
import { FileWithPath } from "@mantine/dropzone";
import { ContextModalProps } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { getDownloadURL } from "firebase/storage";
import { useCallback, useRef, useState } from "react";
import useUserStore, { setReloadConvites } from "@/modules/users/store";
import { MAX_EVIDENCIAS, MAX_FILE_SIZE } from "@/src/routes/cadastrar-evidencias-page/CadastrarEvidenciasPage";
import { addEvidencia } from "../../firestore";
import { uploadAnexosFilesToStorage } from "../../storage";

const BYTES_IN_MEGABYTE = 1_000_000;

const CadastrarEvidenciasModal = ({
  innerProps,
  context,
  id
}: ContextModalProps<{
  idQuestionario: string;
  idEmpresa: string;
  idProjeto: string;
  idEmpresaColaborador: string;
  totalEvidencias: number;
}>) => {
  const { idQuestionario, idEmpresa, idProjeto, idEmpresaColaborador, totalEvidencias } = innerProps;
  const fileInputReference = useRef<HTMLInputElement>(null);
  const reloadConvites = useUserStore((state) => state.reloadConvites);

  const [isUploadMode, setIsUploadMode] = useState(true);
  const [link, setLink] = useState("");
  const [descricao, setDescricao] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLinkChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLink(event.target.value);
  }, []);

  const handleDescricaoChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescricao(event.target.value);
  }, []);

  const handleSuccess = useCallback(() => {
    context.closeModal(id);
    showNotification({
      message: "Evidência incluída com sucesso!",
      color: "green",
      autoClose: 2500
    });
    setReloadConvites(reloadConvites);
  }, [context, id, reloadConvites]);

  const handleFileClick = useCallback(() => {
    fileInputReference.current?.click();
  }, []);

  const handleFileChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;

      if (!files || files.length === 0) return;

      if (totalEvidencias + files.length > MAX_EVIDENCIAS) {
        showNotification({
          message:
            totalEvidencias >= MAX_EVIDENCIAS
              ? `Limite de upload de evidências por questionário atingido! Máximo de ${MAX_EVIDENCIAS}.`
              : `Restam ${MAX_EVIDENCIAS - totalEvidencias} uploads de evidências disponíveis para este questionário!`,
          color: "red",
          autoClose: 2500
        });
        return;
      }

      const tooBigFiles = [...files].filter((file) => file.size > MAX_FILE_SIZE);

      if (tooBigFiles.length > 0) {
        showNotification({
          message: `Não é permitido anexar arquivo(s) maiores que ${(MAX_FILE_SIZE / BYTES_IN_MEGABYTE).toFixed(0)}MB!`,
          color: "red",
          autoClose: 2500
        });
        return;
      }

      setLoading(true);

      try {
        const filePromises = [...files].map<Promise<TFileStored>>((file: FileWithPath) =>
          uploadAnexosFilesToStorage(idEmpresa, idProjeto, idQuestionario, file).then(async (uploadTaskSnapshot) => {
            const url = await getDownloadURL(uploadTaskSnapshot.ref);
            return {
              path: url,
              name: file.name,
              size: file.size,
              type: file.type
            };
          })
        );
        // eslint-disable-next-line compat/compat
        const uploads = await Promise.all(filePromises);
        for (const upload of uploads) {
          const evidencia: Omit<TEvidencia, "id" | "refPath"> = {
            idEmpresa,
            idProjeto,
            idQuestionario,
            idEmpresaColaborador,
            nomeArquivo: upload.name,
            createdAt: new Date(),
            createdBy: "",
            deletedAt: null,
            link: upload.path || "",
            externo: false,
            conferida: false
          };
          await addEvidencia(evidencia);
        }
        handleSuccess();
      } catch {
        showNotification({
          message:
            "Erro ao enviar o arquivo. Verifique se o mesmo é um arquivo válido (PDF ou Vídeo) e tente novamente.",
          color: "red",
          autoClose: 2500
        });
      } finally {
        setLoading(false);
      }
    },
    [idEmpresa, idProjeto, idQuestionario, idEmpresaColaborador, handleSuccess]
  );

  const handleExternalLinkSubmit = useCallback(async () => {
    if (!descricao || !link || !validarLink(link)) {
      showNotification({
        message: "Por favor, insira um link e uma descrição válidos.",
        color: "red",
        autoClose: 2500
      });
      return;
    }

    setLoading(true);
    try {
      const evidencia: Omit<TEvidencia, "id" | "refPath"> = {
        idEmpresa,
        idProjeto,
        idQuestionario,
        idEmpresaColaborador,
        nomeArquivo: descricao ?? link,
        createdAt: new Date(),
        createdBy: "",
        deletedAt: null,
        link: link,
        externo: true,
        conferida: false
      };

      await addEvidencia(evidencia);
      handleSuccess();
    } catch {
      showNotification({
        message: "Erro ao enviar o link. Tente novamente.",
        color: "red",
        autoClose: 2500
      });
    } finally {
      setLoading(false);
    }
  }, [link, descricao, idEmpresa, idProjeto, idQuestionario, idEmpresaColaborador, handleSuccess]);

  const handleSubmit = useCallback(() => void handleExternalLinkSubmit(), [handleExternalLinkSubmit]);
  const handleFileChangeSubmit = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      void handleFileChange(event);
    },
    [handleExternalLinkSubmit]
  );

  const toggleMode = useCallback(() => {
    setIsUploadMode((previous) => !previous);
  }, []);

  if (loading) {
    return (
      <Card style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Loader color="blue" />
      </Card>
    );
  }

  return (
    <>
      {isUploadMode ? (
        <FocusTrap active>
          <Text fw={700} fz="lg">
            Cadastre as evidências do questionário usando uma das opções abaixo:
          </Text>
          <Space h="md" />
          <Group align="center" justify="center">
            <Button onClick={handleFileClick}>Fazer upload de arquivo</Button>
            <Button onClick={toggleMode}>Cadastrar link de arquivo externo</Button>
          </Group>
          <Text fw={10} fz="sm" mt="md">
            {`* Faça upload de até ${MAX_EVIDENCIAS} arquivos (vídeos e pdf).`}
          </Text>
          <Text fw={10} fz="sm">
            * Cadastre links de arquivos em drives, vídeos, publicações e outros conteúdos online.
          </Text>
          <input
            type="file"
            ref={fileInputReference}
            multiple
            style={{ display: "none" }}
            onChange={handleFileChangeSubmit}
          />
        </FocusTrap>
      ) : (
        <FocusTrap active>
          <Text fw={700} fz="lg">
            Enviar link de um arquivo externo
          </Text>
          <Textarea autosize minRows={1} onChange={handleDescricaoChange} value={descricao} label="Descrição:" />
          <Space h="md" />
          <Textarea autosize minRows={1} onChange={handleLinkChange} value={link} label="Link do arquivo:" />
          <Space h="lg" />
          <Group align="center" justify="center">
            <Button onClick={handleSubmit}>Enviar</Button>
          </Group>
        </FocusTrap>
      )}
    </>
  );
};

export default CadastrarEvidenciasModal;

/**
 *
 * @param link - The link to validate
 * @returns - The return true or false
 */
function validarLink(link: string) {
  // Expressão regular para validar o formato do link
  const regex = /^https?:\/\/.+$/;
  return regex.test(link);
}
