import { useMutation } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { TDesafioForm, updateDesafio, updateDesafioForm } from "@/modules/desafios/firestore";
import useUserStore from "@/modules/users/store";

type TUpdateCronogramaResponse = Awaited<ReturnType<typeof updateDesafio>>;
/**
 * Finalizar resposta de inovação mutation hook
 * @returns Finalizar resposta de inovação mutation
 */
// prettier-ignore
export function useUpdatedDesafio() {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  return useMutation<TCronogramaDesafio | TUpdateCronogramaResponse , FirestoreError, TDesafioForm >({
    mutationFn(item) {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!item) {
        throw new Error("Empresa não selecionada");
      }

      return updateDesafioForm(activeEmpresaId, item.id || "", {
        ...item,

      });
    },
  });
}
