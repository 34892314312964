import { Text, Center, Stack, Alert, Card } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconAlertCircle } from "@tabler/icons-react";
import { ColumnDef, Table as ReactTable } from "@tanstack/react-table";
import { forwardRef, useImperativeHandle } from "react";
import NoData from "@/components/no-data";
import ResetTableButton from "@/components/table/ResetTableButton";
import Table, { THandler } from "@/components/table/Table";
import TablePagination from "@/components/table/TablePagination";
import useQueryTable from "@/components/table/useQueryTable";
import useUserStore from "@/modules/users/store";
import tableColumns, { desafioColumnsMobile } from "./Columns";
import { DEFAULT_PAGINATION, DEFAULT_SORTING, DESAFIOS_COLLECTION_KEY, getDesafios } from "../../firestore";

const DEFAULT_TABLE_KEY = [DESAFIOS_COLLECTION_KEY];

type DesafiosTableProperties = {
  columns?: ColumnDef<TDesafio>[];
  filterValue?: string;
};

const DesafiosTable = forwardRef<THandler<TDesafio>, DesafiosTableProperties>(
  ({ columns = tableColumns, filterValue }, reference) => {
    const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
    const isSmMarches = useMediaQuery("(max-width: 48em)");
    const newColumns = isSmMarches ? desafioColumnsMobile : columns;

    const { table, data, isLoading, error, filters, refetch } = useQueryTable<TDesafio>(
      {
        queryKey: [DESAFIOS_COLLECTION_KEY],
        enabled: !!activeEmpresaId,
        subjectType: "TDesafio",
        columns: newColumns,
        initialState: {
          columnFilters: [],
          globalFilter: null,
          pagination: DEFAULT_PAGINATION,
          sorting: DEFAULT_SORTING
        },
        queryFunction: ({ sorting, filters, pagination, globalFilters, paginationCursors }) => {
          if (!activeEmpresaId) {
            throw new Error("Empresa não selecionada");
          }
          // const newFilter = filters?.concat([{ id: "publishedAt", value: ["!=", null] }])

          return getDesafios(activeEmpresaId, {
            sorting,
            filters,
            globalFilters,
            pagination,
            paginationCursors
          });
        }
      },
      /* prettier-ignore */
      filterValue
        ? {
          filterValue,
          options: {
            includeScore: true,
            keys: ["titulo"]
          }
        }
        : undefined
    );

    useImperativeHandle<THandler<TDesafio>, THandler<TDesafio>>(
      reference,
      () => {
        return {
          table,
          data,
          isLoading,
          error,
          filters
        };
      },
      [filters, data, error, isLoading, table]
    );

    /**
     * Handles the close of the error alert.
     */
    function handleErrorClose() {
      void refetch();
    }

    if (error) {
      return (
        <Alert
          icon={<IconAlertCircle size="1rem" />}
          title="Erro ao buscar desafios"
          color="red"
          withCloseButton
          onClose={handleErrorClose}
        >
          {error.message}
        </Alert>
      );
    }

    const isEmpty = !isLoading && data?.entries.length === 0;

    return (
      <>
        <Table table={table} isLoading={isLoading} />
        {isEmpty && !isLoading && (
          <Center m="lg">
            <NoData m="md">
              <Stack gap="xs">
                <Text c="dimmed">Nenhum desafio encontrado!</Text>
                <ResetTableButton table={table as ReactTable<unknown>} queryKey={DEFAULT_TABLE_KEY}>
                  Limpar filtros
                </ResetTableButton>
              </Stack>
            </NoData>
          </Center>
        )}
        {reference && "current" in reference && reference.current !== null ? (
          <Card.Section withBorder p={10}>
            <TablePagination tableHandlers={reference.current} />
          </Card.Section>
        ) : null}
      </>
    );
  }
);

DesafiosTable.displayName = "DesafiosTable";

export default DesafiosTable;
