/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import { Group, Modal, Text } from "@mantine/core";
import { UseQueryResult } from "@tanstack/react-query";
import { CellContext, ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { FirestoreError, getDoc, Timestamp } from "firebase/firestore";
import { useState, useEffect, useMemo } from "react";
import { getColaboradorDocumentReferenceFromPath } from "@/modules/colaboradores/firestore";
import useGetCronograma from "@/modules/desafio-cronograma/components/desafio-cronograma-list/useGetInscricao";
import useUserStore from "@/modules/users/store";
import { getTotalMoedasForDesafioAggregation } from "@/src/modules/desafios-activities-per-colaborador/firestore";
import { textMapDesafios } from "@/src/routes/gestao-inovacao-page/ExportIdeias";

const ANO = 5000;
const MENOR_DATA = new Date(ANO, 0, 1);
const MAIOR_DATA = new Date(ANO * -1, 0, 1);

const columnHelper = createColumnHelper<TDesafio>();

// Cache local em memória.
const colaboradorCache = new Map<string | TColaborador, TColaborador>();

/**
 *
 * @param root0 - Options
 * @param root0.path - Path to get the document reference
 * @param root0.field - field to return
 * @returns - component
 */
function ColaboradorCell<T extends keyof TColaborador>({ path, field }: { path: string | TColaborador; field: T }) {
  const [colaborador, setColaborador] = useState<TColaborador | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!path) {
      return;
    }
    // Primeiro, verifica se existe no cache
    const cached = colaboradorCache.get(path);
    if (cached) {
      setColaborador(cached);
      setIsLoading(false);
      return;
    }

    // Se não existe no cache, busca no Firestore
    const documentReference = getColaboradorDocumentReferenceFromPath(path as string);
    void getDoc(documentReference).then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.data(); // Type assertion here
        setColaborador(data);
        setIsLoading(false);
        colaboradorCache.set(path, snapshot.data());
      } else {
        setColaborador(null);
        setIsLoading(false);
      }
      return null;
    });
  }, [path]);

  if (isLoading) {
    return <Text fz="sm">Carregando...</Text>;
  }

  if (!colaborador || !Object.prototype.hasOwnProperty.call(colaborador, field)) {
    return <Text fz="sm">Nenhum colaborador encontrado</Text>;
  }

  return (
    <Group gap="xs" wrap="nowrap">
      <Text fz="sm">{colaborador[field]?.toString() ?? "Colaborador"}</Text>
    </Group>
  );
}

/**
 *
 * @param root0 - Options
 * @param root0.desafio - Desafio
 * @returns - component
 */
function BuscarMoedas({ desafio }: { desafio: TDesafio }) {
  const empresa = useUserStore((state) => state.activeEmpresa);
  const [isLoading, setIsLoading] = useState(true);
  const [moedas, setMoedas] = useState<number>(0);

  useEffect(() => {
    const fetchActivities = async () => {
      const moedas: number = await getTotalMoedasForDesafioAggregation(empresa?.id as TEmpresa["id"], desafio.id);
      setMoedas(moedas);
      setIsLoading(false);
    };

    void fetchActivities();
  }, [desafio]);

  if (isLoading) {
    return <Text fz="sm">Carregando.</Text>;
  }

  return (
    <Group gap="xs" wrap="nowrap">
      <Text fz="sm">{moedas}</Text>
    </Group>
  );
}

/**
 * @param properties - Properties
 * @param titulo - Title
 * @returns - texto
 */
function GetTexto(properties: CellContext<TDesafio, Date>, titulo: string) {
  const maxTexto = 150;
  const [opened, setOpened] = useState(false);
  const originalText = properties.getValue<string>();
  const isTooLong = originalText.length > maxTexto;
  const truncatedText = isTooLong ? `${originalText.slice(0, maxTexto)} ...` : originalText;
  return (
    <>
      <Group gap="xs" wrap="nowrap">
        <Text
          fz="sm"
          style={{ cursor: isTooLong ? "pointer" : "default" }}
          onClick={() => {
            if (isTooLong) {
              setOpened(true);
            }
          }}
        >
          {truncatedText}
        </Text>
      </Group>
      <Modal opened={opened} onClose={() => setOpened(false)} title={titulo}>
        <Text>{originalText}</Text>
      </Modal>
    </>
  );
}

/**
 *
 * @param root0 - Options
 * @param root0.desafio - Desafio
 * @param root0.posicao - Posicao
 * @returns - component
 */
function BuscarImersoes({ desafio, posicao }: { desafio: TDesafio; posicao: "primeiro" | "ultimo" }) {
  const cronogramas: UseQueryResult<TCronogramaDesafio[], FirestoreError> = useGetCronograma(desafio.id);
  const isLoading = !cronogramas.data;
  const { menorData, maiorData } = useMemo(() => {
    let menorData = MENOR_DATA;
    let maiorData = MAIOR_DATA;

    if (cronogramas.data) {
      const data: TCronogramaDesafio[] | undefined = cronogramas.data;
      for (const item of data) {
        for (const evento of item.event) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const eventoData = evento.data as Timestamp;
          const eventoTimestamp = new Date(0).setSeconds(eventoData.seconds);
          if (eventoTimestamp > maiorData.getTime()) {
            maiorData = new Date(eventoTimestamp);
          }
          if (eventoTimestamp < menorData.getTime()) {
            menorData = new Date(eventoTimestamp);
          }
        }
      }
    }

    return { menorData, maiorData };
  }, [cronogramas.data, posicao]);

  if (isLoading) {
    return <Text fz="sm">Carregando...</Text>;
  }
  if (menorData === MENOR_DATA || maiorData === MAIOR_DATA) {
    return <Text fz="sm">-</Text>;
  }

  return (
    <Group gap="xs" wrap="nowrap">
      <Text fz="sm">{new Intl.DateTimeFormat("pt-BR").format(posicao === "primeiro" ? menorData : maiorData)}</Text>
    </Group>
  );
}

const columns: ColumnDef<TDesafio>[] = [
  columnHelper.accessor("titulo", {
    header: "Título",
    enableSorting: true,
    cell: (properties) => {
      return (
        <Group gap="xs" wrap="nowrap">
          <Text fz="sm">{properties.getValue()}</Text>
        </Group>
      );
    }
  }),
  columnHelper.display({
    id: "autores",
    header: "Autor(es)",
    enableSorting: true,
    cell: (info) => {
      const desafio = info.row.original;
      return <ColaboradorCell path={desafio.propositorReference} field="nome" />;
    }
  }),
  columnHelper.accessor("descricao", {
    header: "Descrição",
    enableSorting: true,
    cell: (properties) => {
      return GetTexto(properties, "Descrição");
    }
  }),
  columnHelper.accessor("objetivo", {
    header: "Objetivo",
    enableSorting: true,
    cell: (properties) => {
      return GetTexto(properties, "Objetivo");
    }
  }),
  columnHelper.display({
    id: "emailAutores",
    header: "Email Autor(es)",
    enableSorting: true,
    cell: (info) => {
      const desafio = info.row.original;
      return <ColaboradorCell path={desafio.propositorReference} field="email" />;
    }
  }),
  columnHelper.accessor("createdAt", {
    header: "Data Criação",
    enableSorting: true,
    cell: (properties) => {
      return properties.getValue()?.toLocaleDateString();
    }
  }),
  columnHelper.accessor("status", {
    header: "Status",
    enableSorting: true,
    cell: (properties) => {
      return (
        <Group gap="xs" wrap="nowrap">
          <Text fz="sm">{textMapDesafios[properties.getValue() as TDesafioStatus]}</Text>
        </Group>
      );
    }
  }),
  columnHelper.display({
    id: "moedas",
    header: "Moedas",
    enableSorting: true,
    cell: (info) => {
      const desafio = info.row.original;
      return <BuscarMoedas desafio={desafio} />;
    }
  }),
  columnHelper.display({
    id: "primeiraImersao",
    header: "Primeira Imersão",
    enableSorting: true,
    cell: (info) => {
      const desafio = info.row.original;
      return <BuscarImersoes desafio={desafio} posicao={"primeiro"} />;
    }
  }),
  columnHelper.display({
    id: "ultimaImersao",
    header: "Ultima Imersão",
    enableSorting: true,
    cell: (info) => {
      const desafio = info.row.original;
      return <BuscarImersoes desafio={desafio} posicao={"ultimo"} />;
    }
  })
];

export default columns;
