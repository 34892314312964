import TiposDeInovacaoHeader from "@/modules/tipos-de-inovacao/components/tipo-de-inovacao-header";

const TiposDeInovacaoPage = () => {
  return (
    <>
      <TiposDeInovacaoHeader />
    </>
  );
};

export default TiposDeInovacaoPage;
