import { Badge, BadgeProps, Group, MantineColor, Text, Tooltip } from "@mantine/core";

export const colorsMap: Record<TDesafioStatus, MantineColor> = {
  // NOVO_DESAFIO: "green.1",
  DRAFT: "gray.1",
  NOVO_DESAFIO: "green.1",
  PROPOSTO_PELO_COLABORADOR: "gray.3",
  PRE_ANALISE: "blue.5",
  ANALISE_DE_VIABILIDADE: "green.2",
  AGUARDANDO_ANALISE_DO_COMITE: "grape.9",
  APROVADO_PELO_COMITE: "green.4",
  AGENDADO: "grape.6",
  RECUSADO_PELO_COMITE: "dark",
  BACKLOG_PARA_AVALIACAO_FUTURA: "purple",
  EM_REALIZACAO: "indigo.9",
  REALIZADO: "indigo.6",
  SOLUCOES_VALIDADAS: "green.9",
  CANCELADA: "red"
};

const textMap: Record<TDesafioStatus, string> = {
  DRAFT: "Rascunho",
  NOVO_DESAFIO: "Novo Desafio",
  PROPOSTO_PELO_COLABORADOR: "Proposto pelo colaborador",
  PRE_ANALISE: "Pré-análise",
  ANALISE_DE_VIABILIDADE: "Análise de Viabilidade",
  AGUARDANDO_ANALISE_DO_COMITE: "Aguardando análise do comitê",
  APROVADO_PELO_COMITE: "Aprovado pelo comitê",
  AGENDADO: "Agendado",
  RECUSADO_PELO_COMITE: "Recusado pelo comitê",
  BACKLOG_PARA_AVALIACAO_FUTURA: "Backlog para avaliação futura",
  EM_REALIZACAO: "Em realização",
  REALIZADO: "Realizado",
  SOLUCOES_VALIDADAS: "Soluções validadas",
  CANCELADA: "Cancelada"
};

const DesafioStatusBadge = ({
  status,
  variant,
  iconOnly = false
}: {
  status: TDesafioStatus;
  iconOnly?: boolean;
  variant?: BadgeProps["variant"];
}) => {
  // eslint-disable-next-line security/detect-object-injection
  const color = colorsMap[status];
  // eslint-disable-next-line security/detect-object-injection
  const statusText = textMap[status];

  if (iconOnly) {
    return (
      <Tooltip label={statusText} position="right" arrowPosition="center" arrowOffset={10} withArrow>
        <Badge color={color} size="sm" radius="sm" />
      </Tooltip>
    );
  }

  if (variant === "filled") {
    return (
      <Badge color={color} size="sm" radius="sm" variant={variant} autoContrast>
        {statusText}
      </Badge>
    );
  }

  return (
    <Group gap={6}>
      <Badge color={color} size="sm" radius="sm" variant={variant} />
      <Text>{statusText}</Text>
    </Group>
  );
};

export default DesafioStatusBadge;
