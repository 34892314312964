import { Card, Group, Space, Text } from "@mantine/core";
import AutorizacaoItem from "../autorizacao-item";
import HeaderTable from "../header-table";
// prettier-ignore
const ListaDeAutorizacaoDePerfil = ({ select }: { select: string | null }) => {
  return (
    <>
      <HeaderTable />
      <Card radius={0}>
        {select === null ?
          <Group align={"center"} justify={"center"}>
            <Text size="md">Acesso não selecionado!</Text>
          </Group>
          : lista.map(item => <AutorizacaoItem
            key={item.id}
            item={{ ...item, editar: item.editar ?? false, remover: item.remover ?? false, criar: item.criar ?? false }} select={select} />)}
        <Space h={"md"} />
      </Card>
    </>
  );
};

export default ListaDeAutorizacaoDePerfil;

const lista = [
  { id: "1", label: "Empresa", editar: false, remover: false, criar: false },
  { id: "2", label: "Colaboradores", editar: false, remover: false, criar: false },
  { id: "3", label: "Ideias", editar: false, remover: false, criar: false },
  // { id: "4", label: "Regulamento" },
  { id: "5", label: "Questionario", editar: false, remover: false, criar: false },
  { id: "6", label: "Grupos", editar: false, remover: false, criar: false },
  { id: "7", label: "Pegunta", editar: false, remover: false, criar: false },
  { id: "8", label: "Tipo de Respostas", editar: false, remover: false, criar: false },
  { id: "9", label: "Desafio", editar: false, remover: false, criar: false },
  // { id: "10", label: "Marco" },
  { id: "11", label: "Categoria de inovação", editar: false, remover: false, criar: false },
  { id: "12", label: "Tipo de jogada", editar: false, remover: false, criar: false },
  { id: "13", label: "Projetos", editar: false, remover: false, criar: false },
  { id: "14", label: "Regulamento de desafios", editar: false, remover: false, criar: false },
  { id: "15", label: "Regulamento de ideias", editar: false, remover: false, criar: false },
  { id: "16", label: "Comitê de inovação", editar: true, remover: true, criar: false },
  { id: "17", label: "Atividades", editar: false, remover: true, criar: true },
  { id: "18", label: "Ranking de colaboradores", editar: false, remover: true, criar: true },
  { id: "19", label: "Implementação das jogadas", editar: true, remover: true, criar: false },
  { id: "20", label: "Tipo de inovação", editar: false, remover: false, criar: false },
  { id: "21", label: "Jogada de inovacão", editar: false, remover: false, criar: false },
  { id: "22", label: "Categoria de desafios", editar: false, remover: false, criar: false },
  { id: "23", label: "Visão Geral de Ideias", editar: true, remover: true, criar: true },
  { id: "24", label: "Visão Geral de Desafios", editar: true, remover: true, criar: true },
  { id: "25", label: "Gerenciar Evidências", editar: false, remover: true, criar: true }
];
