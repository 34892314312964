import { subject } from "@casl/ability";
import { FileWithPath } from "@mantine/dropzone";
import { PaginationState, SortingState } from "@tanstack/react-table";
import {
  DocumentReference,
  FirestoreDataConverter,
  collection,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
  arrayUnion,
  arrayRemove,
  limit
} from "firebase/firestore";
import { getDownloadURL } from "firebase/storage";
import { getCurrentUser } from "@/services/auth";
import { generateQuery } from "@/utils/firestore";
import desafioFormSchema from "./schema";
import { uploadAnexosFilesToStorage } from "./storage";
import { TColaboradorDocument } from "../colaboradores/firestore";
import { getEmpresaDocumentReference } from "../empresas/firestore";

export const DESAFIOS_COLLECTION_KEY = "desafios" as const;

const DEFAULT_LIMIT = 10;
const TOP_DESAFIOS_MOST_VOTED_LIMIT = 3;
export const DEFAULT_PAGINATION: PaginationState = { pageIndex: 0, pageSize: DEFAULT_LIMIT };
export const DEFAULT_SORTING: SortingState = [{ id: "titulo", desc: false }];

export type TDesafioForm = TFormWithTransformations<
  Omit<TDesafio, "status" | "rankingByMoedas" | "participantesReferences">,
  "propositorReference" | "categoriaReference" | "regulamentoReference" | "deletedAt" | "publishedAt"
> & {
  anexosFilesToUpload: FileWithPath[];
  participantesReferences: DocumentReference<TColaborador, TColaboradorDocument>[];
  status: string;
};

type TDesafioDocument = TFirestoreDocument<
  Omit<TDesafio, "status" | "participantesReferences">,
  "propositorReference" | "categoriaReference" | "regulamentoReference"
> & {
  participantesReferences: DocumentReference<TColaborador, TColaboradorDocument>[];
  status: TDesafioStatus;
};

export const desafioConverter: FirestoreDataConverter<TDesafio> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snapshot, options) {
    const {
      createdAt,
      updatedAt,
      deletedAt,
      status,
      propositorReference,
      participantesReferences,
      categoriaReference,
      regulamentoReference,
      publishedAt,
      ...data
    } = snapshot.data(options) as TDesafioDocument;
    const baseData = desafioFormSchema.cast(data, { stripUnknown: true, assert: false });

    const desafio: TDesafio = {
      ...baseData,
      ...data,
      id: snapshot.id,
      refPath: snapshot.ref.path,
      propositorReference: propositorReference.path,
      categoriaReference: categoriaReference.path,
      regulamentoReference: regulamentoReference?.path,
      createdAt: createdAt.toDate(),
      deletedAt: deletedAt ? deletedAt.toDate() : null,
      publishedAt: publishedAt ? publishedAt.toDate() : null,
      updatedAt: updatedAt?.toDate(),
      participantesReferences: (participantesReferences || []).map((reference) => reference.path),
      status
    };

    return subject("TDesafio", desafio);
  }
};

/**
 * Get desafios collection reference from empresaId
 * @param empresaId - Empresa id to get the desafios collection reference
 * @returns - desafios collection reference
 */
function getDesafiosCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, DESAFIOS_COLLECTION_KEY);
}

/**
 * Add desafio to the database
 * @param empresaId - Empresa id to get the desafios collection reference
 * @param desafio - Desafio to add to the database (without id and refPath)
 * @returns - Promise with the desafio id
 */
export async function addDesafio(empresaId: TEmpresa["id"], desafio: TDesafioForm) {
  const collectionReference = getDesafiosCollectionReference(empresaId);

  const { anexosFilesToUpload, ...data } = desafio;

  const desafioDocument = doc(collectionReference);

  // Upload anexos files
  const anexosPromises = anexosFilesToUpload.map<Promise<TFileStored>>((file) =>
    uploadAnexosFilesToStorage(empresaId, desafioDocument.id, file).then(async (uploadTaskSnapshot) => {
      const url = await getDownloadURL(uploadTaskSnapshot.ref);
      return {
        path: url,
        name: uploadTaskSnapshot.metadata.name,
        size: uploadTaskSnapshot.metadata.size,
        type: uploadTaskSnapshot.metadata.contentType || ""
      };
    })
  );
  // eslint-disable-next-line compat/compat
  const anexos = await Promise.all(anexosPromises);

  return setDoc(desafioDocument, { ...data, anexos });
}

/**
 * Get a desafio document snapshot
 * @param empresaId - Empresa id to get the desafio
 * @param desafio - desafio id to get the desafio
 * @returns - Desafio document snapshot
 */
export function getDesafio(empresaId: TEmpresa["id"], desafio: TDesafio["id"]) {
  const documentReference = getDesafioDocumentReference(empresaId, desafio).withConverter(desafioConverter);
  return getDoc(documentReference);
}

/**
 * Update desafio on the database
 * @param empresaId - Empresa id
 * @param desafioId - Desafio id to update
 * @param desafio - Desafio data
 * @returns - Promise with the desafio reference
 */
export function updateDesafio(empresaId: TEmpresa["id"], desafioId: TDesafio["id"], desafio: TDesafioForm) {
  const collectionReference = getDesafioDocumentReference(empresaId, desafioId).withConverter(desafioConverter);
  const currentUserId = getCurrentUser()?.uid;

  return updateDoc(collectionReference, {
    ...desafio,
    updatedAt: serverTimestamp(),
    updatedBy: currentUserId
  });
}

/**
 * Update desafio on the database
 * @param empresaId - Empresa id
 * @param desafioId - Desafio id to update
 * @param desafio - Desafio data
 * @returns - Promise with the desafio reference
 */
export function updateDesafioForm(empresaId: TEmpresa["id"], desafioId: TDesafio["id"], desafio: TDesafioForm) {
  const collectionReference = getDesafioDocumentReference(empresaId, desafioId).withConverter(desafioConverter);
  const currentUserId = getCurrentUser()?.uid;
  return updateDoc(collectionReference, {
    ...desafio,
    categoriaReference: { path: desafio?.categoriaReference },
    regulamentoReference: { path: desafio?.regulamentoReference },
    propositorReference: { path: desafio?.propositorReference },
    updatedAt: serverTimestamp(),
    updatedBy: currentUserId
  });
}

/**
 * Include participante into propositorReference
 * @param empresaId - Empresa id
 * @param desafioId - Desafio id to include participante
 * @param participanteReferences - Participante reference to include
 * @returns - Promise with the desafio reference
 */
export function includeParticipante(
  empresaId: TEmpresa["id"],
  desafioId: TDesafio["id"],
  ...participanteReferences: DocumentReference<TColaborador>[]
) {
  const collectionReference = getDesafioDocumentReference(empresaId, desafioId).withConverter(desafioConverter);
  const currentUserId = getCurrentUser()?.uid;
  return updateDoc(collectionReference, {
    participantesReferences: arrayUnion(...participanteReferences),
    updatedAt: serverTimestamp(),
    updatedBy: currentUserId
  });
}

/**
 * Remove participante from propositorReference
 * @param empresaId - Empresa id
 * @param desafioId - Desafio id to remove participante
 * @param participanteReferences - Participante reference to remove
 * @returns - Promise with the desafio reference
 */
export function removeParticipante(
  empresaId: TEmpresa["id"],
  desafioId: TDesafio["id"],
  ...participanteReferences: DocumentReference<TColaborador>[]
) {
  const collectionReference = getDesafioDocumentReference(empresaId, desafioId).withConverter(desafioConverter);
  const currentUserId = getCurrentUser()?.uid;
  return updateDoc(collectionReference, {
    participantesReferences: arrayRemove(...participanteReferences),
    updatedAt: serverTimestamp(),
    updatedBy: currentUserId
  });
}

/**
 * Get desafios documents from empresaId
 * @param empresaId - Empresa id to get the desafios documents
 * @param options - Options to get the desafios documents
 * @param options.sorting - Sorting options
 * @param options.filters - Filters options
 * @param options.pagination - Pagination options
 * @param options.paginationCursors - Array of pagination cursors (startAt, endAt)[]
 * @param options.globalFilters - Global filters queryConstraints
 * @returns - Desafios documents
 */
export function getDesafios(
  empresaId: TEmpresa["id"],
  {
    sorting = [],
    filters = [],
    pagination = { pageIndex: 0, pageSize: DEFAULT_LIMIT },
    globalFilters,
    paginationCursors
  }: TPaginatedQueryOptions<TDesafio> = {}
): Promise<TPaginatedQueryResponse<TDesafio>> {
  const collectionReference = getDesafiosCollectionReference(empresaId).withConverter(desafioConverter);

  const qAll = generateQuery(collectionReference, { filters, globalFilters });
  const qPaginated = generateQuery(collectionReference, {
    sorting,
    filters,
    globalFilters,
    pagination,
    paginationCursors
  });

  // eslint-disable-next-line compat/compat
  return Promise.all([getDocs(qPaginated), getCountFromServer(qAll)]);
}

/**
 * Get desafios documents from empresaId and colaboradorId with same regulamento
 * @param empresaId - Empresa id to get the desafios documents
 * @param regulamentoDocumentReference - Regulamento document reference to get the desafios documents
 * @returns - Desafios documents
 */
export function getDesafiosByRegulamento(
  empresaId: TEmpresa["id"],
  regulamentoDocumentReference: DocumentReference<TRegulamentoDesafios>
) {
  // Get all desafios with same regulamento and not deleted
  const desafiosActivitiesPerColaboradorCollection = getDesafiosCollectionReference(empresaId);
  const desafiosSameRegulamentoQuery = query(
    desafiosActivitiesPerColaboradorCollection,
    where("regulamentoReference", "==", regulamentoDocumentReference),
    where("deletedAt", "==", null)
  ).withConverter(desafioConverter);

  return getDocs(desafiosSameRegulamentoQuery);
}

/**
 * Get desafios sorted by ranking from empresaId
 * @param empresaId - Empresa id to get the desafios documents
 * @returns - Desafios documents
 */
export function getDesafiosSortedByRanking(empresaId: TEmpresa["id"]) {
  const desafiosCollectionReference = getDesafiosCollectionReference(empresaId).withConverter(desafioConverter);
  const desafiosQuery = query(
    desafiosCollectionReference,
    where("deletedAt", "==", null),
    orderBy("rankingByMoedas.ranking"),
    limit(TOP_DESAFIOS_MOST_VOTED_LIMIT)
  ).withConverter(desafioConverter);

  return getDocs(desafiosQuery);
}

/**
 * Get desafio document reference from empresaId
 * @param empresaId - Empresa id to get the desafio document reference
 * @param desafio - Desafio id to get the desafio document reference
 * @returns - Desafio document reference
 */
export function getDesafioDocumentReference(empresaId: TEmpresa["id"], desafio: TDesafio["id"]) {
  const collectionReference = getDesafiosCollectionReference(empresaId);
  return doc(collectionReference, desafio);
}

/**
 * Delete a desafio to the database
 * @param empresaId - Empresa id to delete the desafio
 * @param desafio - Desafio to delete
 * @returns - Promise
 */
export function deleteDesafio(empresaId: TEmpresa["id"], desafio: TDesafio) {
  const collectionReference = getDesafioDocumentReference(empresaId, desafio.id);
  return updateDoc(collectionReference, {
    deletedAt: serverTimestamp()
  });
}

/**
 * Get started desafios query
 * @param empresaId - Empresa id
 * @returns - Desafio document reference
 */
function queryDesafios(empresaId: TEmpresa["id"]) {
  const projetosReference = getDesafiosCollectionReference(empresaId).withConverter(desafioConverter);
  return query(projetosReference, where("deletedAt", "==", null));
}

/**
 * Get desafios for a empresa
 * @param empresaId - Desafio id to get comitês
 * @returns - Desafio document snapshot
 */
export function getDesafiosAll(empresaId: TEmpresa["id"]) {
  const q = queryDesafios(empresaId);
  return getDocs(q);
}

/**
 * Get started desafios query
 * @param empresaId - Empresa id
 * @returns - Desafio document reference
 */
function queryDesafiosPublished(empresaId: TEmpresa["id"]) {
  const projetosReference = getDesafiosCollectionReference(empresaId).withConverter(desafioConverter);
  return query(projetosReference, where("deletedAt", "==", null), where("published", "==", true));
}

/**
 * Get desafios for a empresa
 * @param empresaId - Desafio id to get comitês
 * @returns - Desafio document snapshot
 */
export function getDesafiosPublishedAll(empresaId: TEmpresa["id"]) {
  const q = queryDesafiosPublished(empresaId);
  return getDocs(q);
}

/**
 * Publish a ideia
 * @param empresaId - Empresa id
 * @param ideiaId - Ideia id to update
 * @returns - Promise with the ideia reference
 */
export function publishDesafio(empresaId: TEmpresa["id"], ideiaId: TIdeia["id"]) {
  const ideiasCollectionReference = getDesafioDocumentReference(empresaId, ideiaId).withConverter(desafioConverter);
  const currentUserId = getCurrentUser()?.uid;
  return updateDoc(ideiasCollectionReference, {
    status: "NOVO_DESAFIO",
    published: true,
    publishedAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
    updatedBy: currentUserId
  });
}
