import { Flex, Loader, Stack } from "@mantine/core";
import * as React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { AbilityContext, ability } from "./components/can/Can";
import useWatchAuthenticatedUser from "./hooks/useWatchAuthenticatedUser";
import authenticatedRoutes from "./routes/authenticatedRoutes";
import noAuthenticatedRoutes from "./routes/noAuthenticatedRoutes";
import noCompanyRoutes from "./routes/noCompanyRoutes";
import passwordChangeRoutes from "./routes/passwordChangeRoutes";

const authenticatedRouter = createBrowserRouter(authenticatedRoutes);
const noAuthenticatedRouter = createBrowserRouter(noAuthenticatedRoutes);
const noCompanyRouter = createBrowserRouter(noCompanyRoutes);
const passwordChangeRouter = createBrowserRouter(passwordChangeRoutes);

if (import.meta.hot) {
  import.meta.hot.dispose(() => noAuthenticatedRouter.dispose());
  import.meta.hot.dispose(() => authenticatedRouter.dispose());
  import.meta.hot.dispose(() => noCompanyRouter.dispose());
}

const PageLoading = () => (
  <Flex mih="100vh" gap="xs" justify="center" align="center" direction="column" wrap="nowrap">
    <Stack gap="lg">
      <Loader />
    </Stack>
  </Flex>
);

type Router = ReturnType<typeof createBrowserRouter>;
type CurrentUser = ReturnType<typeof useWatchAuthenticatedUser>[0];
type CurrentUserClaims = ReturnType<typeof useWatchAuthenticatedUser>[1];

/**
 * App Routes
 * @param user - Current User
 * @param claims - Current User Claims
 * @returns - App Routes
 */
function getRoutesFor(user: CurrentUser, claims: CurrentUserClaims): Router {
  const isAuthenticated = !!user;
  const hasDefaultEmpresa = isAuthenticated && !!claims?.empresaId;
  const authReady = isAuthenticated && hasDefaultEmpresa;
  const requirePasswordChange = authReady && claims?.requirePasswordChange;

  if (isAuthenticated && !hasDefaultEmpresa && !requirePasswordChange) {
    return noCompanyRouter;
  } else if (authReady) {
    return requirePasswordChange ? passwordChangeRouter : authenticatedRouter;
  }
  return noAuthenticatedRouter;
}

/**
 * App Routes
 * @returns - App Routes
 */
function Routes() {
  const [currentUser, claims, isLoading] = useWatchAuthenticatedUser();
  const routes = getRoutesFor(currentUser, claims);

  if (currentUser === false || isLoading || (currentUser && !claims)) {
    return <PageLoading />;
  }

  return (
    <AbilityContext.Provider value={ability}>
      <React.Suspense fallback={<PageLoading />}>
        <RouterProvider router={routes} />
      </React.Suspense>
    </AbilityContext.Provider>
  );
}

Routes.displayName = "Routes";

export default Routes;
