import { Anchor, Badge, Button, Card, Flex, Grid, Group, Space, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { NavLink } from "react-router-dom";
import { verificationDate } from "@/modules/projetos/components/projeto-form/ProjetoForm";
import ProjetosLabel from "@/modules/questionario-de-inovacao/components/projetos-label";
import { getCurrentUser } from "@/services/auth";
import useGetResposta from "../questionario-list/useGetResposta";

const MAGIC_NUMBER = 1000;

const getEmpresaIdFromQuestionario = (questionario: TQuestionario) => {
  const pathParts = questionario?.refPath.split("/") ?? [];
  const hasLicenciada = pathParts[0] === "licenciadas";
  return hasLicenciada ? pathParts[3] : pathParts[1];
};

/* eslint-disable sonarjs/cognitive-complexity */
const QuestionarioItem = ({ questionario }: { questionario: TQuestionario }) => {
  const user = getCurrentUser();
  const empresaId = getEmpresaIdFromQuestionario(questionario);

  const { data } = useGetResposta({ id: user?.uid, idEmpresa: empresaId });
  const userResposta = data?.find(
    (item) => item.idRespondente === user?.uid && item.idQuestionario === questionario.id
  );

  const pausado = !!userResposta && userResposta.pausado;
  const isSmMarches = useMediaQuery("(max-width: 48em)");

  return (
    <>
      <Space h="md" />
      <Card withBorder shadow="md" style={{ flex: 1, width: isSmMarches ? MAGIC_NUMBER : "100%" }}>
        <Grid align="center">
          <Grid.Col span={3}>
            <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
              <Text fw={300} fz="xs">
                Nome do projeto
              </Text>
              <ProjetosLabel id={questionario?.idProjeto} idEmpresa={empresaId} />
            </Flex>
          </Grid.Col>
          <Grid.Col span={3}>
            <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
              <Text fw={300} fz="xs">
                Nome do questionário
              </Text>
              <Text fw={500} fz="md">
                {questionario.nome}
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={3}>
            <Group align={"center"} justify="center">
              {data &&
              data?.filter((item) => item.idRespondente === user?.uid && item.idQuestionario === questionario.id)
                ?.length > 0 &&
              !pausado ? (
                <Badge
                  radius="md"
                  size="xl"
                  variant="outline"
                  style={{ background: "rgba(244, 245, 246, 1)" }}
                  color="rgba(244, 245, 246, 1)"
                >
                  <Text tt="capitalize" fz="xs" fw={500} color={"black"}>
                    Respondido
                  </Text>
                </Badge>
              ) : (
                <Badge
                  radius="md"
                  size="xl"
                  variant="outline"
                  style={{ background: "rgba(105, 198, 105, 0.1)" }}
                  color={
                    pausado
                      ? "rgba(58, 155, 217, 1)"
                      : verificacaoExpirada(questionario)
                        ? "rgba(255, 21, 1, 1)"
                        : "rgba(105, 198, 105, 1)"
                  }
                >
                  <Text tt="capitalize" fz="xs" fw={500}>
                    {pausado
                      ? "Questionario pausado"
                      : verificacaoExpirada(questionario)
                        ? "Fora do período"
                        : "Aguardando resposta"}
                  </Text>
                </Badge>
              )}
            </Group>
          </Grid.Col>
          <Grid.Col span={3}>
            <Group align={"center"} justify="center">
              {data &&
              data?.filter((item) => item.idRespondente === user?.uid && item.idQuestionario === questionario.id)
                ?.length > 0 &&
              !pausado ? (
                <Anchor component={NavLink} to={`/responderQuestionario/${empresaId}/${questionario.id}`}>
                  {" "}
                  <Button type="button" variant="transparent" size="compact-sm">
                    Ver respostas
                  </Button>
                </Anchor>
              ) : verificacaoExpirada(questionario) ? (
                <></>
              ) : (
                <Anchor component={NavLink} to={`/responderQuestionario/${empresaId}/${questionario.id}`}>
                  <Button color="rgba(8, 68, 244, 1)">Responder questionário</Button>
                </Anchor>
              )}
            </Group>
          </Grid.Col>
        </Grid>
      </Card>
    </>
  );
};

export default QuestionarioItem;

const verificacaoExpirada = (questionario: TQuestionario | null | undefined) => {
  const now = new Date();
  const anoAtuacao =
    typeof questionario?.dataAtuacao === "number"
      ? verificationDate(new Date())
      : verificationDate(questionario?.dataAtuacao);
  const anoVigencia =
    typeof questionario?.dataVigencia === "number"
      ? verificationDate(new Date())
      : verificationDate(questionario?.dataVigencia);

  if (anoVigencia && anoAtuacao) {
    return new Date(anoAtuacao) > now || new Date(anoVigencia) < now;
  }
  return false;
};
