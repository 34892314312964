import { Card, Group, Text, Flex, Space, Stack, Button } from "@mantine/core";
import { QueryObserverResult } from "@tanstack/react-query";
import React from "react";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import useUserStore from "@/modules/users/store";
import HomeTaticasListCard from "../home-taticas-list-card";

interface Tatica {
  id: string | number;
  nome: string;
  descricao: string;
  nomeCategoria: string;
  nomeTipo: string;
  vinculos?: [];
  movimentacao?: [];
  idTatica: string;
}
// prettier-ignore
const JogadaDeInovacaoCard: React.FC<{
  jogada: TJogadaDeInovacao;
  refetch: () => Promise<QueryObserverResult>;
  handleCancel: () => void;
  handleConcluir: () => void;
  formatAutorizacoes: TAutorizacao[] | undefined;
}> = ({ jogada, handleCancel, handleConcluir, refetch, formatAutorizacoes }) => {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresa);
  const { data: userColaborador } = useGetColaboradorById(activeEmpresaId?.id || "", jogada.updatedBy || "");

  return (
    <Card padding={"0px"} radius={0}>
      <Group justify="space-between">
        <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
          <Text fw={300} fz="xs">
            Identificador
          </Text>
          <Text fw={300} fz="sm">
            {userColaborador?.nome}
          </Text>
        </Flex>
      </Group>
      <Space h="xl" />
      <Group justify="space-between">
        <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
          <Text fw={300} fz="xs">
            Descrição da jogada
          </Text>
          <Text fw={400} fz="sm">
            {jogada.descricao}
          </Text>
        </Flex>
      </Group>
      {jogada && jogada.taticas && Array.isArray(jogada.taticas) && jogada?.taticas?.length > 0 ? (
        <Card.Section inheritPadding py="xs">
          <Stack>
            <Card style={{ background: "rgb(245,246,247)" }}>
              {jogada.taticas.map((item: Tatica, index: number) => (
                <HomeTaticasListCard key={index} jogada={item} item={jogada} refetch={refetch} formatAutorizacoes={formatAutorizacoes} />
              ))}
            </Card>
          </Stack>
        </Card.Section>
      ) : (
        <span>Nenhuma tática cadastrada</span>
      )}
      <Space h="xl" />
      {jogada.cancelado || jogada.concluido ? <></> : <Group justify={"flex-end"}>
        {verificarMovimentacoes(jogada?.taticas as Tatica[]) ? <Button variant="filled" color="rgba(8, 68, 244, 1)" onClick={handleConcluir} >Concluir jogada</Button> : <></>}
        <Button variant="outline" color="red" radius="lg" onClick={handleCancel}>Cancelar jogada</Button>
      </Group>}
    </Card>
  );
};

export default JogadaDeInovacaoCard;
// prettier-ignore
/**
 * Verifica se há movimentações em um array de táticas.
 * @param array - O array de táticas a ser verificado.
 * @returns boolean Verdadeiro se houver movimentações, falso caso contrário.
 */
function verificarMovimentacoes(array: Tatica[]) {
  const temMovimentacao = array.some((item) => {
    const movimentacao = item?.movimentacao || []
    return movimentacao.length > 0
  });

  if (!temMovimentacao) {
    return false;
  }

  return array
    .filter(item => {
      const movimentacao = item?.movimentacao || []
      return movimentacao.length === 0
    })
    .every(item => {
      if (!item.vinculos || item.vinculos.length === 0) {
        return false;
      }
      return item.vinculos.some(vinculoIndex => {
        const movimentacao = array.find(row => row.idTatica === vinculoIndex)?.movimentacao || [];
        return movimentacao.length > 0
      });
    });
}
