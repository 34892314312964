import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { REGULAMENTOS_DESAFIOS_COLLECTION_KEY, getRegulamentoDocumentFromReferencePath } from "../firestore";

type TGetResponse = Awaited<ReturnType<typeof getRegulamentoDocumentFromReferencePath>>;

/**
 * Get Regulamento from the desafio
 * @param desafio - Desafio to get the regulamento from
 * @returns - Regulamento from the desafio
 */
function useGetRegulamentoFromDesafios(desafio: TDesafio) {
  return useQuery<TGetResponse, FirestoreError, TRegulamentoDesafios | null>({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [
      ...REGULAMENTOS_DESAFIOS_COLLECTION_KEY,
      desafio.id,
      "regulamentoReference",
      desafio.regulamentoReference
    ],
    queryFn() {
      if (typeof desafio.regulamentoReference === "string") {
        return getRegulamentoDocumentFromReferencePath(desafio.regulamentoReference);
      }
      return getRegulamentoDocumentFromReferencePath(desafio.regulamentoReference.refPath);
    },
    select(dataResponse) {
      if (!dataResponse.exists()) {
        return null;
      }

      return dataResponse.data();
    }
  });
}

export default useGetRegulamentoFromDesafios;
