import { Tabs, Text, Card } from "@mantine/core";
import { ComponentProps, PropsWithChildren } from "react";

type TabMenuProperties = ComponentProps<typeof Tabs.Panel> & {
  title: string;
};
const TabPanelMenu = ({ title, children, ...tabPanelProperties }: PropsWithChildren<TabMenuProperties>) => {
  return (
    <Tabs.Panel {...tabPanelProperties}>
      <Card bg="dark.4" p="xs" radius="md">
        <Text c="white.1" fw={700}>
          {title}
        </Text>
      </Card>
      {children}
    </Tabs.Panel>
  );
};

export default TabPanelMenu;
