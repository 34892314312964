import { Table } from "@mantine/core";
interface Media {
  media: number;
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
// prettier-ignore
const TableMediaItem = ({ item }: { item: TEmpresa & Media }) => {
  return (
    <Table.Tr key={item?.nomeFantasia}>
      <Table.Td>{item?.nomeFantasia}</Table.Td>
      <Table.Td>{item?.cnpj}</Table.Td>
      <Table.Td>{item?.responsavel.nome}</Table.Td>
      <Table.Td>{item?.responsavel?.telefone}</Table.Td>
      <Table.Td>{item?.porte}</Table.Td>
      <Table.Td>{item?.endereco?.estado}</Table.Td>
      <Table.Td>{item?.categorias}</Table.Td>
      <Table.Td>{item?.media}</Table.Td>
    </Table.Tr>
  )
};

export default TableMediaItem;
