import { Text, FocusTrap } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import GruposDiagnosticoForm from "../../components/grupos-diagnostico-form";

import { TGruposDiagnosticoForm } from "../../firestore";

const GruposDiagnosticoFormModal = ({
  context,
  innerProps,
  id
}: ContextModalProps<{ item: TGruposDiagnosticoForm }>) => {
  const { item: grupo } = innerProps;
  /**
   * Handle add ignore area success
   */
  function onSuccess() {
    context.closeModal(id);
  }

  return (
    <FocusTrap active={true}>
      <Text fw="bold" mb="md">
        Cadastro de grupos
      </Text>
      <GruposDiagnosticoForm grupo={grupo} onSuccess={onSuccess} />
    </FocusTrap>
  );
};

export default GruposDiagnosticoFormModal;
