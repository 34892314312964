import { Card, Group, Text, Flex, Space } from "@mantine/core";

interface Tipo {
  id: string | number;
  nome: string;
  descricao: string;
}

const TaticaDeInovacaoCard = ({ tatica }: { tatica: Tipo }) => {
  return (
    <Card>
      <Group justify="space-between">
        <Flex
          mih={50}
          // bg="rgba(0, 0, 0, .3)"
          gap="xs"
          justify="center"
          align="flex-start"
          direction="column"
          wrap="wrap"
        >
          <Text fw={600} fz="md">
            {tatica.nome}
          </Text>
          <Space h="lg" />
        </Flex>
      </Group>
      <Space h="lg" />
      {/* <SimpleGrid cols={2}>
        <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
          <Text fw={500} fz="md">
            Segmento que se aplica
          </Text>
          <Text fw={300} fz="sm">
            {tatica.segmento}
          </Text>
        </Flex>
        <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
          <Text fw={500} fz="md">
            Ambição
          </Text>
          <Text fw={300} fz="sm">
            {tatica.ambicao}
          </Text>
        </Flex>
      </SimpleGrid> */}
      <Space h="lg" />
      <Group justify="space-between">
        <Flex
          mih={50}
          // bg="rgba(0, 0, 0, .3)"
          gap="xs"
          justify="center"
          align="flex-start"
          direction="column"
          wrap="wrap"
        >
          <Text fw={500} fz="md">
            Descrição
          </Text>
          <Text fw={300} fz="sm">
            {tatica.descricao}
          </Text>
        </Flex>
      </Group>
    </Card>
  );
};

export default TaticaDeInovacaoCard;
