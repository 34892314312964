import { Button, Checkbox, Group, Modal, Select, Space, TextInput } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconFilter, IconFilterCancel } from "@tabler/icons-react";
import { ChangeEvent, useCallback, useState } from "react";

interface Filtro {
  search: string;
  filtro: string;
  habilitada: boolean;
}

// prettier-ignore
const ModalFilter = ({ setFilter }: { setFilter: React.Dispatch<React.SetStateAction<Filtro>> }) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [values, setValues] = useState<Filtro>(mockFilter)


  const clearFiltro = useCallback(() => {
    setFilter(mockFilter)
  }, [setFilter]);

  const applyFilter = useCallback(() => {
    setFilter(values);
    setValues(mockFilter);
    close();
  }, [setFilter, setValues, close, values]);

  return (
    <>
      <Button variant="transparent" p="0px">
        <IconFilter onClick={open} />
      </Button>
      <Button variant="transparent" onClick={clearFiltro} p="0px">
        <IconFilterCancel />
      </Button>

      <Modal opened={opened} onClose={close} title="Filtro:">
        <Select
          label="Campos a serem filtrados"
          placeholder="..."
          data={arrayItens}
          onChange={changeSelect(setValues)}
        />
        <Space h={"xl"} />
        <TextInput
          label="Insira o valor a ser filtrado"
          placeholder="..."
          onChange={changeInput(setValues)}

        />
        <Space h={"xl"} />
        <Checkbox
          defaultChecked={values.habilitada}
          label="Habilitada"
          onChange={changeCheck(setValues)}
        />
        <Space h={"xl"} />
        <Group justify={"flex-end"}>
          <Button variant="transparent" onClick={close}>
            Cancelar
          </Button>
          <Button variant="filled" onClick={applyFilter}>
            Filtrar
          </Button>
        </Group>
      </Modal>
    </>
  )
};

export default ModalFilter;

// prettier-ignore
const changeInput = (setValues: React.Dispatch<React.SetStateAction<Filtro>>) => {
  return (event: ChangeEvent<HTMLInputElement>) => {
    setValues(values => ({ ...values, search: event.target.value }))
  }
}
// prettier-ignore
const changeSelect = (setValues: React.Dispatch<React.SetStateAction<Filtro>>) => {
  return (event: string) => {
    setValues(values => ({ ...values, filtro: event }))
  }
}

// prettier-ignore
const changeCheck = (setValues: React.Dispatch<React.SetStateAction<Filtro>>) => {
  return (event: ChangeEvent<HTMLInputElement>) => {
    setValues(values => ({ ...values, habilitada: event.target.checked }))
  }
}
// prettier-ignore
const arrayItens = [
  "Empresa",
  "Cnpj",
  "Responsavel",
  "Telefone",
  "Porte",
  "Estado",
  "Media",
  "Categoria",
]

// prettier-ignore
const mockFilter = {
  search: "",
  filtro: "",
  habilitada: false
};
