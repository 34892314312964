/* eslint-disable react/jsx-no-undef */
import { Avatar, Card, Flex, Group, Image, Text, ThemeIcon } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import { IconCoin } from "@tabler/icons-react";
import dayjs from "dayjs";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import useGetIdeiaActivity from "@/modules/ideias-activities-per-colaborador/hooks/useGetIdeiaActivities";
import useUserStore from "@/modules/users/store";

/**
 *  Colaborador activity card
 * @param root0 readOnly
 * @param root0.activity Activity to display
 * @returns JSX.Element
 */
function ColaboradorActivityCard({ activity }: { activity: TIdeiaActivityPerColaborador }) {
  const { activeEmpresaId } = useUserStore();
  const { data } = useGetColaboradorById(activeEmpresaId ?? "", activity.id);

  if (!data) {
    return <></>;
  }

  const profileImage = data.image && data.image[0]?.path;

  const date = dayjs(activity.createdAt).format("DD/MM/YYYY HH:mm");

  return (
    <Card withBorder>
      <Flex direction={"column"} gap={"sm"}>
        <Group display={"flex"} align="center">
          {profileImage ? (
            <Image h={48} w={48} radius="xl" src={profileImage} />
          ) : (
            <Avatar src={""} radius="xl" color="white" size="md" alt={data.nome} />
          )}

          <Flex direction={"column"} flex="1 0 0">
            <Group flex="1 0 0" justify="space-between">
              <Text size="lg">{data.nome}</Text>
              <Text size="sm" c="gray">
                {date}
              </Text>
            </Group>
            <Group display={"flex"} align="center" gap={"xs"}>
              <Text size="xs">Patrocinou:</Text>
              <Group display={"flex"} align="center" gap={4}>
                <ThemeIcon variant="light" color="yellow" size="xs">
                  <IconCoin />
                </ThemeIcon>
                <Text c="#000" size="xs" tt="lowercase">
                  {`${activity?.moedas} moedas`}
                </Text>
              </Group>
            </Group>
          </Flex>
        </Group>
      </Flex>
    </Card>
  );
}

/**
 * Detail ideia patrocinios modal
 * @param root0 readOnly
 * @param root0.innerProps Additional properties passed to the modal
 * @returns JSX.Element
 */
export function DetailIdeiaPatrociniosModal({ innerProps }: ContextModalProps<{ ideia: TIdeia }>) {
  const { data: activities } = useGetIdeiaActivity(innerProps.ideia);

  return (
    <Flex direction="column" gap="sm">
      {activities?.map((activity) => <ColaboradorActivityCard key={activity.id} activity={activity} />)}
    </Flex>
  );
}
