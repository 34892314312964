import {
  Alert,
  Box,
  Button,
  Group,
  LoadingOverlay,
  NumberInput,
  Select,
  Stack,
  Switch,
  TextInput
} from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { useEffect } from "react";
import { extractBaseInputPropertiesFromFormSchema, extractTextInputPropertiesFromFormSchema } from "@/utils/form";
import { PerguntaFormProvider } from "./pergunta-form-context";
import usePerguntaForm from "./usePerguntaForm";
import useQueryGrupo from "./useQueryGrupo";
import useQueryTiposDeRespostas from "./useQueryTipoDePergunta";
import { TPerguntaForm } from "../../firestore";
import perguntaSchema from "../../schema/pergunta-schema";

const PerguntaForm = ({ pergunta, onSuccess }: { pergunta: TPerguntaForm; onSuccess: () => void }) => {
  const { form, isPending, onSubmit } = usePerguntaForm(pergunta, {
    onSuccess
  });
  const { data: grupos, isFetching } = useQueryGrupo();
  const { data: tiposDeRespostas, isLoadingError, isError, refetch } = useQueryTiposDeRespostas();
  const fieldsDescriptor = perguntaSchema.describe();

  useEffect(() => {
    const fetchData = async () => {
      if (isError) {
        await refetch();
      }
    };

    void fetchData();
  }, [isError]);

  useEffect(() => {
    form.setValues((values) => ({
      ...values,
      ordem: typeof values.ordem === "string" ? Number.parseInt(values?.ordem) : values.ordem
    }));
  }, []);

  if (isFetching || isLoadingError) {
    return (
      <Box pos="relative">
        <LoadingOverlay visible={isFetching || isLoadingError} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
      </Box>
    );
  }

  const genericError = form.errors[""];
  return (
    <PerguntaFormProvider form={form}>
      <form onSubmit={onSubmit}>
        <Box pos="relative">
          <LoadingOverlay visible={isPending} />
          <Stack gap="sm" mb="lg">
            <Select
              placeholder="Grupo"
              data={grupos?.map((item) => ({ label: item.nome, value: item.id }))}
              {...form.getInputProps("grupo")}
              {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "grupo")}
              searchable
            />
            <TextInput
              type="text"
              style={{ flex: 1 }}
              {...form.getInputProps("nome")}
              {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "nome")}
            />
            <TextInput
              type="text"
              style={{ flex: 1 }}
              {...form.getInputProps("descricao")}
              {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "descricao")}
            />
            <Select
              placeholder="Tipo de resposta"
              data={tiposDeRespostas?.map((item) => ({
                label: item.nome,
                value: item && item.id ? item.id.toString() : ""
              }))}
              {...form.getInputProps("tipoDeResposta")}
              {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "tipoDeResposta")}
            />
            <Group justify="space-between">
              <NumberInput
                style={{ flex: 1 }}
                {...form.getInputProps("peso")}
                {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "peso")}
              />
              <NumberInput
                style={{ flex: 1 }}
                {...form.getInputProps("ordem")}
                {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "ordem")}
              />
            </Group>
            {/* <Switches pergunta={pergunta} onSuccess={onSuccess} /> */}
            <Group justify="space-between">
              <Switch
                {...form.getInputProps("obrigatoria")}
                {...extractBaseInputPropertiesFromFormSchema(fieldsDescriptor, "obrigatoria")}
                defaultChecked={form.values.obrigatoria}
              />
              <Switch
                {...form.getInputProps("inversa")}
                {...extractBaseInputPropertiesFromFormSchema(fieldsDescriptor, "inversa")}
                defaultChecked={form.values.inversa}
              />
            </Group>
          </Stack>
        </Box>
        <Group justify={genericError ? "space-between" : "flex-end"} mt="md">
          {genericError && (
            <Alert icon={<IconAlertCircle size="1rem" />} color="red">
              {genericError}
            </Alert>
          )}
          <Button type="submit" loading={isPending}>
            Salvar
          </Button>
        </Group>
      </form>
    </PerguntaFormProvider>
  );
};

export default PerguntaForm;
