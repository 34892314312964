import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { QUERY_KEY } from "@/modules/projetos/components/projetos-table/ProjetosTable";
import { getProjetosItem } from "@/modules/projetos/firestore";

type TGetTipoDeInovacaoResponse = Awaited<ReturnType<typeof getProjetosItem>>;

/**
 * Get ideia from url params
 * @param projetoId - projeto id
 * @param activeEmpresaId - empresa id
 * @returns - Ideia or null if not found
 */
function useGetProjetoItem(projetoId: string, activeEmpresaId: string | undefined) {
  return useQuery<TGetTipoDeInovacaoResponse, FirestoreError, TProjeto | null>({
    queryKey: [QUERY_KEY, projetoId, activeEmpresaId],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!projetoId) {
        throw new Error("Ideia não selecionada");
      }

      return getProjetosItem(activeEmpresaId, projetoId);
    },
    select(dataResponse) {
      if (!dataResponse.exists()) {
        return null;
      }

      return dataResponse.data();
    },
    enabled: !!projetoId && !!activeEmpresaId
  });
}

export default useGetProjetoItem;
