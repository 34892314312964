import { Space, Textarea, TextInput } from "@mantine/core";

interface MyState {
  titulo: string;
  descricao: string;
}

// prettier-ignore
const SolucoesCronogramaForm = ({ values, setValues }: { values: MyState, setValues: React.Dispatch<React.SetStateAction<MyState>> }) => {
  return (
    <>
      <TextInput
        label="Título da solução proposta"
        value={values.titulo}
        onChange={onChange("titulo", setValues)}
      />
      <Space h="md" />
      <Textarea
        label="Descreva a solução proposta"
        value={values.descricao}
        onChange={onChangeDescricao("descricao", setValues)}
      />
    </>
  );
};

export default SolucoesCronogramaForm;
// prettier-ignore
const onChange = (field: string, setValues: React.Dispatch<React.SetStateAction<MyState>>) => {
  return (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues(values => ({ ...values, [field]: event.target.value }))
  };
}
// prettier-ignore
const onChangeDescricao = (field: string, setValues: React.Dispatch<React.SetStateAction<MyState>>) => {
  return (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValues(values => ({ ...values, [field]: event.target.value }))
  };
}
