import { serverTimestamp } from "firebase/firestore";
import { ObjectSchema } from "yup";
import schema from "@/schema";
import { getCurrentUser } from "@/services/auth";
import { TCronogramaForm, TCronogramaDatabaseFields, TCronogramaFormFields } from "./firestore";

export const DESAFIO_STATUS: TDesafioStatus[] = [
  "DRAFT",
  "NOVO_DESAFIO",
  "PROPOSTO_PELO_COLABORADOR",
  "PRE_ANALISE",
  "ANALISE_DE_VIABILIDADE",
  "AGUARDANDO_ANALISE_DO_COMITE",
  "APROVADO_PELO_COMITE",
  "AGENDADO",
  "RECUSADO_PELO_COMITE",
  "BACKLOG_PARA_AVALIACAO_FUTURA",
  "EM_REALIZACAO",
  "REALIZADO",
  "SOLUCOES_VALIDADAS",
  "CANCELADA"
];

/**
 * Define only the meta fields that are not part of the form
 */
// prettier-ignore
const cronogramaSchemaDatabaseFields: ObjectSchema<TCronogramaDatabaseFields> = schema.object().shape({
  createdAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .required()
    .defined()
    .nonNullable()
    .default<ReturnType<typeof serverTimestamp>>(() => serverTimestamp()),
  createdBy: schema
    .string()
    .required()
    .defined()
    .nonNullable()
    .default(() => {
      const currentUserId = getCurrentUser()?.uid;
      if (!currentUserId) {
        throw new Error("The current user is not defined");
      }
      return currentUserId;
    }),
  updatedAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .default<ReturnType<typeof serverTimestamp>>(() => serverTimestamp()),
  updatedBy: schema.string().default(() => {
    const currentUserId = getCurrentUser()?.uid;
    if (!currentUserId) {
      throw new Error("The current user is not defined");
    }
    return currentUserId;
  }),
  deletedAt: schema.mixed<ReturnType<typeof serverTimestamp>>().defined().nullable().default(null)
});

/**
 * Define the schema for the form
 */
// prettier-ignore
const cronogramaSchemaFormFields: ObjectSchema<TCronogramaFormFields> = schema.object({
  id: schema.string().optional(),
  refPath: schema.string().optional(),
  idDesafio: schema.string().optional(),
  inscricoes: schema.array().optional(),
  solucoes: schema.array().optional(),
  event: schema.array().optional(),
  // dataDeInicio: schema
  // .date()
  // .required()
  // .defined()
  // .label("Data de inicio do evento"),
  // dataDeTermino: schema
  // .date()
  // .required()
  // .defined()
  // .label("Data de término do evento"),
  quantidadeDeVagas: schema.number().required().defined().label("Quantidade de vagas"),
  // orientacaoHora: schema.string().trim().required().defined().label("Orientações sobre o horário"),
  // orientacaoLocal: schema.string().trim().required().defined().label("Orientações sobre o local"),
  inicioIncricao:  schema
  .date()
  .required()
  .defined()
  .label("Data e hora de início das inscrições"),
  terminoIncricao: schema
  .date()
  .required()
  .defined()
  .label("Data e hora de término das inscrições"),

});
// prettier-ignore
const cronogramaSchema: ObjectSchema<TCronogramaForm> = cronogramaSchemaFormFields
  // eslint-disable-next-line unicorn/prefer-spread
  .concat(cronogramaSchemaDatabaseFields)
  .noUnknown()
  .strict()
  .label("Cronogrma");

export default cronogramaSchema;
