import { Text, FocusTrap } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import DesafioForm from "./DesafioForm";
import { TDesafioForm } from "../../firestore";

const DesafioFormModal = ({ innerProps, context, id }: ContextModalProps<{ item: TDesafio | TDesafioForm }>) => {
  const { item: desafio } = innerProps;

  /**
   * Handle on success form
   */
  function handleOnSuccess() {
    context.closeModal(id);
  }

  return (
    <FocusTrap active={true}>
      <Text fw="bold" mb="md">
        Cadastro de desafio
      </Text>
      <DesafioForm desafio={desafio} onSuccess={handleOnSuccess} />
    </FocusTrap>
  );
};

export default DesafioFormModal;
