import { Button, Card, Group, Skeleton, Stack, TextInput, Textarea, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { PropsWithChildren } from "react";
import { SchemaDescription } from "yup";
import useGetDocumentFromReferencePath from "@/hooks/useGetDocumentFromReferencePath";
import ColaboradorSelector from "@/modules/colaboradores/components/colaborador-selector";
import { QUERY_KEY, desafioCategoriaConverter } from "@/modules/desafio-categorias/firestore";
import desafioFormSchema from "@/modules/desafios/schema";

const DesafioCard = ({ children, desafio }: PropsWithChildren<{ desafio: TDesafio }>) => {
  const [opened, { toggle }] = useDisclosure(false);
  const fieldsDescriptor = desafioFormSchema.describe();

  const { data: categoria, isLoading } = useGetDocumentFromReferencePath({
    referencePath: typeof desafio.categoriaReference === "string" ? desafio.categoriaReference : null,
    converter: desafioCategoriaConverter,
    queryKey: [QUERY_KEY],
    subjectType: "TDesafioCategoria"
  });

  return (
    <Card withBorder>
      <Stack gap="xs" mb="md">
        <Group justify="space-between" mb={5}>
          <Group>
            <Title order={4}>Informações do cadastro de desafios</Title>
            {children}
          </Group>
          <Button
            type="button"
            variant="transparent"
            size="compact-sm"
            rightSection={opened ? <IconChevronUp size="1rem" /> : <IconChevronDown size="1rem" />}
            onClick={toggle}
          >
            Ver mais
          </Button>
        </Group>
      </Stack>

      <ColaboradorSelector
        searchable
        style={{ flex: 1 }}
        clearable={false}
        allowDeselect={false}
        readOnly={true}
        variant="unstyled"
        nothingFoundMessage="Nenhum colaborador encontrado..."
        value={
          typeof desafio.propositorReference === "string"
            ? desafio.propositorReference
            : desafio?.propositorReference?.refPath
        }
        label={(fieldsDescriptor.fields.propositorReference as SchemaDescription).label}
      />

      <TextInput
        type="text"
        style={{ flex: 1 }}
        readOnly={true}
        variant="unstyled"
        value={desafio.titulo}
        label={(fieldsDescriptor.fields.titulo as SchemaDescription).label}
      />
      <Textarea
        style={{ flex: 1 }}
        readOnly={true}
        variant="unstyled"
        value={desafio.descricao}
        label={(fieldsDescriptor.fields.descricao as SchemaDescription).label}
      />
      <Textarea
        style={{ flex: 1 }}
        readOnly={true}
        variant="unstyled"
        value={desafio.objetivo}
        label={(fieldsDescriptor.fields.objetivo as SchemaDescription).label}
      />

      <Skeleton visible={isLoading} height={40}>
        <TextInput
          type="text"
          style={{ flex: 1 }}
          readOnly={true}
          variant="unstyled"
          value={categoria?.nome}
          label={(fieldsDescriptor.fields.categoriaReference as SchemaDescription).label}
        />
      </Skeleton>
    </Card>
  );
};

export default DesafioCard;
