import { Button, Card, Group, Text } from "@mantine/core";
import { useDisclosure, useForceUpdate } from "@mantine/hooks";
import { IconFilter, IconPlus } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef } from "react";
import AddItemButton from "@/components/add-item-button";
import RefreshQueryButton from "@/components/refresh-query-button";
import { THandler } from "@/components/table/Table";
import FilterList from "@/components/table/TableFiltersList";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import EmpresasTable from "@/modules/empresas/components/empresas-table";
import { QUERY_KEY } from "@/modules/empresas/components/empresas-table/EmpresasTable";
import InviteEmpresaButton from "@/modules/empresas/components/invite-empresa-button";
import empresaSchema from "@/modules/empresas/schema/empresa-schema";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import TableFilters from "./TableFilters";

const REFRESH_ALL_EMPRESAS_KEY = [QUERY_KEY];
// prettier-ignore
const EmpresaPage = ({ empresaRole }: { empresaRole: string }) => {
  const tableHandlersReference = useRef<THandler<TEmpresa>>(null);
  const [opened, { close, toggle }] = useDisclosure(false);
  const queryClient = useQueryClient();

  const forceUpdate = useForceUpdate();
  /**
   * Handle filter applied
   */
  function handleFilterApplied() {
    close();
    void queryClient.invalidateQueries({ queryKey: REFRESH_ALL_EMPRESAS_KEY }).then(() => forceUpdate());
  }
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);
  const activeAutorizacao = useUserStore((store) => store.autorizacoes);
  const uid = getCurrentUser()?.uid;
  const { data: userColaborador } = useGetColaboradorById(activeEmpresaId || "", uid || "");

  const autorizacao = dataAutorizacoes.find(item => item.label === userColaborador?.role);
  const formatAutorizacoes = activeAutorizacao?.filter(item => item.tipo === autorizacao?.value);

  useEffect(() => {
    handleFilterApplied();
    forceUpdate();
  }, [empresaRole]);

  return (
    <Card
      withBorder
      shadow="md"
      styles={{
        section: {
          overflow: "auto"
        }
      }}
    >
      <Card.Section inheritPadding py="md" withBorder>
        <Group justify="space-between">
          <Text fw={500} fz="lg">
            {empresaRole === "NORMAL" ? "Listagem de Empresas" : "Empresas Convidadas"}
          </Text>
          <Group>
            {empresaRole === "NORMAL" && formatAutorizacoes?.find(item => item.tipoAutorizacao === "1")?.criacao ?
              <>
                <AddItemButton
                  size="sm"
                  leftSection={<IconPlus size="1rem" />}
                  modalProperties={{ modal: "empresa-form-modal" }}
                  schema={empresaSchema}
                >
                  Adicionar Empresa
                </AddItemButton>
              </>
              : <InviteEmpresaButton variant="outline" size="sm" color="dark">
                Convidar
              </InviteEmpresaButton>}
          </Group>
        </Group>
      </Card.Section>
      <Card.Section inheritPadding py="md" withBorder>
        <Group justify="space-between">
          <Group align="center">
            <Button variant="subtle" size="compact-md" onClick={toggle} leftSection={<IconFilter size="1rem" />}>
              Filtros
            </Button>
            {tableHandlersReference.current && <FilterList filters={tableHandlersReference.current.filters} />}
          </Group>
          <RefreshQueryButton queryKey={REFRESH_ALL_EMPRESAS_KEY} />
        </Group>

        {tableHandlersReference.current && (
          <TableFilters
            table={tableHandlersReference.current.table}
            filters={tableHandlersReference.current.filters}
            onClose={close}
            onFilterApplied={handleFilterApplied}
            opened={opened}
          />
        )}
      </Card.Section>
      <EmpresasTable empresaRole={empresaRole} ref={tableHandlersReference} />
    </Card>
  );
};

export default EmpresaPage;

export const dataAutorizacoes = [
  { value: "1", label: "ADMIN" },
  { value: "2", label: "MEMBER" },
  { value: "3", label: "TEAM" },
  { value: "4", label: "MASTER" },
  { value: "5", label: "COLLABORATOR" }
];
