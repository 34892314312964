import { Anchor, Box, LoadingOverlay } from "@mantine/core";
import { NavLink } from "react-router-dom";
import BreadcrumbsNavigation from "@/components/breadcrumbs-navigation";
import ConvidarEmpresaForm from "@/modules/convidar-empresa/components/convidar-empresa-form";
import useGetProjetoFromUrl from "@/modules/diagnostico/components/ranking-list/hooks/useGetProjetoFromUrl";

const ConvidarEmpresaPage = () => {
  const { data: projeto, isFetching } = useGetProjetoFromUrl();

  if (isFetching) {
    return (
      <Box pos="relative">
        <LoadingOverlay visible={isFetching} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
      </Box>
    );
  }

  return (
    <>
      <BreadcrumbsNavigation>
        <Anchor component={NavLink} to="/diagnostico/1">
          Home
        </Anchor>
      </BreadcrumbsNavigation>
      <ConvidarEmpresaForm projeto={projeto} />
    </>
  );
};

export default ConvidarEmpresaPage;
