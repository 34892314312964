import { Space } from "@mantine/core";
import React from "react";
import { verificationDate } from "@/modules/projetos/components/projeto-form/ProjetoForm";
import PerguntasComponente from "./PerguntasComponente";

const MAGICI_NUMBER = 2;

interface ArrayProperties {
  nome?: string;
  value: number;
  descricao: string;
  id: string;
  tipoDeResposta: string;
  resposta?: string | number | Campo | Campo[] | boolean;
  pergunta?: boolean;
  obrigatoria: boolean;
}

interface Campo {
  campo: string;
  valor: string;
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
const OptionsFormat = ({
  arrayFormat,
  setArrayFormat,
  respostaItem,
  stepPergunta,
  licenciado,
  empresaConvidada,
  questionario
}: {
  arrayFormat: ArrayProperties[];
  setArrayFormat: React.Dispatch<React.SetStateAction<ArrayProperties[]>>;
  stepPergunta: number;
  respostaItem: TResposta | null | undefined;
  licenciado: TLicenciado | null | undefined;
  empresaConvidada: string | null | undefined;
  questionario: TQuestionario | null | undefined;
}) => {
  const changeValues = (value: number | string, item: ArrayProperties) => {
    setArrayFormat((data) =>
      data.map((row: ArrayProperties) => (row?.id === item?.id ? { ...row, resposta: value } : { ...row }))
    );
  };
  return (
    <>
      <Space h="md" />
      {arrayFormat
        .filter((item: ArrayProperties) =>
          stepPergunta <= 0
            ? item?.value === 0
            : Number.parseFloat(item?.value.toFixed(MAGICI_NUMBER)) ===
              Number.parseFloat(stepPergunta?.toFixed(MAGICI_NUMBER))
        )
        .map((item: ArrayProperties) => (
          <PerguntasComponente
            key={item.id}
            item={item}
            changeValues={changeValues}
            disabled={
              (respostaItem && respostaItem.id && !respostaItem.pausado) || verificacaoExpirada(questionario)
                ? true
                : false
            }
            // setArrayFormat={setArrayFormat}
            licenciado={licenciado}
            empresaId={empresaConvidada ?? ""}
          />
        ))}
      <Space h="md" />
    </>
  );
};

export default OptionsFormat;

// prettier-ignore
const verificacaoExpirada = (questionario: TQuestionario | null | undefined) => {
  const now = new Date();
  const anoAtuacao = typeof questionario?.dataAtuacao === "number" ? verificationDate(new Date()) : verificationDate(questionario?.dataAtuacao);
  const anoVigencia = typeof questionario?.dataVigencia === "number" ? verificationDate(new Date()) : verificationDate(questionario?.dataVigencia);

  if (anoVigencia && anoAtuacao) {
    return new Date(anoAtuacao) > now || new Date(anoVigencia) < now;
  }
  return false
}
