import { Table } from "@mantine/core";

interface Habilitada {
  habilitada: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
// prettier-ignore
const TableItem = ({ data }: { data: (TEmpresa & Habilitada) }) => {


  return (
    <Table.Tr key={data?.nomeFantasia}>
      <Table.Td>{data?.nomeFantasia}</Table.Td>
      <Table.Td>{data?.codigoCNAE}</Table.Td>
      <Table.Td>{data?.cnpj}</Table.Td>
      <Table.Td>{data?.responsavel.nome}</Table.Td>
      <Table.Td>{data?.responsavel?.telefone}</Table.Td>
      <Table.Td>{data?.porte}</Table.Td>
      <Table.Td>{data?.endereco?.estado}</Table.Td>
      <Table.Td>{data?.categorias}</Table.Td>
    </Table.Tr>
  )
};

export default TableItem;
