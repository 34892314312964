import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { getSumMoedasPerDesafio } from "../firestore";

type TGetSumMoedasPerDesafioResponse = Awaited<ReturnType<typeof getSumMoedasPerDesafio>>;
type TQueryOptions = Omit<UseQueryOptions<TGetSumMoedasPerDesafioResponse, FirestoreError, number>, "queryKey">;

/**
 * Get desafio activities per colaborador
 * @param desafio - Desafio to get activities
 * @param options - Query options to use
 * @returns - Query result
 */
function useGetTotalMoedasPerDesafio(desafio: TDesafio | null, options: TQueryOptions = {}) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  return useQuery<TGetSumMoedasPerDesafioResponse, FirestoreError, number | null>({
    queryKey: [activeEmpresaId, "desafio", desafio?.id, "activities", "moedas", desafio, desafio?.id],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!desafio) {
        throw new Error("Desafio não informada");
      }

      return getSumMoedasPerDesafio(activeEmpresaId, desafio.id);
    },
    select(dataSnapshot) {
      return dataSnapshot.data().moedas;
    },
    enabled: !!desafio,
    ...options
  });
}

export default useGetTotalMoedasPerDesafio;
