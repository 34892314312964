import { Card, Group, ScrollArea, Space, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import useQueryConvite from "@/modules/analisar-respostas/analise-resposta-list/useQueryConvite";
import getConvitesLoad from "./useQueryQuestionarioConvite";
import QuestionarioItem from "../questionario-item";

const message = "Ocorreu um erro ao carregar os dados:";

interface Colaborador {
  colaboradorId: string;
  nome: string;
  idProjeto: string;
  refPath: string;
  id: string;
}

// prettier-ignore
const QuestionarioDiagnosticoList = () => {
  const { data } = useQueryConvite();
  const [questionariosConvite, setQuestionarioConvite] = useState<TQuestionario[]>([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadData();
      } catch (error) {
        console.error(message, error);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
    // eslint-disable-next-line unicorn/explicit-length-check
  }, [data && data.length])

  const loadData = async () => {
    if (data && data?.length > 0) {
      const newConvites = await getConvitesLoad({ data });
      setQuestionarioConvite(newConvites)
    }
  }

  return (
    <Card withBorder shadow="md" style={{ flex: 1 }}>
      <Group justify={"space-between"}>
        <Text size="xl">Como estão as participações?</Text>
      </Group>
      <Space h={"md"} />
      <ScrollArea w={"100%"}>
        {questionariosConvite?.map((questionario) => <QuestionarioItem key={questionario.id} questionario={questionario as TQuestionario & Colaborador} />)}
      </ScrollArea>
    </Card>
  );
};

export default QuestionarioDiagnosticoList;

// /**
//  * Handles the click event on the button.
//  * @param id - The id questions.
//  * @param idEmpresa - The id company.
//  * @param idProjeto - The id project.
//  * @returns - The return the click.
//  */
// function handleClick(id: string | undefined, idEmpresa: string | undefined, idProjeto: string | undefined) {
//   return (event: MouseEvent<HTMLButtonElement>) => {
//     event.preventDefault();
//     modals.openContextModal({
//       size: "xl",
//       innerProps: {
//         idQuestionario: id,
//         idEmpresa,
//         idProjeto
//       },
//       modal: "convidar-colaborador-modal"
//     });
//   };
// }
