import { ActionIconProps, Mark, Text, UnstyledButton } from "@mantine/core";
import { modals } from "@mantine/modals";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MouseEvent, PropsWithChildren, forwardRef } from "react";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { deleteRegiao } from "../../../../firestore";
import { QUERY_KEY } from "../RegioesTable";

type RemoveButtonProperties = PropsWithChildren<ActionIconProps & { regiao: TRegiao; projetoId: string }>;
type TDeleteResponse = Awaited<ReturnType<typeof deleteRegiao>>;

const RemoveRegiaoButton = forwardRef<HTMLButtonElement, RemoveButtonProperties>(
  ({ regiao, projetoId, children, ...properties }, reference) => {
    const queryClient = useQueryClient();
    const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

    const { mutate: deleteRegiaoMutation, isPending } = useMutation<TDeleteResponse, Error>({
      mutationFn: () => {
        if (!activeEmpresaId) {
          throw new Error("Empresa não selecionada");
        }
        return deleteRegiao(activeEmpresaId, projetoId, regiao);
      },
      onSuccess() {
        return queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      },
      onError(error) {
        captureException(error, true);
      }
    });

    /**
     * Handles the click event on the button.
     * @param event - The event that triggered the click.
     */
    function handleClick(event: MouseEvent<HTMLButtonElement>) {
      event.preventDefault();
      modals.openConfirmModal({
        title: "Remover região",
        centered: true,
        children: (
          <Text size="sm">
            Tem certeza que deseja remover a região <Mark>{regiao.name}</Mark>
          </Text>
        ),
        labels: {
          confirm: "Sim",
          cancel: "Não"
        },
        confirmProps: { color: "red" },
        onConfirm: () => {
          void deleteRegiaoMutation();
        }
      });
    }

    return (
      <UnstyledButton disabled={isPending} ref={reference} {...properties} onClick={handleClick}>
        {children}
      </UnstyledButton>
    );
  }
);

RemoveRegiaoButton.displayName = "RemoveRegiaoButton";

export default RemoveRegiaoButton;
