import { subject } from "@casl/ability";
import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { QUERY_KEY } from "@/modules/questionarios/components/questionario-table/QuestionarioTable";
import useUserStore from "@/modules/users/store";
import { getQuestionarios } from "../../firestore";

type TGetQuestionarioResponse = Awaited<ReturnType<typeof getQuestionarios>>;

/**
 * Get questionário de inovação query hook
 * @returns Questionário de inovação query hook
 */
function useQueryQuestionario() {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);
  const id = useUserStore((store) => store.activeProjeto);

  return useQuery<TGetQuestionarioResponse, FirestoreError, TQuestionario[]>({
    queryKey: ["empresas", { id: activeEmpresaId }, QUERY_KEY, id],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      return getQuestionarios(activeEmpresaId, id || "");
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }
      return dataResponse.docs.map((document_) => subject("TQuestionario", document_.data()));
    }
  });
}

export default useQueryQuestionario;
