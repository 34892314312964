import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { REGULAMENTOS_IDEIAS_COLLECTION_KEY, getRegulamento } from "@/modules/regulamento-ideias/firestore";
import useUserStore from "@/modules/users/store";

type TGetCampanha = Awaited<ReturnType<typeof getRegulamento>>;

/**
 * Hook para obter os cronogramas
 * @returns -  cronogramas
 */
function useGetRegulamentos() {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  return useQuery<TGetCampanha, FirestoreError, TRegulamentoIdeias[]>({
    queryKey: [activeEmpresaId, REGULAMENTOS_IDEIAS_COLLECTION_KEY],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return getRegulamento(activeEmpresaId);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((desafio) => desafio.data());
    }
  });
}
export default useGetRegulamentos;
