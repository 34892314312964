import { subject } from "@casl/ability";
import {
  FirestoreDataConverter,
  Timestamp,
  collection,
  getDocs,
  orderBy,
  query,
  addDoc,
  serverTimestamp,
  getDoc,
  doc,
  updateDoc,
  where
} from "firebase/firestore";
import { getCurrentUser } from "@/services/auth";
import { getEmpresaDocumentReference } from "../empresas/firestore";

export const QUERY_KEY = "jogadaDeInovacao";

export type TTJogadaDeInovacao = Pick<TAuditForm, "createdBy" | "updatedBy" | "createdAt" | "updatedAt"> & {
  startedAt: ReturnType<typeof serverTimestamp>;
  pausedAt: ReturnType<typeof serverTimestamp> | null;
  endedAt: ReturnType<typeof serverTimestamp> | null;
};

type TJogadaDeInovacaoDocument = TAuditDocument &
  Omit<TJogadaDeInovacao, keyof TAudit | keyof TAuditDocument | "startedAt" | "pausedAt" | "endedAt"> & {
    nome: string;
    descricao: string;
    createdAt: Timestamp;
    refPath: string;
    deletedAt: Date | null;
    startedAt: Timestamp;
    pausedAt: Timestamp | null;
    endedAt: Timestamp | null;
  };

export type TJogadaInovacaoFormFields = Omit<
  TJogadaDeInovacao,
  "id" | "createdAt" | "updatedAt" | "createdBy" | "updatedBy" | "startedAt" | "pausedAt" | "endedAt" | "deletedAt"
> &
  Partial<Pick<TJogadaDeInovacao, "id">>;

type TTJogadaInovacaoDatabaseFields = Pick<TAuditForm, "createdBy" | "updatedBy" | "createdAt" | "updatedAt"> & {
  startedAt: ReturnType<typeof serverTimestamp>;
  pausedAt: ReturnType<typeof serverTimestamp> | null;
  endedAt: ReturnType<typeof serverTimestamp> | null;
};

export type TJogadaInovacaoForm = TJogadaInovacaoFormFields & TTJogadaInovacaoDatabaseFields;

const jogadaInovacaoConverter: FirestoreDataConverter<TJogadaDeInovacao> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const {
      nome,
      descricao,
      updatedAt,
      deletedAt,
      createdAt,
      endedAt,
      startedAt,
      pausedAt,
      refPath,
      // nomeCategoria,
      tipoDeJogada,
      taticas,
      // nomeTipo,
      // tipo,
      ...document
    } = snap.data() as TJogadaDeInovacaoDocument;

    const data: TJogadaDeInovacao = subject("TJogadaDeInovacao", {
      ...document,
      id: snap.id,
      nome,
      descricao,
      updatedAt,
      deletedAt,
      refPath,
      // nomeCategoria,
      tipoDeJogada,
      taticas,
      // nomeTipo,
      // tipo,
      createdAt: createdAt.toDate(),
      startedAt: startedAt.toDate(),
      endedAt: endedAt ? endedAt.toDate() : null,
      pausedAt: pausedAt ? pausedAt.toDate() : null
    });

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }
    return subject("TJogadaDeInovacao", data);
  }
};

/**
 * Get all jogadas de inovação
 * @param empresaId - Empresa id
 * @returns - jogadas de inovação collection reference
 */
function getJogadasInovacaoCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, "jogadaDeInovacao");
}

/**
 * Get jogadas de inovação query for a empresa
 * @param empresaId - Empresa id to get comitês
 * @returns - jogadas de inovação query
 */
function generateJogadaDeInovacaoQuery(empresaId: TEmpresa["id"]) {
  const jogadasInovacaoCollectionReference =
    getJogadasInovacaoCollectionReference(empresaId).withConverter(jogadaInovacaoConverter);
  return query(jogadasInovacaoCollectionReference, orderBy("startedAt", "desc"), where("deletedAt", "==", null));
}

/**
 * Get jogadas de inovação for a empresa
 * @param empresaId - Empresa id to get comitês
 * @returns - jogadas de inovação document snapshot
 */
export function getJogadasDeInovacao(empresaId: TEmpresa["id"]) {
  const q = generateJogadaDeInovacaoQuery(empresaId);
  return getDocs(q);
}

/**
 * Start a new jogada de inovação for a empresa
 * @param empresaId - Empresa id to start a new jogada de inovação
 * @param values - jogada de inovação form fields
 * @returns - jogada de inovação document reference
 */
export function addJogadaDeInovacao(empresaId: TEmpresa["id"], values: TJogadaInovacaoFormFields) {
  const jogadasInovacaoCollectionReference = getJogadasInovacaoCollectionReference(empresaId);
  return addDoc(jogadasInovacaoCollectionReference, {
    ...values,
    deletedAt: null
  });
}
/**
 * Get jogada de inovação reference
 * @param empresaId - Empresa id to start a new jogada de inovação
 * @param jogadaDeInovacaoId - jogada de inovação form fields
 * @returns - jogada de inovação document reference
 */
function getJogadaInovacaoDocumentReference(empresaId: TEmpresa["id"], jogadaDeInovacaoId: TJogadaDeInovacao["id"]) {
  const jogadaInovacaoCollectionReference = getJogadasInovacaoCollectionReference(empresaId);
  return doc(jogadaInovacaoCollectionReference, jogadaDeInovacaoId);
}

/**
 * Get jogada de inovação item
 * @param empresaId - Empresa id to start a new jogada de inovação
 * @param jogadaDeInovacaoId - jogada de inovação form fields
 * @returns - jogada de inovação document item
 */
export function getJogadaDeInovacaoItem(empresaId: TEmpresa["id"], jogadaDeInovacaoId: TJogadaDeInovacao["id"]) {
  const jogadaInovacaoDocumentReference = getJogadaInovacaoDocumentReference(empresaId, jogadaDeInovacaoId);
  return getDoc(jogadaInovacaoDocumentReference.withConverter(jogadaInovacaoConverter));
}

/**
 * delete jogada de inovação
 * @param empresaId - Empresa id to start a new jogada de inovação
 * @param jogadaDeInovacaoId - jogada de inovação form fields
 * @returns - jogada de inovação delete
 */
export function deleteJogadaDeInovacao(empresaId: TEmpresa["id"], jogadaDeInovacaoId: TEmpresa["id"]) {
  const jogadaDocumentReference = getJogadaInovacaoDocumentReference(empresaId, jogadaDeInovacaoId);
  return updateDoc(jogadaDocumentReference, {
    deletedAt: serverTimestamp()
  });
}

/**
 * updated jogada de inovação
 * @param empresaId - Empresa id to start a new jogada de inovação
 * @param jogadaDeInovacaoId - jogada de inovação form fields
 * @param values - values to update
 * @returns - jogada de inovação delete
 */
export function updatedJogadaDeInovacao(
  empresaId: TEmpresa["id"],
  jogadaDeInovacaoId: TJogadaDeInovacao["id"],
  values: TJogadaInovacaoFormFields
) {
  const currentUserId = getCurrentUser()?.uid;
  const jogadaDocumentReference = getJogadaInovacaoDocumentReference(empresaId, jogadaDeInovacaoId);

  return updateDoc(jogadaDocumentReference, {
    ...values,
    updatedAt: serverTimestamp(),
    updatedBy: currentUserId
  });
}
