import { serverTimestamp } from "firebase/firestore";
import { ObjectSchema } from "yup";
import schema from "@/schema";
import { getCurrentUser } from "@/services/auth";
import { TTiposDeRespostasForm, TTiposDeRespostasDatabaseFields, TTiposDeRespostasFormFields } from "../firestore";

const MIN_NAME_LENGTH = 3;

/**
 * Define only the meta fields that are not part of the form
 */
const tiposDeRespostasSchemaDatabaseFields: ObjectSchema<TTiposDeRespostasDatabaseFields> = schema.object().shape({
  createdAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .required()
    .defined()
    .nonNullable()
    .default<ReturnType<typeof serverTimestamp>>(() => serverTimestamp()),
  createdBy: schema
    .string()
    .required()
    .defined()
    .nonNullable()
    .default(() => {
      const currentUserId = getCurrentUser()?.uid;
      if (!currentUserId) {
        throw new Error("The current user is not defined");
      }
      return currentUserId;
    }),
  updatedAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .default<ReturnType<typeof serverTimestamp>>(() => serverTimestamp()),
  updatedBy: schema.string().default(() => {
    const currentUserId = getCurrentUser()?.uid;
    if (!currentUserId) {
      throw new Error("The current user is not defined");
    }
    return currentUserId;
  }),
  deletedAt: schema.mixed<ReturnType<typeof serverTimestamp>>().defined().nullable().default(null)
});

/**
 * Define the schema for the form
 */
const tiposDeRespostasSchemaFormFields: ObjectSchema<TTiposDeRespostasFormFields> = schema.object({
  id: schema.string().optional(),
  refPath: schema.string().optional(),
  nome: schema.string().trim().min(MIN_NAME_LENGTH).required().defined().label("Nome"),
  tipoDeResposta: schema.string().trim().required().defined().label("Tipo de resposta"),
  orientacao: schema.string().trim().required().defined().label("Orientação"),
  campos: schema.array().of(schema.object().optional().defined()).default([]).optional().label("Taticas").optional()
});

const tiposDeRespostasSchema: ObjectSchema<TTiposDeRespostasForm> = tiposDeRespostasSchemaFormFields
  // eslint-disable-next-line unicorn/prefer-spread
  .concat(tiposDeRespostasSchemaDatabaseFields)
  .noUnknown()
  .strict()
  .label("TiposDeRespostas");

export default tiposDeRespostasSchema;
