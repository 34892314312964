import { MutationOptions, useMutation } from "@tanstack/react-query";
import { FieldValue, FirestoreError } from "firebase/firestore";
import { addRespostas, TRespostaForm, updateRespostas } from "../firestore";

type TRespostaResponse = Awaited<ReturnType<typeof addRespostas>>;
type TUpdateResponse = Awaited<ReturnType<typeof updateRespostas>>;
type TRespostaMutationOptions = Omit<MutationOptions<TRespostaResponse, FirestoreError>, "mutationFn">;

/**
 * Finalizar resposta de inovação mutation hook
 * @param empresaId - ID da empresa
 * @param item - resposta de inovação
 * @param options = {} - Mutation options
 * @returns Finalizar resposta de inovação mutation
 */
export function useSendResposta(
  empresaId: TEmpresa["id"],
  item: TRespostaForm & Partial<Pick<TResposta, "id" | "refPath">>,
  options: TRespostaMutationOptions = {}
) {
  return useMutation<TResposta | TUpdateResponse | TRespostaResponse, FirestoreError>({
    mutationFn() {
      return item.id
        ? updateRespostas(empresaId, {
            ...item,
            createdAt: item.createdAt instanceof FieldValue ? new Date() : item.createdAt,
            updatedAt: item.updatedAt instanceof FieldValue ? new Date() : item.updatedAt || new Date(),
            deletedAt: item.deletedAt instanceof FieldValue ? null : item.deletedAt || null
          })
        : addRespostas(empresaId, item);
    },
    ...options
  });
}
