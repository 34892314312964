import { Group, Space } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import CardColaborador from "@/modules/diagnosticos/components/cards/CardColaborador";
import CardDiagnostico from "@/modules/diagnosticos/components/cards/CardEmpresa";
import QuestionarioColaboradorPage from "../questionario-colaborador-page";

const QuestionarioDeInovacaoPage = () => {
  const isSmMarches = useMediaQuery("(max-width: 48em)");

  return (
    <>
      <Group>
        {isSmMarches ? <></> : <CardColaborador />}
        <CardDiagnostico titulo={"Numero de questionários respondidos"} colaborador={true} />
      </Group>
      <Space h="md" />
      <QuestionarioColaboradorPage />
    </>
  );
};

export default QuestionarioDeInovacaoPage;
