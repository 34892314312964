import { Box, LoadingOverlay } from "@mantine/core";
import { useParams } from "react-router-dom";
import useGetConvites from "@/components/app-container/useGetConvites";
import useGetQuestionarioConviteFromUrl from "@/modules/responder-questionario/hooks/useGetQuestionarioConviteFromUrl";
import Questionario from "@/modules/vizualizar-questionario-externo/components/questionario";
// prettier-ignore
const VisualizarQuestionarioExternoPage = () => {
  const { idEmpresa } = useParams<{ idEmpresa: string }>();
  const { data: convite } = useGetConvites();
  const { data: data, isFetching } = useGetQuestionarioConviteFromUrl({ activeEmpresaId: convite && convite[0] && convite[0].idEmpresa || idEmpresa });

  if (isFetching) {
    return (
      <Box pos="relative">
        <LoadingOverlay visible={isFetching} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
      </Box>
    );
  }

  return <Questionario data={data} />;
};

export default VisualizarQuestionarioExternoPage;
