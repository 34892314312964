import { ActionIcon, Menu, UnstyledButton, UnstyledButtonProps } from "@mantine/core";
import { IconDotsVertical, IconEdit, IconTrash } from "@tabler/icons-react";
import { PropsWithChildren, forwardRef } from "react";
import { NavLink } from "react-router-dom";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import { dataAutorizacoes } from "@/src/routes/empresa-page/EmpresaPage";
import RemoverRegulamentoIdeiasButton from "../remover-regulamento-ideias-button";

const EditRegulamentoIdeiasButton = forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<{ regulamento: TRegulamentoIdeias } & UnstyledButtonProps>
>(({ regulamento, children, ...properties }, reference) => (
  <UnstyledButton component={NavLink} to={`/ideias/regulamento/${regulamento.id}`} ref={reference} {...properties}>
    {children}
  </UnstyledButton>
));
EditRegulamentoIdeiasButton.displayName = "EditRegulamentoIdeiasButton";
// prettier-ignore
const ContextMenuRow = ({ regulamentoIdeias }: { regulamentoIdeias: TRegulamentoIdeias }) => {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);
  const activeAutorizacao = useUserStore((store) => store.autorizacoes);
  const uid = getCurrentUser()?.uid;
  const { data: userColaborador } = useGetColaboradorById(activeEmpresaId || "", uid || "");

  const autorizacao = dataAutorizacoes?.find(item => item.label === userColaborador?.role);
  const formatAutorizacoes = activeAutorizacao?.filter(item => item.tipo === autorizacao?.value);

  if (!formatAutorizacoes?.find(item => item.tipoAutorizacao === "15")?.editar && !formatAutorizacoes?.find(item => item.tipoAutorizacao === "15")?.remover) {
    return <></>
  }

  return (
    <Menu shadow="md" width={200} position="left" withArrow arrowPosition="center">
      <Menu.Target>
        <ActionIcon variant="transparent" aria-label="Menu" color="gray">
          <IconDotsVertical />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {formatAutorizacoes?.find(item => item.tipoAutorizacao === "15")?.editar ? <Menu.Item
          leftSection={<IconEdit size={14} />}
          component={EditRegulamentoIdeiasButton}
          regulamento={regulamentoIdeias}
        >
          Editar
        </Menu.Item> : <></>}
        {formatAutorizacoes?.find(item => item.tipoAutorizacao === "15")?.remover ? <>
          <Menu.Divider />
          <Menu.Item
            color="red"
            leftSection={<IconTrash size={14} />}
            component={RemoverRegulamentoIdeiasButton}
            regulamentoIdeias={regulamentoIdeias}
          >
            Remover
          </Menu.Item>
        </> : <></>}
      </Menu.Dropdown>
    </Menu>
  );
};

export default ContextMenuRow;
