import { Card, Group, Space, Stack, Tabs } from "@mantine/core";
import { IconBulb, IconTriangleSquareCircle } from "@tabler/icons-react";
import { and, where } from "firebase/firestore";
import { RefObject, useEffect, useRef } from "react";
import { THandler } from "@/components/table/Table";
import StatusComiteInovacao from "@/modules/comite-inovacao/components/status-comite-inovacao";
import DesafiosTable from "@/modules/desafios/components/desafios-table";
import EmpresaSelecionadaCard from "@/modules/empresas/components/empresa-selecionada-card";
import IdeiasCadastradasDesdeCard from "@/modules/ideias/components/ideias-cadastradas-desde-card";
import IdeiasTable from "@/modules/ideias/components/ideias-table";
import useUserStore from "@/modules/users/store";

const DAYS_AGO = 30;
const date30DaysAgo = new Date();
date30DaysAgo.setDate(date30DaysAgo.getDate() - DAYS_AGO);

/**
 * Set the global filter to the table to only show minhas ideias and non deleted items
 * @param tableReference - Reference to the table handlers object
 */
// prettier-ignore
function useNonDeletedGlobalDesafioFilters(tableReference: RefObject<THandler<TDesafio>>) {
  const colaborador = useUserStore((state) => state.colaborador);

  useEffect(() => {
    const tableHandlers = tableReference.current;
    if (tableHandlers && colaborador && tableHandlers.table.getState().globalFilter === null) {
      tableHandlers.table.setGlobalFilter(
        and(
          where("deletedAt", "==", null),
          where("published", "==", true)
        )
      );
    }
  }, [colaborador, tableReference]);
}

/**
 * Set the global filter to the table to only show minhas ideias and non deleted items
 * @param tableReference - Reference to the table handlers object
 */
// prettier-ignore
function useNonDeletedGlobalFilters(tableReference: RefObject<THandler<TIdeia>>) {
  const colaborador = useUserStore((state) => state.colaborador);

  useEffect(() => {
    const tableHandlers = tableReference.current;
    if (tableHandlers && colaborador && tableHandlers.table.getState().globalFilter === null) {
      tableHandlers.table.setGlobalFilter(
        and(
          where("deletedAt", "==", null),
          where("publishedAt", "!=", null),
          where("privado", "==", false),
        )
      );
    }
  }, [colaborador, tableReference]);
}

const RankingDeIdeiasPage = () => {
  const tableHandlersDesafiosReference = useRef<THandler<TDesafio>>(null);
  useNonDeletedGlobalDesafioFilters(tableHandlersDesafiosReference);

  const tableHandlersIdeiasReference = useRef<THandler<TIdeia>>(null);
  useNonDeletedGlobalFilters(tableHandlersIdeiasReference);

  return (
    <>
      <Stack>
        <Group justify="space-around" align="stretch">
          <EmpresaSelecionadaCard />
          <IdeiasCadastradasDesdeCard date={date30DaysAgo} />
        </Group>
        <Card withBorder>
          <StatusComiteInovacao />
        </Card>
        <Card withBorder p={0}>
          <Tabs defaultValue="ideias">
            <Tabs.List>
              <Tabs.Tab value="ideias" leftSection={<IconBulb />}>
                Ideias
              </Tabs.Tab>
              <Tabs.Tab value="desafios" leftSection={<IconTriangleSquareCircle />}>
                Desafios
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="ideias">
              <IdeiasTable ref={tableHandlersIdeiasReference} />
            </Tabs.Panel>

            <Tabs.Panel value="desafios">
              <DesafiosTable ref={tableHandlersDesafiosReference} />
            </Tabs.Panel>
          </Tabs>
        </Card>
      </Stack>
      <Space h={"xl"} />
    </>
  );
};

export default RankingDeIdeiasPage;
