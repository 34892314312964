import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { QUERY_KEY } from "@/modules/questionarios/components/questionario-table/QuestionarioTable";
import { getQuestionarioItem } from "@/modules/questionarios/firestore";
import useUserStore from "@/modules/users/store";

type TGetQuestionarioResponse = Awaited<ReturnType<typeof getQuestionarioItem>>;

/**
 * Get ideia from url params
 * @returns - Ideia or null if not found
 */
function useGetQuestionarioFromUrl() {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresa)?.id;
  const { id } = useParams<{ id: string; idEmpresa: string }>();

  return useQuery<TGetQuestionarioResponse, FirestoreError, TQuestionario | null>({
    queryKey: [QUERY_KEY, id, activeEmpresaId],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!id) {
        throw new Error("Ideia não selecionada");
      }

      return getQuestionarioItem(activeEmpresaId, id);
    },
    select(dataResponse) {
      if (!dataResponse.exists()) {
        return null;
      }

      return dataResponse.data();
    },
    enabled: !!id && !!activeEmpresaId
  });
}

export default useGetQuestionarioFromUrl;
