import { DocumentReference, serverTimestamp } from "firebase/firestore";
import { ObjectSchema } from "yup";
import { TColaboradorDocument, getColaboradorDocumentReferenceFromPath } from "@/modules/colaboradores/firestore";
import { getIdeiaDocumentReferenceFromPath } from "@/modules/ideias/firestore";
import schema from "@/schema";
import { getCurrentUser } from "@/services/auth";
import { TComiteInovacaoForm, TComiteInovacaoFormFields, TTComiteInovacaoDatabaseFields } from "../firestore";

/**
 * Define only the meta fields that are not part of the form
 */
const comiteInovacaoSchemaDatabaseFields: ObjectSchema<TTComiteInovacaoDatabaseFields> = schema.object().shape({
  createdAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .required()
    .defined()
    .nonNullable()
    .default<ReturnType<typeof serverTimestamp>>(() => serverTimestamp()),
  createdBy: schema
    .string()
    .required()
    .defined()
    .nonNullable()
    .default(() => {
      const currentUserId = getCurrentUser()?.uid;
      if (!currentUserId) {
        throw new Error("The current user is not defined");
      }
      return currentUserId;
    }),
  updatedAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .default<ReturnType<typeof serverTimestamp>>(() => serverTimestamp()),
  updatedBy: schema.string().default(() => {
    const currentUserId = getCurrentUser()?.uid;
    if (!currentUserId) {
      throw new Error("The current user is not defined");
    }
    return currentUserId;
  }),
  startedAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .required()
    .defined()
    .nonNullable()
    .default<ReturnType<typeof serverTimestamp>>(() => serverTimestamp()),
  pausedAt: schema.mixed<ReturnType<typeof serverTimestamp>>().defined().nullable().default(null),
  endedAt: schema.mixed<ReturnType<typeof serverTimestamp>>().defined().nullable().default(null)
});

const comiteInovacaoSchemaFormFields: ObjectSchema<TComiteInovacaoFormFields> = schema.object({
  id: schema.string().optional(),
  refPath: schema.string().optional(),
  participantesReferences: schema
    .array()
    .of(schema.mixed<DocumentReference<TColaborador, TColaboradorDocument>>().required().defined())
    .required()
    .defined()
    .default([])
    .transform((value: (string | DocumentReference<TColaborador, TColaboradorDocument>)[]) =>
      value.map((v) => {
        const isString = typeof v === "string";
        return isString ? getColaboradorDocumentReferenceFromPath(v) : v;
      })
    )
    .label("Participantes do Comitê"),

  ideiasReferences: schema
    .array()
    .of(schema.mixed<DocumentReference<TIdeia>>().required().defined())
    .required()
    .defined()
    .default([])
    .transform((value: (string | DocumentReference<TIdeia>)[]) =>
      value.map((v) => {
        const isString = typeof v === "string";
        return isString ? getIdeiaDocumentReferenceFromPath(v) : v;
      })
    )
    .label("Participantes da ideia")
});

const comiteInovacaoSchema: ObjectSchema<TComiteInovacaoForm> = comiteInovacaoSchemaFormFields
  // eslint-disable-next-line unicorn/prefer-spread
  .concat(comiteInovacaoSchemaDatabaseFields)
  .noUnknown()
  .strict()
  .label("Comitê de Inovação");

export default comiteInovacaoSchema;
