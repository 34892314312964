import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { DESAFIO_CRONOGRAMA_COLLECTION_KEY, getCronogramaDesafioFilter } from "@/modules/desafio-cronograma/firestore";
import useUserStore from "@/modules/users/store";

type TGetCronograma = Awaited<ReturnType<typeof getCronogramaDesafioFilter>>;

/**
 * Hook para obter os cronogramas
 * @param idDesafio - id do desafio
 * @param idDesafio.idDesafio - id do desafio
 * @returns -  cronogramas
 */
function useGetCronograma(idDesafio: TDesafio["id"]) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  return useQuery<TGetCronograma, FirestoreError, TCronogramaDesafio[]>({
    queryKey: [activeEmpresaId, DESAFIO_CRONOGRAMA_COLLECTION_KEY, idDesafio],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return getCronogramaDesafioFilter(activeEmpresaId, idDesafio);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((desafio) => desafio.data());
    }
  });
}
export default useGetCronograma;
