import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getRepostasArray, QUERY_KEY } from "@/modules/responder-questionario/firestore";
import useUserStore from "@/modules/users/store";

type TGetRespostaResponse = Awaited<ReturnType<typeof getRepostasArray>>;

/**
 * Get resposta from url params
 * @param route - questionario id
 * @param route.id - id
 * @returns - resposta or null if not found
 */
function useGetResposta({ id }: { id: string | null | undefined }) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  return useQuery<TGetRespostaResponse, FirestoreError, TResposta[] | null>({
    queryKey: [QUERY_KEY, id, activeEmpresaId],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return getRepostasArray(activeEmpresaId, id, "");
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((document_) => document_.data());
    },
    enabled: !!id && !!activeEmpresaId
  });
}

export default useGetResposta;
