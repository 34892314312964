import { subject } from "@casl/ability";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { getIdeaActivities } from "../firestore";

type TGetIdeiaActivities = Awaited<ReturnType<typeof getIdeaActivities>>;
type TQueryOptions = Omit<
  UseQueryOptions<TGetIdeiaActivities, FirestoreError, TIdeiaActivityPerColaborador[]>,
  "queryKey"
>;

/**
 * Get ideia activities per colaborador
 * @param ideia - Ideia to get activitiespackages/ui/src/modules/ideias-activities-per-colaborador/hooks/useGetIdeiaActivityByColaborador.ts
 * @param options - Query options to use
 * @returns - Query result
 */
function useGetIdeiaActivityByColaborador(ideia: TIdeia, options: TQueryOptions = {}) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  return useQuery<TGetIdeiaActivities, FirestoreError, TIdeiaActivityPerColaborador[] | null>({
    queryKey: [activeEmpresaId, "ideia", ideia.id, "activities"],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return getIdeaActivities(activeEmpresaId, ideia.id);
    },
    select(dataSnapshot) {
      if (dataSnapshot.empty) {
        return null;
      }

      const activities = dataSnapshot.docs.map((document_) => document_.data());

      return subject("TIdeiaActivityPerColaborador[]", activities);
    },
    ...options
  });
}

export default useGetIdeiaActivityByColaborador;
