import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getProjetosEmpresa } from "@/modules/projetos/firestore";

type TGetComitesInovacaoResponse = Awaited<ReturnType<typeof getProjetosEmpresa>>;

/**
 * Get comites de inovação query hook
 * @param activeEmpresaId - activeEmpresaId
 * @returns Comites de inovação query hook
 */
function useQueryProjetos(activeEmpresaId: TEmpresa["id"]) {
  return useQuery<TGetComitesInovacaoResponse, FirestoreError, TProjeto[]>({
    queryKey: [activeEmpresaId],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return getProjetosEmpresa(activeEmpresaId);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }
      return dataResponse.docs.map((document_) => document_.data());
    },
    enabled: !!activeEmpresaId
  });
}

export default useQueryProjetos;
