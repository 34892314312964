import { Anchor, Space } from "@mantine/core";
import { NavLink } from "react-router-dom";
import BreadcrumbsNavigation from "@/components/breadcrumbs-navigation";
import QuestionarioDiagnosticoList from "@/modules/questionario-de-inovacao/components/questionario-list";

// prettier-ignore
const QuestionarioColaboradorListPage = () => {
  return (
    <>
      <BreadcrumbsNavigation notHome={true}>
        <Anchor component={NavLink} to="/diagnostico">
          Home
        </Anchor>
      </BreadcrumbsNavigation>
      <Space h="md" />
      <QuestionarioDiagnosticoList />
    </>
  );
};

export default QuestionarioColaboradorListPage;
