import { subject } from "@casl/ability";
import { FileWithPath } from "@mantine/dropzone";
import { PaginationState, SortingState } from "@tanstack/react-table";
import {
  DocumentReference,
  FirestoreDataConverter,
  QueryConstraint,
  Timestamp,
  collection,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
  arrayUnion,
  arrayRemove,
  deleteDoc
} from "firebase/firestore";
import { getDownloadURL } from "firebase/storage";
import { firestore } from "@/base";
import { getCurrentUser } from "@/services/auth";
import { generateNewQuery, generateQuery } from "@/utils/firestore";
import ideiaSchema from "./schema/ideia-schema";
import { uploadAnexosFilesToStorage } from "./storage";
import { TColaboradorDocument } from "../colaboradores/firestore";
import { getEmpresaDocumentReference } from "../empresas/firestore";
import { getRegulamentoDocumentReferenceFromReferencePath } from "../regulamento-ideias/firestore";

const DEFAULT_LIMIT = 10;
export const DEFAULT_SORTING: SortingState = [{ id: "titulo", desc: false }];
export const DEFAULT_PAGINATION: PaginationState = { pageIndex: 0, pageSize: DEFAULT_LIMIT };

export const IDEIAS_COLLECTION_KEY = "ideias";

export type TIdeiaFormFields = Omit<
  TIdeia,
  | "id"
  | "refPath"
  | "createdAt"
  | "updatedAt"
  | "createdBy"
  | "updatedBy"
  | "deletedAt"
  | "cancelAt"
  | "publishedAt"
  | "autorReference"
  | "participantesReferences"
  | "implementadoresReferences"
  | "regulamentoReference"
  | "rankingByMoedas"
  | "rankingByLikes"
> &
  Partial<Pick<TIdeia, "id" | "refPath">> & {
    anexosFilesToUpload: FileWithPath[];
    autorReference: DocumentReference<TColaborador, TColaboradorDocument> | string;
    participantesReferences: DocumentReference<TColaborador, TColaboradorDocument>[];
    implementadoresReferences: DocumentReference<TColaborador, TColaboradorDocument>[];
    regulamentoReference: DocumentReference<TRegulamentoIdeias> | string;
  };

export type TIdeiaDatabaseFields = Pick<TIdeia, "createdBy" | "updatedBy"> & {
  createdAt: ReturnType<typeof serverTimestamp>;
  updatedAt?: ReturnType<typeof serverTimestamp>;
  deletedAt: ReturnType<typeof serverTimestamp> | null;
  cancelAt: ReturnType<typeof serverTimestamp> | null;
  publishedAt: ReturnType<typeof serverTimestamp> | null;
};
export type TIdeiaForm = TIdeiaFormFields & TIdeiaDatabaseFields;

type TIdeiaDocument = Omit<
  TIdeia,
  | "createdAt"
  | "updatedAt"
  | "deletedAt"
  | "cancelAt"
  | "publishedAt"
  | "autorReference"
  | "participantesReferences"
  | "implementadoresReferences"
  | "regulamentoReference"
> & {
  createdAt: Timestamp;
  updatedAt?: Timestamp;
  cancelAt: Timestamp | null;
  deletedAt: Timestamp | null;
  publishedAt: Timestamp | null;
  autorReference: DocumentReference<TColaborador, TColaboradorDocument> | string;
  participantesReferences: DocumentReference<TColaborador, TColaboradorDocument>[];
  implementadoresReferences: DocumentReference<TColaborador, TColaboradorDocument>[];
  regulamentoReference: DocumentReference<TRegulamentoIdeias> | string;
};

export const ideiaConverter: FirestoreDataConverter<TIdeia> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const {
      createdAt,
      updatedAt,
      cancelAt,
      deletedAt,
      publishedAt,
      status,
      autorReference,
      participantesReferences,
      implementadoresReferences,
      regulamentoReference,
      ...document
    } = snap.data() as TIdeiaDocument;

    const ideiaBase = ideiaSchema
      .omit(["createdAt", "updatedAt", "cancelAt", "deletedAt", "publishedAt"])
      .getDefault() as Omit<TIdeiaForm, "createdAt" | "updatedAt" | "deletedAt" | "publishedAt">;
    const data: TIdeia = {
      ...ideiaBase,
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      status: (status as TIdeia["status"]) ?? "NOVA_IDEIA",
      cancelAt: cancelAt ? cancelAt.toDate() : null,
      deletedAt: deletedAt ? deletedAt.toDate() : null,
      publishedAt: publishedAt ? publishedAt.toDate() : null,
      refPath: snap.ref.path,
      autorReference: typeof autorReference === "string" ? autorReference : autorReference.path,
      regulamentoReference: typeof regulamentoReference === "string" ? regulamentoReference : regulamentoReference.path,
      participantesReferences: participantesReferences
        ? participantesReferences.map((reference) => reference.path)
        : [],
      implementadoresReferences: implementadoresReferences
        ? implementadoresReferences.map((reference) => reference.path)
        : []
    };

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }
    return subject("TIdeia", data);
  }
};

/**
 * Get ideias collection reference from empresaId
 * @param empresaId - Empresa id to get the ideias collection reference
 * @returns - Ideias collection reference
 */
function getIdeiasCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, IDEIAS_COLLECTION_KEY);
}

/**
 * Get ideia document reference from empresaId
 * @param empresaId - Empresa id to get the ideia document reference
 * @param ideiaId - Ideia id to get the ideia document reference
 * @returns - Ideia document reference
 */
export function getIdeiaDocumentReference(empresaId: TEmpresa["id"], ideiaId: TIdeia["id"]) {
  const ideiasCollectionReference = getIdeiasCollectionReference(empresaId);
  return doc(ideiasCollectionReference, ideiaId);
}

/**
 * Get ideia document reference from path
 * @param path - Path to get the Ideia document reference
 * @returns - Ideia document reference
 */
export function getIdeiaDocumentReferenceFromPath(path: string) {
  return doc(firestore, path).withConverter(ideiaConverter);
}

const BASE_SORTING: SortingState = [{ id: "publishedAt", desc: false }];

/**
 * Get ideias documents from empresaId
 * @param empresaId - Empresa id to get the ideias documents
 * @param options - Options to get the ideias documents
 * @param options.sorting - Sorting options
 * @param options.filters - Filters options
 * @param options.pagination - Pagination options
 * @param options.paginationCursors - Array of pagination cursors (startAt, endAt)[]
 * @param options.globalFilters - Global filters queryConstraints
 * @returns - Ideias documents
 */
export function getIdeias(
  empresaId: TEmpresa["id"],
  {
    sorting = [],
    filters = [],
    pagination = { pageIndex: 0, pageSize: DEFAULT_LIMIT },
    globalFilters,
    paginationCursors
  }: TPaginatedQueryOptions<TIdeia> = {}
): Promise<TPaginatedQueryResponse<TIdeia>> {
  const ideiasCollectionReference = getIdeiasCollectionReference(empresaId).withConverter(ideiaConverter);
  let allSorting = [...sorting, ...BASE_SORTING];

  if (sorting.some((item) => item.id === "publishedAt")) {
    allSorting = sorting;
  }

  const qAll = generateQuery(ideiasCollectionReference, { filters, globalFilters });

  const qPaginated = generateQuery(ideiasCollectionReference, {
    sorting: allSorting,
    filters,
    globalFilters,
    pagination,
    paginationCursors
  });

  // eslint-disable-next-line compat/compat
  return Promise.all([getDocs(qPaginated), getCountFromServer(qAll)]);
}

/**
 * Get ideias documents from empresaId
 * @param empresaId - Empresa id to get the ideias documents
 * @param options - Options to get the ideias documents
 * @param options.sorting - Sorting options
 * @param options.filters - Filters options
 * @param options.pagination - Pagination options
 * @param options.paginationCursors - Array of pagination cursors (startAt, endAt)[]
 * @param options.globalFilters - Global filters queryConstraints
 * @returns - Ideias documents
 */
// prettier-ignore
export function getIdeiasNew(
  empresaId: TEmpresa["id"],
  {
    sorting = [],
    filters = [],
    pagination,
    globalFilters,
    paginationCursors
  }: TPaginatedQueryOptions<TIdeia> = {}
): Promise<TPaginatedQueryResponse<TIdeia>> {
  const ideiasCollectionReference = getIdeiasCollectionReference(empresaId).withConverter(ideiaConverter);
  let allSorting = [...BASE_SORTING, ...sorting];

  if (sorting.some((item) => item.id === "publishedAt")) {
    allSorting = sorting;
  }

  const qAll = generateNewQuery(ideiasCollectionReference, { filters, globalFilters });

  const qPaginated = generateNewQuery(ideiasCollectionReference, {
    sorting: allSorting,
    filters,
    globalFilters,
    pagination,
    paginationCursors
  });

  // eslint-disable-next-line compat/compat
  return Promise.all([getDocs(qPaginated), getCountFromServer(qAll)]);
}

/**
 * Get ideias sorted by ranking from empresaId
 * @param empresaId - Empresa id to get the ideias documents
 * @returns - Ideias documents
 */
export function getIdeiasSortedByRanking(empresaId: TEmpresa["id"]) {
  const ideiasCollectionReference = getIdeiasCollectionReference(empresaId).withConverter(ideiaConverter);
  const ideiasQuery = query(
    ideiasCollectionReference,
    where("publishedAt", "!=", null),
    where("deletedAt", "==", null),
    where("cancelAt", "==", null),
    orderBy("rankingByLikes.ranking"),
    orderBy("rankingByMoedas.ranking"),
    orderBy("publishedAt")
  ).withConverter(ideiaConverter);

  return getDocs(ideiasQuery);
}

/**
 * Get ideias documents from empresaId and colaboradorId with same regulamento
 * @param empresaId - Empresa id to get the ideias documents
 * @param regulamentoDocumentReference - Regulamento document reference to get the ideias documents
 * @returns - Ideias documents
 */
export function getIdeiasByRegulamento(
  empresaId: TEmpresa["id"],
  regulamentoDocumentReference: DocumentReference<TRegulamentoIdeias>
) {
  // Get all ideias with same regulamento and not deleted
  const ideiasActivitiesPerColaboradorCollection = getIdeiasCollectionReference(empresaId);
  const ideiasSameRegulamentoQuery = query(
    ideiasActivitiesPerColaboradorCollection,
    where("regulamentoReference", "==", regulamentoDocumentReference),
    where("cancelAt", "==", null),
    where("deletedAt", "==", null)
  ).withConverter(ideiaConverter);

  return getDocs(ideiasSameRegulamentoQuery);
}

/**
 * Get ideias documents from empresaId and colaboradorId by status
 * @param empresaId - Empresa id to get the ideias documents
 * @param status - Ideia status to get the ideias documents
 * @returns - Ideias documents
 */
export function getIdeiasByStatus(empresaId: TEmpresa["id"], ...status: TIdeia["status"][]) {
  // Get all ideias with same regulamento and not deleted
  const ideiasActivitiesPerColaboradorCollection = getIdeiasCollectionReference(empresaId);
  const ideiasQuery = query(
    ideiasActivitiesPerColaboradorCollection,
    where("status", "in", status),
    // where("publishedAt", "!=", null),
    where("deletedAt", "==", null)
  ).withConverter(ideiaConverter);

  return getDocs(ideiasQuery);
}

/**
 * Get total ideias documents from empresaId
 * @param empresaId - Empresa id to get the total ideias documents
 * @returns - Total ideias documents
 */
// prettier-ignore
export function getTotalIdeias(empresaId: TEmpresa["id"]) {
  const ideiasCollectionReference = getIdeiasCollectionReference(empresaId);
  const ideiasQuery = query(ideiasCollectionReference, where("deletedAt", "==", null), where("privado", "!=", true));
  return getCountFromServer(ideiasQuery);
}

/**
 * Get total ideias documents from empresaId
 * @param empresaId - Empresa id to get the total ideias documents
 * @param dateStart - Date start between to get the total ideias documents
 * @param dateEnd - Date end between to get the total ideias documents
 * @returns - Total ideias documents
 */
export function getTotalIdeiasBetween(empresaId: TEmpresa["id"], dateStart: Date, dateEnd?: Date) {
  const ideiasCollectionReference = getIdeiasCollectionReference(empresaId);

  const constrains: QueryConstraint[] = [where("deletedAt", "==", null), where("publishedAt", ">=", dateStart)];
  if (dateEnd) {
    constrains.push(where("publishedAt", "<=", dateEnd));
  }
  const ideiasQuery = query(ideiasCollectionReference, ...constrains);
  return getCountFromServer(ideiasQuery);
}

/**
 * Add a new ideia to the given empresa
 * @param empresaId - Empresa id to add the ideia
 * @param ideia - Ideia data
 * @returns - Ideia document reference
 */
export async function addIdeia(empresaId: TEmpresa["id"], ideia: Omit<TIdeiaForm, "id" | "refPath">) {
  const ideiasCollectionReference = getIdeiasCollectionReference(empresaId);
  const { anexosFilesToUpload, ...ideiaData } = ideia;
  const ideiaDocument = doc(ideiasCollectionReference);
  const anexosPromises = anexosFilesToUpload.map<Promise<TFileStored>>((file) =>
    uploadAnexosFilesToStorage(empresaId, ideiaDocument.id, file).then(async (uploadTaskSnapshot) => {
      const url = await getDownloadURL(uploadTaskSnapshot.ref);
      return {
        path: url,
        name: uploadTaskSnapshot.metadata.name,
        size: uploadTaskSnapshot.metadata.size,
        type: uploadTaskSnapshot.metadata.contentType || ""
      };
    })
  );
  // prettier-ignore
  const regulamentoReference = typeof ideiaData.regulamentoReference === "string" ?  getRegulamentoDocumentReferenceFromReferencePath(ideiaData?.regulamentoReference) : ideiaData?.regulamentoReference
  // eslint-disable-next-line compat/compat
  const anexos = await Promise.all(anexosPromises);
  return setDoc(ideiaDocument, { ...ideiaData, regulamentoReference, anexos });
}

/**
 * Get a ideia document snapshot
 * @param empresaId - Empresa id to get the ideia
 * @param ideiaId - ideia id to get the ideia
 * @returns - Ideia document snapshot
 */
export function getIdeia(empresaId: TEmpresa["id"], ideiaId: TIdeia["id"]) {
  const ideiaDocumentReference = getIdeiaDocumentReference(empresaId, ideiaId).withConverter(ideiaConverter);
  return getIdeiaFromDocumentReference(ideiaDocumentReference);
}

/**
 * Get a ideia from a document reference
 * @param ideiaDocumentReference - Ideia document reference to get the ideia
 * @returns - Ideia document snapshot
 */
export function getIdeiaFromDocumentReference(ideiaDocumentReference: DocumentReference<TIdeia>) {
  return getDoc(ideiaDocumentReference);
}

/**
 * Get a ideia from a document reference path
 * @param ideiaReferencePath - Ideia document reference path to get the ideia
 * @returns - Ideia document snapshot
 */
export function getIdeiaFromDocumentReferenceFromPath(ideiaReferencePath: string) {
  const ideiaDocumentReference = getIdeiaDocumentReferenceFromPath(ideiaReferencePath);
  return getIdeiaFromDocumentReference(ideiaDocumentReference);
}

/**
 * Update a empresa to the database
 * @param empresaId - Empresa id
 * @param ideiaId - Ideia id to update
 * @param ideia - Ideia data
 * @returns - Promise with the ideia reference
 */
export function updateIdeia(empresaId: TEmpresa["id"], ideiaId: TIdeia["id"], ideia: TIdeiaForm) {
  const ideiasCollectionReference = getIdeiaDocumentReference(empresaId, ideiaId).withConverter(ideiaConverter);
  const currentUserId = getCurrentUser()?.uid;
  return updateDoc(ideiasCollectionReference, {
    ...ideia,
    updatedAt: serverTimestamp(),
    updatedBy: currentUserId
  });
}

/**
 * Publish a ideia
 * @param empresaId - Empresa id
 * @param ideiaId - Ideia id to update
 * @returns - Promise with the ideia reference
 */
export function publishIdeia(empresaId: TEmpresa["id"], ideiaId: TIdeia["id"]) {
  const ideiasCollectionReference = getIdeiaDocumentReference(empresaId, ideiaId).withConverter(ideiaConverter);
  const currentUserId = getCurrentUser()?.uid;
  return updateDoc(ideiasCollectionReference, {
    status: "NOVA_IDEIA",
    publishedAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
    updatedBy: currentUserId
  });
}

/**
 * Delete a ideia to the database
 * @param empresaId - Empresa id to delete the ideia
 * @param ideia - Ideia to delete
 * @returns - Promise
 */
export function deleteIdeia(empresaId: TEmpresa["id"], ideia: TIdeia) {
  const ideiasCollectionReference = getIdeiaDocumentReference(empresaId, ideia.id);
  return deleteDoc(ideiasCollectionReference);
}

/**
 * Get started ideias query
 * @param empresaId - Empresa id
 * @returns - Ideias document reference
 */
function queryIdeias(empresaId: TEmpresa["id"]) {
  const projetosReference = getIdeiasCollectionReference(empresaId).withConverter(ideiaConverter);
  return query(projetosReference, where("deletedAt", "==", null));
}

/**
 * Get ideias for a empresa
 * @param empresaId - Ideia id to get comitês
 * @returns - Ideia document snapshot
 */
export function getIdeiasAll(empresaId: TEmpresa["id"]) {
  const q = queryIdeias(empresaId);
  return getDocs(q);
}

/**
 * Get started ideias query
 * @param empresaId - Empresa id
 * @returns - Ideias document reference
 */
function queryIdeiasPublished(empresaId: TEmpresa["id"]) {
  const projetosReference = getIdeiasCollectionReference(empresaId).withConverter(ideiaConverter);
  return query(projetosReference, where("deletedAt", "==", null), where("publishedAt", "!=", null));
}

/**
 * Get ideias for a empresa
 * @param empresaId - Ideia id to get comitês
 * @returns - Ideia document snapshot
 */
export function getIdeiasPublished(empresaId: TEmpresa["id"]) {
  const q = queryIdeiasPublished(empresaId);
  return getDocs(q);
}

/**
 * Include implementador into implementadoresReference
 * @param empresaId - Empresa id
 * @param ideiaId - Desafio id to include participante
 * @param implementadorReferences - Implementador reference to include
 * @returns - Promise with the desafio reference
 */
export function includeImplementador(
  empresaId: TEmpresa["id"],
  ideiaId: TDesafio["id"],
  implementadorReferences: DocumentReference<TColaborador> | DocumentReference<TColaborador>[]
) {
  const collectionReference = getIdeiaDocumentReference(empresaId, ideiaId).withConverter(ideiaConverter);
  const currentUserId = getCurrentUser()?.uid;
  const references = Array.isArray(implementadorReferences) ? implementadorReferences : [implementadorReferences];
  return updateDoc(collectionReference, {
    implementadoresReferences: arrayUnion(...references),
    updatedAt: serverTimestamp(),
    updatedBy: currentUserId
  });
}

/**
 * Remove implementador from implementadorReference
 * @param empresaId - Empresa id
 * @param ideiaId - Ideia id to remove implementador
 * @param implementadorReferences - Implementador reference to remove
 * @returns - Promise with the desafio reference
 */
// prettier-ignore
export function removeImplementador(
  empresaId: TEmpresa["id"],
  ideiaId: TDesafio["id"],
  implementadorReferences: DocumentReference<TColaborador> | DocumentReference<TColaborador>[]
) {
  const collectionReference = getIdeiaDocumentReference(empresaId, ideiaId).withConverter(ideiaConverter);
  const currentUserId = getCurrentUser()?.uid;
  const references = Array.isArray(implementadorReferences) ? implementadorReferences : [implementadorReferences];
  return updateDoc(collectionReference, {
    implementadoresReferences: arrayRemove(...references),
    updatedAt: serverTimestamp(),
    updatedBy: currentUserId
  });
}
