/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import { Box, Button, Group, Modal, Stack, Text, TextInput, Title } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useCallback } from "react";
import { getConviteByEmail } from "@/modules/convites/firestore";
import { extractTextInputPropertiesFromFormSchema } from "@/utils/form";
import useConviteForm, { ConviteForm } from "../hooks/useConviteForm";
import useCreateConvite from "../hooks/useCreateConvite";

type Properties = {
  licenciadaId?: TLicenciada["id"];
  empresaId: TEmpresa["id"];
  questionario: TQuestionario;
  empresaRespondenteId: TEmpresa["id"];
  opened: boolean;
  onClose: (convite: TConvite) => void;
};

/**
 * @param properties - Properties
 * @returns React.FC
 */
function CreateConviteModal(properties: Properties) {
  const { form, descriptor } = useConviteForm();

  const { createConvite } = useCreateConvite(properties);

  const getConvite = useCallback(
    (email: string) => {
      return getConviteByEmail(
        properties.empresaId,
        properties.questionario.id,
        properties.empresaRespondenteId,
        email
      );
    },
    [properties]
  );

  const handleSubmit = useCallback(
    (values: ConviteForm) => {
      const submit = async () => {
        const convite = await getConvite(values.email);

        const conviteFound = convite?.data() ?? null;

        if (conviteFound) {
          properties.onClose(conviteFound);
        } else {
          try {
            const newConvite = await createConvite(values.name, values.email);
            if (!newConvite) {
              throw new Error("Falha ao criar convite");
            }

            localStorage.setItem("emailConvite", values.email);
            properties.onClose(newConvite);
          } catch (error) {
            console.error(error);
            const { message } = error as Error;
            showNotification({ message, color: "red" });
          }
        }
      };

      void submit();
    },
    [getConvite]
  );

  return (
    <Modal
      onClose={() => {}}
      opened={properties.opened}
      closeOnClickOutside={false}
      withCloseButton={false}
      closeOnEscape={false}
      size="lg"
      centered
    >
      <Box>
        <Stack>
          <Title>Prêmio Empresa Inovadora 2025</Title>
          <Text>Para responder o questionário, insira seu nome e e-mail no campo abaixo.</Text>
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <Stack>
              <TextInput
                {...form.getInputProps("name")}
                {...extractTextInputPropertiesFromFormSchema(descriptor, "name")}
              />
              <TextInput
                {...form.getInputProps("email")}
                {...extractTextInputPropertiesFromFormSchema(descriptor, "email")}
              />
              <Group justify="end" align="center">
                <Button type="submit">Iniciar</Button>
              </Group>
            </Stack>
          </form>
        </Stack>
      </Box>
    </Modal>
  );
}

export default CreateConviteModal;
