import { Button, Card, Flex, Group, LoadingOverlay, Text, TextInput } from "@mantine/core";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { signInWith } from "@/services/auth";
import { captureException } from "@/services/log";
import { useConvite } from "./useConvite";
import useQueryConviteNovo from "./useGetConviteNovo";
import { checkUser } from "../../functions";
// prettier-ignore
const ConvidarEmpresaForm = () => {
  const navigate = useNavigate();
  const { projetoId, empresaId, colaboradorId } = useParams<{
    projetoId: string;
    colaboradorId: string;
    empresaId: string;
  }>();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState<{ email: string; nome: string; senha: string }>({
    email: "",
    nome: "",
    senha: ""
  });
  const { data } = useQueryConviteNovo(colaboradorId);
  const convite = data && data[0];

  if (!convite?.convite) {
    navigate("/")
  }

  const { mutate } = useConvite(
    {
      id: convite?.id || "",
      idProjeto: convite?.idProjeto || "",
      idEmpresa: convite?.idEmpresa || "",
      idQuestionario: convite?.idQuestionario || "",
      idColaboradorConvidado: convite?.idColaboradorConvidado || "",
      idEmpresaColaboradorConvidado: convite?.idEmpresaColaboradorConvidado || "",
      convite: false,
      createdAt: new Date(),
      createdBy: "",
      refPath: "",
      deletedAt: null
    });


  if (loading) {
    return <LoadingOverlay visible={true} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
  }

  if (convite?.cancelado) {
    return <Group align={"center"} justify={"center"} h={"100vh"}>
      <Text fw={"bold"} color={"red"}>Convite cancelado</Text>
    </Group>
  }

  return <Flex h={"100vh"} justify={"center"} align="center" flex={1}>
    <Card p={50} miw={"350px"}>
      <Flex mih={50} gap="md" justify="center" align="start" direction="column" wrap="wrap">
        <Text size="lg">Salvar dados do colaborador</Text>
        <TextInput
          label="Email"
          placeholder="Digite seu email"
          w={"100%"}
          onChange={handleInputChange("email", setValues)}
        />
        <TextInput
          label="Nome"
          placeholder="Digite seu nome"
          w={"100%"}
          onChange={handleInputChange("nome", setValues)}
        />
        <TextInput
          label="Senha"
          placeholder="Digite sua senha"
          w={"100%"}
          type="password"
          onChange={handleInputChange("senha", setValues)}
        />
        <TextInput
          label="Confirme sua Senha"
          placeholder="Digite sua confirmação de senha"
          w={"100%"}
          type="password"
          onChange={handleInputChange("confirmarSenha", setValues)}
        />
        <Flex w={"100%"} justify="flex-end">
          <Button
            color={"rgba(21, 22, 25, 1)"}
            onClick={createUser(values, setLoading, colaboradorId, empresaId, projetoId, mutate)}
          >
            Salvar e acessar
          </Button>
        </Flex>
      </Flex>
    </Card>
  </Flex>
};
export default ConvidarEmpresaForm;
const isDevelopment = process.env.NODE_ENV === "development";
// prettier-ignore
/**
 * Create user
 * @param values - Values object
 * @param values.email - Values email in object
 * @param values.nome - Values name in object
 * @param values.senha - Values password in object
 * @param setLoading - Loading function hooks
 * @param colaboradorId - The id of user
 * @param empresaId - The id of company
 * @param projetoId - The id of project
 * @param mutate - mutate function
 * @returns - function
 */
function createUser(values: { email: string; nome: string; senha: string }, setLoading: (argument0: boolean) => void, colaboradorId: string | undefined, empresaId: string | undefined, projetoId: string | undefined, mutate: () => void) {
  return () => {
    setLoading(true);
    checkUser({ ...values, colaboradorId, empresaId, projetoId })
      .then((response) => {
        if (response && values && values.email && values.senha) {
          signInWith(values.email, values.senha)
            .then(() => {
              window.open(`${isDevelopment ? "http://localhost:5173" : "https://innovei.com.br"}/listaResponderQuestionario/${projetoId}`, "_self");
              setLoading(false);
              return true;
            })
            .catch((error: Error) => {
              captureException(error, true);
              return false;
            });
        }
        return false;
      })
      .catch((error: Error) => {
        captureException(error, true);
        window.open(isDevelopment ? "http://localhost:5173" : "https://innovei.com.br", "_self");
        setLoading(false);
        return false;
      });
    mutate()
    return false;
  }
}
/**
 * Handles input change for a specific field.
 * @param field - The name of the field being updated
 * @param setValues - The state setter function
 * @returns - function
 */
function handleInputChange(
  field: string,
  setValues: React.Dispatch<React.SetStateAction<{ email: string; nome: string; senha: string }>>
) {
  return (event: { target: { value: string } }) => {
    setValues((values: { email: string; nome: string; senha: string }) => ({
      ...values,
      [field]: event.target.value
    }));
  };
}
