// import Can from "@/components/can";
import { Button, Card, ColorSwatch, Group, Select, Space, Text, Textarea } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { showNotification } from "@mantine/notifications";
import { QueryObserverResult } from "@tanstack/react-query";
import React, { ChangeEvent, useCallback, useState } from "react";
import TaticasListCard from "./TaticasCard";
import { useUpdateJogadaDeInovacao } from "../home-de-inovacao-card-modal/useUpdateJogada";

interface Tatica {
  id: string | number;
  nome: string;
  descricao: string;
  nomeCategoria: string | undefined;
  nomeTipo: string | undefined;
  tipo?: string | undefined;
  categoria?: string | undefined;
  corVinculos?: string | undefined;
  movimentacao?: [];
}

interface MyState {
  status?: string;
  descricao?: string;
  data?: Date;
}

interface Option {
  value?: string;
  label?: string;
  color?: string;
}

// prettier-ignore
const changeSelect = (setValues: React.Dispatch<React.SetStateAction<MyState>>) => {
  return (value: string) => {
    setValues(values => ({ ...values, status: value }))
  }
}
// prettier-ignore
const changeArea = (setValues: React.Dispatch<React.SetStateAction<MyState>>) => {
  return (event: ChangeEvent<HTMLTextAreaElement>) => {
    setValues(values => ({ ...values, descricao: event.target.value }))
  }
}
// prettier-ignore
const changeDate = (setValues: React.Dispatch<React.SetStateAction<MyState>>) => {
  return (value: Date) => {
    setValues(values => ({ ...values, data: value }))
  }
}

// prettier-ignore
const FormMovimentarTatica: React.FC<{
  tatica: Tatica;
  jogada: TJogadaDeInovacao;
  refetch: () => Promise<QueryObserverResult>;
  onSuccess: () => void;
}> = ({ tatica, jogada, onSuccess, refetch }) => {
  const [values, setValues] = useState<MyState>({});
  const { mutate } = useUpdateJogadaDeInovacao();

  const saveMovimentacao = useCallback(() => {
    try {

      if (values?.status === undefined) {
        throw new Error("Status obrigatório");
      }

      if (values?.descricao === "") {
        throw new Error("Descrição obrigatória");
      }

      if (values?.data === undefined) {
        throw new Error("Data obrigatória");
      }

      const taticas = jogada?.taticas.map((item: Tatica) => {
        const movimentacao = item && item.movimentacao && item?.movimentacao?.length > 0 ? item.movimentacao : [];
        return item?.id === tatica.id ? { ...item, movimentacao: [...movimentacao, { ...values, id: generateRandomId() }] } : item
      })

      delete jogada?.refPath;
      mutate({ ...jogada, taticas })

      onSuccess();
      void refetch()
    } catch (error) {
      if (error instanceof Error) {
        showNotification({
          message: error.message,
          color: "red",
          autoClose: 2500
        });
      } else {
        showNotification({
          message: "Ocorreu um erro desconhecido.",
          color: "red",
          autoClose: 2500
        });
      }
    }
  }, [values, tatica, jogada]);

  return (
    <>
      <TaticasListCard jogada={tatica} />
      <Card radius="md" bg="rgb(245,248,254)">
        <Text>Informe a movimentação</Text>
        <Space h={"md"} />
        <Select
          label="Selecione o status"
          placeholder="Status"
          data={status}
          onChange={changeSelect(setValues)}
          renderOption={renderSelectOption}
        />
        <Space h={"md"} />
        <Textarea
          label="Descreva as ações realizadas nessa etapa"
          onChange={changeArea(setValues)}
        />
        <Space h={"md"} />
        <DateInput
          label="Selecione a para verificar o desenvolvimento da ação..."
          onChange={changeDate(setValues)}
        />
        <Space h={"md"} />
        <Group justify={"start"}>
          <Button variant="filled" color="rgba(8, 68, 244, 1)" onClick={saveMovimentacao}>
            Salvar
          </Button>
        </Group>
      </Card>
    </>
  );
};

export default FormMovimentarTatica;
// prettier-ignore
const renderSelectOption = ({ option }: { option: Option }) => {
  return <Group align={"center"} justify={"center"}>
    <ColorSwatch color={option?.color || ""} radius="sm" size={"20"} />
    <Text fw={500} fz="md">
      {option?.label}
    </Text>
  </Group>
}

const status = [
  { value: "1", label: "Em elaboração", color: "#ff810d" },
  { value: "2", label: "Em estudo", color: "#ffc726 " },
  { value: "3", label: "Em andamento", color: "#6288fb " },
  { value: "4", label: "Concluído", color: "#68c669 " }
];

const RANDOM_ID_LENGTH_STRING = 36;
const RANDOM_ID_LENGTH = 9;
const RANDOM_ID_START_INDEX = 2;

/**
 * Gera um ID aleatório.
 * @returns string O ID gerado.
 */
function generateRandomId() {
  return Math.random().toString(RANDOM_ID_LENGTH_STRING).slice(RANDOM_ID_START_INDEX, RANDOM_ID_LENGTH); // Usando slice em vez de substr
}
