import { ObjectSchema } from "yup";
import schema from "@/schema";

const MIN_NAME_LENGTH = 3;

const empresaSchema: ObjectSchema<TEnvioConvite> = schema.object({
  projeto: schema.string().optional().trim().required().defined().default(""),
  nome: schema.string().trim().min(MIN_NAME_LENGTH).required().defined().default("").label("Nome"),
  email: schema.string().trim().email().required().defined().default("").label("E-mail do responsável pelo projeto"),
  cnpj: schema.string().trim().cnpj().required().defined().default("").label("CNPJ")
});

export default empresaSchema;
