import { subject } from "@casl/ability";
import { doc, collection, FirestoreDataConverter, Timestamp, query, where, getDocs } from "firebase/firestore";
import { getQuestionarioCollectionReference } from "../grupos-diagnostico/firestore";

type TMediaGrupoDocument = Omit<TMediaGrupo, "createdAt" | "updatedAt"> & {
  createdAt: Timestamp;
  updatedAt?: Timestamp;
  deletedAt: Timestamp | null;
};

const mediaGrupoConverter: FirestoreDataConverter<TMediaGrupo> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const { createdAt, updatedAt, ...document } = snap.data() as TMediaGrupoDocument;

    const data: TMediaGrupo = {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      refPath: snap.ref.path
    };

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }
    return subject("TMediaGrupo", data);
  }
};

/**
 * Get regioes collection reference from empresaId
 * @param empresaId - Empresa id to get the regioes collection reference
 * @param questionarioId - MediaGTMediaGrupo id to get the regioes collection reference
 * @returns - MediaGrupos collection reference
 */
export function getMediaGruposCollectionReference(empresaId: TEmpresa["id"], questionarioId: TQuestionario["id"]) {
  const questionarioCollection = getQuestionarioCollectionReference(empresaId);
  const questionarioDocument = doc(questionarioCollection, questionarioId);
  return collection(questionarioDocument, "mediaGrupos").withConverter(mediaGrupoConverter);
}

/**
 * Get mediaGrupo document reference from empresaId
 * @param activeEmpresaId - Active Empresa id to get the mediaGrupo document reference
 * @param questionarioId - Questionario id to get the mediaGrupo document reference
 * @param empresaId - Empresa id to get the mediaGrupo document reference
 * @returns - MediaGrupo document reference
 */
export function getMediaGrupoByEmpresaId(
  activeEmpresaId: TEmpresa["id"],
  questionarioId: TQuestionario["id"],
  empresaId: TEmpresa["id"]
) {
  const mediaGruposCollectionReference = getMediaGruposCollectionReference(activeEmpresaId || "", questionarioId);
  const queryResult = query(mediaGruposCollectionReference, where("empresaId", "==", empresaId));
  return getDocs(queryResult);
}
