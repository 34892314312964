import useUserStore from "@/modules/users/store";

const AUTHORIZATION_LEVELS = [
  { value: "1", label: "ADMIN" },
  { value: "2", label: "MEMBER" },
  { value: "3", label: "TEAM" },
  { value: "4", label: "MASTER" },
  { value: "5", label: "COLLABORATOR" }
] as const;

export const useGetAuthorizations = (authorizationType: string) => {
  const { colaborador, activeEmpresaId, autorizacoes: authorizations } = useUserStore();

  if (!colaborador || !activeEmpresaId || !authorizations) {
    throw new Error("Missing authorizations");
  }

  const userAuthorizationLevel = AUTHORIZATION_LEVELS.find((item) => item.label === colaborador?.role);
  const userAuthorizations = authorizations?.filter((item) => item.tipo === userAuthorizationLevel?.value) ?? [];

  const { criacao, editar, remover, leitura } =
    userAuthorizations.find((item) => item.tipoAutorizacao === authorizationType) ?? {};

  return {
    read: !!leitura,
    create: !!criacao,
    update: !!editar,
    remove: !!remover
  };
};
