import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getRepostasArrayEmpresa, QUERY_KEY } from "@/modules/responder-questionario/firestore";
import useUserStore from "@/modules/users/store";

type TGetRespostaResponse = Awaited<ReturnType<typeof getRepostasArrayEmpresa>>;

/**
 * Get resposta from url params
 * @param route - id do questionario
 * @param route.id - id do questionario
 * @param route.idEmpresa - id da empresa
 * @returns - resposta or null if not found
 */
function useGetResposta({
  id,
  idEmpresa
}: {
  id: TQuestionario["id"] | null | undefined;
  idEmpresa: TEmpresa["id"] | undefined;
}) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  return useQuery<TGetRespostaResponse, FirestoreError, TResposta[] | null>({
    queryKey: [QUERY_KEY, id, activeEmpresaId, idEmpresa],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!id) {
        throw new Error("Ideia não selecionada");
      }

      return getRepostasArrayEmpresa(idEmpresa ?? activeEmpresaId, id, activeEmpresaId);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((document_) => document_.data());
    },
    enabled: !!id && !!activeEmpresaId
  });
}

export default useGetResposta;
