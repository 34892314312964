import { ObjectSchema } from "yup";
import schema from "@/schema";

export const estadosData: { label: string; value: TEstado }[] = [
  { value: "AC", label: "Acre" },
  { value: "AL", label: "Alagoas" },
  { value: "AP", label: "Amapá" },
  { value: "AM", label: "Amazonas" },
  { value: "BA", label: "Bahia" },
  { value: "CE", label: "Ceará" },
  { value: "DF", label: "Distrito Federal" },
  { value: "ES", label: "Espírito Santo" },
  { value: "GO", label: "Goías" },
  { value: "MA", label: "Maranhão" },
  { value: "MT", label: "Mato Grosso" },
  { value: "MS", label: "Mato Grosso do Sul" },
  { value: "MG", label: "Minas Gerais" },
  { value: "PA", label: "Pará" },
  { value: "PB", label: "Paraíba" },
  { value: "PR", label: "Paraná" },
  { value: "PE", label: "Pernambuco" },
  { value: "PI", label: "Piauí" },
  { value: "RJ", label: "Rio de Janeiro" },
  { value: "RN", label: "Rio Grande do Norte" },
  { value: "RS", label: "Rio Grande do Sul" },
  { value: "RO", label: "Rondônia" },
  { value: "RR", label: "Roraíma" },
  { value: "SC", label: "Santa Catarina" },
  { value: "SP", label: "São Paulo" },
  { value: "SE", label: "Sergipe" },
  { value: "TO", label: "Tocantins" }
];

const enderecoSchema: ObjectSchema<TEmpresa["endereco"]> = schema.object({
  cep: schema.string().trim().required().defined().default("").label("CEP"),
  logradouro: schema.string().trim().required().defined().default("").label("Logradouro"),
  numero: schema.string().trim().required().defined().default("").label("Número"),
  complemento: schema.string().trim().nullable().defined().default(null).label("Complemento"),
  bairro: schema.string().trim().required().defined().default("").label("Bairro"),
  estado: schema
    .mixed<TEstado>()
    .oneOf(estadosData.map((estado) => estado.value))
    .required()
    .defined()
    .label("Estado"),
  cidade: schema.string().trim().required().defined().default("").label("Cidade")
});

export default enderecoSchema;
