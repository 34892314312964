/* eslint-disable promise/always-return */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { Button, Flex, FocusTrap, Group, Text } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import useUserStore from "@/modules/users/store";
import useImportIdea from "./useImportIdea";
import IdeiaForm from "../../components/ideia-form";
import { refetchIdeasTable } from "../../components/ideias-table/IdeiasTable";
import { TIdeiaForm } from "../../firestore";

const IdeiaFormModal = ({ context, innerProps, id }: ContextModalProps<{ item: TIdeiaForm }>) => {
  const { importIdeas } = useImportIdea();
  const { colaborador } = useUserStore();

  const { item: ideia } = innerProps;
  /**
   * Handle add ignore area success
   */
  function onSuccess() {
    context.closeModal(id);
    // Executa a fun o de refetch da tabela de ideias, se ela existir
    if (refetchIdeasTable) {
      refetchIdeasTable();
    }
  }

  /**
   * Handle import ideas
   */
  function onImport() {
    importIdeas()
      .then(() => {
        showNotification({
          message: "Ideias importadas com sucesso!",
          color: "green",
          autoClose: 2500
        });
      })
      .catch(() => {
        showNotification({
          message: "Houve um problema importando as ideias.",
          color: "red",
          autoClose: 2500
        });
      });
  }

  return (
    <FocusTrap active={true}>
      <Flex direction="column">
        <Group justify="space-between" align="center">
          <Text fw="bold" mb="md" style={{ margin: 0 }}>
            Cadastro de ideia
          </Text>
          {colaborador?.role === "ADMIN" && (
            <Button onClick={onImport} color="blue">
              Importar ideias
            </Button>
          )}
        </Group>
        <IdeiaForm ideia={ideia} onSuccess={onSuccess} />
      </Flex>
    </FocusTrap>
  );
};

export default IdeiaFormModal;
