import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { QUERY_KEY } from "@/modules/projetos/components/projetos-table/ProjetosTable";
import { getProjetosItem } from "@/modules/projetos/firestore";
import useUserStore from "@/modules/users/store";

type TGetProjetoResponse = Awaited<ReturnType<typeof getProjetosItem>>;

/**
 * Get projeto from url params
 * @param idProjeto - id do projeto
 * @param idProjeto.idProjeto - id do projeto
 * @returns - projeto or null if not found
 */
function useGetProjetoItem({ idProjeto }: { idProjeto: TProjeto["id"] | undefined }) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  return useQuery<TGetProjetoResponse, FirestoreError, TProjeto | null>({
    queryKey: [QUERY_KEY, idProjeto, activeEmpresaId],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!idProjeto) {
        throw new Error("Ideia não selecionada");
      }

      return getProjetosItem(activeEmpresaId, idProjeto);
    },
    select(dataResponse) {
      if (!dataResponse.exists()) {
        return null;
      }

      return dataResponse.data();
    },
    enabled: !!idProjeto && !!activeEmpresaId
  });
}

export default useGetProjetoItem;
