import { Flex, Group, Space, Text } from "@mantine/core";
import useGetQuestionarios from "@/modules/questionario-de-inovacao/components/projeto-item/useGetQuestionarios";
import useGetConvite from "./useGetEmpresasConvidadas";
import useGetQuestionariosRespondidos from "./useGetQuestionariosRespondidos";
import useGetEmpresaById from "../ranking-list/useGetEmpresas";
// import { useParams } from "react-router-dom";
// prettier-ignore
const QuestionariosRespondidos = ({ idProjeto, idEmpresa, size, fw }: { idProjeto: TProjeto["id"] | undefined, idEmpresa: TEmpresa["id"] | undefined, size: string, fw: number }) => {
  const { data: empresa } = useGetEmpresaById(idEmpresa);
  const newEmpresa = formatEmpresa(empresa, idProjeto);
  const { data } = useGetQuestionarios({ id: idProjeto });
  const { data: respondidos } = useGetQuestionariosRespondidos(idEmpresa, data?.map(item => item?.id))
  const space = "space-between";
  const { data: convites } = useGetConvite(idEmpresa, idProjeto, false);
  const formateddValue = respondidos?.filter(item => [...convites || [], newEmpresa]?.concat([newEmpresa])?.some(row => row.idEmpresaColaboradorConvidado === item.idEmpresa));

  return (
    <Flex direction="column">
      <Text size="xs">Questionários respondidos</Text>
      <Space h="md" />
      <Group justify={space}>
        <Text size={size} fw={fw}>
          {formateddValue?.length || 0}
        </Text>
      </Group>
    </Flex>
  );
};

export default QuestionariosRespondidos;

// prettier-ignore
const formatEmpresa = (empresa: TEmpresa | null | undefined, idProjeto: string | null | undefined) => {
  return {
    convite: false,
    createdAt: new Date(),
    createdBy: "",
    id: empresa?.id,
    idColaboradorConvidado: "",
    idEmpresa: empresa?.id,
    idEmpresaColaboradorConvidado: empresa?.id,
    idProjeto: idProjeto,
    idQuestionario: ""
  } as TConvite;
}
