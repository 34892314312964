import { Space, Card, Grid, Flex, Text, Anchor, Checkbox } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import { captureException } from "@/services/log";
import { dataAutorizacoes } from "@/src/routes/empresa-page/EmpresaPage";
import { formatDate } from "@/utils/date";
import { updateEvidencia } from "../firestore";

type TCancelResponse = Awaited<ReturnType<typeof updateEvidencia>>;

/**
 * Cancel evidencia mutation hook
 * @param evidencia - The evidencia.
 * @returns Cancel evidencia mutation hook
 */
function useCancelEvidenciaMutation(evidencia: TEvidencia) {
  return useMutation<TCancelResponse, Error>({
    mutationFn: () => {
      evidencia.conferida = !evidencia.conferida;
      return updateEvidencia(evidencia || "", {
        ...evidencia,
        conferida: evidencia.conferida
      });
    },
    onError(error) {
      captureException(error, true);
    }
  });
}

type EvidenciaEnviadaCardProperties = {
  evidencia: TEvidencia;
};

/**
 * Card para exibir o status da inscrição do colaborador em um questionário.
 * @param root0 Props
 * @param root0.evidencia Evidencia
 * @returns Component
 */
function EvidenciaEnviadaCard({ evidencia }: EvidenciaEnviadaCardProperties) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const uid = getCurrentUser()?.uid;
  const { data: userColaborador } = useGetColaboradorById(activeEmpresaId || "", uid || "");
  const activeAutorizacao = useUserStore((store) => store.autorizacoes);
  const autorizacao = dataAutorizacoes.find((item) => item.label === userColaborador?.role);
  const formatAutorizacoes = activeAutorizacao?.filter((item) => item.tipo === autorizacao?.value);
  const getAuthorization = (tipoAutorizacao: string) =>
    formatAutorizacoes?.find((item) => item.tipoAutorizacao === tipoAutorizacao);
  const { mutate: cancelEvidencia } = useCancelEvidenciaMutation(
    evidencia || {
      id: "",
      idEmpresa: "",
      idProjeto: "",
      idQuestionario: "",
      idEmpresaColaborador: "",
      link: "",
      externo: false,
      conferida: false
    }
  );
  const isSmMarches = useMediaQuery("(max-width: 48em)");

  const handleCheckboxChange = useCallback(() => {
    cancelEvidencia();
  }, [cancelEvidencia]);

  return (
    <>
      <Space h="md" />
      <Card withBorder shadow="md" style={{ flex: 1, width: isSmMarches ? "1000px" : "100%" }}>
        <Grid align="center">
          {/* Coluna que ocupa o espaço livre */}
          <Grid.Col style={{ flex: 1 }}>
            <Flex mih={50} gap="xs" justify="flex-start" align="flex-start" direction="column" wrap="wrap">
              <Text fw={300} fz="xs">
                Nome do arquivo
              </Text>
              <Anchor
                href={evidencia?.link}
                target="_blank"
                rel="noopener noreferrer"
                style={{ wordBreak: "break-all" }}
              >
                {evidencia?.nomeArquivo}
              </Anchor>
            </Flex>
          </Grid.Col>
          {/* Coluna de data com alinhamento à direita */}
          <Grid.Col span={3}>
            <Flex mih={50} gap="xs" justify="flex-end" align="flex-start" direction="column" wrap="wrap">
              <Text fw={300} fz="xs">
                Data do envio
              </Text>
              <Text fw={500} fz="md">
                {evidencia && formatDate(evidencia.createdAt)}
              </Text>
            </Flex>
          </Grid.Col>
          {/* Coluna do botão, também alinhada à direita */}
          <Grid.Col span={3}>
            <Flex mih={50} gap="xs" justify="flex-end" align="flex-start" direction="column" wrap="wrap">
              <Flex align="center" gap="xs">
                <Checkbox
                  disabled={getAuthorization("25")?.editar !== true}
                  checked={evidencia?.conferida}
                  onChange={handleCheckboxChange}
                  label="Conferida"
                />
              </Flex>
            </Flex>
          </Grid.Col>
        </Grid>
      </Card>
    </>
  );
}

export default EvidenciaEnviadaCard;
