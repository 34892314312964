import { Flex, Group, Space, Text } from "@mantine/core";
import useGetConvite from "./useGetEmpresasConvidadas";
import useGetEmpresaById from "../ranking-list/useGetEmpresas";
// import { useParams } from "react-router-dom";
// prettier-ignore
const EmpresasParticipantes = ({ idProjeto, idEmpresa, size, fw }: { idProjeto: TProjeto["id"] | undefined, idEmpresa: TEmpresa["id"] | undefined, size: string, fw: number }) => {
  const { data: empresa } = useGetEmpresaById(idEmpresa);
  const newEmpresa = formatEmpresa(empresa, idProjeto);

  const { data } = useGetConvite(idEmpresa, idProjeto, false);
  const space = "space-between";

  return (
    <Flex direction="column">
      <Text size="xs">Empresas participantes</Text>
      <Space h="md" />
      <Group justify={space}>
        <Text size={size} fw={fw}>
          {data && [...data || [], newEmpresa].length > 0 ? [...data || [], newEmpresa].length : 0}
        </Text>
      </Group>
    </Flex>
  );
};

export default EmpresasParticipantes;

// prettier-ignore
const formatEmpresa = (empresa: TEmpresa | null | undefined, idProjeto: string | null | undefined) => {
  return {
    convite: false,
    createdAt: new Date(),
    createdBy: "",
    id: empresa?.id,
    idColaboradorConvidado: "",
    idEmpresa: empresa?.id,
    idEmpresaColaboradorConvidado: empresa?.id,
    idProjeto: idProjeto,
    idQuestionario: ""
  } as TConvite;
}
