import { Avatar, Card, Flex, Group, Text } from "@mantine/core";
import { useParams } from "react-router-dom";
import useGetEmpresaById from "@/modules/analisar-resposta/components/ranking-list/useGetEmpresas";

const CardNomeEmpresa = () => {
  const { idEmpresa } = useParams<{ idEmpresa: string }>();
  const { data } = useGetEmpresaById(idEmpresa);

  return (
    <Card withBorder shadow="md" style={{ flex: 1, height: 140, marginBottom: 15 }}>
      <Group style={{ flex: 1, height: 140 }}>
        <Avatar variant="filled" radius="xl" size="xl" src="" ml={10}>
          {data?.nomeFantasia.charAt(0)}
        </Avatar>
        <Flex direction="column">
          <Text size="xl" fw={700}>
            {data?.nomeFantasia}
          </Text>
          <Text size="xs">Segmento {data?.categorias}</Text>
        </Flex>
      </Group>
    </Card>
  );
};

export default CardNomeEmpresa;
