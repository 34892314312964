import { UseFormReturnType } from "@mantine/form";
import { SchemaDescription, SchemaFieldDescription, SchemaObjectDescription } from "yup";

/**
 * Extract properties from form schema to pass to input
 * @param schema - Schema to extract properties
 * @param field - Field to extract properties
 * @returns - Properties extracted from schema
 */
export function extractBaseInputPropertiesFromFormSchema<T>(
  schema: SchemaObjectDescription,
  field: Parameters<UseFormReturnType<T>["getInputProps"]>[0]
) {
  // eslint-disable-next-line security/detect-object-injection
  const fieldInSchema = schema.fields[field] as SchemaFieldDescription | SchemaObjectDescription["fields"];
  const fieldDescritor = fieldInSchema as SchemaDescription;

  return {
    label: fieldDescritor.label,
    ...fieldDescritor.meta,
    "data-testid": `field-${String(field)}`
  };
}

/**
 * Extract properties from form schema to pass to input
 * @param schema - Schema to extract properties
 * @param field - Field to extract properties
 * @returns - Properties extracted from schema
 */
export function extractTextInputPropertiesFromFormSchema<T>(
  schema: SchemaObjectDescription,
  field: Parameters<UseFormReturnType<T>["getInputProps"]>[0]
) {
  // eslint-disable-next-line security/detect-object-injection
  const fieldInSchema = schema.fields[field] as SchemaFieldDescription | SchemaObjectDescription["fields"];
  const fieldDescritor = fieldInSchema as SchemaDescription;

  return {
    ...extractBaseInputPropertiesFromFormSchema(schema, field),
    ...(fieldDescritor.nullable === false && !fieldDescritor.optional ? { withAsterisk: true } : {})
  };
}

/**
 * Extract test value from schema
 * @param schema - Schema to extract properties
 * @param field - Field to extract properties
 * @param testParameterName - Test parameter name to extract
 * @returns - Test value extracted from schema
 */
export function extractTestValueFromSchema<T>(
  schema: SchemaObjectDescription,
  field: Parameters<UseFormReturnType<T>["getInputProps"]>[0],
  testParameterName: string
) {
  // eslint-disable-next-line security/detect-object-injection
  const fieldInSchema = schema.fields[field] as SchemaFieldDescription | SchemaObjectDescription["fields"];
  const fieldDescritor = fieldInSchema as SchemaDescription;
  const testDescriptor = fieldDescritor.tests.find((test) => test.name === testParameterName);

  if (testDescriptor && testDescriptor.params) {
    // eslint-disable-next-line security/detect-object-injection
    return testDescriptor.params[testParameterName];
  }

  return undefined;
}

/**
 * Extract yup error
 * @param yupError - Yup error to extract
 * @returns - Extracted error
 */
export function extractYupError(yupError: YupValidationResult) {
  const results: Record<string, string> = {};

  for (const error of yupError.inner) {
    results[error.path.replaceAll("[", ".").replaceAll("]", "")] = error.message;
  }

  return results;
}
