import { Text } from "@mantine/core";
import useGetCategoriaItem from "./useGetCategoriaFromUrl";

interface Properties {
  id: string;
}
// prettier-ignore
const JogadasCategoriaLabel = (properties: Properties) => {
  const { data } = useGetCategoriaItem(properties?.id);

  return <Text tt="capitalize" fz="sm" fw={400} color={"rgba(21, 22, 25, 1)"}>
    {data?.nome}
  </Text>
};

export default JogadasCategoriaLabel;
