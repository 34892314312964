import { Text, FocusTrap } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import PerguntaForm from "../../components/perguntas-form";
import { TPerguntaForm } from "../../firestore";

const PerguntaFormModal = ({ context, innerProps, id }: ContextModalProps<{ item: TPerguntaForm }>) => {
  const { item: pergunta } = innerProps;
  /**
   * Handle add ignore area success
   */
  function onSuccess() {
    context.closeModal(id);
  }

  return (
    <FocusTrap active={true}>
      <Text fw="bold" mb="md">
        Cadastro de pergunta
      </Text>
      <PerguntaForm pergunta={pergunta} onSuccess={onSuccess} />
    </FocusTrap>
  );
};

export default PerguntaFormModal;
