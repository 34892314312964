import { showNotification } from "@mantine/notifications";
import { Timestamp } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { useSendResposta } from "./useSendResposta";
import { QuestionarioPage } from "../components/v2/QuestionarioContent";

type Properties = {
  questionario: TQuestionario;
  pages: QuestionarioPage[];
  perguntas: TPergunta[];
  respostas: TResposta["perguntas"];
  resposta: TResposta | undefined;
  empresaId: TEmpresa["id"];
  empresaRespondenteId: TEmpresa["id"];
  userId: string;
};

const TOTAL_PERCENTAGE = 100;

/**
 * useQuestionarioStepper
 * @param root0 - properties
 * @param root0.respostas - respostas
 * @param root0.perguntas - perguntas
 * @param root0.questionario - questionario
 * @param root0.resposta - resposta
 * @param root0.pages - pages
 * @param root0.userId - userId
 * @param root0.empresaId - empresaId
 * @param root0.empresaRespondenteId - empresaRespondenteId
 * @returns step
 */
function useQuestionarioStepper({
  questionario,
  pages,
  perguntas,
  respostas,
  resposta,
  userId,
  empresaId,
  empresaRespondenteId
}: Properties) {
  const finalPageIndex = pages.length - 1;

  const [step, setStep] = useState(0);
  const [pause, setPause] = useState(false);

  const { activeEmpresaId } = useUserStore();

  const respostasUser = useMemo(
    () => ({
      id: resposta?.id ?? "",
      createdAt: Timestamp.now(),
      deletedAt: null,
      createdBy: questionario.createdBy || "",
      pausado: pause,
      stepPausado: step,
      idRespondente: userId,
      idEmpresa: empresaRespondenteId,
      idQuestionario: questionario.id,
      perguntas: respostas
    }),
    [pause, step, resposta, questionario, respostas, activeEmpresaId, userId]
  );

  const navigate = useNavigate();

  const { mutate } = useSendResposta(empresaId, respostasUser, {
    onSuccess() {
      showNotification({
        color: "green",
        message: `Questionário ${pause ? "pausado" : "respondido"} com sucesso!`,
        autoClose: 3500
      });
      navigate("/listaResponderQuestionario/0");
    },
    onError(error) {
      captureException(error, true);
    }
  });

  const isQuestionarioAlreadyFinished = useMemo(() => resposta?.pausado === false, [resposta]);

  useEffect(() => {
    if (resposta && resposta.pausado && resposta.stepPausado) {
      setStep(resposta.stepPausado);
    }
  }, [perguntas.length]);

  const isFirstStep = step === 0;
  const isFinalStep = step === finalPageIndex;
  const isPaused = pause;

  const canPause = !resposta || resposta.pausado;

  const canFinish = useMemo(() => {
    const obligatoryQuestions = perguntas?.filter((item) => item.obrigatoria);
    const answers = respostas.filter((r) => r.resposta !== false);
    return obligatoryQuestions?.every((q) => answers.some((a) => a.id === q.id));
  }, [perguntas, respostas, resposta]);

  const handlePrevious = () => {
    if (!isFirstStep) {
      const newStep = step === finalPageIndex ? 0 : step - 1;
      setStep(newStep);
    }
  };

  const handleNext = () => {
    if (!isFinalStep) {
      const nextStep = step + 1;
      setStep(nextStep);
    }
  };

  const handlePause = () => {
    if (!canPause) {
      return;
    }

    setPause(true);
    mutate();
  };

  const handleFinish = () => {
    if (!canFinish) {
      showNotification({
        color: "red",
        message: `Existem perguntas obrigatórias que não foram respondidas!`,
        autoClose: 3500
      });
      return;
    }

    if (isQuestionarioAlreadyFinished) {
      return navigate(`/questionariosColaborador/${questionario.id}`);
    }

    setPause(false);
    mutate();
  };

  const getCurrentPage = () => pages.at(step);

  const stepGap = useMemo(() => TOTAL_PERCENTAGE / (pages.length || 1), [pages]);

  return {
    step,
    handlePrevious,
    handleNext,
    handlePause,
    handleFinish,
    getCurrentPage,
    isFirstStep,
    isFinalStep,
    isPaused,
    isQuestionarioAlreadyFinished,
    canFinish,
    canPause,
    stepGap
  };
}

export default useQuestionarioStepper;
