import { Anchor, Box, LoadingOverlay, Space } from "@mantine/core";
import { useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import BreadcrumbsNavigation from "@/components/breadcrumbs-navigation";
import CardNomeEmpresa from "@/modules/etapa-processo/components/cards/CardNomeEmpresa";
import CardPremio from "@/modules/etapa-processo/components/cards/CardPremio";
import CardResultados from "@/modules/etapa-processo/components/cards/CardResultados";
import EstrategiaList from "@/modules/etapa-processo/components/estrategia-list/EstrategiaList";
import { useAcessoQuestionario } from "@/modules/etapa-processo/components/estrategia-list/useAcessoQuestionario";
import useQueryGroup from "@/modules/etapa-processo/components/estrategia-list/useGetGroup";
import ExportExcel from "@/modules/etapa-processo/components/export-excel/ExportExcel";
import { getCurrentUser } from "@/services/auth";

const QuestionarioInfoPage = () => {
  const { data, isFetching } = useQueryGroup();
  const { id, idProjeto, idEmpresa } = useParams<{ id: string; idProjeto: string; idEmpresa: string }>();
  const currentUserId = getCurrentUser()?.uid;
  const grupos = data ? data.filter((grupo) => grupo.radar) : [];
  const { mutate } = useAcessoQuestionario({
    idQuestionario: id || "",
    idProjeto: idProjeto || "",
    updatedAt: new Date(),
    deletedAt: null,
    createdBy: currentUserId,
    updatedBy: currentUserId,
    idEmpresa: idEmpresa || ""
  });

  useEffect(() => {
    mutate();
  }, [id]);

  if (isFetching) {
    return (
      <Box pos="relative">
        <LoadingOverlay visible={isFetching} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
      </Box>
    );
  }

  return (
    <>
      <BreadcrumbsNavigation notHome={true}>
        <Anchor component={NavLink} to="/diagnostico">
          Diagnóstico
        </Anchor>
      </BreadcrumbsNavigation>
      <ExportExcel grupos={grupos} />
      <Space h={"md"} />
      <CardNomeEmpresa />
      <Space h={"md"} />
      <CardPremio />
      <Space h={"md"} />
      <CardResultados grupos={grupos} />
      <Space h={"md"} />
      {grupos.map((item) => (
        <EstrategiaList key={item.id} grupo={item} />
      ))}
    </>
  );
};

QuestionarioInfoPage.displayName = "QuestionarioInfoPage";

export default QuestionarioInfoPage;
