import { Tooltip, Avatar } from "@mantine/core";
import { extractInitialsFromText } from "@/utils/text";

const DEFAULT_MAX_VISIBLE = 3;
// prettier-ignore
const AvatarsColaboradores = ({
  colaboradores,
  maxVisible = DEFAULT_MAX_VISIBLE
}: {
  colaboradores: TColaborador[];
  maxVisible?: number;
}) => {
  const firstsColaboradores = colaboradores.slice(0, maxVisible);
  const remainingColaboradores = colaboradores.slice(maxVisible);

  return (
    <Tooltip.Group openDelay={300} closeDelay={100}>
      <Avatar.Group>
        {firstsColaboradores.map((participante) => (
          <Tooltip label={participante.nome || participante.email} withArrow key={participante.id}>
            <Avatar src={participante && participante.image && participante?.image[0]?.path || ""} key={participante.id} alt={participante.nome || participante.email}>
              {extractInitialsFromText(participante.nome || participante.email)}
            </Avatar>
          </Tooltip>
        ))}
        {remainingColaboradores.length > 0 && <Avatar>+{remainingColaboradores.length}</Avatar>}
      </Avatar.Group>
    </Tooltip.Group>
  );
};

export default AvatarsColaboradores;
