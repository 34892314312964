import { Alert, Card, Center, Stack, Text } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { QueryKey } from "@tanstack/react-query";
import { ColumnFiltersState, Table as ReactTable } from "@tanstack/react-table";
import { forwardRef, useImperativeHandle } from "react";
import NoData from "@/components/no-data";
import Table from "@/components/table";
import ResetTableButton from "@/components/table/ResetTableButton";
import { THandler } from "@/components/table/Table";
import TablePagination from "@/components/table/TablePagination";
import useQueryTable from "@/components/table/useQueryTable";
import { getGruposIdsByProjeto } from "@/modules/grupos-diagnostico/firestore";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import projectColumns from "./Columns";
import { DEFAULT_PAGINATION, DEFAULT_SORTING, getPerguntas } from "../../firestore";

export const QUERY_KEY: string = "perguntas-list" as const;

const PerguntasTable = forwardRef<THandler<TPergunta>>((_properties, reference) => {
  const uid = getCurrentUser()?.uid;
  const { activeEmpresaId, activeProjeto } = useUserStore();

  const { table, data, isLoading, error, filters, refetch } = useQueryTable<TPergunta>({
    queryKey: [QUERY_KEY, activeEmpresaId, activeProjeto],
    enabled: !!uid && !!activeEmpresaId,
    subjectType: "TPergunta",
    columns: projectColumns,
    initialState: {
      columnFilters: [],
      globalFilter: "",
      pagination: DEFAULT_PAGINATION,
      sorting: DEFAULT_SORTING
    },
    queryFunction: async ({ sorting, filters, pagination, paginationCursors }) => {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!activeProjeto) {
        throw new Error("Projeto não selecionado");
      }

      const gruposIds = await getGruposIdsByProjeto(activeEmpresaId, activeProjeto);
      const grupoFilters: ColumnFiltersState = [{ id: "grupo", value: ["in", gruposIds] }];
      if (filters) {
        grupoFilters.push(...filters);
      }

      return await getPerguntas(activeEmpresaId, {
        sorting,
        filters: grupoFilters,
        pagination,
        paginationCursors
      });
    }
  });

  useImperativeHandle<THandler<TPergunta>, THandler<TPergunta>>(
    reference,
    () => {
      return {
        table,
        data,
        isLoading,
        error,
        filters
      };
    },
    [filters, data, error, isLoading, table]
  );

  /**
   * Handles the close of the error alert.
   */
  function handleErrorClose() {
    void refetch();
  }

  if (error) {
    return (
      <Alert
        icon={<IconAlertCircle size="1rem" />}
        title="Erro ao buscar perguntas"
        color="red"
        withCloseButton
        onClose={handleErrorClose}
      >
        {error.message}
      </Alert>
    );
  }

  const isEmpty = !isLoading && data?.entries.length === 0;

  return (
    <>
      <Card.Section withBorder>
        <Table table={table} isLoading={isLoading} />
        {isEmpty && !isLoading && (
          <Center m="lg">
            <NoData m="md">
              <Stack gap="xs">
                <Text c="dimmed">Nenhuma Pergunta Encontrado!</Text>
                <ResetTableButton table={table as ReactTable<unknown>} queryKey={QUERY_KEY as unknown as QueryKey}>
                  Limpar filtros
                </ResetTableButton>
              </Stack>
            </NoData>
          </Center>
        )}
      </Card.Section>
      {reference && "current" in reference && reference.current !== null ? (
        <Card.Section withBorder>
          <TablePagination tableHandlers={reference.current} />
        </Card.Section>
      ) : null}
    </>
  );
});

PerguntasTable.displayName = "PerguntasTable";

export default PerguntasTable;
