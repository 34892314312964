import { Button, Group, NumberInput, Text, ThemeIcon } from "@mantine/core";
import { IconCheck, IconCoin } from "@tabler/icons-react";
import useIdeiaPatrocinioForm from "./useIdeiaPatrocinioForm";

interface IdeaPatrocinioFormProperties {
  ideiaActivity?: TIdeiaActivityPerColaborador | null;
  ideia: TIdeia;
  regulamento: TRegulamentoIdeias;
}

const IdeaPatrocinioForm = ({ ideiaActivity, ideia, regulamento }: IdeaPatrocinioFormProperties) => {
  const qtdMoedasGastasNestaIdeia = ideiaActivity?.moedas || 0;

  const { onSubmit, getInputProps, values, isLoading } = useIdeiaPatrocinioForm({ ideia, regulamento, ideiaActivity });

  const { moedas } = values;
  const ideiaSemPatrocinio = qtdMoedasGastasNestaIdeia === 0;
  const alterouQuantidade = moedas !== qtdMoedasGastasNestaIdeia;

  return (
    <form onSubmit={onSubmit}>
      <Group justify="space-between">
        {ideia.status === "NOVA_IDEIA" ? (
          <Group gap="xs">
            <Text c="dark" size="sm">
              Informe a quantidade de moedas para patrocinar a ideia
            </Text>
            <NumberInput
              size="sm"
              suffix={` moedas`}
              allowNegative={false}
              clampBehavior="strict"
              leftSection={
                <ThemeIcon variant="light" color="yellow" size="sm">
                  <IconCoin />
                </ThemeIcon>
              }
              disabled={isLoading}
              {...getInputProps("moedas")}
            />
          </Group>
        ) : (
          <div></div>
        )}

        {!alterouQuantidade && !ideiaSemPatrocinio ? (
          <Group gap={2}>
            <ThemeIcon variant="transparent" color="green" size="sm">
              <IconCheck />
            </ThemeIcon>

            <Text c="green">Patrocínio realizado</Text>
          </Group>
        ) : (
          <Button type="submit" loading={isLoading}>
            {ideiaSemPatrocinio ? "Patrocinar" : "Modificar Patrocínio"}
          </Button>
        )}
      </Group>
    </form>
  );
};

export default IdeaPatrocinioForm;
