import { ThemeIcon, ThemeIconProps } from "@mantine/core";
import { IconCaretUpFilled, IconCaretDownFilled, IconEqual } from "@tabler/icons-react";

const RankingTrendIcon = ({
  ranking,
  ...themeIconProperties
}: { ranking: TRanking | undefined } & Omit<ThemeIconProps, "color">) => {
  if (!ranking) {
    return null;
  }

  if (ranking.fromLastUpdate === "INCREASED") {
    return (
      <ThemeIcon variant="transparent" color="green" size={14} {...themeIconProperties}>
        <IconCaretUpFilled />
      </ThemeIcon>
    );
  }

  if (ranking.fromLastUpdate === "DECREASED") {
    return (
      <ThemeIcon variant="transparent" color="red" size={14} {...themeIconProperties}>
        <IconCaretDownFilled />
      </ThemeIcon>
    );
  }

  return (
    <ThemeIcon variant="transparent" color="blue" size={14} {...themeIconProperties}>
      <IconEqual />
    </ThemeIcon>
  );
};

export default RankingTrendIcon;
