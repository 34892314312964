import { useForm, yupResolver, TransformedValues } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { DESAFIOS_COLLECTION_KEY } from "@/modules/desafios/firestore";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { TMovimentacaoForm, addMovimentacao } from "../firestore";
import movimentacaoSchema from "../schema/ideia-movimentacao-schema";

type TAddMovimentacaoResponse = Awaited<ReturnType<typeof addMovimentacao>>;

/**
 * Hook to handle the desafio movimentcao form
 * @param desafio - desafio to add the movimentcao
 * @returns - Form props
 */
function useDesafioMovimentacaoForm(desafio: TDesafio) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  const queryClient = useQueryClient();

  const form = useForm<TMovimentacaoForm>({
    initialValues: {
      ...(movimentacaoSchema.getDefault() as TMovimentacaoForm),
      observacoes: "",
      marcos: [],
      status: null
    },
    transformValues: (values) =>
      movimentacaoSchema.cast(values, {
        stripUnknown: true,
        context: {
          desafio: desafio.id
        }
      }),
    validate: yupResolver(movimentacaoSchema)
  });

  const { mutate, reset, isPending } = useMutation<TAddMovimentacaoResponse, FirestoreError, TMovimentacaoForm>({
    mutationFn: (values) => {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return addMovimentacao(activeEmpresaId, desafio.id, values);
    },
    async onSuccess() {
      showNotification({
        message: `Movimentação cadastrada com sucesso!`,
        color: "green",
        autoClose: 2500
      });
      reset();
      form.reset();
      // eslint-disable-next-line compat/compat
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [activeEmpresaId, "desafio", desafio.id] }),
        queryClient.invalidateQueries({ queryKey: [DESAFIOS_COLLECTION_KEY, desafio.id] })
      ]);
    },
    onError(error) {
      captureException(error, true);
    }
  });

  type TTransformedValues = TransformedValues<typeof form>;
  /**
   * Handle form submit
   * @param values - Form values
   */
  function onSubmit(values: TTransformedValues) {
    mutate(values);
  }

  return {
    ...form,
    isPending,
    onSubmit: form.onSubmit(onSubmit)
  };
}

export default useDesafioMovimentacaoForm;
