import { Button } from "@mantine/core";
import { modals } from "@mantine/modals";
import { useMutation } from "@tanstack/react-query";
import { FirestoreError, serverTimestamp } from "firebase/firestore";
import { getIdeiaDocumentReferenceFromPath } from "@/modules/ideias/firestore";
import useUserStore from "@/modules/users/store";
import { TMarcoDatabaseFields, updateMarco } from "../firestore";
import marcoSchema from "../schema/marco-movimentacao-schema";

type FormInputs = Pick<TMarcoDatabaseFields, "startedAt">;

/**
 * Open modal to start or continue atuacao
 * @param marco - Marco to start or continue atuacao
 */
function openModal(marco: TMarco) {
  const defaultValues = marcoSchema.getDefault() as TMarco;
  modals.openContextModal({
    size: "xl",
    modal: "iniciar-atuacao-modal",
    innerProps: {
      marco: { ...defaultValues, ...marco }
    }
  });
}

type TFinalizarAtuacaoResponse = Awaited<ReturnType<typeof updateMarco>>;

const OpenAtuacaoModalButton = ({ marco }: { marco: TMarco }) => {
  const isAlreadyStarted = !!marco?.startedAt && !marco?.finishedAt;
  const isFinished = !!marco?.finishedAt;
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  const { mutate: updateAtuacaoMutation, isPending } = useMutation<
    TFinalizarAtuacaoResponse,
    FirestoreError,
    FormInputs
  >({
    mutationFn(values) {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      const ideiaDocumentReference = getIdeiaDocumentReferenceFromPath(marco.ideiaReference);

      return updateMarco(activeEmpresaId, ideiaDocumentReference.id, marco.id, values);
    },
    onSuccess() {
      openModal(marco);
    }
  });

  /**
   * Handle click
   */
  function handleClick() {
    if (isAlreadyStarted) {
      openModal(marco);
    } else {
      updateAtuacaoMutation({ startedAt: serverTimestamp() });
    }
  }

  return (
    <Button
      radius="lg"
      color="blue"
      onClick={handleClick}
      variant={isAlreadyStarted ? "outline" : "filled"}
      loading={isPending}
      disabled={isFinished}
    >
      {marco.startedAt ? "Continuar" : "Iniciar"} atuação
    </Button>
  );
};

export default OpenAtuacaoModalButton;
