import { and, collection, FirestoreDataConverter, getDocs, or, query, Timestamp, where } from "firebase/firestore";
import { firestore } from "@/base";
import { getRespostasCollectionReference } from "../responder-questionario/firestore";

type TConviteDocument = Omit<TConvite, "createdAt" | "updatedAt"> & {
  createdAt: Timestamp;
  updatedAt?: Timestamp;
};

const conviteConverter: FirestoreDataConverter<TConvite> = {
  toFirestore(data) {
    delete data.id;
    return data;
  },
  fromFirestore(snap) {
    const { createdAt, updatedAt, ...document } = snap.data() as TConviteDocument;

    const data: TConvite = {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate()
    };

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }
    return data;
  }
};

/**
 * Get all convites collection reference
 * @returns - Convites collection reference
 */
function getConviteCollectionReference() {
  return collection(firestore, "convites");
}

/**
 * Get all convites from the database
 * @param questionarioId - Questionario id
 * @param empresaId - Empresa id
 * @returns - Promise with all convites
 */
export function getConvitesEmpresaQuestionario(questionarioId: string, empresaId: string) {
  const convitesCollectionReference = getConviteCollectionReference().withConverter(conviteConverter);

  const convitesQuery = query(
    convitesCollectionReference,
    and(
      where("idEmpresaColaboradorConvidado", "==", empresaId),
      or(
        where("idQuestionario", "==", questionarioId),
        where("idQuestionario", "==", ""),
        where("idQuestionario", "==", null)
      )
    )
  );

  return getDocs(convitesQuery);
}

/**
 * Get all convites from the database
 * @param projetoId - Projeto id
 * @param empresaProjetoId - Empresa do projeto id
 * @param empresaColaboradorId - Empresa do colaborador id
 * @returns - Promise with all convites
 */
export function getConvitesProjeto(projetoId: string, empresaProjetoId: string, empresaColaboradorId: string) {
  const convitesCollectionReference = getConviteCollectionReference().withConverter(conviteConverter);

  const convitesQuery = query(
    convitesCollectionReference,
    and(
      where("idProjeto", "==", projetoId),
      where("idEmpresa", "==", empresaProjetoId),
      where("idEmpresaColaboradorConvidado", "==", empresaColaboradorId),
      where("convite", "==", true)
    )
  );

  return getDocs(convitesQuery);
}

/**
 * Get all convites from the database
 * @param empresaId - Id da empresa que CRIOU o questionário
 * @param questionarioId - Questionario id
 * @param empresaRespondenteId - Id da empresa que RESPONDEU o questionário
 * @returns - Promise with all convites
 */
export function getRespostasEmpresaQuestionario(
  empresaId: string,
  questionarioId: string,
  empresaRespondenteId: string
) {
  const respostasCollectionReference = getRespostasCollectionReference(empresaId);

  const convitesQuery = query(
    respostasCollectionReference,
    where("idQuestionario", "==", questionarioId),
    where("idEmpresa", "==", empresaRespondenteId),
    where("pausado", "==", false)
  );

  return getDocs(convitesQuery);
}
