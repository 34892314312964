import { Button, Card, Divider, Flex, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import EstrategiaItem from "./EstrategiaItem";
import useGetPergunta from "./useGetPerguntas";
interface Campo {
  campo: string;
  valor: string;
}
interface respostas {
  id: string;
  resposta?: string | number | Campo | Campo[] | boolean;
}
// prettier-ignore
const EstrategiaList = ({ grupo, respostas }: { grupo: TGruposDiagnostico, respostas: respostas[] }) => {
  const [opened, { toggle }] = useDisclosure(false);
  const { data } = useGetPergunta({ ids: [grupo?.id] });
  const perguntas = data || [];

  return (
    <Card withBorder shadow="md" style={{ flex: 1, marginBottom: 15 }}>
      <Flex justify="space-between" align="center">
        <Text size="xl">{grupo.nome}</Text>
        <Button
          type="button"
          variant="transparent"
          size="compact-sm"
          rightSection={opened ? <IconChevronUp size="1rem" /> : <IconChevronDown size="1rem" />}
          onClick={toggle}
        >
          Ver mais
        </Button>
      </Flex>
      <Text size="xs">Texto de explicação do grupo de perguntas sobre estratégia</Text>
      {opened && <Divider my="md" />}
      {opened && (
        <Flex direction="column">
          {perguntas?.map((pergunta: TPergunta, index: number) => <EstrategiaItem key={pergunta.id} pergunta={pergunta} numero={index + 1} respostas={respostas} />)}
          <Divider my="md" />
        </Flex>
      )}
    </Card>
  );
};

export default EstrategiaList;
