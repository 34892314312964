import { subject } from "@casl/ability";
import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import {
  REGULAMENTOS_IDEIAS_COLLECTION_KEY,
  getRegulamentoDocumentFromReferencePath as getRegulamentoDocumentFromReferencePath
} from "../firestore";

type TGetResponse = Awaited<ReturnType<typeof getRegulamentoDocumentFromReferencePath>>;

/**
 * Get Regulamento from the ideia
 * @param ideia - Ideia to get the regulamento from
 * @returns - Regulamento from the ideia
 */
function useGetRegulamentoFromIdeia(ideia: TIdeia) {
  return useQuery<TGetResponse, FirestoreError, TRegulamentoIdeias | null>({
    queryKey: [...REGULAMENTOS_IDEIAS_COLLECTION_KEY, ideia.id, ideia.regulamentoReference],
    queryFn() {
      return getRegulamentoDocumentFromReferencePath(ideia.regulamentoReference);
    },
    select(dataResponse) {
      if (!dataResponse.exists()) {
        return null;
      }

      return subject("TRegulamentoIdeias", dataResponse.data());
    }
  });
}

export default useGetRegulamentoFromIdeia;
