import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { REGULAMENTOS_DESAFIOS_COLLECTION_KEY, getRegulamento } from "../firestore";

type TGetResponse = Awaited<ReturnType<typeof getRegulamento>>;

/**
 * Get Regulamento from the desafio
 * @returns - Regulamento from the desafio
 */
function useGetRegulamentosDesafios() {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  return useQuery<TGetResponse, FirestoreError, TRegulamentoDesafios[] | null>({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [activeEmpresaId, REGULAMENTOS_DESAFIOS_COLLECTION_KEY],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      return getRegulamento(activeEmpresaId);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((desafio) => desafio.data());
    }
  });
}

export default useGetRegulamentosDesafios;
