import { Text, Card, Group, List, Textarea, Title } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { PropsWithChildren } from "react";
import Can from "@/components/can";
import FileItem from "@/components/file-selector-dropzone/FileItem";
import ColaboradorMultiSelector from "@/modules/colaboradores/components/colaborador-multi-selector";
import OpenAtuacaoModalButton from "./OpenAtuacaoModalButton";
import useGetMarcoFromReferencePath from "../hooks/useGetMarcoFromReferencePath";

type TMarcoItemProperties = PropsWithChildren<{
  marcoPath: string;
}>;

const MarcoItem = ({ marcoPath, children }: TMarcoItemProperties) => {
  const { data: marco } = useGetMarcoFromReferencePath(marcoPath);
  const { isInProgress, borderCardColor, bgCardColor } = verificationMarcos(marco);

  return (
    <Card withBorder style={{ borderColor: borderCardColor }}>
      {marco && (
        <>
          <Group justify="space-between">
            <ColaboradorMultiSelector
              multiple
              style={{ flex: 1 }}
              label="Responsável pelo marco"
              value={marco.colaboradoresResponsaveis}
              readOnly
              variant="unstyled"
            />

            <DateInput
              label="Data limite para a execução da atividade"
              valueFormat="DD/MM/YYYY"
              style={{ flex: 1 }}
              variant="unstyled"
              value={marco.dataPrazo}
              readOnly
            />
            {children}
          </Group>
          <Textarea label="Descrição do marco" variant="unstyled" value={marco.acoes} readOnly />
          {!isInProgress && !marco.finishedAt && (
            <Can I="update" a="TMarco">
              <Group justify="flex-end">
                <OpenAtuacaoModalButton marco={marco} />
              </Group>
            </Can>
          )}
          {marco.startedAt && (
            <Card withBorder my="md" bg={bgCardColor}>
              <Title order={5}>Inicio da atuação</Title>

              <Group justify="space-between">
                <ColaboradorMultiSelector
                  multiple
                  style={{ flex: 1 }}
                  label="Responsável pelo marco"
                  value={marco.colaboradoresResponsaveis}
                  readOnly
                  variant="unstyled"
                />
                <DateInput
                  label="Data limite para a execução da atividade"
                  valueFormat="DD/MM/YYYY"
                  style={{ flex: 1 }}
                  variant="unstyled"
                  value={marco.dataPrazo}
                  readOnly
                />
                {marco.dataConclusao && (
                  <DateInput
                    label="Data para conclusão da atividade"
                    valueFormat="DD/MM/YYYY"
                    style={{ flex: 1 }}
                    variant="unstyled"
                    value={marco.dataConclusao}
                    readOnly
                  />
                )}
              </Group>

              {marco.descricaoAtuacao && (
                <Textarea label="Atuação" variant="unstyled" value={marco.descricaoAtuacao} readOnly />
              )}

              {marco && marco.anexos && marco.anexos.length > 0 && (
                <>
                  <Text fw={500} fz="sm">
                    Anexos
                  </Text>
                  <List
                    withPadding
                    styles={{
                      root: {
                        flex: 1
                      }
                    }}
                  >
                    {marco.anexos.map((file, index) => (
                      <FileItem key={file.path} file={file} index={index} readOnly />
                    ))}
                  </List>
                </>
              )}

              {isInProgress && (
                <Can I="update" a="TMarco">
                  <Group justify="flex-end">
                    <OpenAtuacaoModalButton marco={marco} />
                  </Group>
                </Can>
              )}
            </Card>
          )}
        </>
      )}
    </Card>
  );
};

export default MarcoItem;
// prettier-ignore
const verificationMarcos = (marco: TMarco | null | undefined) => {
  const isAlreadyStarted = !!marco?.startedAt && !marco?.finishedAt;
  const isNotStarted = !marco?.startedAt;
  const isFinished = !!marco?.finishedAt;
  const isInProgress = !!marco?.startedAt && !marco?.finishedAt;
  const borderCardColor = isFinished ? "green" : isNotStarted ? "blue" : isAlreadyStarted ? "gray.1" : "";
  const bgCardColor = isFinished ? "green.1" : isAlreadyStarted ? "gray.2" : "";

  return { isInProgress, borderCardColor, bgCardColor }
};
