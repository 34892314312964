import { Group, ThemeIcon, Text } from "@mantine/core";
import { IconCoin } from "@tabler/icons-react";
import useCatchFirestoreError from "@/hooks/useCatchFirestoreError";
import useGetTotalMoedasTodosRegulamentos from "@/modules/ideias-activities-per-colaborador/components/ideia-patrocinio-form/useGetTotalMoedasTodosRegulamentos";
import useGetRegulamentosIdeias from "@/src/routes/gestao-inovacao-page/useGetRegulamentos";

const TotalCoinsColaboradorIdeias = () => {
  const { data: regulamentos, error: regulamentoError } = useGetRegulamentosIdeias();
  useCatchFirestoreError(regulamentoError);

  const regulamentosAtivos = regulamentos?.filter(
    (regulamento) =>
      (!regulamento.dataFinal || new Date() <= new Date(regulamento.dataFinal)) &&
      (!regulamento.dataInicial || new Date() >= new Date(regulamento.dataInicial))
  );

  const totalMoedas =
    regulamentosAtivos?.reduce(
      (total: number, regulamento) => total + (regulamento.quantidadeMoedasPorColaborador || 0),
      0
    ) || 0;
  const { data: totalMoedasTodosRegulamentos } = useGetTotalMoedasTodosRegulamentos(regulamentosAtivos);
  const totalMoedasGastas: number =
    Array.isArray(totalMoedasTodosRegulamentos) && totalMoedasTodosRegulamentos.length > 0
      ? totalMoedasTodosRegulamentos.reduce((total: number, moedas: number) => total + moedas, 0) || 0
      : 0;
  return (
    <Group>
      <ThemeIcon variant="light" color="yellow" bg="transparent" size="xs">
        <IconCoin />
      </ThemeIcon>
      <Text c="#000" size="xs" tt="lowercase">
        {`${totalMoedas - totalMoedasGastas} moedas disponíveis para patrocinar ideias.`}
      </Text>
    </Group>
  );
};

export default TotalCoinsColaboradorIdeias;
