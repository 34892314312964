import {
  addDoc,
  collection,
  doc,
  FirestoreDataConverter,
  getDocs,
  query,
  Timestamp,
  updateDoc,
  where
} from "firebase/firestore";
import { getEmpresaDocumentReference } from "../empresas/firestore";

const EVIDENCIAS_COLLECTION_KEY = "evidencias";

type TEvidenciaDocument = Omit<TEvidencia, "createdAt" | "updatedAt"> & {
  createdAt: Timestamp;
  updatedAt?: Timestamp;
};

const evidenciaConverter: FirestoreDataConverter<TEvidencia> = {
  toFirestore(data) {
    delete data.id;
    return data;
  },
  fromFirestore(snap) {
    const { createdAt, updatedAt, ...document } = snap.data() as TEvidenciaDocument;

    const data: TEvidencia = {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate()
    };

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }
    return data;
  }
};

/**
 * Get all empresas collection reference
 * @param empresaId - Empresa id
 * @returns - Empresas collection reference
 */
function getEvidenciaCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, EVIDENCIAS_COLLECTION_KEY);
}

/**
 * Get a empresa document reference
 * @param evidenciaId - Empresa id
 * @param empresaId - Empresa id
 * @returns - Empresa document reference
 */
function getEvidenciaDocumentReference(evidenciaId: TEvidencia["id"], empresaId: TEmpresa["id"]) {
  const empresasCollectionReference = getEvidenciaCollectionReference(empresaId);
  // return doc().withConverter(evidenciaConverter);
  return doc(empresasCollectionReference, evidenciaId);
}

/**
 * Get ideias query for the regulamento
 * @param empresaId - empresa id
 * @param projetoId - projeto id
 * @param questionarioId - questionario id
 * @param empresaColaboradorId - empresa colaborador id
 * @returns - Ideias query
 */
export async function getEvidenciasByQuestionarioQuery(
  empresaId: TEmpresa["id"],
  projetoId: TProjeto["id"],
  questionarioId: TQuestionario["id"],
  empresaColaboradorId: TEmpresa["id"]
) {
  const evidenciasCollectionReference = getEvidenciaCollectionReference(empresaId);

  const evidenciasQuery = query(
    evidenciasCollectionReference,
    where("idEmpresa", "==", empresaId),
    where("idProjeto", "==", projetoId),
    where("idQuestionario", "==", questionarioId),
    where("idEmpresaColaborador", "==", empresaColaboradorId),
    where("deletedAt", "==", null)
  ).withConverter(evidenciaConverter);
  return await getDocs(evidenciasQuery);
}

/**
 * Update a empresa to the database
 * @param evidencia - evidencia
 * @param updatedEvidencia - Evidencia to update
 * @returns - Promise with the empresa reference
 */
export function updateEvidencia(evidencia: TEvidencia, updatedEvidencia: TEvidencia) {
  const evidenciaDocumentReference = getEvidenciaDocumentReference(evidencia.id, evidencia.idEmpresa).withConverter(
    evidenciaConverter
  );
  return updateDoc(evidenciaDocumentReference, { ...updatedEvidencia });
}

/**
 * Add Like
 * @param evidencia - Evidencia
 * @returns - Like document
 */
export function addEvidencia(evidencia: Omit<TEvidencia, "id" | "refPath" | "deletedAt">) {
  const evidenciaCollection = getEvidenciaCollectionReference(evidencia.idEmpresa);
  return addDoc(evidenciaCollection, evidencia);
}
