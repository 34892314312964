import { subject } from "@casl/ability";
import {
  FirestoreDataConverter,
  Timestamp,
  WhereFilterOp,
  addDoc,
  collection,
  getDocs,
  query,
  serverTimestamp,
  where
} from "firebase/firestore";
import { getIdeiaDocumentReference } from "../ideias/firestore";

export type FilterMovimentacao = {
  field: string;
  operator: WhereFilterOp;
  value: string | Timestamp | null;
};

export type TMovimentacaoDatabaseFields = Pick<TMovimentacao, "createdBy" | "updatedBy"> & {
  createdAt: ReturnType<typeof serverTimestamp>;
  updatedAt?: ReturnType<typeof serverTimestamp>;
  deletedAt: ReturnType<typeof serverTimestamp> | null;
};
export type TMovimentacaoFormFields = Omit<
  TMovimentacao,
  "id" | "refPath" | "createdAt" | "updatedAt" | "createdBy" | "updatedBy"
> &
  Partial<Pick<TMovimentacao, "id" | "refPath">>;
export type TMovimentacaoForm = TMovimentacaoFormFields & TMovimentacaoDatabaseFields;

type TMovimentacaoDocument = Omit<TMovimentacao, "createdAt" | "updatedAt" | "deletedAt"> & {
  createdAt: Timestamp;
  updatedAt?: Timestamp;
  deletedAt: Timestamp | null;
};

const movimentacaoConverter: FirestoreDataConverter<TMovimentacao> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;

    return data;
  },
  fromFirestore(snap) {
    const { createdAt, updatedAt, ...document } = snap.data() as TMovimentacaoDocument;

    const data: TMovimentacao = subject("TMovimentacao", {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      refPath: snap.ref.path
    });

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }
    return data;
  }
};

/**
 * Get ideias collection reference from empresaId
 * @param empresaId - Empresa id to get the ideias collection reference
 * @param ideiaId - Ideia id to get the ideias collection reference
 * @returns - Ideias collection reference
 */
function getIdeiaMovimentacaoCollectionReference(empresaId: TEmpresa["id"], ideiaId: TIdeia["id"]) {
  const ideiaDocumentReference = getIdeiaDocumentReference(empresaId, ideiaId);
  return collection(ideiaDocumentReference, "movimentacoes");
}

/**
 * Add movimentacao
 * @param empresaId - The empresa ID
 * @param ideiaId - the ideia ID to add comentário
 * @param movimentacao - The movimentação to add
 * @returns - Movimentação document
 */
export function addMovimentacao(empresaId: TEmpresa["id"], ideiaId: TIdeia["id"], movimentacao: TMovimentacaoForm) {
  const ideiaComentariosCollectionReference = getIdeiaMovimentacaoCollectionReference(empresaId, ideiaId);
  return addDoc(ideiaComentariosCollectionReference, movimentacao);
}

/**
 * Get movimentações documents based on multiple filters
 * @param empresaId - The empresa ID
 * @param ideiaId - the ideia ID to add movimentações
 * @param filters - Filters options
 * @returns - The movimentações documents
 */
export function getMovimentacoesWithFilters(
  empresaId: TEmpresa["id"],
  ideiaId: TIdeia["id"],
  filters: FilterMovimentacao[]
) {
  const movimentacoesCollectionReference = getIdeiaMovimentacaoCollectionReference(empresaId, ideiaId).withConverter(
    movimentacaoConverter
  );

  const q = query(movimentacoesCollectionReference, ...generateMovimentacaoFilter(filters));

  return getDocs(q);
}

/**
 * Get movimentacoes filters
 * @param filters - Filters options
 * @returns - The movimentacoes filters
 */
function generateMovimentacaoFilter(filters: FilterMovimentacao[]) {
  return filters.map(({ field, operator, value }) => {
    return where(field, operator, value);
  });
}
