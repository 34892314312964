import { Group, Select, Space } from "@mantine/core";
import { useState } from "react";
import ListaDeAutorizacaoDePerfil from "@/modules/perfil-de-acesso/components/lista-de-autorizacao-de-perfil";

const changeSelect = (setSelect: React.Dispatch<React.SetStateAction<string | null>>) => {
  return (value: string) => {
    setSelect(value);
  };
};

const PerfilDeAcessoPage = () => {
  const [select, setSelect] = useState(null);

  return (
    <>
      <Group justify={"end"}>
        <Select
          label="Acessos"
          placeholder="Perfil de acesso"
          data={data}
          onChange={changeSelect(setSelect)}
          value={select}
        />
      </Group>
      <Space h={"md"} />
      <ListaDeAutorizacaoDePerfil select={select} />
    </>
  );
};

export default PerfilDeAcessoPage;

const data = [
  { value: "1", label: "Administrador" },
  { value: "2", label: "Membro" },
  { value: "3", label: "Equipe" },
  { value: "4", label: "Master" },
  { value: "5", label: "Colaborador" }
];
