import { Card, Title } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DocumentReference, FirestoreError } from "firebase/firestore";
import { useCallback, useMemo, useState } from "react";
import ColaboradorMultiSelector from "@/modules/colaboradores/components/colaborador-multi-selector/ColaboradorMultiSelector";
import { colaboradorConverter } from "@/modules/colaboradores/firestore";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import useGetFirstComiteInovacaoAtivo from "@/modules/comite-inovacao/components/status-comite-inovacao/useGetFirstComiteInovacaoAtivo";
import { IDEIAS_COLLECTION_KEY, removeImplementador, includeImplementador } from "@/modules/ideias/firestore";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import { captureException } from "@/services/log";
import { getDocumentReferenceFromPath } from "@/utils/firestore";

type TIncludeImplementador = Awaited<ReturnType<typeof includeImplementador>>;
type TRemoveImplementador = Awaited<ReturnType<typeof removeImplementador>>;

const getDocumentReferencesFromValues = (values: string[]): DocumentReference<TColaborador>[] => {
  return values.map((value) => getDocumentReferenceFromPath(value, colaboradorConverter));
};
// prettier-ignore
const IdeiaImplementadoresCard = ({ ideia }: { ideia: TIdeia }) => {
  const { data: comiteAtivo } = useGetFirstComiteInovacaoAtivo();
  const queryClient = useQueryClient();
  const referenceCompany = ideia.refPath.split("ideias")[0];
  const empresa = useUserStore((state) => state.activeEmpresa);
  const uid = getCurrentUser()?.uid;
  const { data: colaborador } = useGetColaboradorById(empresa?.id || "", uid || "");

  const implementadoresReferencesPath = useMemo(() =>
    ideia.implementadoresReferences.map((participante) =>
      typeof participante === "string"
        ? participante
        : referenceCompany + `colaboradores/${ideia.createdBy}`
    ),
    [ideia.implementadoresReferences, referenceCompany, ideia.createdBy]
  );

  const [implementadores, setImplementadores] = useState(implementadoresReferencesPath);

  const handleMutationSuccess = useCallback(async () => {
    showNotification({
      message: `Atualizado com sucesso!`,
      color: "green",
      autoClose: 2500
    });
    await queryClient.invalidateQueries({ queryKey: [IDEIAS_COLLECTION_KEY] });
  }, [queryClient]);

  const handleMutationError = useCallback((error: FirestoreError) => {
    captureException(error, true);
  }, []);

  const { mutate: mutateRemove } = useMutation<TRemoveImplementador, FirestoreError, string[]>({
    mutationFn: (values: string[]) => {
      if (!empresa) throw new Error("Nenhuma empresa selecionada");
      if (!values[0]) throw new Error("Nenhum implementador selecionado");
      return removeImplementador(empresa.id, ideia.id, getDocumentReferencesFromValues(values));
    },
    onSuccess: handleMutationSuccess,
    onError: handleMutationError
  });

  const { mutate: mutateInclude } = useMutation<TIncludeImplementador, FirestoreError, string[]>({
    mutationFn: (values: string[]) => {
      if (!empresa) throw new Error("Nenhuma empresa selecionada");
      if (!values[0]) throw new Error("Nenhum implementador selecionado");
      return includeImplementador(empresa.id, ideia.id, getDocumentReferencesFromValues(values));
    },
    onSuccess: handleMutationSuccess,
    onError: handleMutationError
  });

  const handleOnChange = useCallback((values: string[]) => {
    const valoresRemovidos = implementadores.filter((implementador) => !values.includes(implementador));
    const valoresInseridos = values.filter((value) => !implementadores.includes(value));

    if (valoresRemovidos.length > 0) mutateRemove(valoresRemovidos);
    if (valoresInseridos.length > 0) mutateInclude(valoresInseridos);

    setImplementadores(values);
  }, [implementadores, mutateRemove, mutateInclude]);

  const isReadOnly = useMemo(() => {
    return !(
      ((colaborador as { role?: string })?.role === "ADMIN") ||
      (comiteAtivo &&
        colaborador &&
        (comiteAtivo as { participantesReferences?: string[] })?.participantesReferences?.includes(
          (colaborador as { refPath?: string })?.refPath ?? ''
        ))
    );
  }, [colaborador, comiteAtivo]);

  return (
    <Card withBorder mt={"xl"}>
      <Title order={4}>Implementadores da ideia</Title>
      <ColaboradorMultiSelector
        multiple
        style={{ flex: 1, width: "100%" }}
        value={implementadores}
        searchable
        readOnly={isReadOnly}
        nothingFoundMessage="Nenhum colaborador encontrado..."
        variant="unstyled"
        onChange={handleOnChange}
        placeholder={implementadores.length === 0 ? "Selecione os implementadores da ideia" : ""}
      />
    </Card>
  );
};

export default IdeiaImplementadoresCard;
