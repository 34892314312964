import { Alert, Button, Group, Stack, TextInput, Textarea } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { useEffect } from "react";
import ColaboradorSelector from "@/modules/colaboradores/components/colaborador-selector/ColaboradorSelector";
import DesafioCategoriaSelector from "@/modules/desafio-categorias/components/desafio-categoria-selector";
import useGetActiveRegulamentosDesafios from "@/modules/regulamento-desafios/hooks/useGetActiveRegulamentosDesafios";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import { extractTextInputPropertiesFromFormSchema } from "@/utils/form";
import { DesafioFormProvider } from "./desafio-form-context";
import DesafioAnexos from "./DesafioAnexos";
import useDesafioForm from "./useDesafioForm";
import { TDesafioForm } from "../../firestore";
import desafioFormSchema from "../../schema";

/**
 * Sync regulamento reference with form values
 * @returns - Regulamento
 */
function useSyncRegulamentoReference() {
  return useGetActiveRegulamentosDesafios<TRegulamentoDesafios | undefined>({
    select(dataSnapshot) {
      if (dataSnapshot.length === 0) {
        return undefined;
      }

      const [firstRegulamento] = dataSnapshot;
      if (!firstRegulamento) {
        throw new Error("Regulamento não encontrado");
      }
      return firstRegulamento.data();
    }
  });
}
// prettier-ignore
const DesafioForm = ({ desafio, onSuccess }: { onSuccess: () => void; desafio: TDesafio | TDesafioForm }) => {
  const { form, isPending, onSubmit } = useDesafioForm(desafio, onSuccess);
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);
  const uid = getCurrentUser()?.uid;
  const { data: regulamento, isLoading: isLoadingRegulamento } = useSyncRegulamentoReference();
  useEffect(() => {
    const regulamentoReferencePath =
      typeof form.values.regulamentoReference === "string"
        ? form.values.regulamentoReference
        : form.values.regulamentoReference?.path;
    const hasRegulamentoDifferent = regulamentoReferencePath !== regulamento?.refPath;
    if (!isLoadingRegulamento && regulamento && hasRegulamentoDifferent) {
      form.setFieldValue("regulamentoReference", regulamento.refPath);
    }
  }, [isLoadingRegulamento, regulamento, form.setFieldValue, form.values.regulamentoReference, form]);

  const fieldsDescriptor = desafioFormSchema.describe();
  const genericError = form.errors[""] ?? form.errors["regulamentoReference"];

  useEffect(() => {
    if (!form?.values?.id) {
      form.setValues((values) => ({ ...values, propositorReference: `empresas/${activeEmpresaId}/colaboradores/${uid}` }))
    }
  }, [])

  return (
    <DesafioFormProvider form={form}>
      <form onSubmit={onSubmit}>
        <Stack gap="sm">
          <ColaboradorSelector
            searchable
            style={{ flex: 1 }}
            clearable={false}
            allowDeselect={false}
            nothingFoundMessage="Nenhum colaborador encontrado..."
            {...form.getInputProps("propositorReference")}
            {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "propositorReference")}
          />
          <TextInput
            type="text"
            style={{ flex: 1 }}
            {...form.getInputProps("titulo")}
            {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "titulo")}
          />
          <Textarea
            style={{ flex: 1 }}
            {...form.getInputProps("descricao")}
            {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "descricao")}
          />
          <Textarea
            style={{ flex: 1 }}
            {...form.getInputProps("objetivo")}
            {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "objetivo")}
          />
          <DesafioCategoriaSelector
            style={{ flex: 1 }}
            {...form.getInputProps("categoriaReference")}
            {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "categoriaReference")}
          />

          <DesafioAnexos isLoading={isPending} />
        </Stack>

        <Group justify={genericError ? "space-between" : "flex-end"} mt="xl">
          {genericError && (
            <Alert icon={<IconAlertCircle size="1rem" />} color="red">
              {genericError}
            </Alert>
          )}
          <Button type="submit" loading={isPending}>
            Salvar
          </Button>
        </Group>
      </form>
    </DesafioFormProvider>
  );
};

export default DesafioForm;
