import { FocusTrap } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import IdeiaComentarioForm from "../../components/ideia-comentario-form";

const ResponderIdeiaComentarioModal = ({
  context,
  innerProps,
  id
}: ContextModalProps<{ ideia: TIdeia; comentario: TIdeiaComentario }>) => {
  const { ideia, comentario } = innerProps;
  /**
   * Handle add ignore area success
   */
  function onSuccess() {
    context.closeModal(id);
  }

  return (
    <FocusTrap active={true}>
      <IdeiaComentarioForm ideia={ideia} respostaAoComentario={comentario} onSuccess={onSuccess} />
    </FocusTrap>
  );
};

export default ResponderIdeiaComentarioModal;
