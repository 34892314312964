import { Card, Flex, Group, Space, Text } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import { Dispatch, SetStateAction, useCallback } from "react";

interface MyState {
  id?: number;
  data: Date | null;
  descricao: string;
  orientacaoLocal: string;
}

// prettier-ignore
const CardSolucao = ({ item, removeFilter, setEvent }: { item: MyState; removeFilter: (id: number | undefined, setEvent: Dispatch<SetStateAction<MyState[]>>) => void, setEvent: Dispatch<SetStateAction<MyState[]>> }) => {

  const onClick = useCallback(() => {
    removeFilter(item.id, setEvent)
  }, []);

  return (
    <>
      <Card withBorder>
        <Group justify={"space-between"}>
          <Group>
            <Flex direction={"column"}>
              <Text fz="xs" color="rgba(82, 83, 86, 1)">Data</Text>
              <Text>{item?.data?.toLocaleDateString("pt-BR") ?? ""}</Text>
            </Flex>
            <Flex direction={"column"}>
              <Text fz="xs" color="rgba(82, 83, 86, 1)">Descriçao</Text>
              <Text>{item?.descricao}</Text>
            </Flex>
            <Flex direction={"column"}>
              <Text fz="xs" color="rgba(82, 83, 86, 1)">Orientações sobre o local</Text>
              <Text>{item.orientacaoLocal}</Text>
            </Flex>
          </Group>
          <IconTrash size={16} color="rgba(8, 68, 244, 1)" style={{ cursor: "pointer" }} onClick={onClick} />
        </Group>
      </Card>
      <Space h={"md"} />
    </>
  );
};

export default CardSolucao;
