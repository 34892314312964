import { useMutation } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { addStatus, TStatusForm, updateStatus } from "../../../firestore";

type TStatusAddResponse = Awaited<ReturnType<typeof addStatus>>;
type TStatusEditResponse = Awaited<ReturnType<typeof updateStatus>>;
/**
 * Finalizar resposta de inovação mutation hook
 * @returns Finalizar resposta de inovação mutation
 */
export function useStatus() {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  return useMutation<TStatusAddResponse | TStatusEditResponse, FirestoreError, TStatusForm>({
    mutationFn(item) {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      if (!item) {
        throw new Error("Empresa não selecionada");
      }

      return item.id ? updateStatus(activeEmpresaId, item.id, item) : addStatus(activeEmpresaId, item);
    }
  });
}
