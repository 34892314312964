import { Box, Card, Group, LoadingOverlay, Space, Text } from "@mantine/core";
import useQueryQuestionario from "./useQueryQuestionario";
import QuestionarioItem from "../questionario-item";

const QuestionariosList = () => {
  const { data, isFetching } = useQueryQuestionario();

  if (isFetching) {
    return (
      <Box pos="relative">
        <LoadingOverlay visible={isFetching} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
      </Box>
    );
  }

  return (
    <Card withBorder shadow="md">
      <Group justify={"space-between"} align="center">
        <Text size="xl">Questionários aplicados</Text>
      </Group>
      <Space h="md" />
      {data?.map((item) => <QuestionarioItem key={item.id} questionario={item} />)}
    </Card>
  );
};

export default QuestionariosList;
