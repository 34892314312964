import { yupResolver, TransformedValues } from "@mantine/form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { isAudit } from "@/utils/audit";
import { useDesafioForm as useMantineDesafioForm } from "./desafio-form-context";
import { DESAFIOS_COLLECTION_KEY, TDesafioForm, addDesafio, updateDesafio } from "../../firestore";
import desafioFormSchema from "../../schema";

type AddDesafioResponse = Awaited<ReturnType<typeof addDesafio>>;

/**
 * Form for desafio
 * @param desafio - Desafio
 * @param onSuccess - On success callback
 * @returns Form for desafio
 */
// prettier-ignore
function useDesafioForm(desafio: TDesafio | TDesafioForm, onSuccess: () => void) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const queryClient = useQueryClient();

  const initialValues = {
    ...(desafioFormSchema.getDefault({ value: desafio }) as TDesafioForm),
    ...desafio
  } as TDesafioForm;
  const form = useMantineDesafioForm({
    initialValues,
    validate: yupResolver(desafioFormSchema),
    transformValues: (values) => desafioFormSchema.cast(values, { stripUnknown: true })
  });

  const { mutate, reset, isPending } = useMutation<AddDesafioResponse, FirestoreError, TDesafioForm>({
    mutationFn(values) {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      const auditDesafio = desafio as TAudit;
      if (isAudit(auditDesafio) && !!auditDesafio.id) {
        return updateDesafio(activeEmpresaId, auditDesafio.id, {...values, anexos:values.anexosFilesToUpload, });
      }
      return addDesafio(activeEmpresaId, {...values, anexos:values.anexosFilesToUpload});
    },
    onSuccess() {
      form.reset();
      reset();
      onSuccess();
      return queryClient.invalidateQueries({ queryKey: [DESAFIOS_COLLECTION_KEY] });
    },
    onError(error) {
      captureException(error, true);
    }
  });

  type TFormInputs = TransformedValues<typeof form>;

  /**
   * Handle submit form values
   * @param values - Values
   */
  function handleSubmit(values: TFormInputs) {
    mutate(values);
  }

  return { form, isPending, onSubmit: form.onSubmit(handleSubmit) };
}

export default useDesafioForm;
