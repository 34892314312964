import { Button } from "@mantine/core";
import { modals } from "@mantine/modals";

const ResponderIdeiaComentarioButton = ({ ideia, comentario }: { ideia: TIdeia; comentario: TIdeiaComentario }) => {
  /**
   * Handles the click event.
   */
  function handleClick() {
    modals.openContextModal({
      modal: "responder-ideia-comentario-modal",
      size: "xl",
      innerProps: {
        ideia,
        comentario
      },
      ml: "110px"
    });
  }

  return (
    <Button variant="transparent" size="xs" p={0} onClick={handleClick}>
      Responder
    </Button>
  );
};

export default ResponderIdeiaComentarioButton;
