import { getProjetosAll } from "@/modules/projetos/firestore";

/**
 * Get campanhas
 * @param activeEmpresaId - activeEmpresaId
 * @returns - campanhas
 */
// prettier-ignore
async function getProjetos(
  activeEmpresaId: string | undefined
): Promise<TProjeto[]> {

  if (activeEmpresaId ) {
      const newData = await getProjetosAll(activeEmpresaId);
      return newData.docs.map((desafio) => desafio.data());
  }

  return [];
}

export default getProjetos;
