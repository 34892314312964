import { Box, Card, Group, LoadingOverlay, Text } from "@mantine/core";
import useGetConvites from "@/components/app-container/useGetConvites";
import useQueryProjetos from "./useQueryProjetos";
import DiagnosticoItem from "../diagnostico-item";

// prettier-ignore
// eslint-disable-next-line complexity
const DiagnosticoList = () => {
  const { data } = useGetConvites();
  const { data: projetos, isFetching } = useQueryProjetos(data && data[0] && data[0].idEmpresa || "");
  const formatProjeto = projetos?.filter(item => (item && item?.id) === (data && data[0]?.idProjeto)) as TProjeto[];

  if (isFetching) {
    return (
      <Box pos="relative">
        <LoadingOverlay visible={isFetching} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
      </Box>
    );
  }

  return (
    <Card withBorder shadow="md" style={{ flex: 1, overflow: "auto" }} p={0}>
      <div style={{ padding: 16 }}>
        {formatProjeto && formatProjeto?.length > 0 ?
          formatProjeto?.map((projeto) => <DiagnosticoItem key={projeto.id} projeto={projeto} />) : <Group justify={"center"} mt="20px">
            <Text fw={700}>Nenhum projeto concluído!</Text>
          </Group>}
      </div>
    </Card>
  );
};

export default DiagnosticoList;
