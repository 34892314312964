import { Anchor, Group, Text } from "@mantine/core";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import RankingTrendIconLikes from "@/components/ranking-trend-icon/RankingTrendIconLikes";
import ColaboradoresInlineList from "@/modules/colaboradores/components/colaboradores-inline-list";
import IdeiaStatusBadge from "@/modules/ideias/components/ideia-status-badge";
import TotalMoedasBadge from "@/modules/ideias/components/total-moedas-badge";
import useUserStore from "@/modules/users/store";
import TotalLikesBadge from "../ideia-page/TotalLikesBadge";

const columnHelper = createColumnHelper<TIdeia>();

const ColaboradorCreatedByText = ({ colaboradorId }: { colaboradorId: TColaborador["id"] }) => {
  const activeCompanyId = useUserStore((state) => state.activeEmpresaId);
  const colaboradores = useMemo(
    () => [`empresas/${activeCompanyId}/colaboradores/${colaboradorId}`],
    [activeCompanyId, colaboradorId]
  );
  return <ColaboradoresInlineList colaboradores={colaboradores} topVisibleAvatars={0} />;
};
// prettier-ignore
const ideiaColumns: ColumnDef<TIdeia>[] = [
  columnHelper.display({
    id: "top",
    header: "Top",
    enableSorting: false,
    enableColumnFilter: false,
    cell({ row }) {
      const ideia = row.original;

      return (
        <Group gap={2}>
          <Text fw={500}>#{row.index + 1}</Text>{" "}
          <RankingTrendIconLikes ideia={ideia} size={18} />
        </Group>
      );
    }
  }),
  columnHelper.accessor("titulo", {
    header: "Titulo",
    enableSorting: false,
    enableColumnFilter: false,
    cell({ row, getValue }) {
      const id = row.original.id;
      return (
        <Anchor component={NavLink} to={`/ideias/${id}`}>
          {getValue()}
        </Anchor>
      );
    }
  }),
  columnHelper.accessor("createdBy", {
    header: "Autor",
    enableSorting: false,
    enableColumnFilter: false,
    cell({ getValue }) {
      const createdBy = getValue();
      return <ColaboradorCreatedByText colaboradorId={createdBy} />;
    }
  }),
  columnHelper.accessor("status", {
    header: "Status",
    enableSorting: false,
    enableColumnFilter: false,
    cell({ getValue }) {
      return <IdeiaStatusBadge status={getValue()} iconOnly />;
    }
  }),
  columnHelper.accessor("rankingByLikes.totalLikes", {
    header: "Total Curtidas",
    enableSorting: false,
    enableColumnFilter: false,
    cell({ row }) {
      return <TotalLikesBadge ideia={row.original} variant="transparent" />;
    }
  }),
  columnHelper.accessor("rankingByMoedas.totalMoedas", {
    header: "Total Moedas",
    enableSorting: false,
    enableColumnFilter: false,
    cell({ row }) {
      return <TotalMoedasBadge ideia={row.original} variant="transparent" px={0} />;
    }
  })
];

export default ideiaColumns;

// prettier-ignore
export const ideiaColumnsMobile: ColumnDef<TIdeia>[] = [
  columnHelper.display({
    id: "top",
    header: "Top",
    enableSorting: false,
    enableColumnFilter: false,
    cell({ row }) {
      const ideia = row.original;

      return (
        <Group gap={2}>
          <Text fw={500}>#{row.index + 1}</Text>{" "}
          <RankingTrendIconLikes ideia={ideia} size={18} />
        </Group>
      );
    }
  }),
  columnHelper.accessor("titulo", {
    header: "Titulo",
    enableSorting: false,
    enableColumnFilter: false,
    cell({ row, getValue }) {
      const id = row.original.id;
      return (
        <Anchor component={NavLink} to={`/ideias/${id}`}>
          {getValue()}
        </Anchor>
      );
    }
  }),
  columnHelper.accessor("rankingByLikes.totalLikes", {
    header: "Total Curtidas",
    enableSorting: false,
    enableColumnFilter: false,
    cell({ row }) {
      return <TotalLikesBadge ideia={row.original} variant="transparent" />;
    }
  })
];
