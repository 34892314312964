import DOMPurify from "dompurify";
import { marked } from "marked";

/**
 * Extract initials from text (name)
 * @param text - Nome do participante
 * @returns - Iniciais from text
 */
export function extractInitialsFromText(text: string) {
  const [firstName, lastName] = text.split(" ");
  return `${(firstName || "")[0]}${(lastName || text[1] || "")[0]}`;
}

/**
 * Parse markdown text to sanitized html
 * @param text - Text to parse markdown
 * @returns - Parsed markdown text with sanitized html
 */
export async function parseMarkdown(text: string) {
  const parsedMarkdown = await marked.parseInline(text);
  return DOMPurify.sanitize(parsedMarkdown);
}
