import { Text, Card, Group, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconPlus } from "@tabler/icons-react";
import { useRef } from "react";
import AddItemButton from "@/components/add-item-button";
import { TModalProperties } from "@/components/add-item-button/AddItemButton";
import { THandler } from "@/components/table/Table";
import useNonDeletedGlobalFilters from "@/hooks/useNonDeletedGlobalFilters";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import DesafioStatusBadge, { colorsMap } from "@/modules/desafios/components/desafio-status-badge/DesafioStatusBadge";
import DesafiosTable from "@/modules/desafios/components/desafios-table/DesafiosTopTable";
import desafioFormSchema from "@/modules/desafios/schema";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import desafioColumns, { desafioColumnsMobile } from "./DesafioColumns";
import { dataAutorizacoes } from "../empresa-page/EmpresaPage";

const ULTIMOS_DESAFIOS_ATUALIZADAS_LIMIT = 10;

const modalProperties: TModalProperties = {
  modal: "desafio-form-modal"
};

// prettier-ignore
const DesafioStatusLegend = () => {
  const allStatus = Object.keys(colorsMap) as TDesafioStatus[];

  return (
    <>
      <Text fw={500}>Legenda</Text>
      <Group mt="md">
        {allStatus.map((status) => (
          <DesafioStatusBadge key={status} status={status} />
        ))}
      </Group>
    </>
  );
};
// prettier-ignore
const UltimosDesafiosAtualizadasCard = () => {
  const tableHandlersDesafiosReference = useRef<THandler<TDesafio>>(null);
  useNonDeletedGlobalFilters(tableHandlersDesafiosReference);
  const isSmMarches = useMediaQuery("(max-width: 48em)");
  const activeEmpresaId = useUserStore((store) => store.activeEmpresa);
  const activeAutorizacao = useUserStore((store) => store.autorizacoes);
  const uid = getCurrentUser()?.uid;
  const { data: userColaborador } = useGetColaboradorById(activeEmpresaId?.id || "", uid || "");
  const autorizacao = dataAutorizacoes.find(item => item.label === userColaborador?.role);
  const formatAutorizacoes = activeAutorizacao?.filter(item => item.tipo === autorizacao?.value);

  return (
    <Card withBorder>
      <Card.Section inheritPadding py="md">
        <Group justify={"space-between"} mb="md">
          <Title order={3} fw={500} mb="md">
            Os {ULTIMOS_DESAFIOS_ATUALIZADAS_LIMIT} últimos desafios mais atualizados na empresa
          </Title>
          {formatAutorizacoes?.find(item => item.tipoAutorizacao === "9")?.criacao ? <AddItemButton
            size="sm"
            leftSection={<IconPlus size="1rem" />}
            modalProperties={modalProperties}
            schema={desafioFormSchema}
          >
            Adicionar Desafio
          </AddItemButton> : <></>}
        </Group>
        <Card withBorder p={0}>
          <DesafiosTable ref={tableHandlersDesafiosReference} columns={isSmMarches ? desafioColumnsMobile : desafioColumns} />
        </Card>
      </Card.Section>
      {isSmMarches ? <></> : <Card.Section inheritPadding pb="md">
        <Card withBorder>
          <DesafioStatusLegend />
        </Card>
      </Card.Section>}
    </Card>
  );
};

export default UltimosDesafiosAtualizadasCard;
