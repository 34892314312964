import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getRepostasQuestionariosArrayEmpresa } from "@/modules/responder-questionario/firestore";
import useUserStore from "@/modules/users/store";

type TGetComitesInovacaoResponse = Awaited<ReturnType<typeof getRepostasQuestionariosArrayEmpresa>>;
const QUERY_KEY: string = "convite-list" as const;
/**
 * Get comites de inovação query hook
 * @param idQuestionario - id questionario
 * @returns Comites de inovação query hook
 */
// prettier-ignore
function useGetQuestionariosRespondidos(idQuestionario: Array<string> | undefined) {
  const activeEmpresa = useUserStore((store) => store.activeEmpresaId);
  return useQuery<TGetComitesInovacaoResponse, FirestoreError, TResposta[]>({
    queryKey: [QUERY_KEY, idQuestionario, activeEmpresa],
    enabled: !!activeEmpresa && !!idQuestionario,
    queryFn() {
      if (!activeEmpresa) {
        throw new Error("Empresa não autenticada");
      }

      if (!idQuestionario) {
        throw new Error("Projeto não autenticada");
      }
      return getRepostasQuestionariosArrayEmpresa(activeEmpresa, idQuestionario);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((document_) => document_.data());
    }
  });
}

export default useGetQuestionariosRespondidos;
