import { Button } from "@mantine/core";
import { modals } from "@mantine/modals";
import { MouseEvent } from "react";

interface AddMarcosButtonProperties {
  ideia: TIdeia;
  addPathToMovimentacaoCard: (path: string) => void;
}

export const AddMarcosButton = ({ ideia, addPathToMovimentacaoCard }: AddMarcosButtonProperties) => {
  /**
   * Handles the click event.
   * @param event - The event object.
   */
  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    modals.openContextModal({
      modal: "marcos-form-modal",
      size: "xl",
      innerProps: {
        ideia,
        addPathToMovimentacaoCard
      },
      ml: "110px"
    });
  }
  return (
    <Button variant="outline" onClick={handleClick}>
      Adicionar marcos
    </Button>
  );
};
