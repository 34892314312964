import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { getConvitesEmpresaQuestionario } from "../firestore";

type TGetConviteResponse = Awaited<ReturnType<typeof getConvitesEmpresaQuestionario>>;

const QUERY_KEY = "convites-questionario";

/**
 * Get ideia from url params
 * @param questionarioId - id questionario
 * @returns - Ideia or null if not found
 */
function useGetConvitesQuestionario(questionarioId: TQuestionario["id"]) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  return useQuery<TGetConviteResponse, FirestoreError, TConvite[]>({
    queryKey: [QUERY_KEY, questionarioId, activeEmpresaId],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return getConvitesEmpresaQuestionario(questionarioId, activeEmpresaId);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((document_) => document_.data()).filter((c) => !c.cancelado);
    },
    enabled: !!questionarioId && !!activeEmpresaId
  });
}

export default useGetConvitesQuestionario;
