import { Text, Button, Card, Center, Group, Stack, Stepper, Title, Flex } from "@mantine/core";
import { useState, MouseEvent } from "react";
import CleanContainer from "@/components/clean-container";
import useSignOutHandler from "@/hooks/useSignOutHandler";
import EmpresaForm from "@/modules/empresas/components/empresa-form";
import { TEmpresaForm } from "@/modules/empresas/firestore";
import empresaSchema from "@/modules/empresas/schema/empresa-schema";
import { getCurrentUser } from "@/services/auth";
import ChangePasswordForm from "../../modules/users/components/change-password-form";

const SignOutButton = () => {
  const signOutHandler = useSignOutHandler();

  /**
   * Handle logout click
   * @param event - Click event
   */
  function handleLogout(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    signOutHandler().catch((error) => {
      // Trate o erro, se necessário
      console.error("Erro durante o logout:", error);
    });
  }

  return (
    <Button type="button" onClick={handleLogout} variant="subtle" size="compact-xs">
      Sair
    </Button>
  );
};

/**
 * @todo - Fetch witch empresa invite the user
 * @todo - implement set password form
 * @todo - implement set empresa form
 * @returns - SetupMyEmpresaPage
 */
const SetupMyEmpresaPage = () => {
  const [active, setActive] = useState(0);
  const defaultEmpresaValues = empresaSchema.getDefault() as TEmpresaForm;
  const currentUser = getCurrentUser();
  const empresa: TEmpresaForm = {
    ...defaultEmpresaValues,
    email: currentUser?.email || "",
    responsavel: {
      ...defaultEmpresaValues.responsavel,
      nome: currentUser?.displayName || "",
      email: currentUser?.email || ""
    }
  };

  /**
   * Handles the empresa creation
   */
  function onEmpresaCreated() {
    setActive(1);
  }

  return (
    <CleanContainer>
      <Center h="100%" p="lg">
        <Stack>
          <Card shadow="sm" p="md" withBorder>
            <Card.Section inheritPadding py="md" withBorder>
              <Title order={4}>Configurações da Conta</Title>
            </Card.Section>
            <Card.Section inheritPadding py="md" withBorder>
              <Stepper
                color="dark"
                radius="md"
                size="sm"
                active={active}
                onStepClick={setActive}
                allowNextStepsSelect={false}
              >
                <Stepper.Step label="Cadastro da Empresa" allowStepSelect={false} />
                <Stepper.Step label="Definição de Senha" allowStepSelect={false} />
              </Stepper>
            </Card.Section>
            <Card.Section inheritPadding py="md">
              {active === 0 && <EmpresaForm empresa={empresa} onSuccess={onEmpresaCreated} />}
              {active === 1 && <ChangePasswordForm />}
            </Card.Section>
          </Card>
          {currentUser && (
            <Group justify="center">
              <Stack gap={0}>
                <Group gap={6}>
                  <Text fz="sm">Olá</Text>
                  <Text fz="sm" fw={500}>
                    {currentUser.displayName}
                  </Text>
                </Group>
                <Flex gap={0} justify="center" align="center" direction="row" wrap="nowrap">
                  <Text size="xs" c="dimmed">
                    Não é você?
                  </Text>
                  <SignOutButton />
                </Flex>
              </Stack>
            </Group>
          )}
        </Stack>
      </Center>
    </CleanContainer>
  );
};

export default SetupMyEmpresaPage;
