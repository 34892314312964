// prettier-ignore
import { Button, Card, Checkbox, Container, Fieldset, Flex, Group, Loader, Select, Space, TagsInput, Text, TextInput } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { useFocusWithin } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { DocumentData, DocumentReference, serverTimestamp } from "firebase/firestore";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import CepInput from "@/components/cep-input";
import CnpjInput from "@/components/cnpj-input";
import TelInput from "@/components/tel-input";
import useGetConvites from "@/modules/analisar-resposta/components/ranking-list/useGetConvites";
import { addEmpresa } from "@/modules/empresas/firestore";
import { numeroColaboradoresData, porteData } from "@/modules/empresas/schema/empresa-schema";
import { estadosData } from "@/modules/empresas/schema/endereco-schema";
import conviteSchema from "@/modules/projetos/schema/empresa-schema";
import GetEmpresas from "@/modules/relatorio/components/graphics-nivo/useGetEmpresa";
import { extractTextInputPropertiesFromFormSchema } from "@/utils/form";
import useInviteEmpresaForm from "./useInviteEmpresaForm";

const FOCUS_SECTION_COLOR = "var(--mantine-color-blue-light)";
const NUMBER_CARACTERS = 14;
const message = "Ocorreu um erro ao carregar os dados:";
interface TEmpresaData {
  nome_fantasia: string;
  razao_social: string;
  email: string | null;
  ddd_telefone_1: string;
  cnae_fiscal: number;
  cnae_fiscal_descricao: string;
  type?: string | undefined;
  cep: string;
  logradouro: string;
  numero: string;
  complemento: string;
  bairro: string;
  municipio: string;
  uf: TEstado;
}

interface NewValues {
  cnae: string;
  codigoCNAE: string;
  email: string;
  numerocolaboradores: string;
  porte: string;
  razaoSocial: string;
  setores: Array<string>;
  telefone: string;
}

interface EnderecoValues {
  bairro: string;
  cep: string;
  cidade: string;
  complemento: string;
  estado: string;
  logradouro: string;
  numero: string;
}

interface Termos {
  ciente: boolean;
  declaracao: boolean;
}

interface UserValues {
  nome: string;
  telefone: string;
}

// prettier-ignore
const FormInscricaoDoProjeto = ({ projeto }: { projeto: TProjeto | null | undefined }) => {
  const { data } = useGetConvites();
  const [formatEmpresas, setFormatEmpresas] = useState<TEmpresa[]>([])
  const [company, setCompany] = useState<{ id: string | undefined }>({ id: undefined });
  const [newValues, setNewValues] = useState<NewValues>(newValuesOld);
  const [userValues, setUserValues] = useState<UserValues>(newUserOld);
  const [enderecoValues, setEnderecoValues] = useState<EnderecoValues>(newEnderecoOld);
  const [termos, setTermos] = useState<Termos>(newTermos);
  const [loading, setLoading] = useState(false);
  const { form, onSubmit } = useInviteEmpresaForm({ projetoId: projeto?.id, empresaId: company && company.id, setLoading });
  const conviteFieldsDescriptor = conviteSchema.describe();
  const { ref: referenceFieldsetEmpresa, focused: focusedFieldsetEmpresa } = useFocusWithin();
  const { ref: referenceFieldsetResponsavel, focused: focusedFieldsetResponsavel } = useFocusWithin();
  const { ref: referenceFieldsetEndereco, focused: focusedFieldsetEndereco } = useFocusWithin();

  useEffect(() => {
    if (company && company.id) {
      onSubmit();
    }
  }, [company]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadData();
      } catch (error) {
        console.error(message, error);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, [])

  const loadData = async () => {
    if (data && data?.length > 0) {
      const newEmpresa = await GetEmpresas(data);
      setFormatEmpresas(newEmpresa)
    }
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const cnpjValue: TEmpresa["cnpj"] = form.getInputProps("cnpj").value;

    if (typeof cnpjValue === "string") {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      const cnpj = cnpjValue.replaceAll(/\D/g, '') as string;
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      const totalCnpj = cnpj.length as number;

      if (totalCnpj === NUMBER_CARACTERS) {
        fetch(`https://brasilapi.com.br/api/cnpj/v1/${cnpj}`)
          .then(async (response) => {
            const data = await response.json() as TEmpresaData;
            if (data.type === "bad_request") {
              throw new Error("Erro na requisição");
            } else {
              form.setValues((values) => ({
                ...values,
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                nome: data.nome_fantasia as TEmpresa["nomeFantasia"],
              }));

              setNewValues(values => ({
                ...values,
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                cnae: data.cnae_fiscal_descricao as TEmpresa["cnae"],
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                razaoSocial: data.razao_social as TEmpresa["razaoSocial"],
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                codigoCNAE: data.cnae_fiscal.toString() as TEmpresa["codigoCNAE"],
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                email: data.email || "" as TEmpresa["email"],
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                telefone: data.ddd_telefone_1 as TEmpresa["telefone"],
              }))
              setEnderecoValues(values => ({
                ...values,
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                cep: data.cep as TEmpresaData["cep"],
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                logradouro: data.logradouro as TEmpresaData["logradouro"],
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                numero: data.numero as TEmpresaData["numero"],
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                complemento: data.complemento as TEmpresaData["complemento"],
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                bairro: data.bairro as TEmpresaData["bairro"],
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                cidade: data.municipio as TEmpresaData["municipio"],
                estado: data.uf as TEstado
              }))
            }
            return response
          })
          .catch((error) => {
            console.error("Erro na requisição:", error);
          });
      }
    }
  }, [form.getInputProps("cnpj").value])

  if (loading) {
    return <Card w={"100vw"} h={"calc(100vh - 200px)"} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Loader color="blue" />
    </Card>
  }
  return <Container fluid h={"85vh"} w={"100vw"} m={10}>
    <Group justify={"center"}>
      <Text fw={"bold"}>{projeto?.nome}</Text>
    </Group>
    <Space h={"xl"} />
    <Flex justify={"start"} direction="column">
      <Fieldset
        legend="Dados da Empresa"
        mb="sm"
        ref={referenceFieldsetEmpresa}
        style={{
          backgroundColor: focusedFieldsetEmpresa ? FOCUS_SECTION_COLOR : "transparent"
        }}
      >
        <Group gap="md">
          <CnpjInput
            {...form.getInputProps("cnpj")}
            {...extractTextInputPropertiesFromFormSchema(conviteFieldsDescriptor, "cnpj")}
          />
          <TextInput
            type="text"
            style={{ flex: 1 }}
            label="Razão social"
            onChange={onChange("razaoSocial", setNewValues)}
            value={newValues.razaoSocial}
          //
          />
        </Group>
        <Group gap="md">
          <TextInput
            type="text"
            style={{ flex: 1 }}
            {...form.getInputProps("nome")}
            {...extractTextInputPropertiesFromFormSchema(conviteFieldsDescriptor, "nome")}
            label="Nome Fantasia"
          />
          <TextInput
            type="email"
            label="E-mail"
            onChange={onChange("email", setNewValues)}
            value={newValues.email}

          />
          <TelInput
            w="140px"
            label="Telefone"
            onChange={onChange("telefone", setNewValues)}
            value={newValues.telefone}

          />
        </Group>
        <Group gap="md">
          <TextInput
            type="text"
            label="Código CNAE"
            onChange={onChange("codigoCNAE", setNewValues)}
            value={newValues.codigoCNAE}

          />
          <TextInput
            type="text"
            style={{ flex: 1 }}
            label="CNAE"
            onChange={onChange("cnae", setNewValues)}
            value={newValues.cnae}

          />
        </Group>
        <Group gap="md">
          <Select
            data={porteData}
            label="Porte"
            onChange={onChangeString("porte", setNewValues)}
            value={newValues.porte}

          />
          <Select
            data={numeroColaboradoresData}
            label="Número de colaboradores"
            onChange={onChangeString("numerocolaboradores", setNewValues)}
            value={newValues.numerocolaboradores}

          />
          <TagsInput
            style={{ flex: 1 }}
            label="Setores"
            onChange={onChangeArray("setores", setNewValues)}

            value={newValues.setores}
          />
        </Group>
      </Fieldset>
      <Fieldset
        legend="Dados do Responsável"
        mb="sm"
        ref={referenceFieldsetResponsavel}
        style={{
          backgroundColor: focusedFieldsetResponsavel ? FOCUS_SECTION_COLOR : "transparent"
        }}
      >
        <Group gap="md">
          <TextInput
            type="text"
            style={{ flex: 1 }}
            label="Nome"
            onChange={onChange("nome", setUserValues)}

            value={userValues.nome}
          />
          <TextInput
            type="text"
            style={{ flex: 1 }}
            {...form.getInputProps("email")}
            {...extractTextInputPropertiesFromFormSchema(conviteFieldsDescriptor, "email")}
            label="email"
          />
          <TelInput
            w="140px"
            label="Telefone"
            onChange={onChange("telefone", setUserValues)}

            value={userValues.telefone}
          />
        </Group>
      </Fieldset>

      <Fieldset
        legend="Endereço"
        ref={referenceFieldsetEndereco}
        style={{
          backgroundColor: focusedFieldsetEndereco ? FOCUS_SECTION_COLOR : "transparent"
        }}
      >
        <Group gap="md">
          <CepInput
            w="100px"
            label="CEP"
            onChange={onChange("cep", setEnderecoValues)}

            value={enderecoValues.cep}
          />
          <TextInput
            type="text"
            style={{ flex: 1 }}
            label="Logradouro"
            onChange={onChange("logradouro", setEnderecoValues)}

            value={enderecoValues.logradouro}
          />
          <TextInput
            type="text"
            w="100px"
            label="Número"
            onChange={onChange("numero", setEnderecoValues)}

            value={enderecoValues.numero}
          />
          <TextInput
            type="text"
            w="150px"
            label="Complemento"
            onChange={onChange("complemento", setEnderecoValues)}
            value={enderecoValues.complemento}
          />
        </Group>
        <Group gap="md">
          <TextInput
            type="text"
            style={{ flex: 1 }}
            label="Bairro"
            onChange={onChange("bairro", setEnderecoValues)}

            value={enderecoValues.bairro}
          />
          <TextInput
            type="text"
            style={{ flex: 1 }}
            label="Cidade"
            onChange={onChange("cidade", setEnderecoValues)}

            value={enderecoValues.cidade}
          />
          <Select
            data={estadosData}
            style={{ flex: 1 }}
            label="Estado"
            onChange={onChangeString("estado", setEnderecoValues)}
            value={enderecoValues.estado}

          />
        </Group>
      </Fieldset>
      <Space h={"md"} />
      <Text fw={"bold"}>Termo de Participação</Text>
      <Space h={"md"} />
      <Text size="sm" color={"#151619"}>Ao fornecer as minhas informações e confirmar a minha inscrição, estou ciente que receberei comunicações da Innovei, de acordo com meus interesses</Text>
      <Space h={"md"} />
      <Text size="sm" color={"#151619"}>Acesse e leia o regulamento aqui.</Text>
      <Space h={"md"} />
      <Checkbox
        label="Estou ciente do regulamento do Prêmio Empresa Inovadora e da Política de Privacidade."
        onChange={onChangeTermos("ciente", setTermos)}
        checked={termos.ciente}
      />
      <Space h={"md"} />
      <Checkbox
        label="Declaro que minha empresa não é uma startup e também não é a representante de um grupo econômico ou holding. Estou ciente de que o não cumprimento desta declaração pode resultar
        na desclassificação da minha empresa no processo em questão.
        Para mais informações, ver Artigo 2⁰ do regulamento."
        onChange={onChangeTermos("declaracao", setTermos)}
        checked={termos.declaracao}
      />
      <Space h={"md"} />
      <Group justify={"end"} >
        <Button variant="transparent" color={"#525356"} onClick={onClear(setNewValues, setEnderecoValues, setUserValues, form)}>Limpar</Button>
        <Button
          variant="filled"
          bg={"#151619"}
          disabled={verificationDisabled(termos, form.values as { cnpj: string; nome: string; email: string })}
          onClick={saveValues(form.values as { cnpj: string; nome: string; email: string }, setCompany, newValues, userValues, enderecoValues, setLoading, formatEmpresas)}>
          Confirmar Inscrição
        </Button>
      </Group>
    </Flex>
    <Space h={"xl"} />

  </Container>
};

export default FormInscricaoDoProjeto;

// prettier-ignore
const onChange = (field: string, setValues: React.Dispatch<React.SetStateAction<NewValues | EnderecoValues | UserValues>>) => {
  return (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues(values => ({ ...values, [field]: event.target.value }));
  };
};
// prettier-ignore
const onChangeString = (field: string, setValues: React.Dispatch<React.SetStateAction<NewValues | EnderecoValues | UserValues>>) => {
  return (event: string) => {
    setValues(values => ({ ...values, [field]: event }));
  };
};
// prettier-ignore
const onChangeArray = (field: string, setValues: React.Dispatch<React.SetStateAction<NewValues | EnderecoValues | UserValues>>) => {
  return (event: []) => {
    setValues(values => ({ ...values, [field]: event }));
  };
};
// prettier-ignore
const onChangeTermos = (field: string, setValues: React.Dispatch<React.SetStateAction<Termos>>) => {
  return (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues(values => ({ ...values, [field]: event.target.checked }));
  };
};
// prettier-ignore
const onClear = (setNewValues: React.Dispatch<React.SetStateAction<NewValues>>,
  setEnderecoValues: React.Dispatch<React.SetStateAction<EnderecoValues>>,
  setUserValues: React.Dispatch<React.SetStateAction<UserValues>>,
  form: UseFormReturnType<object, (values: object) => object>) => {
  return () => {
    setNewValues(newValuesOld)
    setEnderecoValues(newEnderecoOld)
    setUserValues(newUserOld)
    form.reset();
  };
}

// prettier-ignore
const verificationDisabled = (termos: Termos, values: { cnpj: string; nome: string; email: string }) => {
  if (termos.ciente === false || termos.declaracao === false) {
    return true
  } else if (!values.cnpj || values.cnpj === "") {
    return true
  } else if (!values.email || values.email === "") {
    return true
  } else if (!values.nome || values.nome === "") {
    return true
  } else {
    return false
  }
};

// prettier-ignore
const saveValues = (
  values: { cnpj: string; nome: string; email: string },
  setCompany: (argument0: DocumentReference<DocumentData, DocumentData>) => void,
  newValues: NewValues,
  userValues: UserValues,
  enderecoValues: EnderecoValues,
  setLoading: Dispatch<SetStateAction<boolean>>,
  formatEmpresas: TEmpresa[]
) => {
  return () => {
    const { cnpj, nome, email } = values;
    const { cnae, codigoCNAE, numerocolaboradores, porte, razaoSocial, setores, telefone, } = newValues;
    const { nome: nomeUser, telefone: telefoneUser } = userValues;
    const { bairro, cep, cidade, complemento, estado, logradouro, numero } = enderecoValues;

    try {
      setLoading(true)

      if (formatEmpresas.some(item => item.cnpj === cnpj)) {
        throw new Error("Empresa ja cadastrada!");
      }

      addEmpresa({
        cnpj,
        nomeFantasia: nome,
        email,
        createdAt: serverTimestamp(),
        createdBy: "",
        razaoSocial: razaoSocial,
        telefone: telefone,
        codigoCNAE: codigoCNAE,
        cnae: cnae,
        porte: porte as TPorte,
        setores: setores,
        status: "ATIVO",
        image: [],
        numeroColaboradores: numerocolaboradores as TNumeroColaboradores,
        responsavel: {
          email,
          nome: nomeUser,
          telefone: telefoneUser,
        },
        colaboradores: [],
        endereco: {
          cep: cep,
          logradouro: logradouro,
          numero: numero,
          complemento: complemento,
          bairro: bairro,
          estado: estado as TEstado,
          cidade: cidade
        },
        embaixadores: [],
        role: "NORMAL",
        deletedAt: null
      })
        .then((empresa) => {
          setCompany(empresa);
          return empresa;
        })
        .catch(() => {
          setLoading(false)
          return false;
        });
    } catch (error) {
      if (error instanceof Error) {
        showNotification({
          message: error.message,
          color: "red",
          autoClose: 2500
        });
        setLoading(false)
      } else {
        showNotification({
          message: "Unknown error",
          color: "red",
          autoClose: 2500
        });
        setLoading(false)
      }

    }
  };
};

// prettier-ignore
const newValuesOld = {
  cnae: "",
  codigoCNAE: "",
  email: "",
  numerocolaboradores: "",
  porte: "",
  razaoSocial: "",
  setores: [],
  telefone: "",
}

// prettier-ignore
const newEnderecoOld = {
  bairro: "",
  cep: "",
  cidade: "",
  complemento: "",
  estado: "",
  logradouro: "",
  numero: ""
}

// prettier-ignore
const newUserOld = {
  nome: "",
  telefone: ""
}

// prettier-ignore
const newTermos = {
  ciente: false,
  declaracao: false
}
