import { Box, Button, Checkbox, Group, LoadingOverlay, Stack, Text, TextInput, Title } from "@mantine/core";
import { modals } from "@mantine/modals";
import { MouseEvent } from "react";
import useLoginForm from "./useLoginForm";

const LoginForm = () => {
  const { getInputProps, onSubmit, isPending, values } = useLoginForm();

  /**
   * Open the forgot password modal
   * @param event - The click event
   */
  function handleForgotPasswordClick(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    modals.openContextModal({
      modal: "forgot-password-modal",
      size: "lg",
      innerProps: {
        email: values.email
      }
    });
  }

  return (
    <form onSubmit={onSubmit}>
      <Stack gap="xl">
        <Box pos="relative">
          <LoadingOverlay visible={isPending} />
          <Stack gap="sm">
            <Title>Login</Title>
            <Text c="gray" pb="lg">
              Seja bem-vindo ao sistema Innovei, faça seu login para acessar.
            </Text>
            <TextInput
              withAsterisk
              placeholder="e-mail"
              size="lg"
              type="email"
              autoComplete="email"
              disabled={isPending}
              {...getInputProps("email")}
            />
            <div>
              <TextInput
                withAsterisk
                placeholder="senha"
                size="lg"
                type="password"
                autoComplete="current-password"
                disabled={isPending}
                {...getInputProps("password")}
              />
              <div>
                <Button
                  color="#008c3c"
                  type="button"
                  onClick={handleForgotPasswordClick}
                  variant="subtle"
                  size="xs"
                  disabled={isPending}
                >
                  Esqueci a senha
                </Button>
              </div>
            </div>
            <Checkbox label="Lembrar-me por 30 dias" />
          </Stack>
        </Box>
        <Group justify="center">
          <Button color="#008c3c" type="submit" size="md" loading={isPending} radius="lg" fullWidth>
            Acessar
          </Button>
          <Group justify="center" gap={5}>
            <Text size="sm">Ainda não tem uma conta?</Text>
            <Text c="#008c3c">Cadastre-se!</Text>
          </Group>
        </Group>
      </Stack>
    </form>
  );
};

export default LoginForm;
