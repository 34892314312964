import { Text, Group, Badge, ThemeIcon, BadgeProps } from "@mantine/core";
import { IconMessages } from "@tabler/icons-react";
import useCountTotalComentariosPerIdeia from "@/modules/ideia-comentarios/hooks/useCountTotalComentariosPerIdeia";

const TotalComentariosBadge = ({ ideia, ...badgeProperties }: BadgeProps & { ideia: TIdeia }) => {
  const { data: totalComentariosNaIdeia } = useCountTotalComentariosPerIdeia(ideia);

  return (
    <Group align="center" gap="xs">
      <Badge
        color="gray.2"
        py="sm"
        radius="md"
        leftSection={
          <ThemeIcon variant="light" size="sm" bg="transparent">
            <IconMessages />
          </ThemeIcon>
        }
        {...badgeProperties}
      >
        <Text c="dark" fz="xs" fw={500} tt="lowercase">
          {totalComentariosNaIdeia} comentários
        </Text>
      </Badge>
    </Group>
  );
};

export default TotalComentariosBadge;
