import { DocumentReference } from "firebase/firestore";
import { AnyObject, ObjectSchema } from "yup";
import colaboradorSchema from "@/modules/colaboradores/schema/colaborador-schema";
import useUserStore from "@/modules/users/store";
import schema from "@/schema";
import auditSchema from "@/schema/audit-schema";
import { getDocumentReferenceFromPath } from "@/utils/firestore";
import { TDesafioComentarioForm, desafioComentarioConverter } from "./firestore";

const MIN_TEXT_COMMENT = 2;

// type TDesafioComentarioContext = {
//   desafioId: TDesafio["id"];
// };

type TAutor = Required<Pick<TColaborador, "nome" | "email" | "role" | "refPath">>;

const autorSchema: ObjectSchema<TAutor, AnyObject, TAutor, "d"> = schema
  .object({
    refPath: schema.string().required().defined().label("Autor")
  })
  // eslint-disable-next-line unicorn/prefer-spread
  .concat(colaboradorSchema.pick<"nome" | "email" | "role">(["nome", "email", "role"]).required().defined())
  .default(() => {
    const colaborador = useUserStore.getState().colaborador;

    if (colaborador) {
      return {
        nome: colaborador.nome || colaborador.email,
        email: colaborador.email,
        role: colaborador.role || "MEMBER",
        refPath: colaborador.refPath
      };
    }

    throw new Error("Colaborador não encontrado");
  })
  .noUnknown()
  .strict();

// TDesafioComentarioContext, TDesafioComentarioForm;
const desafioComentarioSchema: ObjectSchema<TDesafioComentarioForm> = auditSchema
  .clone()
  // eslint-disable-next-line unicorn/prefer-spread
  .concat(
    schema.object({
      comentario: schema.string().min(MIN_TEXT_COMMENT).trim().required().defined().default("").label("Comentário"),
      autor: autorSchema.required().defined().label("Autor"),
      colaboradoresMencionadosReferences: schema
        .mixed<DocumentReference<TColaborador>[] | string[]>()
        .defined()
        .required()
        .transform((values: (DocumentReference<TDesafioComentario> | string)[]) => {
          return values.map((value) => {
            const isString = typeof value === "string";
            return isString ? getDocumentReferenceFromPath(value, desafioComentarioConverter) : value;
          });
        })
        .default([])
        .label("Colaboradores mencionados"),
      respostaAoComentarioReference: schema
        .mixed<DocumentReference<TDesafioComentario>>()
        .nullable()
        .transform((value: string | DocumentReference<TDesafioComentario>) => {
          const isString = typeof value === "string";
          return isString ? getDocumentReferenceFromPath(value, desafioComentarioConverter) : value;
        })
        .default(null)
        .label("Resposta ao comentário")
    })
  )
  .noUnknown()
  .strict()
  .label("Comentário");

export default desafioComentarioSchema;
