import { Card, Divider, Flex, Group, Space, Text } from "@mantine/core";
import { IconBuildingSkyscraper, IconUsersGroup } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { getRepostasArrayNewUser } from "@/modules/responder-questionario/firestore";
import useUserStore from "@/modules/users/store";
import useQueryQuestionario from "./useQueryQuestionarios";

const message = "";

interface Externo {
  role: string;
  createdBy: string;
  nome: string;
  email: string;
  id: string;
  createdAt: Date;
  deletedAt: null;
  refPath: string;
  respondido: boolean;
  pausado: boolean;
  idQuestionario: string;
}

// prettier-ignore
const CardDiagnostico = ({
  titulo,
  empresa,
  colaborador
}: {
  titulo: string;
  empresa?: boolean;
  colaborador?: boolean;
}) => {
  const projeto = useUserStore((state) => state.activeProjeto);
  const { data: dataQuestionario } = useQueryQuestionario();
  // const { data: dataRespostas } = useQueryRespostas();
  const [dataRespostas, setDataRespostas] = useState<Externo[] & TResposta[]>([]);
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadData();
      } catch (error) {
        console.error(message, error);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, [projeto]);

  const loadData = async () => {
    if (dataQuestionario && dataQuestionario.length > 0) {
      const verificationQuestionario = dataQuestionario?.filter(item => item.idProjeto === projeto)
      let newData = [] as Externo[] & TResposta[];
      for (const item of verificationQuestionario) {
        const respostas = await getRepostasArrayNewUser(activeEmpresaId || "", item.id);
        const format = respostas.docs.map((resposta) => resposta.data()) as Externo[] & TResposta[];
        newData = [...newData, format].flat() as Externo[] & TResposta[]
      }
      setDataRespostas(newData.filter(item => !item.pausado) as Externo[] & TResposta[])

    }

  }

  return (
    <Card withBorder style={{ flex: 1 }} p="lg" pos="relative">
      <Group>
        {colaborador ? (
          <></>
        ) : empresa ? (
          <IconBuildingSkyscraper color={"rgba(57, 106, 249, 1)"} />
        ) : (
          <IconUsersGroup color={"rgba(57, 106, 249, 1)"} />
        )}
        <Text size="sm">{titulo}</Text>
      </Group>
      <Space h="md" />
      <Text size="xl" fw={700}>
        {calcPercentage(dataQuestionario?.filter(item => item.idProjeto === projeto), dataRespostas?.filter(item => dataQuestionario && dataQuestionario?.filter(row => row.id === item.idQuestionario)?.length > 0))}
      </Text>
      <Divider my="md" />
      <Group justify={"space-between"}>
        <Flex direction="column">
          <Text size="xs">Ultimos 30 dias</Text>
          <Text size="xl" fw={700}>
            {lastDates(dataRespostas?.filter(item => dataQuestionario && dataQuestionario?.filter(row => row.id === item.idQuestionario)?.length > 0) as Externo[] & TResposta[])}
          </Text>
        </Flex>
        {/* <Group justify="flex-end" align="flex-end">
          <IconChartInfographic color={"rgba(105, 198, 105, 1)"} />
          <Text color={"rgba(105, 198, 105, 1)"} size="xs">
            acréscimo de 3,5%
          </Text>
        </Group> */}
      </Group>
    </Card>
  );
};

export default CardDiagnostico;

/**
 * Get percentage of questionarios and respostas
 * @param questionarios - List questionarios
 * @param respostas - List respostas
 * @returns - Percentage
 */
function calcPercentage(questionarios: TQuestionario[] | undefined, respostas: Externo[] | undefined) {
  const lengthQuest: number | undefined = questionarios?.length;
  // prettier-ignore
  const lengthResp: number | undefined = questionarios?.filter(item => respostas && respostas?.filter(row => item.id === row.idQuestionario).length > 0)?.length;

  if (lengthQuest && lengthResp) {
    // eslint-disable-next-line no-magic-numbers
    const calc = (lengthResp / lengthQuest) * 100;
    return calc.toFixed(0) + "%";
  }

  return "-";
}

/**
 * Get percentage of questionarios and respostas
 * @param respostas - List respostas
 * @returns - Percentage
 */
function lastDates(respostas: (Externo[] & TResposta[]) | undefined) {
  const numberRepostas: number | undefined = respostas?.filter((item: TResposta) => {
    const dataCriacao = new Date(item.createdAt);
    const dataLimite = new Date();
    // eslint-disable-next-line no-magic-numbers
    dataLimite.setDate(dataLimite.getDate() - 30); // Subtrai 30 dias da data atual

    return dataCriacao >= dataLimite;
  })?.length;

  if (numberRepostas) {
    return numberRepostas;
  }

  return "-";
}
