/* eslint-disable compat/compat */
import { collection, getDocs, query, Timestamp, where } from "firebase/firestore";
import Papa, { ParseResult } from "papaparse";
import { colaboradorConverter } from "@/modules/colaboradores/firestore";
import { getEmpresaDocumentReference } from "@/modules/empresas/firestore";
import { getRegulamentoIdeiasDocumentReference } from "@/modules/regulamento-ideias/firestore";
import useUserStore from "@/modules/users/store";
import { addIdeia } from "../../firestore";

interface IdeiaCSV {
  [key: string]: string;
}

type IdeiaCSVParsed = {
  anexos: string;
  autorGostariaParticiparImplantacao: boolean;
  autorReference: string;
  comoAtingirObjetivo: string;
  descricao: string;
  descricaoProblema: string;
  diferenciais: string;
  feedback: string;
  objetivo: TIdeiaObjetivo[];
  participantesReferences: string;
  implementadoresReferences: string;
  permitirComentarios: boolean;
  permitirPatrocinio: boolean;
  privado: boolean;
  rankingByMoedas: string;
  rankingByIdeias: string;
  regulamentoReference: string;
  setores: string;
  status: TIdeiaStatus;

  titulo: string;
  regulamentoId: string;
};

/**
 * Method used to pick a CSV file
 * @returns CSV file text
 */
const pickCsvFile = async () => {
  const input = document.createElement("input");
  input.type = "file";
  input.accept = ".csv";
  input.multiple = false;

  input.click();

  // eslint-disable-next-line unicorn/prefer-add-event-listener
  await new Promise((resolve) => (input.onchange = resolve));
  const csvFile = input.files?.[0];
  if (!csvFile) {
    throw new Error("No file selected");
  }

  return await csvFile.text();
};

/**
 * Method used to parse a CSV file
 * @param csv CSV file text
 * @returns parsed CSV file
 */
const parseCsvFile = (csv: string): IdeiaCSVParsed[] => {
  const { data, errors }: ParseResult<IdeiaCSV> = Papa.parse(csv, { header: true });
  if (errors.length > 0 && errors[0]) {
    throw new Error(errors[0].message);
  }
  if (data.length === 0) {
    throw new Error("CSV file is empty or doesn't contain valid data");
  }
  return data.map(
    (ideia: IdeiaCSV): IdeiaCSVParsed =>
      ({
        ...ideia,
        autorGostariaParticiparImplantacao: ideia.autorGostariaParticiparImplantacao === "TRUE",
        permitirComentarios: ideia.permitirComentarios === "TRUE",
        permitirPatrocinio: ideia.permitirPatrocinio === "TRUE",
        privado: ideia.privado === "TRUE",
        objetivo: (ideia.objetivo?.split(",") ?? []) as TIdeiaObjetivo[],
        setores: ideia.setores ?? ""
      }) as IdeiaCSVParsed
  );
};

/**
 * Get colaboradores collection reference
 * @param empresaId - The id of the company that the colaboradores belong
 * @returns - colaboradores collection reference
 */
function getColaboradoresCollectionReference(empresaId: string) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, "colaboradores");
}

/**
 * Get colaborador by email
 * @param email - The email to filter colaboradores
 * @param empresaId - The id of the company that the colaboradores belong
 * @returns - Promise with the first colaborador found with the email
 */
async function getColaboradorByEmail(email: string, empresaId: string): Promise<TColaborador | undefined> {
  const colaboradoresCollectionReference =
    getColaboradoresCollectionReference(empresaId).withConverter(colaboradorConverter);
  const colaboradoresQuery = query(colaboradoresCollectionReference, where("email", "==", email));
  const querySnapshot = await getDocs(colaboradoresQuery);
  return querySnapshot.docs.map((document_) => document_.data())[0];
}

/**
 * Method used to import a list of ideas from a CSV file
 * @returns object with the method to import ideas
 */
function useImportIdea(): { importIdeas: () => Promise<void> } {
  const { activeEmpresaId } = useUserStore();

  if (!activeEmpresaId) {
    throw new Error("No active company selected");
  }

  const saveIdeia = (ideia: IdeiaCSVParsed, colaborador: TColaborador) => {
    return addIdeia(activeEmpresaId, {
      anexos: [],
      anexosFilesToUpload: [],
      setores: ideia.setores.split(","),
      participantesReferences: [],
      implementadoresReferences: [],
      autorGostariaParticiparImplantacao: ideia.autorGostariaParticiparImplantacao,
      autorReference: colaborador.refPath,
      comoAtingirObjetivo: ideia.comoAtingirObjetivo,
      descricao: ideia.descricao,
      descricaoProblema: ideia.descricaoProblema,
      diferenciais: ideia.diferenciais,
      feedback: ideia.feedback,
      objetivo: ideia.objetivo,
      permitirComentarios: ideia.permitirComentarios,
      permitirPatrocinio: ideia.permitirPatrocinio,
      privado: ideia.privado,
      titulo: ideia.titulo,
      status: ideia.status,
      regulamentoReference: getRegulamentoIdeiasDocumentReference(activeEmpresaId, ideia.regulamentoId),
      createdBy: colaborador.id,
      createdAt: Timestamp.now(),
      cancelAt: null,
      deletedAt: null,
      publishedAt: ideia.status === "RASCUNHO" ? null : Timestamp.now()
    });
  };

  const importIdeas = async () => {
    const csv = parseCsvFile(await pickCsvFile());
    await Promise.all(
      csv.map(async (ideia) => {
        const colaborador = await getColaboradorByEmail(ideia.autorReference, activeEmpresaId);
        if (!colaborador) {
          return;
        }
        try {
          await saveIdeia(ideia, colaborador);
        } catch (error) {
          console.error(`Failed to save idea: ${ideia.titulo}`, error);
        }
      })
    );
  };

  return { importIdeas };
}

export default useImportIdea;
