/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import { CheckIcon, Combobox, Group, Pill, PillsInput, useCombobox } from "@mantine/core";
import { Timestamp } from "firebase/firestore";
import { useEffect, useState } from "react";
import useUserStore from "@/modules/users/store";
import { useAddEmbaixadorPessoa } from "./useAddEmbaixadorPessoa";
import { getEmbaixadorPessoa } from "../../firestore";

const message = "Ocorreu um erro ao carregar os dados:";

interface Options {
  value: string;
  label: string;
}

const EmbaixadorSelectMulti = ({ ...properties }) => {
  const colaborador = useUserStore((state) => state.colaborador);
  const empresa = useUserStore((state) => state.activeEmpresa);
  const { mutateAsync } = useAddEmbaixadorPessoa();
  const [listaEmbaixadores, setListaEmbaixadores] = useState<Options[]>([]);
  const [selecao, setSelecao] = useState<string[]>([]);
  const [search, setSearch] = useState<string>("");
  const [reload, setReload] = useState<boolean>(false);
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex("active")
  });

  useEffect(() => {
    setSelecao([]);
    if (properties.form.values.embaixadoresPessoas) {
      for (const item of properties.form.values.embaixadoresPessoas) {
        setSelecao((previous) => [...previous, item]);
      }
    }
    // eslint-disable-next-line unicorn/explicit-length-check, react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line sonarjs/no-identical-functions
    const fetchData = async () => {
      try {
        await loadEmbaixador();
      } catch (error) {
        console.error(message, error);
      }
    };
    void fetchData();
    // eslint-disable-next-line unicorn/explicit-length-check, react-hooks/exhaustive-deps
  }, [empresa, reload]);

  // eslint-disable-next-line sonarjs/no-identical-functions
  const loadEmbaixador = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    const arrayLicenciados = await getEmbaixadorPessoa(empresa?.id || "");
    const aux = arrayLicenciados.docs.map((desafio) => desafio.data());
    const data = aux
      .map((item) => ({ value: item.id, label: item.nome }))
      .sort((a, b) => a.label.localeCompare(b.label));
    setListaEmbaixadores(data);
  };

  const exactOptionMatch = listaEmbaixadores.some((item) =>
    item.label.toLowerCase().includes(search.toLowerCase().trim())
  );

  const handleValueRemove = (value_: string) => {
    setSelecao((previous) => previous.filter((v) => v !== value_));
    properties?.form?.setValues((values: TProjeto) => ({
      ...values,
      embaixadoresPessoas: selecao.filter((v) => v !== value_)
    }));
  };

  const itensComboBox = listaEmbaixadores
    .filter((item) => item.label.toLowerCase().includes(search.trim().toLowerCase()))
    .map((item) => (
      <Combobox.Option value={item.value} key={item.value} active={selecao.includes(item.value)}>
        <Group gap="sm">
          {selecao.includes(item.value) ? <CheckIcon size={12} /> : null}
          <span>{item.label}</span>
        </Group>
      </Combobox.Option>
    ));

  const createOption = async () => {
    try {
      const newEmbaixadorPessoa = await mutateAsync({
        nome: search,
        updatedAt: Timestamp.now(),
        createdAt: Timestamp.now(),
        deletedAt: null,
        createdBy: colaborador?.id || "",
        empresaId: empresa?.id || ""
      });
      const newItem = { value: newEmbaixadorPessoa.id, label: newEmbaixadorPessoa.id };
      setSelecao((previous) => [...previous, newItem.value]);
      properties?.form?.setValues((values: TProjeto) => ({
        ...values,
        embaixadoresPessoas: [...(values.embaixadoresPessoas ?? []), newItem.value]
      }));
    } catch (error) {
      console.error("Erro ao criar novo embaixador:", error);
    } finally {
      setSearch("");
      setReload((reload) => !reload);
    }
  };

  const values = selecao.map((item) => {
    const option = listaEmbaixadores.find((opt) => opt.value === item);
    if (!option) {
      return null;
    }
    return (
      <Pill key={item} withRemoveButton onRemove={() => handleValueRemove(item)}>
        {option?.label}
      </Pill>
    );
  });

  const handleValueSelect = (value_: string) => {
    if (value_ === "$create") {
      void createOption();
    } else {
      if (selecao.includes(value_)) {
        setSearch("");
        return;
      }
      const option = listaEmbaixadores.find((item) => item.value === value_);
      if (option) {
        setSelecao((previous) => [...previous, value_]);
        properties?.form?.setValues((values: TProjeto) => ({
          ...values,
          embaixadores: [...(values.embaixadores ?? []), value_]
        }));
        setSearch("");
      }
    }
    combobox.closeDropdown();
  };

  return (
    <Combobox {...properties} store={combobox} withinPortal={false} onOptionSubmit={handleValueSelect}>
      <Combobox.DropdownTarget>
        <PillsInput onClick={() => combobox.openDropdown()}>
          <Pill.Group>
            {values}
            <Combobox.EventsTarget>
              <PillsInput.Field
                onFocus={() => combobox.openDropdown()}
                onBlur={() => combobox.closeDropdown()}
                value={search}
                placeholder="Valor de busca"
                onChange={(event) => {
                  combobox.updateSelectedOptionIndex();
                  setSearch(event.currentTarget.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Backspace" && search.length === 0) {
                    event.preventDefault();
                    handleValueRemove(selecao.at(-1) || "");
                  }
                  combobox.openDropdown();
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>
      <Combobox.Dropdown>
        <Combobox.Options>
          {itensComboBox}
          {!exactOptionMatch && search.trim().length > 0 && (
            <Combobox.Option value="$create">
              + Criar <b>{search}</b>
            </Combobox.Option>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
// eslint-disable-next-line import/no-unused-modules
export default EmbaixadorSelectMulti;
