import { subject } from "@casl/ability";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { DocumentData, DocumentSnapshot, FirestoreDataConverter, FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { getDocumentSnapshotFromPath } from "@/utils/firestore";

type UseGetDocumentFromReferencePathOptions<T extends DocumentData> = {
  referencePath: string | null;
  converter: FirestoreDataConverter<T>;
  subjectType: Parameters<typeof subject>[0];
  queryKey: UseQueryOptions["queryKey"];
};

/**
 * Get document snapshot from reference path hook
 * @param root0 - Options
 * @param root0.queryKey - Query key to use in react-query cache
 * @param root0.referencePath - Path to get the document reference from firestore
 * @param root0.subjectType - Subject type to use in @casl/ability
 * @param root0.converter - Converter to convert the document data
 * @returns - Query result
 */
function useGetDocumentFromReferencePath<T extends DocumentData>({
  referencePath,
  queryKey,
  converter,
  subjectType
}: UseGetDocumentFromReferencePathOptions<T>) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  return useQuery<DocumentSnapshot<T, DocumentData>, FirestoreError, T | null>({
    enabled: !!referencePath,
    queryKey: [activeEmpresaId, ...queryKey, { referencePath }, converter],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Nenhuma empresa ativa");
      }
      if (!referencePath) {
        throw new Error("Path de referência não informado");
      }

      return getDocumentSnapshotFromPath(referencePath, converter);
    },
    select(data) {
      if (!data.exists()) {
        return null;
      }

      return subject(subjectType, data.data());
    }
  });
}

export default useGetDocumentFromReferencePath;
