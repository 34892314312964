import { Card, Text, Group, Button, Anchor } from "@mantine/core";
import { NavLink } from "react-router-dom";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import TiposDeInovacaoList from "@/modules/tipos-de-inovacao/components/tipo-de-inovacao-list";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import { dataAutorizacoes } from "@/src/routes/empresa-page/EmpresaPage";

// /**
//  *
//  */
// function handleIniciarClick(navigate: (path: string) => void) {
//   navigate("/tipos-de-inovacao/0");
// }
// prettier-ignore
const TiposDeInovacaoHeader = () => {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresa);
  const activeAutorizacao = useUserStore((store) => store.autorizacoes);
  const uid = getCurrentUser()?.uid;
  const { data: userColaborador } = useGetColaboradorById(activeEmpresaId?.id || "", uid || "");
  const autorizacao = dataAutorizacoes.find(item => item.label === userColaborador?.role);
  const formatAutorizacoes = activeAutorizacao?.filter(item => item.tipo === autorizacao?.value);

  // const navigate = useNavigate();
  return (
    <>
      <Card withBorder style={{ flex: 1, overflow: "auto" }}>
        <Card.Section inheritPadding py="md" withBorder>
          <Group justify="space-between" style={{ marginBottom: 20 }}>
            <Text size="xl" fw={500}>
              Tipos de Inovações
            </Text>
            {formatAutorizacoes?.find(item => item.tipoAutorizacao === "20")?.criacao ? <Anchor component={NavLink} color="rgba(8, 68, 244, 1)" to={`/tipos-de-inovacao/0`} underline="never">
              <Button color="rgba(8, 68, 244, 1)" variant="outline" radius="lg" size="md">
                <Text size="sm">Criar tipo de inovação</Text>
              </Button>
            </Anchor> : <></>}
          </Group>
          <TiposDeInovacaoList formatAutorizacoes={formatAutorizacoes} />
        </Card.Section>
      </Card>
    </>
  );
};

export default TiposDeInovacaoHeader;
