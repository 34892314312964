import { Button, Card, Group, Space, Text } from "@mantine/core";
import AutorizacaoItem from "../autorizacao-item";
import HeaderTable from "../header-table";
// prettier-ignore
const ListaDeAutorizacaoDePerfil = ({ select }: { select: string | null }) => {
  return (
    <>
      <HeaderTable />
      <Card padding="lg" radius={0}>
        {select === null ?
          <Group align={"center"} justify={"center"}>
            <Text size="md">Acesso não selecionado!</Text>
          </Group>
          : lista.map(item => <AutorizacaoItem key={item.id} item={item} />)}
        <Space h={"md"} />
        <Group justify={"flex-end"}>
          <Button variant="filled">Salvar</Button>
        </Group>
      </Card>
    </>
  );
};

export default ListaDeAutorizacaoDePerfil;

const lista = [
  { id: "1", label: "Analytics" },
  { id: "2", label: "Analytics-Votos" },
  { id: "3", label: "Autocadastro-Empresa" },
  { id: "4", label: "Categorias" },
  { id: "5", label: "Colaboradores" },
  { id: "6", label: "Colaboradores/Cadastro" },
  { id: "7", label: "Create-Account" },
  { id: "8", label: "Diagnostico" },
  { id: "9", label: "Embaixadores" },
  { id: "10", label: "Embaixadores/Cadastro" },
  { id: "11", label: "Empresas" },
  { id: "12", label: "Empresas/Cadastro" },
  { id: "13", label: "Grupos" },
  { id: "14", label: "Grupos/Cadastro" },
  { id: "15", label: "Home" },
  { id: "16", label: "Licenciados" },
  { id: "17", label: "Licenciados/Cadastro" },
  { id: "18", label: "Login" },
  { id: "19", label: "Modulos" },
  { id: "20", label: "Overview" },
  { id: "21", label: "Pages-Maintaince" },
  { id: "22", label: "Pagina-Popups" },
  { id: "23", label: "Pdf-Diagnostico" },
  { id: "24", label: "Perguntas" },
  { id: "25", label: "Perguntas/Cadastro" },
  { id: "26", label: "Popups" },
  { id: "27", label: "Profiles-Maintaince" },
  { id: "28", label: "Projetos" },
  { id: "29", label: "Projetos-Empresa" },
  { id: "30", label: "Projetos/Cadastro" },
  { id: "31", label: "Questionarios" },
  { id: "32", label: "Questionarios-Disponiveis" },
  { id: "33", label: "Questionarios-Empresa" },
  { id: "34", label: "Questionarios/Cadastro" },
  { id: "35", label: "Relatorios" },
  { id: "36", label: "Reset-Password" },
  { id: "37", label: "Respostas-Maintaince" },
  { id: "38", label: "Schedule-Maintaince" },
  { id: "39", label: "Status-Diagnostico" },
  { id: "40", label: "Templates" },
  { id: "41", label: "Templates/Cadastro" },
  { id: "42", label: "Tipos-Resposta" },
  { id: "43", label: "Tipos-Resposta/Cadastro" }
];
