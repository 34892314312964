/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable unicorn/no-array-for-each */
import { getDocs } from "firebase/firestore";
import { useState } from "react";
import { getEmpresaById, getEmpresasByCategoria } from "@/modules/empresas/firestore";
import { getRegiaoByEmpresaId } from "@/modules/regiao/firestore";
import useUserStore from "@/modules/users/store";
import { getMediaGruposCollectionReference } from "../firestore";
import { getMediaGrupoNota } from "../util";

type GrupoNota = {
  segment: string;
  value: number;
};

const getAllNotas = async (empresaId: TEmpresa["id"], questionarioId: TQuestionario["id"]) => {
  const query = getMediaGruposCollectionReference(empresaId, questionarioId);
  const resultFound = await getDocs(query);
  return resultFound.docs.map((document_) => document_.data());
};

const mapNotas = (notas: TMediaGrupo[], grupos: Map<string, TGruposDiagnostico>) => {
  const notasMap = new Map<string, TMediaGrupo[]>();
  notas.forEach((nota) => {
    const notas = notasMap.get(nota.grupoId) ?? [];
    notas.push(nota);
    notasMap.set(nota.grupoId, notas);
  });

  return [...grupos.values()].map((grupo) => {
    const notasOfGrupo = notasMap.get(grupo.id) ?? [];

    const notasSum = notasOfGrupo.reduce((a, nota) => a + nota.perguntasSum / nota.perguntasCount, 0);
    const nota = getMediaGrupoNota({ respostasCount: notasOfGrupo.length, perguntasSum: notasSum });

    return { segment: grupo.nome, value: nota };
  });
};

const isDatasetInvalid = (notas: GrupoNota[]) => {
  return notas.length === 0 || notas.some((nota) => Number.isNaN(nota.value));
};

/**
 * Hook para buscar os resultados de uma empresa
 * @returns Retorna um objeto com a função `load` para buscar os resultados e a variável `loading` para indicar se está carregando
 */
function useGetResultEmpresa() {
  const [loading, setLoading] = useState(false);

  const [fases, setFases] = useState<string[]>([]);

  const [resultEmpresa, setResultEmpresa] = useState<GrupoNota[]>([]);
  const [resultGeral, setResultGeral] = useState<GrupoNota[]>([]);
  const [resultRegiao, setResultRegiao] = useState<GrupoNota[]>([]);
  const [resultCategoria, setResultCategoria] = useState<GrupoNota[]>([]);

  const { activeEmpresaId } = useUserStore();

  // eslint-disable-next-line complexity
  const load = async (
    questionarioId: TQuestionario["id"],
    projetoId: TProjeto["id"],
    empresaId: TEmpresa["id"],
    grupos: TGruposDiagnostico[]
  ) => {
    if (!activeEmpresaId) return;

    const gruposMap = new Map<string, TGruposDiagnostico>();
    for (const grupo of grupos) {
      gruposMap.set(grupo.id, grupo);
    }

    setLoading(true);
    try {
      const allNotasFound = await getAllNotas(activeEmpresaId ?? "", questionarioId);
      setFases([...new Set(allNotasFound.map((item) => item.fase ?? ""))]);

      const calcResultGeral = mapNotas(allNotasFound, gruposMap);
      if (isDatasetInvalid(calcResultGeral)) {
        setResultGeral([]);
      } else {
        setResultGeral(calcResultGeral);
      }

      const notasEmpresa = allNotasFound.filter((nota) => nota.empresaId === empresaId);
      const calcResultEmpresa = mapNotas(notasEmpresa, gruposMap);

      if (isDatasetInvalid(calcResultEmpresa)) {
        setResultEmpresa([]);
      } else {
        setResultEmpresa(calcResultEmpresa);
      }

      const regiao = await getRegiaoByEmpresaId(activeEmpresaId, projetoId, empresaId);
      if (regiao) {
        const notasRegiao = allNotasFound.filter((nota) => regiao.empresas.includes(nota.empresaId));
        const calcResultRegiao = mapNotas(notasRegiao, gruposMap);
        if (isDatasetInvalid(calcResultRegiao)) {
          setResultRegiao([]);
        } else {
          setResultRegiao(calcResultRegiao);
        }
      }

      const empresa = await getEmpresaById(empresaId);
      const empresaData = empresa.data();
      if (empresaData && empresaData.categorias) {
        const empresasOfCategoria = await getDocs(getEmpresasByCategoria(empresaData.categorias));
        const empresasIds = new Set(empresasOfCategoria.docs.map((document_) => document_.id));
        const notasEmpresasOfCategoria = allNotasFound.filter((nota) => empresasIds.has(nota.empresaId));
        const calcResultEmpresasOfCategoria = mapNotas(notasEmpresasOfCategoria, gruposMap);

        if (isDatasetInvalid(calcResultEmpresasOfCategoria)) {
          setResultCategoria([]);
        } else {
          setResultCategoria(calcResultEmpresasOfCategoria);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    load,
    loading,
    resultEmpresa,
    resultGeral,
    resultCategoria,
    resultRegiao,
    fases
  };
}

export { useGetResultEmpresa };
