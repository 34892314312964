import { Button, Drawer, Group, ScrollArea, Text } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useForm, yupResolver } from "@mantine/form";
import { IconRestore } from "@tabler/icons-react";
import { ComponentProps } from "react";
import { Asserts } from "yup";
import ColaboradorMultiSelector from "@/modules/colaboradores/components/colaborador-multi-selector";
import { extractTextInputPropertiesFromFormSchema } from "@/utils/form";
import filterSchema from "./filter-schema";

type FormInputs = Asserts<typeof filterSchema>;

/**
 * Form for filters drawer
 * @param filterValues - Filter values
 * @returns Form for filters drawer
 */
function useFilterForm(filterValues: FormInputs) {
  return useForm<FormInputs>({
    initialValues: filterSchema.cast(filterValues, { stripUnknown: true, assert: false }),
    validate: yupResolver(filterSchema),
    transformValues: (values) => filterSchema.cast(values, { stripUnknown: true })
  });
}

type FiltersDrawerProperties = ComponentProps<typeof Drawer> & {
  filters: FormInputs;
  onFiltersApplied: (values: FormInputs) => void;
};

const FiltersDrawer = ({ filters, onFiltersApplied, onClose, ...drawerProperties }: FiltersDrawerProperties) => {
  const form = useFilterForm(filters);
  const fieldsDescriptor = filterSchema.describe();

  /**
   * Handle submit form
   * @param values - Values
   */
  function handleSubmit(values: FormInputs) {
    onFiltersApplied(values);
    onClose();
  }

  /**
   * Handle restore click
   */
  function handleRestoreClick() {
    onFiltersApplied(filterSchema.getDefault());
    form.reset();
    onClose();
  }

  return (
    <Drawer
      {...drawerProperties}
      onClose={onClose}
      scrollAreaComponent={ScrollArea.Autosize}
      size="sm"
      title={<Text fw="bold">Filtrar</Text>}
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <ColaboradorMultiSelector
          style={{ flex: 1 }}
          searchable
          multiple
          nothingFoundMessage="Nenhum colaborador encontrado..."
          {...form.getInputProps("colaboradoresReferences")}
          {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "colaboradoresReferences")}
        />

        <DatePickerInput
          style={{ flex: 1 }}
          type="range"
          valueFormat="DD [de] MMMM, YYYY"
          {...form.getInputProps("datePrazoRange")}
          {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "datePrazoRange")}
          label="Período"
        />

        <Group justify="space-between" mt="lg">
          <Button
            type="button"
            variant="subtle"
            color="orange"
            size="compact-sm"
            leftSection={<IconRestore size="1rem" />}
            onClick={handleRestoreClick}
          >
            Restaurar
          </Button>
          <Button type="submit">Aplicar</Button>
        </Group>
      </form>
    </Drawer>
  );
};

export default FiltersDrawer;
