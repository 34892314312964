import { FocusTrap, ScrollArea, Space, Title } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import ListFormCronograma from "../../components/list-form-cronograma";
import NewFormModal from "../../components/new-form-modal";
import { TCronogramaForm } from "../../firestore";
// prettier-ignore
const ModalCronogramaDesafio = ({
  context,
  innerProps,
  id,
}: ContextModalProps<{ item: TCronogramaForm; data: TCronogramaDesafio[] | undefined, refetch: () => void; }>) => {
  const { data, refetch } = innerProps;

  /**
   * Handle add ignore area success
   */
  function onSuccess() {
    context.closeModal(id);
  }

  return (
    <FocusTrap active={true}>
      <Title order={4}>Agendamento da imersão no desafio</Title>
      <Space h={"md"} />
      {data && data?.length > 1 ? <>
        <ScrollArea h={600} p={20}>
          {data.map(item => <ListFormCronograma key={item.id} data={item} onSuccess={onSuccess} refetch={refetch} />)}
        </ScrollArea>
      </> : data && data?.length === 1 ? <NewFormModal data={data && data[0]} onSuccess={onSuccess} refetch={refetch} /> : <></>}
    </FocusTrap>
  );
};

export default ModalCronogramaDesafio;
