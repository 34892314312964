import { Avatar, Card, Flex, Group, Space, Text } from "@mantine/core";
import useGetProjetoFromUrl from "@/modules/diagnostico/components/ranking-list/hooks/useGetProjetoFromUrl";
import ValorResposta from "./ValorRespostaComponente";

interface Campo {
  campo: string;
  valor: string;
}
interface respostas {
  id: string;
  resposta?: string | number | Campo | Campo[] | boolean;
}

// prettier-ignore
const EstrategiaItem = ({ pergunta, numero, /* respostas */ }: { pergunta: TPergunta | null | undefined, numero: number, respostas: respostas[] }) => {
  const { data } = useGetProjetoFromUrl();
  const fases = data?.fases || [];

  return (
    <>
      <Space h="md" />
      <Card withBorder shadow="none" style={{ flex: 1 }}>
        <Flex direction="column">
          <Flex gap="xs" direction="row" wrap="wrap">
            <Avatar bg="#6188FA" color={"white"} radius="xl" size="sm">
              {numero}
            </Avatar>
            <Text fw={500} fz="md">
              {pergunta?.nome}
            </Text>
          </Flex>
          <ValorResposta /* resposta={resposta} */ />
          <Space h={"md"} />
          <Flex gap="xl" align="center" direction="row" wrap="wrap">
            {fases.map((item: { titulo: string; id: string }) => <Group key={item.id}>
              <Text fw={"bold"} fz="sm">
                {item?.titulo}
              </Text>
            </Group>)}
          </Flex>
        </Flex>
      </Card>
    </>
  );
};

export default EstrategiaItem;
