import { Badge } from "@mantine/core";

const RegulamentoDesafioStatus = ({ regulamento }: { regulamento: TRegulamentoDesafios }) => {
  const dataFinal = new Date(regulamento.dataFinal);
  // eslint-disable-next-line no-magic-numbers
  dataFinal.setHours(23, 59, 59, 999);
  const isConcluido = dataFinal < new Date();
  if (isConcluido) {
    <Badge color="green" size="sm">
      Concluído
    </Badge>;
  }

  const dataInicial = new Date(regulamento.dataInicial);
  dataInicial.setHours(0, 0, 0, 0);
  const isEmAndamento = dataInicial < new Date() && dataFinal > new Date();
  if (!isEmAndamento) {
    return null;
  }

  return (
    <Badge color="lime" size="sm">
      Em andamento
    </Badge>
  );
};

export default RegulamentoDesafioStatus;
