// import Can from "@/components/can";
import { Card, Group, Text, Flex, Space, Badge, SimpleGrid } from "@mantine/core";
import React from "react";
import JogadasCategoriaLabel from "@/modules/jogadas-de-inovacao/components/jogadas-categoria-label";
import JogadasTipoLabel from "@/modules/jogadas-de-inovacao/components/jogadasTipoLabel";

interface Tatica {
  id: string | number;
  nome: string;
  descricao: string;
  nomeCategoria: string | undefined;
  nomeTipo: string | undefined;
  tipo?: string | undefined;
  categoria?: string | undefined;
  corVinculos?: string | undefined;
}
// prettier-ignore
const TaticasListCard: React.FC<{
  jogada: Tatica;

}> = ({ jogada }) => {
  return (
    <>
      <Card withBorder radius="lg" style={{ border: jogada && jogada?.corVinculos ? `5px solid ${jogada?.corVinculos}` : "1px solid rgb(226,230,233)" }}>
        <Flex justify={"space-between"}>
          <Group>
            <Badge radius="md" size="xl" variant="transparent" style={{ background: "rgba(244, 245, 246, 1)" }}>
              <Group>
                <Text tt="capitalize" fz="xs" fw={400} color={"rgb(118,119,125)"}>
                  Categoria de Inovação:
                </Text>
                <JogadasCategoriaLabel id={jogada?.categoria || ""} />
              </Group>
            </Badge>
            <Badge radius="md" size="xl" variant="transparent" style={{ background: "rgba(244, 245, 246, 1)" }}>
              <Group>
                <Text tt="capitalize" fz="xs" fw={400} color={"rgb(118,119,125)"}>
                  Tipo de Inovação:
                </Text>
                <JogadasTipoLabel id={jogada?.tipo || ""} />
              </Group>
            </Badge>
          </Group>
        </Flex>
        <Space h="xl" />
        <SimpleGrid cols={2}>
          <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
            <Text fw={300} fz="xs">
              Tática
            </Text>
            <Text fw={500} fz="md">
              {jogada.nome}
            </Text>
          </Flex>
          <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
            <Text fw={300} fz="xs">
              Setor
            </Text>
            <Text fw={500} fz="md">
              {jogada.nome}
            </Text>
          </Flex>
        </SimpleGrid>
      </Card>
      <Space h="xl" />
    </>
  );
};

export default TaticasListCard;
