import { ActionIcon, Menu } from "@mantine/core";
import { IconCopy, IconDotsVertical, IconEdit, IconTrash } from "@tabler/icons-react";
import EditItemButton from "@/components/edit-item-button";
import { useGetAuthorizations } from "@/hooks/useGetAuthorizations";
import projetoSchema from "../../schema/projeto-schema";
import { CopyAutocadastroUrlButton } from "../copy-autocadastro-url-button/CopyAutocadastroUrlButton";
import RemoveProjetoButton from "../remove-projeto-button";

const ContextMenuRow = ({ projeto }: { projeto: TProjeto }) => {
  const { update, remove } = useGetAuthorizations("13");

  return (
    <Menu shadow="md" width={200} position="left" withArrow arrowPosition="center">
      <Menu.Target>
        <ActionIcon variant="transparent" aria-label="Menu" color="gray">
          <IconDotsVertical />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item leftSection={<IconCopy size={14} />} component={CopyAutocadastroUrlButton} projeto={projeto}>
          Url de auto-cadastro
        </Menu.Item>
        {update && (
          <Menu.Item
            leftSection={<IconEdit size={14} />}
            component={EditItemButton}
            modal="projeto-form-modal"
            schema={projetoSchema}
            item={projeto}
          >
            Editar
          </Menu.Item>
        )}

        {remove && (
          <>
            <Menu.Divider />
            <Menu.Item
              color="red"
              leftSection={<IconTrash size={14} />}
              component={RemoveProjetoButton}
              projeto={projeto}
            >
              Remover
            </Menu.Item>
          </>
        )}
      </Menu.Dropdown>
    </Menu>
  );
};

export default ContextMenuRow;
