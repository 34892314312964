import { Anchor, Badge, Card, Grid, Group, MantineColor, Stack, Text, ThemeIcon } from "@mantine/core";
import { IconFileAnalytics } from "@tabler/icons-react";
import { NavLink } from "react-router-dom";
import useGetAllIdeiasFromComite from "@/modules/comite-inovacao/hooks/useGetAllIdeiasFromComite";
// prettier-ignore
const StatusItem = ({ text, total, color, ideias }: { text: string; total: number; color: MantineColor, ideias: TIdeia[] }) => (
  <Grid.Col span="content">
    <Stack gap={2}>
      <Badge color={color} size="sm" radius="sm">
        {text}
      </Badge>
      <Anchor component={NavLink} to={`/ideias?commite-filter=${ideias.map(item => item.id).join(',')}`}>
        <Text fz="lg" fw={500}>
          {total === 1 ? `${total} ideia` : `${total} ideias`}
        </Text>
      </Anchor>
    </Stack>
  </Grid.Col>
);

/**
 * Agrupa as ideias por status
 * @param ideias - Ideias que serão agrupadas
 * @returns - Objeto com as ideias agrupadas por status
 */
function groupIdeiasByStatus(ideias: TIdeia[]) {
  const groupedIdeias: Partial<Record<TIdeia["status"], TIdeia[]>> = {};

  for (const ideia of ideias) {
    if (!groupedIdeias[ideia.status]) {
      groupedIdeias[ideia.status] = [];
    }
    groupedIdeias[ideia.status]?.push(ideia);
  }

  return groupedIdeias;
}
// prettier-ignore
const QuantidadeIdeiasPorStatusCard = ({ comite }: { comite: TComiteInovacao }) => {
  const { data: ideias, isFetched } = useGetAllIdeiasFromComite(comite);

  const ideiasGroupedByStatus = groupIdeiasByStatus(ideias || []);

  const hasNoIdeias = isFetched && ideias && ideias.length === 0;
  if (hasNoIdeias) {
    return null;
  }

  return (
    <Card withBorder>
      <Group align="flex-start">
        <ThemeIcon size="md" radius="xl">
          <IconFileAnalytics width={18} radius="xl" />
        </ThemeIcon>
        <Stack style={{ flex: 1 }}>
          <Text fz="lg">Quantidade de ideias por status</Text>
          <Grid>
            {ideiasGroupedByStatus.APROVADA && (
              <StatusItem text="Aprovada" total={ideiasGroupedByStatus.APROVADA.length} color="green" ideias={ideiasGroupedByStatus.APROVADA} />
            )}
            {ideiasGroupedByStatus.REPROVADA && (
              <StatusItem text="Reprovada" total={ideiasGroupedByStatus.REPROVADA.length} color="pink.2" ideias={ideiasGroupedByStatus.REPROVADA} />
            )}
            {ideiasGroupedByStatus.BACKLOG_DE_AVALIACAO_FUTURA && (
              <StatusItem
                text="Backlog de avaliação futura"
                total={ideiasGroupedByStatus.BACKLOG_DE_AVALIACAO_FUTURA.length}
                color="gray.4"
                ideias={ideiasGroupedByStatus.BACKLOG_DE_AVALIACAO_FUTURA}
              />
            )}
            {ideiasGroupedByStatus.EM_IMPLEMENTACAO && (
              <StatusItem
                text="Em implementação"
                total={ideiasGroupedByStatus.EM_IMPLEMENTACAO.length}
                color="indigo.4"
                ideias={ideiasGroupedByStatus.EM_IMPLEMENTACAO}
              />
            )}
            {ideiasGroupedByStatus.IMPLEMENTADA && (
              <StatusItem text="Implementada" total={ideiasGroupedByStatus.IMPLEMENTADA.length} color="indigo.8" ideias={ideiasGroupedByStatus.IMPLEMENTADA} />
            )}
            {ideiasGroupedByStatus.CANCELADA && (
              <StatusItem text="Cancelada" total={ideiasGroupedByStatus.CANCELADA.length} color="orange.6" ideias={ideiasGroupedByStatus.CANCELADA} />
            )}
            {ideiasGroupedByStatus.RASCUNHO && (
              <StatusItem text="Rascunho" total={ideiasGroupedByStatus.RASCUNHO.length} color="gray.4" ideias={ideiasGroupedByStatus.RASCUNHO} />
            )}
            {ideiasGroupedByStatus.NOVA_IDEIA && (
              <StatusItem text="Nova ideia" total={ideiasGroupedByStatus.NOVA_IDEIA.length} color="lime.2" ideias={ideiasGroupedByStatus.NOVA_IDEIA} />
            )}
            {ideiasGroupedByStatus.PRE_ANALISE && (
              <StatusItem text="Pré analise" total={ideiasGroupedByStatus.PRE_ANALISE.length} color="blue.5" ideias={ideiasGroupedByStatus.PRE_ANALISE} />
            )}
            {ideiasGroupedByStatus.EM_ANALISE_DE_VIABILIDADE && (
              <StatusItem
                text="Em analise de viabilidade"
                total={ideiasGroupedByStatus.EM_ANALISE_DE_VIABILIDADE.length}
                color="grape.4"
                ideias={ideiasGroupedByStatus.EM_ANALISE_DE_VIABILIDADE}
              />
            )}
            {ideiasGroupedByStatus.AGUARDANDO_ANALISE_DO_COMITE && (
              <StatusItem
                text="Aguardando analise do comitê"
                total={ideiasGroupedByStatus.AGUARDANDO_ANALISE_DO_COMITE.length}
                color="grape.9"
                ideias={ideiasGroupedByStatus.AGUARDANDO_ANALISE_DO_COMITE}
              />
            )}
          </Grid>
        </Stack>
      </Group>
    </Card>
  );
};

export default QuantidadeIdeiasPorStatusCard;
