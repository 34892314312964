import { Text, Card, Group, Button } from "@mantine/core";
import { useDisclosure, useForceUpdate } from "@mantine/hooks";
import { IconPlus, IconFilter } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";
import { useRef } from "react";
import { NavLink } from "react-router-dom";
import RefreshQueryButton from "@/components/refresh-query-button";
import { THandler } from "@/components/table/Table";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import RegulamentoIdeiasTable from "@/modules/regulamento-ideias/components/regulamentos-ideias-table";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import TableFilters from "./TableFilters";
import { dataAutorizacoes } from "../empresa-page/EmpresaPage";

const REFRESH_ALL_KEY = ["ideias-regulamentos-list"] as const;
// prettier-ignore
const RegulamentosIdeiasPage = () => {
  const tableHandlersReference = useRef<THandler<TRegulamentoIdeias>>(null);
  const [opened, { close, toggle }] = useDisclosure(false);
  const queryClient = useQueryClient();
  const activeEmpresaId = useUserStore((store) => store.activeEmpresa);
  const activeAutorizacao = useUserStore((store) => store.autorizacoes);
  const uid = getCurrentUser()?.uid;
  const { data: userColaborador } = useGetColaboradorById(activeEmpresaId?.id || "", uid || "");
  const autorizacao = dataAutorizacoes.find(item => item.label === userColaborador?.role);
  const formatAutorizacoes = activeAutorizacao?.filter(item => item.tipo === autorizacao?.value);
  const forceUpdate = useForceUpdate();
  /**
   * Handle filter applied
   */
  function handleFilterApplied() {
    close();
    void queryClient.invalidateQueries({ queryKey: REFRESH_ALL_KEY }).then(() => forceUpdate());
  }

  return (
    <Card withBorder shadow="md">
      <Card.Section inheritPadding py="md" withBorder>
        <Group justify="space-between">
          <Text fw={500} fz="lg">
            Listagem de regulamentos
          </Text>
          {formatAutorizacoes?.find(item => item.tipoAutorizacao === "15")?.criacao ? <Button size="sm" leftSection={<IconPlus size="1rem" />} component={NavLink} to="/ideias/regulamento">
            Adicionar regulamento
          </Button> : <></>}
        </Group>
      </Card.Section>
      <Card.Section inheritPadding py="md" withBorder>
        <Group justify="space-between">
          <Button variant="subtle" size="compact-md" leftSection={<IconFilter size="1rem" />} onClick={toggle}>
            Filtros
          </Button>
          <RefreshQueryButton queryKey={REFRESH_ALL_KEY} />
        </Group>
      </Card.Section>
      {tableHandlersReference.current && (
        <TableFilters
          table={tableHandlersReference.current.table}
          filters={tableHandlersReference.current.filters}
          onClose={close}
          onFilterApplied={handleFilterApplied}
          opened={opened}
        />
      )}
      <RegulamentoIdeiasTable ref={tableHandlersReference} />
    </Card>
  );
};

export default RegulamentosIdeiasPage;
