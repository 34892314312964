import { Select, SelectProps, Space, Text } from "@mantine/core";
import useGetTipoDeInovacao from "./useGetTipoDeInovacao";

interface Tipo {
  nome?: string;
  id?: string;
}

interface NovoTipo {
  categoria: string;
}
// prettier-ignore
const TipoDeInovacaoSelector = (properties: SelectProps & NovoTipo) => {
  const { data, isLoading, error } = useGetTipoDeInovacao();
  const formatData = data?.filter((item: TTipoDeInovacao) => item.categoria === properties?.categoria).map((item: Tipo) => ({
    value: item.id || "",
    label: item.nome || ""
  }));

  return <>
    <Select
      data={formatData}
      disabled={isLoading || properties.disabled && formatData?.length === 0 ? true : false}
      error={error?.message}
      {...properties}
    />
    <Space h={"md"} />
    {properties.categoria !== "" && properties.categoria !== null && formatData?.length === 0 ?
      <Text size="sm" color={"#868e96"}>
        Nenhum tipo cadastrado com essa categoria
      </Text> :
      <></>}
  </>
};

export default TipoDeInovacaoSelector;
