import { DocumentReference } from "firebase/firestore";
import { AnyObject, ObjectSchema } from "yup";
import { getColaboradorDocumentReferenceFromPath } from "@/modules/colaboradores/firestore";
import colaboradorSchema from "@/modules/colaboradores/schema/colaborador-schema";
import useUserStore from "@/modules/users/store";
import schema from "@/schema";
import auditSchema from "@/schema/audit-schema";
import { TIdeiaComentarioForm, TIdeiaComentarioFormFields } from "../firestore";

const MIN_TEXT_COMMENT = 2;

type TIdeiaComentarioContext = {
  ideiaId: TIdeia["id"];
};

type TAutor = Required<Pick<TColaborador, "nome" | "email" | "role" | "refPath">>;

const autorSchema: ObjectSchema<TAutor, AnyObject, TAutor, "d"> = schema
  .object({
    refPath: schema.string().required().defined().label("Autor")
  })
  // eslint-disable-next-line unicorn/prefer-spread
  .concat(colaboradorSchema.pick<"nome" | "email" | "role">(["nome", "email", "role"]).required().defined())
  .default(() => {
    const colaborador = useUserStore.getState().colaborador;
    if (colaborador) {
      return {
        nome: colaborador.nome || colaborador.email,
        email: colaborador.email,
        role: colaborador.role || "MEMBER",
        refPath: colaborador.refPath
      };
    }

    throw new Error("Colaborador não encontrado");
  })
  .noUnknown()
  .strict();

const ideiaComentarioFieldsSchema: ObjectSchema<
  TIdeiaComentarioFormFields,
  TIdeiaComentarioContext,
  TIdeiaComentarioFormFields
> = schema.object({
  comentario: schema.string().min(MIN_TEXT_COMMENT).trim().required().defined().default("").label("Comentário"),
  autor: autorSchema.required().defined().label("Autor"),
  colaboradoresMencionadosReferences: schema
    .mixed<DocumentReference<TColaborador>[] | string[]>()
    .defined()
    .required()
    .default([])
    .transform((value: (string | DocumentReference<TColaborador>)[]) =>
      value.map((v) => {
        const isString = typeof v === "string";
        return isString ? getColaboradorDocumentReferenceFromPath(v) : v;
      })
    )
    .label("Colaboradores mencionados"),
  respostaAoComentarioReference: schema
    .mixed<DocumentReference<TIdeiaComentario>>()
    .nullable()
    .default(null)
    .label("Resposta ao comentário")
});

const ideiaComentarioSchema: ObjectSchema<TIdeiaComentarioForm, TIdeiaComentarioContext, TIdeiaComentarioForm> =
  ideiaComentarioFieldsSchema
    // eslint-disable-next-line unicorn/prefer-spread
    .concat(auditSchema)
    .noUnknown()
    .label("Comentário");

export default ideiaComentarioSchema;
