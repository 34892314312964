import { subject } from "@casl/ability";
import {
  FirestoreDataConverter,
  Timestamp,
  WhereFilterOp,
  addDoc,
  collection,
  getDocs,
  query,
  serverTimestamp,
  where
} from "firebase/firestore";
import { getDesafioDocumentReference } from "../desafios/firestore";

export type FilterMovimentacao = {
  field: string;
  operator: WhereFilterOp;
  value: string | Timestamp | null;
};

export type TMovimentacaoDatabaseFields = Pick<TMovimentacao, "createdBy" | "updatedBy"> & {
  createdAt: ReturnType<typeof serverTimestamp>;
  updatedAt?: ReturnType<typeof serverTimestamp>;
  deletedAt: ReturnType<typeof serverTimestamp> | null;
};
export type TMovimentacaoFormFields = Omit<
  TMovimentacao,
  "id" | "refPath" | "createdAt" | "updatedAt" | "createdBy" | "updatedBy"
> &
  Partial<Pick<TMovimentacao, "id" | "refPath">>;
export type TMovimentacaoForm = TMovimentacaoFormFields & TMovimentacaoDatabaseFields;

type TMovimentacaoDocument = Omit<TMovimentacao, "createdAt" | "updatedAt" | "deletedAt"> & {
  createdAt: Timestamp;
  updatedAt?: Timestamp;
  deletedAt: Timestamp | null;
};

const movimentacaoConverter: FirestoreDataConverter<TMovimentacao> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;

    return data;
  },
  fromFirestore(snap) {
    const { createdAt, updatedAt, ...document } = snap.data() as TMovimentacaoDocument;

    const data: TMovimentacao = subject("TMovimentacao", {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      refPath: snap.ref.path
    });

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }
    return data;
  }
};

/**
 * Get desafios collection reference from empresaId
 * @param empresaId - Empresa id to get the desafios collection reference
 * @param desafioId - Desafio id to get the desafios collection reference
 * @returns - Desafios collection reference
 */
function getDesafioMovimentacaoCollectionReference(empresaId: TEmpresa["id"], desafioId: TDesafio["id"]) {
  const desafioDocumentReference = getDesafioDocumentReference(empresaId, desafioId);
  return collection(desafioDocumentReference, "movimentacoes");
}

/**
 * Add movimentacao
 * @param empresaId - The empresa ID
 * @param desafioId - the desafio ID to add comentário
 * @param movimentacao - The movimentação to add
 * @returns - Movimentação document
 */
export function addMovimentacao(empresaId: TEmpresa["id"], desafioId: TDesafio["id"], movimentacao: TMovimentacaoForm) {
  const desafioComentariosCollectionReference = getDesafioMovimentacaoCollectionReference(empresaId, desafioId);
  return addDoc(desafioComentariosCollectionReference, movimentacao);
}

/**
 * Get movimentações documents based on multiple filters
 * @param empresaId - The empresa ID
 * @param desafioId - the desafio ID to add movimentações
 * @param filters - Filters options
 * @returns - The movimentações documents
 */
// prettier-ignore
export function getMovimentacoesWithFilters(
  empresaId: TEmpresa["id"],
  desafioId: TDesafio["id"],
  filters: FilterMovimentacao[]
) {
  const movimentacoesCollectionReference = getDesafioMovimentacaoCollectionReference(empresaId, desafioId).withConverter(
    movimentacaoConverter
  );

  const q = query(movimentacoesCollectionReference, ...generateMovimentacaoFilter(filters));

  return getDocs(q);
}

/**
 * Get movimentacoes filters
 * @param filters - Filters options
 * @returns - The movimentacoes filters
 */
function generateMovimentacaoFilter(filters: FilterMovimentacao[]) {
  return filters.map(({ field, operator, value }) => {
    return where(field, operator, value);
  });
}
