import { Avatar, Checkbox, Flex, Group, Space, Text, Tooltip } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import { useCallback } from "react";
import { ArrayProperties, Campo, TRespostaField } from "../../types";
import { isCampo } from "../../util";

type Properties = {
  item: ArrayProperties;
  tipo: TTiposDeRespostas;
  disabled: boolean;
  onChange: (value: TRespostaField, item: ArrayProperties) => void;
  horizontal: boolean;
  licenciado: TLicenciado | null | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
const CheckListComponent = ({ item, disabled, tipo, onChange, horizontal, licenciado }: Properties) => {
  const handleChangeCheckbox = useCallback(
    (item: ArrayProperties, value: Campo) => () => onChange(value, item),
    [onChange, item]
  );

  const checkedField = isCampo(item.resposta) ? item.resposta.campo : null;

  return (
    <Flex justify="start" align="center" direction="column" w={"100%"} style={{ overflow: "auto" }}>
      {item.obrigatoria && (
        <>
          <Tooltip label="Está pergunta é obrigatória">
            <IconInfoCircle style={{ cursor: "pointer" }} />
          </Tooltip>
          <Space h="md" />
        </>
      )}
      {licenciado?.logo?.length && licenciado.logo.length > 0 && (
        <>
          <Group>
            {licenciado.logo.map((item) => (
              <>
                <Avatar src={item?.path} radius="sm" size="lg" variant="default" />
                <Space h="md" />
              </>
            ))}
          </Group>
          <Space h="md" />
        </>
      )}
      <Text fw={700} size="lg">
        {item?.nome}
      </Text>
      <Space h="md" />
      <Text size="md">{item?.descricao}</Text>
      <Space h="md" />
      <Flex
        align="start"
        gap="md"
        direction={horizontal ? "row" : "column"}
        pr={10}
        style={{ overflow: "auto" }}
        h={"35vh"}
        w={"100%"}
      >
        {tipo?.campos &&
          Array.isArray(tipo.campos) &&
          tipo.campos.map((value: Campo) => (
            <Checkbox
              key={value.valor}
              style={{ alignSelf: "center" }}
              label={value.campo}
              onChange={handleChangeCheckbox(item, value)}
              disabled={disabled}
              checked={checkedField === value.campo}
            />
          ))}
      </Flex>
      <Space h="md" />
    </Flex>
  );
};

export default CheckListComponent;
