import { Card, Skeleton, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { Cards } from "@/src/routes/desafios-relatorio-visao-geral/DesafiosRelatorioVisaoGeralPage";

const DOIS = 2;
const CEM_PORCENTO = 100;

interface TipoCardProperties {
  cards: Cards;
  tipo: "desafios" | "comentarios" | "resumo";
}

/**
 *
 * @param root0 - The component properties
 * @param root0.tipo - Tipo de card
 * @param root0.cards - cards
 * @returns - Card
 */
export default function RelatorioVisaoGeralCard({ cards, tipo }: TipoCardProperties) {
  const [total, setTotal] = useState<number>(0);
  const [porcentagem, setPorcentagem] = useState<number>(0);
  const [participantes, setParticipantes] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    if (!cards) {
      setIsLoading(true);
      return;
    }
    switch (tipo) {
      case "desafios": {
        setTotal(cards.desafios.total);
        setPorcentagem(cards.desafios.porcentagem);
        setParticipantes(cards.desafios.participantes.size);
        setIsLoading(false);
        break;
      }
      case "comentarios": {
        setTotal(cards.comentarios.total);
        setPorcentagem(cards.comentarios.porcentagem);
        setParticipantes(cards.comentarios.participantes.size);
        setIsLoading(false);
        break;
      }
      case "resumo": {
        const participantesSet = new Set([...cards.desafios.participantes, ...cards.comentarios.participantes]);
        setTotal(participantesSet.size);
        setPorcentagem((participantesSet.size / cards.colaboradoresAtivos) * CEM_PORCENTO);
        setParticipantes(cards.colaboradoresAtivos);
        setIsLoading(false);
        break;
      }
      // No default
    }
  }, [cards]);

  const textos = DefinirTextosLabels(tipo);

  if (isLoading) {
    return (
      <Card withBorder style={{ minWidth: "200px", maxWidth: "300px", minHeight: "280px" }}>
        <Skeleton height={280} />
      </Card>
    );
  }
  return (
    <Card withBorder style={{ minWidth: "200px", maxWidth: "300px", minHeight: "280px" }}>
      <Text mb="xl" fw={500}>
        {textos[0]}
      </Text>
      <Text fw={400}>{total ? Number(total.toFixed(DOIS)) : 0}</Text>
      <Text mb="md" fw={100} size="xs">
        {textos[1]}
      </Text>
      <Text fw={400}>{participantes ? Number(participantes.toFixed(DOIS)) : 0}</Text>
      <Text mb="md" fw={100} size="xs">
        {textos[2]}
      </Text>
      <Text fw={400}>{porcentagem ? Number(porcentagem.toFixed(DOIS)) : 0}%</Text>
      <Text mb="md" fw={100} size="xs">
        {textos[3]}
      </Text>
    </Card>
  );
}

/**
 *
 * @param tipo - tipo do card
 * @returns - textos
 */
function DefinirTextosLabels(tipo: string): string[] {
  let textos: string[] = [];
  switch (tipo) {
    case "desafios": {
      textos = ["Desafios", "Desafios", "Propositores", "Participantes sugeriram desafios"];
      break;
    }
    case "comentarios": {
      textos = ["Comentários", "Comentários", "Comentadores", "Participantes comentaram em desafios"];
      break;
    }
    case "resumo": {
      textos = ["Usuários", "Participantes", "Usuários ativos", "Usuários ativos dentre participantes"];
      break;
    }
    default: {
      textos = ["", "", "", ""];
      break;
    }
  }
  return textos;
}
