import { FocusTrap, Group, Space, TextInput, ColorInput, Card, Switch, Text, ColorSwatch } from "@mantine/core";
import { IconTrashFilled } from "@tabler/icons-react";

interface Fase {
  id: string;
  titulo: string;
  cor: string;
  checked?: boolean;
  // status: Status[];
}
// prettier-ignore
const FaseForm = ({ fases, setFases }: { fases: Fase[]; setFases: React.Dispatch<React.SetStateAction<Fase[]>> }) => {
  // prettier-ignore
  /**
   * Handle remove fase
   * @param item - The item function
   * @returns - function
   */
  function removeTab(item: Fase) {
    return () => {
      setFases((fases: Fase[]) => (fases.length === 1 ? [] : fases.filter((fase) => fase.id !== item.id)));
    };
  }

  // prettier-ignore
  /**
   * Handle change fase
   * @param item - The item function
   * @param field - The field function
   * @returns - function
   */
  function changeTab(item: Fase, field: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      setFases((fases: Fase[]) =>
        fases.map((fase) => (fase.id === item.id ? { ...fase, [field]: event.target.value } : fase))
      );
    };
  }

  // prettier-ignore
  /**
   * Handle change fase
   * @param fase - The fase function
   * @returns - function
   */
  function changeCor(fase: Fase) {
    return (event: string) => {
      setFases((fases: Fase[]) =>
        fases.map((item) => (fase.id === item?.id ? { ...fase, cor: event } : item))
      );
    };
  }

  // prettier-ignore
  /**
   * Handle select status
   * @param fase - The fase function
   * @returns - function
   */
  function selectAvaliacao(fase: Fase) {
    return () => {
      setFases((fases: Fase[]) =>
        fases.map((item) => (item.id === fase.id ? { ...item, checked: !item.checked } : item))
      );
    };
  }

  return (
    <FocusTrap active={true}>
      {ordenarArray(fases).map((fase: Fase) => (
        <>
          <Card withBorder radius="md">
            <Group align={"center"}>
              {fase.id === "1" || fase.id === "7" ? <></> : <Switch
                label="Avaliação"
                onChange={selectAvaliacao(fase)}
                checked={fase?.checked}
              />}
              {fase.id === "1" || fase.id === "7" ?
                <>
                  <ColorSwatch color={fase.cor} />
                  <Text>{fase.titulo}</Text>
                </> :
                <>
                  <TextInput
                    type="text"
                    style={{ flex: 1 }}
                    label={""}
                    value={fase.titulo}
                    onChange={changeTab(fase, "titulo")}
                  />
                  <ColorInput label="" style={{ flex: 1 }} value={fase.cor || ""} onChange={changeCor(fase)} />
                </>}
              {fase.id === "1" || fase.id === "7" ? <></> : <IconTrashFilled size={16} onClick={removeTab(fase)} />}
            </Group>
          </Card>
          <Space h="md" />
        </>
      ))}
    </FocusTrap>
  );
};

export default FaseForm;

/**
 * Ordena o array para que o item com o título "Inscrição" seja o primeiro
 * e o item com o título "Vencedores" seja o último. Os demais itens
 * mantêm a ordem relativa entre si.
 * @param array - O array de objetos a ser ordenado.
 * @returns - O array ordenado.
 */
function ordenarArray(array: Fase[]) {
  return array.sort((a, b) => {
    if (a.titulo === "Inscrição") return -1;
    if (b.titulo === "Inscrição") return 1;

    if (a.titulo === "Vencedores") return 1;
    if (b.titulo === "Vencedores") return -1;

    return 0;
  });
}
