/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import { Group, Modal, Text } from "@mantine/core";
import { CellContext, ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { DocumentSnapshot, getDoc } from "firebase/firestore";
import { useState, useEffect } from "react";
import { getColaboradorDocumentReferenceFromPath } from "@/modules/colaboradores/firestore";
import TotalMoedasBadge from "@/modules/ideias/components/total-moedas-badge";
import useGetRegulamentoFromIdeia from "@/modules/regulamento-ideias/hooks/useGetRegulamentoFromIdeia";
import { textMapIdeias } from "@/src/routes/gestao-inovacao-page/ExportIdeias";
import TotalComentariosBadge from "@/src/routes/ideia-page/TotalComentariosBadge";
import TotalLikesBadge from "@/src/routes/ideia-page/TotalLikesBadge";

const columnHelper = createColumnHelper<TIdeia>();

// Cache local em memória.
//const colaboradorCache = new Map<string | TColaborador, TColaborador>();

/**
 * @param properties - Properties
 * @param titulo - Title
 * @returns - texto
 */
function GetTexto(properties: CellContext<TIdeia, Date>, titulo: string) {
  const maxTexto = 150;
  const [opened, setOpened] = useState(false);
  const originalText = properties.getValue<string>();
  const isTooLong = originalText.length > maxTexto;
  const truncatedText = isTooLong ? `${originalText.slice(0, maxTexto)} ...` : originalText;
  return (
    <>
      <Group gap="xs" wrap="nowrap">
        <Text
          fz="sm"
          style={{ cursor: isTooLong ? "pointer" : "default" }}
          onClick={() => {
            if (isTooLong) {
              setOpened(true);
            }
          }}
        >
          {truncatedText}
        </Text>
      </Group>
      <Modal opened={opened} onClose={() => setOpened(false)} title={titulo}>
        <Text>{originalText}</Text>
      </Modal>
    </>
  );
}

const columns: ColumnDef<TIdeia>[] = [
  columnHelper.accessor("regulamentoReference", {
    header: "Regulamento",
    enableSorting: true,
    cell: (properties) => {
      return <RegulamentoCell ideia={properties.row.original} />;
    }
  }),
  columnHelper.display({
    id: "autores",
    header: "Autor(es)",
    enableSorting: true,
    cell: (info) => {
      const autores = [info.row.original.autorReference, ...info.row.original.participantesReferences]; // eslint-disable-line react-perf/jsx-no-new-array-as-prop
      return <BuscarColaboradorField paths={autores} field="nome" />;
    }
  }),
  columnHelper.accessor("titulo", {
    header: "Título",
    enableSorting: true,
    cell: (properties) => {
      return (
        <Group gap="xs" wrap="nowrap">
          <Text fz="sm">{properties.getValue()}</Text>
        </Group>
      );
    }
  }),
  columnHelper.accessor("descricao", {
    header: "Descrição",
    enableSorting: true,
    cell: (properties) => {
      return GetTexto(properties, "Descrição");
    }
  }),
  columnHelper.accessor("descricaoProblema", {
    header: "Descrição do Problema",
    enableSorting: true,
    cell: (properties) => {
      return GetTexto(properties, "Descrição do Problema");
    }
  }),
  columnHelper.accessor("diferenciais", {
    header: "Diferenciais",
    enableSorting: true,
    cell: (properties) => {
      return GetTexto(properties, "Diferenciais");
    }
  }),
  columnHelper.accessor("feedback", {
    header: "Feedback",
    enableSorting: true,
    cell: (properties) => {
      return GetTexto(properties, "Feedback");
    }
  }),
  columnHelper.display({
    id: "emailAutores",
    header: "Email Autor(es)",
    enableSorting: true,
    cell: (info) => {
      const autores = [info.row.original.autorReference, ...info.row.original.participantesReferences]; // eslint-disable-line react-perf/jsx-no-new-array-as-prop
      return <BuscarColaboradorField paths={autores} field="email" />;
    }
  }),
  columnHelper.accessor("createdAt", {
    header: "Data Criação",
    enableSorting: true,
    cell: (properties) => {
      return properties.getValue()?.toLocaleDateString();
    }
  }),
  columnHelper.display({
    id: "nroCurtidas",
    header: "Curtidas",
    enableSorting: true,
    cell: (info) => {
      return <TotalLikesBadge ideia={info.row.original} variant="transparent" leftSection={null} />;
    }
  }),
  columnHelper.display({
    id: "nroComentarios",
    header: "Comentários",
    enableSorting: true,
    cell: (info) => {
      return <TotalComentariosBadge ideia={info.row.original} variant="transparent" leftSection={null} />;
    }
  }),
  columnHelper.display({
    id: "patrocinios",
    header: "Patrocínios",
    maxSize: 10,
    enableSorting: true,
    cell: (info) => {
      return <TotalMoedasBadge ideia={info.row.original} variant="transparent" px={0} leftSection={null} />;
    }
  }),

  columnHelper.accessor("status", {
    header: "Status",
    enableSorting: true,
    cell: (properties) => {
      return (
        <Group gap="xs" wrap="nowrap">
          <Text fz="sm">{textMapIdeias[properties.getValue()]}</Text>
        </Group>
      );
    }
  })
];

export default columns;

const RegulamentoCell = ({ ideia }: { ideia: TIdeia }) => {
  const regulamento = useGetRegulamentoFromIdeia(ideia);

  return (
    <Group gap="xs" wrap="nowrap">
      <Text fz="sm">{regulamento.data?.nome}</Text>
    </Group>
  );
};
type PathType = string | { refPath: string };

interface BuscarColaboradorFieldProperties<T extends keyof TColaborador> {
  paths: PathType[];
  field: T;
}

const fetchColaborador = async (path: PathType): Promise<TColaborador | null> => {
  try {
    const referencePath = typeof path === "string" ? path : path.refPath;
    const documentReference = getColaboradorDocumentReferenceFromPath(referencePath);
    const documentSnapshot: DocumentSnapshot = await getDoc(documentReference);

    if (!documentSnapshot.exists()) return null;

    const data = documentSnapshot.data() as TColaborador;
    return { ...data, id: documentSnapshot.id };
  } catch (error) {
    console.error("Erro ao buscar colaborador:", error);
    return null;
  }
};

/**
 *
 * @param root0 - Options
 * @param root0.field - field to return
 * @param root0.paths - paths from colaboradores
 * @returns - component
 */
const BuscarColaboradorField = <T extends keyof TColaborador>({
  paths,
  field
}: BuscarColaboradorFieldProperties<T>) => {
  const [colaboradores, setColaboradores] = useState<TColaborador[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchColaboradores = async () => {
      setIsLoading(true);
      try {
        const fetchPromises = paths.map((path) => fetchColaborador(path));
        // eslint-disable-next-line compat/compat
        const resultados = await Promise.all(fetchPromises);
        const colaboradoresValidos = resultados.filter(
          (colaborador): colaborador is TColaborador => colaborador !== null
        );
        setColaboradores(colaboradoresValidos);
      } catch (error) {
        console.error("Erro ao buscar colaboradores:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchColaboradores().catch((error) => {
      console.error("Erro ao buscar colaboradores:", error);
    });
  }, [paths]);

  if (isLoading) {
    return <Text fz="sm">Carregando...</Text>;
  }

  if (
    colaboradores.length === 0 ||
    !colaboradores[0] ||
    !Object.prototype.hasOwnProperty.call(colaboradores[0], field)
  ) {
    return <Text fz="sm">Nenhum colaborador encontrado</Text>;
  }

  const valoresCampo = colaboradores.map((colaborador) => colaborador[field]?.toString() ?? "Colaborador");

  return (
    <Group gap="xs" wrap="nowrap">
      <Text fz="sm">{valoresCampo.join(",\n")}</Text>
    </Group>
  );
};
