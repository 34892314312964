import { Flex, Text } from "@mantine/core";
import { useParams } from "react-router-dom";
import useGetAcessos from "./useGetAcessos";

const TextAcesso = ({ id }: { id: TQuestionario["id"] }) => {
  const { data } = useGetAcessos({ id });
  const { idEmpresa: idEmpresa } = useParams<{ idEmpresa: string }>();

  return (
    <>
      <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
        <Text fw={300} fz="xs">
          Quantidade de acessos
        </Text>
        <Text fw={500} fz="md">
          {data?.filter((item) => item.idEmpresa === idEmpresa).length}
        </Text>
      </Flex>
    </>
  );
};

export default TextAcesso;
