import { Text, UnstyledButton, UnstyledButtonProps } from "@mantine/core";
import { modals } from "@mantine/modals";
import { hideNotification, showNotification, updateNotification } from "@mantine/notifications";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { ForwardedRef, MouseEvent, PropsWithChildren, forwardRef, useId } from "react";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { publishDesafio } from "../../firestore";

const DESAFIOS_COLLECTION_KEY = "desafios" as const;

type TPublishDesafioResponse = Awaited<ReturnType<typeof publishDesafio>>;

/**
 * Mutation that publishes an desafio.
 * @param desafio - The desafio to be publicada.
 * @returns The mutation to be used.
 */
function useSetPublishAtMutation(desafio: TDesafio) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const queryClient = useQueryClient();

  const notificationId = useId();

  return useMutation<TPublishDesafioResponse, FirestoreError>({
    mutationFn() {
      hideNotification(notificationId);
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      showNotification({
        title: "Publicando desafio",
        message: "Aguarde enquanto publicamos seu desafio",
        autoClose: false,
        id: notificationId,
        loading: true
      });
      return publishDesafio(activeEmpresaId, desafio.id);
    },
    onSuccess() {
      updateNotification({
        id: notificationId,
        title: "Desafio publicado",
        color: "green",
        message: "Seu desafio foi publicada com sucesso",
        autoClose: true,
        loading: false
      });
      return queryClient.invalidateQueries({ queryKey: [DESAFIOS_COLLECTION_KEY] });
    },
    onError(error) {
      captureException(error, true);
      hideNotification(notificationId);
    }
  });
}

type PublicarDesafioButtonProperties = PropsWithChildren<UnstyledButtonProps & { desafio: TDesafio }>;

/**
 * A button that opens a modal to edit an item.
 * @param root0 - The properties of the button.
 * @param root0.children - The children of the button.
 * @param root0.desafio - The desafio to be publicada.
 * @param reference - The reference to the button.
 * @returns The element to be rendered.
 */
function PublicarDesafioButton(
  { desafio, children, ...properties }: PublicarDesafioButtonProperties,
  reference: ForwardedRef<HTMLButtonElement>
) {
  const { mutate, isPending } = useSetPublishAtMutation(desafio);

  /**
   * Handles the click event on the button.
   * @param event - The event that triggered the click.
   */
  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    modals.openConfirmModal({
      title: "Deseja publicar seu desafio?",
      children: (
        <Text size="sm">
          Ao publicar seu desafio, ela será visível para todos os usuários e não poderá ser editada ou removida.
        </Text>
      ),
      labels: { confirm: "Publicar", cancel: "Cancelar" },
      onConfirm: () => mutate()
    });
  }

  return (
    <UnstyledButton ref={reference} {...properties} disabled={isPending} onClick={handleClick}>
      {children}
    </UnstyledButton>
  );
}

const PublicarDesafioButtonWithReference = forwardRef(PublicarDesafioButton);

PublicarDesafioButtonWithReference.displayName = "PublicarDesafioButton";

export default PublicarDesafioButtonWithReference;
