import { subject } from "@casl/ability";
import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { FilterMovimentacao, getMovimentacoesWithFilters } from "../firestore";

const QUERY_KEY: string = "historico-movimentacao-list" as const;

type TGetHistoricoMovimentacoesResponse = Awaited<ReturnType<typeof getMovimentacoesWithFilters>>;

/**
 * Get histórico de movimentações de uma desafio
 * @param desafio - The desafio to get the histórico de movimentações
 * @param filters - Filters
 * @returns - Query results for get histórico de movimentações
 */
function useGetHistoricoMovimentacoes(desafio: TDesafio, filters: FilterMovimentacao[]) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  return useQuery<TGetHistoricoMovimentacoesResponse, FirestoreError, TMovimentacao[]>({
    queryKey: [activeEmpresaId, "desafio", desafio.id, QUERY_KEY, filters],
    async queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return getMovimentacoesWithFilters(activeEmpresaId, desafio.id, filters);
    },
    select(dataSnapshot) {
      if (dataSnapshot.empty) {
        return [];
      }
      return dataSnapshot.docs.map((document) => subject("TMovimentacao", document.data()));
    }
  });
}

export default useGetHistoricoMovimentacoes;
