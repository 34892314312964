import { subject } from "@casl/ability";
import { useQuery } from "@tanstack/react-query";
import { DocumentReference, FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { getIdeiaFromDocumentReference, getIdeiaFromDocumentReferenceFromPath } from "../firestore";

type TGetIdeiaResponse = Awaited<ReturnType<typeof getIdeiaFromDocumentReferenceFromPath>>;

/**
 * Hook to get the ideia from a document reference
 * @param ideiaReference - Ideia reference to get the ideia
 * @returns Query result to get the ideia
 */
function useGetIdeiaFromDocumentReference(ideiaReference: string | DocumentReference<TIdeia>) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  return useQuery<TGetIdeiaResponse, FirestoreError, TIdeia | null>({
    queryKey: ["empresas", activeEmpresaId, "ideias", { ideia: ideiaReference }],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      if (typeof ideiaReference === "string") {
        return getIdeiaFromDocumentReferenceFromPath(ideiaReference);
      }

      return getIdeiaFromDocumentReference(ideiaReference);
    },
    select(dataResponse) {
      if (!dataResponse.exists()) {
        return null;
      }

      return subject("TIdeia", dataResponse.data());
    }
  });
}

export default useGetIdeiaFromDocumentReference;
