/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import { subject } from "@casl/ability";
import { Menu, ActionIcon, Button } from "@mantine/core";
import { IconDotsVertical, IconEdit, IconTrash } from "@tabler/icons-react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";

const columnHelper = createColumnHelper<TFeedback>();

const columns: ColumnDef<TFeedback>[] = [
  columnHelper.accessor("title", { header: "Faixa" }),
  columnHelper.accessor("start", { header: "Nota Inicial" }),
  columnHelper.accessor("end", { header: "Nota Final" })
];

type Actions = {
  onEdit?: (feedback: TFeedback) => void;
  onDelete?: (feedback: TFeedback) => void;
};

export const getDeleteFeedbackColumn = (actions?: Actions) => {
  return columnHelper.display({
    id: "actions",
    cell: ({ row }) => {
      const feedback = subject("TFeedback", row.original) as TFeedback;

      if (!actions) {
        return <></>;
      }

      const { onEdit, onDelete } = actions;

      return (
        <Menu shadow="md" position="left" withArrow arrowPosition="center">
          <Menu.Target>
            <ActionIcon variant="transparent" aria-label="Menu" color="gray">
              <IconDotsVertical />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            {onEdit && (
              <Menu.Item>
                <Button variant="transparent" onClick={() => onEdit(feedback)}>
                  <IconEdit size={14} />
                </Button>
              </Menu.Item>
            )}
            <Menu.Divider />
            {onDelete && (
              <Menu.Item>
                <Button variant="transparent" onClick={() => onDelete(feedback)}>
                  <IconTrash size={14} />
                </Button>
              </Menu.Item>
            )}
          </Menu.Dropdown>
        </Menu>
      );
    }
  });
};

export default columns;
