import { ResponsiveRadar } from "@nivo/radar";
import { useEffect, useState } from "react";

type DataItem = {
  segment: string;
  value: number;
};

type Dataset = {
  id: string;
  data: DataItem[];
};

type Properties = {
  segments: string[];
  datasets: Dataset[];
};

type RadarDataSegment = {
  segment: string;
  [key: string]: number | string;
};

/**
 * Componente RadarNota
 * @param root0 Propriedades do componente
 * @param root0.datasets Dados do radar
 * @param root0.segments Segmentos do radar
 * @returns Componente
 */
function RadarNota({ datasets, segments }: Properties) {
  const [radarData, setRadarData] = useState<RadarDataSegment[]>([]);
  const [datasetsIds, setDatasetsIds] = useState<string[]>([]);

  useEffect(() => {
    const segmentsDataMap = new Map<string, RadarDataSegment>();

    for (const dataset of datasets) {
      for (const dataItem of dataset.data) {
        const segmentData = segmentsDataMap.get(dataItem.segment);
        if (segmentData) {
          segmentData[dataset.id] = dataItem.value;
          segmentsDataMap.set(dataItem.segment, segmentData);
        } else {
          segmentsDataMap.set(dataItem.segment, { segment: dataItem.segment, [dataset.id]: dataItem.value });
        }
      }
    }

    setDatasetsIds(datasets.map((dataset) => dataset.id));
    setRadarData([...segmentsDataMap.values()]);
  }, [datasets, segments]);

  return (
    <ResponsiveRadar
      data={radarData}
      keys={datasetsIds}
      indexBy="segment"
      valueFormat=">-.2f"
      margin={{ top: 70, right: 80, bottom: 70, left: 80 }}
      gridLabelOffset={36}
      dotSize={10}
      dotColor={{ theme: "background" }}
      dotBorderWidth={2}
      blendMode="multiply"
      motionConfig="wobbly"
    />
  );
}

export default RadarNota;
