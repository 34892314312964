import { useState } from "react";

export type UseGrupoFeedbacks = {
  feedbacks: TFeedback[];
  saveFeedback: (feedback: TFeedback) => void;
  removeFeedback: (value: string) => void;
};

export const useGrupoFeedbacks = (initialValue: TFeedback[] = []): UseGrupoFeedbacks => {
  const [feedbacks, setFeedbacks] = useState<TFeedback[]>(initialValue);

  const handleSabeFeedback = (feedback: TFeedback) => {
    const index = feedbacks.findIndex(({ title }) => title === feedback.title);

    if (index === -1) {
      setFeedbacks((previous) => [...previous, feedback]);
      return;
    }

    setFeedbacks((previous) => previous.map((feedback_, index_) => (index_ === index ? feedback : feedback_)));
  };

  const handleRemoveFeedback = (value: string) => {
    setFeedbacks((previous) => previous.filter(({ title }) => title !== value));
  };

  return {
    feedbacks,
    saveFeedback: handleSabeFeedback,
    removeFeedback: handleRemoveFeedback
  };
};
