import { UnstyledButton, UnstyledButtonProps } from "@mantine/core";
import { hideNotification, showNotification } from "@mantine/notifications";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { FirebaseError } from "firebase/app";
import { ForwardedRef, PropsWithChildren, forwardRef, useId } from "react";
import { enableUser } from "@/modules/users/functions";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { QUERY_KEY } from "../colaboradores-table/ColaboradoresTable";

type EnableColaboradorButtonProperties = PropsWithChildren<UnstyledButtonProps> & { colaborador: TColaborador };
type TEnableUserResponse = Awaited<ReturnType<typeof enableUser>>;

/**
 * Enable a colaborador.
 * @param colaborador - The colaborador to be enabled.
 * @returns The mutation.
 */
function useEnableUserMutation(colaborador: TColaborador) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const queryClient = useQueryClient();
  const notificationId = useId();
  return useMutation<TEnableUserResponse, FirebaseError>({
    mutationFn() {
      hideNotification(notificationId);
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      showNotification({
        title: "Habilitando colaborador...",
        message: "Aguarde enquanto habilitamos o colaborador",
        autoClose: false,
        id: notificationId,
        loading: true
      });

      return enableUser({ empresaId: activeEmpresaId, userEmail: colaborador.email, colaboradorId: colaborador.id });
    },
    onSuccess() {
      return queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    },
    onError(error) {
      captureException(error, true);
    },
    onSettled() {
      hideNotification(notificationId);
    }
  });
}

/**
 * A button that enables a colaborador.
 * @param properties - The properties of the button.
 * @param properties.children - The children of the button.
 * @param properties.colaborador - The colaborador to be enabled.
 * @param reference - The reference to the button.
 * @returns The element to be rendered.
 */
function EnableColaboradorButton(
  { children, colaborador, ...properties }: EnableColaboradorButtonProperties,
  reference: ForwardedRef<HTMLButtonElement>
) {
  const { mutate } = useEnableUserMutation(colaborador);
  /**
   *
   */
  function handleClick() {
    mutate();
  }

  return (
    <UnstyledButton ref={reference} {...properties} onClick={handleClick}>
      {children}
    </UnstyledButton>
  );
}

const EnableColaboradorButtonReference = forwardRef<HTMLButtonElement, EnableColaboradorButtonProperties>(
  EnableColaboradorButton
);

EnableColaboradorButtonReference.displayName = "EnableColaboradorButton";

export default EnableColaboradorButtonReference;
