import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getTipoDeInovacaoItem } from "@/modules/tipos-de-inovacao/firestore";
import useUserStore from "@/modules/users/store";
import { QUERY_KEY } from "../../firestore";

type TGetTipoDeInovacaoResponse = Awaited<ReturnType<typeof getTipoDeInovacaoItem>>;

/**
 * Get ideia from url params
 * @param tipoDeInovacaoId - tipo de inovacao id
 * @returns - Ideia or null if not found
 */
function useGetTipoDeInovacaoItem(tipoDeInovacaoId: string) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  return useQuery<TGetTipoDeInovacaoResponse, FirestoreError, TTipoDeInovacao | null>({
    queryKey: [QUERY_KEY, tipoDeInovacaoId, activeEmpresaId],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!tipoDeInovacaoId) {
        throw new Error("Ideia não selecionada");
      }

      return getTipoDeInovacaoItem(activeEmpresaId, tipoDeInovacaoId);
    },
    select(dataResponse) {
      if (!dataResponse.exists()) {
        return null;
      }

      return dataResponse.data();
    },
    enabled: !!tipoDeInovacaoId && !!activeEmpresaId
  });
}

export default useGetTipoDeInovacaoItem;
