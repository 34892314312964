import { Paper, Stack, Text, isLightColor, parseThemeColor, useMantineTheme } from "@mantine/core";
import { forwardRef } from "react";

type IdeiasFilterButtonProperties = {
  text: string;
  total: number;
  color: string;
};

const IdeiaStatusCount = forwardRef<HTMLDivElement, IdeiasFilterButtonProperties>(
  ({ text, total, color }, reference) => {
    const theme = useMantineTheme();
    const parsedColor = parseThemeColor({ color, theme });

    return (
      <Paper radius="md" p="xs" bg={color} h={64} ref={reference}>
        <Stack gap={0} pr="xl" align="start">
          <Text fw="bold" c={isLightColor(parsedColor.value) ? "dark" : "light"}>
            + {total}
          </Text>
          <Text fz="sm" c={isLightColor(parsedColor.value) ? "dark" : "light"}>
            {text}
          </Text>
        </Stack>
      </Paper>
    );
  }
);

IdeiaStatusCount.displayName = "IdeiaStatusCount";

export default IdeiaStatusCount;
