import { Button, Flex, Group, Space, Text, Textarea } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { DocumentReference, Timestamp } from "firebase/firestore";
import { useCallback, useState } from "react";
import { getColaboradorDocumentReferenceFromPath, TColaboradorDocument } from "@/modules/colaboradores/firestore";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import { useAddIdeia } from "./useAddIdeia";
import useGetIdeiasByRegulamento from "./useGetIdeia";
import { TIdeiaForm } from "../../firestore";

interface MyState {
  descricao: string;
}

// prettier-ignore
const CampanhaItem = ({ item, onSuccess }: { item: TCampanhaRegulamentoIdeias | undefined, onSuccess: () => void }) => {
  const colaborador = useUserStore((state) => state.colaborador);
  const [values, setValues] = useState({ descricao: "" });
  // const { mutate } = useUpdateCampanha();
  const { mutate } = useAddIdeia();
  const { data: ideia } = useGetIdeiasByRegulamento(item && item?.refPath)
  const ideiasCount = Array.isArray(ideia) ? ideia?.length || 0 : 0;
  const quantidadeGanhoNoCadastro = item?.quantidadeGanhoNoCadastro || 0;

  const onClick = useCallback(() => {
    if (item && item.id && colaborador && colaborador.refPath) {
      const colaboradorReference = getColaboradorDocumentReferenceFromPath(colaborador.refPath) as DocumentReference<TColaborador, TColaboradorDocument>;
      changeMutate(mutate, onSuccess, values, item, colaboradorReference)
    }
  }, [mutate, values]);


  return (
    <Flex direction="column" pb="xs">
      <Group justify={"flex-start"}>
        <Text>{item?.descricao}</Text>
      </Group>
      <Space h={"md"} />
      <Group justify={"space-between"}>
        <DateInput
          label="Data inicial"
          disabled={true}
          value={item?.dataInicial}
          valueFormat="DD/MM/YYYY HH:mm:ss"
        />
        <DateInput
          label="Data final"
          disabled={true}
          value={item?.dataFinal}
          valueFormat="DD/MM/YYYY HH:mm:ss"
        />
      </Group>
      <Space h={"md"} />
      <Group justify={"space-between"}>
        <Textarea
          w={"100%"}
          label="Descrição da ideia"
          placeholder="Digite e envie a sua ideia!"
          defaultValue={values?.descricao}
          onChange={onChangeDescricao("descricao", setValues)}
        />
      </Group>
      <Space h={"md"} />
      <Group justify={"flex-end"}>
        {ideiasCount < quantidadeGanhoNoCadastro ? <Button bg={"rgba(8, 68, 244, 1)"} onClick={onClick} disabled={values.descricao === ""}>
          Entrar na campanha
        </Button> : <Text fz={"sm"} c={"red"}>Todas as ideias ja foram enviadas!</Text>}
      </Group>
    </Flex>
  );
};

export default CampanhaItem;

// prettier-ignore
const onChangeDescricao = (field: string, setValues: React.Dispatch<React.SetStateAction<MyState>>) => {
  return (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValues(values => ({ ...values, [field]: event.target.value }))
  };
}
// prettier-ignore
const changeMutate = (
  mutate: (item: TIdeiaForm) => void,
  onSuccess: () => void,
  values: MyState,
  item: TCampanhaRegulamentoIdeias,
  autorReference: DocumentReference<TColaborador, TColaboradorDocument> | undefined
) => {
  const user = getCurrentUser();
  const { refPath, setores, descricao } = item;
  const regulamentoReference = refPath.split("/campanhas/")[0] || "";

  mutate({
    deletedAt: null,
    updatedAt: Timestamp.now(),
    createdAt: Timestamp.now(),
    createdBy: user?.uid || "",
    titulo: descricao,
    descricao: values?.descricao || "",
    objetivo: [],
    autorReference: autorReference || "",
    comoAtingirObjetivo: "",
    descricaoProblema: "",
    diferenciais: "",
    feedback: "",
    autorGostariaParticiparImplantacao: true,
    participantesReferences: [],
    implementadoresReferences: [],
    anexos: [],
    setores: setores,
    regulamentoReference: regulamentoReference,
    anexosFilesToUpload: [],
    permitirComentarios: true,
    permitirPatrocinio: true,
    status: "RASCUNHO",
    publishedAt: null,
    cancelAt: null,
    updatedBy: user?.uid || "",
    privado: false
  });
  onSuccess();
};
