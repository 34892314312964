import { Button, Group, Text } from "@mantine/core";
import useGetTipoDeInovacao from "./useGetTipoDeInovacao";
import TaticasDeJogadasDeInovacaoCard from "../taticas-de-jogadas-de-inovacao-card";

interface NewTaticas {
  id: string | number;
  nome: string;
  descricao: string;
  vinculos?: number[] | undefined;
}

interface Tatica {
  id: string | number;
  nome: string;
  descricao: string;
  categoria?: string | undefined;
  tipo?: string | undefined;
}

interface Valores {
  categoria: string;
  tipo: string;
}

interface Properties {
  tipo?: string;
  newTaticas: Array<NewTaticas>;
  setNewTaticas: React.Dispatch<React.SetStateAction<Tatica[]>>;
  setAddTatica: React.Dispatch<React.SetStateAction<boolean>>;
  setValues: React.Dispatch<React.SetStateAction<Valores>>;
  setTaticas: React.Dispatch<React.SetStateAction<Tatica[]>>;
  categoria: string;
}
// prettier-ignore
const NewTaticasComponent = (properties: Properties) => {
  const { data } = useGetTipoDeInovacao();
  const taticas = data?.find((item: TTipoDeInovacao) => item?.id === properties?.tipo)?.taticas || [];

  /**
   * Handles input change for a tatica field.
   * @param value - The name of the value being updated
   */
  function changeTaticas(value: Tatica) {
    properties.setNewTaticas((taticas) =>
      taticas.some((item) => item.id === value.id)
        ? taticas.filter((item) => item.id !== value.id)
        : [...taticas, value]
    );
  }

  /**
   * Saves tactics in the state and clears the state of new tactics and values.
   */
  function saveTaticas() {
    properties.setTaticas((taticas: Tatica[]) => {
      const mappedTaticas = properties?.newTaticas?.map((item: NewTaticas) => {
        const categoriaId = properties.categoria;
        const tipoId = properties.tipo;

        return {
          ...item,
          idTatica: item.id,
          categoria: categoriaId,
          tipo: tipoId,
          id: generateRandomId()
        };
      });
      return [...taticas, ...mappedTaticas];
    });
    properties.setNewTaticas([]);
    properties.setAddTatica(false);
    properties.setValues({ categoria: "", tipo: "" });
  }

  /**
   * Close taticas and values
   */
  function closeTaticas() {
    properties.setNewTaticas([]);
    properties.setAddTatica(false);
    properties.setValues({ categoria: "", tipo: "" });
  }

  return <>

    {data && data.length > 0 && Array.isArray(data) && taticas.length > 0 ? (
      taticas?.map((item: Tatica,) => (
        <TaticasDeJogadasDeInovacaoCard
          key={item.id}
          newTaticas={properties?.newTaticas.find(row => row.id === item.id)}
          tatica={item}
          changeTaticas={changeTaticas} />
      ))
    ) : properties.tipo !== "" && properties.tipo !== null && taticas.length === 0 ? (
      <Text size="sm" c={"#868e96"}>
        Nenhuma tática cadastrada nesse tipo de inovação
      </Text>
    ) : <>
    </>}
    {properties?.newTaticas?.length > 0 ? (
      <Group justify={"flex-end"}>
        <Button variant="transparent" color="rgba(82, 83, 86, 1)" onClick={closeTaticas}>
          Cancelar
        </Button>
        <Button variant="filled" color="rgba(8, 68, 244, 1)" onClick={saveTaticas}>
          Incluir táticas
        </Button>
      </Group>
    ) : (
      <></>
    )}
  </>
};

export default NewTaticasComponent;

const RANDOM_ID_LENGTH_STRING = 36;
const RANDOM_ID_LENGTH = 9;
const RANDOM_ID_START_INDEX = 2;

/**
 * Gera um ID aleatório.
 * @returns string O ID gerado.
 */
function generateRandomId() {
  return Math.random().toString(RANDOM_ID_LENGTH_STRING).slice(RANDOM_ID_START_INDEX, RANDOM_ID_LENGTH); // Usando slice em vez de substr
}
