import { httpsCallable } from "firebase/functions";
import { Asserts } from "yup";
import { functions } from "@/base";
import inviteDiagnosticoSchema from "./components/form-incricao-projeto/invite-diagnostico-schema";

type InviteEmpresaInputs = Asserts<typeof inviteDiagnosticoSchema> & {
  licenciadaId?: string | undefined;
  idEmpresa: string;
};
// prettier-ignore
export const inviteEmpresaExterno = httpsCallable<InviteEmpresaInputs, never>(functions, "projetosExternoFunctions-inviteEmpresaExterno");
