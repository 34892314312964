const DEFAULT_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  dateStyle: "short",
  timeStyle: "short"
};

/**
 * Format date using javascript API Intl.DateTimeFormat
 * @param date - The date to format
 * @param options - The options to format date
 * @returns - The formatted date
 */
export function formatDate(date: Date, options: Intl.DateTimeFormatOptions = DEFAULT_FORMAT_OPTIONS) {
  return new Intl.DateTimeFormat("pt-BR", options).format(date);
}

/**
 * Using Intl.RelativeTimeFormat to format relative time from now
 */
const RELATIVE_TIME_FORMAT = new Intl.RelativeTimeFormat("pt-BR", { numeric: "auto" });

const SECONDS_IN_MS = 1000;
const MINUTES_IN_SECONDS = 60;
const HOURS_IN_MINUTES = 60;
const DAYS_IN_HOURS = 24;

/**
 * Format relative time from now using javascript API Intl.RelativeTimeFormat
 * @param date - The date to format
 * @returns - The formatted relative time from now
 */
export function formatRelativeTime(date: Date) {
  const diff = date.getTime() - Date.now();
  const seconds = Math.floor(Math.abs(diff) / SECONDS_IN_MS);
  const minutes = Math.floor(seconds / MINUTES_IN_SECONDS);
  const hours = Math.floor(minutes / HOURS_IN_MINUTES);

  if (seconds < MINUTES_IN_SECONDS) {
    return `há ${Math.abs(Math.round(diff / SECONDS_IN_MS))} segundos`;
  } else if (minutes < HOURS_IN_MINUTES) {
    return `há ${Math.abs(Math.round(diff / SECONDS_IN_MS / MINUTES_IN_SECONDS))} minutos`;
  } else if (hours < DAYS_IN_HOURS) {
    return `há ${Math.abs(Math.round(diff / SECONDS_IN_MS / MINUTES_IN_SECONDS / HOURS_IN_MINUTES))} horas`;
  } else {
    return `há ${Math.abs(Math.round(diff / SECONDS_IN_MS / MINUTES_IN_SECONDS / HOURS_IN_MINUTES / DAYS_IN_HOURS))} dias`;
  }
}

/**
 * Filter Intl.DateTimeFormatPart by type literal
 * @param part - The part to filter
 * @returns - The part filtered
 */
function filterByLiteralOrInteger(part: Intl.RelativeTimeFormatPart) {
  return part.type === "literal" || part.type === "integer";
}

/**
 * Format relative time from now using javascript API Intl.RelativeTimeFormat
 * @param date - The date to format
 * @returns - The formatted relative time from now as an object with value and part properties
 */
export function extractRelativeTimeParts(date: Date) {
  const diff = date.getTime() - Date.now();
  const seconds = Math.floor(Math.abs(diff) / SECONDS_IN_MS);
  const minutes = Math.floor(seconds / MINUTES_IN_SECONDS);
  const hours = Math.floor(minutes / HOURS_IN_MINUTES);

  let parts: Intl.RelativeTimeFormatPart[] = [];
  if (seconds < MINUTES_IN_SECONDS) {
    parts = RELATIVE_TIME_FORMAT.formatToParts(diff / SECONDS_IN_MS, "seconds").filter((part) =>
      filterByLiteralOrInteger(part)
    );
  } else if (minutes < HOURS_IN_MINUTES) {
    parts = RELATIVE_TIME_FORMAT.formatToParts(diff / SECONDS_IN_MS / MINUTES_IN_SECONDS, "minutes").filter((part) =>
      filterByLiteralOrInteger(part)
    );
  } else if (hours < DAYS_IN_HOURS) {
    parts = RELATIVE_TIME_FORMAT.formatToParts(
      diff / SECONDS_IN_MS / MINUTES_IN_SECONDS / HOURS_IN_MINUTES,
      "hours"
    ).filter((part) => filterByLiteralOrInteger(part));
  } else {
    parts = RELATIVE_TIME_FORMAT.formatToParts(
      diff / SECONDS_IN_MS / MINUTES_IN_SECONDS / HOURS_IN_MINUTES / DAYS_IN_HOURS,
      "days"
    ).filter((part) => filterByLiteralOrInteger(part));
  }

  const [, partValue, partText] = parts.filter((part) => filterByLiteralOrInteger(part));
  return {
    value: partValue?.value,
    part: partText?.value
  };
}

/**
 * Get last period from date
 * @description
 * Get the last period from a date
 * For example... date that is 1 month ago, will return the last month
 * @param date - date to get last period
 * @returns last period from date
 */
export function getLastPeriodFromDate(date: Date) {
  const differenceInMSFromToday = Date.now() - date.getTime();
  const previousPeriodDate = new Date(date);
  previousPeriodDate.setTime(date.getTime() - differenceInMSFromToday);
  return previousPeriodDate;
}
