import { Anchor, Card, Flex, Grid, Group, Space, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconEye } from "@tabler/icons-react";
import { NavLink } from "react-router-dom";
import { formatDate } from "@/modules/diagnosticos/components/diagnostico-item/DiagnosticoList";
import { verificationDate } from "@/modules/projetos/components/projeto-form/ProjetoForm";
import useGetQuestionarioItem from "@/src/routes/responder-questionario-externo-page/hooks/useGetQuestionarioFromUrl";
import ColaboradoresConvidados from "./ColaboradoresConvidados";
import ColaboradoresRespondidos from "./ColaboradoresRespondido";

const MAGIC_NUMBER = 1000;

type Properties = {
  empresaId: string;
  questionarioId: string;
};

const QuestionarioItem = ({ questionarioId, empresaId }: Properties) => {
  const { data: questionario } = useGetQuestionarioItem(empresaId, questionarioId);

  const isSmMarches = useMediaQuery("(max-width: 48em)");

  return (
    <>
      <Space h="md" />
      <Card withBorder shadow="md" style={{ flex: 1, width: isSmMarches ? MAGIC_NUMBER : "100%" }}>
        <Grid align="center">
          <Grid.Col span={4}>
            <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
              <Text fw={300} fz="xs">
                Nome do questionário
              </Text>
              <Text fw={500} fz="md">
                {questionario?.nome}
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={2}>
            <ColaboradoresConvidados questionarioId={questionarioId} />
          </Grid.Col>
          <Grid.Col span={2}>
            <ColaboradoresRespondidos empresaId={empresaId} questionarioId={questionarioId} />
          </Grid.Col>
          <Grid.Col span={2}>
            <Group align={"center"} justify={"center"}>
              <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
                <Text fw={300} fz="xs">
                  Período de avaliação
                </Text>
                <Text fw={500} fz="md">
                  {typeof questionario?.dataAtuacao === "number"
                    ? formatDate(verificationDate(new Date()))
                    : formatDate(verificationDate(questionario?.dataAtuacao))}{" "}
                  -{" "}
                  {typeof questionario?.dataVigencia === "number"
                    ? formatDate(verificationDate(new Date()))
                    : formatDate(verificationDate(questionario?.dataVigencia))}
                </Text>
              </Flex>
            </Group>
          </Grid.Col>
          <Grid.Col span={2}>
            <Group align={"center"} justify={"center"}>
              <Anchor component={NavLink} to={`/questionariosColaborador/${questionario?.id}`}>
                <IconEye color="rgba(57, 106, 249, 1)" />
              </Anchor>
            </Group>
          </Grid.Col>
        </Grid>
      </Card>
    </>
  );
};

export default QuestionarioItem;
