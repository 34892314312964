import { Button, Card, Group, Select } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { IconX } from "@tabler/icons-react";
import { Timestamp } from "firebase/firestore";
import { useEffect, useState } from "react";
import useGetActiveColaboradores from "@/modules/colaboradores/hooks/useGetActiveColaboradores";
import { IDEIA_STATUS } from "@/modules/ideias/schema/ideia-schema";
import { FilterMovimentacao } from "../../firestore";
import { gerarDadosSelect } from "../../utils/gerar-dados-select";

const statusSelectData = gerarDadosSelect(IDEIA_STATUS);

type TFiltrosHistoricoProperties = {
  updateFilter: (filters: FilterMovimentacao[]) => void;
};

const FiltrosHistorico = ({ updateFilter }: TFiltrosHistoricoProperties) => {
  const [dates, setDates] = useState<[Date | null, Date | null]>([null, null]);
  const [status, setStatus] = useState<string | null>("");
  const [autor, setAutor] = useState<string | null>("");
  const [hasFilter, setHasFilter] = useState<boolean>(false);

  useEffect(() => {
    const newFilters: FilterMovimentacao[] = [
      { field: "status", operator: "==", value: status },
      { field: "createdBy", operator: "==", value: autor },
      { field: "createdAt", operator: ">", value: dates[0] ? Timestamp.fromDate(dates[0]) : null },
      { field: "createdAt", operator: "<", value: dates[1] ? Timestamp.fromDate(dates[1]) : null }
    ];

    const filtersWithValues = newFilters.filter((filter) => filter.value !== "" && filter.value !== null);
    updateFilter(filtersWithValues);
    setHasFilter(filtersWithValues.length > 0);
  }, [status, autor, dates, updateFilter]);

  const { data: colaboradores } = useGetActiveColaboradores({
    select(dataSnapshot) {
      if (dataSnapshot.empty) {
        return [];
      }

      return dataSnapshot.docs.map((document_) => {
        const colaborador = document_.data();
        return {
          value: colaborador.id,
          label: colaborador.nome
        };
      });
    }
  });

  /**
   * Reset filters to default values
   */
  function handleResetFilters() {
    setDates([null, null]);
    setStatus(null);
    setAutor(null);
  }

  return (
    <Card withBorder style={{ background: "#f4f5f6" }}>
      <Group align="flex-end">
        <Select label="Status" data={statusSelectData} miw="25%" value={status} onChange={setStatus} />
        <Select label="Autor da movimentação" data={colaboradores} miw="30%" value={autor} onChange={setAutor} />
        <DatePickerInput
          type="range"
          valueFormat="DD/MM/YYYY"
          label="Data da modificação"
          onChange={setDates}
          value={dates}
          miw="30%"
        />
        {hasFilter && (
          <Button title="Limpar filtros" maw="10%" onClick={handleResetFilters}>
            <IconX />
          </Button>
        )}
      </Group>
    </Card>
  );
};

export default FiltrosHistorico;
