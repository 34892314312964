import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { QUERY_KEY } from "@/modules/categorias-de-inovacao/firestore";
import { getCategoriasDeInovacao } from "@/modules/tipos-de-inovacao/firestore";
import useUserStore from "@/modules/users/store";

type TGetTiposDeInovacaoResponse = Awaited<ReturnType<typeof getCategoriasDeInovacao>>;

/**
 * Get tipos de inovação query hook
 * @returns tipos de inovação query hook
 */
function useGetTipoDeInovacao() {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  return useQuery<TGetTiposDeInovacaoResponse, FirestoreError, TCategoriaDeInovacao[]>({
    queryKey: ["empresas", { id: activeEmpresaId }, QUERY_KEY],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return getCategoriasDeInovacao(activeEmpresaId);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }
      return dataResponse.docs.map((document_) => document_.data());
    }
  });
}

export default useGetTipoDeInovacao;
