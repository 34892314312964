import Fuse, { IFuseOptions } from "fuse.js";
import { useEffect, useState } from "react";

export const useSearch = <T>(data: T[], options: IFuseOptions<T>) => {
  const [fuse, setFuse] = useState<Fuse<T> | null>(null);
  const [searchResult, setSearchResult] = useState<T[]>([]);
  const [, setSearchTimeout] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    setFuse(new Fuse(data, options));
    setSearchResult(data);
  }, [data, options]);

  const search = (value: string) => {
    if (!value) {
      return data;
    }

    if (fuse) {
      return fuse.search(value).map((result) => result.item);
    }

    return data;
  };

  const handleSearchEvent = (value: string, timeout: number) => {
    setSearchTimeout((state) => {
      if (state) {
        clearTimeout(state);
      }

      return setTimeout(() => {
        setSearchResult(search(value));
      }, timeout);
    });
  };

  return { search, value: searchResult, handleSearchEvent };
};
