import { Card, Group, Stack, Text, ThemeIcon } from "@mantine/core";
import { IconChartArcs } from "@tabler/icons-react";

const QuantidadeIdeiasMovimentadasCard = ({ comite }: { comite: TComiteInovacao }) => {
  return (
    <Card withBorder>
      <Group align="flex-start">
        <ThemeIcon size="md" radius="xl" mt={2}>
          <IconChartArcs width={18} radius="xl" />
        </ThemeIcon>
        <Stack gap={0}>
          <Text fz="lg">Quantidade de ideias que foram movimentadas</Text>
          <Group gap={6}>
            Foram movimentadas
            <Text fw="bold" fz="sm" span>
              {comite.ideiasReferences.length} ideias
            </Text>
          </Group>
        </Stack>
      </Group>
    </Card>
  );
};

export default QuantidadeIdeiasMovimentadasCard;
