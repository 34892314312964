import { Button, Card, Group, Text } from "@mantine/core";
import { useDisclosure, useForceUpdate } from "@mantine/hooks";
import { IconFilter, IconPlus } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";
import { useRef } from "react";
import AddItemButton from "@/components/add-item-button";
import RefreshQueryButton from "@/components/refresh-query-button";
import { THandler } from "@/components/table/Table";
import FilterList from "@/components/table/TableFiltersList";
import { useGetAuthorizations } from "@/hooks/useGetAuthorizations";
import TiposDeRespostasTable from "@/modules/tipos-de-respostas/components/tipos-de-respostas-table";
import { QUERY_KEY } from "@/modules/tipos-de-respostas/components/tipos-de-respostas-table/TiposDeRespostasTable";
import tiposDeRespostasSchema from "@/modules/tipos-de-respostas/schema/tipos-de-respostas-schema";
import TableFilters from "./TableFilters";

const REFRESH_ALL_KEY = [QUERY_KEY];

const TiposDeRespostasPage = () => {
  const tableHandlersReference = useRef<THandler<TTiposDeRespostas>>(null);
  const [opened, { close, toggle }] = useDisclosure(false);
  const queryClient = useQueryClient();

  const { create } = useGetAuthorizations("7");

  const forceUpdate = useForceUpdate();
  /**
   * Handle filter applied
   */
  function handleFilterApplied() {
    close();
    void queryClient.invalidateQueries({ queryKey: REFRESH_ALL_KEY }).then(() => forceUpdate());
  }

  return (
    <Card
      withBorder
      shadow="md"
      styles={{
        section: {
          overflow: "auto"
        }
      }}
    >
      <Card.Section px="md" py="xs" withBorder>
        <Group justify="space-between">
          <Text fw={500} fz="lg">
            Listagem de Tipos de Respostas
          </Text>
          {create && (
            <Group>
              <AddItemButton
                size="sm"
                leftSection={<IconPlus size="1rem" />}
                modalProperties={{ modal: "tipos-de-respostas-form-modal" }}
                schema={tiposDeRespostasSchema}
              >
                Adicionar
              </AddItemButton>
            </Group>
          )}
        </Group>
      </Card.Section>
      <Card.Section inheritPadding py="md" withBorder>
        <Group justify="space-between">
          <Group align="center">
            <Button variant="subtle" size="compact-md" onClick={toggle} leftSection={<IconFilter size="1rem" />}>
              Filtros
            </Button>
            {tableHandlersReference.current && <FilterList filters={tableHandlersReference.current.filters} />}
          </Group>
          <RefreshQueryButton queryKey={REFRESH_ALL_KEY} />
        </Group>

        {tableHandlersReference.current && (
          <TableFilters
            table={tableHandlersReference.current.table}
            filters={tableHandlersReference.current.filters}
            onClose={close}
            onFilterApplied={handleFilterApplied}
            opened={opened}
          />
        )}
      </Card.Section>
      <TiposDeRespostasTable ref={tableHandlersReference} />
    </Card>
  );
};

TiposDeRespostasPage.displayName = "TiposDeRespostasPage";

export default TiposDeRespostasPage;
