/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import { Box, Button, Group, Stack, Text } from "@mantine/core";

import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import Table from "@/components/table";
import GrupoFeedbackForm from "./GrupoFeedbackForm";
import FeedbackColumns, { getDeleteFeedbackColumn } from "./GrupoFeedbacksColumns";
import { UseGrupoFeedbacks } from "../../hooks/useGrupoFeedbacks";

type Properties = {
  controller: UseGrupoFeedbacks;
  questionario: TQuestionario;
};

/**
 * GrupoFeedbacksCard
 * @param root0 Properties
 * @param root0.controller GrupoFeedbacksController
 * @param root0.questionario TQuestionario
 * @returns React component
 */
export default function GrupoFeedbacksCard({ controller, questionario }: Properties) {
  const [addFeedbackOpen, setAddFeedbackOpen] = useState<boolean | TFeedback>(false);

  const { feedbacks, saveFeedback, removeFeedback } = controller;

  const handleEditFeedback = (feedback: TFeedback) => {
    setAddFeedbackOpen(feedback);
  };

  const handleDeleteFeedback = (feedback: TFeedback) => {
    removeFeedback(feedback.title);
  };

  const handleOpenFeedbackForm = () => {
    setAddFeedbackOpen(true);
  };

  const handleCreateFeedback = (feedback?: TFeedback) => {
    if (feedback) {
      saveFeedback(feedback);
    }
    setAddFeedbackOpen(false);
  };

  const tableOptions = useMemo(
    () => ({
      columns: [
        ...FeedbackColumns,
        getDeleteFeedbackColumn({ onEdit: handleEditFeedback, onDelete: handleDeleteFeedback })
      ],
      data: feedbacks,
      getCoreRowModel: getCoreRowModel()
    }),
    [feedbacks]
  );

  const table = useReactTable(tableOptions);

  const faixas = questionario.faixas || [];

  return (
    <Box>
      <Stack gap="sm">
        <Group justify="space-between" align="center">
          <Text size="lg">Feedbacks</Text>
          <Button onClick={handleOpenFeedbackForm}>Adicionar feedback</Button>
        </Group>
        {feedbacks.length > 0 && <Table table={table} isLoading={false} />}
      </Stack>

      <GrupoFeedbackForm open={addFeedbackOpen} onClose={handleCreateFeedback} faixas={faixas} />
    </Box>
  );
}
