import { generateColors } from "@mantine/colors-generator";
import {
  ActionIcon,
  Alert,
  Button,
  Card,
  Checkbox,
  ColorSchemeScript,
  MantineProvider,
  MantineTheme,
  MantineThemeOverride,
  NumberInput,
  Select,
  Table,
  TextInput,
  Textarea
} from "@mantine/core";
import "dayjs/locale/pt";
import { DateInput, DatesProvider } from "@mantine/dates";
import { ModalsProvider } from "@mantine/modals";
import { Notifications as NotificationsProvider } from "@mantine/notifications";
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import * as React from "react";
import CadastrarEvidenciasModal from "./modules/cadastrar-evidencias/modals/cadastrar-evidencias-modal";
import CampanhaRegulamentoIdeiasFormModal from "./modules/campanha-regulamento-ideias/modals/campanha-regulamento-ideias-form-modal";
import CategoriaDeInovacaoFormModal from "./modules/categorias-de-inovacao/modals/categoria-de-inovacao-form-modal";
import ColaboradorFormModal from "./modules/colaboradores/modals/colaborador-form-modal";
import InviteColaboradorModal from "./modules/colaboradores/modals/invite-colaborador-modal";
import IniciarComiteInovacaoModal from "./modules/comite-inovacao/modals/iniciar-comite-inovacao-modal";
import CategoriaDeDesafioFormModal from "./modules/desafio-categorias/modals/categoria-de-desafio-form-modal";
import ResponderDesafioComentarioModal from "./modules/desafio-comentarios/modals/responder-desafio-comentario-modal";
import EditarCronogramaDesafioModal from "./modules/desafio-cronograma/modals/editar-desafio";
import ModalCronogramaDesafio from "./modules/desafio-cronograma/modals/responder-desafio";
import DesafioFormModal from "./modules/desafios/modals/desafio-form-modal";
import EmpresaFormModal from "./modules/empresas/modals/empresa-form-modal";
import InviteEmpresaModal from "./modules/empresas/modals/invite-empresa-modal";
import GruposDiagnosticoFormModal from "./modules/grupos-diagnostico/modals/grupos-diagnostico-form-modal";
import AddBonusFormModal from "./modules/historico-pontos-per-colaborador/modals/add-bonus-form-modal";
import AddMovimentacaoModal from "./modules/home-de-inovacao/modals/add-movimentacao-form-modal";
import AddMovimentacaoTaticaModal from "./modules/home-de-inovacao/modals/modal-add-movimentacao-tatica";
import ResponderIdeiaComentarioModal from "./modules/ideia-comentarios/modals/responder-ideia-comentario-modal";
import IniciarAtuacaoModal from "./modules/ideia-marcos/modals/iniciar-atuacao-modal";
import MarcosFormModal from "./modules/ideia-marcos/modals/marcos-form-modal";
import { DetailIdeiaLikesModal } from "./modules/ideias/modals/detail-ideia-likes-modal";
import { DetailIdeiaPatrociniosModal } from "./modules/ideias/modals/detail-ideia-patrocinios-modal";
import IdeiaFormModal from "./modules/ideias/modals/ideia-form-modal";
import ModalCampanha from "./modules/ideias/modals/modal-campanha";
import LicenciadaFormModal from "./modules/licenciadas/modals/licenciada-form-modal";
import PerguntaFormModal from "./modules/perguntas/modals/pergunta-form-modal";
import ProjetoFormModal from "./modules/projetos/modals/projeto-form-modal";
import convidarColaboradorModal from "./modules/questionario-de-inovacao/modals/convidar-colaborador-modal";
import QuestionarioFormModal from "./modules/questionarios/modals/questionario-form-modal";
import RegiaoFormModal from "./modules/regiao/components/modals/regiao-form-modal";
import RegulamentoDesafiosFormModal from "./modules/regulamento-desafios/modals/regulamento-desafios-form-modal";
import TipoDeJogadaDeFormModal from "./modules/tipo-de-jogada/modals/tipo-de-jogada-form-modal";
import AddTipoDeInovacaoFormModal from "./modules/tipos-de-inovacao/modals/add-tipo-de-inovacao-form-modal";
import TiposDeRespostasFormModal from "./modules/tipos-de-respostas/modals/tipos-de-respostas-form-modal";
import ForgotPasswordModal from "./modules/users/modals/forgot-password-modal";
import { captureException } from "./services/log";

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: Error, query) => {
      captureException(error, query.meta?.displayNotification);
      if (import.meta.env.DEV) {
        console.error(error);
      }
    }
  }),
  defaultOptions: {
    queries: {
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      retry: 3
    }
  }
});

/**
 * Extend Mantine component
 * @param _theme - Mantine theme
 * @param properties - Component properties
 * @param properties.readOnly - If input is read only
 * @returns Component styles
 */
function getVariables(_theme: MantineTheme, properties: { readOnly: boolean }) {
  if (properties.readOnly) {
    return {
      input: {
        color: "gray"
      }
    };
  }
  return { input: {} };
}

const mantineTheme: MantineThemeOverride = {
  colors: {
    white: generateColors("#FFFFFF")
  },
  components: {
    TextInput: TextInput.extend({
      defaultProps: {
        radius: "md"
      },
      vars: getVariables
    }),
    NumberInput: NumberInput.extend({
      defaultProps: {
        radius: "md"
      }
    }),
    DateInput: DateInput.extend({
      defaultProps: {
        radius: "md"
      },
      vars: getVariables
    }),
    Checkbox: Checkbox.extend({
      defaultProps: {
        radius: "md"
      },
      vars(_theme, properties) {
        if (properties.variant === "unstyled") {
          return {
            root: {},
            label: {
              color: "gray"
            }
          };
        }
        return { root: {}, label: {} };
      }
    }),
    Textarea: Textarea.extend({
      defaultProps: {
        radius: "md"
      },
      vars: getVariables
    }),
    Button: Button.extend({
      defaultProps: {
        radius: "lg"
      }
    }),
    Select: Select.extend({
      defaultProps: {
        radius: "md"
      }
    }),
    Alert: Alert.extend({
      defaultProps: {
        radius: "md"
      }
    }),
    Card: Card.extend({
      defaultProps: {
        radius: "lg"
      }
    }),
    ActionIcon: ActionIcon.extend({
      defaultProps: {
        radius: "md"
      }
    }),
    Table: Table.extend({
      defaultProps: {
        verticalSpacing: "sm",
        highlightOnHover: true
      }
    }),
    TableThead: Table.Thead.extend({
      defaultProps: {
        bg: "gray.2"
      }
    })
  }
};

const modals = {
  "forgot-password-modal": ForgotPasswordModal,
  "add-tipo-de-inovacao-form-modal": AddTipoDeInovacaoFormModal, // aqui
  "add-movimentacao-modal": AddMovimentacaoModal,
  "cronograma-desafios": ModalCronogramaDesafio,
  "editar-cronograma-desafios": EditarCronogramaDesafioModal,
  "modal-campanha": ModalCampanha,
  "add-movimentacao-taticas": AddMovimentacaoTaticaModal, // aqui
  // Empresa
  "empresa-form-modal": EmpresaFormModal,
  "invite-empresa-modal": InviteEmpresaModal,

  // Licenciada
  "licenciada-form-modal": LicenciadaFormModal,

  // Colaborador
  "colaborador-form-modal": ColaboradorFormModal,
  "invite-colaborador-modal": InviteColaboradorModal,

  // Projeto
  "projeto-form-modal": ProjetoFormModal,
  "categoria-de-inovacao-form-modal": CategoriaDeInovacaoFormModal,
  "tipo-de-jogada-form-modal": TipoDeJogadaDeFormModal,

  // Projeto
  "convidar-colaborador-modal": convidarColaboradorModal,

  "cadastrar-evidencias-modal": CadastrarEvidenciasModal,

  // Questionario
  "questionario-form-modal": QuestionarioFormModal,

  // Grupo
  "grupos-diagnostico-form-modal": GruposDiagnosticoFormModal,

  // Perguntas
  "pergunta-form-modal": PerguntaFormModal,

  // Tipo de Respotas
  "tipos-de-respostas-form-modal": TiposDeRespostasFormModal,

  // Campanha Regulamento de Ideias
  "campanha-regulamento-ideias-form-modal": CampanhaRegulamentoIdeiasFormModal,

  // Ideia
  "ideia-form-modal": IdeiaFormModal,
  "detail-ideia-patrocinios-modal": DetailIdeiaPatrociniosModal,
  "detail-ideia-likes-modal": DetailIdeiaLikesModal,

  // Ideia Comentário
  "responder-ideia-comentario-modal": ResponderIdeiaComentarioModal,

  // Comitê de Inovação
  "iniciar-comite-inovacao-modal": IniciarComiteInovacaoModal,

  //Marcos
  "marcos-form-modal": MarcosFormModal,
  "iniciar-atuacao-modal": IniciarAtuacaoModal,

  // Regulamento Desafios
  "regulamento-desafios-form-modal": RegulamentoDesafiosFormModal,

  // Desafios
  "desafio-form-modal": DesafioFormModal,
  "categoria-de-desafio-form-modal": CategoriaDeDesafioFormModal,

  // Desafio Comentário
  "responder-desafio-comentario-modal": ResponderDesafioComentarioModal,

  "add-bonus-form-modal": AddBonusFormModal,

  // Região
  "regiao-form-modal": RegiaoFormModal
};

declare module "@mantine/modals" {
  // eslint-disable-next-line import/no-unused-modules
  export interface MantineModalsOverride {
    modals: typeof modals;
  }
}

const AppProviders: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <>
      <ColorSchemeScript defaultColorScheme="light" />
      <MantineProvider theme={mantineTheme} defaultColorScheme="light" forceColorScheme="light">
        <DatesProvider settings={{ locale: "pt" }}>
          <NotificationsProvider position="top-right" />
          <QueryClientProvider client={queryClient}>
            <ModalsProvider modals={modals}>
              {children}
              {import.meta.env.DEV && <ReactQueryDevtools initialIsOpen={false} />}
            </ModalsProvider>
          </QueryClientProvider>
        </DatesProvider>
      </MantineProvider>
    </>
  );
};

export default AppProviders;
