import { serverTimestamp } from "firebase/firestore";
import { ObjectSchema } from "yup";
import schema from "@/schema";
import auditSchema from "@/schema/audit-schema";
import { TRegulamentoDesafiosForm } from "./firestore";

const MIN_NAME_LENGTH = 3;

const regulamentoDesafioFormSchema: ObjectSchema<TRegulamentoDesafiosForm> = auditSchema
  .clone()
  // eslint-disable-next-line unicorn/prefer-spread
  .concat(
    schema.object({
      nome: schema.string().min(MIN_NAME_LENGTH).required().defined().default("").label("Nome"),
      dataInicial: schema.date().required().defined().label("Data Inicial"),
      dataFinal: schema.date().required().defined().label("Data Final"),
      quantidadeMoedasPorColaborador: schema
        .number()
        .transform((value: number | string | null) => (value ? Number(value) : value))
        .required()
        .defined()
        .min(0)
        .default(0)
        .label("Quantidade de Moedas por Colaborador"),
      deletedAt: schema.mixed<ReturnType<typeof serverTimestamp>>().defined().nullable().default(null)
    })
  )
  .noUnknown()
  .label("Regulamento Desafios");

export default regulamentoDesafioFormSchema;
