/* eslint-disable unicorn/consistent-function-scoping */
import { Badge, Button, Card, Divider, Flex, Group, Stack, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { useCallback, useMemo } from "react";
import EstrategiaItem from "./EstrategiaItem";
import useGetPergunta from "./useGetPerguntas";
import useQueryRespostas from "./useGetRespostas";
import useQueryRespostasEmpresa from "./useGetRespostasEmpresa";
import useGetStatusIdEmpresa from "./useGetStatus";

type CardHeaderProperties = {
  grupo: TGruposDiagnostico;
  opened: boolean;
  toggle: () => void;
};

type PerguntaResposta = {
  grupo: string;
  id: string;
  resposta: number;
};

/**
 * CardHeader
 * @param root0 - properties
 * @param root0.grupo - grupo
 * @param root0.opened - opened
 * @param root0.toggle - toggle
 * @returns CardHeader
 */
function CardHeader({ grupo, opened, toggle }: CardHeaderProperties) {
  return (
    <Group align="center" justify="space-between">
      <Stack gap="0" align="start">
        <Text size="xl">{grupo.nome}</Text>
        <Text size="xs">{grupo.descricao}</Text>
      </Stack>

      <Button
        type="button"
        variant="transparent"
        size="compact-sm"
        rightSection={opened ? <IconChevronUp size="1rem" /> : <IconChevronDown size="1rem" />}
        onClick={toggle}
      >
        Ver mais
      </Button>
    </Group>
  );
}

const EstrategiaList = ({ grupo }: { grupo: TGruposDiagnostico }) => {
  const [opened, { toggle }] = useDisclosure(false);

  const { data: statusArray } = useGetStatusIdEmpresa();
  const { data } = useGetPergunta({ ids: [grupo?.id] });

  const { data: respostas } = useQueryRespostas({ id: grupo?.idQuestionario });
  const { data: respostasEmpresas } = useQueryRespostasEmpresa({ id: grupo?.idQuestionario });

  const getAverageInResposta = useCallback((resposta: TResposta) => {
    return (
      resposta.perguntas.reduce((current, item: PerguntaResposta) => current + item.resposta, 0) /
      resposta.perguntas.length
    );
  }, []);

  const averageInGrupo = useMemo(() => {
    return respostasEmpresas
      ? respostasEmpresas.reduce((accumulator, item) => accumulator + getAverageInResposta(item), 0) /
          respostasEmpresas.length
      : 0;
  }, [respostasEmpresas]);

  const feedback = useMemo(() => {
    return grupo.feedbacks.find((f) => f.start <= averageInGrupo && f.end >= averageInGrupo);
  }, [grupo, averageInGrupo]);

  if (!respostas || !respostasEmpresas || !opened) {
    return (
      <Card withBorder shadow="md" style={{ flex: 1, marginBottom: 15 }}>
        <CardHeader grupo={grupo} opened={opened} toggle={toggle} />
      </Card>
    );
  }

  const perguntas = data || [];
  const statusItem = statusArray && statusArray[0];
  const respostasItem = [...respostas, ...respostasEmpresas];

  return (
    <Card withBorder shadow="md" style={{ flex: 1, marginBottom: 15 }}>
      <CardHeader grupo={grupo} opened={opened} toggle={toggle} />

      <Divider my="md" />

      <Stack>
        <Group justify="space-between" align="center">
          <Text size="lg">Análise do resultado na dimensão</Text>

          <Group>
            {grupo.feedbacks.map((f) => {
              const isSelected = f.start <= averageInGrupo && f.end >= averageInGrupo;
              return (
                <Badge key={f.title} variant={isSelected ? "filled" : "transparent"}>
                  {f.title}
                </Badge>
              );
            })}
          </Group>
        </Group>
        <Text size="sm">{feedback?.description}</Text>
      </Stack>

      <Divider my="md" />

      <Flex direction="column">
        {perguntas.length > 0 ? (
          perguntas.map((pergunta: TPergunta, index: number) => (
            <EstrategiaItem
              key={pergunta.id}
              pergunta={pergunta}
              numero={index + 1}
              statusItem={statusItem}
              respostas={respostasItem}
              grupo={grupo}
            />
          ))
        ) : (
          <Group justify="center">
            <Text fw="bold">Nenhum valor adicionado!</Text>
          </Group>
        )}
        <Divider my="md" />
      </Flex>
    </Card>
  );
};

export default EstrategiaList;
