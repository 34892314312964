import { useState } from "react";
import { getMunicipiosByUf, TMunicipio } from "@/services/ibge";

export const useGetMunicipiosByUf = () => {
  const [municipios, setMunicipios] = useState<TMunicipio[]>([]);
  const [loading, setLoading] = useState(true);

  const fetch = async (uf: string) => {
    setLoading(true);
    const municipios = await getMunicipiosByUf(uf);
    setMunicipios(municipios);
    setLoading(false);
  };

  return { municipios, loading, fetch };
};
