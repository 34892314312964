import { subject } from "@casl/ability";
import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { getMediaGrupoByEmpresaId } from "@/modules/media-grupo/firestore";
import useUserStore from "@/modules/users/store";

type TGetMediaGrupoByEmpresaIdResponse = Awaited<ReturnType<typeof getMediaGrupoByEmpresaId>>;

const QUERY_KEY = "respostas";

/**
 * Get respostas query hook
 * @param root0 - root
 * @param root0.id - id
 * @returns Respostas query hook
 */
function useMediaGrupoByEmpresaId({ id }: { id: string }) {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);
  const { idEmpresa } = useParams<{ idEmpresa: string }>();

  return useQuery<TGetMediaGrupoByEmpresaIdResponse, FirestoreError, TMediaGrupo[]>({
    queryKey: ["MediaGrupos", { id: activeEmpresaId }, QUERY_KEY, id, idEmpresa],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      if (!id) {
        throw new Error("Empresa não selecionada");
      }
      return getMediaGrupoByEmpresaId(activeEmpresaId, id, idEmpresa || "");
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }
      return dataResponse.docs.map((document_) => subject("TMediaGrupo", document_.data()));
    }
  });
}

export default useMediaGrupoByEmpresaId;
