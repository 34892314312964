import { Group, Text, List } from "@mantine/core";
import { FileWithPath } from "@mantine/dropzone";
import FileSelectorDropZone from "@/components/file-selector-dropzone";
import FileItem from "@/components/file-selector-dropzone/FileItem";
import { extractTextInputPropertiesFromFormSchema } from "@/utils/form";
import { useDesafioFormContext } from "./desafio-form-context";
import desafioFormSchema from "../../schema";
// prettier-ignore
const DesafioAnexos = ({ isLoading }: { isLoading: boolean }) => {
  const form = useDesafioFormContext();
  const fieldsDescriptor = desafioFormSchema.describe();
  const anexosFieldDescription = extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "anexos");

  /**
   * Handle anexos selected
   * @param files - The selected files
   */
  function handleAnexosSelected(files: FileWithPath[]) {
    for (const file of files) {
      form.insertListItem("anexosFilesToUpload", file);
    }
  }
  /**
   * Handle remove anexo to upload from list
   * @param _file - File to remove (not used)
   * @param index - File index to remove
   */
  function handleRemoveAnexoToUpload(_file: FileWithPath, index: number) {
    form.removeListItem("anexosFilesToUpload", index);
  }
  /**
   * Handle remove anexo to upload from list
   * @param _file - File to remove (not used)
   * @param index - File index to remove
   */
  function handleRemoveAnexo(_file: TFileStored, index: number) {
    form.removeListItem("anexos", index);
  }

  return (
    <div>
      <Text fz="sm" fw={500}>
        {anexosFieldDescription.label}
      </Text>
      <Text fz="xs" c="dimmed">
        {anexosFieldDescription.description}
      </Text>
      <Group mt="sm" wrap="nowrap" justify="space-between" align="center">
        <FileSelectorDropZone disabled={isLoading} onDrop={handleAnexosSelected} miw="200px" />
        <List
          withPadding
          styles={{
            root: {
              flex: 1
            }
          }}
        >
          {form.values.anexosFilesToUpload.map((file, index) => (
            <FileItem key={file.path} file={file} index={index} onRemove={handleRemoveAnexoToUpload} />
          ))}
          {form?.values?.anexos?.length > 0 ? form?.values?.anexos?.map((file, index) => (
            <FileItem key={file.path} file={file} index={index} onRemove={handleRemoveAnexo} />
          )) : <></>}
        </List>
      </Group>
    </div>
  );
};

export default DesafioAnexos;
