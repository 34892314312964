import { Button, ButtonProps } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { modals } from "@mantine/modals";
import { ForwardedRef, MouseEvent, PropsWithChildren, forwardRef } from "react";
import { ObjectSchema } from "yup";

// export type TModalProperties<TKey extends MantineModal> = Omit<Parameters<MantineModals[TKey]>[0], "innerProps">;
// export type TModalProperties<TKey extends MantineModal> = Omit<ContextModalProps<T>, "innerProps">;
export type TModalProperties = Omit<Parameters<typeof modals.openContextModal>[0], "innerProps">;
type AddItemButtonProperties<T extends object> = PropsWithChildren<
  ButtonProps & {
    schema: ObjectSchema<T>;
    item?: Partial<T>;
    onSubmit?: (values: T, form: UseFormReturnType<T>) => void;
    setName?: (name: string) => void;
    modalProperties: TModalProperties;
  }
>;

/**
 * A button that opens a modal to edit an item.
 * @param root0 - The properties of the button.
 * @param root0.modal - The modal to be opened.
 * @param root0.schema - The schema of the item.
 * @param root0.children - The children of the button.
 * @param [root0.item] - The default values for the item.
 * @param [root0.onSubmit] - The function to be called when the form is submitted.]
 * @param [root0.setName] - The function to be called when the form is submitted.
 * @param [root0.modalProperties] - The properties of the modal.
 * @param reference - The reference to the button.
 * @returns The element to be rendered.
 */
function AddItemButton<T extends object>(
  { schema, item, onSubmit, setName, children, modalProperties, ...properties }: AddItemButtonProperties<T>,
  reference: ForwardedRef<HTMLButtonElement>
) {
  /**
   * Handles the click event on the button.
   * @param event - The event that triggered the click.
   */
  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    const defaultValues = schema.getDefault() as T;
    event.preventDefault();
    modals.openContextModal({
      size: "xl",
      innerProps: {
        item: schema.cast({ ...defaultValues, ...item }, { stripUnknown: true, assert: false }),
        onSubmit,
        setName
      },
      ...modalProperties
    });
  }

  return (
    <Button type="button" onClick={handleClick} {...properties} ref={reference}>
      {children}
    </Button>
  );
}

const AddItemButtonWithReference = forwardRef(AddItemButton);

AddItemButtonWithReference.displayName = "AddItemButton";

export default AddItemButtonWithReference;
