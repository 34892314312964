import { Card, Text, Box, LoadingOverlay } from "@mantine/core";
import { useParams } from "react-router-dom";
import BreadcrumbsNavigation from "@/components/breadcrumbs-navigation";
import AddJogadaDeInovacao from "@/modules/jogadas-de-inovacao/components/add-jogadas-de-inovacao";
import useGetJogadaDeInovacaoFromUrl from "@/modules/jogadas-de-inovacao/components/jogadas-de-inovacao-list/useGetJogadaDeInovacaoFromUrl";

const JogadaDeInovacaoPage = () => {
  const { data, isFetching } = useGetJogadaDeInovacaoFromUrl();
  const { tipo } = useParams<{ tipo: string }>();

  if (isFetching) {
    return (
      <Box pos="relative">
        <LoadingOverlay visible={isFetching} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
      </Box>
    );
  }

  return (
    <>
      <BreadcrumbsNavigation notHome />
      <Card withBorder padding="xl">
        <Text size="xl" fw={500}>
          {tipo === "customizada" ? "Customizar" : data?.id ? "Editar" : "Criar"} jogada de inovação
        </Text>
        <Card.Section inheritPadding py="xl">
          <AddJogadaDeInovacao data={data} />
        </Card.Section>
      </Card>
    </>
  );
};

export default JogadaDeInovacaoPage;
