import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getTotalIdeiasBetween } from "@/modules/ideias/firestore";
import useUserStore from "@/modules/users/store";

type TGetTotalIdeiasSince = Awaited<ReturnType<typeof getTotalIdeiasBetween>>;

/**
 * Count total of ideias cadastradas since date
 * @param dateStart - date start to count total of ideias
 * @param dateEnd - date end to count total of ideias
 * @returns count
 */
function useGetCountTotalOfIdeiasCadastradasBetween(dateStart: Date, dateEnd?: Date) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  return useQuery<TGetTotalIdeiasSince, FirestoreError, number>({
    queryKey: [activeEmpresaId, "countTotalOfIdeiasCadastradasSince", { dateStart, dateEnd }],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return getTotalIdeiasBetween(activeEmpresaId, dateStart, dateEnd);
    },
    select(aggregateSnapshot) {
      return aggregateSnapshot.data().count;
    }
  });
}

export default useGetCountTotalOfIdeiasCadastradasBetween;
