/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import { Avatar, Box, Flex, Group, Space, Text } from "@mantine/core";
import CheckListComponent from "./ChecklistComponent";
import RadioComponent from "./RadioComponent";
import RatingComponent from "./RatingComponent";
import TextComponent from "./TextComponent";
import useGetTipoPerguntaConvite from "./useGetTipoPerguntaConvite";
import { ArrayProperties, TRespostaField } from "../../types";

type Properties = {
  item: ArrayProperties;
  changeValues: (value: TRespostaField, item: ArrayProperties) => void;
  disabled: boolean;
  licenciado: TLicenciado | null | undefined;
  empresaId: string;
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
const PerguntasComponente = ({ item, changeValues, disabled, licenciado, empresaId }: Properties) => {
  const { data: tipo } = useGetTipoPerguntaConvite({
    id: item?.tipoDeResposta,
    activeEmpresaId: empresaId
  });

  if (item.value === 0 && !tipo && !item.pergunta) {
    return (
      <Flex justify="center" align="center" direction="column">
        {licenciado?.logo && licenciado.logo.length > 0 && (
          <>
            <Group>
              {licenciado.logo.map((item) => (
                <Box key={item.name}>
                  <Avatar src={item?.path} radius="sm" size="lg" variant="default" />
                  <Space h="md" />
                </Box>
              ))}
            </Group>
            <Space h="md" />
          </>
        )}
        <Text fw={700} size="lg">
          {item?.nome}
        </Text>
        <Space h="md" />
        <Text size="md">{item?.descricao}</Text>
      </Flex>
    );
  }

  switch (tipo?.tipoDeResposta) {
    case "1": {
      return <TextComponent item={item} changeValues={changeValues} disabled={disabled} licenciado={licenciado} />;
    }
    case "2": {
      return <RatingComponent item={item} changeValues={changeValues} disabled={disabled} licenciado={licenciado} />;
    }
    case "3": {
      return (
        <CheckListComponent
          item={item}
          onChange={changeValues}
          tipo={tipo}
          disabled={disabled}
          horizontal={tipo.orientacao === "2"}
          licenciado={licenciado}
        />
      );
    }
    case "4": {
      return (
        <RadioComponent
          item={item}
          tipo={tipo}
          disabled={disabled}
          onChange={changeValues}
          horizontal={tipo.orientacao === "2"}
          licenciado={licenciado}
        />
      );
    }
    default: {
      return <Group />;
    }
  }
};

export default PerguntasComponente;
