import { Text, FocusTrap } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import InviteEmpresaForm from "../../components/invite-empresa-form";

const InviteEmpresaModal = ({ context, id }: ContextModalProps) => {
  /**
   * Handle add ignore area success
   */
  function onSuccess() {
    context.closeModal(id);
  }
  return (
    <FocusTrap active={true}>
      <Text fw="bold" mb="md">
        Enviar convite
      </Text>
      <InviteEmpresaForm onSuccess={onSuccess} />
    </FocusTrap>
  );
};

export default InviteEmpresaModal;
