import { subject } from "@casl/ability";
import { Anchor, Group } from "@mantine/core";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { ability } from "@/components/can/Can";
import { formatDate } from "@/modules/diagnosticos/components/diagnostico-item/DiagnosticoList";
import ContextMenuRow from "./ContextMenuRow";
import { verificationDate } from "../projeto-form/ProjetoForm";

const columnHelper = createColumnHelper<TProjeto>();

const columns: ColumnDef<TProjeto>[] = [
  columnHelper.accessor("nome", {
    header: "Nome",
    enableSorting: true,
    enableColumnFilter: true
  }),
  columnHelper.accessor("anoAtuacao", {
    header: "Data de início",
    enableSorting: true,
    enableColumnFilter: true,
    cell: (properties) => {
      const value = properties.getValue();
      return formatDate(verificationDate(value)) ?? "";
    }
  }),
  columnHelper.accessor("anoVigencia", {
    header: "Data de Fim",
    enableSorting: true,
    enableColumnFilter: true,
    cell: (properties) => {
      const value = properties.getValue();
      return formatDate(verificationDate(value)) ?? "";
    }
  }),
  columnHelper.accessor("linkRegulamento", {
    header: "Regulamento",
    enableSorting: true,
    enableColumnFilter: true,
    cell: (properties) => (
      <Anchor href={properties.getValue()} target="_blank">
        {properties.getValue()}
      </Anchor>
    )
  }),
  columnHelper.display({
    id: "actions",
    cell: (properties) => {
      const projeto = subject("TProjeto", properties.row.original);
      // const projeto = properties.row.original;
      const canIUpdate = ability.can("update", projeto);
      const canIDelete = ability.can("delete", projeto);
      const hasActions = canIDelete || canIUpdate;
      if (hasActions) {
        return (
          <Group justify="center" gap={2}>
            <ContextMenuRow projeto={projeto} />
          </Group>
        );
      }

      return null;
    }
  })
];

export default columns;
