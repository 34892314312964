import { serverTimestamp } from "firebase/firestore";
import { ObjectSchema } from "yup";
import schema from "@/schema";
import auditSchema from "@/schema/audit-schema";
import { TRegulamentoIdeiasForm } from "./firestore";
import campanhaRegulamentoIdeiasSchema from "../campanha-regulamento-ideias/schema";

const MIN_NAME_LENGTH = 3;

const regulamentoIdeiasSchema: ObjectSchema<TRegulamentoIdeiasForm> = auditSchema
  .clone()
  // eslint-disable-next-line unicorn/prefer-spread
  .concat(
    schema.object().shape({
      nome: schema.string().min(MIN_NAME_LENGTH).required().defined().label("Nome"),
      dataInicial: schema.date().required().defined().label("Data Inicial"),
      dataFinal: schema.date().required().defined().label("Data Final"),
      quantidadeMoedasPorColaborador: schema
        .number()
        .transform((value: number | string | null) => (value ? Number(value) : value))
        .required()
        .defined()
        .min(0)
        .default(0)
        .label("Quantidade de Moedas por Colaborador"),

      quantidadeGanhoNoCadastro: schema
        .number()
        .transform((value: number | string | null) => (value ? Number(value) : value))
        .required()
        .defined()
        .min(0)
        .default(0)
        .label("Cadastro de ideias"),
      quantidadeGanhoNaAprovacao: schema
        .number()
        .transform((value: number | string | null) => (value ? Number(value) : value))
        .required()
        .defined()
        .min(0)
        .default(0)
        .label("Aprovação de ideias"),
      quantidadeGanhoNaImplementacao: schema
        .number()
        .transform((value: number | string | null) => (value ? Number(value) : value))
        .required()
        .defined()
        .min(0)
        .default(0)
        .label("Implementação de ideias"),
      quantidadeGanhoNaParticipacaoEImplementacaoIdeiaPropria: schema
        .number()
        .transform((value: number | string | null) => (value ? Number(value) : value))
        .required()
        .defined()
        .min(0)
        .default(0)
        .label("Participação na implementação de ideia própria"),
      quantidadeGanhoNaParticipacaoEImplementacaoIdeiaTerceiro: schema
        .number()
        .transform((value: number | string | null) => (value ? Number(value) : value))
        .required()
        .defined()
        .min(0)
        .default(0)
        .label("Participação na implementação de ideia de terceiros"),

      permitirControlarInteracao: schema
        .boolean()
        .required()
        .defined()
        .default(false)
        .label("Permitir Controlar Interação"),

      permitirIdeiaPrivada: schema.boolean().required().defined().default(false).label("Permitir Ideia Privada"),

      campanhas: schema
        .array()
        .of(campanhaRegulamentoIdeiasSchema)
        .required()
        .defined()
        .default([])
        .min(0)
        .label("Campanhas"),

      linkRegulamento: schema.string().nullable().defined().default(null).label("Link Regulamento"),

      deletedAt: schema.mixed<ReturnType<typeof serverTimestamp>>().defined().nullable().default(null)
    })
  )
  .noUnknown()
  .strict()
  .label("Regulamento Ideias");

export default regulamentoIdeiasSchema;
