import { Card, Group, List, Text, Textarea } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import Can from "@/components/can";
import FileItem from "@/components/file-selector-dropzone/FileItem";
import ColaboradorMultiSelector from "@/modules/colaboradores/components/colaborador-multi-selector";
import useGetColaboradoresFromReference from "@/modules/colaboradores/hooks/useGetColaboradoresFromReference";
import OpenAtuacaoModalButton from "@/modules/ideia-marcos/components/OpenAtuacaoModalButton";
import useUserStore from "@/modules/users/store";
// prettier-ignore
const MarcosList = ({ marco }: { marco: TMarco }) => {
  const { isInProgress, borderCardColor, bgCardColor } = verificationMarcos(marco);
  const { colaborador } = useUserStore();
  const { data: allParticipantes } = useGetColaboradoresFromReference(marco.colaboradoresResponsaveis);
  const canIniciarMarco = allParticipantes?.some((participante) => participante.id === colaborador?.id);

  return (
    <Card withBorder my="md" bg={bgCardColor} style={{ borderColor: borderCardColor }}>
      <Group justify="space-between">
        <ColaboradorMultiSelector
          multiple
          style={{ flex: 1 }}
          label="Responsável pelo marco"
          value={marco.colaboradoresResponsaveis}
          readOnly
          variant="unstyled"
        />
        <DateInput
          label="Data limite para a execução da atividade"
          valueFormat="DD/MM/YYYY"
          style={{ flex: 1 }}
          variant="unstyled"
          value={marco.dataPrazo}
          readOnly
        />
        {marco.dataConclusao && (
          <DateInput
            label="Data para conclusão da atividade"
            valueFormat="DD/MM/YYYY"
            style={{ flex: 1 }}
            variant="unstyled"
            value={marco.dataConclusao}
            readOnly
          />
        )}
      </Group>

      {marco.acoes && (
        <Textarea label="Atuação" variant="unstyled" value={marco.acoes} readOnly />
      )}
      {marco.descricaoAtuacao && (
        <Textarea label="Descrição da atuação" variant="unstyled" value={marco.descricaoAtuacao} readOnly />
      )}
      {marco && marco.anexos && marco.anexos.length > 0 && (
        <>
          <Text fw={500} fz="sm">
            Anexos
          </Text>
          <List
            withPadding
            styles={{
              root: {
                flex: 1
              }
            }}
          >
            {marco.anexos.map((file, index) => (
              <FileItem key={file.path} file={file} index={index} readOnly />
            ))}
          </List>
        </>
      )}

      {isInProgress && canIniciarMarco && (
        <Can I="update" a="TMarco">
          <Group justify="flex-end">
            <OpenAtuacaoModalButton marco={marco} />
          </Group>
        </Can>
      )}
    </Card>
  )
}

export default MarcosList;

// prettier-ignore
const verificationMarcos = (marco: TMarco | null | undefined) => {
  const isAlreadyStarted = !!marco?.startedAt && !marco?.finishedAt;
  const isNotStarted = !marco?.startedAt;
  const isFinished = !!marco?.finishedAt;
  const isInProgress = !marco?.startedAt && !marco?.finishedAt;
  const borderCardColor = isFinished ? "green" : isNotStarted ? "blue" : isAlreadyStarted ? "gray.1" : "";
  const bgCardColor = isFinished ? "green.1" : isAlreadyStarted ? "gray.2" : "";

  return { isInProgress, borderCardColor, bgCardColor }
};
