import { subject } from "@casl/ability";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import {
  serverTimestamp,
  Timestamp,
  FirestoreDataConverter,
  collection,
  getCountFromServer,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  getDoc
} from "firebase/firestore";
import { getCurrentUser } from "@/services/auth";
import { generateQuery } from "@/utils/firestore";
import { getEmpresaDocumentReference } from "../empresas/firestore";

const DEFAULT_LIMIT = 10;
export const DEFAULT_SORTING: SortingState = [{ id: "nome", desc: false }];
export const DEFAULT_PAGINATION: PaginationState = { pageIndex: 0, pageSize: DEFAULT_LIMIT };

export type TTiposDeRespostasFormFields = Omit<
  TTiposDeRespostas,
  "id" | "refPath" | "createdAt" | "updatedAt" | "createdBy" | "updatedBy" | "deletedAt"
> &
  Partial<Pick<TTiposDeRespostas, "id" | "refPath">>;

export type TTiposDeRespostasDatabaseFields = Pick<TTiposDeRespostas, "createdBy" | "updatedBy"> & {
  createdAt: ReturnType<typeof serverTimestamp>;
  updatedAt?: ReturnType<typeof serverTimestamp>;
  deletedAt: ReturnType<typeof serverTimestamp> | null;
};
export type TTiposDeRespostasForm = TTiposDeRespostasFormFields & TTiposDeRespostasDatabaseFields;

type TTiposDeRespostasDocument = Omit<TTiposDeRespostas, "createdAt" | "updatedAt" | "deletedAt"> & {
  createdAt: Timestamp;
  updatedAt?: Timestamp;
  deletedAt: Timestamp | null;
};

const tiposDeRespostasConverter: FirestoreDataConverter<TTiposDeRespostas> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const { createdAt, updatedAt, deletedAt, ...document } = snap.data() as TTiposDeRespostasDocument;

    const data: TTiposDeRespostas = {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      deletedAt: deletedAt ? deletedAt.toDate() : null,
      refPath: snap.ref.path
    };

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }
    return subject("TTiposDeRespostas", data);
  }
};

/**
 * Get tiposDeRespostas collection reference from empresaId
 * @param empresaId - Empresa id to get the tiposDeRespostas collection reference
 * @returns - TiposDeRespostas collection reference
 */
function getTiposDeRespostasCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, "tiposDeRespostas");
}

/**
 * Get tiposDeRespostas document reference from empresaId
 * @param empresaId - Empresa id to get the tiposDeRespostas document reference
 * @param tiposDeRespostasId - TiposDeRespostas id to get the tiposDeRespostas document reference
 * @returns - TiposDeRespostas document reference
 */
function getTiposDeRespostasDocumentReference(empresaId: TEmpresa["id"], tiposDeRespostasId: TTiposDeRespostas["id"]) {
  const tiposDeRespostasCollectionReference = getTiposDeRespostasCollectionReference(empresaId);
  return doc(tiposDeRespostasCollectionReference, tiposDeRespostasId);
}

const BASE_FILTER: ColumnFiltersState = [
  {
    id: "deletedAt",
    value: ["==", null]
  }
];

/**
 * Get tiposDeRespostas documents from empresaId
 * @param empresaId - Empresa id to get the tiposDeRespostas documents
 * @param options - Options to get the tiposDeRespostas documents
 * @param options.sorting - Sorting options
 * @param options.filters - Filters options
 * @param options.pagination - Pagination options
 * @param options.paginationCursors - Array of pagination cursors (startAt, endAt)[]
 * @returns - TiposDeRespostas documents
 */
export function getTiposDeRespostas(
  empresaId: TEmpresa["id"],
  {
    sorting = [],
    filters = [],
    pagination = { pageIndex: 0, pageSize: DEFAULT_LIMIT },
    paginationCursors
  }: TPaginatedQueryOptions<TTiposDeRespostas> = {}
): Promise<TPaginatedQueryResponse<TTiposDeRespostas>> {
  const tiposDeRespostasCollectionReference =
    getTiposDeRespostasCollectionReference(empresaId).withConverter(tiposDeRespostasConverter);

  const allFilters = [...BASE_FILTER, ...filters];
  const qAll = generateQuery(tiposDeRespostasCollectionReference, { filters: allFilters });
  const qPaginated = generateQuery(tiposDeRespostasCollectionReference, {
    sorting,
    filters: allFilters,
    pagination,
    paginationCursors
  });

  // eslint-disable-next-line compat/compat
  return Promise.all([getDocs(qPaginated), getCountFromServer(qAll)]);
}

/**
 * Add a new tiposDeRespostas to the given empresa
 * @param empresaId - Empresa id to add the tiposDeRespostas
 * @param tiposDeRespostas - TiposDeRespostas data
 * @returns - TiposDeRespostas document reference
 */
export async function addTiposDeRespostas(
  empresaId: TEmpresa["id"],
  tiposDeRespostas: Omit<TTiposDeRespostasForm, "id" | "refPath">
) {
  const tiposDeRespostasCollectionReference = getTiposDeRespostasCollectionReference(empresaId);
  return addDoc(tiposDeRespostasCollectionReference, tiposDeRespostas);
}

// /**
//  * Get a tiposDeRespostas document snapshot
//  * @param empresaId - Empresa id to get the tiposDeRespostas
//  * @param tiposDeRespostasId - tiposDeRespostas id to get the tiposDeRespostas
//  * @returns - TiposDeRespostas document snapshot
//  */
// export function getTiposDeRespostas(empresaId: TEmpresa["id"], tiposDeRespostasId: TTiposDeRespostas["id"]) {
//   const tiposDeRespostasDocumentReference = getTiposDeRespostasDocumentReference(empresaId, tiposDeRespostasId).withConverter(tiposDeRespostasConverter);
//   return getDoc(tiposDeRespostasDocumentReference);
// }

/**
 * Update a empresa to the database
 * @param empresaId - Empresa id
 * @param tiposDeRespostasId - TiposDeRespostas id to update
 * @param tiposDeRespostas - TiposDeRespostas data
 * @returns - Promise with the tiposDeRespostas reference
 */
export function updateTiposDeRespostas(
  empresaId: TEmpresa["id"],
  tiposDeRespostasId: TTiposDeRespostas["id"],
  tiposDeRespostas: TTiposDeRespostasForm
) {
  const tiposDeRespostasDocumentReference = getTiposDeRespostasDocumentReference(
    empresaId,
    tiposDeRespostasId
  ).withConverter(tiposDeRespostasConverter);
  const currentUserId = getCurrentUser()?.uid;
  return updateDoc(tiposDeRespostasDocumentReference, {
    ...tiposDeRespostas,
    updatedAt: serverTimestamp(),
    updatedBy: currentUserId
  });
}

/**
 * Delete a tiposDeRespostas to the database
 * @param empresaId - Empresa id to delete the tiposDeRespostas
 * @param tiposDeRespostas - TiposDeRespostas to delete
 * @returns - Promise
 */
export function deleteTiposDeRespostas(empresaId: TEmpresa["id"], tiposDeRespostas: TTiposDeRespostas) {
  const tiposDeRespostasDocumentReference = getTiposDeRespostasDocumentReference(empresaId, tiposDeRespostas.id);
  return updateDoc(tiposDeRespostasDocumentReference, {
    deletedAt: serverTimestamp()
  });
}
// /**
//  * Get questionário query for a empresa
//  * @param empresaId - Empresa id to get comitês
//  * @param perguntas - Filtros da empresa
//  * @returns - questionário query
//  */
// function generateTiposPerguntaQuery(empresaId: TEmpresa["id"], perguntas: string | null | undefined) {
//   const categoriasInovacaoCollectionReference = getTiposDeRespostasCollectionReference(empresaId)
//     .withConverter(tiposDeRespostasConverter);

//   if (perguntas && perguntas?.length > 0) {
//     return query(categoriasInovacaoCollectionReference, where("deletedAt", "==", null), where("id", "==", perguntas));
//   }

//   return query(categoriasInovacaoCollectionReference, where("deletedAt", "==", null));
// }
// /**
//  * Get questionário for a empresa
//  * @param empresaId - Empresa id to get comitês
//  * @param perguntas - Filtros da empresa
//  * @returns - questionário document snapshot
//  */
// export function getTipoPerguntasArray(empresaId: TEmpresa["id"], perguntas: string | null | undefined) {
//   const q = generateTiposPerguntaQuery(empresaId, perguntas);
//   return getDocs(q);
// }

/**
 * Get questionario document reference from empresaId
 * @param empresaId - Empresa id to get the questionario document reference
 * @param tipoDePerguntaId - Questionario id to get the questionario document reference
 * @returns - Questionario document reference
 */
function getTiposDeRespostasCollectionReferenceItem(empresaId: TEmpresa["id"], tipoDePerguntaId: TQuestionario["id"]) {
  const questionariosCollectionReference = getTiposDeRespostasCollectionReference(empresaId);
  return doc(questionariosCollectionReference, tipoDePerguntaId);
}

/**
 * Get questionario item
 * @param empresaId - Empresa id to start a new jogada de inovação
 * @param tipoDePerguntaId - jogada de inovação form fields
 * @returns - questionario document item
 */
export function getTiposDeRespostasItem(empresaId: TEmpresa["id"], tipoDePerguntaId: TQuestionario["id"]) {
  const tipoDeRespostaDcumentReference = getTiposDeRespostasCollectionReferenceItem(empresaId, tipoDePerguntaId);
  return getDoc(tipoDeRespostaDcumentReference.withConverter(tiposDeRespostasConverter));
}
