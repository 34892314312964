import { subject } from "@casl/ability";
import {
  serverTimestamp,
  Timestamp,
  FirestoreDataConverter,
  collection,
  addDoc,
  where,
  query,
  getDocs,
  doc,
  updateDoc
} from "firebase/firestore";
import { getCurrentUser } from "@/services/auth";
import { getEmpresaDocumentReference } from "../empresas/firestore";

export const QUERY_KEY: string = "respostas" as const;
// prettier-ignore
type TRespostaFormFields = Omit<
  TResposta,
  "id" | "refPath" | "createdAt" | "updatedAt" | "createdBy" | "updatedBy" | "deletedAt"
> &
  Partial<Pick<TResposta, "id" | "refPath">>;

type TRespostaDatabaseFields = Pick<TResposta, "createdBy" | "updatedBy"> & {
  createdAt: ReturnType<typeof serverTimestamp>;
  updatedAt?: ReturnType<typeof serverTimestamp>;
  deletedAt: ReturnType<typeof serverTimestamp> | null;
};
export type TRespostaForm = TRespostaFormFields & TRespostaDatabaseFields;

type TRespostaDocument = Omit<TResposta, "createdAt" | "updatedAt" | "deletedAt"> & {
  createdAt: Timestamp;
  updatedAt?: Timestamp;
  deletedAt: Timestamp | null;
};

const respostaConverter: FirestoreDataConverter<TResposta> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const { createdAt, updatedAt, deletedAt, ...document } = snap.data() as TRespostaDocument;

    const data: TResposta = {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      deletedAt: deletedAt ? deletedAt.toDate() : null,
      refPath: snap.ref.path
    };

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }

    return subject("TResposta", data);
  }
};

/**
 * Get respostas collection reference from empresaId
 * @param empresaId - Empresa id to get the respostas collection reference
 * @returns - Respostas collection reference
 */
export function getRespostasCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, "respostas").withConverter(respostaConverter);
}

/**
 * Get respostas document reference from empresaId
 * @param empresaId - Empresa id to get the respostas document reference
 * @param respostasId - Respostas id to get the respostas document reference
 * @returns - Respostas document reference
 */
function getRespostasDocumentReference(empresaId: TEmpresa["id"], respostasId: TResposta["id"]) {
  const respostasCollectionReference = getRespostasCollectionReference(empresaId);
  return doc(respostasCollectionReference, respostasId);
}

/**
 * Add a new resposta to the given empresa
 * @param empresaId - Empresa id to add the resposta
 * @param respostas - Resposta data
 * @returns - Resposta document reference
 */
export async function addRespostas(empresaId: TEmpresa["id"], respostas: Omit<TRespostaForm, "id" | "refPath">) {
  const respostasCollectionReference = getRespostasCollectionReference(empresaId);
  return addDoc(respostasCollectionReference, respostas);
}

/**
 * Update a empresa to the database
 * @param empresaId - Empresa id
 * @param resposta - Respostas id to update
 * @returns - Promise with the resposta reference
 */
export function updateRespostas(empresaId: TEmpresa["id"], resposta: TResposta) {
  const respostaDocumentReference = getRespostasDocumentReference(empresaId, resposta.id).withConverter(
    respostaConverter
  );

  const currentUserId = getCurrentUser()?.uid;
  return updateDoc(respostaDocumentReference, {
    ...resposta,
    updatedAt: serverTimestamp(),
    updatedBy: currentUserId || ""
  });
}

/**
 * Get questionário query for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param idRespondente - id do respondente
 * @param idQuestionario - id do questionario
 * @returns - questionário query
 */
function generateRespostaQuery(
  empresaId: TEmpresa["id"],
  idRespondente: string | null | undefined,
  idQuestionario: string | null | undefined
) {
  const RespostaCollectionReference = getRespostasCollectionReference(empresaId).withConverter(respostaConverter);
  if (idRespondente && idQuestionario) {
    return query(
      RespostaCollectionReference,
      where("deletedAt", "==", null),
      where("idQuestionario", "==", idQuestionario),
      where("idRespondente", "==", idRespondente)
    );
  } else if (idRespondente) {
    return query(
      RespostaCollectionReference,
      where("deletedAt", "==", null),
      where("idRespondente", "==", idRespondente)
    );
  }

  return query(RespostaCollectionReference, where("deletedAt", "==", null));
}

/**
 * Get questionário for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param idRespondente - id do questionario
 * @param idQuestionario - id do questionario
 * @returns - questionário document snapshot
 */
// prettier-ignore
export function getRepostasArray(empresaId: TEmpresa["id"], idRespondente: string | null | undefined, idQuestionario: string | null | undefined) {
  const q = generateRespostaQuery(empresaId, idRespondente, idQuestionario);
  return getDocs(q);
}

/**
 * Get questionário for a empresa
 * @param empresaId - Empresa que criou o questionário
 * @param idRespondente - Colaborador que respondeu (ou não) o questionário
 * @param idQuestionario - Questionário que foi respondido
 * @returns - Query com as respostas encontradas
 */
export function getRespostaColaboradorInQuestionario(
  empresaId: TEmpresa["id"],
  idRespondente: TColaborador["id"],
  idQuestionario: TQuestionario["id"]
) {
  const respostaCollectionReference = getRespostasCollectionReference(empresaId).withConverter(respostaConverter);

  const respostaQuery = query(
    respostaCollectionReference,
    where("deletedAt", "==", null),
    where("idQuestionario", "==", idQuestionario),
    where("idRespondente", "==", idRespondente)
  );

  return getDocs(respostaQuery);
}

/**
 * Get questionário query for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param idQuestionario - id do questionario
 * @returns - questionário query
 */
// prettier-ignore
function generateRespostaUserNewQuery(
  empresaId: TEmpresa["id"],
  idQuestionario: string | null | undefined
) {
  const RespostaCollectionReference = getRespostasCollectionReference(empresaId).withConverter(respostaConverter);
    return query(
      RespostaCollectionReference,
      where("deletedAt", "==", null),
      where("idQuestionario", "==", idQuestionario),
      // where("idEmpresa", "==", ""),
    );
}

/**
 * Get questionário for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param idQuestionario - id do questionario
 * @returns - questionário document snapshot
 */
export function getRepostasArrayNewUser(empresaId: TEmpresa["id"], idQuestionario: string | null | undefined) {
  const q = generateRespostaUserNewQuery(empresaId, idQuestionario);
  return getDocs(q);
}

/**
 * Get questionário query for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param idRespondente - id do respondente
 * @returns - questionário query
 */
// prettier-ignore
function generateRespostaQueryNew(
  empresaId: TEmpresa["id"],
  idRespondente: string | null | undefined,
) {
  const RespostaCollectionReference = getRespostasCollectionReference(empresaId).withConverter(respostaConverter);
    return query(
      RespostaCollectionReference,
      where("deletedAt", "==", null),
      where("idRespondente", "==", idRespondente)
    );
}

/**
 * Get questionário for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param idRespondente - id do questionario
 * @returns - questionário document snapshot
 */
// prettier-ignore
export function getRepostasArrayNew(empresaId: TEmpresa["id"], idRespondente: string | null | undefined) {
  const q = generateRespostaQueryNew(empresaId, idRespondente);
  return getDocs(q);
}

/**
 * Get questionário for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param idQuestionario - id do questionario
 * @returns - questionário document snapshot
 */
// prettier-ignore
export function getRepostasQuestionarioArray(empresaId: TEmpresa["id"], idQuestionario: string | null | undefined) {
  const q = generateRespostaUserNewQuery(empresaId, idQuestionario);
  return getDocs(q);
}

/**
 * Get questionário query for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param idQuestionario - id do questionario
 * @returns - questionário query
 */
// prettier-ignore
function generateRespostaQuestionariosQuery(
  empresaId: TEmpresa["id"],
  idQuestionario: Array<string> | null | undefined
) {
  const RespostaCollectionReference = getRespostasCollectionReference(empresaId).withConverter(respostaConverter);
   return query(RespostaCollectionReference, where("deletedAt", "==", null), where("idQuestionario", "in", idQuestionario));
}

/**
 * Get questionário for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param idQuestionario - id do questionario
 * @returns - questionário document snapshot
 */
// prettier-ignore
export function getRepostasQuestionariosArray(empresaId: TEmpresa["id"], idQuestionario: Array<string> | null | undefined) {
  const q = generateRespostaQuestionariosQuery(empresaId, idQuestionario);
  return getDocs(q);
}

/**
 * Get questionário query for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param idQuestionario - id do questionario
 * @returns - questionário query
 */
// prettier-ignore
function generateRespostaQuestionarioEmpresaQuery(
  empresaId: TEmpresa["id"],
  idQuestionario: Array<string> | null | undefined
) {
  const RespostaCollectionReference = getRespostasCollectionReference(empresaId).withConverter(respostaConverter);
   return query(RespostaCollectionReference, where("deletedAt", "==", null), where("idQuestionario", "in", idQuestionario));
}

/**
 * Get questionário for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param idQuestionario - id do questionario
 * @returns - questionário document snapshot
 */
// prettier-ignore
export function getRepostasQuestionariosArrayEmpresa(empresaId: TEmpresa["id"], idQuestionario: Array<string> | null | undefined) {
  const q = generateRespostaQuestionarioEmpresaQuery(empresaId, idQuestionario);
  return getDocs(q);
}

/**
 * Get questionário query for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param idRespondente - id do respondente
 * @param activeEmpresaId - id do questionario
 * @returns - questionário query
 */
// prettier-ignore
function generateRespostaEmpresaQuery(
  empresaId: TEmpresa["id"],
  idRespondente: string | null | undefined,
   activeEmpresaId: string | null | undefined
) {
const RespostaCollectionReference = getRespostasCollectionReference(empresaId).withConverter(respostaConverter);
    return query(
      RespostaCollectionReference,
      where("deletedAt", "==", null),
      // where("idQuestionario", "==", idQuestionario),
      where("idRespondente", "==", idRespondente),
      where("idEmpresa", "==", activeEmpresaId),
    );
}

/**
 * Get questionário for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param idRespondente - id do questionario
 * @param activeEmpresaId - id do questionario
 * @returns - questionário document snapshot
 */
// prettier-ignore
export function getRepostasArrayEmpresa(empresaId: TEmpresa["id"], idRespondente: string | null | undefined, activeEmpresaId: string | null | undefined) {
  const q = generateRespostaEmpresaQuery(empresaId, idRespondente, activeEmpresaId);
  return getDocs(q);
}

/**
 * Get questionário query for a empresa
 * @param empresaAtivaId - Empresa id to get comitês
 * @param empresaQuestionarioId - Empresa do questionario
 * @param idQuestionario - Empresa id to get comitês
 * @returns - questionário query
 */
function generateRespostaRelatorioQuery(
  empresaAtivaId: TEmpresa["id"],
  empresaQuestionarioId: TEmpresa["id"],
  idQuestionario: TQuestionario["id"]
) {
  const RespostaCollectionReference = getRespostasCollectionReference(empresaAtivaId).withConverter(respostaConverter);
  return query(
    RespostaCollectionReference,
    where("deletedAt", "==", null),
    where("idQuestionario", "==", idQuestionario),
    where("idEmpresa", "==", empresaQuestionarioId)
  );
}

/**
 * Get questionário for a empresa
 * @param empresaAtivaId - Empresa id to get comitês
 * @param empresaQuestionarioId - Empresa do Questionario
 * @param idQuestionario - id do questionario
 * @returns - questionário document snapshot
 */
// prettier-ignore
export function getRepostasArrayRelatorio( empresaAtivaId: TEmpresa["id"], empresaQuestionarioId: TEmpresa["id"], idQuestionario: TQuestionario["id"]) {
  const q = generateRespostaRelatorioQuery(empresaAtivaId, empresaQuestionarioId, idQuestionario);
  return getDocs(q);
}
