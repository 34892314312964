import { httpsCallable } from "firebase/functions";
import { functions } from "@/base";

export const sendResetPasswordToUserEmail = httpsCallable<{ email: string }, never>(
  functions,
  "usersFunctions-sendResetPasswordToUserEmail"
);

export const acceptInviteAsColaborador = httpsCallable(functions, "usersFunctions-acceptInviteAsColaborador");

export const removeEmpresaFromMyClaims = httpsCallable(functions, "usersFunctions-removeEmpresaFromMyClaims");

export const disableUser = httpsCallable<{ empresaId: string; colaboradorId: string; userEmail: string }>(
  functions,
  "usersFunctions-disableUser"
);

export const enableUser = httpsCallable<{ empresaId: string; colaboradorId: string; userEmail: string }>(
  functions,
  "usersFunctions-enableUser"
);
