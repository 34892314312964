import { Text, Button } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconTrash } from "@tabler/icons-react";
import { UseMutationOptions, useMutation, useQueryClient } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { Dispatch, SetStateAction } from "react";
import {
  CAMPANHA_REGULAMENTO_IDEIA_COLLECTION_KEY,
  TCampanhaRegulamentoIdeiasForm,
  removeCampanhaRegulamentoIdeias
} from "@/modules/campanha-regulamento-ideias/firestore";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { useRegulamentoIdeiasFormContext } from "./regulamento-ideias-form-context";
import { TRegulamentoIdeiasForm } from "../../firestore";

type RemoveCampanhaRegulamentoIdeiasResponse = Awaited<ReturnType<typeof removeCampanhaRegulamentoIdeias>>;
type RemoveCampanhaMutationOptions = Pick<
  UseMutationOptions<RemoveCampanhaRegulamentoIdeiasResponse, FirestoreError>,
  "onSuccess" | "onError"
>;
/**
 * Hook to remove a campanha. It returns a mutation to remove the campanha.
 * @param regulamento - The regulamento form
 * @param campanhaId - The campanha id to remove
 * @param options - The mutation options to remove the campanha
 * @returns - The mutation to remove the campanha
 */
function useRemoveCampanhaMutation(
  regulamento: TRegulamentoIdeiasForm,
  campanhaId: TCampanhaRegulamentoIdeias["id"] | undefined,
  options: RemoveCampanhaMutationOptions
) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  return useMutation<RemoveCampanhaRegulamentoIdeiasResponse, FirestoreError>({
    mutationFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!regulamento.id) {
        throw new Error("Regulamento de ideia inválido");
      }
      if (!campanhaId) {
        throw new Error("Campanha inválida");
      }

      return removeCampanhaRegulamentoIdeias(activeEmpresaId, regulamento.id, campanhaId);
    },
    ...options
  });
}

const RemoveCampanhaButton = ({
  regulamento,
  campanha,
  index,
  setReload
}: {
  regulamento: TRegulamentoIdeiasForm;
  campanha: TCampanhaRegulamentoIdeiasForm;
  index: number;
  setReload: Dispatch<SetStateAction<boolean>>;
}) => {
  const queryClient = useQueryClient();
  const form = useRegulamentoIdeiasFormContext();
  const { mutate, isPending } = useRemoveCampanhaMutation(regulamento, campanha.id, {
    onSuccess() {
      form.removeListItem("campanhas", index);
      void queryClient.invalidateQueries({
        queryKey: [
          CAMPANHA_REGULAMENTO_IDEIA_COLLECTION_KEY,
          "getCampanhasRegulamentoIdeias",
          { regulamentoIdeiaId: regulamento.id }
        ]
      });
    },
    onError(error) {
      captureException(error, true);
    }
  });
  const hasCampanhaFinished = campanha.dataFinal < new Date();
  const isCampanhaInProgress = campanha.dataInicial < new Date() && !hasCampanhaFinished;

  /**
   * Handles the remove click event.
   */
  function handleRemoveClick() {
    modals.openConfirmModal({
      title: `Deseja remover a campanha ${campanha.descricao}?`,
      children: <Text size="sm">Ao confirmar a campanha será removida e não poderá ser recuperada.</Text>,
      labels: { confirm: "Remover", cancel: "Cancelar" },
      onConfirm: () => confirmFunction()
    });
  }

  /**
   * Handles the remove click event.
   */
  const confirmFunction = () => {
    if (campanha.id) {
      mutate();
      setReload((reload) => !reload);
    } else {
      form.removeListItem("campanhas", index);
    }
  };

  if (hasCampanhaFinished && campanha.id) {
    return null;
  }

  return (
    <Button
      variant="transparent"
      // pos="absolute"
      // style={{ top: 20, right: 20 }}
      c={isCampanhaInProgress ? "white" : undefined}
      bg="transparent"
      onClick={handleRemoveClick}
      disabled={isPending}
    >
      <IconTrash size="1.2rem" />
    </Button>
    // <CloseButton
    //   // pos="absolute"
    //   // style={{ top: 20, right: 20 }}
    //   c={isCampanhaInProgress ? "white" : undefined}
    //   bg="transparent"
    //   onClick={handleRemoveClick}
    //   disabled={isPending}
    // />
  );
};

export default RemoveCampanhaButton;
