import { subject } from "@casl/ability";
import { FirestoreDataConverter, addDoc, collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { getColaboradoresDocumentReference } from "../colaboradores/firestore";
import { getEmpresaDocumentReference } from "../empresas/firestore";

export type THistoricoPontosPerColaboradorForm = TFormWithTransformations<
  Omit<
    THistoricoPontosPerColaboradorBonificacao,
    "regulamentoIdeias" | "regulamentoDesafios" | "comiteInovacaoReference"
  >,
  "colaboradorReference"
>;

type THistoricoPontosPerColaboradorDocument = TFirestoreDocument<
  THistoricoPontosPerColaboradorBonificacao,
  "colaboradorReference" | "comiteInovacaoReference" | "regulamentoDesafios" | "regulamentoIdeias"
>;

export const HISTORICO_PONTOS_PER_COLABORADOR_KEY = "historicoPontosPerColaborador" as const;

const historicoPontosPerColaboradorConverter: FirestoreDataConverter<THistoricoPontosPerColaborador> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const {
      createdAt,
      updatedAt,
      colaboradorReference,
      comiteInovacaoReference,
      regulamentoDesafios,
      regulamentoIdeias,
      ...document
    } = snap.data() as THistoricoPontosPerColaboradorDocument;

    const data: THistoricoPontosPerColaborador = {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      refPath: snap.ref.path,
      colaboradorReference: colaboradorReference.path,
      comiteInovacaoReference: comiteInovacaoReference?.path || null,
      regulamentoDesafios: regulamentoDesafios?.path || null,
      regulamentoIdeias: regulamentoIdeias?.path || null
    };

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }
    return subject("THistoricoPontosPerColaborador", data);
  }
};

/**
 * Get historicoPontosPerColaborador collection reference
 * @param empresaId - Empresa id where the historicoPontosPerColaborador is located
 * @returns - historicoPontosPerColaborador collection reference
 */
function getHistoricoPontosPerColaboradorCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocument = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocument, HISTORICO_PONTOS_PER_COLABORADOR_KEY);
}

/**
 * Generate query to get historicoPontosPerColaborador  for colaborador in a specific period
 * @param empresaId - Empresa id where the historicoPontosPerColaborador is located
 * @param colaboradorId - Colaborador id to get historicoPontosPerColaborador for
 * @param startDate - Start date to get historicoPontosPerColaborador for
 * @param endDate - End date to get historicoPontosPerColaborador for
 * @returns - historicoPontosPerColaborador query
 */
function generateHistoricoPontosPerColaboradorQuery(
  empresaId: TEmpresa["id"],
  colaboradorId: TColaborador["id"],
  startDate: Date,
  endDate: Date
) {
  const historicoPontosPerColaboradorCollectionReference =
    getHistoricoPontosPerColaboradorCollectionReference(empresaId);
  const colaboradorReference = getColaboradoresDocumentReference(empresaId, colaboradorId);

  const endOfDay = 23;
  const endOfMinuteAndHour = 59;
  const endOfSecond = 999;

  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(endOfDay, endOfMinuteAndHour, endOfMinuteAndHour, endOfSecond);

  return query(
    historicoPontosPerColaboradorCollectionReference,
    where("colaboradorReference", "==", colaboradorReference),
    where("createdAt", ">=", startDate),
    where("createdAt", "<=", endDate),
    orderBy("createdAt", "desc")
  );
}

/**
 * Get historicoPontosPerColaborador for colaborador in a specific period
 * @param empresaId - Empresa id where the historicoPontosPerColaborador is located
 * @param colaboradorId - Colaborador id to get historicoPontosPerColaborador for
 * @param startDate - Start date to get historicoPontosPerColaborador for
 * @param endDate - End date to get historicoPontosPerColaborador for
 * @returns - historicoPontosPerColaborador
 */
export function getHistoricoPontosPerColaborador(
  empresaId: TEmpresa["id"],
  colaboradorId: TColaborador["id"],
  startDate: Date,
  endDate: Date
) {
  const historicoPontosPerColaboradorQuery = generateHistoricoPontosPerColaboradorQuery(
    empresaId,
    colaboradorId,
    startDate,
    endDate
  ).withConverter(historicoPontosPerColaboradorConverter);
  return getDocs(historicoPontosPerColaboradorQuery);
}

/**
 * Add a historicoPontosPerColaborador to a empresa
 * @param empresaId - Empresa id where the historicoPontosPerColaborador is located
 * @param historicoPontosPerColaborador - historicoPontosPerColaborador to add
 * @returns - The added historicoPontosPerColaborador
 */
export function addHistoricoPontosPerColaborador(
  empresaId: TEmpresa["id"],
  historicoPontosPerColaborador: THistoricoPontosPerColaboradorForm
) {
  const historicoPontosPerColaboradorCollectionReference =
    getHistoricoPontosPerColaboradorCollectionReference(empresaId);
  return addDoc(historicoPontosPerColaboradorCollectionReference, historicoPontosPerColaborador);
}
