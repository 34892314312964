import { subject } from "@casl/ability";
import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { DESAFIO_COMENTARIOS_COLLECTION_KEY, getRespostasAoComentario } from "../../firestore";

type TGetRespostasAoComentarioResponse = Awaited<ReturnType<typeof getRespostasAoComentario>>;

/**
 * Get replies from comment from desafio
 * @param desafio - Desafio to get comments
 * @param comentario - Comentario to get replies
 * @returns - Query result with comments
 */
function useGetComentariosRespostas(desafio: TDesafio, comentario: TDesafioComentario) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  return useQuery<TGetRespostasAoComentarioResponse, FirestoreError, TDesafioComentario[]>({
    queryKey: [activeEmpresaId, "desafio", desafio.id, DESAFIO_COMENTARIOS_COLLECTION_KEY, comentario.id],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return getRespostasAoComentario(activeEmpresaId, desafio.id, comentario.id);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      const comentarios: TDesafioComentario[] = [];
      for (const comentario of dataResponse.docs) {
        if (comentario.exists()) {
          comentarios.push(subject("TDesafioComentario", comentario.data()));
        }
      }
      return comentarios;
    }
  });
}

export default useGetComentariosRespostas;
