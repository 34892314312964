import { ActionIconProps, Mark, Text, UnstyledButton } from "@mantine/core";
import { modals } from "@mantine/modals";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MouseEvent, PropsWithChildren, forwardRef } from "react";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { REGULAMENTOS_IDEIAS_COLLECTION_KEY, deleteRegulamentoIdeias } from "../../firestore";

type RemoverButtonProperties = PropsWithChildren<ActionIconProps & { regulamentoIdeias: TRegulamentoIdeias }>;
type TDeleteResponse = Awaited<ReturnType<typeof deleteRegulamentoIdeias>>;

const RemoverRegulamentoIdeiasButton = forwardRef<HTMLButtonElement, RemoverButtonProperties>(
  ({ regulamentoIdeias, children, ...properties }, reference) => {
    const queryClient = useQueryClient();
    const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

    const { mutate: deleteRegulamentoIdeiasMutation, isPending } = useMutation<TDeleteResponse, Error>({
      mutationFn: () => {
        if (!activeEmpresaId) {
          throw new Error("Empresa não selecionada");
        }
        return deleteRegulamentoIdeias(activeEmpresaId, regulamentoIdeias);
      },
      onSuccess() {
        return queryClient.invalidateQueries({ queryKey: [...REGULAMENTOS_IDEIAS_COLLECTION_KEY] });
      },
      onError(error) {
        captureException(error, true);
      }
    });

    /**
     * Handles the click event on the button.
     * @param event - The event that triggered the click.
     */
    function handleClick(event: MouseEvent<HTMLButtonElement>) {
      event.preventDefault();
      modals.openConfirmModal({
        title: "Remover regulamento",
        centered: true,
        children: (
          <Text size="sm">
            Tem certeza que deseja remover o regulamento <Mark>{regulamentoIdeias.nome}</Mark>?
          </Text>
        ),
        labels: {
          confirm: "Sim",
          cancel: "Não"
        },
        confirmProps: { color: "red" },
        onConfirm: () => {
          void deleteRegulamentoIdeiasMutation();
        }
      });
    }

    return (
      <UnstyledButton disabled={isPending} ref={reference} {...properties} onClick={handleClick}>
        {children}
      </UnstyledButton>
    );
  }
);

RemoverRegulamentoIdeiasButton.displayName = "RemoverRegulamentoIdeiasButton";

export default RemoverRegulamentoIdeiasButton;
