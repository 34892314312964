import { Text, Group, Badge, ThemeIcon, BadgeProps } from "@mantine/core";
import { IconCoin } from "@tabler/icons-react";
import useGetDesafioActivityByColaborador from "../../hooks/useGetDesafioActivityByColaborador";

const TotalMoedasPerColaborador = ({
  desafio,
  colaboradorId,
  ...badgeProperties
}: { desafio: TDesafio; colaboradorId: TColaborador["id"] } & BadgeProps) => {
  const { data: desafioActivity } = useGetDesafioActivityByColaborador(desafio, colaboradorId);

  const qtdMoedasGastasNestaDesafio = desafioActivity?.moedas || 0;

  return (
    <Group align="center" gap="xs">
      <Badge
        color="gray.2"
        py="sm"
        radius="md"
        leftSection={
          <ThemeIcon variant="light" color="yellow" size="sm">
            <IconCoin />
          </ThemeIcon>
        }
        {...badgeProperties}
      >
        <Text c="dark" fz="xs" fw={500} tt="lowercase">
          {qtdMoedasGastasNestaDesafio} moedas
        </Text>
      </Badge>
    </Group>
  );
};

export default TotalMoedasPerColaborador;
