import { yupResolver, TransformedValues } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { Asserts } from "yup";
import { CAMPANHA_REGULAMENTO_IDEIA_COLLECTION_KEY } from "@/modules/campanha-regulamento-ideias/firestore";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { useRegulamentoIdeiasForm as useRegulamentoIdeiasFormFromContext } from "./regulamento-ideias-form-context";
import {
  REGULAMENTOS_IDEIAS_COLLECTION_KEY,
  TRegulamentoIdeiasForm,
  addRegulamentoIdeias,
  updateRegulamentoIdeias
} from "../../firestore";
import regulamentoIdeiasSchema from "../../schema";

type FormInputs = Asserts<typeof regulamentoIdeiasSchema>;
type TFirestoreResponse = Awaited<ReturnType<typeof addRegulamentoIdeias>>;

/**
 * RegulamentoIdeias Form hook
 * @param regulamentoIdeias - The regulamentoIdeias data
 * @returns - The regulamentoIdeias form
 */
function useRegulamentoIdeiasForm(
  regulamentoIdeias: TRegulamentoIdeiasForm & Partial<Pick<TRegulamentoIdeias, "id" | "refPath">>
) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const form = useRegulamentoIdeiasFormFromContext({
    validate: yupResolver(regulamentoIdeiasSchema),
    transformValues: (values) => regulamentoIdeiasSchema.cast(values),
    initialValues: regulamentoIdeias
  });

  const { mutate, isPending, reset } = useMutation<TFirestoreResponse, FirestoreError, FormInputs>({
    mutationFn: (values) => {
      if (!activeEmpresaId) {
        throw new Error("Nenhuma empresa selecionada");
      }
      return regulamentoIdeias.id
        ? updateRegulamentoIdeias(activeEmpresaId, regulamentoIdeias.id, values)
        : addRegulamentoIdeias(activeEmpresaId, values);
    },
    async onSuccess(_data, variables) {
      showNotification({
        message: `${variables.nome} ${regulamentoIdeias.id ? "atualizado" : "cadastrado"} com sucesso!`,
        color: "green",
        autoClose: 2500
      });
      reset();
      form.reset();
      await queryClient.invalidateQueries({ queryKey: [...REGULAMENTOS_IDEIAS_COLLECTION_KEY] });
      await queryClient.invalidateQueries({
        queryKey: [
          CAMPANHA_REGULAMENTO_IDEIA_COLLECTION_KEY,
          "getCampanhasRegulamentoIdeias",
          { regulamentoIdeiaId: regulamentoIdeias.id }
        ]
      });

      navigate("/ideias/regulamentos");
    },
    onError(error) {
      captureException(error, true);
    }
  });

  type TFormTransformed = TransformedValues<typeof form>;

  /**
   * Handle form submit
   * @param values - Form values
   */
  function handleFormSubmit(values: TFormTransformed) {
    mutate(values);
  }

  return { form, isPending, onSubmit: form.onSubmit(handleFormSubmit) };
}

export default useRegulamentoIdeiasForm;
