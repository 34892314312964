import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getTotalIdeias } from "@/modules/ideias/firestore";
import useUserStore from "@/modules/users/store";

type TGetTotalIdeias = Awaited<ReturnType<typeof getTotalIdeias>>;

/**
 * Count total of ideias cadastradas
 * @returns count
 */
function useGetCountTotalOfIdeiasCadastradas() {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  return useQuery<TGetTotalIdeias, FirestoreError, number>({
    queryKey: [activeEmpresaId, "countTotalOfIdeiasCadastradas"],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return getTotalIdeias(activeEmpresaId);
    },
    select(aggregateSnapshot) {
      return aggregateSnapshot.data().count;
    }
  });
}

export default useGetCountTotalOfIdeiasCadastradas;
