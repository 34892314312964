import { deleteDoc, DocumentData, DocumentReference, getDoc, serverTimestamp } from "firebase/firestore";
import { useCallback } from "react";
import { createColaborador } from "@/modules/colaboradores/firestore";
import { addConvite } from "@/modules/convites/firestore";

type Properties = {
  licenciadaId?: TLicenciada["id"];
  empresaId: TEmpresa["id"];
  questionario: TQuestionario;
  empresaRespondenteId: TEmpresa["id"];
};

type ColaboradorReference = DocumentReference<TColaborador, DocumentData>;
type ConviteReference = DocumentReference<TConvite, DocumentData>;

const getColaborador = async (colaboradorReference: ColaboradorReference) => {
  const colaboradorDocument = await getDoc(colaboradorReference);
  const colaborador = colaboradorDocument.data();

  if (!colaborador) {
    throw new Error("Houve um erro ao criar o colaborador");
  }

  return colaborador;
};

const getConvite = async (conviteReference: ConviteReference) => {
  const conviteDocument = await getDoc(conviteReference);
  const convite = conviteDocument.data();

  if (!convite) {
    throw new Error("Houve um erro ao criar o convite");
  }

  return convite;
};

const useCreateConvite = ({ empresaId, questionario, licenciadaId, empresaRespondenteId }: Properties) => {
  const handleCreateColaborador = useCallback(
    (name: string, email: string) => {
      return createColaborador(empresaRespondenteId, {
        email: email,
        nome: name,
        role: "COLLABORATOR",
        setores: [],
        image: [],
        createdBy: "",
        createdAt: serverTimestamp(),
        deletedAt: null
      });
    },
    [empresaRespondenteId]
  );

  const handleCreateConvite = useCallback(
    (colaborador: TColaborador) =>
      addConvite({
        convite: true,
        idColaboradorConvidado: colaborador.id,
        idEmpresa: empresaId,
        idEmpresaColaboradorConvidado: empresaRespondenteId,
        idLicenciada: licenciadaId,
        idProjeto: questionario.idProjeto,
        idQuestionario: questionario.id,
        email: colaborador.email,
        externo: true
      }),
    [empresaId, questionario, licenciadaId, empresaRespondenteId]
  );

  const handleConvite = useCallback(
    async (name: string, email: string) => {
      const colaboradorReference = await handleCreateColaborador(name, email);
      const colaborador = await getColaborador(colaboradorReference);

      try {
        const conviteReference = await handleCreateConvite(colaborador);
        return await getConvite(conviteReference);
      } catch {
        void deleteDoc(colaboradorReference);
        return null;
      }
    },
    [handleCreateColaborador, handleCreateConvite]
  );

  return { createConvite: handleConvite };
};

export default useCreateConvite;
