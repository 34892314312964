import { Table as MantineTable } from "@mantine/core";
import { Table as ReactTable, RowData, flexRender } from "@tanstack/react-table";
import { useSearchParams } from "react-router-dom";
import { TIdeiaForm } from "@/modules/ideias/firestore";
import LoadingRows from "./LoadingRows";
import TableHeader from "./TableHeader";

// prettier-ignore
const TableIdeia = <T extends RowData>({ table, isLoading }: { table: ReactTable<T>; isLoading: boolean }) => {
  const [query] = useSearchParams();
  const queryStringFilter = query.get("commite-filter");
  const formatIdIdeia = queryStringFilter?.split(",");

  const verification = table.getRowModel().rows.filter(row => {
    const original = row?.original as TIdeiaForm
    return formatIdIdeia && formatIdIdeia.length > 0 && queryStringFilter !== null ? formatIdIdeia?.some(item => item === original?.id) : row
  })

  return <MantineTable>
    <MantineTable.Thead>
      {table.getHeaderGroups().map((headerGroup) => (
        <MantineTable.Tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <TableHeader key={header.id} header={header} />
          ))}
        </MantineTable.Tr>
      ))}
    </MantineTable.Thead>
    <MantineTable.Tbody>
      {verification.map((row) => (
        <MantineTable.Tr key={row.id}>
          {row.getVisibleCells().map((cell) => {
            return <MantineTable.Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</MantineTable.Td>
          })}
        </MantineTable.Tr>
      ))}
      {isLoading && <LoadingRows table={table} />}
    </MantineTable.Tbody>
  </MantineTable>
};

TableIdeia.displayName = "Table";

export default TableIdeia;
