import { showNotification } from "@mantine/notifications";
import { IconAlertCircle } from "@tabler/icons-react";
import { FirestoreError } from "firebase/firestore";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useUserStore from "@/modules/users/store";
import { signOut, watchCurrentUser } from "@/services/auth";
import { captureException } from "@/services/log";
// prettier-ignore
/**
 * Handles the sign out process
 * @returns Uma promise vazia que indica o logout completo.
 */
function signOutHandler(): Promise<void> {
  return signOut()
    .catch((error: FirestoreError) => {
      captureException(error);
      showNotification({
        icon: <IconAlertCircle size={16} />,
        message: "SOMETHING WENT WRONG",
        color: "red",
        autoClose: 3500
      });
      throw error; // Re-throw para propagar o erro, se necessário
    })
    .finally(() => {
      localStorage.setItem("logout", Date.now().toString());
    });
}

// prettier-ignore
/**
 * Hook that handles the sign out process and redirects to the home page
 * @description
 * This hook is used to handle the sign out process and redirect to the home page
 * by watching the current user and redirecting to the home page when the user is not signed in
 * @returns - The sign out handler
 */
function useSignOutHandler() {
  const navigate = useNavigate();
  const resetState = useUserStore((state) => state.reset);

  useEffect(() => {
    const handleLogoutEvent = () => {
      navigate("/", { replace: true });
      resetState();
      location.reload();
    };

    const logoutListener = (event: StorageEvent) => {
      if (event.key === "logout") {
        handleLogoutEvent();
      }
    };

    window.addEventListener("storage", logoutListener);

    const unsubscribe = watchCurrentUser(
      (user) => {
        if (!user) {
          handleLogoutEvent();
        }
      },
      (error) => captureException(error)
    );

    return () => {
      unsubscribe();
      window.removeEventListener("storage", logoutListener);
    };
  }, [navigate, resetState]);

  return signOutHandler;
}

export default useSignOutHandler;
