import { Center, Stack, Text } from "@mantine/core";
import NoData from "@/components/no-data";
import TipoDeInovacaoHistoryLoading from "./TipoDeInovacaoHistoryLoading";
import useQueryTipoDeInovacao from "./useQueryTipoDeInovacao";
import TipoDeInovacaoItem from "../tipo-de-inovacao-card";

// prettier-ignore
const TiposDeInovacaoList = ({ formatAutorizacoes }: { formatAutorizacoes: TAutorizacao[] | undefined }) => {
  const { data, isLoading, refetch } = useQueryTipoDeInovacao();

  if (data?.length === 0) {
    return (
      <Center m="lg">
        <NoData m="md">
          <Stack gap="xs">
            <Text c="dimmed">Nenhum tipo de inovação encontrado!</Text>
          </Stack>
        </NoData>
      </Center>
    );
  }

  return (
    <Stack gap="xs" mt="md">
      {isLoading && (
        <>
          <TipoDeInovacaoHistoryLoading />
          <TipoDeInovacaoHistoryLoading />
          <TipoDeInovacaoHistoryLoading />
        </>
      )}
      {data &&
        data?.sort(comparar).map((categoria) => <TipoDeInovacaoItem key={categoria.id} categoria={categoria} refetch={refetch} formatAutorizacoes={formatAutorizacoes} />)}
    </Stack>
  );
};

export default TiposDeInovacaoList;

/**
 * Compara dois objetos de tipo Sort.
 * @param a - O primeiro objeto de tipo Sort a ser comparado.
 * @param b - O segundo objeto de tipo Sort a ser comparado.
 * @returns Um número negativo se a vem antes de b, um número positivo se a vem depois de b, ou 0 se são iguais.
 */
function comparar(a: TTipoDeInovacao, b: TTipoDeInovacao): number {
  const nomeCategoriaA = a.nomeCategoria || "";
  const nomeCategoriaB = b.nomeCategoria || "";
  if (nomeCategoriaA < nomeCategoriaB) return -1;
  if (nomeCategoriaA > nomeCategoriaB) return 1;
  if (a.nome < b.nome) return -1;
  if (a.nome > b.nome) return 1;
  return 0;
}
