import {
  Alert,
  Box,
  Button,
  Checkbox,
  Group,
  LoadingOverlay,
  Select,
  Space,
  Stack,
  TagsInput,
  Text
} from "@mantine/core";
import { TransformedValues, useForm, yupResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { IconAlertCircle } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { AnyObject, Asserts, ObjectSchema } from "yup";
import useUserStore from "@/modules/users/store";
import schema from "@/schema";
import { captureException } from "@/services/log";
import { extractTextInputPropertiesFromFormSchema } from "@/utils/form";
import { inviteColaboradorAdd } from "../../functions";
import { QUERY_KEY } from "../colaboradores-table/ColaboradoresTable";

const ROLES_DATA = [
  { value: "ADMIN", label: "Administrador" },
  { value: "MEMBER", label: "Membro" },
  { value: "TEAM", label: "Equipe" },
  { value: "MASTER", label: "Master" },
  { value: "COLLABORATOR", label: "Colaborador" }
];

const EMAILS_SPLIT_CHARTS = [",", ";", "|"];

type TBulkColaboradoresSchema = {
  emails: string[];
  gestao: boolean;
  diagnostico: boolean;
  incentivo: boolean;
  configuracoes: boolean;
  role: "ADMIN" | "MEMBER" | "TEAM" | "MASTER" | "COLLABORATOR";
};

const bulkColaboradoresSchema: ObjectSchema<TBulkColaboradoresSchema, AnyObject, TBulkColaboradoresSchema> = schema
  .object()
  .shape({
    emails: schema
      .array()
      .of(schema.string().email().required().defined().trim())
      .required()
      .defined()
      .default([])
      .label("E-mails"),
    gestao: schema.boolean().optional().default(true).defined().label("Gestão de Inovação"),
    diagnostico: schema.boolean().optional().default(true).defined().label("Diagnóstico"),
    incentivo: schema.boolean().optional().default(true).defined().label("Incentivo a Inovação"),
    configuracoes: schema.boolean().optional().default(true).defined().label("Configurações"),
    role: schema
      .mixed<"ADMIN" | "MEMBER" | "TEAM" | "MASTER" | "COLLABORATOR">()
      .oneOf(["ADMIN", "MEMBER", "TEAM", "MASTER", "COLLABORATOR"])
      .default("MEMBER")
      .required()
      .defined()
      .default("MEMBER")
      .label("Perfil")
  });

type FormInputs = Asserts<typeof bulkColaboradoresSchema>;
type TAddResponse = Awaited<ReturnType<typeof inviteColaboradorAdd>>[];

// prettier-ignore
const BulkAddColaboradorForm = ({ onSuccess }: { onSuccess: () => void }) => {
  const colaboradorFieldsDescriptor = bulkColaboradoresSchema.describe();

  const queryClient = useQueryClient();
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const activeLicenciadaId = useUserStore((state) => state.activeLicenciadaId);
  const { colaborador } = useUserStore()

  const form = useForm({
    initialValues: bulkColaboradoresSchema.getDefault(),
    validate: yupResolver(bulkColaboradoresSchema),
    transformValues: (values) => bulkColaboradoresSchema.cast(values)
  });

  const { mutate, isPending, reset } = useMutation<TAddResponse, FirestoreError, FormInputs>({
    mutationFn: (values) => {
      if (!activeEmpresaId) {
        throw new Error("Nenhuma empresa selecionada");
      }

      const role = values.role

      // eslint-disable-next-line compat/compat
      return Promise.all(
        values.emails.map((email) =>
          inviteColaboradorAdd({
            nome: email?.split("@")[0] || "", email, role, telefone: "", image: [],
            setores: [], dataNascimento: undefined, licenciadaId: activeLicenciadaId as string,
            empresaId: activeEmpresaId, gestao: values.gestao, incentivo: values.incentivo,
            diagnostico: values.diagnostico, configuracoes: values.configuracoes
          })
        )
      );
    },
    async onSuccess() {
      reset();
      form.reset();
      showNotification({
        message: `Colaboradores adicionados com sucesso!`,
        color: "green",
        autoClose: 2500
      });
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      if (onSuccess) {
        return onSuccess();
      }
      return null;
    },
    onError(error) {
      captureException(error, true);
    }
  });

  type TFormTransformed = TransformedValues<typeof form>;
  /**
   * Handle form submit
   * @param values - Form values
   */
  function onSubmit(values: TFormTransformed) {
    mutate(values);
  }

  const bulkColaboradoresSchemaDescriptor = bulkColaboradoresSchema.describe();

  const genericError = form.errors[""];
  const allErrors = Object.values(form.errors).filter((error) => error !== genericError);

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />
        <Stack gap="xs">
          <Text fw={700}>Autorizações de modulos</Text>
          <Space h={"md"} />
          <Group>
            <Checkbox
              {...form.getInputProps("gestao")}
              {...extractTextInputPropertiesFromFormSchema(bulkColaboradoresSchemaDescriptor, "gestao")}
              checked={form.getInputProps("gestao").value as boolean}
            />
            <Checkbox
              {...form.getInputProps("diagnostico")}
              {...extractTextInputPropertiesFromFormSchema(bulkColaboradoresSchemaDescriptor, "diagnostico")}
              checked={form.getInputProps("diagnostico").value as boolean}
            />
            <Checkbox
              {...form.getInputProps("incentivo")}
              {...extractTextInputPropertiesFromFormSchema(bulkColaboradoresSchemaDescriptor, "incentivo")}
              checked={form.getInputProps("incentivo").value as boolean}
            />
            <Checkbox
              {...form.getInputProps("configuracoes")}
              {...extractTextInputPropertiesFromFormSchema(bulkColaboradoresSchemaDescriptor, "configuracoes")}
              checked={form.getInputProps("configuracoes").value as boolean}
            />
            {
              colaborador && colaborador.role === "ADMIN"
                ? <Select
                  data={ROLES_DATA}
                  w="160px"
                  allowDeselect={false}
                  {...form.getInputProps("role")}
                  {...extractTextInputPropertiesFromFormSchema(colaboradorFieldsDescriptor, "role")}
                /> : <></>
            }
          </Group>
          <Space h={"md"} />
          <Text size={"sm"} fw={"600"} >Informe os e-mails dos destinatários, podendo informar mais de um e-mail, digite o email e aperte a tecla enter:</Text>
          <TagsInput
            type="text"
            data-autofocus
            splitChars={EMAILS_SPLIT_CHARTS}
            {...form.getInputProps("emails")}
            error={allErrors.length > 0 ? allErrors.join(", ") : undefined}
            {...extractTextInputPropertiesFromFormSchema(bulkColaboradoresSchemaDescriptor, "emails")}
          />
        </Stack>
      </Box>
      <Group justify={genericError ? "space-between" : "flex-end"} mt="md">
        {genericError && (
          <Alert icon={<IconAlertCircle size="1rem" />} color="red">
            {genericError}
          </Alert>
        )}
        <Button type="submit" loading={isPending}>
          Adicionar
        </Button>
      </Group>
    </form>
  );
};

export default BulkAddColaboradorForm;
