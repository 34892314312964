import { TransformedValues, yupResolver } from "@mantine/form";
import { modals } from "@mantine/modals";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { FirestoreError, serverTimestamp } from "firebase/firestore";
import { FormEvent } from "react";
import { getIdeiaDocumentReferenceFromPath } from "@/modules/ideias/firestore";
import useUserStore from "@/modules/users/store";
import { useAtuacaoForm } from "./atuacao-form-context";
import marcoAtuacaoSchema, { FormInputs } from "./marco-atuacao-schema";
import { updateAtuacao } from "../../firestore";

type TFinalizarAtuacaoResponse = Awaited<ReturnType<typeof updateAtuacao>>;
type TMutationOptions = Omit<UseMutationOptions<TFinalizarAtuacaoResponse, FirestoreError, FormInputs>, "mutationFn">;

/**
 * Use marco atuação form
 * @param marco - Marco
 * @param options - Mutation options to pass to react-query
 * @todo Invalidate marcos query to update the list
 * @returns Form
 */
function useMarcoAtuacaoForm(marco: TMarco, options: TMutationOptions = {}) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  const ideiaDocumentReference = getIdeiaDocumentReferenceFromPath(marco.ideiaReference);

  const form = useAtuacaoForm({
    initialValues: marcoAtuacaoSchema.cast(marco, { stripUnknown: true, assert: false }),
    validate: yupResolver(marcoAtuacaoSchema),
    transformValues: (values) => marcoAtuacaoSchema.cast(values, { stripUnknown: true })
  });

  const { mutate: updateAtuacaoMutation, isPending } = useMutation<
    TFinalizarAtuacaoResponse,
    FirestoreError,
    FormInputs
  >({
    mutationFn(values) {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return updateAtuacao(activeEmpresaId, ideiaDocumentReference.id, marco.id, values);
    },
    ...options
  });

  type TFormValues = TransformedValues<typeof form>;
  /**
   * Handle submit form
   * @param values - Form values
   * @param event - Form event
   */
  function handleSubmit(values: TFormValues, event: FormEvent<HTMLFormElement>) {
    const submitEvent = event.nativeEvent as SubmitEvent;
    const submitButton = submitEvent.submitter as HTMLButtonElement | null;
    const finalizarMarco = submitButton?.value === "FINALIZAR";

    if (finalizarMarco) {
      modals.openConfirmModal({
        title: `Deseja realmente finalizar a atuação?`,
        children: `Ao finalizar a atuação na ideia, não poderá editar ou remover.`,
        labels: { confirm: "Finalizar", cancel: "Cancelar" },
        onConfirm: () => updateAtuacaoMutation({ ...values, finishedAt: serverTimestamp() })
      });
    } else {
      updateAtuacaoMutation({ ...values, finishedAt: null, startedAt: serverTimestamp() });
    }
  }

  return { form, onSubmit: form.onSubmit(handleSubmit), isPending };
}

export default useMarcoAtuacaoForm;
