import { Table as MantineTable, Skeleton } from "@mantine/core";
import { RowData, Table } from "@tanstack/react-table";

const MAX_SIZE = 10;
const MIN_SIZE = 3;

const LoadingRows = <T extends RowData>({ table }: { table: Table<T> }) => {
  const total = Math.floor(Math.random() * MAX_SIZE) + MIN_SIZE;
  return (
    <>
      {Array.from({ length: total }).map(() =>
        table.getHeaderGroups().map((headerGroup) => (
          <MantineTable.Tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <MantineTable.Td key={header.id}>
                <Skeleton height={10} radius="md" />
              </MantineTable.Td>
            ))}
          </MantineTable.Tr>
        ))
      )}
    </>
  );
};

export default LoadingRows;
