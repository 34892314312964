import { Button, Group } from "@mantine/core";
import { ColumnFiltersState } from "@tanstack/react-table";
import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { useCallback, useState } from "react";
import { Cards } from "@/src/routes/desafios-relatorio-visao-geral/DesafiosRelatorioVisaoGeralPage";
import { textMapDesafios } from "@/src/routes/gestao-inovacao-page/ExportIdeias";
import { getAllDesafios } from "../firestore";

const DEZ = 10;
const CEM_PORCENTO = 100;
const DOIS = 2;

interface ExportExcelProperties {
  cards: Cards;
  empresa: TEmpresa | undefined;
  filtros: ColumnFiltersState | undefined;
}

/* eslint-disable @typescript-eslint/no-floating-promises */
// prettier-ignore
const ExportExcel = ({ cards, empresa, filtros }: ExportExcelProperties) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleExport = useCallback(async () => {
    setIsLoading(true);
    try {
      setIsLoading(true);

      // Aguarda o resultado
      const result = await getAllDesafios(empresa?.id, { filters: filtros });
      const workbook = new ExcelJS.Workbook();

      // Aguarda o término de exportaDesafios
      exportaDesafios(workbook, result.documentos?.map((documento) => [
        documento.titulo,
        documento.propositorNome,
        documento.descricao,
        documento.objetivo,
        documento.propositorEmail,
        documento.createdAt,
        textMapDesafios[documento.status as TDesafioStatus],
        documento.moedas && documento.moedas > 0 ? documento.moedas : "",
        documento.primeiraImersao && documento.primeiraImersao.valueOf() > 0 ? new Date(documento.primeiraImersao) : "",
        documento.ultimaImersao && documento.ultimaImersao.valueOf() > 0 ? new Date(documento.ultimaImersao) : "",
      ]));

      // Aguarda o término de exportaCards
      exportaCards(workbook, cards);
      await saveExcel(workbook);

      // Pronto, só agora a planilha foi gerada e salva
    } catch (error) {
      console.error("Erro ao gerar planilha:", error);
    } finally {
      setIsLoading(false);
    }
  }, [empresa, filtros, cards]);

  const handleClick = useCallback(() => {
    void handleExport();
  }, [handleExport]);

  return (
    <Group justify={"flex-end"}>
      <Button
        variant="filled"
        onClick={handleClick}
        loading={isLoading}
      >
        Exportar
      </Button>
    </Group>
  );

};
export default ExportExcel;

const formatarValor = (numero: number) => {
  return `${Math.round((numero + Number.EPSILON) * DEZ) / DEZ}%`;
};

const exportaCards = (workbook: ExcelJS.Workbook, cards: Cards): void => {
  if (!cards) return;
  const columns = ["Desafios", "Comentários", "Usuários"];
  const worksheet = workbook.addWorksheet("Resumo");
  const headerRow = worksheet.addRow(columns);
  headerRow.eachCell((cell) => {
    cell.font = { bold: true };
  });
  const unicos = new Set([...cards.desafios.participantes, ...cards.comentarios.participantes]).size;

  worksheet.addRow([cards.desafios.total, cards.comentarios.total, cards.colaboradoresAtivos]);
  worksheet.addRow([
    cards.desafios.participantes.size,
    cards.comentarios.participantes.size,
    cards.colaboradoresAtivos
  ]);

  worksheet.addRow([
    formatarValor(cards.desafios.porcentagem),
    formatarValor(cards.comentarios.porcentagem),
    formatarValor((unicos * CEM_PORCENTO) / cards.colaboradoresAtivos)
  ]);
  calculateAndSetColumnWidths(worksheet, columns);
};

const saveExcel = async (workbook: ExcelJS.Workbook) => {
  try {
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer], { type: "application/octet-stream" }), `relatorio.xlsx`);
  } catch (error) {
    console.error("Erro ao escrever buffer:", error);
  }
};

// prettier-ignore
const exportaDesafios = (workbook: ExcelJS.Workbook, csvData: (string | number | Date | undefined)[][] | undefined) => {
  if (!csvData) return;
  const columns = ["Título", "Autor(es)", "Descrição", "Objetivo", "Email Autor(es)", "Data de Criação", "Status", "Moedas", "Primeira Imersão", "Última Imersão"];
  const worksheet = workbook.addWorksheet('Desafios');
  const headerRow = worksheet.addRow(columns);
  headerRow.eachCell((cell) => {
    cell.font = { bold: true };
  });
  if (Array.isArray(csvData)) {
    for (const rowData of csvData) {
      worksheet.addRow(rowData);
    }
  }
  calculateAndSetColumnWidths(worksheet, columns);
}

const calculateAndSetColumnWidths = (worksheet: ExcelJS.Worksheet, columns: string[]) => {
  const columnWidths = columns.map((col, index) => {
    let maxLength = col.length;
    worksheet.getColumn(index + 1).eachCell({ includeEmpty: true }, (cell) => {
      let cellValue = "";
      try {
        cellValue = cell.value !== null && cell.value !== undefined ? JSON.stringify(cell.value) : "";
      } catch (error) {
        console.error("Erro ao stringificar o valor da célula:", error);
      }
      maxLength = Math.max(maxLength, cellValue.length);
    });

    return maxLength < DEZ ? DEZ : maxLength + DOIS;
  });
  const validatedColumnWidths = columnWidths.map((width) => {
    if (typeof width !== "number" || width < 0) {
      throw new Error("Invalid column width");
    }
    return width;
  });
  for (const [index, column] of worksheet.columns.entries()) {
    column.width = validatedColumnWidths[index]; // já foi validado anteriormente, ignorar o warning do estlint
  }
};
