import { subject } from "@casl/ability";
import {
  FirestoreDataConverter,
  addDoc,
  collection,
  deleteDoc,
  getCountFromServer,
  getDoc,
  getDocs,
  orderBy,
  query,
  where
} from "firebase/firestore";
import { getColaborador } from "../colaboradores/firestore";
import { getIdeiaDocumentReference } from "../ideias/firestore";
import ideiaSchema from "../ideias/schema/ideia-schema";

const LIKES_COLLECTION_KEY = "likes";

type TIdeiaLikeFormFields = Omit<TIdeiaLike, keyof TAudit>;

type TIdeiaLikeForm = TIdeiaLikeFormFields & TAuditForm;

type TIdeiaLikeDocument = TIdeiaLikeFormFields & TAuditDocument;

const ideiaLikeConverter: FirestoreDataConverter<TIdeiaLike> = {
  toFirestore(data) {
    return data;
  },
  fromFirestore(snap) {
    const { createdAt, ...document } = snap.data() as TIdeiaLikeDocument;
    const ideiaBase = ideiaSchema.omit(["createdAt", "updatedAt"]).getDefault() as Omit<
      TIdeiaLikeForm,
      "createdAt" | "updatedAt"
    >;
    const data: TIdeiaLike = {
      ...ideiaBase,
      ...document,
      createdAt: createdAt.toDate()
    };
    return subject("TIdeiaLike", data);
  }
};

/**
 * Get ideias collection reference from empresaId
 * @param empresaId - Empresa id to get the ideias collection reference
 * @param ideiaId - Ideia id to get the ideias collection reference
 * @returns - Ideias collection reference
 */
function getIdeiaLikesCollection(empresaId: TEmpresa["id"], ideiaId: TIdeia["id"]) {
  const ideiaDocumentReference = getIdeiaDocumentReference(empresaId, ideiaId);
  return collection(ideiaDocumentReference, LIKES_COLLECTION_KEY).withConverter(ideiaLikeConverter);
}

/**
 * Get ideia like document reference
 * @param empresaId - Empresa id to get the empresa document reference
 * @param ideiaId - Ideia id to get the ideia document reference reference
 * @param userId - The user id
 * @returns - ideia like document reference
 */
function getIdeiaLikeByUserDocumentReference(
  empresaId: TEmpresa["id"],
  ideiaId: TIdeia["id"],
  userId: TColaborador["id"]
) {
  const ideiaCollectionReference = getIdeiaLikesCollection(empresaId, ideiaId);
  const qAll = query(ideiaCollectionReference, where("createdBy", "==", userId));
  return getDocs(qAll).then((querySnapshot) => {
    const ideiaLikeDocumentReference = querySnapshot.docs[0]?.ref;
    if (!ideiaLikeDocumentReference) {
      throw new Error("Like não encontrado");
    }
    return ideiaLikeDocumentReference.withConverter(ideiaLikeConverter);
  });
}

/**
 * Get count total of likes from ideia
 * @param empresaId - Empresa id to get the ideias collection reference
 * @param ideiaId - Ideia id to get the ideias collection reference
 * @returns - Ideias collection reference
 */
export function getCountIdeiaLikes(empresaId: TEmpresa["id"], ideiaId: TIdeia["id"]) {
  const ideiaLikesCollection = getIdeiaLikesCollection(empresaId, ideiaId);
  return getCountFromServer(ideiaLikesCollection);
}

/**
 * Add Like
 * @param empresaId - The empresa ID
 * @param ideiaId - the ideia ID to add like
 * @param user - The user active
 * @returns - Like document
 */
export function addLike(empresaId: TEmpresa["id"], ideiaId: TIdeia["id"], user: TColaborador) {
  const ideiaLikesCollection = getIdeiaLikesCollection(empresaId, ideiaId);
  const newLike: TIdeiaLike = {
    createdAt: new Date(),
    createdBy: user.id
  };
  return addDoc(ideiaLikesCollection, newLike);
}

/**
 * Update Ideia by removing likes to it
 * @param empresaId - The empresa ID
 * @param ideiaId - The ideia id
 * @param userId - The user id
 * @returns - The update result
 */
export async function removeLike(empresaId: TEmpresa["id"], ideiaId: TIdeia["id"], userId: TColaborador["id"]) {
  const ideiaLikeDocumentReference = await getIdeiaLikeByUserDocumentReference(empresaId, ideiaId, userId);
  const snapshot = await getDoc(ideiaLikeDocumentReference);
  await deleteDoc(ideiaLikeDocumentReference);
  return snapshot.data();
}

/**
 * Get Likes query for a empresa
 * @param empresaId - Empresa id to get likes
 * @param ideiaId - Ideia id to get likes
 * @returns - Likes query
 */
function generateLikesQuery(empresaId: TEmpresa["id"], ideiaId: TIdeia["id"]) {
  const ideiaLikesCollection = getIdeiaLikesCollection(empresaId, ideiaId);
  return query(ideiaLikesCollection, orderBy("createdAt", "desc"));
}

/**
 * Get like documents
 * @param empresaId - The empresa ID
 * @param ideiaId - the ideia ID to add like
 * @returns - The like documents
 */
export async function getLikes(empresaId: TEmpresa["id"], ideiaId: TIdeia["id"]) {
  const qAll = generateLikesQuery(empresaId, ideiaId);
  //const querySnapshot: QuerySnapshot<TIdeiaLike, DocumentData> = await getDocs(qAll);
  //return querySnapshot.docs.map((document_) => document_.data());
  return getDocs(qAll).then(async (querySnapshot) => {
    const promises = querySnapshot.docs.map(async (document_) => {
      const { createdBy } = document_.data();
      const colaborador = await getColaborador(empresaId, createdBy);
      return {
        ...document_.data(),
        colaborarId: createdBy,
        colaboradorNome: colaborador?.data()?.nome,
        colaboradorImagem: colaborador?.data()?.image?.[0]?.path
      };
    });
    const results = [];
    for (const promise of promises) {
      const result = await promise;
      results.push(result);
    }
    return results;
  });
}
