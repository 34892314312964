/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect, useState } from "react";
import { getEmbaixadoresPessoasByProjeto } from "@/modules/projetos/firestore";

export const useGetEmbaixadoresPessoasAutocadastro = (
  empresaId: TEmpresa["id"],
  projeto: TProjeto,
  licenciadaId?: TLicenciada["id"] | undefined
) => {
  const [embaixadores, setEmbaixadores] = useState<TEmbaixador[]>([]);

  const loadEmbaixadores = async () => {
    const embaixadoresFound = await getEmbaixadoresPessoasByProjeto(empresaId, projeto, licenciadaId);
    setEmbaixadores(embaixadoresFound);
  };

  const getEmbaixadoresPessoasData = () => {
    return embaixadores?.map((embaixador) => ({ value: embaixador.id, label: embaixador.nome }));
  };

  useEffect(() => {
    loadEmbaixadores();
  }, [empresaId, projeto.id]);

  return {
    embaixadores,
    embaixadoresPessoasData: getEmbaixadoresPessoasData()
  };
};
