import { Card, Group, Text, Flex, ThemeIcon, Button, Stack, Space, Badge, Mark } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { QueryObserverResult } from "@tanstack/react-query";
import { MouseEvent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useUserStore from "@/modules/users/store";
import TaticaDeInovacaoCard from "./TaticasDeInovacaoCard";
import { deleteTipoDeInovacao } from "../../firestore";

interface Tipo {
  id: string | number;
  nome: string;
  descricao: string;
}
// prettier-ignore
const TipoDeInovacaoCard = ({
  id,
  descricao,
  nome,
  refetch,
  taticas,
  defaultExpand,
  formatAutorizacoes
}: {
  id: string;
  descricao: string;
  nome: string;
  taticas: Array<Tipo>;
  refetch: () => Promise<QueryObserverResult>;
  defaultExpand?: boolean | undefined;
  formatAutorizacoes: TAutorizacao[] | undefined;
}) => {
  const [opened, { toggle }] = useDisclosure(defaultExpand);
  const navigate = useNavigate();
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  const handleEdit = useCallback(() => {
    navigate(`/tipos-de-inovacao/${id}`);
  }, [navigate, id]);

  /**
   * Handle removing the tipo de inovação.
   * @param event - The event that triggered the function.
   */
  function handleRemove(event: MouseEvent) {
    event.preventDefault();
    modals.openConfirmModal({
      title: "Remover tipo de inovação",
      centered: true,
      children: (
        <Text size="sm">
          Tem certeza que deseja remover o tipo de inovação <Mark>{nome}</Mark>
        </Text>
      ),
      labels: {
        confirm: "Sim",
        cancel: "Não"
      },
      confirmProps: { color: "red" },
      onConfirm: () => {
        void deleteTipoDeInovacao(activeEmpresaId || "", id || "");
        void refetch();
      }
    });
  }

  return (
    <Card style={{ backgroundColor: "rgba(244, 245, 246, 1)" }}>
      <Group justify="space-between">
        <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
          <Text fw={400} fz="md">
            Descrição
          </Text>
          <Text fw={300} fz="sm">
            {descricao}
          </Text>
        </Flex>
        <Badge radius="md" size="xl" variant="outline" style={{ background: "#e6f0e8" }} color="rgba(105, 198, 105, 1)">
          <Group justify="space-between">
            <ThemeIcon color="rgba(105, 198, 105, 1)" radius="lg" size="sm">
              <Text size="sm">{taticas && Array.isArray(taticas) ? taticas.length : 0}</Text>
            </ThemeIcon>
            <Text tt="capitalize" fz="sm" fw={500}>
              Táticas de inovação
            </Text>
          </Group>
        </Badge>
      </Group>
      <Space h="xl" />
      <Group justify="flex-end">
        {formatAutorizacoes?.find(item => item.tipoAutorizacao === "20")?.remover ? <Button
          type="button"
          variant="transparent"
          size="compact-sm"
          color="rgba(252, 85, 85, 1)"
          onClick={handleRemove}
        >
          Excluir
        </Button> : <></>}
        {formatAutorizacoes?.find(item => item.tipoAutorizacao === "20")?.editar ? <Button type="button" variant="transparent" size="compact-sm" color="rgba(8, 68, 244, 1)" onClick={handleEdit}>
          Editar
        </Button> : <></>}
        <Button
          type="button"
          variant="transparent"
          size="compact-sm"
          disabled={!taticas || !Array.isArray(taticas) || taticas.length === 0}
          rightSection={opened ? <IconChevronUp size="1rem" /> : <IconChevronDown size="1rem" />}
          onClick={toggle}
        >
          {opened ? "Recolher todas as táticas" : "Ver todas as táticas"}
        </Button>
      </Group>
      {opened && (
        <>
          <Space h="xl" />
          <Card.Section inheritPadding py="xs">
            <Stack>
              {taticas && Array.isArray(taticas) && taticas.length > 0 ? (
                taticas.map((tatica: Tipo) => <TaticaDeInovacaoCard key={tatica.id} tatica={tatica} />)
              ) : (
                <p>Nenhuma tática de inovação disponível</p>
              )}
            </Stack>
          </Card.Section>
        </>
      )}
    </Card>
  );
};

export default TipoDeInovacaoCard;
