import { Text, FocusTrap } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import { QueryObserverResult } from "@tanstack/react-query";
import { Tatica } from "./Interface";
import FormMovimentarTatica from "../../components/form-movimentar-tatica";
import useGetJogadaDeInovacaoFromUrl from "../../components/form-movimentar-tatica/useGetJogadaDeInovacaoFromUrl";

// prettier-ignore
const AddMovimentacaoTaticaModal = ({
  context,
  innerProps,
  id
}: ContextModalProps<{ tatica: Tatica; jogada: TJogadaDeInovacao, refetch: () => Promise<QueryObserverResult>; }>) => {
  const { tatica, jogada, refetch } = innerProps;
  const { data } = useGetJogadaDeInovacaoFromUrl(jogada?.id)
  /**
   * Handle add ignore area success
   */
  function onSuccess() {
    context.closeModal(id);
  }

  return (
    <FocusTrap active={true}>
      <Text mb="md">
        Movimentação na jogada de inovação
      </Text>
      <FormMovimentarTatica jogada={data as TJogadaDeInovacao} tatica={tatica} onSuccess={onSuccess} refetch={refetch} />
    </FocusTrap>
  );
};

export default AddMovimentacaoTaticaModal;
