import { Alert, Card, Center, Stack, Text } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { QueryKey } from "@tanstack/react-query";
import { ColumnFiltersState, Table as ReactTable } from "@tanstack/react-table";
import { forwardRef, useImperativeHandle } from "react";
import NoData from "@/components/no-data";
import Table from "@/components/table";
import ResetTableButton from "@/components/table/ResetTableButton";
import { THandler } from "@/components/table/Table";
import TablePagination from "@/components/table/TablePagination";
import useQueryTable from "@/components/table/useQueryTable";
import { getQuestionariosIdsByProjeto } from "@/modules/questionarios/firestore";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import gruposColumns from "./Columns";
import { DEFAULT_PAGINATION, DEFAULT_SORTING, getGruposDiagnosticos } from "../../firestore";

export const QUERY_KEY: string = "grupos-diagnostico-list" as const;

const GruposTable = forwardRef<THandler<TGruposDiagnostico>>((_properties, reference) => {
  const uid = getCurrentUser()?.uid;
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const projeto = useUserStore((state) => state.activeProjeto);

  const { table, data, isLoading, error, filters, refetch } = useQueryTable<TGruposDiagnostico>({
    queryKey: [QUERY_KEY, activeEmpresaId, projeto],
    enabled: !!uid && !!activeEmpresaId,
    subjectType: "TGruposDiagnostico",
    columns: gruposColumns,
    initialState: {
      columnFilters: [],
      globalFilter: "",
      pagination: DEFAULT_PAGINATION,
      sorting: DEFAULT_SORTING
    },
    queryFunction: async ({ sorting, filters, pagination, paginationCursors }) => {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      if (!projeto) {
        throw new Error("Projeto não selecionado");
      }

      const questionariosIds = await getQuestionariosIdsByProjeto(activeEmpresaId, projeto);
      const grupoFilters: ColumnFiltersState = [{ id: "idQuestionario", value: ["in", questionariosIds] }];

      if (filters) {
        grupoFilters.push(...filters);
      }

      return await getGruposDiagnosticos(activeEmpresaId, {
        sorting,
        filters: grupoFilters,
        pagination,
        paginationCursors
      });
    }
  });

  useImperativeHandle<THandler<TGruposDiagnostico>, THandler<TGruposDiagnostico>>(
    reference,
    () => {
      return {
        table,
        data,
        isLoading,
        error,
        filters
      };
    },
    [filters, data, error, isLoading, table]
  );

  /**
   * Handles the close of the error alert.
   */
  function handleErrorClose() {
    void refetch();
  }

  if (error) {
    return (
      <Alert
        icon={<IconAlertCircle size="1rem" />}
        title="Erro ao buscar grupos"
        color="red"
        withCloseButton
        onClose={handleErrorClose}
      >
        {error.message}
      </Alert>
    );
  }

  const isEmpty = !isLoading && data?.entries.length === 0;

  return (
    <>
      <Card.Section withBorder>
        <Table table={table} isLoading={isLoading} />
        {isEmpty && !isLoading && (
          <Center m="lg">
            <NoData m="md">
              <Stack gap="xs">
                <Text c="dimmed">Nenhum Grupo Encontrado!</Text>
                <ResetTableButton table={table as ReactTable<unknown>} queryKey={QUERY_KEY as unknown as QueryKey}>
                  Limpar filtros
                </ResetTableButton>
              </Stack>
            </NoData>
          </Center>
        )}
      </Card.Section>
      {reference && "current" in reference && reference.current !== null ? (
        <Card.Section withBorder>
          <TablePagination tableHandlers={reference.current} />
        </Card.Section>
      ) : null}
    </>
  );
});

GruposTable.displayName = "GruposTable";

export default GruposTable;
