import { Card, Flex, Group, Space, Text, Button } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import { Timestamp } from "firebase/firestore";
import { useCallback } from "react";
import useUserStore from "@/modules/users/store";

interface MyState {
  id?: number;
  data: Date | null;
  descricao: string;
  orientacaoLocal: string;
}

// prettier-ignore
interface CardSolucaoProperties {
  item: MyState;
  onDeleteClick?: (item: MyState) => void;
}

const CardSolucao = ({ item, onDeleteClick }: CardSolucaoProperties) => {
  const { data } = verificationDate(item);
  const colaborador = useUserStore((state) => state.colaborador);
  // Define a função de clique fora do JSX usando useCallback
  const handleDeleteClick = useCallback(() => {
    if (onDeleteClick) {
      onDeleteClick(item);
    }
  }, [onDeleteClick, item]);
  return (
    <>
      <Card withBorder>
        <Group justify={"space-between"}>
          <Group>
            <Flex direction={"column"}>
              <Text fz="xs" c="rgba(82, 83, 86, 1)">
                Data de inicio do evento
              </Text>
              <Text>{data.toLocaleString("pt-bt")}</Text>
            </Flex>
            <Flex direction={"column"}>
              <Text fz="xs" c="rgba(82, 83, 86, 1)">
                Descrição
              </Text>
              <Text>{item.descricao}</Text>
            </Flex>
            <Flex direction={"column"}>
              <Text fz="xs" c="rgba(82, 83, 86, 1)">
                Orientações sobre o local
              </Text>
              <Text>{item.orientacaoLocal}</Text>
            </Flex>
          </Group>
          {typeof onDeleteClick === "function" && colaborador && colaborador?.role === "ADMIN" ? (
            <Button variant="transparent" bg="transparent" onClick={handleDeleteClick}>
              <IconTrash size="1.2rem" />
            </Button>
          ) : (
            <></>
          )}
        </Group>
      </Card>
      <Space h={"md"} />
    </>
  );
};

export default CardSolucao;

const SEGUNDOS_EM_MILISEGUNDOS = 1000;

// prettier-ignore
const verificationDate = (item: MyState) => {
  let data: number | undefined;

  if (item.data instanceof Timestamp) {
    data = item.data.seconds;
  } else if (item.data && typeof item.data === 'object') {
    data = 'seconds' in item.data ?
      (item.data as { seconds: number }).seconds :
      (item.data).getTime() / SEGUNDOS_EM_MILISEGUNDOS;
  }

  return { data: data ? new Date(data * SEGUNDOS_EM_MILISEGUNDOS) : "" }
}
