import { subject } from "@casl/ability";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import {
  serverTimestamp,
  Timestamp,
  FirestoreDataConverter,
  collection,
  getCountFromServer,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  getDoc,
  query,
  where
} from "firebase/firestore";
import { getCurrentUser } from "@/services/auth";
import { generateQuery } from "@/utils/firestore";
import { getEmpresaDocumentReference } from "../empresas/firestore";
import { getQuestionarioCollectionReference } from "../grupos-diagnostico/firestore";

const DEFAULT_LIMIT = 10;
export const DEFAULT_SORTING: SortingState = [{ id: "nome", desc: false }];
export const DEFAULT_PAGINATION: PaginationState = { pageIndex: 0, pageSize: DEFAULT_LIMIT };

export type TQuestionarioFormFields = Omit<
  TQuestionario,
  "id" | "refPath" | "createdAt" | "updatedAt" | "createdBy" | "updatedBy" | "deletedAt"
> &
  Partial<Pick<TQuestionario, "id" | "refPath">>;

export type TQuestionarioDatabaseFields = Pick<TQuestionario, "createdBy" | "updatedBy"> & {
  createdAt: ReturnType<typeof serverTimestamp>;
  updatedAt?: ReturnType<typeof serverTimestamp>;
  deletedAt: ReturnType<typeof serverTimestamp> | null;
};
export type TQuestionarioForm = TQuestionarioFormFields & TQuestionarioDatabaseFields;

type TQuestionarioDocument = Omit<
  TQuestionario,
  "createdAt" | "updatedAt" | "deletedAt" | "dataAtuacao" | "dataVigencia"
> & {
  createdAt: Timestamp;
  updatedAt?: Timestamp;
  deletedAt: Timestamp | null;
  dataAtuacao: Timestamp;
  dataVigencia: Timestamp;
};

const questionarioConverter: FirestoreDataConverter<TQuestionario> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const { createdAt, updatedAt, deletedAt, dataAtuacao, dataVigencia, ...document } =
      snap.data() as TQuestionarioDocument;

    const data: TQuestionario = {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      deletedAt: deletedAt ? deletedAt.toDate() : null,
      refPath: snap.ref.path,
      dataAtuacao: dataAtuacao.toDate(),
      dataVigencia: dataVigencia.toDate()
    };

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }

    return subject("TQuestionario", data);
  }
};

/**
 * Get questionarios collection reference from empresaId
 * @param empresaId - Empresa id to get the questionarios collection reference
 * @param licenciadaId - Licenciada id
 * @returns - Questionarios collection reference
 */
function getQuestionariosCollectionReference(empresaId: TEmpresa["id"], licenciadaId?: TLicenciada["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId, licenciadaId);
  return collection(empresaDocumentReference, "questionarios");
}

/**
 * Get questionario document reference from empresaId
 * @param empresaId - Empresa id to get the questionario document reference
 * @param questionarioId - Questionario id to get the questionario document reference
 * @param licenciadaId - Licenciada id
 * @returns - Questionario document reference
 */
function getQuestionarioDocumentReference(
  empresaId: TEmpresa["id"],
  questionarioId: TQuestionario["id"],
  licenciadaId?: TLicenciada["id"]
) {
  const questionariosCollectionReference = getQuestionariosCollectionReference(empresaId, licenciadaId);
  return doc(questionariosCollectionReference, questionarioId);
}

const BASE_FILTER: ColumnFiltersState = [
  {
    id: "deletedAt",
    value: ["==", null]
  }
];

/**
 * Get questionarios documents from empresaId
 * @param empresaId - Empresa id to get the questionarios documents
 * @param options - Options to get the questionarios documents
 * @param options.sorting - Sorting options
 * @param options.filters - Filters options
 * @param options.pagination - Pagination options
 * @param options.paginationCursors - Array of pagination cursors (startAt, endAt)[]
 * @returns - Questionarios documents
 */
export function getQuestionarios(
  empresaId: TEmpresa["id"],
  {
    sorting = [],
    filters = [],
    pagination = { pageIndex: 0, pageSize: DEFAULT_LIMIT },
    paginationCursors
  }: TPaginatedQueryOptions<TQuestionario> = {}
): Promise<TPaginatedQueryResponse<TQuestionario>> {
  const questionariosCollectionReference =
    getQuestionariosCollectionReference(empresaId).withConverter(questionarioConverter);
  const allFilters = [...BASE_FILTER, ...filters];
  const qAll = generateQuery(questionariosCollectionReference, { filters: allFilters });
  const qPaginated = generateQuery(questionariosCollectionReference, {
    sorting,
    filters: allFilters,
    pagination,
    paginationCursors
  });

  // eslint-disable-next-line compat/compat
  return Promise.all([getDocs(qPaginated), getCountFromServer(qAll)]);
}

/**
 * Add a new questionario to the given empresa
 * @param empresaId - Empresa id to add the questionario
 * @param questionario - Questionario data
 * @returns - Questionario document reference
 */
export async function addQuestionario(
  empresaId: TEmpresa["id"],
  questionario: Omit<TQuestionarioForm, "id" | "refPath">
) {
  const questionariosCollectionReference = getQuestionariosCollectionReference(empresaId);
  return addDoc(questionariosCollectionReference, questionario);
}

// /**
//  * Get a questionario document snapshot
//  * @param empresaId - Empresa id to get the questionario
//  * @param questionarioId - questionario id to get the questionario
//  * @returns - Questionario document snapshot
//  */
// export function getQuestionario(empresaId: TEmpresa["id"], questionarioId: TQuestionario["id"]) {
//   const questionarioDocumentReference = getQuestionarioDocumentReference(empresaId, questionarioId).withConverter(questionarioConverter);
//   return getDoc(questionarioDocumentReference);
// }

/**
 * Update a empresa to the database
 * @param empresaId - Empresa id
 * @param questionarioId - Questionario id to update
 * @param questionario - Questionario data
 * @returns - Promise with the questionario reference
 */
export function updateQuestionario(
  empresaId: TEmpresa["id"],
  questionarioId: TQuestionario["id"],
  questionario: TQuestionarioForm
) {
  const questionarioDocumentReference = getQuestionarioDocumentReference(empresaId, questionarioId).withConverter(
    questionarioConverter
  );
  const currentUserId = getCurrentUser()?.uid;
  return updateDoc(questionarioDocumentReference, {
    ...questionario,
    updatedAt: serverTimestamp(),
    updatedBy: currentUserId
  });
}

/**
 * Delete a questionario to the database
 * @param empresaId - Empresa id to delete the questionario
 * @param questionario - Questionario to delete
 * @returns - Promise
 */
export function deleteQuestionario(empresaId: TEmpresa["id"], questionario: TQuestionario) {
  const questionarioDocumentReference = getQuestionarioDocumentReference(empresaId, questionario.id);
  return updateDoc(questionarioDocumentReference, {
    deletedAt: serverTimestamp()
  });
}

/**
 * Get questionario item
 * @param empresaId - Empresa id to start a new jogada de inovação
 * @param questionarioId - jogada de inovação form fields
 * @param licenciadaId - Licenciada id
 * @returns - questionario document item
 */
export function getQuestionarioItem(
  empresaId: TEmpresa["id"],
  questionarioId: TQuestionario["id"],
  licenciadaId?: TLicenciada["id"]
) {
  const questionariocumentReference = getQuestionarioDocumentReference(empresaId, questionarioId, licenciadaId);
  return getDoc(questionariocumentReference.withConverter(questionarioConverter));
}

/**
 * Get questionarios ids by projeto
 * @param empresaId - Empresa id
 * @param projeto - Projeto id
 * @returns - Questionarios ids
 */
export async function getQuestionariosIdsByProjeto(empresaId: TEmpresa["id"], projeto: TProjeto["id"]) {
  const questionariosCollectionReference = getQuestionarioCollectionReference(empresaId);
  const queryQuestionarios = query(questionariosCollectionReference, where("idProjeto", "==", projeto));
  const questionarios = await getDocs(queryQuestionarios);
  return questionarios.docs.map((document_) => document_.id);
}
