import { Text, FocusTrap } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import QuestionarioForm from "../../components/questionario-form";
import { TQuestionarioForm } from "../../firestore";

const QuestionarioFormModal = ({ context, innerProps, id }: ContextModalProps<{ item: TQuestionarioForm }>) => {
  const { item: questionario } = innerProps;
  /**
   * Handle add ignore area success
   */
  function onSuccess() {
    context.closeModal(id);
  }

  return (
    <FocusTrap active={true}>
      <Text fw="bold" mb="md">
        Cadastro de questionario
      </Text>
      <QuestionarioForm questionario={questionario} onSuccess={onSuccess} />
    </FocusTrap>
  );
};

export default QuestionarioFormModal;
