import { Space, Card, Grid, Flex, Group, Badge, Text, Loader } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { useMutation } from "@tanstack/react-query";
import { MouseEvent, useMemo } from "react";
import { useParams } from "react-router-dom";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import useGetRespostaColaborador from "@/modules/responder-questionario/hooks/useGetRespostaColaborador";
import useUserStore, { setReloadConvites } from "@/modules/users/store";
import { captureException } from "@/services/log";
import { formatDate } from "@/utils/date";
import VersionRespostaButton from "./VersionRespostaButton";
import { updateConvite } from "../../firestore";

type TCancelResponse = Awaited<ReturnType<typeof updateConvite>>;

/**
 * Handles the click event on the button.
 * @param cancelInvite - The function to cancel invite.
 * @param reloadConvites - reload
 * @returns - The return the click.
 */
function handleClick(cancelInvite: () => void, reloadConvites: boolean | undefined) {
  return (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    modals.openConfirmModal({
      title: "Cancelar o convite?",
      centered: true,
      children: <Text size="sm">Tem certeza que deseja cancelar a o convite deste usuário?</Text>,
      labels: {
        confirm: "Sim",
        cancel: "Não"
      },
      confirmProps: { color: "red" },
      onConfirm: () => {
        void cancelInvite();
        setReloadConvites(reloadConvites);
      }
    });
  };
}

/**
 * Cancel invite mutation hook
 * @param invite - The invite.
 * @returns Cancel invite mutation hook
 */
function useCancelInviteMutation(invite: TConvite) {
  return useMutation<TCancelResponse, Error>({
    mutationFn: () => {
      return updateConvite(invite.id, {
        ...invite,
        cancelado: true,
        deletedAt: null
      });
    },
    onError(error) {
      captureException(error, true);
    }
  });
}

type StatusBadgeProperties = {
  respondido: boolean;
  cancelado: boolean;
};

/**
 * Exibe o status da resposta do colaborador
 * @param root0 Props
 * @param root0.respondido Se o questionário foi respondido
 * @param root0.cancelado Se o convite foi cancelado
 * @returns Component
 */
function StatusBadge({ respondido, cancelado }: StatusBadgeProperties) {
  if (cancelado) {
    return (
      <Badge
        radius="md"
        size="xl"
        variant="outline"
        style={{ background: "rgba(229, 78, 78, 0.1)" }}
        color="rgba(229, 78, 78, 1)"
      >
        <Text tt="capitalize" fz="xs" fw={500}>
          Cancelado
        </Text>
      </Badge>
    );
  }

  if (respondido) {
    return (
      <Badge
        radius="md"
        size="xl"
        variant="outline"
        style={{ background: "rgba(244, 245, 246, 1)" }}
        color="rgba(244, 245, 246, 1)"
      >
        <Text tt="capitalize" fz="xs" fw={500} color={"black"}>
          Respondido
        </Text>
      </Badge>
    );
  }

  return (
    <Badge
      radius="md"
      size="xl"
      variant="outline"
      style={{ background: "rgba(105, 198, 105, 0.1)" }}
      color="rgba(105, 198, 105, 1)"
    >
      <Text tt="capitalize" fz="xs" fw={500}>
        Aguardando resposta
      </Text>
    </Badge>
  );
}

type ConviteRespostaCardProperties = {
  convite: TConvite;
};

type Parameters_ = {
  id: string;
};

/**
 * Card para exibir o status da inscrição do colaborador em um questionário.
 * @param root0 Props
 * @param root0.convite Convite
 * @returns Component
 */
function ConviteRespostaCard({ convite }: ConviteRespostaCardProperties) {
  const { id: questionarioId } = useParams<Parameters_>();

  const reloadConvites = useUserStore((state) => state.reloadConvites);

  const { data: colaborador = null, isFetching: isFetchingColaborador } = useGetColaboradorById(
    convite.idEmpresaColaboradorConvidado,
    convite.idColaboradorConvidado
  );

  const { data: resposta = null, isFetching: isFetchingResposta } = useGetRespostaColaborador(
    convite.idEmpresa,
    convite.idQuestionario || questionarioId || "",
    convite.idColaboradorConvidado
  );

  const { mutate: cancelInvite } = useCancelInviteMutation(
    convite || {
      id: "",
      idProjeto: "",
      idEmpresa: "",
      idColaboradorConvidado: "",
      idEmpresaColaboradorConvidado: "",
      convite: false,
      idQuestionario: undefined,
      createdAt: new Date(),
      createdBy: "",
      refPath: ""
    }
  );

  const respondido = useMemo(() => {
    return !!resposta && resposta.pausado === false && resposta.perguntas.length > 0;
  }, [resposta]);

  const isSmMarches = useMediaQuery("(max-width: 48em)");

  if (isFetchingColaborador || isFetchingResposta) {
    return (
      <>
        <Space h="md" />
        <Card withBorder shadow="md" style={{ flex: 1, width: isSmMarches ? "1000px" : "100%" }}>
          <Loader size={24} />
        </Card>
      </>
    );
  }

  if (!colaborador) {
    return (
      <>
        <Space h="md" />
        <Card withBorder shadow="md" style={{ flex: 1, width: isSmMarches ? "1000px" : "100%" }}>
          <Text>Não foi possível carregar o colaborador</Text>
        </Card>
      </>
    );
  }

  return (
    <>
      <Space h="md" />
      <Card withBorder shadow="md" style={{ flex: 1, width: isSmMarches ? "1000px" : "100%" }}>
        <Grid align="center">
          <Grid.Col span={3}>
            <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
              <Text fw={300} fz="xs">
                Nome do colaborador
              </Text>
              <Text>{colaborador?.nome}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={3}>
            <Group align={"center"} justify="center">
              <StatusBadge respondido={respondido} cancelado={!!convite.cancelado} />
            </Group>
          </Grid.Col>
          <Grid.Col span={3}>
            <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
              <Text fw={300} fz="xs">
                Data do envio
              </Text>
              <Text fw={500} fz="md">
                {convite && formatDate(convite.createdAt)}
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={3}>
            <VersionRespostaButton
              colaborador={colaborador}
              resposta={resposta}
              convite={convite}
              onClick={handleClick(cancelInvite, reloadConvites)}
            />
          </Grid.Col>
        </Grid>
      </Card>
    </>
  );
}

export default ConviteRespostaCard;
