import { subject } from "@casl/ability";
import { Anchor, Card, Group, Skeleton, Text, Title } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { NavLink, useParams } from "react-router-dom";
import BreadcrumbsNavigation from "@/components/breadcrumbs-navigation";
import NoData from "@/components/no-data";
import ComiteInovacaoStatusButtons from "@/modules/comite-inovacao/components/comite-inovacao-status-buttons";
import ComiteInovacaoItem from "@/modules/comite-inovacao/components/comites-inovacao-history/ComiteInovacaoItem";
import { QUERY_KEY, getComiteInovacao } from "@/modules/comite-inovacao/firestore";
import useUserStore from "@/modules/users/store";
import { formatDate } from "@/utils/date";

type TGetComiteInovacaoResponse = Awaited<ReturnType<typeof getComiteInovacao>>;

/**
 * Get ideia from url params
 * @returns - Ideia or null if not found
 */
function useGetComiteInovacaoFromUrl() {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const { comiteId } = useParams<{ comiteId: string }>();

  return useQuery<TGetComiteInovacaoResponse, FirestoreError, TComiteInovacao | null>({
    queryKey: [QUERY_KEY, comiteId, activeEmpresaId],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!comiteId) {
        throw new Error("Ideia não selecionada");
      }
      return getComiteInovacao(activeEmpresaId, comiteId);
    },
    select(dataResponse) {
      if (!dataResponse.exists()) {
        return null;
      }

      return subject("TComiteInovacao", dataResponse.data());
    },
    enabled: !!comiteId && !!activeEmpresaId
  });
}

const ComiteDeInovacaoPage = () => {
  const { data: comite, isLoading, isFetched } = useGetComiteInovacaoFromUrl();

  const comiteNotFound = isFetched && !comite;
  if (comiteNotFound) {
    return (
      <NoData>
        <Text c="dimmed">Comitê não encontrado!</Text>
      </NoData>
    );
  }

  return (
    <>
      <BreadcrumbsNavigation>
        <Anchor component={NavLink} to="/comites-de-inovacao">
          Comitês de inovacao
        </Anchor>
        <Skeleton radius="md" height={12} visible={isLoading}>
          <Text c="dimmed" mt={-6}>
            {comite ? formatDate(comite.createdAt) : ""}
          </Text>
        </Skeleton>
      </BreadcrumbsNavigation>
      {comite && (
        <Card withBorder>
          <Group justify="space-between" mb="md">
            <Title order={4} fw={500}>
              Ata do comitê
            </Title>
            <ComiteInovacaoStatusButtons comite={comite} />
          </Group>

          <ComiteInovacaoItem comite={comite} defaultExpand={true} />
        </Card>
      )}
    </>
  );
};

export default ComiteDeInovacaoPage;
