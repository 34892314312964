import { Card, FocusTrap, ScrollArea, Space, Title } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import { TCampanhaRegulamentoIdeiasForm } from "@/modules/campanha-regulamento-ideias/firestore";
import CampanhaItem from "../../components/campanhaItem";

// prettier-ignore
const ModalCampanha = ({
  context,
  innerProps,
  id,
}: ContextModalProps<{ item: TCampanhaRegulamentoIdeiasForm; data: TCampanhaRegulamentoIdeias[] | undefined, setReload: () => void }>) => {
  const { data, setReload } = innerProps;

  /**
   * Handle add ignore area success
   */
  function onSuccess() {
    context.closeModal(id);
    void setReload();
  }

  return (
    <FocusTrap active={true}>
      <Title order={4}>{data && data?.length > 1 ? "Lista de Campanhas" : "Campanha"}</Title>
      <Space h={"md"} />
      {data && data?.length > 1 ? <ScrollArea h={600} p={20}>
        {data.map(item =>
          <>
            <Card withBorder>
              <CampanhaItem key={item.id} item={item} onSuccess={onSuccess} />
            </Card>
            <Space h={"md"} />
          </>
        )}
      </ScrollArea> : <CampanhaItem item={data && data[0]} onSuccess={onSuccess} />}
    </FocusTrap>
  );
};

export default ModalCampanha;
