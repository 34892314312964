import {
  addDoc,
  and,
  collection,
  FirestoreDataConverter,
  getDocs,
  query,
  serverTimestamp,
  Timestamp,
  where
} from "firebase/firestore";
import { firestore } from "@/base";
import { getQuestionariosArray } from "../grupos-diagnostico/firestore";

type TConviteDocument = Omit<TConvite, "createdAt" | "updatedAt"> & {
  createdAt: Timestamp;
  updatedAt?: Timestamp;
};

type TConviteForm = Omit<TConvite, "createdAt" | "updatedAt" | "id" | "refPath"> & {
  createdAt: ReturnType<typeof serverTimestamp>;
  updatedAt?: ReturnType<typeof serverTimestamp>;
};

const conviteConverter: FirestoreDataConverter<TConvite> = {
  toFirestore(data) {
    delete data.id;
    return data;
  },
  fromFirestore(snap) {
    const { createdAt, updatedAt, ...document } = snap.data() as TConviteDocument;

    const data: TConvite = {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate()
    };

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }
    return data;
  }
};

/**
 * Gets the convites collection
 * @returns The convites collection
 */
function getConviteCollection() {
  return collection(firestore, "convites").withConverter(conviteConverter);
}

type AddConviteProperties = {
  idProjeto: string;
  idEmpresa: string;
  idQuestionario: string;
  idColaboradorConvidado: string;
  idEmpresaColaboradorConvidado: string;
  convite: boolean;
  email?: string | null | undefined;
  idLicenciada: string | undefined;
  externo: boolean;
};

/**
 * Get all empresas from the database
 * @param empresaId - Empresa id
 * @param questionarioId - Questionario id
 * @param empresaRespondenteId - Empresa respondente id
 * @param email - Email
 * @returns - Promise with all empresas
 */
export async function getConviteByEmail(
  empresaId: TEmpresa["id"],
  questionarioId: TQuestionario["id"],
  empresaRespondenteId: TEmpresa["id"],
  email: string
) {
  const convitesCollectionReference = getConviteCollection();

  const convitesQuery = query(
    convitesCollectionReference,
    and(
      where("idEmpresa", "==", empresaId),
      where("idQuestionario", "==", questionarioId),
      where("idEmpresaColaboradorConvidado", "==", empresaRespondenteId),
      where("email", "==", email)
    )
  );

  const documents = await getDocs(convitesQuery);

  return documents.docs.at(0);
}

/**
 * Add a convite to the database
 * @param convite - The convite to be added
 * @returns - Promise with the convite reference
 */
export async function addConvite(convite: AddConviteProperties) {
  const convitesCollectionReference = getConviteCollection();
  const newConvite: TConviteForm = {
    ...convite,
    createdAt: serverTimestamp(),
    createdBy: ""
  };

  return await addDoc(convitesCollectionReference, newConvite as unknown as TConvite);
}

export type EmpresaQuestionarioConvite = {
  empresaId: string;
  questionarioId: string;
};

/**
 * Get all empresas collection reference where the current user is the owner
 * @param empresaId - Empresa id
 * @returns - Empresas collection reference
 */
export async function getQuestionariosEmpresaConvidada(empresaId: TEmpresa["id"]) {
  const convitesCollectionReference = getConviteCollection();

  const convitesQuery = query(convitesCollectionReference, where("idEmpresaColaboradorConvidado", "==", empresaId));
  const convitesDocuments = await getDocs(convitesQuery);

  const questionariosByEmpresaMap = new Map<string, Set<string>>();
  const projetosByEmpresaMap = new Map<string, Set<string>>();

  for (const convite of convitesDocuments.docs) {
    const { idEmpresa, idQuestionario, idProjeto } = convite.data();

    if (idQuestionario) {
      const questionarios = questionariosByEmpresaMap.get(idEmpresa) ?? new Set();
      questionarios.add(idQuestionario);
      questionariosByEmpresaMap.set(idEmpresa, questionarios);
    } else {
      const projetos = projetosByEmpresaMap.get(idEmpresa) ?? new Set();
      projetos.add(idProjeto);
      projetosByEmpresaMap.set(idEmpresa, projetos);
    }
  }

  for (const [empresaProjeto, projetosSet] of projetosByEmpresaMap) {
    const { docs } = await getQuestionariosArray(empresaProjeto, [...projetosSet]);
    const questionariosIdsFound = docs.map((questionario) => questionario.data().id);

    const existingQuestionarios = questionariosByEmpresaMap.get(empresaProjeto) ?? new Set();
    questionariosByEmpresaMap.set(empresaProjeto, new Set([...existingQuestionarios, ...questionariosIdsFound]));
  }

  const empresasQuestionarios: EmpresaQuestionarioConvite[] = [];

  for (const [empresaId, questionariosSet] of questionariosByEmpresaMap) {
    for (const questionarioId of questionariosSet) {
      empresasQuestionarios.push({ empresaId, questionarioId });
    }
  }

  return empresasQuestionarios;
}
