import { Text, Group, Badge, ThemeIcon, Button, BadgeProps } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconThumbUp } from "@tabler/icons-react";
import { useCallback } from "react";
import useCountTotalLikesPerIdeia from "@/modules/ideias-likes/hooks/useCountTotalLikesPerIdeia";

const TotalLikesBadge = ({ ideia, ...badgeProperties }: BadgeProps & { ideia: TIdeia }) => {
  const { data: totalLikesNaIdeia } = useCountTotalLikesPerIdeia(ideia);

  const handleDetailLikes = useCallback(() => {
    modals.openContextModal({
      modal: "detail-ideia-likes-modal",
      innerProps: { ideia },
      size: "lg",
      title: "Quem curtiu essa a ideia"
    });
  }, [ideia]);

  return (
    <Group align="center" gap="xs">
      <Button onClick={handleDetailLikes} variant="transparent">
        <Badge
          color="gray.2"
          py="sm"
          radius="md"
          leftSection={
            <ThemeIcon variant="light" size="sm" bg="transparent">
              <IconThumbUp />
            </ThemeIcon>
          }
          {...badgeProperties}
        >
          <Text c="dark" fz="xs" fw={500} tt="lowercase">
            {totalLikesNaIdeia} curtidas
          </Text>
        </Badge>
      </Button>
    </Group>
  );
};

export default TotalLikesBadge;
