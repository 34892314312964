import { Box, Group, LoadingOverlay, Text } from "@mantine/core";
import useQueryGrupo from "../perguntas-form/useQueryGrupo";

const GruposColumn = ({ properties }: { properties: string }) => {
  const { data: grupos, isFetching } = useQueryGrupo();
  const gruposArray = grupos || [];

  if (isFetching) {
    return (
      <Box pos="relative">
        <LoadingOverlay visible={isFetching} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
      </Box>
    );
  }

  return (
    <Group>
      <Text>{gruposArray?.find((item) => item.id === properties)?.nome || ""}</Text>
    </Group>
  );
};

export default GruposColumn;
