import { Avatar, Card, Flex, Group, Stack, Text } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import Can from "@/components/can";
import EditItemButton from "@/components/edit-item-button";
import TotalCoinsColaborador from "@/components/total-coins-colaborador";
import useUserStore from "@/modules/users/store";
import { extractInitialsFromText } from "@/utils/text";
import colaboradorSchema from "../../schema/colaborador-schema";

interface ColaboradorCardProperties {
  tipo: "ideias" | "desafios";
}

const ColaboradorCard = ({ tipo }: ColaboradorCardProperties) => {
  const colaborador = useUserStore((state) => state.colaborador);

  if (!colaborador) {
    return null;
  }

  const name = colaborador.nome || colaborador.email;

  return (
    <Card style={{ flex: 1 }} withBorder p="lg" pos="relative">
      <Flex align="center" h="100%">
        <Group gap="xl">
          <Avatar src={null} alt={name} size="xl">
            {extractInitialsFromText(name)}
          </Avatar>
          <Stack>
            <div>
              <Text fw={500}>{colaborador.nome}</Text>
              <Text c="dimmed" tt="lowercase">
                {colaborador.role}
              </Text>
            </div>
            <div>
              <Text fw={500}>{colaborador.email}</Text>
              <Text c="dimmed">Email</Text>
            </div>
            <TotalCoinsColaborador tipo={tipo} />
          </Stack>
        </Group>
      </Flex>
      <Can I="update" this={colaborador}>
        <EditItemButton
          modal="colaborador-form-modal"
          schema={colaboradorSchema}
          item={colaborador}
          size="xs"
          pos="absolute"
          top={20}
          right={30}
        >
          <Group gap={4}>
            <IconEdit size="1.2rem" stroke={1.5} />
            Editar
          </Group>
        </EditItemButton>
      </Can>
    </Card>
  );
};

export default ColaboradorCard;
