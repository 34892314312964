import { Group, Anchor } from "@mantine/core";
import { NavLink, useParams } from "react-router-dom";
import BreadcrumbsNavigation from "@/components/breadcrumbs-navigation";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import useGetRespostaConvite from "../../hooks/useGetRespostaConvite";
import useQueryGroupConvite from "../../hooks/useQueryGroupConvite";
import PerguntasItem from "../perguntas";

const Questionario = ({
  data,
  licenciado
}: {
  data: TQuestionario | null | undefined;
  licenciado: TLicenciado | null | undefined;
}) => {
  const user = getCurrentUser();

  const { idEmpresa } = useParams<{ idEmpresa: string }>();
  const { activeEmpresaId } = useUserStore();

  const { data: grupos } = useQueryGroupConvite({ id: data?.id, activeEmpresaId: idEmpresa });
  const { data: resposta } = useGetRespostaConvite({
    id: user?.uid,
    idQuestionario: data?.id,
    rootEmpresaId: idEmpresa ?? ""
  });

  return (
    <>
      <BreadcrumbsNavigation notHome={true}>
        <Anchor component={NavLink} to="/questionarioDeInovacao">
          Questionário
        </Anchor>
      </BreadcrumbsNavigation>
      <Group>
        <PerguntasItem
          ids={orderGrupos(grupos)?.map((item) => item.id)}
          questionario={data}
          resposta={resposta?.filter((item) => item.idEmpresa === activeEmpresaId)}
          licenciado={licenciado}
        />
      </Group>
    </>
  );
};

export default Questionario;

const orderGrupos = (data: TGruposDiagnostico[] | undefined) => {
  return data?.sort((a, b) => a.ordemQuestionario - b.ordemQuestionario);
};
