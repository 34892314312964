import { FileWithPath } from "@mantine/dropzone";
import { DocumentReference, serverTimestamp } from "firebase/firestore";
import { ObjectSchema } from "yup";
import schema from "@/schema";
import auditSchema from "@/schema/audit-schema";
import { getDocumentReferenceFromPath } from "@/utils/firestore";
import { TDesafioForm } from "./firestore";
import {
  TColaboradorDocument,
  colaboradorConverter,
  getColaboradorDocumentReferenceFromPath
} from "../colaboradores/firestore";
import { desafioCategoriaConverter } from "../desafio-categorias/firestore";
import { getRegulamentoDocumentReferenceFromReferencePath } from "../regulamento-desafios/firestore";

const desafioFormSchema: ObjectSchema<TDesafioForm> = auditSchema
  .clone()
  // eslint-disable-next-line unicorn/prefer-spread
  .concat(
    schema.object({
      titulo: schema.string().required().defined().default("").label("Título"),
      descricao: schema.string().required().defined().default("").label("Descrição"),
      objetivo: schema.string().required().defined().default("").label("Objetivo"),
      publishedAt: schema.mixed<ReturnType<typeof serverTimestamp>>().defined().nullable().default(null),
      published: schema.boolean().optional().defined().default(false),
      categoriaReference: schema
        .mixed<DocumentReference<TDesafioCategoria>>()
        .required()
        .defined()
        .transform((value: string | DocumentReference<TDesafioCategoria>) => {
          const isString = typeof value === "string";
          return isString ? getDocumentReferenceFromPath(value, desafioCategoriaConverter) : value;
        })
        .label("Categoria"),
      status: schema.string().required().defined().default("DRAFT").label("Status"),
      propositorReference: schema
        .mixed<DocumentReference<TColaborador>>()
        .required()
        .defined()
        .transform((value: string | DocumentReference<TColaborador>) => {
          const isString = typeof value === "string";
          return isString ? getDocumentReferenceFromPath(value, colaboradorConverter) : value;
        })
        .label("Propositor"),

      participantesReferences: schema
        .array()
        .of(schema.mixed<DocumentReference<TColaborador, TColaboradorDocument>>().required().defined())
        .required()
        .defined()
        .default([])
        .transform((value: (string | DocumentReference<TColaborador, TColaboradorDocument>)[]) =>
          value.map((v) => {
            const isString = typeof v === "string";
            return isString ? getColaboradorDocumentReferenceFromPath(v) : v;
          })
        )
        .label("Participantes do desafio"),

      regulamentoReference: schema
        .mixed<DocumentReference<TRegulamentoDesafios> | string>()
        .required()
        .defined()
        .transform((value: string | DocumentReference<TRegulamentoDesafios>) => {
          const isString = typeof value === "string";
          return isString ? getRegulamentoDocumentReferenceFromReferencePath(value) : value;
        })
        .label("Regulamento"),

      anexos: schema
        .array()
        .of(schema.mixed<TFileStored>().required().defined())
        .required()
        .defined()
        .default([])
        .meta({
          description:
            "Sintetize e inclua tudo o que for necessário em um arquivo, se tiver vídeos pode incluir o link do vídeo no arquivo de texto"
        })
        .label("Se tiver algum anexo sobre o desafio, pode incluir aqui:"),

      anexosFilesToUpload: schema
        .array()
        .of(schema.mixed<FileWithPath>().required().defined())
        .required()
        .defined()
        .default([]),

      deletedAt: schema.mixed<ReturnType<typeof serverTimestamp>>().defined().nullable().default(null)
    })
  )
  .noUnknown()
  .strict()
  .label("Desafio");

export default desafioFormSchema;
