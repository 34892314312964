import { Button, Stack, Text, isLightColor, lighten, parseThemeColor, useMantineTheme } from "@mantine/core";
import { forwardRef } from "react";
import { useSearchParams } from "react-router-dom";

const LIGHTEN_ACTIVE_BUTTON_PERCENT = 0.7;

type IdeiasFilterButtonProperties = {
  text: string;
  total: number;
  color: string;
  status: TIdeia["status"];
};

const IdeiasFilterButton = forwardRef<HTMLButtonElement, IdeiasFilterButtonProperties>(
  ({ text, total, status, color }, reference) => {
    const theme = useMantineTheme();
    const [query, setSearchParameters] = useSearchParams();

    const filter = query.get("filter");
    const active = status === filter;

    const parsedColor = parseThemeColor({ color, theme });
    const bg = active ? color : lighten(parsedColor.value, LIGHTEN_ACTIVE_BUTTON_PERCENT);

    /**
     * Handle click on filter button
     */
    function handleClick() {
      if (filter === status) {
        setSearchParameters({ filter: "" });
      } else {
        setSearchParameters({ filter: status });
      }
    }

    return (
      <Button radius="md" bg={bg} h={64} onClick={handleClick} ref={reference}>
        <Stack gap={0} pr="xl" align="start">
          <Text fw="bold" c={isLightColor(parsedColor.value) ? "dark" : "light"}>
            + {total}
          </Text>
          <Text fz="sm" fw={active ? "bold" : "normal"} c={isLightColor(parsedColor.value) ? "dark" : "light"}>
            {text}
          </Text>
        </Stack>
      </Button>
    );
  }
);

IdeiasFilterButton.displayName = "IdeiasFilterButton";

export default IdeiasFilterButton;
