import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { getDesafioActivitiesPerColaborador } from "../firestore";

type TGetDesafioActivitiesPerColaboradorResponse = Awaited<ReturnType<typeof getDesafioActivitiesPerColaborador>>;
type TQueryOptions = Omit<
  UseQueryOptions<TGetDesafioActivitiesPerColaboradorResponse, FirestoreError, TDesafioActivityPerColaborador>,
  "queryKey"
>;

/**
 * Get desafio activities per colaborador
 * @param desafio - Desafio to get activities
 * @param colaboradorId - Colaborador ID to get activities from
 * @param options - Query options to use
 * @returns - Query result
 */
function useGetDesafioActivityByColaborador(
  desafio: TDesafio,
  colaboradorId: TColaborador["id"] | undefined,
  options: TQueryOptions = {}
) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  return useQuery<TGetDesafioActivitiesPerColaboradorResponse, FirestoreError, TDesafioActivityPerColaborador | null>({
    queryKey: [activeEmpresaId, "desafio", desafio.id, "activities", colaboradorId],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!colaboradorId) {
        throw new Error("Colaborador não selecionada");
      }

      return getDesafioActivitiesPerColaborador(activeEmpresaId, desafio.id, colaboradorId);
    },
    select(dataSnapshot) {
      if (!dataSnapshot.exists()) {
        return null;
      }

      return dataSnapshot.data();
    },
    ...options
  });
}

export default useGetDesafioActivityByColaborador;
