import { Center, Space, Stack, Tabs, Text } from "@mantine/core";
import NoData from "@/components/no-data";
import JogadasDeInovacaoHistoryLoading from "./JogadasDeInovacaoHistoryLoading";
import useQueryJogadaDeInovacao from "./useQueryJogadaDeInovacao";
import JogadaDeInovacaoItem from "../jogadas-de-inovacao-card";
// prettier-ignore
const JogadasDeInovacaoList = ({ formatAutorizacoes }: { formatAutorizacoes: TAutorizacao[] | undefined }) => {
  const { data, isLoading, refetch, isFetching } = useQueryJogadaDeInovacao();

  if (data?.length === 0) {
    return (
      <Center m="lg">
        <NoData m="md">
          <Stack gap="xs">
            <Text c="dimmed">Nenhum tipo de inovação encontrado!</Text>
          </Stack>
        </NoData>
      </Center>
    );
  }

  return (
    <Stack gap="xs" mt="md" style={{ overflow: "auto" }}>
      {(isLoading || isFetching) && (
        <>
          <JogadasDeInovacaoHistoryLoading />
          <JogadasDeInovacaoHistoryLoading />
          <JogadasDeInovacaoHistoryLoading />
        </>
      )}
      <Tabs defaultValue="gallery">
        <Tabs.List>
          <Tabs.Tab value="gallery">Todos</Tabs.Tab>
          <Tabs.Tab value="messages">Jogadas sugeridas ({data?.filter((item) => item.sugerida && !item.implementada).length})</Tabs.Tab>
          <Tabs.Tab value="settings">Jogadas customizadas ({data?.filter((item) => !item.sugerida && !item.implementada).length})</Tabs.Tab>
        </Tabs.List>
        <Space h="md" />
        <Tabs.Panel value="gallery">
          {data && data.filter((item) => !item.implementada).map((jogada) => <JogadaDeInovacaoItem key={jogada.id} jogada={jogada} refetch={refetch} formatAutorizacoes={formatAutorizacoes} />)}
        </Tabs.Panel>
        <Tabs.Panel value="messages">
          {data && data?.filter((item) => item.sugerida && !item.implementada).map((jogada) => <JogadaDeInovacaoItem key={jogada.id} jogada={jogada} refetch={refetch} formatAutorizacoes={formatAutorizacoes} />)}
        </Tabs.Panel>
        <Tabs.Panel value="settings">
          {data && data?.filter((item) => !item.sugerida && !item.implementada).map((jogada) => <JogadaDeInovacaoItem key={jogada.id} jogada={jogada} refetch={refetch} formatAutorizacoes={formatAutorizacoes} />)}
        </Tabs.Panel>
      </Tabs>
    </Stack>
  );
};

export default JogadasDeInovacaoList;
