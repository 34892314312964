import { Text, FocusTrap } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import { RowItem } from "./Interface";
import TipoDeInovacaoForm from "../../components/tipo-de-inovacao-form";
import { TTipoInovacaoForm } from "../../firestore";

// prettier-ignore
const AddTipoDeInovacaoFormModal = ({
  context,
  innerProps,
  id
}: ContextModalProps<{ item: TTipoInovacaoForm; setTaticas: React.Dispatch<React.SetStateAction<unknown>>, data: RowItem | false }>) => {
  const { data, setTaticas } = innerProps;
  /**
   * Handle add ignore area success
   */
  function onSuccess() {
    context.closeModal(id);
  }
  return (
    <FocusTrap active={true}>
      <Text fw="bold" mb="md">
        Nova tática
      </Text>
      <TipoDeInovacaoForm data={data === false ? { nome: "", descricao: "" } : data} onSuccess={onSuccess} setTaticas={setTaticas} />
    </FocusTrap>
  );
};

export default AddTipoDeInovacaoFormModal;
