import { subject } from "@casl/ability";
import { Button, Group, Switch, Text } from "@mantine/core";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import ContextMenuRow from "./ContextMenuRow";
import RegulamentoIdeiaStatus from "./RegulamentoIdeiaStatus";

const columnHelper = createColumnHelper<TRegulamentoIdeias>();

const handleOpenRegulamento = (url: string) => () => {
  window.open(url, "_blank");
};

const columns: ColumnDef<TRegulamentoIdeias>[] = [
  columnHelper.accessor("nome", {
    header: "Nome",
    enableSorting: true,
    cell: (properties) => {
      return (
        <Group gap="xs" wrap="nowrap">
          <Text fz="sm">{properties.getValue()}</Text>
          <RegulamentoIdeiaStatus regulamento={properties.row.original} />
        </Group>
      );
    }
  }),
  columnHelper.accessor("dataInicial", {
    header: "Data Inicial",
    enableSorting: true,
    cell: (properties) => {
      return properties.getValue()?.toLocaleDateString();
    }
  }),
  columnHelper.accessor("dataFinal", {
    header: "Data Final",
    enableSorting: true,
    cell: (properties) => {
      return properties.getValue()?.toLocaleDateString();
    }
  }),
  columnHelper.accessor("quantidadeMoedasPorColaborador", {
    header: "Quantidade Moedas",
    enableSorting: true
  }),
  columnHelper.accessor("permitirControlarInteracao", {
    header: "Permite Controlar Interação?",
    enableSorting: false,
    cell: (properties) => {
      const value = properties.getValue();
      return <Switch checked={value} disabled onLabel="Sim" offLabel="Não" size="lg" />;
    }
  }),
  columnHelper.accessor("permitirIdeiaPrivada", {
    header: "Permite Ideia Privada?",
    enableSorting: false,
    cell: (properties) => {
      const value = properties.getValue();
      return <Switch checked={value} disabled onLabel="Sim" offLabel="Não" size="lg" />;
    }
  }),
  columnHelper.display({
    id: "linkRegulamento",
    header: "Link Regulamento",
    cell: (properties) => {
      const { linkRegulamento } = subject("TRegulamentoIdeias", properties.row.original);

      return (
        <Group justify="start" align="center">
          {linkRegulamento && <Button onClick={handleOpenRegulamento(linkRegulamento)}>Acessar Regulamento</Button>}
        </Group>
      );
    }
  }),
  columnHelper.display({
    id: "actions",
    cell: (properties) => {
      const regulamentoIdeias = subject("TRegulamentoIdeias", properties.row.original);

      return (
        <Group justify="center" gap={2}>
          <ContextMenuRow regulamentoIdeias={regulamentoIdeias} />
        </Group>
      );
    }
  })
];

export default columns;

// prettier-ignore
export const regulamentoColumnMobile: ColumnDef<TRegulamentoIdeias>[] = [
  columnHelper.accessor("nome", {
    header: "Nome",
    enableSorting: true,
    cell: (properties) => {
      return (
        <Group gap="xs" wrap="nowrap">
          <Text fz="sm">{properties.getValue()}</Text>
          <RegulamentoIdeiaStatus regulamento={properties.row.original} />
        </Group>
      );
    }
  }),
  columnHelper.accessor("dataInicial", {
    header: "Data Inicial",
    enableSorting: true,
    cell: (properties) => {
      return properties.getValue()?.toLocaleDateString();
    }
  }),
  columnHelper.accessor("dataFinal", {
    header: "Data Final",
    enableSorting: true,
    cell: (properties) => {
      return properties.getValue()?.toLocaleDateString();
    }
  }),
  columnHelper.display({
    id: "actions",
    cell: (properties) => {
      const regulamentoIdeias = subject("TRegulamentoIdeias", properties.row.original);

      return (
        <Group justify="center" gap={2}>
          <ContextMenuRow regulamentoIdeias={regulamentoIdeias} />
        </Group>
      );
    }
  })
];
