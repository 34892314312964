import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { getConvitesEmpresa } from "@/modules/empresas/firestore";

type TGetComitesInovacaoResponse = Awaited<ReturnType<typeof getConvitesEmpresa>>;
const QUERY_KEY: string = "convite-list" as const;
/**
 * Get comites de inovação query hook
 * @returns Comites de inovação query hook
 */
function useQueryConvite() {
  // const uid = useUserStore((store) => store.activeEmpresaId);
  // const uid = getCurrentUser()?.uid;
  const { idEmpresa } = useParams<{ idEmpresa: string }>();
  return useQuery<TGetComitesInovacaoResponse, FirestoreError, TConvite[]>({
    queryKey: [QUERY_KEY, idEmpresa],
    enabled: !!idEmpresa,
    queryFn() {
      if (!idEmpresa) {
        throw new Error("Usuário não autenticado");
      }
      return getConvitesEmpresa(idEmpresa);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((document_) => document_.data());
    }
  });
}

export default useQueryConvite;
