import { Card, Group, ScrollArea, Space, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import EvidenciaEnviadaCard from "@/modules/gerenciar-evidencias/components/EvidenciaEnviadaCard";
import SelecionaEvidencias from "@/modules/gerenciar-evidencias/components/SelecionaEvidencias";
import { getEvidenciasByQuestionarioQuery } from "@/modules/gerenciar-evidencias/firestore";
import useUserStore from "@/modules/users/store";

const GerenciarEvidenciasPage = () => {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const activeProjetoId = useUserStore((state) => state.activeProjeto);
  const reloadConvites = useUserStore((state) => state.reloadConvites);

  const [values, setValues] = useState({ questionario: "", empresaEvidencia: "" });
  const [evidencias, setEvidencias] = useState<TEvidencia[]>([]);
  const [refresh, setRefresh] = useState(false);

  // console.log("*****************************", activeEmpresaId, activeProjetoId, values);

  useEffect(() => {
    const evidenciasSnapshot = getEvidenciasByQuestionarioQuery(
      activeEmpresaId || "",
      activeProjetoId || "",
      values.questionario,
      values.empresaEvidencia
    );
    void evidenciasSnapshot.then((document) => {
      setEvidencias(
        document.docs.map((document_) => document_.data()).sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
      );
      return null;
    });
  }, [values, refresh, activeEmpresaId, activeProjetoId]);

  useEffect(() => {
    setRefresh(!refresh);
  }, [reloadConvites]);

  useEffect(() => {
    setValues({ questionario: "", empresaEvidencia: "" });
  }, [activeProjetoId]);

  const selecaoEhValida =
    activeProjetoId && values.questionario !== "" && values.questionario !== null && values.empresaEvidencia !== "";
  return (
    <>
      <SelecionaEvidencias
        setValues={setValues}
        questionario={values.questionario}
        idEmpresa={activeEmpresaId}
        idProjeto={activeProjetoId || ""}
        empresaEvidencia={values.empresaEvidencia}
      />
      <Space h={"xl"} />
      {selecaoEhValida ? (
        <Card withBorder shadow="md" style={{ flex: 1 }}>
          <Group justify={"space-between"}>
            <Text size="xl">Evidências cadastradas</Text>
          </Group>
          <Space h="md" />
          <ScrollArea w="100%">
            {evidencias?.length ? (
              evidencias
                .filter((evidencia) => evidencia.idQuestionario === values.questionario)
                .map((evidencia) => <EvidenciaEnviadaCard key={evidencia.id} evidencia={evidencia} />)
            ) : (
              <Group align={"center"} justify={"center"}>
                <Space h="md" />
                <Text fw={"bold"}>
                  {values.empresaEvidencia
                    ? `Nenhuma evidência cadastrada!`
                    : `Selecione uma empresa para gerenciar as evidências`}
                </Text>
                <Space h="md" />
              </Group>
            )}
          </ScrollArea>
        </Card>
      ) : (
        <Group justify={"center"}>
          <Text fw={700}>
            {activeProjetoId
              ? `Selecione o questionário e a empresa para gerenciar as evidências`
              : `Selecione um projeto para gerenciar as evidências`}
          </Text>
        </Group>
      )}
      <Space h={"xl"} />
    </>
  );
};

export default GerenciarEvidenciasPage;
