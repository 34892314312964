import { subject } from "@casl/ability";
import { useInfiniteQuery } from "@tanstack/react-query";
import { PaginationState, SortingState } from "@tanstack/react-table";
import { DocumentData, DocumentSnapshot, FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { DESAFIO_COMENTARIOS_COLLECTION_KEY, getComentarios } from "../../firestore";

/**
 * Get the comentários
 * @param desafio - The desafio to get the comentários
 * @param root0 - Pagination options
 * @param root0.sortBy - Sort by field
 * @returns - Query results for get comentários
 */
function useGetComentarios(desafio: TDesafio, { sortBy }: { sortBy: string }) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  const [field, order] = sortBy.split(",");
  const DEFAULT_QUERY_OPTIONS: TPaginatedQueryOptions<TDesafioComentario> = {
    pagination: { pageIndex: 1, pageSize: 3 },
    sorting: [{ id: field || sortBy, desc: order === "desc" }],
    filters: [{ id: "respostaAoComentarioReference", value: ["==", null] }]
  };

  return useInfiniteQuery<TPaginatedData<TDesafioComentario>, FirestoreError>({
    queryKey: [
      activeEmpresaId,
      "desafio",
      desafio.id,
      DESAFIO_COMENTARIOS_COLLECTION_KEY,
      { sortBy },
      DEFAULT_QUERY_OPTIONS
    ],
    initialPageParam: 0,
    meta: {
      displayNotification: true
    },
    getNextPageParam(_lastPage, pages) {
      if (_lastPage.lastDocument === undefined) {
        return undefined;
      }
      const paginationCursors = new Map<
        number,
        [
          DocumentSnapshot<TDesafioComentario, DocumentData> | undefined,
          DocumentSnapshot<TDesafioComentario, DocumentData> | undefined
        ]
      >([]);

      for (const [index, page] of pages.entries()) {
        paginationCursors.set(index, [page.firstDocument, page.lastDocument]);
      }

      const [field, order] = sortBy.split(",");
      const sorting: SortingState = [{ id: field || sortBy, desc: order === "desc" }];
      const pagination: PaginationState = { pageIndex: pages.length, pageSize: 3 };

      const options: TPaginatedQueryOptions<TDesafioComentario> = {
        pagination,
        paginationCursors,
        sorting
      };

      return options;
    },

    async queryFn({ pageParam: pageParameter }) {
      const queryOptions = pageParameter as TPaginatedQueryOptions<TDesafioComentario>;
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      const comentariosResponse = await getComentarios(
        activeEmpresaId,
        desafio.id,
        queryOptions || DEFAULT_QUERY_OPTIONS
      );

      if (comentariosResponse.empty) {
        return {
          entries: [],
          firstDocument: undefined,
          lastDocument: undefined,
          total: 0
        };
      }
      const comentarios = comentariosResponse.docs.map((comentarioData) =>
        subject("TDesafioComentario", comentarioData.data())
      );

      const result: TPaginatedData<TDesafioComentario> = {
        entries: comentarios,
        firstDocument: comentariosResponse.docs[0],
        lastDocument: comentariosResponse.docs.at(-1),
        total: comentarios.length
      };

      return result;
    }
  });
}

export default useGetComentarios;
