import { FileWithPath } from "@mantine/dropzone";
import { ref, uploadBytes } from "firebase/storage";
import storage from "@/src/base/storage";

const MAX_RANDOM = 1_000_000;

/**
 * Upload the anexo file to the storage
 * @param empresaId - Empresa id
 * @param projetoId - Projeto Id
 * @param questionarioId - Questionario id
 * @param anexoToUpload  - The anexo
 * @returns - The public url to the file
 */
export function uploadAnexosFilesToStorage(
  empresaId: TEmpresa["id"],
  projetoId: TProjeto["id"],
  questionarioId: TQuestionario["id"],
  anexoToUpload: FileWithPath
) {
  const fileExtension = anexoToUpload.name.split(".").pop();
  if (!fileExtension) {
    throw new Error("The anexo file has no extension");
  }
  const fileId = `${Math.floor(Math.random() * MAX_RANDOM) + 1}`;
  const fileReference = ref(
    storage,
    `empresas/${empresaId}/evidencias/${projetoId}/${questionarioId}/${fileId}/${anexoToUpload.name}`
  );
  return uploadBytes(fileReference, anexoToUpload);
}
