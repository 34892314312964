import { subject } from "@casl/ability";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { getIdeiaActivitiesPerColaborador } from "../firestore";

type TGetIdeiaActivitiesPerColaboradorResponse = Awaited<ReturnType<typeof getIdeiaActivitiesPerColaborador>>;
type TQueryOptions = Omit<
  UseQueryOptions<TGetIdeiaActivitiesPerColaboradorResponse, FirestoreError, TIdeiaActivityPerColaborador>,
  "queryKey"
>;

/**
 * Get ideia activities per colaborador
 * @param ideia - Ideia to get activities
 * @param colaboradorId - Colaborador ID to get activities from
 * @param options - Query options to use
 * @returns - Query result
 */
function useGetIdeiaActivityByColaborador(
  ideia: TIdeia,
  colaboradorId: TColaborador["id"] | undefined,
  options: TQueryOptions = {}
) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  return useQuery<TGetIdeiaActivitiesPerColaboradorResponse, FirestoreError, TIdeiaActivityPerColaborador | null>({
    queryKey: [activeEmpresaId, "ideia", ideia.id, "activities", colaboradorId],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!colaboradorId) {
        throw new Error("Colaborador não selecionada");
      }

      return getIdeiaActivitiesPerColaborador(activeEmpresaId, ideia.id, colaboradorId);
    },
    select(dataSnapshot) {
      if (!dataSnapshot.exists()) {
        return null;
      }

      return subject("TIdeiaActivityPerColaborador", dataSnapshot.data());
    },
    ...options
  });
}

export default useGetIdeiaActivityByColaborador;
