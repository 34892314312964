import { ThemeIcon, Tooltip } from "@mantine/core";
import { IconCheck, IconHourglass, IconLineDashed, IconLock, IconX } from "@tabler/icons-react";

const ColaboradorActiveStatus = () => {
  return (
    <Tooltip label="Ativo" color="blue" position="right" withArrow>
      <ThemeIcon variant="light" color="green" size="md">
        <IconCheck size="1rem" />
      </ThemeIcon>
    </Tooltip>
  );
};
const ColaboradorInactiveStatus = () => {
  return (
    <Tooltip label="Sem acesso ao sistema" color="blue" position="right" withArrow>
      <ThemeIcon variant="light" color="red" size="md">
        <IconX size="1rem" />
      </ThemeIcon>
    </Tooltip>
  );
};
const ColaboradorDisabledStatus = () => {
  return (
    <Tooltip label="Sem acesso ao sistema" color="blue" position="right" withArrow>
      <ThemeIcon variant="light" color="red" size="md">
        <IconLock size="1rem" />
      </ThemeIcon>
    </Tooltip>
  );
};
const ColaboradorPendingStatus = () => {
  return (
    <Tooltip label="Pendente de confirmação" color="blue" position="right" withArrow>
      <ThemeIcon variant="light" color="gray" size="md">
        <IconHourglass size="1rem" />
      </ThemeIcon>
    </Tooltip>
  );
};

const ColaboradorStatusIcon = ({ status }: { status: TColaborador["status"] }) => {
  switch (status) {
    case "ACTIVE": {
      return <ColaboradorActiveStatus />;
    }
    case "INACTIVE": {
      return <ColaboradorInactiveStatus />;
    }
    case "PENDING": {
      return <ColaboradorPendingStatus />;
    }
    case "DISABLED": {
      return <ColaboradorDisabledStatus />;
    }
    default: {
      return (
        <ThemeIcon variant="light" color="gray" size="md">
          <IconLineDashed />
        </ThemeIcon>
      );
    }
  }
};

export default ColaboradorStatusIcon;
