import { Alert, AlertProps } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { PropsWithChildren } from "react";

const NoData = ({ children, ...alertProperties }: PropsWithChildren<AlertProps>) => {
  return (
    <Alert
      icon={<IconAlertCircle size="1rem" />}
      bg="gray.2"
      color="gray.5"
      variant="outline"
      radius="lg"
      {...alertProperties}
    >
      {children}
    </Alert>
  );
};

export default NoData;
