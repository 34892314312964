import { subject } from "@casl/ability";
import { FirestoreDataConverter, Timestamp, collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { getEmpresaDocumentReference } from "../empresas/firestore";

export const QUERY_KEY = "jogadaDeInovacao";

type TJogadaDeInovacaoDocument = TAuditDocument &
  Omit<TJogadaDeInovacao, keyof TAudit | keyof TAuditDocument | "startedAt" | "pausedAt" | "endedAt"> & {
    nome: string;
    descricao: string;
    createdAt: Timestamp;
    refPath: string;
    deletedAt: Date | null;
    startedAt: Timestamp;
    pausedAt: Timestamp | null;
    endedAt: Timestamp | null;
  };

const jogadaInovacaoConverter: FirestoreDataConverter<TJogadaDeInovacao> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const {
      nome,
      descricao,
      updatedAt,
      deletedAt,
      createdAt,
      endedAt,
      startedAt,
      pausedAt,
      refPath,
      // nomeCategoria,
      tipoDeJogada,
      taticas,
      // nomeTipo,
      // tipo,
      ...document
    } = snap.data() as TJogadaDeInovacaoDocument;

    const data: TJogadaDeInovacao = subject("TJogadaDeInovacao", {
      ...document,
      id: snap.id,
      nome,
      descricao,
      updatedAt,
      deletedAt,
      refPath,
      // nomeCategoria,
      tipoDeJogada,
      taticas,
      // nomeTipo,
      // tipo,
      createdAt: createdAt.toDate(),
      startedAt: startedAt.toDate(),
      endedAt: endedAt ? endedAt.toDate() : null,
      pausedAt: pausedAt ? pausedAt.toDate() : null
    });

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }
    return subject("TJogadaDeInovacao", data);
  }
};

/**
 * Get all jogadas de inovação
 * @param empresaId - Empresa id
 * @returns - jogadas de inovação collection reference
 */
function getJogadasInovacaoCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, "jogadaDeInovacao");
}

/**
 * Get jogadas de inovação query for a empresa
 * @param empresaId - Empresa id to get comitês
 * @returns - jogadas de inovação query
 */
function generateJogadaDeInovacaoQuery(empresaId: TEmpresa["id"]) {
  const jogadasInovacaoCollectionReference =
    getJogadasInovacaoCollectionReference(empresaId).withConverter(jogadaInovacaoConverter);
  return query(jogadasInovacaoCollectionReference, orderBy("startedAt", "desc"), where("deletedAt", "==", null));
}

/**
 * Get jogadas de inovação for a empresa
 * @param empresaId - Empresa id to get comitês
 * @returns - jogadas de inovação document snapshot
 */
export function getJogadasDeInovacao(empresaId: TEmpresa["id"]) {
  const q = generateJogadaDeInovacaoQuery(empresaId);
  return getDocs(q);
}
