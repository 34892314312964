import { useMutation, useQueryClient, UseMutationOptions } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { addLike, removeLike } from "@/modules/ideias-likes/firestore";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";

type TAddLikeResponse = Awaited<ReturnType<typeof addLike>>;
type TRemoveLikeResponse = Awaited<ReturnType<typeof removeLike>>;

type UseIdeiaLikeOptions = {
  ideia: TIdeia;
  onSuccess?: (mutationType: "like" | "unlike") => void;
  onError?: (error: FirestoreError) => void;
} & Omit<UseMutationOptions<TAddLikeResponse, FirestoreError, void>, "mutationFn">;

/**
 * Hook to handle adding and removing likes for an Ideia
 * @param options - Parameters
 * @param options.ideia - Ideia to like or unlike
 * @param [options.onSuccess] - On success callback [optional]
 * @param [options.onError] - On error callback [optional]
 * @returns - Functions to like or unlike and loading state
 */
function useIdeiaLike({ ideia, onSuccess, onError }: UseIdeiaLikeOptions) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const currentUser = useUserStore((state) => state.colaborador);
  const queryClient = useQueryClient();

  const { mutate: likeMutate, isPending: isLikePending } = useMutation<TAddLikeResponse, FirestoreError>({
    mutationFn: async () => {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!currentUser) {
        throw new Error("Usuário não autenticado");
      }
      return await addLike(activeEmpresaId, ideia.id, currentUser);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [activeEmpresaId, "ideia", ideia.id] });
      if (onSuccess) {
        onSuccess("like");
      }
    },
    onError: (error) => {
      captureException(error, true);
      if (onError) {
        captureException(error, true);
      }
    }
  });

  const { mutate: unlikeMutate, isPending: isUnlikePending } = useMutation<TRemoveLikeResponse, FirestoreError>({
    mutationFn: async () => {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!currentUser) {
        throw new Error("Usuário não autenticado");
      }
      return await removeLike(activeEmpresaId, ideia.id, currentUser.id);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [activeEmpresaId, "ideia", ideia.id] });
      if (onSuccess) {
        onSuccess("unlike");
      }
    },
    onError: (error) => {
      captureException(error, true);
      if (onError) {
        captureException(error, true);
      }
    }
  });

  return {
    like: likeMutate,
    unlike: unlikeMutate,
    isPending: isLikePending || isUnlikePending
  };
}

export default useIdeiaLike;
