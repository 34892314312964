/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import { Text, FocusTrap, Button, MultiSelect, Flex, Textarea } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { ContextModalProps } from "@mantine/modals";
import useGetActiveColaboradores from "@/modules/colaboradores/hooks/useGetActiveColaboradores";

import { TMarcoForm } from "../../firestore";
import useIdeiaMarcoForm from "../../hooks/useIdeiaMarcoForm";

const MarcosFormModal = ({
  context,
  id,
  innerProps
}: ContextModalProps<{ ideia: TIdeia; addPathToMovimentacaoCard?: (path: string) => void; marco?: TMarcoForm }>) => {
  const { ideia, marco, addPathToMovimentacaoCard } = innerProps;
  const { getInputProps, onSubmit, isPending } = useIdeiaMarcoForm(ideia, onSuccess, marco);

  /**
   * Handle add ignore area success
   * @param path - Path to add
   */
  function onSuccess(path: string | undefined) {
    if (addPathToMovimentacaoCard && path) {
      addPathToMovimentacaoCard(path);
    }

    context.closeModal(id);
  }

  const { data: colaboradores } = useGetActiveColaboradores({
    select(dataSnapshot) {
      if (dataSnapshot.empty) {
        return [];
      }

      return dataSnapshot.docs.map((document_) => {
        const colaborador = document_.data();
        return {
          value: colaborador.refPath,
          label: colaborador.nome
        };
      });
    }
  });

  return (
    <FocusTrap active={true}>
      <Text fw="bold" mb="md">
        Informe grande marcos do acompanhamento da implantação da ideia
      </Text>
      <form>
        <Flex direction="column" gap="sm" mb="md">
          <MultiSelect
            label="Responsável pelo marco"
            placeholder="Pesquisar"
            data={colaboradores}
            withAsterisk
            maw="35vw"
            searchable
            {...getInputProps("colaboradoresResponsaveis")}
          />
          <DateInput
            label="Selecione uma data"
            placeholder="Selecione uma data para um grande marco"
            maw="30vw"
            valueFormat="DD/MM/YYYY"
            clearable
            withAsterisk
            {...getInputProps("dataPrazo")}
          />
          <Textarea
            label="Descrevas as ações que pretende realizar nesta etapa"
            placeholder="Descrição das ações"
            autosize
            minRows={4}
            maxRows={5}
            withAsterisk
            {...getInputProps("acoes")}
          />
        </Flex>
        <Button onClick={() => onSubmit()} type="button" loading={isPending}>
          Salvar
        </Button>
      </form>
    </FocusTrap>
  );
};

export default MarcosFormModal;
