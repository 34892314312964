import { Text, Card, Group, ThemeIcon, Stack } from "@mantine/core";
import { IconUsers } from "@tabler/icons-react";
import ParticipantesComiteInovacaoAvatars from "../../participantes-comite-inovacao-avatars";

const ParticipantesConvidadosCard = ({ comite }: { comite: TComiteInovacao }) => {
  return (
    <Card withBorder>
      <Group align="flex-start">
        <ThemeIcon size="md" radius="xl" mt={2}>
          <IconUsers width={18} radius="xl" />
        </ThemeIcon>
        <Stack gap={0}>
          <Text fz="lg">Participantes convidados</Text>
          <ParticipantesComiteInovacaoAvatars comite={comite} />
        </Stack>
      </Group>
    </Card>
  );
};

export default ParticipantesConvidadosCard;
