import { Select, Stack } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { useEffect } from "react";
import { useGetMunicipiosByUf } from "@/hooks/ibge/useGetMunicipiosByUf";
import { estadosData } from "@/modules/empresas/schema/endereco-schema";
import schema from "@/schema";
import { yup } from "@/schema/schema";
import { extractTextInputPropertiesFromFormSchema } from "@/utils/form";

const cidadeIdentifierFormSchema = schema.object({
  estado: schema.string().required().label("Estado"),
  cidade: schema.string().required().label("Cidade")
});

type CidadeIdentifierFormType = yup.InferType<typeof cidadeIdentifierFormSchema>;

const defaultForm: CidadeIdentifierFormType = {
  estado: "",
  cidade: ""
};

type Properties = {
  onChange: (cidade: string) => void;
};

/**
 *  Cidade Identifier Form
 * @param root0 - Properties
 * @param root0.onChange - On change cidade
 * @returns - Cidade Identifier Form
 */
export function CidadeIdentifierForm({ onChange }: Properties) {
  const form = useForm<CidadeIdentifierFormType>({
    initialValues: defaultForm,
    validate: yupResolver(cidadeIdentifierFormSchema),
    transformValues: (values) => cidadeIdentifierFormSchema.cast(values)
  });

  const { fetch, municipios } = useGetMunicipiosByUf();

  useEffect(() => {
    const estado = form.values.estado;

    if (estado) {
      void fetch(estado);
    }
  }, [form.values.estado]);

  useEffect(() => {
    const cidade = form.values.cidade;

    if (cidade) {
      onChange(cidade);
    }
  }, [form.values.cidade]);

  const descriptor = cidadeIdentifierFormSchema.describe();

  const municipiosOptions = municipios.map((m) => m.nome);

  return (
    <Stack>
      <Select
        data={estadosData}
        {...form.getInputProps("estado")}
        {...extractTextInputPropertiesFromFormSchema(descriptor, "estado")}
      />
      <Select
        data={municipiosOptions}
        searchable
        {...form.getInputProps("cidade")}
        {...extractTextInputPropertiesFromFormSchema(descriptor, "cidade")}
      />
    </Stack>
  );
}
