import { useMemo } from "react";
import { useLocation } from "react-router-dom";

/**
 * Parse query string
 * @returns - Query string
 */
function useQuery() {
  const { search } = useLocation();
  // eslint-disable-next-line compat/compat
  return useMemo(() => new URLSearchParams(search), [search]);
}

export default useQuery;
