import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { QUERY_KEY } from "@/modules/jogadas-de-inovacao/firestore";
import { getTipoDeJogada } from "@/modules/tipos-de-inovacao/firestore";
import useUserStore from "@/modules/users/store";

type TGetTipoDeInovacaoResponse = Awaited<ReturnType<typeof getTipoDeJogada>>;

/**
 * Get tipos de jogada query hook
 * @returns tipos de jogada query hook
 */
function useQueryTipoDeJogadaSelector() {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  return useQuery<TGetTipoDeInovacaoResponse, FirestoreError, TTipoDeJogada[]>({
    queryKey: ["empresas", { id: activeEmpresaId }, QUERY_KEY],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      return getTipoDeJogada(activeEmpresaId);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((document_) => document_.data());
    }
  });
}

export default useQueryTipoDeJogadaSelector;
