import { Stack } from "@mantine/core";
import { IconId, IconKey } from "@tabler/icons-react";
import { NavLink } from "react-router-dom";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import NavLinkMenu from "./NavLinkMenu";
import Can from "../can";
// prettier-ignore
const AppConfigurationMenu = ({ toggleMobile }: { toggleMobile: () => void }) => {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresa);
  const activeAutorizacao = useUserStore((store) => store.autorizacoes);
  const uid = getCurrentUser()?.uid;
  const { data: userColaborador } = useGetColaboradorById(activeEmpresaId?.id || "", uid || "");
  if (activeEmpresaId && activeEmpresaId?.status === "INATIVO") {
    return <></>;
  }
  const autorizacao = data.find(item => item.label === userColaborador?.role);
  const formatAutorizacoes = activeAutorizacao?.filter(item => item.tipo === autorizacao?.value);

  return (
    <Stack mx="md" mt="lg" gap="xs">
      <NavLinkMenu label="Cadastros" leftSection={<IconId size="1.2rem" stroke={1.5} />}>
        <Can I="read" a="TEmpresa">
          {formatAutorizacoes?.find(item => item.tipoAutorizacao === "1")?.leitura ? <NavLinkMenu label="Empresa" component={NavLink} onClick={toggleMobile} to="/empresas" /> : <></>}
          {formatAutorizacoes?.find(item => item.tipoAutorizacao === "1")?.leitura ? <NavLinkMenu label="Empresa Convidada" component={NavLink} onClick={toggleMobile} to="/empresas-convidadas" /> : <></>}
        </Can>
        <Can I="read" a="TColaborador">
          {formatAutorizacoes?.find(item => item.tipoAutorizacao === "2")?.leitura ? <NavLinkMenu label="Colaborador" component={NavLink} onClick={toggleMobile} to="/colaboradores" /> : <></>}
        </Can>

      </NavLinkMenu>

      {userColaborador?.role === "ADMIN" ? <NavLinkMenu
        label="Autorizações"
        leftSection={<IconKey size="1.2rem" stroke={1.5} />}
        component={NavLink}
        onClick={toggleMobile}
        to="/autorizacoes"
      /> : <></>}

    </Stack>
  );
};

export default AppConfigurationMenu;

const data = [
  { value: "1", label: "ADMIN" },
  { value: "2", label: "MEMBER" },
  { value: "3", label: "TEAM" },
  { value: "4", label: "MASTER" },
  { value: "5", label: "COLLABORATOR" }
];
