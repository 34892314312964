import { Center, Space, Stack, Tabs, Text } from "@mantine/core";
import { QueryObserverResult } from "@tanstack/react-query";
import NoData from "@/components/no-data";
import HomeDeInovacaoHistoryLoading from "./HomeDeInovacaoHistoryLoading";
import useQueryJogadaDeInovacao from "./useQueryJogadaDeInovacao";
import JogadaDeInovacaoItemModal from "../home-de-inovacao-card-modal";
import { useAddJogadaDeInovacao } from "../home-de-inovacao-card-modal/useAddJogada";
// prettier-ignore
const JogadasDeInovacaoListModal = ({ onSuccess, refetchList }: { onSuccess: () => void, refetchList: () => Promise<QueryObserverResult>; }) => {
  const { data, isLoading, refetch, isFetching } = useQueryJogadaDeInovacao();
  const { mutate } = useAddJogadaDeInovacao();
  if (data?.length === 0) {
    return (
      <Center m="lg">
        <NoData m="md">
          <Stack gap="xs">
            <Text c="dimmed">Nenhum tipo de inovação encontrado!</Text>
          </Stack>
        </NoData>
      </Center>
    );
  }

  const handleImplement = (jogada: TJogadaDeInovacao) => {
    return () => {
      delete jogada.refPath;
      mutate({ ...jogada, implementada: true, cancelado: false, concluido: false });
      onSuccess();
      void refetchList();
    };
  };

  return (
    <Stack gap="xs" mt="md">
      {(isLoading || isFetching) && (
        <>
          <HomeDeInovacaoHistoryLoading />
          <HomeDeInovacaoHistoryLoading />
          <HomeDeInovacaoHistoryLoading />
        </>
      )}
      <Tabs defaultValue="gallery">
        <Tabs.List>
          <Tabs.Tab value="gallery">Todos</Tabs.Tab>
          <Tabs.Tab value="messages">Jogadas sugeridas ({data?.filter((item) => !item.implementada && item.sugerida)?.length})</Tabs.Tab>
          <Tabs.Tab value="settings">Jogadas customizadas ({data?.filter((item) => !item.implementada && !item.sugerida)?.length})</Tabs.Tab>
        </Tabs.List>
        <Space h="md" />
        <Tabs.Panel value="gallery">
          {data && data.filter((item) => !item.implementada).map((jogada) => <JogadaDeInovacaoItemModal handleImplement={handleImplement} key={jogada.id} jogada={jogada} refetch={refetch} />)}
        </Tabs.Panel>
        <Tabs.Panel value="messages">
          {data && data.filter((item) => !item.implementada && item.sugerida).map((jogada) => <JogadaDeInovacaoItemModal handleImplement={handleImplement} key={jogada.id} jogada={jogada} refetch={refetch} />)}
        </Tabs.Panel>
        <Tabs.Panel value="settings">
          {data && data.filter((item) => !item.implementada && !item.sugerida).map((jogada) => <JogadaDeInovacaoItemModal handleImplement={handleImplement} key={jogada.id} jogada={jogada} refetch={refetch} />)}
        </Tabs.Panel>
      </Tabs>
    </Stack>
  );
};

export default JogadasDeInovacaoListModal;
