import { Flex, Group, Space, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import useGetEmpresaById from "@/modules/analisar-resposta/components/ranking-list/useGetEmpresas";
import { getRespostasMediaEmpresa } from "@/modules/analisar-respostas/firestore";
import { getQuestionarios } from "@/modules/grupos-diagnostico/firestore";
import useGetConvite from "./useGetEmpresasConvidadas";
// import { useParams } from "react-router-dom";

const message = "";

// prettier-ignore
const QuestionariosRespondidos = ({ idProjeto, idEmpresa, size, fw }: { idProjeto: TProjeto["id"] | undefined, idEmpresa: TEmpresa["id"] | undefined, size: string, fw: number }) => {
  const { data: empresa } = useGetEmpresaById(idEmpresa);
  const newEmpresa = formatEmpresa(empresa, idProjeto);
  // const { data: respondidos } = useGetQuestionariosRespondidos(idEmpresa, data?.map(item => item?.id));
  const { data: convites } = useGetConvite(idEmpresa, idProjeto, false);
  const space = "space-between";
  const [respondidos, setRespondidos] = useState<TResposta[]>([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadData();
      } catch (error) {
        console.error(message, error);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
    // eslint-disable-next-line unicorn/explicit-length-check
  }, [idProjeto, convites && convites?.length])

  const loadData = async () => {
    let newArray = [] as [];
    for (const item of [...convites || [], newEmpresa] as TConvite[]) {
      if (item.idEmpresaColaboradorConvidado) continue;
      const newData = await getRespostasMediaEmpresa(item.idEmpresaColaboradorConvidado);
      const format = newData.docs.map((resposta) => resposta.data()) || [] as TResposta[];
      const newQuestionarios = await getQuestionarios(idEmpresa || "", idProjeto || "")
      const formarNewQuestionarios = newQuestionarios?.docs?.map((resposta) => resposta.data()) || [] as TQuestionario[];
      newArray = [...newArray, ...format.filter(item => formarNewQuestionarios.some(row => row.id === item.idQuestionario)) as []]
    }
    setRespondidos(newArray)

  }

  return (
    <Flex direction="column">
      <Text size="xs">Questionários respondidos</Text>
      <Space h="md" />
      <Group justify={space}>
        <Text size={size} fw={fw}>
          {[...new Set(respondidos.map(item => item.idQuestionario))]?.length || 0}
        </Text>
      </Group>
    </Flex>
  );
};

export default QuestionariosRespondidos;

// prettier-ignore
const formatEmpresa = (empresa: TEmpresa | null | undefined, idProjeto: string | null | undefined) => {
  return {
    convite: false,
    createdAt: new Date(),
    createdBy: "",
    id: empresa?.id,
    idColaboradorConvidado: "",
    idEmpresa: empresa?.id,
    idEmpresaColaboradorConvidado: empresa?.id,
    idProjeto: idProjeto,
    idQuestionario: ""
  } as TConvite;
}
