import { Alert, Button, Group, MultiSelect, NumberInput, Stack, TextInput } from "@mantine/core";
import { DatePickerInput, DateValue } from "@mantine/dates";
import { TransformedValues, UseFormReturnType, useForm, yupResolver } from "@mantine/form";
import { IconAlertCircle } from "@tabler/icons-react";
import { useMemo } from "react";
import useUserStore from "@/modules/users/store";
import { extractTextInputPropertiesFromFormSchema } from "@/utils/form";
import { TCampanhaRegulamentoIdeiasForm } from "../../firestore";
import schema from "../../schema";

/**
 * CampanhaRegulamentoIdeias form hook
 * @param campanhaRegulamentoIdeias - CampanhaRegulamentoIdeias values
 * @returns - Form
 */
function useCampanhaRegulamentoIdeiasForm(campanhaRegulamentoIdeias: TCampanhaRegulamentoIdeiasForm) {
  const initialValues: TCampanhaRegulamentoIdeiasForm = {
    ...(schema.getDefault() as TCampanhaRegulamentoIdeiasForm),
    ...campanhaRegulamentoIdeias
  };
  const form = useForm<TCampanhaRegulamentoIdeiasForm>({
    initialValues,
    validate: yupResolver(schema),
    transformValues: (values) => schema.cast(values, { stripUnknown: true })
  });

  return { form };
}

const CampanhaRegulamentoIdeiasForm = ({
  campanhaRegulamentoIdeias,
  onSubmit
}: {
  campanhaRegulamentoIdeias: TCampanhaRegulamentoIdeiasForm;
  onSubmit: (values: TCampanhaRegulamentoIdeiasForm, form: UseFormReturnType<TCampanhaRegulamentoIdeiasForm>) => void;
}) => {
  const activeEmpresa = useUserStore((state) => state.activeEmpresa);
  const { form } = useCampanhaRegulamentoIdeiasForm(campanhaRegulamentoIdeias);

  const schemaFieldsDescriptor = schema.describe();
  const genericError = form.errors[""];

  /**
   * Handle período date change
   * @param dates - [dataInicial, dataFinal]
   */
  function onDateIniciaFinalChange(dates: [Date, Date]) {
    const [dataInicial, dataFinal] = dates;
    form.setFieldValue("dataInicial", dataInicial);
    form.setFieldValue("dataFinal", dataFinal);
  }
  const periodo: [DateValue, DateValue] = useMemo<[DateValue, DateValue]>(
    () => [form.values.dataInicial, form.values.dataFinal],
    [form.values.dataFinal, form.values.dataInicial]
  );

  const today = new Date();

  type TFormInputs = TransformedValues<typeof form>;

  /**
   * Handle form submit
   * @param values - Form values to submit
   */
  function handleSubmit(values: TFormInputs) {
    onSubmit(values, form);
    form.reset();
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <TextInput
          type="text"
          style={{ flex: 1 }}
          data-autofocus
          {...form.getInputProps("descricao")}
          {...extractTextInputPropertiesFromFormSchema(schemaFieldsDescriptor, "descricao")}
        />

        <DatePickerInput
          style={{ flex: 1 }}
          type="range"
          minDate={today}
          valueFormat="DD [de] MMMM, YYYY"
          value={periodo}
          onChange={onDateIniciaFinalChange}
          {...extractTextInputPropertiesFromFormSchema(schemaFieldsDescriptor, "dataInicial")}
        />

        <MultiSelect
          data={activeEmpresa?.setores}
          style={{ flex: 1 }}
          {...form.getInputProps("setores")}
          {...extractTextInputPropertiesFromFormSchema(schemaFieldsDescriptor, "setores")}
        />

        <Group grow>
          <NumberInput
            allowNegative={false}
            min={0}
            {...form.getInputProps("quantidadeGanhoNoCadastro")}
            {...extractTextInputPropertiesFromFormSchema(schemaFieldsDescriptor, "quantidadeGanhoNoCadastro")}
          />
          <NumberInput
            allowNegative={false}
            min={0}
            {...form.getInputProps("quantidadeGanhoNaAprovacao")}
            {...extractTextInputPropertiesFromFormSchema(schemaFieldsDescriptor, "quantidadeGanhoNaAprovacao")}
          />
          <NumberInput
            allowNegative={false}
            min={0}
            {...form.getInputProps("quantidadeGanhoNaImplementacao")}
            {...extractTextInputPropertiesFromFormSchema(schemaFieldsDescriptor, "quantidadeGanhoNaImplementacao")}
          />
        </Group>
        <Group grow>
          <NumberInput
            allowNegative={false}
            min={0}
            {...form.getInputProps("quantidadeGanhoNaParticipacaoEImplementacaoIdeiaPropria")}
            {...extractTextInputPropertiesFromFormSchema(
              schemaFieldsDescriptor,
              "quantidadeGanhoNaParticipacaoEImplementacaoIdeiaPropria"
            )}
          />
          <NumberInput
            allowNegative={false}
            min={0}
            {...form.getInputProps("quantidadeGanhoNaParticipacaoEImplementacaoIdeiaTerceiro")}
            {...extractTextInputPropertiesFromFormSchema(
              schemaFieldsDescriptor,
              "quantidadeGanhoNaParticipacaoEImplementacaoIdeiaTerceiro"
            )}
          />
        </Group>
      </Stack>
      <Group justify={genericError ? "space-between" : "flex-end"} mt="md">
        {genericError && (
          <Alert icon={<IconAlertCircle size="1rem" />} color="red">
            {genericError}
          </Alert>
        )}
        <Button type="submit">{campanhaRegulamentoIdeias.id ? "Salvar" : "Adicionar"}</Button>
      </Group>
    </form>
  );
};

export default CampanhaRegulamentoIdeiasForm;
