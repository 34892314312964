import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { getQuestionariosArray } from "@/modules/grupos-diagnostico/firestore";
import { QUERY_KEY } from "@/modules/questionarios/components/questionario-table/QuestionarioTable";

type TGetQuestionarioResponse = Awaited<ReturnType<typeof getQuestionariosArray>>;

/**
 * Get questionário de inovação query hook
 * @param data - data
 * @param data.data - data
 * @returns Questionário de inovação query hook
 */
function useQueryQuestionarioConvite({ data }: { data: TConvite | undefined }) {
  const activeEmpresaId = (data && data?.idEmpresa) || "";
  const idProjeto = data && data?.idProjeto;
  const { id } = useParams<{ id: string }>();

  return useQuery<TGetQuestionarioResponse, FirestoreError, TQuestionario[]>({
    queryKey: ["empresas", { id: activeEmpresaId }, QUERY_KEY, id, idProjeto],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      return getQuestionariosArray(activeEmpresaId, [id || "", idProjeto || ""]);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }
      return dataResponse.docs.map((document_) => document_.data());
    }
  });
}

export default useQueryQuestionarioConvite;
