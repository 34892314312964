import { subject } from "@casl/ability";
import {
  DocumentReference,
  FirestoreDataConverter,
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where
} from "firebase/firestore";
import { TColaboradorDocument } from "../colaboradores/firestore";
import { addComiteInovacaoActivity } from "../comite-inovacao-activities/firestore";
import { getEmpresaDocumentReference } from "../empresas/firestore";

export const QUERY_KEY = "comitesInovacao";

type TComiteInovacaoDocument = TAuditDocument &
  Omit<
    TComiteInovacao,
    | keyof TAudit
    | keyof TAuditDocument
    | "startedAt"
    | "pausedAt"
    | "endedAt"
    | "participantesReferences"
    | "ideiasReferences"
  > & {
    startedAt: Timestamp;
    pausedAt: Timestamp | null;
    endedAt: Timestamp | null;
    participantesReferences: DocumentReference<TColaborador, TColaboradorDocument>[];
    ideiasReferences: DocumentReference<TIdeia>[];
  };

export type TTComiteInovacaoDatabaseFields = Pick<TAuditForm, "createdBy" | "updatedBy" | "createdAt" | "updatedAt"> & {
  startedAt: ReturnType<typeof serverTimestamp>;
  pausedAt: ReturnType<typeof serverTimestamp> | null;
  endedAt: ReturnType<typeof serverTimestamp> | null;
};

export type TComiteInovacaoFormFields = Omit<
  TComiteInovacao,
  | "id"
  | "refPath"
  | "createdAt"
  | "updatedAt"
  | "createdBy"
  | "updatedBy"
  | "startedAt"
  | "pausedAt"
  | "endedAt"
  | "participantesReferences"
  | "ideiasReferences"
> &
  Partial<Pick<TComiteInovacao, "id" | "refPath">> & {
    participantesReferences: DocumentReference<TColaborador, TColaboradorDocument>[];
    ideiasReferences: DocumentReference<TIdeia>[];
  };

export type TComiteInovacaoForm = TComiteInovacaoFormFields & TTComiteInovacaoDatabaseFields;

const comiteInovacaoConverter: FirestoreDataConverter<TComiteInovacao> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    // delete data.__caslSubjectType__;
    return data;
  },
  fromFirestore(snap) {
    const {
      createdAt,
      updatedAt,
      startedAt,
      endedAt,
      pausedAt,
      participantesReferences,
      ideiasReferences,
      ...document
    } = snap.data() as TComiteInovacaoDocument;

    const data: TComiteInovacao = subject("TComiteInovacao", {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      startedAt: startedAt.toDate(),
      endedAt: endedAt ? endedAt.toDate() : null,
      pausedAt: pausedAt ? pausedAt.toDate() : null,
      participantesReferences: participantesReferences.map((p) => p.path),
      ideiasReferences: ideiasReferences.map((ideia) => ideia.path),
      refPath: snap.ref.path
    });

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }
    return data;
  }
};

/**
 * Get all comitês
 * @param empresaId - Empresa id
 * @returns - Comitês collection reference
 */
function getComitesInovacaoCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, "comitesInovacao");
}

/**
 * Get a comitê de inovação document reference
 * @param empresaId - Empresa id
 * @param comiteInovacaoId - Comitê de inovação id
 * @returns - Comitê de inovação document reference
 */
export function getComiteInovacaoDocumentReference(empresaId: TEmpresa["id"], comiteInovacaoId: TComiteInovacao["id"]) {
  const comiteInovacaoCollectionReference = getComitesInovacaoCollectionReference(empresaId);
  return doc(comiteInovacaoCollectionReference, comiteInovacaoId);
}

/**
 * Start a new comitê de inovação for a empresa
 * @param empresaId - Empresa id to start a new comitê
 * @param values - Comitê de inovação form fields
 * @returns - Comitê de inovação document reference
 */
export function startComiteDeInovacao(empresaId: TEmpresa["id"], values: TComiteInovacaoFormFields) {
  const comitesInovacaoCollectionReference = getComitesInovacaoCollectionReference(empresaId);
  return addDoc(comitesInovacaoCollectionReference, {
    ...values,
    updatedAt: serverTimestamp()
  });
}

/**
 * Pause a comitê de inovação for a empresa
 * @param empresaId - Empresa id to start a new comitê
 * @param comiteInovacaoId - Comitê de inovação id to pause
 * @returns - Comitê de inovação document reference
 */
export function pauseComiteDeInovacao(empresaId: TEmpresa["id"], comiteInovacaoId: TComiteInovacao["id"]) {
  const comitesInovacaoDocumentReference = getComiteInovacaoDocumentReference(empresaId, comiteInovacaoId);

  const addPromise = addComiteInovacaoActivity(empresaId, comiteInovacaoId, {
    type: "PAUSADO"
  });

  const updatePromise = updateDoc(comitesInovacaoDocumentReference, {
    pausedAt: serverTimestamp(),
    updatedAt: serverTimestamp()
  });

  // eslint-disable-next-line compat/compat
  return Promise.all([addPromise, updatePromise]);
}

/**
 * Continue a comitê de inovação for a empresa
 * @param empresaId - Empresa id to continue the comitê
 * @param comiteInovacaoId - Comitê de inovação id to continue
 * @returns - Comitê de inovação document reference
 */
export function continueComiteDeInovacao(empresaId: TEmpresa["id"], comiteInovacaoId: TComiteInovacao["id"]) {
  const comitesInovacaoDocumentReference = getComiteInovacaoDocumentReference(empresaId, comiteInovacaoId);

  const addPromise = addComiteInovacaoActivity(empresaId, comiteInovacaoId, {
    type: "REINICIADO"
  });

  const updatePromise = updateDoc(comitesInovacaoDocumentReference, {
    pausedAt: null,
    updatedAt: serverTimestamp()
  });

  // eslint-disable-next-line compat/compat
  return Promise.all([addPromise, updatePromise]);
}

/**
 * Finalizar a comitê de inovação for a empresa
 * @param empresaId - Empresa id to finalizar the comitê
 * @param comiteInovacaoId - Comitê de inovação id to finalizar
 * @returns - Comitê de inovação document reference
 */
export function finalizarComiteDeInovacao(empresaId: TEmpresa["id"], comiteInovacaoId: TComiteInovacao["id"]) {
  const comitesInovacaoDocumentReference = getComiteInovacaoDocumentReference(empresaId, comiteInovacaoId);

  const addPromise = addComiteInovacaoActivity(empresaId, comiteInovacaoId, {
    type: "FINALIZADO"
  });

  const updatePromise = updateDoc(comitesInovacaoDocumentReference, {
    pausedAt: null,
    endedAt: serverTimestamp(),
    updatedAt: serverTimestamp()
  });

  // eslint-disable-next-line compat/compat
  return Promise.all([addPromise, updatePromise]);
}

/**
 * Get started comitê de inovação query
 * @param empresaId - Empresa id
 * @returns - Comitê de inovação document reference
 */
function queryComitesInovacaoEmAndamento(empresaId: TEmpresa["id"]) {
  const comitesInovacaoCollectionReference =
    getComitesInovacaoCollectionReference(empresaId).withConverter(comiteInovacaoConverter);
  return query(
    comitesInovacaoCollectionReference,
    where("endedAt", "==", null),
    orderBy("startedAt", "desc"),
    limit(1)
  );
}

const DEFAULT_COMITES_LIMIT = 10;
/**
 * Get comitês de inovação query for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param totalComites - Limit of comitês to get (default: 10)
 * @returns - Comitês de inovação query
 */
function generateComitesInovacaoQuery(empresaId: TEmpresa["id"], totalComites: number = DEFAULT_COMITES_LIMIT) {
  const comitesInovacaoCollectionReference =
    getComitesInovacaoCollectionReference(empresaId).withConverter(comiteInovacaoConverter);
  return query(comitesInovacaoCollectionReference, orderBy("startedAt", "desc"), limit(totalComites));
}

/**
 * Get comitês de inovação for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param totalComites - Limit of comitês to get (default: 10)
 * @returns - Comitês de inovação document snapshot
 */
export function getComitesInovacao(empresaId: TEmpresa["id"], totalComites: number = DEFAULT_COMITES_LIMIT) {
  const q = generateComitesInovacaoQuery(empresaId, totalComites);
  return getDocs(q);
}

/**
 * Get comitê de inovação by id
 * @param empresaId - Empresa id
 * @param comiteInovacaoId - Comitê de inovação id
 * @returns - Comitê de inovação document snapshot
 */
export function getComiteInovacao(empresaId: TEmpresa["id"], comiteInovacaoId: TComiteInovacao["id"]) {
  const comiteInovacaoDocumentReference = getComiteInovacaoDocumentReference(empresaId, comiteInovacaoId);
  return getDoc(comiteInovacaoDocumentReference.withConverter(comiteInovacaoConverter));
}

/**
 * Get started comitê de inovação document snapshot
 * @param empresaId - Empresa id
 * @returns - Comitê de inovação document snapshot
 */
export function getComiteDeInovacaoEmAndamentoDocumentSnapshot(empresaId: TEmpresa["id"]) {
  const startedComiteDeInovacaoDocumentReference = queryComitesInovacaoEmAndamento(empresaId);
  return getDocs(startedComiteDeInovacaoDocumentReference);
}
