import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { FirebaseError } from "firebase/app";
import { useEffect } from "react";
import { signInUserWithCustomToken } from "@/services/auth";

type SignInUserWithCustomTokenResponse = Awaited<ReturnType<typeof signInUserWithCustomToken>>;

/**
 * Mutation Hook that signIn user with custom token
 * @param token - The custom token
 * @param options - The mutation options
 * @returns - Mutation Hook
 */
function useSignInWithCustomToken(
  token: string | null,
  options: Pick<
    UseMutationOptions<SignInUserWithCustomTokenResponse, FirebaseError, string>,
    "onSuccess" | "onError" | "onSettled"
  > = {}
) {
  const mutation = useMutation<SignInUserWithCustomTokenResponse, FirebaseError, string>({
    mutationFn: (customToken) => signInUserWithCustomToken(customToken),
    ...options
  });

  const { mutate: signIn } = mutation;

  useEffect(() => {
    if (token) signIn(token);
  }, [signIn, token]);

  return mutation;
}

export default useSignInWithCustomToken;
