import { Button, Card, Group, ScrollArea, Space, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { useEffect, useState } from "react";
import useQueryConvite from "@/modules/diagnosticos/components/diagnostico-list/useQueryConvite";
import ConviteRespostaCard from "@/modules/questionario-de-inovacao/components/questionario-item/ConviteRespostaCard";
import useGetConvitesProjeto from "@/modules/questionario-empresa/hooks/useGetConvitesProjeto";
import RelatorioComponent from "@/modules/relatorio/components/relatorio-item";
import useQueryQuestionarioConvite from "@/modules/responder-questionario-list/components/questionario-list/useQueryQuestionarioConvite";
import useUserStore from "@/modules/users/store";

const ConvidarColaboradoresExternosPage = () => {
  const activeLicenciadaId = useUserStore((state) => state.activeLicenciadaId);
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const reloadConvites = useUserStore((state) => state.reloadConvites);
  const [values, setValues] = useState({ questionario: "" });
  const [loading, setLoading] = useState(true);
  const [empresaId, setEmpresaId] = useState("");
  const [projetoId, setProjetoId] = useState("");

  const { data: convite } = useQueryConvite();
  const { data: questionariosConvite } = useQueryQuestionarioConvite({ data: convite && convite[0] });

  const { data: convites, refetch } = useGetConvitesProjeto(projetoId, empresaId, activeEmpresaId?.toString() || "");

  useEffect(() => {
    if (loading && questionariosConvite && questionariosConvite[0]) {
      setLoading(false);
      setProjetoId(questionariosConvite[0]?.idProjeto);
      if (convite && convite[0]) setEmpresaId(convite[0]?.idEmpresa);
    }
  }, [questionariosConvite]);

  useEffect(() => {
    void refetch();
  }, [reloadConvites]);

  return (
    <>
      <RelatorioComponent
        setValues={setValues}
        questionario={values.questionario}
        idEmpresa={empresaId}
        idProjeto={projetoId}
      />
      <Space h={"xl"} />
      {projetoId && values.questionario !== "" && values.questionario !== null ? (
        <Card withBorder shadow="md" style={{ flex: 1 }}>
          <Group justify={"space-between"}>
            <Text size="xl">Como estão as participações?</Text>
            <Button
              onClick={handleClick(values.questionario, empresaId, projetoId, activeLicenciadaId)}
              color="rgba(8, 68, 244, 1)"
            >
              Convidar colaboradores
            </Button>
          </Group>
          <Space h="md" />
          <ScrollArea w="100%">
            {convites?.length ? (
              convites
                .filter((convite) => convite.convite)
                .map((convite) => <ConviteRespostaCard key={convite.id} convite={convite} />)
            ) : (
              <Group align={"center"} justify={"center"}>
                <Space h="md" />
                <Text fw={"bold"}>Nenhum colaborador convidado!</Text>
                <Space h="md" />
              </Group>
            )}
          </ScrollArea>
        </Card>
      ) : (
        <Group justify={"center"}>
          <Text fw={700}>Selecione um projeto e um questionário para convidar colaboradores!</Text>
        </Group>
      )}
      <Space h={"xl"} />
    </>
  );
};

export default ConvidarColaboradoresExternosPage;

/**
 * Handles the click event on the button.
 * @param id - The id questions.
 * @param idEmpresa - The id company.
 * @param idProjeto - The id project.
 * @param licenciadaId - Id da Licenciada
 * @returns - The return the click.
 */
function handleClick(
  id: string = "",
  idEmpresa: string = "",
  idProjeto: string = "",
  licenciadaId: string | undefined
) {
  return (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    modals.openContextModal({
      size: "xl",
      innerProps: {
        idQuestionario: id,
        idEmpresa,
        idProjeto,
        licenciadaId
      },
      modal: "convidar-colaborador-modal"
    });
  };
}
