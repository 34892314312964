import { TransformedValues, useForm, yupResolver } from "@mantine/form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { captureException } from "@/services/log";
import { getDocumentReferenceFromPath } from "@/utils/firestore";
import {
  DESAFIO_COMENTARIOS_COLLECTION_KEY,
  TDesafioComentarioForm,
  addComentario,
  desafioComentarioConverter
} from "../../firestore";
import desafioComentarioSchema from "../../schema";

type TAddComentarioResponse = Awaited<ReturnType<typeof addComentario>>;

type Options = {
  desafio: TDesafio;
  respostaAoComentario?: TDesafioComentario | null;
  onSuccess?: () => void;
};

/**
 * Hook to handle desafio comentario form
 * @param options - Hook options
 * @param options.desafio - Desafio to comment
 * @param options.onSuccess - Function to be called on success
 * @param [options.respostaAoComentario] - Comment to reply to
 * @returns - Desafio comentario form
 */
function useDesafioComentarioForm({ desafio, respostaAoComentario, onSuccess }: Options) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const queryClient = useQueryClient();

  const form = useForm<TDesafioComentarioForm>({
    initialValues: desafioComentarioSchema.getDefault() as TDesafioComentarioForm,
    transformValues: (values) =>
      desafioComentarioSchema.cast(values, {
        stripUnknown: true,
        context: {
          desafioId: desafio.id
        }
      }),
    validate: yupResolver(desafioComentarioSchema)
  });

  const { mutate, reset, isPending } = useMutation<TAddComentarioResponse, FirestoreError, TDesafioComentarioForm>({
    mutationFn: (values) => {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return addComentario(activeEmpresaId, desafio.id, values);
    },
    async onSuccess() {
      reset();
      form.reset();
      await queryClient.invalidateQueries({
        queryKey: [activeEmpresaId, "desafio", desafio.id, DESAFIO_COMENTARIOS_COLLECTION_KEY]
      });
      if (onSuccess) {
        onSuccess();
      }
    },
    onError(error) {
      captureException(error, true);
    }
  });

  type TTransformedValues = TransformedValues<typeof form>;
  /**
   * Handle form submit
   * @param values - Form values
   */
  function onSubmit(values: TTransformedValues) {
    const respostaAoComentarioReference = respostaAoComentario?.refPath
      ? getDocumentReferenceFromPath(respostaAoComentario?.refPath, desafioComentarioConverter)
      : null;
    mutate({ ...values, respostaAoComentarioReference });
  }

  return { form, isPending, onSubmit: form.onSubmit(onSubmit) };
}

export default useDesafioComentarioForm;
