import { Card, Group, Text } from "@mantine/core";
import useGetConvites from "@/components/app-container/useGetConvites";
import useQueryConvite from "@/modules/diagnosticos/components/diagnostico-list/useQueryConvite";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import useGetStatusIdEmpresa from "./useGetStatusIdEmpresa";
import useQueryQuestionario from "./useQueryQuestionario";
import useQueryQuestionarioConvite from "./useQueryQuestionarioConvite";
import QuestionarioItem from "../questionario-item";

interface Fase {
  id: string;
  titulo: string;
  cor: string;
  checked?: boolean;
  // status: Status[];
}

// prettier-ignore
const QuestionarioDiagnosticoList = () => {
  const { data: convites } = useGetConvites();
  const { data } = useQueryConvite();
  const { data: questionarios } = useQueryQuestionario();
  const { data: questionariosConvite } = useQueryQuestionarioConvite({ data: data && data[0] });
  const { data: statusArray } = useGetStatusIdEmpresa({ idEmpresaItem: data && data[0]?.idEmpresa || "", id: data && data[0]?.idProjeto || "" });
  const arrayQuestionario = filtrarQuestionarios([
    ...(questionariosConvite || []),
    ...(questionarios || [])
  ], statusArray);
  const projeto = useUserStore((state) => state.activeProjeto);
  const colaboradorId = getCurrentUser()?.uid;
  // eslint-disable-next-line no-console
  console.log("**** desafios", { convites, data, questionarios, questionariosConvite, statusArray, arrayQuestionario, projeto, colaboradorId });

  return (
    <Card withBorder shadow="md" style={{ flex: 1, overflow: "auto" }}>
      {arrayQuestionario && arrayQuestionario.length === 0 && data && data.some(item => item.idQuestionario === "") ? <Group justify={"center"}>
        <Text fw={700}>Aguarde a fase de formulário</Text>
      </Group> : arrayQuestionario && arrayQuestionario.length === 0 ? <Group justify={"center"}>
        <Text fw={700}>Nenhum questionário para você responder!</Text>
      </Group> : arrayQuestionario?.filter(item => projeto ? item.idProjeto === projeto : convites && convites.length > 0 ? convites[0]?.idProjeto === item.idProjeto || colaboradorId === item.createdBy : item).map((questionario) => <QuestionarioItem key={questionario.id} questionario={questionario} />)}
    </Card>
  );
};

export default QuestionarioDiagnosticoList;

/**
 * Get questionarios unicos
 * @param array - array de questionario
 * @param status - status
 * @returns - array de questionarios unicos
 */
// prettier-ignore
function filtrarQuestionarios(array: TQuestionario[], status: TStatus[] | undefined) {
  const uniqueQuestionarios: TQuestionario[] = [];

  for (const questionario of array) {
    const isDuplicated = uniqueQuestionarios.some(
      (q) => q.idProjeto === questionario.idProjeto && q.id === questionario.id
    );

    if (!isDuplicated) {
      uniqueQuestionarios.push(questionario);
    }
  }

  const arrayInvite = uniqueQuestionarios.filter(item => {
    const statusVerification = status?.find(row => row.idProjeto === item.idProjeto)
    return item && item.fases && item.fases.length > 0 ? item.fases.filter((data: Fase) => data && data?.id === statusVerification?.status).length : false
  })

  return status && status?.length > 0 ? arrayInvite : uniqueQuestionarios;
}
