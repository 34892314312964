import { Stack, Card, Group, Skeleton } from "@mantine/core";

const IdeiaComentarioLoadingCard = () => (
  <Stack gap="xs" mt="md">
    {Array.from({ length: 3 }).map((_, index) => (
      <Card key={index}>
        <Group gap="sm" align="start">
          <Skeleton height={50} circle mb="xl" />
          <Stack gap="xs" style={{ flex: 1 }}>
            <Stack gap={0}>
              <Group justify="space-between" gap={0}>
                <Skeleton height={8} mt={6} width="50%" />
                <Skeleton height={8} mt={6} width="10%" />
              </Group>
              <Skeleton height={8} mt={6} width="10%" />
            </Stack>
            <Skeleton height={8} mt={10} />
            <Skeleton height={8} width="60%" />
          </Stack>
        </Group>
      </Card>
    ))}
  </Stack>
);

export default IdeiaComentarioLoadingCard;
