/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Group,
  List,
  LoadingOverlay,
  NumberInput,
  Select,
  Space,
  Stack,
  TagsInput,
  Text,
  TextInput,
  Tooltip
} from "@mantine/core";
import { DatePickerInput, DateValue } from "@mantine/dates";
import { FileWithPath, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { showNotification } from "@mantine/notifications";
import { IconAlertCircle, IconPlus } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import FileSelectorDropzone from "@/components/file-selector-dropzone";
import FileItem from "@/components/file-selector-dropzone-projeto/FileItem";
import useUserStore from "@/modules/users/store";
import { extractTextInputPropertiesFromFormSchema } from "@/utils/form";
import EmbaixadorPessoaSelectMulti from "./EmbaixadorPessoaSelectMulti";
import EmbaixadorSelectMulti from "./EmbaixadorSelectMulti";
import LicenciadosFormModal from "./LicenciadosFormModal";
import useProjetoForm from "./useProjetoForm";
import { TProjetoForm, getLicenciados } from "../../firestore";
import projetoSchema from "../../schema/projeto-schema";
import FaseForm from "../fase-form";

const acceptedImageTypes = [...IMAGE_MIME_TYPE];
const positionArray = [
  { value: "1", label: "Topo" },
  { value: "2", label: "Inferior" }
];
// interface Status {
//   id: number;
//   titulo: string;
//   cor: string;
// }
interface Fase {
  id: string;
  titulo: string;
  cor: string;
  checked?: boolean;
  // status: Status[];
}

interface Options {
  value: string;
  label: string;
}

const message = "Ocorreu um erro ao carregar os dados:";

// prettier-ignore
const ProjetoForm = ({ projeto, onSuccess }: { projeto: TProjetoForm; onSuccess: () => void }) => {
  const [fases, setFases] = useState<Fase[]>([]);
  const { form, isPending, onSubmit } = useProjetoForm(projeto, fases, {
    onSuccess
  });
  const activeEmpresa = useUserStore((state) => state.activeEmpresa);
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [licenciados, setLicenciados] = useState<Options[]>([])

  // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
  const changeModal = () => {
    setOpenModal(open => !open)
  }

  useEffect(() => {
    // eslint-disable-next-line sonarjs/no-identical-functions
    const fetchData = async () => {
      try {
        await loadLicenciados();
      } catch (error) {
        console.error(message, error);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    if (!openModal) {
      void fetchData();
    }
  }, [activeEmpresa, openModal])

  // eslint-disable-next-line sonarjs/no-identical-functions
  const loadLicenciados = async () => {
    const arrayLicenciados = await getLicenciados(activeEmpresa?.id || "");
    const aux = arrayLicenciados.docs.map((desafio) => desafio.data())
    setLicenciados(aux.map(item => ({ value: item.id, label: item.nome })))
  }

  const fieldsDescriptor = projetoSchema.describe();
  const genericError = form.errors[""];
  // prettier-ignore
  useEffect(() => {
    if (form.values.fases.length > 0) {
      setFases(
        form.values.fases.map((fase: Fase) => ({
          id: fase.id,
          titulo: fase.titulo,
          cor: fase.cor,
          checked: fase.checked,
        }))
      );
    } else if (form.values.fases.length === 0) {
      setFases(mockFases)
    }
  }, [form.values.fases]);

  useEffect(() => {
    const anoVigencia = verificationDate(form.values.anoVigencia) as Date;
    const anoAtuacao = verificationDate(form.values.anoAtuacao) as Date;

    form.setValues(values => ({ ...values, anoVigencia, anoAtuacao }))
  }, [form.values.fases]);

  /**
   * Handle anoAtuacao change
   * @param fieldName - The field name to be updated
   * @returns The handler function
   */
  function handleAnoChange(fieldName: Parameters<typeof form.setFieldValue>[0]) {
    return (ano: DateValue) => {
      if (ano) {
        form.setFieldValue(fieldName, ano);
      }
    };
  }

  // const anoAtuacaoDate = new Date(form.values.anoAtuacao, 0, 1);
  // const anoVigenciaDate = new Date(form.values.anoVigencia, 0, 1);

  /**
   * Handle add fases
   */
  // prettier-ignore
  function addFases() {
    if (fases.some((fase: Fase) => fase.id === "0")) {
      setFases([{ id: "1", titulo: "Nova Fase", cor: "#ffffff", checked: false }]);
    } else {
      setFases((fases) => [...fases, { id: `${fases.length + 1}`, titulo: "Nova Fase", cor: "#ffffff", checked: false }]);
    }
  }

  /**
   * Handle anexos selected
   * @param files - The selected files
   */
  function handleAnexosSelected(files: FileWithPath[]) {
    for (const file of files) {
      form.insertListItem("images", file);
    }
  }

  /**
   * Handle remove anexo to upload from list
   * @param _file - File to remove (not used)
   * @param index - File index to remove
   */
  function handleRemoveAnexo(_file: TFileStored, index: number) {
    form.removeListItem("images", index);
    form.removeListItem("urlImages", index);
  }

  // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop, unicorn/consistent-function-scoping
  const onSuccessLicenciado = () => {
    changeModal()
    showNotification({
      message: `Cadastrado com sucesso!`,
      color: "green",
      autoClose: 2500
    });
  }

  // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
  const renderSelectOption = ({ option }: { option: Options }) => {
    return <Group align={"center"} justify={"space-between"} flex={1}>
      <Text fz="sm">
        {option?.label}
      </Text>
    </Group>
  }

  return (
    <>
      <LicenciadosFormModal openModal={openModal} changeModal={changeModal} onSuccess={onSuccessLicenciado} />
      <form onSubmit={onSubmit}>
        <Box pos="relative">
          <LoadingOverlay visible={isPending} />
          <Stack gap="sm" mb="lg">
            <TextInput
              type="text"
              style={{ flex: 1 }}
              {...form.getInputProps("nome")}
              {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "nome")}
            />
            <Group align="center">
              <DatePickerInput
                style={{ flex: 1 }}
                {...form.getInputProps("anoAtuacao")}
                {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "anoAtuacao")}
                onChange={handleAnoChange("anoAtuacao")}
                value={typeof form?.values?.anoAtuacao === "number" ? new Date() : verificationDate(form?.values?.anoAtuacao)}
              />
              <DatePickerInput
                style={{ flex: 1 }}
                {...form.getInputProps("anoVigencia")}
                {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "anoVigencia")}
                onChange={handleAnoChange("anoVigencia")}
                minDate={typeof form?.values?.anoAtuacao === "number" ? new Date() : verificationDate(form?.values?.anoAtuacao) as Date}
                value={typeof form?.values?.anoVigencia === "number" ? new Date() : verificationDate(form?.values?.anoVigencia)}
              />
            </Group>
            <Tooltip openDelay={350} label="Insira o link para o regulamento completo deste projeto." position="top-start" withArrow>
              <TextInput
                type="url"
                style={{ flex: 1 }}
                {...form.getInputProps("linkRegulamento")}
                {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "linkRegulamento")}
              />
            </Tooltip>
            <Group align="flex-end">
              <Tooltip openDelay={350} label="Cadastre no botão azul a pessoa ou entidade responsável pela execução do projeto." position="top-start" withArrow>
                <Select
                  data={licenciados}
                  renderOption={renderSelectOption}
                  {...form.getInputProps("licenciado")}
                  {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "licenciado")}
                  style={{ flex: 1 }}
                />
              </Tooltip>
              <Button onClick={changeModal} color="rgba(8, 68, 244, 1)"><IconPlus /></Button>
            </Group>
            <Tooltip openDelay={350} label="Defina o número mínimo de respostas de diagnóstico exigidas por empresa para a participação ser considerada válida." position="top-start" withArrow>
              <NumberInput
                style={{ flex: 1 }}
                {...form.getInputProps("minimoResposta")}
                {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "minimoResposta")}
              />
            </Tooltip>
            <Tooltip openDelay={350} label="Identifique a pessoa ou entidade responsável pela representação e divulgação do projeto." position="top-start" withArrow>
              <Group>
                <EmbaixadorSelectMulti
                  form={form}
                  {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "embaixadores")}
                  style={{ flex: 1 }}
                />
              </Group>
            </Tooltip>
            <Tooltip openDelay={350} label="Identifique o embaixador responsável pela representação e divulgação do projeto." position="top-start" withArrow>
              <Group>
                <EmbaixadorPessoaSelectMulti
                  form={form}
                  {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "embaixadoresPessoas")}
                  style={{ flex: 1 }}
                />
              </Group>
            </Tooltip>
            <Tooltip openDelay={350} label="Cadastre categorias para inscrição no projeto, isso fará a classificação e análise de projetos por tipo ou área de atuação." position="top-start" withArrow>
              <TagsInput
                style={{ flex: 1 }}
                {...form.getInputProps("categoria")}
                {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "categoria")}
              />
            </Tooltip>
            <Box pos="relative">
              <Select
                data={positionArray}
                style={{ flex: 1 }}
                {...form.getInputProps("posicao")}
                {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "posicao")}
              />
              <Space h="md" />
              <NumberInput
                style={{ flex: 1 }}
                {...form.getInputProps("tempo")}
                {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "tempo")}
              />
              <Space h="md" />
              <FileSelectorDropzone miw="200px" onDrop={handleAnexosSelected} accept={acceptedImageTypes} />
              <Space h="xl" />
              <List
                withPadding
                styles={{
                  root: {
                    flex: 1
                  }
                }}
              >
                {form?.values?.images.map((file, index: number) => (
                  <FileItem key={file.path as string} file={file} index={index} onRemove={handleRemoveAnexo} values={form.values} setValues={form.setValues} />
                ))}
              </List>
              <Space h="xl" />
              <Group>
                <Checkbox
                  {...form.getInputProps("exibir")}
                  {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "exibir")}
                  defaultChecked={form.getInputProps("exibir").value as boolean}
                />
                <Checkbox
                  {...form.getInputProps("trocar")}
                  {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "trocar")}
                  defaultChecked={form.getInputProps("trocar").value as boolean}
                />
              </Group>
            </Box>
            <Group justify={"end"}>
              <Button color="rgb(34,139,230)" radius="md" onClick={addFases}>
                <Text size="sm">Adicionar Fase</Text>
              </Button>
            </Group>
          </Stack>

          <FaseForm fases={fases} setFases={setFases} />
        </Box>
        <Group justify={genericError ? "space-between" : "flex-end"} mt="md">
          {genericError && (
            <Alert icon={<IconAlertCircle size="1rem" />} color="red">
              {genericError}
            </Alert>
          )}
          <Button type="submit" loading={isPending}>
            Salvar
          </Button>
        </Group>
      </form >
    </>
  );
};

export default ProjetoForm;

const mockFases = [
  {
    cor: "rgba(231, 235, 239, 1)",
    titulo: "Inscrição",
    checked: false,
    id: "1"
  },
  {
    cor: "rgba(41, 94, 255, 1)",
    titulo: "Autodiagnóstico",
    checked: false,
    id: "2"
  },
  {
    cor: "rgba(105, 198, 105, 1)",
    titulo: "Habilitada",
    checked: false,
    id: "3"
  },
  {
    cor: "rgb(41, 255, 209)",
    titulo: "Evidências",
    checked: false,
    id: "8"
  },
  {
    cor: "rgba(121, 57, 204, 0.6)",
    titulo: "Entrevista Inicial",
    checked: false,
    id: "4"
  },
  {
    cor: "rgba(121, 57, 204, 1)",
    titulo: "Entrevista de aprofundamento",
    checked: false,
    id: "5"
  },
  {
    cor: "rgba(229, 78, 78, 0.2)",
    titulo: "Finalistas",
    checked: false,
    id: "6"
  },
  {
    cor: "rgba(253, 213, 3, 0.6)",
    titulo: "Vencedores",
    checked: false,
    id: "7"
  }
];

const SEGUNDOS_EM_MILISEGUNDOS = 1000;

interface Timestamp {
  seconds: number;
}

// prettier-ignore
export const verificationDate = (date: Date | Timestamp | undefined): Date | null => {
  let data: number | undefined;

  if (date instanceof Date) {
    data = date.getTime() / SEGUNDOS_EM_MILISEGUNDOS;
  } else if (date && typeof date === 'object' && 'seconds' in date) {
    data = date.seconds;
  }

  return data ? new Date(data * SEGUNDOS_EM_MILISEGUNDOS) : null;
};
