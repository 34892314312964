import { Avatar, Card, Flex, Group, Space, Text } from "@mantine/core";
import { useParams } from "react-router-dom";
import { getCurrentUser } from "@/services/auth";
import NotaItem from "./NotaItem";
import useQueryMedias from "./useGetMediaFromUrl";
import useGetProjetoFromUrl from "./useGetProjetoFromUrl";
import useGetQuestionarioFromUrl from "./useGetQuestionarioFromUrl";
interface Fase {
  id: string;
  titulo: string;
  cor: string;
  checked: boolean;
}

interface Objeto {
  resposta: RespostaItem | RespostaItem[] | number | string;
}

type Properties = {
  pergunta: TPergunta | null | undefined;
  numero: number;
  statusItem: TStatus | undefined;
  respostas: TResposta[];
  grupo: TGruposDiagnostico;
};

const EstrategiaItem = ({ pergunta, numero, statusItem, respostas, grupo }: Properties) => {
  const { data } = useGetProjetoFromUrl();
  const fases = data?.fases || [];
  const faseItem = fases.find((item: Fase) => item?.id === statusItem?.status) as Fase;
  const formatResposta = respostas.map((row) => {
    return row.perguntas.find((p: TRespostaPergunta) => p?.id === pergunta?.id);
  });
  const media = calcularMedia(formatResposta as Objeto[]) as number;
  const { data: questionario } = useGetQuestionarioFromUrl();
  const faseQuestionario = questionario?.fases || [];
  const { data: medias, refetch } = useQueryMedias({ id: pergunta?.id || "" });
  const currentUserId = getCurrentUser()?.uid;
  const { idEmpresa } = useParams<{ idEmpresa: string }>();

  if (typeof media === "string") {
    return <></>;
  }

  return (
    <>
      <Space h="md" />
      <Card withBorder shadow="none" style={{ flex: 1 }}>
        <Flex direction="column">
          <Flex gap="xs" direction="row" wrap="wrap" align={"center"}>
            <Avatar bg="#6188FA" color={"white"} radius="xl" size="sm">
              {numero}
            </Avatar>
            <Flex direction="column">
              <Text fw={500} fz="md">
                {pergunta?.nome}
              </Text>
              <Text size="xs">{pergunta?.descricao}</Text>
            </Flex>
          </Flex>
          <Space h={"md"} />
          <Flex gap="xl" align="center" direction="row" wrap="wrap">
            <Group>
              {faseQuestionario && faseQuestionario.length > 0 ? (
                faseQuestionario.map((item: Fase) => (
                  <>
                    <Text fw={"bold"} fz="sm">
                      {item && item?.titulo} - {Number(media && media?.toFixed(1))}
                    </Text>
                  </>
                ))
              ) : (
                <></>
              )}
              <>
                {medias
                  ?.filter((item) => item.idEmpresaNota === idEmpresa)
                  .map((item) => {
                    const faseItem = fases.find((row: Fase) => row?.id === item?.fase) as Fase;
                    return (
                      <>
                        <Text fw={"bold"} fz="sm">
                          {faseItem && faseItem?.titulo} - {item.nota}
                        </Text>
                      </>
                    );
                  })}
                {medias?.some(
                  (item) =>
                    item.respondente === currentUserId &&
                    faseItem?.id === item?.fase &&
                    item.idEmpresaNota === idEmpresa
                ) ? (
                  <></>
                ) : faseItem?.checked ? (
                  <NotaItem pergunta={pergunta} fase={faseItem} refetch={refetch} grupo={grupo} />
                ) : (
                  <></>
                )}
              </>
            </Group>
          </Flex>
        </Flex>
      </Card>
    </>
  );
};

export default EstrategiaItem;
interface RespostaItem {
  valor: number | string;
}

const STRING_TRIM_LENGTH = -2;

/* eslint-disable sonarjs/cognitive-complexity */
/**
 * Calcula a média dos valores numéricos em um array de objetos.
 * @param array - Array de objetos contendo valores a serem incluídos no cálculo da média.
 * @returns A média dos valores numéricos no array, ou uma string contendo valores não numéricos se nenhum valor numérico for encontrado.
 */
function calcularMedia(array: Objeto[]) {
  let total = 0;
  let quantidade = 0;
  let respostasString = "";
  for (const objeto of array) {
    let valor;
    if (Array.isArray(objeto?.resposta)) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      for (const item of objeto?.resposta) {
        if (typeof item.valor === "number") {
          total += Number.parseFloat(item?.valor.toString());
          quantidade++;
        } else if (typeof item?.valor === "string") {
          respostasString += item.valor + ", ";
        }
      }
    } else if (typeof objeto?.resposta === "object" && objeto?.resposta !== null && objeto?.resposta !== undefined) {
      if (typeof objeto.resposta.valor === "number") {
        valor = Number.parseFloat(objeto.resposta.valor.toString());
        if (!Number.isNaN(valor)) {
          total += valor;
          quantidade++;
        }
      } else if (typeof objeto?.resposta?.valor === "string") {
        respostasString += objeto?.resposta?.valor + ", ";
      }
    } else if (typeof objeto?.resposta === "number") {
      valor = Number.parseFloat(objeto.resposta.toString());
      if (!Number.isNaN(valor)) {
        total += valor;
        quantidade++;
      }
    } else if (typeof objeto?.resposta === "string") {
      respostasString += objeto?.resposta + ", ";
    }
  }

  return quantidade === 0 ? respostasString.slice(0, STRING_TRIM_LENGTH) : total / quantidade;
}
