import { Button, Group, NumberInput, Text, ThemeIcon } from "@mantine/core";
import { IconCheck, IconCoin } from "@tabler/icons-react";
import useDesafioPatrocinioForm from "./useDesafioPatrocinioForm";

type DesafioPatrocinioFormProperties = {
  desafioActivity?: TDesafioActivityPerColaborador | null;
  desafio: TDesafio;
  regulamento: TRegulamentoDesafios;
};

const DesafioPatrocinioForm = ({ desafioActivity, desafio, regulamento }: DesafioPatrocinioFormProperties) => {
  const qtdMoedasGastasNesteDesafio = desafioActivity?.moedas || 0;

  const { onSubmit, getInputProps, values, isLoading } = useDesafioPatrocinioForm({
    desafio: desafio,
    regulamento,
    desafioActivity: desafioActivity
  });

  const { moedas } = values;
  const desafioSemPatrocinio = qtdMoedasGastasNesteDesafio === 0;
  const alterouQuantidade = moedas !== qtdMoedasGastasNesteDesafio;

  return (
    <form onSubmit={onSubmit}>
      <Group justify="space-between">
        <Group gap="xs">
          <Text c="dark" size="sm">
            Informe a quantidade de moedas para patrocinar o desafio
          </Text>
          <NumberInput
            size="sm"
            suffix={` moedas`}
            allowNegative={false}
            clampBehavior="strict"
            leftSection={
              <ThemeIcon variant="light" color="yellow" size="sm">
                <IconCoin />
              </ThemeIcon>
            }
            disabled={isLoading}
            {...getInputProps("moedas")}
          />
        </Group>

        {!alterouQuantidade && !desafioSemPatrocinio ? (
          <Group gap={2}>
            <ThemeIcon variant="transparent" color="green" size="sm">
              <IconCheck />
            </ThemeIcon>

            <Text c="green">Patrocínio realizado</Text>
          </Group>
        ) : (
          <Button type="submit" loading={isLoading}>
            {desafioSemPatrocinio ? "Patrocinar" : "Modificar Patrocínio"}
          </Button>
        )}
      </Group>
    </form>
  );
};

export default DesafioPatrocinioForm;
