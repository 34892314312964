import { httpsCallable } from "firebase/functions";
import { Asserts } from "yup";
import { functions } from "@/base";
import inviteColaboradorAddSchema from "./components/colaborador-form/invite-colaborador-schema";
import inviteColaboradorSchema from "./components/invite-colaborador-form/invite-colaborador-schema";

type InviteInputs = Asserts<typeof inviteColaboradorSchema> & {
  licenciadaId: string;
  empresaId: string;
};
export const inviteColaborador = httpsCallable<InviteInputs, never>(
  functions,
  "colaboradoresFunctions-inviteColaborador"
);

type ResentInviteInputs = Pick<InviteInputs, "email" | "empresaId">;
export const resentInviteToColaborador = httpsCallable<ResentInviteInputs, never>(
  functions,
  "colaboradoresFunctions-resentInviteToColaborador"
);

type InviteInputsAdd = Asserts<typeof inviteColaboradorAddSchema> & {
  licenciadaId: string | undefined;
  empresaId: string;
};
export const inviteColaboradorAdd = httpsCallable<InviteInputsAdd, never>(
  functions,
  "colaboradoresFunctions-inviteColaboradorAdd"
);
