import { subject } from "@casl/ability";
import { Group, Text } from "@mantine/core";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import ContextMenuRow from "./ContextMenuRow";
import RegulamentoDesafioStatus from "./RegulamentoDesafioStatus";

const columnHelper = createColumnHelper<TRegulamentoDesafios>();

const columns: ColumnDef<TRegulamentoDesafios>[] = [
  columnHelper.accessor("nome", {
    header: "Nome",
    enableSorting: true,
    cell: (properties) => {
      return (
        <Group gap="xs" wrap="nowrap">
          <Text fz="sm">{properties.getValue()}</Text>
          <RegulamentoDesafioStatus regulamento={properties.row.original} />
        </Group>
      );
    }
  }),
  columnHelper.accessor("dataInicial", {
    header: "Data Inicial",
    enableSorting: true,
    cell: (properties) => {
      return properties.getValue()?.toLocaleDateString();
    }
  }),
  columnHelper.accessor("dataFinal", {
    header: "Data Final",
    enableSorting: true,
    cell: (properties) => {
      return properties.getValue()?.toLocaleDateString();
    }
  }),
  columnHelper.accessor("quantidadeMoedasPorColaborador", {
    header: "Quantidade Moedas",
    enableSorting: true
  }),
  columnHelper.display({
    id: "actions",
    cell: (properties) => {
      const regulamento = subject("TRegulamentoDesafios", properties.row.original);

      return (
        <Group justify="center" gap={2}>
          <ContextMenuRow regulamento={regulamento} />
        </Group>
      );
    }
  })
];

export default columns;

export const columnsDesafioMobile: ColumnDef<TRegulamentoDesafios>[] = [
  columnHelper.accessor("nome", {
    header: "Nome",
    enableSorting: true,
    cell: (properties) => {
      return (
        <Group gap="xs" wrap="nowrap">
          <Text fz="sm">{properties.getValue()}</Text>
          <RegulamentoDesafioStatus regulamento={properties.row.original} />
        </Group>
      );
    }
  }),
  columnHelper.accessor("dataInicial", {
    header: "Data Inicial",
    enableSorting: true,
    cell: (properties) => {
      return properties.getValue()?.toLocaleDateString();
    }
  }),
  columnHelper.accessor("dataFinal", {
    header: "Data Final",
    enableSorting: true,
    cell: (properties) => {
      return properties.getValue()?.toLocaleDateString();
    }
  }),
  columnHelper.display({
    id: "actions",
    cell: (properties) => {
      const regulamento = subject("TRegulamentoDesafios", properties.row.original);

      return (
        <Group justify="center" gap={2}>
          <ContextMenuRow regulamento={regulamento} />
        </Group>
      );
    }
  })
];
