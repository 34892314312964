import { Text, FocusTrap } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import RegulamentoDesafiosForm from "../../components/regulamento-desafios-form";
import { TRegulamentoDesafiosForm } from "../../firestore";

const RegulamentoDesafiosFormModal = ({
  context,
  innerProps,
  id
}: ContextModalProps<{ item: TRegulamentoDesafiosForm }>) => {
  const { item: regulamento } = innerProps;
  /**
   * Handle add ignore area success
   */
  function onSuccess() {
    context.closeModal(id);
  }

  return (
    <FocusTrap active={true}>
      <Text fw="bold" mb="md">
        Cadastro de regulamento de desafios
      </Text>
      <RegulamentoDesafiosForm regulamento={regulamento} onSuccess={onSuccess} />
    </FocusTrap>
  );
};

export default RegulamentoDesafiosFormModal;
