import { subject } from "@casl/ability";
import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { getJogadasDeInovacao, QUERY_KEY } from "../../firestore";

type TGetJogadaDeInovacaoResponse = Awaited<ReturnType<typeof getJogadasDeInovacao>>;

/**
 * Get jogadas de inovação query hook
 * @returns jogadas de inovação query hook
 */
function useQueryJogadaDeInovacao() {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  return useQuery<TGetJogadaDeInovacaoResponse, FirestoreError, TJogadaDeInovacao[]>({
    queryKey: ["empresas", { id: activeEmpresaId }, QUERY_KEY],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return getJogadasDeInovacao(activeEmpresaId);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }
      return dataResponse.docs.map((document_) => subject("TJogadaDeInovacao", document_.data()));
    }
  });
}

export default useQueryJogadaDeInovacao;
