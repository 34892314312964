import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { getLikes } from "../firestore";

type TGetIdeiaActivities = Awaited<ReturnType<typeof getLikes>>;
type TQueryOptions = Omit<UseQueryOptions<TGetIdeiaActivities, FirestoreError, TIdeiaLike[]>, "queryKey">;

/**
 * Get ideia likes
 * @param ideia - Ideia to get likes
 * @param options - Query options to use
 * @returns - Query result
 */
function useGetLikes(ideia: TIdeia, options: TQueryOptions = {}) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  return useQuery<TGetIdeiaActivities, FirestoreError, TIdeiaLike[] | null>({
    queryKey: [activeEmpresaId, "ideia", ideia.id, "activities"],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      return getLikes(activeEmpresaId, ideia.id);
    },
    select(dataResponse) {
      if (dataResponse.length === 0) {
        return [];
      }
      return dataResponse;
    },
    ...options
  });
}

export default useGetLikes;
