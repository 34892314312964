import { subject } from "@casl/ability";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { getIdeiasByStatus } from "../firestore";

type TGetIdeiasByStatus = Awaited<ReturnType<typeof getIdeiasByStatus>>;
type TQueryOptions = Pick<
  UseQueryOptions<TGetIdeiasByStatus, FirestoreError, Record<TIdeia["status"], number>>,
  "enabled"
>;

/**
 * Count ideias by status
 * @param status - Ideia status to count
 * @param options - Query options
 * @returns - Count
 */
function useCountIdeiasByStatusQuery(status: TIdeia["status"][], options: TQueryOptions = {}) {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  return useQuery<TGetIdeiasByStatus, FirestoreError, Record<TIdeia["status"], number>>({
    queryKey: ["empresas", activeEmpresaId, "ideias", { status }],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não encontrada");
      }

      return getIdeiasByStatus(activeEmpresaId, ...status);
    },
    select(dataResponse) {
      const allIdeias = dataResponse.docs.map((document) => subject("TIdeia", document.data()));
      const publicIdeias = allIdeias.filter((ideia) => !ideia.privado);

      const statusCounts: Record<TIdeia["status"], number> = {
        APROVADA: 0,
        REPROVADA: 0,
        BACKLOG_DE_AVALIACAO_FUTURA: 0,
        EM_IMPLEMENTACAO: 0,
        IMPLEMENTADA: 0,
        CANCELADA: 0,
        RASCUNHO: 0,
        NOVA_IDEIA: 0,
        PRE_ANALISE: 0,
        EM_ANALISE_DE_VIABILIDADE: 0,
        AGUARDANDO_ANALISE_DO_COMITE: 0
      };

      for (const ideia of publicIdeias) {
        // eslint-disable-next-line security/detect-object-injection
        if (!statusCounts[ideia.status]) {
          // eslint-disable-next-line security/detect-object-injection
          statusCounts[ideia.status] = 0;
        }
        // eslint-disable-next-line security/detect-object-injection
        statusCounts[ideia.status] += 1;
      }

      return statusCounts;
    },
    ...options
  });
}

export default useCountIdeiasByStatusQuery;
