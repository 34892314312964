import { Group } from "@mantine/core";

/* interface Campo {
  campo: string;
  valor: string;
}
interface respostas {
  id: string;
  resposta?: string | number | Campo | Campo[] | boolean;
} */

// prettier-ignore
const ValorResposta = (/* { resposta }: { resposta: respostas | undefined } */) => {
  return <Group></Group>
};

export default ValorResposta;
