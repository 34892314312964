import { Flex, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getRepostasArrayNewUser } from "@/modules/responder-questionario/firestore";

interface Externo {
  role: string;
  createdBy: string;
  nome: string;
  email: string;
  id: string;
  createdAt: Date;
  deletedAt: null;
  refPath: string;
  respondido: boolean;
  pausado: boolean;
}
const message = "";
// prettier-ignore
const RespostaComponente = ({ id, fw, fz }: { id: TQuestionario["id"], fw: number, fz: string }) => {
  const { idEmpresa } = useParams<{ idEmpresa: string }>();
  const [totalRespostas, setTotalRespostas] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadData();
      } catch (error) {
        console.error(message, error);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, []);

  const loadData = async () => {
    const respostas = await getRepostasArrayNewUser(idEmpresa || "", id);
    const format = respostas.docs.map((resposta) => resposta.data()) as Externo[] & TResposta[];
    setTotalRespostas(format?.filter(item => !item.pausado)?.length)
  }

  return (
    <>
      <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
        <Text fw={300} fz="xs">
          Quantidade de respostas
        </Text>
        <Text fw={fw} fz={fz}>
          {totalRespostas}
        </Text>
      </Flex>
    </>
  );
};

export default RespostaComponente;
