// import Can from "@/components/can";
import { Card, Group, Text, Flex, Space, Badge, SimpleGrid, Button } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import { QueryObserverResult } from "@tanstack/react-query";
import React from "react";
import JogadasCategoriaLabel from "@/modules/jogadas-de-inovacao/components/jogadas-categoria-label";
import JogadasTipoLabel from "@/modules/jogadas-de-inovacao/components/jogadasTipoLabel";

interface Tatica {
  id: string | number;
  nome: string;
  descricao: string;
  nomeCategoria: string | undefined;
  nomeTipo: string | undefined;
  tipo?: string | undefined;
  categoria?: string | undefined;
  corVinculos?: string | undefined;
  movimentacao?: [];
}

// prettier-ignore
const HomeTaticasListCardModal: React.FC<{
  jogada: Tatica;
  item: TJogadaDeInovacao;
  onRemove?: (argument0: Tatica) => void;
  refetch: () => Promise<QueryObserverResult>;
}> = ({ jogada, onRemove }) => {
  return (
    <>
      <Card withBorder radius="lg" style={{ border: jogada && jogada?.corVinculos ? `5px solid ${jogada?.corVinculos}` : "1px solid rgb(226,230,233)" }}>
        <Flex justify={"space-between"}>
          <Group>
            <Badge radius="md" size="xl" variant="transparent" style={{ background: "rgba(244, 245, 246, 1)" }}>
              <Group>
                <Text tt="capitalize" fz="xs" fw={400} color={"rgb(118,119,125)"}>
                  Categoria de Inovação:
                </Text>
                <JogadasCategoriaLabel id={jogada?.categoria || ""} />
              </Group>
            </Badge>
            <Badge radius="md" size="xl" variant="transparent" style={{ background: "rgba(244, 245, 246, 1)" }}>
              <Group>
                <Text tt="capitalize" fz="xs" fw={400} color={"rgb(118,119,125)"}>
                  Tipo de Inovação:
                </Text>
                <JogadasTipoLabel id={jogada?.tipo || ""} />
              </Group>
            </Badge>
          </Group>
          {onRemove && (
            <Button
              color="#525356"
              variant="transparent"
              radius="lg"
              size="md"
              // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
              onClick={() => onRemove(jogada)}
            >
              <Group>
                <IconTrash size={16} />
                <Text size="sm">Excluir</Text>
              </Group>
            </Button>
          )}
        </Flex>
        <Space h="xl" />
        <SimpleGrid cols={4}>
          <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
            <Text fw={300} fz="xs">
              Tática
            </Text>
            <Text fw={500} fz="md">
              {jogada.nome}
            </Text>
          </Flex>
          <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
            <Text fw={300} fz="xs">
              Setor
            </Text>
            <Text fw={500} fz="md">
              {jogada.nome}
            </Text>
          </Flex>
        </SimpleGrid>
        <Space h="xl" />
      </Card>
      <Space h="xl" />
    </>
  );
};

export default HomeTaticasListCardModal;
