import { Text, Group, Badge, ThemeIcon, BadgeProps } from "@mantine/core";
import { IconCoin } from "@tabler/icons-react";
import useGetTotalMoedasPerDesafio from "@/modules/desafios-activities-per-colaborador/hooks/useGetTotalMoedasPerDesafio";

const TotalMoedasBadge = ({ desafio, ...badgeProperties }: BadgeProps & { desafio: TDesafio }) => {
  const { data: totalMoedasNoDesafio } = useGetTotalMoedasPerDesafio(desafio);

  return (
    <Group align="center" gap="xs">
      <Badge
        color="gray.2"
        leftSection={
          <ThemeIcon variant="light" color="yellow" size="sm">
            <IconCoin />
          </ThemeIcon>
        }
        {...badgeProperties}
      >
        <Text c="dark" fz="xs" fw={500} tt="lowercase">
          {totalMoedasNoDesafio} moedas
        </Text>
      </Badge>
    </Group>
  );
};

export default TotalMoedasBadge;
