import { Text, FocusTrap } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import TipoDeJogadaDeForm from "../../components/tipo-de-jogada-form";
import { TTipoDeJogadaForm } from "../../firestore";
// prettier-ignore
const TipoDeJogadaDeFormModal = ({
  context,
  innerProps,
  id
}: ContextModalProps<{ item: TTipoDeJogadaForm }>) => {
  const { item: tipoDeJogada } = innerProps;
  /**
   * Handle add ignore area success
   */
  function onSuccess() {
    context.closeModal(id);
  }

  return (
    <FocusTrap active={true}>
      <Text fw="bold" mb="md">
        Cadastro de tipos de jogada
      </Text>
      <TipoDeJogadaDeForm tipoDeJogada={tipoDeJogada} onSuccess={onSuccess} />
    </FocusTrap>
  );
};

export default TipoDeJogadaDeFormModal;
