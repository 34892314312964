import { Select, SelectProps } from "@mantine/core";
import useGetActiveColaboradores from "../../hooks/useGetActiveColaboradores";

const ColaboradorSelector = (properties: SelectProps) => {
  const { data, isLoading, error } = useGetActiveColaboradores({
    select(dataSnapshot) {
      if (dataSnapshot.empty) {
        return [];
      }

      return dataSnapshot.docs.map((document_) => {
        const colaborador = document_.data();
        return {
          value: colaborador.refPath,
          label: colaborador.nome
        };
      });
    }
  });

  return <Select data={data} disabled={isLoading} error={error?.message} {...properties} />;
};

export default ColaboradorSelector;
