/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-floating-promises */
import {
  Alert,
  Box,
  Button,
  Fieldset,
  Flex,
  Group,
  Image,
  LoadingOverlay,
  rem,
  Select,
  Space,
  TagsInput,
  TextInput
} from "@mantine/core";
import { FileWithPath, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { useFocusWithin } from "@mantine/hooks";
import { IconAlertCircle, IconBuilding } from "@tabler/icons-react";
import { getDownloadURL } from "firebase/storage";
import { useEffect } from "react";
import { SchemaObjectDescription } from "yup";
import CepInput from "@/components/cep-input";
import CNPJInput from "@/components/cnpj-input";
import FileSelectorDropZoneUser from "@/components/file-selector-dropzone/FileSelectorDropZoneUser";
import TelInput from "@/components/tel-input";
import { uploadAnexosFilesToStorage } from "@/modules/ideias/storage";
import { getEmpresaDataByCnpj } from "@/services/brasilapi";
import { extractTextInputPropertiesFromFormSchema } from "@/utils/form";
import useEmpresaForm from "./useEmpresaForm";
import { TEmpresaForm } from "../../firestore";
import empresaSchema, { numeroColaboradoresData, porteData } from "../../schema/empresa-schema";
import { estadosData } from "../../schema/endereco-schema";

const FOCUS_SECTION_COLOR = "var(--mantine-color-blue-light)";
const NUMBER_CARACTERS = 14;
const acceptedImageTypes = [...IMAGE_MIME_TYPE];
const ICON_SIZE = 52;
const MAX_RANDOM = 1_000_000;

const EmpresaForm = ({ empresa, onSuccess }: { empresa: TEmpresaForm; onSuccess: () => void }) => {
  const { onSubmit, form, isPending } = useEmpresaForm(empresa, {
    onSuccess
  });

  const { ref: referenceFieldsetEmpresa, focused: focusedFieldsetEmpresa } = useFocusWithin();
  const { ref: referenceFieldsetResponsavel, focused: focusedFieldsetResponsavel } = useFocusWithin();
  const { ref: referenceFieldsetEndereco, focused: focusedFieldsetEndereco } = useFocusWithin();

  const empresaFieldsDescriptor = empresaSchema.describe();
  const responsavelDescriptor = empresaSchema.fields.responsavel.describe() as SchemaObjectDescription;
  const enderecoDescriptor = empresaSchema.fields.endereco.describe() as SchemaObjectDescription;

  const genericError = form.errors[""];

  const setEmpresaDataByCnpj = async (cnpj: TEmpresa["cnpj"]) => {
    const data = await getEmpresaDataByCnpj(cnpj);
    if (!data) {
      return;
    }
    const empresaRole: TRole = empresa.role ?? "NORMAL";
    form.setValues((values) => ({
      ...values,
      nomeFantasia: data.nome_fantasia as TEmpresa["nomeFantasia"],
      razaoSocial: data.razao_social as TEmpresa["razaoSocial"],
      email: form.values.email || data.email || ("" as TEmpresa["email"]),
      telefone: data.ddd_telefone_1 as TEmpresa["telefone"],
      codigoCNAE: data.cnae_fiscal.toString() as TEmpresa["codigoCNAE"],
      cnae: data.cnae_fiscal_descricao as TEmpresa["cnae"],
      role: empresaRole,
      endereco: {
        cep: data.cep as TEmpresa["endereco"]["cep"],
        logradouro: data.logradouro as TEmpresa["endereco"]["logradouro"],
        numero: data.numero as TEmpresa["endereco"]["numero"],
        complemento: data.complemento as TEmpresa["endereco"]["complemento"],
        bairro: data.bairro as TEmpresa["endereco"]["bairro"],
        cidade: data.municipio as TEmpresa["endereco"]["cidade"],
        estado: data.uf as TEstado
      }
    }));
  };

  useEffect(() => {
    const cnpjInput = form.getInputProps("cnpj");
    const cnpjValue = cnpjInput.value as string;

    if (!cnpjValue) {
      return;
    }

    const cnpj = cnpjValue.replaceAll(/\D/g, "") as string;
    const totalCnpj = cnpj.length;

    if (totalCnpj === NUMBER_CARACTERS) {
      setEmpresaDataByCnpj(cnpj);
    }
  }, [form.getInputProps("cnpj").value]);

  /**
   * Handle anexos selected
   * @param files - The selected files
   */
  async function handleAnexosSelected(files: FileWithPath[]) {
    const lastFile = files.pop();
    const images = [lastFile];
    const anexosPromises = images.map<Promise<TFileStored>>((file: FileWithPath) =>
      uploadAnexosFilesToStorage(empresa?.id || "", `${Math.floor(Math.random() * MAX_RANDOM) + 1}`, file).then(
        async (uploadTaskSnapshot) => {
          const url = await getDownloadURL(uploadTaskSnapshot.ref);
          return {
            path: url,
            name: "",
            size: 0,
            type: ""
          };
        }
      )
    );
    // eslint-disable-next-line compat/compat
    const anexos = await Promise.all(anexosPromises);

    form.setFieldValue("image", anexos);
  }

  return (
    <form onSubmit={onSubmit}>
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />
        <Flex align="center" justify={"center"}>
          <FileSelectorDropZoneUser
            w="300px"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onDrop={handleAnexosSelected}
            accept={acceptedImageTypes}
          >
            <Flex gap={0} justify="center" align="center" direction="column" wrap="wrap">
              {form?.values?.image?.length > 0 ? (
                <Image radius="md" src={form?.values?.image[0]?.path as string} />
              ) : (
                <IconBuilding style={{ width: rem(ICON_SIZE), height: rem(ICON_SIZE), color: "black" }} stroke={1.5} />
              )}
            </Flex>
          </FileSelectorDropZoneUser>
        </Flex>
        <Space h={"md"} />
        <Fieldset
          legend="Dados da Empresa"
          mb="sm"
          ref={referenceFieldsetEmpresa}
          style={{
            backgroundColor: focusedFieldsetEmpresa ? FOCUS_SECTION_COLOR : "transparent"
          }}
        >
          <Group gap="md">
            <CNPJInput
              data-autofocus
              w="160px"
              {...form.getInputProps("cnpj")}
              {...extractTextInputPropertiesFromFormSchema(empresaFieldsDescriptor, "cnpj")}
            />
            <TextInput
              type="text"
              style={{ flex: 1 }}
              {...form.getInputProps("razaoSocial")}
              {...extractTextInputPropertiesFromFormSchema(empresaFieldsDescriptor, "razaoSocial")}
            />
          </Group>
          <Group gap="md">
            <TextInput
              type="text"
              style={{ flex: 1 }}
              {...form.getInputProps("nomeFantasia")}
              {...extractTextInputPropertiesFromFormSchema(empresaFieldsDescriptor, "nomeFantasia")}
            />
            <TextInput
              type="email"
              {...form.getInputProps("email")}
              {...extractTextInputPropertiesFromFormSchema(empresaFieldsDescriptor, "email")}
            />
            <TelInput
              w="140px"
              {...form.getInputProps("telefone")}
              {...extractTextInputPropertiesFromFormSchema(empresaFieldsDescriptor, "telefone")}
            />
          </Group>
          <Group gap="md">
            <TextInput
              type="text"
              {...form.getInputProps("codigoCNAE")}
              {...extractTextInputPropertiesFromFormSchema(empresaFieldsDescriptor, "codigoCNAE")}
            />
            <TextInput
              type="text"
              style={{ flex: 1 }}
              {...form.getInputProps("cnae")}
              {...extractTextInputPropertiesFromFormSchema(empresaFieldsDescriptor, "cnae")}
            />
          </Group>
          <Group gap="md">
            <Select
              data={porteData}
              {...form.getInputProps("porte")}
              {...extractTextInputPropertiesFromFormSchema(empresaFieldsDescriptor, "porte")}
            />
            <Select
              data={numeroColaboradoresData}
              {...form.getInputProps("numeroColaboradores")}
              {...extractTextInputPropertiesFromFormSchema(empresaFieldsDescriptor, "numeroColaboradores")}
            />
            <TagsInput
              style={{ flex: 1 }}
              {...form.getInputProps("setores")}
              {...extractTextInputPropertiesFromFormSchema(empresaFieldsDescriptor, "setores")}
            />
          </Group>
        </Fieldset>

        <Fieldset
          legend="Dados do Responsável"
          mb="sm"
          ref={referenceFieldsetResponsavel}
          style={{
            backgroundColor: focusedFieldsetResponsavel ? FOCUS_SECTION_COLOR : "transparent"
          }}
        >
          <Group gap="md">
            <TextInput
              type="text"
              style={{ flex: 1 }}
              {...form.getInputProps("responsavel.nome")}
              {...extractTextInputPropertiesFromFormSchema(responsavelDescriptor, "nome")}
            />
            <TextInput
              type="text"
              style={{ flex: 1 }}
              {...form.getInputProps("responsavel.email")}
              {...extractTextInputPropertiesFromFormSchema(responsavelDescriptor, "email")}
            />
            <TelInput
              w="140px"
              {...form.getInputProps("responsavel.telefone")}
              {...extractTextInputPropertiesFromFormSchema(responsavelDescriptor, "telefone")}
            />
          </Group>
        </Fieldset>
        <Fieldset
          legend="Endereço"
          ref={referenceFieldsetEndereco}
          style={{
            backgroundColor: focusedFieldsetEndereco ? FOCUS_SECTION_COLOR : "transparent"
          }}
        >
          <Group gap="md">
            <CepInput
              w="100px"
              {...form.getInputProps("endereco.cep")}
              {...extractTextInputPropertiesFromFormSchema(enderecoDescriptor, "cep")}
            />
            <TextInput
              type="text"
              style={{ flex: 1 }}
              {...form.getInputProps("endereco.logradouro")}
              {...extractTextInputPropertiesFromFormSchema(enderecoDescriptor, "logradouro")}
            />
            <TextInput
              type="text"
              w="100px"
              {...form.getInputProps("endereco.numero")}
              {...extractTextInputPropertiesFromFormSchema(enderecoDescriptor, "numero")}
            />
            <TextInput
              type="text"
              w="150px"
              {...form.getInputProps("endereco.complemento")}
              {...extractTextInputPropertiesFromFormSchema(enderecoDescriptor, "complemento")}
            />
          </Group>
          <Group gap="md">
            <TextInput
              type="text"
              style={{ flex: 1 }}
              {...form.getInputProps("endereco.bairro")}
              {...extractTextInputPropertiesFromFormSchema(enderecoDescriptor, "bairro")}
            />
            <TextInput
              type="text"
              style={{ flex: 1 }}
              {...form.getInputProps("endereco.cidade")}
              {...extractTextInputPropertiesFromFormSchema(enderecoDescriptor, "cidade")}
            />
            <Select
              data={estadosData}
              style={{ flex: 1 }}
              {...form.getInputProps("endereco.estado")}
              {...extractTextInputPropertiesFromFormSchema(enderecoDescriptor, "estado")}
            />
          </Group>
        </Fieldset>
      </Box>
      <Space h="md" />
      <Group justify={"space-between"} align="center">
        <Select
          data={status}
          {...form.getInputProps("status")}
          {...extractTextInputPropertiesFromFormSchema(empresaFieldsDescriptor, "status")}
        />
        <Group justify={genericError ? "space-between" : "flex-end"} mt="md" align="center">
          {genericError && (
            <Alert icon={<IconAlertCircle size="1rem" />} color="red">
              {genericError}
            </Alert>
          )}

          <Button type="submit" loading={isPending}>
            Salvar
          </Button>
        </Group>
      </Group>
    </form>
  );
};

export default EmpresaForm;

const status = [
  { label: "Ativo", value: "ATIVO" },
  { label: "Inativo", value: "INATIVO" }
];
