import { TransformedValues, useForm, yupResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { MutationOptions, useMutation } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { Asserts } from "yup";
import useUserStore from "@/modules/users/store";
import { addTipoDeInovacao, TTipoInovacaoForm, updatedTipoDeInovacao } from "../../firestore";
import tipoDeInovacaoSchema from "../../schema/tipo-de-inovacao-schema";

interface Tatica {
  id: string | number;
  nome: string;
  descricao: string;
}

type FormInputs = Asserts<typeof tipoDeInovacaoSchema>;
type TUpdateResponse = Awaited<ReturnType<typeof updatedTipoDeInovacao>>;
type TStartComiteInovacaoResponse = Awaited<ReturnType<typeof addTipoDeInovacao>>;

type TMutationOptions = Omit<
  MutationOptions<TUpdateResponse | TStartComiteInovacaoResponse, FirestoreError, TTipoInovacaoForm>,
  "mutationFn"
>;
const useAddTipoDeInovacaoForm = (
  data: TTipoInovacaoForm & Partial<Pick<TTipoDeInovacao, "id" | "refPath">>,
  taticas: Tatica[],
  categorias: TCategoriaDeInovacao[],
  mutationOptions: TMutationOptions = {}
) => {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);
  const form = useForm<FormInputs>({
    validate: yupResolver(tipoDeInovacaoSchema),
    initialValues: data?.id ? (data as TTipoInovacaoForm) : (tipoDeInovacaoSchema.getDefault() as TTipoInovacaoForm),
    transformValues: (values) => tipoDeInovacaoSchema.cast(values)
  });
  const { mutate, isPending, reset } = useMutation<
    TUpdateResponse | TStartComiteInovacaoResponse,
    FirestoreError,
    TTipoInovacaoForm
  >({
    mutationFn(values) {
      const categoriaId = categorias?.find((item) => item.id === values.categoria)?.id;
      const categoriaNome = categorias?.find((item) => item.id === values.categoria)?.nome;
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      if (categoriaId === undefined) {
        throw new Error("Categoria não encontrada");
      }

      if (categoriaNome === undefined) {
        throw new Error("Categoria não encontrada");
      }

      return data?.id
        ? updatedTipoDeInovacao(activeEmpresaId, data.id, {
            ...values,
            taticas: taticas.map((item) => ({
              id: item.id,
              nome: item.nome,
              descricao: item.descricao
            })),
            nomeCategoria: categoriaNome,
            categoria: categoriaId
          })
        : addTipoDeInovacao(activeEmpresaId, {
            ...values,
            taticas: taticas.map((item) => ({
              id: item.id,
              nome: item.nome,
              descricao: item.descricao
            })),
            nomeCategoria: categoriaNome,
            categoria: categoriaId
          });
    },
    onSuccess() {
      showNotification({
        message: `${data.id ? "Atualizado" : "Cadastrado"} com sucesso!`,
        color: "green",
        autoClose: 2500
      });
      reset();
      form.reset();
      return null;
    },
    ...mutationOptions
  });

  type TTransformedValues = TransformedValues<typeof form>;
  /**
   * Handle form submit event
   * @param values - Form values
   */
  function onSubmit(values: TTransformedValues) {
    mutate(values);
  }

  return { ...form, isLoading: isPending, onSubmit: form.onSubmit(onSubmit) };
};

export default useAddTipoDeInovacaoForm;
