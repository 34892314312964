import { useForm, yupResolver, TransformedValues } from "@mantine/form";
import { useMutation } from "@tanstack/react-query";
import { FirebaseError } from "firebase/app";
import { useNavigate } from "react-router-dom";
import { Asserts } from "yup";
import { updateUserPassword } from "@/services/auth";
import { captureException } from "@/services/log";
import changePasswordSchema from "./change-password-schema";

type ChangePasswordSchemaForm = Asserts<typeof changePasswordSchema>;
type UpdatePasswordResponse = Awaited<ReturnType<typeof updateUserPassword>>;

/**
 * @returns The form properties
 */
function useChangePasswordForm() {
  const navigate = useNavigate();
  const form = useForm<ChangePasswordSchemaForm>({
    validate: yupResolver(changePasswordSchema),
    initialValues: changePasswordSchema.getDefault() as ChangePasswordSchemaForm,
    transformValues: (values) => changePasswordSchema.cast(values)
  });

  const { mutate, isPending } = useMutation<UpdatePasswordResponse, FirebaseError, ChangePasswordSchemaForm>({
    mutationFn: (values) => updateUserPassword(values.password),
    onSuccess() {
      navigate("/", {
        replace: true
      });
    },
    onError(error) {
      captureException(error, true);
    }
  });

  type TPasswordFormValuesTransformed = TransformedValues<typeof form>;
  /**
   * Handles the form submission
   * @param values - The values to be submitted
   */
  function onSubmit(values: TPasswordFormValuesTransformed) {
    mutate(values);
  }

  return { ...form, isPending, onSubmit: form.onSubmit(onSubmit) };
}

export default useChangePasswordForm;
