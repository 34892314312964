import { Alert, Box, Button, Group, LoadingOverlay, TextInput } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { extractTextInputPropertiesFromFormSchema } from "@/utils/form";
import changePasswordSchema from "./change-password-schema";
import useChangePasswordForm from "./useChangePasswordForm";

const ChangePasswordForm = () => {
  const { getInputProps, errors, onSubmit } = useChangePasswordForm();
  const schemaDescription = changePasswordSchema.describe();

  const genericError = errors[""];

  const isLoading = false;

  return (
    <form onSubmit={onSubmit}>
      <Box pos="relative">
        <LoadingOverlay visible={isLoading} />
        <TextInput
          type="password"
          autoComplete="new-password"
          {...getInputProps("password")}
          {...extractTextInputPropertiesFromFormSchema(schemaDescription, "password")}
        />
        <TextInput
          type="password"
          mt="sm"
          autoComplete="confirm-password"
          {...getInputProps("confirmPassword")}
          {...extractTextInputPropertiesFromFormSchema(schemaDescription, "confirmPassword")}
        />
      </Box>

      <Group justify={genericError ? "space-between" : "flex-end"} mt="md">
        {genericError && (
          <Alert icon={<IconAlertCircle size="1rem" />} color="red">
            {genericError}
          </Alert>
        )}
        <Button type="submit" loading={isLoading}>
          Finalizar
        </Button>
      </Group>
    </form>
  );
};

export default ChangePasswordForm;
