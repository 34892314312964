import { Anchor, Space } from "@mantine/core";
import { NavLink } from "react-router-dom";
import BreadcrumbsNavigation from "@/components/breadcrumbs-navigation";
import QuestionarioDiagnosticoList from "@/modules/analisar-questionario-list/components/questionario-list";
import CardColaborador from "@/modules/analisar-respostas/cards/CardColaborador";
// prettier-ignore
const AnalisarListaQuestionarioPage = () => {
  return <>
    <BreadcrumbsNavigation notHome={true}>
      <Anchor component={NavLink} to="/analisarRespostas">
        Home
      </Anchor>
    </BreadcrumbsNavigation>
    <CardColaborador />
    <Space h={"md"} />
    <QuestionarioDiagnosticoList />
  </>
};

export default AnalisarListaQuestionarioPage;
