import { UnstyledButton, UnstyledButtonProps } from "@mantine/core";
import { modals } from "@mantine/modals";
import { ForwardedRef, MouseEvent, PropsWithChildren, forwardRef } from "react";
import { ObjectSchema } from "yup";

type TModalName = Parameters<typeof modals.openContextModal>[0]["modal"];
type EditItemButtonProperties<T extends object> = PropsWithChildren<
  UnstyledButtonProps & { item?: T; schema: ObjectSchema<T>; modal: TModalName }
>;

/**
 * A button that opens a modal to edit an item.
 * @param root0 - The properties of the button.
 * @param root0.item - The item to be edited.
 * @param root0.modal - The modal to be opened.
 * @param root0.schema - The schema of the item.
 * @param root0.children - The children of the button.
 * @param reference - The reference to the button.
 * @returns The element to be rendered.
 */
function EditItemButton<T extends object>(
  { item, modal, schema, children, ...properties }: EditItemButtonProperties<T>,
  reference: ForwardedRef<HTMLButtonElement>
) {
  /**
   * Handles the click event on the button.
   * @param event - The event that triggered the click.
   */
  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    const defaultValues = schema.getDefault() as T;
    event.preventDefault();
    modals.openContextModal({
      modal,
      size: "xl",
      innerProps: {
        item: {
          ...defaultValues,
          ...item
        }
      },
      ml: "110px"
    });
  }

  return (
    <UnstyledButton ref={reference} {...properties} onClick={handleClick}>
      {children}
    </UnstyledButton>
  );
}

const EditItemButtonWithReference = forwardRef(EditItemButton);

EditItemButtonWithReference.displayName = "EditItemButton";

// export default createPolymorphicComponent(EditItemButtonWithReference);
export default EditItemButtonWithReference;
