import { httpsCallable } from "firebase/functions";
import { Asserts } from "yup";
import { functions } from "@/base";
import inviteEmpresaSchema from "./components/invite-empresa-form/invite-empresa-schema";

type InviteEmpresaInputs = Asserts<typeof inviteEmpresaSchema> & {
  licenciadaId?: string | undefined;
  empresaId: string;
};
export const inviteEmpresa = httpsCallable<InviteEmpresaInputs, never>(functions, "empresasFunctions-inviteEmpresa");
