import { useState, useEffect } from "react";
import { parseMarkdown } from "@/utils/text";

/**
 * Parse a markdown text to html
 * @param text - The markdown text to parse
 * @returns - The parsed markdown text as html
 */
function useParsedMarkdownText(text: string) {
  const [markdown, setMarkdown] = useState<string | undefined>(text);
  useEffect(() => {
    if (text === markdown) {
      void parseMarkdown(text).then(setMarkdown);
    }
  }, [text, markdown]);

  return markdown;
}

export default useParsedMarkdownText;
