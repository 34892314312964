import { getQuestionariosArrayNew } from "@/modules/grupos-diagnostico/firestore";

/**
 * Get questionário de inovação query hook
 * @param data - data
 * @param data.data - data
 * @returns Questionário de inovação query hook
 */
// prettier-ignore
async function getConvitesLoad({ data }: { data: TConvite[] | undefined }): Promise<TQuestionario[]> {
  const questionarios: TQuestionario[][] = [];
    if (data && data.length > 0) {
      for (const item of data) {
        const activeEmpresaId = (item && item?.idEmpresa) || "";
        const idProjeto = item && item?.idProjeto;

        const newData = await getQuestionariosArrayNew(activeEmpresaId, idProjeto);
        const questionarioData = newData.docs.map((document_) => document_.data())

        if (questionarioData) {
          questionarios.push(questionarioData.map(row => ({
            ...row,
            colaboradorId:item.idColaboradorConvidado
          })));
        }
      }

      return questionarios.flat();
    }

    return [];
}

export default getConvitesLoad;
