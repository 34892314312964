import { Container } from "@mantine/core";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useGetProjetoItem from "@/modules/analisar-resposta/components/questionario-item/useGetProjeto";
import { getLicenciados } from "@/modules/projetos/firestore";
import PerguntasTipo from "@/modules/responder-questionario/components/pergunta-tipos";
import useGetPerguntaConvite from "./useGetPerguntaConvite";

const message = "";

// prettier-ignore
const PerguntasItem = ({ ids, questionario, resposta, empresaConvidada }: { ids: string[] | undefined, questionario: TQuestionario | null | undefined, resposta: TResposta[] | undefined | null, empresaConvidada: string | null | undefined }) => {
  const { idEmpresa } = useParams<{ idEmpresa: string }>();
  const { data: perguntas } = useGetPerguntaConvite({ ids, activeEmpresaId: empresaConvidada || idEmpresa });
  const [licenciado, setLicenciado] = useState({});
  const { data: projeto } = useGetProjetoItem({ idProjeto: questionario?.idProjeto, empresaConvidada });

  useEffect(() => {
    // eslint-disable-next-line sonarjs/no-identical-functions
    const fetchData = async () => {
      try {
        await loadLicenciados();
      } catch (error) {
        console.error(message, error);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises

    void fetchData();

  }, [empresaConvidada, idEmpresa, projeto?.licenciado])

  // eslint-disable-next-line sonarjs/no-identical-functions
  const loadLicenciados = async () => {
    const arrayLicenciados = await getLicenciados(empresaConvidada || idEmpresa || "");
    const aux = arrayLicenciados.docs.map((desafio) => desafio.data())
    setLicenciado(aux.find(item => item?.id === projeto?.licenciado) as TLicenciado)
  }

  return <Container fluid h={"65vh"} w={"100vw"} bg="#fff">
    <PerguntasTipo perguntas={ordemPerguntas(perguntas)} questionario={questionario} resposta={resposta} licenciado={licenciado as TLicenciado} empresaConvidada={empresaConvidada} />
  </Container>
};

export default PerguntasItem;
// prettier-ignore
const ordemPerguntas = (data: TPergunta[] | undefined) => {
  return data?.sort((a, b) => a?.ordem - b?.ordem);
}
