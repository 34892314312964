import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { DESAFIOS_COLLECTION_KEY, getDesafio } from "@/modules/desafios/firestore";
import useUserStore from "@/modules/users/store";

type TGetDesafioResponse = Awaited<ReturnType<typeof getDesafio>>;

/**
 * Get desafio from url params
 * @returns - Desafio or null if not found
 */
function useGetDesafioFromUrl() {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const { desafioId } = useParams<{ desafioId: string }>();

  return useQuery<TGetDesafioResponse, FirestoreError, TDesafio | null>({
    queryKey: [DESAFIOS_COLLECTION_KEY, desafioId, activeEmpresaId],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!desafioId) {
        throw new Error("Desafio não selecionada");
      }
      return getDesafio(activeEmpresaId, desafioId);
    },
    select(dataResponse) {
      if (!dataResponse.exists()) {
        return null;
      }
      return dataResponse.data();
    },
    enabled: !!desafioId && !!activeEmpresaId
  });
}

export default useGetDesafioFromUrl;
