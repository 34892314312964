import { Group, Anchor } from "@mantine/core";
import { NavLink, useParams } from "react-router-dom";
import useGetConvites from "@/components/app-container/useGetConvites";
import BreadcrumbsNavigation from "@/components/breadcrumbs-navigation";
// import BannerComponent from "./BannerComponent";
import useGetRespostaConvite from "./useGetRespostaConvite";
import useQueryGroupConvite from "./useQueryGroupConvite";
import PerguntasItem from "../perguntas";
// prettier-ignore
const Questionario = ({ data }: { data: TQuestionario | null | undefined, }) => {
  const { idRespondente } = useParams<{ idRespondente: string }>();
  const { data: convite } = useGetConvites();
  const { idEmpresa } = useParams<{ idEmpresa: string }>();
  const { data: grupos } = useQueryGroupConvite({ id: data?.id, activeEmpresaId: convite && convite[0] && convite[0].idEmpresa || idEmpresa });
  const { data: resposta } = useGetRespostaConvite({ id: idRespondente, idQuestionario: data?.id, convite });

  return (
    <>
      <BreadcrumbsNavigation>
        <Anchor component={NavLink} to="/questionarioDeInovacao">
          Questionário
        </Anchor>
      </BreadcrumbsNavigation>
      <Group>
        <PerguntasItem ids={orderGrupos(grupos)?.map((item) => item.id)} questionario={data} resposta={resposta} empresaConvidada={convite && convite[0] && convite[0].idEmpresa} />
      </Group>
    </>
  );
};

export default Questionario;
// prettier-ignore
const orderGrupos = (data: TGruposDiagnostico[] | undefined) => {
  return data?.sort((a, b) => a.ordemQuestionario - b.ordemQuestionario);
}
