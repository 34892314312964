import { Anchor, Breadcrumbs, Button, Group } from "@mantine/core";
import { IconArrowBack } from "@tabler/icons-react";
import { PropsWithChildren } from "react";
import { NavLink, useNavigate } from "react-router-dom";

type BreadcrumbsNavigationProperties = PropsWithChildren & {
  notHome?: boolean;
};

const BreadcrumbsNavigation = ({ children, notHome }: BreadcrumbsNavigationProperties) => {
  const navigate = useNavigate();

  /**
   * Handles the back button click.
   */
  function handleBackClick() {
    navigate(-1);
  }

  return (
    <Group my="lg">
      <Button size="xs" onClick={handleBackClick} leftSection={<IconArrowBack size="1rem" />}>
        Voltar
      </Button>

      <Breadcrumbs>
        {!notHome && (
          <Anchor component={NavLink} to="/">
            Home
          </Anchor>
        )}
        {children}
      </Breadcrumbs>
    </Group>
  );
};

export default BreadcrumbsNavigation;
