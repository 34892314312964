import { subject } from "@casl/ability";
import {
  CollectionReference,
  FirestoreDataConverter,
  QuerySnapshot,
  collection,
  doc,
  getAggregateFromServer,
  getDoc,
  getDocs,
  serverTimestamp,
  setDoc,
  sum
} from "firebase/firestore";
import { getDesafioDocumentReference } from "../desafios/firestore";

const DESAFIO_ACTIVITY_PER_COLABORADOR_COLLECTION_KEY = "desafiosActivitiesPerColaborador" as const;

export type TDesafioActivityPerColaboradorForm = TFormWithTransformations<TDesafioActivityPerColaborador>;

type TDesafioActivityPerColaboradorDocument = TFirestoreDocument<TDesafioActivityPerColaborador>;

export const desafioActivityPerColaboradorConverter: FirestoreDataConverter<TDesafioActivityPerColaborador> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const { createdAt, updatedAt, ...document } = snap.data() as TDesafioActivityPerColaboradorDocument;

    const data: TDesafioActivityPerColaborador = {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      refPath: snap.ref.path
    };

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }

    return subject("TDesafioActivityPerColaborador", data);
  }
};

/**
 * Get desafiosActivitiesPerColaborador collection reference from empresaId
 * @param empresaId - Empresa id to get the desafios collection reference
 * @param desafioId - Desafio id to get the desafios collection reference
 * @returns - desafiosActivitiesPerColaborador collection reference
 */
export function getDesafioActivitiesPerColaboradorCollectionReference(
  empresaId: TEmpresa["id"],
  desafioId: TDesafio["id"]
) {
  const desafioDocumentReference = getDesafioDocumentReference(empresaId, desafioId);
  return collection(desafioDocumentReference, DESAFIO_ACTIVITY_PER_COLABORADOR_COLLECTION_KEY);
}

/**
 * Get the activity document reference from the colaborador in the desafio
 * @param empresaId - Empresa ID
 * @param desafioId - Desafio ID
 * @param colaboradorId - Colaborador ID
 * @returns - The activity document reference from the colaborador in the desafio
 */
function getDesafioActivitiesPerColaboradorDocumentReference(
  empresaId: TEmpresa["id"],
  desafioId: TDesafio["id"],
  colaboradorId: TColaborador["id"]
) {
  const desafioActivitiesPerColaboradorCollection = getDesafioActivitiesPerColaboradorCollectionReference(
    empresaId,
    desafioId
  );
  return doc(desafioActivitiesPerColaboradorCollection, colaboradorId).withConverter(
    desafioActivityPerColaboradorConverter
  );
}

/**
 * Get the activity from the colaborador in the desafio
 * @param empresaId - Empresa ID
 * @param desafioId - Desafio ID
 * @param colaboradorId - Colaborador ID
 * @returns - The activity from the colaborador in the desafio
 */
export function getDesafioActivitiesPerColaborador(
  empresaId: TEmpresa["id"],
  desafioId: TDesafio["id"],
  colaboradorId: TColaborador["id"]
) {
  const desafioActivityPerColaboradorDocumentReference = getDesafioActivitiesPerColaboradorDocumentReference(
    empresaId,
    desafioId,
    colaboradorId
  ).withConverter(desafioActivityPerColaboradorConverter);

  return getDoc(desafioActivityPerColaboradorDocumentReference);
}

/**
 * Get the total moedas from desafio
 * @param empresaId - Empresa ID
 * @param desafioId - Desafio ID
 * @returns - The activity document reference from the colaborador in the desafio
 */
export function getSumMoedasPerDesafio(empresaId: TEmpresa["id"], desafioId: TDesafio["id"]) {
  const desafioActivitiesPerColaboradorCollection = getDesafioActivitiesPerColaboradorCollectionReference(
    empresaId,
    desafioId
  );

  return getAggregateFromServer(desafioActivitiesPerColaboradorCollection, {
    moedas: sum("moedas")
  });
}

/**
 * Get the activity from the colaborador in the desafio
 * @param empresaId - Empresa ID
 * @param colaboradorId - Colaborador ID
 * @param desafiosQuerySnapshot - Desafios query snapshot to get the total moedas
 * @returns - Total moedas for the colaborador in the desafios
 */
export async function getTotalMoedasSpentForColaborador(
  empresaId: TEmpresa["id"],
  colaboradorId: TColaborador["id"],
  desafiosQuerySnapshot: QuerySnapshot<TDesafio>
) {
  if (desafiosQuerySnapshot.empty) {
    return 0;
  }

  let totalMoedas = 0;
  for await (const desafio of desafiosQuerySnapshot.docs) {
    const desafioActivityPerColaboradorDocumentReference = getDesafioActivitiesPerColaboradorDocumentReference(
      empresaId,
      desafio.id,
      colaboradorId
    );
    const desafioActivityPerColaboradorDocument = await getDoc(desafioActivityPerColaboradorDocumentReference);
    const desafioActivityPerColaborador = desafioActivityPerColaboradorDocument.data();
    if (desafioActivityPerColaboradorDocument.exists() && desafioActivityPerColaborador) {
      totalMoedas += desafioActivityPerColaborador.moedas ?? 0;
    }
  }

  return totalMoedas;
}

/**
 * Update the desafio activity per colaborador
 * @param empresaId - The empresa ID
 * @param desafioId - the desafio ID to update activity per colaborador
 * @param colaboradorId - The colaborador ID to update activity per colaborador
 * @param patrocinio - The patrocinio to add
 * @returns - desafio activity per colaborador reference
 */
export function setPatrocinio(
  empresaId: TEmpresa["id"],
  desafioId: TDesafio["id"],
  colaboradorId: TColaborador["id"],
  patrocinio: Pick<TDesafioActivityPerColaboradorForm, "moedas"> & Pick<TAuditForm, "createdBy" | "updatedBy">
) {
  const desafioActivitiesPerColaboradorCollectionReference = getDesafioActivitiesPerColaboradorDocumentReference(
    empresaId,
    desafioId,
    colaboradorId
  );
  return setDoc(
    desafioActivitiesPerColaboradorCollectionReference,
    { ...patrocinio, createdAt: serverTimestamp(), updatedAt: serverTimestamp() },
    { merge: true }
  );
}

/**
 *
 * @param empresaId - Empresa ID
 * @param desafioId - Desafio ID
 * @returns - Desafios activities per colaborador collection reference
 */
function getAllDesafiosActivitiesPerColaboradorCollectionReference(
  empresaId: TEmpresa["id"],
  desafioId: TDesafio["id"]
): CollectionReference<TDesafioActivityPerColaborador> {
  return collection(
    getDesafioDocumentReference(empresaId, desafioId),
    "desafiosActivitiesPerColaborador"
  ) as CollectionReference<TDesafioActivityPerColaborador>;
}

/**
 * Sum all moedas from desafios activities per colaborador
 * @param empresaId - Empresa ID to get the desafios collection reference
 * @param desafioId - Desafio ID to get the desafios activities per colaborador collection reference
 * @returns - Total moedas for the desafio
 */
export async function getTotalMoedasForDesafioAggregation(empresaId: TEmpresa["id"], desafioId: TDesafio["id"]) {
  const desafiosActivitiesPerColaboradorCollectionReference = getAllDesafiosActivitiesPerColaboradorCollectionReference(
    empresaId,
    desafioId
  );
  const querySnapshot = await getDocs(desafiosActivitiesPerColaboradorCollectionReference);
  let totalMoedasForDesafio: number = 0;
  for (const document_ of querySnapshot.docs) {
    totalMoedasForDesafio += document_.data().moedas ?? 0;
  }

  return totalMoedasForDesafio;
}
