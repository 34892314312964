import { subject } from "@casl/ability";
import { PaginationState, SortingState } from "@tanstack/react-table";
import {
  DocumentReference,
  FirestoreDataConverter,
  Timestamp,
  collection,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
  setDoc,
  and,
  addDoc
} from "firebase/firestore";
import { firestore } from "@/base";
import { getCurrentUser } from "@/services/auth";
import { generateQuery } from "@/utils/firestore";
import { getEmpresaDocumentReference } from "../empresas/firestore";

const DEFAULT_LIMIT = 10;
export const DEFAULT_PAGINATION: PaginationState = { pageIndex: 0, pageSize: DEFAULT_LIMIT };
export const DEFAULT_SORTING: SortingState = [{ id: "nome", desc: false }];

export type TColaboradorFormFields = Omit<
  TColaborador,
  | "id"
  | "refPath"
  | "createdAt"
  | "updatedAt"
  | "createdBy"
  | "updatedBy"
  | "inviteSentAt"
  | "inviteAcceptedAt"
  | "deletedAt"
> &
  Partial<Pick<TColaborador, "id" | "refPath">>;

export type TColaboradorDatabaseFields = Pick<TColaborador, "createdBy" | "updatedBy"> & {
  createdAt: ReturnType<typeof serverTimestamp>;
  updatedAt?: ReturnType<typeof serverTimestamp>;
  inviteSentAt?: ReturnType<typeof serverTimestamp> | null;
  inviteAcceptedAt?: ReturnType<typeof serverTimestamp> | null;
  deletedAt: ReturnType<typeof serverTimestamp> | null;
};
export type TColaboradorForm = TColaboradorFormFields & TColaboradorDatabaseFields;

export type TColaboradorDocument = Omit<
  TColaborador,
  "createdAt" | "updatedAt" | "inviteSentAt" | "inviteAcceptedAt" | "deletedAt" | "dataNascimento"
> & {
  createdAt: Timestamp;
  updatedAt?: Timestamp;
  inviteSentAt?: Timestamp | null;
  inviteAcceptedAt?: Timestamp | null;
  deletedAt: Timestamp | null;
  dataNascimento?: Timestamp;
};

export const colaboradorConverter: FirestoreDataConverter<TColaborador> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const { createdAt, updatedAt, inviteAcceptedAt, inviteSentAt, deletedAt, dataNascimento, ...document } =
      snap.data() as TColaboradorDocument;

    const data: TColaborador = subject("TColaborador", {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      deletedAt: deletedAt ? deletedAt.toDate() : null,
      refPath: snap.ref.path
    });

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }
    if (inviteSentAt) {
      data.inviteSentAt = inviteSentAt.toDate();
    }
    if (inviteAcceptedAt) {
      data.inviteAcceptedAt = inviteAcceptedAt.toDate();
    }
    if (dataNascimento) {
      data.dataNascimento = dataNascimento.toDate();
    }
    return data;
  }
};

/**
 * Get colaboradores collection reference from empresaId
 * @param empresaId - Empresa id to get the colaboradores collection reference
 * @returns - Colaboradores collection reference
 */
function getColaboradoresCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, "colaboradores");
}

/**
 * Get colaboradores collection reference from empresaId
 * @param empresaId - Empresa id to get the colaboradores collection reference
 * @param colaboradorId - Colaborador id to get the colaboradores collection reference
 * @returns - Colaboradores collection reference
 */
export function getColaboradoresDocumentReference(empresaId: TEmpresa["id"], colaboradorId: TColaborador["id"]) {
  const colaboradoresCollectionReference = getColaboradoresCollectionReference(empresaId);
  return doc(colaboradoresCollectionReference, colaboradorId);
}

/**
 * Get Colaborador document reference from path
 * @param path - Path to get the Colaborador document reference
 * @returns - Colaborador document reference
 */
export function getColaboradorDocumentReferenceFromPath(path: string) {
  return doc(firestore, path).withConverter(colaboradorConverter);
}

/**
 * Get colaborador
 * @param colaboradorDocumentReference - Colaborador document reference
 * @returns - Colaborador document
 */
export function getColaboradorFromDocument(colaboradorDocumentReference: DocumentReference<TColaborador>) {
  return getDoc(colaboradorDocumentReference);
}

/**
 * Get all colaboradores documents from empresaId excluding deleted ones
 * @param empresaId - Empresa id to get the colaboradores documents
 * @returns - Colaboradores documents
 */
export function getAllActiveColaboradores(empresaId: TEmpresa["id"]) {
  const colaboradoresCollectionReference =
    getColaboradoresCollectionReference(empresaId).withConverter(colaboradorConverter);
  const q = query(colaboradoresCollectionReference, where("deletedAt", "==", null));
  return getDocs(q);
}

/**
 * Get all colaboradores documents from empresaId excluding deleted ones
 * @param empresaId - Empresa id to get the colaboradores documents
 * @returns - Colaboradores documents
 */
export function getAllColaboradoresAtivosENaoExcluidos(empresaId: TEmpresa["id"]) {
  const colaboradoresCollectionReference =
    getColaboradoresCollectionReference(empresaId).withConverter(colaboradorConverter);
  const q = query(
    colaboradoresCollectionReference,
    and(where("deletedAt", "==", null), where("status", "!=", "DISABLED"))
  );
  return getDocs(q);
}

/**
 * Get colaboradores documents from empresaId
 * @param empresaId - Empresa id to get the colaboradores documents
 * @param options - Options to get the colaboradores documents
 * @param options.sorting - Sorting options
 * @param options.filters - Filters options
 * @param options.pagination - Pagination options
 * @param options.paginationCursors - Array of pagination cursors (startAt, endAt)[]
 * @returns - Colaboradores documents
 */
export function getColaboradores(
  empresaId: TEmpresa["id"],
  {
    sorting = [],
    filters = [],
    pagination = { pageIndex: 0, pageSize: DEFAULT_LIMIT },
    paginationCursors
  }: TPaginatedQueryOptions<TColaborador> = {}
): Promise<TPaginatedQueryResponse<TColaborador>> {
  const colaboradoresCollectionReference =
    getColaboradoresCollectionReference(empresaId).withConverter(colaboradorConverter);

  const qAll = generateQuery(colaboradoresCollectionReference, { sorting, filters });
  const qPaginated = generateQuery(colaboradoresCollectionReference, {
    sorting,
    filters,
    pagination,
    paginationCursors
  });

  // eslint-disable-next-line compat/compat
  return Promise.all([getDocs(qPaginated), getCountFromServer(qAll)]);
}

/**
 * Delete a colaborador to the database
 * @param empresaId - Empresa id to delete the colaborador
 * @param colaborador - Colaborador to delete
 * @returns - Promise with the colaborador reference
 */
export function deleteColaborador(empresaId: TEmpresa["id"], colaborador: TColaborador) {
  const colaboradorDocumentReference = getColaboradoresDocumentReference(empresaId, colaborador.id);
  return updateDoc(colaboradorDocumentReference, {
    deletedAt: serverTimestamp()
  });
}

/**
 * Get a colaborador document snapshot
 * @param empresaId - Empresa id to get the colaborador
 * @param colaboradorId - Colaborador id to get the colaborador
 * @returns - Colaborador document snapshot
 */
export function getColaborador(empresaId: TEmpresa["id"], colaboradorId: TColaborador["id"]) {
  const colaboradorDocumentReference = getColaboradoresDocumentReference(empresaId, colaboradorId).withConverter(
    colaboradorConverter
  );
  return getDoc(colaboradorDocumentReference);
}

/**
 * Update a empresa to the database
 * @param empresaId - Empresa id
 * @param colaboradorId - Colaborador id to update
 * @param colaborador - Colaborador data
 * @returns - Promise with the colaborador reference
 */
export function updateColaborador(
  empresaId: TEmpresa["id"],
  colaboradorId: TColaborador["id"],
  colaborador: TColaboradorForm
) {
  const colaboradorDocumentReference = getColaboradoresDocumentReference(empresaId, colaboradorId).withConverter(
    colaboradorConverter
  );
  const currentUserId = getCurrentUser()?.uid;
  return updateDoc(colaboradorDocumentReference, {
    ...colaborador,
    updatedAt: serverTimestamp(),
    updatedBy: currentUserId
  });
}

/**
 * Add a new colaborador to the given empresa
 * @param empresaId - Empresa id to add the colaborador
 * @param colaborador - Colaborador data
 * @returns - Colaborador document reference
 */
// prettier-ignore
export async function addColaborador(
  empresaId: TEmpresa["id"],
  colaborador: TColaboradorForm
) {
  const colaboradoresCollectionReference = getColaboradoresCollectionReference(empresaId);
  const { id, ...colaboradorData } = colaborador;
  const documentReference = doc(colaboradoresCollectionReference, id); // Usa o ID existente para a referência do documento
  // Adiciona o documento com o ID fornecido e o campo id com o mesmo valor
  await setDoc(documentReference, { ...colaboradorData, id });
  return documentReference.withConverter(colaboradorConverter);
}

/**
 * Add a new colaborador to the given empresa
 * @param empresaId - Empresa id to add the colaborador
 * @param colaborador - Colaborador data
 * @returns - Colaborador document reference
 */
export async function createColaborador(empresaId: TEmpresa["id"], colaborador: TColaboradorForm) {
  const colaboradoresCollectionReference = getColaboradoresCollectionReference(empresaId);
  const newColaborador = await addDoc(colaboradoresCollectionReference, colaborador);
  return newColaborador.withConverter(colaboradorConverter);
}
