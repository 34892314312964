import { Button, Flex, Group, NumberInput, Space, Textarea } from "@mantine/core";
import { DateInput, DateValue } from "@mantine/dates";
import { QueryObserverResult } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useState } from "react";
import { extractTextInputPropertiesFromFormSchema } from "@/utils/form";
import CardSolucao from "./CardSolucao";
import useCronogramaForm from "./useDesafioComentarioForm";
import cronogramaSchema from "../../schema";

type DesafioComentarioFormProperties = {
  desafio: TDesafio;
  onSuccess?: () => void;
  refetch: () => Promise<QueryObserverResult>;
};

interface MyState {
  id?: number;
  data: Date | null;
  descricao: string;
  orientacaoLocal: string;
}

// prettier-ignore
const DesafioCronogramaForm = ({ desafio, onSuccess, refetch }: DesafioComentarioFormProperties) => {
  const fieldsDescriptor = cronogramaSchema.describe();
  const [values, setValues] = useState({ data: null, descricao: "", orientacaoLocal: "" })
  const [event, setEvent] = useState<MyState[]>([])

  const { form, isPending, onSubmit } = useCronogramaForm({
    desafio,
    refetch,
    onSuccess() {
      if (onSuccess) {
        onSuccess();
      }
      setEvent([]);
      form.setFieldValue('inicioIncricao', new Date());
      form.setFieldValue('terminoIncricao', new Date());
      form.setFieldValue('quantidadeDeVagas', 1);
    },
    event
  });
  // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
  const addSugestoes = () => {
    setEvent((event: MyState[]) => ([...event, { ...values, id: Math.random() }]))
    setValues({ data: null, descricao: "", orientacaoLocal: "" })
  };

  return (
    <form onSubmit={onSubmit}>
      <Flex direction="column" pb="xs">
        <Group align={"flex-end"}>
          <DateInput
            {...form.getInputProps("inicioIncricao")}
            {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "inicioIncricao")}
            valueFormat="DD/MM/YYYY HH:mm:ss"
            minDate={new Date()}
          />
          <DateInput
            {...form.getInputProps("terminoIncricao")}
            {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "terminoIncricao")}
            valueFormat="DD/MM/YYYY HH:mm:ss"
            minDate={form.values.inicioIncricao || new Date()}
          />
          <NumberInput
            {...form.getInputProps("quantidadeDeVagas")}
            {...extractTextInputPropertiesFromFormSchema(fieldsDescriptor, "quantidadeDeVagas")}
          />
        </Group>
        <Space h={"md"} />
        <Group justify="space-between" align="flex-end">
          <Group align="center" style={{ flexGrow: 1 }}>
            <DateInput
              label="Data da Imersão"
              onChange={changeDate("data", setValues)}
              value={values.data}
              valueFormat="DD/MM/YYYY HH:mm:ss"
              minDate={form.values.terminoIncricao || new Date()}
              style={{ width: 200 }}
            />
            <Textarea
              label="Descrição da Imersão"
              autosize
              minRows={1}
              maxRows={5}
              style={{ flexGrow: 1 }}
              onChange={changeText("descricao", setValues)}
              value={values.descricao}
            />
            <Textarea
              label="Orientações sobre o local de Imersão"
              autosize
              minRows={1}
              maxRows={5}
              style={{ flexGrow: 1 }}
              onChange={changeText("orientacaoLocal", setValues)}
              value={values.orientacaoLocal}
            />
          </Group>
          <Button
            variant="filled"
            onClick={addSugestoes}
            disabled={values.data === null || values.descricao === "" || values.orientacaoLocal === ""}
          >
            Adicionar
          </Button>
        </Group>
        <Space h={"md"} />
        {event && event.length > 0 ? event.map((item: MyState) => <CardSolucao key={item.id} item={item} removeFilter={removeFilter} setEvent={setEvent} />) : <></>}
      </Flex>
      <Space h={"md"} />
      <Group justify="flex-end" mr="sm">
        <Button type="submit" loading={isPending}>
          Salvar
        </Button>
      </Group>
    </form>
  );
};

export default DesafioCronogramaForm;
// prettier-ignore
const changeDate = (field: string, setValues: React.Dispatch<React.SetStateAction<MyState>>) => {
  return (event: DateValue) => {
    setValues((values) => ({ ...values, [field]: event }))
  };
};
// prettier-ignore
const changeText = (field: string, setValues: React.Dispatch<React.SetStateAction<MyState>>) => {
  return (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValues((values) => ({ ...values, [field]: event.target.value }))
  };
};
// prettier-ignore
const removeFilter = (id: number | undefined, setEvent: Dispatch<SetStateAction<MyState[]>>) => {
  setEvent((event) => event.filter(item => item.id !== id));
};
