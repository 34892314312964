import { Card, Grid, Space, Text } from "@mantine/core";
import ComitesInovacaoHistory from "@/modules/comite-inovacao/components/comites-inovacao-history";
import StatusComiteInovacao from "@/modules/comite-inovacao/components/status-comite-inovacao";
import useCountIdeiasByStatusQuery from "@/modules/ideias/hooks/useCountIdeiasByStatusQuery";
import IdeiasFilterButtonLoadingCol from "./IdeiasFilterButtonLoadingCol";
import IdeiaStatusCount from "./IdeiaStatusCount";
import { allStatus } from "../ideias-de-inovacao-page/IdeiasDeInovacaoPage";
// prettier-ignore
const ComitesDeInovacaoPage = () => {
  const { data: statusCount, isLoading: isLoadingStatusCounts } = useCountIdeiasByStatusQuery(
    Object.keys(allStatus) as TIdeia["status"][]
  );

  return (
    <>
      <Card withBorder>
        <Card.Section inheritPadding py="md" withBorder>
          <StatusComiteInovacao title="Comitê de Inovação" />
        </Card.Section>
        <Card.Section inheritPadding py="md">
          <Text fz="lg">Ideias para análise no comitê</Text>
          <Space h="lg" />
          <Card withBorder>
            {isLoadingStatusCounts && (
              <Grid>
                {Object.entries(allStatus).map(([status, { text, color }]) => (
                  <Grid.Col span="content" key={status}>
                    <IdeiasFilterButtonLoadingCol text={text} color={color} />
                  </Grid.Col>
                ))}
              </Grid>
            )}
            {statusCount && (
              <Grid>
                {Object.entries(statusCount).map(([status, total]: [TIdeia["status"], number]) => (
                  <Grid.Col span="content" key={status}>
                    <IdeiaStatusCount {...(allStatus[status] || {})} total={total} />
                  </Grid.Col>
                ))}
              </Grid>
            )}
          </Card>
        </Card.Section>
      </Card>
      <Card withBorder mt="md">
        <Text fz="lg">Histórico dos Comitês</Text>
        <ComitesInovacaoHistory />
      </Card>
    </>
  );
};

export default ComitesDeInovacaoPage;
