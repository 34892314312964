import AddItemButton from "@/components/add-item-button";
import { TModalProperties } from "@/components/add-item-button/AddItemButton";
import historicoPontosPerColaboradorSchema from "@/modules/historico-pontos-per-colaborador/schema";

const modalProperties: TModalProperties = {
  modal: "add-bonus-form-modal"
};

const AdicionarBonusButton = ({ colaborador }: { colaborador: TColaborador }) => {
  return (
    <AddItemButton
      modalProperties={modalProperties}
      schema={historicoPontosPerColaboradorSchema}
      item={{ colaboradorReference: colaborador.refPath }}
    >
      Adicionar Bônus
    </AddItemButton>
  );
};

export default AdicionarBonusButton;
