import { showNotification } from "@mantine/notifications";
import { FirebaseError } from "firebase/app";
import { FirestoreError } from "firebase/firestore";

/**
 * Capture an exception and send it to the server
 * @param exception - The exception to capture
 * @param displayNotification - The notification to display
 */
// eslint-disable-next-line complexity
export function captureException(exception: FirebaseError | Error, displayNotification: string | boolean = false) {
  const isUserNotFoundError = exception instanceof FirebaseError && exception.code === "auth/user-not-found";
  const isPasswordWrong = exception instanceof FirebaseError && exception.code === "auth/wrong-password";
  const isAppCheckError = exception instanceof FirebaseError && exception.code === "appCheck/fetch-status-error";
  const isPermissionError = exception instanceof FirestoreError && exception.code === "permission-denied";

  let errorMessage = exception.message;
  if (isUserNotFoundError) {
    errorMessage = "Usuário não encontrado";
  } else if (isPasswordWrong) {
    errorMessage = "Usuário e/ou senha incorretos";
  } else if (isAppCheckError) {
    errorMessage = "Falha na verificação do aplicativo";
  } else if (isPermissionError) {
    errorMessage = "Permissão negada";
  }

  if (displayNotification) {
    showNotification({
      message: displayNotification === true ? errorMessage : displayNotification,
      color: "red",
      withCloseButton: true,
      autoClose: 3500
    });
  }
}
