import { Text, FocusTrap } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import ProjetoForm from "../../components/projeto-form";
import { TProjetoForm } from "../../firestore";

const ProjetoFormModal = ({ context, innerProps, id }: ContextModalProps<{ item: TProjetoForm }>) => {
  const { item: projeto } = innerProps;
  /**
   * Handle add ignore area success
   */
  function onSuccess() {
    context.closeModal(id);
  }

  return (
    <FocusTrap active={true}>
      <Text fw="bold" mb="md">
        Cadastro de projeto
      </Text>
      <ProjetoForm projeto={projeto} onSuccess={onSuccess} />
    </FocusTrap>
  );
};

export default ProjetoFormModal;
