import { Avatar, Flex, Group, Rating, Space, Text, Tooltip } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import { useCallback } from "react";

interface ArrayProperties {
  nome?: string;
  value: number;
  descricao: string;
  id: string;
  tipoDeResposta: string;
  resposta?: string | number | Campo | Campo[] | boolean;
  pergunta?: boolean;
  obrigatoria: boolean;
}

interface Campo {
  campo: string;
  valor: string;
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
// prettier-ignore
const RatingComponent = ({ item, changeValues, disabled, licenciado }: { item: ArrayProperties, changeValues: (value: string | number, item: ArrayProperties) => void, disabled: boolean, licenciado: TLicenciado | null | undefined }) => {

  const handleChangeRating = useCallback((value: number) => {
    if (!disabled) {
      changeValues(value, item);
    }
  }, [changeValues, item]);

  return (
    <Flex justify={"center"} align="center" direction="column" w={"80vw"} style={{ overflow: "auto" }}>
      {item.obrigatoria ? <>
        <Tooltip label="Está pergunta é obrigatória">
          <IconInfoCircle style={{ cursor: "pointer" }} />
        </Tooltip>
        <Space h="md" />
      </>
        : <></>}
      {licenciado && licenciado.logo && licenciado?.logo?.length > 0 ?
        <>
          <Group>
            {licenciado.logo.map(item =>
              <>
                <Avatar src={item?.path} radius="sm" size="lg" variant="default" />
                <Space h="md" />
              </>
            )}
          </Group>
          <Space h="md" />
        </>
        : <></>}
      <Text fw={700} size="lg">{item?.nome}</Text>
      <Space h="md" />
      <Text size="md">{item?.descricao}</Text>
      <Space h="md" />
      <Rating onChange={handleChangeRating} value={item?.resposta as number || 0} count={10} />
      <Space h="md" />
    </Flex>

  )
};

export default RatingComponent;
