import { Group, Skeleton } from "@mantine/core";
import { useEffect } from "react";
import AvatarsColaboradores from "@/modules/colaboradores/components/avatars-colaboradores";
import { captureException } from "@/services/log";
import useGetAllParticipantesFromComite from "./useGetAllParticipantesFromComite";

const MAX_PARTICIPANTES_COUNT = 4;

const ParticipantesComiteInovacaoAvatars = ({ comite }: { comite: TComiteInovacao }) => {
  const { data: participantes, isLoading, error } = useGetAllParticipantesFromComite(comite);
  useEffect(() => {
    if (error) {
      captureException(error, true);
    }
  }, [error]);

  if (isLoading) {
    return (
      <Group gap={0}>
        <Skeleton height={30} circle mb="xl" />
        <Skeleton height={30} circle mb="xl" />
        <Skeleton height={30} circle mb="xl" />
      </Group>
    );
  }

  if (!participantes) {
    return null;
  }

  return <AvatarsColaboradores colaboradores={participantes} maxVisible={MAX_PARTICIPANTES_COUNT} />;
};

export default ParticipantesComiteInovacaoAvatars;
