import { subject } from "@casl/ability";
import { SortingState, PaginationState } from "@tanstack/react-table";
import {
  FirestoreDataConverter,
  collection,
  getCountFromServer,
  getDocs,
  getDoc,
  doc,
  DocumentReference,
  DocumentData,
  Timestamp
} from "firebase/firestore";
import * as Yup from "yup";
import { firestore } from "@/base";
import { generateQuery } from "@/utils/firestore";
import { getColaboradorFromDocument } from "../colaboradores/firestore";
import { getEmpresaDocumentReference } from "../empresas/firestore";
import { getCountIdeiaComentarios } from "../ideia-comentarios/firestore";
import { ideiaConverter } from "../ideias/firestore";
import ideiaSchema from "../ideias/schema/ideia-schema";
import { getSumMoedasPerIdeia } from "../ideias-activities-per-colaborador/firestore";
import { getCountIdeiaLikes } from "../ideias-likes/firestore";

// Cache local em memória.
const colaboradorCache = new Map<string | undefined, TColaborador | undefined>();

interface TIdeiaExtended
  extends Omit<
    TIdeia,
    | "categoriaReference"
    | "regulamentoReference"
    | "autorReference"
    | "participantesReferences"
    | "status"
    | "implementadoresReferences"
    | "cancelAt"
    | "publishedAt"
    | "deletedAt"
    | "updatedAt"
    | "createdAt"
  > {
  moedas?: number;
  propositorNome?: string;
  propositorEmail?: string;
  curtidas?: number;
  comentarios?: number;
  regulamento?: string;
  createdAt?: string;

  regulamentoReference: string | DocumentReference<TRegulamentoIdeias, DocumentData>;
  autorReference: string;
  participantesReferences: string[];
  implementadoresReferences: string[];

  status: string;
}

const IdeiaExtendedFormSchema = ideiaSchema.shape({
  moedas: Yup.number(),
  propositorNome: Yup.string(),
  propositorEmail: Yup.string(),
  curtidas: Yup.number(),
  comentarios: Yup.string(),
  regulamento: Yup.string(),
  createdBy: Yup.string()
});

export const IDEIAS_COLLECTION_KEY = "ideias" as const;
const DEFAULT_LIMIT = 10;
export const DEFAULT_SORTING: SortingState = [{ id: "createdAt", desc: true }];
export const DEFAULT_PAGINATION: PaginationState = { pageIndex: 0, pageSize: DEFAULT_LIMIT };

type TIdeiaDocument = TFirestoreDocument<TIdeiaExtended>;

const ideiaExtendedConverter: FirestoreDataConverter<TIdeiaExtended> = {
  toFirestore(data: TIdeiaExtended) {
    const { moedas, propositorNome, propositorEmail, ...dataToStore } = data;

    return {
      ...dataToStore,
      moedas,
      propositorNome,
      propositorEmail,
      regulamentoReference: data.regulamentoReference,
      autorReference: data.autorReference,
      status: data.status // Garantir que status é uma string
    };
  },
  fromFirestore(snapshot, options) {
    const {
      createdAt,
      moedas,
      comentarios,
      curtidas,
      propositorNome,
      propositorEmail,
      createdBy,
      participantesReferences,
      implementadoresReferences,
      regulamentoReference,
      autorReference,
      status,
      ...data
    } = snapshot.data(options) as TIdeiaDocument & {
      moedas: number;
      regulamentoReference: string | DocumentReference<TRegulamentoIdeias, DocumentData>;
    };

    const baseData = IdeiaExtendedFormSchema.cast(data, { stripUnknown: true, assert: false });

    const Ideia: TIdeiaExtended = {
      ...baseData,
      moedas,
      comentarios,
      curtidas,
      propositorNome,
      propositorEmail,
      createdBy,
      id: snapshot.id,
      refPath: snapshot.ref.path,
      createdAt:
        createdAt instanceof Timestamp
          ? createdAt.toDate().toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" })
          : createdAt?.toString(),
      status: status as TIdeiaStatus,
      autorReference,
      regulamentoReference: typeof regulamentoReference === "string" ? regulamentoReference : regulamentoReference.path,
      participantesReferences: participantesReferences ? participantesReferences.map((reference) => reference) : [],
      implementadoresReferences: implementadoresReferences
        ? implementadoresReferences.map((reference) => reference)
        : []
    };

    return subject("TIdeia", Ideia);
  }
};

/**
 * Get Ideias collection reference
 * @param empresaId  - Empresa id
 * @returns - Ideias collection reference
 */
function getIdeiasCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, IDEIAS_COLLECTION_KEY);
}

// Defina um tipo para a resposta sem paginação
interface TQueryResponse<T> {
  documentos: T[];
  totalCount: number;
}

/**
 * Get all Ideias from the database
 * @param empresaId - Empresa id
 * @param root0 - Query options
 * @param root0.sorting - Sorting options
 * @param root0.filters - Filters options
 * @param root0.globalFilters - Global filters
 * @returns - Promise with all Ideias
 */
export async function getAllIdeias(
  empresaId: string | undefined,
  { sorting = [], filters = [], globalFilters }: TPaginatedQueryOptions<TIdeia> = {}
): Promise<TQueryResponse<TIdeiaExtended>> {
  if (!empresaId) {
    throw new Error("Empresa id is required");
  }
  const queryIdeiasCollectionReference = getIdeiasCollectionReference(empresaId).withConverter(ideiaExtendedConverter);
  const qAll = generateQuery(queryIdeiasCollectionReference, { sorting, filters, globalFilters });
  const querySnapshot = await getDocs(qAll);
  const ideias: TIdeiaExtended[] = [];
  for (const document_ of querySnapshot.docs) {
    const data = ideiaExtendedConverter.fromFirestore(document_);
    ideias.push(data);
    // results.set(document_.id, data);
  }

  for (const ideia of ideias) {
    const propositores = await getPropositores(ideia);
    ideia.propositorEmail = propositores.propositorEmail;
    ideia.propositorNome = propositores.propositorNome;
    ideia.moedas = await getTotalMoedas(ideia, empresaId);
    const comentariosCount = await getCountIdeiaComentarios(empresaId, ideia.id);
    ideia.comentarios = comentariosCount.data().count;
    const likesCount = await getCountIdeiaLikes(empresaId, ideia.id);
    ideia.curtidas = likesCount.data().count;
    if (typeof ideia.regulamentoReference === "string") {
      const regulamentoReference = doc(firestore, ideia.regulamentoReference) as DocumentReference<
        TRegulamentoIdeias,
        DocumentData
      >;
      const regulamento = await getDoc(regulamentoReference);
      ideia.regulamento = regulamento.data()?.nome;
    } else {
      const regulamento = await getDoc(ideia.regulamentoReference);
      ideia.regulamento = regulamento.data()?.nome;
    }
  }
  const countSnapshot = await getCountFromServer(qAll);
  const totalCount = countSnapshot.data().count;

  return {
    documentos: ideias,
    totalCount
  };
}

/**
 * Get all Ideias from the database
 * @param empresaId - Empresa id
 * @param root0 - Query options
 * @param root0.sorting - Sorting options
 * @param root0.filters - Filters options
 * @param root0.globalFilters - Global filters
 * @param root0.pagination - Pagination options
 * @param root0.paginationCursors - Array of pagination cursors (startAt, endAt)[]
 * @returns - Promise with all Ideias
 */
export function getPaginatedIdeias(
  empresaId: string,
  {
    sorting = [],
    filters = [],
    globalFilters,
    pagination = { pageIndex: 0, pageSize: DEFAULT_LIMIT },
    paginationCursors
  }: TPaginatedQueryOptions<TIdeia> = {}
): Promise<TPaginatedQueryResponse<TIdeia>> {
  const queryIdeiasCollectionReference = getIdeiasCollectionReference(empresaId).withConverter(ideiaConverter);

  const qAll = generateQuery(queryIdeiasCollectionReference, { sorting, filters, globalFilters });
  const qPaginated = generateQuery(queryIdeiasCollectionReference, {
    sorting,
    filters,
    globalFilters,
    pagination,
    paginationCursors
  });

  // eslint-disable-next-line compat/compat
  return Promise.all([getDocs(qPaginated), getCountFromServer(qAll)]);
}

/**
 *
 * @param Ideia - Ideias
 * @param empresaId - empresa id
 * @returns - total de moedas
 */
async function getTotalMoedas(Ideia: TIdeiaExtended, empresaId: string) {
  const moedas = await getSumMoedasPerIdeia(empresaId, Ideia.id);
  return moedas.data().moedas;
}

/**
 *
 * @param ideia - Ideias
 * @returns - propositores
 */
async function getPropositores(ideia: TIdeiaExtended): Promise<{
  propositorEmail: string | undefined;
  propositorNome: string | undefined;
}> {
  const colaboradores = [ideia.autorReference, ...ideia.participantesReferences];
  const propositorData = { emails: [] as string[], nomes: [] as string[] };

  const processAutores = async () => {
    for (const valor of colaboradores) {
      const colaborador = await BuscaColaborador(valor as unknown as DocumentReference<TColaborador>);
      if (colaborador) {
        propositorData.nomes.push(colaborador.nome);
        propositorData.emails.push(colaborador.email);
      }
    }
  };

  await processAutores();

  return {
    propositorNome: propositorData.nomes.join(", "),
    propositorEmail: propositorData.emails.join(", ")
  };
}
/**
 *
 * @param colaboradorReference - ref para o colaborador
 * @returns - colaborador
 */
const BuscaColaborador = async (colaboradorReference: DocumentReference<TColaborador>) => {
  const cached = colaboradorCache.get(colaboradorReference.id);
  if (cached) {
    return cached;
  }

  return await getColaboradorFromDocument(colaboradorReference).then((colaborador) => {
    if (colaborador) {
      colaboradorCache.set(colaboradorReference.id, colaborador.data());
      return colaborador.data();
    }
    return null;
  });
};
