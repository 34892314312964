import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getEmpresaById } from "@/modules/empresas/firestore";

const QUERY_KEY = "empresas";
type TGetProjetoResponse = Awaited<ReturnType<typeof getEmpresaById>>;

/**
 * Get projeto from url params
 * @param activeEmpresaId - id da empresa
 * @param activeLicenciadaId - id da licenciada
 * @returns - projeto or null if not found
 */
function useGetEmpresaById(
  activeEmpresaId: TEmpresa["id"] | undefined,
  activeLicenciadaId?: TLicenciada["id"] | undefined
) {
  return useQuery<TGetProjetoResponse, FirestoreError, TEmpresa | null>({
    queryKey: [QUERY_KEY, activeEmpresaId, activeLicenciadaId],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return getEmpresaById(activeEmpresaId, activeLicenciadaId);
    },
    select(dataResponse) {
      if (!dataResponse.exists()) {
        return null;
      }

      return dataResponse.data();
    },
    enabled: !!activeEmpresaId
  });
}

export default useGetEmpresaById;
