import { Group, Space, Text } from "@mantine/core";
import useGetProjetoFromUrl from "./useGetProjetoFromUrl";
import BannerComponent from "../banner-component";
import FormInscricaoDoProjeto from "../form-incricao-projeto";

// prettier-ignore
const ProjetoExternoItem = () => {
  const { data } = useGetProjetoFromUrl();

  return (
    data ?
      <Group>
        <BannerComponent projeto={data} tipo={"1"} />
        <FormInscricaoDoProjeto projeto={data} />
        <Space h={"xl"} />
        <BannerComponent projeto={data} tipo={"2"} />
      </Group> : <Group justify={"center"} align={"center"} h={"100vh"}>
        <Text fw={700}>Projeto invalido!</Text>
      </Group>
  );
};

export default ProjetoExternoItem;
