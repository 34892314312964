import { Card, Flex, Space, Text } from "@mantine/core";

const CardTipos = ({
  titulo,
  tituloValor,
  descricao,
  descricaoValor
}: {
  titulo: string;
  tituloValor: string;
  descricao: string;
  descricaoValor: string;
}) => {
  return (
    <Card py="xs" style={{ background: "rgba(244, 245, 246, 1)" }}>
      <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
        <Text size="md" fw={500}>
          {titulo}
        </Text>
        <Text size="sm" c="dimmed">
          {tituloValor}
        </Text>
      </Flex>
      <Space h="lg" />
      <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
        <Text size="md" fw={500}>
          {descricao}
        </Text>
        <Text size="sm" c="dimmed">
          {descricaoValor}
        </Text>
      </Flex>
    </Card>
  );
};

export default CardTipos;
