import { TextInput, Group, NumberInput, Switch } from "@mantine/core";
import { DateValue, DatePickerInput } from "@mantine/dates";
import { useMemo } from "react";
import { extractTextInputPropertiesFromFormSchema } from "@/utils/form";
import { useRegulamentoIdeiasFormContext } from "./regulamento-ideias-form-context";
import regulamentoIdeiasSchema from "../../schema";

const RegulamentoIdeiasBaseInputs = () => {
  const form = useRegulamentoIdeiasFormContext();
  const schemaFieldsDescriptor = regulamentoIdeiasSchema.describe();

  /**
   * Handle período date change
   * @param dates - [dataInicial, dataFinal]
   */
  function onDateIniciaFinalChange(dates: [Date, Date]) {
    const [dataInicial, dataFinal] = dates;
    form.setFieldValue("dataInicial", dataInicial);
    form.setFieldValue("dataFinal", dataFinal);
  }
  const periodo: [DateValue, DateValue] = useMemo<[DateValue, DateValue]>(
    () => [form.values.dataInicial, form.values.dataFinal],
    [form.values.dataFinal, form.values.dataInicial]
  );

  const today = new Date();

  return (
    <>
      <TextInput
        type="text"
        style={{ flex: 1 }}
        data-autofocus
        {...form.getInputProps("nome")}
        {...extractTextInputPropertiesFromFormSchema(schemaFieldsDescriptor, "nome")}
      />
      <TextInput
        type="text"
        style={{ flex: 1 }}
        data-autofocus
        {...form.getInputProps("linkRegulamento")}
        {...extractTextInputPropertiesFromFormSchema(schemaFieldsDescriptor, "linkRegulamento")}
      />
      <Group grow>
        <DatePickerInput
          type="range"
          minDate={today}
          valueFormat="DD [de] MMMM, YYYY"
          value={periodo}
          onChange={onDateIniciaFinalChange}
          {...extractTextInputPropertiesFromFormSchema(schemaFieldsDescriptor, "dataInicial")}
          label="Período"
        />
        <NumberInput
          min={0}
          {...form.getInputProps("quantidadeMoedasPorColaborador")}
          {...extractTextInputPropertiesFromFormSchema(schemaFieldsDescriptor, "quantidadeMoedasPorColaborador")}
        />
      </Group>
      <Group grow>
        <NumberInput
          min={0}
          style={{ maxWidth: 200 }}
          {...form.getInputProps("quantidadeGanhoNoCadastro")}
          {...extractTextInputPropertiesFromFormSchema(schemaFieldsDescriptor, "quantidadeGanhoNoCadastro")}
        />
        <NumberInput
          min={0}
          style={{ maxWidth: 200 }}
          {...form.getInputProps("quantidadeGanhoNaAprovacao")}
          {...extractTextInputPropertiesFromFormSchema(schemaFieldsDescriptor, "quantidadeGanhoNaAprovacao")}
        />
        <NumberInput
          min={0}
          {...form.getInputProps("quantidadeGanhoNaImplementacao")}
          {...extractTextInputPropertiesFromFormSchema(schemaFieldsDescriptor, "quantidadeGanhoNaImplementacao")}
        />
        <NumberInput
          min={0}
          style={{ maxWidth: 380 }}
          {...form.getInputProps("quantidadeGanhoNaParticipacaoEImplementacaoIdeiaPropria")}
          {...extractTextInputPropertiesFromFormSchema(
            schemaFieldsDescriptor,
            "quantidadeGanhoNaParticipacaoEImplementacaoIdeiaPropria"
          )}
        />
        <NumberInput
          min={0}
          style={{ maxWidth: 380 }}
          {...form.getInputProps("quantidadeGanhoNaParticipacaoEImplementacaoIdeiaTerceiro")}
          {...extractTextInputPropertiesFromFormSchema(
            schemaFieldsDescriptor,
            "quantidadeGanhoNaParticipacaoEImplementacaoIdeiaTerceiro"
          )}
        />
      </Group>

      <Group>
        <Switch
          description="Permitir que os colaboradores definam se a ideia terá interação ou não"
          {...form.getInputProps("permitirControlarInteracao")}
          {...extractTextInputPropertiesFromFormSchema(schemaFieldsDescriptor, "permitirControlarInteracao")}
        />

        <Switch
          description="Permitir se será possível cadastrar ideias privadas"
          {...form.getInputProps("permitirIdeiaPrivada")}
          {...extractTextInputPropertiesFromFormSchema(schemaFieldsDescriptor, "permitirIdeiaPrivada")}
        />
      </Group>
    </>
  );
};

export default RegulamentoIdeiasBaseInputs;
