import { Card, Grid, Group, Stack, Text, ThemeIcon } from "@mantine/core";
import { IconChartArcs } from "@tabler/icons-react";
import useQueryComiteStatusByType from "@/modules/comite-inovacao/hooks/useQueryComiteStatusByType";
import StatusQuantityItem from "./StatusQuantityItem";

const QuantidadeRegulamentosDeDesafiosAlteradosCard = ({ comite }: { comite: TComiteInovacao }) => {
  const { data: activities, isLoading } = useQueryComiteStatusByType(
    comite.id,
    "REGULAMENTO_DESAFIO_CRIADO",
    "REGULAMENTO_DESAFIO_REMOVIDO",
    "REGULAMENTO_DESAFIO_CANCELADO"
  );

  const hasActivities = !isLoading && activities && activities.length > 0;

  if (!hasActivities) {
    return null;
  }

  const allCriados = activities.filter((activity) => activity.type === "REGULAMENTO_DESAFIO_CRIADO");
  const allRemovidos = activities.filter((activity) => activity.type === "REGULAMENTO_DESAFIO_REMOVIDO");
  const allCancelados = activities.filter((activity) => activity.type === "REGULAMENTO_DESAFIO_CANCELADO");

  return (
    <Card withBorder>
      <Group align="flex-start">
        <ThemeIcon size="md" radius="xl" mt={2}>
          <IconChartArcs width={18} radius="xl" />
        </ThemeIcon>
        <Stack gap={0}>
          <Text fz="lg">Mudanças nos regulamentos de desafios de inovação”</Text>
          <Grid mt="md">
            {allCriados.length > 0 && <StatusQuantityItem text="Criados" total={allCriados.length} color="green" />}
            {allCancelados.length > 0 && (
              <StatusQuantityItem text="Cancelados" total={allCancelados.length} color="orange" />
            )}
            {allRemovidos.length > 0 && <StatusQuantityItem text="Removidos" total={allRemovidos.length} color="red" />}
          </Grid>
        </Stack>
      </Group>
    </Card>
  );
};

export default QuantidadeRegulamentosDeDesafiosAlteradosCard;
