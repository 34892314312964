import { ActionIconProps, Mark, Text, UnstyledButton } from "@mantine/core";
import { modals } from "@mantine/modals";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { MouseEvent, PropsWithChildren, forwardRef } from "react";
import { captureException } from "@/services/log";
import { deleteEmpresa } from "../../firestore";
import { QUERY_KEY } from "../empresas-table/EmpresasTable";

type RemoveEmpresaButtonProperties = PropsWithChildren<ActionIconProps & { empresa: TEmpresa }>;
type TDeleteEmpresaResponse = Awaited<ReturnType<typeof deleteEmpresa>>;

const RemoveEmpresaButton = forwardRef<HTMLButtonElement, RemoveEmpresaButtonProperties>(
  ({ empresa, children, ...properties }, reference) => {
    const queryClient = useQueryClient();
    const { mutate: deleteEmpresaMutation, isPending } = useMutation<TDeleteEmpresaResponse, FirestoreError>({
      mutationFn: () => deleteEmpresa(empresa),
      onSuccess() {
        return queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      },
      onError(error) {
        captureException(error, true);
      }
    });

    /**
     * Handles the click event on the button.
     * @param event - The event that triggered the click.
     */
    function handleClick(event: MouseEvent<HTMLButtonElement>) {
      event.preventDefault();
      modals.openConfirmModal({
        title: "Remover empresa",
        centered: true,
        children: (
          <Text size="sm">
            Tem certeza que deseja remover a empresa <Mark>{empresa.razaoSocial}</Mark>
          </Text>
        ),
        labels: {
          confirm: "Sim",
          cancel: "Não"
        },
        confirmProps: { color: "red" },
        onConfirm: () => {
          void deleteEmpresaMutation();
        }
      });
    }

    return (
      <UnstyledButton ref={reference} disabled={isPending} {...properties} onClick={handleClick}>
        {children}
      </UnstyledButton>
    );
  }
);

RemoveEmpresaButton.displayName = "RemoveEmpresaButton";

export default RemoveEmpresaButton;
