import { Text, Anchor, Button, Card, Group, Tabs, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { Asserts } from "yup";
import BreadcrumbsNavigation from "@/components/breadcrumbs-navigation";
import AvatarsColaboradores from "@/modules/colaboradores/components/avatars-colaboradores";
import useGetColaboradoresFromReference from "@/modules/colaboradores/hooks/useGetColaboradoresFromReference";
import AtividadesList from "@/modules/ideia-marcos/components/atividades-list";
import {
  generateAllMarcosQuery,
  generateMarcosConcluidosQuery,
  generateMarcosEmAndamentoQuery,
  generateMarcosNaoIniciadaQuery,
  generateMarcosVencidosQuery
} from "@/modules/ideia-marcos/firestore";
import useUserStore from "@/modules/users/store";
import { formatDate } from "@/utils/date";
import filterSchema from "./filter-schema";
import FiltersDrawer from "./FiltersDrawer";

type FormInputs = Asserts<typeof filterSchema>;

const AtividadesPage = () => {
  const [activeTab, setActiveTab] = useState<string | null>("todas");
  const [filters, setFilters] = useState<FormInputs>(filterSchema.getDefault());
  const [opened, { open, close }] = useDisclosure(false);
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  const { data: colaboradoresResponsaveis } = useGetColaboradoresFromReference(filters.colaboradoresReferences);
  const [dataPrazoInicial, dataPrazoFinal] = filters.datePrazoRange || [];

  const activeQuery = useMemo(() => {
    if (!activeEmpresaId) {
      throw new Error("activeEmpresaId is required");
    }

    switch (activeTab) {
      case "todas": {
        return generateAllMarcosQuery(activeEmpresaId, filters.colaboradoresReferences);
      }
      case "continuar-atividade": {
        return generateMarcosEmAndamentoQuery(activeEmpresaId, filters.colaboradoresReferences);
      }
      case "aguardando-participacao": {
        return generateMarcosNaoIniciadaQuery(activeEmpresaId, filters.colaboradoresReferences);
      }
      case "vencidas": {
        return generateMarcosVencidosQuery(activeEmpresaId, filters.colaboradoresReferences);
      }
      case "concluidas": {
        return generateMarcosConcluidosQuery(activeEmpresaId, filters.colaboradoresReferences);
      }
      default: {
        throw new Error(`Invalid tab: ${activeTab}`);
      }
    }
  }, [activeEmpresaId, activeTab, filters.colaboradoresReferences]);

  return (
    <>
      <BreadcrumbsNavigation>
        <Anchor component={NavLink} to="/minhas-atividades">
          Atividades
        </Anchor>
      </BreadcrumbsNavigation>
      <Card withBorder>
        <Title order={3} fw={500}>
          Atividades
        </Title>

        <Group my="md">
          <Button type="button" size="sm" variant="light" onClick={open}>
            Filtrar
          </Button>
          <Group>
            <Group gap="sm">
              {colaboradoresResponsaveis && <AvatarsColaboradores colaboradores={colaboradoresResponsaveis} />}
              {dataPrazoInicial && dataPrazoFinal && (
                <Text>
                  Prazo: {formatDate(dataPrazoInicial, { dateStyle: "short" })} -{" "}
                  {formatDate(dataPrazoFinal, { dateStyle: "short" })}
                </Text>
              )}
            </Group>
          </Group>
        </Group>

        <Tabs value={activeTab} onChange={setActiveTab} mb="md">
          <Tabs.List>
            <Tabs.Tab value="todas">Todos</Tabs.Tab>
            <Tabs.Tab value="continuar-atividade">Continuar atividade</Tabs.Tab>
            <Tabs.Tab value="aguardando-participacao">Aguardando participação</Tabs.Tab>
            <Tabs.Tab value="vencidas">Vencidas</Tabs.Tab>
            <Tabs.Tab value="concluidas">Concluídas</Tabs.Tab>
          </Tabs.List>
        </Tabs>

        <AtividadesList query={activeQuery} activeTab={activeTab} />
      </Card>

      <FiltersDrawer opened={opened} onClose={close} filters={filters} onFiltersApplied={setFilters} />
    </>
  );
};

export default AtividadesPage;
