import { subject } from "@casl/ability";
import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { getComiteInovacaoActivitiesByType } from "@/modules/comite-inovacao-activities/firestore";
import useUserStore from "@/modules/users/store";

type TGetComiteInovacaoActivitiesByType = Awaited<ReturnType<typeof getComiteInovacaoActivitiesByType>>;

/**
 * Query comite status by type
 * @param comiteId - Comite inovação id
 * @param types - Comite inovação activity types
 * @returns - Comite inovação activity
 */
function useQueryComiteStatusByType(comiteId: TComiteInovacao["id"], ...types: TComiteInovacaoActivity["type"][]) {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);
  return useQuery<TGetComiteInovacaoActivitiesByType, FirestoreError, TComiteInovacaoActivity[]>({
    queryKey: ["empresas", { id: activeEmpresaId }, "comitesInovacao", { id: comiteId }, ...types],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return getComiteInovacaoActivitiesByType(activeEmpresaId, comiteId, ...types);
    },
    select(dataResponse) {
      if (dataResponse.empty) {
        return [];
      }

      return dataResponse.docs.map((document_) => subject("TComiteInovacaoActivity", document_.data()));
    }
  });
}

export default useQueryComiteStatusByType;
