import { Text, FocusTrap } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { ContextModalProps } from "@mantine/modals";
import CampanhaRegulamentoIdeiasForm from "./CampanhaRegulamentoIdeiasForm";
import { TCampanhaRegulamentoIdeiasForm } from "../../firestore";

const CampanhaRegulamentoIdeiasFormModal = ({
  id,
  context,
  innerProps
}: ContextModalProps<{
  item: TCampanhaRegulamentoIdeiasForm;
  onSubmit: (values: TCampanhaRegulamentoIdeiasForm, form: UseFormReturnType<TCampanhaRegulamentoIdeiasForm>) => void;
}>) => {
  const { item: campanhaRegulamentoIdeias, onSubmit } = innerProps;

  /**
   * Handle form submit
   * @param values - Form values to submit
   * @param form - Form instance
   */
  function handleSubmit(
    values: TCampanhaRegulamentoIdeiasForm,
    form: UseFormReturnType<TCampanhaRegulamentoIdeiasForm>
  ) {
    onSubmit(values, form);
    context.closeModal(id);
  }

  return (
    <FocusTrap active={true}>
      <Text fw="bold" mb="md">
        {campanhaRegulamentoIdeias.id ? "Editar" : "Nova"} campanha de incentivo
      </Text>
      <CampanhaRegulamentoIdeiasForm campanhaRegulamentoIdeias={campanhaRegulamentoIdeias} onSubmit={handleSubmit} />
    </FocusTrap>
  );
};

export default CampanhaRegulamentoIdeiasFormModal;
