import { Button } from "@mantine/core";
import { QueryKey, useIsFetching, useQueryClient } from "@tanstack/react-query";
import { Table } from "@tanstack/react-table";
import { MouseEvent, PropsWithChildren, forwardRef, ForwardedRef } from "react";
import { useSearchParams } from "react-router-dom";

type ResetTableButtonProperties = PropsWithChildren<{ queryKey: QueryKey; table: Table<unknown> }>;

/**
 * The ResetTableButton component.
 * @param root0 - The component properties
 * @param root0.table - The table instance
 * @param root0.queryKey - The query key
 * @param root0.children - The button children
 * @param reference - The button reference
 * @returns The ResetTableButton component
 */
function ResetTableButton(
  { table, queryKey, children }: ResetTableButtonProperties,
  reference: ForwardedRef<HTMLButtonElement>
) {
  const [, setSearchParameters] = useSearchParams();
  const queryClient = useQueryClient();
  /**
   * Handle the click event
   * @param event - The event object
   */
  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    table.resetPagination();
    table.resetColumnFilters();
    setSearchParameters({ filter: "" });
    void queryClient.invalidateQueries({ queryKey });
  }

  const loadingCount = useIsFetching({ queryKey });

  return (
    <Button
      type="button"
      variant="subtle"
      color="orange"
      size="compact-sm"
      ref={reference}
      disabled={loadingCount > 0}
      onClick={handleClick}
    >
      {children}
    </Button>
  );
}

// ResetTableButton.displayName = "ResetTableButton";

export default forwardRef(ResetTableButton);
