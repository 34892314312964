import { Badge, Group, Text } from "@mantine/core";
import RankingTrendIcon from "@/components/ranking-trend-icon";

const RankingByMoedasBadge = ({ desafio }: { desafio: TDesafio }) => {
  if (!desafio.rankingByMoedas) {
    return null;
  }

  return (
    <Group align="center" gap="xs">
      <Badge color="gray.2" py="sm" radius="md" leftSection={<RankingTrendIcon ranking={desafio.rankingByMoedas} />}>
        <Text c="dark" fz="xs" fw={500} tt="lowercase">
          {desafio.rankingByMoedas.ranking}&#9900; no Ranking
        </Text>
      </Badge>
    </Group>
  );
};

export default RankingByMoedasBadge;
