import { Alert, Button, Flex, Group, Stack, Text, Textarea, Title } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { ContextModalProps } from "@mantine/modals";
import { IconAlertCircle } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";
import { PropsWithChildren } from "react";
import ColaboradoresInlineList from "@/modules/colaboradores/components/colaboradores-inline-list";
import { getIdeiaDocumentReferenceFromPath } from "@/modules/ideias/firestore";
import useUserStore from "@/modules/users/store";
import { formatDate } from "@/utils/date";
import { extractTestValueFromSchema, extractTextInputPropertiesFromFormSchema } from "@/utils/form";
import AtividadeAnexos from "./AtividadeAnexos";
import { AtuacaoFormProvider } from "./atuacao-form-context";
import marcoAtuacaoSchema from "./marco-atuacao-schema";
import useMarcoAtuacaoForm from "./useMarcoAtuacaoForm";
import { MARCOS_QUERY_KEY } from "../../hooks/useGetMarcoFromReferencePath";

const SessionItem = ({ title, children }: PropsWithChildren<{ title: string }>) => (
  <Stack gap="xs">
    <Text fz="sm" c="dimmed">
      {title}
    </Text>
    {children}
  </Stack>
);

const IniciarAtuacaoModal = ({ context, innerProps, id }: ContextModalProps<{ marco: TMarco }>) => {
  const { marco } = innerProps;
  const queryClient = useQueryClient();
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);

  const schemaFieldsDescriptor = marcoAtuacaoSchema.describe();

  const { onSubmit, isPending, form } = useMarcoAtuacaoForm(marco, {
    async onSuccess() {
      const ideiaDocumentReference = getIdeiaDocumentReferenceFromPath(marco.ideiaReference);
      // eslint-disable-next-line compat/compat
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [activeEmpresaId, "ideia", ideiaDocumentReference.id] }),
        queryClient.invalidateQueries({ queryKey: [MARCOS_QUERY_KEY, marco.refPath] }),
        queryClient.invalidateQueries({ queryKey: ["empresas", activeEmpresaId, "marcos"] })
      ]);
      context.closeModal(id);
    }
  });

  const genericError = form.errors[""];

  const todayAtBeginOfDay = extractTestValueFromSchema(schemaFieldsDescriptor, "dataConclusao", "min") as
    | Date
    | undefined;

  return (
    <AtuacaoFormProvider form={form}>
      <form onSubmit={onSubmit}>
        <Title mb="md">Iniciar atuação</Title>
        <Stack gap="lg">
          <Group>
            <Flex gap={"lg"}>
              <SessionItem title="Colaboradores responsáveis">
                <ColaboradoresInlineList colaboradores={marco.colaboradoresResponsaveis} />
              </SessionItem>

              <SessionItem title="Data limite para execução da atividade">
                {formatDate(marco.dataPrazo, { dateStyle: "short", timeStyle: undefined })}
              </SessionItem>
            </Flex>
          </Group>
          <SessionItem title="Descrição do marco">{marco.acoes}</SessionItem>
          <DateInput
            w="160px"
            minDate={todayAtBeginOfDay}
            valueFormat="DD/MM/YYYY"
            disabled={isPending}
            {...form.getInputProps("dataConclusao")}
            {...extractTextInputPropertiesFromFormSchema(schemaFieldsDescriptor, "dataConclusao")}
          />
          <Textarea
            disabled={isPending}
            {...form.getInputProps("descricaoAtuacao")}
            {...extractTextInputPropertiesFromFormSchema(schemaFieldsDescriptor, "descricaoAtuacao")}
          />
          <AtividadeAnexos isLoading={isPending} />
        </Stack>
        {genericError && (
          <Alert icon={<IconAlertCircle size="1rem" />} color="red" my="md">
            {genericError}
          </Alert>
        )}
        <Group justify="flex-end" mt="md">
          <Button type="submit" variant="outline" disabled={isPending}>
            Salvar Rascunho
          </Button>
          <Button type="submit" value="FINALIZAR" loading={isPending}>
            Finalizar
          </Button>
        </Group>
      </form>
    </AtuacaoFormProvider>
  );
};

export default IniciarAtuacaoModal;
