import { Box, LoadingOverlay } from "@mantine/core";
import { useParams } from "react-router-dom";

import useGetQuestionarioConviteFromUrl from "@/modules/responder-questionario/hooks/useGetQuestionarioConviteFromUrl";
import Questionario from "@/modules/vizualizar-questionario/components/questionario";
import useGetConvites from "../responder-questionario-externo-page/hooks/useGetConvites";

const VisualizarQuestionarioPage = () => {
  const { idEmpresa } = useParams<{ idEmpresa: string }>();
  const { data: newConvite } = useGetConvites(idEmpresa ?? "");
  const convite = newConvite
    ?.filter((item) => item.idQuestionario !== "")
    .map((item) => ({
      ...item,
      idEmpresa: newConvite.find((row) => row.idQuestionario === "")?.idEmpresa || item.idEmpresa
    }));
  const { data: data, isFetching } = useGetQuestionarioConviteFromUrl({
    activeEmpresaId: (convite && convite[0] && convite[0].idEmpresa) || idEmpresa
  });

  if (isFetching) {
    return (
      <Box pos="relative">
        <LoadingOverlay visible={isFetching} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
      </Box>
    );
  }

  return <Questionario data={data} />;
};

export default VisualizarQuestionarioPage;
