import { useQuery } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import { EmpresaQuestionarioConvite, getQuestionariosEmpresaConvidada } from "@/modules/convites/firestore";
import useUserStore from "@/modules/users/store";

type TGetQuestionariosEmpresaConvidadaResponse = Awaited<ReturnType<typeof getQuestionariosEmpresaConvidada>>;

const useGetQuestionariosEmpresaConvidada = () => {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  return useQuery<TGetQuestionariosEmpresaConvidadaResponse, FirestoreError, EmpresaQuestionarioConvite[] | null>({
    queryKey: [activeEmpresaId],
    queryFn() {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }

      return getQuestionariosEmpresaConvidada(activeEmpresaId);
    },
    select(dataResponse) {
      return dataResponse;
    },
    enabled: !!activeEmpresaId
  });
};

export default useGetQuestionariosEmpresaConvidada;
