import { getQuestionariosArray } from "@/modules/grupos-diagnostico/firestore";

/**
 * Get campanhas
 * @param activeEmpresaId - activeEmpresaId
 * @param idProjeto - id projeto
 * @returns - campanhas
 */
async function getQuestionario(
  activeEmpresaId: string | undefined,
  idProjeto: TProjeto["id"] | undefined | null
): Promise<TQuestionario[]> {
  if (activeEmpresaId) {
    const newData = await getQuestionariosArray(activeEmpresaId, [idProjeto || ""]);
    return newData.docs.map((questionario) => questionario.data());
  }

  return [];
}

export default getQuestionario;
