import { subject } from "@casl/ability";
// prettier-ignore
import { serverTimestamp, collection, addDoc, getDocs, query, where, Timestamp, FirestoreDataConverter, updateDoc, doc } from "firebase/firestore";
import { getCurrentUser } from "@/services/auth";
import { getEmpresaDocumentReference } from "../empresas/firestore";
export const DESAFIO_CRONOGRAMA_COLLECTION_KEY = "cronograma" as const;

// prettier-ignore
export type TCronogramaFormFields = Omit<
TCronogramaDesafio,
 "id" | "refPath" | "createdAt" | "updatedAt" | "createdBy" | "updatedBy" | "deletedAt" | "idDesafio" | "inscricoes"
> &
  Partial<Pick<TCronogramaDesafio, "id" | "refPath" | "idDesafio" | "inscricoes">>;

export type TCronogramaDatabaseFields = Pick<TCronogramaDesafio, "createdBy" | "updatedBy"> & {
  createdAt: ReturnType<typeof serverTimestamp>;
  updatedAt?: ReturnType<typeof serverTimestamp>;
  deletedAt: ReturnType<typeof serverTimestamp> | null;
};
export type TCronogramaForm = TCronogramaFormFields & TCronogramaDatabaseFields;
// prettier-ignore
type TCronogramaDocument = Omit<
TCronogramaDesafio,
  "createdAt" | "updatedAt" | "deletedAt"
> & {
  createdAt: Timestamp;
  updatedAt?: Timestamp;
  inicioIncricao: Timestamp;
  terminoIncricao: Timestamp;
  deletedAt: Timestamp | null;
};

const cronogramaConverter: FirestoreDataConverter<TCronogramaDesafio> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    // prettier-ignore
    const { createdAt, updatedAt, deletedAt, inicioIncricao, terminoIncricao, ...document } = snap.data() as TCronogramaDocument;

    const data: TCronogramaDesafio = {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      deletedAt: deletedAt ? deletedAt.toDate() : null,
      inicioIncricao: inicioIncricao.toDate(),
      terminoIncricao: terminoIncricao.toDate(),
      refPath: snap.ref.path
    };

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }

    return subject("TCronogramaDesafio", data);
  }
};

/**
 * Get cronograma collection reference from empresaId
 * @param empresaId - Empresa id to get the cronogramas collection reference
 * @returns - Cronogramas collection reference
 */
function getCronogramasCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, "cronogramaDesafio");
}

/**
 * Add a new cronograma to the given empresa
 * @param empresaId - Empresa id to add the cronograma
 * @param cronograma - cronograma data
 * @returns - cronograma document reference
 */
// prettier-ignore
export async function addCronograma(
  empresaId: TEmpresa["id"],
  cronograma: Omit<TCronogramaForm, "id" | "refPath">
) {
  const cronogramasCollectionReference = getCronogramasCollectionReference(empresaId);
  return addDoc(cronogramasCollectionReference, cronograma);
}

/**
 * Get cronograma document reference from empresaId
 * @param empresaId - Empresa id to get the cronograma document reference
 * @param cronogramaId - Cronograma id to get the cronograma document reference
 * @returns - Cronograma document reference
 */
function getCronogramasDocumentReference(empresaId: TEmpresa["id"], cronogramaId: TQuestionario["id"]) {
  const cronogramaCollectionReference = getCronogramasCollectionReference(empresaId);
  return doc(cronogramaCollectionReference, cronogramaId);
}

/**
 * Remove empresa from database
 * @param empresaId - Empresa id where the campanha is located
 * @param cronogramaId - Cronograma id to remove
 * @returns - Promise with the removed cronograma reference
 */
export async function removeCronograma(empresaId: TEmpresa["id"], cronogramaId: TCronogramaDesafio["id"]) {
  const cronogramaDocumentReference = getCronogramasDocumentReference(empresaId, cronogramaId).withConverter(
    cronogramaConverter
  );
  const currentUserId = getCurrentUser()?.uid;
  return updateDoc(cronogramaDocumentReference, { deletedAt: serverTimestamp(), updatedBy: currentUserId });
}

/**
 * Update a empresa to the database
 * @param empresaId - Empresa id
 * @param cronogramaId - Cronograma id to update
 * @param cronograma - Cronograma data
 * @returns - Promise with the cronograma reference
 */
export function updateQuestionario(
  empresaId: TEmpresa["id"],
  cronogramaId: TCronogramaDesafio["id"],
  cronograma: TCronogramaForm
) {
  const cronogramaDocumentReference = getCronogramasDocumentReference(empresaId, cronogramaId).withConverter(
    cronogramaConverter
  );
  const currentUserId = getCurrentUser()?.uid;
  return updateDoc(cronogramaDocumentReference, {
    ...cronograma,
    updatedAt: serverTimestamp(),
    updatedBy: currentUserId
  });
}

/**
 * Get all cronograma
 * @param empresaId - Empresa id
 * @returns - cronograma collection reference
 */
function getCronogramaCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, "cronogramaDesafio");
}

/**
 * Get cronograma query for a empresa
 * @param empresaId - Empresa id to get comitês
 * @returns - cronograma query
 */
function generateCronogramaQuery(empresaId: TEmpresa["id"]) {
  const currentDate = Timestamp.now();
  const cronogramaCollectionReference = getCronogramaCollectionReference(empresaId).withConverter(cronogramaConverter);
  return query(
    cronogramaCollectionReference,
    where("deletedAt", "==", null),
    where("terminoIncricao", ">=", currentDate)
  );
}

/**
 * Get cronograma for a empresa
 * @param empresaId - Empresa id to get comitês
 * @returns - cronograma document snapshot
 */
export function getCronogramaDesafio(empresaId: TEmpresa["id"]) {
  const q = generateCronogramaQuery(empresaId);
  return getDocs(q);
}

/**
 * Get cronograma query for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param idDesafio = id do desafio
 * @returns - cronograma query
 */
function generateCronogramaFilterQuery(empresaId: TEmpresa["id"], idDesafio: TDesafio["id"]) {
  const cronogramaCollectionReference = getCronogramaCollectionReference(empresaId).withConverter(cronogramaConverter);
  return query(cronogramaCollectionReference, where("deletedAt", "==", null), where("idDesafio", "==", idDesafio));
}

/**
 * Get cronograma for a empresa
 * @param empresaId - Empresa id to get comitês
 * @param idDesafio = id do desafio
 * @returns - cronograma document snapshot
 */
export function getCronogramaDesafioFilter(empresaId: TEmpresa["id"], idDesafio: TDesafio["id"]) {
  const q = generateCronogramaFilterQuery(empresaId, idDesafio);
  return getDocs(q);
}
