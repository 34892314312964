import { Card, Group, Stack, Tabs, Text, Input } from "@mantine/core";
import { IconBulb, IconBulbFilled, IconPlus, IconX, IconSearch } from "@tabler/icons-react";
import { and, doc, or, where } from "firebase/firestore";
import { RefObject, useEffect, useRef, useState, useCallback } from "react";
import { firestore } from "@/base";
import AddItemButton, { TModalProperties } from "@/components/add-item-button/AddItemButton";
import { THandler } from "@/components/table/Table";
import ColaboradorCard from "@/modules/colaboradores/components/colaborador-card";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import DesafiosTable from "@/modules/desafios/components/desafios-table";
import desafioFormSchema from "@/modules/desafios/schema";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import { dataAutorizacoes } from "../empresa-page/EmpresaPage";

const DAYS_AGO = 30;
const date30DaysAgo = new Date();
date30DaysAgo.setDate(date30DaysAgo.getDate() - DAYS_AGO);

const modalProperties: TModalProperties = {
  modal: "desafio-form-modal"
};

/**
 * Set the global filter to the table to only show minhas ideias and non deleted items
 * @param tableReference - Reference to the table handlers object
 */
// prettier-ignore
function useMinhasNonDeletedIdeiasGlobalFilters(tableReference: RefObject<THandler<TDesafio>>) {
  const colaborador = useUserStore((state) => state.colaborador);

  useEffect(() => {
    const tableHandlers = tableReference.current;
    if (tableHandlers && colaborador && tableHandlers.table.getState().globalFilter === null) {
      tableHandlers.table.setGlobalFilter(
        and(
          where("deletedAt", "==", null),
          or(
            where("participantesReferences", "array-contains", doc(firestore, colaborador.refPath)),
            where("createdBy", "==", colaborador.id)
          )
        )
      );
    }
  }, [colaborador, tableReference]);
}

/**
 * Set the global filter to the table to only show minhas ideias and non deleted items
 * @param tableReference - Reference to the table handlers object
 */
// prettier-ignore
function useNonDeletedGlobalFilters(tableReference: RefObject<THandler<TDesafio>>) {
  const colaborador = useUserStore((state) => state.colaborador);

  useEffect(() => {
    const tableHandlers = tableReference.current;
    if (tableHandlers && colaborador && tableHandlers.table.getState().globalFilter === null) {
      tableHandlers.table.setGlobalFilter(
        and(
          where("deletedAt", "==", null),
          where("published", "==", true)
        )
      );
    }
  }, [colaborador, tableReference]);
}

// prettier-ignore
const HomePage = () => {
  const SEARCH_TIMEOUT = 500;
  const tableHandlersDesafiosReference = useRef<THandler<TDesafio>>(null);
  useNonDeletedGlobalFilters(tableHandlersDesafiosReference);
  const [searchValue, setSearchValue] = useState("");
  const [, setSearchTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const tableHandlersMinhasIdeiasReference = useRef<THandler<TDesafio>>(null);
  useMinhasNonDeletedIdeiasGlobalFilters(tableHandlersMinhasIdeiasReference);

  const activeEmpresaId = useUserStore((store) => store.activeEmpresa);
  const activeAutorizacao = useUserStore((store) => store.autorizacoes);
  const uid = getCurrentUser()?.uid;
  const { data: userColaborador } = useGetColaboradorById(activeEmpresaId?.id || "", uid || "");
  const autorizacao = dataAutorizacoes.find(item => item.label === userColaborador?.role);
  const formatAutorizacoes = activeAutorizacao?.filter(item => item.tipo === autorizacao?.value);

  const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTimeoutId((state) => {
      if (state) {
        clearTimeout(state);
      }

      return setTimeout(() => {
        setSearchValue(event.target.value)
      }, SEARCH_TIMEOUT);
    });
  }, []);

  if (activeEmpresaId && activeEmpresaId?.status === "INATIVO") {
    return <Group justify={"center"} align="center">
      <IconX size="1rem" color={"red"} />
      <Text color={"red"} fw={"bold"}>Empresa inativa</Text>
    </Group>;
  }

  return (
    <Stack>
      <Group justify="space-around" align="stretch">
        <ColaboradorCard tipo="desafios" />
      </Group>

      {formatAutorizacoes?.find(item => item.tipoAutorizacao === "9")?.criacao ?
        <Group justify="space-between" >
          <Input
            onChange={handleSearchChange}
            placeholder="Pesquisar desafios"
            size="sm"
            radius="lg"
            leftSection={<IconSearch size="1rem" />}
          />

          <AddItemButton
            size="sm"
            leftSection={<IconPlus size="1rem" />}
            modalProperties={modalProperties}
            schema={desafioFormSchema}
          >
            Adicionar Desafio
          </AddItemButton>
        </Group>
        : <></>}
      <Card withBorder p={0}>
        <Tabs defaultValue="todas-as-ideias">
          <Tabs.List>
            <Tabs.Tab value="todas-as-ideias" leftSection={<IconBulbFilled />}>
              Todos os desafios
            </Tabs.Tab>
            <Tabs.Tab value="minhas-ideias" leftSection={<IconBulb />}>
              Meus desafios
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="todas-as-ideias">
            <DesafiosTable filterValue={searchValue} ref={tableHandlersDesafiosReference} />
          </Tabs.Panel>
          <Tabs.Panel value="minhas-ideias">
            <DesafiosTable filterValue={searchValue} ref={tableHandlersMinhasIdeiasReference} />
          </Tabs.Panel>
        </Tabs>
      </Card>
    </Stack>
  );
};

HomePage.displayName = "HomePage";

export default HomePage;
