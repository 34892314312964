import { RouteObject } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import PasswordChangePage from "./password-change-page";

const passwordChangeRoutes: RouteObject[] = [
  {
    path: "/",
    index: true,
    element: <PasswordChangePage />,
    errorElement: <ErrorPage />
  }
];

export default passwordChangeRoutes;
