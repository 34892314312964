import { Button, Group } from "@mantine/core";
import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { useCallback } from "react";

interface NColaboradores {
  nColaboradores: number;
  nRespostas: number;
}

/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable unicorn/prefer-spread */
// prettier-ignore
const ExportExcel = ({ empresas }: { empresas: (TEmpresa & NColaboradores)[] }) => {

  const handleExport = useCallback(async () => {
    const uniqueData = Array.from(new Set(empresas.map(item => item.id)))
      .map(id => {
        return empresas.find(item => item.id === id);
      });

    const formatEmpresa = uniqueData?.map((item) => {
      return [
        item?.nomeFantasia,
        item?.cnpj,
        item?.responsavel.nome,
        item?.responsavel.telefone,
        item?.porte,
        item?.endereco.estado,
        item?.nColaboradores,
        item?.nRespostas
      ]
    })

    await onExport(formatEmpresa);
  }, [empresas]);

  return <Group justify={"flex-end"}>
    {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
    <Button variant="filled" onClick={handleExport}>Exportar</Button>
  </Group>

};

export default ExportExcel;

/* eslint-disable sonarjs/cognitive-complexity */
// prettier-ignore
const onExport = async (csvData: (string | number | undefined)[][] | undefined) => {

  // Se csvData for undefined, interrompa a execução
  if (!csvData) return;

  const columns = ["Empresa", "Cnpj", "Responsavel", "Telefone", "Porte", "Estado", "Nº Convidados", "Nº Respostas"];

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Empresas');

  const headerRow = worksheet.addRow(columns);

  headerRow.eachCell((cell) => {
    cell.font = { bold: true };
  });

  if (Array.isArray(csvData)) {
    for (const rowData of csvData) {
      worksheet.addRow(Object.values(rowData)); // Removi a assertiva de tipo desnecessária
    }
  }

  try { // Adicionei um bloco try para capturar possíveis erros
    const buffer = await workbook.xlsx.writeBuffer(); // Aguardo a resolução da Promise
    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      `relatorio.xlsx`
    );
  } catch (error) { // Tratamento de erro
    console.error("Erro ao escrever buffer:", error);
  }
}
