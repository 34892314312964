import { Box, Group, Skeleton, Text, Title } from "@mantine/core";
import useGetColaboradorById from "@/modules/colaboradores/hooks/useGetColaboradorById";
import useUserStore from "@/modules/users/store";
import { getCurrentUser } from "@/services/auth";
import { dataAutorizacoes } from "@/src/routes/empresa-page/EmpresaPage";
import useGetFirstComiteInovacaoAtivo from "./useGetFirstComiteInovacaoAtivo";
import ComiteInovacaoStatusButtons from "../comite-inovacao-status-buttons";
import ParticipantesComiteInovacaoAvatars from "../participantes-comite-inovacao-avatars";
/* eslint-disable complexity */
// prettier-ignore
const StatusComiteInovacao = ({ title: titleDefault }: { title?: string }) => {
  const { data: comiteAtivo, isLoading } = useGetFirstComiteInovacaoAtivo();
  const activeEmpresaId = useUserStore((store) => store.activeEmpresa);
  const activeAutorizacao = useUserStore((store) => store.autorizacoes);
  const uid = getCurrentUser()?.uid;
  const { data: userColaborador } = useGetColaboradorById(activeEmpresaId?.id || "", uid || "");
  const autorizacao = dataAutorizacoes.find(item => item.label === userColaborador?.role);
  const formatAutorizacoes = activeAutorizacao?.filter(item => item.tipo === autorizacao?.value);
  let title = titleDefault;

  if (isLoading) {
    title = titleDefault || "Carregando...";
  } else if (comiteAtivo === undefined || comiteAtivo === null) {
    title = titleDefault || "Nenhum comitê agendado";
  } else if (comiteAtivo.pausedAt) {
    title = titleDefault || "Comitê pausado";
  } else if (comiteAtivo.endedAt) {
    title = titleDefault || "Comitê finalizado";
  } else if (comiteAtivo.startedAt) {
    title = titleDefault || "Comitê em andamento";
  } else {
    title = titleDefault;
  }

  return (
    <>
      <Group justify="space-between">
        <Title order={4} fw="normal">
          {title}
        </Title>
        {isLoading && <Skeleton height={32} radius="md" w={120} />}
        {comiteAtivo !== undefined && formatAutorizacoes?.find(item => item.tipoAutorizacao === "16")?.criacao && <ComiteInovacaoStatusButtons comite={comiteAtivo} />}
      </Group>
      {comiteAtivo && (
        <Box mt="md">
          <Text fz="lg">Participantes convidados</Text>
          <ParticipantesComiteInovacaoAvatars comite={comiteAtivo} />
        </Box>
      )}
    </>
  );
};

export default StatusComiteInovacao;
