import { Anchor, Card, Center, Loader, Text, Skeleton } from "@mantine/core";
import { NavLink, useParams } from "react-router-dom";
import BreadcrumbsNavigation from "@/components/breadcrumbs-navigation";
import RegulamentoIdeiasForm from "@/modules/regulamento-ideias/components/regulamento-ideias-form";
import { TRegulamentoIdeiasForm } from "@/modules/regulamento-ideias/firestore";
import regulamentoIdeiasSchema from "@/modules/regulamento-ideias/schema";
import useGetCampanhasRegulamentoIdeias from "./useGetCampanhasRegulamentoIdeias";
import useGetRegulamentoIdeiasById from "./useGetRegulamentoIdeiasById";

const RegulamentosIdeiaFormPage = () => {
  const { regulamentoIdeiaId } = useParams<"regulamentoIdeiaId">();
  const {
    data: regulamentoIdeias,
    isLoading: isLoadingRegulamento,
    isFetched
  } = useGetRegulamentoIdeiasById(regulamentoIdeiaId);
  const {
    data: campanhas,
    isLoading: isLoadingCampanhas,
    isFetched: isFetchedCampanhas
  } = useGetCampanhasRegulamentoIdeias(regulamentoIdeiaId);

  const isLoading = isLoadingRegulamento || isLoadingCampanhas;

  if (isLoading || (regulamentoIdeiaId && (!isFetched || !isFetchedCampanhas))) {
    return (
      <Center h={400}>
        <Loader />
      </Center>
    );
  }

  const regulamentoIdeiasForm = regulamentoIdeias
    ? regulamentoIdeiasSchema.cast(
        { ...regulamentoIdeias, campanhas },
        {
          stripUnknown: true,
          assert: false
        }
      )
    : (regulamentoIdeiasSchema.getDefault() as TRegulamentoIdeiasForm);

  return (
    <>
      <BreadcrumbsNavigation>
        <Anchor component={NavLink} to="/ideias/regulamentos">
          Regulamento de Ideias
        </Anchor>
        <Skeleton radius="md" height={12} visible={isLoadingRegulamento}>
          <Text c="dimmed" mt={-6}>
            {regulamentoIdeias?.nome || "Novo regulamento"}
          </Text>
        </Skeleton>
      </BreadcrumbsNavigation>
      <Card withBorder>
        <RegulamentoIdeiasForm regulamentoIdeias={regulamentoIdeiasForm} />
      </Card>
    </>
  );
};

export default RegulamentosIdeiaFormPage;
