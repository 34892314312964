import { serverTimestamp } from "firebase/firestore";
import { ObjectSchema } from "yup";
import schema from "@/schema";
import { getCurrentUser } from "@/services/auth";
import { TCategoriaDeDesafioForm, TCategoriaDeDesafioFormFields, TTCategoriaDeDesafio } from "../firestore";

const MIN_NAME_LENGTH = 3;
/**
 * Define only the meta fields that are not part of the form
 */
const categoriaDeDesafioSchemaDatabaseFields: ObjectSchema<TTCategoriaDeDesafio> = schema.object().shape({
  createdAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .required()
    .defined()
    .nonNullable()
    .default<ReturnType<typeof serverTimestamp>>(() => serverTimestamp()),
  createdBy: schema
    .string()
    .required()
    .defined()
    .nonNullable()
    .default(() => {
      const currentUserId = getCurrentUser()?.uid;
      if (!currentUserId) {
        throw new Error("The current user is not defined");
      }
      return currentUserId;
    }),
  updatedAt: schema
    .mixed<ReturnType<typeof serverTimestamp>>()
    .default<ReturnType<typeof serverTimestamp>>(() => serverTimestamp()),
  updatedBy: schema.string().default(() => {
    const currentUserId = getCurrentUser()?.uid;
    if (!currentUserId) {
      throw new Error("The current user is not defined");
    }
    return currentUserId;
  }),
  deletedAt: schema.mixed<ReturnType<typeof serverTimestamp>>().defined().nullable().default(null)
});

const categoriaDeDesafioSchemaFormFields: ObjectSchema<TCategoriaDeDesafioFormFields> = schema.object({
  id: schema.string().optional(),
  refPath: schema.string().optional(),
  nome: schema.string().min(MIN_NAME_LENGTH).required().defined().label("Nome")
});

const categoriaDeDesafioSchema: ObjectSchema<TCategoriaDeDesafioForm> = categoriaDeDesafioSchemaFormFields
  // eslint-disable-next-line unicorn/prefer-spread
  .concat(categoriaDeDesafioSchemaDatabaseFields)
  .noUnknown()
  .strict()
  .label("Categorias de Desafios");

export default categoriaDeDesafioSchema;
