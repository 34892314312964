import { Flex, Group, Loader, Space, Table, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { getRespostasMediaEmpresa } from "@/modules/analisar-respostas/firestore";
import useGetProjetoItem from "@/modules/diagnostico/components/questionario-item/useGetProjeto";
import { RankingListPaginator } from "@/modules/diagnostico/components/ranking-list/components/RankingListPaginator";
import { usePaginator } from "@/modules/diagnostico/components/ranking-list/hooks/usePaginator";
import { getConvites } from "@/modules/questionario-colaborador/firestore";
import { getRepostasArrayRelatorio } from "@/modules/responder-questionario/firestore";
import useUserStore from "@/modules/users/store";
import ExportExcel from "./ExportExcel";
import ModalFilter from "./ModalFilter";
import GetEmpresas from "./useGetEmpresa";
import TableColaboradoresItem from "../table-colaboradores-item";

const message = "Ocorreu um erro ao carregar os dados:";

interface Filtro {
  search: string;
  filtro: string;
  habilitada: boolean;
}
interface NColaboradores {
  nColaboradores: number;
  nRespostas: number;
  habilitada: boolean;
}

interface StatusResposta {
  idQuestionario: string;
  idEmpresa: string;
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
// prettier-ignore
const TableColaboradores = ({ empresas, questionario }: { empresas: TConvite[], questionario: string }) => {
  const empresaAtivaId = useUserStore((state) => state.activeEmpresaId);
  const [filter, setFilter] = useState<Filtro>(mockFilter)
  const [newEmpresa, setNewEmpresa] = useState<(TEmpresa & NColaboradores)[]>([]);
  const [loading, setLoading] = useState(false);
  const projeto = useUserStore((state) => state.activeProjeto);
  const { data } = useGetProjetoItem({ idProjeto: projeto || "" });
  const dados = filteredData(newEmpresa, filter);
  const { currentPageContent, currentPage, pageSize, setCurrentPage, setPageSize } = usePaginator(dados);

  const uniqueArray = empresas.filter((item, index, self) =>
    index === self.findIndex((t) => (
      t.idEmpresa === item.idEmpresa
    ))
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadData();
      } catch (error) {
        console.error(message, error);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, [data?.minimoResposta])

  const loadData = async () => {
    if (empresas && empresas?.length > 0) {
      setLoading(true)
      const newEmpresa = await GetEmpresas([...uniqueArray, ...empresas.map(item => ({ ...item, idEmpresa: item.idEmpresaColaboradorConvidado }))]);
      let newRespostas = [] as TResposta[]
      let newConvites = [] as TConvite[]
      for (const empresa of newEmpresa) {
        const respostas = await getRepostasArrayRelatorio(empresaAtivaId || "", empresa.id, questionario);
        const aux = respostas.docs.map((desafio) => desafio.data());
        const convites = await getConvites(empresa.id, questionario);
        const newFormat = convites.docs.map((desafio) => desafio.data());
        newConvites = [...newConvites, ...newFormat]
        newRespostas = [...newRespostas, ...aux]
      }

      let newArray = [] as [];
      for (const item of newEmpresa) {
        const newData = await getRespostasMediaEmpresa(item.id);
        const format = newData.docs.map((resposta) => resposta.data()) || [] as TResposta[] & StatusResposta[];
        newArray = [...newArray, ...format as TResposta[] & StatusResposta[] as []]
      }

      setNewEmpresa(newEmpresa.map(item => {
        const respostas = (newArray as (TResposta & StatusResposta)[]).filter(row => row.idEmpresa === item.id && row.pausado === false && row.idQuestionario === questionario) || [];
        const minimoResposta = data?.minimoResposta || 0;
        return {
          ...item,
          nColaboradores: removeDuplicates(newConvites.filter(row => row.idEmpresaColaboradorConvidado === item.id && !row.cancelado)).length + newRespostas.filter(row => row.idEmpresa === item.id && row.idRespondente === "").length,
          nRespostas: newRespostas?.filter(row => row.idEmpresa === item?.id && !row.pausado).length,
          habilitada: respostas.length >= minimoResposta
        }
      }))
      setLoading(false)
    }
  }

  const rows = currentPageContent.map((item) => (
    <TableColaboradoresItem key={item.id} item={item} />
  ));

  return (
    <>
      <Group justify={"space-between"} mb={10}>
        <Text size={"md"} fw={700}>Detalhamento das inscrições</Text>
        <Group>
          <ModalFilter setFilter={setFilter} />
          <ExportExcel empresas={filteredData(newEmpresa, filter)} />
        </Group>
      </Group>
      <div style={{ overflowX: "auto" }}>
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Empresa</Table.Th>
              <Table.Th>Cnpj</Table.Th>
              <Table.Th>Responsavel</Table.Th>
              <Table.Th>Telefone</Table.Th>
              <Table.Th>Porte</Table.Th>
              <Table.Th>Estado</Table.Th>
              <Table.Th>Nº Convidados</Table.Th>
              <Table.Th>Nº Respostas</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{loading ? <> </> : rows}</Table.Tbody>
        </Table>
        <Flex p="sm" justify="flex-end" gap="sm">
          <RankingListPaginator
            numberOfItems={dados.length}
            currentPage={currentPage}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
            onPageSizeChange={setPageSize}
          />
        </Flex>
      </div>
      <Space h={"md"} />
      {loading ? <Group justify={"center"} align={"center"}>
        <Loader color="blue" />
      </Group> : <></>}
    </>
  )
};

export default TableColaboradores;

// prettier-ignore
const filteredData = (data: (TEmpresa & NColaboradores)[], filter: Filtro) => {
  return data
    .filter(item => doFilter(item, filter))
    .sort((a, b) => b.nRespostas - a.nRespostas);
}

// prettier-ignore
const doFilter = (item: TEmpresa & NColaboradores, filter: Filtro) => {
  switch (filter.filtro) {
    case "": {
      return filterByHabilitada(item, filter);
    }
    case "Empresa": {
      return filterByEmpresa(item, filter);
    }
    case "Cnpj": {
      return filterByCnpj(item, filter);
    }
    case "Responsavel": {
      return filterByResponsavel(item, filter);
    }
    case "Telefone": {
      return filterByTelefone(item, filter);
    }
    case "Porte": {
      return filterByPorte(item, filter);
    }
    case "Estado": {
      return filterByEstado(item, filter);
    }
    case "Nº Convidados": {
      return filterByColaboradores(item, filter);
    }
    default: {
      return item;
    }
  }
}

// prettier-ignore
const filterByEmpresa = (item: TEmpresa & NColaboradores, filter: Filtro) => {
  return filter.habilitada ?
    item.nomeFantasia === filter.search && item.habilitada === filter.habilitada :
    item.nomeFantasia === filter.search;
};
// prettier-ignore
const filterByCnpj = (item: TEmpresa & NColaboradores, filter: Filtro) => {
  return filter.habilitada ?
    item.cnpj === filter.search && item.habilitada === filter.habilitada :
    item.cnpj === filter.search;
};
// prettier-ignore
const filterByResponsavel = (item: TEmpresa & NColaboradores, filter: Filtro) => {
  return filter.habilitada ?
    item.responsavel.nome === filter.search && item.habilitada === filter.habilitada :
    item.responsavel.nome === filter.search;
};
// prettier-ignore
const filterByTelefone = (item: TEmpresa & NColaboradores, filter: Filtro) => {
  return filter.habilitada ?
    item.telefone === filter.search && item.habilitada === filter.habilitada :
    item.telefone === filter.search;
};
// prettier-ignore
const filterByPorte = (item: TEmpresa & NColaboradores, filter: Filtro) => {
  return filter.habilitada ?
    item.porte === filter.search && item.habilitada === filter.habilitada :
    item.porte === filter.search;
};
// prettier-ignore
const filterByEstado = (item: TEmpresa & NColaboradores, filter: Filtro) => {
  return filter.habilitada ?
    item.endereco.estado === filter.search && item.habilitada === filter.habilitada :
    item.endereco.estado === filter.search;
};

// prettier-ignore
const filterByColaboradores = (item: TEmpresa & NColaboradores, filter: Filtro) => {
  return filter.habilitada ?
    item.nColaboradores.toString() === filter.search && item.habilitada === filter.habilitada :
    item.nColaboradores.toString() === filter.search;
};
// prettier-ignore
const filterByHabilitada = (item: TEmpresa & NColaboradores, filter: Filtro) => {
  return filter.habilitada ? filter.habilitada === item.habilitada : item
}
// if (filter.filtro === "Nº Convidados") {
//   return item.nColaboradores.toString() === filter.search
// }
// prettier-ignore
const mockFilter = {
  search: "",
  filtro: "",
  habilitada: false
};
// prettier-ignore
const removeDuplicates = (data: TConvite[]) => {
  const uniqueIds = new Set();
  return data.filter(item => {
    if (!uniqueIds.has(item.idColaboradorConvidado)) {
      uniqueIds.add(item.idColaboradorConvidado);
      return true;
    }
    return false;
  });
};
