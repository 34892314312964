import { subject } from "@casl/ability";
import { Group, Text } from "@mantine/core";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { ProjetoNameDisplay } from "@/modules/projetos/components/projeto-name-display/ProjetoNameDisplay";
import ContextMenuRow from "./ContextMenuRow";

const formatComparisonType = (comparisonType: string) => {
  return comparisonType === "and" ? "Corresponder a todos" : "Corresponder a qualquer";
};

const columnHelper = createColumnHelper<TRegiao>();

const columns: ColumnDef<TRegiao>[] = [
  columnHelper.accessor("name", {
    header: "Nome",
    enableSorting: true,
    enableColumnFilter: true
  }),
  columnHelper.accessor("comparisonType", {
    header: "Tipo de Comparação",
    enableSorting: true,
    enableColumnFilter: true,
    cell: (tipo) => {
      const comparisonType: string = tipo.row.getValue("comparisonType");
      return <Text size={"14px"}>{formatComparisonType(comparisonType)}</Text>;
    }
  }),
  columnHelper.accessor("projetoId", {
    header: "Projeto",
    enableSorting: true,
    enableColumnFilter: true,
    cell: (tipo) => {
      const projetoId: string = tipo.row.getValue("projetoId");
      return <ProjetoNameDisplay projetoId={projetoId} />;
    }
  }),
  columnHelper.display({
    id: "actions",
    cell: (properties) => {
      const regiao = subject("TRegiao", properties.row.original);

      return (
        <Group justify="center" gap={2}>
          <ContextMenuRow regiao={regiao} />
        </Group>
      );
    }
  })
];

export default columns;
