import { Text, Card, Group, ThemeIcon, Skeleton, Avatar } from "@mantine/core";
import { IconPlayerPlay, IconPlayerPause, IconRotateClockwise, IconHexagonLetterX } from "@tabler/icons-react";
import useGetColaboradorByReferencePath from "@/modules/colaboradores/hooks/useGetColaboradorByReferencePath";
import useUserStore from "@/modules/users/store";
import { formatDate } from "@/utils/date";

const ComiteStatusIcon = ({ type }: { type: TComiteInovacaoActivity["type"] }) => {
  switch (type) {
    case "INICIADO": {
      return (
        <ThemeIcon size="sm" radius="xl" color="green">
          <IconPlayerPlay width={18} radius="xl" />
        </ThemeIcon>
      );
    }
    case "PAUSADO": {
      return (
        <ThemeIcon size="sm" radius="xl" color="yellow">
          <IconPlayerPause width={18} radius="xl" />
        </ThemeIcon>
      );
    }
    case "REINICIADO": {
      return (
        <ThemeIcon size="sm" radius="xl" color="">
          <IconRotateClockwise width={18} radius="xl" />
        </ThemeIcon>
      );
    }
    case "FINALIZADO": {
      return (
        <ThemeIcon size="sm" radius="xl" color="pink">
          <IconHexagonLetterX width={18} radius="xl" />
        </ThemeIcon>
      );
    }
    default: {
      return null;
    }
  }
};

const ActivityCardItem = ({ activity }: { activity: TComiteInovacaoActivity }) => {
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const { data: colaborador, isLoading } = useGetColaboradorByReferencePath(
    activeEmpresaId ? `empresas/${activeEmpresaId}/colaboradores/${activity.createdBy}` : null
  );

  return (
    <Card withBorder radius="md" key={activity.id}>
      <Group justify="space-between">
        <Group>
          <ComiteStatusIcon type={activity.type} />
          <Group gap={6}>
            <Text tt="capitalize">{activity.type.toLowerCase()}</Text>
            {colaborador && <Text>por</Text>}
            {isLoading && <Skeleton w={100} height={18} />}
            <Avatar src={(colaborador?.image && colaborador.image.length > 0 && colaborador.image[0]?.path) || ""} />
            {colaborador && <Text>{colaborador.nome || colaborador.email}</Text>}
          </Group>
        </Group>
        <Group gap="xl">
          <Group gap={6}>
            <Text>Data: </Text>
            <Text fz="sm">{formatDate(activity.createdAt, { dateStyle: "short", timeStyle: undefined })}</Text>
          </Group>
          <Group gap={6}>
            <Text>Hora: </Text>
            <Text fz="sm">{formatDate(activity.createdAt, { dateStyle: undefined, timeStyle: "short" })}</Text>
          </Group>
        </Group>
      </Group>
    </Card>
  );
};
export default ActivityCardItem;
