import { Card, Text, Center, Flex, Group, Stack, Title, Button, Loader } from "@mantine/core";
import { useMutation } from "@tanstack/react-query";
import { MouseEvent, useEffect } from "react";
import CleanContainer from "@/components/clean-container";
import useSignOutHandler from "@/hooks/useSignOutHandler";
import { acceptInviteAsColaborador } from "@/modules/users/functions";
import { getCurrentUser } from "@/services/auth";
import ChangePasswordForm from "../../modules/users/components/change-password-form";

const SignOutButton = () => {
  const signOutHandler = useSignOutHandler();

  /**
   * Handle logout click
   * @param event - Click event
   */
  function handleLogout(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    signOutHandler().catch((error) => {
      // Trate o erro, se necessário
      console.error("Erro durante o logout:", error);
    });
  }

  return (
    <Button type="button" onClick={handleLogout} variant="subtle" size="compact-xs">
      Sair
    </Button>
  );
};

type TAcceptInviteResponse = Awaited<ReturnType<typeof acceptInviteAsColaborador>>;
/**
 * Accept invite as colaborador mutation hook
 * @returns Accept invite as colaborador mutation hook
 */
function useAcceptInviteAsColaboradorMutation() {
  return useMutation<TAcceptInviteResponse, Error>({
    mutationFn: () => acceptInviteAsColaborador()
  });
}

const PasswordChangePage = () => {
  const currentUser = getCurrentUser();
  const { mutate: acceptInvite, isPending } = useAcceptInviteAsColaboradorMutation();

  useEffect(() => {
    acceptInvite();
  }, [acceptInvite]);

  return (
    <CleanContainer>
      <Center w="50vw">
        <Stack>
          <Card shadow="sm" p="md" withBorder>
            <Card.Section inheritPadding py="md" withBorder>
              <Title order={4}>Definir Senha</Title>
              <Text>Defina sua senha de acesso </Text>
            </Card.Section>
            <Card.Section inheritPadding py="md" withBorder>
              {isPending ? (
                <Center p="lg">
                  <Loader size="lg" />
                </Center>
              ) : (
                <ChangePasswordForm />
              )}
            </Card.Section>
          </Card>

          {currentUser && (
            <Group justify="center">
              <Stack gap={0}>
                <Group gap={6}>
                  <Text fz="sm">Olá</Text>
                  <Text fz="sm" fw={500}>
                    {currentUser.displayName}
                  </Text>
                </Group>
                <Flex gap={0} justify="center" align="center" direction="row" wrap="nowrap">
                  <Text size="xs" c="dimmed">
                    Não é você?
                  </Text>
                  <SignOutButton />
                </Flex>
              </Stack>
            </Group>
          )}
        </Stack>
      </Center>
    </CleanContainer>
  );
};

export default PasswordChangePage;
