import { Button, Stack, Text, isLightColor, lighten, parseThemeColor, useMantineTheme } from "@mantine/core";

const LIGHTEN_BUTTON_PERCENT = 0.2;

type IdeiasFilterButtonLoadingColProperties = {
  text: string;
  color: string;
};

const IdeiasFilterButtonLoadingCol = ({ text, color }: IdeiasFilterButtonLoadingColProperties) => {
  const theme = useMantineTheme();

  const parsedColor = parseThemeColor({ color, theme });
  const bg = lighten(parsedColor.value, LIGHTEN_BUTTON_PERCENT);

  return (
    <Button radius="md" bg={bg} h={64} loading={true} disabled autoContrast>
      <Stack gap={0} pr="xl" align="start">
        <Text fz="sm" c={isLightColor(parsedColor.value) ? "dark" : "light"}>
          {text}
        </Text>
      </Stack>
    </Button>
  );
};

export default IdeiasFilterButtonLoadingCol;
