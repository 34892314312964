import QuestionariosList from "@/modules/questionario-colaborador/components/questionarios-list";

const QuestionarioColaboradorPage = () => {
  return (
    <>
      <QuestionariosList />
    </>
  );
};

export default QuestionarioColaboradorPage;
