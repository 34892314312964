import { useMutation } from "@tanstack/react-query";
import { FirestoreError } from "firebase/firestore";
import useUserStore from "@/modules/users/store";
import { addEmbaixadorPessoa, TEmbaixadorPessoaForm } from "../../firestore";

type TAddEmbaixadorPessoaResponse = Awaited<ReturnType<typeof addEmbaixadorPessoa>>;
/**
 * Finalizar resposta de inovação mutation hook
 * @returns Finalizar resposta de inovação mutation
 */
// prettier-ignore
// eslint-disable-next-line import/no-unused-modules
export function useAddEmbaixadorPessoa() {
  const activeEmpresaId = useUserStore((store) => store.activeEmpresaId);

  return useMutation<TAddEmbaixadorPessoaResponse, FirestoreError, TEmbaixadorPessoaForm>({
    mutationFn(item) {
      if (!activeEmpresaId) {
        throw new Error("Empresa não selecionada");
      }
      if (!item) {
        throw new Error("Dados do embaixador não fornecidos");
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      return addEmbaixadorPessoa(activeEmpresaId, item);
    },
  });
}
